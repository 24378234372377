import React, { useRef, forwardRef, useImperativeHandle } from 'react';

import { Name, NameEdit, Form } from './styles';
import { defaultTheme } from './theme';
import { EditableLabelProps } from './types';

const EditableLabel = forwardRef<
  HTMLInputElement | undefined,
  EditableLabelProps
>((props, ref) => {
  const theme = { ...defaultTheme, ...props.theme };
  const inputRef = useRef<HTMLInputElement>();
  const valueHasChanged = useRef(false);

  useImperativeHandle(ref, () => inputRef.current);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    props.onChange && props.onChange(event.target.value);
    valueHasChanged.current = true;
  };

  const handleCommit = (value: string): void => {
    if (valueHasChanged.current && props.onCommit) {
      props.onCommit(value);
    }
    valueHasChanged.current = false;
  };

  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    inputRef.current?.blur(); // will trigger handleCommit
  };

  if (props.locked) {
    return <Name theme={theme}>{props.value}</Name>;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <NameEdit
        ref={inputRef}
        value={props.value}
        onChange={handleOnChange}
        onBlur={(event: React.FocusEvent<HTMLInputElement>): void =>
          handleCommit(event.target.value)
        }
        theme={theme}
        maxLength={props.maxLength}
      />
    </Form>
  );
});

EditableLabel.defaultProps = {
  maxLength: 24,
};

export default EditableLabel;
