import rasterizeImage from '../../helpers/rasterizeImage/rasterizeImage';

/**
 * Maybe register utils for e2e tests.
 * Utils will be registered under `Cypress._kittlUtils` rather than `window`,
 * as this avoids the asyncroness brought by `cy.window().invoke(METHOD)`.
 */
const maybeRegisterE2ETestUtils = (canvas) => {
  if (!window.Cypress) return;

  window.Cypress._kittlUtils = {
    getArtboardScale: () => canvas.getZoom(),
    getArtboardDimension: (opts) => {
      const { x, y, width, height } = rasterizeImage.getArtboardImageClip(
        canvas,
        {
          forViewport: true,
          ...opts,
        }
      );
      return { x, y, width, height };
    },
  };
};

export default maybeRegisterE2ETestUtils;
