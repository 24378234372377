import React from 'react';

import Spacer from '../Spacer/Spacer';
import { H3, P2 } from '../utilities/Typography/styles';
import { Wrapper } from './styles';
import Button from '../Button/Button/Button';
import { primaryTheme } from '../Button/Button/theme';

const OldBrowserWarning = () => {
  return (
    <Wrapper>
      <H3>Your browser is not supported</H3>
      <Spacer h="12px" />
      <P2>
        To use Kittl, we recommend using the latest version of Google Chrome,
        Firefox or Safari.
      </P2>
      <Spacer h="24px" />
      <Button
        as="a"
        href="https://www.kittl.com/help/account/update-your-browser"
        label="Let us help you"
        theme={primaryTheme}
        width="150px"
      />
    </Wrapper>
  );
};

export default OldBrowserWarning;
