import PropTypes from 'prop-types';

export const UnsplashPhoto = PropTypes.shape({
  artistName: PropTypes.string,
  blurHash: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
  downloadLocation: PropTypes.string,
  height: PropTypes.number,
  id: PropTypes.string,
  unsplashProfile: PropTypes.string,
  urlFull: PropTypes.string,
  urlRaw: PropTypes.string,
  width: PropTypes.number,
});
