import { useEffect } from 'react';

import fabric from '../../Artboard/fabric';
import { MockupTemplate, UpdateLayersLoading } from '../types';
import { darkBlendLayerId, lightBlendLayerId, sceneLayerId } from '../utils';

const removeExistingLayers = (canvas: fabric.Canvas): void => {
  const layersToRemove = canvas
    .getObjects()
    .filter(
      (object: fabric.Object): boolean =>
        object.id === sceneLayerId ||
        object.id === darkBlendLayerId ||
        object.id === lightBlendLayerId
    );
  canvas.remove(...layersToRemove);
};

/**
 * This hook updates the canvas with layers upon mockup template changes.
 */
export const useMockupTemplateLayersEffect = (
  canvas: fabric.Canvas | null,
  mockupTemplate: MockupTemplate | null,
  updateLoading: UpdateLayersLoading
): void => {
  useEffect(() => {
    if (!mockupTemplate || !canvas) return;

    removeExistingLayers(canvas);

    // scene layer
    fabric.Image.fromURL(
      mockupTemplate.sceneLayer,
      (object: fabric.Image) => {
        object.set('id', sceneLayerId);
        object.set('locked', true);
        canvas.insertAt(object, 2);

        updateLoading({
          sceneLayer: false,
        });
      },
      { crossOrigin: 'anonymous' }
    );

    // blend layers
    if (mockupTemplate.lightBlendLayer) {
      fabric.Image.fromURL(
        mockupTemplate.lightBlendLayer,
        (object: fabric.Image) => {
          object.set('id', lightBlendLayerId);
          object.set('locked', true);
          object.set('globalCompositeOperation', 'multiply');
          canvas.insertAt(object, 3);

          updateLoading({
            lightBlendLayer: false,
          });
        },
        { crossOrigin: 'anonymous' }
      );
    } else {
      updateLoading({
        lightBlendLayer: false,
      });
    }

    if (mockupTemplate.darkBlendLayer) {
      fabric.Image.fromURL(
        mockupTemplate.darkBlendLayer,
        (object: fabric.Image) => {
          object.set('id', darkBlendLayerId);
          object.set('locked', true);
          object.set('globalCompositeOperation', 'screen');
          canvas.insertAt(object, 4);

          updateLoading({
            darkBlendLayer: false,
          });
        },
        { crossOrigin: 'anonymous' }
      );
    } else {
      updateLoading({
        darkBlendLayer: false,
      });
    }
  }, [canvas, mockupTemplate, updateLoading]);
};
