import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';

import { QUERIES } from '../../utilities/queries';
import { fadeIn } from '../../utilities/styles';
import { P3 } from '../../utilities/Typography/styles';

export const PreviewWrapper = styled.div<any>`
  aspect-ratio: 1;
  border-radius: ${themeStyle.radiusMedium};
  margin-bottom: ${({ theme }) => theme.labelGap};

  box-shadow: ${({ isActive }) =>
    isActive ? '0px 0px 5px rgba(0, 0, 0, 0.25)' : 'unset'};
  transition: box-shadow 0.3s;
`;

export const Previews = styled.div<any>`
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1px;
  grid-template-areas:
    'large large large'
    'large large large'
    'small small small';
  border: solid ${themeStyle.transparentBlack};
  border-width: ${({ isActive }) => (isActive ? '0px' : '1px')};
  background: ${themeStyle.varInkLight};
  border-radius: ${themeStyle.radiusMedium};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  overflow: hidden;

  filter: contrast(
    ${({ hasContrastTransition }) => (hasContrastTransition ? '0.5' : '1')}
  );
  transition: filter 0.5s, border-width 0.3s;

  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: ${themeStyle.radiusMedium};

    background-color: ${themeStyle.transparent};
    border: solid ${themeStyle.inkBase};
    border-width: ${({ isActive }) => (isActive ? '12px' : '0px')};

    transition: background-color 0.3s, border-width 0.3s;
  }

  &:hover {
    &:after {
      background-color: ${({ hasDarkOverlayTransition }) =>
        hasDarkOverlayTransition
          ? `${themeStyle.darkInkMain}33`
          : themeStyle.transparent};
    }
  }
`;

export const Preview = styled.img.attrs(
  ({ gridArea, src }: { gridArea?: string; src: string }) => ({
    as: src ? 'img' : 'div',
    style: { gridArea },
  })
)<any>`
  width: 100%;
  height: 100%;
  background: ${themeStyle.varInkLight};
  object-fit: cover;
  overflow: hidden;

  &:nth-child(1) {
    border-radius: ${themeStyle.radiusMedium} ${themeStyle.radiusMedium} 0px 0px;
  }

  &:nth-child(2) {
    border-bottom-left-radius: ${themeStyle.radiusMedium};
  }

  &:nth-child(4) {
    border-bottom-right-radius: ${themeStyle.radiusMedium};
  }
`;

export const OverlayWrapper = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;

  opacity: 1;
  transition: opacity 0.3s;

  ${QUERIES.largeTabletAndUp} {
    opacity: 0;
  }
`;

export const OuterWrapper = styled.div<any>`
  position: relative;
  margin-bottom: ${({ theme }) => theme.marginBottom};

  animation: ${fadeIn};
  animation-duration: 1s;

  &:hover {
    ${Previews} {
      filter: contrast(1);
    }
    ${OverlayWrapper} {
      opacity: 1;
    }
  }
`;

export const Total = styled(P3)<any>`
  line-height: 19px;
  margin-top: 4px;
  color: ${({ theme }) => theme.editColor};
  cursor: default;
`;
