import { useEffect } from 'react';

import fabric from '../components/Artboard/fabric';
import { themeStoreSelector, useThemeStore } from '../services/theming';
import { getNonTrimViewBoardBackground } from '../utils/editor/theme';

/**
 * This hook handles effects on change of theme.
 */
export const useThemeChanged = (canvas: fabric.Canvas | null): void => {
  const theme = useThemeStore(themeStoreSelector.resolvedTheme);

  useEffect(() => {
    if (!canvas) return;

    canvas.set('backgroundColor', getNonTrimViewBoardBackground());
    canvas.requestRenderAll();
  }, [canvas, theme]);
};
