import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  InputBox,
  Input,
  InputContainer,
  ArrowsContainer,
  ArrowClickArea,
} from './styles';
import { numberInputTheme } from './theme';
import useDynamicTheme from '../../hooks/useDynamicTheme';
import useRAFThrottle from '../../hooks/useRAFThrottle';
import useDebounce from '../../hooks/useDebounce';
import useLongPressIncrement from '../../hooks/useLongPressIncrement';
import WithSlider from '../../hocs/WithSlider/WithSlider';
import { isCompatibleTouchDevice } from '../../utils/detection';
import { NumberInputProps } from './types';
import { ReactComponent as Arrows } from './arrows.svg';

const ARROW_UP = 38;
const ARROW_DOWN = 40;
const ONCHANGED_DELAY = 300;

const isValidInput = (value: string, precision = 2, unit = ''): boolean => {
  const inputRegEx = new RegExp(
    `^[+-]?[0-9]*([.|,][0-9]{0,${precision}})?${unit}?$`
  );
  return inputRegEx.test(value);
};

const NumberInput: React.FC<NumberInputProps> = ({
  name,
  value,
  max = 100,
  min = 0,
  precision = 2,
  unit = '',
  prefix = '',
  disabled,
  isActive,
  onChanged,
  onChanging,
  onValueEdit,
  onBlur,
  onFocus,
  selectOnFocus,
  onEnter,
  startingPlaceholder,
  dataTestId,
  debounceOnChanged,
  ...props
}) => {
  const [theme] = useDynamicTheme(numberInputTheme, props.theme);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [disabledArrows, setDisabledArrows] = useState(false);

  const step = props.step ?? 0.1 ** precision;
  const marks = useMemo(() => {
    if (!props.marks) return null;
    return [min, ...props.marks.filter((m) => m > min && m < max), max];
  }, [min, props.marks, max]);

  const withoutPrefix = useCallback(
    (string: string): string => {
      return string.replace(prefix, '');
    },
    [prefix]
  );

  const inputChanged = useRef(false);
  const getShowValue = useCallback(
    (input: number | string) => {
      if (startingPlaceholder && !inputChanged.current) {
        return startingPlaceholder;
      }

      const parsedInput =
        typeof input === 'string' ? parseFloat(input.replace(',', '.')) : input;
      const limitedInput = Math.max(min, Math.min(parsedInput, max));
      const preciseInput = limitedInput.toFixed(precision);
      return `${prefix}${preciseInput}${unit}`;
    },
    [max, min, precision, unit, prefix, startingPlaceholder]
  );

  const initialValue = value.value ?? min;
  const [val, setVal] = useState(initialValue);

  /*
    currentVal is useful for checking when we should trigger the onChanged function
    using val to compare against the new value can fail if the component hasn't yet
    re-rendered after a change in the input value
  */
  const currentVal = useRef<number>(val);
  const [showVal, setShowVal] = useState(getShowValue(initialValue));
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setVal(initialValue);
    setShowVal(getShowValue(initialValue));
    currentVal.current = initialValue;
  }, [getShowValue, initialValue]);

  const onChangingThrottled = useRAFThrottle(
    (value: number) => onChanging && onChanging(value)
  );

  const onChangedDebounced = useDebounce({
    callback: (value: number) => onChanged && onChanged(value),
    delay: ONCHANGED_DELAY,
  });

  const setShowValueAndValue = useCallback(
    (value: string | number, { throttleAndDebounce = true } = {}) => {
      if (typeof value === 'string' && Number.isNaN(parseFloat(value))) {
        value = val;
      }

      const _onChanging = throttleAndDebounce
        ? onChangingThrottled
        : onChanging;
      const _onChanged =
        throttleAndDebounce && debounceOnChanged
          ? onChangedDebounced
          : onChanged;

      let valueToShow: string;
      if (!inputChanged.current) {
        inputChanged.current = true;

        if (startingPlaceholder) {
          valueToShow = getShowValue(val);
          setShowVal(valueToShow);
          currentVal.current = val;
          _onChanging?.(val);
          _onChanged?.(val);
          return;
        }
      }

      valueToShow = getShowValue(value);
      const parsedValue = parseFloat(withoutPrefix(valueToShow));

      if (marks && !marks.includes(parsedValue)) {
        // We revert back since val hasn't been set yet.
        setShowVal(getShowValue(val));
      } else {
        setVal(parsedValue);
        setShowVal(valueToShow);

        if (currentVal.current !== parsedValue) {
          currentVal.current = parsedValue;

          _onChanging?.(parsedValue);
          _onChanged?.(parsedValue);
        }
      }
    },
    [
      getShowValue,
      onChanged,
      onChanging,
      onChangedDebounced,
      onChangingThrottled,
      marks,
      val,
      withoutPrefix,
      startingPlaceholder,
      debounceOnChanged,
    ]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = withoutPrefix(event.target.value);
      if (!isValidInput(inputValue, precision, unit)) return;
      setShowVal(`${prefix}${inputValue}`);

      onValueEdit && onValueEdit(parseFloat(event.target.value));
    },
    [onValueEdit, precision, unit, prefix, withoutPrefix]
  );

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
        setShowValueAndValue(withoutPrefix(event.target.value));
        onEnter && onEnter(currentVal.current);
        inputRef.current?.blur();
      }
    },
    [setShowValueAndValue, withoutPrefix, onEnter]
  );

  const nextOrPreviousPredefinedValue = useCallback(
    (direction: number): number => {
      if (!marks) throw new Error('No marks are given');
      const currentValue = currentVal.current;

      const isMin = currentValue === min;
      const isMax = currentValue === max;
      if (isMin && direction === -1) return currentValue;
      if (isMax && direction === 1) return currentValue;

      const currentValueIdx = marks.indexOf(currentValue);
      return marks[currentValueIdx + direction];
    },
    [currentVal, marks, min, max]
  );

  const _stepInput = useCallback(
    (direction: number): void => {
      if (marks) {
        setShowValueAndValue(nextOrPreviousPredefinedValue(direction));
      } else {
        const newValue = parseFloat(withoutPrefix(showVal));
        setShowValueAndValue(newValue + direction * step);
      }

      if (inputRef.current) inputRef.current.scrollLeft = 0;
    },
    [
      inputRef,
      showVal,
      setShowValueAndValue,
      step,
      marks,
      nextOrPreviousPredefinedValue,
      withoutPrefix,
    ]
  );

  const handleUpArrowClick = useCallback(
    (
      event:
        | React.KeyboardEvent<HTMLInputElement>
        | React.MouseEvent<HTMLElement>
    ) => {
      event.preventDefault();
      if (event.shiftKey) {
        _stepInput(+10);
      } else {
        _stepInput(+1);
      }
    },
    [_stepInput]
  );

  const handleDownArrowClick = useCallback(
    (
      event:
        | React.KeyboardEvent<HTMLInputElement>
        | React.MouseEvent<HTMLElement>
    ) => {
      event.preventDefault();
      if (event.shiftKey) {
        _stepInput(-10);
      } else {
        _stepInput(-1);
      }
    },
    [_stepInput]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.keyCode === ARROW_UP) {
        handleUpArrowClick(event);
      }
      if (event.keyCode === ARROW_DOWN) {
        handleDownArrowClick(event);
      }
    },
    [handleUpArrowClick, handleDownArrowClick]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocus(false);
      setShowValueAndValue(withoutPrefix(event.target.value), {
        throttleAndDebounce: false,
      });
      setDisabledArrows(false);
      setFocus(false);

      if (inputRef.current) inputRef.current.scrollLeft = 0;
      if (onBlur) onBlur(event);
    },
    [setShowValueAndValue, withoutPrefix, onBlur]
  );

  const handleFocus = useCallback(() => {
    setFocus(true);
    setDisabledArrows(true);
    setFocus(true);
    if (selectOnFocus) inputRef.current?.select();
    if (onFocus) onFocus();
  }, [selectOnFocus, onFocus]);

  const [onLongPressUp, onLongPressReleaseUp] = useLongPressIncrement(
    (increment) => {
      _stepInput(increment);
    }
  );
  const [onLongPressDown, onLongPressReleaseDown] = useLongPressIncrement(
    (increment) => {
      _stepInput(-increment);
    }
  );

  useEffect(() => {
    const handleScroll = (event: WheelEvent): void => {
      if (!(event.target instanceof HTMLInputElement)) return;
      if (!(event.target.selectionStart || event.target.selectionEnd)) return;
      event.preventDefault();
      const dir = Math.sign(event.deltaY) * -1;
      _stepInput(dir);

      // NOTE: this does not work reliably
      event.target.select(); // reselect input value
    };
    const handleWindowScroll = (event: WheelEvent): void => {
      if (event.target === inputRef.current) {
        handleScroll(event);
      }
    };
    const _inputRef = inputRef.current;
    if (_inputRef && focus) {
      _inputRef.addEventListener('wheel', handleScroll, { passive: false });
      // NOTE: after the first event, wheel is triggered on window, not on the input
      // we need to investigate this and remove listener from window
      window.addEventListener('wheel', handleWindowScroll, { passive: false });
    }

    return (): void => {
      _inputRef?.removeEventListener('wheel', handleScroll);
      window.removeEventListener('wheel', handleWindowScroll);
    };
  }, [inputRef, _stepInput, focus]);

  const onInputContainerClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const onInputBoxClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled) event.stopPropagation();
    },
    [disabled]
  );

  // This scenarios make no sense
  if (min > max)
    throw new Error('Props min and max should be such that max >= min');

  return (
    <InputBox
      data-testid={dataTestId}
      disabled={disabled}
      isActive={isActive && !disabled}
      theme={theme}
      hasPrefix={prefix}
      onClick={onInputBoxClick}
    >
      <InputContainer
        theme={theme}
        hideArrows={props.hideArrows}
        disabled={disabled}
        onClick={isCompatibleTouchDevice() ? onInputContainerClick : undefined}
      >
        <Input
          name={name}
          ref={inputRef}
          disabled={disabled}
          isActive={isActive && !disabled}
          theme={theme}
          value={showVal}
          onChange={!disabled ? handleInputChange : undefined}
          onBlur={!disabled ? handleBlur : undefined}
          onFocus={!disabled ? handleFocus : undefined}
          onKeyUp={!disabled ? handleKeyUp : undefined}
          onKeyDown={!disabled ? handleKeyDown : undefined}
          evented={!isCompatibleTouchDevice() || focus}
        />
      </InputContainer>
      {!props.hideArrows && (
        <ArrowsContainer
          isActive={isActive && !disabled}
          disabled={disabled || disabledArrows}
          theme={theme}
        >
          <ArrowClickArea
            direction="up"
            onClick={handleUpArrowClick}
            onMouseDown={onLongPressUp}
            onMouseUp={onLongPressReleaseUp}
            onMouseLeave={onLongPressReleaseUp}
            disabled={disabled || disabledArrows}
          />
          <ArrowClickArea
            direction="down"
            onClick={handleDownArrowClick}
            onMouseDown={onLongPressDown}
            onMouseUp={onLongPressReleaseDown}
            onMouseLeave={onLongPressReleaseDown}
            disabled={disabled || disabledArrows}
          />
          <Arrows width="6px" height="18px" />
        </ArrowsContainer>
      )}
    </InputBox>
  );
};

NumberInput.defaultProps = {
  value: { value: undefined },
  selectOnFocus: true,
  debounceOnChanged: true,
};

export const VanillaNumberInput = NumberInput;

export default WithSlider(NumberInput);
