import styled from 'styled-components';
import { P3, P4 } from '../utilities/Typography/styles';
import { themeStyle } from '../../services/theming';

export const Image = styled.img``;

export const Box = styled.div`
  width: 116px;
  height: 116px;
  background: ${themeStyle.varInkSuperLight};
  border-radius: ${themeStyle.radiusMedium};
  border: 1px solid ${themeStyle.transparent};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: ${themeStyle.varBackgroundAlt};
    border-color: ${themeStyle.varInkLight};
  }

  html[data-theme='dark'] && {
    &:nth-last-child(2),
    :last-child {
      ${Image} {
        filter: invert(1);
      }
    }
  }
`;

export const Boxes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  margin-top: 8px;
  margin-bottom: 10px;
`;

export const Label = styled(P4)`
  color: ${themeStyle.varInkMedium};
  cursor: pointer;
`;

export const Link = styled.a`
  height: 22px;
  text-decoration: none;
`;

export const More = styled(P3)`
  text-decoration-line: underline;
  color: ${themeStyle.varInkMain};
`;

export const CopyLinkPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${themeStyle.varBrandSuperLight};
  border-radius: 5px;
  padding: 12px;
`;

export const PublishPanel = styled.div`
  position: relative;
  background: ${themeStyle.varInkSuperLight};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 225px;
`;

export const PreviewImage = styled.img`
  border-radius: 5px;
  position: absolute;
`;

export const BackgroundImage = styled.img`
  margin-top: 20px;
`;
