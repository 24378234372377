// white space tokenize a given query string
export const tokenize = (query: string): string[] =>
  query.split(' ').filter((token) => token !== '');

// replace the last tokens of the query that would be replaced by the new tag
export const getRestOfQueryForTag = (query: string, tag: string): string[] => {
  const tagTokens = tokenize(tag);
  const queryTokens = tokenize(query);

  // remove last n tokens that contain tag
  let i = queryTokens.length - 1;
  const queryLength = queryTokens.length;
  while (i >= 0 && i >= queryLength - tagTokens.length) {
    const token = queryTokens[i].toLowerCase();
    if (
      tagTokens.some((tagToken) => tagToken.toLowerCase().indexOf(token) >= 0)
    ) {
      queryTokens.pop();
      i--;
    } else {
      // if one of the query tokens in range is not
      // part of the tag stop
      break;
    }
  }
  return queryTokens;
};

export const getPrefix = (
  lastToken: string | undefined,
  preQueryTokens: string[]
): string => {
  if (!lastToken) return '';
  if (preQueryTokens.length) return `... ${lastToken} `;
  return `${lastToken} `;
};
