import styled from 'styled-components';

import { hideScrollBar } from '../utilities/styles';
import VerticallyScrollable from '../VerticallyScrollable/VerticallyScrollable';
import { scrollbarWidth } from '../VerticallyScrollable/style';

export const Wrapper = styled.div.attrs(({ translate }) => ({
  style: {
    transform: `translateY(${translate}px)`,
  },
}))`
  width: 100%;
  transition: ${({ currentFocused }) =>
    currentFocused ? 'unset' : 'transform 0.3s ease-in-out'};
`;

export const GroupWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Loader = styled.div`
  width: 100%;
  height: 0;
`;

export const Groups = styled(VerticallyScrollable)`
  ${({ allowScroll }) =>
    !allowScroll &&
    `
      overflow-y: hidden;
      padding-right: ${scrollbarWidth};
      ${hideScrollBar};
    `}
`;
