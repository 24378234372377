import React, { useEffect, useState } from 'react';
import { defaultTheme } from './theme';
import PropTypes from 'prop-types';
import { Wrapper, InnerDot, InnerRing } from './styles';

/**
 * Show a pulsating circle.
 * Can be used to highlight a certain part of the screen.
 */
const PulsatingCircle = (props) => {
  const [show, setShow] = useState(true);
  const theme = { ...defaultTheme, ...props.theme };

  useEffect(() => {
    const timeout = setTimeout(
      () => setShow(false),
      (props.showTime ?? 2) * 1000
    );
    return () => clearTimeout(timeout);
  }, [props.showTime]);

  return (
    show && (
      <Wrapper theme={theme} style={props.style}>
        <InnerDot theme={theme} />
        <InnerRing theme={theme} />
      </Wrapper>
    )
  );
};

PulsatingCircle.propTypes = {
  /**
   * style object, to determine position of the circle
   */
  style: PropTypes.shape({}),
  /**
   * how long circle should be visible, in seconds
   */
  showTime: PropTypes.number,
  /**
   * theme object
   */
  theme: PropTypes.shape({}),
};

export default PulsatingCircle;
