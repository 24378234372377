export type JSONObject = { [key: string]: JSONValue };

export type JSONValue =
  | null
  | boolean
  | number
  | string
  | Array<JSONValue>
  | JSONObject;

export interface ResponseWithError {
  error: string;
}

export type EventBusData = { key: string; val?: unknown };

export type Dispatcher = (key: string, value?: unknown) => void;

export enum WorkingMode {
  'Default' = 'default', // one is working on a design
  'Mockup' = 'mockup', // one is working on a mockup
  'MockupTemplate' = 'mockupTemplate', // one is working on a mockup template
}
