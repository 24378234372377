import React, { useRef, useState, useEffect, useCallback } from 'react';
import ElementGallery from '../ElementGallery/ElementGallery';
import Api from '../../global/api';
import { Wrapper } from './styles';
import PropTypes from 'prop-types';
import { DEFAULT_TAKE } from '../../global/constants';

const getFetcher = (elementType) => {
  switch (elementType) {
    case 'templates':
      return Api.getSearchTemplates;
    case 'elements':
      return Api.getSearchElements;
    case 'textures':
      return Api.getSearchTextures;
    default:
      throw new Error(
        `Could not find a fetch function for specified type ${elementType}`
      );
  }
};

const getFetchedResults = (response, elementType) => {
  if (response.error) return null;
  if (elementType === 'textures') return response.elements;
  return response[elementType];
};

/*
 * Used to display elements after a search is performed.
 */
const ElementSearch = (props) => {
  const { query, collapsed, categoryName, render, sort, onGoBack } = props;

  const [elements, setElements] = useState([]);
  const fetchInfo = useRef({
    take: DEFAULT_TAKE,
    skip: 0,
    fetching: false,
    finished: false,
  });

  const fetch = useCallback(async () => {
    const { take, skip, fetching, finished } = fetchInfo.current;
    if (fetching || finished) {
      return;
    }
    fetchInfo.current.fetching = true;
    const fetcher = getFetcher(props.elementType);
    const elements = await fetcher(query, take, skip, sort, categoryName);

    if (query !== currentQuery.current || sort !== currentSort.current) return;

    const results = getFetchedResults(elements, props.elementType);
    if (!results) {
      fetchInfo.current.finished = true;
      return;
    }

    setElements((prevElements) => {
      const newElements = [...prevElements, ...results];
      if (newElements.length >= elements.total)
        fetchInfo.current.finished = true;
      return newElements;
    });

    fetchInfo.current.skip += take;
    fetchInfo.current.fetching = false;
  }, [categoryName, query, sort, props.elementType]);

  const currentQuery = useRef(query);
  const currentSort = useRef(sort);
  const currentCategory = useRef(categoryName);

  useEffect(() => {
    if (
      query === currentQuery.current &&
      sort === currentSort.current &&
      categoryName === currentCategory.current
    )
      return;
    currentQuery.current = query;
    currentSort.current = sort;
    fetchInfo.current = {
      ...fetchInfo.current,
      skip: 0,
      fetching: false,
      finished: false,
    };
    setElements([]);
    fetch();
  }, [query, sort, fetch, categoryName]);

  return (
    <Wrapper>
      <ElementGallery
        collapsed={collapsed}
        elements={elements}
        render={render}
        fetch={fetch}
        labelLeft={`SEARCH: ${query}`}
        dimensions={props.elementGalleryDimensions}
        sideEffectBeginTransition={onGoBack}
      />
    </Wrapper>
  );
};

ElementSearch.propTypes = {
  /**
   * Type of elements to be searched
   */
  elementType: PropTypes.string,
  /**
   * Search query to use
   */
  query: PropTypes.string,
  /**
   * Whether the gallery is collapsed or not
   */
  collapsed: PropTypes.bool,
  /**
   * Name of the category to be searched in
   */
  categoryName: PropTypes.string,
  /**
   * Render function for elements
   */
  render: PropTypes.func,
  /**
   *  Sort method used
   */
  sort: PropTypes.string,
  /**
   * Function to trigger when pressing 'Go Back'
   */
  onGoBack: PropTypes.func,
  /**
   * dimensions object (passed down to ElementGallery)
   */
  elementGalleryDimensions: PropTypes.object,
};

export default ElementSearch;
