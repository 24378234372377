import styled, { keyframes } from 'styled-components';
import { themeStyle } from '../../services/theming';

const OuterRingFrames = keyframes`
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
`;

const InnerRingFrames = keyframes`
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: ${({ theme }) => theme.size}px;
  height: ${({ theme }) => theme.size}px;
  z-index: ${themeStyle.zIndexL9};

  pointer-events: none;

  /* outer ring */
  &::after {
    content: '';
    box-sizing: border-box;
    position: relative;
    display: block;
    width: ${({ theme }) => theme.size * 3}px;
    height: ${({ theme }) => theme.size * 3}px;
    box-sizing: border-box;
    margin-left: -${({ theme }) => theme.size}px;
    margin-top: -${({ theme }) => theme.size}px;
    border-radius: 100px;
    border: 2px solid ${({ theme }) => theme.color};
    animation: ${OuterRingFrames} 1.33s cubic-bezier(0.215, 0.61, 0.355, 1)
      infinite;
  }
`;

export const InnerRing = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.color};
  border-radius: 100px;
  animation: ${InnerRingFrames} 1.33s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
    infinite;
`;

export const InnerDot = styled(InnerRing)`
  left: ${({ theme }) => theme.size / 3}px;
  top: ${({ theme }) => theme.size / 3}px;
  width: ${({ theme }) => theme.size / 3}px;
  height: ${({ theme }) => theme.size / 3}px;
  background: ${({ theme }) => theme.color};
`;
