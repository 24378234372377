import React from 'react';
import ElementGroupShowcase from '../ElementGroupShowcase/ElementGroupShowcase';
import ElementSearch from '../ElementSearch/ElementSearch';
import InfiniteElementSearch from '../ElementSearch/InfiniteElementSearch';

/**
 * Base panel that renders an ElementGroupShowcase or an ElementSearch.
 * It is used as the body of all Elements Panels.
 */
const ElementsBasePanel = (props) => {
  const {
    query,
    render,
    category,
    onSearchGoBack,
    sort,
    elementType,
    headGalleries,
    panelBody,
    lockPanelOverflow,
    hide,
    onGoBack,
    groupSort,
    elementGalleryDimensions,
  } = props;

  const renderComponent = () => {
    if (query) {
      if (elementType === 'elements')
        return (
          <InfiniteElementSearch
            render={render}
            collapsed={false}
            query={query.text || query}
            onGoBack={onSearchGoBack}
            panelBody={panelBody}
            lockPanelOverflow={lockPanelOverflow}
            hide={hide}
            elementGalleryDimensions={{
              ...elementGalleryDimensions,
              heightExpanded: null, // ---> Will be set to 100% internally
            }}
          />
        );

      return (
        <ElementSearch
          render={render}
          collapsed={false}
          query={query.text || query}
          categoryName={category?.label}
          onGoBack={onSearchGoBack}
          sort={sort}
          panelBody={panelBody}
          lockPanelOverflow={lockPanelOverflow}
          hide={hide}
          elementType={elementType}
          elementGalleryDimensions={{
            ...elementGalleryDimensions,
            heightExpanded: null, // ---> Will be set to 100% internally
          }}
        />
      );
    }

    return (
      <ElementGroupShowcase
        elementType={elementType}
        sort={sort}
        category={category?.id}
        render={render}
        headGalleries={headGalleries}
        panelBody={panelBody}
        lockPanelOverflow={lockPanelOverflow}
        hide={hide}
        onGoBack={onGoBack}
        groupSort={groupSort}
        elementGalleryDimensions={elementGalleryDimensions}
      />
    );
  };

  return renderComponent();
};

export default ElementsBasePanel;
