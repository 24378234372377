import fabric from '../../Artboard/fabric';
import { EventBusData } from '../../../types';
import { handleCommonEventBusEvents } from '../../../utils/mockup/handleCommonEventBusEvents';
import { isDebugActive } from '../../../utils/dev';
import {
  MTC_CREATE_WRAP_GRID,
  MTC_DELETE_WRAP_GRID,
  MTC_SAVE,
  SET_STATE,
} from '../../../global/events';
import {
  warpControlRootPointObjectType,
  serialiseWarpControlPoints,
  toggleWarpControlPointsVisibility,
  removeWarpControlPoints,
} from '../../Artboard/fabric/mockupTemplate/utils';
import {
  mockupTemplateStore,
  useMockupTemplateStore,
} from '../../../stores/mockupTemplateStore';
import rasterizeImage from '../../../helpers/rasterizeImage/rasterizeImage';
import { MIN_PROJECT_PREVIEW_SIZE } from '../../../global/constants';
import mockupApi from '../../../api/mockups';
import { useToastStore } from '../../../stores/toastStore';
import { updateWarpControlPoints } from './warpControlPoints';

const handleSetState = (
  canvas: fabric.Canvas,
  {
    isHistoryChange,
    state: newState,
  }: {
    isHistoryChange: boolean;
    state: JSON;
  }
): void => {
  canvas.loadFromJSON(newState, () => {
    if (!isHistoryChange) return;

    const allObjects = canvas.getObjects();
    // only keep root points in groupStructure, because we don't want
    // handles to be direct targets of multi-object selection
    canvas.groupStructure.children = canvas.groupStructure.children.filter(
      ({ id }: { id: string }): boolean => {
        const object = allObjects.find(
          (object: fabric.Object) => object.id === id
        );
        return object?.type === warpControlRootPointObjectType;
      }
    );
  });
};

const handleSaveMockupTemplate = async (
  canvas: fabric.Canvas
): Promise<void> => {
  const layerFiles = mockupTemplateStore.getState().layerFiles;

  if (!layerFiles.sceneLayer) return;

  canvas.discardActiveObject(); // without this positions in the exported preview can be wrong
  toggleWarpControlPointsVisibility(canvas, false);

  const preview = rasterizeImage.getDataUrl(canvas, {
    format: 'jpg',
    quality: 0.7,
    forViewport: true,
    targetSize: MIN_PROJECT_PREVIEW_SIZE,
  });

  toggleWarpControlPointsVisibility(canvas, true);
  const points = serialiseWarpControlPoints(canvas);

  const response = await mockupApi.create({
    preview,
    sceneLayer: layerFiles.sceneLayer,
    darkBlendLayer: layerFiles.darkBlendLayer,
    lightBlendLayer: layerFiles.lightBlendLayer,
    warpGrid: points
      ? {
          ...mockupTemplateStore.getState().warpGridSize,
          points,
        }
      : null,
  });

  const success = 'mockup' in response;
  if (success) {
    mockupTemplateStore.getState().resetTemplateData();
  }
  useToastStore.getState().fire({
    label: success
      ? `Saved Mockup`
      : `Failed to save mockup. ${response.error}`,
    error: !success,
  });
};

export const handleEventBusEvents = async (
  canvas: fabric.Canvas,
  data: EventBusData
): Promise<void> => {
  const eventHandled = await handleCommonEventBusEvents(canvas, data);

  if (!eventHandled) {
    const { key, val } = data;
    switch (key) {
      case SET_STATE: {
        handleSetState(
          canvas,
          val as {
            isHistoryChange: boolean;
            state: JSON;
          }
        );
        break;
      }
      case MTC_SAVE: {
        await handleSaveMockupTemplate(canvas);
        removeWarpControlPoints(canvas);
        break;
      }
      case MTC_CREATE_WRAP_GRID: {
        const warpGridSize = useMockupTemplateStore.getState().warpGridSize;
        updateWarpControlPoints(canvas, warpGridSize.row, warpGridSize.col);
        break;
      }
      case MTC_DELETE_WRAP_GRID: {
        updateWarpControlPoints(canvas);
        break;
      }
      default:
        if (isDebugActive()) {
          console.warn('Unhandled event', data);
        }
    }
  }

  canvas.requestRenderAll();
};
