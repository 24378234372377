export const getConfig = ({ control, altOrOpt }) => [
  {
    label: 'Spacebar',
    description: 'Hold to move canvas',
  },
  {
    label: 'Backspace',
    description: 'Delete selected element',
  },
  {
    label: '+',
    description: 'Zoom in',
  },
  {
    label: '-',
    description: 'Zoom out',
  },
  {
    label: 'Shift + 0',
    description: 'Center artboard',
  },
  {
    label: `${control} + A`,
    description: 'Select all elements',
  },
  {
    label: `${control} + C`,
    description: 'Copy selected elements',
  },
  {
    label: `${control} + V`,
    description: 'Paste selected elements',
  },
  {
    label: `${control} + X`,
    description: 'Cut selected elements',
  },
  {
    label: `${control} + G`,
    description: 'Group selected elements',
  },
  {
    label: `${control} + Z`,
    description: 'Undo action',
  },
  {
    label: `${control} + Shift + Z`,
    description: 'Redo action',
  },
  {
    label: `${control} + dragging`,
    description: 'Avoid snapping',
  },
  {
    label: 'Shift + rotate',
    description: 'Perfect rotation',
  },
  {
    label: 'Shift + scale',
    description: 'Stretch element',
  },
  {
    label: `${altOrOpt} + scale`,
    description: 'Scale from center',
  },
  {
    label: `${control} + '`,
    description: 'Show/Hide Grid',
  },
  {
    label: 'T',
    description: 'Add a text object',
  },
  {
    label: 'W',
    description: 'Trim view',
  },
];
