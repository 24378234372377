import { fabric } from 'fabric';
import paper from '@kittl/paper';

/**
 * utility function to get a paper.Path object from the given
 * fabric object
 * @param {Object} _object - a fabric object of type path, circle, rect, polygon or ellipse
 * @param {boolean} options?.ignorePathOffset - option to ignore the pathOffset for polygons
 */
export const getPaperPath = (
  _object: fabric.Object,
  options: {
    ignorePathOffset: boolean;
  } = {
    ignorePathOffset: false,
  }
): paper.Path | paper.CompoundPath | null => {
  if (_object.type === 'path') {
    const object = _object as fabric.Path;
    const path = fabric.util.joinPath(object.path);
    return paper.Path.create(path);
  }

  if (_object.type === 'circle') {
    const object = _object as fabric.Circle;
    return new paper.Path.Circle(
      new paper.Point(
        object.left + object.width / 2,
        object.top + object.height / 2
      ),
      object.radius
    );
  }

  if (_object.type === 'rect') {
    const object = _object as fabric.Rectangle;
    return new paper.Path.Rectangle({
      from: [object.left, object.top], // left, top
      to: [object.left + object.width, object.top + object.height], // left, top
      radius: object.rx,
    });
  }

  if (_object.type === 'polygon') {
    const object = _object as fabric.Polygon;
    let pathOffset = { x: 0, y: 0 };
    if (!options.ignorePathOffset) {
      pathOffset = object.pathOffset;
    }
    return new paper.Path({
      segments: object.points.map((point: fabric.Point) => [
        point.x - pathOffset.x,
        point.y - pathOffset.y,
      ]),
      closed: true,
    });
  }

  if (_object.type === 'ellipse') {
    const object = _object as fabric.Ellipse;
    return new paper.Path.Ellipse({
      center: [object.left + object.width / 2, object.top + object.height / 2],
      radius: [object.rx, object.ry],
    });
  }

  return null;
};
