// Extends functionality for user input and related things

export const MICRO_DRAG_THRESHOLD = 2.5;

// Uses fabric event interface to check if a click is right click
export const isRightClick = (e) => e.button === 2;

export default function (fabric) {
  fabric.Canvas.prototype._onMouseDown = (function (_onMouseDown) {
    return function (e) {
      if (this.isColorPicking) return;

      const oldActiveObject = this.getActiveObject();
      _onMouseDown.bind(this)(e);

      if (isRightClick(e)) return;

      const activeObject = this.getActiveObject();
      if (activeObject && !this._currentTransform) {
        // By default fabric will only call _setupCurrentTransform when you click on an already selected object.
        // We don't want this, since we want users to be able to select and drag right away,
        // so we make sure it is always set
        this._setupCurrentTransform(
          e,
          activeObject,
          activeObject === oldActiveObject
        );
      }

      // Used to avoid micro-drags
      // Check _transformObject
      this.lastMouseDownPosition = {
        x: e.clientX,
        y: e.clientY,
      };

      // Allow box selection when clicking on artboard
      const { target, pointer } = this._transformCache;
      if (target?.type === 'artboard') {
        this._groupSelector = {
          ex: pointer.x,
          ey: pointer.y,
          top: 0,
          left: 0,
        };
      }
    };
  })(fabric.Canvas.prototype._onMouseDown);

  /*
   * _handleGrouping is the native fabric function that deals with shift + click selection
   * NOTE: this override is made on top of the functionality that canvas_selection.mixin.js adds
   * This is to allow users to shift + click on the current selection and immediately start dragging it.
   * If we don't do it, by default, shift + click on something that is selected already, will de-select it.
   * So instead, we schedule it for later (look into canvas mouse:up event). If, before the mouse up, _transformObject
   * is called, it will cancel the grouping operation, and it won't be performed (see its implementation below).
   */
  fabric.Canvas.prototype._handleGrouping = (function (_handleGrouping) {
    return function (e, target) {
      const grouping = function () {
        _handleGrouping.bind(this)(e, target);
      }.bind(this);

      if (target === this._activeObject) {
        this.registerForMouseUp('grouping', grouping);
      } else {
        grouping();
      }
    };
  })(fabric.Canvas.prototype._handleGrouping);

  // Schedule potential grouping operation for mouse up
  fabric.Canvas.prototype.enqueueGrouping = function (groupingOperation) {
    this.enqueuedGrouping = groupingOperation;
  };

  // Cancel potential grouping operation
  fabric.Canvas.prototype.clearEnqueuedGrouping = function () {
    this.enqueuedGrouping = null;
  };

  /**
   * In the cases where we need the cached transform data, it has already been destroyed
   * So we back it up in _transformCache
   */
  fabric.Canvas.prototype._cacheTransformEventData = (function (
    _cacheTransformEventData
  ) {
    return function (e) {
      _cacheTransformEventData.bind(this)(e);
      this._transformCache = {
        target: this._target,
        pointer: this._absolutePointer,
      };
    };
  })(fabric.Canvas.prototype._cacheTransformEventData);

  /**
   * 1. Allow object transformation only when draggin distance is relevant, and not minuscule.
   * 2. If the transform happens, cancel any potential grouping operations.
   */
  fabric.Canvas.prototype._transformObject = (function (_transformObject) {
    return function (e) {
      if (!this.lastMouseDownPosition) return;
      // Only transform object if the dragging distance is big enough. Avoids micro drags when clicking.
      if (
        Math.hypot(
          e.clientX - this.lastMouseDownPosition.x,
          e.clientY - this.lastMouseDownPosition.y
        ) > MICRO_DRAG_THRESHOLD
      ) {
        // If we transform the object, cancel all callbacks registered for mouseup
        if (this.mouseUpActions) {
          for (const key in this.mouseUpActions) {
            this.unregisterForMouseUp(key);
          }
        }
        return _transformObject.bind(this)(e);
      }
    };
  })(fabric.Canvas.prototype._transformObject);

  fabric.Canvas.prototype._onMouseMove = (function (_onMouseMove) {
    return function (e) {
      if (this.isColorPicking) return;
      return _onMouseMove.bind(this)(e);
    };
  })(fabric.Canvas.prototype._onMouseMove);

  fabric.Canvas.prototype._onTouchStart =
    fabric.Canvas.prototype._onTouchMove =
    fabric.Canvas.prototype._onTouchEnd =
      function () {
        return;
      };
}
