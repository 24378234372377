import { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  GrowthBook,
  GrowthBookProvider as GrowthBookProviderComponent,
} from '@growthbook/growthbook-react';
import { GROWTHBOOK_API_ENDPOINT } from '../global/constants';
import analytics from '../global/analytics';
import { AB_TRACKINGCALLBACK } from '../global/analytics/events';
import { userStore } from '../stores/userStore';
import monitoring from './monitoring';

export let growthbook = undefined;

const initGrowthbook = () => {
  if (!GROWTHBOOK_API_ENDPOINT) return;

  growthbook = new GrowthBook({
    trackingCallback: (experiment, result) => {
      analytics.track(AB_TRACKINGCALLBACK, {
        experiment,
        // For simplicity, we raise experiment name(key) level higher
        experimentName: experiment.key,
        result,
        userId: userStore?.getState().user?.id,
        plan: userStore?.getState().user?.plan || 'FREE',
      });
    },
  });
};

export const GrowthbookProvider = ({ children }) => {
  useEffect(() => {
    if (!GROWTHBOOK_API_ENDPOINT) return;

    initGrowthbook();

    fetch(GROWTHBOOK_API_ENDPOINT)
      .then((res) => res.json())
      .then((json) => {
        growthbook.setFeatures(json.features);
      })
      .catch((error) =>
        monitoring.captureException(error, 'Growthbook CDN request failed')
      );
  }, []);

  if (!GROWTHBOOK_API_ENDPOINT) return children;

  return (
    <GrowthBookProviderComponent growthbook={growthbook}>
      {children}
    </GrowthBookProviderComponent>
  );
};

GrowthbookProvider.propTypes = {
  children: PropTypes.node,
};
