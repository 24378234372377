import { State } from './types';

export const downloadPanelStoreSelector = {
  processing: (state: State): State['processing'] => state.processing,
  options: (
    state: State
  ): State['options'] & { aspectRatio: null | number } => ({
    ...state.options,
    aspectRatio:
      state.options.width && state.options.height
        ? state.options.width / state.options.height
        : null,
  }),
  setProcessing: (state: State): State['setProcessing'] => state.setProcessing,
  setOptions: (state: State): State['setOptions'] => state.setOptions,
  resetOptions: (state: State): State['resetOptions'] => state.resetOptions,
};
