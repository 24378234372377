import styled from 'styled-components';

export const SliderWrapper = styled.div`
  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const PanelBackground = styled.div`
  padding: 12px 16px 16px;
`;
