import paper from '@kittl/paper';

export const INTERPOLATION_PRECISION = 3;

export const getInterpolatedLine = (from, to, n) => {
  const stack = [];
  const incX = (to.x - from.x) / n;
  const incY = (to.y - from.y) / n;

  let lastPoint;
  for (let i = 1; i < n; i++) {
    const x = from.x + incX * i;
    const y = from.y + incY * i;

    if (lastPoint) {
      const distance = Math.hypot(lastPoint.x - x, lastPoint.y - y);
      if (distance > INTERPOLATION_PRECISION) {
        lastPoint = { x, y };
        stack.push(`L ${x} ${y}`);
      }
    } else {
      lastPoint = { x, y };
      stack.push(`L ${x} ${y}`);
    }
  }
  stack.push(`L ${from.x + incX * n} ${from.y + incY * n}`); // to
  return stack.join(' ');
};

export const getInterpolatedPolygon = (tl, tr, bl, br, n = 66) => {
  return `M ${tl.x} ${tl.y} ${getInterpolatedLine(
    tl,
    tr,
    n
  )} ${getInterpolatedLine(tr, br, n)} ${getInterpolatedLine(
    br,
    bl,
    n
  )} ${getInterpolatedLine(bl, tl, n)} z`;
};

export const getInterpolatedRect = (
  _width,
  _height,
  origin = { x: 0, y: 0 },
  n = 66
) => {
  const { x, y } = origin;
  const width = x + _width;
  const height = y + _height;
  return `M ${x} ${y} ${getInterpolatedLine(
    origin,
    {
      x: width,
      y,
    },
    n
  )} ${getInterpolatedLine(
    { x: width, y },
    { x: width, y: height },
    n
  )} ${getInterpolatedLine(
    { x: width, y: height },
    { x, y: height },
    n
  )} ${getInterpolatedLine({ x, y: height }, origin, n)} z`;
};

/**
 * Builds the paper bezier curve with the points passed in input, that are
 * P0 P0C2, P1C1 P1 P1C2, P2C1 P2
 * these points come from freeLine control
 * @param {{x: number, y: number}[]} points - bezier points array (of length 7)
 */
export function bezierPathFromPoints(points) {
  const path = new paper.Path();

  const createPoint = (index) => {
    return new paper.Point(points[index].x, points[index].y);
  };

  // Add a segment to the curve with given point/controls index
  const addSegment = (
    pointIndex,
    inIndex, // control point, handle in
    outIndex // control point, handle out
  ) => {
    let handleIn;
    let handleOut;
    const point = createPoint(pointIndex);

    // in paper the handle is relative to the point
    if (inIndex != null) {
      handleIn = createPoint(inIndex);
      handleIn.x -= point.x;
      handleIn.y -= point.y;
    }
    if (outIndex != null) {
      handleOut = createPoint(outIndex);
      handleOut.x -= point.x;
      handleOut.y -= point.y;
    }
    path.add(new paper.Segment(point, handleIn, handleOut));
  };

  if (points.length === 2) {
    addSegment(0, null, null);
    addSegment(1, null, null);
  } else {
    addSegment(0, null, 1);
    addSegment(3, 2, 4);
    addSegment(6, 5, null);
  }

  return path;
}

/**
 * function to get radius and center of a circle constructed by the given points
 * @param {{x: Number, y: Number}[]} points
 */
export const getCircleParameterFromPoints = (points) => {
  // points 0 and 3 are opposite points
  const left = points[0];
  const right = points[3];
  const radius = Math.hypot(right.x - left.x, right.y - left.y) / 2;
  const center = {
    x: (right.x + left.x) / 2,
    y: (right.y + left.y) / 2,
  };
  return { radius, center };
};
