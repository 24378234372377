import floor from 'lodash/floor';

export const getSupersamplingFactor = (destinationDimension) => {
  // The max dimension of a canvas that browsers support is the main limitation to create the supersampling canvas.
  // Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size
  // It's said that maxArea is ~268_435_456 (16384 x 16384) and maxSideLength is ~32767,
  // but Firefox starts to crash with ~121_000_000 (11000 x 11000),
  // Safari starts to crash with ~100_000_000 (10000 x 10000),
  // that's why we have rather conservative values here.
  const maxArea = 81_000_000;
  const maxSideLength = 18_000;

  // Doesn't make much sense to have a higher value
  const maxFactor = 8;

  const { width, height } = destinationDimension;
  const maxFactorFromArea = Math.sqrt(maxArea / (width * height));
  const maxFactorFromSideLength = maxSideLength / Math.max(width, height);

  return floor(
    Math.min(maxFactor, maxFactorFromArea, maxFactorFromSideLength),
    2
  );
};
