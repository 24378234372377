import { useEffect, useState, useRef } from 'react';

import { getRasterizedDesign } from '../utils';

/**
 * This hook handles the rasterization of the design.
 */
export const useDesignDataUrl = (designId: string): string => {
  const [designDataUrl, setDesignDataUrl] = useState<string>('');
  const rendering = useRef<boolean>(false);

  useEffect(() => {
    if (rendering.current) return;

    rendering.current = true;
    getRasterizedDesign(designId).then((data) => {
      setDesignDataUrl(data);
      rendering.current = false;
    });
  }, [designId]);

  return designDataUrl;
};
