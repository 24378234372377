import styled from 'styled-components';

const ButtonLabel = styled.div<any>`
  margin-left: 8px;

  color: ${(props) =>
    props.disabled
      ? props.theme.disabledColor
      : props.isActive
      ? props.theme.activeDefaultColor
      : props.theme.color};

  border-bottom: ${(props) =>
    props.isActive && props.useActiveUnderline
      ? `2px solid ${props.theme.color}`
      : 'none'};
`;

const ButtonWrapper = styled.button<any>`
  display: inline-block;
  color: unset;
  pointer-events: auto;
  font-size: ${({ theme }) => theme.fontSize};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeight};
  line-height: ${({ theme }) => theme.lineHeight};
  text-decoration: ${({ theme }) => theme.textDecoration};
  width: ${({ theme }) =>
    theme.isBlock ? '100%' : !theme.width ? 'auto' : `${theme.width}`};
  height: ${({ theme }) => (!theme.height ? 'auto' : `${theme.height}`)};
  border: none;
  background-color: transparent;

  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  padding: 0;
  margin: 0;

  &:focus,
  &:active {
    outline: none;
  }

  &:hover:not(:disabled) ${ButtonLabel} {
    border-bottom: ${({ theme }) =>
      theme.useActiveUnderline ? `2px solid ${theme.color}` : 'none'};
    color: ${({ theme }) => theme.activeDefaultColor};
  }
`;

const IconWrapperSpan = styled.span<any>`
  display: inline-flex;
  padding: ${(props) => props.padding};
`;

export { ButtonWrapper, ButtonLabel, IconWrapperSpan };
