import Cookies from 'js-cookie';

import { fetchRetry, OfflineError, tryFetchRetry } from '../utils/fetchRetry';
import { USER_ACCESS_TOKEN_KEY } from './constants';
import {
  ArtboardState,
  Design,
  Folder,
  Post,
  Snapshot,
  Template,
  Invite,
  Notification,
  Challenge,
  ResponseWithError,
  SubscriptionPlan,
  User,
  PromptStyleGroup,
  JSONValue,
} from '../types';
import monitoring from '../services/monitoring';

/**
 * get the current accessToken
 */
export const getAccessToken = (): string | undefined => {
  return Cookies.get(USER_ACCESS_TOKEN_KEY);
};

export const USER_API_URL =
  process.env.REACT_APP_USER_API_URL ||
  process.env.NEXT_PUBLIC_USER_API_URL ||
  process.env.STORYBOOK_USER_API_URL ||
  ''; // having an empty string is better than using undefined as string for testing with cypress

export const uploadObject = async (
  file: string | File,
  type = 'image/jpeg'
): Promise<string> => {
  try {
    let blob: Blob;
    if (typeof file === 'string') {
      blob = await (await fetchRetry(file)).blob();
    } else {
      blob = file;
    }

    const resp = await (
      await fetchRetry(`${USER_API_URL}/users/create_signed_url`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
    ).json();

    if (!resp.url || !resp.objectId) {
      // either connections issues or user is not authenticated anymore
      throw new Error(`Failed to create signed url. ${resp.error}`);
    }

    const uploadResponse = await fetchRetry(resp.url, {
      method: 'PUT',
      body: blob,
      headers: { 'Content-Type': type },
    });
    if (!uploadResponse.ok) {
      throw new Error(
        `Failed to upload object. ${uploadResponse.statusText} (${uploadResponse.status})`
      );
    }

    return resp.objectId as string;
  } catch (error) {
    monitoring.captureException(error);
    throw error;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createAPI = ({ url }: { url: string }) => {
  const getUserInfo = async (
    accessToken: string
  ): Promise<User | ResponseWithError> => {
    const resp = await fetchRetry(`${url}/users/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const result = await resp.json();

    if (result.error) {
      return { error: result.error };
    } else {
      /* Temporary until we solve the whole auth flow */
      if (!result.name && result.email) {
        result.name = result.email.split('@')[0];
      }

      return result;
    }
  };

  // exchange a temporary token obtained from a 3rd party authentication for a permanent one.
  const exchangeTemporaryToken = async (
    token: string
  ): Promise<
    | {
        accessToken: string;
        name: string;
        id: string;
        isSignup: boolean;
        promoCode: string;
        referral: string;
      }
    | ResponseWithError
  > => {
    const res = await fetchRetry(`${USER_API_URL}/sessions/exchange`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeURI(`exchangeToken=${token}`),
    });
    const response = await res.json();
    return response;
  };

  const createSession = async (
    email: string,
    password: string
  ): Promise<(User & { accessToken: string }) | ResponseWithError> => {
    const resp = await fetchRetry(`${url}/sessions/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    const response = await resp.json();
    return response;
  };

  const createUser = async ({
    name,
    email,
    password,
    marketingOptIn = false,
    invite,
    referral,
    promoCode,
    impactClickId,
    campaign,
  }: {
    name: string;
    email: string;
    password: string;
    marketingOptIn?: boolean;
    invite: string;
    referral: string;
    promoCode: string;
    impactClickId: string;
    campaign?: string;
  }): Promise<
    {
      success: boolean;
    } & (
      | {
          accessToken: string;
          id: string;
        }
      | ResponseWithError
    )
  > => {
    const optional = {
      marketingOptIn,
      invite,
      referral,
      promoCode,
      impactClickId,
      campaign,
    };

    const resp = await fetchRetry(`${url}/users/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        password,
        ...optional,
      }),
    });
    const response = await resp.json();
    return response;
  };

  const deleteSession = async (): Promise<{ success: boolean }> => {
    const resp = await fetchRetry(`${url}/sessions/delete`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    const result = await resp.json();

    return result;
  };

  const requestPasswordResetCode = async (
    email: string
  ): Promise<{ error?: string; success?: boolean }> => {
    const resp = await fetchRetry(`${url}/users/send_recovery_code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `email=${encodeURIComponent(email)}`,
    });
    const result = await resp.json();

    return result;
  };

  // Update onboarding state when user follows flow
  const updateOnboardingState = async (step: {
    name: string;
  }): Promise<User | { error: string }> => {
    const data = new FormData();
    data.append('onboardingState', step.name);

    const resp = await fetchRetry(`${url}/users/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: data,
    });
    const result = await resp.json();

    return result;
  };

  // get the uploaded elements from a user
  const getUploadedElements = async (
    take = 10,
    cursor = null
  ): Promise<{
    results: { id: string; objectName: string }[];
    skip?: number;
    take?: number;
    cursor?: string;
    nextCursor?: string;
  }> => {
    const resp = await fetchRetry(
      `${url}/uploads/index?take=${take}${
        (cursor && `&skip=1&cursor=${cursor}`) || ''
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    const result = await resp.json();

    // in case uploads are fetched with no user logged in, just return an empty array
    if (!result.results) {
      return { results: [] };
    }

    return result;
  };

  // upload a new file
  const uploadElement = async (
    file: File
  ): Promise<{ error?: string; id?: string; objectName?: string }> => {
    const data = new FormData();

    try {
      const objectId = await uploadObject(file, file.type);
      data.append('objectId', objectId);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      return { error: errorMessage };
    }

    const resp = await fetchRetry(`${url}/uploads/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: data,
    });
    const result = await resp.json();

    return result;
  };

  // delete an uploaded file by id
  const deleteUpload = async (
    id: string
  ): Promise<{ error?: string; success?: boolean }> => {
    const resp = await fetchRetry(`${url}/uploads/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: encodeURI(`id=${id}`),
    });
    const result = await resp.json();

    return result;
  };

  // terminator can be null or an instance of AbortController.
  // With this pattern we can cancel ongoing requests when the user asks for a new one
  // avoiding stalling the app or creating new errors.
  let terminator: AbortController | null = null;

  const getDesign = async (
    id: string
  ): Promise<null | { error: string } | Design> => {
    if (terminator) terminator.abort();

    terminator = new AbortController();
    const signal = terminator.signal;

    try {
      const resp = await fetchRetry(`${url}/designs/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        signal,
      });

      const result = await resp.json();
      terminator = null;
      return result;
    } catch (e) {
      terminator = null;

      if (e instanceof OfflineError) throw e;
      return null;
    }
  };

  const getDesigns = async (
    take: number,
    skip: number,
    folderId?: string
  ): Promise<{
    results: Array<Design | Folder>;
    total: number;
    skip: number;
    take: number;
  }> => {
    const resp = await fetchRetry(
      `${url}/designs/index?take=${take}&skip=${skip}&includeFolders=true${
        folderId ? `&folderId=${folderId}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );

    const result = await resp.json();
    return result;
  };

  // add a new design to the api
  const createDesign = async (
    name: string,
    state: ArtboardState,
    preview: string,
    folderId?: string
  ): Promise<{ error: string } | Design> => {
    const data = new FormData();
    data.append('name', name);
    data.append('state', JSON.stringify(state));
    folderId && data.append('folderId', folderId);

    if (preview) {
      try {
        const objectId = await uploadObject(preview);
        data.append('objectId', objectId);
      } catch (_error) {
        // if creating preview fails, we can create a design without a preview
      }
    }

    const resp = await fetchRetry(`${url}/designs/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: data,
    });

    const result = await resp.json();

    return result;
  };

  // updates an existing design
  const updateDesign = async (
    id: string,
    name?: string,
    state?: ArtboardState,
    preview?: string
  ): Promise<{ error: string } | Design> => {
    const data = new FormData();
    data.append('id', id);
    if (name) data.append('name', name);
    if (state) data.append('state', JSON.stringify(state));
    if (preview) {
      try {
        const objectId = await uploadObject(preview);
        data.append('objectId', objectId);
      } catch (_error) {
        // if creating preview fails, we can update a design without a preview
      }
    }

    const resp = await fetchRetry(`${url}/designs/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: data,
    });

    const result = await resp.json();

    return result;
  };

  const deleteDesign = async (
    id: string
  ): Promise<{ error?: string; success?: boolean }> => {
    const resp = await fetchRetry(`${url}/designs/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: encodeURI(`id=${id}`),
    });
    const result = await resp.json();

    return result;
  };

  const duplicateDesign = async (
    id: string
  ): Promise<{ error: string } | Design> => {
    const resp = await fetchRetry(`${url}/designs/duplicate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: encodeURI(`id=${id}`),
    });

    const result = await resp.json();

    return result;
  };

  const getShare = async (
    id: string
  ): Promise<{ error: string } | Snapshot> => {
    const resp = await fetchRetry(`${url}/share/${id}`, {
      method: 'GET',
    });
    const result = await resp.json();

    return result;
  };

  // Gets an SSO token for logging in to Canny
  const getSSOToken = async (): Promise<string> => {
    const resp = await fetchRetry(`${url}/users/sso_token`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    const ssoTokenResponse = await resp.json();

    return ssoTokenResponse.token;
  };

  const getBookmarkReferences = async (): Promise<{ templates: number[] }> => {
    const resp = await fetchRetry(`${url}/bookmarks/references`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    const result = await resp.json();

    return result;
  };

  // get bookmarks from a user
  const getBookmarks = async (
    take = 10,
    skip = 0,
    type = 'TEMPLATE'
  ): Promise<{
    cursor: string;
    nextCursor: string;
    results: Template[];
    skip: number;
    take: number;
    total: number;
  }> => {
    const resp = await fetchRetry(
      `${url}/bookmarks/index?take=${take}&skip=${skip}&bookmarkType=${type}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    const result = await resp.json();

    return result;
  };

  const createBookmark = async (
    id: number,
    type = 'TEMPLATE'
  ): Promise<{ success?: boolean; error?: string }> => {
    const resp = await fetchRetry(`${url}/bookmarks/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: encodeURI(`cmsObjectId=${id}&bookmarkType=${type}`),
    });
    const result = await resp.json();

    return result;
  };

  const deleteBookmark = async (
    id: number,
    type = 'TEMPLATE'
  ): Promise<{ success?: boolean; error?: string }> => {
    const resp = await fetchRetry(`${url}/bookmarks/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: encodeURI(`cmsObjectId=${id}&bookmarkType=${type}`),
    });
    const result = await resp.json();

    return result;
  };

  // Notifications
  const getNotifications = async (
    take: number,
    skip: number
  ): Promise<{ results: Notification[]; skip: number; take: number }> => {
    const resp = await fetchRetry(
      `${url}/notifications/index?take=${take}&skip=${skip}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return await resp.json();
  };

  const createCmsNotification = async (): Promise<{
    success: boolean;
    created: boolean;
  }> => {
    const resp = await fetchRetry(`${url}/notifications/create_cms`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return await resp.json();
  };

  const markNotificationRead = async (
    id: string
  ): Promise<{ success: boolean }> => {
    const resp = await fetchRetry(`${url}/notifications/mark_as_read`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: encodeURI(`notificationId=${id}`),
    });
    return await resp.json();
  };

  const markNotificationsSeen = async (): Promise<{ success: boolean }> => {
    const resp = await fetchRetry(`${url}/notifications/mark_as_seen`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return await resp.json();
  };

  const trackEvent = async (
    name: string,
    value: string
  ): Promise<{ success: boolean } | undefined> => {
    if (!getAccessToken()) return;
    const resp = await fetchRetry(`${url}/users/track_event`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeURI(`name=${name}&value=${value}`),
    });
    return await resp.json();
  };

  const changeSubscription = async (
    id: string,
    plan: SubscriptionPlan,
    days: number
  ): Promise<{ success: boolean }> => {
    const resp = await fetchRetry(`${url}/subscription/admin/change`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: encodeURI(`id=${id}&plan=${plan}&days=${days}`),
    });
    return await resp.json();
  };

  const createPost = async (
    category: string,
    description: string,
    name: string,
    template: ArtboardState,
    preview: string,
    tags: string[],
    challengeId: string,
    premium: boolean
  ): Promise<{ error: string } | Post> => {
    const resp = await fetchRetry(`${url}/posts/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        category,
        description,
        name,
        template,
        preview,
        tags,
        challengeId,
        premium,
      }),
    });

    const result = await resp.json();
    return result;
  };

  const updatePost = async (
    id: string,
    category: string,
    description: string,
    name: string,
    state: ArtboardState,
    preview: string,
    tags: string[],
    premium: boolean
  ): Promise<{ error: string } | Post> => {
    const resp = await fetchRetry(`${url}/posts/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        category,
        description,
        name,
        state,
        preview,
        templateId: id,
        tags,
        premium,
      }),
    });

    const result = await resp.json();
    return result;
  };

  const findUser = async (query = ''): Promise<User[]> => {
    const resp = await fetchRetry(`${url}/users/find/?query=${query}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return await resp.json();
  };

  const shareDesign = async ({
    designId,
    preview,
    editable,
    email,
    user,
  }: {
    designId?: string;
    preview: string;
    editable: boolean;
    email?: string;
    user?: string;
  }): Promise<{ error: string } | Snapshot> => {
    const objectId = await uploadObject(preview, 'image/png');

    const body = new FormData();
    if (designId) body.append('designId', designId);
    body.append('objectId', objectId);
    body.append('editable', String(editable));
    if (email) body.append('email', email);
    if (user) body.append('user', user);

    const resp = await (
      await fetchRetry(`${url}/share/create`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body,
      })
    ).json();

    return resp;
  };

  const getInvites = async (
    take = 10,
    skip = 0,
    accepted: boolean
  ): Promise<{
    results: Invite[];
    total: number;
    skip: number;
    take: number;
  }> => {
    const resp = await fetchRetry(
      `${url}/invites/index?take=${take}&skip=${skip}${
        (accepted && `&accepted=true`) || ''
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    const result = await resp.json();

    return result;
  };

  const getActiveChallenges = async (
    take = 10,
    skip = 0,
    includeLimit = false
  ): Promise<{
    error?: string;
    success?: boolean;
    results?: Challenge[];
    nextCursor?: string;
    total?: number;
    skip?: number;
    take?: number;
  }> => {
    const resp = await fetchRetry(
      `${url}/challenges/index?take=${take}&skip=${skip}&includeLimit=${includeLimit}&filter=live`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return await resp.json();
  };

  const getChallenge = async (
    slug: string
  ): Promise<{
    error: string;
    success: boolean;
    challenge: Challenge;
    winners?: Post[];
  }> => {
    const resp = await fetchRetry(`${url}/challenges/${slug}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return await resp.json();
  };

  const getPromptStyleGroups = async (): Promise<{
    success: boolean;
    results: PromptStyleGroup[];
  }> => {
    const resp = await fetchRetry(`${url}/promptstyles/groups/index`);
    return await resp.json();
  };

  const createEvent = async (
    event: string,
    content: JSONValue
  ): Promise<void> => {
    if (!getAccessToken()) return;
    await tryFetchRetry(`${url}/events/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({ type: event, data: JSON.stringify(content) }),
    });

    return;
  };

  return {
    // authentication
    createUser,
    getUserInfo,
    getSSOToken,
    exchangeTemporaryToken,
    createSession,
    deleteSession,
    requestPasswordResetCode,

    // onboarding
    updateOnboardingState,

    // uploads
    getUploadedElements,
    uploadElement,
    deleteUpload,

    // designs
    getDesign,
    getDesigns,
    createDesign,
    updateDesign,
    deleteDesign,
    shareDesign,
    duplicateDesign,

    // share
    getShare,

    // bookmarks
    getBookmarkReferences,
    getBookmarks,
    createBookmark,
    deleteBookmark,

    // notifications
    getNotifications,
    createCmsNotification,
    markNotificationRead,
    markNotificationsSeen,

    // user
    trackEvent,
    findUser,

    // subscription
    changeSubscription,

    // posts
    createPost,
    updatePost,

    // invites
    getInvites,

    // challenges
    getActiveChallenges,
    getChallenge,

    // text to image
    getPromptStyleGroups,

    createEvent,
  };
};

export default createAPI({ url: USER_API_URL });
