import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { darkBackgroundBlur } from '../../utilities/styles';

import { scrollbarWidth } from '../../VerticallyScrollable/style';

export const Panel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PanelHead = styled.div<any>`
  padding: 12px ${scrollbarWidth} 16px 0;
  z-index: ${themeStyle.zIndexM1};
`;

export const PanelBody = styled.div<any>`
  position: relative;
  flex: 1;
  margin-bottom: 12px;
  overflow-y: ${({ noOverflow }) => (noOverflow && 'hidden') || 'overlay'};
  overflow-x: hidden;
`;

export const PanelExtensionWrapper = styled.div`
  position: absolute;
  height: 100%;
  left: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PanelExtension = styled.div<any>`
  max-height: 75%;
  margin-left: 0px;
  padding: 12px;
  padding-right: 3px;
  background: ${themeStyle.varBackgroundAlt};
  box-shadow: ${themeStyle.varShadowMedium};
  border-radius: 5px;

  ${darkBackgroundBlur}
`;

export const Header = styled.div`
  &:not(:only-child) {
    padding-bottom: 12px;
  }
`;
