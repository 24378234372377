export enum MockupTemplateLayer {
  'SceneLayer' = 'sceneLayer',
  'DarkBlendLayer' = 'darkBlendLayer',
  'LightBlendLayer' = 'lightBlendLayer',
}

export type MockupTemplateLayers = Record<MockupTemplateLayer, string>;
export type MockupTemplateFiles = Record<MockupTemplateLayer, File | null>;

export interface State {
  /*
    The dataUrl of uploaded layers
   */
  layers: MockupTemplateLayers;

  /**
   * the files of uploaded layers
   */
  layerFiles: MockupTemplateFiles;
  /*
    Warp grid size.
   */
  warpGridSize: {
    row: number;
    col: number;
  };

  updateWarpGridSize: (partial: Partial<State['warpGridSize']>) => void;

  updateLayers: (partial: Partial<MockupTemplateLayers>) => void;

  updateLayerFiles: (partial: Partial<MockupTemplateFiles>) => void;

  /*
    Reset template data.
    Can be useful after exiting MTC mode.
   */
  resetTemplateData: () => void;
}
