import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useDragNDrop from '../../../../../hooks/useDragNDrop/useDragNDrop';
import userApi from '../../../../../global/userApi';
import { defaultTheme } from './theme';
import { smallTheme } from '../../../../ProjectFoldersModal/Folder/theme';
import {
  Wrapper,
  PreviewImage,
  OuterWrapper,
  ButtonsWrapper,
  BorderBox,
} from './styles';
import useDesignsStore, {
  designsStoreSelector,
} from '../../../../../stores/designsStore';
import { DESIGN_RENAME } from '../../../../../global/events';
import { useToastStore, toastSelector } from '../../../../../stores/toastStore';
import EditableLabel from '../../../../EditableLabel/EditableLabel';

/**
 * Design component used to render designs (also called projects) in the LeftMenu
 * Used for Projects and Project Folders
 */
const Design = ({ onDrop, design, ...props }) => {
  const theme = { ...defaultTheme, ...props.theme };

  const [name, setName] = useState(design.name);
  const fireToast = useToastStore(toastSelector.fire);
  const active = useDesignsStore(designsStoreSelector.active);
  const updateElement = useDesignsStore(designsStoreSelector.updateElement);
  const wrapperRef = useRef();
  const imageRef = useRef();

  useEffect(() => {
    setName(design.name);
  }, [design.name]);

  const handleCommit = async () => {
    if (!name.trim().length) {
      setName(design.name);
      return;
    }

    const response = await userApi.updateDesign(design.id, name);
    const success = response.name === name;

    fireToast({
      label: success
        ? `Renamed design to ${response.name}`
        : `Failed to rename design ${design.name}`,
      error: !success,
    });

    if (success) {
      setName(response.name);
      design.name = response.name;
      updateElement(response);
      if (active === design.id)
        props.dispatch(DESIGN_RENAME, { title: response.name });
    }
  };

  const handleOnDrop = useCallback(
    (value) => {
      onDrop &&
        onDrop({
          ...design,
          isActive: active === design.id,
          ...value,
        });
    },
    [onDrop, active, design]
  );

  const { renderOverlay } = useDragNDrop({
    wrapperRef,
    imageRef,
    preview: design.preview,
    onDrop: handleOnDrop,
  });

  return (
    <OuterWrapper>
      <ButtonsWrapper>{props.buttonsComponent}</ButtonsWrapper>
      <Wrapper
        background={design.artboardOptions?.fill || theme.backgroundColor}
        isActive={active === design.id}
        ref={wrapperRef}
      >
        <BorderBox theme={theme} />
        <PreviewImage
          draggable={false}
          ref={imageRef}
          src={design.preview}
          theme={theme}
        />
      </Wrapper>
      <EditableLabel
        value={name}
        locked={false}
        theme={{ ...smallTheme, backgroundColor: theme.backgroundColor }}
        onChange={setName}
        onCommit={handleCommit}
        maxLength={200}
      />
      {renderOverlay()}
    </OuterWrapper>
  );
};

Design.propTypes = {
  /**
   * Design (Project) Data
   */
  design: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    preview: PropTypes.string,
    artboardOptions: PropTypes.object,
  }),
  /**
   * Component to render at the top right of the outermost container
   */
  buttonsComponent: PropTypes.element,
  /**
   * Theme object
   */
  theme: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  /**
   * whether the design is currently active
   */
  isActive: PropTypes.bool,
  onDrop: PropTypes.func,
  dispatch: PropTypes.func,
};

export default React.memo(Design, (prev, next) => {
  // if id changes (it shouldn't in most cases) then props differ
  if (prev.design.id !== next.design.id) return false;

  // update, if preview changes (happens for designs when user changes something and thumbnail updates)
  if (prev.design.preview !== next.design.preview) return false;

  // update, if isActive changes (happens when selecting another design)
  if (prev.isActive !== next.isActive) return false;
  return true;
});
