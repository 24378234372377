import { useEffect } from 'react';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import fabric from '../../components/Artboard/fabric';
import { HISTORY_PREFIX } from '../../global/events';
import { EventBusData } from '../../types';

/**
 * This hook handles the binding/unbinding of event handlers from event bus.
 */
export const useEventSubscriptions = (
  canvas: fabric.Canvas | null,
  bus: Subject<EventBusData>,
  handleEventBusEvents: (
    canvas: fabric.Canvas,
    data: EventBusData
  ) => Promise<void>
): void => {
  useEffect(() => {
    const events = bus.pipe(
      filter(({ key }) => !key.startsWith(HISTORY_PREFIX))
    );

    const mainSubscription = events.subscribe(async (data) => {
      if (canvas) {
        await handleEventBusEvents(canvas, data);
      }
    });

    return (): void => {
      mainSubscription.unsubscribe();
    };
  });
};
