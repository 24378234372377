import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { defaultDimensions } from './config';
import {
  Header,
  Wrapper,
  Gallery,
  ElementWrapper,
  Scroller,
  LeftLabel,
  GalleryWrapper,
} from './styles';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Spacer from '../Spacer/Spacer';
import { useInView } from 'react-intersection-observer';
import Masonry from '../Masonry/Masonry';
import useDebounce from '../../hooks/useDebounce';
import useThrottled from '../../hooks/useThrottle';
import { themeStyle } from '../../services/theming';
import { SMALL_TAKE } from '../../global/constants';
import useNetworkStore, {
  networkStoreSelector,
} from '../../stores/networkStore';
import { getTemplatePreviewWidth } from '../../helpers/templateDimensions';
import VerticallyScrollable from '../VerticallyScrollable/VerticallyScrollable';
import { H6Plus, P2 } from '../utilities/Typography/styles';

/*
  One element is ~132px

  fetchCount = 1
  | 132px | 132px | 132px | ---> Elements
                   \-132--^ ---> Margin

  fetchCount = 2
  | 132px | 132px | 132px | 132px | 132px | 132px | ---> Elements
                                   \----264-------^ ---> Margin

  fetchCount = 3
  | 132px |...| 132px | 132px | 132px | 132px | 132px | ---> Elements
              \----5*132------------------------------^ ---> Margin

  We agree on a value of 5 * 132 px for the biggest possible value.
  Before there are enough elements, we keep the margin low enough so
  that it doesn't come into view and fetches unnecessarily.
*/

const SMALL_FETCHES = 2;
const SMALL_MARGIN = '132px';
const BIG_MARGIN = '264px';
const BIGGER_MARGIN = '660px';

const HEADER_HEIGHT = 32;
const PADDING = 10;
const GAP = 10;

const getHeight = (dimensions, collapsed) => {
  if (collapsed) return `${dimensions.heightCollapsed}px`;
  if (dimensions.heightExpanded) {
    if (isNaN(dimensions.heightExpanded)) return dimensions.heightExpanded;
    return `${dimensions.heightExpanded}px`;
  }
  return '100%';
};

/*
  Component that renders an element gallery.
  It can be in the form of a 1D row of elements, or a 2D grid that uses the Masonry component, and
  contains a switch that the user can press to change this.
  The 'sideEffect' functions can be used to trigger layout (or other) changes when the user changes the mode.
*/

const ElementGallery = (props) => {
  const {
    total,
    labelLeft,
    fetch: _fetch,
    collapsed,
    sideEffectBeginTransition,
    sideEffectEndTransition,
    render,
    hide,
    onToggleCollapse,
  } = props;

  const fetchCount = useRef(0);
  const [rootMargin, setRootMargin] = useState(SMALL_MARGIN);
  const online = useNetworkStore(networkStoreSelector.online);

  const fetch = useCallback(() => {
    if (!_fetch || !online) {
      return;
    }
    if (fetchCount.current <= SMALL_FETCHES) {
      _fetch(SMALL_TAKE).then((res) => {
        if (res) {
          fetchCount.current++;

          if (fetchCount.current === SMALL_FETCHES) {
            setRootMargin(BIG_MARGIN);
          }

          if (fetchCount.current > SMALL_FETCHES) {
            setRootMargin(BIGGER_MARGIN);
          }
        }
      });
    } else {
      _fetch();
    }
  }, [_fetch, online]);

  const dimensions = { ...defaultDimensions, ...props.dimensions };

  const [_collapsed, setCollapsed] = useState(collapsed);
  useEffect(() => {
    setCollapsed(collapsed);
  }, [collapsed]);

  const gallery = useRef();
  const [scrolling, setScrolling] = useState(false);
  const stopScrolling = () => setScrolling(false);
  const startScrolling = () => setScrolling(true);
  const throttledStartScrolling = useThrottled({
    callback: startScrolling,
    delay: 250,
  });
  const debouncedStopScrolling = useDebounce({
    callback: stopScrolling,
    delay: 300,
  });

  useEffect(() => {
    const _gallery = gallery.current;

    const onScroll = () => {
      throttledStartScrolling();
      debouncedStopScrolling();
    };

    _gallery?.addEventListener('scroll', onScroll);

    return () => {
      _gallery?.removeEventListener('scroll', onScroll);
    };
  }, [throttledStartScrolling, debouncedStopScrolling]);

  const { ref: rightSpacer, inView: rightSpacerVisible } = useInView({
    root: gallery.current,
    initialInView: true,
  });

  const { ref: leftSpacer, inView: leftSpacerVisible } = useInView({
    root: gallery.current,
    initialInView: true,
  });

  const { setNode: loader } = useIntersectionObserver(fetch, {
    threshold: 0.1,
    rootMargin: `0px ${rootMargin} 0px 0px`,
    root: gallery.current,
  });

  const [rightScrollerVisible, setRightScrollerVisible] = useState(false);
  const [leftScrollerVisible, setLeftScrollerVisible] = useState(false);

  useEffect(() => {
    const thereIsElements = props.elements.length;
    setRightScrollerVisible(
      !scrolling && thereIsElements && !rightSpacerVisible
    );
    setLeftScrollerVisible(!scrolling && thereIsElements && !leftSpacerVisible);
  }, [rightSpacerVisible, leftSpacerVisible, props.elements.length, scrolling]);

  const [transitioning, setTransitioning] = useState(false);

  const getRowHeight = () =>
    dimensions.heightCollapsed - HEADER_HEIGHT - PADDING;

  const scroll = (d) => {
    gallery.current.scrollBy({
      left: (getRowHeight() + GAP) * d,
      behavior: 'smooth',
    });
  };

  const renderRow = () => {
    if (transitioning) return null;
    if (hide && !_collapsed) return null;

    return (
      <GalleryWrapper headerHeight={HEADER_HEIGHT} padding={PADDING}>
        <Scroller
          dir="left"
          show={leftScrollerVisible && !transitioning}
          onClick={() => scroll(-1)}
        />
        <Scroller
          dir="right"
          show={rightScrollerVisible && !transitioning}
          onClick={() => scroll(1)}
        />
        <Gallery ref={gallery} elementGap={GAP}>
          <Spacer w="10px" h="100%" ref={leftSpacer} />
          {props.elements.map((elem) => {
            const { state } = elem;

            const rowHeight = getRowHeight();
            let elementWidth = rowHeight;

            if (
              state?.artboardOptions?.width &&
              state?.artboardOptions?.height
            ) {
              const width = getTemplatePreviewWidth(
                state.artboardOptions,
                rowHeight
              );
              elementWidth = width;
            }

            return (
              <ElementWrapper key={elem.id}>
                {render({ ...elem, elementWidth, elementHeight: undefined })}
              </ElementWrapper>
            );
          })}
          <Spacer w="10px" h="100%" ref={rightSpacer} />
          <Spacer w="0px" h="100%" ref={loader} />
        </Gallery>
      </GalleryWrapper>
    );
  };

  const renderMasonry = () => {
    if (transitioning) return null;
    if (hide && !_collapsed) return null;
    return (
      <VerticallyScrollable>
        <Masonry
          items={props.elements}
          minColumnWidth={dimensions.minColumnWidth}
          gap={GAP}
          fetch={fetch}
          render={(elem) => render({ ...elem, elementWidth: undefined })}
          transitioning={hide}
          equalDimensions={props.equalDimensions}
        />
      </VerticallyScrollable>
    );
  };

  const handleToggleCollapse = useCallback(() => {
    if (onToggleCollapse) {
      onToggleCollapse();
      return;
    }
    setTransitioning(true);
    setCollapsed((previous) => {
      sideEffectBeginTransition &&
        sideEffectBeginTransition(wrapper.current, !previous);
      return !previous;
    });
  }, [onToggleCollapse, sideEffectBeginTransition]);

  const wrapper = useRef();

  return (
    <Wrapper
      padding={PADDING}
      galleryHeight={getHeight(dimensions, _collapsed)}
      width={dimensions.width}
      onTransitionEnd={() => {
        setTransitioning(false);
        sideEffectEndTransition &&
          sideEffectEndTransition(wrapper.current, _collapsed);
      }}
      ref={wrapper}
    >
      <Header headerHeight={HEADER_HEIGHT} padding={PADDING}>
        <LeftLabel>
          <H6Plus noTextOverflow>{labelLeft}</H6Plus>
          <H6Plus>&nbsp;{total !== undefined ? `(${total})` : ''}</H6Plus>
        </LeftLabel>
        {!props.hideCollapseToggleLabel && (
          <P2
            onClick={handleToggleCollapse}
            cursor="pointer"
            color={themeStyle.varInkMedium}
          >
            {_collapsed ? 'Show All' : 'Go Back'}
          </P2>
        )}
      </Header>
      {_collapsed ? renderRow() : renderMasonry()}
    </Wrapper>
  );
};

ElementGallery.propTypes = {
  /**
   * Total expected amount of elements
   */
  total: PropTypes.number,
  /**
   * Label to show in top left corner
   */
  labelLeft: PropTypes.string,
  /**
   * Function used to fetch elements
   */
  fetch: PropTypes.func,
  /**
   * If collapsed = true, show row of elements, otherwise show masonry
   */
  collapsed: PropTypes.bool,
  /**
   * Side effect that can be triggered after collapsing or expanding has started
   */
  sideEffectBeginTransition: PropTypes.func,
  /**
   * Side effect that can be triggered after collapsing or expanding has finished
   */
  sideEffectEndTransition: PropTypes.func,
  /**
   * Render function for elements
   */
  render: PropTypes.func,
  /**
   * Whether to hide elements or not
   */
  hide: PropTypes.bool,
  /**
   * dimensions object
   */
  dimensions: PropTypes.shape({
    width: PropTypes.string,
    minColumnWidth: PropTypes.number,
    heightCollapsed: PropTypes.number,
    heightExpanded: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  /**
   * whether to hide label on top right
   */
  hideCollapseToggleLabel: PropTypes.bool,
  /**
   * function to overwrite behaviour on toggle collapse state
   */
  onToggleCollapse: PropTypes.func,
  elements: PropTypes.arrayOf(PropTypes.object),
  icon: PropTypes.string,
  equalDimensions: PropTypes.bool,
};

export default ElementGallery;
