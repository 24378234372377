import styled from 'styled-components';
import { BREAKPOINTS, QUERIES } from '../utilities/queries';
import { FlexColumn, hideScrollBar } from '../utilities/styles';

export const Wrapper = styled(FlexColumn)`
  position: relative;
  padding: 24px;
  padding-bottom: 26px;
  width: 100%;
  box-sizing: border-box;
`;

export const Folders = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 24px;

  min-height: 314px;
  max-height: 314px;
  overflow-y: auto;

  // hacks to not hide any shadows
  margin: 0 -5px;
  padding: 0 5px;
  padding-top: 24px;

  ${hideScrollBar}

  @media (min-width: ${BREAKPOINTS.tabletMin}px) {
    max-height: 300px;
  }

  @media (min-width: ${BREAKPOINTS.tabletMin}px) and (min-height: 800px) {
    max-height: 500px;
  }

  @media (min-width: ${BREAKPOINTS.tabletMin}px) and (min-height: 1000px) {
    max-height: 600px;
  }

  ${QUERIES.largeTabletAndUp} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  > * {
    width: 100%;
  }

  ${QUERIES.tabletAndUp} {
    flex-direction: row;
    justify-content: space-between;

    > * {
      width: unset;
    }
  }
`;
