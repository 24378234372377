export default function (fabric) {
  /**
   * Creates canvas needed to perform the rendering process when there is
   * an overlay texture with clipping enabled.
   */
  fabric.StaticCanvas.prototype._createForegroundClipMaskCanvas = function () {
    // Canvas used as a mask to render the overlay texture.
    // When overlayTexture.renderClip = false then it's not used
    this._foregroundClipMask = fabric.util.createCanvasElement();

    // Context for _foregroundClipMask
    this._foregroundClipMaskContext = this._foregroundClipMask.getContext('2d');
  };

  /**
   * Updates the sizes of the canvases used for clip rendering.
   * Should be called once on each call to renderCanvas
   */
  fabric.StaticCanvas.prototype.updateForegroundClipMaskCanvas = function () {
    if (!this._foregroundClipMask) {
      this._createForegroundClipMaskCanvas();
    }

    const dpr = this.exporting ? 1 : fabric.devicePixelRatio;
    this._foregroundClipMask.setAttribute('width', this.width * dpr);
    this._foregroundClipMask.setAttribute('height', this.height * dpr);
    this._foregroundClipMaskContext.scale(dpr, dpr);
  };

  /**
   * Renders objects to both the final canvas and the foreground clip mask,
   * that will later be used to render the clipped overlay.
   */
  fabric.StaticCanvas.prototype.renderObjectsAndForegroundClipMask = function (
    objects,
    compositeContext
  ) {
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (object) {
        object.render(this._foregroundClipMaskContext);
        object.render(compositeContext);
      }
    }
  };

  /**
   * Renders an object to a context using the mask stored in this._foregroundClipMask,
   * to clip it.
   * @param {object} object The object to render
   * @param {CanvasRenderingContext2D} ctx The context to render in
   */
  fabric.StaticCanvas.prototype.renderWithClip = function (object, ctx) {
    // Store original properties to later restore them
    const { globalCompositeOperation, opacity } = object;

    // This makes it so that the object is rendered only
    // where there is content in the destination canvas,
    // with an opacity of 1.
    object.set({
      globalCompositeOperation: 'source-in',
      opacity: 1,
    });

    // After this, this._foregroundClipMaskContext holds
    // the clipped part of object
    object.render(this._foregroundClipMaskContext);

    // Restore properties
    object.set({ globalCompositeOperation, opacity });

    ctx.save();
    object._setupCompositeOperation(ctx); // So that the correct composite operation is used
    object._setOpacity(ctx); // So that object's opacity is respected
    ctx.resetTransform();
    ctx.drawImage(this._foregroundClipMask, 0, 0); // Render clipped part of object to ctx
    ctx.restore();
  };
}
