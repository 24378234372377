import fabric from '../../components/Artboard/fabric';
import { MockupState } from './types';

/**
 * Builds an object with info about the state of the mockup mode for
 * the UI to use in different places. It cherry picks properties we are interested about
 */
export const canvasToMockupState = (canvas: fabric.Canvas): MockupState => {
  return {
    backgroundColor: canvas.artboardOptions?.fill,
  };
};
