import styled from 'styled-components';
import { P3 } from '../utilities/Typography/styles';
import { themeStyle } from '../../services/theming';

export const Skip = styled(P3)`
  color: ${themeStyle.inkBase};
  text-decoration-line: underline;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    color: ${themeStyle.inkMain};
  }
`;
