import Nprogress from 'nprogress';
import {
  DESIGN_NEW,
  ARTBOARD_IS_LOADING,
  DESIGN_DELETE,
  DESIGN_RENAME,
  DESIGN_SAVE,
  DESIGN_DUPLICATE,
  HISTORY_RESET,
  DESIGN_SHARE,
  OPEN_ARTBOARD_SIZE_MODAL,
} from '../../../global/events';
import {
  EMPTY_STATE,
  MIN_PROJECT_PREVIEW_SIZE,
} from '../../../global/constants';
import UserApi from '../../../global/userApi';
import { getSocialSharePreview } from '../../../helpers/download';
import { useUserStore } from '../../../stores/userStore';
import { menuStore } from '../../../stores/menuStore';
import { useDownloadPanelStore } from '../../../stores/downloadPanelStore';
import { useToastStore } from '../../../stores/toastStore';
import designsStore from '../../../stores/designsStore';
import analytics from '../../../global/analytics';
import rasterizeImage from '../../../helpers/rasterizeImage/rasterizeImage';

const { deleteDesign } = UserApi;

const fireToast = useToastStore.getState().fire;

export default async function (event, canvas, dispatch) {
  const value = event?.val;
  switch (event.key) {
    case DESIGN_RENAME:
      canvas.modifyConfig({ title: value.title });
      canvas.fire('object:modified');
      break;
    case DESIGN_SAVE:
      // value.type can be 'auto', 'manual', or 'onExit'.
      // If no type is provided then it is assumed to be 'manual'.
      // With 'auto' bunch of checks are ran to make sure that design is not currently being edited.
      // With 'manual' there are no checks, design is saved regardless.
      // 'onExit' is exactly like manual except that it doesn't make the saved design the 'active' one.
      const autoSaved = value.type === 'auto';
      // This will prevent saving while the user is editing a Layer name input
      if (autoSaved && canvas.isEditingLayerName) return false;
      // don't auto save while text is being edited
      if (autoSaved && canvas.activeTextEdit) return false;
      // don't auto save if there is a color palette being previewed
      if (autoSaved && canvas.isPreviewingColorPalette) return false;
      // don't auto save if there is no saving needed
      if (autoSaved && !menuStore.getState().needsSaving()) return false;
      // don't save if already saving
      if (menuStore.getState().saving) return false;
      // trigger `selection:updated` to force quit text editing on manual save
      if (!autoSaved) canvas.fire('selection:updated');

      const isAuthenticated = !!useUserStore.getState().user;
      if (!isAuthenticated) return false;

      menuStore.getState().saveStart();

      const state = canvas.toJSON();
      // colorPalette does not to be saved
      delete state.colorPalette;

      canvas.renderAll();
      const { artboardOptions } = state;
      const { width, height } = artboardOptions;

      const preview = rasterizeImage.getDataUrl(canvas, {
        format: 'jpg',
        quality: 0.7,
        size: {
          width,
          height,
          unit: 'px',
        },
        forViewport: true,
        targetSize: MIN_PROJECT_PREVIEW_SIZE,
      });

      const title = state.config.title || 'New Design';
      const folderId = value.folderId || state.config.folderId || null;

      const { cb } = value;

      // update or create a design in the user api
      if (state.config.designId) {
        UserApi.updateDesign(state.config.designId, title, state, preview)
          .then((response) => {
            // if there was an error saving the design
            // or user started editing the layer name - interrupt the saving process
            if (response.error || canvas.isEditingLayerName) {
              menuStore.getState().saveInterrupt();
              response.error &&
                fireToast({
                  label: `Failed to save project`,
                  error: true,
                });
              return;
            }
            canvas.modifyConfig({
              updatedAt: response.updatedAt,
            });
            canvas.fire('selection:updated', { noDeselectText: true });
            menuStore.getState().saveFinish(autoSaved);
            designsStore.getState().updateElement(response);
            if (value.type !== 'onExit')
              designsStore.getState().setActive(response.id); // move design to front on save
            cb && cb(response);
          })
          .catch(() => {
            menuStore.getState().saveInterrupt();
            fireToast({
              label: `Failed to save project`,
              error: true,
            });
          });
      } else {
        UserApi.createDesign(title, state, preview, folderId).then(
          (response) => {
            if (response.error) {
              if (response.error === 'MAX_DESIGN_LIMIT') {
                useUserStore
                  .getState()
                  .updateUserInfo({ designsLimitReached: true });
                designsStore.getState().setShowLimitModal(true);
                menuStore.getState().saveForbidden();
              }
              return false;
            }
            canvas.modifyConfig({
              designId: response.id,
              updatedAt: response.updatedAt,
            });
            canvas.fire('selection:updated', { noDeselectText: true });
            canvas.fire('object:modified', { noDeselectText: true });
            menuStore.getState().saveFinish(autoSaved);

            designsStore.getState().insertElement(response);
            designsStore.getState().setActive(response.id);
            designsStore
              .getState()
              .setFolder(response.folderId ? { id: response.folderId } : null); // show view with design inside after creation
            cb && cb(response);
          }
        );
      }
      break;
    case DESIGN_DELETE:
      const { id: deleteId } = event.val;
      deleteDesign(deleteId).then(() => {
        designsStore.getState().deleteElement(deleteId);
        // update design limit on user
        useUserStore.getState().updateUserInfo();
      });
      break;
    case DESIGN_NEW:
      const openArtboardSizeModal = value?.openArtboardSizeModal ?? true;

      dispatch(DESIGN_SAVE, { type: 'onExit' });
      useDownloadPanelStore.getState().resetOptions();
      canvas.resetConfig();
      dispatch(ARTBOARD_IS_LOADING, true);
      canvas.stagedLoadFromJSON(EMPTY_STATE, null, () => {
        dispatch(DESIGN_SAVE, { type: 'auto' });
        dispatch(ARTBOARD_IS_LOADING, false);
        dispatch(HISTORY_RESET, canvas.toJSON());

        if (openArtboardSizeModal) {
          dispatch(OPEN_ARTBOARD_SIZE_MODAL);
        }
      });
      break;
    case DESIGN_DUPLICATE:
      if (await designsStore.getState().canUserCreateDesign()) {
        UserApi.duplicateDesign(value.id).then((newDesign) => {
          designsStore.getState().insertElement(newDesign);
          analytics.track(DESIGN_DUPLICATE, {
            label: `original: ${value.id} new: ${newDesign.id}`,
          });
        });
      }
      break;
    case DESIGN_SHARE:
      const _state = canvas.toJSON();
      Nprogress.start();

      const createShare = async (designId, state) => {
        const canSubmit = await menuStore.getState().canSubmitDesign({ state });
        if (!canSubmit) {
          value.onError && value.onError();
          Nprogress.done();
          return;
        }

        Nprogress.inc(0.1);
        const preview = getSocialSharePreview(_state, canvas);
        Nprogress.inc(0.6);

        UserApi.shareDesign({
          designId,
          preview,
          editable: true,
          email: value.email,
          user: value.user,
        }).then((resp) => {
          Nprogress.done();
          value.callback && value.callback(resp);
        });
      };

      if (!_state.config?.designId) {
        dispatch(DESIGN_SAVE, {
          type: 'manual',
          cb: (resp) => {
            createShare(resp.designId, resp.state);
          },
        });
      } else {
        createShare(_state.config?.designId, _state);
      }
      break;
    default:
      console.warn('Unknown Design event', event?.key, event?.val);
  }
}
