import { nanoid } from 'nanoid';
import { transformRecursive } from './recursive';

interface StructureObjectMeta {
  name?: string;
  type?: string;
  hidden?: boolean;
  locked?: boolean;
  open?: boolean;
}

export interface StructureObject {
  id?: string | null;
  children?: StructureObject[];
  meta?: StructureObjectMeta;
}

// groupStructure utility functions
// most of these functions are recursive and return the updated structure

// return all actual objects (not groups) that are in the given structure
export const getAllChildObjects = (
  structure: StructureObject
): StructureObject['id'][] => {
  if (!structure.children) return [structure.id]; // is object

  return structure.children.reduce(
    (acc: StructureObject['id'][], child: StructureObject) => {
      return [...acc, ...getAllChildObjects(child)];
    },
    []
  );
};

// searches for an element in the structure by id
export const getElementById = (
  structure: StructureObject,
  id: string
): StructureObject | null => {
  if (structure.id === id) {
    return structure;
  }
  if (!structure.children) {
    return null;
  }

  for (const child of structure.children) {
    const subStructure = getElementById(child, id);
    if (subStructure) return subStructure;
  }

  return null;
};

export const getParent = (
  structure: StructureObject,
  id: string,
  parent?: StructureObject
): StructureObject | undefined => {
  if (structure.id === id) return parent;
  return structure.children
    ?.map((child: StructureObject) => getParent(child, id, structure))
    .find((object: StructureObject | undefined) => object);
};

export const getParentId = (
  structure: StructureObject,
  id: string
): string | null => {
  const parent = getParent(structure, id);
  return parent?.id || null;
};

// searches for an element in the structure by id and determines whether it is locked
// an element is locked, if it or any parent element is locked
export const isElementLocked = (
  structure: StructureObject,
  id: string,
  isParentLocked = false
): boolean => {
  const isLocked = Boolean(structure.meta?.locked) || isParentLocked;
  if (structure.id === id) {
    return isLocked; // if found object
  }
  if (!structure.children) {
    return false; // is other leaf object
  }

  for (const child of structure.children) {
    const elementLocked = isElementLocked(child, id, isLocked);
    if (elementLocked) return elementLocked;
  }

  return false;
};

// searches for an element in the structure by id and determines whether it is hidden
// an element is hidden, if it or any parent element is hidden
export const isElementHidden = (
  structure: StructureObject,
  id: string,
  isParentHidden = false
): boolean => {
  const isHidden = Boolean(structure.meta?.hidden) || isParentHidden;
  if (structure.id === id) {
    return isHidden; // if found object
  }
  if (!structure.children) {
    return false; // is other leaf object
  }

  for (const child of structure.children) {
    const elementHidden = isElementHidden(child, id, isHidden);
    if (elementHidden) return elementHidden;
  }

  return false;
};

/**
 * checks if the element with the given id has a group
 */
export const isElementGrouped = (
  structure: StructureObject,
  id: string
): boolean => {
  // ungrouped elements can only be in the root of the structure
  if (!structure.children?.length) return false;
  return structure.children.every((child: StructureObject) => child.id !== id);
};

// return all elements on one level for a given structure
export const getElementsOnLevel = (
  structure: StructureObject,
  level: number
): StructureObject[] => {
  if (!structure.children?.length) return [];
  if (level === 0) return structure.children;

  return structure.children.reduce(
    (acc: StructureObject[], child: StructureObject) => {
      return [...acc, ...getElementsOnLevel(child, level - 1)];
    },
    []
  );
};

// auxiliary function to search for an object and update it
// used for setting meta properties of groups
const searchAndUpdateElement = (
  structure: StructureObject,
  id: string,
  update: (structure: StructureObject) => StructureObject
): StructureObject => {
  // if element was found, call update function
  if (structure.id === id) return update(structure);

  // if structure is a single object
  if (!structure.children) return structure;

  // if structure is a group, apply recursively
  const children = structure.children.map((child: StructureObject) => {
    return searchAndUpdateElement(child, id, update);
  });

  return {
    ...structure,
    children,
  };
};

// toggle a meta property of an element in the structure
// used to toggle groups hidden/locked properties
export const toggleElementsMetaProperty = (
  structure: StructureObject,
  id: string,
  property: keyof Pick<StructureObjectMeta, 'hidden' | 'locked' | 'open'>
): StructureObject => {
  const toggleProperty = (element: StructureObject): StructureObject => {
    const meta = element.meta ? element.meta : {};
    meta[property] = !meta[property];
    element.meta = meta;
    return element;
  };
  return searchAndUpdateElement(structure, id, toggleProperty);
};

// auxiliary function to search for objects and to apply changes to them
// used for grouping and ungrouping
const searchAndUpdateChildren = (
  structure: StructureObject,
  ids: string[],
  action: (structure: StructureObject, childIds: string[]) => StructureObject
): StructureObject => {
  if (!structure.children) return structure;

  const childIds = structure.children
    .map((child: StructureObject) => child.id)
    .filter((id): id is string => typeof id === 'string');
  if (childIds.some((id: string) => ids.includes(id))) {
    return action(structure, childIds);
  }

  // find objects in children of group
  const children = structure.children.map((child: StructureObject) => {
    return searchAndUpdateChildren(child, ids, action);
  });

  return {
    ...structure,
    children,
  };
};

// searches for the objectIds provided and creates a group put of them
// all of the objects should have the same parent
export const createGroupInStructure = (
  structure: StructureObject,
  objectIds: string[],
  groupId: string,
  options?: { name?: string; type?: string }
): StructureObject => {
  const action = (
    foundStructure: StructureObject,
    childIds: string[]
  ): StructureObject => {
    const doNotGroupObjects = foundStructure.children?.filter(
      (child: StructureObject) => !child.id || !objectIds.includes(child.id)
    );

    const children = [];
    if (foundStructure.children?.length) {
      for (const id of objectIds) {
        const object = foundStructure.children.find(
          (child: StructureObject) => child.id && child.id === id
        );
        if (object) children.push(object);
      }
    }

    const newGroup = {
      id: groupId,
      meta: {
        name: options?.name || 'New Group',
        type: options?.type || 'structuralGroup',
        hidden: false,
        locked: false,
        open: true,
      },
      children,
    };

    const groupIndex = Math.min(...objectIds.map((id) => childIds.indexOf(id)));

    // do not group if elements are already grouped
    if (doNotGroupObjects && (doNotGroupObjects.length || !foundStructure.id)) {
      const children = doNotGroupObjects;
      children.splice(groupIndex, 0, newGroup);
      return {
        ...foundStructure,
        children,
      };
    }

    return foundStructure;
  };

  return searchAndUpdateChildren(structure, objectIds, action);
};

// removes a given element from the structure
// optionally, children can be preserved in the structure and take the place of their former parent
export const removeElementFromStructure = (
  structure: StructureObject,
  elementId: string,
  keepChildren?: boolean
): StructureObject => {
  const action = (
    foundStructure: StructureObject,
    childIds: string[]
  ): StructureObject => {
    if (!foundStructure.children) {
      return foundStructure;
    }

    const otherChildren = foundStructure.children.filter(
      (child: StructureObject) => child.id !== elementId
    );

    if (keepChildren) {
      const group = foundStructure.children.find(
        (child: StructureObject) => child.id === elementId
      );
      const groupChildren = group?.children?.length ? group.children : [];

      const groupIndex = childIds.indexOf(elementId);

      const children = otherChildren;
      children.splice(groupIndex, 0, ...groupChildren);

      return {
        ...foundStructure,
        children,
      };
    }

    return {
      ...foundStructure,
      children: otherChildren,
    };
  };

  return searchAndUpdateChildren(structure, [elementId], action);
};

// wrapper to delete a group from the structure, but keep its children
export const discardGroupFromStructure = (
  structure: StructureObject,
  groupId: string
): StructureObject => {
  return removeElementFromStructure(structure, groupId, true);
};

export const removeEmptyGroups = (
  structure: StructureObject
): StructureObject | false => {
  if (!structure.children) return structure;
  if (!structure.children.length) return false;

  // find objects in children of group
  const children = structure.children.map((child: StructureObject) => {
    return removeEmptyGroups(child);
  });
  const filteredChildren = children.filter((child): child is StructureObject =>
    Boolean(child)
  );

  const isCanvas = !structure.id;
  if (!isCanvas && !filteredChildren.length) {
    return false;
  }

  return {
    ...structure,
    children: filteredChildren,
  };
};

// utility function to get the minLevel of all provided objects in the groupStructure
// structure is a groupStructure objects
// objects an array of object ids
export const getMinLevelOfObjects = (
  structure: StructureObject,
  objects: string[],
  currentLevel = 0
): false | number => {
  if (!structure.children) return false;

  // if one of the objects is a child, return the current level
  const childIds = structure.children.map((child: StructureObject) => child.id);
  if (
    childIds.some((id: StructureObject['id']) => id && objects.includes(id))
  ) {
    return currentLevel;
  }

  // otherwise, use this function recursively on all children
  // and get the minimum level at which an object is found
  let minLevel = -1;
  structure.children.forEach((child: StructureObject) => {
    if (child.children) {
      const minInChild = getMinLevelOfObjects(child, objects, currentLevel + 1);
      if (minInChild && minInChild > -1) {
        // if it is > -1, an object was found in the child, so we update minLevel accordingly
        minLevel =
          minLevel === -1 ? minInChild : Math.min(minInChild, minLevel);
      }
    }
  });
  return minLevel;
};

export const addElementsToStructure = (
  structure: StructureObject,
  elements: StructureObject[],
  targetId: string,
  targetIndex: number
): StructureObject => {
  if (!structure.children) return structure;

  if (structure.id === targetId || (targetId === 'canvas' && !structure.id)) {
    const children = [...structure.children];
    children.splice(targetIndex, 0, ...elements);
    return {
      ...structure,
      children,
    };
  }

  // find objects in children of group
  const children = structure.children.map((child: StructureObject) => {
    return addElementsToStructure(child, elements, targetId, targetIndex);
  });

  return {
    ...structure,
    children,
  };
};

/**
 * Moves elements inside of the structure to the target group
 * Returns copy of original structure
 */
export const moveElementsInStructure = (
  structure: StructureObject,
  moveElementIds: string[],
  targetGroupId: string,
  targetIndex: number
): StructureObject => {
  // get and remove elements from group structure
  let intermediateStructure = structure;
  const elements = moveElementIds.map((id) => {
    const innerStructure = getElementById(intermediateStructure, id)!;
    intermediateStructure = removeElementFromStructure(
      intermediateStructure,
      id
    );
    return innerStructure;
  });
  const filteredElements = elements.filter((element) => Boolean(element));

  // add elements to group structure at new position
  const completeStructure = addElementsToStructure(
    intermediateStructure,
    filteredElements,
    targetGroupId,
    targetIndex
  );

  return completeStructure;
};

// auxiliary function to flatten a structure object
export const flattenStructure = (
  structure: StructureObject,
  level = 0,
  groupInfo: { index?: number; groups?: string[] } | undefined = {}
): StructureObject[] => {
  // only the canvas group should not have an id
  const isCanvas = structure.id ? false : true;
  const isObject = !isCanvas && !structure.children?.length;

  const groups = groupInfo.groups?.length ? groupInfo.groups : []; // all higher level groups the element is a child of
  const groupId = groups.length ? groups[groups.length - 1] : ''; // direct parent group of an element

  const addedMetaInfo = {
    indentation: level, // indentation is used to visualize the group structure in layers
    groupId, // groupId is used to limit selection to layers with the same parent and layer move
    groups, // groups is used to hide children of selected groups
    groupIndex: groupInfo.index, // groupIndex is used to find out to which position elements must be moved
  };

  if (isObject) {
    return [{ id: structure.id, ...addedMetaInfo }];
  }

  if (!structure.children?.length) return [];

  const id = isCanvas ? 'canvas' : structure.id;
  // we ignore the canvas object as a separate level
  const nextLevel = isCanvas ? level : level + 1;

  const childGroups = [...groups, id].filter(
    (id): id is string => typeof id === 'string'
  );
  const flatChildren = structure.children.reduce(
    (acc: StructureObject[], child: StructureObject, index: number) => {
      const groupInfo = { groups: childGroups, index };
      return [...acc, ...flattenStructure(child, nextLevel, groupInfo)];
    },
    []
  );

  // canvas is not a separate layer
  if (isCanvas) return flatChildren;

  const groupObject = {
    id,
    type: 'structuralGroup',
    ...structure.meta,
    ...addedMetaInfo,
  };
  return [groupObject, ...flatChildren];
};

/**
 * groupStructure objects should only contain properties id, meta and children for each object
 * other properties can be get by accessing meta or the object by id
 */
export const cleanStructure = (structure: StructureObject): StructureObject => {
  if (!structure.children) return structure;
  const children = transformRecursive(
    structure.children,
    (item: StructureObject) => {
      const { id, meta, children } = item;
      return { id, meta, children };
    }
  );
  return { children };
};

// copies the structure by using the information in newObjects
// newObjects MUST be give in the form { id_1: newId_1, id_2: newId_2, ...},
// where all ids present in the structure should have a corresponding id_k key
export const copyStructure = (
  structure: StructureObject,
  newObjectIds: Record<string, string>
): StructureObject => {
  if (!structure.children) {
    return {
      ...structure,
      id: newObjectIds[structure.id!],
    };
  }

  const children = structure.children.map((child) =>
    copyStructure(child, newObjectIds)
  );

  const id = nanoid();
  return {
    ...structure,
    children,
    id,
  };
};

// insert a child in a structure, specifying a node in said structure via structureId
// if no node is specified, child is added to the children of the main structure
export const insertChild = (
  mainStructure: StructureObject,
  structureId: string | null,
  child: StructureObject,
  index = 0
): void => {
  const structure = structureId
    ? getElementById(mainStructure, structureId)
    : mainStructure;
  if (!structure?.children) return;
  structure.children = [
    ...structure.children.slice(0, index),
    child,
    ...structure.children.slice(index, structure.children.length),
  ];
};

/**
 * Cleans up the copy/layout JS structure into Canvas structure.
 */
export const structureCleanup = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  canvas: any,
  structure: StructureObject[],
  newObjectIds: Record<string, string>,
  rootStructure = null,
  index: number,
  selectAfter = true
): void => {
  const structureIds: string[] = [];

  structure
    .slice()
    .reverse()
    .forEach((struct) => {
      const copiedStructure = copyStructure(struct, newObjectIds);
      structureIds.push(copiedStructure.id!);

      // passing null as structure id inserts the child at the root level
      insertChild(canvas.groupStructure, rootStructure, copiedStructure, index);
    });

  canvas.adjustObjectsIndexToStructure();
  canvas.updateObjectsGroupRelatedProperties();
  if (selectAfter) canvas.selectIds(structureIds);
};

/*
  Checks if the order of the target structures and their equivalents in structure list is the same
  If it is, return the min index in the target structures list,
  Otherwise return 0
*/
export const getCompatibleIndex = (
  structureList: StructureObject[],
  targetStructures: StructureObject[]
): number => {
  const newTargetStructures = structureList.filter((struct) =>
    targetStructures.includes(struct)
  );

  if (newTargetStructures.length !== targetStructures.length) return 0;
  for (let i = 0; i < targetStructures.length; i++) {
    if (newTargetStructures[i] !== targetStructures[i]) return 0;
  }

  const indexList = targetStructures.map((structure: StructureObject) =>
    structureList.findIndex((struct) => struct === structure)
  );

  return Math.min(...indexList);
};

export const getAncestorList = (
  structure: StructureObject,
  nodeId: string | null
): (string | null)[] | null => {
  // Returns the ancestor list of the node,
  // if the node is part of the group structure,
  // otherwise, returns null
  const maybeGetAncestorList = (
    structure: StructureObject,
    nodeId: string | null
  ): (string | null)[] | null => {
    const structureId = structure.id || null;

    if (structureId === nodeId) {
      return [];
    }

    const { children } = structure;
    if (children?.length) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const parentsInChild = maybeGetAncestorList(child, nodeId);
        if (parentsInChild) {
          return [...parentsInChild, structureId];
        }
      }
    }

    return null;
  };

  return maybeGetAncestorList(structure, nodeId) || [];
};

export const isAncestor = (
  structure: StructureObject,
  descendantId: string | null,
  ancestorId: string | null
): boolean => {
  const ancestors = getAncestorList(structure, descendantId);
  if (ancestors === null) return false;
  return ancestors.includes(ancestorId);
};

export const getAncestorBetween = (
  structure: StructureObject,
  node1: string | null,
  node2: string | null
): string | null => {
  const node1Ancestors = getAncestorList(structure, node1) || [];
  const node2Ancestors = getAncestorList(structure, node2) || [];

  const [shortest, longest] = [node1Ancestors, node2Ancestors].sort(
    (a, b) => a.length - b.length
  );

  for (let i = 0; i < shortest.length; i++) {
    const parentShortest = shortest[i];
    const parentLongest = longest[longest.length - shortest.length + i];
    if (parentShortest === parentLongest) {
      return parentShortest;
    }
  }

  return null;
};

export const getCommonAncestor = (
  structure: StructureObject,
  ids: (string | null)[]
): string | null => {
  const _getCommonAncestor = (
    _ids: (string | null)[],
    result?: string | null
  ): string | null => {
    if (!_ids?.length) {
      // There are two cases
      // Case 1: getAncestorBetween was called at least once. This is fine.
      // Case 2: getAncestorBetween was never called. e.g the first picked id
      // to use as partial result was an ancestor of all the other ids, so it was
      // kept until the end. This we need to handle

      if (!result) return null;
      const originalIds = ids;
      if (originalIds.includes(result)) {
        return getParentId(structure, result);
      }

      return result;
    }

    const id = _ids[_ids.length - 1];
    const idsWithoutId = _ids.slice(0, _ids.length - 1);

    if (result === undefined) {
      return _getCommonAncestor(idsWithoutId, id);
    }

    if (isAncestor(structure, id, result)) {
      return _getCommonAncestor(idsWithoutId, result);
    }

    if (isAncestor(structure, result, id)) {
      return _getCommonAncestor(idsWithoutId, id);
    }

    return _getCommonAncestor(
      idsWithoutId,
      getAncestorBetween(structure, result, id)
    );
  };

  return _getCommonAncestor(ids);
};
