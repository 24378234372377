import React, { useState, useCallback } from 'react';

import { SelectProps } from './types';
import { SelectWrapper, SelectInnerWrapper, ErrorMessage } from './styles';
import SelectController from '../SelectController/SelectController';
import Dropdown from '../Dropdown/Dropdown';
import { Option } from '../Dropdown/types';

const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      options,
      compact = false,
      small = false,
      disabled = false,
      noExpandIcon = false,
      error,
      activeOption,
      onSelect,
      customStyles,
      dataTestId,
    },
    ref
  ) => {
    const [dropdownExpanded, setDropdownExpanded] = useState(false);
    const label = activeOption?.label ?? 'Select';

    const handleControllerClick = useCallback(() => {
      setDropdownExpanded((value: boolean): boolean => !value);
    }, [setDropdownExpanded]);

    const handleOptionClick = useCallback(
      (option: Option): void => {
        setDropdownExpanded(false);
        if (onSelect) onSelect(option);
      },
      [onSelect]
    );

    return (
      <SelectWrapper
        ref={ref}
        data-testid={dataTestId}
        customStyles={customStyles}
        small={small}
      >
        <SelectInnerWrapper
          disabled={disabled}
          small={small}
          customStyles={customStyles}
        >
          <SelectController
            small={small}
            disabled={disabled}
            label={label}
            noExpandIcon={noExpandIcon}
            dropdownExpanded={dropdownExpanded}
            alert={!!error}
            customStyles={{
              height: customStyles?.height,
              noBorder: customStyles?.noBorder,
              background: customStyles?.background,
              paddingLeft: customStyles?.paddingLeft,
              borderRadius: customStyles?.borderRadius,
              iconColor: customStyles?.iconColor,
              iconActiveColor: customStyles?.iconActiveColor,
              fontWeight: customStyles?.fontWeight,
              textDecoration: customStyles?.textDecoration,
              textColor: customStyles?.textColor,
              expandIconWidth: customStyles?.expandIconWidth,
            }}
            onClick={handleControllerClick}
          />
          <Dropdown
            dataTestId="select-dropdown"
            small={small}
            compact={compact}
            options={options}
            expanded={dropdownExpanded}
            activeOption={activeOption}
            onOptionClick={handleOptionClick}
          />
        </SelectInnerWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </SelectWrapper>
    );
  }
);

export default React.memo(Select);
