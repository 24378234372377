import React, { useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import Context from '../context';
import {
  Background,
  Foreground,
  ContentWrapper,
  ForegroundWrapper,
  ModalWrapper,
  ExternalCloseButton,
  ModalWrapperWithScrollbar,
} from './styles';

import RoundCloseButton from './RoundCloseButton/RoundCloseButton';
import { ModalProps, ModalState } from './types';

/**
 * The Modal is used to display a virtual window to the user that will be the focus of the page.
 * It is positioned above everything. When open, there is a dark backdrop.
 */
const Modal: React.FC<ModalProps> = ({
  width = '408px',
  height = 'unset',
  externalCloseButton = true,
  blurBackground = true,
  onClose,
  ...props
}) => {
  /* state.isOpen determines whether modal is rendered or not, not if it finished opening */
  const [state, setState] = useState<ModalState>({
    isOpen: props.isOpen,
    closing: false,
  });
  const context = useContext(Context);

  const disableScrollingInBackgroung = useCallback((): void => {
    if (!props.enableBackgroundScrolling) {
      document.body.style.overflow = 'hidden';
    }
  }, [props.enableBackgroundScrolling]);

  const enableScrollingInBackground = useCallback((): void => {
    document.body.style.overflow = 'unset';
  }, []);

  const handleClose = useCallback((): void => {
    if (onClose) onClose();
  }, [onClose]);

  const handleCloseOnEscape = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape' && props.isOpen) handleClose();
    },
    [handleClose, props.isOpen]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleCloseOnEscape);
    return (): void => {
      document.removeEventListener('keydown', handleCloseOnEscape);
    };
  }, [handleCloseOnEscape]);

  useEffect(() => {
    if (props.isOpen && state.isOpen) return;
    if (!props.isOpen && (state.closing || !state.isOpen)) return;

    if (props.isOpen) {
      setState({
        isOpen: true,
        closing: false,
      });
      disableScrollingInBackgroung();
    } else if (state.isOpen) {
      setState({
        isOpen: true,
        closing: true,
      });
      enableScrollingInBackground();
    }
  }, [
    props.isOpen,
    state,
    disableScrollingInBackgroung,
    enableScrollingInBackground,
  ]);

  const onEndTransition = useCallback((): void => {
    setState((prev) => {
      if (prev.closing) {
        return { closing: false, isOpen: false };
      }
      return prev;
    });
  }, []);

  const rootContainer = context.getRootContainer();
  if (!state.isOpen || !rootContainer) return null;

  return createPortal(
    <>
      <Background
        disableClose={!onClose}
        externalCloseButton={externalCloseButton}
        blurBackground={blurBackground}
        onClick={handleClose}
        state={state}
      />
      <ForegroundWrapper>
        <Foreground
          onTransitionEnd={onEndTransition}
          onAnimationEnd={onEndTransition}
          state={state}
        >
          {onClose && externalCloseButton && (
            <ExternalCloseButton>
              <RoundCloseButton onClick={onClose} />
            </ExternalCloseButton>
          )}
          {props.consistentVerticalScrollbar ? (
            <ModalWrapperWithScrollbar
              width={width}
              height={height}
              noOverflowX={props.noOverflowX}
              noShadow={props.noShadow}
            >
              <ContentWrapper height={height}>{props.children}</ContentWrapper>
            </ModalWrapperWithScrollbar>
          ) : (
            <ModalWrapper
              width={width}
              height={height}
              noOverflow={props.noOverflow}
              noOverflowX={props.noOverflowX}
              noShadow={props.noShadow}
              hideScrollbar={props.hideScrollbar}
            >
              <ContentWrapper height={height}>{props.children}</ContentWrapper>
            </ModalWrapper>
          )}
        </Foreground>
      </ForegroundWrapper>
    </>,
    rootContainer
  );
};

export default React.memo(Modal);
