import styled from 'styled-components';

export const Box = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: ${({ checked, borderColor }) =>
    checked ? 'none' : `1px solid ${borderColor}`};
  box-sizing: border-box;
  cursor: pointer;
  background: ${({ checked, checkedColor, defaultColor }) =>
    checked ? checkedColor : defaultColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;
