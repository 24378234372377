import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import nProgress from 'nprogress';

import Modal from '../Modal/Modal';
import {
  Wrapper,
  Content,
  Divider,
  Item,
  Header,
  Attributes,
  IconWrapper,
  FontName,
} from './styles';
import PanelHeader from '../utilities/PanelHeader/PanelHeader';
import { FlexRow } from '../utilities/styles';
import Button from '../Button/Button/Button';
import { primaryTheme, secondaryTheme } from '../Button/Button/theme';
import { H5, P4, P2 } from '../utilities/Typography/styles';
import TextInput from '../TextInput/TextInput';
import { themeStyle } from '../../services/theming';
import Icon from '../Icon/Icon';
import IconEventWrapper from '../Icon/IconEventWrapper';
import { trashTheme, transparentInputTheme } from './theme';
import { processFiles, updateErrors, getNiceName } from './utils';
import {
  userFontsStoreSelector,
  useUserFontsStore,
} from '../../stores/userFontsStore';
import useDebounce from '../../hooks/useDebounce';
import analytics from '../../global/analytics';
import {
  UPLOAD_FONTS_RENAMED,
  UPLOAD_FONTS_STYLE_RENAMED,
  UPLOAD_FONTS_STYLE_DELETED,
  UPLOAD_FONTS_ADDMORE,
  UPLOAD_FONTS_ADD_TO_LIB,
  UPLOAD_FONTS_PICKER_CLOSE,
  UPLOAD_FONTS_CANCELLED,
} from '../../global/analytics/events';

const ONCHANGEDNAME_DELAY = 500;
const ONCHANGEDSTYLE_DELAY = 500;

const FontUploadModal = (props) => {
  const [fonts, setFonts] = useState(
    updateErrors(processFiles(props.files || []))
  );
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const uploadFont = useUserFontsStore(userFontsStoreSelector.uploadFont);

  const onChangedNameDebounced = useDebounce({
    callback: (value) => {
      analytics.track(UPLOAD_FONTS_RENAMED, {
        label: value,
      });
      setName(value);
    },
    delay: ONCHANGEDNAME_DELAY,
  });

  useEffect(() => {
    const files = updateErrors(processFiles(props.files || []));
    setName(files.length ? getNiceName(files[0]) : 'Fontfamily Name');
    setFonts(files);
  }, [props.files]);

  const refInput = useRef();

  // Used to detect when file picker is opened, then cancelled
  const filePickerOpenRef = useRef(false);

  const onWindowFocus = useCallback(() => {
    setUploadCancelled(true);

    if (filePickerOpenRef.current) {
      filePickerOpenRef.current = false;

      setTimeout(() => {
        if (refInput.current?.files.length === 0) {
          analytics.track(UPLOAD_FONTS_PICKER_CLOSE);
        }
      }, 200);
    }
  }, [filePickerOpenRef]);

  useEffect(() => {
    window.addEventListener('focus', onWindowFocus);

    return () => {
      window.removeEventListener('focus', onWindowFocus);
    };
  }, [onWindowFocus]);

  const showUploadDialog = () => {
    analytics.track(UPLOAD_FONTS_ADDMORE);
    filePickerOpenRef.current = true;
    refInput.current.click();
  };

  const handleAddFonts = (event) => {
    const targetFiles = Array.from(event.target.files);
    const files = processFiles(targetFiles);

    setFonts((fonts) => updateErrors([...fonts, ...files]));
  };

  const handleDelete = (font) => {
    analytics.track(UPLOAD_FONTS_STYLE_DELETED);
    setFonts((previousFonts) =>
      updateErrors(
        previousFonts.filter((previousFont) => previousFont.id !== font.id)
      )
    );
  };

  const onChangedStyleDebounced = useDebounce({
    callback: (font, value) => {
      analytics.track(UPLOAD_FONTS_STYLE_RENAMED, {
        label: value,
      });
      setFonts((previousFonts) => {
        const updatedFonts = previousFonts.map((previousFont) => {
          if (previousFont.id === font.id) {
            return { ...previousFont, style: value.trim() };
          }
          return previousFont;
        });

        return updateErrors(updatedFonts);
      });
    },
    delay: ONCHANGEDSTYLE_DELAY,
  });

  const handleSubmit = async () => {
    setLoading(true);
    setUploadCancelled(false);

    analytics.track(UPLOAD_FONTS_ADD_TO_LIB);
    const updatedErrors = updateErrors(fonts, true);
    setFonts(updatedErrors);

    if (updatedErrors.some((font) => font.error)) {
      setLoading(false);
      return;
    }

    nProgress.start();
    await uploadFont(
      name,
      fonts.map((font) => font.file),
      fonts.map((font) => font.style)
    );
    nProgress.done();
    setLoading(false);

    props.onClose && props.onClose(true);
  };

  const [uploadCancelled, setUploadCancelled] = useState(true);

  const emitAndClose = () => {
    if (uploadCancelled) {
      setUploadCancelled(false);
      analytics.track(UPLOAD_FONTS_CANCELLED);
    }
    props.onClose && props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} width={'660px'} onClose={emitAndClose}>
      <Wrapper>
        <PanelHeader
          label="Adding fonts to library"
          theme={{
            fontSize: themeStyle.fontSize16,
            fontWeight: themeStyle.fontBold,
          }}
        />
        <Content>
          {!fonts.length && <P2>No fonts uploaded</P2>}
          {!!fonts.length && (
            <>
              <TextInput
                value={name}
                onChanging={onChangedNameDebounced}
                theme={{
                  ...transparentInputTheme,
                  fontSize: themeStyle.fontSize18,
                }}
                placeholder="Fontfamily Name"
              />
              <Divider />
              {fonts.map((font, index) => (
                <Item key={font.id}>
                  <Header>
                    <P4 color={themeStyle.varInkMedium}>File Name</P4>
                    <P4
                      color={
                        font.error
                          ? themeStyle.varAlertRed
                          : themeStyle.varInkMain
                      }
                    >
                      Style Name
                    </P4>
                  </Header>
                  <Attributes>
                    <H5>{index + 1}.</H5>
                    <IconWrapper>
                      <Icon
                        height="18px"
                        theme={{ color: themeStyle.varInkMain }}
                        name="paperClip"
                      />
                    </IconWrapper>
                    <FontName>{font.name}</FontName>
                    <TextInput
                      value={font.style}
                      onChanging={(value) =>
                        onChangedStyleDebounced(font, value)
                      }
                      error={font.error}
                      theme={{ width: '180px' }}
                      placeholder="Add Name"
                    />
                    <IconWrapper onClick={() => handleDelete(font)}>
                      <IconEventWrapper>
                        <Icon name="delete" height="20px" theme={trashTheme} />
                      </IconEventWrapper>
                    </IconWrapper>
                  </Attributes>
                </Item>
              ))}
            </>
          )}
        </Content>
        <FlexRow gap="12px" justifyContent="end">
          <input
            ref={refInput}
            onChange={handleAddFonts}
            type="file"
            multiple
            hidden
            accept="font/ttf,font/otf"
          />
          <Button
            label="Add font styles"
            height="40px"
            width="140px"
            theme={secondaryTheme}
            onClick={showUploadDialog}
          />
          <Button
            label="Upload to library"
            height="40px"
            width="180px"
            theme={primaryTheme}
            disabled={!fonts.length}
            loading={loading}
            onClick={handleSubmit}
          />
        </FlexRow>
      </Wrapper>
    </Modal>
  );
};

FontUploadModal.defaultProps = {
  isOpen: false,
};

FontUploadModal.propTypes = {
  isOpen: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
};

export default FontUploadModal;
