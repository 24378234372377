import styled from 'styled-components';
import { themeStyle } from '../../services/theming';

export const CreditsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  background: ${themeStyle.varBrandSuperLight};
  border-radius: ${themeStyle.radiusLarge};
`;
