import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import Api from '../global/userApi';
import { PromptStyleGroup } from '../types';

interface StateProperties {
  style: string | null;
  prompt: string | null;
  generating: boolean;
  groups: PromptStyleGroup[];
}

interface StateMethods {
  mergeState: (state: StateSlice) => void;
  fetchGroups: () => Promise<PromptStyleGroup[]>;
}

type State = StateProperties & StateMethods;

type StateSlice = {
  [key in keyof StateProperties]?: StateProperties[key];
};

const textToImagePanelStore = createVanilla<State>((set, get) => ({
  style: null,
  prompt: null,
  generating: false,
  groups: [],

  fetchGroups: async (): Promise<PromptStyleGroup[]> => {
    if (get().groups.length) return get().groups;
    set({ groups: (await Api.getPromptStyleGroups()).results });
    return get().groups;
  },

  mergeState: (state: StateSlice): void => {
    set((oldState) => ({ ...oldState, ...state }));
  },
}));

export const textToImagePanelStoreSelector = {
  style: (state: State): string | null => state.style,
  prompt: (state: State): string | null => state.prompt,
};

const useTextToImagePanelStore = create(textToImagePanelStore);

export default useTextToImagePanelStore;
