/**
 * utility functions to work with fonts
 */

const X_CODE = 120;

// Adobe apps always use 1000 units per em for all fonts, we do the same
export const STANDARD_UNITS_PER_EM = 1000;

/**
 * Utility function to get values to align glyphs
 * To align glyphs, we need to now the offset of the line on the left and additional space that should be added between glyphs.
 * @param {number} width base width without alignment
 * @param {number} targetWidth width to align to
 * @param {number} nGlyphs number of glyphs
 * @param {string} alignment alignment mode
 * @returns {{leftOffset: number, spaceBetween: number}}
 */
export const getGlyphAlignment = (width, targetWidth, nGlyphs, alignment) => {
  const freeSpace = targetWidth - width;

  if (alignment === 'center') {
    // get the amount of free space and cut it in half for 50% on each side of the text
    const leftOffset = Math.max(freeSpace / 2, 0);
    return { leftOffset, spaceBetween: 0 };
  }
  if (alignment === 'right') {
    // move right by the total length of all glyphs combined
    return { leftOffset: freeSpace, spaceBetween: 0 };
  }

  // justify only works with more than 1 glyph
  if (alignment === 'justify' && nGlyphs > 1) {
    // divide total free space remaining equally among the glyphs
    const spaceBetween = Math.max(0, freeSpace / (nGlyphs - 1));
    return { leftOffset: 0, spaceBetween };
  }

  // if no alignment is specified
  // or left alignment is specified
  // or if justify is specified with only one character
  return { leftOffset: 0, spaceBetween: 0 };
};

/**
 * Check if the childPath is a hole in the compound path.
 * childPath is an hole if all his vertex are inside in one path
 * @param {paper.CompoundPath} path - the compound path to check
 * @param {paperPathItem} childPath
 */
export const isHole = (path, childPath) => {
  for (const checkPath of path.children) {
    if (checkPath === childPath) continue;

    let allInsideCheck = true;
    for (const segment of childPath.segments) {
      if (!checkPath.contains(segment.point)) {
        allInsideCheck = false;
        break;
      }
    }
    if (allInsideCheck) return true;
  }
  return false;
};

/**
 * Ensure that the path orientation is correct in a compound path with holes,
 * and fix the orientation when needed.
 * - counter-clockwise the solid part
 * - clockwise the hole
 * @param {paper.CompoundPath} path
 */
export const fixPathOrientation = (path) => {
  if (!path.children) return;

  const wrongDirection = hasWrongDirection(path);
  if (wrongDirection) {
    for (const childPath of path.children) {
      childPath.reverse();
    }
  }
};

/**
 * a path of a glyph is having the wrong direction, if the outer path is clockwise
 * It is important that glyphs follow the right direction, to compute shadows later
 */
export const hasWrongDirection = (path) => {
  if (path.children.length === 1) {
    if (path.children[0].clockwise) return false;
  } else {
    const outerPath = path.children.find(
      (childPath) => !isHole(path, childPath)
    );
    if (outerPath?.clockwise) return false;
  }
  return true;
};

/**
 * Get height of `x` glyph, see https://en.wikipedia.org/wiki/X-height
 */
export const getXHeight = (font) => {
  if (font.hasGlyphForCodePoint(X_CODE)) {
    // calculate the height of `x` glyph
    const glyph = font.glyphForCodePoint(X_CODE);
    return (glyph.bbox.maxY - glyph.bbox.minY) / font.unitsPerEm;
  }
  // fallback to `xHeight` provided by font(it's not always correct)
  return font.xHeight / font.unitsPerEm;
};
