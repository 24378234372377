import React from 'react';
import PropTypes from 'prop-types';

import { defaultTheme } from './theme';
import { Wrapper, Label } from './styles';

/**
 * Utility component to label an input.
 */
const InputLabel = (props) => {
  const theme = { ...defaultTheme, ...props.theme };

  return (
    <Wrapper {...theme} width={props.width}>
      {props.children}
      <Label {...theme}>{props.label}</Label>
    </Wrapper>
  );
};

InputLabel.propTypes = {
  /**
   * Label for the input
   */
  label: PropTypes.string.isRequired,
  /**
   * Width of the label
   */
  width: PropTypes.string,
  /**
   * InputLabel styles theme
   */
  theme: PropTypes.shape({
    colorLabel: PropTypes.string,
    colorLabelActive: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.number,
    fontSize: PropTypes.string,
    lineHeight: PropTypes.string,
    transition: PropTypes.string,
  }),
  children: PropTypes.node,
};

InputLabel.defaultProps = {
  label: 'Input',
  width: '60px',
};

export default InputLabel;
