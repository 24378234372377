import React, { useState, useEffect, useRef } from 'react';

import folderApi from '../../../global/folderApi';
import { buildImageProxyUrl } from '../../../utils/url';
import EditableLabel from '../../EditableLabel/EditableLabel';
import IconButton from '../../Button/IconButton';
import { FlexRow } from '../../utilities/styles';
import {
  Total,
  OuterWrapper,
  OverlayWrapper,
  PreviewWrapper,
  Preview,
  Previews,
} from './styles';
import { useToastStore, toastSelector } from '../../../stores/toastStore';
import { defaultTheme } from './theme';
import useDesignsStore, {
  designsStoreSelector,
} from '../../../stores/designsStore';
import { FolderProps } from './types';

const getDesignPreview = (preview?: string, large?: boolean): string | null => {
  if (!preview) return null;
  return buildImageProxyUrl(`api/${preview}`, {
    width: large ? 260 : 86,
    height: large ? 174 : 86,
    scale: 2,
  });
};

const Folder: React.FC<FolderProps> = ({
  folder,
  isActive,
  locked,
  onClick,
  theme: _theme,
  hasContrastTransition,
  hasDarkOverlayTransition,
  overlay,
  showTotal,
  editMode,
}) => {
  const theme = { ...defaultTheme, ..._theme };
  const [name, setName] = useState(folder.name);
  const fireToast = useToastStore(toastSelector.fire);
  const updateElement = useDesignsStore(designsStoreSelector.updateElement);
  const inputRef = useRef<HTMLInputElement | undefined>();

  useEffect(() => {
    setName(folder.name);
  }, [folder.name]);

  const handleCommit = async (): Promise<void> => {
    if (!name.trim().length) {
      // reset name if user commits empty name
      setName(folder.name);
      return;
    }
    const response = await folderApi.updateFolder({ id: folder.id, name });
    fireToast({
      label: response.success
        ? `Renamed folder to ${response.folder.name}`
        : `Failed to rename folder ${folder.name}`,
      error: !response.success,
    });

    if (response.success) {
      setName(response.folder.name);
      folder.name = response.folder.name;
      updateElement(response.folder);
    }
  };

  const onSelectName = (): void => {
    if (!inputRef.current) return;
    inputRef.current.select();
  };

  useEffect(() => {
    if (editMode) onSelectName();
  }, [editMode]);

  return (
    <OuterWrapper theme={theme}>
      {overlay && <OverlayWrapper>{overlay}</OverlayWrapper>}
      <PreviewWrapper isActive={isActive} theme={theme}>
        <Previews
          onClick={onClick}
          isActive={isActive}
          theme={theme}
          hasContrastTransition={hasContrastTransition}
          hasDarkOverlayTransition={hasDarkOverlayTransition}
        >
          <Preview
            gridArea="large"
            src={getDesignPreview(folder.previews?.[0], true)}
          />
          <Preview src={getDesignPreview(folder.previews?.[1])} />
          <Preview src={getDesignPreview(folder.previews?.[2])} />
          <Preview src={getDesignPreview(folder.previews?.[3])} />
        </Previews>
      </PreviewWrapper>
      <FlexRow gap="6px" alignItems="center">
        <IconButton
          iconName="group"
          height={theme.iconHeight}
          ariaLabel="Edit folder name"
          onClick={onSelectName}
        />
        <EditableLabel
          ref={inputRef}
          value={name}
          locked={locked}
          theme={theme}
          onChange={setName}
          onCommit={handleCommit}
        />
      </FlexRow>
      {showTotal && (
        <Total theme={theme} noTextOverflow>
          {folder.total} Projects
        </Total>
      )}
    </OuterWrapper>
  );
};

export default Folder;
