import { getCenterFromOptions } from '../../../utils/editor/misc';

export default function (fabric) {
  // copied from https://github.com/fabricjs/fabric.js/issues/5079#issuecomment-402665035
  fabric.Group.prototype.realizeTransform = function (object) {
    const matrix = object.calcTransformMatrix(),
      options = fabric.util.qrDecompose(matrix),
      center = new fabric.Point(options.translateX, options.translateY),
      // custom code start ------------------------------------------------------------------
      originalFlipX = object.flipX,
      originalFlipY = object.flipY;
    // custom code end ------------------------------------------------------------------

    object.flipX = false;
    object.flipY = false;
    object.set('scaleX', options.scaleX);
    object.set('scaleY', options.scaleY);
    object.skewX = options.skewX;
    object.skewY = options.skewY;

    // custom code start ------------------------------------------------------------------
    // since we use `lockScalingFlip = true`, a selection can never change object flips
    if (originalFlipX !== object.flipX || originalFlipY !== object.flipY) {
      object.flipX = originalFlipX;
      object.flipY = originalFlipY;
      options.angle -= 180;
    }
    // custom code end ------------------------------------------------------------------

    object.angle = options.angle;
    object.setPositionByOrigin(center, 'center', 'center');

    return object;
  };

  fabric.Group.prototype.initialize = (function (initialize) {
    return function (objects, options) {
      initialize.apply(this, [objects, options]);
      this.positionOnArtboard(options);
      return this;
    };
  })(fabric.Group.prototype.initialize);

  /**
   * Renders instance on a given context
   * We override this function to add `opts` argument
   * @override
   * @param {CanvasRenderingContext2D} ctx context to render instance on
   * @param {Object} opts additional rendering options
   */
  fabric.Group.prototype.render = function (ctx, opts = {}) {
    this._transformDone = true;
    this.callSuper('render', ctx, opts); // <-- That's what we changed
    this._transformDone = false;
  };

  fabric.Group.prototype.positionOnArtboard = function (options) {
    if (options.canvas) {
      let top;
      let left;
      const zoom = options.canvas.getZoom();
      if (options.wasDropped) {
        if (options.distanceToTopCorner) {
          top = options.top - options.distanceToTopCorner.y / zoom;
          left = options.left - options.distanceToTopCorner.x / zoom;
        } else {
          top = options.top;
          left = options.left;
        }
        // scale object to target size and adjust position if it is not a square
        if (options.imgWidth && options.imgHeight) {
          if (this.height * this.scaleY > this.width * this.scaleX) {
            this.scaleToHeight(options.imgHeight);
            const width = this.width * this.scaleX;
            if (width < options.imgWidth / zoom) {
              left += (options.imgWidth / zoom - width) / 2;
            }
          } else {
            this.scaleToWidth(options.imgWidth);
            const height = this.height * this.scaleY;
            if (height < options.imgHeight / zoom) {
              top += (options.imgHeight / zoom - height) / 2;
            }
          }
        }
      } else {
        // center the Group based on viewport, artboard h/w, or default to 0,0
        const { centerX, centerY } = getCenterFromOptions(options);
        const artboard = options.canvas.artboard;
        const minSide = Math.min(artboard.width, artboard.height);
        if (this.width > this.height) {
          this.scaleToWidth(minSide * fabric.NEW_OBJECT_SCALE, true);
        } else {
          this.scaleToHeight(minSide * fabric.NEW_OBJECT_SCALE, true);
        }
        const scaledHeight = this.height * this.scaleY;
        const scaledWidth = this.width * this.scaleX;
        top = centerY - scaledHeight / 2;
        left = centerX - scaledWidth / 2;
      }
      this.set({ top, left });
      this.setCoords();
    }
  };

  // Implements standard Object `getColors`
  fabric.Group.prototype.getColors = function () {
    const firstItem = this.item(0);
    if (firstItem) {
      return firstItem.getColors();
    } else {
      return [];
    }
  };

  // Implements standard Object `setColor`
  fabric.Group.prototype.setColor = function (key, value) {
    if (key === 'fill') {
      this.forEachObject((o) => o.set('fill', value));
    }
  };

  fabric.Group.prototype.updateBounds = function () {
    this._restoreObjectsState();
    fabric.util.resetObjectTransform(this);

    this._calcBounds();
    this._updateObjectsCoords(true);
    this.setCoords();
    this.dirty = true;
    return this;
  };
}
