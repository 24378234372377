import { State } from './types';
import { SubscriptionPlan } from '../../types';

export const userStoreSelector = {
  user: (state: State): State['user'] => state.user,
  plan: (state: State): SubscriptionPlan | undefined =>
    state.user ? state.user?.plan : undefined,
  proAccess: ({ user }: State): boolean =>
    user ? user.plan === 'PRO' || user.plan === 'EXPERT' : false,
  hasCredits: ({ user }: State): boolean =>
    user
      ? user.generationCredits > 0 || user.recurringGenerationCredits > 0
      : false,
  initialize: (state: State): State['initialize'] => state.initialize,
  registerBeforeLoginAction: (
    state: State
  ): State['registerBeforeLoginAction'] => state.registerBeforeLoginAction,
  unregisterBeforeLoginAction: (
    state: State
  ): State['unregisterBeforeLoginAction'] => state.unregisterBeforeLoginAction,
  isLoggedIn: (state: State): boolean => !!state.user,
  logout: (state: State): State['logout'] => state.logout,
  updateUserInfo: (state: State): State['updateUserInfo'] =>
    state.updateUserInfo,
  createUser: (state: State): State['createUser'] => state.createUser,
  loginUser: (state: State): State['loginUser'] => state.loginUser,
  bookmarks: (state: State): State['bookmarks'] => state.bookmarks,
  updateBookmark: (state: State): State['updateBookmark'] =>
    state.updateBookmark,
};

// TODO: Remove this after website is updated.
export const selector = userStoreSelector;
