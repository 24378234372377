import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { H6Plus } from '../utilities/Typography/styles';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${themeStyle.radiusMedium};
  padding: 32px 0 10px 10px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  background: ${themeStyle.varInkSuperLight};
`;

export const Label = styled(H6Plus)`
  position: absolute;
  top: 9px;
  left: 10px;
`;
