import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import userApi from '../global/userApi';
import { useUserStore } from './userStore';
import { uniques } from '../utils';
import {
  ACTIVE_PREFIX,
  LAYER_PREFIX,
  HISTORY_PREFIX,
  ADD_PREFIX,
  EDIT_PREFIX,
  ADD_TEMPLATE,
  DESIGN_RENAME,
  SET_BACKGROUND,
  SET_COLOR_PALETTE,
  RESIZE,
  LAYOUT,
  DELETE_TEXTURE,
  DESIGN_NEW,
  HISTORY_RESET,
} from '../global/events';
import { DEFAULT_TAKE } from '../global/constants';
import { usePromotionStore } from './promotionStore';
import folderApi from '../global/folderApi';

const MODIFIER_EVENT_FIRST_KEYS = [
  ACTIVE_PREFIX,
  LAYER_PREFIX,
  HISTORY_PREFIX,
  ADD_PREFIX,
  EDIT_PREFIX,
];

const MODIFIER_EVENT_SECOND_KEYS = [
  DESIGN_RENAME.split(':')[1],
  SET_BACKGROUND.split(':')[1],
  SET_COLOR_PALETTE.split(':')[1],
  RESIZE.split(':')[1],
  LAYOUT.split(':')[1],
  DELETE_TEXTURE.split(':')[1],
];

const NOT_MODIFIER_EVENT_KEYS = [
  ADD_TEMPLATE.split(':')[1],
  DESIGN_NEW.split(':')[1],
  HISTORY_RESET.split(':')[1],
];

const isArtboardEventModifier = (isActiveDesignModified, eventKeySections) => {
  if (NOT_MODIFIER_EVENT_KEYS.includes(eventKeySections[1])) return false;
  if (MODIFIER_EVENT_FIRST_KEYS.includes(eventKeySections[0])) return true;
  if (MODIFIER_EVENT_SECOND_KEYS.includes(eventKeySections[1])) return true;
  return isActiveDesignModified;
};

const isInElements = (state, id) =>
  !!state.elements.find((elem) => elem.id === id);

const isInFolder = (state, id) =>
  !!state.folderDesigns.find((elem) => elem.id === id);

const designsStore = createVanilla((set, get) => ({
  /**
   * fetched elements (these are project and project folder elements)
   * {
   *    type: 'design' | 'folder',
   *    id: string,
   *    name: string,
   *    ...
   * }[]
   */
  elements: [],
  // elements fetch state
  skip: 0,
  finished: false,
  isFetching: false,
  total: null,

  // activeFolderDesigns
  activeFolder: null,
  /* fetched projects within the active folder
   * {
   *    type: 'design',
   *    id: string,
   *    name: string,
   *    ...
   * }[]
   * */
  folderDesigns: [],
  // folder designs fetch state
  folderFinished: false,
  folderSkip: 0,
  folderIsFetching: false,
  folderTotal: null,

  active: null,
  activeDesign: null,
  isActiveModified: false,

  /**
   * fetch elements from the root folder of the user
   */
  fetch: async (take = DEFAULT_TAKE) => {
    const { skip, finished, isFetching } = get();

    if (finished || isFetching) return;
    set({ isFetching: true });

    const { results, total } = await userApi.getDesigns(take, skip);
    if (!results?.length) {
      set((state) => ({
        finished: true,
        isFetching: false,
        total: total || state.total || 0,
      }));
      return;
    }

    return set((state) => {
      const { elements } = state;
      return {
        elements: uniques([...elements, ...results]),
        skip: skip + take,
        isFetching: false,
        finished: results.length + elements.length === total,
        total,
      };
    });
  },

  /**
   * fetch designs for the currently active folder
   */
  fetchFolder: async (take = DEFAULT_TAKE) => {
    const {
      activeFolder,
      folderSkip: skip,
      folderFinished: finished,
      folderIsFetching: isFetching,
    } = get();

    if (finished || isFetching || !activeFolder) return;
    set({ folderIsFetching: true });

    const { results, total } = await userApi.getDesigns(
      take,
      skip,
      activeFolder.id
    );
    if (!results?.length) {
      set((state) => ({
        folderFinished: true,
        folderIsFetching: false,
        folderTotal: total || state.folderTotal || 0,
      }));
      return;
    }

    return set((state) => {
      const { folderDesigns: designs } = state;
      return {
        folderDesigns: uniques([...designs, ...results]),
        folderSkip: skip + take,
        folderIsFetching: false,
        folderFinished: results.length < take,
        folderTotal: total,
      };
    });
  },

  setFolder: async (folder) => {
    const activeFolder = get().activeFolder;

    // update folder information if closed
    if (activeFolder?.id && activeFolder.id !== folder?.id) {
      get().updateFolder(activeFolder.id);
    }

    // complete folder information
    if (folder && !folder.name) {
      folder = await get().updateFolder(folder.id);
    }

    set({
      activeFolder: folder || null,
      folderDesigns: [],
      folderFinished: false,
      folderSkip: 0,
      folderIsFetching: false,
      folderTotal: null,
    });
  },

  /**
   * reset all data stored in this store
   */
  reset: () => {
    set({
      elements: [],
      skip: 0,
      isFetching: false,
      finished: false,
      total: null,
      active: null,
      activeDesign: null,
    });
    get().setFolder(null);
  },

  deleteElement: (id) =>
    set((state) => {
      if (isInElements(state, id)) {
        return {
          elements: state.elements.filter((elem) => elem.id !== id),
          total: state.total - 1,
          skip: state.skip - 1,
        };
      }

      if (isInFolder(state, id)) {
        return {
          folderDesigns: state.folderDesigns.filter((elem) => elem.id !== id),
          folderTotal: state.folderTotal - 1,
          folderSkip: state.folderSkip - 1,
        };
      }

      return;
    }),

  /**
   * update an element stored in state
   * if the element is not present in the current state, add it
   */
  updateElement: (element) =>
    set((state) => {
      let activeDesign = state.activeDesign;
      let activeFolder = state.activeFolder;
      if (element.id === state.active) {
        activeDesign = element;
      }
      if (element.id === state.activeFolder?.id) {
        activeFolder = element;
      }

      const wasInFolder = isInFolder(state, element.id);
      if (element.folderId) {
        if (element.folderId === state.activeFolder?.id) {
          if (wasInFolder) {
            // update in folder
            return {
              activeDesign,
              elements: state.elements.filter(({ id }) => id !== element.id),
              folderDesigns: state.folderDesigns.map((elem) =>
                element.id === elem.id ? { ...elem, ...element } : elem
              ),
            };
          }
          // add to folder
          return {
            activeDesign,
            elements: state.elements.filter(({ id }) => id !== element.id),
            folderDesigns: [element, ...state.folderDesigns],
            folderTotal: state.folderTotal + 1,
          };
        }
        // remove from state
        return {
          activeDesign,
          elements: state.elements.filter(({ id }) => id !== element.id),
          folderDesigns: wasInFolder
            ? state.folderDesigns.filter(({ id }) => id !== element.id)
            : state.folderDesigns,
          folderTotal: wasInFolder ? state.folderTotal - 1 : state.folderTotal,
        };
      }

      if (isInElements(state, element.id)) {
        // update in root folder
        return {
          activeDesign,
          activeFolder,
          elements: state.elements.map((elem) =>
            element.id === elem.id ? { ...elem, ...element } : elem
          ),
        };
      }

      // add to root folder
      return {
        activeDesign,
        activeFolder,
        elements: [element, ...state.elements],
        total: state.total + 1,
        folderDesigns: wasInFolder
          ? state.folderDesigns.filter(({ id }) => id !== element.id)
          : state.folderDesigns,
        folderTotal: wasInFolder ? state.folderTotal - 1 : state.folderTotal,
      };
    }),

  /**
   * update the data of a folder in state
   * main reason is to have correct previews
   */
  updateFolder: async (id) => {
    const response = await folderApi.getFolder({ id });
    if (!response?.folder) {
      return;
    }

    set((state) => ({
      elements: state.elements.map((element) =>
        element.id === id ? response.folder : element
      ),
    }));
    return response.folder;
  },

  /**
   * insert a new element into state
   */
  insertElement: (element) => {
    if (element.folderId) {
      set((state) => ({
        folderDesigns: [element, ...state.folderDesigns],
        folderTotal: state.folderTotal + 1,
      }));
      return;
    }
    set((state) => ({
      elements: [element, ...state.elements],
      total: state.total + 1,
    }));
  },

  setActive: (id) => {
    set((state) => {
      if (isInFolder(state, id)) {
        const activeDesigns = state.folderDesigns.filter((e) => e.id === id);
        return {
          active: id,
          activeDesign: activeDesigns.length ? activeDesigns[0] : null,
          folderDesigns: [
            ...activeDesigns,
            ...state.folderDesigns.filter((e) => e.id !== id),
          ],
        };
      }

      if (isInElements(state, id)) {
        const activeDesigns = state.elements.filter((e) => e.id === id);
        return {
          active: id,
          activeDesign: activeDesigns.length ? activeDesigns[0] : null,
          elements: [
            ...activeDesigns,
            ...state.elements.filter((e) => e.id !== id),
          ],
        };
      }

      return {
        active: id,
      };
    });
  },

  getActive: async () => {
    if (get().activeDesign) return get().activeDesign;

    let elements = get().elements;
    if (!elements.length) {
      await get().fetch();
      elements = get().elements;
    }
    elements = [...elements, ...get().folderDesigns].filter(
      (element) => element.type === 'design'
    );

    const activeId = get().active;
    if (activeId) {
      const active = elements.find((e) => e.id === activeId);
      if (active) return active;
    }

    if (!elements.length) return null;
    return elements[0];
  },

  setIsActiveModified: (eventKey) => {
    const eventKeySections = eventKey?.split(':') ?? [];

    const isModified = isArtboardEventModifier(
      get().isActiveModified,
      eventKeySections
    );

    set(() => ({ isActiveModified: isModified }));
  },

  forceActiveModified: () => {
    set({ isActiveModified: true });
  },

  setShowLimitModal: (value) => {
    if (value) {
      const user = useUserStore.getState().user;
      const isPro = user ? user.plan === 'PRO' : false;
      const type = isPro ? 'proProjectLimitReached' : 'projectLimitReached';
      usePromotionStore.getState().showUpgradeModal(type);
    } else {
      usePromotionStore.getState().closeUpgradeModal();
    }
  },

  /**
   * check whether the current user can create a new design
   */
  canUserCreateDesign: async () => {
    const updatedUser = await useUserStore.getState().updateUserInfo();
    if (updatedUser?.designsLimitReached) {
      get().setShowLimitModal(true);
      return false;
    }
    return true;
  },
}));

export default create(designsStore);

export const designsStoreSelector = {
  elements: (state) => state.elements,
  active: (state) => state.active,
  fetch: (state) => state.fetch,
  isFetching: (state) => state.isFetching,
  total: (state) => state.total,
  useFolder: (state) => [state.activeFolder, state.setFolder],
  folderDesigns: (state) => state.folderDesigns,
  folderTotal: (state) => state.folderTotal,
  folderFetch: (state) => state.fetchFolder,
  folderIsFetching: (state) => state.folderIsFetching,
  setActive: (state) => state.setActive,
  insertElement: (state) => state.insertElement,
  updateElement: (state) => state.updateElement,
  updateFolder: (state) => state.updateFolder,
  deleteElement: (state) => state.deleteElement,
  reset: (state) => state.reset,
  activeDesign: (state) => state.activeDesign,
  isActiveModified: (state) => state.isActiveModified,
  canUserCreateDesign: (state) => state.canUserCreateDesign,
  forceActiveModified: (state) => state.forceActiveModified,
};
