import { useCallback, useMemo } from 'react';
import { SliderProps } from './types';

/**
 * handle logic around marks for the slider
 */
export const useSliderMarks = ({
  min = 0,
  max = 100,
  pointerPolicy = 'closest',
  ...props
}: Pick<SliderProps, 'marks' | 'min' | 'max' | 'pointerPolicy'>): {
  marks: number[] | null;
  getClosestMarkedValue: (
    marks: number[],
    newValue: number,
    currentValue: number
  ) => number;
  getNextOrPreviousMarkedValue: (
    marks: number[],
    currentValue: number,
    direction: number
  ) => number;
} => {
  const marks = useMemo(() => {
    if (!props.marks) return null;
    return [min, ...props.marks.filter((m) => m > min && m < max), max];
  }, [min, props.marks, max]);

  // Closest value to {value} in marks
  const getClosestValue = useCallback(
    (marks: number[], value: number) => {
      let closest = min;
      marks.forEach((m) => {
        if (Math.abs(value - m) < Math.abs(closest - value)) closest = m;
      });
      return closest;
    },
    [min]
  );

  /**
   * Uses a delta to determine if a different value in marks should be chosen.
   * Can fail even if the user slides past the value, because of the nature of events.
   * If that happens, returns getFallBackValue(value)
   */
  const getClosestValueByDelta = useCallback(
    (marks: number[], value: number): number | null => {
      const delta = 0.025 * (max - min);
      const closest = getClosestValue(marks, value);
      if (Math.abs(closest - value) < delta) return closest;
      return null;
    },
    [max, min, getClosestValue]
  );

  // If the delta test fails, this is used to calculate the new value
  const getFallBackValue = useCallback(
    (marks: number[], newValue: number, currentValue: number): number => {
      let previousValue = undefined;
      let nextValue = undefined;
      const index = marks.indexOf(currentValue);
      if (currentValue !== min) previousValue = marks[index - 1];
      if (currentValue !== max) nextValue = marks[index + 1];
      if (previousValue !== undefined && newValue <= previousValue)
        return previousValue;
      if (nextValue !== undefined && newValue >= nextValue) return nextValue;
      return currentValue;
    },
    [min, max]
  );

  const getClosestMarkedValue = useCallback(
    (marks: number[], newValue: number, currentValue: number): number => {
      if (pointerPolicy === 'closest') {
        return getClosestValue(marks, newValue);
      }
      const deltaValue = getClosestValueByDelta(marks, newValue);
      if (deltaValue !== null) return deltaValue;

      return getFallBackValue(marks, newValue, currentValue);
    },
    [pointerPolicy, getClosestValue, getClosestValueByDelta, getFallBackValue]
  );

  // This is used when stepping (arrows or wheel) and marks is defined
  // (this last thing could happen either if marks is actually defined, or if pointerPolicy = 'closest')
  const getNextOrPreviousMarkedValue = useCallback(
    (marks: number[], currentValue: number, direction: number): number => {
      const isMin = currentValue === min;
      const isMax = currentValue === max;
      if (isMin && direction === -1) return currentValue;
      if (isMax && direction === 1) return currentValue;

      const currentValueIdx = marks.indexOf(currentValue);

      // Could happen if currentValue comes from outside input
      if (currentValueIdx === -1) {
        currentValue = getClosestValue(marks, currentValue);
        return getNextOrPreviousMarkedValue(marks, currentValue, direction);
      }

      return marks[currentValueIdx + direction];
    },
    [min, max, getClosestValue]
  );

  return { marks, getClosestMarkedValue, getNextOrPreviousMarkedValue };
};
