import React from 'react';

import Icon from '../Icon/Icon';
import { VanillaNumberInput as NumberInput } from '../NumberInput/NumberInput';
import { defaultTheme } from './theme';
import { Wrapper, IconWrapper } from './styles';
import Tooltip from '../Tooltip/Tooltip';
import WithSlider from '../../hocs/WithSlider/WithSlider';
import { WrappedNumberInputProps } from './types';
import { TooltipAlignment } from '../Tooltip/types';

const WrappedNumberInput: React.FC<WrappedNumberInputProps> = (props) => {
  const theme = { ...defaultTheme, ...props.theme };
  const iconTheme = {
    color: theme.color,
    activeDefaultColor: theme.colorHover,
    activeColor: theme.colorHover,
    disabledColor: theme.disabledColor,
    transform: theme.iconTransform,
    transition: '0.3s',
  };

  return (
    <Tooltip
      text={props.tooltipText}
      align={props.tooltipAlignment ?? TooltipAlignment.Center}
      placement={props.tooltipPlacement}
    >
      <Wrapper
        theme={{ ...theme, ...iconTheme }}
        disabled={props.disabled}
        isActive={props.isActive}
        activeOnFocus
      >
        <IconWrapper theme={theme}>
          <Icon
            name={props.icon}
            height={theme.iconHeight}
            disabled={props.disabled}
            isActive={props.isActive}
            theme={iconTheme}
          />
        </IconWrapper>
        <NumberInput
          {...props}
          theme={{
            ...theme,
            width: theme.inputWidth,
            height: '100%',
            borderRadius: `${theme.wrapperBorderRadius} 0 0 ${theme.wrapperBorderRadius}`,
          }}
        />
      </Wrapper>
    </Tooltip>
  );
};

export default WithSlider(WrappedNumberInput);
