import styled, { css } from 'styled-components';
import { themeStyle } from '../../services/theming';

export const Wrapper = styled.div`
  position: fixed;
  transition-property: transform;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  will-change: transform;
  z-index: ${themeStyle.zIndexL9};
  transform: ${({ translateY, translateX }) =>
    `translateX(${translateX}) translateY(${translateY})`};
  ${({ positionStyles }) => positionStyles}
`;

export const Container = styled.div`
  transition-property: transform, opacity;
  animation-name: ${({ animation }) => css`
    ${animation}
  `};
  animation-iteration-count: 1;
  animation-duration: ${({ duration }) => duration}ms;
  animation-timing-function: ease-in-out;
  will-change: transform;
`;

export const StyledToast = styled.div`
  height: ${({ height }) => height}px;
  background: ${({ background }) => background};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 24px 8px 8px;
  color: ${({ color }) => color};
  box-shadow: ${({ shadow }) => shadow};
`;
