import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RightMenuHeadline from '../../utilities/RightMenuHeader/RightMenuHeader';
import ColorPicker from '../../ColorPicker/ColorPicker';
import SliderInput from '../../SliderInput/SliderInput';
import NumberInput from '../../NumberInput/NumberInput';
import PositionActionButtons from '../PositionActionButtons/PositionActionButtons';
import {
  ColorWrapper,
  OpacityWrapper,
  PanelRow,
  Panel,
  PanelColumn,
  PanelTitle,
} from '../styles';
import {
  ACTIVE_COLOR,
  ACTIVE_OPACITY,
  ACTIVE_OVERLAY_MODE,
  ACTIVE_STROKE_WIDTH,
} from '../../../global/events';
import Select from '../../SelectNew/Select';
import { P4 } from '../../utilities/Typography/styles';
import { overlayModes as allOverlayModes } from '../../../global/constants';
import { smallNumberInputTheme } from '../../NumberInput/theme';
import { isDebugActive } from '../../../utils/dev';
import { isUnsplashPhoto, isUserUpload } from '../../../utils/url';
import BackgroundRemovalButton from '../../BackgroundRemovalButton/BackgroundRemovalButton';
import { useFeature } from '@growthbook/growthbook-react';
import Spacer from '../../Spacer/Spacer';

// Don't allow alpha mask mode for images
const overlayModes = allOverlayModes.filter(
  (item) => item.id !== 'destination-out'
);

/**
 * The BasePanel
 * containing colors, border and alignment buttons
 */
const BasePanel = (props) => {
  const dispatchEvent = (event, value, isChanging = false) => {
    props.dispatch && props.dispatch(event, { ...value, isChanging });
  };

  const presetColors = Object.keys(props.colorPalette);

  const filteredColors = props.options.colors.filter(
    (color) => color.visible && color.key !== 'stroke'
  );
  const currentStroke = props.options.colors.find(
    (color) => color.key === 'stroke'
  );

  // Detailed shadow has it's own stroke, so border weight control is disabled when it's active
  const hasDetailShadow = props.options.shadowOptions?.type === 'detail';

  const [strokeWidth, setStrokeWidth] = useState(
    props.options.borderWeight.value
  );

  useEffect(() => {
    setStrokeWidth(props.options.borderWeight.value);
  }, [props.options.borderWeight.value]);

  const removeBackgroundEnabled = useFeature('remove-background').value;

  const { objectName } = props.options || {};

  const showBackgroundRemovalButton =
    // show only if in debug mode ot feature is enabled
    (isDebugActive() || removeBackgroundEnabled) &&
    // show only for raster images
    objectName &&
    props.options.type === 'illustrationImage' &&
    (isUnsplashPhoto(objectName) || isUserUpload(objectName));

  return (
    <>
      <RightMenuHeadline label={props.label} />
      <Panel>
        {(props.hasColors || !props.hideOpacity) && (
          <PanelRow justifyContent="space-between" alignItems="center">
            {props.hasColors && (
              <ColorWrapper>
                {filteredColors.map((color) => (
                  <ColorPicker
                    key={color.key}
                    label={color.key.startsWith('color-') ? null : color.key}
                    defaultColor={color.value}
                    onChanging={(value) =>
                      dispatchEvent(
                        ACTIVE_COLOR,
                        { key: color.key, value },
                        true
                      )
                    }
                    onChanged={(value) =>
                      dispatchEvent(
                        ACTIVE_COLOR,
                        { key: color.key, value },
                        false
                      )
                    }
                    presetColors={presetColors}
                    dispatch={props.dispatch}
                  />
                ))}
              </ColorWrapper>
            )}
            {!props.hideOpacity && props.hasColors && (
              <OpacityWrapper>
                <P4>Opacity</P4>
                <NumberInput
                  min={0}
                  max={100}
                  unit={'%'}
                  precision={0}
                  label={'Opacity'}
                  value={{ value: props.options.opacity }}
                  onChanging={(opacity) =>
                    dispatchEvent(ACTIVE_OPACITY, { opacity }, true)
                  }
                  onChanged={(opacity) =>
                    dispatchEvent(ACTIVE_OPACITY, { opacity }, false)
                  }
                  theme={smallNumberInputTheme}
                />
              </OpacityWrapper>
            )}
            {!props.hideOpacity && !props.hasColors && (
              <SliderInput
                min={0}
                max={100}
                unit="%"
                precision={0}
                label="Opacity"
                onChanging={(opacity) =>
                  dispatchEvent(ACTIVE_OPACITY, { opacity }, true)
                }
                onChanged={(opacity) =>
                  dispatchEvent(ACTIVE_OPACITY, { opacity }, false)
                }
                startValue={props.options.opacity}
              />
            )}
          </PanelRow>
        )}
        {props.hasBorder && (
          <PanelRow
            justifyContent="space-between"
            alignItems="center"
            gap="7px"
          >
            <ColorPicker
              key={props.options.id} // key is needed since the value in the picker might be changed, without being reflected in defaultColor
              label={'Border'}
              defaultColor={
                strokeWidth > 0 || hasDetailShadow ? currentStroke?.value : null // use no-color, when no border is displayed
              }
              onChanging={(value) =>
                dispatchEvent(ACTIVE_COLOR, { key: 'stroke', value }, true)
              }
              onChanged={(value) =>
                dispatchEvent(ACTIVE_COLOR, { key: 'stroke', value }, false)
              }
              presetColors={presetColors}
              dispatch={props.dispatch}
            />
            <SliderInput
              label={'Border Weight'}
              startValue={props.options.borderWeight.value}
              precision={2}
              step={0.1}
              min={0}
              max={100}
              disabled={hasDetailShadow}
              onChanging={(width) => {
                setStrokeWidth(width);
                dispatchEvent(ACTIVE_STROKE_WIDTH, { width }, true);
              }}
              onChanged={(width) =>
                dispatchEvent(ACTIVE_STROKE_WIDTH, { width }, false)
              }
            />
          </PanelRow>
        )}
        <PanelRow justifyContent="space-between" alignItems="center" gap="7px">
          <PositionActionButtons dispatch={props.dispatch} />
        </PanelRow>
        {props.options.allowCompositeOperation && (
          <PanelColumn gap="2px">
            <PanelTitle>Blending Mode</PanelTitle>
            <Select
              small
              options={overlayModes}
              activeOption={
                overlayModes.find(
                  (om) => om.id === props.options.globalCompositeOperation
                ) || overlayModes.find((om) => om.default)
              }
              onSelect={(opt) =>
                props.dispatch(ACTIVE_OVERLAY_MODE, {
                  overlayId: opt.id,
                })
              }
            />
            {showBackgroundRemovalButton && (
              <>
                <Spacer h="12px" />
                <BackgroundRemovalButton dispatch={props.dispatch} />
              </>
            )}
          </PanelColumn>
        )}
      </Panel>
    </>
  );
};

BasePanel.propTypes = {
  /**
   * label of the panel
   */
  label: PropTypes.string,
  /**
   * current styles options
   */
  options: PropTypes.shape({
    color: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    borderWeight: PropTypes.shape({ value: PropTypes.number }),
    shadowOptions: PropTypes.shape({ type: PropTypes.string }),
    colors: PropTypes.arrayOf(PropTypes.object),
    opacity: PropTypes.number,
    id: PropTypes.string,
    allowCompositeOperation: PropTypes.bool,
    globalCompositeOperation: PropTypes.string,
    type: PropTypes.string,
  }),
  /**
   * shout there be border settings
   */
  hasBorder: PropTypes.bool,
  /**
   * Array containing information about the artboards color palette
   */
  colorPalette: PropTypes.object,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
  hasColors: PropTypes.bool,
  /**
   * whether to hide opacity input
   */
  hideOpacity: PropTypes.bool,
};

export default BasePanel;
