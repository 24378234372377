// Window url
const location = window?.location;
export const urlParams = new URLSearchParams(location?.search);
export const TEMPLATE_PARAM = 'tid';
export const UPDATE_TEMPLATE_PARAM = 'utid';
export const DESIGN_PARAM = 'did';
export const NEW_DESIGN_PARAM = 'nd';
export const SHARE_PARAM = 'sid';
export const PROMO_CODE = 'promoCode';
export const FOLDER_PARAM = 'fid';
export const ONBOARDING_DESIGN_CATEGORY = 'ndc';
