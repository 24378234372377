import {
  isOnDevEnvironment,
  isOnTestEnvironment,
} from '../../global/environment';

const DEBUG_QUERY_PARAM = '__debug';
const DEBUG_ANALYTICS_QUERY_PARAM = '__debug_analytics';

export const isDebugActive = (): boolean => {
  if (isOnDevEnvironment()) return true;

  if (isOnTestEnvironment()) return false;

  if (typeof window === 'undefined') return false;

  const urlParams = new URLSearchParams(window?.location?.search);
  return Boolean(urlParams.get(DEBUG_QUERY_PARAM));
};

export const isDebugAnalyticsActive = (): boolean => {
  if (typeof window === 'undefined') return false;
  const urlParams = new URLSearchParams(window?.location?.search);
  return !!urlParams.get(DEBUG_ANALYTICS_QUERY_PARAM);
};
