import styled from 'styled-components';
import { OuterWrapper } from '../ElementPanels/Element/styles';

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 7px;
  right: 7px;
  top: 7px;
  pointer-events: none;
  position: absolute;
  opacity: 0;
`;

export const BookmarkContainer = styled.div`
  position: absolute;
  top: 0;
  left: 8px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5));
  opacity: 1;

  ${OuterWrapper}:hover & .fillDefault:not(.secondary) {
    fill: ${({ activeDefaultColor }) => activeDefaultColor};
  }
`;
