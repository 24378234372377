import React, { useState, useEffect, useCallback } from 'react';

import { useUserStore, userStoreSelector } from '../../stores/userStore';
import {
  useAuthenticationStore,
  authenticationStoreSelector,
} from '../../stores/authenticationStore';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import Modal from '../../components/Modal/Modal';
import ForgotPassword from '../AuthComponent/ForgotPassword/ForgotPassword';

import analytics from '../../global/analytics';
import useWindowSize from '../../hooks/useWindowSize';
import {
  AUTHENTICATION_FORGOT_PASSWORD,
  AUTHENTICATION_SWITCH,
} from '../../global/analytics/events';

/**
 * show authentication based on authenticationStore
 */
const Authentication: React.FC = () => {
  const { isMobile } = useWindowSize();
  const isLoggedIn = useUserStore(userStoreSelector.isLoggedIn);
  const authenticationIsOpen = useAuthenticationStore(
    authenticationStoreSelector.isOpen
  );
  const options = useAuthenticationStore(authenticationStoreSelector.options);
  const closeAuthentication = useAuthenticationStore(
    authenticationStoreSelector.closeAuthentication
  );

  const [isAuthComponentOpen, setIsAuthComponentOpen] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [isSignup, setIsSignup] = useState(true);

  const handleSwitchSignup = (value: boolean): void => {
    analytics.track(AUTHENTICATION_SWITCH, {
      label: value ? 'signup' : 'login',
    });
    setIsSignup(value);
  };

  const openForgotPasswordModal = (): void => {
    analytics.track(AUTHENTICATION_FORGOT_PASSWORD);
    setIsForgotPasswordOpen(true);
  };

  const onPasswordForgotGoBack = (): void => {
    setIsForgotPasswordOpen(false);
  };

  const onSubmit = useCallback((): void => {
    options.onSubmit && options.onSubmit();
    closeAuthentication();
  }, [options, closeAuthentication]);

  useEffect(() => {
    if (isLoggedIn) {
      closeAuthentication();
    }
  }, [isLoggedIn, closeAuthentication]);

  useEffect(() => {
    if (!authenticationIsOpen) {
      setIsAuthComponentOpen(false);
      setIsForgotPasswordOpen(false);
    } else if (!isLoggedIn) {
      setIsAuthComponentOpen(true);
      setIsSignup(options.isSignup);
    }
  }, [options.isSignup, authenticationIsOpen, isLoggedIn]);

  return (
    <Modal
      isOpen={!isLoggedIn && isAuthComponentOpen}
      width={isSignup && !isMobile ? '816px' : '408px'}
      onClose={options.enableClose ? closeAuthentication : undefined}
      enableBackgroundScrolling={options.enableBackgroundScrolling}
      blurBackground={options.blurBackground}
      externalCloseButton
    >
      {isForgotPasswordOpen && (
        <ForgotPassword onGoBack={onPasswordForgotGoBack} />
      )}
      {!isForgotPasswordOpen && (
        <AuthComponent
          isSignup={isSignup}
          isMobile={isMobile}
          onForgotPassword={openForgotPasswordModal}
          onGoToLogin={(): void => handleSwitchSignup(false)}
          onGoToSignup={(): void => handleSwitchSignup(true)}
          onClose={options.enableClose ? closeAuthentication : undefined}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  );
};

export default Authentication;
