import Api from './userApi';
import { ADD_ILLUSTRATION } from './events';
import { uploadStore } from '../stores/uploadStore';

import analytics from './analytics';
import { UPLOAD_CREATE } from './analytics/events';
import { useMenuStore } from '../stores/menuStore';

const MAX_SVG_SIZE_MB = 3;
const Me = 2 ** 20;

const svgDataRegExp = /data:.*\/.*;base64/;

/**
 * utility functions to handle the upload of files
 */

/**
 * check that the file can be uploaded and trigger an upload with the API
 */
export const uploadFile = async (file, callback) => {
  if (file.type.match(/.*\/svg/)) {
    // Is SVG

    /*
      file.size is expressed in bytes
      2 ** 20 * byte = 1MeB (mebibyte)

      Note: Preferring MB (megabytes) might cause confusion if using an OS
      that displays sizes in MeB like Linux or (sometimes I think) Windows, since
      it would make it so that the limit is actually a bit lower than 3 for the unsuspecting
      user.
    */
    if (file.size / Me > MAX_SVG_SIZE_MB) {
      useMenuStore.getState().showTooComplexSVGModal();
      return;
    }

    const fileContent = await new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (_) => resolve(fileReader.result);
      fileReader.readAsText(file);
    });

    if (svgDataRegExp.test(fileContent)) {
      uploadStore.getState().setError('SVG_CONTAINS_EMBEDDED_DATA');
      return;
    }
  }

  // check the mime type of the file
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
  if (!file || !file.type?.startsWith('image/')) {
    return false;
  }

  const result = await Api.uploadElement(file);
  if (!result?.objectName) {
    uploadStore.getState().setError(result?.error);
    return false;
  }

  analytics.track(UPLOAD_CREATE, {
    label: result.objectName,
  });
  callback && callback(result);
  // Append the result to the uploadStore as a local upload
  const addAndFormatLocalUpload =
    uploadStore.getState().addAndFormatLocalUpload;
  addAndFormatLocalUpload(result);
  return result;
};

/**
 * handler to register the dropping of a file and uploading that file
 */
export const onDropHandler = (event, dispatch, fireToast) => {
  let file;
  if (event.dataTransfer.items) {
    // use DataTransferItemList interface to access the file
    if (event.dataTransfer.items[0]?.kind === 'file') {
      file = event.dataTransfer.items[0].getAsFile();
    }
  } else {
    // use DataTransfer interface to access the file
    file = event.dataTransfer.files[0];
  }

  if (!file) {
    return;
  }

  // prevent default behavior (prevent file from being opened)
  event.preventDefault();

  return uploadFile(file, (element) => {
    if (dispatch) {
      // add the file to the canvas at the position it was dropped
      // and trigger a notification about it
      dispatch(ADD_ILLUSTRATION, {
        objectName: `api/${element.objectName}`,
        dropX: event.clientX,
        dropY: event.clientY,
        skipPopularityTracking: true,
        fireToast: () =>
          setTimeout(() => {
            fireToast({ label: 'Element uploaded!' });
          }, 100),
      });
    }
  });
};

/**
 * prevent default behavior (prevent file from being opened)
 * should be used in combination with onDropHandler
 */
export const onDragOverHandler = (event) => {
  let hasFile = false;
  if (event.dataTransfer.items) {
    if (event.dataTransfer.items[0]?.kind === 'file') {
      hasFile = true;
    }
  } else if (event.dataTransfer.files.length) {
    hasFile = true;
  }

  if (hasFile) {
    event.preventDefault();
  }
};
