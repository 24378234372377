import React from 'react';
import PropTypes from 'prop-types';
import ShadingPanel from './ShadingPanel/ShadingPanel';
import DecorationPanel from './DecorationPanel/DecorationPanel';
import { filterColorPalette } from '../../utils/editor/misc';
import { PanelWrapper } from '../RightMenu/styles';
import LockedPanel from '../StylesMenu/LockedPanel/LockedPanel';

/**
 * The EffectsMenu
 */
const EffectsMenu = (props) => {
  const options = props.options || {};
  const filteredColorPalette = filterColorPalette(props.colorPalette);

  if (options?.locked) {
    return <LockedPanel id={options?.id} dispatch={props.dispatch} />;
  }

  return (
    <>
      <PanelWrapper>
        <ShadingPanel
          options={{ ...options.shadowOptions, stroke: options.stroke }}
          colorPalette={filteredColorPalette}
          dispatch={props.dispatch}
        />
      </PanelWrapper>
      <PanelWrapper>
        <DecorationPanel
          options={options.decorationOptions}
          colorPalette={filteredColorPalette}
          dispatch={props.dispatch}
        />
      </PanelWrapper>
    </>
  );
};

EffectsMenu.propTypes = {
  /**
   * current effect options
   */
  options: PropTypes.shape({
    type: PropTypes.string,
    locked: PropTypes.bool,
    shadowOptions: PropTypes.shape({}),
    decorationOptions: PropTypes.shape({}),
  }),
  /**
   * Object containing information about the artboards color palette
   */
  colorPalette: PropTypes.object,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
};

export default EffectsMenu;
