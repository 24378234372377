import { useCallback, useEffect } from 'react';
import hotkeys from 'hotkeys-js';

import { ENABLE_DRAGGING, ENABLE_ZOOM_ON_WHEEL } from '../../global/events';
import { Dispatcher } from '../../types';

/**
 * This hook handles the binding/unbinding event handler for blur.
 */
export const useBlurHandler = (dispatch: Dispatcher): void => {
  const removeHotkeysOnBlur = useCallback(() => {
    hotkeys.shift = false;
    hotkeys.ctrl = false;
    hotkeys.alt = false;
    hotkeys.option = false;
    hotkeys.control = false;
    hotkeys.cmd = false;
    hotkeys.command = false;

    dispatch(ENABLE_DRAGGING, false);
    dispatch(ENABLE_ZOOM_ON_WHEEL, false);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('blur', removeHotkeysOnBlur);

    return (): void => window.removeEventListener('blur', removeHotkeysOnBlur);
  }, [removeHotkeysOnBlur]);
};
