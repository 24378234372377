import { ImageFilterType } from '../../types';

export const sliderFilterConfig = [
  {
    type: ImageFilterType.Brightness,
    key: 'brightness',
    label: 'Brightness',
    unit: '%',
    min: -0.6,
    max: 0.6,
  },
  {
    type: ImageFilterType.Contrast,
    key: 'contrast',
    label: 'Contrast',
    unit: '%',
    min: -0.3,
    max: 1,
  },
  {
    type: ImageFilterType.Saturation,
    key: 'saturation',
    label: 'Saturation',
    unit: '%',
    min: -1,
    max: 1,
  },
  {
    type: ImageFilterType.Vibrance,
    key: 'vibrance',
    label: 'Vibrance',
    unit: '%',
    min: -1,
    max: 1,
  },
  {
    type: ImageFilterType.HueRotation,
    key: 'rotation',
    label: 'Hue',
    unit: '',
    min: -1,
    max: 1,
  },
  {
    type: ImageFilterType.Blur,
    key: 'blur',
    label: 'Blur',
    unit: '%',
    min: -1,
    max: 1,
  },
  {
    type: ImageFilterType.Noise,
    key: 'noise',
    label: 'Noise',
    unit: '%',
    min: 0,
    max: 140,
  },
  {
    type: ImageFilterType.Pixelate,
    key: 'blocksize',
    label: 'Pixelate',
    unit: '',
    min: 1,
    max: 100,
  },
];
