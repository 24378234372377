import React from 'react';
import PropTypes from 'prop-types';

import { SpinnerStyle, SpinnerWrapper } from './styles';
import { defaultTheme } from './theme';

const Spinner = ({ size = '15px', style, theme: _theme }) => {
  const theme = { ...defaultTheme, ..._theme };

  return (
    <SpinnerWrapper style={style}>
      <SpinnerStyle size={size} theme={theme} />
    </SpinnerWrapper>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.object,
};

export default Spinner;
