import { useCallback, useRef, useEffect } from 'react';

/* Returns a function based on callback whose execution is delayed until {delay} milliseconds have passed since it was last called */

const useDebounce = ({ callback, delay }) => {
  const timeout = useRef();
  const debounced = useCallback(
    (...args) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        if (callback) callback(...args);
      }, delay);
    },
    [delay, callback]
  );

  // NOTE: this looses last call
  useEffect(() => () => clearTimeout(timeout.current), []);

  return debounced;
};

export default useDebounce;
