import { fabric } from 'fabric';
import Pica from 'pica';
import { changeDpiDataUrl, changeDpiBlob } from 'changedpi';
import cloneDeep from 'lodash/cloneDeep';

import { getSupersamplingFactor } from './utils';
import { settingsStore } from '../../stores/settingsStore';
import { computeFinalPixelSize } from '../../utils/artboard';
import distributedProgress from '../../services/distributedProgress';

const { updateSettings } = settingsStore.getState();

const defaultDpi = 72;

export const scaleToTargetSize = (targetSize, { width, height }) => {
  const targetPixelCount = targetSize ** 2;
  const scale = Math.sqrt(targetPixelCount / (height * width));

  /*
        width * height =
          sqrt(targetPixelCount) ** 2 * (sqrt(width) / sqrt(height)) *  (sqrt(height) / sqrt(width)) =
          targetPixelCount

        And biggest side is at most
          sqrt(max(width, height) / min(width, height)) * sqrt(targetPixelCount)
          In our case the max possible size when getting previews is sqrt(2048 / 144) * 1024 ~= 3861
      */
  return {
    width: Math.round(width * scale),
    height: Math.round(height * scale),
  };
};

const defaultOptions = {
  format: 'png',
  quality: 1,
  dpi: defaultDpi,
  exportPosition: new fabric.Point(0, 0),
  destinationDimension: {
    width: 0,
    height: 0,
  },
  multiplier: 1,
  preservedCanvasState: {
    artboardOpacity: 1,
    invisibleObjects: [],
    showGrid: false,
    backgroundColor: null,
  },
};

const rasterizeImage = {
  ...cloneDeep(defaultOptions),

  _init(canvas, initOptions) {
    const options = { ...cloneDeep(defaultOptions), ...initOptions };
    const { format, quality, dpi } = options;
    if (format) this.format = format === 'jpg' ? 'jpeg' : 'png';
    if (quality) this.quality = quality;
    if (dpi) this.dpi = dpi;
    this._setExportPosition(canvas, options);
    this._setDestinationDimension(canvas, options);
    this._setMultiplier(canvas, options);
  },

  _setExportPosition(canvas, { cropBox }) {
    this.exportPosition = fabric.util.transformPoint(
      cropBox
        ? new fabric.Point(cropBox.left, cropBox.top)
        : new fabric.Point(0, 0),
      canvas.viewportTransform
    );
  },

  _setDestinationDimension(
    canvas,
    { forViewport, targetSize, cropBox, size, dpi }
  ) {
    const { artboardOptions } = canvas;
    let { width, height } = forViewport
      ? cropBox
        ? cropBox
        : artboardOptions
      : computeFinalPixelSize(size, dpi);

    if (targetSize) {
      const scaledDimension = scaleToTargetSize(targetSize, { width, height });
      width = scaledDimension.width;
      height = scaledDimension.height;
    }

    this.destinationDimension = {
      width,
      height,
    };
  },

  _setMultiplier(canvas, { cropBox }) {
    const zoom = canvas.getZoom();
    const viewportToExportRatio = cropBox
      ? 1 // don't scale, when a cropBox was given
      : Math.min(
          canvas.artboardOptions.width /
            Math.round(this.destinationDimension.width),
          canvas.artboardOptions.height /
            Math.round(this.destinationDimension.height)
        );

    this.multiplier = 1 / (zoom * viewportToExportRatio);
  },

  _preserveCanvasState(canvas, { removeBackground, visibleObjectIds, plan }) {
    if (removeBackground) {
      this.preservedCanvasState.artboardOpacity = canvas.artboard.opacity;
      canvas.artboard.opacity = 0;
    }

    // hide objects that are not specified as visible
    // store their opacity in opacityLookup, to restore it later
    this.preservedCanvasState.invisibleObjects = [];
    if (visibleObjectIds?.length) {
      canvas.allObjects().forEach((object) => {
        if (!visibleObjectIds.find((id) => id === object.id)) {
          this.preservedCanvasState.invisibleObjects.push({
            id: object.id,
            opacity: object.opacity,
          });
          object.opacity = 0;
        }
      });
    }

    this.preservedCanvasState.showGrid =
      settingsStore.getState().settings.showGrid;
    updateSettings({ showGrid: false });

    canvas.artboard.removeTransparencyGrid = true;

    this.preservedCanvasState.backgroundColor = canvas.backgroundColor;
    // Removing background and downloading JPEG leads to black background
    // here we specifically set background to white for JPEGs
    if (!plan || this.format === 'jpeg') canvas.backgroundColor = '#FFFFFF';
    else canvas.backgroundColor = null;

    // render trueRender objects in best quality
    fabric.forceTrueRender = true;
    canvas.exporting = true;
  },

  _restoreCanvasState(canvas, { removeBackground, visibleObjectIds }) {
    fabric.forceTrueRender = false;
    canvas.exporting = false;

    canvas.backgroundColor = this.preservedCanvasState.backgroundColor;
    canvas.artboard.removeTransparencyGrid = false;

    if (removeBackground) {
      canvas.artboard.opacity = this.preservedCanvasState.artboardOpacity;
    }

    // reset opacity of objects that where hidden before
    if (visibleObjectIds?.length) {
      canvas.allObjects().forEach((object) => {
        const lookup = this.preservedCanvasState.invisibleObjects.find(
          (lookupObject) => lookupObject.id === object.id
        );
        if (lookup) {
          object.opacity = lookup.opacity;
        }
      });
    }

    updateSettings({ showGrid: this.preservedCanvasState.showGrid });
    canvas.requestRenderAll();
  },

  _rasterize(canvas) {
    const dataUrl = canvas.toDataURL({
      format: this.format,
      width: this.destinationDimension.width / this.multiplier,
      height: this.destinationDimension.height / this.multiplier,
      top: this.exportPosition.y,
      left: this.exportPosition.x,
      quality: this.quality,
      multiplier: this.multiplier,
    });

    return changeDpiDataUrl(dataUrl, this.dpi);
  },

  async _rasterizeWithSupersampling(canvas, options) {
    const supersamplingFactor = getSupersamplingFactor(
      this.destinationDimension
    );
    const supersamplingCanvas = canvas.toCanvasElement(
      this.multiplier * supersamplingFactor,
      {
        width: this.destinationDimension.width / this.multiplier,
        height: this.destinationDimension.height / this.multiplier,
        top: this.exportPosition.y,
        left: this.exportPosition.x,
      }
    );

    // Once supersampling is done, canvas state can be safely restored
    this._restoreCanvasState(canvas, options);

    distributedProgress.proceed();

    const destinationCanvas = fabric.util.createCanvasElement();
    destinationCanvas.width = this.destinationDimension.width;
    destinationCanvas.height = this.destinationDimension.height;

    const pica = new Pica();
    await pica.resize(supersamplingCanvas, destinationCanvas);

    distributedProgress.proceed();

    return new Promise((resolve) => {
      destinationCanvas.toBlob(
        async (_blob) => {
          const blob = await changeDpiBlob(_blob, this.dpi);
          resolve(blob);
        },
        `image/${this.format}`,
        this.quality
      );
    });
  },

  getDataUrl(canvas, options) {
    this._init(canvas, options);
    this._preserveCanvasState(canvas, options);
    const dataUrl = this._rasterize(canvas);
    this._restoreCanvasState(canvas, options);

    return dataUrl;
  },

  getArtboardImageClip(canvas, options) {
    this._init(canvas, options);
    const { x, y } = this.exportPosition;
    const { width, height } = this.destinationDimension;
    const zoom = canvas.getZoom();

    return {
      x,
      y,
      width: width * zoom,
      height: height * zoom,
      exportRatio: 1 / this.multiplier,
    };
  },

  async getBlobWithSupersampling(canvas, options) {
    this._init(canvas, options);
    this._preserveCanvasState(canvas, options);
    return this._rasterizeWithSupersampling(canvas, options);
  },
};

export default rasterizeImage;
