import React, { useCallback, useRef, useState } from 'react';
import { Position, SaturationProps, SVColor } from './types';
import {
  BlackGradient,
  Pointer,
  SaturationWrapper,
  WhiteGradient,
} from './styles';
import { defaultTheme } from './theme';
import useThrottle from '../../../hooks/useThrottle';

const throttleInterval = 50;

// s and v can be represented as an offset in percent
const getPosition: (svColor: SVColor) => Position = ({ s, v }) => {
  return {
    bottom: v * 100,
    left: s * 100,
  };
};

const Saturation: React.FC<SaturationProps> = ({ color, onChange, theme }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [sliding, setSliding] = useState<boolean>(false);
  const [pointerPosition, setPointerPosition] = React.useState<Position>(
    getPosition(color.hsv)
  );

  const calculateColor = useCallback(
    (mouseX: number, mouseY: number): SVColor => {
      if (!ref.current) {
        return { s: 0, v: 0 };
      }

      const { width, height, left, top } = ref.current.getBoundingClientRect();
      const { scrollX, scrollY } = window;

      // calculate the position of the mouse relative to the container
      const xInContainer = Math.max(mouseX - scrollX - left, 0);
      const yInContainer = Math.max(mouseY - scrollY - top, 0);

      // s and v can be represented as an offset between 0 and 1 from left and bottom
      const s = Math.min(xInContainer / width, 1);
      const v = 1 - Math.min(yInContainer / height, 1);
      return { s, v };
    },
    []
  );

  const setColor = useCallback(
    (svColor: SVColor) => {
      onChange(svColor);
    },
    [onChange]
  );

  const throttledSetColor = useThrottle({
    callback: setColor,
    delay: throttleInterval,
    skipLeading: false,
  });

  const handlePointerDown = (
    event: React.PointerEvent<HTMLDivElement>
  ): void => {
    event.preventDefault();
    ref.current?.setPointerCapture(event.pointerId);
    setSliding(true);
    const svColor = calculateColor(event.pageX, event.pageY);
    setPointerPosition(getPosition(svColor));
    throttledSetColor(svColor);
  };

  const handlePointerMove = (
    event: React.PointerEvent<HTMLDivElement>
  ): void => {
    if (!sliding) {
      return;
    }

    const svColor = calculateColor(event.pageX, event.pageY);
    setPointerPosition(getPosition(svColor));
    throttledSetColor(svColor);
  };

  const handlePointerUp = (event: React.PointerEvent<HTMLDivElement>): void => {
    ref.current?.releasePointerCapture(event.pointerId);
    setSliding(false);
  };

  const pointerTheme = { ...defaultTheme, ...theme };

  return (
    <SaturationWrapper
      ref={ref}
      backgroundColor={color.hsv.h}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
    >
      <WhiteGradient />
      <BlackGradient />
      {!color.isEmpty && (
        <Pointer
          colorPointer={pointerTheme.colorPointer}
          bottom={pointerPosition.bottom}
          left={pointerPosition.left}
          theme={pointerTheme}
        />
      )}
    </SaturationWrapper>
  );
};

export default Saturation;
