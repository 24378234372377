export enum PanelState {
  Open = 'open',
  Closed = 'closed',
  Expanded = 'expanded',
}

export interface BasePanelProps {
  /**
   * label of the panel
   */
  label: string;
  /**
   * current state of the panel
   */
  state: PanelState;
  /**
   * optional element for PanelHeader (rendered to the left of the label)
   */
  labelPrefix?: () => JSX.Element;
  /**
   * optional element for PanelHeader (rendered to the right of the label)
   */
  labelPostfix?: () => JSX.Element;
  /**
   * Determines whether the body is scrollable or not
   */
  noOverflow?: boolean;
  /**
   * element that should be rendered in the head of the panel
   */
  headElement: JSX.Element;
  /**
   * element that should be rendered in the body of the panel
   */
  bodyElement: JSX.Element;
  /**
   * element that should be rendered next to the panel
   */
  extensionElement?: JSX.Element;
  /**
   * onClose, triggered when close button is clicked
   */
  onClose?: () => void;
}

export type BasePanelHandler = {
  setShowExtension?: (value: boolean) => void;
};
