import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button/Button';
import Spacer from '../Spacer/Spacer';
import {
  BottomBanner,
  Image,
  NextItem,
  NextItemsWrapper,
  Wrapper,
} from './styles';
import { ReactComponent as StarsIllustration } from '../../assets/images/stars.svg';
import { useOnboardingStore } from '../../stores/onboardingStore';
import { ONBOARDING_USER_STEP } from '../../stores/onboardingStore/constants';
import { H1, H3, H4 } from '../utilities/Typography/styles';
import { buildWebsiteUrl } from '../../utils/url';
import { themeStyle } from '../../services/theming';
import { primaryLightTheme } from '../Button/Button/theme';

const Congratulations = ({ onClose, onStartNewDesign }) => {
  const completeOnboardingAndOpenWebsiteUrl = (url) => {
    window.open(buildWebsiteUrl(url), '_blank');

    useOnboardingStore.getState().setStep(ONBOARDING_USER_STEP.COMPLETE);
  };

  const items = [
    {
      src: '/images/onboardingNextNewDesign.jpg',
      label: 'Start a new design',
      onClick: () => {
        onClose();
        onStartNewDesign();
      },
    },
    {
      src: '/images/onboardingNextTemplates.jpg',
      label: 'Browse Templates',
      onClick: () => {
        completeOnboardingAndOpenWebsiteUrl('/');
      },
    },
    {
      src: '/images/onboardingNextTutorials.jpg',
      label: 'Learn with Kittl tutorials',
      onClick: () => {
        completeOnboardingAndOpenWebsiteUrl('/tutorials');
      },
    },
  ];

  const handleLearnMoreClick = () => {
    completeOnboardingAndOpenWebsiteUrl('/pricing');
  };

  return (
    <Wrapper>
      <Spacer h="37px" />
      <StarsIllustration height="86px" />
      <Spacer h="20px" />
      <H1>Congrats, you are ready to go!</H1>
      <Spacer h="10px" />
      <H3>What would you like to do next?</H3>
      <Spacer h="32px" />
      <NextItemsWrapper gap="24px" justifyContent="center">
        {items.map((item) => (
          <NextItem
            key={item.label}
            type="button"
            alignItems="center"
            onClick={item.onClick}
          >
            <Image
              src={item.src}
              alt="Colorful graphic depicting the action"
              width={254}
              height={194}
            />
            <Spacer h="10px" />
            <H4>{item.label}</H4>
          </NextItem>
        ))}
      </NextItemsWrapper>
      <Spacer h="43px" />
      <BottomBanner alignItems="center">
        <H3 color={themeStyle.inkBase}>Unlock all features with Kittl Pro</H3>
        <Spacer h="12px" />
        <Button
          height="40px"
          width="176px"
          label="Learn More"
          onClick={handleLearnMoreClick}
          theme={primaryLightTheme}
        />
      </BottomBanner>
    </Wrapper>
  );
};

Congratulations.propTypes = {
  /**
   * onClose function
   */
  onClose: PropTypes.func,
  /**
   * onStartNewDesign function
   */
  onStartNewDesign: PropTypes.func,
};

export default Congratulations;
