import { fabric } from 'fabric';
import paper from '@kittl/paper';
import {
  pathTransformPositionHandler,
  circleRotationHandlePositionHandler,
  lockPointToAxis,
} from '../../../utils/editor/misc';
import { themeStyle } from '../../../services/theming';
import {
  bezierPathFromPoints,
  getCircleParameterFromPoints,
} from '../../../utils/editor/shapes';
import {
  isSmallerPoint,
  rotatePointAroundSinglePoint,
} from '../../../utils/editor/points';
import { calcOffset } from '../../../utils/editor/angle';
import { drawFreeFormCurves } from '../../../utils/editor/commands';

const FREE_LINE_TRANSFORM_DELAY = 300;

/**
 * utility to get absolute points from object and
 * translate these points in relation to the context
 */
export const getRelativePoints = (fabricObject, left, top) => {
  const rect = fabricObject.getBoundingRect(true);
  const zoom = fabricObject.canvas ? fabricObject.canvas.getZoom() : 1;

  // points are absolute coordinates, so they need to be adjusted to the rendering of the object,
  // by adding objects dimensions (rect) and current viewport settings (zoom, top, left)
  return fabricObject.points.map((point) => {
    return {
      x: (point.x - rect.left - rect.width / 2) * zoom + left,
      y: (point.y - rect.top - rect.height / 2) * zoom + top,
    };
  });
};

// get a new center of the circle after points have changed and adjust the new
// point to the current angle
const getNewCenterOfCircle = (isVertical, newPoint, oppositePoint, angle) => {
  const isSmaller = isSmallerPoint(newPoint, oppositePoint, angle, isVertical);

  const distance = Math.hypot(
    oppositePoint.x - newPoint.x,
    oppositePoint.y - newPoint.y
  );

  const offsetWithAngle = calcOffset(
    (isSmaller ? -1 : 1) * distance,
    angle + (isVertical ? 90 : 0)
  );

  const adjustedPoint = {
    x: oppositePoint.x + offsetWithAngle.x,
    y: oppositePoint.y + offsetWithAngle.y,
  };

  const center = new fabric.Point(
    (oppositePoint.x + adjustedPoint.x) / 2,
    (oppositePoint.y + adjustedPoint.y) / 2
  );

  return { center, adjustedPoint };
};

const getNextPointOnCircle = (point, center) => {
  const { x, y } = rotatePointAroundSinglePoint(
    new fabric.Point(point.x, point.y),
    center,
    90
  );
  return { x, y };
};

export const circleRotationControl = (eventData, transform, x, y) => {
  const polygon = transform.target;
  const points = polygon.points;

  let { center } = getCircleParameterFromPoints(polygon.points);
  center = new fabric.Point(center.x, center.y);

  const currentAngle = polygon.angle;
  const cursorPosition = new paper.Point(x - center.x, y - center.y);

  const flipAngle = polygon.flipX !== polygon.flipY ? 180 : 0;
  const angle =
    cursorPosition.getAngle() +
    (isSmallerPoint(points[0], points[3], currentAngle, false) ? 90 : -90) +
    flipAngle;
  const angleDifference = angle - currentAngle;

  clearTimeout(polygon.__rotateAroundPointTimeout);

  polygon.__rotateAroundPointTimeout = setTimeout(() => {
    polygon.rotateAroundPoint(angleDifference, center, false);
  }, FREE_LINE_TRANSFORM_DELAY);

  polygon.rotateAroundPoint(angleDifference, center, true);
};

//Gets controls that control the circle's radius
export const getCircleRadiusControl = (fabric, index, obj) => {
  const actionHandler = (eventData, transform, x, y) => {
    const polygon = transform.target;
    const points = polygon.points;
    const flipped = polygon.flipX !== polygon.flipY;

    const angle = polygon.angle;

    let _points = [...points];
    if (index === 0 || index === 3) {
      // horizontal controls
      const oppositeIndex = index === 0 ? 3 : 0;
      const oppositePoint = points[oppositeIndex];

      const { adjustedPoint, center } = getNewCenterOfCircle(
        false,
        { x, y },
        oppositePoint,
        angle
      );

      _points[index] = adjustedPoint;
      _points = [
        _points[0], // left
        getNextPointOnCircle(_points[flipped ? 3 : 0], center), // top
        getNextPointOnCircle(_points[flipped ? 0 : 3], center), // bottom
        _points[3], // right
      ];
    }
    if (index === 1 || index === 2) {
      // vertical controls
      const oppositeIndex = index === 1 ? 2 : 1;
      const oppositePoint = points[oppositeIndex];

      const { adjustedPoint, center } = getNewCenterOfCircle(
        true,
        { x, y },
        oppositePoint,
        angle
      );

      _points[index] = adjustedPoint;
      _points = [
        getNextPointOnCircle(_points[flipped ? 1 : 2], center), // left
        _points[1], // top
        _points[2], // bottom
        getNextPointOnCircle(_points[flipped ? 2 : 1], center), // right
      ];
    }

    polygon.points = _points;

    clearTimeout(polygon.__setTransformPointsTimeout);

    polygon.__setTransformPointsTimeout = setTimeout(() => {
      polygon.setTransformPoints(polygon.points, {
        ...polygon.transformOptions,
        isChanging: false,
      });
    }, FREE_LINE_TRANSFORM_DELAY);

    polygon.setTransformPoints(polygon.points, {
      ...polygon.transformOptions,
      isChanging: true,
    });
  };

  return new fabric.Control({
    positionHandler: pathTransformPositionHandler,
    actionHandler: actionHandler,
    actionName: 'modifyPath',
    pointIndex: index,
  });
};

export const getCircleControl = (fabric, isPreview = false) => {
  const renderCircle = (ctx, left, top, styleOverride, fabricObject) => {
    const points = getRelativePoints(fabricObject, left, top);
    const handlePosition = circleRotationHandlePositionHandler(
      null,
      null,
      fabricObject
    );
    const topPoint = points[1];

    const { radius, center } = getCircleParameterFromPoints(points);

    ctx.save();
    ctx.beginPath();
    ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);

    if (!isPreview) {
      ctx.moveTo(handlePosition.x, handlePosition.y);
      ctx.lineTo(topPoint.x, topPoint.y);
    }

    ctx.strokeStyle = themeStyle.selectionBlue;
    ctx.stroke();
    ctx.restore();
  };

  return new fabric.Control({
    actionName: 'showTransform',
    lineIndex: 0,
    render: renderCircle,
    skipTargetFind: true,
  });
};

export const getFreeLineControl = (fabric, isPreview = false) => {
  const renderLine = (ctx, left, top, styleOverride, fabricObject) => {
    const points = getRelativePoints(fabricObject, left, top);

    const firstPoint = points[0];
    const lastPoint = points[points.length - 1];

    ctx.save();
    ctx.lineWidth = 1;
    ctx.strokeStyle = themeStyle.selectionBlue;
    ctx.beginPath();

    // draw bezier curve
    const curve = bezierPathFromPoints(points);

    const accuracy = 0.01;
    ctx.moveTo(firstPoint.x, firstPoint.y);
    for (let i = 0; i <= 1; i += accuracy) {
      const p = curve.getPointAt(i * curve.length);
      if (!p) continue; // if curve is too short p can be null
      ctx.lineTo(p.x, p.y);
    }
    ctx.lineTo(lastPoint.x, lastPoint.y);

    if (isPreview) {
      ctx.stroke();
      ctx.restore();
      return;
    }

    // add lines between positional points and their controls
    points.forEach((point, index) => {
      if (index % 3 === 0) {
        if (index > 0) {
          // line to previous control
          const previousPoint = points[index - 1];
          ctx.moveTo(previousPoint.x, previousPoint.y);
          ctx.lineTo(point.x, point.y);
        }
        if (index < points.length - 1) {
          // line to next control
          const nextPoint = points[index + 1];
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(nextPoint.x, nextPoint.y);
        }
      }
    });

    ctx.stroke();
    ctx.restore();
  };

  return new fabric.Control({
    actionName: 'showTransform',
    lineIndex: 0,
    render: renderLine,
    skipTargetFind: true,
  });
};

export const getEditPathControl = (fabric, index, obj) => {
  // define a function that will define what the control does
  // this function will be called on every mouse move after a control has been
  // clicked and is being dragged.
  // The function receive as arguments the mouse event, the current transform object
  // and the current position in canvas coordinates
  // transform.target is a reference to the current object being transformed
  function actionHandler(eventData, transform, x, y) {
    const polygon = transform.target;
    const currentControl = polygon.controls[polygon.__corner];

    // the control can be removed when text is deleted
    if (!currentControl) return false;

    const currentControlPoint = polygon.points[currentControl.pointIndex];
    const diffX = x - currentControlPoint.x;
    const diffY = y - currentControlPoint.y;

    polygon.points[currentControl.pointIndex] = { x, y };

    if (polygon.points.length !== 2) {
      if (currentControl.pointIndex % 3 === 0) {
        // update neighboring controls if positional point is changed
        if (indexInArray(currentControl.pointIndex - 1, polygon.points)) {
          const previousPoint = polygon.points[currentControl.pointIndex - 1];
          previousPoint.x += diffX;
          previousPoint.y += diffY;
        }

        if (indexInArray(currentControl.pointIndex + 1, polygon.points)) {
          const nextPoint = polygon.points[currentControl.pointIndex + 1];
          nextPoint.x += diffX;
          nextPoint.y += diffY;
        }
      } else {
        // update partner control, if control changed
        // both controls around a position (partner controls) should have the same angle to the positional point between them
        const partnerControlIndex =
          currentControl.pointIndex % 3 === 1
            ? currentControl.pointIndex - 2
            : currentControl.pointIndex + 2;

        if (indexInArray(partnerControlIndex, polygon.points)) {
          const partnerControl = polygon.points[partnerControlIndex];
          const positionalPoint =
            polygon.points[
              currentControl.pointIndex % 3 === 1
                ? currentControl.pointIndex - 1
                : currentControl.pointIndex + 1
            ];

          const thisOffsetToPos = Math.hypot(
            positionalPoint.x - x,
            positionalPoint.y - y
          );
          const partnerOffsetToPos = Math.hypot(
            positionalPoint.x - partnerControl.x,
            positionalPoint.y - partnerControl.y
          );
          const relativeDiff = thisOffsetToPos / partnerOffsetToPos;

          // set new position of partner control
          polygon.points[partnerControlIndex] = {
            x: positionalPoint.x - (x - positionalPoint.x) / relativeDiff,
            y: positionalPoint.y - (y - positionalPoint.y) / relativeDiff,
          };
        }
      }
    }

    clearTimeout(polygon.__setTransformPointsTimeout);

    polygon.__setTransformPointsTimeout = setTimeout(() => {
      polygon.setTransformPoints(polygon.points, {
        ...polygon.transformOptions,
        isChanging: false,
      });
    }, FREE_LINE_TRANSFORM_DELAY);

    polygon.setTransformPoints(polygon.points, {
      ...polygon.transformOptions,
      isChanging: true,
    });
  }

  return new fabric.Control({
    positionHandler: pathTransformPositionHandler,
    actionHandler: getLockedActionHandler(actionHandler, index),
    mouseDownHandler: obj.setLastPoints.bind(obj),
    actionName: 'modifyPath',
    pointIndex: index,
  });
};

const indexInArray = (index, array) => {
  if (index < 0) return false;
  if (index >= array.length) return false;
  return true;
};

export const getFreeFormControl = (fabric) => {
  const renderLine = (ctx, left, top, styleOverride, fabricObject) => {
    const vpt = fabricObject.canvas.viewportTransform;
    const points = fabricObject.points.map((p) => {
      const point = new fabric.Point(p.x, p.y);
      return fabric.util.transformPoint(point, vpt);
    });

    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = themeStyle.selectionBlue;

    drawFreeFormCurves(points, ctx);

    ctx.stroke();
    ctx.restore();
  };

  return new fabric.Control({
    actionName: 'showTransform',
    lineIndex: 0,
    render: renderLine,
    skipTargetFind: true,
  });
};

export const getFreeFormMeshControl = (fabric, index, obj) => {
  function actionHandler(eventData, transform, x, y) {
    const polygon = transform.target;
    const currentControl = polygon.controls[polygon.__corner];

    const pointIndex = currentControl.pointIndex;
    const point = polygon.points[pointIndex];

    const updateHandleFromParent = (oldHandle) => {
      return { x: oldHandle.x + (x - point.x), y: oldHandle.y + (y - point.y) };
    };

    const updateHandleFromSibling = (handleIndex, parent) => {
      const diff = { x: parent.x - x, y: parent.y - y };
      const handle = polygon.points[handleIndex];
      const lengthFactor =
        Math.hypot(parent.x - handle.x, parent.y - handle.y) /
        Math.hypot(diff.x, diff.y);
      diff.x *= lengthFactor;
      diff.y *= lengthFactor;
      polygon.points[handleIndex] = {
        x: diff.x + parent.x,
        y: diff.y + parent.y,
      };
    };

    //Top middle point
    if (pointIndex === 1) {
      //Also move handles
      let handle = polygon.points[6];
      polygon.points[6] = updateHandleFromParent(handle);

      handle = polygon.points[7];
      polygon.points[7] = updateHandleFromParent(handle);
    }

    //Bottom middle point
    if (pointIndex === 4) {
      //Also move handles
      let handle = polygon.points[8];
      polygon.points[8] = updateHandleFromParent(handle);

      handle = polygon.points[9];
      polygon.points[9] = updateHandleFromParent(handle);
    }

    //Get parent of sibling handle
    let parent;
    if (pointIndex === 8 || pointIndex === 9) {
      parent = polygon.points[4];
    } else if (pointIndex === 6 || pointIndex === 7) {
      parent = polygon.points[1];
    }

    if (parent) {
      //Then we are indeed moving a handle

      //Get handleIndex for the handle we need to update
      const handleIndex = pointIndex + (pointIndex % 2 === 0 ? 1 : -1);
      updateHandleFromSibling(handleIndex, parent);
    }

    polygon.points[currentControl.pointIndex] = { x, y };

    clearTimeout(polygon.__setTransformPointsTimeout);

    polygon.__setTransformPointsTimeout = setTimeout(() => {
      polygon.setTransformPoints(polygon.points, {
        ...polygon.transformOptions,
        isChanging: false,
      });
    }, FREE_LINE_TRANSFORM_DELAY);

    polygon.setTransformPoints(polygon.points, {
      ...polygon.transformOptions,
      isChanging: true,
    });
  }

  return new fabric.Control({
    positionHandler: pathTransformPositionHandler,
    actionHandler: getLockedActionHandler(actionHandler, index),
    mouseDownHandler: obj.setLastPoints.bind(obj),
    actionName: 'modifyPath',
    pointIndex: index,
  });
};

function getLockedActionHandler(actionHandler, index) {
  return function (eventData, transform, x, y) {
    const polygon = transform.target;
    const lastPoints = polygon.lastPoints;
    const lastPosition = lastPoints[index];

    let finalXY = { x, y };

    if (eventData.shiftKey) {
      finalXY = lockPointToAxis({ x, y }, lastPosition);
    }

    actionHandler(eventData, transform, finalXY.x, finalXY.y);
  };
}
