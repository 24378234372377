import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { EMAIL_REGEXP } from '../../global/constants';
import { buildProfileImageUrl } from '../../utils/url';
import userApi from '../../global/userApi';
import useDebounce from '../../hooks/useDebounce';
import Button from '../Button/Button/Button';
import { primarySmallTheme } from '../Button/Button/theme';
import Popover from '../Popover/Popover';
import ProfileImage from '../Profile/ProfileImage/ProfileImage';
import Spacer from '../Spacer/Spacer';
import { FlexColumn, FlexRow } from '../utilities/styles';
import { H5, P4 } from '../utilities/Typography/styles';
import { Input, SuggestionWrapper, Wrapper } from './styles';
import { themeStyle } from '../../services/theming';
import { defaultTheme } from './theme';

const UserSearch = (props) => {
  const theme = { ...defaultTheme, ...props.theme };
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userType, setUserType] = useState(null);
  const inputRef = useRef();
  const shouldPopulateSuggestions = useRef(true);

  const getUserType = useCallback(
    (inputValue) => {
      if (suggestions.length) {
        const someSlugFound = suggestions.some(
          (suggestion) => suggestion.slug === inputValue
        );

        if (someSlugFound) {
          return 'user';
        }
      }

      const isEmail = EMAIL_REGEXP.test(inputValue);
      return isEmail ? 'email' : null;
    },
    [suggestions]
  );

  const fetchSuggestions = async (val) => {
    if (!val) {
      setSuggestions([]);
      return;
    }

    const { results } = await userApi.findUser(val);

    if (val !== inputRef.current.value) return;
    if (!shouldPopulateSuggestions.current) {
      shouldPopulateSuggestions.current = true;
      return;
    }
    if (results) {
      setSuggestions(results.slice(0, 3));
    } else {
      setSuggestions([]);
    }
  };

  const debouncedFetchSuggestions = useDebounce({
    callback: () => fetchSuggestions(inputRef.current.value),
    delay: 100,
  });

  const onInputChange = (event) => {
    setUserType(getUserType(event.target.value));
    debouncedFetchSuggestions();
  };

  const popoverContentRef = useRef();
  const popoverContent = (
    <FlexColumn ref={popoverContentRef} style={{ overflow: 'hidden' }}>
      {suggestions.map((suggestion) => (
        <SuggestionWrapper
          theme={theme}
          key={suggestion.slug}
          onClick={() => {
            inputRef.current.value = suggestion.slug;
            setUserType('user');
            setIsOpen(false);
          }}
        >
          <FlexRow alignItems="center" style={{ overflow: 'hidden' }}>
            <ProfileImage
              user={{
                profileImage: buildProfileImageUrl(suggestion.profileImage),
                name: suggestion.name,
              }}
              theme={{ size: '30px' }}
            />
            <Spacer w="6px" />
            <FlexColumn style={{ overflow: 'hidden' }}>
              <H5 noTextOverflow style={{ color: themeStyle.varInkMain }}>
                {suggestion.name}
              </H5>
              <P4 noTextOverflow color={themeStyle.varInkMedium}>
                @{suggestion.slug}
              </P4>
            </FlexColumn>
          </FlexRow>
        </SuggestionWrapper>
      ))}
    </FlexColumn>
  );

  const handleSubmit = (value) => {
    if (userType) {
      setSuggestions([]);
      shouldPopulateSuggestions.current = false;
      props.onSend({
        [userType]: value,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event.target.value);
    }
  };

  const handleFocus = () => {
    if (suggestions.length) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    setIsOpen(!!suggestions.length);
  }, [suggestions.length]);

  useEffect(() => {
    setUserType(getUserType(inputRef.current.value));
  }, [getUserType]);

  return (
    <Popover
      open={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
      onWillClose={() => setIsOpen(false)}
      content={popoverContent}
      align="start"
      showHeader={false}
      hideTip
      nonToggleable
      padding={0}
      theme={{
        padding: '0px',
      }}
      container={props.container}
      width="376px"
    >
      <Wrapper theme={theme}>
        <Input
          onChange={onInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          theme={theme}
          placeholder="Insert user name or email address"
          ref={inputRef}
        />
        <Button
          theme={primarySmallTheme}
          label="Send Link"
          width="100px"
          height="30px"
          disabled={userType === null}
          onClick={() => handleSubmit(inputRef.current.value)}
        />
      </Wrapper>
    </Popover>
  );
};

UserSearch.propTypes = {
  theme: PropTypes.object,
  onSend: PropTypes.func,
  container: PropTypes.object,
};

export default UserSearch;
