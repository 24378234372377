import { transformPoints } from '../../../utils/editor/points';
import { PositionData, WidgetPosition } from '../types';
import fabric from '../../Artboard/fabric';

export const defaultPositioner = (
  positionData: PositionData,
  boundingBox: DOMRect
): WidgetPosition => {
  const { topline, bottomline, angle } = positionData;

  const toplineMidPoint = {
    x: (topline.o.x + topline.d.x) / 2,
    y: (topline.o.y + topline.d.y) / 2,
  };

  let objectCorners = [topline.o, topline.d, bottomline.o, bottomline.d];

  const { width, height } = boundingBox;

  let buttonCorners = [
    {
      x: toplineMidPoint.x - width / 2,
      y: toplineMidPoint.y - height / 2,
    },
    {
      x: toplineMidPoint.x + width / 2,
      y: toplineMidPoint.y - height / 2,
    },
    {
      x: toplineMidPoint.x - width / 2,
      y: toplineMidPoint.y + height / 2,
    },
    {
      x: toplineMidPoint.x + width / 2,
      y: toplineMidPoint.y + height / 2,
    },
  ];

  const rotation = fabric.util.calcRotateMatrix({ angle: angle });
  const invertedRotation = fabric.util.invertTransform(rotation);

  objectCorners = transformPoints(objectCorners, invertedRotation);
  buttonCorners = transformPoints(buttonCorners, invertedRotation);

  const buttonCornersAllY = buttonCorners.map((p) => p.y);
  const aCoordsAllY = objectCorners.map((p) => p.y);
  const buttonCornersLowest = Math.max(...buttonCornersAllY);
  const aCoordsTop = Math.min(...aCoordsAllY);
  const dist = aCoordsTop - buttonCornersLowest;

  buttonCorners = buttonCorners.map((p) => ({ x: p.x, y: p.y + dist - 25 }));
  buttonCorners = transformPoints(buttonCorners, rotation);

  const buttonCenter = buttonCorners.reduce((acc, p) => ({
    x: acc.x + p.x,
    y: acc.y + p.y,
  }));

  buttonCenter.x /= buttonCorners.length;
  buttonCenter.y /= buttonCorners.length;

  return {
    translateX: buttonCenter.x,
    translateY: buttonCenter.y,
    angle: 0,
  };
};
