import { themeStyle } from '../../../services/theming';
import { settingsStore } from '../../../stores/settingsStore';
import { getArtboardBorderColor } from '../../../utils/editor/theme';

const GRID_THICKNESS = 1;

export default function (fabric) {
  /**
   * Artboard class
   * This class handles the artboard in the canvas, mainly the grid
   * @class fabric.Artboard
   * @extends fabric.Rect
   */
  fabric.Artboard = fabric.util.createClass(fabric.Rect, {
    type: 'artboard',

    initialize: function (options) {
      options || (options = {});
      options.showGrid = false;
      options.gridSize = 50;
      options.snappingEnabled = true;

      this.callSuper('initialize', options);
      this.set('originX', 'left');
      this.set('originY', 'top');
      this.set('hasControls', false);
      this.set('lockMovementX', true);
      this.set('lockMovementY', true);
      this.set('excludeFromExport', true);
      this.set('borderThickness', 1);
      this.set('hoverCursor', 'default');
    },

    /**
     * do not hide when opacity is 0
     */
    isNotVisible: function () {
      return false;
    },

    renderBorder: function (ctx) {
      ctx.save();

      const vpt = this.canvas.viewportTransform;
      ctx.transform(vpt[0], vpt[1], vpt[2], vpt[3], vpt[4], vpt[5]);

      const zoom = this.canvas.getZoom();

      const pixelBorderThickness = this.borderThickness / zoom;
      ctx.strokeStyle = getArtboardBorderColor();
      ctx.lineWidth = pixelBorderThickness;

      ctx.strokeRect(
        -pixelBorderThickness / 2,
        -pixelBorderThickness / 2,
        this.width + pixelBorderThickness,
        this.height + pixelBorderThickness
      );
      ctx.restore();
    },

    /**
     * render a background, that is visible when the artboard has opacity
     */
    renderTransparencyGrid(ctx) {
      ctx.save();
      ctx.globalAlpha = 1;

      const height = this.height;
      const width = this.width;
      if (!this.hideTransparencyGrid) {
        const squareSize = 15;
        let indexI = 0;
        ctx.fillStyle = themeStyle.inkLight;
        for (let i = -width / 2; i < width / 2; i += squareSize) {
          let indexJ = 0;
          for (let j = -height / 2; j < height / 2; j += squareSize) {
            if ((indexI + indexJ) % 2 === 0)
              ctx.fillRect(
                i,
                j,
                Math.min(squareSize, width - (i + width / 2)),
                Math.min(squareSize, height - (j + height / 2))
              );
            indexJ++;
          }
          indexI++;
        }
      }

      ctx.fillStyle = themeStyle.backgroundAlt;
      ctx.fillRect(-width / 2, -height / 2, width, height);

      ctx.restore();
    },

    // Renders horizontal grid lines
    renderLineGrid({ ctx, gridSize, width, height, zoom }) {
      ctx.lineWidth = GRID_THICKNESS / zoom;
      ctx.strokeStyle = themeStyle.inkLight;

      const top = 0;
      const bottom = height;
      const left = 0;
      const right = width;
      for (let i = top + gridSize; i <= bottom; i += gridSize) {
        ctx.beginPath();
        ctx.moveTo(left, i);
        ctx.lineTo(right, i);
        ctx.stroke();
      }
      // vertical grid lines
      for (let j = left + gridSize; j <= right; j += gridSize) {
        ctx.beginPath();
        ctx.moveTo(j, top);
        ctx.lineTo(j, bottom);
        ctx.stroke();
      }
    },

    renderGrid: function (ctx) {
      const { gridSize, showGrid } = settingsStore.getState().settings;

      if (!gridSize || !showGrid) return;

      const width = this.get('width');
      const height = this.get('height');

      const zoom = this.canvas ? this.canvas.getZoom() : 1;

      // Don't render grid, when nothing can be seen behind it
      if ((GRID_THICKNESS * 8) / zoom > gridSize) return;

      ctx.save();

      // This allows us to remove shadow from grid lines
      this._removeShadow(ctx);

      this.renderLineGrid({
        ctx,
        gridSize,
        width,
        height,
        zoom,
      });

      ctx.restore();
    },
  });
}
