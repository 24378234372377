import React, { useEffect, useRef } from 'react';

import { Board } from './style';
import { updateCanvasSize } from '../../utils/editor/misc';
import fabric from '../Artboard/fabric';
import { MockupTemplateBoardProps } from './types';
import { MockupTemplateLayer } from '../../stores/mockupTemplateStore/types';
import { useEventSubscriptions } from '../../hooks/mockup/useEventSubscriptions';
import { useResizeHandler } from '../../hooks/mockup/useResizeHandler';
import { useBlurHandler } from '../../hooks/mockup/useBlurHandler';
import { useLayerEffect } from './hooks/useLayerEffect';
import { useCanvasEventHandlers } from './hooks/useCanvasEventHandlers';
import { useCanvasCreated } from './hooks/useCanvasCreated';
import { useHotkeys } from '../../hooks/mockup/useHotkeys';
import { useWarpGridEffect } from './hooks/useWarpGridEffect';
import { handleEventBusEvents } from './utils/handleEventBusEvents';
import useHistory from '../../hooks/useHistory';
import { mockupTemplateDimension } from './utils';
import { useThemeChanged } from '../../hooks/useThemeChanged';

const MockupTemplateBoard: React.FC<MockupTemplateBoardProps> = ({
  dispatch,
  bus,
}) => {
  const boardElRef = useRef<HTMLDivElement | null>(null);
  const canvasElRef = useRef<HTMLCanvasElement | null>(null);
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const setCanvasCreated = useCanvasCreated();
  const { historyRef } = useHistory(bus, dispatch);

  // init fabric canvas
  useEffect(() => {
    if (canvasRef.current || !canvasElRef.current || !boardElRef.current)
      return;

    const options = {
      artboardOptions: {
        width: mockupTemplateDimension.width,
        height: mockupTemplateDimension.height,
        fill: 'transparent',
      },
    };
    canvasRef.current = new fabric.Canvas(canvasElRef.current, options);
    updateCanvasSize(boardElRef.current, canvasRef.current);
    setCanvasCreated();
  });

  // handle hot keys
  useHotkeys(canvasRef.current, dispatch);

  // handle events from fabric canvas
  useCanvasEventHandlers(canvasRef.current, historyRef.current, dispatch);

  // subscribe to event bus
  useEventSubscriptions(canvasRef.current, bus, handleEventBusEvents);

  useResizeHandler(boardElRef.current, canvasRef.current);
  useBlurHandler(dispatch);

  useLayerEffect(canvasRef.current, MockupTemplateLayer.SceneLayer);
  useLayerEffect(canvasRef.current, MockupTemplateLayer.LightBlendLayer);
  useLayerEffect(canvasRef.current, MockupTemplateLayer.DarkBlendLayer);

  useWarpGridEffect(canvasRef.current, dispatch);

  useThemeChanged(canvasRef.current);

  return (
    <Board ref={boardElRef}>
      <canvas ref={canvasElRef} />
    </Board>
  );
};

export default React.memo(MockupTemplateBoard);
