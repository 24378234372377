import { useEffect, useState } from 'react';

import { SubscriptionPlan, SubscriptionPlanInterval } from '../../../types';

type MonthlyFee = number | null;

/**
 * This hook gets the monthly fee given the subscription plan and interval.
 * @param plan
 * @param interval
 */
export const useMonthlyFee = (
  plan: SubscriptionPlan | null,
  interval: SubscriptionPlanInterval | null
): MonthlyFee => {
  const [monthlyFee, setMonthlyFee] = useState<MonthlyFee>(null);

  // TODO: check if we have experiments with different prices
  useEffect(() => {
    if (!plan || !interval) {
      setMonthlyFee(null);
    } else if (
      plan === SubscriptionPlan.PRO &&
      interval === SubscriptionPlanInterval.YEAR
    ) {
      setMonthlyFee(10);
    } else if (
      plan === SubscriptionPlan.PRO &&
      interval === SubscriptionPlanInterval.MONTH
    ) {
      setMonthlyFee(15);
    } else if (
      plan === SubscriptionPlan.EXPERT &&
      interval === SubscriptionPlanInterval.YEAR
    ) {
      setMonthlyFee(24);
    } else if (
      plan === SubscriptionPlan.EXPERT &&
      interval === SubscriptionPlanInterval.MONTH
    ) {
      setMonthlyFee(30);
    }
  }, [plan, interval]);

  return monthlyFee;
};
