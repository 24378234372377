import React from 'react';
import PropTypes from 'prop-types';

import { DropIndicatorStyle } from '../styles';

import { defaultTheme } from '../theme';

/**
 * Line below the DropTarget.
 * Code taken from https://github.com/react-component/tree/blob/5d1e71a3b1e8843558f5cfde5c91b11632e62fdd/src/DropIndicator.tsx
 * Added DropIndicatorStyle
 */
const DropIndicator = (props) => {
  const theme = { ...defaultTheme, ...props.theme };

  const { dropPosition, dropLevelOffset, indent } = props;
  const style = {};
  switch (dropPosition) {
    case -1:
      style.top = 0;
      style.left = -dropLevelOffset * indent;
      break;
    case 1:
      style.bottom = 0;
      style.left = -dropLevelOffset * indent;
      break;
    default:
      style.bottom = 0;
      style.left = indent;
      break;
  }

  return <DropIndicatorStyle style={style} {...theme} />;
};

DropIndicator.propTypes = {
  /**
   * dropPosition
   */
  dropPosition: PropTypes.oneOf([-1, 0, 1]),
  /**
   * dropLevelOffset
   */
  dropLevelOffset: PropTypes.number,
  /**
   * Indentation of DropIndicator
   */
  indent: PropTypes.number,
  /**
   * DropIndicator Theme
   */
  theme: PropTypes.shape({}),
};

export default DropIndicator;
