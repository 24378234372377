import React, { useCallback } from 'react';
import { GenericNotification, NotificationType } from '../../../../types';

import { buildImageProxyUrl, WEBSITE_URL } from '../../../../utils/url';
import NotificationHeader from '../NotificationHeader/NotificationHeader';
import PreviewImage from '../PreviewImage/PreviewImage';
import { NotificationWrapper, DesignContent, DesignText } from './styles';
import { NotificationProps } from './types';

const DesignNotification: React.FC<
  NotificationProps<
    GenericNotification<
      NotificationType,
      {
        path: string;
        templatePreviewObjectName?: string;
      }
    >
  > & {
    headline: string;
    text?: string;
    link?: string;
    previewImage?: string;
  }
> = ({
  notification,
  headline,
  text,
  previewImage,
  link,
  onReadNotification,
}) => {
  const handleClick = useCallback((): void => {
    onReadNotification(notification.id);

    if (link) {
      window.open(link, '_blank');
      return;
    }
    window.open(
      `${WEBSITE_URL || ''}/${notification.data.path}`,
      WEBSITE_URL ? '_blank' : '_self'
    );
  }, [onReadNotification, link, notification]);

  const image = previewImage || notification.data?.templatePreviewObjectName;
  if (!image) return null;

  return (
    <NotificationWrapper role="button" onClick={handleClick}>
      <NotificationHeader
        user={notification.relatedUser}
        message={headline}
        createdAt={notification.createdAt}
        isUnseen={!notification.seenAt}
      />
      <DesignContent>
        <PreviewImage
          noMargin
          src={buildImageProxyUrl(image, {
            height: 70,
            width: 0,
            scale: 2,
          })}
        />
        {text && <DesignText>{text}</DesignText>}
      </DesignContent>
    </NotificationWrapper>
  );
};

export default DesignNotification;
