import tinycolor from 'tinycolor2';
import fabric from '../../components/Artboard/fabric';
import { useMenuStore } from '../../stores/menuStore';
import {
  MAX_ZOOM,
  MIN_ZOOM,
  ZOOM_STEPS,
  TYPE_ACTIVE_SELECTION,
  COLOR_PICKER_URL,
} from '../../global/constants';
import { updateArtboardPosition } from '../../stores/onboardingStore';
import { Dispatcher } from '../../types';
import { ZOOM_TO } from '../../global/events';

/**
 * Here is a set of utils for handling events that behave similarly in different modes,
 * i.e default, mockup, etc?.
 *
 * This way we avoid repeating code for identical, or almost identical events.
 * HOWEVER: be mindful when modifying these functions that they should not break in
 * any of the modes.
 *
 * Ideally, these functions don't rely on custom properties introduced by us, and
 * display the same behavior no matter what mode they are being called for.
 */

export const handleZoom = (canvas: fabric.Canvas, zoom: string): void => {
  canvas.zoomToPoint(
    {
      x: canvas.width / 2,
      y: canvas.height / 2,
    },
    canvas.adjustZoom(parseFloat(zoom))
  );
  useMenuStore.setState({ zoom: canvas.getZoom() });
};

export const handleZoomTo = (
  canvas: fabric.Canvas,
  { pos, zoom }: { pos: { x: number; y: number }; zoom: number }
): void => {
  canvas.zoomToPoint(pos, canvas.adjustZoom(zoom));
};

export const handleZoomStep = (canvas: fabric.Canvas, step: 1 | -1): void => {
  const zoomIn = step > 0; // value specifies the direction to zoom in
  const currentZoom = Number(parseFloat(canvas.getZoom()).toFixed(2));
  const nextZoom = zoomIn
    ? ZOOM_STEPS.find((val: number) => val > currentZoom)
    : ZOOM_STEPS.slice()
        .reverse()
        .find((val: number) => val < currentZoom);
  // if current zoom is at or outside boundaries we fallback to them
  const newZoom = nextZoom ? nextZoom : zoomIn ? MAX_ZOOM : MIN_ZOOM;
  canvas.zoomToPoint(
    {
      x: canvas.width / 2,
      y: canvas.height / 2,
    },
    canvas.adjustZoom(newZoom)
  );
  updateArtboardPosition(
    canvas.artboard,
    canvas.getZoom(),
    canvas.viewportTransform
  );
  useMenuStore.setState({ zoom: canvas.getZoom() });
};

const handlePinchZoom = (
  canvas: fabric.Canvas,
  { delta, pos }: { delta: number; pos: { x: number; y: number } },
  dispatch: Dispatcher
): void => {
  const currentZoom = canvas.getZoom();
  const zoomFactor =
    Math.log2(currentZoom + 1 - MIN_ZOOM) / Math.log2(MAX_ZOOM + 1 - MIN_ZOOM);

  const ZOOM_MULTIPLIER = 0.025;
  const zoom =
    canvas.getZoom() -
    delta * ZOOM_MULTIPLIER * Math.sqrt(zoomFactor + MIN_ZOOM);

  dispatch(ZOOM_TO, {
    zoom: zoom,
    pos,
  });
  // correct viewport position, if the artboard would not be in the view after zoom
  canvas.resetViewport();
};

export const handleWheelEvent = (
  canvas: fabric.Canvas,
  event: WheelEvent,
  dispatch: Dispatcher
): void => {
  // e.ctrlKey is true while pinching on trackpad
  if (canvas.zoomOnWheel || event.ctrlKey) {
    handlePinchZoom(
      canvas,
      {
        delta: event.deltaY,
        pos: {
          x: event.offsetX,
          y: event.offsetY,
        },
      },
      dispatch
    );
  } else {
    // Move viewport by scroll
    const vpt = canvas.viewportTransform;

    // calculate the new viewport position
    const SCROLL_MULTIPLIER = 2;
    const newPosX = vpt[4] - event.deltaX * SCROLL_MULTIPLIER;
    const newPosY = vpt[5] - event.deltaY * SCROLL_MULTIPLIER;
    // set the new viewport pos
    canvas.setViewportPos(newPosX, newPosY);
    canvas.requestRenderAll();
  }

  updateArtboardPosition(
    canvas.artboard,
    canvas.getZoom(),
    canvas.viewportTransform
  );

  event.preventDefault();
  event.stopPropagation();
};

export const handleZoomCenter = (canvas: fabric.Canvas): void => {
  canvas.centerArtboard();
  useMenuStore.setState({ zoom: canvas.getZoom() });
};

export const handleEnableZoomOnWheel = (
  canvas: fabric.Canvas,
  value: boolean
): void => {
  canvas.set('zoomOnWheel', value);
};

export const handleEnableDragging = (
  canvas: fabric.Canvas,
  value: boolean
): void => {
  // disable dragging while moving objects or editing text
  if (!canvas.hasMovingObject && !canvas.activeTextEdit) {
    canvas.set('draggingEnabled', value);
    canvas.set('disableSelection', value);
  }
};

export const handleActiveMoveBy = (
  canvas: fabric.Canvas,
  moveBy: { x: number; y: number }
): void => {
  const activeObject = canvas.getActiveObject();

  if (
    activeObject &&
    !canvas.shouldLockSelection() &&
    activeObject.type !== 'artboard'
  ) {
    const newPos = {
      left: activeObject.left + moveBy.x,
      top: activeObject.top + moveBy.y,
    };
    activeObject.set(newPos);
    if (activeObject.type === TYPE_ACTIVE_SELECTION) {
      canvas.getActiveObjects().forEach((object: fabric.Object) => {
        object.handleOnMove();
      });
    } else {
      activeObject.handleOnMove();
    }
    canvas.fire('object:modified', {
      target: activeObject,
      action: 'drag',
    });
  }
};

export const handleSetState = (
  canvas: fabric.Canvas,
  {
    isHistoryChange,
    state: newState,
  }: {
    isHistoryChange: boolean;
    state: JSON;
  }
): void => {
  const selectedStructureIds = canvas._selectedElements;
  const reselectElements =
    isHistoryChange && selectedStructureIds?.length
      ? (canvas: fabric.Canvas): void => {
          canvas.selectIds(selectedStructureIds);
          canvas.fire('object:modified');
        }
      : (): void => canvas.fire('object:modified');

  canvas.loadFromJSON(newState, reselectElements);
};

export const handlePickColor = (
  pickEvent: React.MouseEvent,
  canvas: fabric.Canvas,
  cb: (color: string) => void
): void => {
  const { target: pickTarget } = pickEvent;
  const lowerCanvas = canvas.lowerCanvasEl;
  const upperCanvas = canvas.upperCanvasEl;

  if (pickTarget !== upperCanvas && pickTarget !== lowerCanvas) return;

  const getColor = (
    x: number,
    y: number,
    canvas: HTMLCanvasElement
  ): Uint8ClampedArray => {
    const context = canvas.getContext('2d')!;
    const color = context.getImageData(x, y, 1, 1).data;
    return color;
  };

  let { clientX: pickedX, clientY: pickedY } = pickEvent;
  const devicePixelRatio = window.devicePixelRatio;
  pickedX *= devicePixelRatio;
  pickedY *= devicePixelRatio;

  let pickedColor: string | Uint8ClampedArray = getColor(
    pickedX,
    pickedY,
    upperCanvas
  );

  if (!pickedColor[3]) {
    pickedColor = getColor(pickedX, pickedY, lowerCanvas);
  }

  pickedColor = tinycolor({
    r: pickedColor[0],
    g: pickedColor[1],
    b: pickedColor[2],
    a: pickedColor[3],
  }).toHex();

  cb && cb(pickedColor);
};

export const handleColorPicking = (
  value: boolean,
  canvas: fabric.Canvas
): void => {
  if (!canvas) return;
  canvas.isColorPicking = value;
  if (canvas.isColorPicking) {
    canvas.upperCanvasEl.style.cursor = `url(${COLOR_PICKER_URL}) 0 16, pointer`;
  } else {
    canvas.upperCanvasEl.style.cursor = 'default';
  }
};
