import fabric from '../components/Artboard/fabric';

export interface LineCoordinates {
  topline: {
    o: fabric.Point;
    d: fabric.Point;
  };
  rightline: {
    o: fabric.Point;
    d: fabric.Point;
  };
  bottomline: {
    o: fabric.Point;
    d: fabric.Point;
  };
  leftline: {
    o: fabric.Point;
    d: fabric.Point;
  };
}

export enum ObjectType {
  Artboard = 'artboard',
  Illustration = 'illustration',
  IllustrationImage = 'illustrationImage',
  BasicShape = 'basicShape',
  PathText = 'pathText',
  OverlayTexture = 'overlayTexture',
  ActiveSelection = 'activeSelection',
  WarpedImage = 'warpedImage',
}

export type NamedFilter = fabric.Image.filters.BaseFilter & { name: string };

export enum ImageFilterType {
  'Saturation' = 'Saturation',
  'Contrast' = 'Contrast',
  'Brightness' = 'Brightness',
  'RemoveColor' = 'RemoveColor',
  'Vibrance' = 'Vibrance',
  'HueRotation' = 'HueRotation',
  'Noise' = 'Noise',
  'Pixelate' = 'Pixelate',
  'Blur' = 'Blur',
  'Convolute' = 'Convolute',
}
