import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;

  > div {
    margin-bottom: 0.5rem;

    span {
      display: inline-block;
      width: 8rem;
    }

    label {
      margin-right: 1rem;
    }
  }

  .StripeElement {
    margin: 2rem 0;
  }

  button {
    width: 100%;
    height: 3rem;
    margin: 1rem 0;
  }
`;
