import styled from 'styled-components';
import { themeStyle } from '../../services/theming';

export const Input = styled.input`
  height: 100%;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: ${({ theme }) => theme.fontWeight};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.fontColor};
  background-color: ${themeStyle.varBackgroundAlt};
  outline: none !important;
  border: none !important;
`;

export const Wrapper = styled.div`
  width: ${({ theme }) => theme.width};
  height: ${({ theme }) => theme.height};
  background-color: ${themeStyle.varBackgroundAlt};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 5px;
  padding: 8px 11px;
  box-sizing: border-box;
  gap: 10px;
`;

export const SuggestionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${({ theme }) => theme.suggestionHeight};
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid ${themeStyle.varInkSuperLight};
  cursor: pointer;
  &:last-child {
    border: none;
  }
  &:hover {
    background: ${({ theme }) => theme.suggestionHighlightColor};
  }
`;
