import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PanelWrapper, PreviewImage, RightColumn } from './styles';
import { BorderSliderWrapper } from '../styles';
import { FlexRow } from '../../utilities/styles';
import RightMenuHeadline from '../../utilities/RightMenuHeader/RightMenuHeader';
import SliderInput from '../../SliderInput/SliderInput';
import Select from '../../SelectNew/Select';
import Button from '../../Button/Button/Button';
import { buildImageProxyUrl } from '../../../utils/url';
import {
  EDIT_TEXTURE,
  DELETE_TEXTURE,
  RELEASE_TEXTURE,
  OVERLAY_CLIP,
} from '../../../global/events';
import { defaultTheme } from './theme';
import { overlayModes } from '../../../global/constants';
import Spacer from '../../Spacer/Spacer';
import Switch from '../../Switch/Switch';
import themeStyle from '../../../global/themeStyle';
import { P3 } from '../../utilities/Typography/styles';
import { secondarySmallTheme } from '../../Button/Button/theme';

/**
 * The TexturePanel
 */
const TexturePanel = (props) => {
  const dispatchOption = (event, value, isChanging) => {
    props.dispatch &&
      props.dispatch(event, {
        ...value,
        isChanging,
      });
  };

  const switchTheme = {
    colorTrack: themeStyle.black,
    padding: '2px',
  };

  const [clipContent, setClipContent] = useState(props.options.renderClip);
  const onClipContent = (value) => {
    setClipContent(value);
    dispatchOption(OVERLAY_CLIP, { renderClip: value });
  };

  return (
    <>
      <RightMenuHeadline label={'Texture'} />
      <PanelWrapper theme={defaultTheme}>
        <FlexRow>
          <PreviewImage
            src={buildImageProxyUrl(props.options.texture)}
            theme={defaultTheme}
          />
          <RightColumn>
            <BorderSliderWrapper>
              <SliderInput
                label={'Opacity'}
                startValue={props.options.opacity}
                precision={1}
                step={0.1}
                min={0}
                max={100}
                onChanging={(opacity) => {
                  dispatchOption(EDIT_TEXTURE, { opacity }, true);
                }}
                onChanged={(opacity) =>
                  dispatchOption(EDIT_TEXTURE, { opacity }, false)
                }
                dataTestId="opacity"
              />
            </BorderSliderWrapper>
            <FlexRow justifyContent={'space-between'} width="100%">
              <Select
                small
                options={overlayModes}
                activeOption={overlayModes.find(
                  (mode) => props.options.mode === mode.id
                )}
                onSelect={(option) =>
                  dispatchOption(EDIT_TEXTURE, {
                    mode: option.id,
                  })
                }
                dataTestId="select-blend-mode"
              />
            </FlexRow>
          </RightColumn>
        </FlexRow>
        <Spacer h="8px" />
        <FlexRow justifyContent="space-between" gap="12px">
          <Button
            theme={secondarySmallTheme}
            width="152px"
            height="30px"
            label={'Release Texture'}
            icon={{ name: 'unlock', height: '12px' }}
            onClick={() => dispatchOption(RELEASE_TEXTURE)}
            disabled={!props.options.loaded}
          />
          <Button
            theme={secondarySmallTheme}
            width="100px"
            height="30px"
            label={'Delete'}
            icon={{ name: 'delete', height: '12px' }}
            onClick={() => dispatchOption(DELETE_TEXTURE)}
          />
        </FlexRow>
        <Spacer h="12px" />
        <FlexRow justifyContent="space-between" alignItems="center">
          <P3>Clip Content</P3>
          <Switch
            checked={clipContent}
            theme={switchTheme}
            onChange={onClipContent}
          />
        </FlexRow>
      </PanelWrapper>
    </>
  );
};

TexturePanel.propTypes = {
  /**
   * current options
   */
  options: PropTypes.shape({
    texture: PropTypes.string,
    opacity: PropTypes.number,
    mode: PropTypes.string,
    renderClip: PropTypes.bool,
    loaded: PropTypes.bool,
  }),
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
};

export default TexturePanel;
