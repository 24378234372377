import { themeStyle } from '../../services/theming';

const defaultTheme = {
  backgroundColor: themeStyle.varBackgroundAlt,
  boxShadow: themeStyle.varShadowMedium,

  borderRadius: themeStyle.radiusLarge,
  zIndex: themeStyle.zIndexZero,
  zIndexTip: themeStyle.zIndexM1,

  hasHeadBorder: true,
};

export { defaultTheme };
