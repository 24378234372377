import React, { useCallback } from 'react';

import { IconTheme, SelectControllerProps } from './types';
import {
  SelectControllerWrapper,
  Label,
  AlertIcon,
  ExpandIcon,
} from './styles';
import Icon from '../Icon/Icon';
import { themeStyle } from '../../services/theming';

const SelectController = React.forwardRef<
  HTMLDivElement,
  SelectControllerProps
>(
  (
    {
      label,
      small = false,
      disabled = false,
      noExpandIcon = false,
      alert = false,
      dropdownExpanded,
      customStyles,
      onClick,
      dataTestId,
    },
    ref
  ) => {
    const iconTheme: IconTheme = {
      color: disabled
        ? themeStyle.varInkLight
        : alert
        ? themeStyle.varAlertRed
        : customStyles?.iconColor ?? themeStyle.varInkMedium,
      activeColor: disabled
        ? themeStyle.varInkLight
        : alert
        ? themeStyle.varAlertRed
        : customStyles?.iconActiveColor ?? themeStyle.varInkMain,
      activeDefaultColor: disabled
        ? themeStyle.varInkLight
        : alert
        ? themeStyle.varAlertRed
        : customStyles?.iconActiveColor ?? themeStyle.varInkMain,
    };

    const handleClick = useCallback(
      (e: React.MouseEvent) => {
        if (!disabled && onClick) onClick(e);
      },
      [disabled, onClick]
    );

    return (
      <SelectControllerWrapper
        disabled={disabled}
        onClick={handleClick}
        dropdownExpanded={dropdownExpanded}
        noExpandIcon={noExpandIcon}
        small={small}
        alert={alert}
        customStyles={customStyles}
        theme={iconTheme}
        ref={ref}
        data-testid={dataTestId}
      >
        <Label data-testid="select-value">{label}</Label>
        {alert && (
          <AlertIcon>
            <Icon
              name="infoThick"
              height={small ? '16px' : '18px'}
              theme={{
                color: themeStyle.varAlertRed,
                secondaryColor: themeStyle.varInkBase,
              }}
            />
          </AlertIcon>
        )}
        {!noExpandIcon && (
          <ExpandIcon
            small={small}
            dropdownExpanded={dropdownExpanded}
            customStyles={customStyles}
          >
            <Icon
              name="arrowUp"
              height={small ? '5px' : '6px'}
              isActive={dropdownExpanded}
              theme={{
                ...iconTheme,
                transition: `all ${themeStyle.transitionDuration}`,
              }}
            />
          </ExpandIcon>
        )}
      </SelectControllerWrapper>
    );
  }
);

export default React.memo(SelectController);
