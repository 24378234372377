import React from 'react';
import PropTypes from 'prop-types';

import { ADD_TEXT, ADD_TEXT_LAYOUT } from '../../../global/events';
import Button from '../../Button/Button/Button';
import { secondaryTheme } from '../../Button/Button/theme';
import BasePanel from '../../LeftMenu/BasePanel/BasePanel';
import ElementsBasePanel from '../ElementBasePanel';
import SortAndSearch from '../SortAndSearch/SortAndSearch';
import { getRenderBaseProps } from '../util';
import { ButtonList } from './styles';
import { useUserStore, userStoreSelector } from '../../../stores/userStore';
import {
  promotionSelector,
  usePromotionStore,
} from '../../../stores/promotionStore';
import Element from '../Element/Element';

const TextLayoutsPanelHeader = (props) => {
  const { dispatch } = props;

  const buttonProps = {
    theme: {
      ...secondaryTheme,
      iconMinWidth: '21px',
    },
    width: '100%',
    isCentered: false,
  };

  return (
    <ButtonList>
      <Button
        {...buttonProps}
        label={'Add Headline'}
        icon={{ name: 't', height: '16px' }}
        onClick={() => dispatch(ADD_TEXT)}
      />
      <Button
        {...buttonProps}
        label={'Add Paragraph'}
        icon={{ name: 'alignLeft', height: '16px' }}
        onClick={() =>
          dispatch(ADD_TEXT, {
            text: 'This is a sample paragraph. Use it to add multiple lines of text for your poster, greeting card, or other design projects which require more information.',
            fontSize: 36,
            fontFamily: 'Roboto',
            fontWeight: 'Regular',
            width: 730,
            textAlignment: 'left',
          })
        }
      />
    </ButtonList>
  );
};

TextLayoutsPanelHeader.propTypes = {
  dispatch: PropTypes.func,
};

const WithoutSortAndSearch = (props) => {
  const { dispatch, transitioning, sort, category, setSelectedCategory } =
    props;
  const proAccess = useUserStore(userStoreSelector.proAccess);
  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );

  const render = (element) => {
    const { elementProps } = getRenderBaseProps(element, 'layouts');

    const onAdd = (val) => {
      if (element.premium && !proAccess) {
        showUpgradeModal('premiumLayoutUsage');
        return;
      }

      dispatch(ADD_TEXT_LAYOUT, val);
    };

    return (
      <Element
        element={elementProps}
        onDrop={onAdd}
        outlineOnHover
        theme={{
          imagePadding: '15px',
        }}
      />
    );
  };

  const headElement = <TextLayoutsPanelHeader {...props} />;
  const bodyElement = (
    <ElementsBasePanel
      {...props}
      render={render}
      elementType="layouts"
      headGalleries={[]}
      hide={transitioning}
      sort={sort?.id}
      category={category}
      onGoBack={() => setSelectedCategory({ id: 'All', label: 'All' })}
    />
  );

  return (
    <BasePanel
      label={'Add Text'}
      onClose={props.onClose}
      headElement={headElement}
      bodyElement={bodyElement}
    />
  );
};

const WithSortAndSearch = (props) => {
  return (
    <SortAndSearch elementType="layouts">
      <WithoutSortAndSearch {...props} />
    </SortAndSearch>
  );
};

const propTypes = {
  dispatch: PropTypes.func,
  transitioning: PropTypes.bool,
  sort: PropTypes.object,
  onClose: PropTypes.func,
  category: PropTypes.object,
  categories: PropTypes.object,
  setSelectedCategory: PropTypes.func,
};

WithoutSortAndSearch.propTypes = propTypes;

WithSortAndSearch.propTypes = propTypes;

export default WithSortAndSearch;
