import styled from 'styled-components';

import { themeStyle } from '../../services/theming';

export const Wrapper = styled.div`
  margin: 20px 0;
  margin-top: ${({ hasBanner }) => (hasBanner ? '40px' : '20px')};
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 24px;

  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
`;

export const LeftButtons = styled.div`
  display: flex;

  button:not(first-child) {
    margin-left: 25px;
  }

  button:first-child {
    margin-left: 40px;
  }
`;

export const LinkToWebsite = styled.a`
  display: inline-block;
  height: 36px;
  cursor: pointer;
  pointer-events: auto;
`;

export const MiddleButtons = styled.div`
  position: absolute;
  width: calc(100% - 48px); /* width - horizontal padding */
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 300px 0 135px;

  @media (min-width: 1280px) {
    padding: 0;
  }
`;

export const RightButtons = styled.div`
  pointer-events: auto;
  display: flex;
  align-items: center;

  button {
    pointer-events: unset;
  }
`;

export const ToolsWrapper = styled.div`
  box-sizing: border-box;
  height: 40px;
  padding: 10px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${themeStyle.varBackgroundAlt};
  border-radius: ${themeStyle.radiusMedium};
  box-shadow: ${themeStyle.varShadowMedium};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  button {
    width: 24px;
    margin: 0 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;

export const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;
  border-right: 1px solid ${themeStyle.varInkSuperLight};
`;
