import { nanoid } from 'nanoid';
import fabric from '.';
import {
  cleanStructure,
  createGroupInStructure,
  getParent,
  moveElementsInStructure,
  removeEmptyGroups,
} from '../../../utils/groupStructure';
import { findMaskTarget } from '../../../utils/masking';

const createObject = async (
  type: string,
  objectName: string,
  options = {}
): Promise<fabric.Object> => {
  const addOptions = { ...options, excludeFromExport: true };
  return new Promise((resolve) => {
    if (type === 'basicShape') {
      new fabric.BasicShape(objectName, addOptions, resolve);
    } else if (objectName.endsWith('.svg')) {
      new fabric.Illustration(objectName, addOptions, resolve);
    } else {
      new fabric.IllustrationImage(objectName, addOptions, resolve);
    }
  });
};

export default function (fabric: fabric): void {
  /**
   * extends the canvas with masking functionality for objects
   * this is currently used to handle mask previews when dragging or moving an object
   */
  fabric.util.object.extend(fabric.StaticCanvas.prototype, {
    /**
     * while an element is dragged onto the canvas, its object name is stored here
     */
    draggingObjectName: null,
    /**
     * this is a reference to the preview object that was created while dragging an element
     */
    draggingObject: null,
    /**
     * this is the current target, over which the element is dragged
     */
    draggingObjectTarget: null,

    /**
     * when an element is dragged onto/over the canvas, a preview object for it is
     * created and stored.
     */
    onStartDraggingElement: async function (
      type: string,
      objectName: string,
      options = {}
    ) {
      if (type === 'mask') return;
      if (this.draggingObjectName === objectName) return;
      if (
        !this._objects.find(({ type }: { type: string }) => type === 'mask')
      ) {
        // don't do anything if there aren't any masks on the canvas
        return;
      }

      // create object and store a reference in canvas
      this.draggingObjectName = objectName;
      const object = await createObject(type, objectName, options);
      // in case another drag started, break
      if (this.draggingObjectName !== objectName) return;
      this.draggingObject = object;
    },

    /**
     * while an element is being dragged, its preview can be used for masking
     * this is searching for possible mask targets and lets them know what is happening
     */
    onDraggingElement: function (position: { dropX?: number; dropY: number }) {
      if (!this.draggingObject) return;
      // find possible mask target based on mouse position
      const target = findMaskTarget(position, this);
      if (this.draggingObjectTarget && this.draggingObjectTarget !== target) {
        // if target changes, tell the previous target
        this.draggingObjectTarget.handleDragOut();
      }
      this.draggingObjectTarget = target;
      if (target) {
        target.handleDragOver();
      }
    },

    /**
     * when the dragging is stopped, the preview data is cleaned up
     */
    onStopDraggingElement: function () {
      if (this.draggingObjectTarget) {
        this.draggingObjectTarget.handleDragOut();
        this.draggingObjectTarget = null;
        this.fire('object:modified', { action: 'drag' });
      }
      this.draggingObjectName = null;
      this.draggingObject = null;
    },

    /**
     * create group for masking
     */
    createClippingMaskStructure(object: fabric.Object, mask: fabric.Object) {
      this.discardActiveObject(); // reset selection
      let currentGroupStructure = this.groupStructure;

      const maskParent = getParent(currentGroupStructure, mask.id);
      // move the object next to the mask, to prepare it for grouping
      if (maskParent) {
        currentGroupStructure = moveElementsInStructure(
          currentGroupStructure,
          [object.id],
          maskParent.id ?? 'canvas',
          maskParent.children?.findIndex((child) => child.id === mask?.id) ?? 0
        );
        currentGroupStructure = removeEmptyGroups(
          cleanStructure(currentGroupStructure)
        );
      }

      const maskId = nanoid();
      this.groupStructure = createGroupInStructure(
        currentGroupStructure,
        [object.id, mask.id],
        maskId,
        { name: 'Mask Group', type: 'clippingMask' }
      );
      this.adjustObjectsIndexToStructure(); // move objects to group index
      this.updateGroupStructure();
      this.fire('selection:updated'); // update UI
      this.requestRenderAll();
    },
  });
}
