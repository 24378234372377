import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useUserStore, userStoreSelector } from '../../../stores/userStore';
import { BodyWrapper, DesignsPanelWrapper, ProjectLimit } from './styles';
import Button from '../../Button/Button/Button';
import { primaryTheme } from '../../Button/Button/theme';
import Designs from './Designs/Designs';
import BasePanel from '../BasePanel/BasePanel';
import { DESIGN_NEW } from '../../../global/events';
import useDesignsStore, {
  designsStoreSelector,
} from '../../../stores/designsStore';
import { H4, P1, P3 } from '../../utilities/Typography/styles';
import { buildWebsiteUrl } from '../../../utils/url';

import analytics from '../../../global/analytics';
import {
  PROJECTLIMIT_PANEL,
  PROJECTLIMIT_PANEL_CLICK,
} from '../../../global/analytics/events';
import Icon from '../../Icon/Icon';
import Spacer from '../../Spacer/Spacer';
import { FlexRow } from '../../utilities/styles';
import { themeStyle } from '../../../services/theming';

const DesignsMenu = (props) => {
  const registerBeforeLoginAction = useUserStore(
    (state) => state.registerBeforeLoginAction
  );
  const resetDesigns = useDesignsStore(designsStoreSelector.reset);
  useEffect(() => {
    registerBeforeLoginAction(resetDesigns);
  }, [registerBeforeLoginAction, resetDesigns]);

  const user = useUserStore(userStoreSelector.user);
  const authenticated = !!user;

  const canUserCreateDesign = useDesignsStore(
    designsStoreSelector.canUserCreateDesign
  );

  useEffect(() => {
    if (user?.designsLimitReached) {
      analytics.track(PROJECTLIMIT_PANEL);
    }
  }, [user?.designsLimitReached]);

  const createNewProject = async () => {
    if (await canUserCreateDesign()) {
      props.dispatch(DESIGN_NEW);
    }
  };

  const head = (
    <Button
      theme={primaryTheme}
      width="100%"
      isCentered={false}
      disabled={!authenticated}
      label={'New Project'}
      icon={{ name: 'create' }}
      onClick={createNewProject}
      dataTestId="new-project-button"
    />
  );

  const body = (
    <BodyWrapper>
      <DesignsPanelWrapper>
        <Designs {...props} elementType="designs" />
      </DesignsPanelWrapper>
      {user?.designsLimitReached && (
        <ProjectLimit>
          <Icon
            name="lightning"
            height="29px"
            theme={{ color: themeStyle.varBrandMedium }}
          />
          <Spacer h="16px" />
          <H4>Unlock More Projects</H4>
          <P1>
            {user?.designsCount} / {user?.designsLimit} projects used
          </P1>
          <Spacer h="16px" />
          <P3>
            Earn 1 more project for each invited friend or upgrade for unlimited
            projects.
          </P3>
          <Spacer h="24px" />
          <FlexRow justifyContent="space-around" width="100%">
            <Button
              label={'Invite Friends'}
              onClick={() => {
                analytics.track(PROJECTLIMIT_PANEL_CLICK, {
                  label: 'inviteFriends',
                });
                window.location.href = buildWebsiteUrl(
                  '/user/settings/referral'
                );
              }}
              height={'40px'}
              width={'130px'}
              theme={primaryTheme}
            />
            <Button
              label={'Upgrade'}
              onClick={() => {
                analytics.track(PROJECTLIMIT_PANEL_CLICK, {
                  label: 'subscription',
                });
                window.location.href = buildWebsiteUrl(
                  '/user/settings/subscription'
                );
              }}
              height={'40px'}
              width={'100px'}
              theme={primaryTheme}
            />
          </FlexRow>
        </ProjectLimit>
      )}
    </BodyWrapper>
  );

  return (
    <BasePanel
      label="My Projects"
      onClose={props.onClose}
      headElement={head}
      bodyElement={body}
    />
  );
};

DesignsMenu.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
};

export default DesignsMenu;
