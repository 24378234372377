import { useState } from 'react';
import PropTypes from 'prop-types';

const StateProvider = ({ context, initialState, children }) => {
  const [state, setState] = useState(initialState);
  return (
    <context.Provider value={[state, setState]}>{children}</context.Provider>
  );
};

StateProvider.propTypes = {
  context: PropTypes.object,
  initialState: PropTypes.any,
  children: PropTypes.node,
};

export default StateProvider;
