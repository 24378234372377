import styled from 'styled-components';

export const BREAKPOINTS = {
  tabletMin: 550,
  largeTabletMin: 800,
  laptopMin: 1100,
  desktopMin: 1500,
  largeDesktopMin: 1800,
};
export const QUERIES = {
  tabletAndUp: `@media (min-width: ${BREAKPOINTS.tabletMin}px)`,
  largeTabletAndUp: `@media (min-width: ${BREAKPOINTS.largeTabletMin}px)`,
  laptopAndUp: `@media (min-width: ${BREAKPOINTS.laptopMin}px)`,
  desktopAndUp: `@media (min-width: ${BREAKPOINTS.desktopMin}px)`,
  largeDesktopAndUp: `@media (min-width: ${BREAKPOINTS.largeDesktopMin}px)`,
};

export const HideSmallMobile = styled.div`
  display: none;

  ${QUERIES.tabletAndUp} {
    display: flex;
  }
`;

export const HideMobile = styled.div`
  display: none;

  ${QUERIES.largeTabletAndUp} {
    display: ${({ display }) => display || 'block'};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`;

export const HideDesktop = styled.div`
  display: ${({ display }) => display || 'block'};
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  ${QUERIES.largeTabletAndUp} {
    display: none;
  }
`;
