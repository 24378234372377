import paper from '@kittl/paper';
import { PaperOffset } from '../../../offset';
import { fixPathOrientation } from '../../../../../../font/util';
import {
  calcOffset,
  degToRad,
  getTimesWithTangent,
} from '../../../../../../utils/editor/angle';

const ERROR_MARGIN = 0.01;

paper.setup();

const OFFSET_PARAMS = {
  limit: 2.6, // NOTE: works for all our fonts (as of March 2021), raising this value can cause some letters (e.g. `H` and `K` in Brillon and `r` in Mogan to stop working with 'detail' shadow.
  join: 'round', // NOTE: `miter` generally looks better, but with lower limit imposed by the note above `round` seems to be preferable
  insert: false,
  skipDirectionCheck: true, // NOTE: direction checking was causing problems with free line transform
};

const applyShadow = (path, shadowOptions, fontSize) => {
  // set lineWidth to 2% of font-size
  const adjustedStroke = fontSize * 0.02;
  // normalize offset between 0% and 200% of font size (not exactly)
  // we do a special case for -1, to continue supporting old values
  const adjustedOffset =
    shadowOptions.offset === -1
      ? 0
      : (shadowOptions.offset / 100) * (fontSize * 1.99) + fontSize * 0.01;

  switch (shadowOptions.type) {
    case 'line':
      // normalize line offset between 1% and 6% of font size
      const adjustedLineOffset =
        (shadowOptions.offset / 100) * (fontSize * 0.05) + fontSize * 0.01;
      return doLineShadow(
        path,
        shadowOptions.angle,
        adjustedLineOffset,
        adjustedStroke
      );
    case 'block':
      return doLongShadow(path, shadowOptions.angle, adjustedOffset);
    case 'detail':
      return doDetailShadow(
        path,
        shadowOptions.angle,
        adjustedOffset,
        adjustedStroke
      );
    default:
      return { shadowPath: '' };
  }
};

const doLineShadow = (path, angle, offset, strokeWidth) => {
  // Apply Angle to strokeWidth and the offset
  const { x: strokeX, y: strokeY } = calcOffset(strokeWidth, angle);
  const { x: offsetX, y: offsetY } = calcOffset(offset, angle);

  // Create paper.js paths
  const textPath = new paper.Path.create(path);
  const auxiliaryPath = textPath.clone({ insert: false });

  // get center position
  const pathCenter = textPath.bounds.center;

  // Set position of paths. The auxiliary path is placed next to the path with an offset of the stroke
  textPath.position = new paper.Point(pathCenter.x, pathCenter.y);
  auxiliaryPath.position = new paper.Point(
    pathCenter.x + strokeX,
    pathCenter.y + -strokeY
  );

  // subtract the text path from the auxiliary path
  // the result is the area of the auxiliary path, that did not overlap with the text
  const result = auxiliaryPath.subtract(textPath);
  // the position of the result must be adjusted. The position in paper.js is set as the center of an object
  // and the relative center changes in some cases after the subtraction, so it needs to be adjusted
  const { x: adjustX } = calcOffset(
    (result.bounds._width - auxiliaryPath.bounds._width) / 2,
    angle
  );
  const { y: adjustY } = calcOffset(
    (result.bounds._height - auxiliaryPath.bounds._height) / 2,
    angle
  );

  // set the position of the shadow path.
  result.position = new paper.Point(
    pathCenter.x + strokeX + offsetX - adjustX,
    pathCenter.y + -strokeY + -offsetY + adjustY
  );

  // clear paper.js canvas
  paper.project.activeLayer.removeChildren();

  return { shadowPath: result.pathData };
};

/**
 *Compute the 3D shadow for the given path
 * @param {path as paper.PathItem} path
 * @param {*shadow as object with length and angle in degrees} shadow
 * @returns paper.CompoundPath with the shadow
 */
function computeShadow(path, shadow) {
  const shadowVector = new paper.Point({
    length: shadow.length,
    angle: -shadow.angle,
  });

  const shadowCompPath = new paper.CompoundPath({ insert: false });
  const angleRad = degToRad(-shadow.angle);

  const children = path.children ? path.children : [path];

  for (const childPath of children) {
    // the path is reversed, since the shadow implementation assumes a clockwise direction of the outer paths
    childPath.reverse();
    //find all the point in which the curve is tangent to the shadow vector,
    //and add it as intermediate point. If the tangent is at beginning / end of the
    //curve the new point is not added
    for (const curve of childPath.curves) {
      if (curve.isStraight()) continue;
      const tangents = getTimesWithTangent(curve, angleRad);
      for (const time of tangents) {
        if (time < ERROR_MARGIN || time > 1 - ERROR_MARGIN) continue;
        curve.divideAtTime(time);
      }
    }

    //Build a path linking each segment of the the main path, with
    //the corresponding segment of the shadow (same path translated by the shadow vector)
    //keeps only the polygons that are "in front"  (not clockwise)
    for (let i = 0; i < childPath.curves.length; i++) {
      const childCurve = childPath.curves[i];
      const poly = new paper.Path({ insert: false });
      poly.closed = true;
      poly.moveTo(childCurve.points[0]);
      poly.lineTo(childCurve.points[0].add(shadowVector));
      poly.cubicCurveTo(
        childCurve.points[1].add(shadowVector),
        childCurve.points[2].add(shadowVector),
        childCurve.points[3].add(shadowVector)
      );
      poly.lineTo(childCurve.points[3]);
      poly.cubicCurveTo(
        childCurve.points[2],
        childCurve.points[1],
        childCurve.points[0]
      );
      if (poly.clockwise) continue;
      shadowCompPath.addChild(poly);
    }
    //Add the path itself preventing artefact from a transparent background
    shadowCompPath.addChild(childPath.clone());
  }

  // clear paper.js canvas
  paper.project.activeLayer.removeChildren();

  return shadowCompPath;
}

const doLongShadow = (path, angle, offset) => {
  const originalTextPath = new paper.CompoundPath({
    pathData: path,
    insert: false,
  });

  const shadow = computeShadow(originalTextPath, {
    angle: angle,
    length: offset,
  });

  // clear paper.js canvas
  paper.project.activeLayer.removeChildren();

  return { shadowPath: shadow.pathData };
};

const doDetailShadow = (path, angle, shadowLength, strokeWidth) => {
  // Create paper.js paths
  const textPath = new paper.CompoundPath({ pathData: path, insert: false });

  // Create offsets. They are similar to border/stroke, but they are actual shapes,
  // so it's possible to create e.g. shadows from them.
  const offsets = textPath.children.map((child) => {
    let offsettedPath;
    try {
      if (!child.clockwise) {
        // if path is not clockwise it means that it's a hole (e.g. inner part of `o`)
        // in such cases offsettedPath is created larger that the original.
        offsettedPath = PaperOffset.offset(child, strokeWidth, OFFSET_PARAMS);
        // path is subtracted from offset to create a stroke/border
        return offsettedPath.subtract(child, { insert: false });
      } else {
        // normal case, offsettedPath is created smaller than the original
        offsettedPath = PaperOffset.offset(child, -strokeWidth, OFFSET_PARAMS);

        // offsettedPath is subtracted from path to create a stroke/border
        return child.subtract(offsettedPath, { insert: false });
      }
    } catch (e) {
      // let offset errors fail silently
      return child;
    }
  });

  // for each offset path create a shadow
  const shadows = offsets.map((o) => {
    fixPathOrientation(o);
    return computeShadow(o, { length: strokeWidth, angle });
  });

  // combine all offsets into a single object
  const offsetPath = new paper.CompoundPath({
    children: offsets,
    insert: false,
  });
  // combine all shadows into a single object
  const offsetShadowPath = new paper.CompoundPath({
    children: shadows,
    insert: false,
  });
  // create long/block shadow for text
  const shadowPath = computeShadow(textPath, { length: shadowLength, angle });

  // clear paper.js canvas
  paper.project.activeLayer.removeChildren();

  return {
    shadowPath: shadowPath.pathData,
    offsetPath: offsetPath.pathData,
    offsetShadowPath: offsetShadowPath.pathData,
  };
};

export default applyShadow;
