import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useContextSelector } from 'use-context-selector';
import leftMenuContext, {
  getCanvasContainer,
  getRootNode,
} from '../../components/leftMenuContext';
import useThrottled from '../useThrottle';
import { OverlayImage } from './styles';
import { State, UseDragNDropProps } from './types';

const DISTANCE_THRESHOLD = 15;

let currentPointer: number | null = null;

const isTouch = (event: PointerEvent): boolean =>
  event.pointerType === 'touch' || event.pointerType === 'pen';

const initialState = {
  isDragging: false,
  draggedEnough: false,
  isLongTouch: false,
  pointer: null,
  inputDownPosition: null,
  distanceToTopCorner: null,
};

const useDragNDrop = ({
  wrapperRef,
  imageRef,
  preview,
  onDrop,
  onStartDragging,
  onDragging,
  onStopDragging,
}: UseDragNDropProps): { renderOverlay: () => React.ReactPortal | null } => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayPosition, setOverlayPosition] = useState<{
    left: number;
    top: number;
  } | null>(null);

  const rootNode = useContextSelector(leftMenuContext, getRootNode);
  const canvasContainer = useContextSelector(
    leftMenuContext,
    getCanvasContainer
  );

  /**
   * state is a ref since it's only used to keep track of flags
   * for the dragging process. It does not trigger any rendering,
   * so useState is avoided.
   */
  const state = useRef<State>({ ...initialState });
  const getState = (): State => state.current;
  const setState = (newState: Partial<State>): void => {
    state.current = { ...state.current, ...newState };
  };

  const renderOverlay = (): React.ReactPortal | null => {
    if (!showOverlay || !rootNode || !overlayPosition) return null;
    const { distanceToTopCorner } = getState();
    return createPortal(
      <OverlayImage
        src={preview}
        left={overlayPosition.left - (distanceToTopCorner?.x ?? 0)}
        top={overlayPosition.top - (distanceToTopCorner?.y ?? 0)}
        imgWidth={imageRef.current?.clientWidth ?? 150}
        imgHeight={imageRef.current?.clientHeight ?? 150}
      />,
      rootNode
    );
  };

  const _onDrop = useCallback(
    (event?: PointerEvent) => {
      const { distanceToTopCorner, draggedEnough } = getState();

      let eventVal = {};
      if (draggedEnough && imageRef.current && distanceToTopCorner && event) {
        let scale = imageRef.current.height / imageRef.current.naturalHeight;

        const newWidth = imageRef.current.naturalWidth * scale;
        if (newWidth > imageRef.current.width) {
          scale *= imageRef.current.width / newWidth;
        }

        const finalWidth = imageRef.current.naturalWidth * scale;
        const finalHeight = imageRef.current.naturalHeight * scale;

        const dx =
          distanceToTopCorner.x - (imageRef.current.width - finalWidth) / 2;

        const dy =
          distanceToTopCorner.y - (imageRef.current.height - finalHeight) / 2;

        eventVal = {
          dropX: event.clientX,
          dropY: event.clientY,
          distanceToTopCorner: {
            x: dx,
            y: dy,
          },
          imgWidth: finalWidth,
          imgHeight: finalHeight,
        };
      }

      onDrop && onDrop(eventVal);
    },
    [onDrop, imageRef]
  );

  const setInputPosition = useCallback(
    (event: PointerEvent) => {
      if (!imageRef.current) return;
      const imageBoundingBox = imageRef.current.getBoundingClientRect();
      setState({
        inputDownPosition: {
          x: event.clientX,
          y: event.clientY,
        },
        distanceToTopCorner: {
          x: event.clientX - imageBoundingBox.left,
          y: event.clientY - imageBoundingBox.top,
        },
      });
    },
    [imageRef]
  );

  const startDragging = useCallback(
    (event: PointerEvent) => {
      const { isDragging } = getState();

      if (isDragging) {
        return false;
      }

      setState({ isDragging: true, pointer: event.pointerId });
      setInputPosition(event);
      setOverlayPosition({ left: event.clientX, top: event.clientY });
      return true;
    },
    [setInputPosition]
  );

  const stopWaitingForLongTouch = useCallback(() => {
    const { longTouchAwaiter } = getState();
    if (!longTouchAwaiter) return;

    clearTimeout(longTouchAwaiter);
    setState({ longTouchAwaiter: null });
  }, []);

  const waitForLongTouch = useCallback(() => {
    setState({
      longTouchAwaiter: setTimeout(() => {
        setState({ isLongTouch: true });
        stopWaitingForLongTouch();
        setShowOverlay(true);
      }, 300),
    });
  }, [stopWaitingForLongTouch]);

  const resetState = useCallback(() => {
    stopWaitingForLongTouch();
    const wasDragging = state.current.draggedEnough;
    state.current = { ...initialState };
    setShowOverlay(false);
    setOverlayPosition(null);
    currentPointer = null;
    if (wasDragging && onStopDragging) {
      onStopDragging();
    }
  }, [stopWaitingForLongTouch, onStopDragging]);

  const onPointerDown = useCallback(
    (event: PointerEvent) => {
      event.preventDefault();

      if (!startDragging(event)) return;

      if (isTouch(event)) {
        waitForLongTouch();
      }
    },
    [waitForLongTouch, startDragging]
  );

  const isFarAwayEnough = useCallback((x: number, y: number): boolean => {
    const { inputDownPosition } = getState();
    if (!inputDownPosition) return false;
    return (
      Math.hypot(x - inputDownPosition.x, y - inputDownPosition.y) >=
      DISTANCE_THRESHOLD
    );
  }, []);

  const handleDrag = useCallback((event: PointerEvent) => {
    setOverlayPosition({ left: event.clientX, top: event.clientY });
    setShowOverlay(true);
  }, []);

  const throttledIsDragging = useThrottled({
    delay: 100,
    callback: (event: PointerEvent) => {
      onDragging && onDragging({ dropX: event.clientX, dropY: event.clientY });
    },
    skipLeading: false,
  });

  const onPointerMove = useCallback(
    (event: PointerEvent) => {
      event.preventDefault();

      const { isDragging, pointer, draggedEnough } = getState();

      if (event.pointerId !== pointer) return;
      if (!isDragging) return;

      const isDraggingEnough =
        draggedEnough || isFarAwayEnough(event.clientX, event.clientY);
      if (!draggedEnough && isDraggingEnough && onStartDragging) {
        onStartDragging();
      }
      setState({ draggedEnough: isDraggingEnough });

      if (!isDraggingEnough) return;

      if (isTouch(event) && pointer === event.pointerId) {
        stopWaitingForLongTouch();
        const { isLongTouch } = getState();
        if (isLongTouch) {
          handleDrag(event);
        }
      } else {
        handleDrag(event);
      }
      throttledIsDragging(event);
    },
    [
      handleDrag,
      isFarAwayEnough,
      onStartDragging,
      throttledIsDragging,
      stopWaitingForLongTouch,
    ]
  );

  const onPointerUp = useCallback(
    (event: PointerEvent) => {
      const { draggedEnough, isLongTouch, pointer } = getState();

      if (event.pointerId !== pointer) return;

      const target = event.target as HTMLElement;
      if (isTouch(event)) {
        if (draggedEnough && isLongTouch) {
          _onDrop(event);
        } else if (!draggedEnough && !isLongTouch) {
          const insideElement =
            event.target === wrapperRef.current ||
            wrapperRef.current?.contains(target);
          if (insideElement) {
            _onDrop();
          }
        }
      } else {
        const insideCanvas =
          event.target === canvasContainer || canvasContainer?.contains(target);

        if (insideCanvas) _onDrop(event);
        else if (!draggedEnough) _onDrop();
      }

      resetState();
    },
    [_onDrop, resetState, canvasContainer, wrapperRef]
  );

  const onTouchMove = useCallback((event: TouchEvent) => {
    const { isLongTouch } = getState();
    if (isLongTouch) {
      event.preventDefault();
    }
  }, []);

  const onTouchEnd = useCallback(
    (event: TouchEvent) => {
      const { isLongTouch } = getState();
      if (!isLongTouch) resetState();
      event.preventDefault(); // cancel the event sequence
    },
    [resetState]
  );

  useEffect(() => {
    const ctr = wrapperRef.current;
    if (!ctr) return;

    const extendedPointerUp = (event: PointerEvent): void => {
      const { pointer } = getState();
      if (event.pointerId !== pointer) return;

      onPointerUp(event);
      document.removeEventListener('pointermove', onPointerMove);
      document.removeEventListener('pointerup', extendedPointerUp);

      currentPointer = null;
    };

    const extendedPointerDown = (event: PointerEvent): void => {
      if (event.which === 3 || event.button === 2 || event.ctrlKey) return;
      if (currentPointer) return;

      currentPointer = event.pointerId;

      onPointerDown(event);
      document.addEventListener('pointermove', onPointerMove, {
        passive: false,
      });
      document.addEventListener('pointerup', extendedPointerUp, {
        passive: false,
      });
    };

    ctr.addEventListener('pointerdown', extendedPointerDown, {
      passive: false,
    });
    ctr.addEventListener('touchmove', onTouchMove, { passive: false });
    ctr.addEventListener('pointercancel', resetState, { passive: false });
    ctr.addEventListener('touchend', onTouchEnd, { passive: false });

    return (): void => {
      ctr.removeEventListener('pointerdown', extendedPointerDown);
      ctr.removeEventListener('touchmove', onTouchMove);
      ctr.removeEventListener('pointercancel', resetState);
      ctr.removeEventListener('touchend', onTouchEnd);
    };
  }, [
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onTouchMove,
    onTouchEnd,
    resetState,
    wrapperRef,
  ]);

  return { renderOverlay };
};

export default useDragNDrop;
