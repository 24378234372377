import styled from 'styled-components';
import { themeStyle } from './services/theming';

export const AppWrapper = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const AppUIOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  pointer-events: none;
  overflow-x: hidden;
  z-index: ${themeStyle.zIndexS1};
`;
