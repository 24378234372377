import {
  getFreeLineControl,
  getEditPathControl,
  getCircleControl,
  getCircleRadiusControl,
  getFreeFormControl,
  getFreeFormMeshControl,
  circleRotationControl,
} from './customControls';
import { fabric } from 'fabric';
import { circleRotationHandlePositionHandler } from '../../../utils/editor/misc';

export const CURVED_TRANSFORMS = ['angle', 'arch', 'rise', 'wave', 'flag'];
export const ROTATION_TRANSFORMS = ['freeLine', 'circle']; // transforms that rotate glyph objects by transform

// relative point to initialize transforms from
const transformPoints = {
  freeLine: [
    { x: 0, y: 1 },
    { x: 1 / 6, y: 1 },
    { x: 2 / 6, y: 0.8 },
    { x: 0.5, y: 0.8 },
    { x: 4 / 6, y: 0.8 },
    { x: 5 / 6, y: 1 },
    { x: 1, y: 1 },
  ],
  freeForm: [
    { x: 0, y: 0 },
    { x: 1 / 2, y: 0 },
    { x: 1, y: 0 },
    { x: 1, y: 1 },
    { x: 1 / 2, y: 1 },
    { x: 0, y: 1 },
    //Bezier Handles
    { x: 1 / 4, y: 0 },
    { x: 3 / 4, y: 0 },
    { x: 1 / 4, y: 1 },
    { x: 3 / 4, y: 1 },
  ],
  arch: [
    { x: 0, y: 1 },
    { x: 0.2, y: 0.7 },
    { x: 0.33, y: 0.65 },
    { x: 0.5, y: 0.65 },
    { x: 0.67, y: 0.65 },
    { x: 0.8, y: 0.7 },
    { x: 1, y: 1 },
  ],
  wave: [
    { x: 0.0, y: 1.0 },
    { x: 0.35, y: 0.65 },
    { x: 0.45, y: 0.6 },
    { x: 0.65, y: 0.55 },
    { x: 0.75, y: 0.525 },
    { x: 0.8, y: 0.5 },
    { x: 1.0, y: 0.6 },
  ],
  rise: [
    { x: 0.0, y: 1.0 },
    { x: 0.25, y: 0.95 },
    { x: 0.4, y: 0.75 },
    { x: 0.6, y: 0.6 },
    { x: 0.75, y: 0.5 },
    { x: 0.8, y: 0.45 },
    { x: 1.0, y: 0.5 },
  ],
  flag: [
    { x: 0.0, y: 0.85 },
    { x: 0.2, y: 1.0 },
    { x: 0.35, y: 1 },
    { x: 0.5, y: 0.85 },
    { x: 0.65, y: 0.7 },
    { x: 0.8, y: 0.7 },
    { x: 1.0, y: 0.85 },
  ],
  angle: [
    { x: 0.0, y: 1.0 },
    { x: 1.0, y: 0.4 },
  ],
};

const transformControls = {
  freeLine: {
    line: getFreeLineControl,
    point: getEditPathControl,
  },
  circle: {
    line: getCircleControl,
    point: getCircleRadiusControl,
  },
  freeForm: {
    line: getFreeFormControl,
    point: getFreeFormMeshControl,
  },
};

const controls = {
  circle: {
    rotation: new fabric.Control({
      actionHandler: circleRotationControl,
      positionHandler: circleRotationHandlePositionHandler,
    }),
  },
};

/**
 * Get relative points to initialize points for the current transform
 * @param {String} type
 */
export const getRelativeTransformPoints = (type) => {
  if (transformPoints[type]) {
    return transformPoints[type];
  } else {
    console.error(`Request for unknown transform '${type}'`);
  }
};

/**
 * Get functions to build controls for the current transform
 * @param {String} type
 */
export const getTransformControls = (type) => {
  if (transformControls[type]) {
    return transformControls[type];
  } else {
    // default to line
    return transformControls['freeLine'];
  }
};

export const getControls = (type) => {
  return controls[type] || [];
};
