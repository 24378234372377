import Testimonial from '../Testimonial/Testimonial';
import { P2 } from '../utilities/Typography/styles';
import { FlexColumn } from '../utilities/styles';

import MinimalTestimonial, {
  MinimalTestimonialSize,
} from '../Testimonial/MinimalTestimonial';
import {
  BrandsImage,
  BrandsWrapper,
  DesignPreview,
  TestimonialWrapper,
} from './styles';
import Spacer from '../Spacer/Spacer';
import { buildImageProxyUrl } from '../../utils/url';
import { ImageComponentType } from './types';
import { themeStyle } from '../../services/theming';

const unlimitedPremiumTemplates = (
  <P2>
    Unlimited <b>premium templates</b> and exclusive fonts
  </P2>
);

const advancedDesignFeatures = (
  <P2>
    Stand out with <b>advanced design features</b>
  </P2>
);

const projectLimitReachedDescription = (
  projectCount: number,
  projectCountLimit: number
): string => `You've completed ${projectCount}/${projectCountLimit} projects with us - that's impressive. Now, you're ready for the next step.
Upgrade your account and stand out with your designs.`;

const extraSpace = (amountOfSpace: string): React.ReactNode => (
  <P2>
    <b>{amountOfSpace} upload space</b> to fuel your creations
  </P2>
);

const HighResVectorDownloadContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      Unlimited <b>high-resolution downloads</b> (.png, .svg, .pdf)
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

const PremiumDesignUsage = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Unlimited</b> premium templates, illustrations, fonts and more
    </P2>,
    <P2>
      <b>100 projects & exports</b> (all vector and rastered file formats)
    </P2>,
    advancedDesignFeatures,
  ];
};

const PremiumLayoutUsage = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      Full library of predefined <b>drag-and-drop text layouts</b> and font
      pairings
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

const PremiumMockupUsage = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Unlimited premium mockups</b>, templates, illustrations, fonts and more
    </P2>,
    <P2>
      <b>More projects & exports</b> (all vector and rastered file formats)
    </P2>,
    advancedDesignFeatures,
  ];
};

// Free => Pro
const ProjectLimitReachedContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Create 100 projects</b> with pro accounts
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

// Pro => Expert
const ProProjectLimitReachedContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Create unlimited projects</b> with expert accounts
    </P2>,
    <P2>
      <b>Kittl AI+</b> (100 credits/mo)
    </P2>,
    extraSpace('10GB'),
  ];
};

const UploadLimitReachedContent = (
  amountOfSpace: string
): ReadonlyArray<React.ReactNode> => {
  return [
    extraSpace(amountOfSpace),
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

const RemoveBackgroundContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Remove any backgrounds</b> with just one click
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

const UploadCustomFontsContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Upload custom fonts to your account</b> to use for any projects
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

const CustomArtboardSizeContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Custom artboard sizes</b> to create designs in any dimensions for any
      needs
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

const ImproveImageQualityContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Image enhancement</b> for best quality downloads
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

const PremiumColorPalettesContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Global color palettes</b>for any project
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

const TextToImageContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      <b>Generate images and illustrations</b> via text prompts
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

const ImageBackgroundRemovalContent = (): ReadonlyArray<React.ReactNode> => {
  return [
    <P2>
      Unlimited <b>AI-powered background removing</b>
    </P2>,
    unlimitedPremiumTemplates,
    advancedDesignFeatures,
  ];
};

export const content = {
  highResVectorDownload: {
    title: 'Get High Resolution Downloads',
    description:
      'Upgrade now for pixel-perfect designs on cards, t-shirts and more.',
    items: HighResVectorDownloadContent(),
  },
  premiumDesignUsage: {
    title: 'Get Premium Design Templates',
    description: 'Upgrade your account for unlimited premium access.',
    items: PremiumDesignUsage(),
  },
  premiumLayoutUsage: {
    title: 'Upgrade Your Account',
    description:
      'Upgrade now for unlimited text layouts, font pairings, and badge designs.',
    items: PremiumLayoutUsage(),
  },
  premiumMockupUsage: {
    title: 'Use Premium Mockups',
    description: 'Upgrade your account for unlimited premium access.',
    items: PremiumMockupUsage(),
  },
  projectLimitReached: {
    title: 'Unlock Your Next Project',
    description: projectLimitReachedDescription,
    items: ProjectLimitReachedContent(),
  },
  proProjectLimitReached: {
    title: 'Unlock Your Next Project',
    description: projectLimitReachedDescription,
    items: ProProjectLimitReachedContent(),
  },
  freeUploadLimitReached: {
    title: 'Need more space?',
    description: 'Upgrade now for advanced upload space.',
    items: UploadLimitReachedContent('1GB'),
  },
  proUploadLimitReached: {
    title: 'Need more space?',
    description: 'Upgrade to Kittl Expert for advanced upload space.',
    items: UploadLimitReachedContent('10GB'),
  },
  removeBackground: {
    title: 'Get unlimited transparent images',
    description:
      'Upgrade now and use unlimited transparent vector downloads for any editable designs.',
    items: RemoveBackgroundContent(),
  },
  uploadCustomFonts: {
    title: 'Need Your Own Fonts?',
    description:
      'Custom font upload is there. Upgrade now to use your own custom fonts for any designs.',
    items: UploadCustomFontsContent(),
  },
  customArtboardSize: {
    title: 'Need Custom Artboards?',
    description:
      'Use custom artboard sizes to create designs in any dimensions for any needs.',
    items: CustomArtboardSizeContent(),
  },
  improveImageQuality: {
    title: 'Need Better Image Quality?',
    description: 'Upgrade now for advanced image enhancements of any designs.',
    items: ImproveImageQualityContent(),
  },
  premiumColorPalettes: {
    title: 'Unlock All Color Palettes',
    description: `
      Upgrade now to unlock the full library of special color palettes,
      ready to use with just one click.
    `,
    items: PremiumColorPalettesContent(),
  },
  imageBackgroundRemoval: {
    title: 'Easily Remove Backgrounds',
    description:
      'Upgrade now to remove backgrounds of photos and images with one click.',
    items: ImageBackgroundRemovalContent(),
  },
  textToImage: {
    title: 'Generate stunning images with Kittl AI',
    description:
      'You have reached your credit limit. Upgrade now for more credits to use Kittl AI.',
    items: TextToImageContent(),
  },
};

const Quote: React.FC = () => {
  return (
    <TestimonialWrapper>
      <Testimonial
        compact
        name="CHRIS SPOONER"
        title="FOUNDER OF SPOON GRAPHICS"
        text={`It's definitely a one-of-a-kind tool that is of a higher grade than the kinds of tools and resources Adobe are releasing.`}
        image="/images/upgrade/ChrisSpooner.png"
      />
    </TestimonialWrapper>
  );
};

const QuoteV2: React.FC = () => {
  return (
    <TestimonialWrapper>
      <Testimonial
        compact
        name="WILL PATERSON"
        title="LETTERING ARTIST"
        text={`Kittl has been an invaluable resource for our company this year.`}
        image="/images/upgrade/WillPaterson.png"
      />
    </TestimonialWrapper>
  );
};

const Testimonials: React.FC = () => {
  return (
    <TestimonialWrapper>
      <FlexColumn gap="17px" width="100%">
        <MinimalTestimonial
          name="CHRIS SPOONER"
          title="FOUNDER OF SPOON GRAPHICS"
          image="/images/upgrade/ChrisSpooner.png"
          size={MinimalTestimonialSize.Medium}
        />
        <MinimalTestimonial
          name="WILL PATERSON"
          title="LETTERING ARTIST"
          image="/images/upgrade/WillPaterson.png"
          size={MinimalTestimonialSize.Large}
        />
        <MinimalTestimonial
          name="VIKTOR BALTUS"
          title={'TYPE & GRAPHIC DESIGNER'}
          image="/images/upgrade/ViktorBaltus.png"
          size={MinimalTestimonialSize.Small}
        />
      </FlexColumn>
    </TestimonialWrapper>
  );
};

const Brands: React.FC = () => {
  return (
    <BrandsWrapper height="100%">
      <P2
        color={themeStyle.inkBase}
        style={{ textAlign: 'center', marginTop: 'auto' }}
      >
        Used by brands like:
      </P2>
      <Spacer h="8px" />
      <BrandsImage src={'/images/upgrade/brands.png'} />
    </BrandsWrapper>
  );
};

const DesignPreviewWrapper: React.FC<{ designPreview: string }> = (props) => {
  return (
    <DesignPreview
      src={buildImageProxyUrl(props.designPreview, {
        width: 390,
        scale: 2,
      })}
    />
  );
};

export const imageComponents = {
  quote: Quote,
  quoteV2: QuoteV2,
  testimonials: Testimonials,
  brands: Brands,
  designPreview: DesignPreviewWrapper,
  aiImages: Brands,
  none: (): null => null,
};

/**
 * get the background based on the chosen component type
 * @param {string} imageComponentType
 */
export const getBackground = (
  imageComponentType: ImageComponentType
): string => {
  if (imageComponentType === 'brands') {
    return 'url(/images/upgrade/UpgradeBrandsBackground.jpg)';
  }

  if (imageComponentType === 'designPreview') {
    return themeStyle.transparent;
  }

  if (imageComponentType === 'aiImages') {
    return 'url(/images/upgrade/UpgradeAiBackground.jpg)';
  }

  return 'url(/images/upgrade/UpgradeBackground.png)';
};
