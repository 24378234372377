import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { content } from '../components/UpgradeModal/config';
import analytics from '../global/analytics';
import {
  DOWNLOAD_MODAL,
  POPUP_LEARN_MORE_SHOW,
  PROJECTLIMIT_MODAL,
  UPGRADE_MODAL_SHOW,
  UPLOAD_FONT_MODAL,
} from '../global/analytics/events';
import { useUserStore } from './userStore';

// upgrade types that are triggered by the download panel
export const DOWNLOAD_PANEL_UPGRADE_TYPES = [
  'highResVectorDownload',
  'improveImageQuality',
  'removeBackground',
];

const powerOfTwo = (x) => {
  return (Math.log(x) / Math.log(2)) % 1 === 0;
};

const getUpgradeImage = (type) => {
  if (type === 'premiumDesignUsage') return 'designPreview';
  if (type === 'textToImage') return 'aiImages';
  return 'brands';
};

/**
 * a store to manage and show promotions, like the upgrade account modal or
 * the invite friends popup
 */
const promotionStore = createVanilla((set, get) => ({
  showInviteFriendsPopup: false,
  actionCounter: { download: 0, share: 0 },

  /**
   * function to trigger the invite friends popup
   */
  triggerInviteFriendsPopup: async () => {
    const user = useUserStore.getState().user;
    if (!user) return; // probably a debug mode or user logged out
    if (get().showInviteFriendsPopup) return;

    // only show popup, if user is on the FREE plan
    if (user?.plan) return;

    analytics.track(POPUP_LEARN_MORE_SHOW, { label: user.plan });
    set({ showInviteFriendsPopup: true });
  },

  closeInviteFriendsPopup: () => {
    set({ showInviteFriendsPopup: false });
  },

  // TODO: this isn't used anywhere?
  triggerInviteFriendsPopupOnAction: (action) => {
    const count = get().actionCounter[action] + 1;
    if (count > 1 && powerOfTwo(count)) {
      get().triggerInviteFriendsPopup();
    }
    set({ actionCounter: { ...get().actionCounter, [action]: count } });
  },

  /**
   * whether the upgrade modal should be open
   * @type Boolean
   */
  upgradeModalIsOpen: false,

  /**
   * the type of the current upgrade modal
   * @type string | null
   */
  upgradeModalType: null,

  /**
   * image component used by the upgradeModal
   * @type string
   */
  upgradeModalImageComponent: 'brands',

  /**
   * a preview that can be displayed in the UpgradeModal of type `premiumDesignUsage`
   * @type string | null
   */
  upgradeModalDesignPreview: null,

  /**
   * trigger showing the upgrade modal for a type
   * @param {String} type - type of the modal to show
   * @param {Object} options - add more options that are only used for certain types or in certain scenarios
   * @param {String} options.designPreview - previewImage to be displayed in premiumDesignUsage
   */
  showUpgradeModal: (type, options = {}) => {
    if (get().upgradeModalIsOpen && get().upgradeModalType === type) return;
    if (!content[type]) {
      console.warn(`Unknown upgrade modal type: ${type}`);
      return;
    }

    // track old events for show upgrade modals
    // TODO: remove after test phase
    if (type === 'projectLimitReached') {
      analytics.track(PROJECTLIMIT_MODAL);
    } else if (DOWNLOAD_PANEL_UPGRADE_TYPES.includes(type)) {
      analytics.track(DOWNLOAD_MODAL);
    } else if (type === 'uploadCustomFonts') {
      analytics.track(UPLOAD_FONT_MODAL);
    }
    // track with new event
    analytics.track(UPGRADE_MODAL_SHOW, { label: type });

    set({
      upgradeModalIsOpen: true,
      upgradeModalType: type,
      upgradeModalImageComponent: getUpgradeImage(type),
      upgradeModalDesignPreview: options.designPreview || null,
    });
  },
  closeUpgradeModal: () => {
    set({ upgradeModalIsOpen: false });
  },
}));

export const usePromotionStore = create(promotionStore);

export const promotionSelector = {
  triggerInviteFriendsPopup: (state) => state.triggerInviteFriendsPopup,
  closeInviteFriendsPopup: (state) => state.closeInviteFriendsPopup,
  showInviteFriendsPopup: (state) => state.showInviteFriendsPopup,
  upgradeModalProps: (state) => ({
    isOpen: state.upgradeModalIsOpen,
    contentType: state.upgradeModalType,
    designPreview: state.upgradeModalDesignPreview,
    imageComponentType: state.upgradeModalImageComponent,
    onClose: state.closeUpgradeModal,
  }),
  showUpgradeModal: (state) => state.showUpgradeModal,
  closeUpgradeModal: (state) => state.closeUpgradeModal,
  upgradeModalIsOpen: (state) => state.upgradeModalIsOpen,
};
