import { fabric } from 'fabric';
import { getAngleDifference } from './angle';

/**
 * Reflect a set of points around a line between p0 and p1
 * see: https://bl.ocks.org/balint42/b99934b2a6990a53e14b
 */
export const reflectPoints = (points, p0, p1) => {
  const dx = p1.x - p0.x;
  const dy = p1.y - p0.y;

  if (!dx && !dy) return points; // p0 and p1 must be different points

  const a = (dx * dx - dy * dy) / (dx * dx + dy * dy);
  const b = (2 * dx * dy) / (dx * dx + dy * dy);

  return points.map((p) => {
    const x = a * (p.x - p0.x) + b * (p.y - p0.y) + p0.x;
    const y = b * (p.x - p0.x) - a * (p.y - p0.y) + p0.y;

    return { x, y };
  });
};

/**
 * flip points relative to all points
 * In comparison to `reflectPoints`, this function relies only on points and does
 * not take other properties of the underlying object (width/height/angle/...) into
 * account.
 * This function is useful when only the relative position between points after the flip is
 * important. For example when the object is repositioned afterwards.
 */
export const flipPoints = (points, flipX, flipY) => {
  const allX = points.map((o) => o.x);
  const minX = Math.min(...allX);
  const maxX = Math.max(...allX);

  const allY = points.map((o) => o.y);
  const minY = Math.min(...allY);
  const maxY = Math.max(...allY);

  points = points.map((p) => {
    return {
      x: flipX ? maxX - (p.x - minX) : p.x,
      y: flipY ? maxY - (p.y - minY) : p.y,
    };
  });

  return points;
};

/**
 * get absolute coordinates for a relative point, in relation to its origin
 * @param {x,y} relativePoint
 * @param {x,y} origin
 */
export const getAbsolutePoint = (relativePoint, origin) => ({
  x: origin.x + relativePoint.x,
  y: origin.y + relativePoint.y,
});

/**
 * get relative coordinates for an absolute point, in relation to origin
 * @param {x,y} relativePoint
 * @param {x,y} origin
 */
export const getRelativePoint = (absolutePoint, origin) => ({
  x: absolutePoint.x - origin.x,
  y: absolutePoint.y - origin.y,
});

/**
 * rotates a single point around a given other point
 * @param {fabric.Point} point to rotate
 * @param {fabric.Point} centerPoint to rotate around
 * @param {number} angleOfRotation in degrees
 * returns a Fabric.js point object with new coordinates
 */
export const rotatePointAroundSinglePoint = (
  point,
  centerPoint,
  angleOfRotation
) => {
  return fabric.util.rotatePoint(
    point,
    centerPoint,
    fabric.util.degreesToRadians(angleOfRotation)
  );
};

/**
 * rotate a series of points around one point via the difference between old and new angles
 * @param {{x, y}[]} points to be rotated
 * @param {fabric.Point} centerPoint to rotate points around
 * @param {number} previousAngle in degrees
 * @param {number} newAngle in degrees
 * returns an array of objects with newly rotated x and y values
 */
export const rotatePointsAroundSinglePoint = (
  points,
  centerPoint,
  previousAngle,
  newAngle
) => {
  const angleDifference = getAngleDifference(previousAngle, newAngle);

  const rotatedPoints = points.map((currentPoint) => {
    return rotatePointAroundSinglePoint(
      new fabric.Point(currentPoint.x, currentPoint.y),
      centerPoint,
      -angleDifference
    );
  });

  // just take the x and y value from Fabric Point object
  const mappedPoints = rotatedPoints.map((thisPoint) => {
    return { x: thisPoint.x, y: thisPoint.y };
  });

  return mappedPoints;
};

export const adjustRelativePoints = (points, options) => {
  const { width, adjustedWidth, alignment } = options;
  if (!adjustedWidth || !width) {
    return points;
  }

  const relativeAdjustedWidth = adjustedWidth / width;
  const diff = relativeAdjustedWidth - 1; // 1 is total relative width
  const minX =
    alignment === 'left' ? 0 : alignment === 'right' ? -diff : -diff / 2;

  return points.map(({ x, y }) => {
    return { y, x: x * relativeAdjustedWidth - minX };
  });
};

export const transformPoints = (points, transform) => {
  return points.map((p) => fabric.util.transformPoint(p, transform));
};

export const isSmallerPoint = (p1, p2, angle, isVertical) => {
  const _p1 = new fabric.Point(p1.x, p1.y);
  const _p2 = new fabric.Point(p2.x, p2.y);
  const rotation = fabric.util.calcRotateMatrix({ angle: -angle });
  const resetedP1 = fabric.util.transformPoint(_p1, rotation);
  const resetedP2 = fabric.util.transformPoint(_p2, rotation);
  return isVertical ? resetedP1.y < resetedP2.y : resetedP1.x < resetedP2.x;
};
