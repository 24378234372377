import { forwardRef, PropsWithChildren } from 'react';

import { IconEventStyle } from './styles';
import { defaultTheme } from './theme';
import { IconEventWrapperProps } from './types';

/**
 * This component is used as a wrapper to catch events (eg. hover) that
 * should affect icons, that are children of this wrapper.
 * One use case is a button. Where the icon should look active when the button is hovered.
 */
const IconEventWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<IconEventWrapperProps>
>((props, ref) => {
  const theme = { ...defaultTheme, ...props.theme };
  return (
    <IconEventStyle
      ref={ref}
      theme={theme}
      activeOnHover={props.activeOnHover}
      activeOnFocus={props.activeOnFocus}
      fitParent={props.fitParent}
      disabled={props.disabled}
      className={props.className}
      onClick={props.onClick}
      data-testid={props.dataTestId}
    >
      {props.children}
    </IconEventStyle>
  );
});

IconEventWrapper.defaultProps = {
  activeOnHover: true,
  activeOnFocus: false,
  disabled: false,
  fitParent: false,
  theme: defaultTheme,
};

export default IconEventWrapper;
