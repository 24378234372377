import { nanoid } from 'nanoid';

export const updateErrors = (fonts, errorOnEmpty) => {
  const knownStyles = [];
  return fonts.map((font) => {
    const style = font.style?.toLowerCase();
    if (!style && errorOnEmpty) {
      return { ...font, error: 'Please specify a style' };
    }
    if (style && knownStyles.includes(style)) {
      return { ...font, error: 'Style already exists' };
    }
    knownStyles.push(style);
    return { ...font, error: '' };
  });
};

const SUPPORTED_FORMATS = ['ttf', 'otf'];

const isSupportedFormat = (name) => {
  const lowerName = name.toLowerCase();
  return SUPPORTED_FORMATS.some((format) => {
    return lowerName.endsWith(`.${format}`);
  });
};

export const processFiles = (files) => {
  return files
    .filter((file) => isSupportedFormat(file.name))
    .map((file) => {
      const name = file.name;
      return { file, name, id: nanoid(), style: fileNameToStyleName(name) };
    });
};

export const getNiceName = (font) => {
  return font.name
    .replace(new RegExp(`${font.style}|.otf|.ttf`, 'gi'), '')
    .replaceAll(/_|-|\./gi, ' ')
    .trim();
};

const defaultFontStyle = 'regular';

const FONT_STYLES = [
  defaultFontStyle,
  'thin',
  'light',
  'medium',
  'bold',
  'black',
  'heavy',
  'wide',
];

const FONT_PREFIXES = ['extra', 'semi', 'ultra', 'demi'];

const fileNameToStyleName = (fileName) => {
  const cleanName = fileName.toLowerCase();
  const isItalic = cleanName.includes('italic');
  const prefix = FONT_PREFIXES.find((prefix) => {
    return cleanName.includes(prefix);
  });
  const name = FONT_STYLES.find((style) => {
    return cleanName.includes(style);
  });
  const names = [name || defaultFontStyle];
  if (prefix) names.unshift(prefix);
  if (isItalic) names.push('italic');

  return names
    .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
    .join(' ');
};
