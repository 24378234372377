export const formatResponseError = (response, form, updateError) => {
  const error = response.error;

  if (error === 'INVALID_PASSWORD') {
    updateError('password', 'Password is invalid');
  } else if (error === 'INVALID_PASSWORD_LENGTH') {
    if (form.password.length < response.minLength) {
      updateError('password', 'Password is too short');
    } else if (form.password.length > response.maxLength) {
      updateError('password', 'Password is too long');
    } else {
      // in case an unexpected password issue appears (eg. in encoding)
      updateError('password', 'Password is invalid');
    }
  } else if (error === 'AUTHORIZATION_FAILURE') {
    updateError('server', 'Your credentials are invalid');
  } else if (error === 'INVITE_REQUIRED') {
    updateError('server', 'You need an invitation to sign up at this point');
  } else if (error === 'EMAIL_ALREADY_USED') {
    updateError('email', 'E-Mail is already in use');
  } else if (error === 'INVALID_EMAIL') {
    updateError('email', 'E-Mail is invalid');
  } else {
    updateError('server', 'Server problem / Connection problem');
  }
};
