import { keyframes } from 'styled-components';
import { FADE_IN_OUT_DURATION, TOAST_GAP } from '../../stores/toastStore';

const getXDirection = ({ horizontalAlignment }) => {
  if (horizontalAlignment === 'center') return 0;
  if (horizontalAlignment === 'left') return '-100%';
  if (horizontalAlignment === 'right') return '100%';
};
const getYDirection = ({ horizontalAlignment, verticalAlignment }) => {
  if (horizontalAlignment !== 'center') return 0;
  if (verticalAlignment === 'top') return '-100%';
  if (verticalAlignment === 'bottom') return '100%';
};

export const getPositionStyles = ({
  horizontalAlignment,
  verticalAlignment,
}) => {
  const pos = {};

  if (verticalAlignment === 'top') pos.top = TOAST_GAP;
  if (verticalAlignment === 'bottom') pos.bottom = TOAST_GAP;

  if (horizontalAlignment === 'center') pos.left = '50%';
  if (horizontalAlignment === 'left') pos.left = TOAST_GAP;
  if (horizontalAlignment === 'right') pos.right = TOAST_GAP;

  return pos;
};

export const getFadeToastAnimation = ({
  duration,
  horizontalAlignment,
  verticalAlignment,
}) => {
  const fadePercentage = (FADE_IN_OUT_DURATION * 100) / duration;
  const transitionPercentages = {
    enter: `${fadePercentage}%`,
    exit: `${100 - fadePercentage}%`,
  };
  const xDirection = getXDirection({ horizontalAlignment });
  const yDirection = getYDirection({ horizontalAlignment, verticalAlignment });

  return keyframes`
    0% {
      transform: translateX(${xDirection}) translateY(${yDirection});
      opacity:0
    }
    ${transitionPercentages.enter},${transitionPercentages.exit} {
      transform: translateX(0) translateY(0);
      opacity:1
    }
    100% {
      opacity:0
    }
  `;
};
