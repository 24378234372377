import { themeStyle } from '../../../services/theming';
import { ReactComponent as BlockShadowIcon } from '../assets/blockShadow.svg';
import { ReactComponent as DetailedShadowIcon } from '../assets/detailedShadow.svg';
import { ReactComponent as DropShadowIcon } from '../assets/dropShadow.svg';
import { ReactComponent as LineShadowIcon } from '../assets/lineShadow.svg';

const commonIconMapping = {
  primary: {
    light: [themeStyle.inkLight, themeStyle.backgroundAlt],
    dark: [themeStyle.darkInkMain, themeStyle.darkBrandLight],
  },
  secondary: {
    light: [themeStyle.inkMain, themeStyle.inkMain],
    dark: [themeStyle.darkInkMedium, themeStyle.darkBackgroundAlt],
  },
};

export const config = [
  {
    id: 'drop',
    label: 'Drop Shadow',
    hasBlur: true,
    icon: DropShadowIcon,
    iconMapping: commonIconMapping,
  },
  {
    id: 'line',
    icon: LineShadowIcon,
    label: 'Line Shadow',
    hasBlur: false,
    iconMapping: commonIconMapping,
  },
  {
    id: 'block',
    icon: BlockShadowIcon,
    label: 'Block Shadow',
    hasBlur: false,
    hasOutline: true,
    iconMapping: {
      primary: {
        light: [themeStyle.inkLight, themeStyle.backgroundAlt],
        dark: [themeStyle.darkInkMain, themeStyle.darkBrandLight],
      },
      secondary: {
        light: [themeStyle.inkMain, themeStyle.inkMain],
        dark: [themeStyle.darkInkLight, themeStyle.darkBackgroundAlt],
      },
    },
  },
  {
    id: 'detail',
    icon: DetailedShadowIcon,
    label: 'Detailed 3d Shadow',
    hasBlur: false,
    hasOutline: true,
    isBorderColorConfigurable: true,
    iconMapping: {
      primary: {
        light: [themeStyle.inkLight, themeStyle.backgroundAlt],
        dark: [themeStyle.darkInkMedium, themeStyle.darkBrandLight],
      },
      secondary: {
        light: [themeStyle.inkMain, themeStyle.inkMain],
        dark: [themeStyle.darkInkLight, themeStyle.darkBackgroundAlt],
      },
      tertiary: {
        light: [themeStyle.inkLight, themeStyle.backgroundAlt],
        dark: [themeStyle.darkInkMain, themeStyle.darkBrandLight],
      },
    },
  },
];
