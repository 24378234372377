import { themeStyle } from '../../services/theming';

export const defaultTheme = {
  // font
  fontFamily: themeStyle.fontFamily,
  fontWeight: themeStyle.fontMedium,

  // label
  colorLabel: themeStyle.varInkMedium,
  colorLabelActive: themeStyle.varInkMedium,

  colorPointer: themeStyle.backgroundAlt,

  buttonShadow: themeStyle.shadowSmallHeavy,
  activeButtonInnerShadow: themeStyle.shadowSmallFocus,
};

export const colorPickerIconTheme = {
  color: themeStyle.varInkMedium,
  activeColor: themeStyle.varInkMain,
};

export const inputTheme = {
  width: '100%',
  height: '28px',
  textAlign: 'center',
  inputMarginLeft: '0px',
  backgroundColor: themeStyle.varBackgroundAlt,
};
