import { SetState, GetState } from 'zustand';

import { MockupTemplateFiles, MockupTemplateLayers, State } from './types';

const createDefaultTemplateData = (): Pick<
  State,
  'layers' | 'layerFiles' | 'warpGridSize'
> => ({
  layers: {
    sceneLayer: '',
    darkBlendLayer: '',
    lightBlendLayer: '',
  },

  layerFiles: {
    sceneLayer: null,
    darkBlendLayer: null,
    lightBlendLayer: null,
  },

  warpGridSize: {
    row: 2,
    col: 2,
  },
});

export const stateCreator = (
  set: SetState<State>,
  get: GetState<State>
): State => ({
  ...createDefaultTemplateData(),

  updateWarpGridSize: (partial: Partial<State['warpGridSize']>): void => {
    set({
      warpGridSize: {
        ...get().warpGridSize,
        ...partial,
      },
    });
  },

  updateLayers: (partial: Partial<MockupTemplateLayers>): void => {
    set({
      layers: {
        ...get().layers,
        ...partial,
      },
    });
  },

  updateLayerFiles: (partial: Partial<MockupTemplateFiles>): void => {
    set({
      layerFiles: {
        ...get().layerFiles,
        ...partial,
      },
    });
  },

  resetTemplateData: (): void => {
    set({
      ...createDefaultTemplateData(),
    });
  },
});
