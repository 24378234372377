import React, { useCallback } from 'react';
import { ADD_MOCKUP_TEMPLATE } from '../../global/events';
import themeStyle from '../../global/themeStyle';
import {
  promotionSelector,
  usePromotionStore,
} from '../../stores/promotionStore';
import { userStoreSelector, useUserStore } from '../../stores/userStore';
import { Mockup } from '../../types/Mockup';
import { buildImageProxyUrl } from '../../utils/url';
import Element from '../ElementPanels/Element/Element';
import { Element as ElementType } from '../ElementPanels/Element/types';
import ElementsBasePanel from '../ElementPanels/ElementBasePanel';
import ElementsDefaultHeader from '../ElementPanels/ElementsDefaultHeader';
import SortAndSearch from '../ElementPanels/SortAndSearch/SortAndSearch';
import { SortAndSearchChildProps } from '../ElementPanels/SortAndSearch/types';
import BasePanel from '../LeftMenu/BasePanel/BasePanel';
import { PanelProps } from '../LeftMenu/types';
import { OnDropValue } from '../../hooks/useDragNDrop/types';

const MockupTemplatesBasePanel: React.FC<
  PanelProps & SortAndSearchChildProps
> = (props) => {
  const { dispatch, transitioning, category } = props;

  const proAccess = useUserStore(userStoreSelector.proAccess);
  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );
  const setSelectedCategory = props.setSelectedCategory;
  const sort = props.sort;

  const onAdd = useCallback(
    (value: ElementType & { fireToast: () => void } & OnDropValue): void => {
      if (value.premium && !proAccess) {
        showUpgradeModal('premiumMockupUsage');
        return;
      }

      dispatch(ADD_MOCKUP_TEMPLATE, value.id);
      return;
    },
    [dispatch, proAccess, showUpgradeModal]
  );

  const render = useCallback(
    (element: Mockup): React.ReactNode => {
      return (
        <Element
          element={{
            ...element,
            preview: buildImageProxyUrl(element.preview),
          }}
          theme={{
            borderColor: `${themeStyle.grey300}80`,
            borderColorActive: `${themeStyle.grey300}CC`,
          }}
          onDrop={onAdd}
        />
      );
    },
    [onAdd]
  );

  const headElement = <ElementsDefaultHeader {...props} disabled />;
  const bodyElement = (
    <ElementsBasePanel
      {...props}
      render={render}
      elementType="mockups"
      headGalleries={[]}
      hide={transitioning}
      sort={sort?.id}
      category={category}
      onGoBack={(): void => setSelectedCategory?.({ id: 'All', label: 'All' })}
    />
  );

  return (
    <BasePanel
      label={'Mockups'}
      headElement={headElement}
      bodyElement={bodyElement}
      state={props.state}
      noOverflow={false}
    />
  );
};

const MockupTemplatesPanel: React.FC<PanelProps> = (props) => {
  return (
    <SortAndSearch elementType="mockups">
      <MockupTemplatesBasePanel {...props} />
    </SortAndSearch>
  );
};

export default MockupTemplatesPanel;
