import { Point } from '../../types';

export const moveTo = (point: Point): string => `M ${point.x} ${point.y}`;

export const lineTo = (point: Point): string => `L ${point.x} ${point.y}`;

export const cubicCurveTo = (
  startControlPoint: Point,
  endControlPoint: Point,
  endPoint: Point
): string =>
  `C ${startControlPoint.x} ${startControlPoint.y} 
    ${endControlPoint.x} ${endControlPoint.y} 
    ${endPoint.x} ${endPoint.y}
    `;

export const closePath = (): string => 'Z';
