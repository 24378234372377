/**
 * Key codes that are handled even if artboard has disabled hotkeys
 * This way we can separate codes that are intended to provide interactivity while
 * dragging stuff on the canvas / performing other eventual actions.
 */
const PRIORITY_KEY_CODES = [
  'ShiftLeft',
  'Space',
  'AltLeft',
  'AltRight',
  'MetaLeft',
  'MetaRight',
  'OSRight', // Same as MetaRight when using Firefox
  'OSLeft', // Same as MetaLeft when using Firefox
  'ControlLeft',
  'ControlRight',
];

/**
 * figure out whether a keyCode is a priority keyCode
 * @param {String} keyCode
 */
export const isPriorityKeyCode = (keyCode: string): boolean =>
  PRIORITY_KEY_CODES.includes(keyCode);
