import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { fadeIn } from '../../utilities/styles';

export const ButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
`;

export const IconsWrapper = styled.div`
  position: absolute;
  bottom: 7px;
  left: 9px;
  z-index: 1;
  pointer-events: none;
`;

export const Wrapper = styled.div.attrs<{
  background: string;
  padding: string;
}>(({ background }) => ({
  style: {
    backgroundColor: background,
  },
}))<{
  background: string;
  padding: string;
}>`
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  position: relative;
  border-radius: ${themeStyle.radiusMedium};

  -webkit-touch-callout: none;
  touch-action: pan-x pan-y;
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: ${({ theme }) => theme.imageBorderRadius};
`;

export const BorderBox = styled.div<{
  outlineOnHover?: boolean;
  borderColor: string;
}>`
  position: absolute;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  border: 1px solid;
  border-color: ${({ outlineOnHover, borderColor }) =>
    outlineOnHover ? themeStyle.transparent : borderColor};
  border-radius: ${themeStyle.radiusMedium};

  z-index: 1;
  pointer-events: none;
`;

export const OuterWrapper = styled.div.attrs<any>(
  ({
    elementWidth,
    elementHeight,
  }: {
    elementWidth: number;
    elementHeight: number;
  }) => ({
    style: {
      width: elementWidth ? `${elementWidth}px` : '100%',
      height: elementHeight ? `${elementHeight}px` : '100%',
    },
  })
)<any>`
  position: relative;

  &:hover {
    ${ButtonsWrapper} > * {
      opacity: 1;
    }
    ${BorderBox} {
      border: 1px solid ${themeStyle.varInkLight};
    }
  }

  animation: ${fadeIn};
  animation-duration: 1s;

  &:hover {
    ${Wrapper} {
      filter: contrast(1);
    }
  }
`;
