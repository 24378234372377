import { State } from './types';

export const mockupTemplateStoreSelector = {
  layers: (state: State): State['layers'] => state.layers,

  layerFiles: (state: State): State['layerFiles'] => state.layerFiles,

  sceneLayer: (state: State): State['layers']['sceneLayer'] =>
    state.layers.sceneLayer,

  darkBlendLayer: (state: State): State['layers']['darkBlendLayer'] =>
    state.layers.darkBlendLayer,

  lightBlendLayer: (state: State): State['layers']['lightBlendLayer'] =>
    state.layers.lightBlendLayer,

  warpGridSize: (state: State): State['warpGridSize'] => state.warpGridSize,

  updateWarpGridSize: (state: State): State['updateWarpGridSize'] =>
    state.updateWarpGridSize,

  updateLayers: (state: State): State['updateLayers'] => state.updateLayers,

  updateLayerFiles: (state: State): State['updateLayerFiles'] =>
    state.updateLayerFiles,

  resetTemplateData: (state: State): State['resetTemplateData'] =>
    state.resetTemplateData,
};
