const GRAY = '#8B8B8B';
const LIGHT_GRAY = '#C1C1C1';
const MEDIUM_GRAY = '#C4C4C4';
const DARK_GRAY = '#333333';

export default {
  /**
   * Colors
   * see https://www.figma.com/file/OejbDIWv1RN7tXiuWiI4Tl/Kittl-(WIP)?node-id=5176%3A4940
   */

  // primary
  primary: '#00C19C',
  primaryHighlighted: '#4BD6B7',
  primaryLight: '#96EAD1',
  primaryVeryLight: '#79EDD0',
  primarySuperLight: '#E9FCFB',

  // secondary
  offWhite: '#F5F1EB',
  offWhiteHighlighted: '#FFFBF5',
  offWhiteDarkened: '#DBD3C5',

  // neutrals
  grey100: '#F6F6F6',
  grey200: '#EFEFEF',
  grey300: '#E2E2E2',
  grey400: '#C5C5C5',
  grey500: '#979797',
  grey550: '#838383',
  grey600: '#606060',
  grey700: '#505050',

  // shades
  black: '#2D2D2D',
  white: '#FFFFFF',

  // selection
  blue: '#18A0FB',
  cyan: '#56BCBC',
  lightCyan: '#A2E1E1',
  highlight: '#00C19C',
  highlightHover: '#33CDB0',

  // errors/ alerts
  red: '#D01320',
  highAlertRed: '#FF0000',

  green: '#5BA648',
  linkBlue: '#3C67C4',
  facebookBlue: '#1877F2',
  gold: '#D3A764',

  // shadows
  inputShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
  shadowPopover:
    '0px 36px 98px rgba(0, 0, 0, 0.07),0px 13.1406px 35.7716px rgba(0, 0, 0, 0.0482987), 0px 6.37951px 17.3665px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 8.51336px rgba(0, 0, 0, 0.0310596), 0px 1.23656px 3.36619px rgba(0, 0, 0, 0.0217013)',

  shadowModal: `0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);`,

  shadowMenu: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',

  shadowDropdown:
    '1px 1px 2px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.4)',

  profileMenuShadow:
    '0px 36px 98px rgba(0, 0, 0, 0.07), 0px 13.1406px 35.7716px rgba(0, 0, 0, 0.0482987), 0px 6.37951px 17.3665px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 8.51336px rgba(0, 0, 0, 0.0310596), 0px 1.23656px 3.36619px rgba(0, 0, 0, 0.0217013)',

  // font
  fontFamily: 'Inter',
  fontFamilyDouble: 'Double',

  // font weights
  fontRegular: 400,
  fontMedium: 500,
  fontSemiBold: 600,
  fontBold: 700,

  // font sizes
  fontSize8_5: '8.5px',
  fontSizeSmaller: '9px',
  fontSize9_5: '9.5px',
  fontSizeSmall: '10px',
  fontSize10_5: '10.5px',
  fontSize11: '11px',
  fontSizeMedium: '12px',
  fontSizeLarge: '14px',
  fontSizeLarger: '16px',
  fontSize18: '18px',
  fontSize20: '20px',
  fontSize22: '22px',
  fontSize24: '24px',
  fontSize30: '30px',
  fontSize34: '34px',

  inputDefaultArrowWidth: '5.5px',
  inputDefaultArrowHeight: '4px',
  inputDefaultFontSize: '16px',
  inputDefaultWidth: '61px',
  inputDefaultHeight: '45px',
  inputDefaultBorderRadius: '5px',

  gray: GRAY,
  lightGray: LIGHT_GRAY,
  textGray: '#606060',

  layoutColor: '#171f22',
  sidePanelBlack: '#171F22',
  collapseBorder: DARK_GRAY,

  //zIndex
  zIndexNegative: -1,
  zIndexZero: 0,
  zIndexS1: 1,
  zIndexS2: 2,
  zIndexS3: 3,
  zIndexS9: 9,
  zIndexM1: 10,
  zIndexM9: 99,
  zIndexL9: 999,

  // the following colors are deprecated and should be replaced by the ones in the top of this file

  // primary (old)
  primaryBlack: '#2D2D2D',
  transparent: 'transparent',
  whiteDisabled: '#F5F5F5',
  mediumGray: MEDIUM_GRAY,

  primaryBackground: '#F5F1EB',
  disabledBackground: '#A5A39F',

  grayDisabledDark: '#A9A8A6',
  grayDisabledLight: '#D2D0CC',
  iconGray: '#979797',

  grayText: '#AEAEAE',
  lightGrayText: '#EFEFEF',
  lightGrayTextAlt: '#747677',
  darkGrayText: '#858788',
  darkText: '#2D2D2D',
  sliderHandleDisabled: MEDIUM_GRAY,
  sliderDisabledText: MEDIUM_GRAY,
  iconRowTextColor: '#A5A5A5',
  inputLabelColor: '#A5A5A5',
  inputPrefixColor: '#BDBDBD',

  /* Equivalent of darkText to be used with CSS filter property. Useful for coloring svg elements precisely */
  darkTextFilter: `brightness(0) 
                  saturate(100%) 
                  invert(15%) 
                  sepia(0%) 
                  saturate(1%) 
                  hue-rotate(262deg) 
                  brightness(115%) 
                  contrast(100%)`,

  grayBackground: '#EFEFEF',
  darkerGrayBackground: '#E2E2E2',

  blueControls: '#18A0FB',
  darkBlue: '#4148E2',
  blueControlsTransparent: 'rgba(24, 160, 251, 0.1)', // rgb(24, 160, 251) ≈ #18A0FB
  grayControlsLighter: '#D6D6D6',
  grayControlsLight: '#C5C5C5', // used for #C3C3C3 as well
  grayControlsSemiLight: '#B6B6B6',
  grayControlsRegular: '#9E9E9E',
  grayControlsSemiDark: '#969696',
  grayControlsDark: '#8D8F90', // used for #8F8F8F as well
  grayControlsDarker: '#6C6C6C',
  haloGray: MEDIUM_GRAY,

  grayPanelHover: 'rgba(239, 239, 239, 0.2)',
  grayPanelActive: 'rgba(239, 239, 239, 0.4)',
  grayPanelInactive: 'rgba(239, 239, 239, 0.5)',

  grayScrollbar: '#C9C9C9',

  menuBackgroundDarkGray: '#606060',
  menuDividerDarkGray: '#505050',

  widgetShadow:
    '0px 3.790558099746704px 6.051592826843262px 0px #00000013, 0px 12.731696128845215px 20.326040267944336px 0px #0000001B, 0px 57px 91px 0px #0000002E',

  purple: '#730F6F',
};
