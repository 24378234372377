import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { darkBackgroundBlur } from '../utilities/styles';

const marginBottom = '70px'; // height of the BottomMenu

export const Wrapper = styled.div<{ marginTop: string }>`
  position: absolute;
  right: 0px;
  top: ${({ marginTop }) => marginTop};
  height: calc(100% - ${({ marginTop }) => marginTop} - ${marginBottom});
  width: 100%;
  z-index: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  height: 100%;
  width: 296px;
  position: absolute;
  right: 0;
  box-sizing: border-box;
`;

const MenuWrapper = styled.div<{ state: string }>`
  display: flex;
  flex-direction: column;
  background: ${themeStyle.varBackgroundAlt};
  box-shadow: ${themeStyle.shadowMedium};
  border-radius: ${themeStyle.radiusMedium} 0 0 ${themeStyle.radiusMedium};
  pointer-events: auto;
  overflow: hidden;

  transform: translateX(
    ${(props) => (props.state === 'entered' ? '0' : '322')}px
  );
  transition: transform 0.5s ease-in-out;

  ${darkBackgroundBlur}
`;

export const StylesWrapper = styled(MenuWrapper)`
  flex-grow: 1;
  min-height: 1px;
  margin-bottom: 8px;
`;

export const LayersWrapper = styled(MenuWrapper)`
  flex-shrink: 0;
  height: 30%;
  padding-bottom: 8px;
`;

export const Header = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
`;

export const ScrollContent = styled.div<any>`
  overflow-y: auto;
  overflow-x: hidden; /* hide overflow on safari and FF(win) */
  overscroll-behavior: none; /* restrict overscroll on macOS, as it makes tabs look broken */
  flex: 1;
`;

export const TabContent = styled.div`
  width: 296px;
  padding: 0 16px 0 16px;
  box-sizing: border-box;
`;

export const PanelWrapper = styled.div`
  border-bottom: 1px solid ${themeStyle.varInkSuperLight};
  padding: 16px;
`;
