import { ElementProps } from '../../types';
import { buildImageProxyUrl, buildProfileImageUrl } from '../../utils/url';

/**
 * utility function to get the author info from an element
 * can be used to display the author
 */
export const getElementAuthor = (
  element: ElementProps,
  size = 43
): { name: string; profileImage: string | null } => {
  return element.createdBy
    ? {
        ...element.createdBy,
        profileImage: element.createdBy?.profileImage
          ? buildProfileImageUrl(element.createdBy.profileImage, {
              width: size,
              height: size,
              scale: 2,
            })
          : null,
      }
    : {
        /* Temporary author to handle existing, unlabeled data */
        name: 'Tobias Saul',
        profileImage: buildImageProxyUrl(
          'elements/overlay/1626096802730-T01773T1ZAP-U017KG7P25P-34ab4f575016-512.png',
          { width: size, height: size, scale: 2 }
        ),
      };
};
