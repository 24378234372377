import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { ONBOARDING_KEY } from '../../global/constants';
import analytics from '../../global/analytics';

import Api from '../../global/userApi';
import { ONBOARDING_STEP } from '../../global/analytics/events';
import { ANCHOR_REF_KEYS, ONBOARDING_USER_STEP } from './constants';

const { updateOnboardingState } = Api;

const updateOnboardingStorage = async (step) => {
  await updateOnboardingState(step);
  sessionStorage.removeItem(ONBOARDING_KEY); // remove previously used local onboarding state
};

export const onboardingStore = createVanilla((set, get) => ({
  // Current onboarding step
  step: null,

  // individual steps may have sub steps. Their index is stored here.
  subStep: 0,

  // show the restart popover
  showRestart: false,

  // category to show in templates
  onboardingCategory: null,

  init: (step) => {
    const knownStep = ONBOARDING_USER_STEP[step?.name];
    // ignore unknown onboarding states
    if (!knownStep) return;

    set({
      step: ONBOARDING_USER_STEP[step.name],
    });
  },
  skipOnboarding: () => {
    get().setStep(ONBOARDING_USER_STEP.SKIPPED);
  },
  setStep: async (step, subStep = 0, next = false) => {
    analytics.track(ONBOARDING_STEP, {
      label: `${next ? 'next-' : ''}${step.name}-${subStep}`,
    });

    if (step.name === ONBOARDING_USER_STEP.SKIPPED.name) {
      set({ showRestart: true });
    }

    set({
      step,
      subStep,
    });

    await updateOnboardingStorage(get().step);
  },
  setStepIfActive: (step, subStep) => {
    const currentStep = get().step;
    const currentSubStep = get().subStep;
    const selectedStep = ONBOARDING_USER_STEP[step.name];

    if (
      !currentStep ||
      currentStep.name === ONBOARDING_USER_STEP.SKIPPED.name
    ) {
      return false;
    }

    const isLastSubStep =
      currentSubStep === currentStep.steps.length - 1 ||
      !currentStep.steps.length;

    if (
      // next step and current subStep was last one
      (currentStep.index === selectedStep.index - 1 && isLastSubStep) ||
      // or next sub step of current step
      (currentStep.index === selectedStep.index &&
        currentSubStep === subStep - 1)
    ) {
      return get().setStep(step, subStep);
    }
  },
  setNextStep: async () => {
    const currentStep = get().step;
    const subStep = get().subStep;
    if (subStep >= currentStep?.steps?.length - 1) {
      // goto next step
      const nextStep =
        ONBOARDING_USER_STEP[
          Object.keys(ONBOARDING_USER_STEP).find(
            (key) => ONBOARDING_USER_STEP[key].index === currentStep.index + 1
          )
        ];
      if (nextStep) {
        return get().setStep(nextStep, 0, true);
      }
    } else {
      // goto next sub step
      return get().setStep(currentStep, subStep + 1, true);
    }
  },
  registerStepRef: (ref, step) => {
    set((state) => ({ refs: { ...state.refs, [step]: ref } }));
  },
  setShowRestart: (val) => {
    set({ showRestart: val });
  },
  setOnboardingCategory: (val) => {
    set({ onboardingCategory: val });
  },
}));

export const useOnboardingStore = create(onboardingStore);

/**
 *
 * Utils for onboarding
 *
 */
export const updateArtboardPosition = (artboard, zoom, vpt) => {
  if (!artboard) {
    return;
  }

  const rect = {
    left: vpt[4] + artboard.left * zoom,
    top: vpt[5] + artboard.top * zoom,
    width: artboard.scaleX * artboard.width * zoom,
    height: artboard.scaleY * artboard.height * zoom,
  };

  onboardingStore.getState().registerStepRef(rect, ANCHOR_REF_KEYS.ARTBOARD);
};

export const getDisplayStep = (step, subStep = 0) => {
  if (!step) {
    return null;
  }

  const isLastSubStep = subStep === step.steps.length - 1 || !step.steps.length;
  const onboardingStep = isLastSubStep
    ? ONBOARDING_USER_STEP[
        Object.keys(ONBOARDING_USER_STEP).find(
          (key) => ONBOARDING_USER_STEP[key].index === step.index + 1
        )
      ]
    : step;

  if (!onboardingStep) return null;

  onboardingStep.currentSubStep = isLastSubStep ? 0 : subStep + 1;
  return onboardingStep;
};

export const onboardingStoreSelector = {
  setStep: (state) => state.setStep,
  setNextStep: (state) => state.setNextStep,
  skipOnboarding: (state) => state.skipOnboarding,
  step: (state) => state.step,
  subStep: (state) => state.subStep,
  showRestart: (state) => state.showRestart,
  refs: (state) => state.refs,
  setShowRestart: (state) => state.setShowRestart,
  onboardingCategory: (state) => state.onboardingCategory,
  setOnboardingCategory: (state) => state.setOnboardingCategory,
};
