import styled, { css } from 'styled-components';
import { handleTextOverflow } from '../utilities/styles';
import { P2, P4 } from '../utilities/Typography/styles';
import { TextInputTheme } from './types';

interface GetColorProps {
  disabled?: boolean;
  hovered?: boolean;
  isActive?: boolean;
  error: boolean;
  theme: TextInputTheme;
}

const getColor = ({
  disabled,
  hovered,
  isActive,
  error,
  theme,
}: GetColorProps): string => {
  if (disabled) return theme.color;
  if (error) return theme.colorError;
  if (isActive && theme.colorActive) return theme.colorActive;
  if (hovered && theme.colorHover) return theme.colorHover;
  return theme.color;
};

const getBorderColor = ({
  disabled,
  hovered,
  isActive,
  error,
  theme,
}: GetColorProps): string => {
  if (disabled) return theme.borderColor;
  if (error) return theme.colorError;
  if (isActive && theme.borderColorActive) return theme.borderColorActive;
  if (hovered && theme.borderColorHover) return theme.borderColorHover;
  return theme.borderColor;
};

export const IconWrapper = styled.div<any>`
  ${({ isEnd, theme }) =>
    `padding-${isEnd ? 'right' : 'left'}: ${theme.innerPadding}`};
`;

const InputStyle = css<any>`
  flex: 1;
  min-width: 0; /* overwrite default input width */
  box-sizing: border-box;
  height: 100%;

  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeight};
  font-size: ${({ theme }) => theme.fontSize};
  line-height: ${({ theme }) => theme.lineHeight};
  text-align: ${({ theme }) => theme.textAlign};

  padding: 0 ${({ theme }) => theme.innerPadding};
  margin: 0;
  border: none;
  background: transparent;

  transition: ${({ theme }) => theme.transition};
  transition-property: color;

  font-style: ${({ theme }) => (theme.fontStyle ? theme.fontStyle : 'unset')};

  &:focus {
    outline: none;
  }
`;

export const InputField = styled.input.attrs<any>(({ isPasswordInput }) => ({
  type: isPasswordInput ? 'password' : 'text',
}))<any>`
  ${InputStyle}
  cursor: ${({ theme, disabled }) =>
    theme.cursor ?? disabled ? 'unset' : 'text'};

  // Textarea styles
  resize: none;
  ${({ isTextArea }) => (isTextArea ? 'padding: 10px' : null)};
`;

export const InputBox = styled.div.attrs<any>((props) => ({
  contentEditable: !props.disabled,
  suppressContentEditableWarning: true,
}))<any>`
  ${InputStyle}
  ${handleTextOverflow}
  user-select: auto;
  cursor: ${({ theme }) => theme.cursor};
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const InputWrapper = styled.div<any>`
  width: ${({ theme }) => theme.width};
  max-width: 100%;
  box-sizing: border-box;
`;

export const InputContainer = styled.div<any>`
  width: 100%;
  height: ${({ theme }) => theme.height};
  box-sizing: border-box;

  transition: ${({ theme }) => theme.transition};
  transition-property: box-shadow, border-color, color, background-color;

  background-color: ${({ theme }) => theme.backgroundColor};
  box-shadow: unset;

  border: ${({ theme }) => theme.border};
  border-color: ${({ error, isActive, disabled, theme }) =>
    getBorderColor({ disabled, isActive, error, theme })};
  border-radius: ${({ theme }) => theme.borderRadius};

  ${InputField}, ${InputBox} {
    color: ${({ disabled, isActive, error, theme }) =>
      getColor({ disabled, isActive, error, theme })};

    &::placeholder {
      color: ${({ disabled, isActive, error, theme }) =>
        theme.placeholderColor ||
        getColor({ disabled, isActive, error, theme })};
      opacity: 1; /* Firefox */

      transition: ${({ theme }) => theme.transition};
      transition-property: color;
    }
  }

  &:hover {
    border-color: ${({ disabled, isActive, error, theme }) =>
      getBorderColor({ disabled, isActive, error, theme, hovered: true })};
    box-shadow: ${({ theme, disabled }) =>
      disabled ? 'unset' : theme.hoverShadow};

    ${InputField} {
      color: ${({ disabled, isActive, error, theme }) =>
        getColor({ disabled, isActive, error, theme, hovered: true })};

      &::placeholder {
        color: ${({ disabled, isActive, error, theme }) =>
          theme.placeholderColor ||
          getColor({ disabled, isActive, error, theme, hovered: true })};
        opacity: 1; /* Firefox */
      }
    }
  }

  html[data-theme='dark'] && {
    background-color: ${({ theme }) =>
      theme.backgroundColorDark ?? theme.backgroundColor};

    &:hover {
      background-color: ${({ theme, disabled }) =>
        disabled
          ? theme.backgroundColor
          : theme.backgroundColorHoverDark ??
            theme.backgroundColorDark ??
            theme.backgroundColor};
    }
  }

  &:active,
  &:focus-within {
    border-color: ${({ disabled, error, theme }) =>
      getBorderColor({ disabled, isActive: true, error, theme })};

    ${InputField}, ${InputBox} {
      color: ${({ disabled, error, theme }) =>
        getColor({ disabled, isActive: true, error, theme })};

      &::placeholder {
        color: ${({ disabled, error, theme }) =>
          theme.placeholderColor ||
          getColor({ disabled, isActive: true, error, theme })};
        opacity: 1; /* Firefox */
      }
    }
  }

  & input {
    &:disabled {
      color: ${({ theme, error }) =>
        getColor({ disabled: true, error, theme })};
    }
  }
`;

export const RightIcons = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  background: transparent;

  & > * {
    padding: 0 5px;
  }

  button {
    border: none;
    background: transparent;
  }
`;

export const Label = styled(P2)<any>`
  color: ${({ theme, error }) => (error ? theme.colorError : theme.labelColor)};
  margin-bottom: 3px;
`;

export const InfoText = styled(P4)<any>`
  color: ${({ theme, error }) => getColor({ error, theme })};
  margin-top: 3px;
`;
