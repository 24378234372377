import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BasePanel from '../BasePanel/BasePanel';
import TextInput from '../../TextInput/TextInput';
import PhotoElements from '../../PhotoElements/PhotoElements';
import usePhotosStoreStore, {
  photosStoreSelector,
} from '../../../stores/photosStore';
import { BodyWrapper, PhotosPanelWrapper, SearchBarContainer } from './styles';

const PhotosMenu = (props) => {
  const { query, setQuery } = usePhotosStoreStore(
    photosStoreSelector.photosQuery
  );

  const [scrollResultsToTop, setScrollResultsToTop] = useState(0);

  const setSearch = (query) => {
    setQuery(query);
    setScrollResultsToTop(scrollResultsToTop + 1);
  };

  const head = (
    <SearchBarContainer>
      <TextInput
        placeholder={'Search'}
        value={query}
        icon={'search'}
        iconAtEnd={true}
        onEnter={setSearch}
      />
    </SearchBarContainer>
  );

  const body = (
    <BodyWrapper>
      <PhotosPanelWrapper>
        <PhotoElements
          {...props}
          scrollToTop={scrollResultsToTop}
          label="TRENDING PHOTOS"
        />
      </PhotosPanelWrapper>
    </BodyWrapper>
  );

  return (
    <BasePanel
      label="Photos"
      onClose={props.onClose}
      headElement={head}
      bodyElement={body}
    />
  );
};

PhotosMenu.propTypes = {
  onClose: PropTypes.func,
};

export default PhotosMenu;
