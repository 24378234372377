import React from 'react';
import PropTypes from 'prop-types';

import Profile from '../../Profile/Profile';
import { FlexRow } from '../../utilities/styles';
import { buildWebsiteUrl } from '../../../utils/url';
import { options } from '../../Profile/UserNavigation/config';
import { User } from '../../../propTypes';

const UserButtons = ({ user, onLogOut }) => {
  const handleNavigation = (item) => {
    if (item.url && !item.disabled) {
      window.location.href = buildWebsiteUrl(item.url);
    }
  };

  const handleHelp = () => {
    window.location.href = buildWebsiteUrl('/help');
  };

  return (
    <FlexRow alignItems="center">
      <Profile
        user={user}
        onClickItem={handleNavigation}
        options={options}
        onHelp={handleHelp}
        onLogOut={onLogOut}
      />
    </FlexRow>
  );
};

UserButtons.propTypes = {
  user: User,
  onLogOut: PropTypes.func,
};

export default UserButtons;
