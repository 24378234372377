import styled from 'styled-components';

export const MenuContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
`;

export const CursorInfoBoxWrapper = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.backgroundColor};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 3px 5px;
  color: ${(props) => props.theme.labelColor};

  font-size: ${(props) => props.theme.labelFontSize};
  font-weight: ${(props) => props.theme.labelFont};

  transition: opacity 0.2s ease-in-out;
  opacity: ${(props) => (props.isInPosition ? '1' : '0')};
`;
