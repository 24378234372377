import React, {
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Scrollable } from './style';

/**
 * A wrapper that provides a consistent vertical scrollbar across browers.
 */
const VerticallyScrollable = forwardRef<
  HTMLDivElement,
  PropsWithChildren<Record<string, unknown>> & { className?: string }
>(({ children, className }, ref) => {
  // For iPad and FireFox, we might need a right padding as the scrollbar may not always take space.
  const [needsPadding, setNeedsPadding] = useState(false);
  const scrollableRef = useRef<HTMLDivElement>(null);

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    ref,
    () => scrollableRef.current
  );

  useLayoutEffect(() => {
    if (scrollableRef.current) {
      // detect if the scrollbar is taking space.
      const clientWidth = scrollableRef.current.clientWidth;
      const width = scrollableRef.current.getBoundingClientRect().width;

      if (clientWidth === width) {
        setNeedsPadding(true);
      }
    }
  }, []);

  return (
    <Scrollable
      ref={scrollableRef}
      needsPadding={needsPadding}
      // className is needed since for modal we extend a style with this component
      className={className}
    >
      {children}
    </Scrollable>
  );
});

export default React.memo(VerticallyScrollable);
