import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  useLayoutEffect,
} from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import {
  RightClickMenuWrapper,
  MenuContainer,
  MenuButton,
  ShortCutLabel,
  IconWrapper,
  Divider,
} from './styles';
import Context from '../context';
import Icon from '../Icon/Icon';
import { menuGroups } from './groups';
import {
  Theme,
  themeStoreSelector,
  themeStyle,
  useThemeStore,
} from '../../services/theming';

const calculatePosition = (targetPos, popoverRect, containInRect) => {
  const padding = 5;

  let left = targetPos.left;
  let top = targetPos.top;

  // Keep menu within workbench
  const maxLeft =
    containInRect.left + containInRect.width - padding - popoverRect.width;
  left = Math.min(left, maxLeft);
  const maxTop =
    containInRect.top + containInRect.height - padding - popoverRect.height;
  top = Math.min(top, maxTop);

  return [left.toFixed(), top.toFixed()];
};

/**
 * ContextMenu, that shows up when a user right clicks on the canvas.
 */
const RightClickMenu = ({ canvasContainerRef, ...props }) => {
  const context = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({
    left: 0,
    top: 0,
  });
  const [targetPosition, setTargetPosition] = useState(null);
  const menuRef = useRef();

  /**
   * update the position state, based on the target and container positions
   */
  const updatePosition = useCallback(() => {
    if (!isOpen) {
      return false;
    }
    if (targetPosition && menuRef.current && canvasContainerRef.current) {
      const popoverRect = menuRef.current.getBoundingClientRect();
      const containInRect = canvasContainerRef.current.getBoundingClientRect();

      const [left, top] = calculatePosition(
        targetPosition,
        popoverRect,
        containInRect
      );

      if (
        !position.isInPosition ||
        left !== position.left ||
        top !== position.top
      ) {
        setPosition({
          left: left,
          top: top,
          isInPosition: true,
        });
      }
    }
  }, [isOpen, targetPosition, canvasContainerRef, position]);

  const showMenu = useCallback((event) => {
    event.preventDefault();
    setTargetPosition({ left: event.x, top: event.y });
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        close();
      }
    },
    [close]
  );

  /**
   * update event listeners to open and close the menu
   */
  useEffect(() => {
    const containerRef = canvasContainerRef.current;
    containerRef.addEventListener('contextmenu', showMenu);

    if (isOpen) {
      containerRef.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('closeRightClickMenu', close);
      window.addEventListener('resize', close);
    }

    return () => {
      containerRef.removeEventListener('contextmenu', showMenu);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('closeRightClickMenu', close);
      containerRef.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', close);
    };
  }, [isOpen, canvasContainerRef, showMenu, close, handleClickOutside]);

  useLayoutEffect(() => {
    if (isOpen) {
      updatePosition();
    }
  }, [isOpen, updatePosition]);

  const onClickAction = useCallback(
    (event, value) => {
      props.dispatch && props.dispatch(event, value);
      close();
    },
    [close, props]
  );

  const theme = useThemeStore(themeStoreSelector.resolvedTheme);
  const textColor =
    theme === Theme.LIGHT ? themeStyle.varInkMedium : themeStyle.varInkMain;
  const iconTheme = {
    color: textColor,
    activeDefaultColor: themeStyle.varInkMain,
    transition: `all ${themeStyle.transitionDuration}`,
  };

  const getMenuButtonGroup = (menuGroup, index) => (
    <div key={index}>
      {index !== 0 && <Divider height="2px" />}
      {menuGroup
        .filter(
          (menuGroup) => menuGroup.icon !== 'clippingMask' || props.canUnmask
        )
        .map(({ action, icon, label, shortcut }, index, items) => {
          return (
            <React.Fragment key={icon}>
              <MenuButton
                textColor={textColor}
                theme={iconTheme}
                onClick={() => onClickAction.apply(null, action)}
              >
                <IconWrapper>
                  <Icon theme={iconTheme} name={icon} height="12px" />
                </IconWrapper>
                <span>{label}</span>
                {shortcut && <ShortCutLabel>{shortcut}</ShortCutLabel>}
              </MenuButton>
              {index !== items.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
    </div>
  );

  const Menu = (
    <MenuContainer data-testid="right-click-menu">
      <RightClickMenuWrapper
        ref={menuRef}
        isInPosition={position.isInPosition}
        style={{
          left: position.left + 'px',
          top: position.top + 'px',
        }}
      >
        {menuGroups.map((menuGroup, index) =>
          getMenuButtonGroup(menuGroup, index)
        )}
      </RightClickMenuWrapper>
    </MenuContainer>
  );

  if (isOpen) {
    return createPortal(Menu, context.getRootContainer());
  }
  return null;
};

RightClickMenu.propTypes = {
  /**
   * Reference to the canvas container
   */
  canvasContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  /**
   * Dispatch function that sends events into the bus
   */
  dispatch: PropTypes.func,
  /**
   * status to show whether the user can delete a mask
   */
  canUnmask: PropTypes.bool,
};

// memoize the component, since the other props don't change
export default React.memo(
  RightClickMenu,
  (prev, newProps) => prev.canUnmask === newProps.canUnmask
);
