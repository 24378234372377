import { useEffect, useState } from 'react';
import { VanillaNumberInput } from '../../components/NumberInput/NumberInput';
import Popover from '../../components/Popover/Popover';
import Slider from '../../components/Slider/Slider';
import themeStyle from '../../global/themeStyle';
import { isCompatibleTouchDevice } from '../../utils/detection';
import { Wrapper } from './styles';

const WithSlider = (Component) => {
  const WithSliderComponent = (props) => {
    const [showSlider, setShowSlider] = useState(false);

    const [value, setValue] = useState(props.value);
    useEffect(() => {
      setValue(props.value);
    }, [props.value]);

    const handleSliderChange = (value, isChanging = false) => {
      if (isChanging) {
        props.onChanging && props.onChanging(value);
      } else {
        props.onChanged && props.onChanged(value);
      }

      setValue({ value });
    };

    const slider = (
      <Slider
        onChanging={(val) => handleSliderChange(val, true)}
        onChanged={(val) => handleSliderChange(val)}
        value={value?.value}
        min={props.min}
        max={props.max}
        theme={{ trackBackgroundColor: themeStyle.grey200 }}
      />
    );

    const onFocus = (event) => {
      if (props.onFocus) {
        props.onFocus(event);
      }

      setShowSlider(true);
    };

    return (
      <Popover
        open={showSlider}
        showHeader={false}
        content={slider}
        hideTip
        label=""
        align="start"
        width="180px"
        onClickOutside={() => setShowSlider(false)}
        theme={{ padding: '12px' }}
        padding={0}
        className="popover-friend"
      >
        <Wrapper theme={props.theme}>
          <Component
            {...props}
            onFocus={onFocus}
            value={value}
            selectOnFocus={false}
          />
        </Wrapper>
      </Popover>
    );
  };

  WithSliderComponent.propTypes = VanillaNumberInput.propTypes;
  WithSliderComponent.defaultProps = VanillaNumberInput.defaultProps;
  return WithSliderComponent;
};

const WithSliderForTouch = (Component) => {
  if (!isCompatibleTouchDevice()) {
    return Component;
  }

  return WithSlider(Component);
};

export default WithSliderForTouch;
