import styled from 'styled-components';
import { QUERIES } from '../../utilities/queries';

export const Wrapper = styled.div<{ layout: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ layout }) => layout};
  align-items: center;

  gap: 8px;
  ${QUERIES.laptopAndUp} {
    gap: 12px;
  }
`;
