import styled from 'styled-components';
import { themeStyle } from '../../../../services/theming';

import { H6Plus, P2 } from '../../../utilities/Typography/styles';
import { scrollbarWidth } from '../../../VerticallyScrollable/style';

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isGroup }) =>
    isGroup ? `calc(100% - ${scrollbarWidth})` : '100%'};
  height: 100%;

  background: ${({ isGroup }) =>
    isGroup ? themeStyle.varInkSuperLight : themeStyle.transparent};
  border-radius: ${({ isGroup }) =>
    isGroup ? themeStyle.radiusMedium : '0px'};
  padding: ${({ isGroup }) => (isGroup ? '10px 0 10px 10px' : '0px')};
  margin-right: ${({ isGroup }) => (isGroup ? scrollbarWidth : '0')};

  box-sizing: border-box;
`;

export const MasonryWrapper = styled.div`
  flex: 1;
  height: 100%;

  overflow: hidden;
`;

export const FolderTitle = styled(H6Plus)`
  flex: 1;
`;

export const BackLabel = styled(P2)`
  color: ${themeStyle.varInkMedium};
  width: 95px;
  margin-right: ${({ isGroup }) => (isGroup ? scrollbarWidth : '0')};
  text-align: right;
  cursor: pointer;
`;

export const UnauthenticatedHint = styled(P2)`
  margin-right: ${scrollbarWidth};
`;
