import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button/Button/Button';
import {
  SET_STATE,
  DEV_VISUALIZE_COORDS,
  DEV_OUTLINE_BOUNDING_RECT,
  DEV_VISUALIZE_POINTS,
  DEV_SAVE_TEXT_LAYOUT,
  TOGGLE_SUBSCRIPTION_MODAL,
} from '../../../global/events';
import BasePanel from '../BasePanel/BasePanel';
import { FlexColumn, FlexRow } from '../../utilities/styles';
import {
  onboardingStoreSelector,
  useOnboardingStore,
} from '../../../stores/onboardingStore';
import Api from '../../../global/userApi';
import { useUserStore, userStoreSelector } from '../../../stores/userStore';
import Select from '../../SelectNew/Select';
import themeStyle from '../../../global/themeStyle';
import { ONBOARDING_USER_STEP } from '../../../stores/onboardingStore/constants';
import MockupTemplateControls from './MockupTemplateControls';
import { PROJECT_ID_KEY } from '../../../global/constants';
import { useThemeStore } from '../../../services/theming';

const DebugMenu = ({ artboardState, dispatch, onClose }) => {
  const [state, setState] = useState(JSON.stringify(artboardState, null, 2));
  const [displayCoordinates, setDisplayCoordinates] = useState(false);
  const [displayPoints, setDisplayPoints] = useState(false);

  const user = useUserStore(userStoreSelector.user);
  const plans = [
    { id: 'FREE', label: 'FREE' },
    { id: 'PRO', label: 'PRO' },
    { id: 'EXPERT', label: 'EXPERT' },
  ];
  const [plan, setPlan] = useState(plans[0]);

  const setStep = useOnboardingStore(onboardingStoreSelector.setStep);
  const updateUserInfo = useUserStore(userStoreSelector.updateUserInfo);

  const handleResetStep = (toTemplates = false) => {
    setStep(ONBOARDING_USER_STEP[toTemplates ? 'SURVEY' : 'SIGN_UP']);
  };

  const handleCreateCmsNotification = () => {
    Api.createCmsNotification();
  };

  const testError = () => {
    throw new Error('Test error');
  };

  useEffect(() => {
    setState(JSON.stringify(artboardState, null, 2));
  }, [artboardState]);

  const dispatchEvent = (event, value) => {
    dispatch && dispatch(event, value);
  };

  const showSubscriptionModal = () => {
    dispatchEvent(TOGGLE_SUBSCRIPTION_MODAL, true);
  };

  const toggleTheme = () => {
    useThemeStore
      .getState()
      .setTheme(useThemeStore.getState().theme === 'dark' ? 'light' : 'dark');
  };

  const renderBody = (
    <textarea
      style={{
        width: '295px',
        height: '450px',
        boxSizing: 'border-box',
        position: 'absolute',
        fontSize: '10px',
      }}
      value={state}
      onChange={(e) => {
        setState(e.target.value);
      }}
    />
  );

  const buttonTheme = { fontSize: themeStyle.fontSizeMedium };

  const renderHead = (
    <FlexColumn gap="5px">
      <FlexRow justifyContent="space-between">
        <Button
          width={'145px'}
          label={'Set state'}
          onClick={() => dispatchEvent(SET_STATE, { state })}
          theme={buttonTheme}
        />
        <Button
          width={'145px'}
          label={'Clear storage'}
          onClick={() => localStorage.removeItem(PROJECT_ID_KEY)}
          theme={buttonTheme}
        />
      </FlexRow>
      <FlexRow justifyContent="space-between">
        <Button
          width={'145px'}
          label={displayCoordinates ? 'Hide Coords' : 'ShowCoords'}
          isActive={displayCoordinates}
          onClick={() => setDisplayCoordinates(!displayCoordinates)}
          theme={buttonTheme}
        />
        <Button
          width={'145px'}
          label={'Draw BoundingRect'}
          onClick={() =>
            dispatchEvent(DEV_OUTLINE_BOUNDING_RECT, { displayCoordinates })
          }
          theme={buttonTheme}
        />
      </FlexRow>
      <FlexRow justifyContent="space-between">
        <Button
          width={'145px'}
          label={'Draw aCoords'}
          onClick={() =>
            dispatchEvent(DEV_VISUALIZE_COORDS, {
              coordType: 'aCoords',
              displayCoordinates,
            })
          }
          theme={buttonTheme}
        />
        <Button
          width={'145px'}
          label={'Draw oCoords'}
          onClick={() =>
            dispatchEvent(DEV_VISUALIZE_COORDS, {
              coordType: 'oCoords',
              displayCoordinates,
            })
          }
          theme={buttonTheme}
        />
      </FlexRow>
      <FlexRow justifyContent="space-between">
        <Button
          width={'145px'}
          label={displayPoints ? 'Hide Points' : 'Show Points'}
          isActive={displayPoints}
          onClick={() => {
            dispatchEvent(DEV_VISUALIZE_POINTS, {
              displayPoints: !displayPoints,
            });
            setDisplayPoints(!displayPoints);
          }}
          theme={buttonTheme}
        />
        <Button
          width={'145px'}
          label={'Save as text layout'}
          onClick={() => {
            dispatchEvent(DEV_SAVE_TEXT_LAYOUT);
          }}
          theme={buttonTheme}
        />
      </FlexRow>
      <FlexRow justifyContent="space-between">
        <Button
          width={'145px'}
          label={'Reset Onboarding'}
          onClick={() => {
            handleResetStep(false);
          }}
          theme={buttonTheme}
        />
        <Button
          width={'145px'}
          label={'Reset Onboarding Editor'}
          onClick={() => {
            handleResetStep(true);
          }}
          theme={buttonTheme}
        />
      </FlexRow>
      <FlexRow justifyContent="space-between">
        <Button
          width={'145px'}
          label={'Draw Bounding Rect (Include Text Extras)'}
          onClick={() =>
            dispatchEvent(DEV_OUTLINE_BOUNDING_RECT, {
              displayCoordinates,
              includeTextExtras: true,
            })
          }
          theme={buttonTheme}
        />
        <Button
          width={'145px'}
          label={'Create CMS notification'}
          onClick={handleCreateCmsNotification}
          theme={buttonTheme}
        />
      </FlexRow>
      <FlexRow justifyContent="space-between">
        <Button
          width={'145px'}
          label={'Test Error'}
          onClick={testError}
          theme={buttonTheme}
        />
      </FlexRow>
      <FlexRow justifyContent="space-between">
        <Button
          width={'145px'}
          label={'Show Subscription Modal'}
          onClick={showSubscriptionModal}
          theme={buttonTheme}
        />
        <Button
          width={'145px'}
          label={'Toggle Theme'}
          onClick={toggleTheme}
          theme={buttonTheme}
        />
      </FlexRow>
      {user?.admin && (
        <FlexRow justifyContent="space-between">
          <Select
            options={plans}
            activeOption={plan}
            customStyles={{
              width: '145px',
            }}
            onSelect={setPlan}
          />
          <Button
            width={'145px'}
            label={'Set plan'}
            onClick={async () => {
              await Api.changeSubscription(user.id, plan.id, 30);
              updateUserInfo();
            }}
            theme={buttonTheme}
          />
        </FlexRow>
      )}
      {/*TODO: should only show if user is admin or content manager*/}
      <MockupTemplateControls dispatch={dispatch} />
    </FlexColumn>
  );

  return (
    <BasePanel
      label="Debug"
      onClose={onClose}
      bodyElement={renderBody}
      headElement={renderHead}
    />
  );
};

export default DebugMenu;

DebugMenu.propTypes = {
  /**
   * event dispatcher
   */
  dispatch: PropTypes.func,
  artboardState: PropTypes.object,
  onClose: PropTypes.func,
};

DebugMenu.defaultProps = {};
