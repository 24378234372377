import tinycolor from 'tinycolor2';
import paper from '@kittl/paper';
import memoize from 'lodash/memoize';

import createPerformancer from '../../../utils/performancer';
import { isOnDevEnvironment } from '../../../global/environment';
import { buildSources } from '../../../utils/url';
import { getPaperPath } from '../../../utils/path/paper';
import { loadSVG } from '../../../utils/editor/loadSvg';

const getColorsFromProperty = (prop) => {
  if (!prop) return [];
  if (!prop.colorStops) return [{ color: prop }];
  return prop.colorStops.map((s) => {
    // fabric removes alpha component from `stop-color` and automatically combines it
    // with `stop-opacity` property of a gradient `<stop>`
    // we need to add it back, or we'll loose alpha on design reload
    const color = tinycolor(s.color);
    color.setAlpha(s.opacity);
    return {
      color: color.toRgbString(),
      isColorStop: true,
    };
  });
};

const getColorsFromPath = (path) => {
  const fillColors = getColorsFromProperty(path.fill).map((c) => ({
    ...c,
    type: 'fill',
  }));
  const strokeColors = getColorsFromProperty(path.stroke).map((c) => ({
    ...c,
    type: 'stroke',
  }));

  return [...fillColors, ...strokeColors];
};

export default function (fabric) {
  // allow re-initialize on dev environment to prevent issues with fast refresh
  if (fabric.Illustration && !isOnDevEnvironment()) {
    fabric.warn('fabric.Illustration is already defined');
    return;
  }
  /**
   * Illustration class
   * This class handles Illustrations
   * @class fabric.Illustration
   * @extends fabric.Group
   */
  fabric.Illustration = fabric.util.createClass(fabric.Group, {
    type: 'illustration',

    initialize: async function (objectName, options, cb) {
      this.inLoadingStage = options.inLoadingStage;

      const perf = createPerformancer(
        `initialize Illustration ${options.id || 'new'}`
      );
      perf.mark('init');

      options || (options = {});

      const { src, previewSrc } = buildSources(objectName);
      options.objectName = objectName;
      options.src = src;

      // default attributes
      this.set('borderIsDisabled', true);

      this.objectCaching = options.opacity < 1;
      this.trueRender = this.objectCaching || this.clipPath;

      this.clipPathMaskId = options.clipPathMaskId;
      this.findClipPath();

      // load paths and initialize object
      const paths = await this.initializeSvg({ ...options, previewSrc }, cb);

      // create colormap from paths
      // for every fill color found in a path, a new color is created and added to the list
      // if it does not already exist in it. Otherwise, instead of adding the color to the list,
      // a linkedColor object that refers to an object in the list is added to the linkedColors property
      // of the path
      const colorList = [];
      paths
        .filter((path) => !path.isOutline)
        .forEach((path, index) => {
          const colors = getColorsFromPath(path);
          const linkedColors = {};

          colors.forEach((item, colorIndex) => {
            const { color, isColorStop, type } = item;

            const existingColor = colorList.find(
              (c) => c.value === color && !c.isColorStop
            );

            let newColorKey = existingColor?.key;
            if (!existingColor) {
              newColorKey = `color-${index}-${colorIndex}`;
              const newColorType = {
                key: newColorKey,
                value: color || null, // `|| null`, to use no-color when svgs have color set to none. Otherwise we would save it as black
                isColorStop,
              };
              colorList.push(newColorType);
            }

            linkedColors[newColorKey] = {
              type,
              index: colorIndex,
            };
          });

          path.linkedColors = linkedColors;
        });

      const colorMap = new Map(colorList.map((obj) => [obj.key, obj.value]));
      this.setColorMap(colorMap);

      const illustrationColors = options.fill;
      // update colors
      if (illustrationColors) {
        illustrationColors.forEach((obj) => {
          // Even though now our keys look like color-{index}-{index}
          // Some old keys from templates and old designs might have keys in the form color-{index}
          // We add -0 in this case, which is enough for us

          const splittedKey = obj.key.split('-');
          if (splittedKey.length < 3) {
            obj.key += '-0';
          }
          this.updateColorMap(obj.key, obj.value);
        });
      }
      perf.mark('colorMap');

      this.onPathsAdded(paths, this);

      const objectScaling = this.getObjectScaling();
      this.lastScale = (objectScaling.scaleX + objectScaling.scaleY) / 2;

      // handle scaling of strokeWidth during scaling
      const onScaling = (event) => {
        const isOneDimensionScaling = ['ml', 'mr', 'mb', 'mt'].includes(
          event?.transform?.corner
        );
        if (isOneDimensionScaling) return;

        const { scaleX, scaleY } = this.getObjectScaling();
        const scale = (scaleX + scaleY) / 2;

        if (!this.lastStrokeWidth) {
          this.lastStrokeWidth = this.strokeWidth;
        }

        this.set(
          'strokeWidth',
          this.lastStrokeWidth * (scale / this.lastScale)
        );
      };

      // handle scaling of strokeWidth after scaling
      const onScaled = (event) => {
        const isOneDimensionScaling = ['ml', 'mr', 'mb', 'mt'].includes(
          event?.transform?.corner
        );
        const { scaleX, scaleY } = this.getObjectScaling();
        const scale = (scaleX + scaleY) / 2;

        if (!isOneDimensionScaling) {
          this.set(
            'strokeWidth',
            (this.lastStrokeWidth || this.strokeWidth) *
              (scale / this.lastScale)
          );
        }

        this.lastScale = scale;
        this.lastStrokeWidth = null;
      };

      this.on('scaled', onScaled);
      this.on('scaling', onScaling);

      this.on('selected', () => {
        if (this.group) {
          // when the object is selected and in a group, it is in an `ActiveSelection`
          // events are no longer fired on `this`, but just on the selection, which is `this.group`
          this.group.on('scaled', onScaled);
          this.group.on('scaling', onScaling);
        }
      });

      this.on('moving', this.tryPreviewMaskOnMoving);
      this.on('moved', this.tryMaskingOnMoved);

      if (!this.initialized) cb && cb(this);

      perf.mark('after callback');
      this.canvas && this.canvas.requestRenderAll();
      perf.mark('end');
      // eslint-disable-next-line no-console
      perf.measure().forEach((m) => console.debug(m));
      perf.clear();
    },

    /**
     * this can be used to add functionality after new paths
     * are added to an illustration
     */
    onPathsAdded: (paths, options) => {},

    _set: function (key, value) {
      if (key === 'fill') {
        if (value?.key) {
          this.updateColorMap(value.key, value.value);
        }
      } else if (key === 'stroke') {
        this[key] = value;
        const outline = this.getOutlinePath();
        if (outline) {
          outline.set(key, value);
        }
        this.dirty = true;
      } else if (key === 'strokeWidth') {
        this[key] = value;
        const outline = this.getOutlinePath();
        if (outline) {
          outline.set(key, value);
        }

        // if strokeWidth value is changed and the illustration is fully initialized, but there is no outline yet
        if (value && !outline && !this.previewImage && this._objects.length) {
          // create outline path on demand, if strokeWidth is changed after initialization
          this.createOutlinePath(this).then((createdOutline) => {
            if (createdOutline) {
              this.insertAt(createdOutline, 0);
            }
          });
        }

        this.dirty = true;
      } else if (key === 'opacity') {
        this[key] = value;
        this.objectCaching = value < 1;
        this.trueRender = this.objectCaching || this.clipPath;
      } else {
        this.callSuper('_set', key, value);
      }

      return this;
    },

    setColorMap: function (colorMap) {
      this.colorMap = colorMap;
      this.fill = mapToList(this.colorMap);
      this.canvas && this.canvas.fire('object:modified');
    },

    createOutlinePath: async function (options) {
      const joinedPath = await getUnitedPath(options.src);
      if (!joinedPath) return null;

      const outlinePath = new fabric.Path(joinedPath.pathData);
      outlinePath.set({
        fill: 'transparent',
        stroke: options.stroke || '#8E8E8E',
        strokeWidth: options.strokeWidth || 0,
        strokeLineJoin: 'round',
        isOutline: true,
        top: -this.height / 2,
        left: -this.width / 2,
        objectCaching: false, // avoid cache issues right after initialization
        _finalizeDimensions: (x, y) => ({ x, y }), // ignore stroke for boundingbox
      });
      return outlinePath;
    },

    getOutlinePath() {
      return this.getObjects().find((object) => object.isOutline);
    },

    // Implements standard Object `getColors`
    getColors(skipStroke = false) {
      const isUsed = (key) => {
        // Returns true if there is a path that has the color as part of its fill property
        // Or if there is a path that uses it as stroke AND has a strokeWidth greater than 0
        return this.getObjects().some((obj) => {
          const linkedColor = obj.linkedColors?.[key];
          if (!linkedColor) return false;

          return (
            (!skipStroke && obj.strokeWidth) || linkedColor.type !== 'stroke'
          );
        });
      };

      let colors = [];
      if (Array.isArray(this.fill)) {
        colors = this.fill.map(({ key, value }) => {
          return {
            key,
            value,
            visible: isUsed(key),
          };
        });
      }

      colors.push({
        key: 'stroke',
        value: this.stroke,
        visible: !!this.strokeWidth,
      });

      return colors;
    },

    // Implements standard Object `setColor`
    setColor(key, value) {
      if (key === 'stroke') {
        this.set(key, value);
      } else {
        this.set('fill', { key, value });
      }
    },

    updateColorMap: function (key, value) {
      this.colorMap.set(key, value);
      this.fill = mapToList(this.colorMap);

      this.forEachObject((obj, idx) => {
        const linkedColor = obj.linkedColors && obj.linkedColors[key];
        if (linkedColor) {
          const colorStops = obj && obj[linkedColor.type]?.colorStops;
          if (colorStops) {
            const color = value === null ? 'rgba(0, 0, 0, 0)' : value;
            const solidColor = tinycolor(color);
            solidColor.setAlpha(1);
            obj.set(
              linkedColor.type,
              new fabric.Gradient({
                ...obj[linkedColor.type],
                colorStops: colorStops.map((stop, idx) =>
                  idx === linkedColor.index
                    ? {
                        ...stop,
                        color: solidColor.toRgbString(),
                        opacity: tinycolor(color).toHsl().a,
                      }
                    : stop
                ),
              })
            );
          } else {
            obj.set(linkedColor.type, value);
          }
        }
      });
      this.dirty = true;
    },

    toObject: function () {
      const propertiesToInclude = ['objectName', 'inLoadingStage'];
      const object = this.callSuper('toObject', propertiesToInclude);

      if (!object.inLoadingStage) {
        delete object.inLoadingStage;
      }

      if (this.type !== 'basicShape') {
        delete object.skewX;
        delete object.skewY;
      }

      const unusedAttrs = [
        'clipPath',
        // we don't care about the paths of child objects, so we remove them
        'objects',
        'globalCompositeOperation',
      ];
      unusedAttrs.forEach((attr) => {
        delete object[attr];
      });

      // map to list
      const colorList = this.colorMap
        ? mapToList(this.colorMap)
        : this.previousColorList || [];

      return fabric.util.object.extend(object, {
        fill: colorList,
        clipPathMaskId: this.clipPathMaskId
          ? this.clipPathMaskId
          : this.clipPath?.id,
      });
    },

    /**
     * ignore stroke for bounding box, but include it in cache size
     */
    _finalizeDimensions: function (width, height, includeStroke) {
      return includeStroke && this.strokeUniform
        ? { x: width + this.strokeWidth, y: height + this.strokeWidth }
        : { x: width, y: height };
    },

    switchFastRendering: function (enable) {
      this.trueRender = !enable && (this.objectCaching || this.clipPath);
    },
  });

  /**
   * Unite paths for an illustration.
   * This is used for the outline.
   */
  const getUnitedPath = memoize(async (src) => {
    if (!src) return null;
    const paths = await loadSVG(src);

    let joinedPath;
    paths.forEach((path) => {
      const pathObject = getPaperPath(path, { ignorePathOffset: true });
      if (!pathObject) return;
      if (path.angle) {
        pathObject.rotate(path.angle, new paper.Point(path.left, path.top));
      }
      if (path.scaleX !== 1 || path.scaleY !== 1) {
        pathObject.scale(path.scaleX, path.scaleY);
      }
      if (!joinedPath) {
        joinedPath = pathObject;
      } else {
        joinedPath = joinedPath.unite(pathObject, { insert: false });
      }
    });
    return joinedPath;
  });

  fabric.Illustration.fromObject = function (object, callback) {
    new fabric.Illustration(object.objectName, object, (target) => {
      target.canvas && target.canvas.fire('object:modified', { target });
      callback(target);
    });
  };
}

const mapToList = (inputMap) => {
  return Array.from(inputMap).map(([key, value]) => ({
    key,
    value,
  }));
};
