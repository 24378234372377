import { forwardRef, useMemo } from 'react';

import ButtonBase from '../ButtonBase';
import {
  Content,
  Label,
  Caret,
  InputContent,
  ButtonContainer,
  IconWrapper,
} from './styles';
import Icon from '../../Icon/Icon';
import { primaryTheme } from './theme';
import IconEventWrapper from '../../Icon/IconEventWrapper';
import { SpinnerWrapper } from '../../Icon/styles';
import { StyledTheme } from '../../../types/StyledComponents';
import { ButtonProps } from './types';

const getHeight = (
  height?: string,
  themeHeight?: StyledTheme['height']
): string => {
  if (height) return height;
  if (themeHeight && typeof themeHeight === 'string') {
    return themeHeight;
  }
  return '32px'; // default
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      icon,
      loading,
      appendIcon,
      width,
      height,
      hasCaret,
      isCentered,
      isActive,
      disabled,
      isBlock,
      theme,
      onClick,
      type,
      as,
      dataTestId,
      href,
      target,
      style,
    },
    ref
  ) => {
    if (loading) {
      icon = { name: 'loading', height: icon?.height };
      disabled = true;
    }

    const _theme = useMemo(() => {
      return { ...(primaryTheme as StyledTheme), ...theme };
    }, [theme]);
    const iconHeight =
      icon?.height ?? (_theme.iconHeight as string | undefined);

    const renderCaret = (): JSX.Element => (
      <Caret>
        <Icon
          name="chevronRight"
          height="7px"
          theme={{
            ..._theme,
            color: _theme.color,
            activeColor: _theme.activeColor,
          }}
          isActive={isActive}
        />
      </Caret>
    );

    const renderIcon = (): JSX.Element | null =>
      icon ? (
        <IconWrapper theme={_theme}>
          <SpinnerWrapper theme={_theme}>
            <Icon
              name={icon.name}
              height={iconHeight}
              theme={{
                ..._theme,
                color: _theme.iconColor || _theme.color,
                disabledColor: _theme.iconDisabledColor || _theme.disabledColor,
                activeColor: _theme.iconActiveColor || _theme.activeColor,
                activeDefaultColor:
                  _theme.iconActiveDefaultColor || _theme.activeColor,
                secondaryColor:
                  _theme.iconSecondaryColor || _theme.secondaryColor,
              }}
              isActive={!disabled && isActive}
              disabled={disabled}
            />
          </SpinnerWrapper>
        </IconWrapper>
      ) : null;

    return (
      <ButtonBase
        as={as}
        href={href}
        target={target}
        width={width}
        height={getHeight(height, _theme.height)}
        isBlock={isBlock}
        disabled={disabled}
        theme={_theme}
        onClick={onClick}
        ref={ref}
        type={type}
        dataTestId={dataTestId}
        style={style}
      >
        <ButtonContainer
          theme={_theme}
          isActive={isActive}
          disabled={disabled}
          isLoading={loading}
        >
          <IconEventWrapper
            fitParent
            activeOnHover={!disabled}
            theme={{
              color: _theme.iconColor || _theme.color,
              disabledColor: _theme.iconDisabledColor || _theme.disabledColor,
              activeColor: _theme.iconActiveColor || _theme.activeColor,
              activeDefaultColor:
                _theme.iconActiveDefaultColor || _theme.activeColor,
              secondaryColor:
                _theme.iconSecondaryColor || _theme.secondaryColor,
            }}
          >
            <Content
              theme={_theme}
              isCentered={isCentered}
              isDisabled={disabled && !loading}
            >
              {/* InputContent is just a container for 'inputted' content,
               *  meaning label and icon. It's convenient for spacing.
               */}
              <InputContent>
                {icon && !appendIcon && renderIcon()}
                {label && (
                  <Label isActive={isActive} theme={_theme}>
                    {label}
                  </Label>
                )}
                {icon && appendIcon && renderIcon()}
              </InputContent>
              {hasCaret && renderCaret()}
            </Content>
          </IconEventWrapper>
        </ButtonContainer>
      </ButtonBase>
    );
  }
);

Button.defaultProps = {
  appendIcon: false,
  width: '122px',
  hasCaret: false,
  isActive: false,
  disabled: false,
  isCentered: true,
  isBlock: false,
};

export default Button;
