import styled from 'styled-components';

export const Background = styled.div`
  height: ${({ theme }) => theme.height}px;
  border-radius: ${({ theme }) => theme.height / 2}px;
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};
  position: relative;
`;

export const Foreground = styled.div<any>`
  position: absolute;
  left: 0;
  top: 0;
  height: ${({ theme }) => theme.height}px;
  border-radius: ${({ theme }) => theme.height / 2}px;
  background: ${({ theme }) => theme.foregroundColor};
  width: ${({ width }) => width}%;
  transition: width linear ${({ transitionDuration }) => transitionDuration}ms;
`;
