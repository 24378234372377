import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';

export const ColorSwatchWrapper = styled.button<any>`
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.buttonShadow};
  border: none;
  background: ${themeStyle.transparent};

  flex-shrink: 0;

  /* disable default button styles */
  padding: 0;
  outline: none;
  &:focus {
    outline: none;
  }

  &:before {
    content: '';
    position: absolute;
    display: ${({ isEmpty }) => (isEmpty ? 'block' : 'none')};
    top: 0;
    left: 8px;
    width: 3px;
    height: 20px;
    background-color: ${themeStyle.varAlertRed};
    transform: rotate(45deg);
    z-index: 50;
    pointer-events: none;
  }

  /**
   * highlight on isActive/:hover 
   * this is done via an :after element with an inset shadow instead of a border
   * so that the content of the ColorSwatch keeps the same dimensions
   */
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;

    box-shadow: ${({ isActive, theme }) =>
      isActive ? theme.activeButtonInnerShadow : 'none'};
  }

  &:hover:after {
    box-shadow: ${({ theme }) => theme.activeButtonInnerShadow};
  }

  html[data-theme='dark'] && {
    box-shadow: none;
  }
`;

export const InnerWrapper = styled.div<{ isEmpty: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  background: repeating-conic-gradient(white 0% 25%, black 0% 50%) 50% /
    calc(40px / 8) calc(40px / 8);

  overflow: visible;
`;

export const ColorTransparent = styled.div`
  height: 20px;
  width: 20px;
`;

export const HalfColorSolid = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  height: 10px;
  width: 20px;
`;
