import { themeStyle } from '../../services/theming';

export const forgotPasswordButtonTheme = {
  borderColor: themeStyle.transparent,
  borderActiveColor: themeStyle.transparent,
  backgroundColor: themeStyle.transparent,
  backgroundActiveColor: themeStyle.transparent,
  backgroundColorDark: themeStyle.transparent,
  borderColorDark: themeStyle.transparent,
  backgroundActiveColorDark: themeStyle.transparent,
  borderActiveColorDark: themeStyle.transparent,
  color: themeStyle.varBrandMain,
  activeColor: themeStyle.varBrandMedium,
};
