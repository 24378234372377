import React, { useState } from 'react';
import { LoaderWrapper, Option, OptionsContainer } from './styles';
import { MultiOptionProps } from './types';

const MultiOption: React.FC<MultiOptionProps> = (props) => {
  const keys = Object.keys(props.options);
  const [visible, setVisible] = useState(props.visibleCount ?? keys.length);

  const showAll = (): void => {
    setVisible(keys.length);
  };

  return (
    <>
      <OptionsContainer visible={visible}>
        {keys.map((key) => {
          const option = props.options[key];
          return (
            <Option
              key={key}
              dataTestId={key}
              selected={props.selected === key}
              onClick={(): void => props.select(key)}
            >
              {option.render ? option.render() : props.renderOption(key)}
            </Option>
          );
        })}
      </OptionsContainer>
      {props.renderLoader && (
        <LoaderWrapper visible={visible < keys.length} onClick={showAll}>
          {props.renderLoader()}
        </LoaderWrapper>
      )}
    </>
  );
};

export default MultiOption;
