import {
  Theme,
  themeStoreSelector,
  themeStyle,
  useThemeStore,
} from '../../services/theming';

export const getBoardBackground = (
  theme: Theme.LIGHT | Theme.DARK,
  trimView: boolean
): string =>
  trimView
    ? getTrimViewBoardBackground(theme)
    : getNonTrimViewBoardBackground(theme);

export const getTrimViewBoardBackground = (
  _theme?: Theme.LIGHT | Theme.DARK
): string => {
  const theme =
    _theme ?? themeStoreSelector.resolvedTheme(useThemeStore.getState());
  return theme === Theme.LIGHT
    ? themeStyle.inkSuperLight
    : themeStyle.darkBackgroundBase;
};

export const getNonTrimViewBoardBackground = (
  _theme?: Theme.LIGHT | Theme.DARK
): string => {
  const theme =
    _theme ?? themeStoreSelector.resolvedTheme(useThemeStore.getState());
  return theme === Theme.LIGHT
    ? themeStyle.backgroundAlt
    : themeStyle.darkBackgroundAlt;
};

export const getArtboardBorderColor = (): string => {
  const theme = themeStoreSelector.resolvedTheme(useThemeStore.getState());
  return theme === Theme.LIGHT ? themeStyle.inkLight : themeStyle.darkInkLight;
};
