import React from 'react';
import PropTypes from 'prop-types';
import BasePanel from './BasePanel/BasePanel';
import TextSettingsPanel from './TextSettingsPanel/TextSettingsPanel';
import TransformPanel from './TransformPanel/TransformPanel';
import PolygonPanel from './PolygonPanel/PolygonPanel';
import BackgroundPanel from './BackgroundPanel/BackgroundPanel';
import ColorPalettePanel from './ColorPalettePanel/ColorPalettePanel';
import TexturePanel from './TexturePanel/TexturePanel';
import { filterColorPalette } from '../../utils/editor/misc';
import { Layer } from '../../propTypes';
import ImageAdjustments from '../ImageAdjustments/ImageAdjustments';
import { isIpadOperatingSystem } from '../../utils/detection';
import { PanelWrapper } from '../RightMenu/styles';
import { getObjectLabel } from '../RightMenu/utils';
import LockedPanel from './LockedPanel/LockedPanel';
import { H6 } from '../utilities/Typography/styles';

const IMAGE_TYPES = ['image', 'illustrationImage', 'overlayTexture'];
const NON_BORDER_TYPES = [...IMAGE_TYPES, 'mask'];

const getPanelLabel = (options, background) => {
  const isImage = IMAGE_TYPES.includes(options?.type);
  const isMask = options?.type === 'mask';

  if (isImage) return 'Image Settings';
  if (isMask) return 'Mask Settings';
  return `${getObjectLabel(options, background)} Color`;
};

/**
 * The StylesMenu
 */
const StylesMenu = ({ options, background, dispatch, ...props }) => {
  const filteredColorPalette = filterColorPalette(props.colorPalette);

  if (options?.type === 'artboard') {
    // show style panels for the background/artboard
    return (
      <>
        <PanelWrapper>
          <BackgroundPanel
            options={background}
            colorPalette={filteredColorPalette}
            dispatch={dispatch}
          />
        </PanelWrapper>
        {props.overlay && (
          <PanelWrapper data-testid="texture-panel">
            <TexturePanel options={props.overlay} dispatch={dispatch} />
          </PanelWrapper>
        )}
      </>
    );
  } else if (options?.type === 'activeSelection') {
    const reducedColorPalette = Object.keys(props.colorPalette).reduce(
      (palette, key) => {
        const keyPairings = props.colorPalette[key];
        const selectedPairings = keyPairings.filter(({ id }) =>
          props.activeObjects.includes(id)
        );
        if (selectedPairings.length) {
          palette[key] = selectedPairings;
        }
        return filterColorPalette(palette, true);
      },
      {}
    );

    return (
      <>
        <PanelWrapper>
          <ColorPalettePanel
            label={'Group Colors'}
            isGroup
            colorPalette={reducedColorPalette}
            documentPalette={filteredColorPalette}
            layers={props.layers}
            selectedLayers={props.selectedLayers}
            dispatch={dispatch}
          />
        </PanelWrapper>
        {options.multiplePathTextEdit && (
          <PanelWrapper>
            <TextSettingsPanel
              options={options.characterStyles}
              dispatch={dispatch}
              isMultipleEdit
            />
          </PanelWrapper>
        )}
      </>
    );
  } else if (options && !options.locked) {
    // if there is a selected object that is not locked
    const isImage = IMAGE_TYPES.includes(options?.type);
    const showImageAdjustments = options.type === 'illustrationImage';

    return (
      <>
        <PanelWrapper>
          <BasePanel
            label={getPanelLabel(options, background)}
            options={options}
            colorPalette={filteredColorPalette}
            hasBorder={!NON_BORDER_TYPES.includes(options?.type)}
            hasColors={!isImage}
            hideOpacity={options?.type === 'mask'}
            dispatch={dispatch}
          />
        </PanelWrapper>

        {showImageAdjustments && !isIpadOperatingSystem() && (
          <PanelWrapper>
            <H6 style={{ paddingBottom: '13px' }}>ADJUSTMENTS</H6>
            <ImageAdjustments
              dispatch={dispatch}
              object={options}
              colorPalette={props.colorPalette}
            />
          </PanelWrapper>
        )}

        {options?.type === 'pathText' && (
          <>
            {/* style panels that only apply to pathText */}
            <PanelWrapper>
              <TextSettingsPanel
                options={options.characterStyles}
                dispatch={dispatch}
              />
            </PanelWrapper>
            <PanelWrapper>
              <TransformPanel
                options={{
                  ...options.transformOptions,
                  inPathEditMode: options.inPathEditMode,
                }}
                dispatch={dispatch}
              />
            </PanelWrapper>
          </>
        )}
        {options?.type === 'editablePolygon' && (
          <PanelWrapper>
            {/* style panel that only applies to editablePolygon */}
            <PolygonPanel dispatch={dispatch} />
          </PanelWrapper>
        )}
      </>
    );
  } else if (options?.locked) {
    return <LockedPanel id={options?.id} dispatch={dispatch} />;
  }
  return null;
};

StylesMenu.propTypes = {
  /**
   * current styles options
   */
  options: PropTypes.shape({
    type: PropTypes.string,
    locked: PropTypes.bool,
    colors: PropTypes.array,
    borderWeight: PropTypes.shape({}),
    characterStyles: PropTypes.shape({}),
    transformOptions: PropTypes.shape({}),
    id: PropTypes.string,
    multiplePathTextEdit: PropTypes.bool,
    inPathEditMode: PropTypes.bool,
    objectName: PropTypes.string,
  }),
  /**
   * background object, style of the artboard
   */
  background: PropTypes.shape({
    fill: PropTypes.string,
  }),
  /**
   * overlay object, style of the overlay
   */
  overlay: PropTypes.shape({
    texture: PropTypes.string,
    opacity: PropTypes.number,
    mode: PropTypes.string,
  }),
  /**
   * Object containing information about the color palette of the artboard
   */
  colorPalette: PropTypes.object,
  /**
   * A list if ids that indicate which objects are currently selected
   */
  activeObjects: PropTypes.arrayOf(PropTypes.string),
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
  layers: PropTypes.arrayOf(Layer),
  selectedLayers: PropTypes.arrayOf(PropTypes.string),
};

export default StylesMenu;
