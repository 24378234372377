import React, { useCallback } from 'react';
import Button from '../Button/Button/Button';
import { primaryTheme, secondaryTheme } from '../Button/Button/theme';
import ColorPicker from '../ColorPicker/ColorPicker';
import RightMenuHeader from '../utilities/RightMenuHeader/RightMenuHeader';
import { MockupMenuProps } from './types';
import tinycolor from 'tinycolor2';
import {
  DOWNLOAD,
  MOCKUP_SAVE_TO_UPLOADS,
  SET_BACKGROUND,
} from '../../global/events';
import useMockupStore, {
  mockupStoreSelector,
} from '../../stores/mockupStore/mockupStore';
import useDesignsStore, {
  designsStoreSelector,
} from '../../stores/designsStore';
import { TooltipAlignment } from '../Tooltip/types';
import Tooltip from '../Tooltip/Tooltip';
import { PanelWrapper } from '../RightMenu/styles';
import { ActionButtons } from './styles';

const MockupMenu: React.FC<MockupMenuProps> = (props) => {
  const activeDesignId = useDesignsStore(designsStoreSelector.activeDesign).id;
  const exporting = useMockupStore(mockupStoreSelector.exporting);
  const uploading = useMockupStore(mockupStoreSelector.uploading);

  const { dispatch } = props;
  const download = useCallback(() => {
    if (exporting) return;
    dispatch(DOWNLOAD, { activeDesignId });
  }, [dispatch, activeDesignId, exporting]);

  const onChange = useCallback(
    (isChanging: boolean) =>
      (colorString: string): void => {
        const color = tinycolor(colorString).toHexString();
        dispatch(SET_BACKGROUND, { color, isChanging });
      },
    [dispatch]
  );

  const backgroundColor = useMockupStore(
    mockupStoreSelector.state('backgroundColor')
  );

  const saveToUpload = useCallback(() => {
    if (uploading) return;
    dispatch(MOCKUP_SAVE_TO_UPLOADS, { activeDesignId });
  }, [dispatch, activeDesignId, uploading]);

  return (
    <div>
      <PanelWrapper>
        <RightMenuHeader label="Mockup Colors" />
        <ColorPicker
          dispatch={dispatch}
          defaultColor={backgroundColor}
          onChanging={onChange(true)}
          onChanged={onChange(false)}
          disableNoColor
          disableAlpha
        />
      </PanelWrapper>
      <ActionButtons gap="12px">
        <Button
          width="100%"
          height="40px"
          label="Download Mockup"
          theme={primaryTheme}
          onClick={download}
          loading={exporting}
        />
        <Tooltip text="Save to Uploads" align={TooltipAlignment.End}>
          <Button
            width="40px"
            height="40px"
            icon={{ name: 'upload', height: '20px' }}
            theme={secondaryTheme}
            loading={uploading}
            ariaLabel="Save to Uploads"
            onClick={saveToUpload}
          />
        </Tooltip>
      </ActionButtons>
    </div>
  );
};

export default MockupMenu;
