import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_SORT_ALL } from '../../global/constants';
import useElementsStore, {
  elementStoreSelector,
} from '../../stores/elementsStore';
import ElementGallery from '../ElementGallery/ElementGallery';

const ElementGroup = (props) => {
  const {
    groupId,
    elementType,
    collapsed,
    render,
    hide,
    sideEffectBeginTransition,
    sideEffectEndTransition,
    elementGalleryDimensions,
  } = props;

  const { name, total, elements, popularity } = useElementsStore(
    useCallback((state) => state[elementType][groupId], [elementType, groupId])
  );

  const sort = name === 'All' ? DEFAULT_SORT_ALL : props.sort;

  const fetchElements = useElementsStore(elementStoreSelector.fetch);

  const fetch = useRef((take) =>
    fetchElements(elementType, groupId, sort, take)
  );
  useEffect(() => {
    useElementsStore.subscribe(
      (state) =>
        (fetch.current = (take) =>
          state.fetch(elementType, groupId, sort, take))
    );
  }, [elementType, groupId, sort]);

  const galleryProps = {
    id: groupId,
    elements,
    labelLeft: name,
    total,
    sort,
    popularity,
    render,
    fetch: fetch.current,
    collapsed,
    dimensions: elementGalleryDimensions,
    hide,
    sideEffectBeginTransition,
    sideEffectEndTransition,
  };

  return <ElementGallery {...galleryProps} elements={elements} />;
};

ElementGroup.propTypes = {
  groupId: PropTypes.number,
  elementType: PropTypes.string,
  elementGalleryDimensions: PropTypes.object,
  collapsed: PropTypes.bool,
  render: PropTypes.func,
  hide: PropTypes.bool,
  sideEffectBeginTransition: PropTypes.func,
  sideEffectEndTransition: PropTypes.func,
  sort: PropTypes.string,
};

export default ElementGroup;
