import { themeStyle } from '../../services/theming';
import { TagTheme } from './types';

export const bigTheme: TagTheme = {
  height: '27px',
  iconHeight: '10px',
  backgroundColor: 'transparent',
  backgroundColorHover: themeStyle.varBackgroundAlt,
  borderColor: 'transparent',
  borderColorHover: themeStyle.varInkLight,
  fontSize: themeStyle.fontSize12,
  lineHeight: '15px',
  letterSpacing: '0.01em',
  borderRadius: '100px',
  padding: '0 14px',
};

export const specialTheme: TagTheme = {
  color: themeStyle.varBrandMain,
  colorHover: themeStyle.varBrandMain,
  borderColor: themeStyle.varBrandMain,
  backgroundColor: themeStyle.varBrandSuperLight,
  fontWeight: themeStyle.fontSemiBold,
  letterSpacing: '0.01em',
  borderRadius: '100px',
};
