import { saveAs } from 'file-saver';
import PDFDocument from 'pdfkit';
import blobStream from 'blob-stream';
import { encode } from 'html-entities';
import SVGtoPDF from '@heritage-type/svg-to-pdfkit';
import { changeDpiDataUrl } from 'changedpi';
import { computeFinalPixelSize } from '../utils/artboard';
import {
  DEFAULT_DOWNLOAD_DPI,
  SOCIAL_SHARE_TARGET_RESOLUTION,
  UNITS,
} from '../global/constants';
import rasterizeImage from './rasterizeImage/rasterizeImage';
import { downloadPanelStore } from '../stores/downloadPanelStore';
import distributedProgress from '../services/distributedProgress';
import { useToastStore } from '../stores/toastStore';
import monitoring from '../services/monitoring';
import {
  getSVGWithAdjustedOverlay,
  getSVGWithPostProcessedOutlines,
  getSVGWithSunkTransforms,
} from './svgPostProcessing';

const setDownloadProcessing = downloadPanelStore.getState().setProcessing;
const fireToast = useToastStore.getState().fire;

/*
 * If we are rendering for PDF and the object is PathText,
 * we render the drop shadow separately
 */
const handlePathTextPdfExport = async (obj, size, artboardOptions) => {
  // Get Isolated markup of drop shadow
  const dropShadowMarkup = obj.svgDropShadow();

  if (dropShadowMarkup) {
    const dropShadowIsCrisp = !obj.shadow.blur || obj.shadow.blur === 0;
    if (dropShadowIsCrisp) {
      // If drop shadow does not have blur, avoid rasterizing since quality suffers.
      // Just add a copy of the pathText and position it correctly

      // This properties will have to be restored
      const { decorationOptions, shadow, fill, stroke } = obj;

      // Get the object markup
      // We are exporting to PDF so shadow is not rendered since it is not supported
      const pathTextMarkup = obj.toSVG();

      // Get the drop shadow markup
      // For this, set stroke and fill to the color of the shadow and then get object markup
      // Also, get rid of decoration path
      obj.set('fill', shadow.color);
      obj.set('stroke', shadow.color);
      obj.setDecoration({ type: null });
      let dropShadowMarkup = obj.toSVG();

      // Restore properties
      obj.set({ fill, stroke });
      obj.setDecoration(decorationOptions);

      const { scale } = obj.getTextScale();

      dropShadowMarkup = `
        <g transform="translate(${shadow.offsetX * scale} ${
        shadow.offsetY * scale
      })">
      ${dropShadowMarkup}
        </g>
      `;

      return [dropShadowMarkup, pathTextMarkup];
    }

    // There is an actual drop shadow

    /*
     * Create an SVG with the same dimensions of the final one that wraps
     * the drop shadow markup.
     */
    const dropShadowSVG = createSVG(
      { objects: [dropShadowMarkup] },
      size,
      artboardOptions,
      true
    );

    // Render said svg in an auxiliary canvas

    const _canvas = document.createElement('canvas');
    const context = _canvas.getContext('2d');

    const image = new Image();
    await new Promise((resolve, reject) => {
      image.onload = function () {
        _canvas.width = this.width;
        _canvas.height = this.height;
        context.drawImage(this, 0, 0);
        resolve();
      };
      image.onerror = () => {
        reject('handlePathTextPdfExport: Error drawing dropShadow');
      };
      image.src =
        'data:image/svg+xml; charset=utf8, ' +
        encodeURIComponent(dropShadowSVG);
    });

    // Render the canvas to a png image and embed it in an svg image tag
    const dropShadowSVGImageElement = `
      <image width="100%" height="100%" xlink:href="${_canvas.toDataURL(
        'image/png'
      )}" />
    `;

    // Finally, get the object svg **without** the drop shadow
    const shadow = obj.shadow;
    obj.set('shadow', null);
    const pathTextMarkup = obj.toSVG();
    obj.set('shadow', shadow);

    return [dropShadowSVGImageElement, pathTextMarkup];
  }

  return obj.toSVG();
};

/**
 * get an svg file as string from a fabric.Canvas
 * @param {fabric.Canvas} canvas
 * @param {{removeBackground: Boolean}} options
 * @returns {string | null}
 */
export const getSVG = async (canvas, options) => {
  const { dpi, size } = options;
  const { width, height } = computeFinalPixelSize(size, dpi);
  const { artboardOptions, overlayTexture } = canvas;

  // `viewportToExportRatio` is used to determine final size of raster images
  const viewportToExportRatio = Math.min(
    artboardOptions.width / Math.round(width),
    artboardOptions.height / Math.round(height)
  );

  canvas.viewportToExportRatio = viewportToExportRatio;

  const thereIsOverlay = overlayTexture && !overlayTexture.hidden;
  const overlayIsClipped = thereIsOverlay && overlayTexture.renderClip;
  const overlayIsAlphaMask = thereIsOverlay && overlayTexture.isAlphaMask;

  const artboard = canvas.artboard;
  const objects = canvas.allObjects().filter((obj) => obj.type !== 'artboard');
  objects.forEach((obj) => {
    if (obj._objects?.length) {
      // encode ids for export (main target are paths of uploaded svgs)
      obj._objects.forEach((innerObj) => (innerObj.id = encode(innerObj.id)));
    }
  });

  const artboardMarkup = options.removeBackground ? '' : artboard.toSVG();

  if (overlayIsClipped && !overlayIsAlphaMask) {
    // We still can't handle this properly for SVG, so we just get rasterized version
    // and embed it inside an SVG
    const rasterized = await downloadImage(canvas, options, true);

    // rasterized can be undefined, if an error is handled inside
    if (!rasterized) return null;

    let dataUrl;
    if (rasterized.blob) {
      await new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = (res) => {
          dataUrl = res.target.result;
          resolve();
        };
        fr.onerror = () => {
          reject('getSVG: Error reading rasterized blob');
        };
        fr.readAsDataURL(rasterized.blob);
      });
    } else {
      dataUrl = rasterized.dataUrl;
    }

    return createSVG(
      {
        objects: [
          `<image width="100%" height="100%" xlink:href="${dataUrl}" />`,
        ],
      },
      size,
      artboardOptions
    );
  }

  // get object svgs
  const svgs = objects
    .map(async (obj) => {
      if (options.forPDF && obj.type === 'pathText') {
        return handlePathTextPdfExport(obj, size, artboardOptions);
      }
      return obj.toSVG();
    })
    .flat();

  // add overlay
  if (overlayTexture) {
    svgs.push(overlayTexture.toSVG());
  }

  return Promise.all(svgs).then((_svgs) =>
    createSVG(
      {
        artboard: artboardMarkup,
        objects: _svgs,
      },
      size,
      {
        ...artboardOptions,
        overlayIsAlphaMask,
        overlayIsClipped,
      }
    )
  );
};

export const downloadSVG = async (canvas, options) => {
  try {
    const completeSVG = await getSVG(canvas, options);
    if (completeSVG) {
      downloadFile(
        getFileName(canvas.config, 'svg'),
        'data:text/plain;charset=utf-8,' + encodeURIComponent(completeSVG)
      );
    } else {
      monitoring.captureException('downloadSVG: Got empty result from getSVG');
    }
  } catch (error) {
    monitoring.captureException(error);
    fireToast({
      label: 'There was an error while downloading SVG',
      duration: 3000,
      error: true,
    });
  } finally {
    setDownloadProcessing({ format: options.format, processing: false });
  }
};

export const downloadImage = async (canvas, _options, skipSave = false) => {
  const options = { ..._options };
  const fileName = getFileName(canvas.config, options.format);

  let response;

  if (options.optimizedQuality) {
    // progress after each step:
    // 0.2: supersampling canvas is ready
    // 0.4: destination canvas is ready from downsampling the supersampling canvas
    // 0.8: destination canvas blob data is ready
    // 1: file is saved
    distributedProgress.init([0, 0.2, 0.4, 0.8, 1]);

    try {
      const blob = await rasterizeImage.getBlobWithSupersampling(
        canvas,
        options
      );
      distributedProgress.proceed();

      // `blob` can be `null`, check reference here: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#parameters.
      // In that case, we fallback to the standard export.
      if (blob) {
        response = { blob, fileName };
        if (!skipSave) {
          saveAs(blob, fileName);
        }
      } else {
        const dataUrl = getLegacyImageDataURL(canvas, options);
        response = { dataUrl, fileName };
        if (!skipSave) {
          downloadFile(fileName, dataUrl);
        }
      }
    } catch (e) {
      // Optimized quality download failed, reset state and throw warning
      downloadPanelStore.getState().setOptions({ optimizedQuality: false });
      fireToast({
        label: `There was an error while downloading with optimized quality`,
        duration: 3000,
        error: true,
      });
      monitoring.captureException(e);
    } finally {
      distributedProgress.end();
      setDownloadProcessing({
        format: options.format,
        processing: false,
      });
    }
  } else {
    const dataUrl = getLegacyImageDataURL(canvas, options);
    response = { dataUrl, fileName };
    if (!skipSave) {
      downloadFile(fileName, dataUrl);
    }
    setDownloadProcessing({ format: options.format, processing: false });
  }

  return response;
};

const getLegacyImageDataURL = (canvas, options) => {
  let dataUrl = rasterizeImage.getDataUrl(canvas, options);
  dataUrl = changeDpiDataUrl(dataUrl, options.dpi);
  return dataUrl;
};

const getPdfBlobFromSvg = async (svg, width, height, unit) =>
  new Promise((resolve, reject) => {
    // Create a document
    const doc = new PDFDocument({
      font: null,
      size: [width, height],
      info: {
        Producer: 'Kittl',
        Creator: 'Kittl',
        Title: 'Artboard',
        Author: 'Unknown Author',
      },
    });
    // pipe the document to a blob
    const stream = doc.pipe(blobStream());
    // add the svg to the doc
    SVGtoPDF(doc, svg, 0, 0, { width, height, assumePt: unit === UNITS.px });
    // get a blob and download it
    doc.end();
    stream.on('error', () => {
      reject('getPdfBlobFromSvg: Error writing stream');
    });
    stream.on('finish', () => {
      const blob = stream.toBlob('application/pdf');
      resolve(blob);
    });
  });

export const downloadPDF = async (canvas, options) => {
  options.forPDF = true;

  try {
    let svg = await getSVG(canvas, options);

    if (!svg) {
      monitoring.captureException('downloadPDF: Got empty result from getSVG');
    } else {
      svg = getSVGWithSunkTransforms(
        svg,
        canvas
          .getObjects()
          .filter(({ clipPathMaskId }) => !!clipPathMaskId)
          .map(({ id }) => id)
      );
      svg = getSVGWithPostProcessedOutlines(svg, canvas);
      svg = await getSVGWithAdjustedOverlay(svg, canvas);

      const { size } = options;

      // Please note: PDFKit works with 72 dpi by default,
      // so if we want a 1 inch width we should set it to 72.
      // See: https://github.com/foliojs/pdfkit/issues/268
      const { width, height } = computeFinalPixelSize(
        size,
        DEFAULT_DOWNLOAD_DPI
      );
      const blob = await getPdfBlobFromSvg(svg, width, height, size.unit);
      downloadFile(
        getFileName(canvas.config, 'pdf'),
        URL.createObjectURL(blob)
      );
    }
  } catch (error) {
    monitoring.captureException(error);
    fireToast({
      label: 'There was an error while downloading PDF',
      duration: 3000,
      error: true,
    });
  } finally {
    setDownloadProcessing({ format: options.format, processing: false });
  }
};

/**

Utilities for downloading and exporting

 */

const getFileName = (config, format) =>
  `${config?.title || 'artboard'}.${format}`;

/**
 * Download a file in the browser
 * @param {*} fileName name of the file
 * @param {*} href encoded content of the file
 */
const downloadFile = (fileName, href) => {
  const element = document.createElement('a');
  element.setAttribute('href', href);
  element.setAttribute('download', fileName);
  element.setAttribute('target', '_blank');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  URL.revokeObjectURL(href);
  document.body.removeChild(element);
};

export const createSVG = (svgs, size, options, inline = false) => {
  const { artboard, objects } = svgs;

  const finalWidth = `${size.width}${size.unit}`;
  const finalHeight = `${size.height}${size.unit}`;

  const renderSVGs = (artboard, objects) => {
    if (options?.overlayIsAlphaMask) {
      return `
        ${options?.overlayIsClipped ? artboard || '' : ''}
        <g mask="url(#kittl-overlay-mask)">
          ${artboard}
          ${objects.join('')}
        </g>
      `;
    }
    return [artboard || '', ...objects].join('');
  };

  return `${inline ? '' : '<?xml version="1.0" encoding="UTF-8"?>'}
  <svg width="${finalWidth}" height="${finalHeight}" viewBox="0 0 ${
    options.width
  } ${
    options.height
  }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  ${renderSVGs(artboard, objects)}
  </svg>`;
};

/**
 * get a preview image for shares
 * @param {*} state state of the design to share
 * @param {*} canvas reference to the current canvas to get the image from
 */
export const getSocialSharePreview = (state, canvas) => {
  let {
    artboardOptions: { width: shareWidth, height: shareHeight },
  } = state;

  const biggestSide = Math.max(shareWidth, shareHeight);
  const scale = SOCIAL_SHARE_TARGET_RESOLUTION / biggestSide;

  shareWidth *= scale;
  shareHeight *= scale;

  return rasterizeImage.getDataUrl(canvas, {
    format: 'png',
    size: {
      width: shareWidth,
      height: shareHeight,
      unit: 'px',
    },
  });
};
