import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { ARTBOARD_SETTINGS_KEY } from '../global/constants';

const getSettings = () => {
  const localStorageSettings = localStorage.getItem(ARTBOARD_SETTINGS_KEY);
  if (localStorageSettings) return JSON.parse(localStorageSettings);
  return null;
};

const saveSettings = (settings) => {
  localStorage.setItem(ARTBOARD_SETTINGS_KEY, JSON.stringify(settings));
  return localStorage.getItem(ARTBOARD_SETTINGS_KEY);
};

export const settingsStore = createVanilla((set, get) => ({
  settings: {
    hideTransparencyGrid: false,
    showGrid: false,
    gridSize: 100,
    trimView: true,
  },

  /**
   * initialize the store
   */
  init: async () => {
    const localSettings = getSettings();

    if (localSettings) {
      set({ settings: localSettings });
    } else {
      const settings = get().settings;
      saveSettings(settings);
    }
  },
  updateSettings: (newSettings) => {
    const updatedSettings = { ...get().settings, ...newSettings };
    set({ settings: updatedSettings });
    saveSettings(updatedSettings);
  },
}));

export const settingsStoreSelector = {
  init: (state) => state.init,
  showGrid: (state) => state.settings.showGrid,
  gridSize: (state) => state.settings.gridSize,
  trimView: (state) => state.settings.trimView,
};

export const useSettingsStore = create(settingsStore);
