import { nanoid } from 'nanoid';
import { isElementGrouped } from '../../../utils/groupStructure';
import { themeStyle } from '../../../services/theming';
import { isDebugActive } from '../../../utils/dev';
import { makeDebugCircle, makeDebugText } from '../../../utils/dev/fabric';
import { getBounds } from '../../../utils/editor/objects';

const DEFAULT_OPTIONS = {
  fill: '#000',
  stroke: '#C1C1C1',
  strokeWidth: 0,
  // one of 'butt', 'round' or 'square' (see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-linecap)
  strokeLineCap: 'round',
  // one of 'miter', 'round' or 'bevel' (see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-linejoin)
  strokeLineJoin: 'miter',
  strokeMiterLimit: 10,
  touchCornerSize: 40,
  cornerSize: 10,
};

const SNAP_ANGLE = 15;
const ALIASING_LIMIT = 2; // it's used in _getCacheCanvasDimensions
const HALF = 0.5; // it's used in _updateCacheCanvas
const DOUBLE = 2; // it's used in _getCacheCanvasDimensions

export default function (fabric) {
  const fireEventWithCommonInfo = (eventName, eventData, transform, x, y) => {
    fabric.controlsUtils.fireEvent(eventName, {
      e: eventData,
      transform: transform,
      pointer: {
        x,
        y,
      },
    });
  };

  // Modify default rotation handler to support angle snapping while shift is pressed
  const rotationActionHandler = (eventData, transform, x, y) => {
    if (eventData['shiftKey']) {
      if (transform.target.snapAngle !== SNAP_ANGLE) {
        // save the actual snapAngle value
        transform.target._snapAngle = transform.target.snapAngle;
      }
      transform.target.snapAngle = SNAP_ANGLE;
    } else {
      // restore the actual snapAngle value
      transform.target.snapAngle = transform.target._snapAngle || 0;
    }

    const result = fabric.controlsUtils.rotationWithSnapping(
      eventData,
      transform,
      x,
      y
    );

    // Here the position of the object is updated after the action, could be useful when real-time position is needed.
    // Ref: https://github.com/fabricjs/fabric.js/blob/f858df562d93f876872f3b49caba2435f343eb98/src/controls.actions.js#L725
    fireEventWithCommonInfo('rotating:updated', eventData, transform, x, y);

    return result;
  };

  const scalingEquallyHandler = (eventData, transform, x, y) => {
    const result = fabric.controlsUtils.scalingEqually(
      eventData,
      transform,
      x,
      y
    );

    // Here the position of the object is updated after the action, could be useful when real-time position is needed.
    // Ref: https://github.com/fabricjs/fabric.js/blob/f858df562d93f876872f3b49caba2435f343eb98/src/controls.actions.js#L725
    fireEventWithCommonInfo('scaling:updated', eventData, transform, x, y);

    return result;
  };

  const scalingYOrSkewingXHandler = (eventData, transform, x, y) => {
    const result = fabric.controlsUtils.scalingYOrSkewingX(
      eventData,
      transform,
      x,
      y
    );

    // Here the position of the object is updated after the action, could be useful when real-time position is needed.
    // Ref: https://github.com/fabricjs/fabric.js/blob/f858df562d93f876872f3b49caba2435f343eb98/src/controls.actions.js#L725
    fireEventWithCommonInfo('skewing:updated', eventData, transform, x, y);

    return result;
  };

  const scalingXOrSkewingYHandler = (eventData, transform, x, y) => {
    const result = fabric.controlsUtils.scalingXOrSkewingY(
      eventData,
      transform,
      x,
      y
    );

    fabric.controlsUtils.fireEvent('skewing:updated', {
      e: eventData,
      transform: transform,
      pointer: {
        x,
        y,
      },
    });

    return result;
  };

  delete fabric.Object.prototype.controls.mtr;

  // Check if object has a custom group
  fabric.Object.prototype.hasGroup = function () {
    return isElementGrouped(this.canvas.groupStructure, this.id);
  };

  /**
   * Defines the number of fraction digits to use when serializing object values.
   * You can use it to increase/decrease precision of such values like left, top, scaleX, scaleY, etc.
   * @type Number
   */
  fabric.Object.NUM_FRACTION_DIGITS = 6; // the fabric default is 2

  // Extensions
  // below are the functions that we extend by adding extra functionality

  // Add properties to toObject method and remove those that are unused
  fabric.Object.prototype.toObject = (function (toObject) {
    return function (propertiesToIncludeOpt) {
      const propertiesToInclude = [
        'id',
        'locked',
        'hidden',
        'linkedToObject',
        'name',
        ...(propertiesToIncludeOpt || []),
      ];
      const object = toObject.apply(this, [propertiesToInclude]);

      if (object.originX === 'left') {
        delete object.originX;
      }

      if (object.originY === 'top') {
        delete object.originY;
      }

      const unusedCommonAttrs = [
        'visible', // we have `hidden`
        'backgroundColor',
      ];
      unusedCommonAttrs.forEach((attr) => {
        delete object[attr];
      });

      // delete attributes with default value
      if (object.fillRule === 'nonzero') {
        delete object.fillRule;
      }
      if (object.paintFirst === 'fill') {
        delete object.paintFirst;
      }
      if (object.strokeLineCap === 'butt') {
        delete object.strokeLineCap;
      }
      if (object.strokeLineJoin === 'miter') {
        delete object.strokeLineJoin;
      }
      if (object.strokeDashArray === null) {
        delete object.strokeDashArray;
      }
      if (object.strokeDashOffset === 0) {
        delete object.strokeDashOffset;
      }
      if (object.strokeMiterLimit === 4) {
        delete object.strokeMiterLimit;
      }

      return object;
    };
  })(fabric.Object.prototype.toObject);

  // apply locked property on creating object
  fabric.Object._fromObject = (function (fromObject) {
    return function (className, object, callback, extraParam) {
      return fromObject.apply(this, [
        className,
        {
          ...object,
          visible: !object.hidden,
          hasControls: !object.locked,
          selectable: !object.locked,
          lockMovementX: object.locked,
          lockMovementY: object.locked,
        },
        callback,
        extraParam,
      ]);
    };
  })(fabric.Object._fromObject);

  fabric.Object.prototype._set = (function (_set) {
    return function (key, value) {
      _set.bind(this)(key, value);
      if (key === 'name') {
        this.canvas?.fire('object:modified');
      }
    };
  })(fabric.Object.prototype._set);

  fabric.Object.prototype.initialize = (function (initialize) {
    return function (options) {
      options = { ...DEFAULT_OPTIONS, ...(options || {}) };
      // add id on initialization of object
      options.id = options.id || nanoid();
      // add border and color properties for selection
      options.borderColor = themeStyle.selectionBlue;
      options.borderScaleFactor = 1;
      options.strokeUniform = true;
      options.noScaleCache = false;
      options.cornerStyle = 'square';
      options.cornerStrokeColor = themeStyle.selectionBlue;
      options.cornerColor = themeStyle.backgroundAlt;
      options.transparentCorners = false;
      // We don't want most objects to be non-uniformly scaled
      this.setControlsVisibility({
        mr: false,
        mt: false,
        ml: false,
        mb: false,
      });
      return initialize.apply(this, [options]);
    };
  })(fabric.Object.prototype.initialize);

  // apply locked property on setting it
  fabric.Object.prototype._set = (function (_set) {
    return function (key, value) {
      _set.apply(this, [key, value]); // apply default _set(key, value)

      // update locked an hidden status of object
      if (key === 'locked' || key === 'lockedParent') {
        this._updateLocked();
      } else if (key === 'hidden' || key === 'hiddenParent') {
        this._updateHidden();
      }

      return this; // default return of _set
    };
  })(fabric.Object.prototype._set);

  fabric.Object.prototype._updateLocked = function () {
    const isLocked = this.locked || this.lockedParent;
    this.set('hasControls', !isLocked);
    this.set('selectable', !isLocked);
    this.set('lockMovementX', isLocked);
    this.set('lockMovementY', isLocked);
  };

  fabric.Object.prototype._updateHidden = function () {
    const isHidden = this.hidden || this.hiddenParent;
    this.set('visible', !isHidden);
  };

  // Function used as a fallback, needs to be implemented by every object, that has editable colors
  // response looks like: `[{key: 'fill', value: 'rgba(0,0,0,1)'}]`
  fabric.Object.prototype.getColors = function () {
    const colors = [
      {
        key: 'fill',
        value: this.fill,
        visible: !!this.fill,
      },
      {
        key: 'stroke',
        value: this.stroke,
        visible: this.stroke && !!this.strokeWidth,
      },
    ];
    return colors;
  };

  // Function used as a fallback, needs to be implemented by every object, that has editable colors
  fabric.Object.prototype.setColor = function (key, value) {
    if (key === 'fill') {
      this.set('fill', value);
    } else if (key === 'stroke') {
      this.set('stroke', value);
    }
  };

  fabric.Object.prototype.setAllColors = function (value) {
    const colors = this.getColors();
    colors.map(({ key }) => this.setColor(key, value));
  };

  /**
   * Default reflect, should be implemented by individual objects if custom behavior is required
   * @param {String} direction - if the object should be reflected horizontal (xAxis) or vertical (yAxis)
   */
  fabric.Object.prototype.reflect = function (direction = 'horizontal') {
    const flipKey = direction === 'horizontal' ? 'flipX' : 'flipY';
    const originalFlipValue = this.get(flipKey);
    this.set(flipKey, !originalFlipValue);
    this.rotate(-this.angle); // also flip angle
  };

  fabric.Object.prototype.align = function (value) {
    const artboard = this.canvas?.artboard;
    if (!artboard) return;
    const rect = this.getBoundingRect(true);
    // calculate offset between relative and absolute top/left
    // that's needed for rotated objects
    const topOffset = this.top - rect.top;
    const leftOffset = this.left - rect.left;
    if (value === 'left') {
      this.left = leftOffset;
    } else if (value === 'center') {
      this.left = leftOffset + artboard.width / 2 - rect.width / 2;
    } else if (value === 'right') {
      this.left = leftOffset + artboard.width - rect.width;
    } else if (value === 'top') {
      this.top = topOffset;
    } else if (value === 'middle') {
      this.top = topOffset + artboard.height / 2 - rect.height / 2;
    } else if (value === 'bottom') {
      this.top = topOffset + artboard.height - rect.height;
    }
    this.handleOnMove();
    this.setCoords(); // update acoords after changing position
  };

  fabric.Object.prototype.handleOnMove = function () {};

  fabric.Object.prototype.handleRotation = function () {};

  /**
   * function to always return the absolute top/left coordinates of the object in relation to the canvas
   * see also: https://github.com/fabricjs/fabric.js/issues/801#issuecomment-571103825
   * @returns {{top: Number, left: Number}}
   */
  fabric.Object.prototype.getAbsoluteTopLeft = function () {
    if (this.group) {
      const matrix = this.calcTransformMatrix();
      const point = {
        x: ((this.flipX ? 1 : -1) * this.width) / 2,
        y: ((this.flipY ? 1 : -1) * this.height) / 2,
      }; // top/left relative to center
      const absolutePoint = fabric.util.transformPoint(point, matrix); // transform point
      return { top: absolutePoint.y, left: absolutePoint.x };
    } else {
      return { top: this.top, left: this.left };
    }
  };

  /**
   * Returns the absolute top left coordinates of the object
   * @return {fabric.Point}
   */
  fabric.Object.prototype.getAbsoluteTopLeftPoint = function () {
    const topLeft = this.getAbsoluteTopLeft();
    return new fabric.Point(topLeft.left, topLeft.top);
  };

  /**
   * Returns the real absolute center coordinates of the object
   * This function is agnostic to the objects origin parameter
   * @return {fabric.Point}
   */
  fabric.Object.prototype.getAbsoluteCenterPoint = function () {
    const matrix = this.calcTransformMatrix();
    const point = { x: 0, y: 0 }; // center
    return fabric.util.transformPoint(point, matrix); // transform point
  };

  // similar to `calcACoords`, but also works in groups
  fabric.Object.prototype.getAbsoluteCoords = function () {
    const matrix = this.calcTransformMatrix();
    const w = ((this.flipX ? -1 : 1) * this.width) / 2;
    const h = ((this.flipY ? -1 : 1) * this.height) / 2;

    return {
      // corners
      tl: fabric.util.transformPoint({ x: -w, y: -h }, matrix),
      tr: fabric.util.transformPoint({ x: w, y: -h }, matrix),
      bl: fabric.util.transformPoint({ x: -w, y: h }, matrix),
      br: fabric.util.transformPoint({ x: w, y: h }, matrix),
    };
  };

  /*
    Overrides
    below are the functions that we completely override,
    due to inability to extend them.
    Wherever possible changes/changed lines are mentioned
  */

  // Override _getCacheCanvasDimensions to double the cache canvas dims
  // that's needed because of the trick we do in `_updateCacheCanvas`
  fabric.Object.prototype._getCacheCanvasDimensions = function () {
    const objectScale = this.getTotalObjectScaling();
    // caculate dimensions without skewing
    const dim = this._getTransformedDimensions(0, 0, true);
    const neededX = ((dim.x * objectScale.scaleX) / this.scaleX) * DOUBLE;
    const neededY = ((dim.y * objectScale.scaleY) / this.scaleY) * DOUBLE;
    return {
      // for sure this ALIASING_LIMIT is slightly creating problem
      // in situation in which the cache canvas gets an upper limit
      // also objectScale contains already scaleX and scaleY
      width: neededX + ALIASING_LIMIT,
      height: neededY + ALIASING_LIMIT,
      zoomX: objectScale.scaleX,
      zoomY: objectScale.scaleY,
      x: neededX,
      y: neededY,
    };
  };

  /*
   * Calculate object bounding box dimensions from its properties scale, skew.
   * @param {Number} skewX, a value to override current skewX
   * @param {Number} skewY, a value to override current skewY
   * @param {Boolean} includeStroke, a value to tell `_finalizeDimensions` to include stroke
   * @private
   * @return {Object} .x width dimension
   * @return {Object} .y height dimension
   * @override to add includeStroke logic for cashed canvas dimensions
   */
  fabric.Object.prototype._getTransformedDimensions = function (
    skewX,
    skewY,
    includeStroke
  ) {
    if (typeof skewX === 'undefined') {
      skewX = this.skewX;
    }
    if (typeof skewY === 'undefined') {
      skewY = this.skewY;
    }
    let dimensions, dimX, dimY;
    const noSkew = skewX === 0 && skewY === 0;

    if (this.strokeUniform) {
      dimX = this.width;
      dimY = this.height;
    } else {
      dimensions = this._getNonTransformedDimensions();
      dimX = dimensions.x;
      dimY = dimensions.y;
    }
    if (noSkew) {
      return this._finalizeDimensions(
        dimX * this.scaleX,
        dimY * this.scaleY,
        includeStroke
      );
    }
    const bbox = fabric.util.sizeAfterTransform(dimX, dimY, {
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      skewX: skewX,
      skewY: skewY,
    });
    return this._finalizeDimensions(bbox.x, bbox.y, includeStroke);
  };

  /* eslint-disable */
  /*
    Override _updateCacheCanvas to reduce the amount of cache redraws.
    Small summary of changes:
    `zoomChanged` and `dimensionsChanged` are now set only if change diff is greater than 50%
    `shouldResizeCanvas` is completely removed and replaced by `shouldRedraw`
    `cappedChanged` introduced to update cache when max cache size is reached
  */
  fabric.Object.prototype._updateCacheCanvas = function () {
    const targetCanvas = this.canvas;
    if (this.noScaleCache && targetCanvas && targetCanvas._currentTransform) {
      const target = targetCanvas._currentTransform.target;
      const action = targetCanvas._currentTransform.action;
      if (this === target && action.slice && action.slice(0, 5) === 'scale') {
        return false;
      }
    }
    const canvas = this._cacheCanvas;
    const dims = this._limitCacheSize(this._getCacheCanvasDimensions());
    const minCacheSize = fabric.minCacheSideLimit;
    const width = dims.width;
    const height = dims.height;
    let drawingWidth;
    let drawingHeight;
    const zoomX = dims.zoomX;
    const zoomY = dims.zoomY;

    let zoomChanged, dimensionsChanged;
    const zoomXDiff = Math.abs(this.zoomX - zoomX);
    const zoomYDiff = Math.abs(this.zoomY - zoomY);
    const widthDiff = Math.abs(this.cacheWidth - width);
    const heightDiff = Math.abs(this.cacheHeight - height);

    zoomChanged =
      zoomXDiff > this.zoomX * HALF ||
      Number.isNaN(zoomXDiff) ||
      zoomYDiff > this.zoomY * HALF ||
      Number.isNaN(zoomYDiff);
    dimensionsChanged =
      widthDiff > this.cacheWidth * HALF ||
      heightDiff > this.cacheHeight * HALF ||
      Number.isNaN(widthDiff) ||
      Number.isNaN(heightDiff);

    // check if capped changed, if it did, we can redraw.
    const cappedChanged = this.cacheDimsCapped !== dims.capped;

    const shouldRedraw = dimensionsChanged || zoomChanged || cappedChanged;
    let additionalWidth = 0;
    let additionalHeight = 0;
    if (
      dimensionsChanged &&
      !dims.capped &&
      (width > minCacheSize || height > minCacheSize)
    ) {
      additionalWidth = width * 0.1;
      additionalHeight = height * 0.1;
    }
    if (shouldRedraw) {
      this.cacheDimsCapped = dims.capped; // that's introduced by us
      canvas.width = Math.ceil(width + additionalWidth);
      canvas.height = Math.ceil(height + additionalHeight);
      drawingWidth = dims.x / 2;
      drawingHeight = dims.y / 2;
      this.cacheTranslationX =
        Math.round(canvas.width / 2 - drawingWidth) + drawingWidth;
      this.cacheTranslationY =
        Math.round(canvas.height / 2 - drawingHeight) + drawingHeight;
      this.cacheWidth = width;
      this.cacheHeight = height;
      this._cacheContext.translate(
        this.cacheTranslationX,
        this.cacheTranslationY
      );
      this._cacheContext.scale(zoomX, zoomY);
      this.zoomX = zoomX;
      this.zoomY = zoomY;
      return true;
    }
    return false;
  };
  /* eslint-enable */

  fabric.Object.prototype.isNotVisible = (function (isNotVisible) {
    return function () {
      if (this.isClipPath) return false; // clipping object with opacity 0 should be visible
      return isNotVisible.bind(this)();
    };
  })(fabric.Object.prototype.isNotVisible);

  // Override render method to skip cache canvas removal
  // `this._cacheIsDirty` is introduced to track if cache needs to be updated between cacheable/non-cacheable states
  // `opts` arguments is added to override some rendering behaviours
  fabric.Object.prototype.render = function (ctx, opts = {}) {
    // do not render if width/height are zeros or object is not visible
    if (this.isNotVisible()) {
      return;
    }
    if (
      this.canvas &&
      this.canvas.skipOffscreen &&
      !this.group &&
      !this.isOnScreen() &&
      !opts?.renderIfOffScreen // <-- That's what we added
    ) {
      return;
    }
    const colors = this.getColors();
    if (opts?.ignoreFill) {
      this.setAllColors('black');
    }
    ctx.save();
    this._setupCompositeOperation(ctx);
    this.drawSelectionBackground(ctx);
    this.transform(ctx);
    this._setOpacity(ctx);
    this._setShadow(ctx, this);
    if (this.shouldCache()) {
      this.dirty = this.dirty || this._cacheIsDirty; // <-- That's what we added
      this._cacheIsDirty = false; // <-- That's what we added
      this.renderCache();
      this.drawCacheOnCanvas(ctx);
    } else {
      this._cacheIsDirty = this.dirty || this._cacheIsDirty; // <-- That's what we added
      // this._removeCacheCanvas(); // <-- That's what we removed
      this.dirty = false;
      this.drawObject(ctx);
      if (this.objectCaching && this.statefullCache) {
        this.saveState({ propertySet: 'cacheProperties' });
      }
    }
    ctx.restore();
    if (opts?.ignoreFill) {
      colors.map(({ key, value }) => this.setColor(key, value));
    }
  };

  // Override clone function with extra argument `propertiesToExclude`,
  // allowing removal of unwanted properties from the cloned object
  fabric.Object.prototype.clone = function (
    callback,
    propertiesToInclude,
    propertiesToExclude = []
  ) {
    const objectForm = this.toObject(propertiesToInclude);
    propertiesToExclude.forEach((prop) => delete objectForm[prop]);
    if (this.constructor.fromObject) {
      this.constructor.fromObject(objectForm, callback);
    } else {
      fabric.Object._fromObject('Object', objectForm, callback);
    }
  };
  /* eslint-enable */

  /**
   * Return the object opacity counting also the group property
   * Override to handle trueRender case
   * @return {Number}
   */
  fabric.Object.prototype.getObjectOpacity = function () {
    let opacity = this.opacity;
    // `&& !this.group.trueRender` was added below
    if (this.group && !this.group.trueRender) {
      opacity *= this.group.getObjectOpacity();
    }
    return opacity;
  };

  // Add rotate control
  const rotateControlSize = 26;
  const img = document.createElement('img');
  img.src = '/icons/canvas/rotate.svg';
  fabric.Object.prototype.controls.rotate = new fabric.Control({
    x: 0,
    y: 0.5,
    offsetY: 25,
    cornerSize: rotateControlSize,
    sizeX: rotateControlSize,
    sizeY: rotateControlSize,
    actionHandler: rotationActionHandler,
    actionName: 'rotate',
    cursorStyle: 'grabbing',
    render: function (ctx, left, top) {
      ctx.save();
      ctx.translate(left, top);
      ctx.shadowBlur = 15;
      ctx.shadowOffsetY = 8;
      ctx.shadowColor = 'rgba(0,0,0,0.08)';
      ctx.drawImage(
        img,
        -rotateControlSize / 2,
        -rotateControlSize / 2,
        rotateControlSize,
        rotateControlSize
      );
      ctx.restore();
    },
  });

  fabric.Object.prototype.controls.tl.actionHandler = scalingEquallyHandler;
  fabric.Object.prototype.controls.tr.actionHandler = scalingEquallyHandler;
  fabric.Object.prototype.controls.bl.actionHandler = scalingEquallyHandler;
  fabric.Object.prototype.controls.br.actionHandler = scalingEquallyHandler;

  /*
    We override this since to calculate the corners of a control, fabric takes object rotation into account,
    and for edit transform control, we actually don't want that, because the control doesn't rotate with the object,
    so in that case we set angle to 0. In every other case this function remains unchanged.
  */
  fabric.Object.prototype._setCornerCoords = function () {
    const coords = this.oCoords;

    for (const control in coords) {
      const angle = control === 'editTransform' ? 0 : this.angle; //---> We added this
      const controlObject = this.controls[control];
      coords[control].corner = controlObject.calcCornerCoords(
        angle,
        this.cornerSize,
        coords[control].x,
        coords[control].y,
        false
      );
      coords[control].touchCorner = controlObject.calcCornerCoords(
        angle,
        this.touchCornerSize,
        coords[control].x,
        coords[control].y,
        true
      );
    }
  };

  fabric.Object.prototype.toSVG = (function (toSVG) {
    return function (reviver) {
      let markup = [toSVG.bind(this)(reviver)];
      if (this.allowCompositeOperation && this.globalCompositeOperation) {
        markup = [
          '<g style="mix-blend-mode:' + this.globalCompositeOperation + '">',
          markup[0],
          '</g>',
        ];
      }
      return markup.join('');
    };
  })(fabric.Object.prototype.toSVG);

  /**
   * Gets absolute line coordinates of object
   * @returns {Object} Line coordinates as segments provided by @method _getImageLines
   */
  fabric.Object.prototype.getLineCoordinates = function () {
    let coords;

    const { group } = this;
    if (group) {
      const vpt = this.getViewportTransform();
      const groupTransform = group.calcTransformMatrix();
      const finalTransform = fabric.util.multiplyTransformMatrices(
        vpt,
        groupTransform
      );
      coords = this._getCoords(true, true);
      coords.tl = fabric.util.transformPoint(coords.tl, finalTransform);
      coords.tr = fabric.util.transformPoint(coords.tr, finalTransform);
      coords.bl = fabric.util.transformPoint(coords.bl, finalTransform);
      coords.br = fabric.util.transformPoint(coords.br, finalTransform);
    } else {
      coords = this._getCoords(false, true);
    }

    return this._getImageLines(coords);
  };

  const corners = [
    {
      name: 'mr',
      x: 0.5,
      y: 0,
    },
    {
      name: 'ml',
      x: -0.5,
      y: 0,
    },
    {
      name: 'mt',
      x: 0,
      y: -0.5,
    },
    {
      name: 'mb',
      x: 0,
      y: 0.5,
    },
  ];
  corners.forEach((corner) => {
    const isHorizontal = ['ml', 'mr'].includes(corner.name);

    // create icon
    const icon = document.createElement('img');
    const iconName = isHorizontal ? 'horizontal' : 'vertical';
    icon.src = `/icons/canvas/corner-${iconName}.svg`;

    // define by width and height of image
    const largeSide = 45;
    const smallSide = 10;

    // set control
    fabric.Object.prototype.controls[corner.name] = new fabric.Control({
      x: corner.x,
      y: corner.y,
      offsetY: 0, // offset
      offsetX: 0, // offset
      cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
      actionHandler:
        corner.name === 'ml' || corner.name === 'mr'
          ? scalingXOrSkewingYHandler
          : scalingYOrSkewingXHandler,
      getActionName: fabric.controlsUtils.scaleOrSkewActionName,
      render: function (ctx, left, top, styleOverride, fabricObject) {
        const size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        // icon, x, y, width, height
        ctx.drawImage(
          icon,
          isHorizontal ? -smallSide / 2 : -size / 2, //x
          isHorizontal ? -size / 2 : -smallSide / 2, //y
          isHorizontal ? smallSide : size, // width
          isHorizontal ? size : smallSide // height
        );
        ctx.restore();
      },
      cornerSize: largeSide,
      sizeX: isHorizontal ? smallSide : largeSide,
      sizeY: isHorizontal ? largeSide : smallSide,
      withConnection: false,
    });
  });

  /**
   * position this object relative to another one.
   * With a scale of 1, this object should cover the other object
   */
  fabric.Object.prototype.positionRelativeToObject = function (
    object,
    scale = 1
  ) {
    // reset rotation for easier calculations
    const angle = object.angle;
    this.rotate(0);
    this.setCoords();
    object.rotate(0);
    object.setCoords();

    const objectCenter = object.getAbsoluteCenterPoint();
    const { x: objectWidth, y: objectHeight } =
      object._getTransformedDimensions();
    const { x: width, y: height } = this._getTransformedDimensions();

    // scale this to fit the object
    if (objectWidth / width > objectHeight / height) {
      this.scaleToWidth(objectWidth * scale, true);
    } else {
      this.scaleToHeight(objectHeight * scale, true);
    }

    // position this relative to the center of the object
    const { x: scaledWidth, y: scaledHeight } =
      this._getTransformedDimensions();
    const top = objectCenter.y - scaledHeight / 2;
    const left = objectCenter.x - scaledWidth / 2;
    this.set({ top, left });

    object.rotate(angle);
    object.setCoords();
    this.rotate(angle);
    this.setCoords();
  };

  /**
   * This function is an internal util in a new version of fabric.
   * @param value value to check if NaN
   * @param [valueIfNaN]
   * @returns `fallback` is `value is NaN
   */
  const ifNaN = (value, valueIfNaN) => {
    return isNaN(value) && typeof valueIfNaN === 'number' ? valueIfNaN : value;
  };

  /**
   * There is a bug in fabric where scaleToHeight and scaleToWidth allow
   * division by zero, which later on causes more issues. Here we defend against that.
   */

  fabric.Object.prototype.scaleToHeight = function (value, absolute) {
    // adjust to bounding rect factor so that rotated shapes would fit as well
    const boundingRectFactor =
      this.getBoundingRect(absolute).height / this.getScaledHeight();
    return this.scale(ifNaN(value / this.height / boundingRectFactor, 0)); // ifNaN check is added by us
  };

  fabric.Object.prototype.scaleToWidth = function (value, absolute) {
    // adjust to bounding rect factor so that rotated shapes would fit as well
    const boundingRectFactor =
      this.getBoundingRect(absolute).width / this.getScaledWidth();
    return this.scale(ifNaN(value / this.width / boundingRectFactor, 0)); // ifNaN check is added by us
  };

  if (isDebugActive()) {
    fabric.Object.prototype.devOutlineBoundingRect = function (opts) {
      const { displayCoordinates, includeTextExtras } = opts;

      if (!this.canvas) return;
      const strokeWidth = 3;
      const strokeColor = 'red';
      const { top, left, height, width } = getBounds(this, includeTextExtras);
      const points = [
        { x: left, y: top },
        { x: left, y: top + height },
        { x: left + width, y: top + height },
        { x: left + width, y: top },
        { x: left, y: top },
      ];

      if (displayCoordinates) {
        points.forEach((currentPoint, index) => {
          const offsetInPixels = 10;
          const textOffsets = [
            [-offsetInPixels, -offsetInPixels],
            [offsetInPixels, offsetInPixels],
            [offsetInPixels, offsetInPixels],
            [offsetInPixels, -offsetInPixels],
            [-offsetInPixels, -offsetInPixels],
          ];
          const { x, y } = currentPoint;
          const [xOffset, yOffset] = textOffsets[index];
          const newText = makeDebugText(
            x + xOffset,
            y + yOffset,
            `x: ${x.toFixed(3).toString()}\ny: ${y.toFixed(3).toString()}`
          );
          if (index < 4) {
            this.canvas.add(newText);
          }
        });
      }
      this.canvas.devLine(points, strokeWidth, strokeColor);
    };

    fabric.Object.prototype.devVisualizeCoords = function ({
      coordType,
      displayCoordinates,
    }) {
      if (!this.canvas) return;
      if (!this[coordType]) return;
      const radius = 2;
      const fill = 'red';
      const objCoords = this[coordType];
      Object.keys(objCoords).forEach((currentKey) => {
        const coords = objCoords[currentKey];
        const circle = makeDebugCircle(
          coords.x - radius,
          coords.y - radius,
          radius,
          fill
        );
        const displayKey = displayCoordinates
          ? `${currentKey} - x: ${coords.x.toFixed(3)}\ny: ${coords.y.toFixed(
              3
            )}`
          : currentKey;
        const text = makeDebugText(coords.x, coords.y, displayKey);
        this.canvas.add(circle);
        this.canvas.add(text);
      });
    };

    fabric.Object.prototype.onselectstart = null;
  }
}
