import fabric from '../fabric';
import {
  ACTIVE_CLIPPING_MASK,
  START_DRAGGING_ELEMENT,
  DRAGGING_ELEMENT,
  STOP_DRAGGING_ELEMENT,
} from '../../../global/events';
import { EventBusData } from '../../../types';
import { isMaskGroup } from '../../../utils/masking';

export const MASKING_EVENTS = [
  ACTIVE_CLIPPING_MASK,
  START_DRAGGING_ELEMENT,
  DRAGGING_ELEMENT,
  STOP_DRAGGING_ELEMENT,
];

export default async function (
  event: EventBusData,
  canvas: fabric.Canvas
): Promise<void> {
  switch (event.key) {
    case ACTIVE_CLIPPING_MASK: {
      const activeObject = canvas.getActiveObject();
      const maskStatus = isMaskGroup(activeObject?._objects || []);
      if (activeObject?.type !== 'activeSelection' || !maskStatus) {
        return;
      }

      const base = activeObject._objects.find(
        (object: fabric.Object) => object.clipPathMaskId
      );
      base.removeClipPath();
      canvas.discardGroup();

      canvas.fire('object:modified');
      canvas.requestRenderAll();
      break;
    }
    case START_DRAGGING_ELEMENT: {
      const { type, objectName } = event.val as {
        type: string;
        objectName: string;
      };
      canvas.onStartDraggingElement(type, objectName);
      break;
    }
    case DRAGGING_ELEMENT: {
      canvas.onDraggingElement(event.val);
      break;
    }
    case STOP_DRAGGING_ELEMENT: {
      canvas.onStopDraggingElement();
      break;
    }
    default:
      console.warn('Unknown Artboard Layer event', event?.key, event?.val);
  }
  canvas.requestRenderAll();
}
