import React, { useEffect, useState, useCallback } from 'react';
import useElementsStore from '../../../stores/elementsStore';
import { useMenuStore } from '../../../stores/menuStore';
import { sortOptions } from './config';
import Api from '../../../global/api';
import {
  getDisplayStep,
  onboardingStoreSelector,
  useOnboardingStore,
} from '../../../stores/onboardingStore';
import { ONBOARDING_USER_STEP } from '../../../stores/onboardingStore/constants';

const getSuggester = (elementType) => {
  switch (elementType) {
    case 'templates':
      return Api.getTemplateSuggestions;
    case 'elements':
      return Api.getElementsSuggestions;
    case 'textures':
      return Api.getTextureSuggestions;
    default:
      return null;
  }
};

/*
 * Provides state and functions to panels of elements that need sorting, searching and category functionality
 */
const SortAndSearch = (props) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [searchQuery, setSearchQuery] = useState('');

  const onboardingCategory = useOnboardingStore(
    onboardingStoreSelector.onboardingCategory
  );
  const step = useOnboardingStore(onboardingStoreSelector.step);
  const subStep = useOnboardingStore(onboardingStoreSelector.subStep);
  const displayStep = getDisplayStep(step, subStep);

  const setQueryAndCommit = (text) => {
    setSearchQuery({ text, commit: true });
  };
  const [suggestions, setSuggestions] = useState([]);
  const [sortOption, setSortOption] = useState(
    sortOptions.find((option) =>
      props.elementType === 'templates'
        ? option.id === 'trending'
        : option.default
    )
  );

  const onSort = (option) => {
    setSortOption(option);
  };

  const onSearchGoBack = () => setSearchQuery(null);

  const categories = useElementsStore(
    useCallback(
      (state) => state.categories[props.elementType],
      [props.elementType]
    )
  );

  const getCategoryOptions = (categories) => {
    return Object.keys(categories).map((category) => ({
      id: category,
      label: category,
    }));
  };

  useEffect(() => {
    if (!categories) {
      return;
    }
    const categoryOptions = getCategoryOptions(categories);

    if (onboardingCategory) {
      const recommendedCategory = categoryOptions.find(
        (category) => category.id === onboardingCategory
      );
      if (recommendedCategory) {
        setSelectedCategory(recommendedCategory);
        return;
      }
    }

    if (displayStep?.name === ONBOARDING_USER_STEP.ADD_ELEMENT.name) {
      const illustrationsCategory = categoryOptions.find(
        (category) => category.id === 'illustrations'
      );
      if (illustrationsCategory) {
        setSelectedCategory(illustrationsCategory);
        return;
      }
    }

    const defaultCategory = categoryOptions.find(
      (category) => category.id === useMenuStore.getState()[props.elementType]
    );
    setSelectedCategory(defaultCategory || categoryOptions[0]);
  }, [categories, onboardingCategory, props.elementType, displayStep?.name]);

  useEffect(() => {
    const _getSuggestions = async () => {
      const categoryKey = selectedCategory?.id;
      const getSuggestions = getSuggester(props.elementType);
      if (!getSuggestions) return;
      const { names, authors, tags, groups } = await getSuggestions(
        categoryKey
      );
      setSuggestions([
        ...(names || []),
        ...(authors || []),
        ...tags,
        ...groups,
      ]);
    };
    _getSuggestions();
  }, [selectedCategory, props.elementType]);

  const providedProps = {
    query: searchQuery,
    setSearchQuery,
    setQueryAndCommit,
    getCategoryOptions,
    category: selectedCategory,
    setSelectedCategory,
    categories,
    onSort,
    onSearchGoBack,
    suggestions,
    sort: sortOption,
  };

  return React.Children.map(props.children, (child) =>
    React.cloneElement(child, providedProps)
  );
};

export default SortAndSearch;
