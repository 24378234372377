import fabric from '../fabric';

import { onboardingStore } from '../../../stores/onboardingStore';
import useDesignsStore from '../../../stores/designsStore';

// If onboarding is active and user has to edit text,increase step
export const setOnboardingStepIfActive =
  onboardingStore.getState().setStepIfActive;

export const setIsActiveDesignModified =
  useDesignsStore.getState().setIsActiveModified;

export const getMainElement = (allObjects) =>
  allObjects.reduce((target, object) => {
    if (object.type === 'pathText') {
      if (!target || object.fontSize > target.fontSize) {
        return object;
      }
    }
    return target;
  }, null);

// This function should be called only when all of the objects selected are of type pathText
export const callOnAllActiveObjects = (f, canvas) => {
  let activeObject = canvas.getActiveObject();

  if (activeObject instanceof fabric.ActiveSelection) {
    // handles updates to text that don't work with in active selection
    // pathText has some positioning issues when updating inside active selection

    // update object width before ungrouping
    const groupScale = activeObject.scaleX;
    activeObject.getObjects().forEach((obj) => (obj.width *= groupScale));

    canvas._discardActiveObject();

    const calls = activeObject.getObjects().map(
      (obj) =>
        new Promise((resolve) =>
          f(obj, () => {
            resolve();
          })
        )
    );

    Promise.all(calls).then(() => {
      activeObject = new fabric.ActiveSelection(activeObject.getObjects(), {
        canvas,
      });
      canvas._setActiveObject(activeObject);
      activeObject.getObjects().forEach((obj) => {
        obj.fire('selected');
      });
      canvas.fire('selection:updated'); // update values in UI
    });
  } else {
    f(activeObject);
  }
};

export const clearAuxiliaryObjects = (canvas, allObjects) => {
  allObjects.forEach((obj) => obj.isAlignmentAuxiliary && canvas.remove(obj));
};
