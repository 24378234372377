import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useElementsStore, {
  elementStoreSelector,
} from '../../stores/elementsStore';
import IconButton from '../Button/IconButton';
import ElementInfo from '../ElementInfo/ElementInfo';
import Popover from '../Popover/Popover';
import { BookmarkContainer, ButtonRow } from './styles';
import userApi from '../../global/userApi';
import { popoverTheme } from './theme';
import { circledIconTheme } from '../Icon/theme';
import { themeStyle } from '../../services/theming';
import analytics from '../../global/analytics';
import { TEMPLATE_BOOKMARK } from '../../global/analytics/events';
import { Template } from '../../propTypes';

/*
 * Component that renders buttons for a Template.
 */
const TemplateButtons = (props) => {
  const { element, onTagClick, onUserNameClick, onUseTemplate } = props;

  const storeBookmarks = useElementsStore(elementStoreSelector.templates);
  const isBookmarked = useElementsStore(elementStoreSelector.isBookmarked);

  const [bookmarked, setBookmarked] = useState(false);
  useEffect(() => {
    setBookmarked(isBookmarked('templates', element.id));
  }, [storeBookmarks, element.id, isBookmarked]);

  const addOrRemoveBookmark = useElementsStore(
    elementStoreSelector.addOrRemoveBookmark
  );
  const handleBookmark = () => {
    if (isBookmarked('templates', element.id)) {
      userApi.deleteBookmark(element.id);
    } else {
      userApi.createBookmark(element.id);
      analytics.track(TEMPLATE_BOOKMARK);
    }
    addOrRemoveBookmark('templates', element);
  };

  const infoContent = (
    <ElementInfo
      element={element}
      onUseTemplate={() => onUseTemplate({ ...element, fireToast: () => {} })}
      onTagClick={onTagClick}
      onUserNameClick={onUserNameClick}
      onBookmark={handleBookmark}
      elementType="templates"
    />
  );

  return (
    <>
      <ButtonRow>
        <Popover
          content={infoContent}
          align="start"
          placement="right"
          label={`${
            (element.groups?.length && element.groups[0].category?.name) || ''
          } design`}
          theme={popoverTheme}
          width="262px"
        >
          <IconButton
            iconName="info"
            isActive={false}
            height="24px"
            theme={circledIconTheme}
          />
        </Popover>
      </ButtonRow>
      {bookmarked && (
        <BookmarkContainer activeDefaultColor={themeStyle.backgroundAlt}>
          <IconButton
            iconName="templateBookmark"
            height="27px"
            theme={{
              color: themeStyle.backgroundAlt,
              activeDefaultColor: themeStyle.backgroundAlt,
              activeColor: themeStyle.inkMain,
              secondaryColor: themeStyle.backgroundAlt,
            }}
            onClick={handleBookmark}
            activeOnHover={true}
          />
        </BookmarkContainer>
      )}
    </>
  );
};

TemplateButtons.propTypes = {
  element: Template,
  onTagClick: PropTypes.func,
  onUserNameClick: PropTypes.func,
  onUseTemplate: PropTypes.func,
};

export default TemplateButtons;
