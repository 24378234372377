import { useState, useEffect } from 'react';

/* 
  Useful for components where the theme might change in real time 
  Usually, instead of const theme = {...baseTheme, inputTheme}, you would write
  const [theme] = useDynamicTheme(baseTheme, inputTheme), or
  const [theme, setTheme] = useDynamicTheme(baseTheme, inputTheme) if you also
  want to be able to change the theme from within the component
*/
const useDynamicTheme = (baseTheme, inputTheme) => {
  const [_theme, setTheme] = useState({ ...baseTheme, ...inputTheme });
  useEffect(() => {
    setTheme({ ...baseTheme, ...inputTheme });
  }, [inputTheme, baseTheme]);

  return [_theme, setTheme];
};

export default useDynamicTheme;
