import React, { useEffect, useMemo, useState } from 'react';
import { useReward } from 'react-rewards';
import PropTypes from 'prop-types';

import { Anchor, RewardMask } from './styles';
import themeStyle from '../../global/themeStyle';
import Popover from '../Popover/Popover';
import OnboardingStep from '../OnboardingStep/OnboardingStep';
import PulsatingCircle from '../PulsatingCircle/PulsatingCircle';
import {
  useOnboardingStore,
  getDisplayStep,
  onboardingStoreSelector,
} from '../../stores/onboardingStore';
import Modal from '../Modal/Modal';
import Congratulations from '../Congratulations/Congratulations';
import {
  ANCHOR_REF_KEYS,
  ONBOARDING_USER_STEP,
} from '../../stores/onboardingStore/constants';
import { DESIGN_NEW, DESIGN_SAVE } from '../../global/events';
import { popupTheme } from './theme';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';

const rewardContainerId = 'onboarding-reward';
const rewardConfig = {
  lifetime: 225,
  decay: 0.9,
  spread: 200,
  startVelocity: 75,
  elementCount: 512,
  elementSize: 8,
  colors: ['#3692c2', '#ff627c', '#183e5e', '#ffadae', themeStyle.primary],
};

const OnboardingManager = ({ step, subStep, dispatch, onOpenDesignsPanel }) => {
  const refs = useOnboardingStore(onboardingStoreSelector.refs);
  const displayStep = getDisplayStep(step, subStep);
  const { reward } = useReward(rewardContainerId, 'confetti', rewardConfig);

  const setStep = useOnboardingStore(onboardingStoreSelector.setStep);
  const setNextStep = useOnboardingStore(onboardingStoreSelector.setNextStep);
  const skipOnboarding = useOnboardingStore(
    onboardingStoreSelector.skipOnboarding
  );

  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  useEffect(() => {
    if (displayStep?.name === ONBOARDING_USER_STEP.COMPLETE.name) {
      reward();
      setOnboardingModalOpen(true);
    }
  }, [reward, displayStep]);

  const displayState = useMemo(() => {
    if (
      !refs ||
      !displayStep ||
      (displayStep.steps.every((subStep) =>
        subStep.refs?.some((key) => !refs[key])
      ) &&
        displayStep.fallback?.refs?.some((key) => !refs[key]))
    ) {
      return null;
    }

    switch (displayStep.name) {
      case ONBOARDING_USER_STEP.CHOOSE_TEMPLATE.name: {
        const leftMenu =
          refs[ANCHOR_REF_KEYS.LEFT_MENU]?.getBoundingClientRect();
        // test that a left menu is open
        if (leftMenu?.left >= 0) {
          return {
            subStep: displayStep.currentSubStep,
            anchor: {
              position: {
                top: leftMenu.top + 180,
                left: leftMenu.left + leftMenu.width,
              },
              placement: 'right',
            },
          };
        }

        const templates =
          refs[ANCHOR_REF_KEYS.TEMPLATES_BUTTON]?.getBoundingClientRect();
        if (!templates) {
          return null;
        }

        return {
          subStep: displayStep.currentSubStep,
          anchor: {
            position: {
              top: templates.top + templates.height * 0.5,
              left: templates.left + templates.width + 15,
            },
            placement: 'right',
          },
        };
      }
      case ONBOARDING_USER_STEP.EDIT_TEXT.name: {
        const rect = refs[ANCHOR_REF_KEYS.ARTBOARD];
        if (!rect) {
          return null;
        }
        return {
          subStep: displayStep.currentSubStep,
          anchor: {
            position: {
              top: rect.top + rect.height * 0.5,
              left: rect.left,
            },
            placement: 'left',
          },
        };
      }
      case ONBOARDING_USER_STEP.TRANSFORM_TEXT.name: {
        const stylesMenu =
          refs[ANCHOR_REF_KEYS.STYLES_MENU]?.getBoundingClientRect();
        // test that a styles menu is open
        if (stylesMenu?.left < window?.innerWidth) {
          const transformationPanel =
            refs[ANCHOR_REF_KEYS.TRANSFORMATION]?.getBoundingClientRect();

          if (transformationPanel) {
            return {
              subStep: 0,
              anchor: {
                position: {
                  top: transformationPanel.top + 60,
                  left: transformationPanel.left - 23,
                },
                placement: 'left',
              },
            };
          }
        }

        // fallback step
        const rect = refs[ANCHOR_REF_KEYS.ARTBOARD];
        if (!rect) {
          return null;
        }
        return {
          subStep: -1,
          anchor: {
            position: {
              top: rect.top + rect.height * 0.5,
              left: rect.left + rect.width,
            },
            placement: 'right',
          },
        };
      }
      case ONBOARDING_USER_STEP.ADD_ELEMENT.name: {
        const elements =
          refs[ANCHOR_REF_KEYS.ELEMENTS_BUTTON].getBoundingClientRect();
        if (!elements) {
          return null;
        }

        const leftMenu =
          refs[ANCHOR_REF_KEYS.LEFT_MENU]?.getBoundingClientRect();
        // test that a left menu is open
        if (leftMenu?.left >= 0) {
          return {
            subStep: 0,
            anchor: {
              position: {
                top: leftMenu.top + 230,
                left: leftMenu.left + leftMenu.width,
              },
              placement: 'right',
            },
            circles: [
              {
                style: {
                  top: elements.top + elements.height * 0.5,
                  left: elements.left + elements.width,
                },
                showTime: 2,
              },
            ],
          };
        }

        return {
          subStep: 0,
          anchor: {
            position: {
              top: elements.top + elements.height * 0.5,
              left: elements.left + elements.width + 15,
            },
            placement: 'right',
          },
        };
      }
      case ONBOARDING_USER_STEP.COLOR_PALETTE.name: {
        const colorsMenu =
          refs[ANCHOR_REF_KEYS.COLORS_PANEL]?.getBoundingClientRect();
        const colorsButton =
          refs[ANCHOR_REF_KEYS.COLORS_BUTTON].getBoundingClientRect();

        if (!colorsButton) {
          return null;
        }

        // test that a colors menu is open
        if (colorsMenu?.left < window?.innerWidth) {
          return {
            subStep: 0,
            anchor: {
              position: {
                top: colorsMenu.top + 220,
                left: colorsMenu.left - 55,
              },
              placement: 'left',
            },
            circles: [
              {
                style: {
                  top: colorsButton.top,
                  left: colorsButton.left + 15,
                },
                showTime: 4,
              },
            ],
          };
        }

        return {
          subStep: 0,
          anchor: {
            position: {
              top: colorsButton.top,
              left: colorsButton.left,
            },
            placement: 'top',
          },
        };
      }
      case ONBOARDING_USER_STEP.DOWNLOAD.name: {
        const downloadPanel =
          refs[ANCHOR_REF_KEYS.DOWNLOAD_PANEL]?.getBoundingClientRect();
        if (downloadPanel) {
          return {
            subStep: 0,
            anchor: {
              position: {
                top: downloadPanel.top - 10,
                left: downloadPanel.left - 10,
              },
              placement: 'left',
            },
          };
        }

        const downloadButton =
          refs[ANCHOR_REF_KEYS.DOWNLOAD_BUTTON]?.getBoundingClientRect();
        if (!downloadButton) {
          return null;
        }

        return {
          subStep: 0,
          anchor: {
            position: {
              top: downloadButton.bottom + 10,
              left: downloadButton.left,
            },
            placement: 'bottom',
          },
        };
      }
      default:
        return null;
    }
  }, [refs, displayStep]);

  const defaultOptions = Object.keys(ONBOARDING_USER_STEP)
    .filter((key) => ONBOARDING_USER_STEP[key].label)
    .map((key) => ONBOARDING_USER_STEP[key]);

  const handleNextStep = async (currentStep, isFallback) => {
    if (dispatch && currentStep.dispatch) {
      await dispatch(currentStep.dispatch.event, currentStep.dispatch.value);
    }
    if (!isFallback) {
      await setNextStep();
    }
  };

  const handleCongratulationsModalClose = () => {
    setStep(ONBOARDING_USER_STEP.COMPLETE);
    setOnboardingModalOpen(false);
  };

  const handleCongratulationsModalStartNewDesign = () => {
    if (dispatch) {
      dispatch(DESIGN_SAVE, { type: 'auto' });
      dispatch(DESIGN_NEW, { openArtboardSizeModal: false });

      onOpenDesignsPanel();
    }
  };

  return (
    <>
      <RewardMask>
        <span id={rewardContainerId} />
      </RewardMask>
      <Modal
        externalCloseButton
        width="1020px"
        isOpen={onboardingModalOpen}
        onClose={handleCongratulationsModalClose}
      >
        <Congratulations
          onClose={handleCongratulationsModalClose}
          onStartNewDesign={handleCongratulationsModalStartNewDesign}
        />
      </Modal>
      <div key={JSON.stringify(displayState)}>
        {displayState && (
          <>
            <Popover
              open={true}
              placement={displayState.anchor.placement}
              align={displayState.anchor.align || 'start'}
              showHeader={false}
              wrapperMargin={10}
              theme={{
                ...displayState.anchor.theme,
                ...popupTheme,
              }}
              content={
                <OnboardingStep
                  options={defaultOptions}
                  currentOption={displayStep?.index}
                  currentDisplaySubStep={displayState.subStep}
                  currentSubStep={displayStep.currentSubStep}
                  onNextStep={handleNextStep}
                  onSkip={skipOnboarding}
                />
              }
              width={'240px'}
            >
              <Anchor
                style={displayState.anchor.position}
                size={displayState.anchor.size} // size is used to get good tip positions when popover alignment is not `start`
              />
            </Popover>
            {displayState.circles &&
              displayState.circles.map((circle, index) => (
                <PulsatingCircle
                  key={index}
                  style={circle.style}
                  showTime={circle.showTime}
                />
              ))}
          </>
        )}
      </div>
    </>
  );
};

OnboardingManager.propTypes = {
  step: PropTypes.object,
  subStep: PropTypes.number,
  dispatch: PropTypes.func,
  onOpenDesignsPanel: PropTypes.func,
};

const Wrapper = ({ isLoading, dispatch, onOpenDesignsPanel }) => {
  const step = useOnboardingStore(onboardingStoreSelector.step);
  const subStep = useOnboardingStore(onboardingStoreSelector.subStep);

  const workingMode = useMenuStore(menuStoreSelector.workingMode);

  if (isLoading || workingMode !== 'default') {
    return null;
  }

  return (
    <OnboardingManager
      step={step}
      subStep={subStep}
      dispatch={dispatch}
      onOpenDesignsPanel={onOpenDesignsPanel}
    />
  );
};

Wrapper.propTypes = {
  isLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  onOpenDesignsPanel: PropTypes.func,
};

export default Wrapper;
