import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import PaymentForm from './PaymentForm';
import subscriptionApi from '../../global/subscriptionApi';
import monitoring from '../../services/monitoring';

const stripePromise = Promise.resolve(process.env.REACT_APP_STRIPE_KEY)
  .then((stripeKey) => {
    if (!stripeKey) {
      return null;
    }

    return loadStripe(stripeKey);
  })
  .catch((err) => {
    console.error('failed to load stripe:', err);

    return null;
  });

interface InitialPaymentProps {
  // The price id for a specific plan and interval
  priceId: string;
  // The handler after the payment succeeds
  onSuccess: () => void;
  // The handler when loading
  onLoading: () => void;
  // The handler when loaded
  onLoaded: () => void;
}

const InitialPayment: React.FC<InitialPaymentProps> = ({
  priceId,
  onSuccess,
  onLoading,
  onLoaded,
}) => {
  const [clientSecret, setClientSecret] = useState('');
  const loading = useRef<boolean>(false);

  const createSubscriptionIntent = useCallback(async (): Promise<void> => {
    if (loading.current) return;

    loading.current = true;
    onLoading();
    setClientSecret('');
    try {
      const result = await subscriptionApi.createSubscriptionIntent(priceId);
      setClientSecret(result.clientSecret);
    } catch (error) {
      monitoring.captureException(error);
    }
    loading.current = false;
    onLoaded();
  }, [priceId, onLoading, onLoaded]);

  useEffect(() => {
    createSubscriptionIntent();
  }, [priceId, createSubscriptionIntent]);

  const stripeElementOptions: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };

  return clientSecret ? (
    <div>
      <Elements
        // force <Elements> to remount upon `clientSecret`'s change, otherwise
        // when confirming payment we might have staled `clientSecret`.
        key={clientSecret}
        stripe={stripePromise}
        options={stripeElementOptions}
      >
        {clientSecret && <PaymentForm onSuccess={onSuccess} />}
      </Elements>
    </div>
  ) : null;
};

export default memo(InitialPayment);
