import { useEffect, useRef, useState } from 'react';

/**
 * Feature detection to figure out if Intersection Observer polyfill needs to be imported
 **/
async function loadIntersectionObserverPolyfill() {
  if (
    typeof window !== 'undefined' &&
    typeof window.IntersectionObserver === 'undefined'
  ) {
    await import('intersection-observer');
  }
}
loadIntersectionObserverPolyfill();

const useIntersectionObserver = (callback, opts) => {
  const [node, setNode] = useState();
  const observer = useRef();
  useEffect(() => {
    if (!node) return;

    const _observer = (observer.current = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) callback && callback();
      },
      opts || {}
    ));
    _observer.observe(node);

    return () => _observer.disconnect();
  }, [node, callback, opts]);

  return {
    setNode,
  };
};

export default useIntersectionObserver;
