import React from 'react';
import Popover from '../Popover/Popover';
import ProfileImage from './ProfileImage/ProfileImage';
import UserNavigation from './UserNavigation/UserNavigation';
import { Header, UserInfo } from './styles';
import { smallTheme } from './ProfileImage/theme';
import { H5, P3 } from '../utilities/Typography/styles';
import { themeStyle } from '../../services/theming';
import { ProfileProps } from './types';
import { popoverTheme } from './theme';

/**
 * render the profile in the header
 */
const Profile: React.FC<ProfileProps> = (props) => {
  const content = (
    <>
      <Header>
        <ProfileImage user={props.user} theme={smallTheme} />
        <UserInfo data-testid="user-info">
          <H5 color={themeStyle.varInkMain}>{props.user.name}</H5>
          <P3 color={themeStyle.varInkMedium}>{props.user.email}</P3>
        </UserInfo>
      </Header>
      <UserNavigation {...props} />
    </>
  );

  return (
    /* eslint-disable-next-line */
    //@ts-ignore
    <Popover
      content={content}
      dataTestId={'profile'}
      showHeader={false}
      align="end"
      width="224px"
      padding={-20}
      useHover={true}
      margin={'25px 0 0 0'}
      onClick={(): void => props.onClickItem(props.options['Profile'])}
      hideTip
      theme={popoverTheme}
    >
      <ProfileImage user={props.user} theme={{ size: '40px' }} />
    </Popover>
  );
};

export default Profile;
