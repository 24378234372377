import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { FlexRow } from '../utilities/styles';
import { H5 } from '../utilities/Typography/styles';
import IconEventWrapper from '../Icon/IconEventWrapper';

export const ItemWrapper = styled(FlexRow)`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 42px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid ${themeStyle.varInkSuperLight};

  * {
    user-select: none;
  }
`;

export const ItemsWrapper = styled.div<{ isMultiOption: boolean }>`
  display: flex;
  align-items: center;
  height: 20px;

  & > * {
    @media (hover: hover) {
      &:hover {
        ${H5} {
          color: ${themeStyle.varInkMain};
        }
      }
    }

    &:not(:first-child) {
      padding-left: 14px;
    }

    &:not(:last-child) {
      padding-right: 14px;
      border-right: 1px solid ${themeStyle.varInkSuperLight};
    }

    cursor: ${({ isMultiOption }) => (isMultiOption ? 'pointer' : 'unset')};
  }
`;

export const CloseButtonWrapper = styled(IconEventWrapper)`
  cursor: pointer;
`;
