import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 22px 22px 32px 32px;
  display: flex;
  flex-direction: column;
`;

export const LeftColumn = styled.div`
  width: 350px;
`;

export const Image = styled.img`
  width: 290px;
  height: 228px;
`;

export const List = styled.ul`
  margin: 0;
  margin-top: 24px;
  margin-bottom: 18px;
  padding-inline-start: 20px;

  > li {
    margin-bottom: 10px;
  }
`;
