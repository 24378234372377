import { themeStyle } from '../../services/theming';

export const defaultTheme = {
  color: themeStyle.varInkMain,
  activeColor: themeStyle.varInkMain,
  hoverBackgroundColor: themeStyle.varInkSuperLight,
  fontSize: themeStyle.fontSize14,
  fontWeight: themeStyle.fontSemiBold,
  arrowHeight: '7.5px',
  buttonHeight: '43px',
  outerPadding: '10px',
};

export const arrowTheme = {
  color: themeStyle.varInkMedium,
  activeColor: themeStyle.varInkMedium,
};
