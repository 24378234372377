import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useDesignsStore, {
  designsStoreSelector,
} from '../../../stores/designsStore';
import useElementsStore, {
  elementStoreSelector,
} from '../../../stores/elementsStore';
import { ADD_TEMPLATE } from '../../../global/events';
import TemplateButtons from '../../TemplateButtons/TemplateButtons';
import BasePanel from '../../LeftMenu/BasePanel/BasePanel';
import Select from '../../SelectNew/Select';
import Bookmarks from '../../Bookmarks/Bookmarks';
import ElementsBasePanel from '../ElementBasePanel';
import ElementsDefaultHeader from '../ElementsDefaultHeader';
import SortAndSearch from '../SortAndSearch/SortAndSearch';
import { getRenderBaseProps } from '../util';
import DecisionModal from '../../DecisionModal/DecisionModal';
import { userStoreSelector, useUserStore } from '../../../stores/userStore';
import {
  promotionSelector,
  usePromotionStore,
} from '../../../stores/promotionStore';
import Element from '../Element/Element';
import {
  onboardingStoreSelector,
  useOnboardingStore,
} from '../../../stores/onboardingStore';
import Recommended from '../../Recommended/Recommended';
import Spacer from '../../Spacer/Spacer';

const WithoutSortAndSearch = (props) => {
  const {
    dispatch,
    setQueryAndCommit,
    setSearchQuery,
    category,
    getCategoryOptions,
    categories,
    setSelectedCategory,
    transitioning,
    sort,
  } = props;

  const proAccess = useUserStore(userStoreSelector.proAccess);
  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const isActiveModified = useDesignsStore(
    designsStoreSelector.isActiveModified
  );
  const canUserCreateDesign = useDesignsStore(
    designsStoreSelector.canUserCreateDesign
  );
  const storeBookmarks = useElementsStore(elementStoreSelector.storeBookmarks);
  const localBookmarks = useElementsStore(elementStoreSelector.localBookmarks);

  const onboardingCategory = useOnboardingStore(
    onboardingStoreSelector.onboardingCategory
  );
  const categoriesOverview = useElementsStore(elementStoreSelector.categories);

  const handleNewProject = async () => {
    if (!selectedTemplate) return;

    if (await canUserCreateDesign()) {
      dispatch(ADD_TEMPLATE, { newProject: true, ...selectedTemplate });
    }
  };

  const handleReplace = () => {
    if (!selectedTemplate) return;
    dispatch(ADD_TEMPLATE, selectedTemplate);
  };

  const render = (element) => {
    const { elementProps } = getRenderBaseProps(element, 'templates', 2);

    const onAdd = (val) => {
      if (element.premium && !proAccess) {
        showUpgradeModal('premiumDesignUsage', {
          designPreview: element.templatePreviewObjectName,
        });
        return;
      }

      if (isActiveModified) {
        setShowModal(true);
        setSelectedTemplate(val);
      } else {
        dispatch(ADD_TEMPLATE, val);
      }
    };

    const buttons = (
      <TemplateButtons
        element={elementProps}
        onUseTemplate={onAdd}
        elementType="templates"
        onTagClick={setQueryAndCommit}
        onUserNameClick={setQueryAndCommit}
      />
    );

    return (
      <Element
        element={elementProps}
        buttonsComponent={buttons}
        onDrop={onAdd}
        hasBookmarkIcon
      />
    );
  };

  const headGalleries = [];
  if (onboardingCategory) {
    const allCategories = categoriesOverview?.templates
      ? Object.keys(categoriesOverview.templates)
      : [];
    const selectedCategory = allCategories.includes(onboardingCategory)
      ? onboardingCategory
      : allCategories[0];
    if (selectedCategory === category?.id) {
      const recommended = <Recommended {...props} category={category?.id} />;
      headGalleries.push(recommended);
    }
  }
  if ((storeBookmarks?.length || 0) + (localBookmarks?.length || 0)) {
    headGalleries.push(<Bookmarks />);
  }

  const headElement = (
    <>
      <Select
        activeOption={category}
        options={getCategoryOptions(categories)}
        onSelect={(selected) => {
          setSelectedCategory(selected);
          setSearchQuery(null);
        }}
      />
      <Spacer h="12px" />
      <ElementsDefaultHeader {...props} />
    </>
  );
  const bodyElement = (
    <ElementsBasePanel
      {...props}
      render={render}
      elementType="templates"
      headGalleries={headGalleries}
      hide={transitioning}
      sort={sort?.id}
    />
  );

  return (
    <>
      <BasePanel
        label="Templates"
        onClose={props.onClose}
        headElement={headElement}
        bodyElement={bodyElement}
      />
      <DecisionModal
        isOpen={showModal}
        title="Attention"
        message="This action replaces your current project. You can also create a new project instead."
        onClose={() => setShowModal(false)}
        firstButton={{
          label: 'Replace',
          onClick: handleReplace,
        }}
        secondButton={{
          label: 'New Project',
          onClick: handleNewProject,
        }}
        escapeTrigger={false}
      />
    </>
  );
};

const WithSortAndSearch = (props) => {
  return (
    <SortAndSearch elementType="templates">
      <WithoutSortAndSearch {...props} />
    </SortAndSearch>
  );
};

const propTypes = {
  dispatch: PropTypes.func,
  transitioning: PropTypes.bool,
  sort: PropTypes.object,
  onClose: PropTypes.func,
  setQueryAndCommit: PropTypes.func,
  setSearchQuery: PropTypes.func,
  category: PropTypes.object,
  getCategoryOptions: PropTypes.func,
  categories: PropTypes.object,
  setSelectedCategory: PropTypes.func,
};

WithoutSortAndSearch.propTypes = propTypes;

WithSortAndSearch.propTypes = propTypes;

export default WithSortAndSearch;
