import styled, { css } from 'styled-components';
import { NumberInputTheme } from './types';

export const getColor = ({
  theme,
  disabled,
  isActive,
}: {
  theme: NumberInputTheme;
  disabled?: boolean;
  isActive?: boolean;
}): string => {
  if (disabled) return theme.colorDisabled;
  if (isActive) return theme.colorHover;
  return theme.color;
};

export const InputContainer = styled.div<any>`
  width: ${({ hideArrows }) => (hideArrows ? '100%' : 'calc(100% - 14px)')};
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-left: ${({ theme }) => theme.inputMarginLeft};
  display: block;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
`;

export const Input = styled.input.attrs<any>(({ value, disabled, name }) => ({
  type: 'text',
  name,
  value,
  disabled,
}))<any>`
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.fontSize};
  font-family: Inter;
  font-weight: ${({ theme }) => theme.fontWeight};
  text-align: ${({ theme }) => theme.textAlign};
  color: ${getColor};
  transition: color 0.3s;
  background-color: transparent;
  outline: none !important;
  border: none !important;
  padding: 0;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  overflow: auto;

  pointer-events: ${({ evented }) => (evented ? 'auto' : 'none')};
`;

export const ArrowsContainer = styled.div<any>`
  width: 14px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  user-select: none;
  position: relative;

  .fillDefault {
    transition: fill 0.3s;
    fill: ${getColor};
  }
`;

export const ArrowClickArea = styled.div<any>`
  position: absolute;
  width: 100%;
  height: 50%;
  ${({ direction }) => (direction === 'up' && 'top') || 'bottom'}: 0;
  pointer-events: ${({ disabled }) => (disabled && 'none') || 'unset'};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
`;

/**
 * this style is exported so that hover on parent components (WrappedNumberInput, Slider)
 * can also trigger correct styles for the input
 */
export const inputActiveStyle = css`
  ${Input} {
    color: ${(props) => getColor({ ...props, isActive: true })};
  }

  ${ArrowsContainer} {
    .fillDefault {
      fill: ${(props) => getColor({ ...props, isActive: true })};
    }
  }
`;

export const InputBox = styled.div<any>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: ${({ theme }) => theme.width};
  height: ${({ theme }) => theme.height};

  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.borderColor};
  margin-bottom: ${({ theme }) => theme.marginBottom};

  transition: border-color 0.3s;

  &:hover,
  &:focus-within {
    ${inputActiveStyle}
  }
`;
