import React from 'react';
import PropTypes from 'prop-types';

import { SliderWrapper, PanelBackground } from './styles';
import SliderInput from '../../SliderInput/SliderInput';
import { ACTIVE_VARIATION } from '../../../global/events';

/**
 * The VariableFontPanel. Panel to configure variable values of a font.
 * This panel is opened in a Popover from the TextSettingsPanel.
 */
const VariableFontPanel = ({ activeFont, ...props }) => {
  const dispatchVariation = (variation, value, isChanging) => {
    props.dispatch &&
      props.dispatch(ACTIVE_VARIATION, { variation, value }, isChanging);
  };

  return (
    <PanelBackground>
      {activeFont.variationKeys?.length &&
        activeFont.variationKeys.map((variation) => {
          const {
            name,
            min,
            max,
            default: def,
          } = activeFont.variationAxes[variation];
          return (
            <SliderWrapper key={name}>
              <SliderInput
                label={name}
                startValue={props.options?.variation?.value[variation] || def}
                precision={0}
                step={1}
                min={min}
                max={max}
                onChanging={(value) => {
                  dispatchVariation(variation, value, true);
                }}
                onChanged={(value) => {
                  dispatchVariation(variation, value, false);
                }}
              />
            </SliderWrapper>
          );
        })}
    </PanelBackground>
  );
};

VariableFontPanel.propTypes = {
  /**
   * name of the font for which variations should be configured
   */
  activeFont: PropTypes.object.isRequired,
  /**
   * current text setting options
   */
  options: PropTypes.shape({
    variationAxes: PropTypes.object,
    variation: PropTypes.object,
  }).isRequired,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
};

export default VariableFontPanel;
