import { memo, useEffect, useRef, useState, forwardRef } from 'react';
import { WidgetContent, WidgetsWrapper, WidgetWrapper } from './styles';
import { Widget, WidgetStageProps } from './types';
import { widgets } from './config';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import { ObjectType } from '../../types/fabric';

//eslint-disable-next-line @typescript-eslint/ban-types
const WithRef = <T extends object>(
  Component: React.ComponentType<T>
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<T> & React.RefAttributes<HTMLDivElement>
> => {
  return forwardRef<HTMLDivElement, T>((props: T, ref) => {
    return (
      <div ref={ref}>
        <Component {...props} />
      </div>
    );
  });
};

const WrappedWidget: React.FC<WidgetStageProps & { widget: Widget }> = ({
  widget,
  ...props
}) => {
  const [boundingBox, setBoundingBox] = useState<DOMRect | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setBoundingBox(wrapperRef.current?.getBoundingClientRect() || null);
  }, []);

  const position = boundingBox
    ? widget.computePosition(props.objectPosition, boundingBox)
    : null;

  const Component = widget.render;

  return (
    <WidgetWrapper visible={!!position} {...position}>
      <WidgetContent>
        <Component {...props} ref={wrapperRef} />
      </WidgetContent>
    </WidgetWrapper>
  );
};

const WidgetStage: React.FC<WidgetStageProps> = (props) => {
  const showWidgets = useMenuStore(menuStoreSelector.showObjectWidgets);

  if (!showWidgets) {
    return null;
  }

  const { object } = props;

  const widgetsToRender =
    widgets[object.type as ObjectType] || widgets.default!;

  return (
    <WidgetsWrapper>
      {widgetsToRender.map((control: Widget, idx: number) => {
        const renderWithRef = WithRef(control.render);
        return (
          <WrappedWidget
            widget={{ ...control, render: renderWithRef }}
            key={`control-${idx}`}
            {...props}
          />
        );
      })}
    </WidgetsWrapper>
  );
};

export default memo(WidgetStage);
