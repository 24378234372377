import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { P4 } from '../utilities/Typography/styles';

export const Wrapper = styled.button<any>`
  width: 100%;
  cursor: pointer;
  display: flex;
  gap: 22px;
  padding: 6px;
  height: 62px;
  white-space: nowrap;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  border: 1px solid ${themeStyle.varInkLight};
  border-radius: ${themeStyle.radiusMedium};
  background: transparent;

  transition: box-shadow ease-in-out 0.2s;
  @media (hover: hover) {
    &:hover {
      box-shadow: ${({ locked }) =>
        !locked ? themeStyle.shadowSmallHeavy : 'none'};
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  box-sizing: border-box;
  background: linear-gradient(to right, #730f6f 50%, transparent 50% 100%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Grid = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  border-radius: 0 5px 5px 0;
  background: repeating-conic-gradient(#c4c4c4 0% 25%, transparent 0% 50%) 50% /
    calc(50px / 8) calc(50px / 8);

  html[data-theme='dark'] && {
    background: repeating-conic-gradient(#d5d6d7 0% 25%, #9fa0a1 0% 50%) 50% /
      calc(50px / 8) calc(50px / 8);
  }
`;

export const Foreground = styled.img.attrs(() => ({
  src: '/images/bg_remove.png',
}))`
  width: 46px;
  height: 46px;
  z-index: ${themeStyle.zIndexS1};
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: -13.5px;
  z-index: ${themeStyle.zIndexS2};
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
`;

export const LoadingWrapper = styled.div`
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubText = styled(P4)`
  font-size: ${themeStyle.fontSize10};
`;
