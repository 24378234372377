import styled from 'styled-components';

import { themeStyle } from '../../services/theming';
import { QUERIES } from '../utilities/queries';
import { MinimalTestimonialSize } from './MinimalTestimonial';

export const TestimonialWrapper = styled.div<{
  imageSize: number;
  compact: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  width: 300px;
  padding: 54px 25px 30px;
  ${({ compact }): string =>
    !compact
      ? `${QUERIES.largeTabletAndUp} {
    width: 432px;
    padding: 70px 56px 60px;`
      : ''}        
  }
  margin-top: ${({ imageSize }): string => `${imageSize / 2}px`};
  background: ${themeStyle.varBackgroundAlt};
  border-radius: ${themeStyle.radiusLarge};
  box-shadow: ${themeStyle.varShadowSoft};

  .text p {
    display: inline;
  }
`;

export const MinimalTestimonialSizeStyles = {
  [MinimalTestimonialSize.Large]: {
    width: '355px',
    titleFontSize: themeStyle.fontSize12,
    nameFontSize: themeStyle.fontSize18,
  },
  [MinimalTestimonialSize.Medium]: {
    width: '300px',
    titleFontSize: themeStyle.fontSize11,
    nameFontSize: themeStyle.fontSize18,
  },
  [MinimalTestimonialSize.Small]: {
    width: '258px',
    titleFontSize: themeStyle.fontSize10,
    nameFontSize: themeStyle.fontSize16,
  },
};

export const MinimalTestimonialWrapper = styled.div<{
  size: MinimalTestimonialSize;
  imageSize: number;
}>`
  position: relative;
  box-sizing: border-box;
  width: ${({ size }): string => MinimalTestimonialSizeStyles[size].width};
  padding: 14px;
  padding-top: ${({ imageSize }): string => `${imageSize / 2 + 8}px`};
  background: ${themeStyle.varBackgroundAlt};
  border-radius: ${themeStyle.radiusLarge};
  box-shadow: ${themeStyle.varShadowSoft};

  .title {
    font-size: ${({ size }): string =>
      MinimalTestimonialSizeStyles[size].titleFontSize};
    letter-spacing: 0.1em;
    line-height: 1.2;
    color: ${themeStyle.varInkMedium};
  }

  .name {
    font-size: ${({ size }): string =>
      MinimalTestimonialSizeStyles[size].nameFontSize};
    line-height: 1.8;
  }
`;

export const ProfileImageWrapper = styled.div<{ imageSize: number }>`
  position: absolute;
  top: ${({ imageSize }): string => `-${imageSize / 2}px`};
`;
