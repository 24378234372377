/**
 * utility function to get a unique id in browser
 */
export const getUniqueId = (): string | undefined => {
  let id;
  // we only want this code to run in the browser
  if (typeof window !== 'undefined') {
    if (window.crypto?.randomUUID) {
      // `randomUUID` is supported in chrome 92+
      id = window.crypto.randomUUID();
    } else if (window.crypto?.getRandomValues) {
      // fallback to `getRandomValues` for older browsers
      // `getRandomValues` is supported in chrome 11+
      const arr = new Uint8Array(20);
      window.crypto.getRandomValues(arr);
      id = Array.from(arr, (dec) => dec.toString(16).padStart(2, '0')).join('');
    } else {
      // realistically we can't be here, but lets be nice and add fallback for IE6
      // theoretically `Math.random()` can return 1 or 0, so there is yet another fallback
      id = Math.random().toString().split('.')[1] || '1337';
    }
  }
  return id;
};
