import { Point, Segment } from '../../types';
import { projectPointOnLine } from './line';
import { getDistance } from './point';

/**
 * We write segments as { o: point, d: point },
 * since this is the same interface that fabric uses, so it's convenient.
 * o and d are just the two points that define the segment
 */

/**
 * Gets the distance between a point and a finite segment defined by two points
 * @param point {{ x: number, y: number }} The point to get the distance from
 * @param segment {{ o: { x: number, y: number }, d: { x: number, y: number } }} The segment to get the distance to
 * @returns {number}
 */
export const getPointDistanceToSegment = (
  point: Point,
  segment: Segment
): number => {
  const { o, d } = segment;

  // Build a line that contains segment
  // Note that line(0) = o and line(1) = d
  const line = {
    point: o,
    direction: {
      x: d.x - o.x,
      y: d.y - o.y,
    },
  };

  const projection = projectPointOnLine(point, line);

  if (!projection) {
    // There is probably something wrong with segment.
    // We are defensive of this until we know what's wrong

    // Sentry.captureMessage(`
    //   Distance from segment to point failed!\n
    //   o: { x: ${o.x}, y: ${o.y}}\n
    //   d: { x: ${d.x}, y: ${d.y}}\n
    //   point: { x: ${point.x}, y: ${point.y}}
    // `);

    const midPoint = {
      x: (o.x + d.x) / 2,
      y: (o.y + d.y) / 2,
    };

    return getDistance(point, midPoint);
  }

  const { t: pointProjectionTime, point: pointProjection } = projection;

  const pointFellOnSegment =
    0 <= pointProjectionTime && pointProjectionTime <= 1;

  if (pointFellOnSegment) {
    // If the point was projected on the segment itself, just return distance
    // from the point to the projection
    return Math.hypot(point.x - pointProjection.x, point.y - pointProjection.y);
  }

  // Otherwise, the projection falls on the line that contains the segment, but not on
  // the segment itself. Then, the closest point in the segment is either of its ends
  return Math.min(
    Math.hypot(point.x - o.x, point.y - o.y),
    Math.hypot(point.x - d.x, point.y - d.y)
  );
};

/**
 * Gets the minimum distance between a point and a set of finite segments
 * @param point {{ x: number, y: number }} The point to get the distance from
 * @param segments {[{ o: { x: number, y: number }, d: { x: number, y: number } }]} The set of segments
 * @returns {boolean}
 */
export const getPointDistanceToSegments = (
  point: Point,
  segments: Segment[]
): number => {
  return Math.min.apply(
    null,
    segments.map((segment) => getPointDistanceToSegment(point, segment))
  );
};
