import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Content } from './styles';

const PanelContent = ({ isActive, children }) => {
  const [height, setHeight] = useState(0);
  const contentRef = useRef();

  useEffect(() => {
    if (isActive) {
      const height = contentRef.current.scrollHeight;
      setHeight(height);
    } else {
      setHeight(0);
    }
  }, [isActive]);

  return (
    <Content style={{ height: `${height}px` }} ref={contentRef}>
      {children}
    </Content>
  );
};

PanelContent.propTypes = {
  /**
   * whether the panel is open
   */
  isActive: PropTypes.bool,
  children: PropTypes.node,
};

export default PanelContent;
