import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button/Button';
import {
  menuTheme,
  primaryLightTheme,
  secondarySmallTheme,
} from '../Button/Button/theme';
import Modal from '../Modal/Modal';
import { H2, H3, P2, P3 } from '../utilities/Typography/styles';
import { AttributionFooter, CopyContainer, InnerWrapper } from './styles';
import { FlexColumn, FlexRow } from '../utilities/styles';
import Spacer from '../Spacer/Spacer';
import { config } from './config';

import { getSubscriptionPageUrl } from '../../utils/url';
import analytics from '../../global/analytics';
import {
  ATTRIBUTION_CLICK_UPGRADE,
  ATTRIBUTION_COPY,
} from '../../global/analytics/events';
import { useToastStore, toastSelector } from '../../stores/toastStore';
import { themeStyle } from '../../services/theming';

const ActiveTab = ({ activeTab }) => {
  const data = config.find((tab) => tab.key === activeTab);
  const fireToast = useToastStore(toastSelector.fire);

  const handleCopy = () => {
    analytics.track(ATTRIBUTION_COPY, { label: activeTab });
    navigator.clipboard?.writeText(data?.credit).then(
      () => {
        fireToast({ label: 'Copied to clipboard' });
      },
      () => {} // empty error handling, to hide error message
    );
  };

  if (!data) return null;

  return (
    <>
      <P3>
        <b>{data.title}</b>
        <br />
        {data.text}
      </P3>
      <Spacer h="24px" />
      <CopyContainer onClick={handleCopy}>
        <P2 noTextOverflow style={{ flex: 1 }}>
          {data.credit}
        </P2>
        <Button
          width="144px"
          height="32px"
          theme={{ ...secondarySmallTheme, padding: '0px' }}
          label="Copy and credit"
        />
      </CopyContainer>
    </>
  );
};

ActiveTab.propTypes = {
  activeTab: PropTypes.string,
};

const AttributionModal = (props) => {
  const [activeTab, setActiveTab] = useState('social');

  const handleUpgrade = () => {
    analytics.track(ATTRIBUTION_CLICK_UPGRADE);
    window.location.href = getSubscriptionPageUrl();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} width="816px">
      <FlexColumn width="100%">
        <InnerWrapper>
          <img
            src="/images/attributionIllustration.svg"
            alt="Remember to credit Kittl"
            height="266"
            width="284"
          />
          <FlexColumn style={{ flex: 1, overflow: 'hidden' }}>
            <H2 data-testid="credit-kittl-hint">Remember to credit Kittl</H2>
            <Spacer h="8px" />
            <P3>
              To use Kittl without crediting easily upgrade your plan below.
            </P3>
            <Spacer h="24px" />
            <FlexRow justifyContent="center" gap="24px">
              {config.map(({ key, label }) => (
                <Button
                  key={key}
                  width="auto"
                  height="32px"
                  theme={menuTheme}
                  label={label}
                  isActive={activeTab === key}
                  onClick={() => setActiveTab(key)}
                />
              ))}
            </FlexRow>
            <Spacer h="24px" />
            <ActiveTab activeTab={activeTab} />
          </FlexColumn>
        </InnerWrapper>
        <AttributionFooter>
          <H3 color={themeStyle.inkBase} style={{ textAlign: 'center' }}>
            Upgrade your plan to use designs
            <br />
            without crediting.
          </H3>
          <Button
            height="40px"
            width="auto"
            theme={primaryLightTheme}
            label="Upgrade Plan"
            onClick={handleUpgrade}
          />
        </AttributionFooter>
      </FlexColumn>
    </Modal>
  );
};

AttributionModal.propTypes = {
  activeTab: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AttributionModal;
