import styled from 'styled-components';
import themeStyle from '../../../../global/themeStyle';

export const Wrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${themeStyle.white};
  gap: 17px;
  padding: 8px 14px;
  box-sizing: border-box;
  box-shadow: ${themeStyle.widgetShadow};
  border-radius: 100px;
  touch-action: none;
`;

export const MoreWrapper = styled.div<{ height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
  cursor: pointer;
`;
