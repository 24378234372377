import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useFeature } from '@growthbook/growthbook-react';

import {
  ExportButtonsContainer,
  InputContainer,
  ModalContent,
  Section,
  Wrapper,
  ExportLabelWrapper,
  Interactive,
} from './styles';
import { FlexRow } from '../../utilities/styles';
import Switch from '../../Switch/Switch';
import Button from '../../Button/Button/Button';
import NumberInput from '../../NumberInput/NumberInput';
import Select from '../../SelectNew/Select';
import Icon from '../../Icon/Icon';
import { buttonTheme, numberInputTheme, selectCustomStyles } from './theme';
import { DOWNLOAD } from '../../../global/events';
import {
  DEFAULT_DOWNLOAD_DPI,
  UNITS,
  MINIMUM_EXPORT_SIZE,
  MAXIMUM_EXPORT_SIZE,
  MAXIMUM_FREE_EXPORT_SIZE,
  ARTBOARD_DPI,
  MAXIMUM_IPAD_EXPORT_SIZE,
  SAFE_EXPORT_PX_COUNT,
} from '../../../global/constants';
import { useUserStore, userStoreSelector } from '../../../stores/userStore';
import {
  useDownloadPanelStore,
  downloadPanelStoreSelector,
} from '../../../stores/downloadPanelStore';
import {
  convertToUnit,
  getFormattedArtboardSize,
} from '../../../utils/artboard';
import analytics from '../../../global/analytics';
import {
  CLICK_DOWNLOAD_PANEL_REMOVE_BACKGROUND_SWITCH,
  CLICK_DOWNLOAD_PANEL_OPTIMIZE_QUALITY_SWITCH,
  CLICK_DOWNLOAD_PANEL_EXPORT_LABEL,
} from '../../../global/analytics/events';
import { isIpadOperatingSystem } from '../../../utils/detection';
import DecisionModal from '../../DecisionModal/DecisionModal';
import { menuStoreSelector, useMenuStore } from '../../../stores/menuStore';
import { P2, P3, P4 } from '../../utilities/Typography/styles';
import Spacer from '../../Spacer/Spacer';
import Checkbox from '../../Checkbox/Checkbox';
import {
  getDisplayStep,
  onboardingStoreSelector,
  useOnboardingStore,
} from '../../../stores/onboardingStore';
import {
  ANCHOR_REF_KEYS,
  ONBOARDING_USER_STEP,
} from '../../../stores/onboardingStore/constants';
import { themeStyle } from '../../../services/theming';

/* Options for the selection of a unit */
const UNIT_OPTIONS = Object.keys(UNITS).map((key) => {
  return { id: key, label: key };
}, {});

/**
 * Panel to configure and trigger a download.
 */
const DownloadPanel = ({ options, ...props }) => {
  const plan = useUserStore(userStoreSelector.plan);
  const downloadOptions = useDownloadPanelStore(
    downloadPanelStoreSelector.options
  );
  const setDownloadOptions = useDownloadPanelStore(
    downloadPanelStoreSelector.setOptions
  );
  const [removeBackground, setRemoveBackground] = useState(
    downloadOptions.removeBackground
  );

  const optimizedQuality = downloadOptions.optimizedQuality;
  const setOptimizedQuality = useCallback(
    (optimizedQuality) => {
      setDownloadOptions({ optimizedQuality });
    },
    [setDownloadOptions]
  );

  const [size, setSize] = useState({
    width: downloadOptions.width,
    height: downloadOptions.height,
    dpi: downloadOptions.dpi,
  });
  const [editInterimSize, setEditInterimSize] = useState(size);
  const [unit, setUnit] = useState(downloadOptions.unit);
  const [aspectRatio, setAspectRatio] = useState(downloadOptions.aspectRatio);

  const [firstDownload, setFirstDownload] = useState(true);
  const step = useOnboardingStore(onboardingStoreSelector.step);
  const subStep = useOnboardingStore(onboardingStoreSelector.subStep);
  const displayStep = getDisplayStep(step, subStep);

  const downloadTestEnabled = useFeature('download-size-test').value;
  const maximumSizeEnabled = useMemo(
    () => plan || downloadTestEnabled,
    [plan, downloadTestEnabled]
  );
  const maximums = useMemo(
    () =>
      maximumSizeEnabled
        ? isIpadOperatingSystem()
          ? MAXIMUM_IPAD_EXPORT_SIZE
          : MAXIMUM_EXPORT_SIZE
        : MAXIMUM_FREE_EXPORT_SIZE,
    [maximumSizeEnabled]
  );

  const handleShowUpgradeModal = (type = 'highResVectorDownload') => {
    if (plan || !props.onShowUpgradeModal) return;
    props.onShowUpgradeModal(type);
  };

  useEffect(() => {
    setEditInterimSize(size);
  }, [size, setEditInterimSize]);

  useEffect(() => {
    setDownloadOptions({
      removeBackground,
      optimizedQuality,
      width: size.width,
      height: size.height,
      dpi: size.dpi,
      unit,
    });
  }, [removeBackground, optimizedQuality, size, unit, setDownloadOptions]);

  useEffect(() => {
    if (size.width && size.height) return;

    const formattedArtboardSize = getFormattedArtboardSize(options);
    let adjustedArtboardSize = formattedArtboardSize;

    const { unit } = formattedArtboardSize;

    // Find output size with default DPI
    const { width: pixelOutputWidth, height: pixelOutputHeight } =
      convertToUnit({
        width: formattedArtboardSize.width,
        height: formattedArtboardSize.height,
        oldUnit: formattedArtboardSize.unit,
        newUnit: 'px',
        dpi: DEFAULT_DOWNLOAD_DPI,
      });

    /*
      Handle problem caused by ARTBOARD_DPI < DEFAULT_DOWNLOAD_DPI
    */
    if (pixelOutputWidth > maximums.px || pixelOutputHeight > maximums.px) {
      /*
        If pixel output size is too large,

        1. Convert artboard size to px using DPI = ARTBOARD_DPI.
           This should be within bounds since it's the actual pixel size of artboard
           and we don't allow bigger artboard sizes than export sizes.

        2. Convert back to the original unit using DEFAULT_DPI to get size to be back within range.
           DEFAULT_DPI > ARTBOARD_DPI => size / DEFAULT_DPI < size / ARTBOARD_DPI, so dimensions will
           end up smaller.

        This is only an issue when artboard is set to mm or in.
        px should not cause problems.
      */

      adjustedArtboardSize = convertToUnit({
        width: formattedArtboardSize.width,
        height: formattedArtboardSize.height,
        oldUnit: formattedArtboardSize.unit,
        newUnit: 'px',
      });

      adjustedArtboardSize = convertToUnit({
        width: adjustedArtboardSize.width,
        height: adjustedArtboardSize.height,
        oldUnit: 'px',
        newUnit: unit,
        dpi: DEFAULT_DOWNLOAD_DPI,
      });
    }

    const { width, height } = adjustedArtboardSize;

    const newAspectRatio = width / height;
    if (newAspectRatio >= 1) {
      // width is larger side
      const adjustedWidth = Math.min(
        Math.max(width, MINIMUM_EXPORT_SIZE[unit]),
        maximums[unit]
      );
      setSize({
        width: adjustedWidth,
        height: adjustedWidth / newAspectRatio,
        dpi: downloadOptions.dpi,
      });
    } else {
      const adjustedHeight = Math.min(
        Math.max(height, MINIMUM_EXPORT_SIZE[unit]),
        maximums[unit]
      );
      setSize({
        width: adjustedHeight * newAspectRatio,
        height: adjustedHeight,
        dpi: downloadOptions.dpi,
      });
    }

    setUnit(unit);
    setAspectRatio(newAspectRatio);
  }, [options, maximums, size, downloadOptions.dpi]);

  const onUnitChange = (unitId) => {
    // Maintain size but change unit
    const { width, height } = convertToUnit({
      width: size.width,
      height: size.height,
      oldUnit: unit,
      newUnit: unitId,
      dpi: size.dpi,
    });
    setUnit(unitId);
    setSize((size) => ({ width, height, dpi: size.dpi }));
  };

  const handleDpiClick = () => {
    handleShowUpgradeModal();
  };

  const onDpiChange = (dpi, isEditInterim = false) => {
    const _size = !isEditInterim ? size : editInterimSize;
    const _setSize = !isEditInterim ? setSize : setEditInterimSize;

    if (unit === 'px') {
      let width = (_size.width * dpi) / _size.dpi;
      let height = (_size.height * dpi) / _size.dpi;

      const max = Math.max(width, height);
      const min = Math.min(width, height);

      if (min < MINIMUM_EXPORT_SIZE.px) {
        _setSize((size) => ({ ...size }));
        return;
      }

      if (max > maximums.px) {
        const longerSide = maximums.px;
        const shorterSide = (maximums.px * min) / max;

        if (width >= height) {
          width = longerSide;
          height = shorterSide;
        } else {
          width = shorterSide;
          height = longerSide;
        }
      }

      _setSize(() => ({
        width,
        height,
        dpi,
      }));
    } else {
      const sizeInPixel = convertToUnit({
        width: _size.width,
        height: _size.height,
        oldUnit: unit,
        newUnit: 'px',
        dpi,
      });

      const max = Math.max(sizeInPixel.width, sizeInPixel.height);
      const min = Math.min(sizeInPixel.width, sizeInPixel.height);

      if (max > maximums.px || min < MINIMUM_EXPORT_SIZE.px) {
        _setSize((size) => ({ ...size }));
      } else {
        _setSize((size) => ({
          ...size,
          dpi,
        }));
      }
    }
  };

  const onSizeChange = (key, val, isEditInterim = false) => {
    const _size = !isEditInterim ? size : editInterimSize;
    const _setSize = !isEditInterim ? setSize : setEditInterimSize;

    let newWidth = MINIMUM_EXPORT_SIZE[unit];
    let newHeight = MINIMUM_EXPORT_SIZE[unit] / aspectRatio;

    if (val) {
      newWidth = key === 'width' ? val : val * aspectRatio;
      newHeight = key === 'height' ? val : val / aspectRatio;
    }

    const { width: finalWidth, height: finalHeight } = convertToUnit({
      width: newWidth,
      height: newHeight,
      oldUnit: unit,
      newUnit: 'px',
      dpi: _size.dpi,
    });

    const max = Math.max(finalWidth, finalHeight);
    if (max > maximums.px) {
      newWidth *= maximums.px / max;
      newHeight *= maximums.px / max;
    }

    const min = Math.min(finalWidth, finalHeight);
    if (min < MINIMUM_EXPORT_SIZE.px) {
      newWidth *= MINIMUM_EXPORT_SIZE.px / min;
      newHeight *= MINIMUM_EXPORT_SIZE.px / min;
    }

    _setSize((size) => ({
      width: newWidth,
      height: newHeight,
      dpi: size.dpi,
    }));
  };

  const inputProps = {
    precision: unit === 'px' ? 0 : 2,
    theme: numberInputTheme,
  };

  const getExportLabel = () => {
    const size = editInterimSize;
    const labelUnit = unit !== 'px' ? 'px' : 'in';
    const suffix = ` ${labelUnit}`;
    const { width: labelWidth, height: labelHeight } = convertToUnit({
      width: size.width,
      height: size.height,
      oldUnit: unit,
      newUnit: labelUnit,
      dpi: size.dpi,
    });

    if (!maximumSizeEnabled) {
      const finalWidth = unit !== 'px' ? labelWidth : size.width;
      const finalHeight = unit !== 'px' ? labelHeight : size.height;

      if (
        Math.round(finalWidth) >= MAXIMUM_FREE_EXPORT_SIZE.px ||
        Math.round(finalHeight) >= MAXIMUM_FREE_EXPORT_SIZE.px
      ) {
        return `<b>Free Plan</b> max. export size: ${MAXIMUM_FREE_EXPORT_SIZE.px}px`;
      }
    }

    const maybeRound =
      labelUnit === 'px' ? Math.round : (num) => Number(num.toFixed(2));
    return `Export size: ${maybeRound(labelWidth)}${suffix} × ${maybeRound(
      labelHeight
    )}${suffix}`;
  };

  const onSwitchClick = (type) => {
    const event =
      type === 'removeBackground'
        ? CLICK_DOWNLOAD_PANEL_REMOVE_BACKGROUND_SWITCH
        : CLICK_DOWNLOAD_PANEL_OPTIMIZE_QUALITY_SWITCH;
    analytics.track(event, { plan });
    handleShowUpgradeModal(type);
  };

  const onClickExportLabel = () => {
    if (!maximumSizeEnabled) {
      analytics.track(CLICK_DOWNLOAD_PANEL_EXPORT_LABEL);
      handleShowUpgradeModal();
    }
  };

  const setImageDownloadProcessing = useDownloadPanelStore(
    downloadPanelStoreSelector.setProcessing
  );

  const callbackValue = useRef();
  const dispatchDownload = (value, ignoreWarnings = false) => {
    const tooBig = Math.sqrt(size.width * size.height) > SAFE_EXPORT_PX_COUNT;
    const isRaster = ['png', 'jpg'].includes(value.format);

    if (!ignoreWarnings && tooBig && isRaster) {
      if (!highResolutionWarningDismissed) {
        setShowHighResolutionWarning(true);
        callbackValue.current = value;
        return;
      }
    }

    setImageDownloadProcessing({ format: value.format, processing: true });

    props.dispatch &&
      props.dispatch(DOWNLOAD, {
        removeBackground,
        optimizedQuality,
        size: {
          ...size,
          unit,
        },
        dpi: size.dpi,
        ...value,
        plan,
        callback: () => {
          if (!plan && props.onShowAttributionModal) {
            if (
              displayStep?.name !== ONBOARDING_USER_STEP.DOWNLOAD.name ||
              !firstDownload
            ) {
              props.onShowAttributionModal();
            } else {
              setFirstDownload(false);
            }
          }
        },
      });
  };

  const downloadProcessing = useDownloadPanelStore(
    downloadPanelStoreSelector.processing
  );

  const dpiAdjustment = ARTBOARD_DPI / size.dpi;
  const maximumsForUnit =
    unit === 'px'
      ? maximums.px
      : (unit === 'in' ? maximums.in : maximums.mm) * dpiAdjustment;

  const showHighResolutionWarning = useMenuStore(
    menuStoreSelector.showHighResolutionWarning
  );
  const setShowHighResolutionWarning = useMenuStore(
    menuStoreSelector.setShowHighResolutionWarning
  );
  const highResolutionWarningDismissed = useMenuStore(
    menuStoreSelector.highResolutionWarningDismissed
  );
  const setHighResolutionWarningDismissed = useMenuStore(
    menuStoreSelector.setHighResolutionWarningDismissed
  );

  const modalContent = (
    <ModalContent>
      <P2>
        The file size of your export is very large and can cause browser
        complications. Our recommended size limit is {SAFE_EXPORT_PX_COUNT} ×{' '}
        {SAFE_EXPORT_PX_COUNT}px.
      </P2>
      <Spacer h="24px" />
      <P2>Are you sure you want to continue?</P2>
      <Spacer h="24px" />
      <FlexRow justifyContent="center" alignItems="center" gap="12px">
        <Checkbox
          value={highResolutionWarningDismissed}
          onClick={() =>
            setHighResolutionWarningDismissed(!highResolutionWarningDismissed)
          }
        />
        <P2>Don't show this again</P2>
      </FlexRow>
    </ModalContent>
  );

  const renderPremiumBadge = () => <Icon name="premiumBadge" height="16px" />;

  return (
    <>
      <DecisionModal
        isOpen={showHighResolutionWarning}
        title={'Large File Size'}
        content={modalContent}
        onClose={() => setShowHighResolutionWarning(false)}
        firstButton={{
          label: 'Discard',
          type: 'secondary',
          onClick: () => {
            setShowHighResolutionWarning(false);
          },
        }}
        secondButton={{
          label: 'Continue',
          type: 'primary',
          onClick: () => {
            dispatchDownload(callbackValue.current, true);
          },
        }}
      />
      <Wrapper>
        <Section>
          <P3 color={themeStyle.varInkMain}>Download Size</P3>
          <FlexRow
            justifyContent={'space-between'}
            alignItems={'center'}
            ref={(node) =>
              useOnboardingStore
                .getState()
                .registerStepRef(node, ANCHOR_REF_KEYS.DOWNLOAD_PANEL)
            }
          >
            <InputContainer direction={'row'} width={'135px'}>
              <InputContainer>
                <FlexRow alignItems={'center'}>
                  <P4 style={{ width: '45px' }} color={themeStyle.varInkMain}>
                    Width:
                  </P4>
                  <NumberInput
                    dataTestId="export-width"
                    min={0}
                    max={
                      size.height > size.width
                        ? maximumsForUnit * aspectRatio
                        : maximumsForUnit
                    }
                    value={{ value: size.width }}
                    onChanging={(value) => onSizeChange('width', value)}
                    onValueEdit={(value) => onSizeChange('width', value, true)}
                    {...inputProps}
                  />
                </FlexRow>
                <FlexRow alignItems={'center'}>
                  <P4 style={{ width: '45px' }} color={themeStyle.varInkMain}>
                    Height:
                  </P4>
                  <NumberInput
                    dataTestId="export-height"
                    min={0}
                    max={
                      size.width > size.height
                        ? maximumsForUnit / aspectRatio
                        : maximumsForUnit
                    }
                    value={{ value: size.height }}
                    onChanging={(value) => onSizeChange('height', value)}
                    onValueEdit={(value) => onSizeChange('height', value, true)}
                    {...inputProps}
                  />
                </FlexRow>
              </InputContainer>
              <Icon
                name="lockedValues"
                height={'40px'}
                theme={{ color: themeStyle.varInkMedium }}
              />
            </InputContainer>
            <InputContainer>
              <FlexRow alignItems={'center'}>
                <P4 style={{ width: '35px' }} color={themeStyle.varInkMain}>
                  Unit:
                </P4>
                <Select
                  dataTestId="export-unit"
                  customStyles={selectCustomStyles}
                  options={UNIT_OPTIONS}
                  activeOption={UNIT_OPTIONS.find((opt) => opt.id === unit)}
                  onSelect={(val) => onUnitChange(val.id)}
                  small={true}
                />
              </FlexRow>
              <Interactive
                enabled={!maximumSizeEnabled}
                data-testid="export-dpi-blocker"
              >
                <FlexRow
                  alignItems={'center'}
                  position="relative"
                  onClick={handleDpiClick}
                  enabled={!maximumSizeEnabled}
                >
                  <P4
                    style={{ width: '35px' }}
                    color={
                      maximumSizeEnabled
                        ? themeStyle.varInkMain
                        : themeStyle.varInkLight
                    }
                  >
                    DPI:
                  </P4>
                  <NumberInput
                    dataTestId="export-dpi"
                    value={{ value: size.dpi }}
                    onChanging={onDpiChange}
                    onValueEdit={(value) => onDpiChange(value, true)}
                    precision={0}
                    min={10}
                    max={300}
                    disabled={!maximumSizeEnabled}
                    theme={numberInputTheme}
                  />
                </FlexRow>
              </Interactive>
            </InputContainer>
          </FlexRow>
          <FlexRow alignItems="center" gap="8px">
            <Icon
              name="infoThick"
              height={'14px'}
              theme={{
                color: themeStyle.varInkMedium,
                secondaryColor: 'transparent',
              }}
            />
            <ExportLabelWrapper>
              <P3
                onClick={onClickExportLabel}
                color={themeStyle.varInkMedium}
                dangerouslySetInnerHTML={{ __html: getExportLabel() }}
              />
            </ExportLabelWrapper>
          </FlexRow>
        </Section>
        <Section
          enabled={!plan}
          onClick={() => onSwitchClick('removeBackground')}
        >
          <FlexRow justifyContent="space-between" alignItems="center">
            <FlexRow alignItems="center" gap="6px">
              {!plan && renderPremiumBadge()}
              <P3 color={themeStyle.varInkMain}>Remove Background</P3>
            </FlexRow>
            <Switch
              dataTestId="export-remove-bg"
              onChange={setRemoveBackground}
              checked={removeBackground}
              disabled={!plan}
              theme={{ padding: 0 }}
            />
          </FlexRow>
        </Section>
        <Section
          enabled={!plan}
          onClick={() => onSwitchClick('improveImageQuality')}
        >
          <FlexRow justifyContent="space-between" alignItems="center">
            <FlexRow alignItems="center" gap="6px">
              {!plan && renderPremiumBadge()}
              <P3 color={themeStyle.varInkMain}>Optimize Quality</P3>
            </FlexRow>
            <Switch
              dataTestId="export-high-quality"
              onChange={setOptimizedQuality}
              checked={optimizedQuality}
              disabled={isIpadOperatingSystem() || !plan}
              theme={{ padding: 0 }}
            />
          </FlexRow>
        </Section>
        <Section>
          <FlexRow justifyContent={'space-between'} gap="10px">
            <ExportButtonsContainer width={'135px'}>
              <P4 color={themeStyle.varInkMain}>For digital use</P4>
              <Button
                dataTestId="export-png"
                theme={buttonTheme}
                width={'110px'}
                height={'40px'}
                label="PNG"
                loading={downloadProcessing.png}
                onClick={() =>
                  dispatchDownload({
                    format: 'png',
                  })
                }
              />
              <Button
                dataTestId="export-jpg"
                theme={buttonTheme}
                width={'110px'}
                height={'40px'}
                label="JPG"
                loading={downloadProcessing.jpg}
                onClick={() =>
                  dispatchDownload({
                    format: 'jpg',
                  })
                }
              />
            </ExportButtonsContainer>

            <ExportButtonsContainer
              width={'135px'}
              onClick={() => handleShowUpgradeModal()}
              enabled={!plan}
            >
              <P4 color={themeStyle.varInkMain}>Ideal for prints</P4>
              <Button
                dataTestId="export-pdf"
                theme={buttonTheme}
                width={'110px'}
                height={'40px'}
                label="PDF"
                onClick={() =>
                  dispatchDownload({
                    format: 'pdf',
                  })
                }
                icon={!plan ? { name: 'premiumBadge', height: '16px' } : null}
                loading={downloadProcessing.pdf}
                disabled={!plan}
              />
              <Button
                dataTestId="export-svg"
                theme={buttonTheme}
                width={'110px'}
                height={'40px'}
                label="SVG"
                onClick={() =>
                  dispatchDownload({
                    format: 'svg',
                  })
                }
                icon={!plan ? { name: 'premiumBadge', height: '16px' } : null}
                loading={downloadProcessing.svg}
                disabled={!plan}
              />
            </ExportButtonsContainer>
          </FlexRow>
        </Section>
      </Wrapper>
    </>
  );
};

DownloadPanel.propTypes = {
  /**
   * Current artboard options
   */
  options: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  /**
   * Dispatch function for handling events
   */
  dispatch: PropTypes.func,
  theme: PropTypes.object,
  onShowUpgradeModal: PropTypes.func,
  onShowAttributionModal: PropTypes.func,
  onMockupMode: PropTypes.func,
};

export default DownloadPanel;
