import { themeStyle } from '../../services/theming';

export const popoverTheme = {
  fontSize: themeStyle.fontSize12,
  zIndex: themeStyle.zIndexS2,
  fontWeight: themeStyle.fontMedium,
  padding: '12px',
  hasHeadBorder: false,

  textTransform: 'capitalize',
};
