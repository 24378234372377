import { useCallback, useEffect, useRef } from 'react';

import {
  designLayerId,
  findDesignLayer,
  isMockupTemplateWithWarpGrid,
} from '../utils';
import fabric from '../../Artboard/fabric';
import {
  LayersLoadingState,
  MockupTemplate,
  UpdateLayersLoading,
} from '../types';

/**
 * This hook handles the rendering of the design and draw that on board.
 */
export const useNonWarpRenderer = (
  canvas: fabric.Canvas | null,
  designDataUrl: string,
  mockupTemplate: MockupTemplate | null,
  layersLoading: LayersLoadingState,
  updateLoading: UpdateLayersLoading
): void => {
  const designLayer = useRef<fabric.Image>(null);

  const drawDesignLayer = useCallback(
    (object: fabric.Image | null) => {
      if (!canvas || !designLayer) return;

      // TODO:
      // we will need some metadata with the mockup template,
      // regarding where to position the design.
      // Now we simply put it in the center.
      object.adjustToArtboard({ canvas });
      object.set({
        visible: true,
      });
      canvas.insertAt(object, 1);
      canvas.setActiveObject(object);
    },
    [canvas]
  );

  // can only draw design on canvas when all other layers are loaded
  const canDrawDesignLayer =
    !layersLoading.sceneLayer &&
    !layersLoading.lightBlendLayer &&
    !layersLoading.darkBlendLayer &&
    !layersLoading.designLayer &&
    Boolean(mockupTemplate);

  useEffect(() => {
    if (!mockupTemplate || isMockupTemplateWithWarpGrid(mockupTemplate)) return;

    if (!designLayer.current && !layersLoading.designLayer) {
      updateLoading({
        designLayer: true,
      });

      fabric.Image.fromURL(
        designDataUrl,
        (object: fabric.Image) => {
          object.set({
            id: designLayerId,
            visible: canDrawDesignLayer,
          });

          // rotate is not supported
          object.setControlsVisibility({
            rotate: false,
          });

          designLayer.current = object;

          if (canDrawDesignLayer) {
            drawDesignLayer(object);
          }

          updateLoading({
            designLayer: false,
          });
        },
        { crossOrigin: 'anonymous' }
      );
    } else if (canDrawDesignLayer && !findDesignLayer(canvas)) {
      drawDesignLayer(designLayer.current);
    }
  }, [
    canvas,
    designDataUrl,
    mockupTemplate,
    canDrawDesignLayer,
    layersLoading.designLayer,
    updateLoading,
    drawDesignLayer,
  ]);
};
