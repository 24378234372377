import { layouts } from '../components/SettingsPanel/config';
import {
  ARTBOARD_DPI,
  IN_MULTIPLIER,
  MM_MULTIPLIER,
  ONE_MM_IN_INCHES,
} from '../global/constants';
import { ArtboardLayout, Unit } from '../types';

interface ArtboardOptions {
  activeLayout: ArtboardLayout;
  width: number;
  height: number;
  unitWidth: number;
  unitHeight: number;
  fill: string;
  opacity: number;
  portrait?: boolean;
  unit?: Unit;
}

/**
 * compute the final size in px
 */
export const computeFinalPixelSize = (
  sizeObject: { width: number; height: number; unit: Unit },
  dpi: number
): { width: number; height: number } => {
  const { unit } = sizeObject;
  let { width, height } = sizeObject;

  if (unit === 'in') {
    width *= dpi;
    height *= dpi;
  }

  if (unit === 'mm') {
    const dpmm = ONE_MM_IN_INCHES * dpi; // ---> Dots per mm
    width *= dpmm;
    height *= dpmm;
  }

  return {
    width,
    height,
  };
};

/**
 * convert current size to another unit
 */
export const convertSizeToUnit = (
  sizeObj: { width: number; height: number; unit: Unit },
  newUnit: Unit,
  dpi: number
): { width: number; height: number } => {
  // Previous size had a final pixel size for output. We want to maintain it
  let { width, height } = computeFinalPixelSize(sizeObj, dpi);

  if (newUnit === 'in') {
    width /= dpi;
    height /= dpi;
  }

  if (newUnit === 'mm') {
    const dpmm = ONE_MM_IN_INCHES * dpi; // ---> Dots per mm
    width /= dpmm;
    height /= dpmm;
  }

  return {
    width,
    height,
  };
};

/**
 * get width and height from artboardOtpions
 */
export const getUnitSizes = (
  artboardOptions: ArtboardOptions
): { unit: Unit; unitWidth: number; unitHeight: number } => {
  const { width, height, activeLayout } = artboardOptions;
  let { unitWidth, unitHeight } = artboardOptions;
  let unit = artboardOptions.unit || Unit.px;

  if (!unitWidth || !unitHeight) {
    if (activeLayout === 'custom') {
      const unitMultiplier =
        unit === Unit.mm ? MM_MULTIPLIER : unit === Unit.in ? IN_MULTIPLIER : 1;

      unitWidth = width / unitMultiplier;
      unitHeight = height / unitMultiplier;
    } else {
      const layout = layouts[activeLayout];
      unit = layout.size.unit;
      unitWidth = layout.size.x;
      unitHeight = layout.size.y;
    }
  }

  return {
    unit,
    unitWidth,
    unitHeight,
  };
};

/**
 * get width and height with the aspectRatio from artboardOtpions
 */
export const getSizeFromArtboardOptions = (
  artboardOptions: ArtboardOptions
): { width: number; height: number; unit: Unit; aspectRatio: number } => {
  const { unit, unitWidth, unitHeight } = getUnitSizes(artboardOptions);
  const [finalWidth, finalHeight] = [unitWidth, unitHeight].sort((a, b) =>
    artboardOptions.portrait ? a - b : b - a
  );
  const aspectRatio = finalWidth / finalHeight;

  return {
    width: finalWidth,
    height: finalHeight,
    unit,
    aspectRatio,
  };
};

/**
 * convert width and height from one unit to another
 */
export const convertToUnit = (options: {
  width: number;
  height: number;
  oldUnit: Unit;
  newUnit: Unit;
  dpi?: number;
}): { width: number; height: number } => {
  const { oldUnit, newUnit } = options;
  const dpi = options.dpi || ARTBOARD_DPI;
  let { width, height } = options;

  if (oldUnit === newUnit) {
    return {
      width,
      height,
    };
  }

  // First make sure units are expressed as inches
  if (oldUnit !== 'in') {
    if (oldUnit === 'px') {
      width /= dpi;
      height /= dpi;
    } else {
      // oldUnit === mm
      width *= ONE_MM_IN_INCHES;
      height *= ONE_MM_IN_INCHES;
    }
  }

  // Convert to new unit
  if (newUnit !== 'in') {
    if (newUnit === 'px') {
      width *= dpi;
      height *= dpi;
    } else {
      // newUnit === mm
      height /= ONE_MM_IN_INCHES;
      width /= ONE_MM_IN_INCHES;
    }
  }

  return {
    width,
    height,
  };
};

/**
 * Modifies artboardOptions such that dimensions are
 * expressed in the actual unit of the design.
 */
export const getFormattedArtboardSize = (options: {
  width: number;
  height: number;
  unit?: Unit;
  activeLayout?: ArtboardLayout;
  portrait?: boolean;
}): {
  width: number;
  height: number;
  unit: Unit;
  activeLayout: ArtboardLayout;
  portrait?: boolean;
} => {
  const unit = options.unit || Unit.px;
  const activeLayout = options.activeLayout || ArtboardLayout.custom;

  let width = options.width;
  let height = options.height;
  if (unit) {
    const { width: convertedWidth, height: convertedHeight } = convertToUnit({
      width,
      height,
      oldUnit: Unit.px,
      newUnit: unit,
    });
    width = convertedWidth;
    height = convertedHeight;
  }

  return { width, height, unit, activeLayout, portrait: options.portrait };
};
