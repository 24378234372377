import { tryFetchRetry } from '../utils/fetchRetry';
import { getAccessToken, USER_API_URL } from '../global/userApi';

export enum GeneratedContentStatus {
  Started = 'STARTED',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
}

export type GenerateImageResponse =
  | {
      id: string;
      success: true;
      objectName?: string;
      backend: string;
      status: GeneratedContentStatus;
    }
  | { success: false; error: string; errorMessage?: string };

type RemoveBackgroundResponse =
  | { success: true; id: string; objectName: string }
  | { success: false; error: string; errorMessage?: string };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createAPI = ({ url }: { url: string }) => {
  const generateImage = async (
    imagePrompt: string,
    style?: string | null
  ): Promise<GenerateImageResponse> => {
    return await tryFetchRetry<GenerateImageResponse>(`${url}/image`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        prompt: imagePrompt,
        style,
      }),
    });
  };

  const showGeneratedImage = async (
    id: string
  ): Promise<GenerateImageResponse> => {
    return await tryFetchRetry<GenerateImageResponse>(`${url}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  };

  const removeBackground = async (
    imageUrl: string
  ): Promise<RemoveBackgroundResponse> => {
    return await tryFetchRetry<RemoveBackgroundResponse>(
      `${url}/remove_background`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          image: imageUrl,
        }),
      }
    );
  };

  return {
    generateImage,
    showGeneratedImage,
    removeBackground,
  };
};

const GeneratorApi = createAPI({ url: `${USER_API_URL}/generators` });
export default GeneratorApi;
