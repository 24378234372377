import { themeStyle } from '../../services/theming';
import { useOnboardingStore } from '../../stores/onboardingStore';
import { ANCHOR_REF_KEYS } from '../../stores/onboardingStore/constants';
import { StyledTheme } from '../../types';
import Icon from '../Icon/Icon';
import IconEventWrapper from '../Icon/IconEventWrapper';
import { H5 } from '../utilities/Typography/styles';
import {
  CloseButtonWrapper,
  ItemsWrapper,
  ItemWrapper,
  Wrapper,
} from './styles';
import { defaultIconTheme } from './theme';
import { MenuTabConfig, MenuHeaderProps } from './types';

const MenuHeader: React.FC<MenuHeaderProps> = (props) => {
  const getRefCallback = (
    item: MenuTabConfig
  ): ((node: HTMLDivElement) => void) | null => {
    if (item.onMount) {
      return item.onMount;
    }

    return null;
  };

  const getIconTheme = (item: MenuTabConfig): StyledTheme => {
    return item.iconTheme ?? defaultIconTheme;
  };

  return (
    <Wrapper>
      <ItemsWrapper isMultiOption={props.config.length > 1}>
        {props.config.map(
          (item: MenuTabConfig): React.ReactNode => (
            <IconEventWrapper
              key={item.id}
              theme={getIconTheme(item)}
              onClick={(): void =>
                props.onClickTab && props.onClickTab(item.id)
              }
              dataTestId={`panel-${item.id}`}
              ref={getRefCallback(item)}
            >
              <ItemWrapper>
                {item.icon && (
                  <Icon
                    isActive={props.active === item.id}
                    theme={getIconTheme(item)}
                    name={item.icon}
                    height="16px"
                  />
                )}
                <H5
                  color={
                    props.active === item.id
                      ? themeStyle.varInkMain
                      : themeStyle.varInkMedium
                  }
                  ref={(node: HTMLHeadingElement | null): void => {
                    if (item?.id === 'projectColors') {
                      useOnboardingStore
                        .getState()
                        .registerStepRef(node, ANCHOR_REF_KEYS.COLORS_PANEL);
                    }
                  }}
                >
                  {item.label}
                </H5>
              </ItemWrapper>
            </IconEventWrapper>
          )
        )}
      </ItemsWrapper>
      {!props.hideClose && (
        <CloseButtonWrapper
          dataTestId="right-panel-close-icon"
          theme={defaultIconTheme}
          onClick={props.onClose}
        >
          <Icon theme={defaultIconTheme} name="close" height="10px" />
        </CloseButtonWrapper>
      )}
    </Wrapper>
  );
};

export default MenuHeader;
