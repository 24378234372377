import fontkit from '@heritage-type/fontkit';
import { detect } from 'detect-browser';

import { API_STORAGE_URL, CMS_STORAGE_URL } from '../utils/url';

import { userFontsStore } from '../stores/userFontsStore';
import { fetchRetry } from '../utils/fetchRetry';
import monitoring from '../services/monitoring';

export const DEFAULT_FONT_WEIGHT = 'Regular';

// This is a simple trick to implement Blob.arrayBuffer (https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer) using FileReader
// From: https://gist.github.com/hanayashiki/8dac237671343e7f0b15de617b0051bd
// We need it to load fonts in `loadFontByURL`
File.prototype.arrayBuffer = File.prototype.arrayBuffer || fallbackArrayBuffer;
Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || fallbackArrayBuffer;

function fallbackArrayBuffer() {
  // this: File or Blob
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsArrayBuffer(this);
  });
}

const NUMBER_STRING_MAP = {
  0: 'O',
  1: 'l',
  2: 'Z',
  3: 'E',
  4: 'A',
  5: 'S',
  6: 'G',
  7: 'L',
  8: 'B',
  9: 'g',
};

// This is needed as firefox can't handle numbers in font names
export const createSafeFontName = (name) => {
  const noNumbers = name.replace(/\d/g, (l) => NUMBER_STRING_MAP[l]);
  return noNumbers.replaceAll('/', '-').replace('.', '-');
};

/**
 * @deprecated
 * Old static fonts array, need to be completely replaced by CMS version.
 * Preserved for now to keep tests intact.
 */
export const DEPRECATED_FONTS = [
  {
    fontFamily: 'Blackriver',
    defaultWeight: 'Bold',
    weights: ['Bold'],
    img: '/images/fontPreviews/blackRiverBoldFont.svg',
  },
  {
    fontFamily: 'Blackriver_VARGX',
    variable: true,
    defaultWeight: 'Bold',
    weights: ['Bold'],
    img: '/images/fontPreviews/blackRiverBoldFont.svg',
    ext: 'ttf',
  },
  {
    fontFamily: 'Brilon',
    defaultWeight: 'Regular',
    weights: ['Regular'],
    img: '/images/fontPreviews/brilonRegularFont.svg',
  },
  {
    fontFamily: 'Mogan',
    defaultWeight: 'Regular',
    img: '/images/fontPreviews/moganRegularFont.svg',
    weights: ['Regular'],
  },
  {
    fontFamily: 'Charoe',
    defaultWeight: 'Regular',
    img: '/images/fontPreviews/charoeFont.svg',
    weights: ['Regular', 'Medium', 'Bold'],
  },
  {
    fontFamily: 'Signmaster',
    variable: true,
    defaultWeight: 'Regular',
    img: '/images/fontPreviews/signmasterRegularFont.svg',
    weights: ['Regular'],
    ext: 'ttf',
  },
  {
    fontFamily: 'Milkstore',
    defaultWeight: 'Regular',
    img: '/images/fontPreviews/milkstoreRegularFont.svg',
    weights: ['Regular'],
    ext: 'ttf',
  },
];

const fontsCache = {};
const fontsLoading = {};
const loadedFontFaces = new Set();

const loadFontByURL = (url, name, resolve, reject) => {
  if (fontsCache[url]) {
    return resolve(fontsCache[url]);
  }
  if (fontsLoading[url]) {
    return fontsLoading[url].push({ resolve, reject });
  }

  fontsLoading[url] = [{ resolve, reject }];

  return fetchRetry(url)
    .then((res) => res.blob())
    .then((blob) => blob.arrayBuffer())
    .then(async (arrayBuffer) => {
      const font = fontkit.create(Buffer.from(arrayBuffer));
      fontsCache[url] = font;
      fontsLoading[url].forEach(({ resolve }) => resolve(font));
      delete fontsLoading[url];
    })
    .catch((error) => {
      fontsLoading[url].forEach(({ reject }) => reject(error));
      delete fontsLoading[url];
    });
};

const loadLocalFont = (fontName, ext, resolve, reject) => {
  return loadFontByURL(
    `/fonts/editor/${fontName}.${ext || 'otf'}`,
    fontName,
    resolve,
    reject
  );
};

/**
 * utility function, to check if a font has a certain weight
 * if the font does not support that weight, the default fontWeight is returned
 * @param {*} fontParams
 * @param {String} weight
 */
const _checkFontWeight = (fontParams, weight) => {
  let fontWeight = weight;
  // fallback to provided `defaultWeight` if `weight` is not specified
  if (!fontWeight) {
    fontWeight = fontParams.defaultWeight;
  }
  // check if font supports `fontWeight` and fallback to `defaultWeight` if not
  if (!fontParams.weights.includes(fontWeight)) {
    fontWeight = fontParams.defaultWeight;
  }

  return fontWeight;
};

/**
 * check if the combination of fontFamily and weight is valid. In case
 * the font does not support the weight, a default fontWeight is returned.
 */
export const checkFontWeight = async (fontFamily, weight) => {
  const fontParams = await userFontsStore.getState().getFontParams(fontFamily);
  return new Promise((resolve, reject) => {
    if (!fontParams) {
      reject('Unknown font');
    }
    const fontWeight = _checkFontWeight(fontParams, weight);

    // everything failed, probably the fonts weights array is empty
    if (!fontWeight) {
      reject('Failed to determine font weight');
    }

    resolve(fontWeight);
  });
};

/**
 * Loads a font by its name and weight.
 * This function doesn't make more than 1 request per font.
 * fonts are cached in `fontsCache` object
 * @param {string} fontFamily
 * @param {string} weight
 */
export const loadFont = async (fontFamily, weight) => {
  let fontData;
  try {
    fontData = await getFontData(fontFamily, weight);
  } catch (error) {
    monitoring.captureException(error || new Error('Failed to load fontData'), {
      fontFamily,
      fontWeight: weight,
    });
    throw error;
  }

  const {
    url: fontURL,
    params: fontParams,
    family,
    weight: fontWeight,
  } = fontData;

  return new Promise((resolve, reject) => {
    const getResolver = (isUserFont) => (val) => {
      val.isUserFont = isUserFont;
      resolve(val);
    };
    const rejectionHandler = (reason) => {
      monitoring.captureException(reason || new Error('Failed to load font'), {
        fontFamily: family,
        fontWeight,
        fontURL,
        isCmsFont: fontParams.isCmsFont,
        isUserFont: fontParams.isUserFont,
      });
      reject(reason);
    };

    const fontName = `${family}-${fontWeight}`;
    if (fontParams.isCmsFont || fontParams.isUserFont) {
      loadFontByURL(
        fontURL,
        fontName,
        getResolver(fontParams.isUserFont),
        rejectionHandler
      );
    } else {
      loadLocalFont(
        fontName,
        fontParams.ext,
        getResolver(false),
        rejectionHandler
      );
    }
  });
};

/**
 * Gets font data.
 * @param {string} fontFamily
 * @param {string} weight
 */
export const getFontData = async (fontFamily, weight) => {
  const fontParams = await userFontsStore.getState().getFontParams(fontFamily);
  if (!fontParams) {
    throw new Error('Unknown font');
  }
  const fontWeight = _checkFontWeight(fontParams, weight);

  // everything failed, probably the fonts weights array is empty
  if (!fontWeight) {
    throw new Error('Failed to determine font weight');
  }

  const fontStyle = fontParams.styles.find(
    (style) => style.name === fontWeight
  );
  const fontURL = fontParams.isUserFont
    ? `${API_STORAGE_URL}/${fontStyle.objectName}`
    : `${CMS_STORAGE_URL}/${fontStyle.objectName}`;
  return {
    url: fontURL,
    params: fontParams,
    family: fontFamily,
    weight: fontWeight,
  };
};

export const loadFontFace = async (fontFamily, fontWeight, variation) => {
  const { url, family, weight } = await getFontData(fontFamily, fontWeight);
  const variationSettings = Object.keys(variation)
    .map((key) => {
      return `"${key}" ${variation[key]}`;
    })
    .join(',');

  const variationString = Object.keys(variation)
    .map((key) => {
      return `${key}-${variation[key]}`;
    })
    .join('-');

  const fontName = createSafeFontName(`${family}-${weight}-${variationString}`);
  if (loadedFontFaces.has(fontName)) {
    return fontName;
  }
  // modify font url for safari as it causes CORS issues (see https://app.clickup.com/t/t1czpz)
  // We assume that all browsers on iOS are "safari-like"
  // Firefox on iPad is detected as Safari on Mac OS by the way...
  const browser = detect();
  const safariLike = browser?.name === 'safari' || browser?.os === 'iOS';
  const fontUrl = safariLike ? `${url}?safari=true` : url;
  const fontFace = new FontFace(fontName, `url('${fontUrl}')`, {
    variationSettings: variationSettings || undefined, // NOTE: empty string is invalid options (this setting doesn't work in chrome)
    // variant: 'no-common-ligatures', // NOTE: doesn't work in any browser(throws errors in chrome)
  });
  await fontFace.load();
  document.fonts.add(fontFace);
  loadedFontFaces.add(fontName);
  return fontName;
};
