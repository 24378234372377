import React from 'react';
import PropTypes from 'prop-types';

import { ColorWrapper, OpacityWrapper } from '../styles';
import RightMenuHeadline from '../../utilities/RightMenuHeader/RightMenuHeader';
import ColorPicker from '../../ColorPicker/ColorPicker';
import NumberInput from '../../NumberInput/NumberInput';
import { SET_BACKGROUND } from '../../../global/events';
import { smallNumberInputTheme } from '../../NumberInput/theme';
import { FlexRow } from '../../utilities/styles';
import { P4 } from '../../utilities/Typography/styles';

/**
 * The BackgroundPanel. A panel to change the style of the background object.
 */
const BackgroundPanel = (props) => {
  const dispatchEvent = (value, isChanging = false) => {
    props.dispatch && props.dispatch(SET_BACKGROUND, { ...value, isChanging });
  };

  const presetColors = Object.keys(props.colorPalette);

  return (
    <>
      <RightMenuHeadline label={'Background Color'} />
      <FlexRow alignItems="center">
        <ColorWrapper>
          <ColorPicker
            defaultColor={props.options.fill}
            onChanging={(fill) => dispatchEvent({ fill }, true)}
            onChanged={(fill) => dispatchEvent({ fill }, false)}
            presetColors={presetColors}
            dispatch={props.dispatch}
          />
        </ColorWrapper>
        <OpacityWrapper>
          <P4>Opacity</P4>
          <NumberInput
            min={0}
            max={100}
            unit={'%'}
            precision={0}
            value={{ value: props.options.opacity * 100 }}
            onChanging={(opacity) =>
              dispatchEvent({ opacity: opacity / 100 }, true)
            }
            onChanged={(opacity) =>
              dispatchEvent({ opacity: opacity / 100 }, false)
            }
            theme={smallNumberInputTheme}
          />
        </OpacityWrapper>
      </FlexRow>
    </>
  );
};

BackgroundPanel.propTypes = {
  /**
   * current background styles options
   */
  options: PropTypes.shape({
    fill: PropTypes.string,
    opacity: PropTypes.number,
  }),
  /**
   * Object containing information about the artboards color palette
   */
  colorPalette: PropTypes.object,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
};

export default BackgroundPanel;
