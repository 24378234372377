import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { P2, P4 } from '../utilities/Typography/styles';

export const InputContainer = styled.div`
  width: ${({ inputWidth }) => inputWidth};
  min-width: 5ch;
  padding: 0;
`;

export const InfoText = styled(P4)`
  color: ${themeStyle.varInkMedium};
  margin-top: 3px;
  max-width: 100%;
`;

export const TagInputWithInfoText = styled.div`
  width: 100%;
`;

export const Input = styled(P2).attrs({ as: 'input' })`
  width: 100%;
  min-width: 5ch;
  height: auto;
  padding: 0;
  color: ${themeStyle.varInkMedium};
  text-align: left;
  background-color: transparent;
  border: none;

  transition: color ${themeStyle.transitionDuration};

  &::placeholder {
    color: ${themeStyle.varInkMedium};
    transition: color ${themeStyle.transitionDuration};
  }

  &:active,
  &:focus {
    outline: none;
    color: ${themeStyle.varInkMain};

    &::placeholder {
      color: ${themeStyle.varInkMain};
    }
  }
`;

export const TagInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  width: 100%;

  cursor: ${({ showTextInput, disabled }) =>
    disabled || !showTextInput ? 'default' : 'text'};

  border: 1px solid;
  border-radius: ${themeStyle.radiusMedium};
  border-color: ${themeStyle.varInkLight};
  background-color: ${themeStyle.varBackgroundAlt};

  transition: ${themeStyle.transitionDuration};
  transition-property: box-shadow, border-color, background-color;
  gap: 8px;

  &:hover {
    border-color: transparent;
    box-shadow: ${themeStyle.varShadowSmallFocus};

    html[data-theme='dark'] && {
      background-color: ${themeStyle.darkInkSuperLight};
    }
  }

  padding: 8px 10px;
`;
