import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon/Icon';

import { layerIconTheme, layerIconThemeGroup } from '../theme';

const typeToIconMapping = {
  image: 'elements',
  pathText: 't',
  artboard: 'background',
  structuralGroup: 'group',
  illustration: 'draw',
  overlay: 'overlay',
  background: 'background',
  clippingMask: 'clippingMask',
};

const GROUP_TYPES = ['structuralGroup', 'clippingMask'];

/**
 * Icon of a layer
 */
const LayerIcon = ({ type, hasChildren, expanded, selected }) => {
  const icon = typeToIconMapping[type] || 'elements';
  const expandedWithChildren = hasChildren && expanded;
  const theme = GROUP_TYPES.includes(type)
    ? layerIconThemeGroup
    : layerIconTheme;

  return (
    <Icon
      name={icon}
      theme={theme}
      isActive={expandedWithChildren || selected}
      height={type === 'group' ? '9px' : '10px'}
    />
  );
};

LayerIcon.propTypes = {
  /**
   * The type of the layer
   */
  type: PropTypes.string,
  /**
   * Whether the layer has children
   */
  hasChildren: PropTypes.bool,
  /**
   * Whether the layer is expanded (important for groups)
   */
  expanded: PropTypes.bool,
  /**
   * Whether the layer is selected
   */
  selected: PropTypes.bool,
};

export default React.memo(LayerIcon);
