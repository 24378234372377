import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import nProgress from 'nprogress';
import Cookies from 'js-cookie';

import { FormWrapper, IconWrapper } from './styles';
import { errorTheme } from './theme';

import Button from '../../Button/Button/Button';
import { primaryTheme } from '../../Button/Button/theme';
import Spacer from '../../Spacer/Spacer';
import TextInput from '../../TextInput/TextInput';
import { P4 } from '../../utilities/Typography/styles';

import {
  validateName,
  validateEmail,
  validatePassword,
} from '../../../utils/validation';
import { useUserStore, userStoreSelector } from '../../../stores/userStore';
import { formatResponseError } from './utils';

import Icon from '../../Icon/Icon';
import { FlexRow } from '../../utilities/styles';
import analytics from '../../../global/analytics';
import {
  CREATE_ACCOUNT,
  LOGIN,
  LOGIN_LOCAL_ERROR,
  LOGIN_SERVER_ERROR,
  SIGNUP_LOCAL_ERROR,
  SIGNUP_MOBILE,
  SIGNUP_SERVER_ERROR,
} from '../../../global/analytics/events';
import { isMobile } from '../../../utils/detection';
import {
  IMPACT_CLICK_ID,
  COOKIE_UTM_CAMPAIGN,
} from '../../../global/constants';
import { themeStyle } from '../../../services/theming';

/**
 * Login/ Signup form within the AuthComponent
 */
const Form = ({
  email,
  error: errorFromProps,
  isSignup,
  inviteCode,
  referralCode,
  promoCode,
  onSubmit,
  callToAction,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: email || '',
    password: '',
  });
  const [error, setError] = useState({
    name: null,
    email: null,
    password: null,
    server: null,
  });

  useEffect(() => {
    setError((prev) => ({ ...prev, server: errorFromProps }));
  }, [errorFromProps]);

  const validateValue = (prop, value) => {
    switch (prop) {
      case 'name':
        return validateName(value);
      case 'email':
        return validateEmail(value);
      case 'password':
        return validatePassword(value);
      default:
        return null;
    }
  };

  const handleValidation = (prop, value) => {
    const error = validateValue(prop, value);
    setError((prev) => ({ ...prev, [prop]: error }));
  };

  const handleValidationReset = (prop, value) => {
    const error = validateValue(prop, value);
    if (!error) setError((prev) => ({ ...prev, [prop]: null }));
  };

  const handleCleaning = (prop, value) => {
    if (prop !== 'password') {
      return value.trim();
    }
    return value;
  };

  const handleSubmitValidation = () => {
    const errors = { name: null, email: null, password: null, server: null };
    if (isSignup) errors.name = validateValue('name', form.name);
    errors.email = validateValue('email', form.email);
    errors.password = validateValue('password', form.password);
    const hasErrors = Object.entries(errors).some(([_, err]) => err !== null);
    return { errors, hasErrors };
  };

  const updateForm = (prop, value) => {
    const cleanValue = handleCleaning(prop, value);
    handleValidationReset(prop, cleanValue);
    setForm((prev) => ({ ...prev, [prop]: cleanValue }));
  };

  const handleSubmit = () => {
    const { errors, hasErrors } = handleSubmitValidation();
    setError({ ...errors });
    Object.keys(errors).forEach((key) => {
      if (errors[key]) {
        analytics.track(isSignup ? SIGNUP_LOCAL_ERROR : LOGIN_LOCAL_ERROR, {
          label: `field: ${key}; error: ${errors[key]}`,
        });
      }
    });
    if (hasErrors || isLoading) return;

    analytics.track(isSignup ? CREATE_ACCOUNT : LOGIN, {
      label: `email: ${form.email}; name: ${form.name}`,
    });

    setLoading(true);
    nProgress.start();
  };

  const createUser = useUserStore(userStoreSelector.createUser);
  const loginUser = useUserStore(userStoreSelector.loginUser);

  const impactClickId = Cookies.get(IMPACT_CLICK_ID);
  const submitForm = useCallback(() => {
    const action = isSignup ? createUser : loginUser;

    const args = {
      name: form.name,
      email: form.email,
      password: form.password,
      invite: inviteCode,
      referral: referralCode,
      promoCode: promoCode,
      impactClickId,
    };

    const campaign = isSignup && Cookies.get(COOKIE_UTM_CAMPAIGN);

    if (campaign) {
      args.campaign = campaign;
    }

    return action(args);
  }, [
    isSignup,
    createUser,
    loginUser,
    form.name,
    form.email,
    form.password,
    inviteCode,
    referralCode,
    promoCode,
    impactClickId,
  ]);

  // The actual submission is moved here, because `Form` can be unmounted when the request is completed,
  // on which we cannot perform set state by then.
  useEffect(() => {
    let isRendered = true;

    if (isLoading) {
      submitForm()
        .then((response) => {
          if (isSignup && isMobile()) {
            analytics.track(SIGNUP_MOBILE);
          }
          if (response.error) {
            formatResponseError(response, form, (prop, error) => {
              if (isRendered) {
                setError((prev) => ({ ...prev, [prop]: error }));
              }

              analytics.track(
                isSignup ? SIGNUP_SERVER_ERROR : LOGIN_SERVER_ERROR,
                {
                  label: `field: ${prop}; error: ${error}`,
                }
              );
            });
          } else {
            onSubmit && onSubmit();
          }
        })
        .finally(() => {
          nProgress.done();

          if (isRendered) {
            setLoading(false);
          }
        });
    }

    return () => {
      isRendered = false;
    };
  }, [isLoading, isSignup, onSubmit, form, submitForm]);

  return (
    <FormWrapper name={isSignup ? 'signup' : 'login'}>
      {isSignup && (
        <>
          <TextInput
            name="name"
            placeholder="Name"
            onEnter={handleSubmit}
            onChanging={(name) => updateForm('name', name)}
            onBlur={() => handleValidation('name', form.name)}
            error={error.name}
          />
          <Spacer h={error.name ? '8px' : '12px'} />
        </>
      )}
      <TextInput
        value={form.email}
        placeholder="Your E-Mail"
        name="email"
        autoComplete="username"
        onEnter={handleSubmit}
        onChanging={(email) => updateForm('email', email)}
        onBlur={() => handleValidation('email', form.email)}
        error={error.email}
      />
      <Spacer h={error.email ? '8px' : '12px'} />
      <TextInput
        placeholder="Your Password"
        name="password"
        autoComplete={isSignup ? 'new-password' : 'current-password'}
        isPasswordInput
        onEnter={handleSubmit}
        onChanging={(password) => updateForm('password', password)}
        onBlur={() => handleValidation('password', form.password)}
        error={error.password}
      />
      <Spacer h={error.password ? '8px' : '12px'} />
      {error.server && (
        <FlexRow alignItems={'center'} marginBottom={'6px'}>
          <IconWrapper>
            <Icon name={'infoThick'} height={'18px'} theme={errorTheme} />
          </IconWrapper>
          <P4 color={themeStyle.varAlertRed}>{error.server}</P4>
        </FlexRow>
      )}
      <Button
        type="button"
        dataTestId={isSignup ? 'signup' : 'login'}
        label={
          callToAction
            ? callToAction
            : isSignup
            ? 'Create Free Account'
            : 'Log In'
        }
        theme={primaryTheme}
        isBlock
        height="40px"
        onClick={handleSubmit}
        disabled={isLoading}
      />
    </FormWrapper>
  );
};

Form.propTypes = {
  /**
   * Handler for when the form is submitted and the response is errorless
   */
  onSubmit: PropTypes.func,
  /**
   * Whether the form will be used to sign up
   */
  isSignup: PropTypes.bool,
  /**
   * pre-fill the form with an email address
   */
  email: PropTypes.string,
  /**
   * inviteCode for the signup
   */
  inviteCode: PropTypes.string,
  /**
   * referralCode for the signup
   */
  referralCode: PropTypes.string,
  /**
   * promoCode for the signup
   */
  promoCode: PropTypes.string,
  /**
   * error message that will be displayed as a server error
   */
  error: PropTypes.string,
  callToAction: PropTypes.string,
};

export default Form;
