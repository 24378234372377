import { useEffect, useRef } from 'react';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { setIsActiveDesignModified } from '../components/Artboard/handleEvents/utils';
import {
  HISTORY_PREFIX,
  HISTORY_RESET,
  REDO,
  SET_STATE,
  UNDO,
} from '../global/events';
import createHistory, { HistoryState, HistoryUtil } from '../services/history';
import { useMenuStore } from '../stores/menuStore';
import { Dispatcher, EventBusData, WorkingMode } from '../types';

const useHistory = (
  bus: Subject<EventBusData>,
  dispatch: Dispatcher
): { historyRef: React.MutableRefObject<HistoryUtil> } => {
  const historyRef = useRef<HistoryUtil>(createHistory());

  useEffect(() => {
    const historyEvents = bus.pipe(
      filter(({ key }) => key.startsWith(HISTORY_PREFIX))
    );
    const historySubscription = historyEvents.subscribe((event) => {
      const { key, val } = event;

      let newState;
      if (key === UNDO) {
        newState = historyRef.current.undo();
      } else if (key === REDO) {
        newState = historyRef.current.redo();
      } else if (key === HISTORY_RESET) {
        historyRef.current.reset(val as HistoryState);
      }

      if (useMenuStore.getState().workingMode === WorkingMode.Default) {
        setIsActiveDesignModified(key);
      }

      newState &&
        dispatch(SET_STATE, { state: newState, isHistoryChange: true });
    });

    return (): void => historySubscription.unsubscribe();
  }, [bus, dispatch]);

  return { historyRef };
};

export default useHistory;
