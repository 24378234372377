import { useEffect } from 'react';

import fabric from '../../Artboard/fabric';
import { updateWarpControlPoints } from '../utils/warpControlPoints';
import {
  mockupTemplateStoreSelector,
  useMockupTemplateStore,
} from '../../../stores/mockupTemplateStore';
import { Dispatcher } from '../../../types';
import { getWarpControlPoints } from '../../Artboard/fabric/mockupTemplate/utils';
import { HISTORY_RESET } from '../../../global/events';

/**
 * This hook handles the generation of warp control points, on warp grid size changes.
 */
export const useWarpGridEffect = (
  canvas: fabric.Canvas | null,
  dispatch: Dispatcher
): void => {
  const { row, col } = useMockupTemplateStore(
    mockupTemplateStoreSelector.warpGridSize
  );

  useEffect(() => {
    // Only update the warp control points when there are existing ones,
    // so we don't create the points initially.
    if (!canvas || getWarpControlPoints(canvas).length === 0) return;

    updateWarpControlPoints(canvas, row, col);

    // reset history when having different grid settings
    dispatch(HISTORY_RESET);
  }, [canvas, dispatch, row, col]);
};
