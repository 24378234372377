/*
    Handles backwards compatibility with old fabric (< 5.0.0) transform events
    (moved, scaled, rotated, skew)

    see http://fabricjs.com/v5-breaking-changes
*/

const TRANSFORM_EVENTS = {
  moved: 'drag',
  scaled: 'scale',
  rotated: 'rotate',
  skewed: 'skew',
};

export default function (fabric) {
  fabric.Observable.on = (function (on) {
    return function (eventName, handler) {
      const actionName = TRANSFORM_EVENTS[eventName];
      if (actionName) {
        if (!this.deprecatedTransformEventHandlers) {
          this.deprecatedTransformEventHandlers = {
            drag: [],
            scale: [],
            rotate: [],
            skew: [],
          };
        }

        this.deprecatedTransformEventHandlers[actionName].push(handler);
        return this;
      }

      return on.bind(this)(eventName, handler);
    };
  })(fabric.Observable.on);

  fabric.Observable.off = (function (off) {
    return function (eventName, handler) {
      const actionName = TRANSFORM_EVENTS[eventName];
      if (actionName) {
        if (!this.deprecatedTransformEventHandlers) return;

        this.deprecatedTransformEventHandlers[actionName] =
          this.deprecatedTransformEventHandlers[actionName].filter(
            (h) => h !== handler
          );

        return this;
      }

      return off.bind(this)(eventName, handler);
    };
  })(fabric.Observable.off);

  fabric.Observable.fire = (function (fire) {
    return function (eventName, options = {}) {
      if (eventName === 'modified') {
        const { action } = options;
        if (
          action &&
          this.deprecatedTransformEventHandlers &&
          this.deprecatedTransformEventHandlers[action]
        ) {
          this.deprecatedTransformEventHandlers[action].forEach((f) => {
            f.call(this, options || {});
          });
        }
      } else {
        const actionName = TRANSFORM_EVENTS[eventName];
        if (actionName) {
          if (!this.deprecatedTransformEventHandlers) return;

          this.deprecatedTransformEventHandlers[actionName].forEach((f) => {
            f.call(this, options || {});
          });
        }
      }

      return fire.bind(this)(eventName, options);
    };
  })(fabric.Observable.fire);

  // Taken from fabric.util code
  function fireEvent(eventName, options) {
    const target = options.transform.target;
    const canvas = target.canvas;

    /*
      We changed order of these two lines.

      We take a snapshot of state when canvas fires 'object:modified',
      so we want target to first handle its modification with its own handlers
      before this happens. Otherwise state might be incorrect.
    */
    target.fire(eventName, options);
    canvas &&
      canvas.fire(
        'object:' + eventName,
        Object.assign({}, options, { target: target })
      );
  }

  fabric.Canvas.prototype._fire = fireEvent;

  // These classes make use of observable, we update them
  fabric.util.object.extend(fabric.StaticCanvas.prototype, fabric.Observable);
  fabric.util.object.extend(fabric.Object.prototype, fabric.Observable);
  fabric.util.object.extend(fabric.IText.prototype, fabric.Observable);
  fabric.util.object.extend(fabric.Textbox.prototype, fabric.Observable);
}
