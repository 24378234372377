/**
 * definitions of themes
 */

import {
  getCssVarObject,
  getFilterShadowObject,
  prefixObjectKeys,
} from './utils';

export const themeColorsDefault = {
  // brand
  brandMain: '#17A191',
  brandMedium: '#5BD3C1',
  brandLight: '#B8F1E3',
  brandSuperLight: '#67E0CA4D', // 30% alpha

  // background
  backgroundBase: '#F3F5F7',
  backgroundAlt: '#FFFFFF',
  backgroundAltTransparent: '#FFFFFF00', // 0% alpha, used for better transitions
  backgroundGradientHalf:
    'radial-gradient(113.87% 115.79% at 27.66% -52.37%,#5affcdcc 0%,#5affcd00 100%) ,radial-gradient(110.76% 113.16% at 73.95% -47.19%,#5753ff6e 0%,#5753ff00 100%)',
  backgroundGradientFull:
    'linear-gradient(311.66deg, rgba(65, 72, 226, 0.2) -13.98%, rgba(65, 72, 226, 0) 42.49%), radial-gradient(94.22% 116.41% at 36.4% 82.85%, rgba(15, 111, 255, 0.2) 0%, rgba(15, 111, 255, 0) 100%) , radial-gradient(135.79% 111.32% at 64.05% -16.67%, rgba(100, 255, 209, 0.9) 0%, rgba(81, 255, 224, 0) 100%) , radial-gradient(86.55% 88.86% at 84.74% -26.75%, rgba(15, 255, 241, 0.8) 0%, rgba(15, 169, 255, 0) 100%) , #191B1E',

  // ink
  inkBase: '#FFFFFF',
  inkMain: '#21242A',
  inkMedium: '#6F7780',
  inkLight: '#E2E6ED',
  inkSuperLight: '#F3F5F7',

  // misc
  highlight: '#4148E2',
  selectionBlue: '#18A0FB',
  selectionBlue10: '#18A0FB1A',
  selectionBlue30: '#18A0FB4D',
  alertRed: '#FF5757',
  goldGradientLight: 'linear-gradient(180deg, #FFFFFD 0%, #FFECBC 100%)',
  goldGradient: 'linear-gradient(180deg, #F9C642 40.1%, #EBA429 100%)',
  bronze: '#E77F5E',
  transparentBlack: '#0000000D', // 5% alpha

  transparent: 'transparent',
};

export const themeColorsDark = {
  // brand
  brandMain: '#55D4C1',
  brandMedium: '#89E7D9',
  brandLight: '#C7FCEF',
  brandSuperLight: '#67E0CA2E', // 18% alpha

  // background
  backgroundBase: '#191B1E',
  backgroundAlt: '#25282FEB', // 92% alpha
  backgroundAltTransparent: '#25282F00', // 0% alpha, used for better transitions

  // ink
  inkBase: '#191B1E',
  inkMain: '#E9EEF5',
  inkMedium: '#7E8B98',
  inkLight: '#686F8480', // 50% alpha
  inkSuperLight: '#3F455480', // 50% alpha

  // misc
  highlight: '#4E55FA',
  transparentBlack: '#00000026', // 15% alpha
};

export const themeShadowsDefault = {
  /**
   * Use over dark overlays
   * Use for Tooltips
   */
  shadowHeavy:
    '0px 60px 90px rgba(0, 0, 0, 0.18), 0px 12px 20px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.08)',
  /**
   * Use as default
   * Use for Menus
   */
  shadowMedium:
    '0px 36px 98px rgba(0, 0, 0, 0.07), 0px 13px 36px rgba(0, 0, 0, 0.05), 0px 6px 18px rgba(0, 0, 0, 0.0389404), 0px 3px 8px rgba(0, 0, 0, 0.0310596), 0px 2px 4px rgba(0, 0, 0, 0.02)',
  /**
   * Use for modals that are integrated on a page
   * Ex. challenge tiles
   */
  shadowSoft:
    '0px 12px 32px rgba(0, 0, 0, 0.05), 0px 6px 15px rgba(0, 0, 0, 0.0389404), 0px 3px 7px rgba(0, 0, 0, 0.0310596), 0px 1px 3px rgba(0, 0, 0, 0.02)',
  /**
   * Use for active states, ex. inputs
   */
  shadowSmallFocus: '0px 1px 4px rgba(0, 0, 0, 0.14)',
  /**
   * Use for small elements that need hard contrast
   * Use for Color Swatches
   */
  shadowSmallHeavy: '1px 1px 3px rgba(0, 0, 0, 0.25)',
};

export const themeFiltersDefault = getFilterShadowObject(themeShadowsDefault);

export const themeShadowsDark = {
  /**
   * Use over dark overlays
   * Use for Tooltips
   */
  shadowHeavy:
    '0px 57px 91px rgba(0, 0, 0, 0.38), 0px 13px 20px rgba(0, 0, 0, 0.2), 0px 4px 6px rgba(0, 0, 0, 0.18)',
  /**
   * Use as default
   * Use for Menus
   */
  shadowMedium:
    '0px 36px 98px rgba(0, 0, 0, 0.17), 0px 13px 36px rgba(0, 0, 0, 0.2), 0px 7px 18px rgba(0, 0, 0, 0.14), 0px 3px 9px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.1217)',
  /**
   * Use for modals that are integrated on a page
   * Ex. challenge tiles
   */
  shadowSoft:
    '0px 12px 30px rgba(0, 0, 0, 0.14), 0px 5px 25px rgba(0, 0, 0, 0.2), 0px 3px 8px rgba(0, 0, 0, 0.35), 0px 1px 3px rgba(0, 0, 0, 0.02)',
  /**
   * Use for active states, ex. inputs
   */
  shadowSmallFocus: '0px 1px 4px rgba(0, 0, 0, 0.45)',
  /**
   * Use for small elements that need hard contrast
   * Use for Color Swatches
   */
  shadowSmallHeavy: '1px 1px 3px rgba(0, 0, 0, 0.55)',
};

export const themeFiltersDark = getFilterShadowObject(themeShadowsDark);

const defaultTheme = {
  ...themeColorsDefault,
  ...themeShadowsDefault,
  ...themeFiltersDefault,
};

const darkTheme = {
  ...prefixObjectKeys<typeof themeColorsDark, 'dark'>(themeColorsDark, 'dark'),
  ...prefixObjectKeys<typeof themeShadowsDark, 'dark'>(
    themeShadowsDark,
    'dark'
  ),
  ...prefixObjectKeys<typeof themeFiltersDark, 'dark'>(
    themeFiltersDark,
    'dark'
  ),
};

export const themeStyle = {
  ...defaultTheme,
  ...darkTheme,

  fontFamily: 'Inter',

  // font sizes
  fontSize10: '10px',
  fontSize11: '11px',
  fontSize12: '12px',
  fontSize14: '14px',
  fontSize16: '16px',
  fontSize18: '18px',
  fontSize26: '26px',
  fontSize36: '36px',

  // font weights
  fontRegular: 400,
  fontMedium: 500,
  fontSemiBold: 600,
  fontBold: 700,
  fontExtraBold: 800,

  // border radius
  radiusSmall: '5px',
  radiusMedium: '8px',
  radiusLarge: '12px',

  //zIndex
  zIndexNegative: -1,
  zIndexZero: 0,
  zIndexS1: 1,
  zIndexS2: 2,
  zIndexS3: 3,
  zIndexS9: 9,
  zIndexM1: 10,
  zIndexM9: 99,
  zIndexL9: 999,

  // transition
  transitionDuration: '0.3s',

  // vars (colors, shadows)
  // using vars should be preferred over using theme colors directly
  ...getCssVarObject<typeof defaultTheme>(defaultTheme),
};
