import React from 'react';
import PropTypes from 'prop-types';

import EffectSelect from '../../EffectSelect/EffectSelect';
import RightMenuHeadline from '../../utilities/RightMenuHeader/RightMenuHeader';
import ColorPicker from '../../ColorPicker/ColorPicker';
import SliderInput from '../../SliderInput/SliderInput';

import { ACTIVE_COLOR, ACTIVE_DECORATION } from '../../../global/events';
import { config } from './config';
import { useOnboardingStore } from '../../../stores/onboardingStore';
import { ANCHOR_REF_KEYS } from '../../../stores/onboardingStore/constants';
import { ConfigurationPanel } from '../../EffectSelect/styles';

/**
 * The DecorationPanel
 */
const DecorationPanel = (props) => {
  const options = props.options || {};

  const updateOptions = (type, value, isChanging) => {
    if (type === 'color') {
      props.dispatch &&
        props.dispatch(ACTIVE_COLOR, { key: 'decoration', value, isChanging });
    } else if (type === 'type' && options.type !== value) {
      // set distance and weight to default values
      const updatedDecoration = {
        ...options,
        type: value,
        weight: ['horizontalLines', 'obliqueLines'].includes(value)
          ? 0.01
          : 0.05,
        distance: 0.01,
        cutDistance: 0.5,
        isChanging,
      };

      props.dispatch && props.dispatch(ACTIVE_DECORATION, updatedDecoration);
    } else {
      const updatedDecoration = {
        ...options,
        [type]: value,
        isChanging,
      };
      props.dispatch && props.dispatch(ACTIVE_DECORATION, updatedDecoration);
    }
  };

  const colorPicker = (
    <ColorPicker
      defaultColor={options.color}
      onChanging={(value) => updateOptions('color', value, true)}
      onChanged={(value) => updateOptions('color', value, false)}
      presetColors={Object.keys(props.colorPalette)}
      dispatch={props.dispatch}
    />
  );

  const getConfigPanel = (item) => {
    const isColorCut =
      options.type === 'colorCut' || options.type === 'fadingColorCut';
    const distanceKey = isColorCut ? 'cutDistance' : 'distance';

    return (
      <ConfigurationPanel gap="14px">
        <RightMenuHeadline
          label={item.label}
          colorPickers={[colorPicker]}
          small
        />
        <div
          ref={(node) =>
            useOnboardingStore
              .getState()
              .registerStepRef(node, ANCHOR_REF_KEYS.DECORATION_SLIDER)
          }
        >
          <SliderInput
            dataTestId="decoration-weight"
            label={'Weight'}
            unit={'%'}
            startValue={options.weight * 100}
            min={1}
            max={10}
            precision={0}
            step={1}
            onChanging={(value) => updateOptions('weight', value / 100, true)}
            onChanged={(value) => updateOptions('weight', value / 100, false)}
            disabled={!options.type || options.type === 'colorCut'}
          />
        </div>
        <SliderInput
          dataTestId="decoration-distance"
          label={'Distance'}
          unit={'%'}
          startValue={options[distanceKey] * 100}
          min={1}
          max={isColorCut ? 100 : 12}
          precision={0}
          step={1}
          onChanging={(value) => updateOptions(distanceKey, value / 100, true)}
          onChanged={(value) => updateOptions(distanceKey, value / 100, false)}
          disabled={!options.type}
        />
      </ConfigurationPanel>
    );
  };

  const processedConfig = config.map(({ id, icon, ...item }) => {
    const configPanel = getConfigPanel(item);
    return {
      id,
      icon,
      configPanel,
      iconMapping: item.iconMapping,
    };
  });

  return (
    <EffectSelect
      label={'Decoration'}
      useActiveOverlay={true}
      activeEffect={options.type}
      config={processedConfig}
      disabled={props.disabled}
      onSelect={(value) => updateOptions('type', value, false)}
    />
  );
};

DecorationPanel.propTypes = {
  /**
   * current effect options
   */
  options: PropTypes.shape({
    type: PropTypes.oneOf([
      'horizontalLines',
      'colorCut',
      'obliqueLines',
      'fadingColorCut',
    ]),
    weight: PropTypes.number,
    distance: PropTypes.number,
    color: PropTypes.string,
  }),
  /**
   * Object containing information about the artboards color palette
   */
  colorPalette: PropTypes.object,
  /**
   * Whether the panel is disabled
   */
  disabled: PropTypes.bool,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
};

export default DecorationPanel;
