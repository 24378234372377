import { themeStyle } from '../../../services/theming';
import { primaryTheme } from '../../Button/Button/theme';

const numberInputTheme = {
  height: '30px',
  width: '75px',
};

const selectCustomStyles = {
  width: '75px',
  height: '30px',
  paddingLeft: '8px',
};

const buttonTheme = {
  ...primaryTheme,
  backgroundDisabledColorDark: themeStyle.varInkLight,
  disabledColorDark: themeStyle.varInkMedium,
};

export { numberInputTheme, selectCustomStyles, buttonTheme };
