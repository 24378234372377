import styled, { css } from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { IconMapping } from './types';
import {
  getActiveDark,
  getActiveLight,
  getBackgroundColor,
  getBorder,
  getCSSIconStyle,
  getDark,
  getLight,
} from './styleutils';

export interface WrapperProps {
  size: string;
  isActive?: boolean;
  iconMapping?: IconMapping;
}

const getDefaultDarkStyles = (_: WrapperProps) => css`
  background: ${getBackgroundColor(false, true)};
  border: ${getBorder(false, true)};
  ${getCSSIconStyle(getDark)}
`;

const getActiveDarkStyles = (_: WrapperProps) => css`
  background: ${themeStyle.darkBrandSuperLight};
  border: ${getBorder(true, true)};
  box-shadow: inset 0 0 0 2px ${getBackgroundColor(true, true)};
  ${getCSSIconStyle(getActiveDark)}
`;

const getDarkStyles = (props: WrapperProps) => {
  return props.isActive
    ? getActiveDarkStyles(props)
    : getDefaultDarkStyles(props);
};

const getDefaultStyles = (_: WrapperProps) => css`
  background: ${getBackgroundColor(false, false)};
  border: ${getBorder(false, false)};
  ${getCSSIconStyle(getLight)}
`;

const getActiveStyles = (_: WrapperProps) => css`
  background: ${themeStyle.brandSuperLight};
  border: ${getBorder(true, false)};
  box-shadow: inset 0 0 0 2px ${getBackgroundColor(true, false)};
  ${getCSSIconStyle(getActiveLight)}
`;

const getWrapperStyles = (props: WrapperProps) => {
  return props.isActive ? getActiveStyles(props) : getDefaultStyles(props);
};

export const Wrapper = styled.div<WrapperProps>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: ${themeStyle.radiusMedium};
  cursor: pointer;

  ${getWrapperStyles}

  @media (hover: hover) {
    &:hover {
      ${getActiveStyles}
    }
  }

  html[data-theme='dark'] && {
    ${getDarkStyles}

    @media (hover: hover) {
      &:hover {
        ${getActiveDarkStyles}
      }
    }
  }
`;
