import styled, { Keyframes, keyframes } from 'styled-components';

const blurIn = keyframes`
  from {
    filter: blur(10px);
  }

  to {
    filter: blur(0px);
  }
`;

export const Board = styled.div<{ $loading: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;

  filter: blur(${({ $loading }): string => ($loading ? '10' : '0')}px);
  animation: ${({ $loading }): string | Keyframes => (!$loading ? blurIn : '')}
    0.5s linear;

  .canvas-container {
    width: 100% !important;
    height: 100%;
    outline: none;
  }
`;
