import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';

import {
  Wrapper,
  InnerWrapper,
  TabList,
  Tab,
  Message,
  Group,
  GroupLabel,
  ContentWrapper,
  Item,
  Img,
  FontLabel,
  ActionsWrapper,
} from './styles';
import TextInput from '../../TextInput/TextInput';
import { getConfig, filterOptions, addTags } from './config';
import analytics from '../../../global/analytics';
import useCommonFontsStore, {
  commonFontsStoreSelector,
} from '../../../stores/commonFontsStore';
import {
  SEARCH_FONTS,
  UPLOAD_CAUTION_MODAL_CLOSE,
  UPLOAD_CHOOSE_FONTS_CLICK,
  UPLOAD_FONT_BUTTON_CLICK,
  UPLOAD_FONTS_CHOSEN,
  UPLOAD_FONTS_PICKER_CLOSE,
} from '../../../global/analytics/events';
import { useUserStore, userStoreSelector } from '../../../stores/userStore';
import DecisionModal from '../../DecisionModal/DecisionModal';
import CustomFontsWarning from './CustomFontsWarning/CustomFontsWarning';
import {
  useUserFontsStore,
  userFontsStoreSelector,
} from '../../../stores/userFontsStore';
import FontUploadModal from '../../FontUploadModal/FontUploadModal';
import {
  promotionSelector,
  usePromotionStore,
} from '../../../stores/promotionStore';
import { secondaryTheme } from '../../Button/Button/theme';
import Button from '../../Button/Button/Button';

const TypefacePanel = (props) => {
  const [filter, setFilter] = useState('all');
  const [query, setQuery] = useState('');

  const [options, setOptions] = useState(getConfig(props.fonts));
  const [showWarningModal, setShowWarningModal] = useState(false);

  const showUploadError = useUserFontsStore(
    userFontsStoreSelector.showUploadError
  );
  const closeUploadError = useUserFontsStore(
    userFontsStoreSelector.closeUploadError
  );
  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );

  const recentlyUsedFonts = useCommonFontsStore(
    commonFontsStoreSelector.recentFonts
  );
  const [recentOptions, setRecentOptions] = useState([]);

  // Used to detect when file picker is opened, then cancelled
  const filePickerOpenRef = useRef(false);

  const onWindowFocus = useCallback(() => {
    if (filePickerOpenRef.current) {
      filePickerOpenRef.current = false;

      setTimeout(() => {
        if (refInput.current?.files.length === 0) {
          analytics.track(UPLOAD_FONTS_PICKER_CLOSE);
        }
      }, 200);
    }
  }, [filePickerOpenRef]);

  useEffect(() => {
    window.addEventListener('focus', onWindowFocus);

    return () => {
      window.removeEventListener('focus', onWindowFocus);
    };
  }, [onWindowFocus]);

  // simple filter, to filter by filterOptions
  const byFilter = useCallback((font, filter) => {
    switch (filter) {
      case 'all':
        // show all
        return true;
      default:
        // show all fonts with the specified filter
        return font.filter === filter;
    }
  }, []);

  // simple filter, to search by a query
  const byQuery = useCallback((font, query) => {
    if (!query.length) return true;
    return font.tags.some((tag) => tag.indexOf(query) !== -1);
  }, []);

  // update options, when filter or query changes
  useEffect(() => {
    const config = getConfig(props.fonts);

    const filteredOptions = config.map((group) => {
      // apply filter
      let items = group.items.filter((item) => byFilter(item, filter));

      // apply query
      const q = query.trim().toLowerCase();
      items = items.filter((item) => byQuery(item, q));

      // return group
      return {
        ...group,
        items,
      };
    });

    // remove empty groups, set options
    setOptions(filteredOptions.filter((g) => g.items.length));
  }, [filter, query, setOptions, byFilter, byQuery, props.fonts]);

  // update recently used font options
  useEffect(() => {
    if (!recentlyUsedFonts?.length) {
      setRecentOptions([]);
      return;
    }

    // apply filter
    let items = addTags(recentlyUsedFonts).filter((item) =>
      byFilter(item, filter)
    );

    // apply query
    const q = query.trim().toLowerCase();
    items = items.filter((item) => byQuery(item, q));

    setRecentOptions(items);
  }, [filter, query, setOptions, byFilter, byQuery, recentlyUsedFonts]);

  const updateFilter = (id) => {
    setFilter(id);
  };

  const hasProAccess = useUserStore(userStoreSelector.proAccess);
  const refInput = useRef();

  const showUploadDialog = () => {
    if (refInput.current) {
      refInput.current.click();
      filePickerOpenRef.current = true;
    }
  };

  const handleClickUpload = (skipWarning) => {
    analytics.track(UPLOAD_FONT_BUTTON_CLICK);

    if (!hasProAccess) {
      showUpgradeModal('uploadCustomFonts');
      return;
    }

    if (!skipWarning) {
      props.onModalOpen && props.onModalOpen();
      setShowWarningModal(true);
    } else {
      showUploadDialog();
    }
  };

  const onCloseWarningModal = () => {
    props.onModalClose && props.onModalClose();
    setShowWarningModal(false);
  };

  const onCloseWarningModalTracked = () => {
    analytics.track(UPLOAD_CAUTION_MODAL_CLOSE);
    onCloseWarningModal();
  };

  const onCloseErrorModal = () => {
    closeUploadError();
  };

  const onConfirmWarning = () => {
    analytics.track(UPLOAD_CHOOSE_FONTS_CLICK);
    setShowWarningModal(false);
    showUploadDialog();
  };

  const [uploadFiles, setUploadFiles] = useState();

  const onFileUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      analytics.track(UPLOAD_FONTS_CHOSEN, {
        value: files.length,
      });
      props.onModalOpen && props.onModalOpen();
      setUploadFiles(files);
    }
  };

  const closeUpload = (filesUploaded = false) => {
    setUploadFiles(null);
    props.onModalClose && props.onModalClose();

    if (refInput.current) {
      refInput.current.value = '';
    }
  };

  const beforeFilePicker = () => {
    filePickerOpenRef.current = true;
  };

  const onQueryChange = (query) => {
    setFilter('all');
    setQuery(query);
  };

  return (
    <>
      <div onClick={beforeFilePicker}>
        <input
          ref={refInput}
          onChange={onFileUpload}
          type="file"
          multiple
          hidden
          accept="font/ttf,font/otf"
        />
      </div>
      <FontUploadModal
        isOpen={!!uploadFiles}
        files={uploadFiles}
        onClose={closeUpload}
      />
      <CustomFontsWarning
        isOpen={showWarningModal}
        onClose={onCloseWarningModal}
        onConfirm={onConfirmWarning}
        onCloseButton={onCloseWarningModalTracked}
      />
      <DecisionModal
        isOpen={showUploadError}
        title={'Something went wrong'}
        message={
          "Sorry, we couldn't upload your file. Please make sure it is a supported font file (OTF or TTF)."
        }
        onClose={onCloseErrorModal}
        firstButton={{
          label: 'Cancel',
          onClick: () => {
            onCloseErrorModal();
          },
        }}
        secondButton={{
          label: 'Try again',
          onClick: () => {
            handleClickUpload(true);
            onCloseErrorModal();
          },
        }}
      />
      <Wrapper>
        <ActionsWrapper>
          <TextInput
            value={query}
            icon={'search'}
            placeholder={'Search'}
            onBlur={() => {
              if (query?.length)
                analytics.track(SEARCH_FONTS, { label: query });
            }}
            onChanging={onQueryChange}
          />
          <Button
            icon={{
              name: 'upload',
            }}
            width="40px"
            onClick={handleClickUpload}
            theme={secondaryTheme}
          />
        </ActionsWrapper>
        <InnerWrapper>
          <TabList>
            {filterOptions.map(({ id, label }) => (
              <Tab
                key={id}
                isActive={id === filter}
                onClick={() => updateFilter(id)}
              >
                {label}
              </Tab>
            ))}
          </TabList>
          <ContentWrapper>
            {!options.length && !recentOptions.length && (
              <Message>No typeface available that fits your settings.</Message>
            )}
            {!!recentOptions.length && (
              <Group>
                <GroupLabel>Recently Used</GroupLabel>
                {recentOptions?.map((item) => (
                  <Item
                    key={item.fontFamily}
                    onClick={() => {
                      props.onSelect && props.onSelect(item);
                    }}
                  >
                    {item.img && <Img src={item.img} alt={item.name} />}
                    {!item.img && <FontLabel>{item.name}</FontLabel>}
                  </Item>
                ))}
              </Group>
            )}
            {options.map((group) => (
              <Group key={group.label}>
                {(options.length > 1 ||
                  !!recentOptions.length ||
                  filter === 'all') && <GroupLabel>{group.label}</GroupLabel>}
                {group.items.map((item) => (
                  <Item
                    data-testid={kebabCase(item.fontFamily)}
                    key={item.fontFamily}
                    onClick={() => {
                      props.onSelect && props.onSelect(item);
                    }}
                  >
                    {item.img && <Img src={item.img} alt={item.fontFamily} />}
                    {!item.img && <FontLabel>{item.name}</FontLabel>}
                  </Item>
                ))}
              </Group>
            ))}
          </ContentWrapper>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

TypefacePanel.propTypes = {
  /**
   * A function that is triggered after an option is selected. Gets the current option.
   */
  onSelect: PropTypes.func,
  /**
   * Array of font objects
   */
  fonts: PropTypes.array,
  onModalOpen: PropTypes.func,
  onModalClose: PropTypes.func,
};

TypefacePanel.defaultProps = {
  fonts: [],
};

export default TypefacePanel;
