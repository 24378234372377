const config = {
  FREE: {
    subTitle: 'NEW! Invite friends to upgrade your account.',
    benefits: [
      'For each friend, get one extra project for free',
      'Earn up to 100 extra projects in total',
      'Extra projects will be unlocked and stay free forever',
    ],
  },
  PRO: {
    subTitle: 'NEW! Invite friends to upgrade your account.',
    benefits: [
      'For each friend, get one extra project for free',
      'Earn up to 100 extra projects in total',
      'Extra projects will be unlocked and stay free for ever',
    ],
  },
  EXPERT: {
    subTitle: 'NEW! Invite friends and earn rewards.',
    benefits: [
      'Invite 10 friends and get our limited Heritage art print, perfect for your home',
      'Invite 15 friends and get access to an exclusive live masterclass by founder Tobias Saul',
    ],
  },
};

export const getSubTitle = (plan) => {
  if (plan && config[plan]?.subTitle) return config[plan].subTitle;
  return config['FREE'].subTitle;
};

export const getBenefits = (plan) => {
  if (plan && config[plan]?.benefits) return config[plan].benefits;
  return config['FREE'].benefits;
};
