import paper from '@kittl/paper';
import { isOnDevEnvironment } from '../../../global/environment';
import { getPaperPath } from '../../../utils/path/paper';

export default function (fabric) {
  // allow re-initialize on dev environment to prevent issues with fast refresh
  if (fabric.BasicShape && !isOnDevEnvironment()) {
    fabric.warn('fabric.BasicShape is already defined');
    return;
  }
  /**
   * BasicShape class
   * This class handles BasicShapes
   * @class fabric.BasicShape
   * @extends fabric.Illustration
   */
  fabric.BasicShape = fabric.util.createClass(fabric.Illustration, {
    type: 'basicShape',

    initialize: async function (objectName, options, cb) {
      this.callSuper('initialize', objectName, options, (target) => {
        // We need to enable middle controls back, as they're disabled on Object level
        target.setControlsVisibility({
          mr: true,
          mt: true,
          ml: true,
          mb: true,
        });
        cb && cb(target);
      });
    },

    /**
     * add stroke and stroke behavior to paths
     */
    onPathsAdded: (paths, options) => {
      paths.forEach((path) => {
        path._finalizeDimensions = (x, y) => ({ x, y });
        path.set({
          stroke: options.stroke,
          strokeWidth: options.strokeWidth,
        });
      });
    },

    _set: function (key, value) {
      if (key === 'stroke') {
        this[key] = value;
        this.getObjects().forEach((obj) => obj.set(key, value));
      } else if (key === 'strokeWidth') {
        this[key] = value;
        this.getObjects().forEach((obj) => obj.set(key, value));
      } else {
        this.callSuper('_set', key, value);
      }

      return this;
    },

    // Extend illustrations `getColor` by stroke
    getColors() {
      return this.callSuper('getColors', true);
    },

    // basicShapes don't need a outline path
    createOutlinePath: async function (options) {
      return null;
    },
  });

  /**
   * Returns svg representation of an instance
   *
   * when adjustStroke is set, the strokeWidth for the svg export is adjusted to scale,
   * to get good results in applications that don't support `vector-effect="non-scaling-stroke"`
   * for example in Adobe Illustrator or our pdf export
   *
   * @param {Boolean} adjustStroke
   * @param {Function} [reviver] Method for further parsing of svg representation.
   * @return {String} svg representation of an instance
   */
  fabric.BasicShape.prototype.toSVG = function (reviver) {
    /**
     * overwrite children's _toSVG function to scale path
     */
    const scaleX = this.scaleX;
    const scaleY = this.scaleY;

    this._objects.forEach((object) => {
      object._toSVG = () => {
        const paperPath = getPaperPath(object);
        if (!paperPath) return [];

        // scale path
        paperPath.scale(scaleX, scaleY);

        // clear paper.js canvas
        paper.project.activeLayer.removeChildren();

        return [
          '<path ',
          'COMMON_PARTS',
          'd="',
          paperPath.pathData,
          '" stroke-linecap="round" ',
          '/>\n',
        ];
      };
    });

    // usual fabric to svg behavior
    const svg = this._createBaseSVGMarkup(this._toSVG(reviver), {
      reviver: reviver,
    });

    return svg;
  };

  /**
   * Returns transform-string for svg-export
   * We overwrote this function to set scale in the svg export to 1 1
   * @param {Boolean} use the full transform or the single object one.
   * @return {String}
   */
  fabric.BasicShape.prototype.getSvgTransform = function (
    full,
    additionalTransform
  ) {
    // set scale to 1, 1. <-- this is what we changed
    // this only works if the object is not part of a group
    const tMatrix = this._calcTranslateMatrix();
    const options = {
      angle: this.angle,
      translateX: tMatrix[4],
      translateY: tMatrix[5],
      scaleX: 1,
      scaleY: 1,
      skewX: this.skewX,
      skewY: this.skewY,
      flipX: this.flipX,
      flipY: this.flipY,
    };
    const transform = fabric.util.composeMatrix(options);
    const svgTransform = 'transform="' + fabric.util.matrixToSVG(transform);

    return svgTransform + (additionalTransform || '') + '" ';
  };

  fabric.BasicShape.fromObject = function (object, callback) {
    new fabric.BasicShape(object.objectName, object, (target) => {
      callback(target);
    });
  };
}
