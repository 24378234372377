import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { elements } from './config';
import {
  BackgroundImage,
  Box,
  Boxes,
  CopyLinkPanel,
  Image,
  Label,
  Link,
  More,
  PreviewImage,
  PublishPanel,
} from './styles';
import { DESIGN_SHARE, SOCIAL_SHARE } from '../../global/events';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import { useUserStore, userStoreSelector } from '../../stores/userStore';
import { P3, P4 } from '../utilities/Typography/styles';
import { FlexRow } from '../utilities/styles';
import Button from '../Button/Button/Button';
import Collapse from '../Collapse/Collapse';
import { primaryTheme } from '../Button/Button/theme';
import { buildImageProxyUrl, buildWebsiteUrl } from '../../utils/url';
import UserSearch from '../UserSearch/UserSearch';
import Spacer from '../Spacer/Spacer';
import useDesignsStore, {
  designsStoreSelector,
} from '../../stores/designsStore';
import DecisionModal from '../DecisionModal/DecisionModal';
import { selector, useToastStore } from '../../stores/toastStore';
import { themeStyle } from '../../services/theming';

const SharePanelContent = (props) => {
  const authenticated = useUserStore(userStoreSelector.isLoggedIn);
  const handleModalOpen = useMenuStore(menuStoreSelector.showSubmitModal);
  const activeDesign = useDesignsStore(designsStoreSelector.activeDesign);

  const fireToast = useToastStore(selector.fire);

  let previewWidth = 0;
  let previewHeight = 0;
  if (activeDesign?.preview && activeDesign.state?.artboardOptions) {
    const { width, height } = activeDesign.state.artboardOptions;
    if (height === width) {
      previewHeight = 145;
      previewWidth = 145;
    } else if (height > width) {
      previewHeight = 170;
      previewWidth = 170 * (width / height);
    } else {
      previewWidth = 200;
      previewHeight = 200 * (height / width);
    }
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <Collapse activePanel={'share'}>
      <div
        id="share"
        icon="picture"
        iconHeight="18px"
        label="Share image"
        panelTheme={{
          paddingTop: '12px',
        }}
      >
        <Boxes>
          {elements.map((e) => (
            <Box key={e.label} onClick={() => props.dispatch(SOCIAL_SHARE, e)}>
              <Image src={e.src} alt={e.label} height={'56px'} width={'56px'} />
              <Label>{e.label}</Label>
            </Box>
          ))}
        </Boxes>
      </div>
      <div
        id="copy"
        icon="files"
        iconHeight="22px"
        label="Share a copy of your project"
      >
        <DecisionModal
          isOpen={showModal}
          title={'We are sorry'}
          message={`You can't share a design that is using custom uploaded fonts as editable.`}
          onClose={() => setShowModal(false)}
          firstButton={{
            label: 'Continue',
            onClick: () => setShowModal(false),
            type: 'primary',
          }}
        />
        <Spacer h={'8px'} />
        <CopyLinkPanel>
          <P4 style={{ maxWidth: '170px', color: themeStyle.varInkMain }}>
            People with the link get an editable copy of your project.
          </P4>
          <Button
            width={'130px'}
            height={'40px'}
            isCentered
            label={'Copy Link'}
            icon={{ name: 'linkShare', height: '16px' }}
            onClick={() => {
              props.dispatch(DESIGN_SHARE, {
                callback: (share) => {
                  const link = share.link;
                  if (link && navigator?.clipboard) {
                    navigator.clipboard.writeText(link).then(
                      () => {
                        fireToast({
                          label: 'Link copied to clipboard',
                        });
                      },
                      () => {} // empty error handling, to hide error message
                    );
                  }
                },
                onError: () => setShowModal(true),
              });
            }}
          />
        </CopyLinkPanel>
        <Spacer h={'14px'} />
        <P3 style={{ color: themeStyle.varInkMain }}>
          Share with a user or via email
        </P3>
        <Spacer h={'6px'} />
        <UserSearch
          container={props.wrapperRef}
          onSend={(userInfo) => {
            props.dispatch(DESIGN_SHARE, {
              ...userInfo,
              callback: () => fireToast({ label: 'Design Shared!' }),
              onError: () => setShowModal(true),
            });
          }}
        />
        <Spacer h={'10px'} />
      </div>
      <div
        id="publish"
        icon="eyelashes"
        iconHeight="20px"
        label="Publish to Community"
        panelTheme={{
          paddingBottom: '12px',
        }}
      >
        <Spacer h={'6px'} />
        <PublishPanel>
          <BackgroundImage
            width={'300'}
            src={'/images/publishedPosts.png'}
            alt=""
          />
          {!!previewWidth && (
            <PreviewImage
              width={previewWidth}
              height={previewHeight}
              src={buildImageProxyUrl(`api/${activeDesign.preview}`)}
              alt=""
            />
          )}
        </PublishPanel>
        <P3 margin={'12px 0 0 0'} style={{ color: themeStyle.varInkMain }}>
          Publish a copy of your design to the community to be usable and
          modifiable by others.
        </P3>
        <FlexRow
          justifyContent={'space-between'}
          alignItems={'end'}
          marginTop={'6px'}
        >
          <Link href={buildWebsiteUrl('/publish/guidelines')} target={'_blank'}>
            <More>Learn more...</More>
          </Link>
          <Button
            theme={primaryTheme}
            width={'182px'}
            height={'40px'}
            isCentered
            disabled={!authenticated || props.isLoading}
            label={'Publish'}
            icon={{ name: 'eyelashes', height: '15px' }}
            onClick={handleModalOpen}
          />
        </FlexRow>
      </div>
    </Collapse>
  );
};

SharePanelContent.propTypes = {
  dispatch: PropTypes.func,
  wrapperRef: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default SharePanelContent;
