import defaultObject from './defaultObject';
import { GROUP_TYPES } from '../../components/Artboard/fabric';
import multipleTextObjectsConverter from './multipleTextObjectsConverter';

// recursively searches for the first object that is not a group
// if the group around that object is locked, the object is also be locked (workaround for svg-path groups)
const getFirstObject = (obj, isLocked) => {
  if (!obj) return null;
  const locked = isLocked ? isLocked : obj.locked;
  const type = obj.type;
  if (GROUP_TYPES.includes(type)) {
    return getFirstObject(obj.getObjects()[0], locked);
  }
  const colors = obj.getColors();
  return { ...obj, locked, type, colors };
};

// TODO: eventually we want to remove defaultObject completely
export default (activeObjects) => {
  if (!Array.isArray(activeObjects) || !activeObjects.length) {
    return null;
  }
  const activeObject = getFirstObject(activeObjects[0], false);
  if (!activeObject) return null;
  const object = {
    ...defaultObject,
    id: activeObject.id,
    fill: activeObject.fill,
    stroke: activeObject.stroke,
    colors: activeObject.colors,
    objectName: activeObject.objectName,
  };
  object.locked = activeObject.locked;
  object.opacity =
    (activeObject.opacity !== undefined ? activeObject.opacity : 1) * 100;
  // XXX: This is kinda fake, but that's what it is generally called in fabric
  object.type =
    activeObjects.length === 1 ? activeObject.type : 'activeSelection';
  object.borderWeight = {
    isDisabled: activeObject.borderIsDisabled
      ? activeObject.borderIsDisabled
      : false,
    value: activeObject.strokeWidth,
  };
  if (activeObject.allowCompositeOperation) {
    object.allowCompositeOperation = true;
    object.globalCompositeOperation = activeObject.globalCompositeOperation;
  }

  const getFontSize = (pathTextObj) =>
    pathTextObj.fontSize * pathTextObj.scaleX * pathTextObj.pathText.scaleX;

  const isActiveSelection = object.type === 'activeSelection';

  if (activeObject.type === 'pathText' && !isActiveSelection) {
    object.characterStyles = {
      ...defaultObject.characterStyles,
      fontFamily: activeObject.fontFamily,
      fontWeight: activeObject.fontWeight,
      textAlignment: activeObject.textAlignment,
      uppercase: activeObject.uppercase,
      useLigatures: activeObject.useLigatures,
      lineHeight: activeObject.lineHeight,
      letterSpacing: activeObject.letterSpacing,
      // We always want to have "real" fontSize
      fontSize: getFontSize(activeObject),
      // textAlignment: 'center',
      variationAxes: activeObject.variationAxes,
      variation: activeObject.variation,
    };

    // Conform entries to our universal interface for single/ multiple pathText objects
    for (const [key, val] of Object.entries(object.characterStyles)) {
      object.characterStyles[key] = {
        value: val,
        mixture: false,
      };
    }

    object.shadowOptions = {
      ...defaultObject.shadowOptions,
      ...activeObject.shadowOptions,
    };
    // Account for no-color
    object.shadowOptions.color = object.shadowOptions.color || null;
    object.transformOptions = {
      ...defaultObject.transformOptions,
      ...activeObject.transformOptions,
    };
    object.decorationOptions = {
      ...defaultObject.decorationOptions,
      ...activeObject.decorationOptions,
    };
    // Account for no-color
    object.decorationOptions.color = object.decorationOptions.color || null;
    object.inPathEditMode = activeObject.inPathEditMode;
  }

  const onlyPathTextObjects = activeObjects.every(
    (obj) => obj.type === 'pathText'
  );

  // Check if suitable for multiple text edit
  if (isActiveSelection && onlyPathTextObjects) {
    object.multiplePathTextEdit = true;
    object.characterStyles = multipleTextObjectsConverter(activeObjects);
  }

  if (activeObject.type === 'illustrationImage') {
    object.filters = activeObject.filters.map((filter) => filter.toObject());
  }

  return object;
};
