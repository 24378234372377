import styled from 'styled-components';
import { handleTextOverflow, applyFontStyles } from '../utilities/styles';

export const CellWrapper = styled.div`
  cursor: pointer;
  min-width: 0; /* prevent overflow */
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  height: ${({ theme }) =>
    (!theme.heightToContent && `${theme.rowHeight}px`) || 'unset'};

  background: ${({ theme, isActive }) =>
    isActive ? theme.activeBackgroundColor : theme.backgroundColor};
`;

export const RowWrapper = styled.div`
  display: grid;
  width: ${({ theme }) => theme.width};
  grid-template-columns: ${({ count }) => `repeat(${count}, 1fr)`};
  grid-column-gap: ${({ theme }) => theme.cellGap}px;

  ${CellWrapper}:first-child ${IconWrapper} {
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  }

  ${CellWrapper}:last-child ${IconWrapper} {
    border-top-right-radius: ${({ theme }) => theme.borderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  }

  ${CellWrapper}:not(:last-child) ${IconWrapper} {
    border-right: ${({ theme }) => theme.borderBetween};
  }
`;

export const Label = styled.label`
  ${applyFontStyles}
  color: ${(props) =>
    (props.isActive && props.textActiveColor) || props.textColor};
  width: 100%;
  text-align: center;
  margin-top: ${(props) => props.iconCellLabelGap}px;
  cursor: pointer;
  ${handleTextOverflow}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    ${Label} {
      color: ${({ theme }) => theme.textActiveColor};
    }

    ${IconWrapper} {
      background: ${({ theme }) => theme.activeBackgroundColor};
    }
  }
`;
