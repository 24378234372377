export default function (fabric) {
  /**
   * Returns svg representation of an instance
   * Extended to limit the decimals of paths
   * @return {Array} an array of strings with the specific svg representation
   * of the instance
   */
  fabric.Path.prototype._toSVG = function () {
    const path = this.path
      .map((path) => {
        return path
          .map((c) => {
            if (typeof c === 'number') {
              return c.toFixed(2);
            }
            return c;
          })
          .join(' ');
      })
      .join(' ');
    return [
      '<path ',
      'COMMON_PARTS',
      'd="',
      path,
      '" stroke-linecap="round" ',
      '/>\n',
    ];
  };
}
