import { themeStyle } from '../../services/theming';
import { TextInputTheme } from '../TextInput/types';

export const iconTheme = {
  // icon
  color: themeStyle.varInkMain,
  activeColor: themeStyle.varBrandMedium,
  activeDefaultColor: themeStyle.varInkMain,
};

export const inputTheme: Partial<TextInputTheme> = {
  // text
  fontWeight: themeStyle.fontSemiBold,
  fontSize: themeStyle.fontSize14,
  lineHeight: '17px',

  color: themeStyle.varInkMain,
  colorActive: themeStyle.varInkMain,
  backgroundColor: themeStyle.transparent,
  backgroundColorHoverDark: null,
  border: 'none',
  innerPadding: '0px',
  hoverShadow: 'unset',

  // size
  height: '18px',
  width: 'unset',
};
