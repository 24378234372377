import styled from 'styled-components';
import { handleTextOverflow, applyFontStyles } from '../styles';

export const Label = styled.div`
  ${applyFontStyles}
  color: ${(props) => props.colorLabel};
  text-align: center;
  user-select: none;
  margin-top: 4px;

  transition: color ${(props) => props.transition};

  ${handleTextOverflow}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};

  &:hover,
  &:focus-within {
    ${Label} {
      color: ${(props) => props.colorLabelActive};
    }
  }
`;
