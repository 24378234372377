import styled from 'styled-components';
import { themeStyle } from '../../services/theming';

export const Wrapper = styled.div``;

// rc-tree styles https://github.com/react-component/tree/blob/master/assets/index.less
export const LayersWrapper = styled.div`
  width: 100%;

  .rc-tree .rc-tree-treenode {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 32px;

    margin: 0;
    padding: 0;
    white-space: nowrap;
    list-style: none;
    outline: 0;

    border-bottom: 1px solid transparent;
    &:not(:last-child) {
      border-color: ${themeStyle.varInkSuperLight};
    }

    &.rc-tree-treenode-selected {
      background: ${themeStyle.varInkSuperLight};
    }

    & .draggable {
      user-select: none;
    }

    /* hightlight group to drop into */
    &.drop-container {
      box-shadow: 0 -1px 0 0 ${themeStyle.varSelectionBlue},
        inset 1px 0 0 0 ${themeStyle.varSelectionBlue},
        inset -1px 0 0 ${themeStyle.varSelectionBlue}; /* top, left, right */

      border-bottom: 1px solid ${themeStyle.varSelectionBlue};

      /* vertically highlight group to drop into (layer after container) */
      & ~ .rc-tree-treenode {
        box-shadow: inset 1px 0 0 ${themeStyle.transparent};
      }
    }

    /* highlight group node to drop next to/into */
    &.drop-target {
      background-color: ${themeStyle.varInkSuperLight};

      /* prevent vertical highlight after target */
      & ~ .rc-tree-treenode {
        box-shadow: none;
      }
    }

    /* align layer content */
    & .rc-tree-node-content-wrapper {
      position: relative;
      flex: 1;
      height: 100%;

      display: flex;
      align-items: center;

      cursor: pointer;
    }

    /* switch and layer icons */

    & .rc-tree-switcher + * {
      .rc-tree-iconEle {
        margin-left: 0px;
      }
    }

    & .rc-tree-iconEle {
      cursor: pointer;
      margin-left: 6px;
    }

    & .rc-tree-switcher {
      cursor: pointer;
    }

    &.rc-tree-treenode-disabled {
      cursor: default;

      & .rc-tree-node-content-wrapper,
      & .rc-tree-switcher,
      & .rc-tree-iconEle {
        cursor: default;
      }
    }

    & .rc-tree-node-content-wrapper-open {
      input {
        font-weight: ${themeStyle.fontBold};
      }

      path {
        stroke-width: 1.2px;
      }
    }
  }

  /* layer content wrapper */
  .rc-tree-title {
    flex: 1;
    width: 1px; /* initial width, to grow from */
    height: 100%;
  }

  /* indentation */
  .rc-tree-indent {
    display: inline-flex;
  }
  .rc-tree-indent-unit {
    width: 14px;
    display: inline-flex;
  }
`;

export const DropIndicatorStyle = styled.div`
  pointer-events: none;
  position: absolute;
  right: 0px;
  height: 2px;
  background-color: ${themeStyle.varSelectionBlue};
`;

export const SwicthIcon = styled.div`
  padding: 5px;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;

export const ModalTitle = styled.h3`
  text-align: center;
  font-size: 20px;
  font-weight: ${themeStyle.fontSemiBold};
  color: ${themeStyle.primaryBlack};
`;

export const ModalLabel = styled.label`
  text-align: center;
  font-size: ${themeStyle.fontSizeLarger};
  color: ${themeStyle.textGray};
  font-weight: ${themeStyle.fontSemiBold};
  line-height: 22px;
`;

export const ModalButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding-bottom: 15px;

  & > * {
    margin: 0 5px;
  }
`;
