import styled from 'styled-components';
import themeStyle from '../../global/themeStyle';

export const Anchor = styled.div`
  position: absolute;
  pointer-events: none;

  ${({ size }) =>
    size &&
    `
    width: ${size}px;
    height: ${size}px;
    transform: translateX(-50%) translateY(-50%);
  `}
`;

export const RewardMask = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: ${themeStyle.zIndexL9};
`;
