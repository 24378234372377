import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';

export const Interactive = styled.div`
  ${({ enabled }) =>
    enabled
      ? `
    cursor: pointer;
    * {
      cursor: pointer;
    }
  `
      : ''};
`;

export const ExportButtonsContainer = styled(Interactive)`
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border-radius: ${themeStyle.radiusMedium};
  background: ${themeStyle.varInkSuperLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  * {
    text-align: center;
  }
`;

export const Section = styled(Interactive)`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ExportLabelWrapper = styled.div`
  b {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const InputContainer = styled.div`
  display: inline-flex;
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: space-between;
  align-items: center;
  width: ${(props) => props.width || 'unset'};
  height: 70px;
`;

export const ModalContent = styled.div`
  margin-top: 12px;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Section}:not(:last-child) {
    border-bottom: 1px solid ${themeStyle.varInkSuperLight};
  }
`;
