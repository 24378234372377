import styled from 'styled-components';

import { themeStyle } from '../../services/theming';
import { FlexColumn } from '../utilities/styles';
import { P2, P4 } from '../utilities/Typography/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 32px 24px;
`;

export const Header = styled(FlexColumn)`
  align-items: center;
  gap: 20px;
  text-align: center;
  margin: 0 auto;
`;

export const Message = styled(P2)`
  text-align: center;
  white-space: pre-line;
  margin-top: 12px;
`;

export const Note = styled(P4)`
  text-align: center;
  color: ${themeStyle.varInkMedium};
  white-space: pre-line;
  margin-top: 6px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
`;
