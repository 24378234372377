import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import {
  TagInputContainer,
  InputContainer,
  InfoText,
  TagInputWithInfoText,
  Input,
} from './styles';
import Tag from '../Tag/Tag';

const TagInput = (props) => {
  const [tags, setTags] = useState(new Set(props.tags));
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    setTags(new Set(props.tags));
  }, [props.tags]);

  const showPlaceholder = tags.size === 0;
  const showTextInput = tags.size < props.maxTagsAmount;

  const handleKeyPress = (evt) => {
    if (evt.key === ',' || evt.key === 'Enter') {
      addTag(evt);
      evt.preventDefault();
      return;
    }
  };

  const addTag = (evt) => {
    const trimmedVal = evt.target.value.trim();
    if (!trimmedVal) return setInputValue('');

    const newTags = new Set([...tags.values(), trimmedVal.toLowerCase()]);
    setTags(newTags);
    setInputValue('');
    props.onChange && props.onChange(newTags);
  };

  const removeTag = (tag) => {
    const _tags = new Set(tags);
    _tags.delete(tag);
    setTags(new Set(_tags));
    inputRef.current && inputRef.current.focus();
    props.onChange && props.onChange(_tags);
  };

  return (
    <TagInputWithInfoText>
      <TagInputContainer
        showTextInput={showTextInput}
        onClick={() => inputRef.current && inputRef.current.focus()}
      >
        {[...tags.values()].map((tag, index) => (
          <Tag key={index} onClose={() => removeTag(tag)}>
            {capitalize(tag)}
          </Tag>
        ))}

        {showTextInput && (
          <InputContainer
            inputWidth={`${
              showPlaceholder ? '100%' : `${inputValue.length}ch`
            }`}
          >
            <Input
              placeholder={showPlaceholder ? props.placeholder : ''}
              onChange={(evt) => setInputValue(evt.target.value)}
              onKeyPress={handleKeyPress}
              value={inputValue}
              onBlur={addTag}
              ref={inputRef}
              disabled={props.disabled}
            />
          </InputContainer>
        )}
      </TagInputContainer>
      <InfoText noTextOverflow>{props.infoText}</InfoText>
    </TagInputWithInfoText>
  );
};

TagInput.propTypes = {
  /**
   * Placeholder for the value
   */
  placeholder: PropTypes.string,
  /**
   * A disabled input cant be used.
   */
  disabled: PropTypes.bool,
  /**
   * maximal length of the input string
   */
  maxTagsAmount: PropTypes.number,
  /**
   * onChange action
   */
  onChange: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.string),
  infoText: PropTypes.string,
};

TagInput.defaultProps = {
  tags: [],
  placeholder: 'Add tags',
  infoText: 'Separate with enter (max. 8 tags)',
  maxTagsAmount: 8,
};

export default TagInput;
