/**
 * Typography
 *
 * Master styles for headlines and text.
 * See: https://www.figma.com/file/iLh3LOpdfxU049JzeU10GU/Kittl-UI-Update-2023?node-id=5831%3A18331&t=nsgMdxlBvQIL2mSG-4
 *
 */

import styled, { css } from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { handleTextOverflow } from '../styles';
import { HeadlineProps, ParagraphProps } from './types';

const h = css<HeadlineProps>`
  font-family: ${themeStyle.fontFamily};
  color: ${({ color }) => color || themeStyle.varInkMain};

  padding: 0px;
  margin: ${({ margin }) => margin || '0px'};

  ${({ noTextOverflow }) => (noTextOverflow ? handleTextOverflow : '')};
`;

export const H1 = styled.h1<HeadlineProps>`
  ${h}
  font-weight: ${themeStyle.fontExtraBold};
  font-size: ${themeStyle.fontSize36};
  line-height: 42px;
`;

export const H2 = styled.h2<HeadlineProps>`
  ${h}
  font-weight: ${themeStyle.fontExtraBold};
  font-size: ${themeStyle.fontSize26};
  line-height: 34px;
`;

export const H3 = styled.h3<HeadlineProps>`
  ${h}
  font-weight: ${themeStyle.fontBold};
  font-size: ${themeStyle.fontSize18};
  line-height: 24px;
`;

export const H4 = styled.h4<HeadlineProps>`
  ${h}
  font-weight: ${themeStyle.fontBold};
  font-size: ${themeStyle.fontSize16};
  line-height: 20px;
`;

export const H5 = styled.h5<HeadlineProps>`
  ${h}
  font-weight: ${themeStyle.fontSemiBold};
  font-size: ${themeStyle.fontSize14};
  line-height: 18px;
`;

export const H6 = styled.h6<HeadlineProps>`
  ${h}
  font-weight: ${themeStyle.fontSemiBold};
  font-size: ${themeStyle.fontSize12};
  line-height: 15px;
`;

export const H6Plus = styled(H6)<HeadlineProps>`
  font-weight: ${themeStyle.fontBold};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

const p = css<ParagraphProps>`
  font-family: ${themeStyle.fontFamily};
  font-weight: ${themeStyle.fontMedium};
  color: ${({ color }) => color || themeStyle.varInkMain};

  padding: 0px;
  margin: ${({ margin }) => margin || '0px'};
  text-decoration: ${({ textDecoration }) => textDecoration || 'unset'};
  cursor: ${({ cursor }) => cursor || 'unset'};

  & > b {
    font-weight: ${themeStyle.fontBold};
  }

  & > a {
    cursor: pointer;
    color: ${({ color }) => color || themeStyle.varInkMain};
    text-decoration-line: underline;
  }

  ul {
    margin: 12px 0;
    li + li {
      padding-top: 6px;
    }
  }

  ${({ noTextOverflow }) => (noTextOverflow ? handleTextOverflow : '')};
`;

export const P1 = styled.p<ParagraphProps>`
  ${p}
  font-size: ${themeStyle.fontSize16};
  line-height: 26px;

  ul {
    padding-left: 24px;
  }
`;

export const P2 = styled.p<ParagraphProps>`
  ${p}
  font-size: ${themeStyle.fontSize14};
  line-height: 22px;

  ul {
    padding-left: 21px;
  }
`;

export const P3 = styled.p<ParagraphProps>`
  ${p}
  font-size: ${themeStyle.fontSize12};
  line-height: 19px;

  ul {
    padding-left: 18px;
  }
`;

export const P4 = styled.p<ParagraphProps>`
  ${p}
  font-size: ${themeStyle.fontSize11};
  line-height: 18px;

  ul {
    padding-left: 16px;
  }
`;
