import React, { useCallback } from 'react';
import ColorPicker from '../ColorPicker/ColorPicker';
import { ColorWrapper } from './styles';
import PropTypes from 'prop-types';

const ColorSwatch = ({
  index,
  color,
  gap,
  choosingColor,
  onChanging,
  onChanged,
  onToggle,
  onClose,
  dispatch,
}) => {
  const onColorChanging = useCallback(
    (newColor) => {
      onChanging(color, newColor);
    },
    [color, onChanging]
  );
  const onColorChanged = useCallback(
    (newColor) => {
      onChanged(color, newColor);
    },
    [color, onChanged]
  );

  return (
    <ColorWrapper zIndex={-index} gap={gap} choosingColor={choosingColor}>
      <ColorPicker
        defaultColor={color}
        onChanging={onColorChanging}
        onChanged={onColorChanged}
        dispatch={dispatch}
        onToggle={onToggle}
        onClose={onClose}
        placement="top"
        alignment="center"
      />
    </ColorWrapper>
  );
};

ColorSwatch.propTypes = {
  /**
   * The index in the color palette, used for position purpose
   */
  index: PropTypes.number,
  color: PropTypes.string,
  choosingColor: PropTypes.bool,
  colorCount: PropTypes.number,
  gap: PropTypes.number,
  onChanging: PropTypes.func,
  onChanged: PropTypes.func,
  onToggle: PropTypes.func,
  onClose: PropTypes.func,
  dispatch: PropTypes.func,
};

export default React.memo(ColorSwatch);
