import React, { useCallback } from 'react';
import { GenericNotification, NotificationType } from '../../../../types';

import { WEBSITE_URL } from '../../../../utils/url';
import NotificationHeader from '../NotificationHeader/NotificationHeader';
import { NotificationWrapper } from './styles';
import { NotificationProps } from './types';

const MessageNotification: React.FC<
  NotificationProps<GenericNotification<NotificationType, { message: string }>>
> = ({ notification, onReadNotification }) => {
  const handleClick = useCallback((): void => {
    onReadNotification(notification.id);
    if (notification.relatedUser) {
      window.open(
        `${WEBSITE_URL || ''}/${notification.relatedUser.slug}`,
        WEBSITE_URL ? '_blank' : '_self'
      );
    }
  }, [onReadNotification, notification]);

  return (
    <NotificationWrapper role="button" onClick={handleClick}>
      <NotificationHeader
        user={notification.relatedUser}
        message={notification.data.message}
        createdAt={notification.createdAt}
        isUnseen={!notification.seenAt}
      />
    </NotificationWrapper>
  );
};

export default MessageNotification;
