import createVanilla from 'zustand/vanilla';
import create from 'zustand';
import analytics from '../global/analytics';

/*
  This store is used to trigger changes in the ui based on a connection status (e.g opening a recconnection modal).
  The connection status is handled by the application itself.
*/

const networkStore = createVanilla((set, get) => ({
  online: true, // assume connected before initialization,
  tick: null, // will check every 250 seconds for a reconnection when offline

  setStatus: (status) => {
    if (status === get().online) return;

    analytics.track('Connection', {
      label: `Went ${status ? 'Online' : 'Offline'}`,
    });

    if (!status) {
      set({
        tick: setInterval(() => {
          if (navigator.onLine) {
            get().setStatus(true);
          }
        }, 250),
      });
    } else {
      clearInterval(get().tick);
      set({ tick: null });
    }

    set({ online: status });
  },
}));

const useNetworkStore = create(networkStore);

export const networkStoreSelector = {
  online: (state) => state.online,
};

export default useNetworkStore;
