import Bezier from '../../Bezier';
import {
  checkIfPointIsOnLeftSideOfLine,
  getLineParam,
} from '../../../utils/geometry/line';
import { getInterpolatedPoint } from '../../../utils/geometry/point';
import { checkIfPointOnLeftSideOfVector } from '../../../utils/geometry/vector';

export default class DistortTransform {
  constructor({ points, bounds }) {
    this.points = points;
    this.bounds = this.prepareBounds(bounds);
    this.transformConvex = this.getTransformConvex(points);
  }

  prepareBounds(bounds) {
    return [
      {
        x: bounds.minX,
        y: -bounds.maxY,
      },
      {
        x: bounds.maxX,
        y: -bounds.maxY,
      },
      {
        x: bounds.maxX,
        y: -bounds.minY,
      },
      {
        x: bounds.minX,
        y: -bounds.minY,
      },
    ];
  }

  getTransformConvex(points) {
    const topLeft = points[0];
    const topCenter = points[1];
    const topRight = points[2];
    const bottomRight = points[3];
    const bottomCenter = points[4];
    const bottomLeft = points[5];
    const topLeftHandle = points[6];
    const topRightHandle = points[7];
    const bottomLeftHandle = points[8];
    const bottomRightHandle = points[9];

    const topLeftCurve = new Bezier(topLeft, topLeft, topLeftHandle, topCenter);
    const topRightCurve = new Bezier(
      topCenter,
      topRightHandle,
      topRight,
      topRight
    );
    const bottomLeftCurve = new Bezier(
      bottomLeft,
      bottomLeft,
      bottomLeftHandle,
      bottomCenter
    );
    const bottomRightCurve = new Bezier(
      bottomCenter,
      bottomRightHandle,
      bottomRight,
      bottomRight
    );

    return {
      top: {
        breakpoint: topCenter,
        left: topLeftCurve,
        right: topRightCurve,
      },
      bottom: {
        breakpoint: bottomCenter,
        left: bottomLeftCurve,
        right: bottomRightCurve,
      },
    };
  }

  getBreakPointsOnBounds() {
    const topPoint = {
      x:
        this.bounds[0].x +
        (this.transformConvex.top.left.len /
          (this.transformConvex.top.left.len +
            this.transformConvex.top.right.len)) *
          (this.bounds[1].x - this.bounds[0].x),
      y: this.bounds[0].y,
    };

    const bottomPoint = {
      x:
        this.bounds[3].x +
        (this.transformConvex.bottom.left.len /
          (this.transformConvex.bottom.left.len +
            this.transformConvex.bottom.right.len)) *
          (this.bounds[2].x - this.bounds[3].x),
      y: this.bounds[3].y,
    };

    return {
      top: topPoint,
      bottom: bottomPoint,
    };
  }

  getXRatio({ x, y }, breakPointsOnBounds) {
    const pointOnLeftSide = checkIfPointIsOnLeftSideOfLine(
      { x, y },
      breakPointsOnBounds.top,
      breakPointsOnBounds.bottom
    );
    const { k, m } = getLineParam(
      breakPointsOnBounds.top,
      breakPointsOnBounds.bottom
    );

    let xRatio = 0;

    if (pointOnLeftSide) {
      const xStart = this.bounds[0].x;
      const xEnd =
        Math.abs(k) === Infinity ? breakPointsOnBounds.top.x : (y - m) / k;
      xRatio = (x - xStart) / (xEnd - xStart);
    } else {
      const xStart =
        Math.abs(k) === Infinity ? breakPointsOnBounds.top.x : (y - m) / k;
      const xEnd = this.bounds[1].x;
      xRatio = (x - xStart) / (xEnd - xStart);
    }

    return xRatio;
  }

  getTransformedPoint({ x, y }, partTransformConvex, xRatio) {
    const topIntersect = partTransformConvex.top.getPointAtLengthRatio(xRatio);
    const bottomIntersect =
      partTransformConvex.bottom.getPointAtLengthRatio(xRatio);

    return getInterpolatedPoint(
      topIntersect,
      bottomIntersect,
      (y - this.bounds[0].y) / (this.bounds[3].y - this.bounds[0].y)
    );
  }

  getTransformedCoordinatesString(originalCoordinatesString) {
    const [x, y] = originalCoordinatesString.split(' ');
    const breakPointsOnBounds = this.getBreakPointsOnBounds();
    const pointOnLeftSide = checkIfPointOnLeftSideOfVector(
      {
        x: Number(x),
        y: Number(y),
      },
      breakPointsOnBounds.top,
      breakPointsOnBounds.bottom
    );
    const xRatio = this.getXRatio({ x, y }, breakPointsOnBounds);
    const partTransformConvex = pointOnLeftSide
      ? {
          top: this.transformConvex.top.left,
          bottom: this.transformConvex.bottom.left,
          points: [
            this.points[0],
            this.transformConvex.top.breakpoint,
            this.transformConvex.bottom.breakpoint,
            this.points[5],
          ],
        }
      : {
          top: this.transformConvex.top.right,
          bottom: this.transformConvex.bottom.right,
          points: [
            this.transformConvex.top.breakpoint,
            this.points[2],
            this.points[3],
            this.transformConvex.bottom.breakpoint,
          ],
        };

    const transformedPoint = this.getTransformedPoint(
      { x, y },
      partTransformConvex,
      xRatio
    );

    return `${transformedPoint.x} ${transformedPoint.y}`;
  }
}
