import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ActionContainer, TextInputContainer } from './styles';
import { FlexRow } from '../../utilities/styles';
import IconButton from '../../Button/IconButton';

import {
  LAYER_LOCKED,
  LAYER_HIDDEN,
  SELECT_ARTBOARD,
  LAYER_SELECTION,
} from '../../../global/events';
import TextInput from '../../TextInput/TextInput';
import { SPECIAL_LAYERS } from '../../../global/constants';
import { layerInputTheme } from '../../TextInput/theme';
import { layerActionsIconTheme } from '../theme';

/**
 * The Layer component displays the key information and interaction options of a single layer.
 */
const Layer = ({
  id,
  title: titleFromProps,
  type,
  hidden,
  locked,
  disabled,
  onIsEditMode,
  dispatch,
  onNameChange,
}) => {
  const isSpecialLayer = SPECIAL_LAYERS.includes(type);

  const dispatchEvent = (event, value) => {
    dispatch && dispatch(event, value);
  };

  const handleClick = () => {
    if (isSpecialLayer) {
      dispatch && dispatch(SELECT_ARTBOARD);
    } else if (disabled) {
      // disabled layer should still be selectable. This is important for
      // objects in mask groups
      dispatchEvent(LAYER_SELECTION, {
        ids: [id],
      });
    }
  };

  const [title, setTitle] = useState(titleFromProps);
  useEffect(() => {
    setTitle(titleFromProps);
  }, [titleFromProps]);

  const [isEditMode, setIsEditMode] = useState(false);
  const handleDoubleClick = () => {
    if (isSpecialLayer) return;
    setIsEditMode(true);
  };

  const inputRef = useRef();
  useEffect(() => {
    if (isEditMode) {
      inputRef.current.select();
      onIsEditMode && onIsEditMode(isEditMode);
    }
  }, [isEditMode, onIsEditMode]);

  return (
    <FlexRow
      justifyContent={'space-between'}
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
      height="100%"
      onDoubleClick={() => handleDoubleClick()}
    >
      <TextInputContainer isActive={isEditMode}>
        <TextInput
          theme={layerInputTheme}
          value={title}
          isActive={isEditMode}
          onChanging={(val) => setTitle(val)}
          onChanged={(val) => {
            onNameChange(id, val);
            setIsEditMode(false);
            onIsEditMode && onIsEditMode(false);
          }}
          disabled={isSpecialLayer}
          inputRef={inputRef}
        />
      </TextInputContainer>
      <ActionContainer alignLeft={isSpecialLayer}>
        <IconButton
          dataTestId="hide-layer"
          iconName={hidden ? 'eyeSlash' : 'eye'}
          height={'10px'}
          isActive={!hidden}
          theme={layerActionsIconTheme}
          onClick={(e) => {
            e.stopPropagation();
            dispatchEvent(LAYER_HIDDEN, { id });
          }}
        />
        {!isSpecialLayer && (
          <IconButton
            dataTestId="lock-layer"
            iconName={locked ? 'lock' : 'unlock'}
            height={'13px'}
            isActive={locked}
            disabled={disabled}
            theme={layerActionsIconTheme}
            onClick={(e) => {
              e.stopPropagation();
              dispatchEvent(LAYER_LOCKED, { id });
            }}
          />
        )}
      </ActionContainer>
    </FlexRow>
  );
};

Layer.propTypes = {
  /**
   * The data of this layer
   */
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
  onIsEditMode: PropTypes.func,
  onNameChange: PropTypes.func,
};

export default React.memo(Layer);
