export const pointFromTouch = (touch) => {
  return {
    x: touch.clientX,
    y: touch.clientY,
  };
};

export const distance = (aTouch, bTouch) => {
  const { clientX: aX, clientY: aY } = aTouch;
  const { clientX: bX, clientY: bY } = bTouch;
  return Math.hypot(aX - bX, aY - bY);
};

export const updateTouchDictionary = (touchDictionary, touchEntries) => {
  for (let i = 0; i < touchEntries.length; i++) {
    const entry = touchEntries[i];
    touchDictionary[entry.identifier] = entry;
  }
};

export const deleteFromTouchDictionary = (touchDictionary, touchEntries) => {
  for (let i = 0; i < touchEntries.length; i++) {
    const entry = touchEntries[i];
    delete touchDictionary[entry.identifier];
  }
};
