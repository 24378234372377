import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import analytics from '../global/analytics';
import { CLICK_NOTIFICATION } from '../global/analytics/events';
import Api from '../global/userApi';
import { useUserStore } from './userStore';

const NOTIFICATIONS_UPDATE_INTERVAL = 1000 * 60 * 5; // five minutes
const NOTIFICATIONS_CREATE_INTERVAL = 1000 * 60 * 10; // ten minutes
const TAKE = 10;

const runNotificationsInterval = (get, set) =>
  setInterval(async () => {
    const user = useUserStore.getState().user;
    if (!user) return false;
    // Try to prevent weird notification request bursts
    if (get().isLoading) return false;

    set({ isLoading: true });
    const response = await Api.getNotifications(TAKE, 0);
    set({ isLoading: false });

    if (!response.results?.length) {
      if (response.error === 'UNAUTHORIZED') {
        await useUserStore.getState().removeUser();
      }
      return false;
    }

    const currentNotificationsIds = get().notifications.map((n) => n.id);
    const newNotifications = response.results.filter(
      (n) => !currentNotificationsIds.includes(n.id)
    );
    if (newNotifications.length) {
      set({
        notifications: [...newNotifications, ...get().notifications],
        skip: get().skip + newNotifications.length,
        showUnseenBadge:
          get().showUnseenBadge ||
          newNotifications.some((notification) => !notification.seenAt),
      });
    }
  }, NOTIFICATIONS_UPDATE_INTERVAL);

const attemptToCreateCmsNotification = () => {
  setInterval(async () => {
    const user = useUserStore.getState().user;
    if (!user) return false;

    const response = await Api.createCmsNotification();
    if (response.error === 'UNAUTHORIZED') {
      await useUserStore.getState().removeUser();
      return false;
    }
  }, NOTIFICATIONS_CREATE_INTERVAL);
};

/**
 * NotificationType suggestions
 * FOLLOW, ADDED_TEMPLATES, NEW_ILLUSTRATION, TRENDING_TEMPLATES, MESSAGE
 */

/**
 * Notification schema:
 *
 * @param {string} id - Notification id
 * @param {NotificationType} type - Notification type
 * @param {number} recipientId - Who receives the notification
 * @param {Object} relatedUser - Sender information (name, profile picture)
 * @param {timestamp} createdAt - Time of creation
 * @param {(timestamp|null)} readAt - Time of read
 * @param {[]|Object} data - Notification data
 */

export const notificationStore = createVanilla((set, get) => ({
  notifications: [],
  showUnseenBadge: false,
  isLoading: false,

  skip: 0,
  finished: false,

  /**
   * initialize the store
   */
  init: async () => {
    await get().fetch();

    runNotificationsInterval(get, set);
    get().createCmsNotification();
  },
  /**
   * fetch new notifications from the api
   */
  fetch: async () => {
    const user = useUserStore.getState().user;
    if (!user) return false;

    if (get().finished || get().isLoading) {
      return false;
    }
    set({ isLoading: true });

    const response = await Api.getNotifications(TAKE, get().skip);
    if (!response.results?.length) {
      return set({
        isLoading: false,
        finished: true,
      });
    }

    const notifications = [...get().notifications, ...response.results];

    let showUnseenBadge = useUserStore.getState().showUnseenBadge;
    if (get().skip === 0) {
      showUnseenBadge = notifications.some(
        (notification) => !notification.seenAt
      );
    }

    return set({
      isLoading: false,
      skip: get().skip + TAKE,
      notifications,
      showUnseenBadge,
      finished: response.results.length < TAKE,
    });
  },
  createCmsNotification: () => {
    attemptToCreateCmsNotification();
  },
  /**
   * reset all values to their defaults
   */
  reset: () => {
    set({
      notifications: [],
      showUnseenBadge: false,
      isLoading: false,

      skip: 0,
      finished: false,
    });
  },
  setUnseenBadge: async (bool) => {
    await Api.markNotificationsSeen();
    set({
      showUnseenBadge: bool,
    });
  },
  handleSeeNotificationPanel: () => {
    get().setUnseenBadge(false);
  },
  handleHasSeenNotificationPanel: () => {
    // remove red dot from all notifications
    const date = toString(new Date());
    set({
      notifications: get().notifications.map((n) => {
        return { ...n, seenAt: date };
      }),
    });
  },
  setReadNotification: (id) => {
    const readDate = toString(new Date());
    set({
      notifications: get().notifications.map((n) => {
        if (n.id === id) {
          return { ...n, readAt: readDate, seenAt: readDate };
        }
        return n;
      }),
    });

    Api.markNotificationRead(id);

    const notification = get().notifications.find((n) => n.id === id);
    analytics.track(CLICK_NOTIFICATION, {
      label: notification.type,
    });
  },
}));

export const notificationStoreSelector = {
  notifications: (state) => state.notifications,
  isLoading: (state) => state.isLoading,
  finished: (state) => state.finished,
  showUnseenBadge: (state) => state.showUnseenBadge,
  handleSeeNotificationPanel: (state) => state.handleSeeNotificationPanel,
  handleHasSeenNotificationPanel: (state) =>
    state.handleHasSeenNotificationPanel,
  setReadNotification: (state) => state.setReadNotification,
  fetch: (state) => state.fetch,
  init: (state) => state.init,
  reset: (state) => state.reset,
};

export const useNotificationStore = create(notificationStore);
