import styled from 'styled-components';

import { themeStyle } from '../../services/theming';
import { FlexRow } from '../utilities/styles';

export const AutoAdjustControlRow = styled(FlexRow)`
  top: 0;
  position: sticky;
  box-sizing: border-box;
  width: calc(100% + 5px);
  z-index: ${themeStyle.zIndexS1};
  background: ${themeStyle.varBackgroundAlt};
  padding: 12px 5px 12px 0;
  margin: -10px 0 -12px 0;
`;
