import { getTemplatePreviewHeight } from '../../helpers/templateDimensions';
import { Item } from './types';

/**
 * calculate the height and item will take up inside the masonry
 * used to allocate items to the shortest columns
 */
export const getItemHeight = (
  item: Item,
  columnWidth: number,
  equalDimensions?: boolean
): number => {
  if (equalDimensions) return columnWidth;
  const width =
    item.width ||
    item.state?.artboardOptions?.width ||
    item.template?.state?.artboardOptions?.width ||
    1;
  const height =
    item.height ||
    item.state?.artboardOptions?.height ||
    item.template?.state?.artboardOptions?.height ||
    1;

  const itemHeight = getTemplatePreviewHeight({ width, height }, columnWidth);
  return itemHeight;
};

/**
 * calculate the number of columns and their width
 */
export const calculateColumns = (
  wrapperSize: number,
  minColumnWidth: number,
  gap: number
): {
  columnWidth: number;
  columnNumber: number;
} => {
  const columnNumber = Math.max(
    1,
    Math.floor((wrapperSize + gap) / (minColumnWidth + gap))
  );
  const columnWidth = (wrapperSize + gap) / columnNumber - gap;

  return {
    columnWidth,
    columnNumber,
  };
};
