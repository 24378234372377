const ALERT_URL = '/icons/alert.svg';
export const ALERT_WIDTH = 24;
const ALERT_CONTROL_GAP = 25;

/* Gets the point where the text will disappear on the object if too long for the curve */
const getLastPoint = (points, object) => {
  const { type, directionInverted } = object.transformOptions || {};

  if (type === 'circle') {
    return directionInverted ? points[1] : points[2];
  }
  return object.textAlignment === 'right'
    ? points[0]
    : points[points.length - 1];
};

const initDangerControl = function (fabric) {
  const img = new Image();

  img.src = ALERT_URL;
  const imgWidth = ALERT_WIDTH;

  const alertControl = new fabric.Control({
    render: function (ctx, left, top, styleOverride, fabricObject) {
      if (!fabricObject.textOutOfBounds) {
        /* Delete position key so that there's not message displayed when hovered */
        delete fabricObject.pathTextAlertPosition;
        /* This is so that control does not react to cursor being placed on top of it */
        this.skipTargetFind = true;
        return;
      }

      /* Restore interaction */
      this.skipTargetFind = false;

      const vpt = fabricObject.canvas.viewportTransform;
      const inverseVPT = fabric.util.invertTransform(vpt);

      /* Points in screen coordinates */
      const points = fabricObject.points.map((p) =>
        fabric.util.transformPoint(p, vpt)
      );

      /* Center of object in screen coordinates*/
      const center = fabric.util.transformPoint(
        fabricObject.getAbsoluteCenterPoint(),
        vpt
      );

      const { x, y } = getLastPoint(points, fabricObject);

      /* Position where image will be placed */
      const imageCenter = {
        x: x,
        y: y - ALERT_CONTROL_GAP,
      };

      let offset = {
        x: imageCenter.x - center.x,
        y: imageCenter.y - center.y,
      };

      /* Inverted rotation of object */
      const invRot = fabric.util.invertTransform(
        fabric.util.calcRotateMatrix({ angle: fabricObject.angle })
      );

      /* 
        Offset accounts for object rotation, but we are calculating everything manually,
        based on the object's points, so we don't need it. We cancel it out
      */
      offset = fabric.util.transformPoint(offset, invRot);

      this.offsetX = offset.x;
      this.offsetY = offset.y;

      ctx.save();
      ctx.translate(imageCenter.x, imageCenter.y);
      ctx.drawImage(img, -imgWidth / 2, -imgWidth / 2, imgWidth, imgWidth);
      ctx.restore();

      const alertPosition = fabric.util.transformPoint(imageCenter, inverseVPT);
      fabricObject.pathTextAlertPosition = alertPosition;
    },
    cursorStyle: 'default',
    sizeX: ALERT_WIDTH,
    sizeY: ALERT_WIDTH,
    cornerSize: ALERT_WIDTH,
  });

  fabric.PathText.prototype.alertControl = alertControl;
};

export default initDangerControl;
