import React, { useEffect } from 'react';
import { Wrapper } from './styles';

import Button from '../../Button/Button/Button';
import {
  primarySmallTheme,
  primaryTheme,
  secondarySmallTheme,
  secondaryTheme,
} from '../../Button/Button/theme';
import analytics from '../../../global/analytics';
import {
  AUTHENTICATION_CLICK_SIGNUP,
  AUTHENTICATION_CLICK_LOGIN,
} from '../../../global/analytics/events';
import { AuthenticationButtonsProps } from './types';
import {
  authenticationStoreSelector,
  useAuthenticationStore,
} from '../../../stores/authenticationStore';

/**
 * Component to display the authentication buttons
 */
const AuthenticationButtons: React.FC<AuthenticationButtonsProps> = ({
  layout = 'row',
  showSignUpInitially,
  showSignInInitially,
  ...props
}) => {
  const openAuthentication = useAuthenticationStore(
    authenticationStoreSelector.openAuthentication
  );
  useEffect(() => {
    if (showSignUpInitially) {
      openAuthentication({ isSignup: true });
    } else if (showSignInInitially) {
      openAuthentication({ isSignup: false });
    }
  }, [showSignUpInitially, showSignInInitially, openAuthentication]);

  const openLogin = (): void => {
    analytics.track(AUTHENTICATION_CLICK_LOGIN, { label: 'navigation' });
    openAuthentication({ isSignup: false });
  };

  const openSignup = (): void => {
    analytics.track(AUTHENTICATION_CLICK_SIGNUP, { label: 'navigation' });

    // If props.onSignup is defined, call it and don't use default behavior
    if (props.onSignup) {
      props.onSignup();
      return;
    }
    openAuthentication({ isSignup: true });
  };

  return (
    <Wrapper layout={layout}>
      {layout === 'row' && (
        <Button
          theme={secondaryTheme}
          label="Log In"
          width="110px"
          height="40px"
          onClick={openLogin}
        />
      )}
      <Button
        theme={layout === 'row' ? primaryTheme : primarySmallTheme}
        label="Sign Up"
        width={layout === 'row' ? '110px' : '100%'}
        height={layout === 'row' ? '40px' : '30px'}
        onClick={openSignup}
      />
      {layout === 'column' && (
        <Button
          theme={secondarySmallTheme}
          label="Login"
          width="100%"
          height="30px"
          onClick={openLogin}
        />
      )}
    </Wrapper>
  );
};

export default AuthenticationButtons;
