import { themeStyle } from '../../services/theming';
import { numberInputTheme } from '../NumberInput/theme';
import { WrappedNumberInputTheme } from './types';

export const defaultTheme: WrappedNumberInputTheme = {
  ...numberInputTheme,

  // size
  width: '82px',
  height: '30px',
  iconHeight: '16px',
  iconWidth: '30px',

  // wrapper
  wrapperBackgroundColor: themeStyle.varInkSuperLight,
  wrapperBackgroundColorDark: null,
  wrapperBackgroundColorDisabled: null,
  wrapperBackgroundColorDisabledDark: null,
  wrapperBorderColor: themeStyle.varInkLight,
  wrapperBorderColorDark: themeStyle.varInkSuperLight,
  wrapperBorderColorDisabled: null,
  wrapperBorderColorDisabledDark: null,
  wrapperBorderRadius: themeStyle.radiusMedium,

  // input
  inputWidth: '50px',
  backgroundColor: themeStyle.varBackgroundAlt,
  borderColor: 'transparent',
  color: themeStyle.varInkMedium,
  colorHover: themeStyle.varInkMain,
  fontSize: themeStyle.fontSize12,

  iconTransform: 'unset',
  disabledColor: themeStyle.varInkLight,
};

export const settingsInputTheme: Partial<WrappedNumberInputTheme> = {
  width: '128px',
  iconHeight: '8px',
  inputWidth: '98px',
  inputMarginLeft: '5px',
  wrapperBackgroundColorDark: themeStyle.varBackgroundAlt,
  wrapperBackgroundColor: themeStyle.varBackgroundAlt,
  wrapperBorderRadius: themeStyle.radiusSmall,
  wrapperBorderColorDark: themeStyle.varInkLight,
};
