import {
  ADD_BASIC_SHAPE,
  ADD_ILLUSTRATION,
  ADD_MASK,
} from '../../global/events';
import { buildImageProxyUrl } from '../../utils/url';

export const getObjectName = (elementType) => {
  switch (elementType) {
    case 'templates':
      return 'templatePreviewObjectName';
    case 'layouts':
      return 'preview';
    case 'mockups':
      return 'preview';
    default:
      return 'objectName';
  }
};

export const getRenderBaseProps = (element, elementType, scale = 1) => {
  const elementProps = {
    ...element,
    artboardOptions: element.state?.artboardOptions,
    preview: buildImageProxyUrl(element[getObjectName(elementType)], {
      scale,
    }),
  };

  return { elementProps };
};

const EVENT_MAP = [
  {
    event: ADD_MASK,
    groupName: 'Masks',
    type: 'mask',
  },
  {
    event: ADD_BASIC_SHAPE,
    groupName: 'Basic Shapes',
    type: 'basicShape',
  },
];

/**
 * @param groupName Name of the group the element was added from
 * @param val event value (conatins information about the element and all its groups)
 */
export const getElementTypeByGroupName = (groupName, val) => {
  for (const item of EVENT_MAP) {
    if (
      item.groupName === groupName ||
      val.groups?.some((g) => g.name === item.groupName)
    ) {
      return item.type;
    }
  }
  return 'illustration';
};

/**
 * @param groupName Name of the group the element was added from
 * @param val event value (conatins information about the element and all its groups)
 */
export const getEventByElementGroup = (groupName, val) => {
  for (const item of EVENT_MAP) {
    if (
      item.groupName === groupName ||
      val.groups?.some((g) => g.name === item.groupName)
    ) {
      return item.event;
    }
  }
  return ADD_ILLUSTRATION;
};
