import { useCallback, useEffect, useState } from 'react';
import mockupApi from '../../../api/mockups';
import monitoring from '../../../services/monitoring';
import { toastSelector, useToastStore } from '../../../stores/toastStore';
import { buildElementUrl } from '../../../utils/url';
import { MockupTemplate, UpdateLayersLoading } from '../types';

const defaultActiveMockupId = 23;
/**
 * This hook fetches the mockup template.
 */
export const useMockupTemplate = (
  updateLoading: UpdateLayersLoading
): {
  mockupTemplate: MockupTemplate | null;
  setActiveMockupId: (id: number) => void;
} => {
  const [activeMockupId, setActiveMockupId] = useState<number>(
    defaultActiveMockupId
  );
  const [mockupTemplate, setMockupTemplate] = useState<MockupTemplate | null>(
    null
  );
  const fireToast = useToastStore(toastSelector.fire);

  const updateMockupTemplate = useCallback(async (): Promise<void> => {
    try {
      updateLoading({
        sceneLayer: true,
        darkBlendLayer: true,
        lightBlendLayer: true,
      });

      const mockupData = await mockupApi.get(activeMockupId);
      if (!('mockup' in mockupData)) {
        throw new Error('Failed to load mockup');
      }
      setMockupTemplate({
        id: String(activeMockupId),
        sceneLayer: buildElementUrl(mockupData.mockup.sceneLayer),
        lightBlendLayer: mockupData.mockup.lightBlendLayer
          ? buildElementUrl(mockupData.mockup.lightBlendLayer)
          : '',
        darkBlendLayer: mockupData.mockup.darkBlendLayer
          ? buildElementUrl(mockupData.mockup.darkBlendLayer)
          : '',
        warpGrid: mockupData.mockup.warpGrid
          ? JSON.parse(mockupData.mockup.warpGrid)
          : undefined,
      });
    } catch (error) {
      monitoring.captureException(error);
      fireToast({ label: 'Failed to load mockup', error: true });
    }
  }, [updateLoading, fireToast, activeMockupId]);

  useEffect(() => {
    updateMockupTemplate();
  }, [updateMockupTemplate]);

  return { mockupTemplate, setActiveMockupId };
};
