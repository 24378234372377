/*
Utility functions for visual debugging
*/

import { fabric } from 'fabric';
import { bezierPathFromPoints } from '../editor/shapes';
import { Point } from '../../types';

// debugging tools on dev environment
// probably move these first two to another file
export const makeDebugCircle = (
  left: number,
  top: number,
  radius: number,
  fillColor: string
): fabric.Circle => {
  return new fabric.Circle({
    excludeFromExport: true,
    left,
    top,
    radius,
    fill: fillColor,
    stroke: '#666',
    selectable: false,
    centeredScaling: true,
    padding: 2,
    hasRotatingPoint: false,
  });
};

export const makeDebugText = (
  left: string,
  top: string,
  text = 'test',
  color: string
): fabric.Text => {
  return new fabric.Text(text, {
    excludeFromExport: true,
    fontFamily: 'Calibri',
    fontSize: 15,
    textAlign: 'center',
    originX: 'center',
    originY: 'center',
    left: left,
    top: top,
    color,
  });
};

/**
 * DevUtils for PathText
 */

/**
 * render the points of an object on a canvas
 * @param {*} ctx
 * @param {*} points
 */
export const renderPoints = (
  ctx: CanvasRenderingContext2D,
  points: Point[]
): void => {
  ctx.save();

  const radius = 2;
  ctx.fillStyle = 'red';
  ctx.globalAlpha = 0.8;

  points.forEach(({ x, y }, index) => {
    ctx.fillText(String(index), x, y);
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.fill();
  });

  ctx.restore();
};

/**
 * render an absolute point on the canvas
 * @param {*} ctx
 * @param {*} point
 * @param {*} color
 */
export const renderPoint = (
  ctx: CanvasRenderingContext2D,
  { x, y }: Point,
  color: string
): void => {
  ctx.save();

  const radius = 2;
  ctx.fillStyle = color || 'red';
  ctx.globalAlpha = 0.8;

  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI);
  ctx.fill();

  ctx.restore();
};

/**
 * render the bezier path from freeLine controls on a canvas
 * @param {*} ctx
 * @param {*} points
 */
export const renderFreeLine = (
  ctx: CanvasRenderingContext2D,
  points: Point[]
): void => {
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];

  const curve = bezierPathFromPoints(points);

  ctx.save();
  ctx.lineWidth = 1;
  ctx.strokeStyle = 'blue';
  ctx.beginPath();
  ctx.moveTo(firstPoint.x, firstPoint.y);

  const accuracy = 0.01;
  for (let i = 0; i <= 1; i += accuracy) {
    const p = curve.getPointAt(i * curve.length);
    if (!p) continue; // if curve is too short p can be null
    // relative position of object
    const xRelToObject = p.x;
    const yRelToObject = p.y;

    ctx.lineTo(xRelToObject, yRelToObject);
  }
  ctx.lineTo(lastPoint.x, lastPoint.y);

  // Add lines between positional points and their controls
  points.forEach((point, index) => {
    if (index % 3 === 0) {
      // line to previous control
      if (index > 0) {
        const lastPoint = points[index - 1];
        ctx.moveTo(lastPoint.x, lastPoint.y);
        ctx.lineTo(point.x, point.y);
      }
      if (index < points.length - 1) {
        const nextPoint = points[index + 1];
        ctx.moveTo(point.x, point.y);
        ctx.lineTo(nextPoint.x, nextPoint.y);
      }
    }
  });

  ctx.stroke();
  ctx.restore();
};
