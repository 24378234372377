const iconUrl = '/icons/share/';

export const elements = [
  {
    icon: 'facebook',
    label: 'Facebook',
    src: `${iconUrl}facebook.svg`,
  },
  {
    icon: 'twitter',
    label: 'Twitter',
    src: `${iconUrl}twitter.svg`,
  },
  {
    icon: 'whatsApp',
    label: 'WhatsApp',
    src: `${iconUrl}whatsapp.svg`,
  },
  {
    icon: 'pinterest',
    label: 'Pinterest',
    src: `${iconUrl}pinterest.svg`,
  },
  {
    icon: 'mail',
    label: 'Mail',
    src: `${iconUrl}mail.svg`,
  },
  {
    icon: 'link',
    label: 'Link',
    src: `${iconUrl}link.svg`,
  },
];
