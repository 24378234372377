import React from 'react';
import PropTypes from 'prop-types';

import { Skip } from './styles';
import PanelHeader from '../utilities/PanelHeader/PanelHeader';
import { H4, P2 } from '../utilities/Typography/styles';
import { FlexRow } from '../utilities/styles';
import { headerTheme } from './theme';
import Button from '../Button/Button/Button';
import { secondaryLightSmallTheme } from '../Button/Button/theme';
import Icon from '../Icon/Icon';
import { themeStyle } from '../../services/theming';

/**
 * The content of an onboarding step, that is displayed in a popover.
 */
const OnboardingStep = (props) => {
  const currentOption = props.options.find(
    (option) => option.index === props.currentOption
  );
  const isFallback = props.currentDisplaySubStep === -1;
  const currentSubStep = isFallback
    ? currentOption.fallback
    : currentOption.steps[props.currentDisplaySubStep];

  const displayIndex = currentSubStep.displayIndex;

  return (
    <>
      <PanelHeader
        label={`${displayIndex} / ${props.options.length}`}
        onClose={props.onSkip}
        theme={headerTheme}
      />
      <FlexRow marginTop={'10px'} gap={'8px'}>
        <H4 color={themeStyle.inkBase}>
          {currentSubStep.label || currentOption.label}
        </H4>
        {currentSubStep.icon && (
          <Icon
            name={currentSubStep.icon}
            theme={{ color: themeStyle.inkBase }}
          />
        )}
      </FlexRow>
      <FlexRow marginBottom={'32px'} marginTop={'10px'}>
        <P2 color={themeStyle.inkLight}>{currentSubStep.description}</P2>
      </FlexRow>
      <FlexRow
        justifyContent={'space-between'}
        alignItems={'end'}
        marginTop={'8px'}
      >
        <Skip color={themeStyle.inkLight} onClick={props.onSkip}>
          {'Skip Tour'}
        </Skip>
        <Button
          label={'Next Step'}
          onClick={() => props.onNextStep(currentSubStep, isFallback)}
          theme={secondaryLightSmallTheme}
          height={'30px'}
          width={'95px'}
        />
      </FlexRow>
    </>
  );
};

OnboardingStep.propTypes = {
  /**
   * onboarding steps
   */
  options: PropTypes.array,
  /**
   * index of the current option
   */
  currentOption: PropTypes.number,
  /**
   * index of the current subStep for the current option
   * can also be -1 to indicate that it is the fallback
   */
  currentSubStep: PropTypes.number,
  /**
   * action to trigger when skip is clicked
   */
  onSkip: PropTypes.func,
  theme: PropTypes.object,
  currentDisplaySubStep: PropTypes.number,
  onNextStep: PropTypes.func,
};

OnboardingStep.defaultProps = {
  options: [],
  currentOption: 0,
  currentSubStep: 0,
};

export default OnboardingStep;
