import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ButtonsWrapper } from './styles';
import DecisionModal from '../../../../DecisionModal/DecisionModal';
import IconButton from '../../../../Button/IconButton';
import { circledIconTheme } from '../../../../Icon/theme';
import Popover from '../../../../Popover/Popover';
import OptionList from '../../../../OptionList/OptionList';
import folderApi from '../../../../../global/folderApi';

import useDesignsStore, {
  designsStoreSelector,
} from '../../../../../stores/designsStore';
import { useToastStore, toastSelector } from '../../../../../stores/toastStore';

const FolderButtons = ({ id }) => {
  const [isConfirmPanelOpen, setIsConfirmPanelOpen] = useState(false);
  const deleteElement = useDesignsStore(designsStoreSelector.deleteElement);
  const fireToast = useToastStore(toastSelector.fire);

  const onConfirmDelete = async () => {
    const response = await folderApi.deleteFolder({ id });
    if (!response.success) {
      fireToast({
        label: response.error || 'Failed to delete the folder',
        error: true,
      });
    } else {
      deleteElement(id);
    }

    setIsConfirmPanelOpen(false);
  };

  const onCancelDelete = () => {
    setIsConfirmPanelOpen(false);
  };

  const options = [
    {
      key: 'delete',
      label: 'Delete',
      iconName: 'delete',
      onClick: () => setIsConfirmPanelOpen(true),
    },
  ];

  const optionList = <OptionList options={options} />;

  return (
    <>
      <DecisionModal
        isOpen={isConfirmPanelOpen}
        onClose={() => setIsConfirmPanelOpen(false)}
        icon="delete"
        message="Are you sure you want to delete this folder?"
        note="This will also delete all projects inside."
        firstButton={{
          label: 'Cancel',
          onClick: onCancelDelete,
        }}
        secondButton={{
          label: 'Confirm',
          onClick: onConfirmDelete,
        }}
      />
      <ButtonsWrapper>
        <Popover
          hideTip
          placement="right"
          align="start"
          showHeader={false}
          width="156px"
          content={optionList}
        >
          <IconButton
            iconName="designElementSettings"
            height="24px"
            theme={circledIconTheme}
          />
        </Popover>
      </ButtonsWrapper>
    </>
  );
};

FolderButtons.propTypes = {
  /**
   * id of the folder
   */
  id: PropTypes.string,
};

export default FolderButtons;
