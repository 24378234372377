import { Point } from '../../types';
import { TOLERANCE } from './constants';
import { getDistance } from './point';

/**
 * Get the angle of a vector defined by two given points.
 * @param p1 {{ x: number, y: number }}: starting point of the vector
 * @param p2 {{ x: number, y: number }}: ending point of the vector
 * @returns {number}: 0 <= angle < 2 * Math.PI
 */
export const getVectorAngle = (p1: Point, p2: Point): number => {
  const length = getDistance(p1, p2);
  let angle = Math.acos((p2.x - p1.x) / length);

  if (p2.y - p1.y < 0) {
    angle = 2 * Math.PI - angle;
  }

  return angle;
};

/**
 * Check if a point q is on the left side of the vector, defined by two given points p1, p2.
 * Note: left side meaning the angle from vector p1p2 to p1q is between 0 and Math.PI.
 * @param q {{ x: number, y: number }}: the point to check
 * @param p1 {{ x: number, y: number }}: starting point of the vector
 * @param p2 {{ x: number, y: number }}: ending point of the vector
 * @returns {boolean}
 */
export const checkIfPointOnLeftSideOfVector = (
  q: Point,
  p1: Point,
  p2: Point
): boolean => {
  const theta = getVectorAngle(p1, q) - getVectorAngle(p1, p2);

  return theta >= 0 && theta < Math.PI;
};

/**
 * Get the interpolated angle at ratio a, between two vectors defined by four given points.
 * the returned value is within *the smaller half* of the space divided by two vectors,
 * e.g, if vector1 has angle 0, vector2 has angle 3*Math.PI/2,
 * 7*Math.PI/4 is returned but not 3*Math.PI/4.
 * @param p1 {{ x: number, y: number }}: starting point of the first vector
 * @param p2 {{ x: number, y: number }}: ending point of the first vector
 * @param p3 {{ x: number, y: number }}: starting point of the second vector
 * @param p4 {{ x: number, y: number }}: ending point of the second vector
 * @param a {number}
 * @returns {number}: 0 <= angle < 2 * Math.PI
 */
export const getInterpolatedAngle = (
  p1: Point,
  p2: Point,
  p3: Point,
  p4: Point,
  a: number
): number => {
  let angle1 = getVectorAngle(p1, p2);
  const angle2 = getVectorAngle(p3, p4);

  if (angle2 - angle1 > Math.PI) {
    angle1 += 2 * Math.PI;
  } else if (angle1 - angle2 > Math.PI) {
    angle1 -= 2 * Math.PI;
  }

  return (angle1 + a * (angle2 - angle1) + 2 * Math.PI) % (2 * Math.PI);
};

/**
 * Get moved point along direction with given distance from origin.
 * @param origin {{ x: number, y: number }}
 * @param vectorAngle {number}: angle of the vector
 * @param distance {number}
 * @param asc {boolean}: true means along the direction of the vector
 * @returns {{x: number, y: number}}
 */
export const getMovedPointAlongDirection = (
  origin: Point,
  vectorAngle: number,
  distance: number,
  asc: boolean
): Point => {
  const angle = asc ? vectorAngle : vectorAngle + Math.PI;
  return {
    x: origin.x + distance * Math.cos(angle),
    y: origin.y + distance * Math.sin(angle),
  };
};

/**
 * True if v1 and v2 are vectors with the same direction regardless of orientation
 * NOTE: v1 = (0, 0) and v2 = (0, 0) yield true for convenience, although the notion
 * of "parallel" does not really apply.
 * @param v1 {{ x: number, y: number }} The first vector
 * @param v2 {{ x: number, y: number }} The second vector
 * @returns {boolean}
 */
export const areVectorsParallel = (v1: Point, v2: Point): boolean => {
  if (!v1.x) {
    return !v2.x;
  }

  if (!v1.y) {
    return !v2.y;
  }

  return Math.abs(v2.x / v1.x - v2.y / v1.y) < TOLERANCE;
};

/**
 * Gets the mid-point from a list of points
 * @param points {[{ x: number, y: number }]}
 * @returns {{x: number, y: number}}
 */
export const getMidPoint = (points: Point[]): Point => {
  const length = points.length;

  const mid = points.reduce(
    (acc, point) => ({
      x: acc.x + point.x,
      y: acc.y + point.y,
    }),
    { x: 0, y: 0 }
  );

  mid.x /= length;
  mid.y /= length;
  return mid;
};

/**
 * Gets the difference between two points
 * @param p1 {[{ x: number, y: number }]}
 * @param p2 {[{ x: number, y: number }]}
 * @returns {{x: number, y: number}}
 */
export const getDifference = (p1: Point, p2: Point): Point => {
  return {
    x: p1.x - p2.x,
    y: p1.y - p2.y,
  };
};
