import { createPortal } from 'react-dom';
import Toast from './Toast';
import { useToastStore, selector } from '../../stores/toastStore';

const ToastQueue = () => {
  const bodyElem =
    typeof window !== 'undefined' && window.document.querySelector('body');
  const queue = useToastStore(selector.queue);

  if (!bodyElem) return null;

  return createPortal(
    <>
      {queue?.map((t, i) => (
        <Toast key={t.id} id={t.id} index={i} />
      ))}
    </>,
    bodyElem
  );
};

export default ToastQueue;
