export const MAX_FONT_SIZE = 3000;
export const NUMBER_OF_BACKGROUND_OBJECTS = 1; // artboard/background is not part of allObjects, but in _objects
export const CLIPBOARD_LOCAL_STORAGE_KEY = 'htcDesignerClipboard';

// key for user access token
export const USER_ACCESS_TOKEN_KEY = 'htcUserAccessToken';
// key for device id
export const DEVICE_ID_KEY = 'htcDeviceId';
// key for user onboarding
export const ONBOARDING_KEY = 'htcOnboarding';
// key for user notifications
export const NOTIFICATIONS_KEY = 'htcNotifications';
// key for artboard settings
export const ARTBOARD_SETTINGS_KEY = 'htcArtboardSettings';
// key for invite friends popup
export const INVITE_FRIENDS_KEY = 'htcInviteFriends';
/**
 * key for current state
 * @deprecated we now only want to store PROJECT_ID_KEY
 */
export const ARTBOARD_STATE_KEY = 'artboard';
// key for current projectId
export const PROJECT_ID_KEY = 'htcProjectId';

//name of cookie to use in website to check if editor has been visited
export const HTC_DESIGNER_VISITED = 'htcDesignerVisited';

export const HIGH_RES_WARNING_DISMISSED = 'htcHighResWarningDismissed';

// cookie storing impact.com referral id
export const IMPACT_CLICK_ID = 'ktlImpactClickId';

export const COOKIE_UTM_CAMPAIGN = 'htcUtmCampaign';

let isPreview = false;
if (typeof window !== 'undefined') {
  isPreview =
    document?.location?.hostname?.endsWith('.netlify.app') ||
    document?.location?.hostname?.endsWith('.vercel.app');
}

// set cookie domain only in production or staging, not in deployment previews
export const COOKIE_DOMAIN = isPreview
  ? undefined
  : process.env.REACT_APP_DOMAIN || process.env.NEXT_PUBLIC_DOMAIN;

//simple regex to test for a valid email in frontend. Not bullet proof, backend should handle this properly
export const EMAIL_REGEXP = /^(\S+)@(\S+)\.\S{2,}$/;
// This is copied from API (src/config/default.config.ts). We can change it if needed
export const MIN_PASSWORD_LENGTH = 8;

export const ONE_MM_IN_INCHES = 5 / 127;
export const ARTBOARD_DPI = 60;
export const DEPRECATED_DPMM = 3;
export const DEFAULT_DOWNLOAD_DPI = 72;
export const MAX_ARTBOARD_SIZE = 6144;
export const MIN_ARTBOARD_SIZE = 60;
export const MAX_FREE_EXPORT_SCALE = 800 / MAX_ARTBOARD_SIZE; // max pixel goes to 800

export const MAXIMUM_SIZE = {
  mm: MAX_ARTBOARD_SIZE / (ARTBOARD_DPI * ONE_MM_IN_INCHES),
  in: MAX_ARTBOARD_SIZE / ARTBOARD_DPI,
  px: MAX_ARTBOARD_SIZE,
};

export const SAFE_EXPORT_PX_COUNT = 6000;

export const MAX_EXPORT_SIZE = 10800;
export const MAXIMUM_EXPORT_SIZE = {
  mm: MAX_EXPORT_SIZE / (ARTBOARD_DPI * ONE_MM_IN_INCHES),
  in: MAX_EXPORT_SIZE / ARTBOARD_DPI,
  px: MAX_EXPORT_SIZE,
};

export const MAXIMUM_FREE_EXPORT_SIZE = {
  mm: MAXIMUM_SIZE.mm * MAX_FREE_EXPORT_SCALE,
  in: MAXIMUM_SIZE.in * MAX_FREE_EXPORT_SCALE,
  px: MAXIMUM_SIZE.px * MAX_FREE_EXPORT_SCALE,
};

export const MINIMUM_SIZE = {
  mm: MIN_ARTBOARD_SIZE / (ARTBOARD_DPI * ONE_MM_IN_INCHES),
  in: MIN_ARTBOARD_SIZE / ARTBOARD_DPI,
  px: MIN_ARTBOARD_SIZE,
};

export const MINIMUM_EXPORT_SIZE = MINIMUM_SIZE;

export const IPAD_SIDE_LIMIT = 4096;
export const MAXIMUM_IPAD_EXPORT_SIZE = {
  mm: IPAD_SIDE_LIMIT / (ARTBOARD_DPI * ONE_MM_IN_INCHES),
  in: IPAD_SIDE_LIMIT / ARTBOARD_DPI,
  px: IPAD_SIDE_LIMIT,
};

export const UNITS = {
  // id: label
  in: '"',
  px: 'px',
  mm: 'mm',
};

export const UNIT_DESCRIPTIONS = {
  in: 'Inches (in)',
  px: 'Pixel (px)',
  mm: 'Millimetres (mm)',
};

export const IN_MULTIPLIER = 60;
export const MM_MULTIPLIER = 3;

// designs
export const AUTOSAVE_THROTTLE_DELAY = 15 * 1000; // 15 seconds
export const MAX_DESIGN_NAME_LENGTH = 48;
export const PREVIEW_TARGET_SIZE = 512;

export const MIN_ZOOM = 0.05;
export const DEFAULT_ZOOM = 0.8;
export const MAX_ZOOM = 10;

export const ZOOM_STEPS = [
  MIN_ZOOM,
  0.1,
  0.15,
  0.25,
  0.5,
  0.65,
  0.8,
  0.9,
  1.0,
  1.1,
  1.2,
  1.35,
  1.5,
  1.75,
  2.0,
  2.5,
  3.0,
  3.5,
  4.25,
  5,
  7.5,
  MAX_ZOOM,
];

export const EMPTY_STATE = {
  artboardOptions: {
    activeLayout: 'standard',
    width: 1200,
    height: 1200,
    fill: '#FFFFFF',
    gridSize: 20,
    trimView: false,
  },
  objects: [],
};

export const SOCIAL_SHARE_TARGET_RESOLUTION = 1200;

export const DEFAULT_SORT = 'popularity';
export const DEFAULT_SORT_ALL = 'recent';
export const DEFAULT_TAKE = 10;
export const DEFAULT_USER_FONTS_TAKE = 50;

export const MIN_PREVIEW_SIZE = 1024;
export const MIN_PROJECT_PREVIEW_SIZE = 724; // largest size we use to load design previews on website

// svg blend modes: https://www.w3.org/TR/SVG11/filters.html#feBlendModeAttribute (https://drafts.fxtf.org/compositing-2/#ltblendmodegt)
// canvas composition operations https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
// other available modes: 'screen', 'darken', 'lighten', 'color-dodge', 'hard-light', 'difference', 'exclusion', 'hue', 'saturation', 'color', 'luminosity',
export const overlayModes = [
  { id: 'normal', label: 'Normal', default: true },
  { id: 'color-burn', label: 'Color Burn' },
  { id: 'multiply', label: 'Multiply' },
  { id: 'overlay', label: 'Overlay' },
  { id: 'soft-light', label: 'Soft Light' },
  { id: 'destination-out', label: 'Alpha Mask' },
];

export const SPECIAL_LAYERS = ['overlay', 'background'];
export const SMALL_TAKE = 3;

export const ENABLE_BILLING =
  (process.env.REACT_APP_ENABLE_BILLING ||
    process.env.NEXT_PUBLIC_ENABLE_BILLING) === 'true';

export const GROWTHBOOK_API_KEY =
  process.env.REACT_APP_GROWTHBOOK_API_KEY ||
  process.env.NEXT_PUBLIC_GROWTHBOOK_API_KEY;
export const GROWTHBOOK_API_ENDPOINT = GROWTHBOOK_API_KEY
  ? `https://cdn.growthbook.io/api/features/${GROWTHBOOK_API_KEY}`
  : null;

export const TYPE_ACTIVE_SELECTION = 'activeSelection';

export const COLOR_PICKER_URL = '/icons/eyedropper.svg';

export const MOCKUP_TARGET_SIZE = 3000;

export const MOCKUP_EXPORT_SIZE_FREE = {
  width: 800,
  height: 800,
  unit: 'px',
};

export const MOCKUP_EXPORT_SIZE_PRO = {
  width: 1024,
  height: 1024,
  unit: 'px',
};

export const MOCKUP_EXPORT_SIZE_EXPERT = {
  width: 3000,
  height: 3000,
  unit: 'px',
};

// Export size for mockups is fixed to 3000 x 3000
export const MOCKUP_EXPORT_OPTIONS = {
  format: 'jpg',
  dpi: 72,
};
