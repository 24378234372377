import styled from 'styled-components';
import { applyFontStyles, handleTextOverflow } from '../utilities/styles';

export const OptionsRow = styled.div`
  margin-left: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  ${applyFontStyles}
  color: ${(props) => props.colorLabel};
  user-select: none;

  ${handleTextOverflow}
`;

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ margin }) => margin};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
