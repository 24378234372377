import { Bezier as _Bezier } from 'bezier-js';

import { getInterpolatedPoint, getDistance } from '../utils/geometry/point';

/**
 * Extended Bezier class of the original lib mainly for performance-friendly length computing
 * Added property:
 * @property len {number}: the accurate* length of the bezier curve, only calculated once when initiating
 * @property _flattend {{ points: [], stepLengths: [] }}:
 *  a line representation of the curve, served as look up table for performance-friendly length computing
 */
export default class Bezier extends _Bezier {
  constructor(...args) {
    super(...args);

    this.len = this.length();
    this._flattened = this._getFlattenedCurve();
  }

  _getFlattenedCurve() {
    const step = 100;
    const flattened = {
      points: [],
      stepLengths: [],
    };

    for (let i = 0; i <= step; i++) {
      const point = this.get(i / step);
      flattened.points.push(point);

      if (i > 0) {
        const lineLength = getDistance(flattened.points[i - 1], point);

        if (i === 1) {
          flattened.stepLengths.push({
            self: lineLength,
            sum: lineLength,
          });
        } else {
          flattened.stepLengths.push({
            self: lineLength,
            sum: flattened.stepLengths[i - 2].sum + lineLength,
          });
        }
      }
    }

    return flattened;
  }

  /**
   * Gets the projected point on the curve.
   * For explanation, please check the chapter here: https://pomax.github.io/bezierinfo/#projections
   * The code basically comes from the example in the above chapter: https://pomax.github.io/bezierinfo/chapters/projections/project.js
   * @param {x, y}, point needs to be projected
   * @param initialLookupTable, contains two sets of values as the boundary of the projected point
   * @returns {{ x, y }}
   * @private
   */
  _getProjectedPoint({ x, y }, initialLookupTable) {
    let lookupTable = initialLookupTable;
    let i = 0;
    let point = lookupTable[i];
    let count = 1;
    let distance = Infinity;

    do {
      const i1 = i === 0 ? 0 : i - 1;
      const i2 = i === lookupTable.length - 1 ? lookupTable.length - 1 : i + 1;
      const t1 = lookupTable[i1].t;
      const t2 = lookupTable[i2].t;
      /*
        We already know that lookupTable[i1] and lookupTable[i2] are *not* good distances,
        so we know that a better distance will be somewhere between them.
        We generate three new points between those two, so we end up with
        five points, and then check which three of those five are a new,
        better, interval to check within.
      */
      const updatedLookupTable = [];
      const step = (t2 - t1) / 4;

      if (step < 0.0001) break;

      updatedLookupTable.push(lookupTable[i1]);

      for (let j = 1; j <= 3; j++) {
        const newPoint = this.get(t1 + j * step);
        newPoint.distance = getDistance(
          { x: newPoint.x, y: newPoint.y },
          { x, y }
        );
        if (newPoint.distance < distance) {
          distance = newPoint.distance;
          point = newPoint;
          i = j;
        }
        updatedLookupTable.push(newPoint);
      }

      updatedLookupTable.push(lookupTable[i2]);

      // Update the lookupTable to be our new five point lookupTable, and run again.
      lookupTable = updatedLookupTable;

      // The value of `count` is mostly a safety measure: it will never kick in.
    } while (count++ < 20);

    return point;
  }

  /**
   * Gets the point on a bezier which forms the length at a specific ratio.
   * To avoid calculating lengths a lot, we utilize the flattened curve to find the approximate point, then project it on the curve.
   * This issue was refenrenced during development: https://github.com/Pomax/bezierjs/issues/33.
   * Two main comcepts behind this method:
   * 1) Simplied curve (flattening), more reference: https://pomax.github.io/bezierinfo/#flattening;
   * 2) Projecting point, more reference: https://pomax.github.io/bezierinfo/#projections;
   * @param ratio {number}
   * @returns {{x, y}}
   */
  getPointAtLengthRatio(ratio) {
    const flattenedLength =
      this._flattened.stepLengths[this._flattened.stepLengths.length - 1].sum;
    const length = ratio * flattenedLength;

    let stepIndex = 0;
    for (; stepIndex < this._flattened.stepLengths.length; stepIndex++) {
      if (
        length <= this._flattened.stepLengths[stepIndex].sum ||
        stepIndex === this._flattened.stepLengths.length - 1
      ) {
        break;
      }
    }

    const stepLength = this._flattened.stepLengths[stepIndex].self;
    const previousStepLengthSum =
      this._flattened.stepLengths[stepIndex - 1]?.sum || 0;
    const subRatio = (length - previousStepLengthSum) / stepLength;
    const pointOnLine = getInterpolatedPoint(
      this._flattened.points[stepIndex],
      this._flattened.points[stepIndex + 1],
      subRatio
    );

    return this._getProjectedPoint(
      pointOnLine,
      this._flattened.points.slice(stepIndex, stepIndex + 2)
    );
  }
}
