import { detect } from 'detect-browser';

/**
 * detect whether the current browser is old
 * old browser are not supported
 */
export default function isOldBrowser(): boolean {
  const browser = detect();

  if (browser && browser.name && browser.version) {
    switch (browser.name) {
      case 'chrome':
        if (Number(browser.version.split('.')[0]) < 69)
          // Version 69 released on 2018-09
          return true;
        break;
      case 'firefox':
        if (Number(browser.version.split('.')[0]) < 60)
          // Version 60 released on 2018-05
          return true;
        break;
      case 'edge':
      case 'edge-chromium':
      case 'ie':
        if (Number(browser.version.split('.')[0]) < 88)
          // Version 88, first Edge release, 2020-12
          return true;
        break;
      case 'safari':
        if (Number(browser.version.split('.')[0]) < 11)
          // Version 11, released on 2017-09
          return true;
        break;
      default:
        break;
    }
  }

  return false;
}
