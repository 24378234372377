import { useCallback, useEffect } from 'react';

import fabric from '../../components/Artboard/fabric';
import { updateCanvasSize } from '../../utils/editor/misc';

/**
 * This hook handles the binding/unbinding event handler for resize.
 */
export const useResizeHandler = (
  boardEl: HTMLElement | null,
  canvas: fabric.Canvas | null
): void => {
  const handleResize = useCallback(() => {
    if (!boardEl || !canvas) return;

    updateCanvasSize(boardEl, canvas);
  }, [boardEl, canvas]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
};
