import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';
import mixpanelPlugin from '@analytics/mixpanel';
import { isOnProductionEnvironment } from '../environment';
import customerIoPlugin from './customerIoPlugin';
import { isDebugAnalyticsActive } from '../../utils/dev';
import debugPlugin from './debugPlugin';
import apiPlugin from './apiPlugin';

// GA_ID comes in a format `UA-{some number}-{some other small number}`
const GA_ID = process.env.REACT_APP_GA_ID || process.env.NEXT_PUBLIC_GA_ID;

// GTM_ID comes in a format `GTM-{some letters and numbers}`
const GTM_ID = process.env.REACT_APP_GTM_ID || process.env.NEXT_PUBLIC_GTM_ID;

// MIXPANEL_TOKEN is a relatively long hex string
const MIXPANEL_TOKEN =
  process.env.REACT_APP_MIXPANEL_TOKEN ||
  process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
const FORCE_ENABLE_MIXPANEL =
  process.env.REACT_APP_FORCE_ENABLE_MIXPANEL ||
  process.env.NEXT_PUBLIC_FORCE_ENABLE_MIXPANEL;

export const CUSTOMERIO_SITE_ID =
  process.env.REACT_APP_CUSTOMERIO_SITE_ID ||
  process.env.NEXT_PUBLIC_CUSTOMERIO_SITE_ID;

let isMixpanelPluginAvailable = false;

const plugins = [];

if (isOnProductionEnvironment()) {
  if (GA_ID) {
    plugins.push(
      googleAnalytics({
        trackingId: GA_ID,
      })
    );
  }
  if (MIXPANEL_TOKEN) {
    plugins.push(
      mixpanelPlugin({
        token: MIXPANEL_TOKEN,
        pageEvent: 'pageView',
        options: {
          ignore_dnt: true,
        },
      })
    );
    isMixpanelPluginAvailable = true;
  }
  if (GTM_ID) {
    plugins.push(
      googleTagManager({
        containerId: GTM_ID,
      })
    );
  }
  if (CUSTOMERIO_SITE_ID) {
    plugins.push(customerIoPlugin({ siteId: CUSTOMERIO_SITE_ID }));
  }
  plugins.push(apiPlugin());
}

if (isDebugAnalyticsActive()) {
  plugins.push(debugPlugin());
}

const analytics = Analytics({
  app: 'kittl',
  plugins,
});

export const disableMixpanel = async () => {
  if (FORCE_ENABLE_MIXPANEL === 'true') return;
  if (isMixpanelPluginAvailable) await analytics.plugins.disable('mixpanel');
};

export const enableMixpanel = async () => {
  if (isMixpanelPluginAvailable) await analytics.plugins.enable('mixpanel');
};

disableMixpanel();

export default analytics;
