import styled from 'styled-components';
import { applyFontStyles, handleTextOverflow } from '../../utilities/styles';

export const Label = styled.div`
  ${applyFontStyles}
  color: ${(props) => props.colorLabel};
  font-weight: ${(props) =>
    props.isBold ? props.fontWeightBold : props.fontWeight};
  ${handleTextOverflow};
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ alignLeft }) =>
    alignLeft ? 'flex-start' : 'space-between'};
  margin-left: 5px;
  margin-right: 5px;

  width: 28px;
  flex-shrink: 0;
`;

export const TextInputContainer = styled.div`
  display: flex;
  pointer-events: ${({ isActive }) => (isActive ? 'unset' : 'none')};

  input {
    ${handleTextOverflow}
    width: 100%;
  }
`;
