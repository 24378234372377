import { fabric } from 'fabric';
import updateObject from './Object';
import updateGroup from './Group';
import updateRect from './Rect';
import updateCircle from './Circle';
import updateCanvas from './Canvas';
import addCanvasGrouping from './canvas_grouping.mixin';
import addCanvasColorPalette from './canvas_color_palette.mixin';
import updateActiveSelection from './ActiveSelection';
import updateGradient from './Gradient';
import updateImage from './Image';
import updatePath from './path.class';
import initArtboard from './Artboard';
import initIllustration from './Illustration.shape';
import initSvgLoading from './InitializeSvg.mixin';
import initBasicShape from './BasicShape.shape';
import { initPathText, initPathTextRender } from './PathText/PathText';
import initPathTextFreeLine from './PathText/PathTextFreeLine.mixin';
import initPathTextDecoration from './PathText/PathTextDecoration.mixin';
import initEditablePolygon from './EditablePolygon.shape';
import initClipPath from './ClipPath.shape';
import initTrueRender, { addTrueRenderToCanvas } from './TrueRender.mixin';
import initCustomTextbox from './CustomTextbox';
import initIllustrationImage from './IllustrationImage';
import initOverlayTexture from './OverlayTexture.shape';
import initDangerControl from './DangerControl';
import initEditTransformControl from './EditTransformControl';
import initObjectInteractivity from './object_interactivity.mixin';
import initCanvasMasking from './canvas_masking.mixin';
import initObjectMasking from './object_masking.mixin';
import initShadow from './Shadow';
import extendEvents from './extendEvents.mixin';
import initTargetFinding from './canvas_target_finding.mixin';
import initClipRender from './ClipRender';
import initSelectionSolver from './selection_solver.mixin';
import initCanvasSelection from './canvas_selection.mixin';
import initCanvasInput from './canvas_events.mixin';
import initMask from './Mask.shape';
import initMockupTemplateUtils from './mockupTemplate';
import initFilterBackend from './FilterBackend';
import initWarpedImage from './WarpedImage';

// New object should take ⅓ of the artboard's smallest side
fabric.NEW_OBJECT_SCALE = 1 / 3;

// Increasing these values can cause severe lags, but will increase cache quality
// fabric.perfLimitSizeTotal = 2097152 * 4;
// fabric.minCacheSideLimit = 4096 * 2;

// Here we update fabric.js with our custom classes
extendEvents(fabric);
updateObject(fabric);
updateGroup(fabric);
updateCanvas(fabric);
addTrueRenderToCanvas(fabric);
addCanvasGrouping(fabric);
addCanvasColorPalette(fabric);
updateActiveSelection(fabric);
updateRect(fabric);
updateCircle(fabric);
updateGradient(fabric);
updateImage(fabric);
updatePath(fabric);
initArtboard(fabric);
initIllustration(fabric);
initSvgLoading(fabric, fabric.Illustration);
initBasicShape(fabric);
initMask(fabric);
initSvgLoading(fabric, fabric.Mask);
initPathText(fabric);
initPathTextFreeLine(fabric);
initPathTextDecoration(fabric);
initEditablePolygon(fabric);
initClipPath(fabric);
initTrueRender(fabric, fabric.ClipPath);
initTrueRender(fabric, fabric.PathText);
initTrueRender(fabric, fabric.Illustration);
initTrueRender(fabric, fabric.Mask);
initPathTextRender(fabric);
initCustomTextbox(fabric);
initIllustrationImage(fabric);
initTrueRender(fabric, fabric.IllustrationImage);
initOverlayTexture(fabric);
initDangerControl(fabric);
initEditTransformControl(fabric);
initObjectInteractivity(fabric);
initCanvasMasking(fabric);
initObjectMasking(fabric, fabric.Illustration);
initObjectMasking(fabric, fabric.IllustrationImage);
initShadow(fabric);
initTargetFinding(fabric);
initClipRender(fabric);
initSelectionSolver(fabric);
initCanvasSelection(fabric);
initCanvasInput(fabric);
initMockupTemplateUtils(fabric);
initFilterBackend(fabric);
initWarpedImage(fabric);

export default fabric;

export const GROUP_TYPES = ['group', 'activeSelection'];
