import React, { useState, useEffect, useCallback } from 'react';

import { Wrapper } from './styles';
import { defaultTheme } from './theme';

import useThrottled from '../../hooks/useThrottle';

import TextInput from '../TextInput/TextInput';
import useTrie from '../../hooks/useTrie';
import { AutoSuggestProps } from './types';
import { getPrefix, getRestOfQueryForTag, tokenize } from './utils';
import { TextInputElement } from '../TextInput/types';
import Dropdown from '../Dropdown/Dropdown';

const SUGGESTION_LIMIT = 5;
const ARROW_UP = 38;
const ARROW_DOWN = 40;
const KEY_ENTER = 13;

const AutoSuggest: React.FC<AutoSuggestProps> = ({ onChanged, ...props }) => {
  const theme = { ...defaultTheme, ...props.theme };

  const tagTrie = useTrie(props.tags);
  const [matchPointer, setMatchPointer] = useState<number | null>(null);

  // store a query state, consisting of the actual query
  // and matching tags for that query
  const [queryState, setQueryState] = useState<{
    query: string;
    matches: { tag: string; prefix?: string }[];
  }>({
    query: '',
    matches: [],
  });

  useEffect(() => {
    setQueryState((prev) => {
      const text =
        typeof props.value === 'string' ? props.value : props.value?.text;

      if (text === prev.query) return prev;
      const commit =
        typeof props.value === 'string' ? false : props.value?.commit;
      const query = text || '';
      return {
        query,
        matches: commit
          ? []
          : tagTrie.getMatches(query, {
              limit: SUGGESTION_LIMIT,
            }),
      };
    });
  }, [props.value, tagTrie]);

  const searchThrottled = useThrottled({
    delay: 2000,
    callback: (query: string) => {
      onChanged && onChanged(query);
    },
    skipLeading: false,
  });

  const updateQuery = useCallback(
    (query: string, justAddedTag = false) => {
      // reset matchPointer
      setMatchPointer(null);

      searchThrottled(query);

      // if a tag was just added, wait for new input to add suggestions for
      if (justAddedTag) {
        return setQueryState({
          query,
          matches: [],
        });
      }

      const queryMatches = tagTrie.getMatches(query, {
        limit: SUGGESTION_LIMIT,
      });
      const queryTokens = tokenize(query);
      const lastToken = queryTokens.pop();

      // if there are no matches for the whole query, add matches
      // for the last token in the query
      if (queryMatches.length < SUGGESTION_LIMIT) {
        if (lastToken) {
          const tailMatches = tagTrie
            .getMatches(lastToken, {
              limit: SUGGESTION_LIMIT,
            })
            // filter out duplicates
            .filter(
              ({ tag: newTag }) =>
                !queryMatches.find(({ tag }) => newTag === tag)
            )
            // make sure to keep limit
            .slice(0, SUGGESTION_LIMIT - queryMatches.length)
            .map((tag) => ({
              ...tag,
              isTail: true,
            }));
          queryMatches.push(...tailMatches);
        }
      }

      // add the prefix query for each tag
      const prefixedMatches = queryMatches.map(({ tag }) => {
        const preQueryTokens = getRestOfQueryForTag(query, tag);
        const lastToken = preQueryTokens.pop();
        const prefix = getPrefix(lastToken, preQueryTokens);

        return {
          tag,
          prefix,
        };
      });

      setQueryState({
        query,
        matches: prefixedMatches,
      });
    },
    [tagTrie, searchThrottled]
  );

  const onSelectOption = useCallback(
    ({ tag }: { tag: string }): void => {
      const queryTokens = getRestOfQueryForTag(queryState.query, tag);

      updateQuery(
        `${queryTokens.length ? `${queryTokens.join(' ')} ` : ''}${tag}`,
        true
      );
    },
    [updateQuery, queryState.query]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<TextInputElement>) => {
      if (event.keyCode === ARROW_UP || event.keyCode === ARROW_DOWN) {
        event.preventDefault();
        const direction = event.keyCode === ARROW_UP ? -1 : +1;
        setMatchPointer((prevPointer) => {
          const newPointer = prevPointer === null ? 0 : prevPointer + direction;
          const positivePointer = newPointer + queryState.matches.length;
          return positivePointer % queryState.matches.length;
        });
        return;
      }

      if (event.keyCode === KEY_ENTER || event.key === 'Enter') {
        if (matchPointer !== null && queryState.matches.length) {
          onSelectOption(queryState.matches[matchPointer]);
        } else {
          // submit current query
          updateQuery(queryState.query, true);
        }
        return;
      }

      updateQuery(queryState.query, false);
    },
    [updateQuery, matchPointer, onSelectOption, queryState]
  );

  const handleTextChanged = useCallback(() => {
    // don't trigger changes when matches are shown
    if (queryState.matches.length && matchPointer !== null) return;
    onChanged && onChanged(queryState.query);
  }, [onChanged, queryState, matchPointer]);

  const options = queryState.matches.map(({ tag, prefix }) => {
    const label = `${prefix ? `${prefix} ` : ''}${tag}`;
    return {
      id: tag,
      label,
    };
  });

  return (
    <Wrapper theme={theme}>
      <TextInput
        value={queryState.query}
        placeholder={props.placeholder}
        icon={props.icon}
        iconAtEnd={props.iconAtEnd}
        theme={theme}
        disabled={props.disabled}
        blurOnEnter={false}
        onChanging={updateQuery}
        onEnter={handleTextChanged}
        onKeyDown={onKeyDown}
      />
      <Dropdown
        small={false}
        compact={false}
        options={options}
        expanded={!!queryState.matches.length}
        activeOption={matchPointer !== null ? options[matchPointer] : undefined}
        onOptionClick={(option): void =>
          onSelectOption({ tag: option.id as string })
        }
        onMouseEnterOption={(option): void =>
          setMatchPointer(options.findIndex((o) => o.id === option.id))
        }
        customStyles={{ width: '100%' }}
      />
    </Wrapper>
  );
};

AutoSuggest.defaultProps = {
  value: '',
  tags: [],
  placeholder: '',
  icon: 'search',
  iconAtEnd: true,
};

export default AutoSuggest;
