import React, { ReactElement, useEffect, useRef } from 'react';

import ElementGallery from '../ElementGallery/ElementGallery';
import { FlexColumn } from '../utilities/styles';
import { PromptStyleGroup } from '../../types';
import { Element } from './StyleElement/types';

interface ExtensionPanelProps {
  groups: PromptStyleGroup[];
  activeGroupId?: number;
  renderImageStyleElement: (element: Element) => ReactElement;
}

const getGroupAnchorClassName = (id: number): string => `group-${id}`;

const ExtensionPanel: React.FC<ExtensionPanelProps> = ({
  groups,
  activeGroupId,
  renderImageStyleElement,
}) => {
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (activeGroupId && wrapperRef.current) {
        const activeGroupAnchor = wrapperRef.current.getElementsByClassName(
          getGroupAnchorClassName(activeGroupId)
        )?.[0];
        if (activeGroupAnchor) {
          activeGroupAnchor.scrollIntoView({ behavior: 'auto' });
        }
      }
    }, 0);
  }, [activeGroupId]);

  return (
    <FlexColumn gap="12px" ref={wrapperRef}>
      {groups.map((group) => (
        <div className={getGroupAnchorClassName(group.id)}>
          <ElementGallery
            key={group.id}
            labelLeft={group.name.toUpperCase()}
            render={renderImageStyleElement}
            elements={group.styles}
            collapsed={false}
            dimensions={{
              width: '300px',
              minColumnWidth: 85,
              heightExpanded: 'unset',
            }}
            equalDimensions
            hideCollapseToggleLabel
          />
        </div>
      ))}
    </FlexColumn>
  );
};

export default React.memo(ExtensionPanel);
