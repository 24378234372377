import React from 'react';

import { Benefit, BottomContent, Brands, Wrapper, Content } from './styles';

import Icon from '../../Icon/Icon';
import Spacer from '../../Spacer/Spacer';
import { H2, P2 } from '../../utilities/Typography/styles';

import { themeStyle } from '../../../services/theming';

const benefits = [
  'Unlimited free and premium content',
  'Create stunning graphics with just a few clicks',
  'No professional experience required',
  'Use professional graphic templates worth more than $10.000',
];

const RightColumn = () => {
  return (
    <Wrapper>
      <Content>
        <H2 color={themeStyle.inkBase}>
          Create Stunning Graphics, Fast & Easy.
        </H2>
        <Spacer h={'48px'} />
        <BottomContent>
          {benefits.map((benefit, index) => (
            <Benefit key={index}>
              <Icon
                name={'check'}
                height={'18px'}
                theme={{ color: themeStyle.brandMain }}
              />
              <Spacer w={'10px'} />
              <P2 color={themeStyle.inkBase}>{benefit}</P2>
            </Benefit>
          ))}
          <Spacer h={'52px'} />
          <Brands src={'/images/brands.png'} />
        </BottomContent>
      </Content>
    </Wrapper>
  );
};

export default RightColumn;
