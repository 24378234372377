import paper from '@kittl/paper';
import { wrapLineOfGlyphs } from './textWrap';
import {
  getGlyphAlignment,
  fixPathOrientation,
  STANDARD_UNITS_PER_EM,
} from './util';

const defaultGetPathOptions = {
  text: 'Test',
  width: undefined,
  singleline: false,
  fontSize: 72,
  letterSpacing: 0,
  lineHeight: 400,
  textAlignment: 'center',
};

/* Returns multiple paths for glyphs of a given text, supports letter spacing, multiline and text alignment
 * This is structured in three steps:
 * 1. Calculate baseline positions of glyphs
 * 2. Add text alignment to the positions
 * 3. Generate svg paths for glyphs and underline
 */
export const getPaths = (font, options) => {
  const { text, singleline, width, fontSize, letterSpacing, lineHeight } = {
    ...defaultGetPathOptions,
    ...cleanObj(options),
  };

  const layoutOptions = {
    liga: !!options.useLigatures,
  };

  const actualLetterSpacing =
    (letterSpacing * font.unitsPerEm) / STANDARD_UNITS_PER_EM;

  const actualLineHeight =
    (lineHeight * font.unitsPerEm) / STANDARD_UNITS_PER_EM;

  try {
    if (font.variationAxes && Object.keys(font.variationAxes).length) {
      font = font.getVariation(options.variation || {});
    }
  } catch (e) {
    // do nothing
  }

  // split text at new line
  const lines = text.split('\n');
  const scale = fontSize / font.unitsPerEm;

  // 1a. calculate glyph positions for each line
  // this is a preparation step to get the baseline positions of all glyphs
  const glyphPositions = lines.map((lineText, lineIndex) => {
    const lineHeightPos = (fontSize + actualLineHeight * scale) * lineIndex;
    const run = font.layout(lineText, layoutOptions);

    // get glyphs
    let advance = 0;
    const glyphs = run.glyphs.map((g, i) => {
      const { xAdvance } = run.positions[i];
      const glyphObj = {
        g,
        x: advance * scale,
        y: lineHeightPos,
        width: xAdvance * scale,
      };
      advance += xAdvance + actualLetterSpacing;
      return glyphObj;
    });

    return {
      width: advance * scale,
      glyphs,
    };
  });

  // 1b. split lines that are too long
  let splitGlyphPositions = glyphPositions;
  if (!singleline && width !== undefined) {
    // get glyph ids
    const GLYPH_SPACE = font.layout(' ').glyphs[0]?.id;
    const GLYPH_DASH = font.layout('-').glyphs[0]?.id;

    splitGlyphPositions = glyphPositions
      .reduce((acc, line) => {
        if (line.width <= width) {
          return [...acc, line];
        } else {
          // wrap text in line to fit to width
          const wrappedLines = wrapLineOfGlyphs(line.glyphs, width, {
            GLYPH_SPACE,
            GLYPH_DASH,
          });

          return [...acc, ...wrappedLines];
        }
      }, [])
      .map((line, lineIndex) => ({
        // update y to new lineIndex
        ...line,
        glyphs: line.glyphs.map((g) => ({
          ...g,
          y: (fontSize + actualLineHeight * scale) * lineIndex,
        })),
      }));
  }

  // 2. calculate the aligned glyph positions, by adjusting positions based on the diff to the width
  const targetWidth =
    !singleline && width !== undefined
      ? width
      : Math.max(...glyphPositions.map((line) => line.width)); // fallback to maxLineWidth
  const alignedGlyphPositions = splitGlyphPositions.map((line) => {
    // get properties to align glyphs by
    const { leftOffset, spaceBetween } = getGlyphAlignment(
      line.width,
      targetWidth,
      line.glyphs?.length,
      options.textAlignment
    );

    const newGlyphPositions = line.glyphs.map((glyph, index) => {
      return {
        ...glyph,
        x: glyph.x + leftOffset + spaceBetween * index,
      };
    });

    return {
      ...line,
      glyphs: newGlyphPositions,
    };
  });

  let minX;
  let minY = 0;
  let maxX = 0;
  let maxY = 0;

  // 3. get svg paths for glyphs, based on the previously calculated positions
  const paths = alignedGlyphPositions.reduce((acc, line, lineIndex) => {
    // get glyphs svg paths
    const linePaths = line.glyphs.map((glyph) => {
      const gCbox = glyph.g.cbox;
      const advance = glyph.x / scale;
      if (typeof minX === 'undefined') minX = (gCbox.minX + advance) * scale;
      minX = Math.min(minX, (gCbox.minX + advance) * scale);
      minY = Math.min(minY, gCbox.minY * scale - glyph.y);
      maxX = Math.max(maxX, (gCbox.maxX + advance) * scale);
      maxY = Math.max(maxY, gCbox.maxY * scale - glyph.y);
      const p = glyph.g.path
        // This does scaling and translation in one transform
        .transform(scale, 0, 0, -scale, glyph.x, glyph.y)
        .toSVG();
      if (options.fixOrientation) {
        const compPath = new paper.CompoundPath({ pathData: p, insert: false });
        fixPathOrientation(compPath);
        return compPath.pathData;
      }
      return p;
    });

    // add underline
    if (options.underline && line.glyphs.length) {
      const firstGlyph = line.glyphs[0];
      const lastGlyph = line.glyphs[line.glyphs.length - 1];
      const lineHeightPos = lastGlyph.y; // lineHeightPos is the y of all glyphs on a line
      const { underlinePosition, underlineThickness } = font;
      const underlineWidth = scale * underlineThickness;
      const underlineY = scale * -underlinePosition + lineHeightPos;
      // start from the leftmost position of the first glyph
      const underlineFirstX = firstGlyph.x + firstGlyph.g.cbox.minX * scale;
      // find rightmost position of the last glyph
      const underlineLastX = lastGlyph.x + lastGlyph.g.cbox.maxX * scale;

      const underline = `M${underlineFirstX} ${
        underlineY - underlineWidth / 2
      }L${underlineFirstX} ${
        underlineY + underlineWidth / 2
      }L${underlineLastX} ${
        underlineY + underlineWidth / 2
      }L${underlineLastX} ${underlineY - underlineWidth / 2}Z`;
      linePaths.push(underline);
    }

    return [...acc, ...linePaths];
  }, []);

  return { paths, bounds: { minX, maxX, minY, maxY }, targetWidth };
};

const cleanObj = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((prop) => {
    typeof newObj[prop] === 'undefined' && delete newObj[prop];
  });
  return newObj;
};
