import {
  renderPoints,
  renderPoint,
  renderFreeLine,
} from '../../../../utils/dev/fabric';
import {
  getControls,
  getRelativeTransformPoints,
  getTransformControls,
} from '../transformConfig';
import {
  adjustRelativePoints,
  reflectPoints,
  rotatePointsAroundSinglePoint,
} from '../../../../utils/editor/points';
import { getAbsolutePointsOnObject } from '../../../../utils/editor/objects';
import { rotationOffset } from '../../../../utils/editor/angle';
import { isCompatibleTouchDevice } from '../../../../utils/detection';

export default function (fabric) {
  fabric.util.object.extend(fabric.PathText.prototype, {
    /**
     * Toggle editing mode
     * @param {*} reset - whether we should reset control points, if true always enables editing
     */
    toggleEdit(reset) {
      const options = this.transformOptions;
      if (reset) {
        // reset FreeLine path to the default curve
        const options = this.transformOptions;
        this.setEditMode(options, reset);
        return;
      }
      if (!this.inPathEditMode) {
        // add a boolean flag to the optional options argument we pass to setTransform
        const justEnabledFreeLineEdit = true;
        this.setTransform(options, { justEnabledFreeLineEdit });
      } else {
        this.setEditMode(false);
      }
    },

    /**
     * Translate the PathText object according to the position of the points and the control vector
     * The control vector is the offset between the firstPoint and the controlPoint corner of the
     * generated text path.
     */
    translateToControlPoint() {
      if (!this.transformOptions?.type) return;
      if (!this._controlVector || !this.points) return;

      // `this._controlVector` is calculated based on a non-rotated object, but points and
      // top left are affected by angle and flip, so this needs to be applied to it.
      const offset = rotationOffset(
        {
          x: (this.flipX ? -1 : 1) * this._controlVector.x,
          y: (this.flipY ? -1 : 1) * this._controlVector.y,
        },
        this.angle
      );

      const firstPoint = this.points[0];

      // place this object based on the firstPoint and the controlVector
      this.top = firstPoint.y - offset.y;
      this.left = firstPoint.x - offset.x;

      // store current position, to later know how to move points
      this.updateLastPos();
    },

    /**
     * The center point of the actual text
     * This can differ from the center of the object,
     * when using adjustable width in a single line
     */
    getTextCenterPoint() {
      const useAdjustableWidth = !this.singleline && this.width !== undefined;
      if (
        useAdjustableWidth &&
        ['left', 'right'].includes(this.textAlignment)
      ) {
        const pathDims = this.pathText._calcDimensions();
        const relativeCenter =
          this.textAlignment === 'left'
            ? pathDims.width / this.width / 2
            : 1 - pathDims.width / this.width / 2;
        return getAbsolutePointsOnObject(
          [{ x: relativeCenter, y: 0.5 }],
          this.pathText
        )[0];
      }

      return this.getAbsoluteCenterPoint();
    },

    /**
     * Adjust the this object to have the following center point.
     * This is used to keep the text in position when enabling and
     * disabling a transform.
     * @param {{x: Number, y: Number}} previousCenter
     */
    adjustToCenter(center) {
      // keep same center position for untransformed text
      const newCenter = this.getTextCenterPoint();
      const diffX = center.x - newCenter.x;
      const diffY = center.y - newCenter.y;
      this.set({
        top: this.top + diffY,
        left: this.left + diffX,
      });
      this.handleOnMove();
    },

    /**
     * Flip the object and its associated points
     * @param {String} direction - if the object should be reflected horizontal (xAxis) or vertical (yAxis)
     */
    reflect(direction = 'horizontal') {
      const flipKey = direction === 'horizontal' ? 'flipX' : 'flipY';
      const originalFlipValue = this.get(flipKey);
      this.set(flipKey, !originalFlipValue);
      this.handleFlip();

      // also reflect angle
      this.rotate(-this.angle);
      this.handleRotation(this);
      this.updateLastAngle();

      this.updateShadowsAndDecorations();
    },

    /**
     * Creates free line control points
     * @param {Boolean} reset if the width should be reset
     */
    createFreeLinePoints(reset) {
      // get list of relative points to start a freeLine from
      const relativePoints = getRelativeTransformPoints('freeLine');
      const adjustedPoints = adjustRelativePoints(relativePoints, {
        width: this.width,
        alignment: this.textAlignment,
        adjustedWidth: reset ? this.width : this.adjustableWidth,
      });
      // make points absolute
      const absolutePoints = getAbsolutePointsOnObject(adjustedPoints, this);

      // offset points to have them based at xHeight
      const { minY } = this._bounds;
      const halfXHeight = (this.xHeight * this.fontSize) / 2;
      const offset = rotationOffset(
        { x: 0, y: minY - halfXHeight },
        this.angle
      );
      const freeLinePoints = absolutePoints.map(({ x, y }) => {
        return {
          x: x + offset.x,
          y: y + offset.y,
        };
      });
      this.setPoints(freeLinePoints);
    },

    /**
     * Creates points for controls of warp transforms
     * @param {String} type warp transform type
     * @param {Boolean} reset if the width should be reset
     */
    createWarpPoints(type, reset) {
      // get list of relative points to initiate a warp transform
      const relativePoints = getRelativeTransformPoints(type);
      const adjustedPoints = adjustRelativePoints(relativePoints, {
        width: this.width,
        alignment: this.textAlignment,
        adjustedWidth: reset ? this.width : this.adjustableWidth,
      });
      // make points absolute
      const absolutePoints = getAbsolutePointsOnObject(adjustedPoints, this);

      this.setPoints(absolutePoints);
      this.adjustPointsToCurve(80, 80);
    },
    /**
     * Creates circle control points
     */
    createCirclePoints() {
      // create a circle with a radius of width/2
      // position points so that center top stays at the same position after the transform is applied
      const relativeHeightRadius = this.width / (2 * this.height); // = (this.width / 2) / this.height
      const relativePoints = [
        { x: 0, y: relativeHeightRadius }, // left
        { x: 0.5, y: 0 }, // top
        { x: 0.5, y: 2 * relativeHeightRadius }, // bottom
        { x: 1, y: relativeHeightRadius }, // right
      ];
      const absolutePoints = getAbsolutePointsOnObject(relativePoints, this);
      const { minY } = this._bounds;
      const halfXHeight = (this.xHeight * this.fontSize) / 2;
      const offset = rotationOffset(
        { x: 0, y: minY - halfXHeight },
        this.angle
      );
      const circlePoints = absolutePoints.map(({ x, y }) => {
        return {
          x: x - offset.x,
          y: y - offset.y,
        };
      });
      this.setPoints(circlePoints);
    },

    createFreeFormPoints() {
      // get list of relative points to initiate a freeForm transform
      const relativePoints = getRelativeTransformPoints('freeForm');
      // make points absolute
      const absolutePoints = getAbsolutePointsOnObject(relativePoints, this);

      this.setPoints(absolutePoints);
    },

    /**
     * Enables/disables free line transform
     * @param {*} options - transform options
     * @param {*} reset - whether controls should be re-created
     */
    setEditMode(options, reset) {
      const previouslyInEdit = this.inPathEditMode;
      this.inPathEditMode = !!options;
      if (this.inPathEditMode) {
        const previousTransform = this.transformOptions?.type;
        // if previous transform was of a different type we need to recreate points
        const doReset = reset || previousTransform !== options.type;
        // define edit points if they don't exist
        if (!this.points || doReset) {
          const center = this.getTextCenterPoint();
          this.set('singleline', true);
          // updateText to reset text to default size to initialize points from
          this.set('transformOptions', {});
          this.updateText(() => {
            // update transformOptions
            this.set('transformOptions', {
              ...options,
              curve: 80,
            });

            // create points based on transformType
            if (options.type === 'freeLine') {
              this.createFreeLinePoints(reset);
            } else if (options.type === 'circle') {
              this.createCirclePoints();
            } else if (options.type === 'freeForm') {
              this.createFreeFormPoints();
            } else {
              this.createWarpPoints(options.type, reset);
            }

            this.updateTransformControls();

            // update text, to render path based on newly generated points
            this.updateText(() => {
              // keep same center position for newly transformed text
              this.adjustToCenter(center);
              if (reset) {
                // reset adjustableWidth. This is important for when the transform is
                // disabled right after it was reseted
                this.adjustableWidth = undefined;
              }
              this._callModified();
            }, false);
          }, false); // This is false only to support singleline rendering
        } else {
          // update transformOptions
          this.set('transformOptions', { ...options, points: this.points });
          this.updateTransformControls();

          // update text, to render path based on changed transformOptions and points
          this.updateText(() => {
            this._callModified();
          }, false);
        }
      } else if (previouslyInEdit) {
        this._callModified();
      }
      this.updateTransformControls();
    },

    /**
     * update the controls of pathText based on the current edit mode and active transform
     * this handles the change of controls between transform and non-transform
     */
    updateTransformControls: function () {
      const transformType = this.transformOptions?.type;
      if (this.inPathEditMode && transformType) {
        this.cornerStyle = 'circle';
        this.hasBorders = false;

        // add controls for editing the current transform
        const transformControls = getTransformControls(transformType);
        this.controls = this.points.reduce(
          (acc, point, index) => {
            acc['p' + index] = transformControls.point(fabric, index, this);
            return acc;
          },
          { l: transformControls.line(fabric) }
        );

        const controls = getControls(transformType);
        this.controls = {
          ...this.controls,
          ...controls,
          alert: this.alertControl,
        };
      } else {
        // reset to normal controls
        this.hasBorders = true;
        this.cornerStyle = 'square';
        this.resetControls(true);
      }
    },

    adjustPointsToCurve: function (curve, previousCurve) {
      if (!this.points?.length) return;

      // curve = 0 would remove relative y offsets between points, so at curve = 0, we transform actually at 0.00001
      curve = curve || 0.00001;
      previousCurve = previousCurve || 0.00001;
      const switchDirection = Math.sign(curve) !== Math.sign(previousCurve);
      const curveFactor = curve / 100;

      // remove object transforms, apply curve transform and restore objects transformation to points
      const transform = this.calcTransformMatrix();
      const inverseTransform = fabric.util.invertTransform(transform);
      const resetedPoints = this.points.map((p) =>
        fabric.util.transformPoint(p, inverseTransform)
      );

      const pointsY = resetedPoints.map(({ y }) => y);
      const minY = Math.min(...pointsY);
      const maxY = Math.max(...pointsY);
      const centerY = minY + (maxY - minY) / 2;

      // flat points don't have curve information
      if (maxY === minY) return;

      const adjustedPoints = resetedPoints.map(({ x, y }) => {
        const relativeOffset = (y - centerY) / (maxY - minY);
        return {
          x,
          y:
            centerY +
            relativeOffset *
              // maximum y difference between points should be fontSize
              this.fontSize *
              // sign of curveFactor information is already part of relativeOffset -> abs
              Math.abs(curveFactor) *
              // in case of sign change in curve, relativeOffset must be inverted
              (switchDirection ? -1 : 1),
        };
      });

      const restoredPoints = adjustedPoints.map((p) =>
        fabric.util.transformPoint(p, transform)
      );

      this.setPoints(restoredPoints);
    },

    getCurveFactorFromPoints: function () {
      if (!this.points?.length) return;

      // remove object transforms, apply curve transform and restore objects transformation to points
      const transform = this.calcTransformMatrix();
      const inverseTransform = fabric.util.invertTransform(transform);
      const resetedPoints = this.points.map((p) =>
        fabric.util.transformPoint(p, inverseTransform)
      );

      const pointsY = resetedPoints.map(({ y }) => y);
      const minY = Math.min(...pointsY);
      const maxY = Math.max(...pointsY);

      return Math.round(((maxY - minY) / this.fontSize) * 100);
    },

    /**
     * get transformOptions of this object that have no angle transform applied
     */
    getNeutralTransformOptions: function () {
      const transformOptions = { ...this.transformOptions };
      if (transformOptions.points) {
        transformOptions.points = rotatePointsAroundSinglePoint(
          transformOptions.points,
          this.getAbsoluteCenterPoint(),
          this.angle,
          0
        );
      }
      return transformOptions;
    },

    /**
     * get all pathText options that are needed to apply a transform
     */
    getApplyTransformOptions: function () {
      return {
        flipX: this.flipX,
        flipY: this.flipY,
        bounds: this._bounds,
        underline: this.underline,
        textAlignment: this.textAlignment,
        xHeight: this.xHeight * this.fontSize,
      };
    },

    /**
     * Handle implications of a moved event
     * Update shadows, decorations, `points` and `lastPos` according to movement.
     */
    handleOnMove: function () {
      const newPos = this.getAbsoluteTopLeft();
      if (this.lastPos && this.points) {
        const diffX = newPos.left - this.lastPos.left;
        const diffY = newPos.top - this.lastPos.top;
        const updatedPoints = this.points.map((point) => {
          return {
            x: point.x + diffX,
            y: point.y + diffY,
          };
        });
        this.setPoints(updatedPoints);
      }
      this.lastPos = newPos;

      this.updateShadowsAndDecorations();
    },

    handleOnScale: function () {
      const { scale: newScale } = this.getTextScale();
      if (this.points) {
        // this flag indicates that pathText was scaled without that being
        // factored in in updateText. Will be set to false on the next updateText
        this._pathTextWasChangedAfterUpdate = true;

        const relativePoint = this.lastPos;
        const scaleFactor = (1 / this.lastScale) * newScale; // undo last scale and apply new scale
        const scalePoint = (point) => ({
          x: relativePoint.left + (point.x - relativePoint.left) * scaleFactor, // scale offset of point
          y: relativePoint.top + (point.y - relativePoint.top) * scaleFactor, // scale offset of point
        });
        this.setPoints(this.points.map(scalePoint));
        this.handleOnMove(); // scaling can also move the top/left coordinates of the object
      }
      this.lastScale = newScale;
    },

    handleFlip: function () {
      const newFlip = { x: this.flipX, y: this.flipY };
      const flipOnX = newFlip.x !== this.lastFlip.x;
      const flipOnY = newFlip.y !== this.lastFlip.y;

      if (this.points && (flipOnX || flipOnY)) {
        const { tl, tr, br, bl } = this.getAbsoluteCoords();
        let updatedPoints = this.points;

        // this flag indicates that pathText was flipped without that being
        // factored in in updateText. Will be set to false on the next updateText
        this._pathTextWasChangedAfterUpdate = true;

        if (flipOnX) {
          // flip points horizontally
          const start = {
            x: bl.x + (br.x - bl.x) / 2,
            y: bl.y + (br.y - bl.y) / 2,
          };
          const end = {
            x: tl.x + (tr.x - tl.x) / 2,
            y: tl.y + (tr.y - tl.y) / 2,
          };
          updatedPoints = reflectPoints(updatedPoints, start, end);
        }

        if (flipOnY) {
          // flip points vertically
          const start = {
            x: bl.x + (tl.x - bl.x) / 2,
            y: bl.y + (tl.y - bl.y) / 2,
          };
          const end = {
            x: br.x + (tr.x - br.x) / 2,
            y: br.y + (tr.y - br.y) / 2,
          };
          updatedPoints = reflectPoints(updatedPoints, start, end);
        }

        this.setPoints(updatedPoints);
      }
      this.lastFlip = newFlip;
    },

    handleRotation: function (rotatingObject, isAltKey, transformOrigin) {
      if (this.points) {
        if (!transformOrigin) {
          transformOrigin = isAltKey
            ? rotatingObject.getAbsoluteTopLeftPoint()
            : rotatingObject.getAbsoluteCenterPoint();
        }

        const updatedPoints = rotatePointsAroundSinglePoint(
          this.points,
          transformOrigin,
          rotatingObject.lastAngle,
          rotatingObject.angle
        );
        this.setPoints(updatedPoints);
      }

      this.updateLastPos(); // update lastPos for later movements
      this.updateLastAngle();
    },

    updateLastPos: function () {
      this.lastPos = this.getAbsoluteTopLeft();
    },

    updateLastFlip: function () {
      this.lastFlip = { x: this.flipX, y: this.flipY };
    },

    updateLastAngle: function () {
      this.lastAngle = this.angle;
    },

    updateLastScale: function () {
      const { scale } = this.getTextScale();
      this.lastScale = scale;
    },

    /**
     * update points data that is used for transforms
     */
    setPoints: function (points) {
      this.points = points; // used for rendering controls
      this.transformOptions.points = points; // used for history and text rendering

      this.adjustAdjustableWidthToPoints();
    },

    /* Snapshot points in lastPoints. Used for control axis locking when pressing shift */
    setLastPoints: function () {
      this.lastPoints = [...this.points];
    },

    /**
     * adjust the current adjustableWidth of text to the horizontal
     * distance between the transform points
     */
    adjustAdjustableWidthToPoints: function () {
      if (
        !this.transformOptions?.type ||
        this.transformOptions.type === 'circle' ||
        this.transformOptions.type === 'freeForm'
      ) {
        return;
      }

      // get points without rotation
      const neutralOptions = this.getNeutralTransformOptions();
      if (neutralOptions.points) {
        // keep width between points
        const xCoordinates = neutralOptions.points.map((point) => point.x);
        const horizontlDistance =
          Math.max(...xCoordinates) - Math.min(...xCoordinates);
        this.adjustableWidth = horizontlDistance;
      }
    },

    /**
     * render current points as a utility for development
     */
    renderDevUtils: function (ctx) {
      if (this.canvas?.devSettings?.displayPoints && this.points?.length) {
        renderPoints(ctx, this.points);
        if (this.transformOptions?.type === 'freeLine') {
          renderFreeLine(ctx, this.points);
        }
        renderPoint(ctx, { x: this.left, y: this.top }, 'orange');
      }
    },

    /**
     * reset the pathText/transform specific controls based on the parameter showOverlays
     * and the current transform options
     * @param {Boolean} showOverlays if true, overlays like the line and alert are displayed as well
     */
    resetControls(showOverlays) {
      const transformType = this.transformOptions?.type;
      const transformControls = getTransformControls(transformType);

      let overlays =
        (showOverlays &&
          transformType &&
          transformType !== 'freeForm' && {
            l: transformControls.line(fabric, true),
            alert: this.alertControl,
          }) ||
        {};

      if (transformType && !isCompatibleTouchDevice()) {
        this.editTransformControl.updateOffsets(this);
        overlays = {
          editTransform: this.editTransformControl,
          ...overlays,
        };
      }

      this.controls = {
        ...fabric.Object.prototype.controls,
        ...overlays,
      };
    },
  });
}
