/**
 * Interfaces/ Terminology
 *
 * glyph: A glyph object contains data on the positioning and representation of a glyph
 *        that was extracted from a font.
 *        {
 *          {Number} x      - the left offset of the glyph from the start of the text
 *          {Number} width  - the width of the glyph
 *          {Object} g      - the glyph data for rendering it (includes an id)
 *        }
 * glyphs: An array of multiple glyph objects.
 * group: An object grouping multiple glyphs. The x offset of each glyph is computed from the
 *        start of the group and groups usually also have a width, which is an aggregation of
 *        all contained glyphs. Also named line, word, or token.
 *        {
 *          {Number} width  - width of the whole group
 *          {Array} glyphs  - array of multiple glyph objects
 *        }
 *
 * Text is generally wrapped by splitting text into smaller tokens and then joining these tokens
 * together again. In case a single token does not fit the specified target width. It is split up
 * and joined at a more granular level again.
 *
 * 1. Split/Join on white spaces
 * 2. Split/Join on separation token (dash)
 * 3. Split/Join individual glyphs
 */

const TARGET_WIDTH_DELTA = 1e-8;

/**
 * wraps a list of glyphs into multiple lines/lists, depending on the width
 * @param {*} glyphs list of glyph objects
 * @param {*} targetWidth a target width to wrap to
 * @param {*} fontAttributes attributes of the font that are needed for wrapping
 */
export const wrapLineOfGlyphs = (glyphs, targetWidth, fontAttributes) => {
  const { GLYPH_SPACE, GLYPH_DASH } = fontAttributes;

  // break line into words
  const words = splitGlyphs(glyphs, GLYPH_SPACE);

  // join words that fit into a single line and wrap words that do not
  const subLines = joinWordsIntoLines(words, targetWidth, GLYPH_DASH);

  // return clean lines
  return subLines.reduce((acc, line) => {
    const glyphs = line.glyphs;
    let width = line.width;
    // remove trailing spaces
    while (glyphs.length && glyphs[glyphs.length - 1].g.id === GLYPH_SPACE) {
      const removeGlyph = glyphs.pop();
      width -= removeGlyph.width;
    }

    if (glyphs.length) {
      return [...acc, { width, glyphs }];
    }
    return acc;
  }, []);
};

/**
 * Split a list of glyphs into multiple lists. Separator is appended to the previous token.
 * Resets glyphs x position relative to the start of the token. Each resulting token is returned
 * as a set of glyphs and its width.
 * @param {Array} glyphs
 * @param {String} separator
 */
const splitGlyphs = (glyphs, separator) => {
  const newTokens = [];
  let newTokenGlyphs = [];
  let tokenStart = 0; // start of the current token in comparison to the original line
  let tokenWidth = 0;
  while (glyphs.length) {
    const nextGlyph = glyphs.shift();
    // actual width is the space between nextGlyph and the previous one + nextGlyphs width
    const actualWidth =
      nextGlyph.x - (tokenStart + tokenWidth) + nextGlyph.width;
    nextGlyph.x = nextGlyph.x - tokenStart;
    tokenWidth += actualWidth;
    newTokenGlyphs.push(nextGlyph);
    // if the current glyph indicates the end of a token
    if (nextGlyph.g.id === separator || !glyphs.length) {
      newTokens.push({ width: tokenWidth, glyphs: newTokenGlyphs });
      newTokenGlyphs = [];
      tokenStart += nextGlyph.x + nextGlyph.width;
      tokenWidth = 0;
    }
  }
  return newTokens;
};

/**
 * join previously split groups to fit to a targetWidth
 * @param {Array} groups
 * @param {Number} targetWidth
 * @param {Function} handleLongToken
 */
const joinGlyphGroups = (groups, targetWidth, handleLongToken) => {
  const newGroups = [];

  // function to push a new line to the stack
  const pushNewLine = (glyphs, width) => {
    if (TARGET_WIDTH_DELTA <= targetWidth - width) {
      newGroups.push({ width, glyphs });
    } else {
      // this means a new line contains a single group that is larger than targetWidth
      // so it should be split up further
      const subLines = handleLongToken(glyphs);
      newGroups.push(...subLines);
    }
  };

  let newGroupGlyphs = [];
  let groupWidth = 0;
  while (groups.length) {
    const nextGroup = groups.shift();
    const newWidth = groupWidth + nextGroup.width;
    // if the group can't fit the new glyphs and contains already some glyphs, it is added to newGroups
    if (
      newWidth - targetWidth > TARGET_WIDTH_DELTA &&
      newGroupGlyphs.length !== 0
    ) {
      pushNewLine(newGroupGlyphs, groupWidth);
      newGroupGlyphs = [];
      groupWidth = 0;
    }

    const additionalWidth = groupWidth;
    groupWidth += nextGroup.width;
    newGroupGlyphs.push(
      ...nextGroup.glyphs.map((g) => ({ ...g, x: g.x + additionalWidth }))
    );
  }
  pushNewLine(newGroupGlyphs, groupWidth);

  return newGroups;
};

/**
 * Split a list of glyphs into lines
 * @param {Array} glyphs
 * @param {Number} targetWidth
 */
const splitGlyphsIntoLines = (glyphs, targetWidth) => {
  const newLines = [];
  let newLineGlyphs = [];
  let lineStart = 0; // start of the current newLine in comparison to the original single lines
  let lineWidth = 0;
  while (glyphs.length) {
    const nextGlyph = glyphs.shift();
    // actual width is the space between nextGlyph and the previous one + nextGlyphs width
    const actualWidth = nextGlyph.x - (lineStart + lineWidth) + nextGlyph.width;
    // if the line can't fit the new glyph and contains already some glyphs, it is added to subLines
    if (
      lineWidth + actualWidth - targetWidth > TARGET_WIDTH_DELTA &&
      newLineGlyphs.length !== 0
    ) {
      newLines.push({
        width: lineWidth,
        glyphs: newLineGlyphs,
      });
      newLineGlyphs = [];
      lineStart = nextGlyph.x;
      lineWidth = 0;
    }
    nextGlyph.x = nextGlyph.x - lineStart;
    lineWidth += actualWidth;
    newLineGlyphs.push(nextGlyph);
  }
  return [...newLines, { width: lineWidth, glyphs: newLineGlyphs }];
};

/**
 * handle wrapping a single word
 * @param {Object} word
 * @param {Number} targetWidth
 */
const splitSingleWord = (word, targetWidth, dashGlyphId) => {
  // split single words that are too long into tokens
  const tokens = splitGlyphs(word.glyphs, dashGlyphId);

  const handleTooLongTokens = (glyphs) =>
    splitGlyphsIntoLines(glyphs, targetWidth);
  return joinGlyphGroups(tokens, targetWidth, handleTooLongTokens);
};

/**
 * Joins a set of words into lines with a targetWidth
 * @param {Array} words
 * @param {Number} targetWidth
 */
const joinWordsIntoLines = (words, targetWidth, dashGlyphId) => {
  const splitTooLongWords = (glyphs) =>
    splitSingleWord({ glyphs }, targetWidth, dashGlyphId);

  return joinGlyphGroups(words, targetWidth, splitTooLongWords);
};
