import React from 'react';
import PropTypes from 'prop-types';

import { ADD_TEXTURE } from '../../../global/events';
import BasePanel from '../../LeftMenu/BasePanel/BasePanel';
import Element from '../Element/Element';
import ElementsBasePanel from '../ElementBasePanel';
import ElementsDefaultHeader from '../ElementsDefaultHeader';
import SortAndSearch from '../SortAndSearch/SortAndSearch';
import { getRenderBaseProps } from '../util';

const WithoutSortAndSearch = (props) => {
  const { dispatch, transitioning, sort } = props;

  const render = (element) => {
    const { elementProps } = getRenderBaseProps(element, 'textures');

    const onAdd = (val) => {
      dispatch(ADD_TEXTURE, val);
    };

    return <Element element={elementProps} onDrop={onAdd} />;
  };

  const headElement = <ElementsDefaultHeader {...props} />;
  const bodyElement = (
    <ElementsBasePanel
      {...props}
      render={render}
      elementType="textures"
      hide={transitioning}
      sort={sort?.id}
    />
  );

  return (
    <BasePanel
      label={'Textures'}
      onClose={props.onClose}
      headElement={headElement}
      bodyElement={bodyElement}
    />
  );
};

const WithSortAndSearch = (props) => {
  return (
    <SortAndSearch elementType="textures">
      <WithoutSortAndSearch {...props} />
    </SortAndSearch>
  );
};

const propTypes = {
  dispatch: PropTypes.func,
  transitioning: PropTypes.bool,
  sort: PropTypes.object,
  onClose: PropTypes.func,
};

WithoutSortAndSearch.propTypes = propTypes;

WithSortAndSearch.propTypes = propTypes;

export default WithSortAndSearch;
