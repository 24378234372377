import { transformPoints } from '../../../utils/editor/points';
import {
  Theme,
  themeStoreSelector,
  useThemeStore,
} from '../../../services/theming';

const EDIT_TRANSFORM_CONTROL_GAP = 12;

const editTransformImageSize = {
  width: 114,
  height: 30,
};

const initEditTransformControl = function (fabric) {
  const img = {
    [Theme.LIGHT]: new Image(),
    [Theme.DARK]: new Image(),
  };
  img[Theme.LIGHT].src = '/images/editTransformLight.svg';
  img[Theme.DARK].src = '/images/editTransformDark.svg';

  const getPositionData = (fabricObject) => {
    if (!fabricObject.canvas) {
      // if the object has no canvas, it was probably deleted
      return false;
    }

    /* Object angle */
    const angle = fabricObject.angle;

    /* Viewport transform */
    const vpt = fabricObject.canvas.viewportTransform;

    /* Store aCoords in array and convert them to viewport coordinates */
    let aCoords = Object.keys(fabricObject.aCoords).map((key) => {
      const point = fabricObject.aCoords[key];
      return fabric.util.transformPoint(point, vpt);
    });

    /*
    Set initial arbitrary (well, not really, should be aligned on x with object center) center for control.
    We choose center of aCoords just because I make the rules.
    */
    const center = aCoords.reduce((acc, p) => ({
      x: acc.x + p.x,
      y: acc.y + p.y,
    }));
    center.x /= aCoords.length; // ---> 4
    center.y /= aCoords.length; // ---> 4

    /* Halved button dimensions */
    const buttonHalfWidth = editTransformImageSize.width / 2;
    const buttonHalfHeight = editTransformImageSize.height / 2;

    /* Build button corners around control's center */
    let buttonCorners = [
      {
        x: center.x - buttonHalfWidth,
        y: center.y - buttonHalfHeight,
      },
      {
        x: center.x + buttonHalfWidth,
        y: center.y - buttonHalfHeight,
      },
      {
        x: center.x - buttonHalfWidth,
        y: center.y + buttonHalfHeight,
      },
      {
        x: center.x + buttonHalfWidth,
        y: center.y + buttonHalfHeight,
      },
    ];

    /* Get object rotation and inverse */
    const rot = fabric.util.calcRotateMatrix({ angle: angle });
    const invRot = fabric.util.invertTransform(rot);

    /* Now cancel out rotation */
    aCoords = transformPoints(aCoords, invRot);
    buttonCorners = transformPoints(buttonCorners, invRot);

    /* Get (signed) distance between lowest point and top of (un-rotated) object */
    const buttonCornersAllY = buttonCorners.map((p) => p.y);
    const aCoordsAllY = aCoords.map((p) => p.y);
    const buttonCornersLowest = Math.max(...buttonCornersAllY);
    const aCoordsTop = Math.min(...aCoordsAllY);
    const dist = aCoordsTop - buttonCornersLowest;

    /* Move corners vertically so that the closest one to the top of (un-rotated) object is exactly 'gap' pixels above*/
    const gap = EDIT_TRANSFORM_CONTROL_GAP;
    buttonCorners = buttonCorners.map(
      (p) => new fabric.Point(p.x, p.y + dist - gap)
    );

    /* Restore rotation */
    buttonCorners = transformPoints(buttonCorners, rot);
    aCoords = transformPoints(aCoords, rot);

    /* Finally, get center of buttonCorners */
    const buttonCenter = buttonCorners.reduce((acc, p) => ({
      x: acc.x + p.x,
      y: acc.y + p.y,
    }));
    buttonCenter.x /= buttonCorners.length; // <--- 4;
    buttonCenter.y /= buttonCorners.length; // <--- 4;

    /*
    Get offset for control. Fabric should see the control positioned exactly where we draw the button so
    that hovering works properly.
    */
    let offset = {
      x: buttonCenter.x - center.x,
      y: buttonCenter.y - center.y,
    };

    /* Fabric takes object rotation into account, so we cancel that out */
    offset = fabric.util.transformPoint(offset, invRot);

    return {
      offset,
      buttonCenter,
    };
  };

  const editTransformControl = new fabric.Control({
    render: function (ctx, left, top, styleOverride, fabricObject) {
      const data = getPositionData(fabricObject);
      if (!data) return false;

      const { offset, buttonCenter } = data;

      const theme = themeStoreSelector.resolvedTheme(useThemeStore.getState());

      /* Set offset */
      this.offsetX = offset.x;
      this.offsetY = offset.y;

      /* Button dimensions */
      const buttonWidth = editTransformImageSize.width;
      const buttonHeight = editTransformImageSize.height;

      ctx.save();
      ctx.translate(buttonCenter.x, buttonCenter.y);
      ctx.shadowBlur = 25;
      ctx.shadowOffsetY = 4;
      ctx.shadowColor = 'rgba(0,0,0,0.1)';
      ctx.drawImage(
        img[theme],
        -buttonWidth / 2,
        -buttonHeight / 2,
        buttonWidth,
        buttonHeight
      );
      ctx.restore();
    },
    cursorStyle: 'pointer',
    sizeX: editTransformImageSize.width,
    sizeY: editTransformImageSize.height,
    mouseUpHandler: function (eventData, transformData) {
      transformData.target.toggleEdit(false);
    },
    updateOffsets: function (fabricObject) {
      const data = getPositionData(fabricObject);
      if (!data) return false;

      const { offset } = data;
      this.offsetX = offset.x;
      this.offsetY = offset.y;
    },
  });

  fabric.PathText.prototype.editTransformControl = editTransformControl;
};

export default initEditTransformControl;
