import { Point } from '../../types';

/**
 * Get the interpolated point between two given points p1, p2, at ratio a.
 * @param p1 {{ x: number, y: number }}
 * @param p2 {{ x: number, y: number }}
 * @param a {number}
 * @returns {{x: number, y: number}}
 */
export const getInterpolatedPoint = (
  p1: Point,
  p2: Point,
  a: number
): Point => {
  return {
    x: p1.x + a * (p2.x - p1.x),
    y: p1.y + a * (p2.y - p1.y),
  };
};

/**
 * Get the distance between two given points
 * @param p1 {{ x: number, y: number }}
 * @param p2 {{ x: number, y: number }}
 * @returns {number}
 */
export const getDistance = (p1: Point, p2: Point): number =>
  Math.hypot(p1.x - p2.x, p1.y - p2.y);

/**
 * Flips a point along the center axes within bounds
 * @param params {Object}
 * @param params.point {{ x: number, y: number }}
 * @param params.bounds {{ x: number, y: number, width: number, height: number }}
 * @param params.flipX {boolean}
 * @param params.flipY {boolean}
 * @returns {{x: number, y: number}}
 */
export const getFlippedPointInBounds = ({
  point: { x, y },
  bounds,
  flipX,
  flipY,
}: {
  point: Point;
  bounds: { x: number; y: number; width: number; height: number };
  flipX: boolean;
  flipY: boolean;
}): Point => ({
  x: flipX ? bounds.x + (bounds.x + bounds.width - x) : x,
  y: flipY ? bounds.y + (bounds.y + bounds.height - y) : y,
});

/**
 * Check if two points are at the same position.
 * @param pointA
 * @param pointB
 */
export const areIdenticalPoints = (pointA: Point, pointB: Point): boolean =>
  pointA.x === pointB.x && pointA.y === pointB.y;
