import { DEFAULT_USER_FONTS_TAKE } from './constants';
import { fetchRetry } from '../utils/fetchRetry';
import { getAccessToken, USER_API_URL } from './userApi';
import { FontFamily } from '../types/FontFamily';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createAPI = ({ url }: { url: string }) => {
  /**
   * uploads a new fontFamily with various font files as styles
   */
  const uploadFont = async (
    name: string,
    files: File[],
    styles: string[]
  ): Promise<
    { error: string } | { success: boolean; fontFamily: FontFamily }
  > => {
    const formData = new FormData();
    const objectIds = [];

    formData.append('name', name);

    for await (const file of files) {
      const resp = await (
        await fetchRetry(`${url}/users/create_signed_url`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
      ).json();

      // On windows `type` is very unpredictable and quite often is an empty string
      // read more here: https://developer.mozilla.org/en-US/docs/Web/API/File/type
      // if type is missing we try to fallback to types based on file extension
      let fileType = file.type;
      if (!fileType) {
        if (file.name?.toLowerCase().endsWith('.ttf')) {
          fileType = 'font/ttf';
        } else if (file.name?.toLowerCase().endsWith('.otf')) {
          fileType = 'font/otf';
        }
      }

      await fetchRetry(resp.url, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': fileType },
      });

      objectIds.push(resp.objectId);
    }

    formData.append('objectIds', objectIds.join(','));

    formData.append(
      'styles',
      styles.map((style) => style.replaceAll(',', ' ')).join(',')
    );

    const resp = await fetchRetry(`${url}/fonts/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: formData,
    });

    const result = await resp.json();
    return result;
  };

  const getFonts = async (
    take = DEFAULT_USER_FONTS_TAKE,
    skip = 0,
    cursor = null
  ): Promise<{ error: string } | null | FontFamily> => {
    try {
      const resp = await fetchRetry(
        `${url}/fonts/index?take=${take}&skip=${skip}&${
          cursor ? `cursor=${cursor}` : ''
        }`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return await resp.json();
    } catch (e) {
      console.warn('Could not load user fonts');
      return { error: 'Connection failed' };
    }
  };

  const findFonts = async (
    ids: string[]
  ): Promise<{ error: string } | { results: FontFamily[] }> => {
    const idsJoined = ids.join(',');
    try {
      const resp = await fetchRetry(`${url}/fonts/find_families`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: idsJoined }),
      });
      return await resp.json();
    } catch (e) {
      console.warn('Could not load user fonts');
      return { error: 'Connection failed' };
    }
  };

  return {
    uploadFont,
    getFonts,
    findFonts,
  };
};

export default createAPI({ url: USER_API_URL });
