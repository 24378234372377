import React from 'react';

import { MainContainer } from './styles';
import { AuthComponentProps } from './types';

import Authentication from './Authentication/Authentication';
import RightColumn from './RightColumn/RightColumn';

const AuthComponent: React.FC<AuthComponentProps> = (props) => {
  return (
    <MainContainer isSignup={props.isSignup}>
      <Authentication {...props} />
      {props.isSignup && <RightColumn />}
    </MainContainer>
  );
};

export default AuthComponent;
