import { themeStyle } from '../../services/theming';

export const headerTheme = {
  fontWeight: themeStyle.fontMedium,
  fontSize: themeStyle.fontSize12,
  lineHeight: '19px',
  letterSpacing: 'normal',
  textTransform: 'none',

  colorIcon: themeStyle.inkLight,
  colorIconActive: themeStyle.inkMain,
  opacityIcon: 0.8,

  colorLabel: themeStyle.inkBase,
};
