/**
 * polyfill for String.replaceAll
 */
if (!String.prototype.replaceAll) {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === '[object regexp]'
    ) {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr);
  };
}

// Recursively reduce sub-arrays to the specified depth
const flatten = function (arr, depth) {
  // If depth is 0, return the array as-is
  if (depth < 1) {
    return arr.slice();
  }

  // Otherwise, concatenate into the parent array
  return arr.reduce((acc, val) => {
    return acc.concat(Array.isArray(val) ? flatten(val, depth - 1) : val);
  }, []);
};

/**
 * polyfill for Array.flat
 * Adapted from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#reduce_concat_isArray_recursivity
 */
if (!Array.prototype.flat) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.flat = function (depth) {
    // If no depth is specified, default to 1
    if (depth === undefined) {
      depth = 1;
    }

    return flatten(this, depth);
  };
}
