import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Body, ExpandButton } from './styles';
import { ReactComponent as ExpandSvg } from '../../assets/images/expand.svg';
import SideMenuNavigation from '../utilities/SideMenuNavigation/SideMenuNavigation';
import { panels } from './config';
import analytics from '../../global/analytics';
import {
  LEFT_MENU_CHANGE,
  LEFT_MENU_CLOSE,
  LEFT_MENU_COLLAPSE,
  LEFT_MENU_EXPAND,
  LEFT_MENU_OPEN,
} from '../../global/analytics/events';
import { useContextSelector } from 'use-context-selector';
import leftMenuContext from '../leftMenuContext';
import { ADD_TEMPLATE } from '../../global/events';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import { useOnboardingStore } from '../../stores/onboardingStore';
import { ANCHOR_REF_KEYS } from '../../stores/onboardingStore/constants';

/**
 * LeftMenu is the menu in the left of the app. It is mainly used to add elements
 * to the artboard (eg. templates, texts and illustrations).
 */
const LeftMenu = (props) => {
  const [state, setState] = useState(props.state);
  const setContextState = useContextSelector(leftMenuContext, (v) => v[1]);
  const _setState = useCallback(
    (state) => {
      setState(state);
      setContextState((oldContext) => ({
        ...oldContext,
        expanded: state === 'expanded',
      }));
    },
    [setContextState]
  );

  const [activePanel, setActivePanel] = useState(
    props.active ? panels.find(({ id }) => id === props.active.panel) : null
  );

  const canUpdateTemplate = !!useMenuStore(menuStoreSelector.updateTemplateId);

  useEffect(() => {
    props.active &&
      setActivePanel(panels.find(({ id }) => id === props.active.panel));
    props.state && setState(props.state);
  }, [props.active, props.state]);

  const bodyRef = useRef();

  // This is a fixed value for the width of the SideMenuNavigation
  // Besides for the nav itself, it is also needed for the padding of the body.
  const navWidth = '50px';

  const { dispatch } = props;
  const dispatchEvent = useCallback(
    (event, value) => {
      // collapse menu when template is added
      if (event === ADD_TEMPLATE) {
        _setState('open');
      }
      dispatch && dispatch(event, value);
    },
    [dispatch, _setState]
  );

  const handleNavigation = (panelId) => {
    let event = LEFT_MENU_CHANGE;
    if (state !== 'closed' && (!panelId || panelId === activePanel?.id)) {
      // close
      event = LEFT_MENU_CLOSE;
      _setState('closed');
    } else {
      const newActive = panels.find(({ id }) => id === panelId);
      // check if menu is closed or if new panel is not expandable
      if (
        state === 'closed' ||
        (state === 'expanded' && !newActive.isExpandable)
      ) {
        event = LEFT_MENU_OPEN;
        _setState('open');
      }
      // change active
      setActivePanel(newActive);
      analytics.track(event, {
        label: panelId,
      });
    }
  };

  const toggleExpand = () => {
    let event = LEFT_MENU_EXPAND;
    if (state === 'open') {
      // expand
      _setState('expanded');
    } else if (state === 'expanded') {
      event = LEFT_MENU_COLLAPSE;
      _setState('open');
    }
    analytics.track(event, {
      label: activePanel?.id,
    });
  };

  const lastState = useRef();
  const [isMoving, setIsMoving] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  useEffect(() => {
    const isTransitioning =
      lastState.current === 'expanded' ||
      (lastState.current === 'open' && state === 'expanded');
    setTransitioning(isTransitioning);
    setIsMoving(state !== lastState.current);
    lastState.current = state;
  }, [state]);

  const renderActivePanel = () => {
    if (activePanel)
      return (
        <activePanel.element
          panel={activePanel.id}
          state={state}
          onClose={state !== 'closed' ? () => handleNavigation() : () => {}}
          dispatch={dispatchEvent}
          artboardState={props.artboardState}
          transitioning={transitioning}
        />
      );
  };

  const workingMode = useMenuStore(menuStoreSelector.workingMode);
  const isMockupMode = workingMode === 'mockup';

  const onTransitionEnd = useCallback(
    (event) => {
      if (event.target === bodyRef.current) {
        if (
          event.propertyName === 'transform' ||
          event.propertyName === 'width'
        ) {
          useOnboardingStore
            .getState()
            .registerStepRef(bodyRef.current, ANCHOR_REF_KEYS.LEFT_MENU);
        }
        if (event.propertyName === 'width') {
          setTransitioning(false);
          setIsMoving(false);
        }
        if (event.propertyName === 'transform') {
          setIsMoving(false);
        }
      }

      if (activePanel?.id === 'mockup' && !isMockupMode) {
        setActivePanel(
          props.active
            ? panels.find(({ id }) => id === props.active.panel)
            : null
        );
      }
    },
    [props.active, activePanel, isMockupMode]
  );

  const _panels = panels
    .filter((p) => p.id !== 'mockup')
    .map((p) => ({ ...p }));
  if (canUpdateTemplate) {
    const [designs, templates] = _panels.filter((p) =>
      ['designs', 'template'].includes(p.id)
    );
    designs.disabled = true;
    templates.disabled = true;
  }

  return (
    <Wrapper marginTop={props.marginTop} data-testid="left-panel">
      <SideMenuNavigation
        items={_panels}
        onClickItem={handleNavigation}
        state={state}
        activeItemId={activePanel?.id}
        width={navWidth}
        visible={activePanel?.id !== 'mockup'}
        menuIsMoving={isMoving && !transitioning}
      />
      <Body
        {...{ state, navWidth }}
        navHidden={isMockupMode}
        ref={(node) => {
          bodyRef.current = node;
          useOnboardingStore
            .getState()
            .registerStepRef(node, ANCHOR_REF_KEYS.LEFT_MENU);
        }}
        onTransitionEnd={onTransitionEnd}
      >
        {renderActivePanel()}
        {activePanel?.isExpandable && (
          <ExpandButton
            onClick={toggleExpand}
            state={state}
            show={state === 'open'}
          >
            <ExpandSvg />
          </ExpandButton>
        )}
      </Body>
    </Wrapper>
  );
};

LeftMenu.propTypes = {
  /**
   * start state of the panel
   */
  state: PropTypes.oneOf(['closed', 'open', 'expanded']),
  /**
   * active child of the panel
   * if empty, no panel is active
   */
  active: PropTypes.shape({ panel: PropTypes.string }),
  /**
   * event dispatcher
   */
  dispatch: PropTypes.func,
  /**
   * Artboard state
   */
  artboardState: PropTypes.object,
  marginTop: PropTypes.string,
};

LeftMenu.defaultProps = {
  state: 'closed',
  active: null,
  artboardState: {},
};

export default React.memo(LeftMenu);
