import styled from 'styled-components';

export const ElementWrapper = styled.div<any>``;

export const FlexColumn = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${ElementWrapper} {
    margin-bottom: ${({ gap }) => gap}px;
  }

  min-width: min(${({ minColumnWidth }) => minColumnWidth}px, 100%);
  height: 100%;
`;

export const Wrapper = styled.div<any>`
  width: 100%;
  display: flex;

  ${FlexColumn} + ${FlexColumn} {
    margin-left: ${({ gap }) => gap}px;
  }
`;

export const OuterWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  will-change: transform;
  height: 100%;
  width: 100%;
`;
