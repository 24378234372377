import { JSONValue } from './misc';

export enum SubscriptionPlan {
  PRO = 'PRO',
  EXPERT = 'EXPERT',
}

export enum SubscriptionPlanInterval {
  YEAR = 'year',
  MONTH = 'month',
}

export interface UserProfile {
  slug: string | null;
  name: string | null;
  profileImage: string | null;
  description: string | null;
  location: string | null;
  website: string | null;
  instagram: string | null;
  followerCount: number;
  followingCount: number;
  following: boolean;
  challengeStats: {
    firstPlace: number;
    secondPlace: number;
    thirdPlace: number;
  } | null;
}

export enum OnboardingState {
  SignUp = 'SIGN_UP',
  Survey = 'SURVEY',
  ChooseTemplate = 'CHOOSE_TEMPLATE',
  EditText = 'EDIT_TEXT',
  TransformText = 'TRANSFORM_TEXT',
  AddElement = 'ADD_ELEMENT',
  ColorPalette = 'COLOR_PALETTE',
  Download = 'DOWNLOAD',
  Complete = 'COMPLETE',
  Skipped = 'SKIPPED',
  Mobile = 'MOBILE',
}

export interface User extends UserProfile {
  admin?: boolean;
  creator?: boolean;
  contentManager?: boolean;
  id: string;
  createdAt: string;
  invitesLimit: number;
  confirmed: boolean;
  email: string;
  marketingOptIn: boolean;
  allowCommentEmails: boolean;
  allowShareViewEmails: boolean;
  onboardingState: OnboardingState | null;
  plan?: SubscriptionPlan;
  uploadSpaceLimitReached: boolean;
  designsLimit: number;
  designsCount: number;
  designsLimitReached: boolean;
  invite?: {
    sender?: {
      id: string;
      name: string | null;
    };
    createdAt: string;
    acceptedAt: Date | null;
  };
  shippingInfo: JSONValue;
  support?: boolean;
  /**
   * credits for ai generation, can be given by admins
   * will decrease when user uses ai features
   */
  generationCredits: number;
  /**
   * recurring credits for ai generartion, are given to a user each month based on their plan
   * will decrease when user uses ai features
   */
  recurringGenerationCredits: number;
  /**
   * amount of credits the user gets each month as recurringGenerationCredits
   */
  monthlyRecurringGenerationCreditAmount: number;
}
