import { themeStyle } from '../../../services/theming';
import { default as oldThemeStyle } from '../../../global/themeStyle';
import { StyledTheme } from '../../../types';

const baseTheme: StyledTheme = {
  height: '40px',
  disabledColor: themeStyle.inkMedium,
  backgroundDisabledColor: themeStyle.inkLight,
  borderDisabledColor: themeStyle.inkLight,

  disabledColorDark: themeStyle.darkInkMedium,
  backgroundDisabledColorDark: themeStyle.darkInkSuperLight,
  borderDisabledColorDark: themeStyle.transparent,

  fontSize: themeStyle.fontSize14,
  fontWeight: themeStyle.fontSemiBold,
  lineHeight: '18px',
  iconHeight: '16px',
  textDecoration: 'none',

  borderRadius: themeStyle.radiusMedium,
  padding: '16px',
  iconMinWidth: '0px',
};

export const primaryTheme: StyledTheme = {
  ...baseTheme,

  color: themeStyle.varInkBase,
  spinnerColor: themeStyle.varInkBase,
  activeColor: themeStyle.varInkBase,

  backgroundColor: themeStyle.brandMain,
  borderColor: themeStyle.brandMain,
  backgroundActiveColor: themeStyle.brandMedium,
  borderActiveColor: themeStyle.brandMedium,

  backgroundColorDark: themeStyle.darkBrandMain,
  borderColorDark: themeStyle.darkBrandMain,
  backgroundActiveColorDark: themeStyle.darkBrandMedium,
  borderActiveColorDark: themeStyle.darkBrandMedium,
};

export const primaryLightTheme: StyledTheme = {
  ...baseTheme,

  color: themeStyle.inkBase,
  spinnerColor: themeStyle.inkBase,
  activeColor: themeStyle.inkBase,

  backgroundColor: themeStyle.brandMain,
  borderColor: themeStyle.brandMain,
  backgroundActiveColor: themeStyle.brandMedium,
  borderActiveColor: themeStyle.brandMedium,

  backgroundColorDark: null,
  borderColorDark: null,
  backgroundActiveColorDark: null,
  borderActiveColorDark: null,
};

export const secondaryTheme: StyledTheme = {
  ...baseTheme,

  color: themeStyle.varInkMain,
  spinnerColor: themeStyle.varInkMain,
  activeColor: themeStyle.varInkMain,

  backgroundColor: themeStyle.varInkLight,
  borderColor: themeStyle.transparent,
  backgroundActiveColor: themeStyle.varInkSuperLight,
  borderActiveColor: themeStyle.varInkLight,

  backgroundColorDark: null,
  borderColorDark: null,
  backgroundActiveColorDark: null,
  borderActiveColorDark: null,
};

export const secondaryLightTheme: StyledTheme = {
  ...baseTheme,

  color: themeStyle.inkMain,
  spinnerColor: themeStyle.inkMain,
  activeColor: themeStyle.inkMain,

  backgroundColor: themeStyle.inkLight,
  borderColor: themeStyle.transparent,
  backgroundActiveColor: themeStyle.inkSuperLight,
  borderActiveColor: themeStyle.inkLight,

  backgroundColorDark: null,
  borderColorDark: null,
  backgroundActiveColorDark: null,
  borderActiveColorDark: null,
};

export const transparentTheme: StyledTheme = {
  ...secondaryTheme,
  backgroundColor: themeStyle.transparent,
  backgroundActiveColor: themeStyle.varInkSuperLight,
  borderColor: themeStyle.transparent,
  borderActiveColor: themeStyle.transparent,
};

const smallTheme = {
  height: '30px',
  fontSize: themeStyle.fontSize12,
  lineHeight: '15px',
  iconHeight: '14px',
  padding: '14px',
};

export const primarySmallTheme: StyledTheme = {
  ...primaryTheme,
  ...smallTheme,
};

export const primaryLightSmallTheme: StyledTheme = {
  ...primaryLightTheme,
  ...smallTheme,
};

export const secondarySmallTheme: StyledTheme = {
  ...secondaryTheme,
  ...smallTheme,
};

export const secondaryLightSmallTheme: StyledTheme = {
  ...secondaryLightTheme,
  ...smallTheme,
};

const giantTheme = {
  height: '48px',
  padding: '32px',
};

export const primaryGiantTheme: StyledTheme = {
  ...primaryTheme,
  ...giantTheme,
};

export const secondaryGiantTheme: StyledTheme = {
  ...secondaryTheme,
  ...giantTheme,
};

export const menuTheme: StyledTheme = {
  ...primaryTheme,

  height: '32px',
  color: themeStyle.varInkMedium,
  spinnerColor: themeStyle.varInkMedium,
  backgroundColor: themeStyle.transparent,
  borderColor: themeStyle.transparent,

  activeColor: themeStyle.varInkMain,
  backgroundActiveColor: themeStyle.varInkSuperLight,
  borderActiveColor: themeStyle.varInkSuperLight,

  padding: '12px',

  backgroundColorDark: null,
  borderColorDark: null,
  backgroundActiveColorDark: null,
  borderActiveColorDark: null,
};

export const menuSmallTheme: StyledTheme = {
  ...menuTheme,
  ...smallTheme,
};

export const floatTheme: StyledTheme = {
  ...menuTheme,
  height: '40px',
  padding: '16px',
  color: themeStyle.varInkMain,
  backgroundColor: themeStyle.varBackgroundAlt,
  borderColor: themeStyle.varBackgroundAlt,
  activeColor: themeStyle.varBrandMain,
  backgroundActiveColor: themeStyle.varBackgroundAlt,
  borderActiveColor: themeStyle.varBackgroundAlt,
  boxShadow: themeStyle.shadowMedium,
};

export const greyTheme: StyledTheme = {
  ...primaryTheme,

  color: themeStyle.varInkMain,
  spinnerColor: themeStyle.varInkMain,
  backgroundColor: themeStyle.varInkSuperLight,
  borderColor: themeStyle.varInkSuperLight,

  activeColor: themeStyle.varInkMain,
  backgroundActiveColor: themeStyle.varInkLight,
  borderActiveColor: themeStyle.varInkLight,

  padding: '12px',

  backgroundColorDark: null,
  borderColorDark: null,
  backgroundActiveColorDark: null,
  borderActiveColorDark: null,
};

export const greySmallTheme: StyledTheme = {
  ...greyTheme,
  ...smallTheme,
};

export const editorMenuItemTheme: StyledTheme = {
  ...menuTheme,

  height: '27px',
  padding: '10px',
  backgroundActiveColor: themeStyle.varBrandSuperLight,
  borderActiveColor: themeStyle.transparent,
};

/**
 * @deprecated
 */
export const themeGreyButton = greyTheme;

/**
 * @deprecated
 */
export const textTheme = menuTheme;

/**
 * @deprecated
 * all instances using this theme will be removed with the design update
 */
export const blackTheme: StyledTheme = {
  ...primaryTheme,

  color: oldThemeStyle.white,
  backgroundColor: oldThemeStyle.grey700,
  borderColor: oldThemeStyle.grey700,

  activeColor: oldThemeStyle.white,
  backgroundActiveColor: oldThemeStyle.black,
  borderActiveColor: oldThemeStyle.black,
};

/**
 * @deprecated
 * all instances using this theme will be removed with the design update
 */
export const blackSmallTheme: StyledTheme = {
  ...blackTheme,

  fontSize: oldThemeStyle.fontSizeMedium,
  lineHeight: '15px',
  padding: '14px',
};
