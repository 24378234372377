import React, { useCallback } from 'react';
import { AddedTemplatesNotification } from '../../../../types';

import { buildImageProxyUrl, WEBSITE_URL } from '../../../../utils/url';
import NotificationHeader from '../NotificationHeader/NotificationHeader';
import PreviewImage from '../PreviewImage/PreviewImage';
import { NotificationWrapper, ImageRow } from './styles';
import { NotificationProps } from './types';

const TemplateNotification: React.FC<
  NotificationProps<AddedTemplatesNotification>
> = ({ notification, onReadNotification }) => {
  const trimmedTemplates = notification.data.slice(0, 6);
  const message =
    notification.data.length > 1
      ? `Added ${notification.data.length} new designs`
      : 'Added a new design';

  const handleClick = useCallback((): void => {
    onReadNotification(notification.id);
    if (!notification.relatedUser) return;
    window.open(
      `${WEBSITE_URL || ''}/${notification.relatedUser.slug}?sort=recent`,
      WEBSITE_URL ? '_blank' : '_self'
    );
  }, [onReadNotification, notification.id, notification.relatedUser]);

  if (!trimmedTemplates.length) return null;

  return (
    <NotificationWrapper role="button" onClick={handleClick}>
      <NotificationHeader
        user={notification.relatedUser}
        message={message}
        createdAt={notification.createdAt}
        isUnseen={!notification.seenAt}
      />
      <ImageRow>
        {trimmedTemplates.map((t) => (
          <PreviewImage
            {...t}
            key={t.id}
            src={buildImageProxyUrl(t.templatePreviewObjectName)}
          />
        ))}
      </ImageRow>
    </NotificationWrapper>
  );
};

export default TemplateNotification;
