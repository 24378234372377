import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';

export const Content = styled.div`
  width: 100%;
  max-width: 420px;
  box-sizing: border-box;
  margin: 0 auto;

  padding: 48px 20px;
  @media (min-width: 400px) {
    padding: 48px 40px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const OrWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 12px 0;
`;

export const PolicyLabel = styled.label`
  font-family: ${themeStyle.fontFamily};
  font-weight: ${themeStyle.fontMedium};
  font-size: ${themeStyle.fontSize11};
  line-height: 18px;
  color: ${themeStyle.varInkMedium};
  margin-top: 8px;

  & > a {
    font-weight: ${themeStyle.fontSemiBold};
    color: ${themeStyle.varInkMedium};
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration-line: underline;
    }
  }
`;

export const SwitchModeLabel = styled.label`
  font-family: ${themeStyle.fontFamily};
  font-weight: ${themeStyle.fontBold};
  font-size: ${themeStyle.fontSize14};
  color: ${themeStyle.varBrandMain};
  cursor: pointer;
  text-align: center;
  text-decoration-line: underline;
  width: 100%;
  margin-top: 2px;

  &:hover {
    color: ${themeStyle.varBrandMedium};
  }
`;

export const Divider = styled.div`
  flex-grow: 1;
  border-bottom: 1px solid ${themeStyle.varInkSuperLight};
`;

export const OtherProviders = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > button {
    flex: 1;
    max-width: 160px;
  }
`;
