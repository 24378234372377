import React from 'react';
import { Wrapper, ColorWrapper } from './styles';
import { H6, P4, P3 } from '../Typography/styles';
import { RightMenuHeaderProps } from './types';
import { themeStyle } from '../../../services/theming';

/**
 * Utility component to create a headline in the RightMenu.
 * Optionally, this component can be extended by a ColorPicker component, that is placed
 * in the end of the header.
 */
const RightMenuHeader: React.FC<RightMenuHeaderProps> = (props) => {
  const Label = props.small ? P3 : H6;

  return (
    <Wrapper small={props.small}>
      <Label>{props.label}</Label>
      {/*
          Only show color label if there is only one color picker
        */}
      {props.colorPickers && (
        <ColorWrapper>
          {props.colorPickers?.length === 1 && (
            <P4 color={themeStyle.varInkMain}>{props.colorLabel}</P4>
          )}
          {props.colorPickers.map((colorPicker, index) =>
            React.cloneElement(colorPicker, { key: index })
          )}
        </ColorWrapper>
      )}
    </Wrapper>
  );
};

RightMenuHeader.defaultProps = {
  label: 'Headline',
  colorLabel: 'Color',
};

export default RightMenuHeader;
