import fabric from '../../Artboard/fabric';
import {
  addWarpControlPoint,
  removeWarpControlPoints,
} from '../../Artboard/fabric/mockupTemplate/utils';
import { mockupTemplateDimension } from './index';

/**
 * Generate warp control points with given row and column count on the canvas.
 */
export const generateWarpControlPoints = (
  canvas: fabric.Canvas,
  row: number,
  col: number
): void => {
  // a "unit" in the warp grid
  const unitSize = {
    width: mockupTemplateDimension.width / col,
    height: mockupTemplateDimension.height / row,
  };

  for (let i = 0; i <= row; i++) {
    for (let j = 0; j <= col; j++) {
      const position = {
        x: unitSize.width * j,
        y: unitSize.height * i,
      };
      const rootPoint = new fabric.WarpControlRootPoint({
        left: position.x,
        top: position.y,
        i,
        j,
      });
      addWarpControlPoint(canvas, rootPoint);
    }
  }
};

/**
 * Updates the warp control points on the canvas.
 */
export const updateWarpControlPoints = (
  canvas: fabric.Canvas,
  row?: number,
  col?: number
): void => {
  canvas.discardActiveObject();
  removeWarpControlPoints(canvas);

  if (row && col) {
    generateWarpControlPoints(canvas, row, col);
  }

  // make sure history is updated
  canvas.fire('object:modified');
};
