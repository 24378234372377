import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { DropdownProps, Option } from './types';
import { DropdownWrapper } from './styles';
import DropdownOption from './DropdownOption';

const minSafeMargin = 20;

const Dropdown: React.FC<DropdownProps> = ({
  options,
  small = false,
  compact = false,
  expanded,
  activeOption,
  onOptionClick,
  onMouseEnterOption,
  customStyles,
  dataTestId,
}) => {
  const nodeRef = useRef<null | HTMLDivElement>(null);
  const [rightAligned, setRightAligned] = useState<boolean>(false);
  const [isOnTop, setIsOnTop] = useState<boolean>(false);

  useEffect(() => {
    if (!nodeRef.current || !expanded) return;

    const boundingRect = nodeRef.current.getBoundingClientRect();
    if (boundingRect.right + minSafeMargin > window.innerWidth) {
      setRightAligned(true);
    }

    if (boundingRect.bottom + minSafeMargin > window.innerHeight) {
      setIsOnTop(true);
    }
  }, [expanded, setRightAligned]);

  return (
    <CSSTransition in={expanded} nodeRef={nodeRef} timeout={300} unmountOnExit>
      <DropdownWrapper
        ref={nodeRef}
        small={small}
        compact={compact}
        customStyles={customStyles}
        rightAligned={rightAligned}
        isOnTop={isOnTop}
        data-testid={dataTestId}
      >
        {options.map((option: Option) => {
          return (
            <DropdownOption
              key={option.id}
              small={small}
              compact={compact}
              option={option}
              isActive={activeOption?.id === option.id}
              onClick={onOptionClick}
              onMouseEnter={onMouseEnterOption}
            />
          );
        })}
      </DropdownWrapper>
    </CSSTransition>
  );
};

export default React.memo(Dropdown);
