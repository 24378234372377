import styled from 'styled-components';
import { ColorSwatchWrapper } from '../ColorPicker/ColorSwatch/styles';
import { themeStyle } from '../../services/theming';
import { FlexColumn, FlexRow } from '../utilities/styles';
import { P3 } from '../utilities/Typography/styles';

export const BorderSliderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;

  ${ColorSwatchWrapper} {
    margin-right: 8px;
    margin-bottom: 2px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const ColorWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 10px;
`;

export const OpacityWrapper = styled.div`
  display: flex;
  width: 112px;
  flex-shrink: 0;

  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  font-family: ${themeStyle.fontFamily};
  font-weight: ${themeStyle.fontMedium};
  font-size: ${themeStyle.fontSize11};
  line-height: 13px;

  color: ${themeStyle.primaryBlack};
`;

export const UnlockMessage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: ${themeStyle.radiusMedium};
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background: ${themeStyle.varInkSuperLight};
  gap: 12px;
  height: 126px;
`;

/**
 * Aliases for markup readability.
 * No special logic goes for them
 */

export const Panel = styled(FlexColumn)`
  gap: 12px;
`;

export const PanelColumn = styled(FlexColumn)``;

export const PanelRow = styled(FlexRow)`
  width: 100%;
`;

export const PanelTitle = styled(P3)``;
