import styled from 'styled-components';

import { themeStyle } from '../../services/theming';
import { handleTextOverflow } from '../utilities/styles';
import { H5 } from '../utilities/Typography/styles';

export const Wrapper = styled.div`
  padding: 20px 20px 24px 20px;
  width: 100%;
`;

export const Content = styled.div`
  margin: 12px 0;
  padding: 24px;
  background: ${themeStyle.varInkSuperLight};
  border-radius: ${themeStyle.radiusLarge};
`;

export const Divider = styled.div`
  margin: 24px 0;
  border-bottom: 1px solid ${themeStyle.varInkLight};
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 224px;
  margin-bottom: 5px;
`;

export const Attributes = styled.div`
  display: grid;
  grid-template-columns: 22px 20px auto 196px 20px;
  gap: 8px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'cursor')};
`;

export const FontName = styled(H5)`
  ${handleTextOverflow}
`;
