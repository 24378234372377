import { createGlobalStyle } from 'styled-components';
import {
  themeColorsDefault,
  themeColorsDark,
  themeShadowsDark,
  themeShadowsDefault,
  themeFiltersDefault,
  themeFiltersDark,
} from './theme';
import { getCssVarName } from './utils';

export const ThemeVariables = createGlobalStyle`
:root {
  ${Object.entries(themeColorsDefault).map(
    ([key, color]) => `${getCssVarName(key)}: ${color};`
  )}

  ${Object.entries(themeShadowsDefault).map(
    ([key, shadow]) => `${getCssVarName(key)}: ${shadow};`
  )}

  ${Object.entries(themeFiltersDefault).map(
    ([key, shadow]) => `${getCssVarName(key)}: ${shadow};`
  )}
}

[data-theme='dark'] {
  ${Object.entries(themeColorsDark).map(
    ([key, color]) => `${getCssVarName(key)}: ${color};`
  )}

  ${Object.entries(themeShadowsDark).map(
    ([key, shadow]) => `${getCssVarName(key)}: ${shadow};`
  )}

  ${Object.entries(themeFiltersDark).map(
    ([key, shadow]) => `${getCssVarName(key)}: ${shadow};`
  )}
}
`;
