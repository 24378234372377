import tinycolor from 'tinycolor2';

/**
 * function to transform a rbg string representation into an object
 * @param {string} rgb
 * returns an object with the shape { r, g, b (, a) } or the input string
 */
export const rgbStringToObj = (rgb) => {
  const rgbArr = rgb.replace(/[^\d,.]/g, '').split(',');
  if (rgbArr.length === 3 || rgbArr.length === 4) {
    const rgbObj = {
      r: parseInt(rgbArr[0]),
      g: parseInt(rgbArr[1]),
      b: parseInt(rgbArr[2]),
    };
    if (rgbArr.length === 4) {
      rgbObj.a = parseFloat(rgbArr[3]);
    } else {
      rgbObj.a = 1;
    }
    return rgbObj;
  }
  return rgb;
};

/**
 * Get rgb(a) representation of a color
 * @param {*} color
 * @returns {string}
 */
export const toRgb = (color) => tinycolor(color).toRgbString();

/**
 * function to transform a hsv object into a hsl object
 * @param {{h, s, v}} hsv
 * returns an hsl object
 *
 * source: https://gist.github.com/defims/0ca2ef8832833186ed396a2f8a204117
 */
export const hsvToHsl = ({ h, s, v }) => {
  const _hslL = ((200 - s) * v) / 100;
  const [hslS, hslL] = [
    _hslL === 0 || _hslL === 200
      ? 0
      : ((s * v) / 100 / (_hslL <= 100 ? _hslL : 200 - _hslL)) * 100,
    (_hslL * 5) / 10,
  ];
  return { h, s: hslS, l: hslL };
};
