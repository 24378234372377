import React, { useCallback, useEffect, useState } from 'react';
import { themeStyle } from '../../../services/theming';
import Button from '../../Button/Button/Button';
import TextInput from '../../TextInput/TextInput';
import { FlexColumn } from '../../utilities/styles';
import VerticallyScrollable from '../../VerticallyScrollable/VerticallyScrollable';
import { primaryTheme } from '../../Button/Button/theme';
import { H5, P2, P3 } from '../../utilities/Typography/styles';
import Spacer from '../../Spacer/Spacer';
import {
  LoadingSpinnerWrapper,
  LoadingWrapper,
  MenuWrapper,
  PromptConfigurationWrapper,
  TextInputContainer,
  Wrapper,
} from './styles';
import LoadingBar from '../../LoadingBar/LoadingBar';
import useTextToImagePanelStore from '../../../stores/textToImagePanelStore';
import Spinner from '../../utilities/Spinner/Spinner';
import ElementGallery from '../../ElementGallery/ElementGallery';
import { buildWebsiteUrl } from '../../../utils/url';
import { BodyElementProps } from './types';
import { TextInputTheme } from '../../TextInput/types';
import { ElementGalleryDimensions } from '../../ElementGallery/types';

const elementGalleryDimensions: Partial<ElementGalleryDimensions> = {
  heightCollapsed: 127,
  heightExpanded: 314,
  minColumnWidth: 80,
};

const BodyElement: React.FC<BodyElementProps> = ({
  renderImageStyleElement,
  onGenerateImage,
  onShowAll,
  onUpgrade,
  hasCredits,
  groups,
}) => {
  const { style, prompt, generating, mergeState } = useTextToImagePanelStore();
  const getStyleName = (style: string): string => {
    return groups
      .map((group) => group.styles)
      .flat()
      .find((promptStyle) => promptStyle?.id === Number(style))!.name;
  };

  const textInputTheme: Partial<TextInputTheme> = {
    width: '100%',
    height: '80px',
    hoverShadow: 'unset',
    borderColorHover: themeStyle.varInkLight,
  };

  const setPromptFromInput = useCallback(
    (value: string) => {
      mergeState({ prompt: value });
    },
    [mergeState]
  );
  const [astronautLoaded, setAstronautLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = '/images/astronaut.svg';
    img.onload = (): void => {
      setAstronautLoaded(true);
    };
  }, []);

  const buttonDisabled = !prompt;
  const onMenuClick = useCallback(() => {
    if (!hasCredits) {
      onUpgrade();
    }
  }, [onUpgrade, hasCredits]);

  const menu = (
    <MenuWrapper visible={!generating} onClick={onMenuClick}>
      <FlexColumn gap="16px" width="100%">
        <P3>
          Use Kittl AI to generate images with one click.
          <br />
          <a
            href={buildWebsiteUrl('/feature/kittl-ai-prompt-book')}
            target="__blank"
          >
            Learn more
          </a>
        </P3>
        <PromptConfigurationWrapper
          visible={!generating}
          disabled={!hasCredits}
          onClick={onMenuClick}
        >
          <TextInputContainer>
            <TextInput
              value={prompt}
              onChanging={setPromptFromInput}
              theme={textInputTheme}
              isTextArea={true}
              activeOnHover={false}
              placeholder="Describe what you want to see…"
            />
          </TextInputContainer>
          <Button
            disabled={buttonDisabled}
            theme={primaryTheme}
            width="100%"
            label={buttonDisabled ? 'Input a Prompt' : 'Generate Image –>'}
            appendIcon
            onClick={onGenerateImage}
          />
          {groups.map((group) => (
            <ElementGallery
              key={group.id}
              labelLeft={group.name.toUpperCase()}
              render={renderImageStyleElement}
              elements={group.styles}
              dimensions={elementGalleryDimensions}
              collapsed={true}
              onToggleCollapse={(): void => onShowAll(group)}
              equalDimensions
            />
          ))}
        </PromptConfigurationWrapper>
      </FlexColumn>
    </MenuWrapper>
  );

  const loading =
    astronautLoaded && generating ? (
      <LoadingWrapper>
        <FlexColumn>
          <img alt="" src="/images/astronaut.svg" />
          <Spacer h="28px" />
          <P2>
            Generating <b>“{prompt}”</b>
            {style && (
              <>
                &nbsp;in <b>{getStyleName(style)}</b> style
              </>
            )}
            . This can take a few seconds.
          </P2>
          <Spacer h="10px" />
          <LoadingBar transitionDuration={5000} />
          <Spacer h="10px" />
          <LoadingSpinnerWrapper>
            <Spinner
              theme={{
                trackColor: themeStyle.transparent,
                color: themeStyle.varBrandMedium,
              }}
            />
            <H5 color={themeStyle.varInkMedium}>Generating...</H5>
          </LoadingSpinnerWrapper>
        </FlexColumn>
      </LoadingWrapper>
    ) : null;

  return (
    <VerticallyScrollable>
      <Wrapper>
        {loading}
        {menu}
      </Wrapper>
    </VerticallyScrollable>
  );
};

export default BodyElement;
