import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RightMenuHeadline from '../../utilities/RightMenuHeader/RightMenuHeader';
import ColorPicker from '../../ColorPicker/ColorPicker';
import PositionActionButtons from '../PositionActionButtons/PositionActionButtons';
import { Wrapper, ColorWrapper, OpacityWrapper } from '../styles';
import NumberInput from '../../NumberInput/NumberInput';
import { findRecursive } from '../../../utils/groupStructure/recursive';
import { SET_COLOR_PALETTE, ACTIVE_OPACITY } from '../../../global/events';
import { Layer } from '../../../propTypes';
import { smallNumberInputTheme } from '../../NumberInput/theme';
import { P4 } from '../../utilities/Typography/styles';

const getOpacity = (selectedItems, allItems) => {
  // get the minimum opacity value of selected objects
  // opacity of a fabric object is between 0 and 1, the slider between 0 and 100 -> * 100
  if (selectedItems?.length) {
    const opacityOverflowValue = 2;
    let minOpacity = opacityOverflowValue;

    for (let i = 0; i < selectedItems?.length; i++) {
      const item = findRecursive(allItems, ({ id }) => id === selectedItems[i]);

      if (item && item.opacity !== undefined && item.opacity < minOpacity) {
        minOpacity = item.opacity;
      }
    }

    if (minOpacity !== opacityOverflowValue) {
      return minOpacity * 100;
    } else {
      return 100; // None of the selected objects had a set opacity
    }
  }
  return 100; // default
};

/**
 * The ColorPalettePanel. A panel to change colors in the color palette.
 */
const ColorPalettePanel = ({
  colorPalette,
  selectedLayers,
  layers,
  ...props
}) => {
  const [colors, setColors] = useState(colorPalette);
  const [opacity, setOpacity] = useState(getOpacity(selectedLayers, layers));

  const [changingColor, setChangingColor] = useState(null);
  useEffect(() => {
    if (changingColor === null || changingColor) return;
    setColors(colorPalette);
  }, [changingColor, colorPalette, colors]);

  useEffect(() => {
    setOpacity(getOpacity(selectedLayers, layers));
  }, [layers, selectedLayers]);

  const dispatchEvent = (event, value, isChanging = false) => {
    props.dispatch && props.dispatch(event, { ...value, isChanging });
  };

  const dispatchColorEvent = (oldColor, newColor, isChanging = false) => {
    const toUpdate = colors[oldColor];
    dispatchEvent(
      SET_COLOR_PALETTE,
      {
        toUpdate,
        newColor,
      },
      isChanging
    );
  };

  useEffect(() => {
    if (changingColor) return;
    setColors(colorPalette);
  }, [colorPalette, colors, changingColor]);

  const handleToggle = () => setChangingColor(true);
  const handleClose = () => setChangingColor(false);

  const getIds = (color) => {
    const objs = colors[color];
    return objs.map((obj) => obj.id).join('');
  };

  return (
    <>
      <RightMenuHeadline label={props.label} />
      <Wrapper>
        <ColorWrapper>
          {Object.keys(colors)
            /*
              We sort the colors based on the joined string of the object ids that they are linked to,
              since when they change value, the palette gets re-computed and they can change the order in the UI,
              which is annoying.
            */
            .sort((a, b) => getIds(a).localeCompare(getIds(b)))
            .map((color) => (
              <ColorPicker
                key={color}
                defaultColor={color}
                onChanging={(newColor) =>
                  dispatchColorEvent(color, newColor, true)
                }
                onChanged={(newColor) =>
                  dispatchColorEvent(color, newColor, false)
                }
                dispatch={props.dispatch}
                presetColors={Object.keys(props.documentPalette)}
                onToggle={handleToggle}
                onClose={handleClose}
              />
            ))}
        </ColorWrapper>
        {props.isGroup && (
          <OpacityWrapper>
            <P4>Opacity</P4>
            <NumberInput
              min={0}
              max={100}
              unit={'%'}
              precision={0}
              value={{ value: opacity }}
              onChanging={(opacity) =>
                dispatchEvent(ACTIVE_OPACITY, { opacity }, true)
              }
              onChanged={(opacity) =>
                dispatchEvent(ACTIVE_OPACITY, { opacity }, false)
              }
              theme={smallNumberInputTheme}
            />
          </OpacityWrapper>
        )}
      </Wrapper>
      {props.isGroup && (
        <PositionActionButtons dispatch={props.dispatch} marginTop={'16px'} />
      )}
    </>
  );
};

ColorPalettePanel.propTypes = {
  /**
   * label of this panel
   */
  label: PropTypes.string,
  /**
   * Object containing information about a color palette.
   * These colors are displayed directly in the panel
   */
  colorPalette: PropTypes.object,
  /**
   * Layer options. Each layer can have more layers in children
   */
  layers: PropTypes.arrayOf(Layer),
  /**
   * A list if ids that indicate which layers are currently selected
   */
  selectedLayers: PropTypes.arrayOf(PropTypes.string),
  /**
   * Object containing information about the document color palette.
   * These colors can be used to set colors in this panel
   */
  documentPalette: PropTypes.object,
  /**
   * whether its the palette for a group
   */
  isGroup: PropTypes.bool,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
  headlineMargin: PropTypes.string,
};

ColorPalettePanel.defaultProps = {
  label: 'Global Color Palette',
  headlineMargin: '6px',
};

export default ColorPalettePanel;
