import { themeStyle } from '../../services/theming';

export const defaultTheme = {
  size: '20px',
  borderColor: themeStyle.varInkMedium,
  defaultColor: themeStyle.varBackgroundAlt,
  checkColor: themeStyle.varInkBase,
  checkedColor: themeStyle.varBrandMain,
  checkIconSize: '10px',
  borderRadius: themeStyle.radiusSmall,
};
