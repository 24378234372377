/* global _cio */

import customerIoEvents from './customerIoEvents';

/**
 * Customer.io analytics integration
 * source: https://github.com/DavidWells/analytics/blob/master/packages/analytics-plugin-customerio/src/browser.js
 *
 *
 * @link https://getanalytics.io/plugins/customerio/
 * @link https://customer.io/docs/javascript-quick-start
 * @param {object} pluginConfig - Plugin settings
 * @param {string} pluginConfig.siteId - Customer.io site Id for client side tracking
 */
function customerIoPlugin(pluginConfig = {}) {
  // Because customer.io automatically fired a page view onLoad
  // We need to ignore the first .page() call
  let initialPageViewFired = false;

  // we keep track of tracked events, to not track events twice in one session
  let trackedEvents = [];

  return {
    name: 'customerio',
    config: pluginConfig,
    initialize: ({ config }) => {
      if (typeof window === 'undefined') return;
      const { siteId } = config;
      if (!siteId || typeof _cio !== 'undefined') {
        return;
      }

      window._cio = [];
      (function () {
        let c;
        const a = function (f) {
          return function () {
            _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
          };
        };
        const b = ['load', 'identify', 'sidentify', 'track', 'page'];
        for (c = 0; c < b.length; c++) {
          _cio[b[c]] = a(b[c]);
        }
        const t = document.createElement('script');
        const s = document.getElementsByTagName('script')[0];
        t.async = true;
        t.id = 'cio-tracker';
        t.setAttribute('data-site-id', siteId);
        t.src = 'https://assets.customer.io/assets/track-eu.js'; // important to use track-eu
        s.parentNode.insertBefore(t, s);
      })();
    },
    page: ({ payload }) => {
      if (!payload.userId) return;
      /* ignore the first .page() call b/c customer.io already fired it */
      if (!initialPageViewFired) {
        initialPageViewFired = true;
        return;
      }

      if (typeof _cio === 'undefined') return;
      _cio.page(document.location.href, payload.properties);
    },
    reset: ({ instance }) => {
      /* Clear customer.io cookies on reset */
      const { storage } = instance;
      const opts = { storage: 'cookie' };
      storage.removeItem('_cio', opts);
      storage.removeItem('_cioid', opts);
      trackedEvents = [];
    },
    track: ({ payload }) => {
      const { userId, event, properties } = payload;
      if (!userId || typeof _cio === 'undefined') return;
      if (!customerIoEvents.includes(event)) return;

      // only track events once per session
      if (trackedEvents.includes(event)) return;
      trackedEvents.push(event);

      _cio.track(event, properties);
    },
    identify: ({ payload }) => {
      const { userId, traits } = payload;
      if (!userId || typeof _cio === 'undefined') return;

      _cio.identify({
        id: userId,
        ...traits,
      });
    },
    loaded: () => {
      if (typeof window === 'undefined') return false;
      return !!(window._cio && window._cio.push !== Array.prototype.push);
    },
  };
}

export default customerIoPlugin;
