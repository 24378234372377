import { HelpList } from './styles';
import Button from '../Button/Button/Button';
import { buildWebsiteUrl } from '../../utils/url';
import {
  onboardingStoreSelector,
  useOnboardingStore,
} from '../../stores/onboardingStore';
import { ONBOARDING_USER_STEP } from '../../stores/onboardingStore/constants';
import { menuTheme } from '../Button/Button/theme';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';

const buttonTheme = {
  ...menuTheme,
  padding: '9px',
};

const HELP_URL = buildWebsiteUrl('/help');
const CHAT_URL = buildWebsiteUrl('/help?gs_open_chat=true');
const TUTORIALS_URL = buildWebsiteUrl('/tutorials');
const CANNY_URL = 'https://feedback.kittl.com/feature-requests';

const HelpPopover = () => {
  const setStep = useOnboardingStore(onboardingStoreSelector.setStep);
  const workingMode = useMenuStore(menuStoreSelector.workingMode);

  const handleChat = () => {
    window.open(CHAT_URL, '_blank').focus();
  };
  const handleFeatureRequest = () => {
    window.open(CANNY_URL, '_blank').focus();
  };
  const handleTutorials = () => {
    window.open(TUTORIALS_URL, '_blank').focus();
  };
  const handleResetOnboarding = () => {
    setStep(ONBOARDING_USER_STEP.SURVEY);
  };
  const handleHelpPortal = () => {
    window.open(HELP_URL, '_blank').focus();
  };

  return (
    <HelpList>
      <li>
        <Button
          icon={{
            name: 'help',
            height: '16px',
          }}
          width="100%"
          isCentered={false}
          label={'Help center'}
          useActiveUnderline={false}
          theme={buttonTheme}
          onClick={handleHelpPortal}
        />
      </li>
      <li>
        <Button
          icon={{
            name: 'dialog',
            height: '15px',
          }}
          width="100%"
          isCentered={false}
          label={'Chat'}
          useActiveUnderline={false}
          theme={buttonTheme}
          onClick={handleChat}
        />
      </li>
      <li>
        <Button
          icon={{
            name: 'settings',
            height: '15px',
          }}
          width="100%"
          isCentered={false}
          label={'Feature requests'}
          useActiveUnderline={false}
          theme={buttonTheme}
          onClick={handleFeatureRequest}
        />
      </li>
      <li>
        <Button
          icon={{
            name: 'playCircle',
            height: '15px',
          }}
          width="100%"
          isCentered={false}
          label={'Tutorials'}
          useActiveUnderline={false}
          theme={buttonTheme}
          onClick={handleTutorials}
        />
      </li>
      <li>
        <Button
          icon={{
            name: 'undo',
            height: '10px',
          }}
          width="100%"
          isCentered={false}
          label={'Restart Onboarding'}
          useActiveUnderline={false}
          theme={buttonTheme}
          onClick={handleResetOnboarding}
          disabled={workingMode !== 'default'}
        />
      </li>
    </HelpList>
  );
};

export default HelpPopover;
