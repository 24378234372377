import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { DEFAULT_TAKE } from '../global/constants';
import { usePromotionStore } from './promotionStore';
import UserApi from '../global/userApi';
import { buildImageProxyUrl } from '../utils/url';
import { userStore } from './userStore';

/**
 * Currently there's two arrays and two data sources for the upload store.
 * First one is "uploads" which comes from the API. Even though we don't need to
 * save it on the Zustand store they are added to facilitate refactorning.
 * The second is "localUploads" which are the elements that the user uploads on the
 * current session. They are uploaded to the API but remain stored locally so
 * the uploads panel can show them as "prependElements".
 */

export const uploadStore = createVanilla((set, get) => ({
  // Set to store every upload key (Set avoids duplicates)
  allIds: new Set(),
  // Uploads coming from the API
  uploads: [],
  // Uploads done by the user on this session
  // They'll be added to the uploads panel via prependElements
  localUploads: [],
  // an error that happened during the last upload
  error: null,
  // store is currently fetching
  isFetching: false,

  take: DEFAULT_TAKE,
  cursor: null,

  fetchAndHandleLocal: async () => {
    const { take, cursor, allIds, isFetching } = get();

    if (cursor === -1 || isFetching) return;
    set({ isFetching: true });

    const { results, nextCursor } = await UserApi.getUploadedElements(
      take,
      cursor
    );

    const newResults = [];

    results.forEach((elem) => {
      const objectName = `api/${elem.objectName}`;

      // If the id Set already has this element (local upload)
      if (allIds.has(elem.id)) {
        // Filter the local uploads to remove current element
        const localUploadsWithoutResult = get().localUploads.filter(
          (l) => l.id !== elem.id
        );
        // Update localUploads without current element
        set({ localUploads: localUploadsWithoutResult });
      } else {
        // If the id Set does not contain the id, we add it
        allIds.add(elem.id);
      }

      newResults.push({
        ...elem,
        objectName,
        src: buildImageProxyUrl(objectName, { trimSVG: true, isUpload: true }),
        skipPopularityTracking: true,
      });
    });

    set({
      allIds,
      uploads: [...get().uploads, ...newResults],
      cursor: nextCursor ? nextCursor : -1,
      isFetching: false,
    });

    return {
      results: newResults,
      nextCursor,
    };
  },

  addAndFormatLocalUpload: (item) => {
    const allIds = get().allIds;
    const objectName = `api/${item.objectName}`;
    const object = {
      ...item,
      objectName,
      src: buildImageProxyUrl(objectName, { trimSVG: true, isUpload: true }),
      skipPopularityTracking: true,
      isLocalUpload: true,
    };
    if (!allIds.has(item.id)) {
      allIds.add(item.id);
      set({
        allIds: allIds,
        localUploads: [object, ...get().localUploads],
      });
    }
  },
  deleteUpload: (id) => {
    const allIds = get().allIds;
    allIds.delete(id);

    const newUploads = get().uploads.filter((u) => u.id !== id);
    const newLocalUploads = get().localUploads.filter((u) => u.id !== id);

    set({ allIds, uploads: newUploads, localUploads: newLocalUploads });

    UserApi.deleteUpload(id);
  },
  /**
   * set error object, by processing the error message
   */
  setError: (error) => {
    if (error === null) {
      return set({ error: null });
    }

    if (error === 'PAYLOAD_TOO_LARGE') {
      return set({
        error: {
          title: 'File too Large',
          message:
            'The file that you tried to upload was too large. Try to upload this file with a lower resolution or in a different format.',
        },
      });
    }

    if (error === 'UNSUPPORTED_FILE_TYPE') {
      return set({
        error: {
          title: 'Unsupported File Type',
          message:
            'The type of the file that you tried to upload is not supported. Only image files are accepted.',
        },
      });
    }

    if (error === 'UPLOAD_SPACE_LIMIT_REACHED') {
      const user = userStore.getState().user;
      const isPro = user ? user.plan === 'PRO' : false;

      const type = isPro ? 'proUploadLimitReached' : 'freeUploadLimitReached';

      usePromotionStore.getState().showUpgradeModal(type);
      return;
    }

    if (error === 'SVG_CONTAINS_EMBEDDED_DATA') {
      return set({
        error: {
          title: 'Unsupported File',
          message:
            'The SVG file that you tried to upload contains unsupported elements.',
        },
      });
    }

    return set({
      error: {
        title: 'Unknown Error',
        message: 'An unknown error occurred while processing your upload.',
        note: error,
      },
    });
  },
}));

export const uploadStoreSelector = {
  error: (state) => state.error,
  uploads: (state) => state.uploads,
  localUploads: (state) => state.localUploads,
  fetchAndHandleLocal: (state) => state.fetchAndHandleLocal,
  addAndFormatLocalUpload: (state) => state.addAndFormatLocalUpload,
  setError: (state) => state.setError,
};

export const useUploadStore = create(uploadStore);
