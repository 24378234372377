import React, { useMemo } from 'react';

import {
  Wrapper,
  Content,
  Table,
  Combination,
  Description,
  Divider,
} from './styles';
import { H4 } from '../../utilities/Typography/styles';
import { getOperatingSystem } from '../../../utils/detection';
import { getConfig } from './config';

/**
 * The ShortcutPanel
 */
const ShortcutPanel = () => {
  const control = getOperatingSystem() === 'MacOS' ? '⌘' : 'Ctrl';
  const altOrOpt = getOperatingSystem() === 'MacOS' ? '⌥' : 'Alt';

  const config = useMemo(() => {
    const _config = getConfig({ control, altOrOpt });
    const middleIndex = Math.ceil(_config.length / 2);
    const firstColumn = _config.slice(0, middleIndex);
    const secondColumn = _config.slice(middleIndex);
    return { 1: firstColumn, 2: secondColumn };
  }, [control, altOrOpt]);

  return (
    <Wrapper>
      <H4>Keyboard Shortcuts</H4>
      <Content>
        <Table>
          <tbody>
            {config[1].map(({ label, description }) => (
              <tr key={label}>
                <Combination>{label}</Combination>
                <Description>{description}</Description>
              </tr>
            ))}
          </tbody>
        </Table>
        <Divider />
        <Table>
          <tbody>
            {config[2].map(({ label, description }) => (
              <tr key={label}>
                <Combination>{label}</Combination>
                <Description>{description}</Description>
              </tr>
            ))}
          </tbody>
        </Table>
      </Content>
    </Wrapper>
  );
};

export default ShortcutPanel;
