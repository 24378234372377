import styled, { keyframes } from 'styled-components';
import { themeStyle } from '../../services/theming';
import { TooltipWrapper } from '../Tooltip/styles';
const BLUR = 10;

const blurIn = keyframes`
  from {
    filter: blur(${BLUR}px);
  }

  to {
    filter: blur(0px);
  }
`;

export const ArtboardWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  filter: blur(${(props) => (props.isLoading ? BLUR : '0')}px);
  animation: ${(props) => (!props.isLoading ? blurIn : '')} 0.5s linear;

  .canvas-container {
    width: 100% !important;
    height: 100%;
    outline: none;
  }
`;

export const PathTextAlert = styled(TooltipWrapper)`
  top: ${({ top }) => top - 18}px;
  left: ${({ left }) => left + 20}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: ${themeStyle.fontSize10};
`;

export const PathTextAlertTitle = styled.div`
  font-weight: ${themeStyle.fontBold};
`;

export const PathTextAlertContent = styled.div`
  font-weight: ${themeStyle.fontMedium};
`;
