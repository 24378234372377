import React, { memo, useEffect, useRef } from 'react';
import tinycolor from 'tinycolor2';
import { filter } from 'rxjs/operators';
import fabric from '../Artboard/fabric';
import { Board } from './style';
import { useNonWarpRenderer } from './hooks/useNonWarpRenderer';
import { useWarpRenderer } from './hooks/useWarpRenderer/useWarpRenderer';
import { useMockupTemplateLayersEffect } from './hooks/useMockupTemplateLayersEffect';
import { useMockupTemplate } from './hooks/useMockupTemplate';
import { useLayersLoading } from './hooks/useLayersLoading';
import { MockupBoardProps } from './types';
import {
  ADD_MOCKUP_TEMPLATE,
  HISTORY_RESET,
  SET_BACKGROUND,
} from '../../global/events';
import useDesignsStore, {
  designsStoreSelector,
} from '../../stores/designsStore';
import useHistory from '../../hooks/useHistory';
import { useCanvasEventHandlers } from './hooks/useCanvasEventHandlers';
import { updateCanvasSize } from '../../utils/editor/misc';
import { useHotkeys } from '../../hooks/mockup/useHotkeys';
import { useEventSubscriptions } from '../../hooks/mockup/useEventSubscriptions';
import { useResizeHandler } from '../../hooks/mockup/useResizeHandler';
import { useBlurHandler } from '../../hooks/mockup/useBlurHandler';
import { handleEventBusEvents } from './utils/handleEventBusEvents';
import { useDesignDataUrl } from './hooks/useDesignDataUrl';
import { mockupTemplateDimension } from '../MockupTemplateBoard/utils';
import analytics from '../../global/analytics';
import { MOCKUP_ADD } from '../../global/analytics/events';
import { useThemeChanged } from '../../hooks/useThemeChanged';
import { useGestures } from '../../hooks/useGestures';

const MockupBoard: React.FC<MockupBoardProps> = ({ bus, dispatch }) => {
  const activeDesign = useDesignsStore(designsStoreSelector.activeDesign);
  const boardElRef = useRef<HTMLDivElement | null>(null);
  const canvasElRef = useRef<HTMLCanvasElement | null>(null);
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const { historyRef } = useHistory(bus, dispatch);

  // init fabric canvas
  useEffect(() => {
    if (canvasRef.current || !canvasElRef.current || !boardElRef.current)
      return;

    const options = {
      artboardOptions: {
        width: mockupTemplateDimension.width,
        height: mockupTemplateDimension.height,
      },
    };
    canvasRef.current = new fabric.Canvas(canvasElRef.current, options);
    updateCanvasSize(boardElRef.current, canvasRef.current);
    canvasRef.current.uniScaleKey = null; // disallow non-uniform scaling
  }, []);

  // handle hot keys
  useHotkeys(canvasRef.current, dispatch);

  // handle events from fabric canvas
  useCanvasEventHandlers(canvasRef.current, historyRef.current, dispatch);

  // subscribe to event bus
  useEventSubscriptions(canvasRef.current, bus, handleEventBusEvents);

  useResizeHandler(boardElRef.current, canvasRef.current);
  useBlurHandler(dispatch);

  const [layersLoading, updateLayersLoading] = useLayersLoading();

  const { mockupTemplate, setActiveMockupId } =
    useMockupTemplate(updateLayersLoading);

  const designDataUrl = useDesignDataUrl(activeDesign.id);

  useMockupTemplateLayersEffect(
    canvasRef.current,
    mockupTemplate,
    updateLayersLoading
  );

  /*
    Here we use the non warp renderer or the warp renderer.
    As React doesn't allow conditional hooks, those two hooks contain early return on their own.
   */
  useNonWarpRenderer(
    canvasRef.current,
    designDataUrl,
    mockupTemplate,
    layersLoading,
    updateLayersLoading
  );
  useWarpRenderer(
    canvasRef.current,
    designDataUrl,
    mockupTemplate,
    layersLoading,
    updateLayersLoading
  );

  const loading =
    layersLoading.designLayer ||
    layersLoading.sceneLayer ||
    layersLoading.lightBlendLayer ||
    layersLoading.darkBlendLayer;

  useEffect(() => {
    const mockupBoardEvents = bus.pipe(
      filter(({ key }) => key === ADD_MOCKUP_TEMPLATE)
    );

    const addMockupSubscription = mockupBoardEvents.subscribe(({ val }) => {
      if (!loading) {
        analytics.track(MOCKUP_ADD, {
          mockupId: val,
        });
        setActiveMockupId(val as number);
      }
    });

    return (): void => {
      addMockupSubscription.unsubscribe();
    };
  }, [loading, bus, setActiveMockupId]);

  // set background color on initialization
  useEffect(() => {
    if (canvasRef.current) {
      // prevent canvas from rendering intermediate steps during load
      canvasRef.current.preventRenderAll = loading;
    }

    if (loading) return;

    // reset history after layers loaded
    // only if there is history to reset
    if (historyRef.current.getState()) {
      dispatch(HISTORY_RESET, undefined);
    }

    dispatch(SET_BACKGROUND, {
      color: tinycolor(activeDesign.state.artboardOptions.fill).toHexString(),
    });
  }, [dispatch, activeDesign, loading, historyRef]);

  useGestures(canvasRef.current, boardElRef.current, dispatch);

  useThemeChanged(canvasRef.current);

  return (
    <>
      <Board ref={boardElRef} $loading={loading}>
        <canvas ref={canvasElRef} />
      </Board>
    </>
  );
};

export default memo(MockupBoard);
