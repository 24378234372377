import {
  DEV_VISUALIZE_COORDS,
  DEV_OUTLINE_BOUNDING_RECT,
  DEV_VISUALIZE_POINTS,
  DEV_SAVE_TEXT_LAYOUT,
} from '../../../global/events';

import Api, { CMS_API_URL } from '../../../global/api';

import { getElementById } from '../../../utils/groupStructure';
import rasterizeImage from '../../../helpers/rasterizeImage/rasterizeImage';
import { useUserStore } from '../../../stores/userStore';
import { getBounds } from '../../../utils/editor/objects';

export default function (event, canvas) {
  const value = event?.val;
  const activeObject = canvas.getActiveObject();
  switch (event.key) {
    case DEV_OUTLINE_BOUNDING_RECT:
      activeObject && activeObject.devOutlineBoundingRect(value);
      break;
    case DEV_VISUALIZE_COORDS:
      activeObject && activeObject.devVisualizeCoords(value);
      break;
    case DEV_VISUALIZE_POINTS:
      const devSettings = {
        ...canvas.devSettings,
        displayPoints: event.val.displayPoints,
      };
      canvas.devSettings = devSettings;
      canvas.requestRenderAll();
      break;
    case DEV_SAVE_TEXT_LAYOUT:
      const objects = [];
      const selection = canvas.getActiveObjects();

      const activeObj = canvas.getActiveObject();

      // In case there's nothing selected we avoid canvas errors
      if (!selection.length || !activeObj) {
        return;
      }
      const crop = getBounds(activeObj, true);

      const structureSelection = canvas._selectedElements;

      const structuresToCopy = [];
      structureSelection.forEach((id) => {
        const element = getElementById(canvas.groupStructure, id);
        if (element) structuresToCopy.push(element);
      });

      canvas.discardActiveObject();

      selection.forEach((obj) => {
        const toObj = obj.toObject();
        objects.push(toObj);
      });

      const layoutPreview = rasterizeImage.getDataUrl(canvas, {
        format: 'png',
        removeBackground: true,
        forViewport: true,
        cropBox: crop,
        // Only selected objects will be saved on the preview image.
        visibleObjectIds: objects.map((s) => s.id),
        plan: useUserStore.getState().user?.plan,
      });

      const res = Api.__saveTextLayout(
        { objects, structure: structuresToCopy },
        layoutPreview
      );
      res.then((r) => {
        if (r.layout?.id)
          window.open(`${CMS_API_URL}/layouts/${r.layout.id}`, '_blank');
      });

      break;
    default:
      console.warn('Unknown Developer  event', event?.key, event?.val);
  }
}
