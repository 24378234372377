import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { SwitchSize } from './types';

interface ThumbProps {
  size: SwitchSize;
  checked: boolean;
  disabled?: boolean;
  shadow: string;
}

interface RailProps {
  width: number;
  height: number;
  disabled?: boolean;
  checked: boolean;
}

export const Wrapper = styled.div<{ padding: string }>`
  display: flex;
  flex: auto;
  justify-content: flex-end;
  padding: ${(props) => props.padding};
`;

const getThumbSize = (size: SwitchSize): number => {
  return size.height - 2 * size.horizontalInnerPadding * (5 / 4);
};

// utility function to get thumb color from props
const getThumbColor = (props: { checked?: boolean }) => {
  if (props.checked) return themeStyle.inkMain;
  return themeStyle.varInkMedium;
};

// utility function to get thumb color from props
const getRailColor = (props: RailProps, hover = false) => {
  if (props.checked) return themeStyle.varBrandMedium;
  if (hover && !props.disabled) return themeStyle.varInkSuperLight;
  return themeStyle.varInkLight;
};

export const ThumbStyle = styled.div<ThumbProps>`
  position: absolute;
  top: ${({ size }) =>
    size.horizontalInnerPadding + (1 / 8) * size.horizontalInnerPadding}px;
  left: ${({ size }) => size.horizontalInnerPadding}px;

  width: ${({ size }) => getThumbSize(size)}px;
  height: ${({ size }) => getThumbSize(size)}px;
  border-radius: 50px;

  transition: background-color, transform 0.2s ease-in-out;
  background-color: ${getThumbColor};
  transform: translateX(
    ${({ checked, size }) =>
      checked
        ? `calc(${size.width}px - 2 * ${
            size.horizontalInnerPadding
          }px - ${getThumbSize(size)}px)`
        : '0'}
  );

  box-shadow: ${({ shadow }) => shadow};

  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const RailStyle = styled.div<RailProps>`
  position: relative;
  flex-shrink: 0; /* Don't shrink with growing label */
  transition: background-color, transform 0.2s ease-in-out;
  background-color: ${(props) => getRailColor(props)};

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 50px;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover ${ThumbStyle} {
    background-color: ${(props) => getThumbColor(props)};
  }

  &:hover {
    background-color: ${(props) => getRailColor(props, true)};
  }
`;

/** Utility style to display multiple switches in a vertical list */
export const SwitchList = styled.div`
  & > *:not(:last-child) {
    border-bottom: 1px solid ${themeStyle.varInkSuperLight};
  }
`;
