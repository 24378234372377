import fabric from '../index';
import {
  createWarpControlPointName,
  drawGirdOrHandleLine,
  drawWarpControlPoint,
  getWarpControlPoint,
  addWarpControlPoint,
  warpControlPointDefaultOptions,
  warpControlRootPointObjectType,
} from './utils';
import {
  WarpControlHandleDirection,
  WarpControlPointInitOptions,
} from './types';
import { themeStyle } from '../../../../services/theming';
import { cubicCurveTo, lineTo, moveTo } from '../../../../utils/path/commands';
import {
  getMovedPointAlongDirection,
  getVectorAngle,
} from '../../../../utils/geometry/vector';
import { getDistance } from '../../../../utils/geometry/point';
import { Point } from '../../../../types';

export const extendWarpControlRootPoint = (fabric: fabric): void => {
  /**
   * The class for warp control root point.
   * Instance properties:
   * @property {string} name: follows a pattern, useful for look-up
   * @property {number} i: on which row the point is in the warp grid
   * @property {number} j: on which column the point is in the warp grid
   * @property {{ x: number; y: number }} lastPosition:
   *  last absolute position, useful when moving
   * @property {{ x: fabric.Path; y: fabric.Path }} gridSegments:
   *  The warp grid is rendered as segments,
   *  each WarpControlRootPoint renders the grid segments to its right and bottom.
   */
  fabric.WarpControlRootPoint = fabric.util.createClass(fabric.Circle, {
    type: warpControlRootPointObjectType,

    initialize({
      left,
      top,
      i,
      j,
      locked = false,
    }: WarpControlPointInitOptions): fabric.WarpControlRootPoint {
      const options = {
        ...warpControlPointDefaultOptions,
        name: createWarpControlPointName(i, j),
        left,
        top,
        locked,
        fill: themeStyle.selectionBlue,
      };
      this.callSuper('initialize', options);

      this.i = i;
      this.j = j;
      this.updateLastPosition();
      this.initGridSegments();

      this.on('added', this.onAdded);
      this.on('moving', this.handleMove);

      return this;
    },

    onAdded(): void {
      this.bindHandlersOnCanvas();
    },

    bindHandlersOnCanvas(): void {
      const onObjectScale = (): void => {
        this.handleObjectScale();
      };
      this.canvas.on('object:scaling', onObjectScale);

      const onDblClick = (): void => {
        this.handleDblClick();
      };
      this.canvas.on('mouse:dblclick', onDblClick);
    },

    initGridSegments(): void {
      const attrs = {
        locked: true,
        fill: 'transparent',
        stroke: themeStyle.selectionBlue,
        objectCaching: false,
      };
      this.gridSegments = {
        x: new fabric.Path(),
        y: new fabric.Path(),
      };
      this.gridSegments.x.set(attrs);
      this.gridSegments.y.set(attrs);
    },

    updateLastPosition(): void {
      this.lastPosition = this.getAbsoluteCenterPoint();
    },

    /*
      Gets the handle points associated with this.
     */
    getHandlePoints(): {
      [key in WarpControlHandleDirection]?: fabric.WarpControlHandlePoint;
    } {
      const handlePoints: {
        [key in WarpControlHandleDirection]?: fabric.WarpControlHandlePoint;
      } = {};

      if (this.canvas) {
        Object.values(WarpControlHandleDirection).forEach(
          (direction: WarpControlHandleDirection): void => {
            const handle = getWarpControlPoint(
              this.canvas,
              this.i,
              this.j,
              direction
            );

            if (handle) {
              handlePoints[direction] = handle;
            }
          }
        );
      }

      return handlePoints;
    },

    hasHandlePoints(): boolean {
      return Object.keys(this.getHandlePoints()).length > 0;
    },

    handleMove(): void {
      // Move all the handle points along with this
      this.moveHandlePoints();
      this.updateLastPosition();
    },

    moveHandlePoints(): void {
      const position = this.getAbsoluteCenterPoint();
      const delta = position.subtract(this.lastPosition);
      const handlePoints = this.getHandlePoints();
      Object.values(handlePoints).forEach(
        (handlePoint: fabric.WarpControlHandlePoint): void => {
          handlePoint.moveWithRoot(delta);
        }
      );
    },

    handleObjectScale(): void {
      // Still werid that sometimes `this.canvas` does not exist
      if (!this.canvas) return;

      const isBeingScaled = this.canvas
        .getActiveObjects()
        .find((object: fabric.Object) => object.id === this.id);

      if (isBeingScaled) {
        this.handleMove();
      }
    },

    handleDblClick(): void {
      if (!this.canvas) return;

      if (this.canvas.getActiveObject() === this) {
        if (this.hasHandlePoints()) {
          this.removeHandlePoints();
        } else {
          this.addHandlePoints();
        }
      }

      this.canvas.fire('object:modified');
    },

    removeHandlePoints(): void {
      Object.values(this.getHandlePoints()).forEach(
        (handlePoint: fabric.WarpControlHandlePoint): void => {
          this.canvas.remove(handlePoint);
        }
      );
    },

    addHandlePoints(): void {
      const positions: {
        left?: Point;
        right?: Point;
        top?: Point;
        bottom?: Point;
      } = {};

      const rootPointOnTopPosition = getWarpControlPoint(
        this.canvas,
        this.i - 1,
        this.j
      )?.getAbsoluteCenterPoint();
      const rootPointOnRightPosition = getWarpControlPoint(
        this.canvas,
        this.i,
        this.j + 1
      )?.getAbsoluteCenterPoint();
      const rootPointOnBottomPosition = getWarpControlPoint(
        this.canvas,
        this.i + 1,
        this.j
      )?.getAbsoluteCenterPoint();
      const rootPointOnLeftPosition = getWarpControlPoint(
        this.canvas,
        this.i,
        this.j - 1
      )?.getAbsoluteCenterPoint();

      const thisPosition = this.getAbsoluteCenterPoint();

      if (rootPointOnTopPosition) {
        positions.top = getMovedPointAlongDirection(
          thisPosition,
          getVectorAngle(
            rootPointOnBottomPosition || thisPosition,
            rootPointOnTopPosition
          ),
          getDistance(thisPosition, rootPointOnTopPosition) / 3,
          true
        );
      }

      if (rootPointOnBottomPosition) {
        positions.bottom = getMovedPointAlongDirection(
          thisPosition,
          getVectorAngle(
            rootPointOnTopPosition || thisPosition,
            rootPointOnBottomPosition
          ),
          getDistance(thisPosition, rootPointOnBottomPosition) / 3,
          true
        );
      }

      if (rootPointOnLeftPosition) {
        positions.left = getMovedPointAlongDirection(
          thisPosition,
          getVectorAngle(
            rootPointOnRightPosition || thisPosition,
            rootPointOnLeftPosition
          ),
          getDistance(thisPosition, rootPointOnLeftPosition) / 3,
          true
        );
      }

      if (rootPointOnRightPosition) {
        positions.right = getMovedPointAlongDirection(
          thisPosition,
          getVectorAngle(
            rootPointOnLeftPosition || thisPosition,
            rootPointOnRightPosition
          ),
          getDistance(thisPosition, rootPointOnRightPosition) / 3,
          true
        );
      }

      Object.entries(positions).forEach(([direction, position]) => {
        const handlePoint = new fabric.WarpControlHandlePoint({
          i: this.i,
          j: this.j,
          left: position.x,
          top: position.y,
          direction,
        });
        addWarpControlPoint(this.canvas, handlePoint, true);
      });
    },

    /*
      This is an override of fabric function.
     */
    _set(key: string, value: unknown): void {
      // in some cases there are methods setting `hasControls` to be `true`,
      // but we don't want any controls for this object.
      if (key === 'hasControls') {
        this.callSuper('_set', key, false);
      } else {
        this.callSuper('_set', key, value);
      }
    },

    renderGridSegmentToRight(
      ctx: CanvasRenderingContext2D,
      rightHandle: undefined | fabric.WarpControlHandlePoint
    ): void {
      const rootPointOnRight = getWarpControlPoint(
        this.canvas,
        this.i,
        this.j + 1
      );
      const rootPointOnRightLeftHandle = getWarpControlPoint(
        this.canvas,
        this.i,
        this.j + 1,
        WarpControlHandleDirection.Left
      );

      if (!rootPointOnRight) return;

      let pathData: string;
      if (rightHandle || rootPointOnRightLeftHandle) {
        pathData =
          moveTo(this.getAbsoluteCenterPoint()) +
          cubicCurveTo(
            (rightHandle || this).getAbsoluteCenterPoint(),
            (
              rootPointOnRightLeftHandle || rootPointOnRight
            ).getAbsoluteCenterPoint(),
            rootPointOnRight.getAbsoluteCenterPoint()
          );
      } else {
        pathData =
          moveTo(this.getAbsoluteCenterPoint()) +
          lineTo(rootPointOnRight.getAbsoluteCenterPoint());
      }

      this.gridSegments.x.set({
        path: fabric.util.parsePath(pathData),
      });
      drawGirdOrHandleLine(ctx, this.canvas, this.gridSegments.x);
    },

    renderGridSegmentToBottom(
      ctx: CanvasRenderingContext2D,
      bottomHandle: undefined | fabric.WarpControlHandlePoint
    ): void {
      const rootPointOnBottom = getWarpControlPoint(
        this.canvas,
        this.i + 1,
        this.j
      );
      const rootPointOnBottomTopHandle = getWarpControlPoint(
        this.canvas,
        this.i + 1,
        this.j,
        WarpControlHandleDirection.Top
      );

      if (!rootPointOnBottom) return;

      let pathData: string;
      if (bottomHandle || rootPointOnBottomTopHandle) {
        pathData =
          moveTo(this.getAbsoluteCenterPoint()) +
          cubicCurveTo(
            (bottomHandle || this).getAbsoluteCenterPoint(),
            (
              rootPointOnBottomTopHandle || rootPointOnBottom
            ).getAbsoluteCenterPoint(),
            rootPointOnBottom.getAbsoluteCenterPoint()
          );
      } else {
        pathData =
          moveTo(this.getAbsoluteCenterPoint()) +
          lineTo(rootPointOnBottom.getAbsoluteCenterPoint());
      }

      this.gridSegments.y.set({
        path: fabric.util.parsePath(pathData),
      });
      drawGirdOrHandleLine(ctx, this.canvas, this.gridSegments.y);
    },

    renderGridSegments(ctx: CanvasRenderingContext2D): void {
      const handlePoints = this.getHandlePoints();

      this.renderGridSegmentToRight(
        ctx,
        handlePoints[WarpControlHandleDirection.Right]
      );
      this.renderGridSegmentToBottom(
        ctx,
        handlePoints[WarpControlHandleDirection.Bottom]
      );
    },

    /*
      This is an override of fabric function.
     */
    render(ctx: CanvasRenderingContext2D): void {
      if (!this.visible) return;
      drawWarpControlPoint(ctx, this.canvas, this);
      this.renderGridSegments(ctx);
    },

    /*
      This is an override of fabric function.
     */
    toObject(): void {
      // keep extra properties for initialization
      return this.callSuper('toObject', ['i', 'j']);
    },
  });

  fabric.WarpControlRootPoint.fromObject = (
    object: Record<string, unknown>,
    callback: (object: fabric.WarpControlHandlePoint) => void
  ): void => {
    const rootPoint = new fabric.WarpControlRootPoint(object);
    callback(rootPoint);
  };
};
