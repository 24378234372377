import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useContextSelector } from 'use-context-selector';

import Popover from '../../Popover/Popover';
import Button from '../../Button/Button/Button';
import IconButton from '../../Button/IconButton';
import { circledIconTheme } from '../../Icon/theme';
import Spacer from '../../Spacer/Spacer';
import {
  ADD_PHOTO,
  DRAGGING_ELEMENT,
  START_DRAGGING_ELEMENT,
  STOP_DRAGGING_ELEMENT,
} from '../../../global/events';
import api from '../../../global/api';
import { buildImageProxyUrl } from '../../../utils/url';
import { popoverTheme } from './theme';
import {
  OuterWrapper,
  PreviewImage,
  ButtonRow,
  InfoText,
  Wrapper,
} from './styles';
import useDragNDrop from '../../../hooks/useDragNDrop/useDragNDrop';
import leftMenuContext from '../../leftMenuContext';
import { toastSelector, useToastStore } from '../../../stores/toastStore';
import { UnsplashPhoto } from '../../../propTypes';
import { primarySmallTheme } from '../../Button/Button/theme';

export const UNSPLASH_PHOTO_WIDTH = 264;

const Element = ({ element, dispatch, ...props }) => {
  const wrapperRef = useRef();
  const imageRef = useRef();
  const previewURL = buildImageProxyUrl(element.urlFull, {
    width: UNSPLASH_PHOTO_WIDTH,
  });
  const fireToast = useToastStore(toastSelector.fire);
  const leftMenuExpanded = useContextSelector(leftMenuContext, (v) => {
    return v[0].expanded;
  });

  const addImage = useCallback(
    (val) => {
      api.flagUnsplashDownload(element.id, element.downloadLocation);
      val.objectName = element.urlFull;
      val.skipPopularityTracking = true;
      val.fireToast = leftMenuExpanded
        ? fireToast({ label: 'Photo Added', delay: 100 })
        : () => {};
      dispatch && dispatch(ADD_PHOTO, val);
    },
    [
      dispatch,
      element.id,
      element.downloadLocation,
      element.urlFull,
      fireToast,
      leftMenuExpanded,
    ]
  );

  const onStartDragging = () => {
    dispatch(START_DRAGGING_ELEMENT, {
      objectName: element.urlFull,
      type: 'illustration',
    });
  };

  const onDragging = (value) => {
    dispatch(DRAGGING_ELEMENT, value);
  };

  const onStopDragging = () => {
    dispatch(STOP_DRAGGING_ELEMENT);
  };

  const { renderOverlay, handlePointerMove } = useDragNDrop({
    wrapperRef,
    imageRef,
    preview: previewURL,
    onDrop: addImage,
    onStartDragging,
    onDragging,
    onStopDragging,
  });

  const infoContent = (
    <>
      <Spacer h="10px" />
      <InfoText>
        Photo by&nbsp;
        <a
          href={
            element.unsplashProfile + '?utm_source=kittl&utm_medium=referral'
          }
          target="_blank"
          rel="noreferrer"
        >
          {element.artistName}
        </a>
        &nbsp;on&nbsp;
        <a
          href="https://unsplash.com?utm_source=kittl&utm_medium=referral"
          target="_blank"
          rel="noreferrer"
        >
          Unsplash
        </a>
      </InfoText>
      <Spacer h="8px" />
      <Button
        label="Add to Artboard"
        width="100%"
        theme={primarySmallTheme}
        onClick={addImage}
      />
    </>
  );

  return (
    <OuterWrapper padding="5" elementHeight={element.elementHeight}>
      <ButtonRow>
        <Popover
          content={infoContent}
          align="start"
          placement="right"
          label="Photography"
          theme={popoverTheme}
          width="262px"
        >
          <IconButton
            iconName="info"
            isActive={true}
            height="24px"
            theme={circledIconTheme}
          />
        </Popover>
      </ButtonRow>
      <Wrapper ref={wrapperRef}>
        <PreviewImage
          draggable={false}
          src={previewURL}
          ref={imageRef}
          onPointerMove={handlePointerMove}
        />
      </Wrapper>
      {renderOverlay()}
    </OuterWrapper>
  );
};

Element.propTypes = {
  element: UnsplashPhoto,
  dispatch: PropTypes.func,
};

export default Element;
