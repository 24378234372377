import styled from 'styled-components';

export const Board = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .canvas-container {
    width: 100% !important;
    height: 100%;
    outline: none;
  }
`;
