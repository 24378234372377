import { useCallback, useRef } from 'react';

const emitIncrementInterval = 100;
const longPressTimeout = 500;
const speedUpTimeout = 2000;
const speedUpIncrementStep = 10;

/**
 * Hook to handle increment on long press.
 *
 * @param {function} onLongPressIncrement Callback to be executed every X amount of time.
 * @returns {{function, function}[]}
 * First returned function must be assigned to 'onMouseDown' and second
 * must be assigned to 'onMouseUp' and 'onMouseLeave'.
 */
const useLongPressIncrement = (
  onLongPressIncrement: (value: number) => void
): [() => void, () => void] => {
  const interval = useRef<NodeJS.Timer | null>(null);
  const increment = useRef<number>(0);
  const pressStartTimestamp = useRef<number>(0);

  // Creates the interval and executes the function
  const start = useCallback(() => {
    pressStartTimestamp.current = Date.now();
    interval.current = setInterval(() => {
      const timeElapsed = Date.now() - pressStartTimestamp.current;

      if (timeElapsed > speedUpTimeout) {
        increment.current = increment.current + speedUpIncrementStep;
      } else if (timeElapsed > longPressTimeout) {
        increment.current = increment.current + 1;
      } else {
        return;
      }

      onLongPressIncrement(increment.current);
    }, emitIncrementInterval);
  }, [onLongPressIncrement]);

  // Clears interval when it's not used anymore
  const clear = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    interval.current = null;
    increment.current = 0;
    pressStartTimestamp.current = 0;
  }, []);

  return [start, clear];
};

export default useLongPressIncrement;
