import styled from 'styled-components';
import { themeStyle } from '../../services/theming';

export const Option = styled.div<any>`
  height: 35px;
  background: ${({ selected }) =>
    selected ? themeStyle.varBrandSuperLight : themeStyle.varInkSuperLight};
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  color: ${({ selected }) =>
    selected ? themeStyle.varInkMain : themeStyle.varInkMedium};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    background: ${themeStyle.varBrandSuperLight};
    color: ${themeStyle.varInkMain};
  }
`;

export const OptionsContainer = styled.div.attrs<{ visible: number }>(
  ({ visible }) => ({
    style: {
      height: `${visible * 35 + visible - 1}px`,
    },
  })
)<{ visible: number }>`
  margin-top: 8px;

  ${Option}:first-child {
    border-radius: 5px 5px 0 0;
  }

  ${Option}:last-child {
    border-radius: 0 0 5px 5px;
    margin-bottom: 0;
  }

  transition: height ease-in-out 0.25s;
  overflow: hidden;
`;

export const LoaderWrapper = styled.div.attrs<{ visible: boolean }>(
  ({ visible }) => ({
    style: {
      height: visible ? '35px' : '0px',
    },
  })
)<{ visible: boolean }>`
  margin-top: 1px;
  border-radius: 0 0 5px 5px;
  background: ${themeStyle.varInkSuperLight};
  transition: height ease-in-out 0.25s;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background: ${themeStyle.varBrandSuperLight};
  }
`;
