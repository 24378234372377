import { isDebugActive } from '../../../utils/dev';
import { themeStyle } from '../../../services/theming';

import {
  MIN_ZOOM,
  MAX_ZOOM,
  EMPTY_STATE,
  DEPRECATED_DPMM,
  ARTBOARD_DPI,
  ONE_MM_IN_INCHES,
} from '../../../global/constants';

import Nprogress from 'nprogress';
import api from '../../../global/api';
import userApi from '../../../global/userApi';
import filterArtboardOptions from './artboardOptionsInterface';

import analytics from '../../../global/analytics';
import { ADD_TEMPLATE } from '../../../global/events';

import { updateArtboardPosition } from '../../../stores/onboardingStore';
import { menuStore } from '../../../stores/menuStore';
import { settingsStore } from '../../../stores/settingsStore';
import { commonFontsStore } from '../../../stores/commonFontsStore';
import useDesignsStore from '../../../stores/designsStore';
import { userFontsStore } from '../../../stores/userFontsStore';
import debounce from 'lodash/debounce';
import { getObjectById } from '../../../utils/editor/objects';
import { getTextureIndicatorId, showIndicator } from '../../../utils/dev/test';
import {
  getNonTrimViewBoardBackground,
  getTrimViewBoardBackground,
} from '../../../utils/editor/theme';

const { addFontsFromCanvas } = commonFontsStore.getState();

const LEFT_CLICK = 1;
const UPDATE_OBJECT_COORDS_DELAY = 250;

/*
  Reference constants to compute targetFindTolerance
  (which determines how far the cursor can be from a solid part of an object to be considered 'touching')
*/
const SELECTION_MASK_THRESHOLD = 10;
const SELECTION_MASK_THRESHOLD_SMALL = 1;

const defaultConfig = {
  title: 'New Project',
};

const settings = settingsStore.getState().settings;

const defaultArtboardOptions = {
  activeLayout: 'custom',
  width: 600,
  height: 400,
  fill: themeStyle.backgroundAlt,
  opacity: 1,
};

const DPI_VERSION = 1;
const REQUIRED_CONFIG = { dpiVersion: DPI_VERSION };

export default function (fabric) {
  // Add properties to toObject method
  fabric.StaticCanvas.prototype.toJSON = (function (toJSON) {
    return function () {
      // Remove clipPath and background from serialization
      const { clipPath, background, ...props } = toJSON.apply(this);
      return {
        config: { ...defaultConfig, ...this.config },
        artboardOptions: this.artboardOptions,
        overlayOptions: this.overlayTexture?.toObject() || null,
        groupStructure: this.groupStructure,
        colorPalette: this.getColorPalette(),
        ...props,
      };
    };
  })(fabric.StaticCanvas.prototype.toJSON);

  /**
   * overwrites fabrics _toObjects to 100% prevent artboard being exported
   * and adds a warning for debug mode to check when this happens
   */
  fabric.StaticCanvas.prototype._toObjects = function (
    methodName,
    propertiesToInclude
  ) {
    return this._objects
      .filter((object) => {
        if (object.type === 'artboard' && !object.excludeFromExport) {
          isDebugActive() && console.warn('Tried to export artboard', object);
          return false;
        }
        return !object.excludeFromExport;
      })
      .map(function (instance) {
        return this._toObject(instance, methodName, propertiesToInclude);
      }, this);
  };

  fabric.StaticCanvas.prototype.resetConfig = function () {
    this.config = REQUIRED_CONFIG;
  };

  fabric.StaticCanvas.prototype.modifyConfig = function (config) {
    this.config = { ...this.config, ...config };
  };

  // overwrite loadFromJson, to add groupStructure check and addArtboard before callback
  fabric.StaticCanvas.prototype.loadFromJSON = (function (loadFromJSON) {
    return function (json, callback, reviver) {
      // serialize if it wasn't already
      const serialized = typeof json === 'string' ? JSON.parse(json) : json;
      // reset background color
      serialized.background = getNonTrimViewBoardBackground();
      // prevent loading an artboard as an object
      if (serialized.objects?.length) {
        serialized.objects = serialized.objects.filter(
          (object) => object.type !== 'artboard'
        );
      }
      this.preventRenderAll = true;

      const extendedCallback = () => {
        this.overlayOptions = serialized.overlayOptions || null;
        this.initializeStaticObjects(() => {
          this.initGroupStructure(serialized);
          this.updateGroupStructure();
          this.updateObjectsGroupRelatedProperties();
          this.preventRenderAll = false;
          callback && callback(this);
        });
      };

      const extendedReviver = (data, instance, error) => {
        // startAngle and endAngle have changed in 5.0.0, this accounts for backwards compatibility
        if (parseInt(data.version.slice(0, 1), 10) < 5) {
          instance.startAngle = fabric.util.radiansToDegrees(data.startAngle);
          instance.endAngle = fabric.util.radiansToDegrees(data.endAngle);
        }

        reviver && reviver(data, instance, error);
      };

      return loadFromJSON.apply(this, [
        serialized,
        extendedCallback,
        extendedReviver,
      ]);
    };
  })(fabric.StaticCanvas.prototype.loadFromJSON);

  /**
   * Renders the canvas
   * modified to prevent any render when the preventRenderAll flag is active
   * @return {fabric.Canvas} instance
   */
  fabric.Canvas.prototype.renderAll = (function (renderAll) {
    return function () {
      if (!this.preventRenderAll) {
        return renderAll.apply(this);
      }
      return this;
    };
  })(fabric.Canvas.prototype.renderAll);

  /**
   * Clears all contexts (background, main, top) of an instance
   * modified to prevent any render when the preventRenderAll flag is active
   * @return {fabric.StaticCanvas} thisArg
   */
  fabric.StaticCanvas.prototype.clear = function () {
    this._objects.length = 0;
    this.backgroundImage = null;
    this.overlayImage = null;
    this.backgroundColor = '';
    this.overlayColor = '';
    if (this._hasITextHandlers) {
      this.off('mouse:up', this._mouseUpITextHandler);
      this._iTextInstances = null;
      this._hasITextHandlers = false;
    }
    // this if clause has been added
    if (!this.preventRenderAll) {
      this.clearContext(this.contextContainer);
      this.fire('canvas:cleared');
      this.renderOnAddRemove && this.requestRenderAll();
    }
    return this;
  };

  /**
   * load a state from json in two stages, by first initializing a minimal version with the artboard
   * and an image to preview and then loading the detailed state
   * @param {*} json
   * @param {String} src src to get the image from
   * @param {Function} callback
   * @param {boolean} standalone If true, object:modified is not fired, and other app related
   * side effects are not triggered, so that the function can be used in other contexts
   */
  fabric.Canvas.prototype.stagedLoadFromJSON = function (
    json,
    src,
    callback,
    standalone = false
  ) {
    if (!standalone) Nprogress.start();

    this.disposeWebGLResources();

    // serialize if it wasn't already
    let serialized = typeof json === 'string' ? JSON.parse(json) : json;

    const previousConfig = this.config || {};

    const loadActualState = async (canvas) => {
      if (!standalone) Nprogress.set(0.1); // artboard and preview are loaded
      if (!serialized.objects) {
        // we don't have actual state, so we load it from the api
        if (serialized.designId) {
          const response = await userApi.getDesign(serialized.designId);

          if (response?.state) {
            if (!standalone) {
              useDesignsStore.getState().updateElement(response);
            }

            serialized = response.state;
            serialized.config = {
              ...previousConfig,
              ...serialized.config,
              designId: response.id,
              title: response.name,
              updatedAt: response.updatedAt,
            };
          }
        } else if (serialized.templateId) {
          const response = await api.getTemplate(serialized.templateId);

          if (response?.template?.state) {
            analytics.track(ADD_TEMPLATE, {
              label: response.template.name,
            });
            api.registerTemplateUse(serialized.templateId);

            if (response.template.groups.length) {
              menuStore.setState({
                templates: response.template.groups[0].category?.name,
              });
            }

            const {
              template: { state, id: templateId },
            } = response;

            // if there is a designId or folderId in the template, it should not be used here
            delete state.config?.designId;
            delete state.config?.folderId;

            serialized = state;
            serialized.config = {
              ...previousConfig,
              ...serialized.config,
              title: response.template.name,
              templateId: templateId,
            };
          }
        } else if (serialized.shareId) {
          const response = await userApi.getShare(serialized.shareId);

          if (response?.state) {
            const state = response.state;

            // if there is a designId or folderId in the state, it should not be used here
            delete state.config?.designId;
            delete state.config?.folderId;

            serialized = state;
            serialized.config = {
              ...previousConfig,
              ...serialized.config,
              title: response.name,
            };
          }
        }
      }

      if (!serialized?.objects) {
        // handle the case that a template was deleted before the
        // user adds it to the canvas
        serialized = EMPTY_STATE;
      } else {
        const fontNames = serialized.objects.reduce((acc, val) => {
          if (val.type === 'pathText') {
            if (!acc.includes(val.fontFamily)) {
              return [...acc, val.fontFamily];
            }
          }
          return acc;
        }, []);

        // Register font names in fonts store to know what user fonts to load first
        userFontsStore.getState().setHeaderUserFonts(fontNames);
      }

      if (!standalone) Nprogress.set(0.2); // artboard and preview are loaded
      const progressInc = 0.7 / (serialized.objects?.length || 1);

      addFontsFromCanvas(serialized.objects);

      // flag illustrations to not load preview
      if (serialized.objects.length) {
        serialized.objects.forEach((object) => {
          if (['illustration', 'basicShape', 'mask'].includes(object.type)) {
            object.skipPreview = true;
          }
        });
      }

      setTimeout(
        () => {
          if (!standalone) Nprogress.set(0.2); // artboard and preview are rendered
          canvas.loadFromJSON(
            serialized,
            (canvas) => {
              // Scale project if needed
              let { unit } = canvas.artboardOptions;
              let dpiVersion = canvas.config?.dpiVersion;

              unit = unit || 'px';
              dpiVersion = dpiVersion || 0;

              if (unit === 'mm' && !dpiVersion) {
                this.selectIds(
                  canvas
                    .getObjects()
                    .filter((object) => object.type !== 'artboard')
                    .map((object) => object.id)
                );
                const activeSelection = this._activeObject;

                const { top, left } = activeSelection;

                const scaleFactor =
                  (ARTBOARD_DPI / DEPRECATED_DPMM) * ONE_MM_IN_INCHES;
                canvas.modifyArtboard({
                  width: canvas.artboardOptions.width * scaleFactor,
                  height: canvas.artboardOptions.height * scaleFactor,
                  isChanging: standalone,
                });

                activeSelection.scale(scaleFactor);
                activeSelection.set({
                  left: left * scaleFactor,
                  top: top * scaleFactor,
                });

                activeSelection.fire('scaled');
                activeSelection.fire('moved');

                this.discardActiveObject();
              }

              const config = canvas.config || {};

              // Make sure config includes REQUIRED_CONFIG
              this.resetConfig();
              this.modifyConfig(config);

              canvas.lastColorPalette = null; // Reset backup colors for color palette presets
              canvas.centerArtboard();
              !standalone && canvas.fire('object:modified');
              canvas.renderAll();
              callback && callback();
              if (!standalone) Nprogress.done();
            },
            () => {
              // reviver is called when an object is loaded
              if (!standalone) Nprogress.inc(progressInc);
            }
          );
        },
        17 // 1000/60, wait a frame
      );
    };

    const { artboardOptions } = serialized;
    this.loadFromJSON(
      {
        artboardOptions,
        groupStructure: { children: [] },
      },
      (newCanvas) => {
        newCanvas.centerArtboard();

        if (src) {
          const isDataURL = src.indexOf('data:') === 0;
          fabric.Image.fromURLRetry(
            src,
            (img) => {
              img.set({
                left: 0,
                top: 0,
                excludeFromExport: true,
                selectable: false,
                evented: false,
              });
              img.scaleToHeight(artboardOptions.height);
              newCanvas.add(img);
              newCanvas.renderAll();
              loadActualState(newCanvas);
            },
            {
              srcFromAttribute: !isDataURL,
              crossOrigin: 'anonymous',
            }
          );
        } else {
          newCanvas.renderAll();
          loadActualState(newCanvas);
        }
      }
    );
  };

  fabric.Canvas.prototype.getActiveObject = (function (getActiveObject) {
    return function () {
      const activeObject = getActiveObject.apply(this);
      if (activeObject?.linkedToObject) {
        return getObjectById(activeObject.linkedToObject, this.getObjects());
      }
      return activeObject;
    };
  })(fabric.Canvas.prototype.getActiveObject);

  fabric.Canvas.prototype.getActiveObjects = (function (getActiveObjects) {
    return function () {
      const objectIds = this.getObjects().map((obj) => obj.id);
      const aObjects = getActiveObjects
        .apply(this)
        .map((activeObj) => {
          if (activeObj.linkedToObject) {
            const obj = getObjectById(
              activeObj.linkedToObject,
              this.getObjects()
            );
            return obj;
          }
          return activeObj;
        })
        .filter((object) => object);
      /*
        Map can mess up object ordering and it is important that we return them in the same order as fabric's getActiveObjects
      */
      aObjects.sort(
        (a, b) => objectIds.indexOf(a.id) - objectIds.indexOf(b.id)
      );
      return aObjects;
    };
  })(fabric.Canvas.prototype.getActiveObjects);

  fabric.StaticCanvas.prototype.allObjects = function () {
    return this.getObjects().filter(
      (obj) => obj.type !== 'artboard' && obj.type !== 'overlayTexture'
    );
  };

  fabric.Canvas.prototype.initialize = (function (initialize) {
    return function (el, options) {
      options || (options = {});
      options.preserveObjectStacking = true; // important, otherwise stacking is kinda weird
      options.fireRightClick = true;
      options.perPixelTargetFind = true;
      options.targetFindTolerance = this.getTargetFindTolerance();

      options.hasMovingObject = false;

      options.uniformScaling = true;

      // Add style to selection box
      options.selectionColor = themeStyle.selectionBlue10;
      options.selectionBorderColor = themeStyle.selectionBlue;
      options.selectionLineWidth = 1;

      options.controlsAboveOverlay = true;

      options.backgroundColor = getNonTrimViewBoardBackground();

      options.artboardOptions = {
        ...defaultArtboardOptions,
        ...options.artboardOptions,
      };
      options.overlayOptions = options.overlayOptions || null;

      initialize.call(this, el, options);
      this.initializeStaticObjects();
      this.initGroupStructure(); // init empty group structure
      this.updateObjectsGroupRelatedProperties();

      /*
        This is used to keep track of the coordinates of the last mouse down event.
        Whenever there is a mouse up event, outdated is set to true.

        The purpose of this is to avoid micro drags.
      */
      this.mouseDownEventScreenCoordinates = {
        outdated: true,
      };

      this._selectedElements = [];

      this.on('mouse:up:before', () => {
        if (this.mouseUpActions) {
          for (const [id, callback] of Object.entries(this.mouseUpActions)) {
            callback();
            this.unregisterForMouseUp(id);
          }
        }
      });

      return this;
    };
  })(fabric.Canvas.prototype.initialize);

  // apply enable dragging property on setting it
  fabric.Canvas.prototype._set = (function (_set) {
    return function (key, value) {
      if (key === 'draggingEnabled') {
        const activeObj = this.getActiveObject();
        if (value) {
          if (!this.get('draggingEnabled')) {
            this.set('selection', false); // disable group selection
            this.set('defaultCursor', 'grab');
            this.setCursor('grab'); // setCursor is needed to change cursor immediately
            if (activeObj) {
              activeObj.set('hasControls', false);
              activeObj.set('selectable', false);
              activeObj.set('lockMovementX', true);
              activeObj.set('lockMovementY', true);
            }
            this.allObjects().forEach((obj) => obj.set('selectable', false));
            this.artboard.set('selectable', false);
          }
        } else {
          this.set('isDragging', false);
          this.set('selection', true);
          this.set('defaultCursor', 'default');
          this.setCursor('default');
          // reset to locked properties
          if (activeObj) {
            if (activeObj.type !== 'artboard') {
              activeObj.set('locked', activeObj.locked);
            } else {
              activeObj.set('selectable', true);
            }
          }
          this.allObjects().forEach((obj) =>
            obj.set('selectable', !obj.get('locked'))
          );
          this.artboard.set('selectable', true);
        }
      }

      if (key === 'artboardOptions') {
        if (value) {
          const filtered = filterArtboardOptions(value);
          return _set.apply(this, [key, filtered]);
        }
      }

      return _set.apply(this, [key, value]);
    };
  })(fabric.Canvas.prototype._set);

  fabric.StaticCanvas.prototype.modifyArtboard = function ({
    isChanging,
    ...options
  }) {
    const artboardOptions = { ...this.artboardOptions, ...options };
    this.set('artboardOptions', artboardOptions);

    if (!this.artboard) {
      this.initializeStaticObjects();
      return false;
    }

    this.artboard.set(options);
    this.artboard.set('dirty', true);
    !isChanging && this.fire('object:modified');

    if (this.overlayTexture && (options.width || options.height)) {
      this.overlayTexture.scaleToArtboard();
    }
  };

  /**
   * Modify the current overlay
   */
  fabric.StaticCanvas.prototype.modifyOverlay = function ({
    isChanging,
    ...options
  }) {
    const overlayOptions = { ...this.overlayOptions, ...options };
    this.overlayOptions = overlayOptions;

    if (!this.overlayTexture) {
      this.initializeStaticObjects();
      return;
    }

    const isAlphaMaskMode = overlayOptions.mode === 'destination-out';

    const updateTextureFromOptions = (texture) => {
      const callback = () => {
        texture.set({
          opacity: overlayOptions.opacity / 100,
          globalCompositeOperation: overlayOptions.mode,
          hidden: overlayOptions.hidden,
        });

        texture.set('dirty', true);
        !isChanging && this.fire('object:modified');
        this.requestRenderAll();
        showIndicator(
          getTextureIndicatorId({
            texture: overlayOptions.texture,
            opacity: overlayOptions.opacity / 100,
            globalCompositeOperation: overlayOptions.mode,
          })
        );
      };

      if (options.texture || isAlphaMaskMode !== texture.isAlphaMask) {
        // Then we need to update alpha mask mode
        texture.toggleAlphaMask(isAlphaMaskMode, callback);
      } else {
        callback();
      }
    };

    if (options.texture) {
      // update texture with image change
      this.overlayTexture.setTexture(options.texture, updateTextureFromOptions);
    } else {
      updateTextureFromOptions(this.overlayTexture);
    }
  };

  fabric.StaticCanvas.prototype.initializeStaticObjects = function (cb) {
    const artboardOptions = {
      ...settings,
      ...defaultArtboardOptions,
      ...this.artboardOptions,
    };
    this.set('artboardOptions', artboardOptions);
    this.overlayOptions = this.overlayOptions || null;

    let artboard;
    if (this.artboard) {
      artboard = this.artboard;
      this.remove(artboard);

      artboard.set(artboardOptions);
    } else {
      artboard = new fabric.Artboard(artboardOptions);
    }

    // add artboard to canvas
    this.insertAt(artboard, 0);
    this.artboard = artboard;

    artboard.setCoords();

    if (this.overlayOptions) {
      const overlayOptions = this.overlayOptions;
      this.overlayTexture = new fabric.OverlayTexture(
        this.overlayOptions.texture,
        {
          opacity: this.overlayOptions.opacity / 100,
          globalCompositeOperation: this.overlayOptions.mode,
          hidden: this.overlayOptions.hidden,
          canvas: this,
          renderClip: this.overlayOptions.renderClip,
          isAlphaMask: this.overlayOptions.isAlphaMask,
        },
        (target) => {
          const callback = () => {
            this.fire('object:modified');
            this.requestRenderAll();
            cb && cb();
            // By the time the texture is rendered, it could happen that it got removed;
            // so we keep a reference of the original options to use here.
            showIndicator(
              getTextureIndicatorId({
                texture: overlayOptions.texture,
                opacity: overlayOptions.opacity / 100,
                globalCompositeOperation: overlayOptions.mode,
              })
            );
          };

          if (target.isAlphaMask) {
            target.toggleAlphaMask(true, callback);
          } else {
            callback();
          }
        }
      );
    } else {
      this.overlayTexture = null;
      cb && cb();
    }
  };

  /**
   * Removes object from the canvas by ids
   * @param {array} ids - object ids to remove
   */
  fabric.Canvas.prototype.removeObjectsByIds = function (ids) {
    const idsSet = new Set(ids);
    const allObjects = this.allObjects();
    idsSet.forEach((id) => {
      const object = getObjectById(id, allObjects);
      this.remove(object);
    });
    this.onAddRemoveObject();
  };

  /**
   * Override `_createActiveSelection` to avoid firing more than one batch of
   * selection events during selection, because of our custom group selection
   */
  fabric.Canvas.prototype._createActiveSelection = (function (
    _createActiveSelection
  ) {
    return function (target, e) {
      const group = this._createGroup(target);
      this._hoveredTarget = group;
      this._setActiveObject(group, e);
    };
  })(fabric.Canvas.prototype._createActiveSelection);

  fabric.Canvas.prototype.updateSelectionLock = function () {
    const activeObject = this.getActiveObject();
    if (activeObject && activeObject.type === 'activeSelection') {
      const isLocked = this.shouldLockSelection();
      activeObject.set('hasControls', !isLocked);
      activeObject.set('lockMovementX', isLocked);
      activeObject.set('lockMovementY', isLocked);
    }
  };

  /**
   * utility function to calculate a zoom level at which the artboard
   * is completely visible when centered on the canvas
   */
  fabric.Canvas.prototype._getCenteredArtboardZoom = function () {
    // 0.7 and 0.8 are used to make sure that the artboard is completly
    // visible and has a padding of at least 10% vertically and 15% horizontally.
    // We use more padding horizontally, since the UI hides more space on the left and right.
    const widthRatio = (this.width / this.artboard.width) * 0.7;
    const heightRatio = (this.height / this.artboard.height) * 0.8;

    return Math.min(widthRatio, heightRatio);
  };

  fabric.Canvas.prototype.adjustZoom = function (newZoom) {
    if (!this.artboard) {
      return newZoom;
    }

    // calculate ratio between canvas and artboard, so that it is possible to still
    // see the whole artboard, even when it is larger then the canvas (=window) size
    const centeredArtboardZoom = this._getCenteredArtboardZoom();
    const minZoom = Math.min(centeredArtboardZoom, MIN_ZOOM);

    return Math.max(minZoom, Math.min(newZoom, MAX_ZOOM));
  };

  const VERTICAL_PADDING = 1.5;
  fabric.Canvas.prototype.centerOnObjectInTopPart = function (
    id,
    topPart = 0.5
  ) {
    const object = getObjectById(id, this._objects);
    if (!object) return;

    let zoom = this.getZoom();
    const vpt = this.viewportTransform;

    let objectHeight = object.height * object.scaleY * zoom;
    // adjust zoom to fit object easily in top half
    if (objectHeight * VERTICAL_PADDING > this.height * topPart) {
      zoom =
        (this.height * topPart) /
        (object.height * object.scaleY * VERTICAL_PADDING);

      // set zoom
      vpt[0] = zoom;
      vpt[3] = zoom;

      // update height used for viewport transform
      objectHeight = object.height * object.scaleY * zoom;
    }

    const objectWidth = object.width * object.scaleX * zoom;

    vpt[4] = (this.width - objectWidth) / 2 - object.left * zoom;
    vpt[5] = (this.height * topPart - objectHeight) / 2 - object.top * zoom;

    this.setViewportTransform(vpt);
  };

  fabric.Canvas.prototype.centerArtboard = function () {
    if (!this.artboard) return;

    const zoom = this._getCenteredArtboardZoom();
    this.setZoom(zoom);

    const vpt = this.viewportTransform;
    vpt[4] = (this.width - this.artboard.width * zoom) / 2;
    vpt[5] = (this.height - this.artboard.height * zoom) / 2;

    this.requestRenderAll();
    updateArtboardPosition(
      this.artboard,
      this.getZoom(),
      this.viewportTransform
    );
  };

  fabric.Canvas.prototype.zoomToPoint = (function (zoomToPoint) {
    return function (point, value) {
      zoomToPoint.bind(this)(point, value);
      this.updateObjectsCoords();
      this.targetFindTolerance = this.getTargetFindTolerance();
      menuStore.getState().setZoomDebounced(value);
    };
  })(fabric.Canvas.prototype.zoomToPoint);

  fabric.Canvas.prototype.setViewportPos = function (posX, posY) {
    const vpt = this.viewportTransform;
    const limitedPos = this.getViewportPos(posX, posY);
    vpt[4] = limitedPos.x;
    vpt[5] = limitedPos.y;
    this.updateObjectsCoords();
    this.targetFindTolerance = this.getTargetFindTolerance();
  };

  fabric.Canvas.prototype.getViewportPos = function (posX, posY, zoom) {
    if (!this.artboard) {
      return { x: posX, y: posY };
    }

    // the viewport should contain at least a quarter of the artboard
    zoom = zoom || this.getZoom();

    const minX = -this.artboard.width * zoom + this.width / 2;
    const maxX = this.width - this.width / 2;
    const limitedPosX = Math.max(minX, Math.min(posX, maxX));

    const minY = -this.artboard.height * zoom + this.height / 2;
    const maxY = this.height - this.height / 2;
    const limitedPosY = Math.max(minY, Math.min(posY, maxY));
    return { x: limitedPosX, y: limitedPosY };
  };

  fabric.Canvas.prototype.resetViewport = function () {
    const vpt = this.viewportTransform;
    this.setViewportPos(vpt[4], vpt[5]);
  };

  /**
   * Custom function that tries to render controls on upper canvas
   * @param {CanvasRenderingContext2D} ctx
   */
  fabric.Canvas.prototype._renderControls = function (ctx) {
    // Skip control rendering if `_activeObject` has no canvas (this happens when empty textbox is deselected)
    if (!this._activeObject?.canvas) return;
    // CustomTextbox (inherited from Textbox) uses upper canvas to render its controls by default,
    // so it can't be used if CustomTextbox is active as it breaks the rendering of controls
    if (this.contextTop && this._activeObject?.type !== 'CustomTextbox') {
      this.drawControls(this.contextTop);
      this.contextTopDirty = true;
    } else {
      this.drawControls(ctx);
    }
  };

  /**
   * @override
   * Renders background, objects, overlay and controls.
   * Overwritten to allow TrueRender, overlay rendering and modified controls rendering
   * @param {CanvasRenderingContext2D} ctx
   * @param {Array} objects to render
   * @return {fabric.Canvas} instance
   * @chainable
   */
  fabric.StaticCanvas.prototype.renderCanvas = function (ctx, objects) {
    const v = this.viewportTransform;
    this.cancelRequestedRender();

    // block below is our custom code
    // if `renderOnlyControls` flag is set, only controls are rendered, everything else is skipped.
    if (this.renderOnlyControls) {
      this.renderOnlyControls = false;
      this._renderControls(ctx);
      return;
    }

    const thereIsOverlayTexture =
      this.overlayTexture && !this.overlayTexture.hidden;

    const overlayTextureIsClipped =
      thereIsOverlayTexture && this.overlayTexture.renderClip;

    const overlayTextureIsAlphaMask =
      thereIsOverlayTexture && this.overlayTexture.isAlphaMask;

    this.calcViewportBoundaries();
    this.clearContext(ctx);

    // Update size of canvases used for TrueRender
    this.updateTrueCanvas();

    // Set image smoothing for canvas
    fabric.util.setImageSmoothing(ctx, this.imageSmoothingEnabled);

    this.fire('before:render', { ctx: ctx });
    ctx.save();

    // Transform canvas with viewport transform, once for all rendering process
    ctx.transform(...v);

    // If the overlay is an alpha mask, the clipping is dealt with later-on
    if (overlayTextureIsClipped && !overlayTextureIsAlphaMask) {
      // If the overlay texture is using clip render:

      // Update size of clip mask
      this.updateForegroundClipMaskCanvas();

      // Set image smoothing for clip canvas
      fabric.util.setImageSmoothing(
        this._foregroundClipMaskContext,
        this.imageSmoothingEnabled
      );

      // Save state of clip canvas
      this._foregroundClipMaskContext.save();

      // Transform clip canvas with viewport transform, once for all rendering process
      this._foregroundClipMaskContext.transform(...v);

      // Remove artboard from objects
      objects = objects.filter((obj) => obj.type !== 'artboard');

      // Render artboard separately
      this.artboard && this.artboard.render(ctx);

      // Render objects to context, and populate
      // _foregroundClipMask so that it can later be used by the texture to be clipped
      this.renderObjectsAndForegroundClipMask(objects, ctx);

      // Render the texture using clipping
      this.renderWithClip(this.overlayTexture, ctx);

      // Restore state of clip canvases
      this._foregroundClipMaskContext.restore();
    } else {
      // Else render normally
      this._renderObjects(ctx, objects);
      if (thereIsOverlayTexture) {
        this.overlayTexture.render(ctx);
      }
    }

    if (overlayTextureIsClipped && overlayTextureIsAlphaMask) {
      // Re-render artboard to achieve the "clip" effect
      const artboardCompositeOperation = this.artboard.globalCompositeOperation;
      this.artboard.globalCompositeOperation = 'destination-over';
      this.artboard.render(ctx);
      this.artboard.globalCompositeOperation = artboardCompositeOperation;
    }

    // Render transparency grid after objects to avoid potential alpha-masks
    // destroying it
    if (this.artboard && !this.artboard.removeTransparencyGrid) {
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      this.artboard.transform(ctx);
      this.artboard.renderTransparencyGrid(ctx);
      ctx.restore();
    }

    this.artboard && this.artboard.renderGrid(ctx); // <-- This line was added

    ctx.restore();

    if (!this.controlsAboveOverlay && this.interactive) {
      this._renderControls(ctx); // <-- this line was modified
    }

    const { trimView } = settingsStore.getState().settings;
    if (this.artboard && trimView) {
      // instead of clipping the canvas with the artboard, we now draw
      // rects around it, to cover overflowing objects
      const vpt = this.artboard.getViewportTransform();
      const zoom = vpt[0];
      const artboardWidth = this.artboard.width * zoom;
      // size is rounded to avoid tiny offsets between the rects
      const artboardHeight = Math.round(this.artboard.height * zoom);
      ctx.save();

      ctx.fillStyle = getTrimViewBoardBackground();

      // top and bottom rects cover the whole width above and below the artboard
      const top = Math.round(vpt[5]);
      if (top > 0) {
        ctx.fillRect(0, 0, this.width, top);
      }
      const bottom = this.height - top - artboardHeight;
      if (bottom > 0) {
        ctx.fillRect(0, top + artboardHeight, this.width, bottom);
      }

      // left and right rects cover the horizontal space next to the artboard
      // and between the top and bottom rects
      const left = vpt[4];
      if (left > 0) {
        ctx.fillRect(
          0,
          Math.max(top, 0),
          left,
          artboardHeight + Math.min(top, 0)
        );
      }
      const right = this.width - left - artboardWidth;
      if (right > 0) {
        ctx.fillRect(
          left + artboardWidth,
          Math.max(top, 0),
          right,
          artboardHeight + Math.min(top, 0)
        );
      }
      ctx.restore();
    }

    // Background is rendered after everything
    // to allow artboard to be rendered with globalCompositeOperation = 'destination-over'
    // i.e if background was rendered before artboard, then artboard would be rendered
    // below it, and would not be visible
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    this._renderBackground(ctx);
    ctx.restore();

    this._renderOverlay(ctx);
    this.artboard && this.artboard.renderBorder(ctx); // <-- This line was added
    if (this.controlsAboveOverlay && this.interactive) {
      this._renderControls(ctx); // <-- this line was modified
    }
    this.fire('after:render', { ctx: ctx });
  };

  /**
   * @private
   * @override
   * We override this method to add `renderOnlyControls` flags when `down*` events are registered
   * Handle event firing for target and subtargets
   * @param {Event} e event from mouse
   * @param {String} eventType event to fire (up, down or move)
   * @param {fabric.Object} targetObj receiving event
   * @param {Number} [button] button used in the event 1 = left, 2 = middle, 3 = right
   * @param {Boolean} isClick for left button only, indicates that the mouse up happened without move.
   */
  fabric.Canvas.prototype._handleEvent = function (
    e,
    eventType,
    button,
    isClick
  ) {
    const target = this._target;
    const targets = this.targets || [];
    const options = {
      e: e,
      target: target,
      subTargets: targets,
      button: button || LEFT_CLICK,
      isClick: isClick || false,
      pointer: this._pointer,
      absolutePointer: this._absolutePointer,
      transform: this._currentTransform,
    };

    this.renderOnlyControls = eventType.startsWith('down'); // 'down' and 'down:before'

    if (eventType === 'up') {
      options.currentTarget = this.findTarget(e);
      options.currentSubTargets = this.targets;
    }

    this.fire('mouse:' + eventType, options);
    target && target.fire('mouse' + eventType, options);
    for (let i = 0; i < targets.length; i++) {
      targets[i].fire('mouse' + eventType, options);
    }
  };
  fabric.StaticCanvas.prototype.toCanvasElement = function (
    multiplier,
    cropping
  ) {
    multiplier = multiplier || 1;
    cropping = cropping || {};
    const scaledWidth = Math.round((cropping.width || this.width) * multiplier), // ---> we changed this
      scaledHeight = Math.round((cropping.height || this.height) * multiplier), // ---> we changed this
      zoom = this.getZoom(),
      originalWidth = this.width,
      originalHeight = this.height,
      newZoom = zoom * multiplier,
      vp = this.viewportTransform,
      translateX = (vp[4] - (cropping.left || 0)) * multiplier,
      translateY = (vp[5] - (cropping.top || 0)) * multiplier,
      originalInteractive = this.interactive,
      newVp = [newZoom, 0, 0, newZoom, translateX, translateY],
      originalRetina = this.enableRetinaScaling,
      canvasEl = fabric.util.createCanvasElement(),
      originalContextTop = this.contextTop;
    canvasEl.width = scaledWidth;
    canvasEl.height = scaledHeight;
    this.contextTop = null;
    this.enableRetinaScaling = false;
    this.interactive = false;
    this.viewportTransform = newVp;
    this.width = scaledWidth;
    this.height = scaledHeight;
    this.calcViewportBoundaries();
    this.renderCanvas(canvasEl.getContext('2d'), this._objects);
    this.viewportTransform = vp;
    this.width = originalWidth;
    this.height = originalHeight;
    this.calcViewportBoundaries();
    this.interactive = originalInteractive;
    this.enableRetinaScaling = originalRetina;
    this.contextTop = originalContextTop;
    return canvasEl;
  };

  fabric.StaticCanvas.prototype.releaseTexture = function () {
    if (!this.overlayTexture) return;

    /*
      Turn overlayTexture to an illustrationimage for consistency,
      since the same happens when loading from state.
    */

    const release = () => {
      fabric.OverlayTexture.fromObject(this.overlayTexture, (target) => {
        this.overlayTexture = null;
        this.overlayOptions = null;
        target.opacity *= 100;
        this.add(target);
        this.onAddRemoveObject(target);
      });
    };

    if (this.overlayTexture.isAlphaMask) {
      this.overlayTexture.toggleAlphaMask(false, () => {
        this.overlayTexture.set({ globalCompositeOperation: 'source-over' });
        release();
      });
    } else {
      release();
    }
  };

  fabric.Canvas.prototype._drawSelection = function (ctx) {
    const selector = this._groupSelector;
    const viewportStart = new fabric.Point(selector.ex, selector.ey);
    const start = fabric.util.transformPoint(
      viewportStart,
      this.viewportTransform
    );
    const viewportExtent = new fabric.Point(
      selector.ex + selector.left,
      selector.ey + selector.top
    );
    const extent = fabric.util.transformPoint(
      viewportExtent,
      this.viewportTransform
    );

    let minX = Math.min(start.x, extent.x);
    let minY = Math.min(start.y, extent.y);
    let maxX = Math.max(start.x, extent.x);
    let maxY = Math.max(start.y, extent.y);

    /*
      Don't draw if rect area is 0
      Further calculations involving strokeOffset make it so that a tiny rectangle
      is drawn on every click
    */
    if (minX === maxX && minY === maxY) return; // ---> Added by us

    const strokeOffset = this.selectionLineWidth / 2;

    if (this.selectionColor) {
      ctx.fillStyle = this.selectionColor;
      ctx.fillRect(minX, minY, maxX - minX, maxY - minY);
    }

    if (!this.selectionLineWidth || !this.selectionBorderColor) {
      return;
    }
    ctx.lineWidth = this.selectionLineWidth;
    ctx.strokeStyle = this.selectionBorderColor;

    minX += strokeOffset;
    minY += strokeOffset;
    maxX -= strokeOffset;
    maxY -= strokeOffset;
    // selection border
    fabric.Object.prototype._setLineDash.call(
      this,
      ctx,
      this.selectionDashArray
    );
    ctx.strokeRect(minX, minY, maxX - minX, maxY - minY);
  };

  fabric.Canvas.prototype.updateObjectsCoords = function () {
    if (!this.debouncedUpdateObjectCoords) {
      this.debouncedUpdateObjectCoords = debounce(
        () => this.forEachObject((obj) => obj.setCoords()),
        UPDATE_OBJECT_COORDS_DELAY
      );
    }

    this.debouncedUpdateObjectCoords();
  };

  fabric.Canvas.prototype.getTargetFindTolerance = function () {
    const zoom = this.getZoom();
    /*
      When zoom < 1, interpolate linearly between the two thresholds based on zoom distance to 0
    */
    const threshold =
      zoom < 1
        ? (SELECTION_MASK_THRESHOLD - SELECTION_MASK_THRESHOLD_SMALL) * zoom +
          SELECTION_MASK_THRESHOLD_SMALL
        : SELECTION_MASK_THRESHOLD;
    return threshold;
  };

  /**
   * Registers a callback for the next mouse:up event.
   * If an object is transformed before, the action is cancelled.
   */
  fabric.Canvas.prototype.registerForMouseUp = function (id, callback) {
    if (!this.mouseUpActions) {
      this.mouseUpActions = {};
    }

    this.mouseUpActions[id] = callback;
  };

  /**
   * Unregisters a callback that was registered for the next mouse:up event
   */
  fabric.Canvas.prototype.unregisterForMouseUp = function (id) {
    if (!this.mouseUpActions) {
      this.mouseUpActions = {};
    }

    delete this.mouseUpActions[id];
  };

  fabric.Canvas.prototype.disposeWebGLResources = function () {
    this.getObjects().forEach((obj) => {
      if (obj instanceof fabric.IllustrationImage) {
        obj.dispose();
      }
    });
  };

  if (isDebugActive()) {
    fabric.Canvas.prototype.devLine = function (
      points,
      strokeWidth = 3,
      stroke = 'black',
      closed = false
    ) {
      const totalPoints = closed ? [...points, points[0]] : points;
      const poly = new fabric.Polyline(totalPoints, {
        excludeFromExport: true,
        stroke,
        strokeWidth,
      });
      poly.set('fill', 'rgba(255, 0, 0, 0)');
      this.add(poly);
    };

    fabric.Canvas.prototype.devPoints = function (
      points,
      size = 10,
      color = 'red'
    ) {
      const makeRect = ({ x, y }) => {
        this.add(
          new fabric.Rect({
            excludeFromExport: true,
            left: x - size / 2,
            top: y - size / 2,
            fill: color,
            width: size,
            height: size,
          })
        );
      };

      if (points.length) {
        points.forEach((point) => {
          makeRect(point);
        });
      } else {
        makeRect(points);
      }
    };
  }
}
