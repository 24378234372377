import React, { forwardRef } from 'react';

import Button from '../../Button/Button/Button';
import { editorMenuItemTheme } from '../../Button/Button/theme';
import Tooltip from '../../Tooltip/Tooltip';
import { TooltipAlignment } from '../../Tooltip/types';

interface GlyphsButtonProps {
  isActive?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

const GlyphsButton = forwardRef<HTMLDivElement, GlyphsButtonProps>(
  ({ isActive = false, onClick }, ref) => (
    <Tooltip text="Glyphs" align={TooltipAlignment.End} ref={ref}>
      <Button
        icon={{
          name: 'glyph',
          height: '24px',
        }}
        width="35px"
        theme={editorMenuItemTheme}
        isActive={isActive}
        onClick={onClick}
      />
    </Tooltip>
  )
);

export default GlyphsButton;
