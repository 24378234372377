import styled from 'styled-components';

import { handleTextOverflow } from '../../utilities/styles';
import { H6, P1, P4 } from '../../utilities/Typography/styles';
import { themeStyle } from '../../../services/theming';

export const Wrapper = styled.div`
  padding: 12px 16px;
`;

export const InnerWrapper = styled.div`
  position: relative;
`;

export const TabList = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

export const Tab = styled.div`
  font-size: ${themeStyle.fontSize11};
  font-weight: ${({ isActive }) =>
    isActive ? themeStyle.fontSemiBold : themeStyle.fontMedium};
  text-decoration-line: ${({ isActive }) => (isActive ? 'underline' : 'none')};
  text-underline-offset: 2px;
  color: ${({ isActive }) =>
    isActive ? themeStyle.varInkMain : themeStyle.varInkMedium};
  cursor: pointer;
`;

export const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 40px;
  gap: 8px;
`;

export const ContentWrapper = styled.div`
  height: 560px;
  overflow-y: auto;

  /* scrollbar */
  margin-right: -10px;
  padding-right: 10px;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  scrollbar-color: ${({ colorScrollbar }) => colorScrollbar}
    ${({ colorPanel }) => colorPanel};
`;

export const Message = styled(P4)`
  padding: 10px 0;
  text-align: center;
  color: ${themeStyle.varInkMedium};
`;

export const Group = styled.div`
  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const GroupLabel = styled(H6)`
  padding: 4px 0;
  border-bottom: 1px solid ${themeStyle.varInkSuperLight};
  line-height: 18px;
  color: ${themeStyle.varInkMedium};
  font-weight: ${themeStyle.fontMedium};
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  cursor: pointer;

  box-sizing: border-box;
  height: 60px;
  width: 100%;

  border-bottom: 1px solid ${themeStyle.varInkSuperLight};

  /* border-top is only needed when there is no group label */
  &:first-child {
    border-top: 1px solid ${themeStyle.varInkSuperLight};
  }

  background: transparent;
  transition: background 0.2s;

  &:hover {
    background: ${themeStyle.varInkSuperLight};
  }
`;

export const Img = styled.img.attrs(({ src, alt }) => ({
  src,
  alt,
}))`
  height: 28px;
  overflow-x: hidden;

  html[data-theme='dark'] && {
    filter: invert(1);
  }
`;

export const FontLabel = styled(P1)`
  ${handleTextOverflow};
`;
