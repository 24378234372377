import { CanvasRenderingContext2D } from 'canvas';
import tinycolor from 'tinycolor2';
import fabric from '.';
import { isOnDevEnvironment } from '../../../global/environment';
import monitoring from '../../../services/monitoring';
import { JSONObject } from '../../../types';
import { clone } from '../../../utils/editor/loadSvg';
import { buildSources } from '../../../utils/url';

// these options are not complete, but only contain options used explicitly for Mask initialization
interface MaskOptions {
  inLoadingStage?: boolean;
  objectName?: string;
  opacity?: number;
  maskFill?: string;
  isClipPath?: boolean;
}

const DEFAULT_MASK_FILL = '#D8D8D8';

export default function (fabric: fabric): void {
  // allow re-initialize on dev environment to prevent issues with fast refresh
  if (fabric.Mask && !isOnDevEnvironment()) {
    fabric.warn('fabric.Mask is already defined');
    return;
  }
  /**
   * Mask class
   * This class handles Masks
   * @class fabric.Mask
   * @extends fabric.Group
   */
  fabric.Mask = fabric.util.createClass(fabric.Group, {
    type: 'mask',

    /**
     * whether the mask is currently used as a clipPath for other objects
     */
    isClipPath: false,

    /**
     * if it is currently masking a preview, that id is stored here
     */
    previewTargetId: null,

    /**
     * fill color used while it is a clipPath
     */
    maskFill: DEFAULT_MASK_FILL,

    initialize: async function (
      objectName: string,
      options?: MaskOptions,
      cb?: (object: fabric.Object) => void
    ): Promise<void> {
      options || (options = {});
      this.inLoadingStage = options.inLoadingStage;

      const { src, previewSrc } = buildSources(objectName);
      options.objectName = objectName;

      // default attributes
      this.set('borderIsDisabled', true);

      this.objectCaching = false;
      this.trueRender = this.clipPath;
      this.maskFill = options.maskFill || DEFAULT_MASK_FILL;

      // load paths and initialize object
      let paths;
      try {
        paths = await this.initializeSvg(
          {
            ...options,
            src,
            previewSrc,
            skipPreview: options.isClipPath,
          },
          cb
        );
      } catch (error) {
        monitoring.captureException(error);
      }

      // We need to get the clipPath used for the svg.
      // Every path should have it, so we take it from the first one.
      const clipPath = paths?.[0]?.clipPath;
      if (clipPath) {
        try {
          const overlay = await clone(clipPath);
          this.maskOverlay = overlay;
          this.maskOverlay.set('fill', this.maskFill);
          this.maskOverlay.set('objectCaching', false);
          const clip = await clone(clipPath);
          this.clip = clip;

          this.updatePathsVisibility();
          this.add(overlay);
        } catch (error) {
          monitoring.captureException(error);
        }
      }
      paths.forEach((path: fabric.Object) => (path.clipPath = null));
      if (!this.initialized && cb) cb(this);

      this.canvas && this.canvas.requestRenderAll();
    },

    /**
     * toggle settings, if this mask is used as a clipPath for other objects
     */
    setIsClipPath: function (isClipPath: boolean) {
      this.isClipPath = isClipPath;
      this.absolutePositioned = isClipPath;
      this.updatePathsVisibility();
    },

    /**
     * change visibility of children.
     * If isClipPath, we only need to show an overlay with a background color
     */
    updatePathsVisibility: function () {
      if (!this.maskOverlay) return;

      this.set('clipPath', this.isClipPath ? null : this.clip);
      this.set('trueRender', !!this.clipPath);
      this.maskOverlay.set('visible', !!this.isClipPath);
      this.getObjects().forEach((path: fabric.Path) => {
        if (path.id === this.maskOverlay.id) return;
        path.set('visible', !this.isClipPath);
      });
    },

    handleDragOver: function () {
      if (!this.canvas?.draggingObject || this.previewTargetId) {
        // stop if no object to preview or preview already active
        return;
      }
      if (this.isClipPath) {
        // if not already in preview, store reference to the original and hide that
        const clippedObject = this.canvas
          .getObjects()
          .find((object: fabric.Object) => object.clipPathMaskId === this.id);
        if (clippedObject) {
          clippedObject.set('visible', false);
        } else {
          // this should never happend, but might be useful when debugging
          console.warn(`Couldn not find Clipped Object for Mask ${this.id}.`);
        }
      }

      this.previewTargetId = this.canvas.draggingObject.id;
      this.canvas.add(this.canvas.draggingObject);
      this.canvas.draggingObject.positionRelativeToObject(this);
      this.canvas.draggingObject.addClipPath(this.id);
    },

    handleDragOut: function () {
      // return if preview does not exist or was not applied
      if (!this.canvas?.draggingObject || !this.previewTargetId) return;
      this.canvas.remove(this.canvas.draggingObject);

      // get the original object that was masked, before the preview was shown
      const originalTarget = this.canvas
        .getObjects()
        .find(
          (object: fabric.Object) =>
            object.clipPathMaskId === this.id &&
            object.id !== this.previewTargetId
        );
      this.previewTargetId = null;
      if (originalTarget) {
        // show original target again
        originalTarget.set('visible', true);
      } else {
        this.setIsClipPath(false);
      }
    },

    _set: function (key: string, value: unknown) {
      if (key === 'opacity') {
        const color = tinycolor(this.maskFill);
        color.setAlpha(value as number);
        this.set('maskFill', color.toRgbString());
      } else if (key === 'maskFill') {
        this.maskFill = value;
        if (this.maskOverlay) {
          this.maskOverlay.set('fill', value);
        }
      } else {
        this.callSuper('_set', key, value);
      }

      return this;
    },

    // Implements standard Object `getColors`
    getColors: function () {
      return [
        {
          key: 'maskFill',
          value: this.maskFill,
          visible: this.isClipPath,
        },
      ];
    },

    // Implements standard Object `setColor`
    setColor: function (key: string, value: string) {
      if (key !== 'maskFill') return;
      this.set('maskFill', value);
    },

    /**
     * use true render when necessary
     */
    switchFastRendering: function (enable?: boolean) {
      this.trueRender = !enable && this.clipPath;
    },

    toObject: function () {
      const object = this.callSuper('toObject');
      const unusedAttrs = [
        'skewX',
        'skewY',
        'shadow',
        'fill',
        'stroke',
        'strokeWidth',
        'strokeUniform',
        'globalCompositeOperation',
      ];
      unusedAttrs.forEach((attr) => {
        delete object[attr];
      });
      return fabric.util.object.extend(object, {
        objectName: this.objectName,
        isClipPath: this.isClipPath,
        maskFill: this.maskFill,
        // We don't care about the paths of child objects, so we remove them
        objects: [],
        inLoadingStage: this.inLoadingStage,
      });
    },

    /**
     * fabric.Group drawObject does not support the `forClipping`,
     * so we need to set colors to black manually
     */
    drawObject: function (
      ctx: CanvasRenderingContext2D,
      forClipping?: boolean
    ) {
      const originalFill = this.maskOverlay?.fill;
      if (forClipping && this.maskOverlay) {
        this.maskOverlay.fill = 'black';
        this.maskOverlay.dirty = true; // don't use cache that wasn't meant for clipping
      }

      // apply default drawObject of group
      fabric.Group.prototype.drawObject.apply(this, [ctx]);

      if (forClipping && this.maskOverlay) {
        this.maskOverlay.fill = originalFill;
        this.maskOverlay.dirty = true; // don't use cache that was meant for clipping in other cases
      }
    },

    /**
     * Returns svg clipPath representation of an instance
     * overwritten to ignore invisible objects. This is an optimization, but also removed some artifacts
     * @param {Function} [reviver] Method for further parsing of svg representation.
     * @return {String} svg representation of an instance
     */
    toClipPathSVG: function (reviver?: (value: string) => string) {
      const svgString = [];

      for (let i = 0, len = this._objects.length; i < len; i++) {
        if (!this._objects[i].visible) continue; // <-- this was added
        svgString.push('\t', this._objects[i].toClipPathSVG(reviver));
      }

      return this._createBaseClipPathSVGMarkup(svgString, { reviver });
    },
  });

  fabric.Mask.fromObject = function (
    object: JSONObject,
    callback: (object: fabric.Object) => void
  ): void {
    new fabric.Mask(object.objectName, object, (target: fabric.Object) => {
      target.canvas && target.canvas.fire('object:modified', { target });
      callback(target);
    });
  };
}
