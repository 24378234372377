import {
  getAllChildObjects,
  getElementById,
} from '../../../utils/groupStructure';
import { isObjectInIds } from '../../../utils';

function buildSelectionApi(fabric) {
  /*
   * These functions constitute an API to perform different types of selection
   * operations in the artboard.
   */

  /**
   * Solves selection based on an object that
   * should be selected, and then uses _selectIds to perform custom selection
   * @param {object} object Object to select, can be in the group structure or not.
   * @param {object} options The options object for the selection operation (see solveSelection in selection_solver.mixin.js).
   *                         Holds MouseEvent data as well.
   * @fires SelectionEvents
   */

  fabric.Canvas.prototype.setActiveObject = function (object, options = {}) {
    if (this.disableSelection) return;

    const currentActives = this.getActiveObjects();
    if (this._shouldSelectObject(object, options)) {
      if (object instanceof fabric.ActiveSelection) {
        this._activeObject = object;
        return;
      }

      // There are objects that are not part of the group structure but still
      // selectable. e.g Artboard, CustomTextbox. For these ones we need to default
      // to fabric's native selection

      // Check if object is suitable for group selection
      const isInGroupStructure = getAllChildObjects(
        this.groupStructure
      ).includes(object.id);

      if (object !== this.artboard && !isInGroupStructure) {
        this._activeObject = object;
      } else {
        this._selectIds([object.id], options);
      }
    }

    this._fireSelectionEvents(currentActives, options);
    this.updateSelectionLock();
  };

  /**
   * Selects a list of objects based on an array of their ids.
   * @param {array} ids The list of ids. All ids are expected to be objects in the group structure or the artboard
   * @param {object} options The options object for the selection operation (see solveSelection in selection_solver.mixin.js)
   * @fires SelectionEvents
   */
  fabric.Canvas.prototype.selectIds = function (
    ids,
    options = { isOverwrite: true }
  ) {
    if (this.disableSelection) return;
    const currentActives = this.getActiveObjects();
    this._discardActiveObject();
    this._selectIds(ids, options);
    this._fireSelectionEvents(currentActives, {});
    this.updateSelectionLock();
  };

  /**
   * Selects all objects in the design.
   * @fires SelectionEvents
   */
  fabric.Canvas.prototype.selectAll = function () {
    if (this.disableSelection) return;
    const ids = getAllChildObjects(this.groupStructure);
    this.selectIds(ids);
  };

  /**
   * Clears selection
   * @fires SelectionEvents
   */
  fabric.Canvas.prototype.discardActiveObject = (function (
    discardActiveObject
  ) {
    return function (e) {
      if (this.disableSelection) return;
      this.resetGroupSelection();
      discardActiveObject.bind(this)(e);
    };
  })(fabric.Canvas.prototype.discardActiveObject);
}

/**
 * Most of these functions are small overrides to fabric native ones to make them work with
 * our selection, and some others are just utilities for the selection api.
 */
function addSelectionOverrides(fabric) {
  fabric.Canvas.prototype._selectIds = function (ids, options) {
    this._activeObject = this._getSelection(ids, options);
  };

  /**
   * Solves selection and returns the object to be selected
   * @param {array} ids Array of ids to select
   * @param {object} options The options object for the selection operation (see solveSelection in selection_solver.mixin.js)
   * @returns {object} An object suitable to select
   */
  fabric.Canvas.prototype._getSelection = function (ids, options) {
    // Sadly artboard is not a part of group structure so we need to handle it at this level
    if (ids.length === 1 && ids[0] === this.artboard.id) {
      this.resetGroupSelection();
      return this.artboard;
    }

    if (ids.length > 1) {
      // Filter out the artboard if selecting many ids
      ids = ids.filter((id) => id !== this.artboard.id);
    }

    this.solveSelection(ids, options);

    if (!this._selectedElements.length) {
      return null;
    }

    const selectedNodes = [];
    this._selectedElements.forEach((id) => {
      const element = getElementById(this.groupStructure, id);

      if (element) selectedNodes.push(element);
    });

    const leafIdsToSelect = [].concat(
      ...selectedNodes.map((node) => getAllChildObjects(node))
    );

    const shouldBeSelected = isObjectInIds(leafIdsToSelect);
    const objects = this.getObjects().filter(shouldBeSelected);

    return this._createSelectionObject(objects);
  };

  /**
   * Returns an object to select. It can either update the current ActiveSelection,
   * return a new one, or just a single object
   * @param {array} objects Objects to select
   * @returns {object} An object suitable for selection
   */
  fabric.Canvas.prototype._createSelectionObject = function (objects) {
    if (this._activeObject instanceof fabric.ActiveSelection) {
      return this.__updateActiveSelection(objects);
    }

    if (objects.length > 1) {
      return new fabric.ActiveSelection(objects, { canvas: this });
    }

    return objects[0];
  };

  /**
   * Updates the current ActiveSelection to contain all the items in objects,
   * and only items in objects. If the end result is an ActiveSelection with a single object,
   * this object is returned instead.
   * @param {array} objects Array of objects that should end up in the ActiveSelection
   * @returns The updated ActiveSelection
   */
  fabric.Canvas.prototype.__updateActiveSelection = function (objects) {
    const activeSelection = this.getActiveObject();
    const shouldBeSelected = isObjectInIds(objects.map((obj) => obj.id));

    const activeObjects = activeSelection.getObjects();

    for (let i = 0; i < activeObjects.length; i++) {
      const obj = activeObjects[i];
      if (!shouldBeSelected(obj)) {
        activeSelection.removeWithUpdate(obj);
      }
    }

    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];
      if (!activeSelection.contains(obj)) {
        activeSelection.addWithUpdate(obj);
      }
    }

    return activeSelection.size() === 1
      ? activeSelection.getObjects()[0]
      : activeSelection;
  };

  /*
   * Whether an object should be selected or not.
   * Emulates native _setActiveObject behavior without actually setting _activeObject
   */
  fabric.Canvas.prototype._shouldSelectObject = function (object, e) {
    return (
      this._activeObject !== object &&
      this._discardActiveObject(e, object) &&
      !object.onSelect({ e })
    );
  };

  /**
   * @override
   * Handles grouping (shift + click selection)
   * Only change we do is calling selectObject in the end, so that it uses
   * our custom logic for selection
   */
  fabric.Canvas.prototype._handleGrouping = function (e, target) {
    // ===== FabricJS code =====
    const activeObject = this._activeObject;

    if (activeObject.__corner) {
      return;
    }

    if (target === activeObject) {
      target = this.findTarget(e, true);

      if (!target || !target.selectable) {
        return;
      }
    }
    // ===== FabricJS code end =====

    const currentActives = this.getActiveObjects().slice();
    const selectionObject = this._getSelection([target.id], {
      isMultiSelect: true,
    });

    if (
      activeObject instanceof fabric.ActiveSelection &&
      !(selectionObject instanceof fabric.ActiveSelection)
    ) {
      this._discardActiveObject();
    }

    this._activeObject = selectionObject;
    this._fireSelectionEvents(currentActives, e);
    this.clearEnqueuedGrouping();
  };

  /**
   * @override
   * This handles box-selection.
   * We replace selection step with our custom selection logic.
   */
  fabric.Canvas.prototype._groupSelectedObjects = function (e) {
    const ids = this._collectObjects(e).map((obj) => obj.id);
    return this.selectIds(ids, e);
  };

  /**
   * @override
   * Almost identical to fabric's original, but excludes case where
   * an active selection is selected, and then something outside of it is clicked.
   * We always call _discardActiveObject when selecting, so ActiveSelections should still
   * be destroyed properly in this case.
   */
  fabric.Canvas.prototype._shouldClearSelection = function (e, target) {
    const activeObject = this._activeObject;

    return (
      !target ||
      (target && !target.evented) ||
      (target && !target.selectable && activeObject && activeObject !== target)
    );
  };

  /**
   * @override
   * PathText listens to the `selected` event as a way to deal with ActiveSelection.
   * Consider this case:
   *     - PathText is the only selected object
   *     - User shift + clicks on something else
   *     - ActiveSelection is now created with N objects inside
   *
   * But PathText was already selected. So even if the ActiveSelection is created,
   * fabric won't fire `selected` for the PathText, and group handlers wont be created.
   * Note: This is a hack and we should take a look at the approach inside PathText.
   */
  fabric.Canvas.prototype._fireSelectionEvents = (function (
    _fireSelectionEvents
  ) {
    return function (oldObjects, e) {
      _fireSelectionEvents.bind(this)(oldObjects, e);
      if (this._activeObject instanceof fabric.ActiveSelection) {
        if (oldObjects.length === 1) {
          oldObjects[0].fire('selected');
        }
      }
    };
  })(fabric.Canvas.prototype._fireSelectionEvents);
}

export default function (fabric) {
  buildSelectionApi(fabric);
  addSelectionOverrides(fabric);
}
