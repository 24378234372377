import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  padding: 8px;
  display: flex;
  justify-content: flex-end;

  & > * {
    pointer-events: auto;
  }
`;
