import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { H6Plus } from '../../utilities/Typography/styles';
import { scrollbarWidth } from '../../VerticallyScrollable/style';

export const Background = styled.div`
  box-sizing: border-box;
  width: calc(100% - ${scrollbarWidth});
  height: 100%;
  border-radius: ${themeStyle.radiusMedium};
  padding: 32px 0 10px 10px;
  margin-right: ${scrollbarWidth};
  position: relative;
  overflow: hidden;
  background: ${themeStyle.varInkSuperLight};
`;

export const Label = styled(H6Plus)`
  position: absolute;
  top: 9px;
  left: 10px;
`;

export const ButtonsWrapper = styled.div`
  padding: 8px;
  opacity: 0;
  display: flex;
  justify-content: flex-end;

  & > * {
    pointer-events: auto;
  }
`;
