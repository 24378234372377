import kebabCase from 'lodash/kebabCase';
import { Theme } from './themeStore';

export const getCssVarName = (key: string): string => {
  return `--${kebabCase(key)}`;
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * convertis a css box shadow to a css filter drop shadow
 * relies on the shadows using rgb colors (different shadows are splitted by `), `)
 */
export const boxShadowToFilter = (boxShadow: string): string => {
  const shadows = boxShadow.split('), ');
  return shadows
    .map((shadow) => `drop-shadow(${shadow}${shadow.endsWith(')') ? '' : ')'})`)
    .join(' ');
};

type addPrefix<TKey, TPrefix extends string> = TKey extends string
  ? `${TPrefix}${Capitalize<TKey>}`
  : never;

type Prefixed<
  TObject extends Record<string, string>,
  TPrefix extends string
> = {
  [key in addPrefix<keyof TObject, TPrefix>]: string;
};

export const prefixObjectKeys = <
  TObject extends Record<string, string>,
  TPrefix extends string
>(
  object: TObject,
  prefix: string
): Prefixed<TObject, TPrefix> => {
  return Object.entries(object).reduce(
    (acc: Record<string, string>, [key, value]) => {
      acc[`${prefix}${capitalizeFirstLetter(key)}`] = value;
      return acc;
    },
    {}
  ) as Prefixed<TObject, TPrefix>;
};

export const getCssVarObject = <TObject extends Record<string, string>>(
  object: TObject
): Prefixed<TObject, 'var'> => {
  return Object.entries(object).reduce((acc: Record<string, string>, [key]) => {
    acc[`var${capitalizeFirstLetter(key)}`] = `var(${getCssVarName(key)})`;
    return acc;
  }, {}) as Prefixed<TObject, 'var'>;
};

export const getFilterShadowObject = <TObject extends Record<string, string>>(
  object: TObject
): Prefixed<TObject, 'filter'> => {
  return Object.entries(object).reduce(
    (acc: Record<string, string>, [key, value]) => {
      acc[`filter${capitalizeFirstLetter(key)}`] = boxShadowToFilter(value);
      return acc;
    },
    {}
  ) as Prefixed<TObject, 'filter'>;
};

export const MEDIA_SYSTEM_DARK = '(prefers-color-scheme: dark)';

export const getSystemTheme = (): Exclude<Theme, Theme.SYSTEM> => {
  const e = window.matchMedia(MEDIA_SYSTEM_DARK);
  const isDark = e.matches;
  return isDark ? Theme.DARK : Theme.LIGHT;
};

/**
 * disable transition in document
 * and return a function to enable them again
 */
export const disableAnimation = (): (() => void) => {
  const css = document.createElement('style');
  css.appendChild(
    document.createTextNode(
      `*{-webkit-transition:none!important;-moz-transition:none!important;-o-transition:none!important;-ms-transition:none!important;transition:none!important}`
    )
  );
  document.head.appendChild(css);

  return (): void => {
    // Force restyle
    window.getComputedStyle(document.body);

    // Wait for next tick before removing
    setTimeout(() => {
      document.head.removeChild(css);
    }, 1);
  };
};
