import styled from 'styled-components';
import { themeStyle } from '../../../../services/theming';
import { P4 } from '../../../utilities/Typography/styles';

export const NotificationWrapper = styled.li`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 12px;
  cursor: pointer;
  transition: background-color 250ms ease;

  &:hover {
    background-color: ${themeStyle.varBrandSuperLight};
  }

  &:not(:last-of-type) {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${themeStyle.varInkSuperLight};
  }
`;

export const ImageRow = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
`;

export const Bubble = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto minmax(40%, 1fr);
  align-items: center;
  background-color: ${themeStyle.varInkSuperLight};
  margin-top: 12px;
  border-radius: 5px;
  padding: 6px;

  &:after {
    content: '';
    position: absolute;
    top: -8px;
    left: 9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 8px 6px;
    border-color: transparent transparent ${themeStyle.varInkSuperLight}
      transparent;
  }
`;

export const BubbleTextWrapper = styled.div`
  min-width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  overflow: hidden;
`;

export const BubbleText = styled(P4)`
  font-weight: ${themeStyle.fontMedium};
  color: ${themeStyle.varInkMedium};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const DesignContent = styled.div`
  display: grid;
  grid-template-columns: auto minmax(50%, 1fr);
  margin-top: 10px;
`;

export const DesignText = styled(P4)`
  color: ${themeStyle.varInkMedium};

  padding-left: 10px;
  overflow: hidden;
`;
