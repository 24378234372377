import { themeStyle } from '../../services/theming';

export const elementsIconRowTheme = {
  rowHeight: 40,
  backgroundColor: themeStyle.varInkLight,
  activeBackgroundColor: themeStyle.varInkSuperLight,
  cellGap: 1,
  borderRadius: themeStyle.radiusMedium,

  textColor: themeStyle.varInkMedium,
  textActiveColor: themeStyle.varInkMain,
  activeColor: themeStyle.varInkMain,
};
