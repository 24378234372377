/**
 * Utility scripts to
 *   - load information about available glyphs from a font
 *   - and render glyphs in a presentable way
 */
import { loadFont } from './fonts';

// create a union of two sets
const union = (setA, setB) => {
  const _union = new Set(setA);
  for (const elem of setB) {
    _union.add(elem);
  }
  return _union;
};

const rangeRecordsToGlyphIds = (rangeRecords) => {
  return rangeRecords.reduce(
    (acc, { start, end }) => [
      ...acc,
      ...Array.from(Array(end - start + 1), (_, x) => x + start),
    ],
    []
  );
};

/**
 * This monstrosity is from https://github.com/petermikitsh/ligatures
 * It creates an array of strings, that are ligatures
 * @param {*} font
 * @returns {Array} [ligature, ] eg. ['ffi', ]
 */
export const getLigatures = (font) => {
  if (!font.GSUB?.lookupList) {
    return [];
  }
  const lookupLists = font.GSUB.lookupList.toArray();

  return lookupLists.reduce((acc0, lookupList) => {
    // Table Type 4 is ligature substitutions:
    // https://docs.microsoft.com/en-us/typography/opentype/spec/gsub#lookuptype-4-ligature-substitution-subtable
    if (lookupList.lookupType !== 4) {
      return acc0;
    }

    const {
      coverage: { glyphs, rangeRecords },
      ligatureSets,
    } = lookupList.subTables[0];

    const leadingChars = rangeRecords
      ? rangeRecordsToGlyphIds(rangeRecords).map(
          (id) => font.stringsForGlyph(id)[0]
        )
      : glyphs.map((glyph) => {
          const result = font.stringsForGlyph(glyph);
          return result.join('');
        });

    return [
      ...acc0,
      ...ligatureSets.toArray().reduce(
        (acc2, ligatureSet, index) => [
          ...acc2,
          ...ligatureSet.reduce((acc3, ligature) => {
            const fullLigature =
              leadingChars[index] +
              ligature.components
                .map((x) => font.stringsForGlyph(x)[0])
                .join('');
            return [...acc3, fullLigature].filter(Boolean);
          }, []),
        ],
        []
      ),
    ];
  }, []);
};

/**
 * Create a map for alternative glyphs
 * @param {*} font
 * @returns {
 *  [glyph_id]: Set('code', ),
 * }
 */
const getAlternativeSubGlyphs = (font) => {
  if (!font.GSUB?.lookupList) {
    return [];
  }

  const lookupLists = font.GSUB.lookupList.toArray();

  const alternativeGlyphsLists = lookupLists.filter(
    (lookupList) => lookupList.lookupType === 3
  );
  return alternativeGlyphsLists.reduce((acc, list) => {
    const {
      coverage: { glyphs: _glyphs, rangeRecords },
      alternateSet,
    } = list.subTables[0];

    let glyphs = _glyphs;
    if (!glyphs) {
      glyphs = rangeRecordsToGlyphIds(rangeRecords);
    }

    return {
      ...acc,
      ...alternateSet.toArray().reduce((acc1, x, i) => {
        if (!acc1[glyphs[i]]) {
          acc1[glyphs[i]] = new Set();
        }
        x.forEach((code) => {
          acc1[glyphs[i]].add(code);
        });
        return acc1;
      }, {}),
    };
  }, {});
};

/**
 * Create a map for alternative subGlyphs
 * @param {*} font
 * @returns {
 *  [glyph_id]: Set('code', ),
 * }
 */
const getSingleSubGlyphs = (font) => {
  if (!font.GSUB?.lookupList) {
    return [];
  }

  const lookupLists = font.GSUB.lookupList.toArray();

  const alternativeGlyphsLists = lookupLists.filter(
    (lookupList) => lookupList.lookupType === 1
  );
  return alternativeGlyphsLists.reduce((acc, list) => {
    const {
      coverage: { glyphs: _glyphs, rangeRecords },
      substitute,
      deltaGlyphID,
    } = list.subTables[0];
    let glyphs = _glyphs;
    if (!glyphs) {
      glyphs = rangeRecordsToGlyphIds(rangeRecords);
    }
    if (substitute) {
      substitute.toArray().forEach((s, i) => {
        const key = glyphs[i];
        if (acc[key]) {
          acc[key].add(s);
        } else {
          acc[key] = new Set([s]);
        }
      });
      return acc;
    } else if (deltaGlyphID) {
      glyphs.forEach((key) => {
        const s = key + deltaGlyphID;
        if (acc[key]) {
          acc[key].add(s);
        } else {
          acc[key] = new Set([s]);
        }
      });
      return acc;
    } else {
      return acc;
    }
  }, {});
};

export const getAllAlternativeSubGlyphs = (font) => {
  const alternativeTable = getAlternativeSubGlyphs(font);
  const singleSubGlyphs = getSingleSubGlyphs(font);

  Object.keys(singleSubGlyphs).forEach((key) => {
    if (alternativeTable[key]) {
      alternativeTable[key] = union(
        alternativeTable[key],
        singleSubGlyphs[key]
      );
    } else {
      alternativeTable[key] = singleSubGlyphs[key];
    }
  });

  return alternativeTable;
};

/**
 * Create a svg string from glyph path and width information
 * Svg viewbox defaults to 55, the glyph is centered horizontally, according to its width
 * @param {*} p path
 * @param {*} contentWidth
 */
const getGlyphSVG = (p, contentWidth, contentHeight) => {
  const size = Math.max(Math.max(contentWidth, contentHeight).toFixed(), 55);
  const offsetY =
    contentHeight < size
      ? -(size * 0.075).toFixed()
      : ((contentHeight - size) / 2).toFixed(); // offset content vertically, if its height is smaller than height, otherwise center
  const offsetX = ((contentWidth - size) / 2).toFixed(); // center content horizontally
  return `
  <svg width="55" height="55" viewBox="${offsetX} ${offsetY} ${size} ${size}" xmlns="http://www.w3.org/2000/svg">
  <path d="${p}"/>
  </svg>
  `;
};

/**
 * Creates an array of all available glyphs in a font. Renders them and sets descriptive tags.
 * @param {String} fontName eg. `Mogan`
 */
export const getAllGlyphs = (fontName) => {
  return loadFont(fontName).then((font) => {
    const getPath = (glyph) => {
      const fontSize = 36;
      const { unitsPerEm } = font;
      const scale = fontSize / unitsPerEm;
      const glyphPath = glyph.path
        .transform(scale, 0, 0, -scale, 0, fontSize)
        .toSVG();

      // Use the quicker way to calculate the box by default,
      // switch to the more accurate way on error.
      let glyphWidth;
      let glyphHeight;
      try {
        glyphWidth = glyph.cbox.width * scale;
        glyphHeight = glyph.cbox.height * scale;
      } catch (_) {
        glyphWidth = glyph.bbox.width * scale;
        glyphHeight = glyph.bbox.height * scale;
      }

      return { glyphPath, glyphWidth, glyphHeight };
    };

    const allGlyphs = font.characterSet.map((codePoint) => {
      return font.glyphForCodePoint(codePoint);
    });

    const alternativeTable = getAllAlternativeSubGlyphs(font);

    // create a map to easily find alternative glyphs
    // map {[altGlyph_id]=orgGlyph_id}
    const altGlyphMap = Object.keys(alternativeTable).reduce((map, key) => {
      alternativeTable[key].forEach((id) => {
        map[id] = parseInt(key);
      });
      return map;
    }, {});

    const ligatures = getLigatures(font);
    const ligatureGlyphs = ligatures.map((ligature) => {
      const run = font.layout(ligature, { liga: true });
      return {
        ...run.glyphs[0],
        path: run.glyphs[0].path,
        cbox: run.glyphs[0].cbox,
        ligature,
      };
    });

    return [...allGlyphs, ...ligatureGlyphs].reduce((list, glyph) => {
      if (list.find(({ id }) => id === glyph.id)) return list; // filter out duplicates

      const { glyphPath, glyphWidth, glyphHeight } = getPath(glyph);
      if (!glyphPath.length) return list; // filter out glyphs that can't be rendered

      /**
       * Tags:
       *   - charCode of glyph
       *   - (isAlternative) charCode of original glyph
       *   - (isGlyph) text representation of the ligature
       * More options for tags:
       *   - glyph.name (Problem: some fonts just use unicodes)
       */

      // initialize tags with char code as string
      const tags = glyph.codePoints.map((codePoint) =>
        // usually there is only one codePoint
        String.fromCharCode(codePoint)
      );

      // the current glyph is an alternative glyph, if it is a key in altGlyphMap
      const isAltGlyph = Object.keys(altGlyphMap).includes(glyph.id.toString());
      if (isAltGlyph) {
        // find the original glyph for an alternative glyph
        const orgGlyph = allGlyphs.find(
          ({ id }) => id === altGlyphMap[glyph.id]
        );
        if (orgGlyph) {
          // add tags for char of original glyph
          orgGlyph.codePoints.forEach((codePoint) =>
            tags.push(String.fromCharCode(codePoint))
          );
        }
      }

      if (glyph.ligature) {
        tags.push(glyph.ligature);
        tags.push('ligature');
      }

      return [
        ...list,
        {
          id: glyph.id,
          codePoints: glyph.codePoints,
          label: glyph.name,
          tags,
          isLigature: !!glyph.ligature,
          svg: getGlyphSVG(glyphPath, glyphWidth, glyphHeight),
        },
      ];
    }, []);
  });
};
