import styled from 'styled-components';
import { inputActiveStyle } from '../NumberInput/styles';

export const Container = styled.div<any>`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &:hover,
  &:focus-within {
    ${inputActiveStyle}
  }
`;

export const SliderContainer = styled.div<any>`
  width: ${({ numberInputWidth, hasNumberInput }) =>
    hasNumberInput ? `calc(100% - ${numberInputWidth} - 10px)` : '100%'};
`;
