/* eslint-disable */

// PLEASE NOTE: in toSVG only ~8 lines of code are changed (see `OUR CHANGES` comments)

export default function (fabric) {
  const clone = fabric.util.object.clone;
  /**
   * Returns SVG representation of an gradient
   * @param {Object} object Object to create a gradient for
   * @return {String} SVG representation of an gradient (linear/radial)
   */
  fabric.Gradient.prototype.toSVG = function (object, _options) {
    const coords = clone(this.coords, true);
    let i;
    let len;
    const options = _options || {};
    let markup;
    let commonAttributes;
    const colorStops = clone(this.colorStops, true);
    const needsSwap = coords.r1 > coords.r2;
    const transform = this.gradientTransform
      ? this.gradientTransform.concat()
      : fabric.iMatrix.concat();
    let offsetX = -this.offsetX;
    let offsetY = -this.offsetY;
    const withViewport = !!options.additionalTransform;
    const gradientUnits =
      this.gradientUnits === 'pixels' ? 'userSpaceOnUse' : 'objectBoundingBox';
    // colorStops must be sorted ascending
    colorStops.sort(function (a, b) {
      return a.offset - b.offset;
    });

    if (gradientUnits === 'objectBoundingBox') {
      offsetX /= object.width;
      offsetY /= object.height;
    } else {
      offsetX += object.width / 2;
      offsetY += object.height / 2;
    }
    // OUR CHANGES
    // if (object.type === 'path') {
    //   offsetX -= object.pathOffset.x;
    //   offsetY -= object.pathOffset.y;
    // }
    // END OF OUR CHANGES

    transform[4] -= offsetX;
    transform[5] -= offsetY;

    commonAttributes =
      'id="SVGID_' + this.id + '" gradientUnits="' + gradientUnits + '"';
    commonAttributes +=
      ' gradientTransform="' +
      (withViewport ? options.additionalTransform + ' ' : '') +
      fabric.util.matrixToSVG(transform) +
      '" ';

    if (this.type === 'linear') {
      markup = [
        '<linearGradient ',
        commonAttributes,
        ' x1="',
        coords.x1,
        '" y1="',
        coords.y1,
        '" x2="',
        coords.x2,
        '" y2="',
        coords.y2,
        '">\n',
      ];
    } else if (this.type === 'radial') {
      // svg radial gradient has just 1 radius. the biggest.
      markup = [
        '<radialGradient ',
        commonAttributes,
        ' cx="',
        needsSwap ? coords.x1 : coords.x2,
        '" cy="',
        needsSwap ? coords.y1 : coords.y2,
        '" r="',
        needsSwap ? coords.r1 : coords.r2,
        '" fx="',
        needsSwap ? coords.x2 : coords.x1,
        '" fy="',
        needsSwap ? coords.y2 : coords.y1,
        '">\n',
      ];
    }

    if (this.type === 'radial') {
      if (needsSwap) {
        // svg goes from internal to external radius. if radius are inverted, swap color stops.
        colorStops = colorStops.concat();
        colorStops.reverse();
        for (i = 0, len = colorStops.length; i < len; i++) {
          colorStops[i].offset = 1 - colorStops[i].offset;
        }
      }
      const minRadius = Math.min(coords.r1, coords.r2);
      if (minRadius > 0) {
        // i have to shift all colorStops and add new one in 0.
        const maxRadius = Math.max(coords.r1, coords.r2);
        const percentageShift = minRadius / maxRadius;
        for (i = 0, len = colorStops.length; i < len; i++) {
          colorStops[i].offset += percentageShift * (1 - colorStops[i].offset);
        }
      }
    }

    for (i = 0, len = colorStops.length; i < len; i++) {
      const colorStop = colorStops[i];
      markup.push(
        '<stop ',
        'offset="',
        colorStop.offset * 100 + '%',
        '" style="stop-color:',
        colorStop.color,
        typeof colorStop.opacity !== 'undefined'
          ? ';stop-opacity: ' + colorStop.opacity
          : ';',
        '"/>\n'
      );
    }

    markup.push(
      this.type === 'linear' ? '</linearGradient>\n' : '</radialGradient>\n'
    );

    return markup.join('');
  };
}
/* eslint-enable */
