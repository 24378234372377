import { fetchRetry } from '../utils/fetchRetry';
import { getAccessToken } from './userApi';
import { Stripe, StripeElements } from '@stripe/stripe-js';

export const domain =
  process.env.REACT_APP_USER_API_URL ||
  process.env.NEXT_PUBLIC_USER_API_URL ||
  process.env.STORYBOOK_USER_API_URL;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createApi = (domain: string) => {
  /**
   * Create a subscription intent with given `priceId`.
   * Upon success, a `clientSecret` is returned, which can be used for Stripe Elements.
   * @param priceId
   */
  const createSubscriptionIntent = async (
    priceId: string
  ): Promise<{
    success: boolean;
    subscriptionId: string;
    clientSecret: string;
  }> => {
    const response = await fetchRetry(`${domain}/subscription/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeURI(`price=${priceId}`),
    });
    const result = await response.json();

    return result.error ? Promise.reject(result.error) : result;
  };

  /**
   * Confirm a subscription intent with Stripe Elements.
   * @param stripe
   * @param elements
   */
  const comfirmSubscriptionIntent = async (
    stripe: Stripe,
    elements: StripeElements
  ): Promise<void> => {
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required', // no redirect afterwards
    });

    if (!error) {
      return;
    } else {
      const message =
        (error.type === 'card_error' || error.type === 'validation_error') &&
        error.message
          ? error.message
          : 'An unexpected error occurred.';
      return Promise.reject(message);
    }
  };

  return {
    createSubscriptionIntent,
    comfirmSubscriptionIntent,
  };
};

export default createApi(domain!);
