import { themeStyle } from '../../../services/theming';

export const iconEventWrapperTheme = {
  color: themeStyle.varInkMedium,
  activeDefaultColor: themeStyle.varInkMain,
  activeColor: themeStyle.varInkMain,
  justifyContent: 'space-between',
};

export const iconTheme = {
  color: themeStyle.varInkMedium,
  activeDefaultColor: themeStyle.varInkMain,
  activeColor: themeStyle.varInkMain,
};
