import styled from 'styled-components';

import { themeStyle } from '../../services/theming';
import { darkBackgroundBlur } from '../utilities/styles';

export const DropdownWrapper = styled.div<{
  small: boolean;
  rightAligned: boolean;
  compact: boolean;
  isOnTop: boolean;
  customStyles?: { width?: string };
}>`
  box-sizing: border-box;
  position: absolute;
  z-index: ${themeStyle.zIndexM9};
  min-width: 100%;
  width: ${({ customStyles }) => customStyles?.width ?? 'fit-content'};
  max-height: ${({ compact }) => (compact ? '124px' : '300px')};
  padding: ${({ compact }) => (compact ? '4px' : '8px')};
  background: ${themeStyle.varBackgroundAlt};
  border-radius: ${themeStyle.radiusMedium};
  box-shadow: ${themeStyle.varShadowMedium};
  overflow-y: auto;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 5px;
  }
  font-weight: ${themeStyle.fontSemiBold};
  font-size: ${({ small }) =>
    small ? themeStyle.fontSize12 : themeStyle.fontSize14};

  top: ${({ isOnTop }) => (isOnTop ? '0' : '100%')};

  ${({ rightAligned }) => (rightAligned ? 'right: 0' : 'left: 0')};

  transform: ${({ isOnTop }) => isOnTop && 'translate(0, -100%)'};

  visibility: hidden;

  margin-top: 0;
  opacity: 0;

  ${darkBackgroundBlur}

  &.enter-active {
    margin-top: ${({ isOnTop }) => (isOnTop ? '-8px' : '8px')};
    opacity: 1;
    visibility: visible;
    transition-property: opacity, margin-top;
    transition-duration: ${themeStyle.transitionDuration};
  }

  &.enter-done {
    margin-top: ${({ isOnTop }) => (isOnTop ? '-8px' : '8px')};
    opacity: 1;
    visibility: visible;
  }

  &.exit {
    margin-top: ${({ isOnTop }) => (isOnTop ? '-8px' : '8px')};
    opacity: 1;
    visibility: visible;
  }

  &.exit-active {
    margin-top: 0;
    opacity: 0;
    transition-property: opacity, margin-top;
    transition-duration: ${themeStyle.transitionDuration};
  }

  &.exit-done {
    visibility: hidden;
  }
`;

export const DropdownOptionWrapper = styled.div<{
  compact: boolean;
  small: boolean;
  isActive: boolean;
}>`
  box-sizing: border-box;
  height: ${({ small, compact }) =>
    compact ? (small ? '20px' : '24px') : small ? '31px' : '32px'};
  line-height: ${({ small, compact }) =>
    compact ? (small ? '20px' : '24px') : small ? '31px' : '32px'};
  padding: ${({ small, compact }) =>
    compact ? (small ? '0 7px' : '0 10px') : small ? '0 8px' : '0 12px'};
  border-radius: ${themeStyle.radiusMedium};
  color: ${({ isActive }) =>
    isActive ? themeStyle.varInkMain : themeStyle.varInkMedium};
  background: ${({ isActive }) =>
    isActive ? themeStyle.varInkSuperLight : themeStyle.transparent};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  :hover {
    color: ${themeStyle.varInkMain};
    background: ${themeStyle.varInkSuperLight};
  }
`;
