import { createContext } from 'use-context-selector';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import noop from 'lodash/noop';

export const getRootNode = (v: LeftMenuContextValue): HTMLDivElement | null =>
  v[0].rootNode.current;
export const getCanvasContainer = (
  v: LeftMenuContextValue
): HTMLDivElement | null => v[0].canvasContainer.current;

interface LeftMenuContextState {
  rootNode: MutableRefObject<HTMLDivElement | null>;
  canvasContainer: MutableRefObject<HTMLDivElement | null>;
  expanded: boolean;
}

type LeftMenuContextValue = [
  LeftMenuContextState,
  Dispatch<SetStateAction<LeftMenuContextState>>
];

const leftMenuContext = createContext<LeftMenuContextValue>([
  {} as LeftMenuContextState,
  noop,
]);

export default leftMenuContext;
