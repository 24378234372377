import Nprogress from 'nprogress';

import { lockToAxis } from '../../../utils/editor/misc';
import fabric from '../fabric';
import { useMenuStore } from '../../../stores/menuStore';
import { commonFontsStore } from '../../../stores/commonFontsStore';
import { useDownloadPanelStore } from '../../../stores/downloadPanelStore';
import {
  getLineGuideStops,
  getObjectSnappingEdges,
  getGuides,
  drawGuides,
  getGridStops,
} from '../magneticAlignment';
import {
  ACTIVE_ALIGN,
  ACTIVE_COLOR,
  ACTIVE_COPY,
  ACTIVE_CUT,
  ACTIVE_DECORATION,
  ACTIVE_DELETE,
  ACTIVE_EDIT_MODE,
  ACTIVE_FILTER,
  ACTIVE_FILTER_RESET,
  ACTIVE_FONTFAMILY,
  ACTIVE_FONTSIZE,
  ACTIVE_GLYPH,
  ACTIVE_GROUP,
  ACTIVE_LETTERSPACING,
  ACTIVE_LIGATURES,
  ACTIVE_LINEHEIGHT,
  ACTIVE_LOCK,
  ACTIVE_MOVE,
  ACTIVE_MOVED,
  ACTIVE_MOVE_BY,
  ACTIVE_OPACITY,
  ACTIVE_OVERLAY_MODE,
  ACTIVE_PASTE,
  ACTIVE_PREFIX,
  ACTIVE_REFLECT_HORIZONTAL,
  ACTIVE_REFLECT_VERTICAL,
  ACTIVE_REMOVE_BG,
  ACTIVE_SHADOW,
  ACTIVE_STROKE_WIDTH,
  ACTIVE_TEXTALIGNMENT,
  ACTIVE_TIDY,
  ACTIVE_TRANSFORM,
  ACTIVE_TRANSFORM_CURVE,
  ACTIVE_UNDERLINE,
  ACTIVE_UPPERCASE,
  ACTIVE_VARIATION,
  ADD_PREFIX,
  ADD_TEXT,
  COLOR_PICKING,
  DELETE_TEXTURE,
  DELETE_UPLOAD,
  DESELECT_OBJECTS,
  DESIGN_PREFIX,
  DESIGN_SAVE,
  DEV_PREFIX,
  DOWNLOAD,
  EDIT_PREFIX,
  EDIT_SHAPE,
  EDIT_TEXT,
  EDIT_TEXTURE,
  ENABLE_DRAGGING,
  ENABLE_SNAPPING,
  ENABLE_ZOOM_ON_WHEEL,
  GRID_SET,
  GRID_SHOW,
  HIDE_TRANSPARENCY_GRID,
  HISTORY_RESET,
  LAYER_PREFIX,
  MAIN_EDIT,
  MAIN_SELECT,
  OPEN_DOWNLOAD_PANEL,
  OPEN_SHARE_PANEL,
  OVERLAY_CLIP,
  PICK_COLOR,
  POST_SUBMIT,
  RELEASE_TEXTURE,
  REMOVE_COLOR_PALETTE_PRESET_PREVIEW,
  RESIZE,
  SELECT_ALL,
  SELECT_ARTBOARD,
  SET_BACKGROUND,
  SET_COLOR_PALETTE,
  SET_COLOR_PALETTE_PRESET,
  SET_COLOR_PALETTE_PRESET_PREVIEW,
  SET_STATE,
  SOCIAL_SHARE,
  TRIM_VIEW,
  ZOOM,
  ZOOM_BY,
  ZOOM_CENTER,
  ZOOM_STEP,
  ZOOM_TO,
} from '../../../global/events';
import handleAddRemoveEvents from './handleAddRemoveEvents';
import handleDeveloperEvents from './handleDeveloperEvents';
import handleLayerEvents from './handleLayerEvents';
import handleDesignEvents from './handleDesignEvents';
import {
  downloadSVG,
  downloadImage,
  downloadPDF,
  getSocialSharePreview,
} from '../../../helpers/download';
import { isDebugActive } from '../../../utils/dev';
import {
  ARTBOARD_DPI,
  MIN_PREVIEW_SIZE,
  ONE_MM_IN_INCHES,
  TYPE_ACTIVE_SELECTION,
} from '../../../global/constants';
import { loadFontFace } from '../../../font/fonts';
import handleAnalyticsEvents from '../../../global/analytics/handleEvents';
import { handleShare } from '../../../utils/share';
import { useSettingsStore } from '../../../stores/settingsStore';
import userApi from '../../../global/userApi';
import analytics from '../../../global/analytics';
import rasterizeImage from '../../../helpers/rasterizeImage/rasterizeImage';
import { isMaskGroup } from '../../../utils/masking';
import {
  callOnAllActiveObjects,
  clearAuxiliaryObjects,
  getMainElement,
  setIsActiveDesignModified,
} from './utils';
import monitoring from '../../../services/monitoring';
import { useToastStore } from '../../../stores/toastStore';
import { isIpadOperatingSystem } from '../../../utils/detection';
import Tidiable from '../../../utils/editor/tidy/Tidiable';
import { hasEnoughItemsToTidy } from '../../../utils/editor/tidy/utils';
import {
  handleActiveMoveBy,
  handleColorPicking,
  handleEnableDragging,
  handleEnableZoomOnWheel,
  handlePickColor,
  handleZoom,
  handleZoomCenter,
  handleZoomStep,
  handleZoomTo,
} from '../../../utils/editor/eventHandlers';
import { uploadFile } from '../../../global/uploads';
import { buildImageProxyUrl, getRemBGUrl } from '../../../utils/url';
import handleMaskingEvents, { MASKING_EVENTS } from './handleMaskingEvents';
import GeneratorApi from '../../../api/generators';

const { addSingleFont } = commonFontsStore.getState();

const otherAddRemoveEvents = [
  ACTIVE_COPY,
  ACTIVE_PASTE,
  ACTIVE_CUT,
  ACTIVE_DELETE,
  DELETE_UPLOAD,
];

let enableGuideRedraw = true;

const handleEvents = async (canvas, event, dispatch) => {
  const activeObject = canvas.getActiveObject();
  const allObjects = canvas.allObjects();

  // Analytics logic
  handleAnalyticsEvents(event);

  /**
   * If the design is modified we save that information to the store to warn
   * the user about replacing the Artboard with a new template.
   */
  setIsActiveDesignModified(event?.key);

  if (
    event?.key.startsWith(ADD_PREFIX) ||
    otherAddRemoveEvents.includes(event?.key)
  ) {
    return handleAddRemoveEvents(event, canvas, dispatch);
  } else if (event?.key.startsWith(LAYER_PREFIX)) {
    return handleLayerEvents(event, canvas, allObjects);
  } else if (event?.key.startsWith(DESIGN_PREFIX)) {
    return handleDesignEvents(event, canvas, dispatch);
  } else if (isDebugActive() && event?.key.startsWith(DEV_PREFIX)) {
    return handleDeveloperEvents(event, canvas);
  } else if (MASKING_EVENTS.includes(event?.key)) {
    return handleMaskingEvents(event, canvas);
  }

  const value = event?.val;
  if (
    (event?.key.startsWith(ACTIVE_PREFIX) ||
      event?.key.startsWith(EDIT_PREFIX)) &&
    !activeObject
  ) {
    // ACTIVE_ and EDIT_ events rely on an existing activeObject
    return;
  }

  switch (event?.key) {
    case ACTIVE_FILTER:
      const { type, value: filterValue, isChanging: filterIsChanging } = value;
      if (!activeObject.updateFilters) break;
      activeObject.updateFilters({ type, value: filterValue });

      if (!filterIsChanging) {
        canvas.fire('object:modified');
      }
      break;
    case ACTIVE_FILTER_RESET:
      activeObject.resetFilters();
      canvas.fire('object:modified');
      break;
    case ACTIVE_EDIT_MODE:
      canvas.getActiveObject().toggleEdit(false);
      canvas.fire('selection:updated');
      break;
    case OVERLAY_CLIP:
      canvas.overlayTexture.setRenderClip(value.renderClip);
      canvas.fire('object:modified');
      break;
    case PICK_COLOR:
      const { pickEvent } = value;
      handlePickColor(pickEvent, canvas, (color) => (value.color = color));
      break;
    case COLOR_PICKING:
      handleColorPicking(value, canvas);
      break;
    case EDIT_TEXT:
      if (canvas.draggingEnabled) break;
      const { target: pathText, selectAll, deselect } = value;
      // Disable editText in pathEditMode, because width change from new text would have to be applied to points
      if (pathText.inPathEditMode) break;

      // Load font and add it to document
      let fontName = '';
      try {
        fontName = await loadFontFace(
          pathText.fontFamily,
          pathText.fontWeight,
          pathText.variation
        );
      } catch (error) {
        useToastStore
          .getState()
          .fire({ label: 'Failed to load font face.', error: true });
        monitoring.captureException(
          error || new Error('Failed to load FontFace'),
          {
            fontFamily: pathText.fontFamily,
            fontWeight: pathText.fontWeight,
            variation: pathText.variation,
          }
        );
      }

      // test that the text object is still selected
      if (pathText.id !== canvas.getActiveObject()?.id) return;

      // create input field
      const textbox = new fabric.CustomTextbox(pathText, {
        fontFamily: fontName,
      });
      canvas.set('activeTextEdit', true);
      canvas.add(textbox);

      // set text field into edit mode
      canvas.setActiveObject(textbox);
      const fullHeight = window.visualViewport?.height;
      textbox.enterEditing();

      const handleViewportResize = () => {
        const height = window.visualViewport?.height;
        if (height && height !== fullHeight) {
          canvas.centerOnObjectInTopPart(textbox.id, height / fullHeight);
        }
      };

      if (isIpadOperatingSystem()) {
        window.visualViewport?.addEventListener('resize', handleViewportResize);
      }
      if (selectAll) {
        textbox.selectAll();
      }
      pathText.set('visible', false);
      pathText.editTextBox = textbox;
      pathText.hoverCursor = 'default';

      // handle the removal of the textfield after editing
      const removeEditText = () => {
        window.visualViewport?.removeEventListener(
          'resize',
          handleViewportResize
        );
        canvas.off('object:modified', handleObjectModification);
        canvas.off('selection:updated', handleObjectModification);
        const text = textbox.text;
        pathText.editTextBox = null;
        canvas.remove(textbox);
        canvas.set('activeTextEdit', false);
        if (text.trim() !== '') {
          pathText.hoverCursor = 'move';
          pathText.set('visible', !pathText.get('hidden'));
          if (text !== pathText.text) {
            pathText.setText(text);
          }
          canvas.selectIds([pathText.id]);
          canvas.requestRenderAll();
        } else {
          canvas.remove(pathText);
          canvas.onAddRemoveObject(); // update groupStructure and UI
        }
      };

      const handleObjectModification = (e) => {
        if (e?.noDeselectText) return;

        removeEditText();
      };

      // set event listener
      textbox.on('editing:exited', removeEditText);
      canvas.on('object:modified', handleObjectModification);
      canvas.on('selection:updated', handleObjectModification);

      if (deselect) {
        textbox.exitEditing();
      }

      break;
    case EDIT_SHAPE:
      activeObject.toggleEdit(value);
      break;
    case EDIT_TEXTURE:
      canvas.modifyOverlay({ ...value, hidden: false });
      break;
    case DELETE_TEXTURE:
      canvas.overlayTexture = null;
      canvas.requestRenderAll();
      canvas.fire('object:modified');
      break;
    case ACTIVE_COLOR:
      const { key, value: newColor, isChanging } = value;
      activeObject.setColor(key, newColor);
      canvas.lastColorPalette = null;
      if (!isChanging) {
        canvas.fire('object:modified', { target: activeObject }); // call state update
      }
      break;
    case ACTIVE_STROKE_WIDTH:
      const setStrokeWidth = (obj) => {
        obj.set({ strokeWidth: value.width });
      };
      setStrokeWidth(activeObject);
      if (!value.isChanging) {
        canvas.fire('object:modified', { target: activeObject }); // call state update
      }
      break;
    case ACTIVE_GLYPH:
      const glyph = String.fromCodePoint(...value.codePoints);
      if (activeObject.editTextBox) {
        activeObject.editTextBox.insertGlyph(glyph);

        if (activeObject.editTextBox.hiddenTextarea) {
          activeObject.editTextBox.hiddenTextarea.focus();
        }
      } else {
        activeObject.setText(`${activeObject.text}${glyph}`);
      }
      value.isLigature && activeObject.setUseLigatures(true);
      break;
    case ACTIVE_FONTFAMILY:
      addSingleFont(value);
      const changeFontFamily = (obj, cb) => obj.setFontFamily(value, cb);
      callOnAllActiveObjects(changeFontFamily, canvas);
      break;
    case ACTIVE_FONTSIZE:
      const changeFontSize = (obj, cb) =>
        obj.setFontSize &&
        obj.setFontSize(value.fontSize, value.isChanging, cb);
      callOnAllActiveObjects(changeFontSize, canvas);
      break;
    case ACTIVE_LETTERSPACING:
      const changeLetterSpacing = (obj, cb) =>
        obj.setLetterSpacing &&
        obj.setLetterSpacing(value.letterSpacing, value.isChanging, cb);
      callOnAllActiveObjects(changeLetterSpacing, canvas);
      break;
    case ACTIVE_UPPERCASE:
      const changeUpperCase = (obj, cb) =>
        obj.setUppercase(value.uppercase, cb);
      callOnAllActiveObjects(changeUpperCase, canvas);
      break;
    case ACTIVE_TEXTALIGNMENT:
      const changeTextAlignment = (obj, cb) =>
        obj.setTextAlignment(value.textAlignment, cb);
      callOnAllActiveObjects(changeTextAlignment, canvas);
      break;
    case ACTIVE_UNDERLINE:
      const changeUnderline = (obj, cb) =>
        obj.setUnderline(value.underline, cb);
      callOnAllActiveObjects(changeUnderline, canvas);
      break;
    case ACTIVE_LIGATURES:
      const changeUseLigatures = (obj, cb) =>
        obj.setUseLigatures(value.useLigatures, cb);
      callOnAllActiveObjects(changeUseLigatures, canvas);
      break;
    case ACTIVE_LINEHEIGHT:
      const changeLineHeight = (obj, cb) =>
        obj.setLineHeight &&
        obj.setLineHeight(value.lineHeight, value.isChanging, cb);
      callOnAllActiveObjects(changeLineHeight, canvas);
      break;
    case ACTIVE_VARIATION:
      const changeVariation = (obj, cb) =>
        obj.setVariation(value.variation, value.value, value.isChanging, cb);
      callOnAllActiveObjects(changeVariation, canvas);
      break;
    case ACTIVE_SHADOW:
      activeObject.setShadow(value);
      break;
    case ACTIVE_TRANSFORM:
      activeObject.setTransform(value);
      canvas.fire('selection:updated');
      break;
    case ACTIVE_TRANSFORM_CURVE:
      activeObject.setTransformCurve(value);
      break;
    case ACTIVE_DECORATION:
      activeObject.setDecoration(value);
      break;
    case ACTIVE_OPACITY:
      // Opacity from slider is [0..100], opacity from fabric object is [0..1]
      const opacity = parseFloat(value.opacity) / 100;
      canvas.getActiveObjects().forEach((obj) => obj.set({ opacity }));

      if (!value.isChanging) {
        // handle group opacity, if current active objects are grouped
        canvas.handleGroupOpacity(opacity);
        canvas.fire('object:modified', { target: activeObject }); // update state
      }
      break;
    case ACTIVE_MOVE:
      const { target, lock, pointer } = value;

      const targetRect = target.getBoundingRect(true);

      canvas.hasMovingObject = true;

      if (lock && lock.doLock) {
        lockToAxis(target, lock, pointer);
        break;
      }

      if (!canvas.artboard.snappingEnabled) {
        clearAuxiliaryObjects(canvas, allObjects);
        break;
      }

      // set coords to update position
      target.setCoords();
      // Skip active objects and selection
      const skipObjects = [target, ...canvas.getActiveObjects()];
      // find possible snapping lines
      const lineGuideStops = getLineGuideStops(skipObjects, canvas);
      // find snapping points of current object
      const itemBounds = getObjectSnappingEdges(target);
      // now find where can we snap current object
      const guides = getGuides(lineGuideStops, itemBounds);

      // throttled drawing of lines
      if (enableGuideRedraw) {
        // clear all previous lines on the screen
        clearAuxiliaryObjects(canvas, allObjects);

        if (guides.length) {
          drawGuides(guides, targetRect, canvas);
        }
        enableGuideRedraw = false;
        setTimeout(() => (enableGuideRedraw = true), 50);
      }

      if (canvas.artboard.get('showGrid') === true) {
        const gridSize = canvas.artboard.get('gridSize');
        const adjustedGridSize =
          (gridSize / 100) *
          Math.min(canvas.artboard.width, canvas.artboard.height);
        const gridStops = getGridStops(targetRect, adjustedGridSize);
        const gridGuides = getGuides(gridStops, itemBounds);

        // if there are no alignments to objects on an axis, try align to grid instead
        gridGuides.forEach((guide) => {
          if (!guides.find((g) => g.orientation === guide.orientation)) {
            guides.push(guide);
          }
        });
      }

      // now force object position
      guides.forEach((lineGuide) => {
        if (lineGuide.orientation === 'V') {
          target.left = lineGuide.lineGuide + lineGuide.offset;
        } else {
          target.top = lineGuide.lineGuide + lineGuide.offset;
        }
      });
      break;
    case ACTIVE_MOVE_BY:
      handleActiveMoveBy(canvas, value);
      break;
    case ACTIVE_MOVED:
      // delete previous aux obj
      canvas.hasMovingObject = false;
      clearAuxiliaryObjects(canvas, allObjects);
      break;
    case ACTIVE_REFLECT_HORIZONTAL:
      if (!activeObject) break;
      if (activeObject.type === TYPE_ACTIVE_SELECTION) {
        canvas.getActiveObjects().forEach((obj) => {
          obj.reflect('horizontal');
          // adjust position to place object at the other side of the group
          // since the groups coordinate system is centered, we just need to move
          // `left` of the untransformed bounding box to `-right` of it
          obj.setCoords();
          const objRect = obj.getBoundingRect(true);
          const right = objRect.left + objRect.width;
          const innerLeftOffset = Math.abs(objRect.left - obj.left);
          obj.left = -right + innerLeftOffset;
          obj.handleOnMove();
        });
      } else {
        activeObject.reflect('horizontal');
      }
      canvas.fire('object:modified', { target: activeObject, action: 'flipX' });
      break;
    case ACTIVE_REFLECT_VERTICAL:
      if (!activeObject) break;
      if (activeObject.type === TYPE_ACTIVE_SELECTION) {
        canvas.getActiveObjects().forEach((obj) => {
          obj.reflect('vertical');
          // adjust position to place object at the other side of the group
          // since the groups coordinate system is centered, we just need to move
          // `top` of the untransformed bounding box to `-bottom` of it
          obj.setCoords();
          const objRect = obj.getBoundingRect(true);
          const bottom = objRect.top + objRect.height;
          const innerTopOffset = Math.abs(objRect.top - obj.top);
          obj.top = -bottom + innerTopOffset;
          obj.handleOnMove();
        });
      } else {
        activeObject.reflect('vertical');
      }
      canvas.fire('object:modified', { target: activeObject, action: 'flipY' });
      break;
    case ACTIVE_ALIGN:
      if (!activeObject) break;
      activeObject.align(value);
      canvas.fire('object:modified', { target: activeObject });
      break;
    case ACTIVE_GROUP:
      const groupObjects = canvas.getActiveObjects();
      if (!groupObjects.length) break;

      const groupCanUnmask = isMaskGroup(activeObject?._objects || []);
      if (groupCanUnmask) {
        // handle a group being a mask group
        const base = activeObject._objects.find(
          (object) => object.clipPathMaskId
        );
        base.removeClipPath();
        canvas.discardGroup();
      } else {
        // ungroup or group?
        const shouldGroup = !canvas.shouldUngroup();
        if (shouldGroup) {
          // group objects
          canvas.createGroup();
        } else {
          // ungroup objects
          canvas.discardGroup();
        }
      }

      canvas.fire('object:modified');
      break;
    case ACTIVE_LOCK:
      if (!canvas._selectedElements?.length) break;
      canvas._selectedElements.forEach((id) => canvas.toggleElementLocked(id));
      canvas.fire('object:modified');
      // Update selection, to take locked property into account
      canvas.updateSelectionLock();
      break;
    case ACTIVE_OVERLAY_MODE:
      const { overlayId } = value;
      activeObject.set({ globalCompositeOperation: overlayId });
      canvas.fire('object:modified');
      break;
    case ACTIVE_TIDY:
      const shouldTidy = hasEnoughItemsToTidy(
        canvas._selectedElements,
        canvas.getActiveObjects()
      );

      if (shouldTidy) {
        // To reset any rotation, flip, or scale on the active selection that affect objects' positions,
        // we discard the active object and select the elements again.
        // This might not be ideal but manipulating `_activeObject` is troublesome.
        const selectedIds = canvas._selectedElements;
        canvas.discardActiveObject();
        canvas.selectIds(selectedIds);

        const objects = canvas.getActiveObjects();
        const tidiable = new Tidiable(
          canvas.groupStructure,
          canvas._selectedElements,
          objects
        );
        tidiable.tidy();
        objects.forEach((object) => {
          object.handleOnMove();
        });

        // The selection box might be different after tidy,
        // we create new active object to update that.
        canvas.discardActiveObject();
        canvas.selectIds(selectedIds);

        canvas.fire('object:modified');
      }

      break;
    case ACTIVE_REMOVE_BG:
      if (!activeObject?.objectName) break;
      Nprogress.start();
      // Use a smaller version of an image, as rembg service can't handle big images.
      const src = buildImageProxyUrl(activeObject.objectName, {
        width: 2160,
        height: 0,
        scale: 1,
        keepFormat: true,
      });
      const finalize = () => {
        Nprogress.done();
        useMenuStore.getState().setRemovingBackground(false);
      };
      let objectName;
      try {
        const response = await GeneratorApi.removeBackground(src);
        Nprogress.inc(0.1);

        if ('error' in response) {
          throw new Error(response.error);
        }

        Nprogress.inc(0.4);
        objectName = response.objectName;
      } catch (_error) {
        // ignore errors here for now
      }
      if (!objectName) {
        try {
          // use remBG backend
          // Get a link to background removal service and fetch a file from there
          const rembgUrl = getRemBGUrl(src, value || {});
          const nobgfile = await fetch(rembgUrl);

          Nprogress.inc(0.5);

          // Create a user upload of a modified file
          const blob = await nobgfile.blob();
          const response = await uploadFile(blob);
          objectName = response.objectName;
        } catch (error) {
          monitoring.captureException(error);
          finalize();
          useToastStore
            .getState()
            .fire({ label: 'Failed to remove background.', error: true });
          break;
        }
      }

      Nprogress.inc(0.9);

      if (objectName) {
        activeObject.setObjectName(`api/${objectName}`, () => {
          canvas.fire('object:modified');
          finalize();
        });
      } else {
        finalize();
      }
      break;
    case OPEN_DOWNLOAD_PANEL:
      useMenuStore.setState({ downloadPanel: true });
      break;
    case DOWNLOAD:
      canvas.discardActiveObject(); // without this positions in the export can be wrong

      const isPngOrJpg = value.format === 'png' || value.format === 'jpg';
      const trackOptions = {
        label: value.format,
        dimensions: value.size,
        removeBackground: value.removeBackground,
        designId: canvas.config?.designId,
        templateId: canvas.config?.templateId,
      };
      if (isPngOrJpg) {
        trackOptions.optimizedQuality = value.optimizedQuality;
      }
      analytics.track(DOWNLOAD, trackOptions);

      if (isPngOrJpg) {
        await downloadImage(canvas, value);
      } else if (value.format === 'pdf') {
        await downloadPDF(canvas, value);
      } else {
        await downloadSVG(canvas, value);
      }
      value.callback && value.callback();
      break;
    case SET_STATE:
      const { isHistoryChange, state: newState } = value;
      const selectedStructureIds = canvas._selectedElements;

      const reselectElements =
        isHistoryChange && selectedStructureIds?.length
          ? (canvas) => {
              canvas.selectIds(selectedStructureIds);
              canvas.fire('object:modified');
            }
          : () => canvas.fire('object:modified');
      canvas.loadFromJSON(newState, reselectElements);
      break;
    case SELECT_ALL:
      if (canvas.hasMovingObject) break;
      canvas.discardActiveObject();

      const selectableTopLevelIds = [];
      canvas.groupStructure.children.forEach((child) => {
        const isGroup = Boolean(child.children);

        if (isGroup) {
          if (!child.meta.locked && !child.meta.hidden) {
            selectableTopLevelIds.push(child.id);
          }
        } else {
          const object = allObjects.find(({ id }) => id === child.id);
          if (object && !object.hidden && !object.locked) {
            selectableTopLevelIds.push(child.id);
          }
        }
      });
      canvas.selectIds(selectableTopLevelIds);

      break;
    case SET_BACKGROUND:
      canvas.modifyArtboard(value);
      break;
    case SET_COLOR_PALETTE:
      canvas.setColorPalette(value);
      canvas.lastColorPalette = null;
      !value.isChanging && canvas.fire('object:modified'); // update state
      break;
    case GRID_SET:
      useSettingsStore.getState().updateSettings({ gridSize: value.gridSize });
      break;
    case GRID_SHOW:
      useSettingsStore.getState().updateSettings({ showGrid: value });
      break;
    case TRIM_VIEW:
      useSettingsStore.getState().updateSettings({ trimView: value });
      break;
    case HIDE_TRANSPARENCY_GRID:
      canvas.modifyArtboard({ hideTransparencyGrid: value });
      break;
    case ZOOM:
      handleZoom(canvas, value);
      break;
    case ZOOM_TO:
      handleZoomTo(canvas, value);
      break;
    case ZOOM_STEP:
      handleZoomStep(canvas, value);
      break;
    case ZOOM_CENTER:
      handleZoomCenter(canvas);
      break;
    /* ZOOM_BY is deprecated. Use ZOOM_STEP */
    case ZOOM_BY:
      canvas.zoomToPoint(
        {
          x: canvas.width / 2,
          y: canvas.height / 2,
        },
        canvas.adjustZoom(canvas.getZoom() + value.zoom)
      );
      break;
    case ENABLE_SNAPPING:
      canvas.artboard.set('snappingEnabled', value);
      !value && clearAuxiliaryObjects(canvas, allObjects);
      break;
    case ENABLE_ZOOM_ON_WHEEL:
      handleEnableZoomOnWheel(canvas, value);
      break;
    case ENABLE_DRAGGING:
      handleEnableDragging(canvas, value);
      break;
    case RESIZE:
      useDownloadPanelStore.getState().resetOptions();
      const {
        key: activeLayout,
        size: { x, y, unit },
        dpi,
        portrait,
        resetHistory = false,
      } = value;
      const [width, height] = [x, y].sort((a, b) => (portrait ? a - b : b - a)); // in portrait mode, the larger side is height

      const multiplier =
        unit === 'mm'
          ? ONE_MM_IN_INCHES * ARTBOARD_DPI
          : unit === 'in'
          ? ARTBOARD_DPI
          : 1;

      // this updates and applies artboard options
      canvas.modifyArtboard({
        activeLayout,
        portrait,
        width: width * multiplier, // ---> These are the dimensions of the artboard (in pixels) in the canvas (without zoom)
        height: height * multiplier, // ---> These are the dimensions of the artboard (in pixels) in the canvas (without zoom)
        unitWidth: width, // ---> These are the dimensions of the artboard using the correct unit. It's editor agnostic
        unitHeight: height, // ---> These are the dimensions of the artboard using the correct unit. It's editor agnostic
        unit,
      });

      if (dpi) useDownloadPanelStore.getState().setOptions({ dpi });

      // center canvas on artboard
      canvas.centerArtboard();
      !value.isChanging && canvas.fire('object:modified');

      if (resetHistory) {
        dispatch(HISTORY_RESET, canvas.toJSON());
      }

      break;
    case SOCIAL_SHARE:
      Nprogress.start();
      const state = canvas.toJSON();

      Nprogress.inc(0.1);

      const previewImage = getSocialSharePreview(state, canvas);

      Nprogress.inc(0.6);

      const createShare = () => {
        handleShare(value.label, canvas.toJSON().config, previewImage).then(
          ({ url, target }) => {
            Nprogress.done();

            if (!target) {
              useMenuStore.setState({ shareUrl: url });
            }
          }
        );
      };

      if (state.config?.designId) {
        createShare();
      } else {
        dispatch(DESIGN_SAVE, {
          type: 'auto',
          cb: createShare,
        });
      }

      break;
    case DESELECT_OBJECTS:
      canvas.discardActiveObject(null, true);

      if (value?.colors) {
        useMenuStore.getState().mergeRightMenuState({
          activeGeneralPanel: null,
          activeObjectPanel: 'projectColors',
          open: true,
        });
      }
      break;
    case SELECT_ARTBOARD:
      const artboard = canvas.artboard;
      if (artboard) {
        canvas.setActiveObject(artboard);
      }
      break;
    case POST_SUBMIT:
      canvas.discardActiveObject(); // without this positions in the exported preview can be wrong
      const template = canvas.toJSON();
      // colorPalette does not to be saved
      delete template.colorPalette;

      const { title, templateId, dpiVersion } = template.config;

      // reduce config to title and templateId
      template.config = {
        title,
        templateId,
        dpiVersion,
      };

      const templatePreview = rasterizeImage.getDataUrl(canvas, {
        format: 'png',
        forViewport: true,
        targetSize: MIN_PREVIEW_SIZE,
      });

      let response;
      if (value.id) {
        response = await userApi.updatePost(
          value.id,
          value.category,
          value.description,
          value.templateName,
          template,
          templatePreview,
          value.tags,
          value.premium
        );
      } else {
        response = await userApi.createPost(
          value.category,
          value.description,
          value.templateName,
          template,
          templatePreview,
          value.tags,
          value.challengeId,
          value.premium
        );
      }

      value.callback && value.callback(response);
      break;
    case MAIN_EDIT: {
      const main = getMainElement(allObjects);

      if (main) {
        canvas.selectIds([main.id]);
        dispatch(EDIT_TEXT, { target: main });
      } else {
        dispatch(ADD_TEXT);
      }
      break;
    }
    case MAIN_SELECT: {
      const main = getMainElement(allObjects);

      if (main) {
        canvas.discardActiveObject();
        canvas.selectIds([main.id]);
        if (value?.effects) {
          useMenuStore.getState().mergeRightMenuState({
            activeGeneralPanel: null,
            activeObjectPanel: 'effects',
            open: true,
          });
        }
      } else {
        dispatch(ADD_TEXT);
      }
      break;
    }
    case OPEN_SHARE_PANEL: {
      useMenuStore.setState({ sharePanel: true });
      break;
    }
    case RELEASE_TEXTURE:
      canvas.releaseTexture();
      break;
    case SET_COLOR_PALETTE_PRESET:
      const { colorPalettePreset, colorKeys } = value;
      canvas.previousColorPalette = undefined;
      canvas.applyColorPalettePreset(colorPalettePreset, colorKeys);
      canvas.fire('object:modified');
      break;
    case SET_COLOR_PALETTE_PRESET_PREVIEW:
      const {
        colorPalettePreset: colorPalettePresetPreview,
        colorKeys: colorKeysPreview,
      } = value;
      if (!canvas.previousColorPalette) {
        canvas.previousColorPalette = canvas.getColorPalette();
      }
      canvas.isPreviewingColorPalette = true;
      canvas.applyColorPalettePreset(
        colorPalettePresetPreview,
        colorKeysPreview
      );
      break;
    case REMOVE_COLOR_PALETTE_PRESET_PREVIEW:
      const prevPalette = canvas.previousColorPalette;
      if (!prevPalette) return;
      const prevPaletteWithoutNoColor = Object.keys(prevPalette).filter(
        (x) => x !== 'null'
      );
      prevPaletteWithoutNoColor.forEach((colorKey) => {
        canvas.setColorPalette({
          toUpdate: prevPalette[colorKey],
          newColor: colorKey,
        });
      });
      canvas.isPreviewingColorPalette = false;
      canvas.previousColorPalette = undefined;
      break;
    default:
      if (isDebugActive()) {
        console.warn('Unknown Artboard event', event?.key, event?.val);
      }
  }
  canvas.requestRenderAll();
};

export default handleEvents;
