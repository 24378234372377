import React, { useRef, useEffect, useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { SET_COLOR_PALETTE } from '../../global/events';
import { Wrapper, HoverBlock } from './styles';
import ColorPaletteButton from '../ColorPaletteButton/ColorPaletteButton';
import { useMenuStore, menuStoreSelector } from '../../stores/menuStore';
import analytics from '../../global/analytics';
import { PROJECT_COLORS_OPEN } from '../../global/analytics/events';
import ColorSwatch from './ColorSwatch';

const getColorsFromColorPalette = (colorPalette) =>
  Object.keys(colorPalette).reverse();

const ColorPalette = ({ colorPalette, dispatch, gap, isLoading }) => {
  const [colors, setColors] = useState(getColorsFromColorPalette(colorPalette));
  useEffect(() => {
    setColors(getColorsFromColorPalette(colorPalette));
  }, [colorPalette]);

  useEffect(() => {
    setChoosingColor(false);
  }, [colors.length]);

  const lastColorPalette = useRef({});
  useEffect(() => {
    lastColorPalette.current = colorPalette;
  }, [colorPalette]);

  const dispatchEvent = useCallback(
    (oldColor, newColor, isChanging = false) => {
      const toUpdate = lastColorPalette.current[oldColor];

      if (dispatch) {
        dispatch(SET_COLOR_PALETTE, {
          toUpdate,
          newColor,
          isChanging,
        });
      }
    },
    [dispatch]
  );

  const mergeRightMenuState = useMenuStore(
    menuStoreSelector.mergeRightMenuState
  );
  const openProjectColors = () => {
    analytics.track(PROJECT_COLORS_OPEN);
    mergeRightMenuState({
      activeGeneralPanel: 'projectColors',
      open: true,
    });
  };

  const onChanging = useCallback(
    (color, newColor) => dispatchEvent(color, newColor, true),
    [dispatchEvent]
  );

  const onChanged = useCallback(
    (color, newColor) => dispatchEvent(color, newColor, false),
    [dispatchEvent]
  );

  const [choosingColor, setChoosingColor] = useState(false);

  const onColorPickerToggle = useCallback(
    () => setChoosingColor(true),
    [setChoosingColor]
  );

  const onColorPickerClose = useCallback(
    () => setChoosingColor(false),
    [setChoosingColor]
  );

  const wrapper = useRef();
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (!hovering && !choosingColor) wrapper.current.scrollLeft = 0;
  }, [choosingColor, hovering]);

  const onUnhover = () => {
    setHovering(false);
  };

  const onHover = () => {
    setHovering(true);
  };

  const colorCount = colors.length;

  return (
    <>
      <HoverBlock
        count={colorCount}
        gap={gap}
        hovering={hovering || choosingColor}
      />
      <Wrapper
        count={colorCount}
        gap={gap}
        hovering={hovering || choosingColor}
        choosingColor={choosingColor}
        onMouseLeave={onUnhover}
        onMouseEnter={onHover}
        ref={wrapper}
        isLoading={isLoading}
      >
        <ColorPaletteButton
          key="colorPaletteButton"
          hovering={hovering || choosingColor}
          onClick={openProjectColors}
        />
        {colors.map((color, index) => (
          <ColorSwatch
            // Here used `index` as key because we don't want to unmount then
            // mount the color picker on color change, as that will cause the
            // color picker to collapse while we are still picking the color.
            key={index}
            index={index}
            color={color}
            gap={gap}
            choosingColor={choosingColor}
            onChanging={onChanging}
            onChanged={onChanged}
            onToggle={onColorPickerToggle}
            onClose={onColorPickerClose}
            dispatch={dispatch}
          />
        ))}
      </Wrapper>
    </>
  );
};

ColorPalette.propTypes = {
  colorPalette: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  dispatch: PropTypes.func,
  gap: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default memo(ColorPalette);
