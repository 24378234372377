import PropTypes from 'prop-types';

export const Layer = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  locked: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.array,
});
