import styled from 'styled-components';
import { themeStyle } from '../../services/theming';

export const Wrapper = styled.div`
  height: 15px;
  min-width: 15px;
  border: 1px solid ${themeStyle.varInkMedium};
  background: ${themeStyle.varBackgroundBase};
  box-sizing: border-box;
  border-radius: 2.5px;
  text-align: center;
  line-height: ${themeStyle.fontSize10};
`;

export const Label = styled.label`
  font-family: ${themeStyle.fontFamily};
  font-weight: ${themeStyle.fontMedium};
  font-size: ${themeStyle.fontSize10};
  color: ${themeStyle.varInkMedium};
`;
