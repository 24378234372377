import { useCallback } from 'react';
import { LAYER_LOCKED } from '../../../global/events';
import Button from '../../Button/Button/Button';
import { secondarySmallTheme } from '../../Button/Button/theme';
import { PanelWrapper } from '../../RightMenu/styles';
import { P2 } from '../../utilities/Typography/styles';
import { UnlockMessage } from '../styles';
import { LockedPanelProps } from './types';

const LockedPanel: React.FC<LockedPanelProps> = ({ id, dispatch }) => {
  const unlock = useCallback(() => {
    dispatch(LAYER_LOCKED, { id });
  }, [dispatch, id]);

  return (
    <PanelWrapper>
      <UnlockMessage>
        <P2 style={{ textAlign: 'center' }}>
          This object is locked. Unlock the object to edit it.
        </P2>
        <Button
          icon={{ name: 'unlock', height: '12px' }}
          label="Unlock Object"
          width="134px"
          height="30px"
          theme={secondarySmallTheme}
          onClick={unlock}
        />
      </UnlockMessage>
    </PanelWrapper>
  );
};

export default LockedPanel;
