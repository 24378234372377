import { themeStyle } from '../../services/theming';
import { AutoSuggestTheme } from './types';

export const defaultTheme: AutoSuggestTheme = {
  // size
  width: '100%',
  height: '40px',

  // dropdown
  dropdownZIndex: themeStyle.zIndexM1,
  dropDownShadow: themeStyle.varShadowMedium,
  dropDownBackgroundColor: themeStyle.varBackgroundBase,
};
