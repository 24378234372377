import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import contentApi from '../global/contentApi';

const getRecentFontsFromQueue = (queue, fontList) => {
  if (!queue.length || !fontList.length) return [];

  const newRecentFonts = [];
  queue.forEach((q) => {
    const foundFont = fontList.find((f) => f.fontFamily === q.id);
    if (foundFont) newRecentFonts.push(foundFont);
  });
  return newRecentFonts;
};

export const commonFontsStore = createVanilla((set, get) => ({
  queue: [],
  // Full font objects to be displayed in UI.
  recentFonts: [],
  // Complete font list as it comes from the API.
  fontList: [],
  lastAddedSingleFont: null,

  init: async (user) => {
    if (!user) return;

    const contentUseQueue = await contentApi.getContentUses();
    if (!contentUseQueue) return;

    set({
      queue: contentUseQueue,
    });
  },
  setFontList: (fontList) => {
    set({ fontList });
    const newRecentFonts = getRecentFontsFromQueue(get().queue, fontList);
    if (newRecentFonts.length) set({ recentFonts: newRecentFonts });
  },
  // Adds fonts to the queue from Artboard objects. (stageLoadFromJson and ADD_TEXT_LAYOUT event)
  addFontsFromCanvas: async (objects) => {
    if (!objects.length) return;
    const pathTexts = objects.filter((o) => o.type === 'pathText');
    if (!pathTexts.length) return;

    const fontFamilies = pathTexts.map((f) => f.fontFamily);
    const noDuplications = [...new Set(fontFamilies)];

    await get().update(noDuplications);
  },
  addSingleFont: async (font) => {
    const fontFamily = font.fontFamily;
    if (fontFamily !== get().lastAddedSingleFont) {
      set({ lastAddedSingleFont: fontFamily });
      await get().update([fontFamily]);
    }
  },
  update: async (newQueue) => {
    const res = await contentApi.createContentUse(newQueue);
    if (!res) return;

    set({ queue: res });
    const newRecentFonts = getRecentFontsFromQueue(res, get().fontList);
    if (newRecentFonts.length) set({ recentFonts: newRecentFonts });
  },
}));

export const commonFontsStoreSelector = {
  recentFonts: (state) => state.recentFonts,
  init: (state) => state.init,
};

export default create(commonFontsStore);
