import React, { useCallback } from 'react';
import { TrendingTemplatesNotification } from '../../../../types';

import { buildImageProxyUrl, WEBSITE_URL } from '../../../../utils/url';
import NotificationHeader from '../NotificationHeader/NotificationHeader';
import PreviewImage from '../PreviewImage/PreviewImage';
import { NotificationWrapper, ImageRow } from './styles';
import { NotificationProps } from './types';

const WorkHighlightNotification: React.FC<
  NotificationProps<TrendingTemplatesNotification>
> = ({ notification, onReadNotification }) => {
  const trimmedTemplates = notification.data.slice(0, 6);
  const message = 'Their work is used a lot';

  const handleClick = useCallback(() => {
    onReadNotification(notification.id);
    if (!notification.relatedUser) return;
    window.open(
      `${WEBSITE_URL || ''}/${notification.relatedUser.slug}?sort=popularity`,
      WEBSITE_URL ? '_blank' : '_self'
    );
  }, [onReadNotification, notification.id, notification.relatedUser]);

  if (!trimmedTemplates.length) return null;

  return (
    <NotificationWrapper role="button" onClick={handleClick}>
      <NotificationHeader
        user={notification.relatedUser}
        message={message}
        createdAt={notification.createdAt}
        isUnseen={!notification.seenAt}
      />
      <ImageRow>
        {trimmedTemplates.map((t) => (
          <PreviewImage
            {...t}
            key={t.id}
            src={buildImageProxyUrl(t.templatePreviewObjectName)}
          />
        ))}
      </ImageRow>
    </NotificationWrapper>
  );
};

export default WorkHighlightNotification;
