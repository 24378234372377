import { useCallback, useEffect, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import BasePanel from '../LeftMenu/BasePanel/BasePanel';
import UserCredits from '../UserCredits/UserCredits';
import useTextToImagePanelStore from '../../stores/textToImagePanelStore';
import { userStoreSelector, useUserStore } from '../../stores/userStore';
import StyleElement from './StyleElement/StyleElement';
import { Element } from './StyleElement/types';
import { PanelProps } from '../LeftMenu/types';
import { PromptStyleGroup, SubscriptionPlan } from '../../types';
import { ADD_ILLUSTRATION } from '../../global/events';
import { toastSelector, useToastStore } from '../../stores/toastStore';
import { uploadStoreSelector, useUploadStore } from '../../stores/uploadStore';
import GeneratorApi, {
  GeneratedContentStatus,
  GenerateImageResponse,
} from '../../api/generators';
import DecisionModal from '../DecisionModal/DecisionModal';
import BodyElement from './BodyElement/BodyElement';
import { BasePanelHandler } from '../LeftMenu/BasePanel/types';
import {
  promotionSelector,
  usePromotionStore,
} from '../../stores/promotionStore';
import { P4 } from '../utilities/Typography/styles';
import { themeStyle } from '../../services/theming';

import { AI_GENERATE_IMAGE_CLICK } from '../../global/analytics/events';
import analytics from '../../global/analytics';
import ExtensionPanel from './ExtensionPanel';

const GENERATOR_LOOK_UP_INTERVAL = 3000; // 3s

const IconPrefix = (): JSX.Element => (
  <Icon
    name="starPairSolid"
    height="17px"
    theme={{ color: themeStyle.varBrandMedium }}
  />
);

const LabelPostfix = (): JSX.Element => (
  <P4 style={{ lineHeight: '20px', marginLeft: '4px' }}>(beta)</P4>
);

const TextToImagePanel: React.FC<PanelProps> = (props) => {
  const { style, prompt, generating, fetchGroups, mergeState } =
    useTextToImagePanelStore();
  const [groups, setGroups] = useState<PromptStyleGroup[]>([]);
  const [showRequestBlocked, setShowRequestBlocked] = useState(false);
  const basePanelHandler = useRef<BasePanelHandler>(null);

  const [showAvailableSoon, setShowAvailableSoon] = useState(false);
  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );

  const userPlan = useUserStore(userStoreSelector.plan);
  const userHasCredits = useUserStore(userStoreSelector.hasCredits);

  const handleUpgradePlanClick = useCallback(() => {
    if (userPlan !== SubscriptionPlan.EXPERT) {
      showUpgradeModal('textToImage');
      return;
    }
    setShowAvailableSoon(true);
  }, [userPlan, showUpgradeModal]);

  const closeAvailableSoon = useCallback(() => setShowAvailableSoon(false), []);

  const setStyleFromGalleryElement = useCallback(
    (element: Element) => {
      mergeState({ style: element.id === style ? null : element.id });
      basePanelHandler.current?.setShowExtension?.(false);
    },
    [mergeState, style]
  );

  const updateUserInfo = useUserStore(userStoreSelector.updateUserInfo);

  const { dispatch } = props;
  const fireToast = useToastStore(toastSelector.fire);

  const addAndFormatLocalUpload = useUploadStore(
    uploadStoreSelector.addAndFormatLocalUpload
  );

  const onImageGenerated = useCallback(
    (id: string, objectName: string) => {
      const callback = async (): Promise<void> => {
        const user = await updateUserInfo();
        if (
          user &&
          user.generationCredits + user.recurringGenerationCredits === 0 &&
          user.plan !== SubscriptionPlan.EXPERT
        ) {
          // show upgrade modal, when the user used their last credits
          showUpgradeModal('textToImage');
        }
        mergeState({ generating: false });
      };

      dispatch(ADD_ILLUSTRATION, {
        objectName: `api/${objectName}`,
        skipPopularityTracking: true,
        callback,
      });

      addAndFormatLocalUpload({ id, objectName });
    },
    [
      updateUserInfo,
      mergeState,
      dispatch,
      showUpgradeModal,
      addAndFormatLocalUpload,
    ]
  );

  const onError = useCallback(
    (error: string) => {
      mergeState({ generating: false });

      // for development purposes 'INVALID_PARAMETERS' can also mean other paramters
      // but for the user it should only happen, if the generator rejects the prompt
      if (error === 'INVALID_PARAMETERS') {
        setShowRequestBlocked(true);
        return;
      }
      if (error === 'TRY_AGAIN_LATER') {
        fireToast({
          label: 'Service is currently unavailable. Please try again later.',
          duration: 3000,
          error: true,
        });
        return;
      }
      fireToast({
        label: 'Error processing your request',
        duration: 3000,
        error: true,
      });
    },
    [fireToast, mergeState]
  );

  const handleResponse = useCallback(
    (response: GenerateImageResponse) => {
      if ('error' in response) {
        onError(response.error);
      } else if (response.status === GeneratedContentStatus.Failed) {
        onError('Failed to generate Image');
      } else if (response.status === GeneratedContentStatus.Started) {
        // look it up again
        setTimeout(async () => {
          const newResponse = await GeneratorApi.showGeneratedImage(
            response.id
          );
          handleResponse(newResponse);
        }, GENERATOR_LOOK_UP_INTERVAL);
      } else {
        // GeneratedContentStatus.Complete
        if (response.objectName) {
          onImageGenerated(response.id, response.objectName);
        } else {
          onError('Failed to load Image');
        }
      }
    },
    [onImageGenerated, onError]
  );

  const generateImage = useCallback(async () => {
    if (!userHasCredits || !prompt) {
      return;
    }

    mergeState({ generating: true });
    analytics.track(AI_GENERATE_IMAGE_CLICK, { plan: userPlan ?? 'FREE' });

    const response = await GeneratorApi.generateImage(prompt, style);
    handleResponse(response);
  }, [mergeState, userHasCredits, userPlan, prompt, style, handleResponse]);

  const renderImageStyleElement = useCallback(
    (element: Element) => {
      return (
        <StyleElement
          element={element}
          onSelect={setStyleFromGalleryElement}
          active={element.id === style}
        />
      );
    },
    [style, setStyleFromGalleryElement]
  );

  useEffect(() => {
    const loadGroups = async (): Promise<void> => {
      setGroups((await fetchGroups()).filter((group) => group.styles?.length));
    };
    loadGroups();
  }, [fetchGroups]);

  const [activeGroupId, setActiveGroupId] = useState<undefined | number>();
  const onShowAllFromGroup = useCallback((group: PromptStyleGroup): void => {
    setActiveGroupId(group.id);
    basePanelHandler.current?.setShowExtension?.(true);
  }, []);

  const bodyElement = (
    <>
      <BodyElement
        onGenerateImage={generateImage}
        onShowAll={onShowAllFromGroup}
        onUpgrade={handleUpgradePlanClick}
        renderImageStyleElement={renderImageStyleElement}
        hasCredits={userHasCredits}
        groups={groups}
      />
      <DecisionModal
        isOpen={showRequestBlocked}
        onClose={(): void => setShowRequestBlocked(false)}
        firstButton={{
          label: 'Cancel',
          type: 'primary',
          onClick: (): void => setShowRequestBlocked(false),
        }}
        icon="notificationCross"
        iconTheme={{
          color: themeStyle.varAlertRed,
          secondaryColor: themeStyle.varBackgroundAlt,
        }}
        title="Request blocked"
        message="Your request may not follow our content policy. Prompts that contain pornographic, immoral, or violence-glorifying terms are blocked."
      />
      <DecisionModal
        isOpen={showAvailableSoon}
        onClose={closeAvailableSoon}
        title="Available Soon"
        message="We will notify you immediately when credit upgrades are available. Thank you"
        firstButton={{
          label: 'Close',
          type: 'primary',
          onClick: closeAvailableSoon,
        }}
      />
    </>
  );

  return (
    <BasePanel
      {...props}
      label="Kittl AI"
      labelPrefix={IconPrefix}
      labelPostfix={LabelPostfix}
      bodyElement={bodyElement}
      headElement={<UserCredits onUpgrade={handleUpgradePlanClick} />}
      ref={basePanelHandler}
      extensionElement={
        <ExtensionPanel
          groups={groups}
          activeGroupId={activeGroupId}
          renderImageStyleElement={renderImageStyleElement}
        />
      }
      noOverflow={generating || !userHasCredits}
    />
  );
};

export default TextToImagePanel;
