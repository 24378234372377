import { getElementById } from '../../../utils/groupStructure';
import { buildElementUrl } from '../../../utils/url';

export default function (fabric) {
  /**
   * OverlayTexture class
   * This class handles OverlayTextures
   * @class fabric.OverlayTexture
   * @extends fabric.Image
   */
  fabric.OverlayTexture = fabric.util.createClass(fabric.Image, {
    type: 'overlayTexture',

    initialize: function (objectName, options, cb) {
      options || (options = {});
      options.objectName = objectName;

      this.callSuper('initialize', '', options);
      this.setTexture(objectName, cb);

      return this;
    },

    /**
     * update the underlying image of this texture
     * @param {String} objectName
     */
    setTexture: function (objectName, cb, retry) {
      const src = buildElementUrl(objectName);

      // load image
      this.setSrc(
        src,
        (_, isError) => {
          if (isError) {
            if (!retry) {
              // if old texture is still in cache, we reenable it
              return this.setTexture(this.objectName, cb, true);
            }
            cb && cb(this);
            return;
          }

          // update object
          this.objectName = objectName;
          this.scaleToArtboard();
          this.loaded = true;
          cb && cb(this);
        },
        { crossOrigin: 'anonymous' }
      );
    },

    /**
     * scale the texture to cover the full artboard
     */
    scaleToArtboard: function () {
      if (this.canvas?.artboard) {
        const artboard = this.canvas.artboard;
        this.setCoords();

        // Find smallest ratio of artboard:width / image:width and artboard:height / image: height
        const ratio = Math.min(
          artboard.width / this.width,
          artboard.height / this.height
        );

        // scale this to cover artboard
        if (ratio <= 1) {
          this.scaleToWidth(artboard.width, true);
          const newHeight = this.height * this.scaleY;

          if (newHeight < artboard.height) {
            this.scaleToHeight(artboard.height, true);
          }
        } else {
          this.scaleToHeight(artboard.height, true);
          const newWidth = this.width * this.scaleX;

          if (newWidth < artboard.width) {
            this.scaleToWidth(artboard.width, true);
          }
        }

        // align at center of artboard
        const shiftLeft = (artboard.width - this.width * this.scaleX) / 2;
        const shiftTop = (artboard.height - this.height * this.scaleY) / 2;
        this.left = artboard.left + shiftLeft;
        this.top = artboard.top + shiftTop;
      }
    },

    toObject: function () {
      const isObjectInCanvas =
        this.canvas?.groupStructure &&
        Boolean(getElementById(this.canvas.groupStructure, this.id));
      const obj = isObjectInCanvas ? this.callSuper('toObject') : {};
      return fabric.util.object.extend(obj, {
        texture: this.objectName,
        mode: this.globalCompositeOperation,
        opacity: this.opacity * 100,
        hidden: this.hidden,
        loaded: this.loaded,
        renderClip: this.renderClip,
        isAlphaMask: this.isAlphaMask,
      });
    },

    /**
     * By default fabric will not render the object if it's not visible (e.g opacity = 0)
     * But an alpha mask should always be rendered. If it's opacity is 0, it should
     * make objects below disappear. This makes it so it's always visible as far as fabric
     * is concerned.
     */
    isNotVisible: function () {
      if (this.isAlphaMask) return false;
      return this.callSuper('isNotVisible');
    },

    toSVG: function () {
      if (this.isAlphaMask) {
        const { globalCompositeOperation, opacity } = this;
        this.globalCompositeOperation = null;
        this.opacity = 1; // Opacity is embedded in the mask

        let markup = this.callSuper('toSVG');

        markup = markup.replace(
          '<g',
          '<g filter="url(#kittl-mask-invert) url(#kittl-mask-intensity)"'
        );

        markup = `
          <mask id="kittl-overlay-mask" mask-type="alpha">
            <filter id="kittl-mask-invert">
              <feComponentTransfer>
                <feFuncA type="table" tableValues="1 0"/>
              </feComponentTransfer>
            </filter>
            <filter id="kittl-mask-intensity">
              <feComponentTransfer>
                <feFuncA type="table" tableValues="${1 - opacity} 1"/>
              </feComponentTransfer>
            </filter>
            ${markup}
          </mask>
        `;

        this.globalCompositeOperation = globalCompositeOperation;
        this.opacity = opacity;
        return markup;
      }

      return this.callSuper('toSVG');
    },

    setRenderClip: function (value) {
      this.renderClip = value;
    },
  });

  fabric.OverlayTexture.fromObject = function (object, callback) {
    new fabric.IllustrationImage(
      object.objectName,
      {
        ...object,
        opacity: object.opacity / 100,
        objectName: object.texture,
        type: 'illustrationImage',
      },
      (target) => {
        callback(target);
      }
    );
  };
}
