import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import {
  Wrapper,
  SizeElementContainer,
  SizeElement,
  CustomSizeContainer,
  CustomInputContainer,
  CustomInputLabel,
  PresetsWrapper,
} from './styles';
import {
  MAXIMUM_SIZE,
  MINIMUM_SIZE,
  MAXIMUM_EXPORT_SIZE,
  MINIMUM_EXPORT_SIZE,
  UNIT_DESCRIPTIONS,
} from '../../global/constants';
import { RESIZE } from '../../global/events';
import { themeStyle } from '../../services/theming';
import { UpdateArtboardSizeOptions, ArtboardSizeModalProps } from './types';
import { ArtboardLayout, Unit } from '../../types';
import { convertToUnit } from '../../utils/artboard';
import { H4, P4, P3, P2 } from '../utilities/Typography/styles';
import { FlexRow } from '../utilities/styles';
import { layouts } from '../SettingsPanel/config';
import VerticallyScrollable from '../VerticallyScrollable/VerticallyScrollable';
import Icon from '../Icon/Icon';
import { IconType } from '../Icon/types';
import IconEventWrapper from '../Icon/IconEventWrapper';
import Select from '../SelectNew/Select';
import Button from '../Button/Button/Button';
import { useUserStore, userStoreSelector } from '../../stores/userStore';
import {
  promotionSelector,
  usePromotionStore,
} from '../../stores/promotionStore';
import NumberInput from '../NumberInput/NumberInput';
import { largeNumberInputTheme } from '../NumberInput/theme';

const unitOptions = Object.entries(UNIT_DESCRIPTIONS).map(([key, val]) => {
  return { id: key, label: val };
}, {});

type CustomSize = {
  width: number;
  height: number;
  dpi: number;
  unit: Unit;
};

const ArtboardSizeModal: React.FC<ArtboardSizeModalProps> = (
  props: ArtboardSizeModalProps
) => {
  const hasProAccess = useUserStore(userStoreSelector.proAccess);
  const [customSize, setCustomSize] = useState<CustomSize>({
    height: 1200,
    width: 1200,
    unit: Unit.px,
    dpi: 72,
  });
  const [activePreset, setActivePreset] = useState('standard');
  const [portraitModeActive, setPortraitModeActive] = useState(true);

  useEffect(() => {
    if (customSize.width > customSize.height) setPortraitModeActive(false);
    else if (customSize.height > customSize.width) setPortraitModeActive(true);
  }, [customSize, setPortraitModeActive]);

  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );
  function handleShowUpgradeModal(): void {
    if (hasProAccess) return;
    showUpgradeModal('customArtboardSize');
  }

  function handleClose(): void {
    props.onClose && props.onClose();
  }

  function handleResize(
    value: UpdateArtboardSizeOptions & { dpi: number }
  ): void {
    props.dispatch(RESIZE, value);
    props.onClose && props.onClose();
  }

  function handleSetPortraitMode(): void {
    if (portraitModeActive) return;
    setPortraitModeActive(true);
    setCustomSize((size) => ({
      ...size,
      width: size.height,
      height: size.width,
    }));
  }

  function handleSetLandscapeMode(): void {
    if (!portraitModeActive) return;
    setPortraitModeActive(false);
    setCustomSize((size) => ({
      ...size,
      width: size.height,
      height: size.width,
    }));
  }

  function handleCreateClick(): void {
    handleResize({
      size: {
        x: customSize.height,
        y: customSize.width,
        unit: customSize.unit,
      },
      dpi: customSize.dpi,
      key: activePreset,
      portrait: portraitModeActive,
      resetHistory: true,
    });
  }

  const onLayoutClick = (key: string): void => {
    setActivePreset(key);
    const layout = layouts[key as Exclude<ArtboardLayout, 'custom'>];
    let width: number;
    let height: number;
    if (portraitModeActive) {
      width = Math.min(layout.size.x, layout.size.y);
      height = Math.max(layout.size.x, layout.size.y);
    } else {
      width = Math.max(layout.size.x, layout.size.y);
      height = Math.min(layout.size.x, layout.size.y);
    }
    setCustomSize({
      width,
      height,
      unit: layout.size.unit,
      dpi: 72,
    });
  };

  const onInputSizeChange = (key: string, val: number): void => {
    setActivePreset('custom');
    setCustomSize((size) => ({
      ...size,
      [key]: val,
    }));
  };

  const onUnitChange = (unit: Unit): void => {
    setActivePreset('custom');
    const { width: newWidth, height: newHeight } = convertToUnit({
      width: customSize.width,
      height: customSize.height,
      oldUnit: customSize.unit,
      newUnit: unit,
    });
    setCustomSize((size) => ({
      width: newWidth,
      height: newHeight,
      unit,
      dpi: size.dpi,
    }));
  };

  const onDpiChange = (dpi: number): void => {
    setActivePreset('custom');
    if (customSize.unit === 'px') {
      let width = (customSize.width * dpi) / customSize.dpi;
      let height = (customSize.height * dpi) / customSize.dpi;

      const max = Math.max(width, height);
      const min = Math.min(width, height);

      if (min < MINIMUM_SIZE.px) {
        setCustomSize((size: CustomSize) => ({ ...size }));
        return;
      }

      if (max > MAXIMUM_SIZE['px']) {
        const longerSide = MAXIMUM_SIZE['px'];
        const shorterSide = (MAXIMUM_SIZE['px'] * min) / max;

        if (width >= height) {
          width = longerSide;
          height = shorterSide;
        } else {
          width = shorterSide;
          height = longerSide;
        }
      }

      setCustomSize((size: CustomSize) => ({
        width,
        height,
        dpi,
        unit: size.unit,
      }));
    } else {
      const sizeInPixel = convertToUnit({
        width: customSize.width,
        height: customSize.height,
        oldUnit: customSize.unit,
        newUnit: Unit.px,
        dpi,
      });

      const max = Math.max(sizeInPixel.width, sizeInPixel.height);
      const min = Math.min(sizeInPixel.width, sizeInPixel.height);

      if (max > MAXIMUM_EXPORT_SIZE['px'] || min < MINIMUM_EXPORT_SIZE.px) {
        setCustomSize((size: CustomSize) => ({ ...size }));
      } else {
        setCustomSize((size: CustomSize) => ({
          ...size,
          dpi,
        }));
      }
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={handleClose}
      width={'876px'}
      height={'588px'}
      noOverflowX
      noOverflow
      externalCloseButton
    >
      <Wrapper>
        <PresetsWrapper>
          <H4 style={{ paddingBottom: '12px' }}>Artboard Size Presets</H4>
          <VerticallyScrollable>
            <SizeElementContainer>
              {Object.entries(layouts).map(
                ([key, layout], index, { length }) => (
                  <IconEventWrapper key={key} activeOnHover={false}>
                    <SizeElement
                      onClick={(): void => onLayoutClick(key)}
                      className={activePreset === key ? 'active' : ''}
                      data-testid={`size-element-${index}`}
                      style={{
                        marginBottom: index + 4 > length ? '3px' : '0px',
                      }}
                    >
                      {
                        <Icon
                          name={
                            layout.icon
                              ? (layout.icon as keyof typeof IconType)
                              : 'a4_a5'
                          }
                          height={'26px'}
                          theme={{
                            color:
                              activePreset === key
                                ? themeStyle.varInkMain
                                : themeStyle.varInkMedium,
                          }}
                        />
                      }
                      <P2
                        style={{
                          paddingTop: '25px',
                          color:
                            activePreset === key
                              ? themeStyle.varInkMain
                              : themeStyle.varInkMedium,
                        }}
                      >
                        {layout.label}
                      </P2>
                      <P4
                        color={themeStyle.varInkMedium}
                        data-testid={`size-element-size-${index}`}
                      >
                        {layout.size.x}
                        {layout.size.unit === 'in' ? '"' : ''} x {layout.size.y}
                        {layout.size.unit === 'in' ? '"' : ''}{' '}
                        {layout.size.unit !== 'in' ? layout.size.unit : ''}
                      </P4>
                    </SizeElement>
                  </IconEventWrapper>
                )
              )}
            </SizeElementContainer>
          </VerticallyScrollable>
        </PresetsWrapper>
        <CustomSizeContainer>
          <H4 style={{ paddingBottom: '10px' }}>Settings</H4>
          {!hasProAccess && (
            <FlexRow style={{ marginBottom: '10px', alignItems: 'center' }}>
              <Icon name="premiumBadge" height="15px" />
              <P3
                color={themeStyle.varInkMedium}
                style={{
                  marginLeft: '8px',
                }}
              >
                Custom Size
              </P3>
              <P3
                textDecoration={'underline'}
                cursor={'pointer'}
                color={themeStyle.varBrandMain}
                style={{
                  marginLeft: '5px',
                }}
                onClick={handleShowUpgradeModal}
              >
                Upgrade Plan
              </P3>
            </FlexRow>
          )}
          <FlexRow
            onClick={handleShowUpgradeModal}
            style={{ opacity: !hasProAccess ? 0.5 : 1 }}
          >
            <CustomInputContainer style={{ paddingRight: '12px' }}>
              <CustomInputLabel>Width</CustomInputLabel>
              <NumberInput
                min={MINIMUM_SIZE[customSize.unit]}
                max={MAXIMUM_SIZE[customSize.unit]}
                value={{ value: customSize.width }}
                theme={largeNumberInputTheme}
                onChanging={(val: number): void =>
                  onInputSizeChange('width', val)
                }
                onChanged={(val: number): void =>
                  onInputSizeChange('width', val)
                }
                precision={customSize.unit === 'px' ? 0 : 2}
                disabled={!hasProAccess}
                dataTestId="width-input"
              />
            </CustomInputContainer>
            <CustomInputContainer>
              <CustomInputLabel>Height</CustomInputLabel>
              <NumberInput
                min={MINIMUM_SIZE[customSize.unit]}
                max={MAXIMUM_SIZE[customSize.unit]}
                value={{ value: customSize.height }}
                theme={largeNumberInputTheme}
                onChanging={(val: number): void =>
                  onInputSizeChange('height', val)
                }
                onChanged={(val: number): void =>
                  onInputSizeChange('height', val)
                }
                precision={customSize.unit === 'px' ? 0 : 2}
                disabled={!hasProAccess}
                dataTestId="height-input"
              />
            </CustomInputContainer>
          </FlexRow>
          <FlexRow
            style={{ paddingTop: '12px', opacity: !hasProAccess ? 0.5 : 1 }}
            onClick={handleShowUpgradeModal}
          >
            <CustomInputContainer style={{ paddingRight: '12px' }}>
              <CustomInputLabel>Unit</CustomInputLabel>
              <Select
                options={unitOptions}
                activeOption={unitOptions.find(
                  (opt) => opt.id === customSize.unit
                )}
                onSelect={(val): void => onUnitChange(val.id as Unit)}
                disabled={!hasProAccess}
                customStyles={{
                  fontSize: themeStyle.fontSize12,
                  paddingLeft: '8px',
                }}
              />
            </CustomInputContainer>
            <CustomInputContainer>
              <CustomInputLabel>DPI</CustomInputLabel>
              <NumberInput
                min={10}
                max={300}
                value={{ value: customSize.dpi }}
                theme={largeNumberInputTheme}
                onChanging={(val: number): void => onDpiChange(val)}
                onChanged={(val: number): void => onDpiChange(val)}
                precision={0}
                disabled={!hasProAccess}
                dataTestId="dpi-input"
              />
            </CustomInputContainer>
          </FlexRow>
          <P4 style={{ paddingTop: '12px', color: themeStyle.varInkMedium }}>
            Orientation
          </P4>
          <FlexRow>
            <Button
              icon={{ name: 'portrait' }}
              height={'38px'}
              width={'38px'}
              theme={{
                color: themeStyle.varInkMedium,
                backgroundColor: portraitModeActive
                  ? themeStyle.varBrandLight
                  : themeStyle.varInkSuperLight,
                backgroundColorDark: portraitModeActive
                  ? themeStyle.varBrandLight
                  : themeStyle.varInkSuperLight,
                borderColor: themeStyle.varInkSuperLight,
                borderColorDark: themeStyle.varInkSuperLight,
                borderActiveColor: themeStyle.varInkSuperLight,
                iconActiveColor: themeStyle.varInkMain,
              }}
              isActive={portraitModeActive}
              style={{ marginRight: '5px' }}
              onClick={handleSetPortraitMode}
              dataTestId="portrait-mode-button"
            />
            <Button
              icon={{ name: 'landscape', height: '15px' }}
              height={'38px'}
              width={'38px'}
              theme={{
                color: themeStyle.varInkMedium,
                backgroundColor: !portraitModeActive
                  ? themeStyle.varBrandLight
                  : themeStyle.varInkSuperLight,
                backgroundColorDark: !portraitModeActive
                  ? themeStyle.varBrandLight
                  : themeStyle.varInkSuperLight,
                borderColor: themeStyle.varInkSuperLight,
                borderColorDark: themeStyle.varInkSuperLight,
                borderActiveColor: themeStyle.varInkSuperLight,
                iconActiveColor: themeStyle.varInkMain,
              }}
              isActive={!portraitModeActive}
              onClick={handleSetLandscapeMode}
              dataTestId="landscape-mode-button"
            />
          </FlexRow>
          <Button
            label={'Create'}
            width={'204px'}
            height={'40px'}
            style={{ marginTop: 'auto', width: '100%' }}
            onClick={handleCreateClick}
            dataTestId="create-button"
          />
        </CustomSizeContainer>
      </Wrapper>
    </Modal>
  );
};

export default ArtboardSizeModal;
