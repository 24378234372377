import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { BREAKPOINTS } from '../../utilities/queries';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 33px 24px;
  border-top-right-radius: ${themeStyle.radiusLarge};
  border-bottom-right-radius: ${themeStyle.radiusLarge};

  background: ${themeStyle.backgroundGradientFull};

  display: none;
  @media (min-width: ${BREAKPOINTS.largeTabletMin}px) {
    display: flex;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 42px 16px 24px 24px;
`;

export const BottomContent = styled.div`
  margin-top: auto;
`;

export const Benefit = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const Brands = styled.img`
  width: 100%;
`;
