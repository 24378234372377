import { useEffect } from 'react';

import fabric from '../../Artboard/fabric';
import {
  mockupTemplateStoreSelector,
  useMockupTemplateStore,
} from '../../../stores/mockupTemplateStore';
import { MockupTemplateLayer } from '../../../stores/mockupTemplateStore/types';

const maybeSetBlendingMode = (
  object: fabric.Object,
  layerName: MockupTemplateLayer
): void => {
  if (layerName === MockupTemplateLayer.DarkBlendLayer) {
    object.set('globalCompositeOperation', 'screen');
  } else if (layerName === MockupTemplateLayer.LightBlendLayer) {
    object.set('globalCompositeOperation', 'multiply');
  }
};

const addLayerToCanvas = (
  canvas: fabric.Canvas,
  object: fabric.Object,
  layerName: MockupTemplateLayer
): void => {
  canvas[layerName] = object;

  if (layerName === MockupTemplateLayer.SceneLayer) {
    canvas.insertAt(object, 1);
  } else if (layerName === MockupTemplateLayer.LightBlendLayer) {
    canvas.insertAt(object, 2);
  } else if (layerName === MockupTemplateLayer.DarkBlendLayer) {
    canvas.insertAt(object, 3);
  }
};

/**
 * This hook handles side effects when there's a new layer uploaded.
 */
export const useLayerEffect = (
  canvas: fabric.Canvas | null,
  layerName: MockupTemplateLayer
): void => {
  const layer = useMockupTemplateStore(mockupTemplateStoreSelector[layerName]);

  useEffect(() => {
    if (!canvas) return;

    if (canvas[layerName]) {
      canvas.remove(canvas[layerName]);
    }

    if (layer) {
      fabric.Image.fromURL(layer, (object: fabric.Image) => {
        object.set('locked', true);
        maybeSetBlendingMode(object, layerName);
        addLayerToCanvas(canvas, object, layerName);
      });
    }
  }, [canvas, layerName, layer]);
};
