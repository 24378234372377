import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
  cloneElement,
} from 'react';
import { createPortal } from 'react-dom';
import noop from 'lodash/noop';

import Context from '../context';
import { calculateTooltipPosition } from './utils';
import { TooltipWrapper } from './styles';
import { TooltipAlignment, TooltipPlacement, TooltipProps } from './types';

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      text,
      children,
      placement = TooltipPlacement.Top,
      align = TooltipAlignment.Start,
      delay = 1,
      offsetX = 0,
      offsetY = 0,
      ...otherProps
    },
    ref
  ) => {
    const context = useContext(Context);

    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState({
      left: '0',
      top: '0',
      isPositioned: false,
    });

    const targetRef = useRef<HTMLElement | null>(null);
    const popoverRef = useRef<HTMLDivElement | null>(null);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const hoverHandler = useCallback(
      (_: unknown, flag: boolean) => {
        timerRef.current && clearTimeout(timerRef.current);
        if (flag) {
          timerRef.current = setTimeout(() => setIsOpen(true), delay * 100);
        } else {
          setIsOpen(false);
          setPosition({ left: '0', top: '0', isPositioned: false });
        }
      },
      [delay]
    );

    // We clear the timeout function when component unmounts
    useEffect(() => {
      return (): void => {
        timerRef.current && clearTimeout(timerRef.current);
      };
    }, []);

    useEffect(() => {
      const target = targetRef.current;
      if (target) {
        const onHoverIn = (): void => hoverHandler(target, true);
        const onHoverOut = (): void => hoverHandler(target, false);
        target.addEventListener('pointerover', onHoverIn);
        target.addEventListener('pointerleave', onHoverOut);
        target.addEventListener('pointerdown', onHoverOut);

        return (): void => {
          target.removeEventListener('pointerover', onHoverIn);
          target.removeEventListener('pointerleave', onHoverOut);
          target.removeEventListener('pointerdown', onHoverOut);
        };
      }
      return noop;
    }, [hoverHandler]);

    const calculatePosition = useCallback(
      (targetRect: DOMRect, popoverRect: DOMRect, containerRect: DOMRect) =>
        calculateTooltipPosition({
          targetRect,
          popoverRect,
          containerRect,
          align,
          placement,
          offsetX,
          offsetY,
        }),
      [align, placement, offsetX, offsetY]
    );

    const updatePosition = useCallback(() => {
      const rootContainer = context.getRootContainer();
      if (
        !isOpen ||
        !targetRef.current ||
        !popoverRef.current ||
        !rootContainer
      ) {
        return;
      }

      const targetRect = targetRef.current.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();
      const containerRect = rootContainer.getBoundingClientRect();

      const [left, top] = calculatePosition(
        targetRect,
        popoverRect,
        containerRect
      );

      if (left !== position.left || top !== position.top) {
        setPosition({
          left: left,
          top: top,
          isPositioned: true,
        });
      }
    }, [position, calculatePosition, context, isOpen]);

    useEffect(() => {
      // Update position on re-render
      updatePosition();
    }, [updatePosition, isOpen]);

    const tooltipElement = (
      <TooltipWrapper
        style={{
          left: position.left + 'px',
          top: position.top + 'px',
        }}
        ref={popoverRef}
        isPositioned={position.isPositioned}
      >
        {text}
      </TooltipWrapper>
    );

    const renderTooltip = (): JSX.Element | null => {
      const rootContainer = context.getRootContainer();
      if (!text || !isOpen || !rootContainer) return null;
      return createPortal(tooltipElement, rootContainer);
    };

    return (
      <>
        {cloneElement(children, {
          ...otherProps,
          ref: (node: HTMLDivElement) => {
            targetRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          },
        })}
        {renderTooltip()}
      </>
    );
  }
);

export default Tooltip;
