import React from 'react';

export enum TooltipPlacement {
  Bottom = 'bottom',
  Top = 'top',
  Right = 'right',
  Left = 'left',
}

export enum TooltipAlignment {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export interface TooltipProps {
  /**
   * Element to display a tooltip on
   */
  children: React.ReactElement;
  /**
   * Text to show on tooltip
   */
  text?: string;
  /**
   * On which side of the target should the popover be placed
   */
  placement?: TooltipPlacement;
  /**
   * How should the popover be aligned in relation to the target
   */
  align?: TooltipAlignment;
  /**
   * Offset x allows customization of vertical tooltip position
   */
  offsetX?: number;
  /**
   * Offset y allows customization of horizontal tooltip position
   */
  offsetY?: number;
  /**
   * Delay (in seconds) in which the tooltip will be shown
   */
  delay?: number;
}
