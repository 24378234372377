export const mouseEventDefaults = {
  view: window,
  bubbles: true,
  cancelable: true,
};

export function touchAsMouseEvent(touch, mouseEventName) {
  return new MouseEvent(mouseEventName, {
    ...mouseEventDefaults,
    clientX: touch.clientX,
    clientY: touch.clientY,
    screenX: touch.screenX,
    screenY: touch.screenY,
    offsetX: touch.offsetX,
    offsetY: touch.offsetY,
  });
}

export function dispatchMouseEventFromTouch(
  touch,
  mouseEventName,
  targetElement
) {
  const mouseEvent = touchAsMouseEvent(touch, mouseEventName);
  // this is a hack to make fabric think that this event is a touch event;
  mouseEvent.pointerType = 'touch';
  targetElement.dispatchEvent(mouseEvent);
}
