// values from this file are used in case there is no event in global/events.js, that can be used

export const LEFT_MENU_PREFIX = 'leftMenu';
export const LEFT_MENU_OPEN = `${LEFT_MENU_PREFIX}:open`;
export const LEFT_MENU_CLOSE = `${LEFT_MENU_PREFIX}:close`;
export const LEFT_MENU_CHANGE = `${LEFT_MENU_PREFIX}:change`;
export const LEFT_MENU_EXPAND = `${LEFT_MENU_PREFIX}:expand`;
export const LEFT_MENU_COLLAPSE = `${LEFT_MENU_PREFIX}:collapse`;

export const RIGHT_MENU_PREFIX = 'rightMenu';
export const RIGHT_MENU_OPEN = `${RIGHT_MENU_PREFIX}:open`;
export const RIGHT_MENU_CLOSE = `${RIGHT_MENU_PREFIX}:close`;
export const PROJECT_COLORS_OPEN = `${RIGHT_MENU_PREFIX}:projectColors:open`;

export const USER_PREFIX = 'user';
export const USER_LOGIN = `${USER_PREFIX}:login`;
export const USER_SIGNUP = `${USER_PREFIX}:signup`;
export const USER_LOGOUT = `${USER_PREFIX}:logout`;

export const SEARCH_PREFIX = 'search';
export const SEARCH_TEMPLATES = `${SEARCH_PREFIX}:templates`;
export const SEARCH_ELEMENTS = `${SEARCH_PREFIX}:elements`;
export const SEARCH_TEXTURES = `${SEARCH_PREFIX}:textures`;
export const SEARCH_FONTS = `${SEARCH_PREFIX}:fonts`;
export const SEARCH_PHOTOS = `${SEARCH_PREFIX}:photos`;

export const ONBOARDING_STEP = `onboarding:step`;

// Upload events
export const UPLOAD_PREFIX = 'upload';
export const UPLOAD_MEDIA_CLICK = `${UPLOAD_PREFIX}:mediaClick`;
export const UPLOAD_FONT_BUTTON_CLICK = `${UPLOAD_PREFIX}:customFontClick`;
export const UPLOAD_CREATE = `${UPLOAD_PREFIX}:create`;
export const UPLOAD_CAUTION_MODAL_CLOSE = `${UPLOAD_PREFIX}:cautionModalClose`;
export const UPLOAD_CHOOSE_FONTS_CLICK = `${UPLOAD_PREFIX}:chooseFontsClick`;
export const UPLOAD_FONTS_CHOSEN = `${UPLOAD_PREFIX}:fontsChosen`;
export const UPLOAD_FONTS_PICKER_CLOSE = `${UPLOAD_PREFIX}:filePickerClose`;
export const UPLOAD_FONTS_CANCELLED = `${UPLOAD_PREFIX}:uploadCancelled`;
export const UPLOAD_FONTS_RENAMED = `${UPLOAD_PREFIX}:fontRenamed`;
export const UPLOAD_FONTS_STYLE_RENAMED = `${UPLOAD_PREFIX}:fontStyleRenamed`;
export const UPLOAD_FONTS_STYLE_DELETED = `${UPLOAD_PREFIX}:fontStyleDeleted`;
export const UPLOAD_FONTS_ADDMORE = `${UPLOAD_PREFIX}:addMoreFontsClick`;
export const UPLOAD_FONTS_ADD_TO_LIB = `${UPLOAD_PREFIX}:addFontToLibrary`;

export const USER_NAVIGATION_CLICK = 'userNavigation:click';

// Notification events
export const NOTIFICATION_PREFIX = 'notification';
export const CLICK_NOTIFICATION = `${NOTIFICATION_PREFIX}:click`;

// Invite friends modal and panel events
export const INVITE_PREFIX = 'invite';
export const PROJECTLIMIT_MODAL = `${INVITE_PREFIX}:modalOpen`;
export const PROJECTLIMIT_MODAL_CLICK = `${INVITE_PREFIX}:modalActionClick`;
export const PROJECTLIMIT_PANEL = `${INVITE_PREFIX}:panelShown`;
export const PROJECTLIMIT_PANEL_CLICK = `${INVITE_PREFIX}:panelActionClick`;
export const DOWNLOAD_MODAL = `${INVITE_PREFIX}:downloadModalShown`;
export const DOWNLOAD_MODAL_CLICK = `${INVITE_PREFIX}:downloadModalActionClick`;
export const UPLOAD_FONT_MODAL = `${INVITE_PREFIX}:uploadFontModalShown`;
export const UPLOAD_FONT_MODAL_CLICK = `${INVITE_PREFIX}:uploadFontModalClick`;

const UPGRADE_MODAL_PREFIX = 'upgradeModal';
export const UPGRADE_MODAL_SHOW = `${UPGRADE_MODAL_PREFIX}:show`;
export const UPGRADE_MODAL_CLICK = `${UPGRADE_MODAL_PREFIX}:click`;

export const POPUP_LEARN_MORE_SHOW = `${INVITE_PREFIX}:popupLearnMoreShow`;
export const POPUP_LEARN_MORE_CLICK = `${INVITE_PREFIX}:popupLearnMoreClick`;

const ATTRIBUTION_PREFIX = 'attributionModal';
export const ATTRIBUTION_SHOW = `${ATTRIBUTION_PREFIX}:show`;
export const ATTRIBUTION_COPY = `${ATTRIBUTION_PREFIX}:copyLink`;
export const ATTRIBUTION_CLICK_UPGRADE = `${ATTRIBUTION_PREFIX}:clickUpgrade`;

// invite friends button in header
export const INVITE_FRIENDS_CLICK = `${INVITE_PREFIX}:inviteFriendsClick`;

// Bottom menu
export const SHORTCUTS_MENU_OPEN = `shortcuts:open`;

// Project limits
export const PROJECTLIMIT_WEBSITE_PANEL = `${INVITE_PREFIX}:projectLimitWebsitePanel`;
export const PROJECTLIMIT_WEBSITE_PANEL_CLICK = `${INVITE_PREFIX}:projectLimitWebsitePanelClick`;

// Authentication
const AUTHENTICATION_PREFIX = 'authentication';
// This guy is used for anything that opens signup e.g. clicking `Use Design` on the website
// we need to pass `label` with source of the event
export const AUTHENTICATION_CLICK_SIGNUP = `${AUTHENTICATION_PREFIX}:clickSignup`;
export const AUTHENTICATION_CLICK_LOGIN = `${AUTHENTICATION_PREFIX}:clickLogin`;
export const AUTHENTICATION_SWITCH = `${AUTHENTICATION_PREFIX}:switchView`;
export const AUTHENTICATION_SHOW = `${AUTHENTICATION_PREFIX}:show`;
export const AUTHENTICATION_FORGOT_PASSWORD = `${AUTHENTICATION_PREFIX}:passwordForgot`;

// Signup
export const SIGNUP_PREFIX = 'signup';
export const CREATE_ACCOUNT = `${SIGNUP_PREFIX}:createAccount`;
export const SIGNUP_SERVER_ERROR = `${SIGNUP_PREFIX}:serverError`;
export const SIGNUP_LOCAL_ERROR = `${SIGNUP_PREFIX}:localError`;
export const SIGNUP_MOBILE = `${SIGNUP_PREFIX}:mobile`;

// Login
export const LOGIN_PREFIX = 'login';
export const LOGIN = `${LOGIN_PREFIX}:createAccount`;
export const LOGIN_SERVER_ERROR = `${LOGIN_PREFIX}:serverError`;
export const LOGIN_LOCAL_ERROR = `${LOGIN_PREFIX}:localError`;

// Social
export const SOCIAL_PREFIX = 'social';
export const SOCIAL_FACEBOOK = `${SOCIAL_PREFIX}:facebook`;
export const SOCIAL_GOOGLE = `${SOCIAL_PREFIX}:google`;

// Form
export const TOGGLE_PASSWORD = `${LOGIN_PREFIX}:togglePassword`;

export const TEMPLATE_BOOKMARK = `template:bookmark`;
export const FOLLOW_CREATE = `follow:create`;

export const CLICK_DOWNLOAD_PANEL_REMOVE_BACKGROUND_SWITCH = `downloadPanel:removeBackgroundClick`;
export const CLICK_DOWNLOAD_PANEL_OPTIMIZE_QUALITY_SWITCH = `downloadPanel:optimizeQualityClick`;
export const CLICK_DOWNLOAD_PANEL_EXPORT_LABEL = `downloadPanel:exportLabelClick`;

// Growthbook (A/B testing)
export const AB_PREFIX = 'abTest';
export const AB_TRACKINGCALLBACK = `${AB_PREFIX}:trackingCallback`;

// Background removal
export const BACKGROUND_REMOVAL_CLICK = `backgroundRemoval:click`;

// AI
export const AI_GENERATE_IMAGE_CLICK = 'ai:generateImageClick';

// Mockups
export const MOCKUP_PREFIX = 'mockup';
export const MOCKUP_OPEN = `${MOCKUP_PREFIX}:open`;
export const MOCKUP_ADD = `${MOCKUP_PREFIX}:add`;
export const MOCKUP_DOWNLOAD = `${MOCKUP_PREFIX}:download`;
export const MOCKUP_SAVE_TO_UPLOADS = `${MOCKUP_PREFIX}:saveToUploads`;
