import styled from 'styled-components';

import { P4 } from '../../utilities/Typography/styles';
import { themeStyle } from '../../../services/theming';

export const Wrapper = styled.div`
  padding: 8px 16px 16px;
`;

export const Controls = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc((100% - 10px) / 2));
  gap: 10px;
  align-items: center;
  margin-bottom: 12px;
`;

export const ContentWrapper = styled.div`
  height: ${({ gridHeight }) => gridHeight}px;
  box-sizing: border-box;
`;

export const Item = styled.div`
  box-sizing: border-box;
  position: relative;
  text-align: center;
  border-radius: ${themeStyle.radiusSmall};

  cursor: pointer;

  background: transparent;
  transition: background ${themeStyle.transitionDuration};

  margin: 0 auto; /* horizontally center in grid element */

  img {
    height: 90%;
    width: 90%;
  }

  &:hover {
    background: ${themeStyle.varInkSuperLight};
  }

  html[data-theme='dark'] && {
    img {
      filter: invert(0.92);
    }
  }
`;

export const Message = styled(P4)`
  margin-top: 20px;
  text-align: center;
  color: ${themeStyle.varInkMedium};
`;
