import styled from 'styled-components';
import { FlexColumn, FlexRow } from '../utilities/styles';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  border-radius: 8px;
`;

export const NextItemsWrapper = styled(FlexRow)`
  flex-wrap: wrap;
`;

export const NextItem = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const BottomBanner = styled(FlexColumn)`
  width: 100%;
  padding: 32px 0;
  background-size: cover;
  background-position: center center;
  background-image: url('/images/onboardingCongratsBottomBanner.png');
`;
