import styled from 'styled-components';

export const ButtonList = styled.div`
  button {
    margin-right: 10px;
  }
  button:not(:last-child) {
    margin-bottom: 10px;
  }
`;
