import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { activeStyle, IconStyle } from '../Icon/styles';
import { darkBackgroundBlur } from '../utilities/styles';

export const MenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: auto;
  z-index: ${themeStyle.zIndexS2};
`;

export const MenuButton = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 32px 1fr 55px;
  align-items: center;
  padding: 7px 8px 7px 0;
  line-height: 16px;
  background: ${themeStyle.transparent};
  color: ${({ textColor }) => textColor};
  user-select: none;
  cursor: pointer;
  transition-property: all;
  transition-duration: ${themeStyle.transitionDuration};

  span {
    font-family: ${themeStyle.fontFamily};
    font-size: ${themeStyle.fontSize10};
    font-weight: ${themeStyle.fontMedium};
  }

  &:hover {
    background: ${themeStyle.varBrandSuperLight};
    color: ${themeStyle.varInkMain};

    ${IconStyle} {
      ${activeStyle}
    }
  }
`;

export const IconWrapper = styled.div`
  width: 32px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShortCutLabel = styled.span`
  margin-left: auto;
`;

export const RightClickMenuWrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 185px;
  opacity: ${(props) => (props.isInPosition ? '1' : '0')};
  background: ${themeStyle.varBackgroundAlt};
  border-radius: ${themeStyle.radiusMedium};
  box-shadow: ${themeStyle.varShadowSmallHeavy};
  overflow: hidden;
  transition-property: opacity;
  transition-duration: ${themeStyle.transitionDuration};

  ${darkBackgroundBlur}
`;

export const Divider = styled.div`
  position: absolute;
  width: 100%;
  height: ${({ height }) => height ?? '1px'};
  background: ${themeStyle.varInkLight};
  opacity: 0.2;
  transform: translate(0, -50%);
`;
