import React, { useState, useEffect, useCallback } from 'react';
import { VanillaNumberInput as NumberInput } from '../NumberInput/NumberInput';
import { Container, SliderContainer } from './styles';
import { defaultSliderInputTheme } from './theme';
import Slider from '../Slider/Slider';
import { SliderInputProps } from './types';

const SliderInput: React.FC<SliderInputProps> = ({
  startValue,
  onChanged,
  onChanging,
  ...props
}) => {
  const [value, setValue] = useState(startValue);

  useEffect(() => {
    setValue(startValue);
  }, [startValue]);

  const _onChanged = useCallback(
    (value: number) => {
      setValue(value);
      if (onChanged) onChanged(value);
    },
    [onChanged]
  );

  const _onChanging = useCallback(
    (value: number) => {
      setValue(value);
      if (onChanging) onChanging(value);
    },
    [onChanging]
  );

  const theme = {
    ...defaultSliderInputTheme,
    ...props.theme,
  };

  const onPointerDown = useCallback(
    (event: React.PointerEvent<HTMLDivElement>): void => {
      (event.target as HTMLDivElement).setPointerCapture(event.pointerId);
    },
    []
  );

  return (
    <Container
      disabled={props.disabled}
      theme={theme}
      data-testid={props.dataTestId}
      onPointerDown={onPointerDown}
    >
      <SliderContainer
        numberInputWidth={theme.width}
        hasNumberInput={props.hasNumberInput}
      >
        <Slider
          allowWheel
          {...props}
          dataTestId={props.dataTestId ? `${props.dataTestId}--slider` : ''}
          value={value}
          theme={theme}
          onChanging={_onChanging}
          onChanged={_onChanged}
        />
      </SliderContainer>
      {props.hasNumberInput && (
        <NumberInput
          selectOnFocus
          {...props}
          dataTestId={
            props.dataTestId ? `${props.dataTestId}--number-input` : ''
          }
          value={{ value }}
          theme={{
            ...theme,
            marginBottom: props.marks || props.showCenterGuide ? '10px' : '0',
          }}
          onChanging={_onChanging}
          onChanged={_onChanged}
        />
      )}
    </Container>
  );
};

SliderInput.defaultProps = {
  min: 0,
  max: 100,
  precision: 2,
  pointerPolicy: 'delta',
  hasNumberInput: true,
};

export default SliderInput;
