export default {
  locked: false,
  fill: 'rgba(121, 49, 49, 0.5)',
  colors: [{ key: 'fill', value: 'rgba(121, 49, 49, 0.5)' }],
  type: 'pathText',
  borderWeight: {
    isDisabled: true,
    value: 2,
  },
  characterStyles: {
    fontWeight: 'Regular',
    textAlignment: 'center',
    fontFamily: 'BlackriverBold',
    uppercase: false,
    useLigatures: true,
    fontSize: 30,
    letterSpacing: 10,
    lineHeight: 400,
  },
  shadowOptions: {
    type: null,
    offset: 5,
    angle: -90,
    blur: 5,
    outlineWidth: 0,
    color: 'rgba(0,0,0,0.5)',
  },
  decorationOptions: {
    type: null,
    weight: 0.05,
    distance: 0.05,
    cutDistance: 0.5,
    color: '#888',
  },
  transformOptions: {
    type: null,
    curve: 80,
  },
};
