import paper from '@kittl/paper';
import fabric from '../components/Artboard/fabric';

/**
 * Parses XML/HTML markup into an Element
 * @param {string} svg Valid XML/HTML markup
 * @returns {Element} The root element of the parsed document
 */
const parseSVG = (svg: string): Element => {
  const parser = new DOMParser();
  return parser.parseFromString(svg, 'image/svg+xml').documentElement;
};

/**
 * Serializes an XML node into a string
 * @param {XMLDocument} node The node to serialize
 * @returns {string} A string representation of the node
 */
const serializeXMLDocument = (node: Node): string => {
  const serializer = new XMLSerializer();
  return serializer.serializeToString(node);
};

/**
 * Gets the transform attribute of an element
 * @param {Element} element The element
 * @returns {string} The transform attribute. An empty string if it's not present.
 */
const getTransform = (element: Element): string =>
  element.getAttribute('transform') || '';

/**
 * Concatenates strings representing svg transforms
 * @param {string} t1 The first transform string
 * @param {string} t2 The second transform string
 * @returns {string} The two transforms concatenated
 */
const concatTransforms = (t1: string, t2: string): string =>
  (t1 + ' ' + t2).trim();

/**
 * "Sinks" transforms in an SVG document. Meaning:
 * - All transforms are removed from <g> nodes that are not a leaf
 * - If item X is a leaf and has g1, g2, g3, ... gn <g> ancestors, where g1 is the furthest one from X,
 *   then in the end, X.transform = 'g1.transform + ... + gn.transform + X.originalTransform'
 * TLDR: Group transforms are combined and "applied" to leaf nodes.
 *
 * Why: There is no good reason, other than SVGToPDFKit seems to not be able to handle
 * nested transforms very well (i.e gradients break with different levels of opacity)
 *
 * @param {Element} element An SVG element
 */
const sinkTransforms = (
  element: Element,
  transform = '',
  maskedObjectIds: string[] = []
): void => {
  if (!element.children?.length) {
    element.setAttribute(
      'transform',
      concatTransforms(transform, getTransform(element))
    );
    return;
  }

  Array.from(element.children)
    .filter((child) => {
      if (child.tagName !== 'clipPath') return true;
      return ![child.parentElement?.id, child.parentElement?.parentElement?.id]
        .filter((id) => !!id)
        .find((id) => maskedObjectIds.includes(id!));
    }) // clipPath Masks don't need to be sunk
    .forEach((child) =>
      sinkTransforms(
        child,
        concatTransforms(transform, getTransform(element)),
        maskedObjectIds
      )
    );
  element.removeAttribute('transform');
};

/**
 * Searches for a node inside the tree defined by an Element.
 * The search is done left to right BFS. The first found node satisfying pred is chosen.
 * @param {Element} element The element to search in
 * @param {Function} pred The predicate to satisfy
 * @returns {Element | undefined} The node if found, undefined otherwise.
 */
const search = (
  element: Element,
  pred: (e: Element) => boolean
): Element | undefined => {
  if (pred(element)) {
    return element;
  }

  const { children } = element;
  if (children?.length) {
    return Array.from(children)
      .map((child: Element) => search(child, pred))
      .find((e) => e);
  }

  return undefined;
};

/**
 * Processes illustration outlines to solve scaling issues.
 * Outline transforms are "baked" into their path data.
 *
 * ** IMPORTANT NOTE **
 * It is assumed that there are no nested transforms in the provided element.
 * i.e @method sinkTransforms should have been called on the SVG source before.
 * Otherwise the implementation of this function is less trivial.
 *
 * @param {Element} element An SVG Element
 * @param {fabric.Canvas} canvas A Fabric Canvas object
 */
const postProcessOutlines = (element: Element, canvas: fabric.Canvas): void => {
  const id = element.id;
  const illustration = canvas
    .getObjects()
    .find((obj: fabric.Object) => obj.id === id && obj.getOutlinePath);

  if (illustration) {
    /**
     * The node represents an illustration.
     * We know then that it's a group node, i.e <g/>
     */
    const outline = illustration.getOutlinePath(); // The fabric Path object representing the illustration

    if (outline) {
      const isOutlinePath = (e: Element): boolean => e.id === outline.id;

      /**
       * The SVG path matching "outline".
       * It should have one child containing the path data
       */
      const outlinePath = search(element, isOutlinePath)?.children?.[0];
      if (!outlinePath) return;

      // Create paper Path
      const paperOutlinePath = new paper.CompoundPath(
        outlinePath.getAttribute('d') || ''
      );

      // The SVG transform attribute as a fabric matrix (just an array)
      const transform = fabric.parseTransformAttribute(
        getTransform(outlinePath)
      );
      const paperMatrix = new paper.Matrix(transform);
      // Apply transform to paper path. This modifies its pathData
      paperOutlinePath.transform(paperMatrix);

      // Update path data
      outlinePath.setAttribute('d', paperOutlinePath.pathData);

      /**
       * Transform is not needed anymore.
       * ONLY works because of the assumption of no nested transforms.
       */
      outlinePath.removeAttribute('transform');
    }
  } else if (element.children?.length) {
    Array.from(element.children).forEach((child) =>
      postProcessOutlines(child, canvas)
    );
  }
};

/**
 * Processes the texture overlay so that it is correctly displayed when
 * generating an SVG for PDF conversion.
 *
 * It searches the texture overlay node inside @param element
 * and it replaces the source with the correct one (PDF uses an already inverted mask, in contrast to SVG).
 * Then it processes the image to account for opacity correctly.
 *
 * NOTE: SVG doesn't need the inverted mask since inversion is performed via SVG filters, but our PDF
 * library does not support them.
 *
 * @param { Element } element An SVG element
 * @param { fabric.Canvas } fabricCanvas fabric Canvas
 * @returns { Promise<void> | void }
 */
const adjustOverlay = (
  element: Element,
  fabricCanvas: fabric.Canvas
): Promise<void> | void => {
  const { overlayTexture } = fabricCanvas;

  if (!overlayTexture || !overlayTexture.isAlphaMask) {
    return;
  }

  const isMask = (e: Element): boolean => e.id === 'kittl-overlay-mask';
  const maskElement = search(element, isMask)!;

  const isImage = (e: Element): boolean => e.nodeName === 'image';
  const imageElement = search(maskElement, isImage)!;

  const { opacity, pdfSource } = overlayTexture;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  return new Promise<void>((resolve) => {
    const img = new Image();
    img.src = pdfSource;
    img.onload = (): void => {
      canvas.setAttribute('width', img.naturalWidth.toString());
      canvas.setAttribute('height', img.naturalHeight.toString());

      ctx.drawImage(img, 0, 0);
      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
      const { data } = imageData;

      for (let i = 0; i < data.length; i++) {
        const alpha = data[i + 3] / 255; // Normalized
        data[i + 3] = (1 - opacity + alpha * opacity) * 255;
      }

      ctx?.putImageData(imageData!, 0, 0);
      imageElement.setAttribute('xlink:href', canvas.toDataURL());
      resolve();
    };
  });
};

/**
 * Given an SVG string, it sinks transforms and returns
 * the processed markup. Look into @method sinkTransforms
 * @param {string} svg Valid SVG markup
 * @returns {string} Processed markup
 */
export const getSVGWithSunkTransforms = (
  svg: string,
  maskedObjectIds?: string[]
): string => {
  const element = parseSVG(svg);
  sinkTransforms(element, '', maskedObjectIds);
  return serializeXMLDocument(element);
};

/**
 * Given an SVG string, it processes transforms
 * for Illustration outlines. Look into @method postProcessOutlines
 *
 * ** IMPORTANT NOTE **
 * There should be no nested transforms in the markup. i.e @method sinkTransforms
 * should have been called on the SVG source already.
 * Look into @method postProcessOutlines for more details.
 *
 * @param {string} svg Valid SVG markup
 * @returns {string} Processed markup
 */
export const getSVGWithPostProcessedOutlines = (
  svg: string,
  canvas: fabric.Canvas
): string => {
  const element = parseSVG(svg);
  postProcessOutlines(element, canvas);
  return serializeXMLDocument(element);
};

/**
 * Given an SVG string, and a fabric canvas,
 * it adjusts alpha mask overlays (if they exist),
 * so that they are displayed properly.
 * @param {string} svg Valid SVG markup
 * @returns {string} Processed markup
 */
export const getSVGWithAdjustedOverlay = async (
  svg: string,
  canvas: fabric.Canvas
): Promise<string> => {
  const element = parseSVG(svg);
  await adjustOverlay(element, canvas);
  return serializeXMLDocument(element);
};
