import { themeStyle } from '../../services/theming';

export const defaultTheme = {
  width: '100%',
  height: '53px',
  borderColor: themeStyle.darkInkSuperLight,
  fontFamily: themeStyle.fontFamily,
  fontWeight: themeStyle.fontMedium,
  fontColor: themeStyle.varInkMedium,
  fontSize: themeStyle.fontSize12,
  suggestionHeight: '62px',

  // dropdown
  dropdownBackround: themeStyle.white,
  dropdownZIndex: themeStyle.zIndexM1,
  dropdownShadow: themeStyle.shadowMedium,
};
