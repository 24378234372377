import fabric from '../../Artboard/fabric';

import rasterizeImage from '../../../helpers/rasterizeImage/rasterizeImage';
import { MOCKUP_TARGET_SIZE } from '../../../global/constants';
import { MockupTemplate, MockupTemplateWithWarpGrid } from '../types';

interface Size {
  width: number;
  height: number;
}

export const getRasterizedDesign = (
  designId: string,
  size?: Size
): Promise<string> => {
  const canvasEl = document.createElement('canvas');
  const canvas = new fabric.Canvas(canvasEl);

  return new Promise((resolve) => {
    canvas.stagedLoadFromJSON(
      { designId },
      null,
      () => {
        // render clipped content when having texture
        const hasVisibleOverlayTexture =
          canvas.overlayTexture && !canvas.overlayTexture.hidden;

        if (hasVisibleOverlayTexture) {
          canvas.overlayTexture.setRenderClip(true);
        }

        const canvasWidth = canvas.artboardOptions.width;
        const canvasHeight = canvas.artboardOptions.height;
        const factor =
          MOCKUP_TARGET_SIZE / 2 / Math.max(canvasWidth, canvasHeight);

        const data = rasterizeImage.getDataUrl(canvas, {
          format: 'png',
          removeBackground: true,
          size: {
            width: size ? size.width : canvas.artboardOptions.width * factor,
            height: size ? size.height : canvas.artboardOptions.height * factor,
            unit: 'px',
          },
          plan: 'PRO', // TODO: needs refactoring; passing plan does not make sense
        });

        resolve(data);
      },
      true
    );
  });
};

export const isMockupTemplateWithWarpGrid = (
  mockupTemplate: MockupTemplate | MockupTemplateWithWarpGrid
): mockupTemplate is MockupTemplateWithWarpGrid =>
  Boolean(mockupTemplate.warpGrid);

export const designLayerId = 'design-layer';
export const sceneLayerId = 'scene-layer';
export const darkBlendLayerId = 'dark-blend-layer';
export const lightBlendLayerId = 'light-blend-layer';

export const findDesignLayer = (
  canvas: fabric.Canvas
): fabric.Image | undefined =>
  canvas
    .getObjects()
    .find((object: fabric.Object): boolean => object.id === designLayerId);
