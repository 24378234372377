import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';

export const RoundCloseButtonStyle = styled.div`
  width: 30px;
  height: 30px;
  background: ${themeStyle.varBackgroundAlt};
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: absolute;
  cursor: pointer;

  @media (max-width: 820px) {
    display: none;
  }
`;
