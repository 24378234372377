import { useCallback, useEffect, useState } from 'react';
import { Background, Foreground } from './styles';
import { defaultTheme } from './theme';
import { LoadingBarProps } from './types';

const LoadingBar: React.FC<LoadingBarProps> = (props) => {
  const theme = { ...defaultTheme, ...props.theme };

  const [width, setWidth] = useState(0);
  useEffect(() => {
    setTimeout(() => setWidth(50), 0);
  }, []);

  const nextStep = useCallback(() => {
    setWidth((width) => width + (100 - width) / 2);
  }, []);

  return (
    <Background theme={theme}>
      <Foreground
        theme={theme}
        transitionDuration={props.transitionDuration}
        disableAnimation={width !== 50}
        width={width}
        onAnimationEnd={nextStep}
        onTransitionEnd={nextStep}
      />
    </Background>
  );
};

export default LoadingBar;
