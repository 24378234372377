import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AutoSuggest from '../../AutoSuggest/AutoSuggest';
import Element from '../Element/Element';
import IconRow from '../../IconRow/IconRow';
import BasePanel from '../../LeftMenu/BasePanel/BasePanel';
import ElementsBasePanel from '../ElementBasePanel';
import SortAndSearch from '../SortAndSearch/SortAndSearch';
import { SearchBarContainer } from '../styles';
import { elementsIconRowTheme } from '../theme';
import {
  getElementTypeByGroupName,
  getEventByElementGroup,
  getRenderBaseProps,
} from '../util';
import { iconRowConfig } from './config';
import {
  DRAGGING_ELEMENT,
  START_DRAGGING_ELEMENT,
  STOP_DRAGGING_ELEMENT,
} from '../../../global/events';

const ElementsPanelHeader = (props) => {
  const {
    query,
    setSearchQuery,
    suggestions,
    setSelectedCategory,
    category,
    onChangeCategory,
  } = props;

  const [_config, setConfig] = useState(iconRowConfig);
  const _onClick = (cell) => {
    setConfig((oldConfig) => {
      return oldConfig.map((_cell) => ({
        ..._cell,
        isActive: cell.id === _cell.id,
      }));
    });
    setSelectedCategory(cell);
    setSearchQuery(null);
    onChangeCategory && onChangeCategory();
  };

  return (
    <>
      <SearchBarContainer margin="10px">
        <AutoSuggest
          value={query}
          placeholder={'Search'}
          icon={'search'}
          tags={suggestions}
          onChanged={setSearchQuery}
        />
      </SearchBarContainer>
      <IconRow
        theme={elementsIconRowTheme}
        config={_config.map((option) => ({
          ...option,
          isActive: option.id === category?.id && !query,
        }))}
        onClick={_onClick}
      />
    </>
  );
};

const WithoutSortAndSearch = (props) => {
  const { dispatch, transitioning, sort } = props;

  const render = (element) => {
    const { elementProps } = getRenderBaseProps(element, 'elements');

    const onAdd = (val) => {
      const event = getEventByElementGroup(element.groupName, val);
      dispatch(event, val);
    };

    const onStartDragging = () => {
      dispatch(START_DRAGGING_ELEMENT, {
        objectName: element.objectName,
        type: getElementTypeByGroupName(element.groupName, element),
      });
    };

    const onDragging = (value) => {
      dispatch(DRAGGING_ELEMENT, value);
    };

    const onStopDragging = () => {
      dispatch(STOP_DRAGGING_ELEMENT);
    };

    return (
      <Element
        element={elementProps}
        onDrop={onAdd}
        onStartDragging={onStartDragging}
        onDragging={onDragging}
        onStopDragging={onStopDragging}
        outlineOnHover
        theme={{
          imagePadding: '15px',
          imageBorderRadius: '0px',
        }}
      />
    );
  };

  const [key, setKey] = useState(0);
  const onChangeCategory = () => setKey((key) => key + 1);

  const groupSort = (a, b) => {
    if (a.groupId === 172) return -1; // All
    if (b.groupId === 172) return +1;
    if (a.groupId === 59) return -1; // Basic Shapes
    if (b.groupId === 59) return +1;
    if (a.groupId === 280) return -1; // Masks
    if (b.groupId === 280) return +1;
    return b.popularity - a.popularity; // other groups
  };

  const headElement = (
    <ElementsPanelHeader {...props} onChangeCategory={onChangeCategory} />
  );
  const bodyElement = (
    <ElementsBasePanel
      {...props}
      render={render}
      elementType="elements"
      headGalleries={[]}
      hide={transitioning}
      sort={sort?.id}
      groupSort={groupSort}
      key={key}
      elementGalleryDimensions={{
        minColumnWidth: 100,
        heightCollapsed: 142,
      }}
    />
  );

  return (
    <BasePanel
      label={'Add Elements'}
      onClose={props.onClose}
      headElement={headElement}
      bodyElement={bodyElement}
    />
  );
};

const WithSortAndSearch = (props) => {
  return (
    <SortAndSearch elementType="elements">
      <WithoutSortAndSearch {...props} />
    </SortAndSearch>
  );
};

ElementsPanelHeader.propTypes = {
  query: PropTypes.string,
  setSearchQuery: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  setSelectedCategory: PropTypes.func,
  category: PropTypes.object,
  onChangeCategory: PropTypes.func,
};

WithoutSortAndSearch.propTypes = {
  dispatch: PropTypes.func,
  transitioning: PropTypes.bool,
  sort: PropTypes.object,
  onClose: PropTypes.func,
};

WithSortAndSearch.propTypes = {
  dispatch: PropTypes.func,
  transitioning: PropTypes.bool,
  sort: PropTypes.object,
  onClose: PropTypes.func,
};

export default WithSortAndSearch;
