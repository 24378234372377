// default node ENV variable names
const DEVELOPMENT = 'development';
const PRODUCTION = 'production';
const TEST = 'test';

export const getNodeEnv = () => {
  return process.env.NODE_ENV;
};

export const isOnDevEnvironment = () => {
  return getNodeEnv() === DEVELOPMENT;
};

export const isOnProductionEnvironment = () => {
  return getNodeEnv() === PRODUCTION;
};

export const isOnTestEnvironment = () => {
  return getNodeEnv() === TEST;
};
