import PropTypes from 'prop-types';

export const Template = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  templatePreviewObjectName: PropTypes.string,
  state: PropTypes.shape({
    artboardOptions: PropTypes.object,
    version: PropTypes.string,
  }),
  published: PropTypes.bool,
  publishedAt: PropTypes.string,
  createdByUserId: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  popularity: PropTypes.number,
  premium: PropTypes.bool,
  review: PropTypes.bool,
  reviewState: PropTypes.string,
  trendingScore: PropTypes.number,
  description: PropTypes.string,
  remixOfId: PropTypes.number,
  challengeId: PropTypes.string,
  createdBy: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    profileImage: PropTypes.string,
    slug: PropTypes.string,
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      categoryId: PropTypes.number,
      category: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    })
  ),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
});
