import React from 'react';
import PropTypes from 'prop-types';

import EffectSelect from '../../EffectSelect/EffectSelect';
import SliderInput from '../../SliderInput/SliderInput';
import Switch from '../../Switch/Switch';
import {
  ACTIVE_TRANSFORM,
  ACTIVE_TRANSFORM_CURVE,
  EDIT_SHAPE,
} from '../../../global/events';
import { config, defaultIconMapping } from './config';
import Button from '../../Button/Button/Button';
import { secondarySmallTheme } from '../../Button/Button/theme';
import { useOnboardingStore } from '../../../stores/onboardingStore';
import { ANCHOR_REF_KEYS } from '../../../stores/onboardingStore/constants';
import { FlexRow } from '../../utilities/styles';
import { P3 } from '../../utilities/Typography/styles';
import { ConfigurationPanel } from '../../EffectSelect/styles';

/**
 * The TransformPanel
 */
const TransformPanel = (props) => {
  const transform = props.options;

  const updateTransform = (value, isChanging = false) => {
    // value must have prop `type` and can have `curve`
    const newTransform = {
      ...transform,
      ...value,
      isChanging,
    };
    props.dispatch && props.dispatch(ACTIVE_TRANSFORM, newTransform);
  };

  const setCurve = (curve, isChanging = false) => {
    props.dispatch &&
      props.dispatch(ACTIVE_TRANSFORM_CURVE, { curve, isChanging });
  };

  const getConfigPanel = (item) => {
    const id = item.id;
    const transformLabel = id === 'freeLine' ? 'Free Line' : 'Transform';
    if (id === 'freeLine' || id === 'freeForm') {
      return (
        <ConfigurationPanel gap="10px">
          <EditTransformOptions
            editLabel={`Edit ${transformLabel}`}
            dispatch={props.dispatch}
            editing={transform.inPathEditMode}
          />
        </ConfigurationPanel>
      );
    } else if (item.id === 'circle') {
      return (
        <ConfigurationPanel gap="10px">
          <FlexRow>
            <P3>Direction Inverted</P3>
            <Switch
              checked={props.options.directionInverted}
              theme={{ padding: '0' }}
              onChange={(directionInverted) =>
                updateTransform({ directionInverted }, false)
              }
            />
          </FlexRow>
          <EditTransformOptions
            editLabel={'Edit Transform'}
            dispatch={props.dispatch}
            styles={{ marginTop: '10px' }}
            editing={transform.inPathEditMode}
          />
        </ConfigurationPanel>
      );
    }

    return (
      <ConfigurationPanel gap="10px">
        <SliderInput
          label={`${item.label} Curve`}
          startValue={props.options.curve}
          min={-100}
          max={100}
          unit={'%'}
          precision={0}
          step={1}
          disabled={!item.hasCurve}
          onChanging={(curve) => setCurve(curve, true)}
          onChanged={(curve) => setCurve(curve, false)}
        />
        <EditTransformOptions
          editLabel={'Edit Transform'}
          dispatch={props.dispatch}
          styles={{ marginTop: '10px' }}
          editing={transform.inPathEditMode}
        />
      </ConfigurationPanel>
    );
  };

  const processedConfig = config.map((item) => {
    const configPanel = getConfigPanel(item);
    return {
      id: item.id,
      icon: item.icon,
      disabled: item.disabled,
      configPanel,
    };
  });

  return (
    <div
      ref={(node) =>
        useOnboardingStore
          .getState()
          .registerStepRef(node, ANCHOR_REF_KEYS.TRANSFORMATION)
      }
    >
      <EffectSelect
        label={'Transformation'}
        useActiveOverlay={true}
        config={processedConfig}
        activeEffect={props.options.type}
        onSelect={(type) => updateTransform({ type }, false)}
        defaultIconMapping={defaultIconMapping}
      />
    </div>
  );
};

TransformPanel.propTypes = {
  /**
   * current transform options
   */
  options: PropTypes.shape({
    type: PropTypes.string,
    curve: PropTypes.number,
    inPathEditMode: PropTypes.bool,
    directionInverted: PropTypes.bool,
  }),
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
};

// utility to add edit transform options buttons to a transform panel
const EditTransformOptions = (props) => {
  return (
    <FlexRow justifyContent="space-between" gap="12px">
      <Button
        width={'100%'}
        onClick={() => {
          props.dispatch(EDIT_SHAPE);
        }}
        label={props.editing ? 'Confirm' : props.editLabel || 'Edit Transform'}
        theme={secondarySmallTheme}
      />
      <Button
        width={'100%'}
        height={'30px'}
        onClick={() => props.dispatch(EDIT_SHAPE, true)}
        label="Reset Transform"
        theme={secondarySmallTheme}
      />
    </FlexRow>
  );
};

EditTransformOptions.propTypes = {
  styles: PropTypes.object,
  dispatch: PropTypes.func,
  editing: PropTypes.bool,
  editLabel: PropTypes.string,
};

export default TransformPanel;
