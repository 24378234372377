import tinycolor from 'tinycolor2';
import { arrayToBuckets } from '../../../utils/editor/misc';
import { getObjectById } from '../../../utils/editor/objects';
import { toRgb } from '../../../utils/editor/colors';

const createOrPush = (obj, key, value) => {
  if (obj[key]) {
    obj[key].push(value);
  } else {
    obj[key] = [value];
  }
};

export default function (fabric) {
  fabric.util.object.extend(fabric.Canvas.prototype, {
    /**
     * This function returns colorPalette object. It looks like that:
     * {
     *  [color] : [
     *    {
     *      id: object.id,
     *      key: color key (e.g. 'fill', 'stroke', 'shadow', etc.)
     *    }
     *  ]
     * }
     */
    getColorPalette() {
      const objects = [
        ...this.allObjects().filter((object) => !object.isAlignmentAuxiliary),
        this.artboard,
      ];

      const colorPalette = objects.reduce((acc, object) => {
        const colors = object.getColors();
        colors.forEach((color) => {
          const colorValue = !color.value ? null : toRgb(color.value);
          createOrPush(
            acc,
            // Account for no-color
            colorValue,
            {
              id: object.id,
              key: color.key,
              visible: color.visible,
              color: color.value ? tinycolor(color.value).toHsl() : null,
            }
          );
        });
        return acc;
      }, {});

      this._colorPalette = colorPalette;
      return colorPalette;
    },

    /**
     * Updates toUpdate objects to use 'newColor' instead
     * @param {object} updateObject - parameter containing 'toUpdate' and 'newColor'
     * @param {function} conversionFunction - function that takes a color and a targetColor
     * and returns a resulting color from the two. By default it returns targetColor
     */
    setColorPalette(
      { toUpdate, newColor },
      conversionFunction = (color, targetColor) => targetColor
    ) {
      if (!toUpdate || toUpdate.length === 0) return;

      const objects = [...this.allObjects(), this.artboard];
      toUpdate.forEach((t) => {
        const object = getObjectById(t.id, objects);
        if (!object) return;

        /*
          If there is a last color palette defined and the color to update is there, we work on that.
          Otherwise, use the current color of the object. This is to allow users to make successive changes
          on a set of colors without actually iterating on the results.
        */
        const color = conversionFunction(t.color, newColor);

        if (object.type === 'artboard') {
          this.modifyArtboard({ [t.key]: color, isChanging: true });
        } else {
          object.setColor(t.key, color);
        }
      });
    },

    /**
     * Returns a color conversion function based on a list of keys.
     */
    getApplyColorFunction(colorKeys) {
      return function (color, paletteColor) {
        color = tinycolor(color).toHsl();
        paletteColor = tinycolor(paletteColor).toHsl();

        for (const key of colorKeys) {
          color[key] = paletteColor[key];
        }

        return toRgb(color);
      };
    },

    /**
     * Assigns a color in presetPalette to each color in colorPalette based on luminance.
     * Returns an object in the form
     * {
     *  [presetPaletteColor1]: [colorPaletteColor[someIndex], colorPaletteColor[someIndex], ...],
     *  [presetPaletteColor2]: [colorPaletteColor[someIndex], colorPaletteColor[someIndex], ...],
     *  ...
     *  ...
     * }
     * @param {array} colorPalette - list of colors to map to presetPalette
     * @param {array} presetPalette - list of colors to choose from
     */
    createColorMap(colorPalette, presetPalette, favorLights) {
      /*
        Sorts array of color strings in ascending order based on their luminance.
        Filters out falsy elements. In our case, no-color elements which are represented by null
      */
      const sortByLuminance = (colors) =>
        colors
          .filter((c) => c)
          .map((c) => tinycolor(c).toHsl())
          .sort((a, b) => (favorLights ? b.l - a.l : a.l - b.l));

      presetPalette = sortByLuminance(presetPalette);
      colorPalette = sortByLuminance(colorPalette);

      /*
        Returns an array of arrays, where all colors at sub array at index i
        are assigned to presetColor[i]

        Darker colors in colorPalette are thus assigned to darker colors in presetPalette
      */
      const colorPaletteBuckets = arrayToBuckets(
        colorPalette,
        presetPalette.length
      );

      const map = {};
      presetPalette.forEach((presetColor, index) => {
        const rgbaPresetColor = toRgb(presetColor);
        if (colorPaletteBuckets[index]) {
          map[rgbaPresetColor] = colorPaletteBuckets[index].map((c) =>
            toRgb(c)
          );
        }
      });

      return map;
    },

    /* Applies a color palette preset to the current design */
    applyColorPalettePreset(preset, colorKeys) {
      if (!preset.length) return;

      if (!this.lastColorPalette) {
        this.lastColorPalette = { ...this.getColorPalette() };
      }

      const colorPalette = this.lastColorPalette;

      /*
        Filter out all colors that are not visible from colorPalette,
        or colors that are null (no-color)
      */
      const visibleColorPalette = {};

      for (const key in colorPalette) {
        if (key === 'null') continue;

        const val = colorPalette[key];
        for (const e of val) {
          if (e.visible) {
            visibleColorPalette[key] = val;
            break;
          }
        }
      }

      if (!Object.keys(visibleColorPalette).length) return;

      const getAverageLuminance = (colors) => {
        return (
          colors
            .map((color) => tinycolor(color).getLuminance())
            .reduce((acc, val) => acc + val, 0) / preset.length
        );
      };

      const averagePresetLuminance = getAverageLuminance(preset);
      const averageVisiblePaletteLuminance = getAverageLuminance(
        Object.keys(visibleColorPalette)
      );
      const favorLights =
        averagePresetLuminance < averageVisiblePaletteLuminance;

      const colorMap = this.createColorMap(
        Object.keys(visibleColorPalette),
        preset,
        favorLights
      );

      Object.keys(colorMap).forEach((presetColor) => {
        const targetColors = colorMap[presetColor];

        const toUpdate = [].concat(
          ...targetColors.map((targetColor) => visibleColorPalette[targetColor])
        );

        this.setColorPalette(
          {
            toUpdate: toUpdate.filter((e) => e.color),
            newColor: presetColor,
            fromPreset: true,
          },
          this.getApplyColorFunction(colorKeys).bind(this)
        );
      });
    },
  });
}
