import themeStyle from '../../../global/themeStyle';

export const defaultTheme = {
  // label
  colorLabel: themeStyle.grey550,
  colorLabelActive: themeStyle.primaryBlack,
  fontFamily: themeStyle.fontFamily,
  fontWeight: themeStyle.fontMedium,
  fontSize: themeStyle.fontSize11,
  lineHeight: '12px',
  transition: '0.3s',
};
