import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import useResizeObserver from '@react-hook/resize-observer';

import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { Wrapper, FlexColumn, ElementWrapper, OuterWrapper } from './styles';
import { Column, ColumnItem, MasonryProps } from './types';
import { calculateColumns, getItemHeight } from './utils';

const Masonry: React.FC<MasonryProps> = ({
  minColumnWidth,
  gap,
  fetch,
  render,
  computeKey,
  transitioning,
  scrollToTop,
  specialComponent,
  specialComponentHeight,
  equalDimensions,
  ...props
}) => {
  const outerWrapper = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const [wrapperSize, setWrapperSize] = useState<number | null>(null);
  const [columns, setColumns] = useState<Column[]>([]);
  const [visible, setVisible] = useState(true);

  const [specialElemDimensions, setSpecialElemDimensions] = useState({
    width: 0,
    height: 0,
  });

  const buildColumns = useCallback((): Column[] => {
    if (!wrapperSize) return [];
    const { columnWidth, columnNumber } = calculateColumns(
      wrapperSize,
      minColumnWidth,
      gap
    );
    if (!columnNumber) return [];

    setSpecialElemDimensions({
      width: columnNumber > 1 ? columnWidth * 2 + gap : columnWidth,
      height: specialComponentHeight ?? 0,
    });

    const columns = Array(columnNumber)
      .fill(null)
      .map(() => ({
        elements: [] as ColumnItem[],
        height: 0,
        isLoader: false,
      }));

    if (specialComponentHeight) {
      if (columns?.length >= 1) columns[0].height += specialComponentHeight;
      if (columns?.length >= 2) columns[1].height += specialComponentHeight;
    }

    const items = [...props.items].reverse();

    const findShortestColumn = (): number => {
      let shortest = 0;
      columns.forEach((col, idx) => {
        if (col.height < columns[shortest].height) shortest = idx;
      });
      return shortest;
    };

    while (items.length) {
      const item = items[items.length - 1];
      const shortestColumnIndex = findShortestColumn();
      const itemHeight = getItemHeight(item, columnWidth, equalDimensions);
      columns[shortestColumnIndex].elements.push({
        ...item,
        elementHeight: itemHeight,
        elementWidth: columnWidth,
        artboardOptions: item.state?.artboardOptions,
        isLoader: false,
      });
      columns[shortestColumnIndex].height += itemHeight;
      items.pop();
    }

    // Find what element to use as loader
    const shortestColumn = findShortestColumn();
    const shortestColumnElements = columns[shortestColumn].elements;

    if (shortestColumnElements.length) {
      shortestColumnElements[shortestColumnElements.length - 1].isLoader = true;
    } else {
      columns[shortestColumn].isLoader = true;
    }

    return columns;
  }, [
    wrapperSize,
    minColumnWidth,
    props.items,
    gap,
    specialComponentHeight,
    equalDimensions,
  ]);

  const getKey = useCallback(
    (item: ColumnItem, index: number) => {
      if (computeKey) return computeKey(item);
      return item.id || index;
    },
    [computeKey]
  );

  const maybeFetch = useCallback(() => {
    if (fetch) fetch();
  }, [fetch]);

  const { setNode } = useIntersectionObserver(maybeFetch, {
    threshold: 0.1,
    rootMargin: '0px 0px 100% 0px',
    root: null,
  });

  useLayoutEffect(() => {
    if (!wrapper.current) return;
    setWrapperSize(wrapper.current.getBoundingClientRect().width);
  }, [wrapper]);

  useResizeObserver(wrapper, (entry) => {
    // Calculating window innerWidth and body offseWitdh we get the scrollbar width
    // If there is no scrollbar scrollbarWidth will be 0
    const scrollbarWidth = window?.innerWidth - document?.body.offsetWidth;

    // Throttle taking into account the scrollbar width to avoid weird scrollbar flicker.
    // See https://app.clickup.com/t/2443ybv
    if (
      Math.abs(entry.contentRect.width - (wrapperSize ?? 0)) > scrollbarWidth
    ) {
      setWrapperSize(entry.contentRect.width);
    }
  });

  useEffect(() => {
    if (!props.items.length && wrapperSize) maybeFetch();
  }, [props.items, maybeFetch, wrapperSize]);

  useLayoutEffect(() => {
    if (!wrapperSize) return;
    setColumns(buildColumns());
  }, [props.items.length, wrapperSize, buildColumns]);

  useEffect(() => {
    setVisible(!transitioning);
  }, [transitioning]);

  useEffect(() => {
    outerWrapper.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [scrollToTop]);

  const renderCol = (column: Column, index: number): JSX.Element => {
    return (
      <FlexColumn
        key={`column-${index}`}
        minColumnWidth={minColumnWidth}
        ref={column.isLoader ? setNode : null}
        gap={gap}
      >
        {column.elements.map((element, index) => {
          return (
            <ElementWrapper
              key={getKey(element, index)}
              ref={element.isLoader ? setNode : null}
            >
              {render(element)}
            </ElementWrapper>
          );
        })}
      </FlexColumn>
    );
  };

  return (
    <OuterWrapper ref={outerWrapper}>
      <Wrapper gap={gap} ref={wrapper}>
        {visible && (
          <>
            <FlexColumn>
              {!!specialComponent && (
                <div
                  style={{
                    width: `${specialElemDimensions.width}px`,
                    marginBottom: `${gap}px`,
                  }}
                >
                  {specialComponent}
                </div>
              )}
              <Wrapper
                style={{
                  width: `${specialElemDimensions.width}px`,
                }}
              >
                {columns.map((column, index) => {
                  if (index >= 2) return null;
                  return renderCol(column, index);
                })}
              </Wrapper>
            </FlexColumn>
            {columns.map((column, index) => {
              if (index < 2) return null;
              return renderCol(column, index);
            })}
          </>
        )}
      </Wrapper>
    </OuterWrapper>
  );
};

Masonry.defaultProps = {
  scrollToTop: 0,
};

export default Masonry;
