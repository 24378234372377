import hotkeys from 'hotkeys-js';

import {
  ACTIVE_DELETE,
  SELECT_ALL,
  GRID_SHOW,
  UNDO,
  REDO,
  ACTIVE_COPY,
  ACTIVE_PASTE,
  ACTIVE_CUT,
  ACTIVE_GROUP,
  ACTIVE_MOVE_BY,
  ZOOM_STEP,
  ZOOM_CENTER,
  ADD_TEXT,
  ENABLE_SNAPPING,
  ENABLE_ZOOM_ON_WHEEL,
  ENABLE_DRAGGING,
  TRIM_VIEW,
  DESIGN_SAVE,
} from '../../global/events';
import { useSettingsStore } from '../../stores/settingsStore';
import { menuStore } from '../../stores/menuStore';
import { lockToAxis } from '../../utils/editor/misc';
import { isPriorityKeyCode } from '../../utils/keys';

const MOVE_BY_THROTTLE = 17;

const BACKSPACE = 8;
const DELETE = 46;

const DELETE_KEYS = [BACKSPACE, DELETE];

/**
 * based on the current canvas, decide whether Hotkeys should be disabled or not
 */
const shouldDisableHotkeys = (canvas) => {
  const hasMovingObject = canvas.hasMovingObject; // user is moving objects
  const isDragging = canvas.draggingEnabled; // user is dragging the canvas viewport
  const hotkeysEnabled = menuStore.getState().artboardHotkeysEnabled;

  return !hotkeysEnabled || hasMovingObject || isDragging;
};

const handleShift = (event, canvas, info) => {
  let { lock, movingInfo } = info.current.Shift;

  lock = lock.current;
  movingInfo = movingInfo.current;

  if (event.type === 'keydown') {
    const setLock = () => {
      lock.doLock = true;
    };
    setLock();

    /*
      If movingInfo has stuff then user is moving some object/objects
      If shift is pressed then, we want to lock to axis so there's visual feedback
    */
    if (movingInfo) {
      lockToAxis(movingInfo.event.target, lock, movingInfo.event.pointer);
      canvas.requestRenderAll();
    }
  } else {
    const resetLock = () => {
      lock.doLock = false;
    };
    resetLock();

    /*
      If movingInfo has stuff then user is moving some object/objects
      If shift is released then, we want to set the position back to movingInfo.positionWithoutLocking
    */
    if (movingInfo) {
      const target = movingInfo.event.target;
      const positionWithoutLocking = movingInfo.positionWithoutLocking;
      target.left = positionWithoutLocking.left;
      target.top = positionWithoutLocking.top;
      canvas.requestRenderAll();
    }
  }
};

let enableMoveBy = true;

export const addHotkeysHandlers = (canvas, canvasEl, dispatch, info) => {
  // See: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
  canvasEl.parentElement.tabIndex = -1;
  const keydownHandler = (event) => {
    if (DELETE_KEYS.includes(event.keyCode)) {
      if (shouldDisableHotkeys(canvas) || canvas.activeTextEdit) return;
      dispatch(ACTIVE_DELETE);
      event.preventDefault();
    }
  };
  canvasEl.parentElement.addEventListener('keydown', keydownHandler);

  /**
   * register a new hotkey with a callback
   * @param  {...any} args
   */
  const setHotkey = (...args) => {
    // extend given hotkey callback to check whether it should be blocked
    const callback = args.pop(); // last arg is the callback
    const extendedCallback = (event, ...rest) => {
      const disableHotkeys = shouldDisableHotkeys(canvas);
      const keyCode = event.code;
      if (!isPriorityKeyCode(keyCode) && disableHotkeys) return;

      callback(event, ...rest);
    };
    return hotkeys(...args, extendedCallback);
  };

  setHotkey('ctrl+s,command+s', (event) => {
    event.preventDefault();

    dispatch(DESIGN_SAVE, { type: 'manual' });
  });

  setHotkey('ctrl+a,command+a', (event) => {
    dispatch(SELECT_ALL);
    event.preventDefault();
  });

  // ...+shift+' is needed for the german keyboard
  setHotkey("ctrl+',command+',ctrl+shift+',command+shift+'", (event) => {
    dispatch(GRID_SHOW, !useSettingsStore.getState().settings.showGrid);
    event.preventDefault();
  });

  setHotkey('ctrl+z,command+z', (event) => {
    dispatch(UNDO);
    event.preventDefault();
  });

  setHotkey('ctrl+y,command+y,ctrl+shift+z,command+shift+z', (event) => {
    dispatch(REDO);
    event.preventDefault();
  });

  setHotkey('ctrl+c,command+c', (event) => {
    dispatch(ACTIVE_COPY);
    event.preventDefault();
  });

  setHotkey('ctrl+v,command+v', (event) => {
    dispatch(ACTIVE_PASTE);
    event.preventDefault();
  });

  setHotkey('ctrl+x,command+x', (event) => {
    dispatch(ACTIVE_CUT);
    event.preventDefault();
  });

  setHotkey('ctrl+g,command+g', (event) => {
    dispatch(ACTIVE_GROUP);
    event.preventDefault();
  });

  setHotkey('-,shift+-', (event) => {
    dispatch(ZOOM_STEP, -1);
    event.preventDefault();
  });

  // `shift+=` is the plus key ¯\_(ツ)_/¯
  setHotkey('shift+=,=', (event) => {
    dispatch(ZOOM_STEP, +1);
    event.preventDefault();
  });

  setHotkey('shift+0', (event) => {
    dispatch(ZOOM_CENTER);
    event.preventDefault();
  });

  setHotkey('t', (event) => {
    dispatch(ADD_TEXT);
    event.preventDefault();
  });

  // based on InDesign hotkey
  setHotkey('w', (event) => {
    dispatch(TRIM_VIEW, !useSettingsStore.getState().settings.trimView);
    event.preventDefault();
  });

  setHotkey('*', { keyup: true }, (event) => {
    const isKeyDown = event.type === 'keydown';

    if (event.code === 'ShiftLeft') {
      handleShift(event, canvas, info);
    }

    // Include `selectable` as well as we want to allow moving objects
    // after they're selected in layers (see CU-bgzuwc)
    const artboardTargeted = ['canvas-container', 'selectable'].some((c) =>
      event.target.classList.contains(c)
    );
    const bodyTargeted = event.target.tagName === 'BODY';

    if (hotkeys.cmd || hotkeys.ctrl) {
      const keyPressed = hotkeys.isPressed('cmd') || hotkeys.isPressed('ctrl');
      dispatch(ENABLE_SNAPPING, !keyPressed);
      dispatch(ENABLE_ZOOM_ON_WHEEL, keyPressed);
    }

    // Replicate zoom functionality with option and alt a-la Adobe XD
    if (hotkeys.option || hotkeys.alt) {
      const keyPressed =
        hotkeys.isPressed('option') || hotkeys.isPressed('alt');
      dispatch(ENABLE_ZOOM_ON_WHEEL, keyPressed);
    }

    // Handle arrow keys
    // XXX: it's not in the separate handler because of diagonals and shift support
    const left = hotkeys.isPressed('left');
    const right = hotkeys.isPressed('right');
    const up = hotkeys.isPressed('up');
    const down = hotkeys.isPressed('down');

    if (
      (left || right || up || down) &&
      (artboardTargeted || bodyTargeted) &&
      isKeyDown
    ) {
      let x = left ? -1 : right ? 1 : 0;
      let y = up ? -1 : down ? 1 : 0;
      if (hotkeys.shift) {
        x *= 10;
        y *= 10;
      }

      // throttled triggering of ACTIVE_MOVE_BY
      if (enableMoveBy) {
        dispatch(ACTIVE_MOVE_BY, { x, y });

        enableMoveBy = false;
        setTimeout(() => (enableMoveBy = true), MOVE_BY_THROTTLE);
      }
      event.preventDefault();
    }
  });

  setHotkey('space', { keyup: true }, (event, handler) => {
    if (event.type === 'keydown') {
      dispatch(ENABLE_DRAGGING, true);
    } else if (event.type === 'keyup') {
      dispatch(ENABLE_DRAGGING, false);
    }
  });

  return () => {
    canvasEl.parentElement.removeEventListener('keydown', keydownHandler);
    hotkeys.unbind('ctrl+s,command+s');
    hotkeys.unbind('ctrl+a,command+a');
    hotkeys.unbind("ctrl+',command+',ctrl+shift+',command+shift+'");
    hotkeys.unbind('ctrl+z,command+z');
    hotkeys.unbind('ctrl+y,command+y,ctrl+shift+z,command+shift+z');
    hotkeys.unbind('ctrl+c,command+c');
    hotkeys.unbind('ctrl+x,command+x');
    hotkeys.unbind('ctrl+v,command+v');
    hotkeys.unbind('ctrl+g,command+g');
    hotkeys.unbind('-,shift+-');
    hotkeys.unbind('shift+=,=');
    hotkeys.unbind('shift+0');
    hotkeys.unbind('t');
    hotkeys.unbind('w');
    hotkeys.unbind('*');
    hotkeys.unbind('space');
  };
};
