import styled from 'styled-components';
import { themeStyle } from '../../services/theming';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 24px;
`;

export const Preview = styled.div`
  flex: 1;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 336px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 1;
`;

export const Image = styled.img.attrs(({ src }) => {
  return {
    src,
    crossOrigin: 'anonymous',
  };
})`
  display: block;
  margin: 0 auto;
  user-drag: none;
  border-radius: ${themeStyle.radiusMedium};
  border: 1px solid ${themeStyle.varInkLight};

  ${({ isWide }) =>
    isWide
      ? `
    width: 100%;
    height: auto;
  `
      : `
    height: 100%;
    width: auto;
  `}
`;

export const LoadingWrapper = styled.div`
  background: ${themeStyle.varBackgroundAlt};
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
