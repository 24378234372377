import {
  getCommonAncestor,
  getParent,
  getParentId,
  isAncestor,
} from '../../../utils/groupStructure';
import { isInIds } from '../../../utils';

export default function (fabric) {
  /**
   * Given a list of ids and a common ancestor of their corresponding nodes in the design tree,
   * figures out a valid list of ids to set _selectedElements.
   * For reference, check: https://app.clickup.com/t/1xwc4um
   * @param {array} ids Ids to select
   * @param {string | null} commonAncestorId The id of the common ancestor of the corresponding nodes of the list of ids
   */
  fabric.Canvas.prototype._stabilizeSelection = function (
    ids,
    commonAncestorId = null
  ) {
    const traversed = {};
    const stabilized = [];

    //This loop does the following:
    //  Finds all nodes that are direct children of the common ancestor, and that
    //  are also ancestors of some node with id in ids.
    //  It keeps track of already traversed nodes to not do unnecessary operations.
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      while (id) {
        traversed[id] = true;

        const parentId = getParentId(this.groupStructure, id);

        if (traversed[parentId]) break;
        if (parentId === commonAncestorId) {
          stabilized.push(id);
          break;
        }

        id = parentId;
      }
    }

    return stabilized;
  };

  /**
   * Handles sub-target selection. For us, "double click" selection on a group / ActiveSelection
   * @param {string} subTargetId Id of the sub-target
   */
  fabric.Canvas.prototype._handleSubTargetSelect = function (subTargetId) {
    let current = subTargetId;
    const isInCurrentSelection = isInIds(this._selectedElements);

    // Go "up" until a top-level selected object is found
    while (current && !isInCurrentSelection(current)) {
      current = getParentId(this.groupStructure, current);
    }

    if (this._selectedElements.length > 1) {
      current = getParentId(this.groupStructure, current);
    }

    this._selectedElements = this._stabilizeSelection([subTargetId], current);
  };

  /**
   * Handles multi selection. For us, "shift + click" selection
   * @param {string} id Id of the object to select
   */
  fabric.Canvas.prototype._handleMultiSelect = function (id) {
    if (!this._selectedElements.length) {
      return this._handleNormalSelect([id]);
    }

    // Getting the parent of the first element is equal to getting the parent of the whole selection
    // Since only objects with the same parent can be top-level selected elements at the same time
    const selectionParent = getParent(
      this.groupStructure,
      this._selectedElements[0]
    );
    const selectionParentId = selectionParent?.id || null;

    if (!isAncestor(this.groupStructure, id, selectionParentId)) {
      //If the object to select is not a descendant of the selection's parent,
      //then select normally.
      //i.e we are trying to multi select objects that are in different sub-trees
      return this._handleNormalSelect([id]);
    }

    const stabilizedSelection = this._stabilizeSelection(
      [id],
      selectionParentId
    );

    const onlyResultingNodeId = stabilizedSelection[0];

    if (this._selectedElements.includes(onlyResultingNodeId)) {
      // If the resulting node was already selected, deselect
      this._selectedElements = this._selectedElements.filter(
        (id) => id !== onlyResultingNodeId
      );
      return;
    }

    // Else add it to selection
    this._selectedElements = [...this._selectedElements, onlyResultingNodeId];
  };

  /**
   * Handles normal selection. For us, "click" selection.
   * @param {array} ids Ids to select
   */
  fabric.Canvas.prototype._handleNormalSelect = function (ids) {
    if (!this._selectedElements.length) {
      // If there is no current selection, only consider incoming ids when stabilizing
      this._selectedElements = this._stabilizeSelection(ids);
    } else {
      // Else, consider the current selection as well
      const commonAncestorId = getCommonAncestor(
        this.groupStructure,
        ids.concat(this._selectedElements)
      );
      this._selectedElements = this._stabilizeSelection(ids, commonAncestorId);
    }
  };

  /**
   * Performs selection based on a list of ids and an options object
   * @param {array} ids List of ids
   * @param {object} options Options object
   * @param {boolean} options.isSubTargetSelect - True if we want to select "inside"
   *    the current top level elements (e.g double click selection on groups). Only the first Id counts.
   * @param {boolean} options.isMultiSelect - True if performing selection of multiple objects (shift + click)
   * @param {boolean} options.isOverwrite - True if we want to select the list of ids no matter the current state.
   *  Useful, for example, for layers panel. If an object is selected, and the user selects some other object
   *  in the panel that is at a deeper level, normal selection will resolve to some ancestor of the element
   *  the user clicked, which is not what we want in that case.
   *  Tip: if you would use selectStructureObjects (deprecated) before, you need isOverwrite = true
   */
  fabric.Canvas.prototype.solveSelection = function (ids, options) {
    if (options.isSubTargetSelect) {
      this._handleSubTargetSelect(ids[0]);
    } else if (options.isMultiSelect) {
      this._handleMultiSelect(ids[0]);
    } else if (options.isOverwrite) {
      this._selectedElements = ids;
    } else {
      this._handleNormalSelect(ids);
    }
  };
}
