import PropTypes from 'prop-types';

export const User = PropTypes.shape({
  admin: PropTypes.bool,
  id: PropTypes.string,
  confirmed: PropTypes.bool,
  creator: PropTypes.bool,
  email: PropTypes.string,
  marketingOptIn: PropTypes.bool,
  name: PropTypes.string,
  onboardingState: PropTypes.string,
  profileImage: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.string,
  contentManager: PropTypes.bool,
  plan: PropTypes.string,
  invite: PropTypes.shape({
    createdAt: PropTypes.string,
    acceptedAt: PropTypes.string,
    sender: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
});
