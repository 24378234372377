import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { P3 } from '../utilities/Typography/styles';

export const CreatedByLabel = styled(P3)`
  margin-left: 6px;
  color: ${themeStyle.varInkMedium};
  b {
    color: ${themeStyle.varInkMain};
    font-weight: ${themeStyle.fontSemiBold};
  }
`;

export const Tags = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  max-height: 45px;
  overflow: hidden;
`;
