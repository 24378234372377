import paper from '@kittl/paper';
import applyDecoration from '../decoration/applyDecoration';
import distortTransform from '../../../../../../helpers/textTransform/distortTransform';
import {
  fixPathOrientation,
  getGlyphAlignment,
} from '../../../../../../font/util';
import {
  flipPoints,
  getAbsolutePoint,
  getRelativePoint,
  isSmallerPoint,
} from '../../../../../../utils/editor/points';
import {
  bezierPathFromPoints,
  getCircleParameterFromPoints,
} from '../../../../../../utils/editor/shapes';

/**
 * Applies a transformation to all points on the given paths.
 * @param {String[]} paths a list of paths
 * @param {function} transform function to transform a single point.
 *          Gets a point as input and must return a point ({x,y}) => ({x,y})
 * @returns a transformed list of paths
 */
const transformPaths = (paths, transform, smooth = false) => {
  // iterate over all paths (letters) and their segments
  const completePath = new paper.CompoundPath({
    pathData: paths.join(''),
    insert: false,
  });
  const newPaths = completePath.children.map((path) => {
    const newSegments = path.segments.map((segment) => {
      // transform each segment with the specified transform
      return new paper.Segment(
        new paper.Point(transform(segment.point)),
        getTransformedHandle(segment.handleIn, segment.point, transform),
        getTransformedHandle(segment.handleOut, segment.point, transform)
      );
    });

    const _path = new paper.Path({ segments: newSegments, closed: true });
    if (smooth) {
      _path.smooth();
    }

    return _path;
  });
  return newPaths;
};

// function to transform a relative handle
const getTransformedHandle = (handle, origin, transform) =>
  getRelativePoint(
    transform(getAbsolutePoint(handle, origin)),
    transform(origin)
  );

/**
 * align and transform glyphs on a curve
 * @param {String[]} paths
 * @param {paper.Group} group
 * @param {paper.Path} curve
 * @param {*} options
 * @returns {String[]}
 */
const alignTextOnCurve = (
  paths,
  group,
  curve,
  { textAlignment, xHeight, directionInverted, bounds, ...options }
) => {
  let textOutOfBounds = false;

  // get the width
  const totalWidth = bounds.maxX - bounds.minX;

  // get properties to align glyphs by
  const { leftOffset, spaceBetween } = getGlyphAlignment(
    totalWidth,
    curve.length,
    paths.length,
    textAlignment
  );

  // get the full width of all glyphs combined
  const completeGroup = new paper.CompoundPath(paths.join(''));
  const baseBounds = getControlBox(completeGroup.bounds);
  baseBounds.maxY *= -1;
  baseBounds.minY *= -1;

  const data = paths.map((currentPath, currentPathIndex) => {
    let glyph = new paper.CompoundPath({
      pathData: currentPath,
      insert: false,
    });
    const glyphOffset = glyph.bounds.x + glyph.bounds.width / 2;

    if (options.decoration) {
      glyph = applyDecoration(options.decoration, glyph, baseBounds, options);
    }

    const alignedGlyphOffset =
      glyphOffset + leftOffset + spaceBetween * currentPathIndex;

    // position on the curve to place the updated glyph
    // when directionInverted, glyphs should be placed in reversed order
    const pointOnCurve = directionInverted
      ? curve.length - alignedGlyphOffset
      : alignedGlyphOffset;

    const newGlyphPoint = curve.getPointAt(pointOnCurve);
    // newGlyphPoint can be null when pointOnCurve is > 0
    // pointOnCurve can be < 0 in right alignment with a too-small curve
    // in these cases, do not show the glyph
    if (!newGlyphPoint || pointOnCurve < 0) {
      textOutOfBounds = true;
      return '';
    }

    const translateCoords = new paper.Point(
      newGlyphPoint.x - glyphOffset,
      newGlyphPoint.y + xHeight / 2
    );
    const tangent = curve.getTangentAt(pointOnCurve);
    glyph.rotate(
      tangent.angle + (directionInverted ? 180 : 0),
      new paper.Point(glyphOffset, -xHeight / 2)
    );
    glyph.translate(translateCoords);

    if (!options.decoration) {
      fixPathOrientation(glyph);
    }

    group.addChild(glyph);
    return glyph.pathData;
  });
  return { data, outOfBounds: textOutOfBounds };
};

const warpTextOnLine = (paths, group, curve, { textAlignment, bounds }) => {
  let textOutOfBounds = false;

  // get the width
  const totalWidth = bounds.maxX - bounds.minX;
  // get properties to align glyphs by
  const { leftOffset, spaceBetween } = getGlyphAlignment(
    totalWidth,
    curve.length,
    paths.length,
    textAlignment
  );

  const xStart = curve.bounds.x;
  const baseY = curve.getPointAt(0).y;

  const data = paths.map((currentPath, currentPathIndex) => {
    const glyph = new paper.CompoundPath({
      pathData: currentPath,
      insert: false,
    });
    // horizontal offset to the center of the glyph
    const glyphOffset = glyph.bounds.x + glyph.bounds.width / 2;
    // position on the curve to place the aligned glyph
    const pointOnCurve =
      -bounds.minX + glyphOffset + leftOffset + spaceBetween * currentPathIndex;

    const newGlyphPoint = curve.getPointAt(pointOnCurve);
    // pointOnCurve can be < -bounds.minX in right alignment with a too-small curve
    // in that case, do not show the glyph
    if (!newGlyphPoint || pointOnCurve < -bounds.minX) {
      textOutOfBounds = true;
      return '';
    }

    // move glyph to pointOnCurve
    const translateCoords = new paper.Point(
      pointOnCurve + xStart - glyphOffset,
      baseY // Enable line at xHeight `+ xHeight / 2`
    );
    glyph.translate(translateCoords);

    // warp glyph
    const warpPoint = (point) => {
      const relativePosition = point.x - xStart;
      // not perfect handling of overflow. First/last letter might get too narrow
      const curvePoint = curve.getPointAt(
        Math.min(Math.max(0, relativePosition), curve.length)
      );

      if (textAlignment === 'right' && relativePosition < 0)
        textOutOfBounds = true;
      if (
        textAlignment !== 'right' &&
        textAlignment !== 'justify' &&
        relativePosition > curve.length
      ) {
        textOutOfBounds = true;
      }

      return {
        x: curvePoint.x,
        y: curvePoint.y + point.y - baseY,
      };
    };

    // iterate over all paths (letters) and their segments
    const transformedPaths = transformPaths([glyph.pathData], warpPoint);

    const transformedPath = new paper.CompoundPath({
      children: transformedPaths,
      insert: false,
    });

    fixPathOrientation(transformedPath);

    group.addChild(transformedPath);
    return transformedPath.pathData;
  });
  return { data, outOfBounds: textOutOfBounds };
};

// get the controlBox information from a paper bounds object
const getControlBox = (bounds) => ({
  minX: bounds.x,
  maxX: bounds.right,
  minY: -bounds.y,
  maxY: -bounds.bottom,
});

// get control parameters to position a transformed path on the artboard
const getControlParameter = (curve, group, options) => {
  const firstPoint = curve.getPointAt(0) || curve.segments[0].point; // handle edge case when all points share position

  // if curve length is so short that not a single glyph can fit -
  // add a fake component to preserve position
  if (!group.children.length) {
    // NOTE: not sure if it's the perfect solution, but adding anything else to the group causes a weird paper.js bug
    group.addChild(new paper.Path([new paper.Segment(firstPoint)]));
  }

  // Find the "control point", this point is later used to translate PathText by its coordinates, so it's correctly displayed
  // by default this is the top/left corner, when a flip is applied, this changes for example to bottom/right with flipX and flipY
  const controlPoint = new paper.Point(
    group.bounds.x + (options.flipX ? group.bounds.width : 0),
    group.bounds.y + (options.flipY ? group.bounds.height : 0)
  );
  // the control vector is the offset of the group and the first point on the curve
  // its used to position the group later in relation to the first point
  const controlVector = firstPoint.subtract(controlPoint);

  // Compute the new controlBox
  const bounds = getControlBox(group.bounds);

  return { controlVector, bounds };
};

const applyTransform = (originalPaths, transformOptions, options) => {
  const isArray = Array.isArray(originalPaths);
  const path = isArray ? originalPaths.join('') : originalPaths;
  const paths = isArray ? [...originalPaths] : [originalPaths]; // make a copy of originalPaths, not to accidentally mutate them

  if (transformOptions.type === 'circle') {
    if (transformOptions.points) {
      let points = transformOptions.points;
      if (options.flipX || options.flipY) {
        points = flipPoints(points, options.flipX, options.flipY);
      }

      const { radius, center } = getCircleParameterFromPoints(points);

      const curve = new paper.Path.Circle(
        new paper.Point(center.x, center.y),
        radius
      );

      // the path of a paper.Circle starts at the left edge of the circle. However, the transform
      // should start at the top or bottom, so the curve needs to be rotated
      const angle = transformOptions.directionInverted ? 90 : -90;
      curve.rotate(angle);

      options.directionInverted = transformOptions.directionInverted;

      // Create a group to get updated bounds later
      const group = new paper.Group({ insert: false });

      // align each path along the curve of the circle
      // and apply the transform to each glyph
      const { data: transformedPaths, outOfBounds } = alignTextOnCurve(
        paths,
        group,
        curve,
        options
      );

      const isSmaller = isSmallerPoint(points[0], points[3], 0, false); //Points are never rotated

      // remove rotation to get correct control vector
      curve.rotate((isSmaller ? -1 : 1) * angle);

      const { controlVector, bounds } = getControlParameter(
        curve,
        group,
        options
      );

      const path = transformedPaths.join('');
      paper.project.activeLayer.removeChildren(); // clear paper.js canvas

      return { path, bounds, controlVector, outOfBounds };
    }
  }

  if (transformOptions.type === 'freeLine') {
    if (transformOptions.points) {
      let points = transformOptions.points;
      if (options.flipX || options.flipY) {
        points = flipPoints(points, options.flipX, options.flipY);
      }

      if (options.underline) {
        // remove underline path (CU-c50pdn)
        paths.pop(); // underline is always the last path
      }

      const curve = bezierPathFromPoints(points);

      // Create a group to get updated bounds later
      const group = new paper.Group({ insert: false });

      // align each path along the curve of the FreeLine
      // and apply transform to each glyph
      const { data: transformedPaths, outOfBounds } = alignTextOnCurve(
        paths,
        group,
        curve,
        options
      );

      const { controlVector, bounds } = getControlParameter(
        curve,
        group,
        options
      );

      const path = transformedPaths.join('');
      // clear paper.js canvas
      paper.project.activeLayer.removeChildren();

      return { path, bounds, controlVector, outOfBounds };
    }
  }

  if (transformOptions.type === 'freeForm') {
    if (transformOptions.points) {
      let points = transformOptions.points;
      if (options.flipX || options.flipY) {
        points = flipPoints(points, options.flipX, options.flipY);
      }
      return distortTransform(paths, { ...transformOptions, points }, options);
    }
  }

  // warp
  if (transformOptions.points) {
    let points = transformOptions.points;
    if (options.flipX || options.flipY) {
      points = flipPoints(points, options.flipX, options.flipY);
    }

    if (options.underline) {
      // remove underline path (CU-c50pdn)
      paths.pop(); // underline is always the last path
    }

    const curve = bezierPathFromPoints(points);
    const curveLength = curve.length;

    // Create a group to get updated bounds later
    const group = new paper.Group({ insert: false });

    // align each path along the curve of the FreeLine
    const { data: transformedPaths, outOfBounds } = warpTextOnLine(
      paths,
      group,
      curve,
      options
    );

    const { controlVector, bounds } = getControlParameter(
      curve,
      group,
      options
    );

    const path = transformedPaths.join('');
    paper.project.activeLayer.removeChildren(); // clear paper.js canvas

    return { path, bounds, controlVector, outOfBounds, curveLength };
  }

  return { path, bounds: options.bounds };
};

export default applyTransform;
