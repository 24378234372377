import React from 'react';
import { themeStyle } from '../../../services/theming';

import Icon from '../../Icon/Icon';
import IconEventWrapper from '../../Icon/IconEventWrapper';
import { RoundCloseButtonStyle } from './styles';
import { RoundCloseButtonProps } from './types';

const iconTheme = {
  color: themeStyle.varInkMedium,
  activeColor: themeStyle.varInkMain,
};

const RoundCloseButton: React.FC<RoundCloseButtonProps> = ({ onClick }) => {
  return (
    <RoundCloseButtonStyle onClick={onClick}>
      <IconEventWrapper fitParent theme={iconTheme}>
        <Icon name="close" theme={iconTheme} height="10px" />
      </IconEventWrapper>
    </RoundCloseButtonStyle>
  );
};

export default React.memo(RoundCloseButton);
