import { themeStyle } from '../../../services/theming';

const panelHeaderTheme = {
  // label
  colorLabel: themeStyle.varInkMain,
  fontWeight: themeStyle.fontBold,
  fontSize: themeStyle.fontSize16,
  lineHeight: '20px',
};

export { panelHeaderTheme };
