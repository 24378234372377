import { useState, memo } from 'react';
import {
  SET_COLOR_PALETTE_PRESET,
  SET_COLOR_PALETTE_PRESET_PREVIEW,
  REMOVE_COLOR_PALETTE_PRESET_PREVIEW,
} from '../../global/events';
import themeStyle from '../../global/themeStyle';
import { filterColorPalette } from '../../utils/editor/misc';
import ColorPalettePreset from '../ColorPalettePresets/ColorPalettePreset';
import presets from '../ColorPalettePresets/presets';
import ColorPalettePanel from '../StylesMenu/ColorPalettePanel/ColorPalettePanel';
import Switch from '../Switch/Switch';
import RightMenuHeader from '../utilities/RightMenuHeader/RightMenuHeader';
import { FlexColumn } from '../utilities/styles';
import { P3 } from '../utilities/Typography/styles';
import { useUserStore, userStoreSelector } from '../../stores/userStore';
import PropTypes from 'prop-types';
import { AutoAdjustControlRow } from './styles';
import {
  usePromotionStore,
  promotionSelector,
} from '../../stores/promotionStore';
import { PanelWrapper } from '../RightMenu/styles';

const ProjectColorsMenu = (props) => {
  const proAccess = useUserStore(userStoreSelector.proAccess);
  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );
  const colorPalette = filterColorPalette(props.colorPalette);

  const [preserveLuminance, setPreserveLuminance] = useState(true);

  const { dispatch } = props;

  const togglePreserveLuminance = () => setPreserveLuminance((val) => !val);

  const handlePresetClick = (presetName) => {
    const canUsePalette = proAccess || presets[presetName].type !== 'premium';
    if (canUsePalette) {
      dispatch(SET_COLOR_PALETTE_PRESET, {
        colorPalettePreset: presets[presetName].colors,
        colorKeys: ['h', 's', ...(preserveLuminance ? [] : ['l'])],
        presetName: presetName,
      });
    } else {
      showUpgradeModal('premiumColorPalettes');
    }
  };

  const handleMouseEnterPreset = (presetName) => {
    dispatch(SET_COLOR_PALETTE_PRESET_PREVIEW, {
      colorPalettePreset: presets[presetName].colors,
      colorKeys: ['h', 's', ...(preserveLuminance ? [] : ['l'])],
      presetName: presetName,
    });
  };

  const handleMouseLeave = () => {
    dispatch(REMOVE_COLOR_PALETTE_PRESET_PREVIEW);
  };

  const renderPresets = () => {
    return Object.keys(presets).map((presetName, idx) => {
      const { colors, type } = presets[presetName];
      return (
        <ColorPalettePreset
          key={`preset-${idx}`}
          presetName={presetName}
          showPremium={type === 'premium' && !proAccess}
          colors={colors}
          type={type}
          onClick={() => handlePresetClick(presetName)}
          onMouseEnter={() => handleMouseEnterPreset(presetName)}
          onMouseLeave={handleMouseLeave}
        />
      );
    });
  };

  const switchTheme = {
    colorTrack: themeStyle.black,
    padding: '2px',
  };

  return (
    <FlexColumn>
      <PanelWrapper>
        <ColorPalettePanel
          colorPalette={colorPalette}
          documentPalette={colorPalette}
          dispatch={props.dispatch}
          label="Current Palette"
        />
      </PanelWrapper>
      <PanelWrapper>
        <RightMenuHeader label="More Palettes" />
        <FlexColumn width="100%" gap="12px">
          <AutoAdjustControlRow
            justifyContent="space-between"
            alignItems="center"
          >
            <P3>Auto Adjust Brightness</P3>
            <Switch
              dataTestId="adjust-brightness-switcher"
              label=""
              theme={switchTheme}
              checked={preserveLuminance}
              onChange={togglePreserveLuminance}
            />
          </AutoAdjustControlRow>
          {renderPresets()}
        </FlexColumn>
      </PanelWrapper>
    </FlexColumn>
  );
};

ProjectColorsMenu.propTypes = {
  /**
   * Object containing information about the artboard's color palette
   */
  colorPalette: PropTypes.object,
  /**
   * Dispatch function for artboard events
   */
  dispatch: PropTypes.func,
};

export default memo(ProjectColorsMenu);
