import React from 'react';

import TemplateNotification from './NotificationTypes/TemplateNotification';
import WorkHighlightNotification from './NotificationTypes/WorkHighlightNotification';
import CommentNotification from './NotificationTypes/CommentNotification';
import MessageNotification from './NotificationTypes/MessageNotification';
import DesignNotification from './NotificationTypes/DesignNotification';
import CommentMentionNotification from './NotificationTypes/CommentMentionNotification';
import { Notification, NotificationType } from '../../../types';

const NotificationsRenderer = ({
  notifications,
  setReadNotification,
}: {
  notifications?: Notification[];
  setReadNotification: (id: string) => void;
}): (React.ReactElement | null)[] | null => {
  if (!notifications?.length) {
    return null;
  }

  return notifications.map((n) => {
    switch (n.type) {
      case NotificationType.ADDED_TEMPLATES:
        return (
          <TemplateNotification
            key={n.id}
            notification={n}
            onReadNotification={setReadNotification}
          />
        );
      case NotificationType.TRENDING_TEMPLATES:
        return (
          <WorkHighlightNotification
            key={n.id}
            notification={n}
            onReadNotification={setReadNotification}
          />
        );
      case NotificationType.DESIGN_COMMENT:
        return (
          <CommentNotification
            key={n.id}
            notification={n}
            onReadNotification={setReadNotification}
          />
        );
      case NotificationType.COMMENT_MENTION:
        return (
          <CommentMentionNotification
            key={n.id}
            notification={n}
            onReadNotification={setReadNotification}
          />
        );
      case NotificationType.FOLLOW:
        return (
          <MessageNotification
            key={n.id}
            notification={n}
            onReadNotification={setReadNotification}
          />
        );
      case NotificationType.LIKE:
      case NotificationType.BOOKMARK:
        return (
          <DesignNotification
            key={n.id}
            notification={n}
            onReadNotification={setReadNotification}
            headline={
              n.type === NotificationType.LIKE
                ? 'liked your design'
                : 'saved your design'
            }
          />
        );
      case NotificationType.DESIGN_SHARED:
        return (
          <DesignNotification
            key={n.id}
            notification={n}
            onReadNotification={setReadNotification}
            headline={'shared a design with you'}
            link={n.data.link}
            previewImage={`api/${n.data.objectName}`}
          />
        );
      case NotificationType.TEMPLATE_FEATURED:
        return (
          <DesignNotification
            key={n.id}
            notification={n}
            onReadNotification={setReadNotification}
            headline={'Your design got featured'}
            text={
              'Congrats! Your design was chosen to be featured in the design gallery.'
            }
          />
        );
      case NotificationType.DESIGN_UPVOTE:
        return (
          <DesignNotification
            key={n.id}
            notification={n}
            onReadNotification={setReadNotification}
            headline={'upvoted your design'}
            text={
              'Congrats! You got a new vote. Share your design to win the challenge.'
            }
          />
        );
      default:
        return null;
    }
  });
};

export default NotificationsRenderer;
