import { fabric } from 'fabric';

import { Center, TidiableItem } from './types';
import { getObjectsRect } from './utils';
import {
  getAllChildObjects,
  getElementById,
  getParent,
  StructureObject,
} from '../../groupStructure';
import { getStdOf } from '../../index';

export default class Tidiable {
  /**
   * The group structure of all objects
   * @private
   */
  private readonly groupStructure: StructureObject;

  /**
   * Only top level ids.
   * Might contain group ids, and the ids of children of groups are not present.
   * Usually comes from `canvas._selectedElements`
   * @private
   */
  private readonly topLevelIds: string[];

  /**
   * All objects to be tidied.
   * Those are actual objects on the canvas, usually comes from `canvas.getActiveObjects()`.
   *
   * Note that `objects` and `topLevelIds` represent the same set of elements, just in different ways:
   * If we query all descedants of `topLevelIds` in `groupStructure`, we can get `objects`;
   * and with `objects` and `groupStructure`, we get the same `topLevelIds`.
   * We keep those two because `objects` contains detailed information on each object.
   * @private
   */
  private readonly objects: fabric.Object[];

  /**
   * An item is an entity, whose position gets adjusted when tidy.
   * This is useful because we have groups.
   * In this case, an item all the descedants of the group,
   * their relative positions to each other are not change after tidy.
   * @private
   */
  private readonly items: TidiableItem[];

  /**
   * All top level ids are on the same level in the group structure,
   * because technically we can only select in such way.
   * This is the common parent of them.
   * @private
   */
  private readonly commonParent: StructureObject;

  constructor(
    groupStructure: StructureObject,
    topLevelIds: string[],
    objects: fabric.Object[]
  ) {
    this.groupStructure = groupStructure;
    this.topLevelIds = topLevelIds;
    this.objects = objects;
    const { items, commonParent } = this.getItemsAndCommonParent(
      this.topLevelIds
    );
    this.items = items;
    this.commonParent = commonParent;
  }

  private isGroup(id: string): boolean {
    return Boolean(getElementById(this.groupStructure, id)!.children);
  }

  private getItemsAndCommonParent(ids: string[]): {
    items: TidiableItem[];
    commonParent: StructureObject;
  } {
    // If only one group is select, we take its children as items.
    // This enables us to tidy elements inside a group.
    if (ids.length === 1 && this.isGroup(ids[0])) {
      const group = getElementById(this.groupStructure, ids[0])!;
      const childIds = group.children!.map((child) => child.id!);
      return this.getItemsAndCommonParent(childIds);
    }

    return {
      items: ids.map((id) => this.getItem(id)),
      commonParent: getParent(this.groupStructure, ids[0])!,
    };
  }

  private getItem(id: string): TidiableItem {
    const node = getElementById(this.groupStructure, id)!;
    const objects = getAllChildObjects(node).map(
      (id) => this.objects.find((object) => object.id === id)!
    );

    return {
      id,
      objects,
      rect: getObjectsRect(objects),
    };
  }

  /**
   * If two items has same position (on the direction for tidy, e.g.,
   * same `left` when needs tidy horizontally, or same `top` when needs tidy vertically),
   * we place the items based on layer order.
   * @param itemA
   * @param itemB
   * @private
   */
  private sortByLayerOrder(itemA: TidiableItem, itemB: TidiableItem): number {
    const indexA = this.commonParent.children!.findIndex(
      (child) => child.id === itemA.id
    );
    const indexB = this.commonParent.children!.findIndex(
      (child) => child.id === itemB.id
    );

    return indexA - indexB;
  }

  /**
   * Get gap between items along the direction of tidy
   * @param direction
   * @private
   */
  private getGap(direction: keyof Center): number {
    const itemsTotalLength = this.items.reduce(
      (sum, item) =>
        sum + (direction === 'x' ? item.rect.width : item.rect.height),
      0
    );
    const itemsAreaRect = getObjectsRect(this.objects);
    const totalGap =
      (direction === 'x' ? itemsAreaRect.width : itemsAreaRect.height) -
      itemsTotalLength;
    return totalGap / (this.items.length - 1);
  }

  /**
   * tidy might update the bounding rect of all items, resulting in a new gap in next tidy.
   * This function is to check if the items are already "tidied" -- equally-gapped,
   * avoiding them to be able to get tidied multiple times.
   */
  private checkIfAlreadyTidied(
    propertyToUpdate: 'left' | 'top',
    lengthPropertyOnDirection: 'width' | 'height'
  ): boolean {
    let alreadyTidied = true;
    let lastGap: null | number = null;

    for (let i = 1; i < this.items.length; i++) {
      const item = this.items[i];
      const lastItem = this.items[i - 1];
      const gap =
        item.rect[propertyToUpdate] -
        (lastItem.rect[propertyToUpdate] +
          lastItem.rect[lengthPropertyOnDirection]);

      if (lastGap === null) {
        lastGap = gap;
        continue;
      }

      if (Math.abs(gap - lastGap) > 0.0001) {
        alreadyTidied = false;
        break;
      }

      lastGap = gap;
    }

    return alreadyTidied;
  }

  private tidyOnDirection(direction: keyof Center): void {
    let propertyToUpdate: 'left' | 'top';
    let lengthPropertyOnDirection: 'width' | 'height';

    if (direction === 'x') {
      propertyToUpdate = 'left';
      lengthPropertyOnDirection = 'width';
    } else {
      propertyToUpdate = 'top';
      lengthPropertyOnDirection = 'height';
    }

    this.items.sort((itemA, itemB) => {
      const valueA = itemA.rect[propertyToUpdate];
      const valueB = itemB.rect[propertyToUpdate];

      if (valueA === valueB) {
        return this.sortByLayerOrder(itemA, itemB);
      } else {
        return valueA - valueB;
      }
    });

    if (
      this.checkIfAlreadyTidied(propertyToUpdate, lengthPropertyOnDirection)
    ) {
      return;
    }

    const gap = this.getGap(direction);
    this.items.forEach((item, itemIndex) => {
      if (itemIndex > 0) {
        const updatedItemPropertyValue =
          this.items[itemIndex - 1].rect[propertyToUpdate] +
          this.items[itemIndex - 1].rect[lengthPropertyOnDirection] +
          gap;
        item.objects.forEach((object) => {
          const groupOffset =
            object[propertyToUpdate] - item.rect[propertyToUpdate];
          object[propertyToUpdate] = updatedItemPropertyValue + groupOffset;
        });
        item.rect[propertyToUpdate] = updatedItemPropertyValue;
      }
    });
  }

  tidy(): void {
    // determine which direction we need to tidy by querying item center std
    const horizontalCenterStd = getStdOf(
      this.items,
      ({ rect: { left, width } }) => left + width / 2
    );
    const verticalCenterStd = getStdOf(
      this.items,
      ({ rect: { top, height } }) => top + height / 2
    );
    const needsTidyHorizontally = horizontalCenterStd >= verticalCenterStd;
    const needsTidyVertically = verticalCenterStd >= horizontalCenterStd;

    if (needsTidyHorizontally) {
      this.tidyOnDirection('x');
    }

    if (needsTidyVertically) {
      this.tidyOnDirection('y');
    }
  }
}
