import { useState } from 'react';

/**
 * This hook is used to trigger re-render when fabric canvas is created.
 * The main purpose is to make sure it exists in hooks that depends on it,
 * for example, to add event handlers on the canvas;
 * This way we can avoid watching a rather big object (farbic canvas).
 */
export const useCanvasCreated = (): (() => void) => {
  const [, setCreated] = useState<boolean>(false);
  return (): void => setCreated(true);
};
