import { forwardRef, useState, useEffect, useCallback } from 'react';
import { ProfileImg, ProfileImageFallback, Loading } from './styles';
import { defaultTheme } from './theme';
import { ProfileImageProps } from './types';

/**
 * render the profile image of a user
 */
const ProfileImage = forwardRef<
  HTMLImageElement | HTMLDivElement,
  ProfileImageProps
>(({ user, ...props }, ref) => {
  const theme = { ...defaultTheme, ...props.theme };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleImageLoaded = useCallback(() => {
    setError(false);
    setLoading(false);
  }, [setError, setLoading]);

  const handleImageLoadError = useCallback(() => {
    setError(true);
    setLoading(false);
  }, [setError, setLoading]);

  useEffect(() => {
    if (!user.profileImage) {
      setError(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    const img = new Image();
    img.addEventListener('load', handleImageLoaded);
    img.addEventListener('error', handleImageLoadError);
    img.src = user.profileImage;

    return (): void => {
      img.removeEventListener('load', handleImageLoaded);
      img.removeEventListener('error', handleImageLoadError);
    };
  }, [user.profileImage, handleImageLoaded, handleImageLoadError]);

  if (loading) {
    return <Loading theme={theme} />;
  }

  if (error) {
    return (
      <ProfileImageFallback
        ref={ref}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onTouchStart={props.onTouchStart}
        hasOnClick={!!props.onClick}
        theme={theme}
        data-testid="profile-image"
      >
        {user.name?.length ? user.name[0].toUpperCase() : ''}
      </ProfileImageFallback>
    );
  }

  return (
    <ProfileImg
      ref={ref}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onTouchStart={props.onTouchStart}
      hasOnClick={!!props.onClick}
      theme={theme}
      src={user.profileImage}
      data-testid="profile-image"
      alt={`Profile picture of ${user.name}`}
    />
  );
});

export default ProfileImage;
