import { createFreeFormCurves } from '../../components/Artboard/fabric/PathText/utils/transformation';

/**
 * draw a curve on the canvas
 * @param {paper.Path} curve
 * @param {*} ctx
 */
export const drawCurve = (curve, ctx) => {
  const accuracy = 0.01;

  const start = curve.getPointAt(0);
  const end = curve.getPointAt(curve.length);
  ctx.moveTo(start.x, start.y);

  for (let i = 0; i <= 1; i += accuracy) {
    const p = curve.getPointAt(i * curve.length);
    if (!p) continue; // if curve is too short p can be null
    ctx.lineTo(p.x, p.y);
  }
  ctx.lineTo(end.x, end.y);
};

export const drawLine = ({ start, end }, ctx) => {
  ctx.moveTo(start.x, start.y);
  ctx.lineTo(end.x, end.y);
};

export const drawFreeFormCurves = (points, ctx) => {
  const { top, bottom } = createFreeFormCurves(points);
  drawCurve(top, ctx);
  drawCurve(bottom, ctx);

  const topLeft = points[0];
  const topCenter = points[1];
  const topRight = points[2];
  const bottomRight = points[3];
  const bottomCenter = points[4];
  const bottomLeft = points[5];
  const topLeftHandle = points[6];
  const topRightHandle = points[7];
  const bottomLeftHandle = points[8];
  const bottomRightHandle = points[9];
  // draw left and right line segments
  drawLine({ start: topLeft, end: bottomLeft }, ctx);
  drawLine({ start: topRight, end: bottomRight }, ctx);
  // draw handles
  drawLine({ start: topLeftHandle, end: topCenter }, ctx);
  drawLine({ start: topCenter, end: topRightHandle }, ctx);
  drawLine({ start: bottomLeftHandle, end: bottomCenter }, ctx);
  drawLine({ start: bottomCenter, end: bottomRightHandle }, ctx);
};
