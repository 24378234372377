export default function (fabric) {
  fabric.Shadow.prototype.toSVG = function (object) {
    let fBoxX = 40;
    let fBoxY = 40;
    const NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS;
    const offset = fabric.util.rotateVector(
      { x: this.offsetX, y: this.offsetY },
      fabric.util.degreesToRadians(-object.angle)
    );
    const BLUR_BOX = 20;
    const color = new fabric.Color(this.color);
    const toFixed = fabric.util.toFixed;

    if (object.width && object.height) {
      //http://www.w3.org/TR/SVG/filters.html#FilterEffectsRegion
      // we add some extra space to filter box to contain the blur ( 20 )
      fBoxX =
        toFixed(
          (Math.abs(offset.x) + this.blur) / object.width,
          NUM_FRACTION_DIGITS
        ) *
          100 +
        BLUR_BOX;
      fBoxY =
        toFixed(
          (Math.abs(offset.y) + this.blur) / object.height,
          NUM_FRACTION_DIGITS
        ) *
          100 +
        BLUR_BOX;
    }

    if (object.flipX) {
      offset.x *= -1;
    }

    if (object.flipY) {
      offset.y *= -1;
    }

    /*
        Up to here the code is the same as fabric.js.
        If this.exportStandalone is truthy, then the feMerge operation
        is modified so that the shadow's source is not rendered in the final svg.
    */
    return (
      '<filter id="SVGID_' +
      this.id +
      '" y="-' +
      fBoxY +
      '%" height="' +
      (100 + 2 * fBoxY) +
      '%" ' +
      'x="-' +
      fBoxX +
      '%" width="' +
      (100 + 2 * fBoxX) +
      '%" ' +
      '>\n' +
      '\t<feGaussianBlur in="SourceAlpha" stdDeviation="' +
      toFixed(this.blur ? this.blur / 2 : 0, NUM_FRACTION_DIGITS) +
      '"></feGaussianBlur>\n' +
      '\t<feOffset dx="' +
      toFixed(offset.x, NUM_FRACTION_DIGITS) +
      '" dy="' +
      toFixed(offset.y, NUM_FRACTION_DIGITS) +
      '" result="oBlur" ></feOffset>\n' +
      '\t<feFlood flood-color="' +
      color.toRgb() +
      '" flood-opacity="' +
      color.getAlpha() +
      '"/>\n' +
      '\t<feComposite in2="oBlur" operator="in" />\n' +
      '\t<feMerge>\n' +
      '\t\t<feMergeNode></feMergeNode>\n' +
      (this.exportStandalone
        ? ''
        : '\t\t<feMergeNode in="SourceGraphic"></feMergeNode>\n') +
      '\t</feMerge>\n' +
      '</filter>\n'
    );
  };
}
