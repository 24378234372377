import { Line, Point } from '../../types';
import { areVectorsParallel } from './vector';

/**
 * Get param (k, m) for line y = k*x + m defined by two given points p1, p2.
 * Special cases:
 * 1) if p1 and p2 have the same coordinates, k === undefined, m === undefined;
 * 2) for vertical lines, k === Infinity, m === undefined;
 * 3) for horizontal lines, k === 0, m === p1.y === p2.y;
 * @param p1 {{ x: number, y: number }}
 * @param p2 {{ x: number, y: number }}
 * @returns {{k: number | Infinity | undefined, m: number | undefined}}
 */
export const getLineParam = (
  p1: Point,
  p2: Point
): { k: number | undefined; m: number | undefined } => {
  const k = getK(p1, p2);
  const m = k === Infinity || k === undefined ? undefined : p2.y - k * p2.x;

  return { k, m };
};

/**
 * Get the slope of a line defined by two given points p1, p2.
 * Special cases:
 * 1) if p1 and p2 have the same coordinates, k === undefined;
 * 2) for vertical lines, k === Infinity;
 * 3) for horizontal lines, k === 0;
 * @param p1 {{ x: number, y: number }}
 * @param p2 {{ x: number, y: number }}
 * @returns {number | Infinity | undefined}
 */
export const getK = (p1: Point, p2: Point): number | undefined => {
  if (p1.x === p2.x && p1.y === p2.y) {
    return undefined;
  } else if (p1.x === p2.x && p1.y !== p2.y) {
    return Infinity;
  } else if (p1.x !== p2.x && p1.y === p2.y) {
    // Sometimes JS returns -0, just to make sure we only see 0
    return 0;
  } else {
    return (p1.y - p2.y) / (p1.x - p2.x);
  }
};

/**
 * Check if a point is on the left side of a line, defined by two other given points p1, p2.
 * Special cases:
 * 0) if p1 and p2 have the same coordinates, return true;
 * 1) if the point is on the line, return true;
 * 2) if the line is a horizontal line, the result tells if the point is on the upper side of the line;
 * @param x {number}
 * @param y {number}
 * @param p1 {{ x: number, y: number }}
 * @param p2 {{ x: number, y: number }}
 * @returns {boolean}
 */
export const checkIfPointIsOnLeftSideOfLine = (
  { x, y }: Point,
  p1: Point,
  p2: Point
): boolean => {
  const { k, m } = getLineParam(p1, p2);

  let pointOnLeftSide = true;

  if (k === undefined) {
    // p1 and p2 have the same coordinates
    pointOnLeftSide = true;
  } else if (
    (k === Infinity && x > p1.x) ||
    (k > 0 && k * x + m! > y) ||
    (k < 0 && k * x + m! < y) ||
    (k === 0 && y < p1.y)
  ) {
    pointOnLeftSide = false;
  }

  return pointOnLeftSide;
};

/**
 * Evaluates line at a specified time
 * @param line {{ direction: { x: number, y: number }, point: { x: number, y: number } }} The line to evaluate
 * @param time {{ number }} The time to evaluate at
 * @returns {{x: number, y: number}}:
 */
export const evaluateLine = (line: Line, time: number): Point => {
  return {
    x: line.point.x + time * line.direction.x,
    y: line.point.y + time * line.direction.y,
  };
};

/**
 * Computes intersection between two lines
 * @param lineA {{ direction: { x: number, y: number }, point: { x: number, y: number } }} The first line
 * @param lineB {{ direction: { x: number, y: number }, point: { x: number, y: number } }} The second line
 * @returns {point: {x: number, y: number} | null, t: number}:
 *    point: The intersection point, if there is one. null otherwise
 *    t: The time parameter for lineB such that lineB(t) = point
 */
export const intersectLines = (
  lineA: Line,
  lineB: Line
): { point: Point; t: number } | null => {
  const { point: pA, direction: vA } = lineA;
  const { point: pB, direction: vB } = lineB;

  if (areVectorsParallel(vA, vB)) {
    return null; // No intersection possible
  }

  // lineA and lineB are written in the following form
  // lineA(t_a) = (t_a * vA.x + pA.x, t_a * vA.y + pA.y)
  // lineB(t_b) = (t_b * vB.x + pB.x, t_b * vB.y + pB.y)

  // We then want
  // t_a * vA.x + pA.x = t_b * vB.x + pB.x
  // t_a * vA.y + pA.y = t_b * vB.y + pB.y

  // If vA.x = 0
  // t_b = (pA.x - pB.x) / vB.x
  // NOTE: vB.x != 0 since vA and vB are not parallel

  // Else
  // From 1.
  //    t_a = (t_b * vB.x + pB.x - pA.x) / vA.x
  // Then from 2.
  //    (vA.y / vA.x) * (t_b * vB.x + pB.x - pA.x) + pA.y = t_b * vB.y + pB.y
  //    (vA.y / vA.x) * t_b * vB.x + (vA.y / vA.x) * (pB.x - pA.x) + pA.y =  t_b * vB.y + pB.y
  //    (vA.y / vA.x) * t_b * vB.x - t_b * vB.y = pB.y - pA.y - (vA.y / vA.x) * (pB.x - pA.x)
  //    t_b * ((vA.y / vA.x) * vB.x - vB.y) = pB.y - pA.y - (vA.y / vA.x) * (pB.x - pA.x)
  //    t_b = (pB.y - pA.y - (vA.y / vA.x) * (pB.x - pA.x)) / ((vA.y / vA.x) * vB.x - vB.y)
  //    NOTE: ((vA.y / vA.x) * vB.x - vB.y) != 0 since vA and vB are not parallel

  let t_b;
  if (!vA.x) {
    t_b = (pA.x - pB.x) / vB.x;
  } else {
    t_b =
      (pB.y - pA.y - (vA.y / vA.x) * (pB.x - pA.x)) /
      ((vA.y / vA.x) * vB.x - vB.y);
  }

  return {
    t: t_b,
    point: evaluateLine(lineB, t_b),
  };
};

/**
 * Computes orthogonal projection of a point on a line
 * @param point {{ x: number, y: number }}
 * @param line {{ direction: { x: number, y: number }, point: { x: number, y: number } }}
 * @returns {point: {x: number, y: number} | null, t: number} as returned by @method intersectLines
 */
export const projectPointOnLine = (
  point: Point,
  line: Line
): { point: Point; t: number } | null => {
  const { direction: d } = line;

  const orthogonalDirection = d.y
    ? {
        x: 1,
        y: -d.x / d.y,
      }
    : {
        x: 0,
        y: 1,
      }; // This way dot(orthogonalDirection, line.direction) = 0

  const orthogonalLine = { direction: orthogonalDirection, point };
  return intersectLines(orthogonalLine, line);
};
