import { GetState, SetState } from 'zustand';
import { Scope } from '@sentry/types';

import { State } from './types';
import { updateOnboarding, getUserInfo, setToken } from './utils';
import { User, ResponseWithError } from '../../types';
import userApi from '../../global/userApi';
import {
  TEMPLATE_BOOKMARK,
  USER_LOGIN,
  USER_LOGOUT,
  USER_SIGNUP,
} from '../../global/analytics/events';
import { notificationStore } from '../notificationStore';
import monitoring from '../../services/monitoring';
import analytics, {
  disableMixpanel,
  enableMixpanel,
} from '../../global/analytics';

let getUserLoading: { resolve: (user: User | null) => void }[] = [];

export const stateCreator = (
  set: SetState<State>,
  get: GetState<State>
): State => ({
  /**
   * user state
   * contains information about the current user
   * false: state unclear, null: no user
   */
  user: false,

  /**
   * store templates that are bookmarked by a user
   */
  bookmarks: [],

  /**
   * Array where functions can be registered using
   * registerBeforeLoginAction. They are triggered in order before login
   */
  // TODO：this naming is not semantically correct anymore, should consider refactoring. Ref: https://app.clickup.com/t/2chcv6r
  beforeLoginActions: [],

  /**
   * Array where functions can be registered using
   * registerAfterSignupAction. They are triggered in order after signup
   */
  afterSignupActions: [],

  isLoading: false,

  _loadUserInfo: async (token?: string): Promise<User | null> => {
    set({ isLoading: true });
    const user = await getUserInfo(token);

    getUserLoading.forEach(({ resolve }) => resolve(user));
    getUserLoading = [];

    set({ isLoading: false });

    return user;
  },

  getUser: async (): Promise<State['user']> => {
    if (!get().isLoading) return get().user;

    return new Promise((resolve) => {
      getUserLoading.push({ resolve });
    });
  },

  _setUser: async (user: State['user']): Promise<void> => {
    updateOnboarding(user);
    const previousUser = get().user;
    set({ user });

    if (user && (!previousUser || user.id !== previousUser?.id)) {
      get().beforeLogin(user);
      const { templates } = await userApi.getBookmarkReferences();
      set({ bookmarks: templates });
      await enableMixpanel();
      analytics.identify(user.id, {
        email: user.email,
        name: user.name,
        id: user.id,
      });
    }
  },

  /**
   * initialize the user state
   */
  initialize: async (): Promise<void> => {
    if (get().user === false) {
      const user = await get()._loadUserInfo();
      await get()._setUser(user);
    }
  },
  /**
   * update the user with a new accessToken
   */
  updateUser: async (accessToken: string): Promise<void> => {
    setToken(accessToken);
    const user = await get()._loadUserInfo(accessToken);
    await get()._setUser(user);
  },
  /**
   * update the information about the current user
   */
  updateUserInfo: async (data?: Partial<User>): Promise<User | null> => {
    let user: User | null;
    if (data) {
      user = { ...(get().user || {}), ...data } as User;
    } else {
      user = await get()._loadUserInfo();
    }

    set({ user });
    return user;
  },
  /**
   * create the user with a new accessToken and update
   */
  createUser: async ({
    name,
    email,
    password,
    invite,
    referral,
    promoCode,
    impactClickId,
    campaign,
  }: {
    name: string;
    email: string;
    password: string;
    invite: string;
    referral: string;
    promoCode: string;
    impactClickId: string;
    campaign?: string;
  }): Promise<{ user: User; accessToken: string } | ResponseWithError> => {
    try {
      const res = await userApi.createUser({
        name,
        email,
        password,
        invite,
        referral,
        promoCode,
        impactClickId,
        campaign,
      });
      if ('accessToken' in res) {
        await get().updateUser(res.accessToken);
        analytics.track(USER_SIGNUP, {
          label: 'email',
          authenticationMethod: 'email',
          userId: res.id,
          invite,
          referral,
          promoCode,
          impactClickId,
        });
        get().afterSignup();
        return { accessToken: res.accessToken, user: get().user as User };
      } else {
        return res;
      }
    } catch (error) {
      return { error: String(error) };
    }
  },
  /**
   * login the user with a new accessToken and update
   */
  loginUser: async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<{ user: User; accessToken: string } | ResponseWithError> => {
    try {
      const res = await userApi.createSession(email, password);
      if ('accessToken' in res) {
        await get().updateUser(res.accessToken);
        analytics.track(USER_LOGIN, {
          label: 'email',
          authenticationMethod: 'email',
          userId: res.id,
        });
        return { accessToken: res.accessToken, user: get().user as User };
      } else {
        return res;
      }
    } catch (error) {
      return { error: String(error) };
    }
  },
  /**
   * exchange a 3rd party token for a HT token and update cookie
   */
  exchangeTemporaryToken: async (
    token: string
  ): Promise<{ user: User; accessToken: string } | ResponseWithError> => {
    try {
      const res = await userApi.exchangeTemporaryToken(token);
      if ('accessToken' in res) {
        await get().updateUser(res.accessToken);
        if (res.isSignup) {
          analytics.track(USER_SIGNUP, {
            label: res.name,
            authenticationMethod: res.name,
            userId: res.id,
            referral: res.referral,
            promoCode: res.promoCode,
          });
        } else {
          analytics.track(USER_LOGIN, {
            label: res.name,
            authenticationMethod: res.name,
            userId: res.id,
          });
        }
        return { accessToken: res.accessToken, user: get().user as User };
      } else {
        return res;
      }
    } catch (error) {
      return { error: String(error) };
    }
  },
  /**
   * clean all current user state
   */
  removeUser: async (): Promise<void> => {
    setToken(null);
    notificationStore.getState().reset();
    analytics.reset();
    await disableMixpanel();
    monitoring.configureScope((scope: Scope) => scope.setUser(null));
    return set({ user: null, bookmarks: [] });
  },
  /**
   * logout the user and perform cleanup
   */
  logout: async (): Promise<void> => {
    await userApi.deleteSession();
    analytics.track(USER_LOGOUT);
    await get().removeUser();
  },

  /**
   * Registers action to trigger before login
   */
  registerBeforeLoginAction: (action: () => void): void => {
    const actions = get().beforeLoginActions;

    if (!actions.includes(action)) {
      set((state) => ({
        beforeLoginActions: [...state.beforeLoginActions, action],
      }));
    }
  },

  unregisterBeforeLoginAction: (action: () => void): void => {
    const actions = get().beforeLoginActions;
    const actionIndex = actions.findIndex(action);

    if (actionIndex > -1) {
      set((state) => ({
        beforeLoginActions: [
          ...state.beforeLoginActions.slice(0, actionIndex),
          ...state.beforeLoginActions.slice(actionIndex + 1),
        ],
      }));
    }
  },

  /**
   * Registers action to trigger after signup
   */
  registerAfterSignupAction: (action): void => {
    set((state) => ({
      afterSignupActions: [...state.afterSignupActions, action],
    }));
  },

  /**
   * Triggers all before login actions
   */
  beforeLogin: (user: User | null): void => {
    if (user) {
      try {
        monitoring.setUser({
          email: user.email,
          id: user.id,
          username: user.slug,
        });
      } catch (e) {
        console.warn('Error with sentry: ', e);
      }
    }
    get().beforeLoginActions.forEach((action) => action(user));
  },

  /**
   * Triggers all after signup actions
   */
  afterSignup: (user?: User): void => {
    get().afterSignupActions.forEach((action) => action(user));
  },

  /**
   * updates whether an element is bookmarked or not
   * both locally and in the API
   */
  updateBookmark: async (isBookmarked: boolean, id: number): Promise<void> => {
    const bookmarkType = 'TEMPLATE';
    const bookmarks = get().bookmarks;
    if (!isBookmarked && !bookmarks.includes(id)) {
      // add to bookmarks
      await userApi.createBookmark(id, bookmarkType);
      analytics.track(TEMPLATE_BOOKMARK);
      set({
        bookmarks: [id, ...bookmarks],
      });
    } else if (isBookmarked && bookmarks.includes(id)) {
      // remove from bookmarks
      await userApi.deleteBookmark(id, bookmarkType);
      set({
        bookmarks: bookmarks.filter((bId) => bId !== id),
      });
    }
  },
});
