import React from 'react';
import PropTypes from 'prop-types';
import { Label, Wrapper } from './styles';

const Shortcut = ({ label }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
    </Wrapper>
  );
};

Shortcut.propTypes = {
  /**
   * label for the shortcut
   */
  label: PropTypes.string,
};

export default React.memo(Shortcut);
