import { useEffect, useRef } from 'react';

import { isCompatibleTouchDevice } from '../utils/detection';
import GestureTracker from '../helpers/TouchInput/GestureTracker';
import { createGestureHandlers } from '../helpers/TouchInput/Gestures';
import { ZOOM_TO } from '../global/events';

export const useGestures = (canvas, canvasContainer, dispatch, callback) => {
  const zoom = useRef(null);

  useEffect(() => {
    if (!canvasContainer || !canvas) return;

    const upperCanvas = canvas.upperCanvasEl;

    const isTouch = isCompatibleTouchDevice();
    const gestureTracker = new GestureTracker(upperCanvas);
    const gestureHandlers = createGestureHandlers(canvas, canvasContainer);

    const withSideEffects = (handler) => {
      return (args) => {
        const { gestureSize } = args;
        if (gestureSize === 2) {
          callback && callback(canvas.getActiveObject());
        }
        return handler(args);
      };
    };

    gestureTracker.on('gesturestart', (args) => {
      const { gestureSize } = args;
      const handler = gestureHandlers.onStart[gestureSize];
      handler && withSideEffects(handler)(args);
    });

    gestureTracker.on('gesturechange', (args) => {
      const { gestureSize } = args;
      const handler = gestureHandlers.onChange[gestureSize];
      handler && withSideEffects(handler)(args);
    });

    gestureTracker.on('gestureend', (args) => {
      const { gestureSize } = args;
      const handler = gestureHandlers.onEnd[gestureSize];
      handler && withSideEffects(handler)(args);
    });

    gestureTracker.on('tap', (args) => {
      const { taps } = args;
      const handler = gestureHandlers.onTap[taps.length];
      handler && withSideEffects(handler)(args);
    });

    // Add listeners for safari gestures.
    // They are only called on desktop since native gestures can break
    // our own custom gestures

    const handleSafariPinch = (event) => {
      if (zoom.current === null) {
        zoom.current = canvas.getZoom();
      }

      const newZoom = zoom.current * event.scale;
      const { x, y } = canvas.getPointer(event, true);

      dispatch(ZOOM_TO, {
        zoom: newZoom,
        pos: { x, y },
      });

      canvas.resetViewport();
      event.preventDefault();
    };

    const preventDefault = (event) => event.preventDefault();

    const gestureEnd = (event) => {
      preventDefault(event);
      zoom.current = null;
    };

    const onlyCallOnDesktop = (f) => {
      return (...args) => {
        if (isTouch) {
          return;
        }
        f(...args);
      };
    };

    const onGestureStart = onlyCallOnDesktop(preventDefault);
    const onGestureChange = onlyCallOnDesktop(handleSafariPinch);
    const onGestureEnd = onlyCallOnDesktop(gestureEnd);

    canvasContainer.addEventListener('gesturestart', onGestureStart, {
      passive: false,
    });

    canvasContainer.addEventListener('gesturechange', onGestureChange, {
      passive: false,
    });

    canvasContainer.addEventListener('gestureend', onGestureEnd, {
      passive: false,
    });

    return () => {
      gestureTracker.cleanupListeners();
      canvasContainer?.removeEventListener('gesturechange', onGestureStart);
      canvasContainer?.removeEventListener('gesturestart', onGestureChange);
      canvasContainer?.removeEventListener('gestureend', onGestureEnd);
    };
  }, [canvas, canvasContainer, dispatch, callback]);
};
