import React from 'react';

import { Wrapper } from './styles';
import { ReactComponent as LogoSVG } from './logo.svg';
import { LogoProps } from './types';

const defaultSize = {
  width: 118,
  height: 36,
};

const Logo: React.FC<LogoProps> = ({ width, height, style }) => {
  const logoSize = { ...defaultSize };

  if (width) {
    logoSize.width = width;
    logoSize.height = (width / defaultSize.width) * defaultSize.height;
  } else if (height) {
    logoSize.height = height;
    logoSize.width = (height / defaultSize.height) * defaultSize.width;
  }

  return (
    <Wrapper style={style}>
      <LogoSVG width={`${logoSize.width}px`} height={`${logoSize.height}px`} />
    </Wrapper>
  );
};

export default React.memo(Logo);
