import styled, { keyframes } from 'styled-components';
import themeStyle from '../../../../global/themeStyle';

const shimmer = keyframes`
  from {
    background-position: -468px 0
  }
  to {
    background-position: 468px 0
  }
`;

export const ImageContainer = styled.div<{
  loaded?: boolean;
  noMargin?: boolean;
  withPadding?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  height: 70px;
  min-width: ${({ loaded }) => (loaded ? 'unset' : '70px')};

  margin-top: ${({ noMargin }) => (noMargin ? '0' : '10px')};
  margin-right: ${({ noMargin }) => (noMargin ? '0' : '6px')};
  padding: ${({ withPadding }) => (withPadding ? '10px' : '0')};
  border-radius: 5px;

  animation: ${({ loaded }) => (loaded ? 'none' : shimmer)};
  animation-duration: 2.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    ${themeStyle.grey300},
    ${themeStyle.white} 50%,
    ${themeStyle.grey300} 100%
  );
  background-size: 1000px 70px;
  border-radius: 5px;

  img {
    box-sizing: border-box;
    visibility: ${({ loaded }) => (loaded ? 'visible' : 'hidden')};
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
