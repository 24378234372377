import styled, { keyframes } from 'styled-components';
import { themeStyle } from '../../services/theming';
import { OverlayImageProps } from './types';

const scaleUp = keyframes`
  from {
    transform: scale(1)
  }

  to {
    transform: scale(1.1)
  }
`;

export const OverlayImage = styled.img.attrs<OverlayImageProps>(
  ({
    src,
    left,
    top,
    imgWidth,
    imgHeight,
  }: {
    src: string;
    left: number;
    top: number;
    imgWidth: number;
    imgHeight: number;
  }) => ({
    src,
    crossOrigin: 'anonymous',
    style: {
      left,
      top,
      width: imgWidth,
      height: imgHeight,
    },
  })
)<OverlayImageProps>`
  border-radius: 5px;
  position: absolute;
  object-fit: contain;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
  -webkit-touch-callout: none;
  pointer-events: none;
  cursor: pointer;
  z-index: ${themeStyle.zIndexS2};

  @media (hover: none) {
    animation: ${scaleUp} 0.25s ease-in-out;
    transform: scale(1.1);
  }
`;
