import { TooltipPlacement, TooltipAlignment } from './types';

export const calculateTooltipPosition = ({
  targetRect,
  popoverRect,
  containerRect,
  placement,
  align,
  padding = 10,
  offsetX = 0,
  offsetY = 0,
}: {
  targetRect: DOMRect;
  popoverRect: DOMRect;
  containerRect: DOMRect;
  placement: TooltipPlacement;
  align: TooltipAlignment;
  padding?: number;
  offsetX?: number;
  offsetY?: number;
}): [string, string, string] => {
  let left = 0;
  let top = 0;
  let arrowPos = 0;

  const arrowWidth = 10;
  const verticalArrowPadding = 20;

  const placementIsVertical = placement === 'top' || placement === 'bottom';

  if (placementIsVertical) {
    if (placement === 'top') {
      top = targetRect.top - popoverRect.height - padding;
    } else {
      top = targetRect.bottom + padding;
    }

    if (align === 'center') {
      left = targetRect.left + targetRect.width / 2 - popoverRect.width / 2;
      arrowPos = popoverRect.width / 2 - arrowWidth / 2;
    } else if (align === 'start') {
      left = targetRect.left;
      arrowPos = targetRect.width / 2 + arrowWidth / 2;
    } else {
      left = targetRect.left + targetRect.width - popoverRect.width;
      arrowPos = popoverRect.width - targetRect.width / 2 - arrowWidth / 2;
    }
  } else {
    if (placement === 'right') {
      left = targetRect.right + padding;
    } else {
      left = targetRect.left - popoverRect.width - padding;
    }

    if (align === 'center') {
      top = targetRect.top + targetRect.height / 2 - popoverRect.height / 2;
      arrowPos = popoverRect.height / 2 - arrowWidth / 2;
    } else if (align === 'start') {
      top = targetRect.top - verticalArrowPadding;
      arrowPos = targetRect.height / 2 - arrowWidth / 2 + verticalArrowPadding;
    } else {
      top =
        targetRect.top +
        targetRect.height -
        popoverRect.height +
        verticalArrowPadding;
      arrowPos =
        popoverRect.height -
        targetRect.height / 2 -
        arrowWidth / 2 -
        verticalArrowPadding;
    }
  }

  top = top - containerRect.top + offsetY;
  left = left - containerRect.left + offsetX;

  // Keep popovers within window
  if (left > containerRect.width - popoverRect.width) {
    if (placementIsVertical) {
      arrowPos = arrowPos + (left - (containerRect.width - popoverRect.width));
    }
    left = containerRect.width - popoverRect.width;
  } else if (left < 0) {
    if (placementIsVertical) {
      arrowPos = arrowPos + left;
    }
    left = 0;
  }

  if (top > window.innerHeight - popoverRect.height) {
    if (!placementIsVertical) {
      arrowPos = arrowPos + (top - (window.innerHeight - popoverRect.height));
    }
    top = window.innerHeight - popoverRect.height;
  }

  if (top < 0) {
    if (!placementIsVertical) {
      arrowPos = arrowPos + top;
    }
    top = 0;
  }

  return [left.toFixed(), top.toFixed(), arrowPos.toFixed()];
};
