/**
 * utility to get the dimensions in that template images should be displayed
 */

const MIN_PERCENTAGE = 0.5;
const MAX_PERCENTAGE = 2;

/**
 * get height for a preview, based on a fixed width
 */
export const getTemplatePreviewHeight = (
  { width, height }: { width: number; height: number },
  fixedWidth: number
): number => {
  const ratio = width / height;
  const baseHeight = Math.floor(fixedWidth / ratio);
  return Math.min(
    Math.max(MIN_PERCENTAGE * fixedWidth, baseHeight),
    MAX_PERCENTAGE * fixedWidth
  );
};

/**
 * get width for a preview, based on a fixed height
 */
export const getTemplatePreviewWidth = (
  { width, height }: { width: number; height: number },
  fixedHeight: number
): number => {
  const ratio = width / height;
  const baseWidth = Math.floor(fixedHeight * ratio);
  return Math.min(
    Math.max(MIN_PERCENTAGE * fixedHeight, baseWidth),
    MAX_PERCENTAGE * fixedHeight
  );
};
