import noop from 'lodash/noop';
import { DOWNLOAD } from '../events';
import userApi from '../userApi';

// plugin to send selected events to api/events
export default function apiPlugin() {
  return {
    name: 'api',
    initialize: noop,
    page: noop,
    track: async ({ payload }) => {
      try {
        const { event, properties } = payload;
        if (event === DOWNLOAD) {
          await userApi.createEvent('DOWNLOAD', properties);
        }
      } catch (_error) {
        // ignore error
      }
    },
    identify: noop,
    reset: noop,
  };
}
