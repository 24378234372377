import { Subject } from 'rxjs';
import { Dispatcher, EventBusData } from '../types';

export default function createDispatcher(): {
  bus: Subject<EventBusData>;
  dispatch: Dispatcher;
} {
  const bus = new Subject<EventBusData>();
  const dispatch: Dispatcher = (key, val) => bus.next({ key, val });
  return { bus, dispatch };
}
