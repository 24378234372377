import React, { useMemo } from 'react';

import { buildProfileImageUrl } from '../../../../utils/url';
import { timeAgo } from '../../../../utils/timeAgo';
import ProfileImage from '../../../Profile/ProfileImage/ProfileImage';
import {
  NotificationHeaderWrapper,
  CreatedAt,
  UnreadDot,
  UsernameAndMessage,
} from './styles';
import { H6, H5, P4 } from '../../../utilities/Typography/styles';
import { themeStyle } from '../../../../services/theming';
import { NotificationHeaderProps } from './types';

const NotificationHeader: React.FC<NotificationHeaderProps> = ({
  user,
  message,
  createdAt,
  isUnseen,
}) => {
  const formattedCreatedAt = useMemo(
    () => timeAgo(createdAt, true, true),
    [createdAt]
  );

  return (
    <NotificationHeaderWrapper hasUser={!!user}>
      {(user && (
        <>
          <ProfileImage
            user={{
              name: user.name,
              profileImage: buildProfileImageUrl(user.profileImage),
            }}
            theme={{ size: '30px' }}
          />
          <UsernameAndMessage>
            <H6 noTextOverflow color={themeStyle.varInkMain}>
              {user.name}
            </H6>
            <P4 color={themeStyle.varInkMedium}>{message}</P4>
          </UsernameAndMessage>
        </>
      )) || <H5>{message}</H5>}
      <CreatedAt>
        {isUnseen && <UnreadDot />}
        <P4 color={themeStyle.varInkMedium} noTextOverflow>
          {formattedCreatedAt}
        </P4>
      </CreatedAt>
    </NotificationHeaderWrapper>
  );
};

export default NotificationHeader;
