import { ACTIVE_UPPERCASE, ACTIVE_LIGATURES } from '../../../global/events';

export const textTransformConfig = [
  {
    id: 'uppercase',
    icon: 'uppercase',
    event: ACTIVE_UPPERCASE,
    tooltip: 'Uppercase',
  },
  {
    id: 'useLigatures',
    icon: 'ligature',
    event: ACTIVE_LIGATURES,
    tooltip: 'Ligatures',
  },
];

export const textAlignConfig = [
  { id: 'left', icon: 'alignLeft', tooltip: 'Align left' },
  { id: 'center', icon: 'alignCenter', tooltip: 'Align center' },
  { id: 'right', icon: 'alignRight', tooltip: 'Align right' },
  { id: 'justify', icon: 'alignJustify', tooltip: 'Justify' },
];
