import React from 'react';
import PropTypes from 'prop-types';
import { FadeIn } from './styles';

import { useUserStore, userStoreSelector } from '../../stores/userStore';

/**
 * This is a wrapper for the user navigation in the header.
 * It displays a navigation for logged in users and authentication options for
 * users that are not logged in.
 */
const WithAuth = ({ renderAuth, renderUnauth, disableAnimation }) => {
  const user = useUserStore(userStoreSelector.user);
  const onLogOut = useUserStore(userStoreSelector.logout);

  return (
    <FadeIn enabled={!disableAnimation}>
      {renderUnauth && user === null && renderUnauth()}
      {user && renderAuth(user, onLogOut)}
    </FadeIn>
  );
};

WithAuth.propTypes = {
  /**
   * render function with current user
   * (user, onLogOut) => {}
   */
  renderAuth: PropTypes.func.isRequired,
  /**
   * render function without current user
   * (user, onLogOut) => {}
   */
  renderUnauth: PropTypes.func,
  /**
   * disables fadein animation
   */
  disableAnimation: PropTypes.bool,
};

export default WithAuth;
