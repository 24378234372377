import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import debounce from 'lodash/debounce';

import useDesignsStore from './designsStore';
import { DEFAULT_ZOOM, HIGH_RES_WARNING_DISMISSED } from '../global/constants';
import { DESIGN_SAVE } from '../global/events';

const ZOOM_UPDATE_DELAY = 500;

/**
 * store to handle configuration options of menus
 */
export const menuStore = createVanilla((set, get) => ({
  templates: 'logo',
  elements: 'shapes',
  layers: false,
  updateTemplateId: false,
  submitDesign: null,
  blockSubmitDesign: false,
  rightMenuState: {
    open: false,
    activeObjectPanel: null,
    activeGeneralPanel: null,
    showAllFilters: false,
  },
  sharePanel: false,
  tooComplexSVGModalVisible: false,

  zoom: DEFAULT_ZOOM.toFixed(2),
  setZoomDebounced: debounce((zoom) => set({ zoom }), ZOOM_UPDATE_DELAY),

  // share url to show a modal when opening a share failed
  shareUrl: null,

  showHighResolutionWarning: false,
  highResolutionWarningDismissed: !!localStorage.getItem(
    HIGH_RES_WARNING_DISMISSED
  ),

  setShareUrl: (shareUrl) => set({ shareUrl }),

  // If false, artboard ignores all hotkeys
  artboardHotkeysEnabled: true,

  showObjectWidgets: true,

  dispatch: () => {},

  /*
    Current working mode.
    @type {WorkingMode}
   */
  workingMode: 'default',

  downloadPanel: false,

  /**
   * function to show the submit modal
   */
  showSubmitModal: async () => {
    const design = await useDesignsStore.getState().getActive();

    if (design) {
      set({
        submitDesign: design,
      });
    }

    if (!get().saved || !design) {
      // TODO: this can cause a bunch of saves at the same time, leading to race conditions.
      // Ideally we need to introduce a save queue
      get().dispatch(DESIGN_SAVE, {
        cb: async (response) => {
          if (!response?.id) return;
          await get().checkCanSubmitDesign(response);
          set({
            submitDesign: response,
          });
        },
      });
    } else {
      await get().checkCanSubmitDesign(design);
    }
  },

  canSubmitDesign: (design) => {
    const textObjects = design.state?.objects?.filter(
      (object) => object.type === 'pathText'
    );
    if (textObjects?.length) {
      const hasUserFonts = textObjects.find((text) => text.isUserFont);
      if (hasUserFonts) {
        return false;
      }
    }
    return true;
  },

  checkCanSubmitDesign: (design) => {
    if (!get().canSubmitDesign(design)) {
      set({ blockSubmitDesign: true });
    }
  },

  /**
   * function to hide the submit modal
   */
  hideSubmitModal: () => {
    set({
      submitDesign: null,
      blockSubmitDesign: false,
    });
  },

  // Code below handles all things related project saving state

  saving: false, // True when the design is currently being used is saved
  disableAutoSave: false, // True, when the design should no longer be auto saved
  autoSaved: false, // True when the last save was performed because of autosave
  // NOTE: it's possible for `saved` and `changed` to be false at the same time,
  // this happens while `saving` is true.
  saved: true, // False when there are unsaved changes
  changed: false, // True, when there are unsaved changed

  // Should be called every time there are changes to the project
  change: () => {
    set({
      changed: true,
      saved: false,
    });
  },
  saveStart: () => {
    set({
      // theoretically it shouldn't be possible for `saved` to be true on start of save, but we are being extra protective here
      saved: false,
      saving: true,
      changed: false,
    });
  },
  saveInterrupt: () => {
    set({
      saving: false,
      disableAutoSave: false,
    });
  },
  saveForbidden: () => {
    set({
      saving: false,
      disableAutoSave: true,
    });
  },
  saveFinish: (autoSaved) => {
    set({
      saving: false,
      // If there were any changes during the saving process, design is not really saved,
      // so we set it to false and autosave will run again after some time
      saved: !get().changed,
      autoSaved,
      disableAutoSave: false,
    });
  },
  // check if design needs saving(used to prevent autosaves after manual saves)
  needsSaving: () => {
    return !get().saved || get().changed;
  },

  setStyles: (styles) => {
    set({
      rightMenuActivePanel: null,
      styles,
    });
  },

  mergeRightMenuState: (state) =>
    set({
      rightMenuState: {
        ...get().rightMenuState,
        ...state,
      },
    }),

  getRightMenuActivePanel: () => {
    const {
      rightMenuState: { activeGeneralPanel, activeObjectPanel },
    } = get();
    return activeGeneralPanel || activeObjectPanel;
  },

  showTooComplexSVGModal: () => {
    set({ tooComplexSVGModalVisible: true });
  },

  hideTooComplexSVGModal: () => {
    set({ tooComplexSVGModalVisible: false });
  },

  setArtboardHotkeysEnabled: (enabled) => {
    set({ artboardHotkeysEnabled: enabled });
  },

  setUpdateTemplateId: (update) => {
    set({ updateTemplateId: update });
  },

  setLayers: (layers) => {
    set({ layers });
  },

  setShowObjectWidgets: (showObjectWidgets) => {
    set({ showObjectWidgets });
  },

  setShowHighResolutionWarning: (showHighResolutionWarning) => {
    set({ showHighResolutionWarning });
  },

  setHighResolutionWarningDismissed: (highResolutionWarningDismissed) => {
    localStorage.setItem(
      HIGH_RES_WARNING_DISMISSED,
      highResolutionWarningDismissed
    );
    set({ highResolutionWarningDismissed });
  },

  setWorkingMode: (value) => {
    set({
      workingMode: value,
    });
  },

  resetWorkingMode: () => {
    set({
      workingMode: 'default',
    });
  },

  setDownloadPanel: (downloadPanel) => {
    set({ downloadPanel });
  },

  // Background removal
  removingBackground: false,
  setRemovingBackground: (removingBackground) => {
    set({ removingBackground });
  },
}));

export const menuStoreSelector = {
  zoom: (state) => state.zoom,
  layers: (state) => state.layers,
  showSubmitModal: (state) => state.showSubmitModal,
  shareUrl: (state) => state.shareUrl,
  setShareUrl: (state) => state.setShareUrl,
  tooComplexSVGModalVisible: (state) => state.tooComplexSVGModalVisible,
  hideTooComplexSVGModal: (state) => state.hideTooComplexSVGModal,
  updateTemplateId: (state) => state.updateTemplateId,
  submitDesign: (state) => state.submitDesign,
  setArtboardHotkeysEnabled: (state) => state.setArtboardHotkeysEnabled,
  mergeRightMenuState: (state) => state.mergeRightMenuState,
  rightMenuState: (state) => state.rightMenuState,
  hideSubmitModal: (state) => state.hideSubmitModal,
  setUpdateTemplateId: (state) => state.setUpdateTemplateId,
  setLayers: (state) => state.setLayers,
  saving: (state) => state.saving,
  autoSaved: (state) => state.autoSaved,
  saved: (state) => state.saved,
  disableAutoSave: (state) => state.disableAutoSave,
  sharePanel: (state) => state.sharePanel,
  blockSubmitDesign: (state) => state.blockSubmitDesign,
  showObjectWidgets: (state) => state.showObjectWidgets,
  setShowObjectWidgets: (state) => state.setShowObjectWidgets,
  showHighResolutionWarning: (state) => state.showHighResolutionWarning,
  highResolutionWarningDismissed: (state) =>
    state.highResolutionWarningDismissed,
  setShowHighResolutionWarning: (state) => state.setShowHighResolutionWarning,
  setHighResolutionWarningDismissed: (state) =>
    state.setHighResolutionWarningDismissed,
  workingMode: (state) => state.workingMode,
  setWorkingMode: (state) => state.setWorkingMode,
  resetWorkingMode: (state) => state.resetWorkingMode,
  downloadPanel: (state) => state.downloadPanel,
  setDownloadPanel: (state) => state.setDownloadPanel,
  removingBackground: (state) => state.removingBackground,
  setRemovingBackground: (state) => state.setRemovingBackground,
};

export const useMenuStore = create(menuStore);
