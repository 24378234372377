import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../Button/IconButton';
import { Clickable, Close, Wrapper } from './styles';
import { closeIconTheme } from './theme';

/**
 * A banner on the top of the page that can be used to inform
 * the user about something.
 */
const Banner = (props) => {
  const { onClick, disabled } = props;
  const handleClick = useCallback(() => {
    if (disabled) return false;
    onClick && onClick();
  }, [onClick, disabled]);

  return (
    <Wrapper>
      <>
        {props.label}
        {props.onClick && (
          <Clickable
            onClick={handleClick}
            disabled={props.disabled}
            underline={props.underlineLabel}
          >
            {props.onClickLabel}
          </Clickable>
        )}
      </>
      {props.onClose && (
        <Close>
          <IconButton
            iconName={'close'}
            height={'10px'}
            onClick={props.onClose}
            theme={closeIconTheme}
          />
        </Close>
      )}
    </Wrapper>
  );
};

Banner.propTypes = {
  /**
   * label on the banner
   */
  label: PropTypes.string,
  /**
   * whether the label is underlined
   */
  underlineLabel: PropTypes.bool,
  /**
   * label for a clickable element on the banner
   */
  onClickLabel: PropTypes.string,
  /**
   * function that is triggered when the clickable element is clicked
   */
  onClick: PropTypes.func,
  /**
   * the onClick function is disabled
   */
  disabled: PropTypes.bool,
  /**
   * function that is triggered when the close icon is clicked
   */
  onClose: PropTypes.func,
};

export default Banner;
