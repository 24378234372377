import styled from 'styled-components';

import { themeStyle } from '../../services/theming';

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: ${themeStyle.radiusMedium};
  transition: box-shadow ${themeStyle.transitionDuration};

  h6 {
    color: ${themeStyle.varInkMedium};
    transition: color ${themeStyle.transitionDuration};
  }

  :after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 1px solid ${themeStyle.varInkLight};
    border-radius: ${themeStyle.radiusMedium};
    opacity: 1;
    transition: opacity ${themeStyle.transitionDuration};
  }

  &:hover {
    box-shadow: ${themeStyle.shadowSmallHeavy};

    h6 {
      color: ${themeStyle.varInkMain};
    }

    :after {
      opacity: 0;
    }
  }
`;

export const InnerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  overflow: hidden;

  :hover {
    border-radius: ${themeStyle.radiusMedium};
  }
`;

export const Color = styled.div.attrs(({ color }) => ({
  style: { background: color },
}))`
  height: 100%;
  width: 100%;
`;

export const Colors = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
`;

export const NameWrapper = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-left: 12px;
  box-sizing: border-box;
  background: ${themeStyle.varInkSuperLight};
  transition: color ease-in-out 0.25s;
`;
