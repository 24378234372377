const allowedProps = [
  'activeLayout',
  'width',
  'height',
  'unit',
  'fill',
  'opacity',
  'portrait',
];

const filterArtboardOptions = (opts) => {
  const filtered = {};
  Object.keys(opts).forEach((key) => {
    const val = opts[key];
    if (allowedProps.includes(key)) filtered[key] = val;
  });
  return filtered;
};

export default filterArtboardOptions;
