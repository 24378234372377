import styled from 'styled-components';

import { themeStyle } from '../../services/theming';
import { CustomStyles } from './types';

export const SelectWrapper = styled.div<{
  customStyles?: CustomStyles;
  small: boolean;
}>`
  box-sizing: border-box;
  width: ${({ customStyles }) => customStyles?.width ?? '100%'};
  font-family: ${themeStyle.fontFamily};
`;

export const SelectInnerWrapper = styled.div<{
  disabled: boolean;
  customStyles?: CustomStyles;
  small: boolean;
}>`
  box-sizing: border-box;
  width: 100%;
  height: ${({ customStyles, small }) =>
    customStyles?.height ?? (small ? '30px' : '40px')};
  position: relative;
  font-size: ${({ customStyles, small }) =>
    customStyles?.fontSize ??
    (small ? themeStyle.fontSize12 : themeStyle.fontSize14)};
  text-align: ${({ customStyles }) => customStyles?.textAlign ?? 'left'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const ErrorMessage = styled.span`
  margin-top: 4px;
  color: ${themeStyle.varAlertRed};
  font-size: ${themeStyle.fontSize11};
  font-weight: ${themeStyle.fontMedium};
`;
