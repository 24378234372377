import styled from 'styled-components';
import themeStyle from '../../../global/themeStyle';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 15px 18px;
`;

export const ContentWrapper = styled.div`
  margin-top: 25px;
`;

export const Label = styled.div`
  min-width: 100px;
  color: ${({ theme }) => (theme.color ? theme.color : 'inherit')};
  font-size: ${({ theme }) => (theme.fontSize ? theme.fontSize : 'inherit')};
`;

export const MockupTemplateControlsWrapper = styled.div<{
  $isMockupTemplateMode: boolean;
}>`
  border-bottom: ${({ $isMockupTemplateMode }) =>
    $isMockupTemplateMode ? `1px solid ${themeStyle.grey400}` : 'none'};
  border-top: ${({ $isMockupTemplateMode }) =>
    $isMockupTemplateMode ? `1px solid ${themeStyle.grey400}` : 'none'};
  padding: ${({ $isMockupTemplateMode }) =>
    $isMockupTemplateMode ? '0.5rem 0' : '0'};
  font-size: ${themeStyle.fontSizeLarge};

  > * {
    margin: 0.5rem 0;
  }

  label {
    display: block;
    min-width: 50%;
    max-width: 100%;

    input {
      display: block;
      min-width: 40px;
      max-width: 80%;
      margin: 0.5rem 0 0 1rem;
    }
  }
`;
