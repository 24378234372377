import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { fadeIn } from '../../utilities/styles';
import { P3 } from '../../utilities/Typography/styles';

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 7px;
  right: 7px;
  top: 7px;
  pointer-events: none;
  position: absolute;
  opacity: 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
`;

export const OuterWrapper = styled.div.attrs(
  ({ elementWidth, elementHeight }) => ({
    style: {
      width: elementWidth ? `${elementWidth}px` : '100%',
      height: elementHeight ? `${elementHeight}px` : '100%',
    },
  })
)`
  position: relative;

  &:hover ${ButtonRow} {
    opacity: 100;
  }

  animation: ${fadeIn};
  animation-duration: 1s;

  -webkit-touch-callout: none;
  touch-action: pan-x pan-y;
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: ${themeStyle.radiusMedium};
  cursor: pointer;
`;

export const InfoText = styled(P3)`
  color: ${themeStyle.inkMedium};

  a {
    color: ${themeStyle.black};
    font-weight: ${themeStyle.fontSemiBold};
    cursor: pointer;
    text-decoration: none;
  }
`;
