function LayerSelection() {
  // We store range objects that determine which indexes of a list are selected
  // [start, end + 1] means that all elements from { start } to { end } (inclusive) are selected
  this.ranges = [];

  // Pivot (index) that is used to perform bulk selection
  this.pivot = null;
}

LayerSelection.prototype.reset = function () {
  this.ranges = [];
  this.pivot = null;
};

// Returns true if two ranges are disjoint
LayerSelection.prototype.disjointRanges = function (range1, range2) {
  return range1[1] <= range2[0] || range2[1] <= range1[0];
};

// Gets the range object for an index
// Will be defined if an index is part of a selected range
LayerSelection.prototype.getRange = function (index) {
  return this.ranges.find((r) => index < r[1] && index >= r[0]);
};

// Select element at index normally, resets state
LayerSelection.prototype.normalSelect = function (index) {
  this.ranges = [[index, index + 1]];
  this.pivot = index;
};

// Splits a range in two based on an index
LayerSelection.prototype.splitRange = function (range, index) {
  const left = [range[0], index];
  const right = [index + 1, range[1]];
  if (left[0] === left[1]) return [right];
  if (right[0] === right[1]) return [left];
  return [left, right];
};

// Prunes ranges
// Meaning:
//  Every range 'a' of length 1 that touches some other range 'b' (of any length)
//  will be merged with range b
// i.e this.ranges = [[1,5], [5, 6]] ---> this.prune() ---> this.ranges = [[1, 6]]
LayerSelection.prototype.prune = function () {
  const tryMerge = (singleElement) => {
    const singleElementIndex = singleElement[0];
    return this.ranges.some((range) => {
      if (singleElementIndex === range[0] - 1) {
        if (this.pivot === range[0]) {
          this.pivot = singleElementIndex;
        }
        range[0] = singleElementIndex;
        return true;
      }

      if (singleElementIndex === range[1]) {
        if (this.pivot === range[1] - 1) {
          this.pivot = singleElementIndex;
        }
        range[1] = singleElementIndex + 1;
        return true;
      }

      return false;
    });
  };

  let pruned = false;
  for (let i = 0; i < this.ranges.length; i++) {
    const range = this.ranges[i];
    if (range[1] - range[0] !== 1) continue;

    if (tryMerge(range)) {
      pruned = true;
      this.ranges[i] = null;
    }
  }

  this.ranges = this.ranges.filter((r) => r);
  return pruned;
};

// Perform selection using multi select policy
// i.e add element at index to new selection, if possible
LayerSelection.prototype.multiSelect = function (index) {
  // Check if element is in some range
  const range = this.getRange(index);

  if (range) {
    // In this case, deselect the element
    if (range[1] - range[0] === 1) {
      // If only one element in range, delete it
      this.ranges = this.ranges.filter((r) => r[0] !== range[0]);

      // Update pivot if needed
      // Use last range
      if (this.pivot === index) {
        this.pivot = this.ranges.length
          ? this.ranges[this.ranges.length - 1][0]
          : null;
      }
    } else {
      // Split the range
      const indexOfRange = this.ranges.findIndex((r) => r[0] === range[0]);
      const splittedRange = this.splitRange(range, index);
      this.ranges = [
        ...this.ranges.slice(0, indexOfRange),
        ...splittedRange,
        ...this.ranges.slice(indexOfRange + 1, this.ranges.length),
      ];

      // Update pivot if needed
      if (this.pivot === index) {
        this.pivot = splittedRange[0][0];
      }
    }
  } else {
    // Select the element
    this.ranges.push([index, index + 1]);

    // Multi selection can update pivot
    if (!this.prune()) {
      this.pivot = index;
    }
  }
};

LayerSelection.prototype.bulkSelect = function (index) {
  if (this.pivot === null) {
    // If there is no pivot, normal select
    this.normalSelect(index);
    return;
  }

  if (
    index === this.pivot &&
    this.ranges.length === 1 &&
    this.ranges[0][1] - this.ranges[0][0] === 1
  ) {
    // If index is the same as the only selected element, reset
    this.reset();
    return;
  }

  const pivotRange = this.getRange(this.pivot);
  const pivotRangeStart = pivotRange[0];
  const pivotRangeEnd = pivotRange[1];

  if (this.pivot === pivotRangeStart) {
    if (index < pivotRangeStart) {
      pivotRange[0] = index;
      pivotRange[1] = pivotRangeStart + 1;
    } else {
      pivotRange[1] = index + 1;
    }
  } else {
    if (index >= pivotRangeEnd) {
      pivotRange[0] = pivotRangeEnd - 1;
      pivotRange[1] = index + 1;
    } else {
      pivotRange[0] = index;
    }
  }

  this.ranges = this.ranges.filter(
    (r) => r === pivotRange || this.disjointRanges(r, pivotRange)
  ); //---> pivotRange is not disjoint with itself
};

LayerSelection.prototype.toSelectionList = function () {
  const rangeToList = (range) => {
    const list = [];
    for (let i = range[0]; i < range[1]; i++) {
      list.push(i);
    }
    return list;
  };

  return this.ranges.reduce((acc, range) => {
    return [...acc, ...rangeToList(range.sort((a, b) => a - b))];
  }, []);
};

export default LayerSelection;
