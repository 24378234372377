import { Point } from '../../../../types';
import Bezier from '../../../../helpers/Bezier';
import {
  areIdenticalPoints,
  getDistance,
} from '../../../../utils/geometry/point';
import {
  getInterpolatedAngle,
  getMovedPointAlongDirection,
} from '../../../../utils/geometry/vector';

export interface WarpGridUnitPoints {
  tl: {
    root: Point;
    handles: {
      bottom: Point;
      right: Point;
    };
  };
  tr: {
    root: Point;
    handles: {
      left: Point;
      bottom: Point;
    };
  };
  br: {
    root: Point;
    handles: {
      top: Point;
      left: Point;
    };
  };
  bl: {
    root: Point;
    handles: {
      right: Point;
      top: Point;
    };
  };
}

export interface UnitDivision {
  row: number;
  col: number;
}

interface WarpGridUnitOptions {
  points: WarpGridUnitPoints;
  division: {
    row: number;
    col: number;
  };
}

interface BoundingCurves {
  top: Bezier;
  left: Bezier;
  right: Bezier;
  bottom: Bezier;
}

interface DivisionCurves {
  horizontal: Bezier[];
  vertical: Bezier[];
}

interface VerticesOnBoundingCurves {
  top: Point[];
  left: Point[];
  right: Point[];
  bottom: Point[];
}

/*
  Get n points inside a Bezier curve that are equally spaced in length.
 */
const getNPointsInsideCurve = (curve: Bezier, n: number): Point[] => {
  const points: Point[] = [];

  for (let i = 0; i < n; i++) {
    points.push(curve.getPointAtLengthRatio((i + 1) / (n + 1)));
  }

  return points;
};

const getInterpolatedCurveHandles = (
  curveA: Bezier,
  curveB: Bezier,
  left: Point,
  right: Point,
  ratio: number
): { left: Point; right: Point } => {
  const leftA = curveA.points[0];
  const leftHandleA = curveA.points[1];
  const rightHandleA = curveA.points[2];
  const rightA = curveA.points[3];
  const leftHandleALength = getDistance(leftA, leftHandleA);
  const rightHandleALength = getDistance(rightHandleA, rightA);
  const leftB = curveB.points[3];
  const leftHandleB = curveB.points[2];
  const rightHandleB = curveB.points[1];
  const rightB = curveB.points[0];
  const leftHandleBLength = getDistance(leftB, leftHandleB);
  const rightHandleBLength = getDistance(rightHandleB, rightB);

  const leftHandleAngle = getInterpolatedAngle(
    leftA,
    areIdenticalPoints(leftA, leftHandleA) ? rightHandleA : leftHandleA,
    leftB,
    areIdenticalPoints(leftB, leftHandleB) ? rightHandleB : leftHandleB,
    ratio
  );
  const rightHandleAngle = getInterpolatedAngle(
    rightA,
    areIdenticalPoints(rightA, rightHandleA) ? leftHandleA : rightHandleA,
    rightB,
    areIdenticalPoints(rightB, rightHandleB) ? leftHandleB : rightHandleB,
    ratio
  );
  const leftHandleLength =
    leftHandleALength + ratio * (leftHandleBLength - leftHandleALength);
  const leftHandle = getMovedPointAlongDirection(
    left,
    leftHandleAngle,
    leftHandleLength,
    true
  );
  const rightHandleLength =
    rightHandleALength + ratio * (rightHandleBLength - rightHandleALength);
  const rightHandle = getMovedPointAlongDirection(
    right,
    rightHandleAngle,
    rightHandleLength,
    true
  );

  return {
    left: leftHandle,
    right: rightHandle,
  };
};

/**
 * A class representing a warp grid unit, i.e. a sub division of a warp grid.
 */
export default class WarpGridUnit {
  /*
    The points make up the unit
   */
  points: WarpGridUnitPoints;
  /*
    Count of divisions in the unit
   */
  division: UnitDivision;
  /*
    The bounding curves of the unit. Useful when generating vertices.
   */
  boundingCurves: BoundingCurves;
  /*
    The vertices *inside* bounding curves. Useful when generating interpolated division curves.
   */
  verticesInsideBoundingCurves: VerticesOnBoundingCurves;
  /*
    Interpolated division curves. Useful when getting division intersections.
   */
  divisionCurves: DivisionCurves;
  /*
    Intersections of the division curves, namely vertices *inside* the unit
    (not including vertives on the bounding curves).
   */
  divisionIntersections: Point[][];
  /*
    All vertices in the unit.
   */
  vertices: Point[][];

  constructor({ points, division }: WarpGridUnitOptions) {
    this.points = points;
    this.division = division;
    this.boundingCurves = this.getBoundingCurves();
    this.verticesInsideBoundingCurves = this.getVerticesInsideBoundingCurves();
    this.divisionCurves = this.getDivisionCurves();
    this.divisionIntersections = this.getPseudoDivisionIntersections();
    this.vertices = this.getVertices();
  }

  getBoundingCurves(): BoundingCurves {
    return {
      top: new Bezier(
        this.points.tl.root,
        this.points.tl.handles.right,
        this.points.tr.handles.left,
        this.points.tr.root
      ),
      right: new Bezier(
        this.points.tr.root,
        this.points.tr.handles.bottom,
        this.points.br.handles.top,
        this.points.br.root
      ),
      bottom: new Bezier(
        this.points.br.root,
        this.points.br.handles.left,
        this.points.bl.handles.right,
        this.points.bl.root
      ),
      left: new Bezier(
        this.points.bl.root,
        this.points.bl.handles.top,
        this.points.tl.handles.bottom,
        this.points.tl.root
      ),
    };
  }

  getVerticesInsideBoundingCurves(): VerticesOnBoundingCurves {
    return {
      top: getNPointsInsideCurve(
        this.boundingCurves.top,
        this.division.col - 1
      ),
      right: getNPointsInsideCurve(
        this.boundingCurves.right,
        this.division.row - 1
      ),
      bottom: getNPointsInsideCurve(
        this.boundingCurves.bottom,
        this.division.col - 1
      ),
      left: getNPointsInsideCurve(
        this.boundingCurves.left,
        this.division.row - 1
      ),
    };
  }

  getDivisionCurves(): DivisionCurves {
    const { row, col } = this.division;

    const horizontalCurves: Bezier[] = [];
    for (let i = 0; i < row - 1; i++) {
      const right = this.verticesInsideBoundingCurves.right[i];
      const left = this.verticesInsideBoundingCurves.left[row - 2 - i];
      const { left: leftHandle, right: rightHandle } =
        getInterpolatedCurveHandles(
          this.boundingCurves.top,
          this.boundingCurves.bottom,
          left,
          right,
          (i + 1) / row
        );
      const curve = new Bezier(left, leftHandle, rightHandle, right);
      horizontalCurves.push(curve);
    }

    const verticalCurves: Bezier[] = [];
    for (let i = 0; i < col - 1; i++) {
      const right = this.verticesInsideBoundingCurves.top[i];
      const left = this.verticesInsideBoundingCurves.bottom[col - 2 - i];
      const { left: leftHandle, right: rightHandle } =
        getInterpolatedCurveHandles(
          this.boundingCurves.left,
          this.boundingCurves.right,
          left,
          right,
          (i + 1) / col
        );
      const curve = new Bezier(left, leftHandle, rightHandle, right);
      verticalCurves.push(curve);
    }

    return {
      horizontal: horizontalCurves,
      vertical: verticalCurves,
    };
  }

  /*
    The original method used in early-staged research for getting intersections.
    It's deprecated and only kept for reference,
    for its lack of performance and the drawback of incorrect result sometimes.
   */
  _getDivisionIntersections(): Point[][] {
    const intersections: Point[][] = [];

    for (let i = 0; i < this.division.row - 1; i++) {
      const intersectionsRow = [];

      for (let j = 0; j < this.division.col - 1; j++) {
        const curve1 = this.divisionCurves.horizontal[i];
        const intersectionsRaw = curve1.intersects(
          this.divisionCurves.vertical[j]
        );
        let intersection = { x: 0, y: 0 };

        intersectionsRaw.forEach((intersectionString) => {
          const [t1, t2] = String(intersectionString)
            .split('/')
            .map((str) => Number(str));

          if (t1 > 0 && t1 < 1 && t2 > 0 && t2 < 1) {
            intersection = curve1.get(t1);
          }
        });

        if (intersectionsRaw.length === 0) {
          console.warn(
            'no intersections found for curves: ',
            this.divisionCurves.horizontal[i],
            this.divisionCurves.vertical[j]
          );
        }

        intersectionsRow.push(intersection);
      }

      intersections.push(intersectionsRow);
    }

    return intersections;
  }

  /*
    Compared to the original method involving the calculation of two bezier curves,
    this function gets the "pseudo" intersection by interpolation, which is way more performant.
    This can be close enough when we have fine enough divisions; which we do.
   */
  getPseudoDivisionIntersections(): Point[][] {
    const intersections: Point[][] = [];

    for (let i = 0; i < this.division.row - 1; i++) {
      const intersectionsRow = getNPointsInsideCurve(
        this.divisionCurves.horizontal[i],
        this.division.col - 1
      );

      intersections.push(intersectionsRow);
    }

    return intersections;
  }

  /*
    Get all vertices by stitching parts together.
   */
  getVertices(): Point[][] {
    const vertices = [];

    for (let i = 0; i < this.division.row + 1; i++) {
      const pointsOnRow = [];

      if (i === 0) {
        pointsOnRow.push(this.points.tl.root);
        pointsOnRow.push(...this.verticesInsideBoundingCurves.top);
        pointsOnRow.push(this.points.tr.root);
      } else if (i === this.division.row) {
        pointsOnRow.push(this.points.bl.root);
        pointsOnRow.push(
          ...[...this.verticesInsideBoundingCurves.bottom].reverse()
        );
        pointsOnRow.push(this.points.br.root);
      } else {
        pointsOnRow.push(
          this.verticesInsideBoundingCurves.left[this.division.row - 1 - i]
        );

        for (let j = 0; j < this.division.col - 1; j++) {
          pointsOnRow.push(this.divisionIntersections[i - 1][j]);
        }

        pointsOnRow.push(this.verticesInsideBoundingCurves.right[i - 1]);
      }

      vertices.push(pointsOnRow);
    }

    return vertices;
  }
}
