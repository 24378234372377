import * as THREE from 'three';

import { Point } from '../../../../types';
import WarpGridUnit, { UnitDivision, WarpGridUnitPoints } from './WarpGridUnit';

/*
  The key should following the pattern:
    - ${row}_${col}, the root points
    - or ${row}_${col}_${'left' | 'right' | 'top' | 'bottom'}, the handle points
 */
type WarpControlPoints = Record<string, Point>;

interface WarpGridOptions {
  row: number;
  col: number;
  points: WarpControlPoints;
}

export interface GeometryArea {
  start: {
    i: number;
    j: number;
  };
  end: {
    i: number;
    j: number;
  };
}

/**
 * A class representing a warp grid, which can be used to create geometries inside it.
 * A warp grid consists of (row * col) warp grid units;
 * this unitization is needed for a more effective triangulation of the warp grid area,
 * as in each warp grid unit all its 4 sides are cubic Bezier curves.
 */
export default class WarpGrid {
  row: number;
  col: number;
  /*
    The points that make up the grid.
   */
  points: WarpControlPoints;
  /*
    Further division in each warp grid unit for triangulation.
   */
  unitDivision: UnitDivision;
  /*
    All the vertices in the warp grid as a result of triangulation.
    Used when creating geometries.
   */
  vertices: Point[][];

  constructor({ row, col, points }: WarpGridOptions) {
    this.row = row;
    this.col = col;
    this.points = points;
    this.unitDivision = this.getUnitDivision();
    this.vertices = this.getVertices();
  }

  get totalDivision(): { row: number; col: number } {
    return {
      row: this.unitDivision.row * this.row,
      col: this.unitDivision.col * this.col,
    };
  }

  getUnitDivision(): UnitDivision {
    const { minX, minY, maxX, maxY } = this.getEstimatedBoundary();
    // Each division has a size of 20px*20px.
    // We can have pretty good results (design is still smooth),
    // and nice performance
    // (less than 100ms to get all vertices with a ~3000px*3000px grid,
    // and 1ms to create a geometry).
    const divisionSize = 20;
    return {
      row: Math.round((maxY - minY) / this.row / divisionSize),
      col: Math.round((maxX - minX) / this.col / divisionSize),
    };
  }

  /*
    Get the estimated boundary of the warp grid from the points.
    This is used when calculating the unit division.
    We only need a rough value for that purpose so here we are iterating through the points,
    rather than calculating the exact boundary consisted of 4 complex Bezier curve,
    which can take a lot of time.
   */
  getEstimatedBoundary(): {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
  } {
    const xValues: number[] = [];
    const yValues: number[] = [];
    Object.entries(this.points).forEach(([key, value]) => {
      if (key.match(/\d+_\d+/)) {
        xValues.push(value.x);
        yValues.push(value.y);
      }
    });
    return {
      minX: Math.min(...xValues),
      minY: Math.min(...yValues),
      maxX: Math.max(...xValues),
      maxY: Math.max(...yValues),
    };
  }

  /*
    Get all vertices by stitching those in warp grid units together.
   */
  getVertices(): Point[][] {
    const vertices: Point[][] = [];

    for (let i = 0; i < this.row; i++) {
      for (let j = 0; j < this.col; j++) {
        const unitPoints = this.getUnitPoints(i, j);
        const unit = new WarpGridUnit({
          points: unitPoints,
          division: this.unitDivision,
        });
        const verticesOfUnit = unit.getVertices();
        for (let k = 0; k < verticesOfUnit.length; k++) {
          // do not push the first row of unit unless it's the first row in all
          if (k === 0 && i !== 0) continue;

          const verticesOfUnitOnRow = verticesOfUnit[k];
          const rowInGrid = i * (verticesOfUnit.length - 1) + k;

          if (!vertices[rowInGrid]) {
            vertices[rowInGrid] = [];
          }

          vertices[rowInGrid].push(
            ...verticesOfUnitOnRow.slice(j === 0 ? 0 : 1)
          );
        }
      }
    }

    return vertices;
  }

  /*
    Get the points for a single warp grid unit
   */
  getUnitPoints(i: number, j: number): WarpGridUnitPoints {
    const topLeftRoot = this.points[`${i}_${j}`];
    const topRightRoot = this.points[`${i}_${j + 1}`];
    const bottomRightRoot = this.points[`${i + 1}_${j + 1}`];
    const bottomLeftRoot = this.points[`${i + 1}_${j}`];

    return {
      tl: {
        root: topLeftRoot,
        handles: {
          bottom: this.points[`${i}_${j}_bottom`] || topLeftRoot,
          right: this.points[`${i}_${j}_right`] || topLeftRoot,
        },
      },
      tr: {
        root: topRightRoot,
        handles: {
          left: this.points[`${i}_${j + 1}_left`] || topRightRoot,
          bottom: this.points[`${i}_${j + 1}_bottom`] || topRightRoot,
        },
      },
      br: {
        root: bottomRightRoot,
        handles: {
          top: this.points[`${i + 1}_${j + 1}_top`] || bottomRightRoot,
          left: this.points[`${i + 1}_${j + 1}_left`] || bottomRightRoot,
        },
      },
      bl: {
        root: bottomLeftRoot,
        handles: {
          right: this.points[`${i + 1}_${j}_right`] || bottomLeftRoot,
          top: this.points[`${i + 1}_${j}_top`] || bottomLeftRoot,
        },
      },
    };
  }

  /*
    Get the vertex on row i and col j.
   */
  getVertex(i: number, j: number): Point {
    return this.vertices[i][j];
  }

  /*
    Get interpolated vertex using berycentric coords from a set of three other vertices that form a triangle.
   */
  getInterpolatedVertex(
    pointA: Point,
    pointB: Point,
    pointC: Point,
    offset: Point
  ): Point {
    const baryCoord = {
      a: offset.y,
      b: offset.x,
      c: 1 - offset.x - offset.y,
    };

    return {
      x:
        baryCoord.a * pointA.x +
        baryCoord.b * pointB.x +
        baryCoord.c * pointC.x,
      y:
        baryCoord.a * pointA.y +
        baryCoord.b * pointB.y +
        baryCoord.c * pointC.y,
    };
  }

  /*
    Create a geometry at given area.
   */
  createGeometry(
    area: GeometryArea = {
      start: {
        i: 0,
        j: 0,
      },
      end: {
        i: this.totalDivision.row,
        j: this.totalDivision.col,
      },
    }
  ): {
    geometry: THREE.BufferGeometry;
    boundary: {
      minX: number;
      minY: number;
      maxX: number;
      maxY: number;
    };
    corners: {
      tl: Point;
      tr: Point;
      bl: Point;
      br: Point;
    };
  } {
    const geometry = new THREE.BufferGeometry();
    const boundary = {
      minX: Infinity,
      maxX: -Infinity,
      minY: Infinity,
      maxY: -Infinity,
    };
    const corners = {} as {
      tl: Point;
      tr: Point;
      bl: Point;
      br: Point;
    };

    const indices = [];
    const vertices = [];
    const normals = [];
    const uvs = [];

    const rowCount = Math.ceil(area.end.i - area.start.i);
    const colCount = Math.ceil(area.end.j - area.start.j);

    for (let i = 0; i <= rowCount; i++) {
      for (let j = 0; j <= colCount; j++) {
        const row = area.start.i + (i * (area.end.i - area.start.i)) / rowCount;
        const col = area.start.j + (j * (area.end.j - area.start.j)) / colCount;
        const vertex = this.getInterpolatedVertex(
          this.getVertex(Math.ceil(row), Math.floor(col)),
          this.getVertex(Math.floor(row), Math.ceil(col)),
          this.getVertex(Math.floor(row), Math.floor(col)),
          {
            y: row % 1,
            x: col % 1,
          }
        );

        if (i === 0 && j === 0) {
          corners.tl = { ...vertex };
        } else if (i === 0 && j === colCount) {
          corners.tr = { ...vertex };
        } else if (i === rowCount && j === 0) {
          corners.bl = { ...vertex };
        } else if (i === rowCount && j === colCount) {
          corners.br = { ...vertex };
        }

        boundary.minX = Math.min(boundary.minX, vertex.x);
        boundary.maxX = Math.max(boundary.maxX, vertex.x);
        boundary.minY = Math.min(boundary.minY, vertex.y);
        boundary.maxY = Math.max(boundary.maxY, vertex.y);

        vertices.push(vertex.x, vertex.y, 0);

        const uv = {
          x: j / colCount,
          y: 1 - i / rowCount,
        };
        uvs.push(uv.x, uv.y);

        // normals do not matter, they all point to the z axis as it's a 2D surface
        normals.push(0, 0, 1);

        // push the indices of the two triangles inside the quadrilateral
        if (i < rowCount && j < colCount) {
          indices.push(
            i * (colCount + 1) + j,
            (i + 1) * (colCount + 1) + j + 1,
            i * (colCount + 1) + j + 1
          );
          indices.push(
            i * (colCount + 1) + j,
            (i + 1) * (colCount + 1) + j,
            (i + 1) * (colCount + 1) + j + 1
          );
        }
      }
    }

    geometry.setIndex(indices);
    geometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(vertices, 3)
    );
    geometry.setAttribute(
      'normal',
      new THREE.Float32BufferAttribute(normals, 3)
    );
    geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));

    return {
      geometry,
      boundary,
      corners,
    };
  }
}
