import { useState } from 'react';

import { ImageContainer } from './styles';
import { PreviewImageProps } from './types';

/**
 * Preview image for elements in notifications
 * Before the image is loaded, a grey/white shimmering square is displayed
 */
const PreviewImage: React.FC<PreviewImageProps> = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <ImageContainer
      loaded={loaded}
      withPadding={props.withPadding}
      noMargin={props.noMargin}
    >
      <img
        alt={props.name}
        onLoad={(): void => {
          setLoaded(true);
        }}
        src={props.src}
      />
    </ImageContainer>
  );
};

export default PreviewImage;
