import fabric from '.';
import { isIpadOperatingSystem } from '../../../utils/detection';
import monitoring from '../../../services/monitoring';

// Handle initialization of image filter backend to support
// platforms with different limitations.

const MAX_TEXTURE_SIZE_BIG = 8192;
const MAX_TEXTURE_SIZE_MEDIUM = 4096;
const MAX_TEXTURE_SIZE_SMALL = 2048;

function initFilterBackend(fabric: fabric): void {
  if (isIpadOperatingSystem()) return; // Don't support filters on iPad for now
  if (fabric.enableGLFiltering && fabric.isWebglSupported) {
    const textureSizes = [
      MAX_TEXTURE_SIZE_SMALL,
      MAX_TEXTURE_SIZE_MEDIUM,
      MAX_TEXTURE_SIZE_BIG,
    ];

    const supportedTextureSizes = textureSizes.filter((ts) =>
      fabric.isWebglSupported(ts)
    );

    if (supportedTextureSizes.length) {
      fabric.WebGLBackends = supportedTextureSizes.map((ts, index) => {
        const backend = new fabric.WebglFilterBackend({ tileSize: ts });
        const { canvas } = backend;

        /**
         * Handling of context loss. See https://www.khronos.org/webgl/wiki/HandlingContextLost
         * If a backend canvas loses context, nullify it in the WebGLBackends so that it can't be used for
         * filtering.
         */
        canvas.addEventListener(
          'webglcontextlost',
          (event: WebGLContextEvent) => {
            monitoring.captureMessage(
              `WebGL context was lost for backend of size ${backend.tileSize}`
            );
            event.preventDefault();
            fabric.WebGLBackends[index] = null;
          },
          false
        );

        /**
         * When context is restored, dispose current backend (not sure if needed, seems to only be dealing with GC, but doesn't hurt),
         * and replace it with a new one.
         */
        canvas.addEventListener('webglcontextrestored', () => {
          backend.dispose();
          fabric.WebGLBackends[index] = new fabric.WebglFilterBackend({
            tileSize: backend.tileSize,
          });
        });

        return backend;
      });
    }
  }

  fabric.JSFilterBackend = new fabric.Canvas2dFilterBackend();
}

export default initFilterBackend;
