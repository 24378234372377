import { mapToRange } from '../../utils';

/**
 * convert a value from one range into other,
 * but keep 0 as the center of the range
 */
export const convertFilterValue = (
  value: number,
  rangeFrom: [number, number],
  rangeTo: [number, number]
): number => {
  if (value === 0) return 0;
  if (value < 0) {
    return mapToRange(value, [rangeFrom[0], 0], [rangeTo[0], 0]);
  }
  return mapToRange(value, [0, rangeFrom[1]], [0, rangeTo[1]]);
};
