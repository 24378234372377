import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import { Tags, CreatedByLabel } from './styles';
import ProfileImage from '../Profile/ProfileImage/ProfileImage';
import Button from '../Button/Button/Button';
import { primarySmallTheme, secondarySmallTheme } from '../Button/Button/theme';
import { getElementAuthor } from '../Profile/utils';

import useElementsStore, {
  elementStoreSelector,
} from '../../stores/elementsStore';
import Spacer from '../Spacer/Spacer';
import Tag from '../Tag/Tag';
import { H4 } from '../utilities/Typography/styles';
import { Divider, FlexRow, FlexColumn } from '../utilities/styles';
import { themeStyle } from '../../services/theming';

const ElementInfo = (props) => {
  const { element, elementType } = props;

  const author = getElementAuthor(element);

  const isBookmarked = useElementsStore(elementStoreSelector.isBookmarked);
  const bookmarked = isBookmarked(elementType, element.id);

  return (
    <FlexColumn>
      <Spacer h="6px" />
      <H4>{element.name}</H4>
      <Spacer h="16px" />
      <FlexRow alignItems="center">
        <ProfileImage user={author} theme={{ size: '35px' }} />
        <CreatedByLabel noTextOverflow>
          Created by&nbsp;
          <b
            onClick={() =>
              props.onUserNameClick && props.onUserNameClick(author.name)
            }
          >
            {author.name}
          </b>
        </CreatedByLabel>
      </FlexRow>
      <Spacer h="16px" />
      {element.tags.length !== 0 && (
        <Tags>
          {element.tags.map((tag, idx) => (
            <Tag
              key={tag.name}
              onClick={() => props.onTagClick && props.onTagClick(tag.name)}
            >
              {capitalize(tag.name)}
            </Tag>
          ))}
        </Tags>
      )}
      <Spacer h="16px" />
      <Divider color={themeStyle.varInkSuperLight} />
      <Spacer h="16px" />
      <Button
        isBlock
        label={`${bookmarked ? 'Remove ' : ''}Bookmark`}
        icon={{ name: 'bookmark' }}
        onClick={props.onBookmark}
        theme={secondarySmallTheme}
      />
      <Spacer h="8px" />
      <Button
        isBlock
        label="Use this Design"
        theme={primarySmallTheme}
        onClick={props.onUseTemplate}
      />
    </FlexColumn>
  );
};

ElementInfo.propTypes = {
  element: PropTypes.object,
  elementType: PropTypes.string,
  onUserNameClick: PropTypes.func,
  onTagClick: PropTypes.func,
  onBookmark: PropTypes.func,
  onUseTemplate: PropTypes.func,
};

export default ElementInfo;
