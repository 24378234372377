import { themeStyle } from '../../services/theming';
import { NumberInputTheme } from './types';

export const numberInputTheme: NumberInputTheme = {
  // size
  width: '68px',
  height: '30px',

  // text
  textAlign: 'unset',
  fontWeight: themeStyle.fontMedium,
  fontSize: themeStyle.fontSize12,
  color: themeStyle.varInkMedium,
  colorHover: themeStyle.varInkMain,
  colorDisabled: themeStyle.varInkLight,
  backgroundColor: themeStyle.varBackgroundAlt,
  borderColor: themeStyle.varInkLight,

  borderRadius: themeStyle.radiusMedium,
  inputMarginLeft: '8px',
  marginBottom: '0',
};

export const smallNumberInputTheme: Partial<NumberInputTheme> = {
  width: '62px',
  height: '28px',
};

export const largeNumberInputTheme: Partial<NumberInputTheme> = {
  height: '40px',
  width: '90px',
};
