import { buildImageProxyUrl } from './url';
import userApi from '../global/userApi';

enum ShareType {
  Link = 'Link',
  Mail = 'Mail',
  Facebook = 'Facebook',
  Pinterest = 'Pinterest',
  Twitter = 'Twitter',
  WhatsApp = 'WhatsApp',
  LinkedIn = 'LinkedIn',
}

interface ShareOptions {
  /**
   * title can be be used as subject in mails
   */
  title?: string;
  /**
   * a preview can be used for sharing on pinterest
   * preview will be turned into a full url
   */
  preview?: string;
  /**
   * url for an image for sharing on pinterest
   */
  image?: string;
  /**
   * when a design is shared, justCreated can be added to the link share
   * this will show a toast when opening the link
   */
  justCreated?: boolean;
}

export const buildFacebookURL = (link: string): string =>
  encodeURI(
    `https://www.facebook.com/dialog/share?app_id=${
      process.env.REACT_APP_FACEBOOK_APP_ID ||
      process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
    }&display=page&href=${link}&redirect_uri=${
      process.env.REACT_APP_WEBSITE_URL || process.env.NEXT_PUBLIC_WEBSITE_URL
    }`
  );

export const buildEMailURL = (link: string, designName?: string): string =>
  `mailto:?subject=Kittl - ${designName}&body=${encodeURI(link)}`;

export const buildWhatsappURL = (link: string): string =>
  `https://web.whatsapp.com/send?text=${encodeURI(link)}`;

export const buildPinterestURL = (link: string, preview?: string): string =>
  `https://www.pinterest.com/pin/create/link/?url=${encodeURI(link)}${
    preview ? `&media=${encodeURI(preview)}` : ''
  }`;

export const buildTwitterURL = (link: string): string =>
  `https://twitter.com/intent/tweet?url=${encodeURI(link)}`;

export const buildLinkedinUrl = (link: string): string =>
  `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;

/**
 * build a link for sharing
 */
export const getShareLink = (
  shareType: ShareType,
  link: string,
  shareOptions: ShareOptions = {}
): string => {
  switch (shareType) {
    case 'Link':
      return `${link}${shareOptions.justCreated ? '?created=true' : ''}`;
    case 'Mail':
      return buildEMailURL(link, shareOptions.title);
    case 'Facebook':
      return buildFacebookURL(link);
    case 'Pinterest':
      if (shareOptions.image)
        return buildPinterestURL(link, shareOptions.image);
      if (shareOptions.preview) {
        return buildPinterestURL(
          link,
          buildImageProxyUrl(shareOptions.preview, {
            width: 1200,
            height: 630,
            format: 'jpg',
          })
        );
      }
      return buildPinterestURL(link);
    case 'Twitter':
      return buildTwitterURL(link);
    case 'WhatsApp':
      return buildWhatsappURL(link);
    case 'LinkedIn':
      return buildLinkedinUrl(link);
    default:
      console.warn(
        `Got request to build a link for unsupported share type '${shareType}'.`
      );
      return '';
  }
};

/**
 * handle sharing a design
 */
export const handleShare = async (
  shareType: ShareType,
  shareOptions: ShareOptions & { designId?: string } = {},
  preview: string
): Promise<{ url: string; target: Window | null | true }> => {
  const share = await userApi.shareDesign({
    designId: shareOptions.designId,
    preview,
    editable: false,
    email: undefined,
    user: undefined,
  });
  if ('error' in share) return { url: '', target: null };
  const url = getShareLink(shareType, share.link, {
    title: shareOptions.title,
    image: shareOptions.image,
    preview: `api/${share.preview}`,
    justCreated: true,
  });

  const noopener = shareType === 'Link' ? [] : ['noopener'];
  const target = window.open(url, '_blank', ...noopener);

  return { url, target: shareType === 'Link' ? target : true };
};
