import fabric from '.';
import { IEvent } from 'fabric/fabric-impl';
import { findMaskTarget, tryMasking } from '../../../utils/masking';

interface MoveEvent extends IEvent<MouseEvent> {
  target?: fabric.Object & { canvas?: fabric.Canvas };
}

export default function (fabric: fabric, klass: fabric.Object): void {
  /**
   * extends an object so that it can be masked by a fabric.Mask object
   */
  fabric.util.object.extend(klass.prototype, {
    /**
     * id of the mask object that is used as a clipPath for this object
     * this is needed for the initialization, to connect the obejct with the mask
     * afterwards this should be the same as `this.clipPath?.id`
     */
    clipPathMaskId: null,

    /**
     * add an object from canvas as clipPath to this object
     * @param {string} id id of the object on canvas
     */
    addClipPath: function (id: string) {
      if (!this.canvas) return false;

      const clipObj = this.canvas
        .allObjects()
        .find((object: fabric.Object) => object.id === id);
      if (clipObj) {
        this.clipPathMaskId = id; // store a reference to the clip object
        this.clipPath = clipObj; // add the clipObject as a clipPath for this object
        this.clipPath.setIsClipPath(true); // store in the clip object the information that it is used for clipping
        this.dirty = true;
        this.trueRender = true;
        return true;
      }

      return false;
    },

    removeClipPath: function () {
      if (!this.canvas || !this.clipPathMaskId) return false;

      const clipObj = this.canvas
        .allObjects()
        .find((object: fabric.Object) => object.id === this.clipPathMaskId);

      this.clipPathMaskId = null;
      this.clipPath = null;
      this.dirty = true;
      this.trueRender = this.objectCaching;
      if (clipObj) {
        clipObj.setIsClipPath(false);

        return true;
      }

      return false;
    },

    tryPreviewMaskOnMoving: function (event: MoveEvent) {
      // don't do anything if already masked
      if (this.clipPathMaskId || !this.canvas) return;

      if (!this.canvas.draggingObjectName) {
        // first create the preview object for dragging
        this.canvas.onStartDraggingElement(this.type, this.objectName, {
          ...this.toObject(),
          filters: [], // not adding filters here can improve performance massively
        });
        return;
      }

      this.canvas.onDraggingElement({
        dropX: event.e.clientX,
        dropY: event.e.clientY,
      });
    },

    tryMaskingOnMoved: function (event: MoveEvent) {
      const canvas = event.target?.canvas;
      if (!canvas) return;

      canvas.onStopDraggingElement();
      // don't do anything if already masked
      if (this.clipPathMaskId) return;

      const mask = findMaskTarget(
        { dropX: event.e.clientX, dropY: event.e.clientY },
        canvas
      );
      tryMasking(canvas, event.target, mask);
    },

    findClipPath: function () {
      // `clipPathMaskId` is a variable stored in this object that is a reference
      // to the id of an object that should be used as a clipPath for this one
      if (this.clipPathMaskId) {
        // when added to canvas, search for the clipping object and clip this group by it
        this.on('added', () => {
          const addedClipPath = this.addClipPath(this.clipPathMaskId);
          if (!addedClipPath && this.canvas) {
            // if the clipPath was not found yet, try it again when other objects are added to canvas
            this.canvas.on('object:modified', () => {
              if (!this.clipPath) {
                this.addClipPath(this.clipPathMaskId);
              }
            });
          }
        });
      }
    },
  });
}
