import { useCallback } from 'react';
import { userStoreSelector, useUserStore } from '../../stores/userStore';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import Icon from '../Icon/Icon';
import { FlexRow } from '../utilities/styles';
import { H6 } from '../utilities/Typography/styles';
import {
  Foreground,
  Grid,
  IconWrapper,
  ImageWrapper,
  TextWrapper,
  Wrapper,
  LoadingWrapper,
  SubText,
} from './styles';
import {
  promotionSelector,
  usePromotionStore,
} from '../../stores/promotionStore';
import { ACTIVE_REMOVE_BG } from '../../global/events';
import { Dispatcher } from '../../types';
import analytics from '../../global/analytics';
import { BACKGROUND_REMOVAL_CLICK } from '../../global/analytics/events';
import { SpinnerWrapper } from '../Icon/styles';
import { themeStyle } from '../../services/theming';

interface RemovalButtonProps {
  /**
   * event dispatch function
   */
  dispatch?: Dispatcher;
}

const BackgroundRemovalButton: React.FC<RemovalButtonProps> = ({
  dispatch,
}) => {
  const locked = !useUserStore(userStoreSelector.proAccess);
  const loading = useMenuStore(menuStoreSelector.removingBackground);
  const setLoading = useMenuStore(menuStoreSelector.setRemovingBackground);
  const textColor =
    locked || loading ? themeStyle.varInkMedium : themeStyle.varInkMain;

  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );

  const handleClick = useCallback(() => {
    if (locked) {
      showUpgradeModal('imageBackgroundRemoval');
    } else if (!loading && dispatch) {
      setLoading(true);
      analytics.track(BACKGROUND_REMOVAL_CLICK);
      dispatch(ACTIVE_REMOVE_BG);
    }
  }, [showUpgradeModal, locked, dispatch, setLoading, loading]);

  return (
    <Wrapper locked={locked} onClick={handleClick}>
      {loading ? (
        <LoadingWrapper>
          <SpinnerWrapper theme={{ spinnerColor: themeStyle.varBrandMain }}>
            <Icon name="loading" height="28px" />
          </SpinnerWrapper>
        </LoadingWrapper>
      ) : (
        <ImageWrapper>
          <Foreground />
          <Grid />
          <IconWrapper>
            <Icon
              name="starPairSolid"
              height="18px"
              theme={{ color: themeStyle.varBrandMain }}
            />
          </IconWrapper>
        </ImageWrapper>
      )}
      <TextWrapper>
        <FlexRow alignItems="center" justifyContent="center" gap="6px">
          <H6 color={textColor}>AI Background Remover</H6>
          {locked && <Icon name="premiumBadge" height="16px" />}
        </FlexRow>
        <SubText color={textColor}>
          {loading ? 'Loading...' : 'Click to remove image background'}
        </SubText>
      </TextWrapper>
    </Wrapper>
  );
};

export default BackgroundRemovalButton;
