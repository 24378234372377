import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  cloneElement,
  useRef,
  useContext,
  useImperativeHandle,
} from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { defaultTheme } from './theme';
import {
  PopoverWrapper,
  Content,
  PopoverTip,
  PopoverContainer,
  HeadWrapper,
} from './styles';
import Context from '../../components/context';
import PanelHeader from '../utilities/PanelHeader/PanelHeader';

const isPopoverFriendly = (node) => {
  while (node) {
    if (node.getAttribute) {
      const classes = node.getAttribute('class');
      if (classes && classes.split(' ').includes('popover-friend')) {
        return true;
      }
    }

    node = node.parentNode;
  }

  return false;
};

/**
 * The popover component is used to display content that is floating above the default layer.
 * It can be triggered by a target component, that is the only child of it.
 */
const Popover = ({
  open,
  placement,
  label,
  padding,
  margin,
  align,
  content,
  dataTestId,
  onMouseLeave,
  onClose,
  onWillClose,
  showHeader,
  width,
  hideTip,
  ignoreEventOutside,
  theme: _theme,
  useHover,
  onClick,
  onClickOutside,
  className,
  wrapperMargin,
  ...props
}) => {
  const context = useContext(Context);
  const theme = useMemo(() => ({ ...defaultTheme, ..._theme }), [_theme]);

  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({
    left: 0,
    top: 0,
    arrowPos: 0,
    isInPosition: false,
  });

  const [hoverTarget, setHoverTarget] = useState(false);
  const [hoverPopover, setHoverPopover] = useState(false);

  const targetRef = useRef(null);
  const popoverRef = useRef(null);

  // make the targetRef available via the ref prop
  useImperativeHandle(props.targetRef, () => ({
    current: targetRef?.current,
  }));

  const close = useCallback(() => {
    onWillClose && onWillClose();
    if (open) return false; // if popover is forced to stay open, don't close

    onClose && onClose();
    setIsOpen(false);
  }, [onClose, onWillClose, open]);

  const handleEventOutside = useCallback(
    (event) => {
      if (isPopoverFriendly(event.target)) return false;
      if (ignoreEventOutside) return false;
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        targetRef.current &&
        !targetRef.current.contains(event.target) &&
        document.contains(event.target) // an element might re-render when its clicked, so we check if it still exists
      ) {
        setHoverPopover(false);
        setHoverTarget(false);
        onClickOutside && onClickOutside();
        close();
      }
    },
    [close, ignoreEventOutside, onClickOutside]
  );

  useImperativeHandle(props.wrapperRef, () => popoverRef.current);

  const getContainer = useCallback(() => {
    return props.container?.current || context.getRootContainer();
  }, [props.container, context]);

  const calculatePosition = useCallback(
    (targetRect, popoverRect, containerRect) => {
      let left = 0;
      let top = 0;
      let arrowPos = 0;

      const arrowWidth = hideTip ? 0 : 10;
      const verticalArrowPadding = hideTip ? 0 : 20;
      const horizontalArrowPadding = hideTip ? 0 : 12;

      const placementIsVertical = placement === 'top' || placement === 'bottom';

      if (placementIsVertical) {
        if (placement === 'top') {
          top = targetRect.top - popoverRect.height - padding;
        } else {
          top = targetRect.bottom + padding;
        }

        if (align === 'center') {
          left = targetRect.left + targetRect.width / 2 - popoverRect.width / 2;
          arrowPos = popoverRect.width / 2 - arrowWidth / 2;
        } else if (align === 'start') {
          left = targetRect.left - horizontalArrowPadding;
          arrowPos =
            targetRect.width / 2 + arrowWidth / 2 + horizontalArrowPadding;
        } else {
          left =
            targetRect.left +
            targetRect.width -
            popoverRect.width +
            horizontalArrowPadding;
          arrowPos =
            popoverRect.width -
            targetRect.width / 2 -
            arrowWidth / 2 -
            horizontalArrowPadding;
        }
      } else {
        if (placement === 'right') {
          left = targetRect.right + padding;
        } else {
          left = targetRect.left - popoverRect.width - padding;
        }

        if (align === 'center') {
          top = targetRect.top + targetRect.height / 2 - popoverRect.height / 2;
          arrowPos = popoverRect.height / 2 - arrowWidth / 2;
        } else if (align === 'start') {
          top = targetRect.top - verticalArrowPadding;
          arrowPos =
            targetRect.height / 2 - arrowWidth / 2 + verticalArrowPadding;
        } else {
          top =
            targetRect.top +
            targetRect.height -
            popoverRect.height +
            verticalArrowPadding;
          arrowPos =
            popoverRect.height -
            targetRect.height / 2 -
            arrowWidth / 2 -
            verticalArrowPadding;
        }
      }

      top = top - containerRect.top;
      left = left - containerRect.left;

      // Keep popovers within window
      if (left > containerRect.width - popoverRect.width - wrapperMargin) {
        if (placementIsVertical) {
          arrowPos =
            arrowPos +
            (left - (containerRect.width - popoverRect.width - wrapperMargin));
        }
        left = containerRect.width - popoverRect.width - wrapperMargin;
      } else if (left < 0 + wrapperMargin) {
        if (placementIsVertical) {
          arrowPos = arrowPos + left + wrapperMargin;
        }
        left = 0 + wrapperMargin;
      }

      const visualViewport = window?.visualViewport;
      if (visualViewport) {
        // Don't adjust top property if the virtual keyboard is on screen
        const virtualKeyboard =
          visualViewport.height !== document.body.clientHeight;
        if (virtualKeyboard) {
          return [left.toFixed(), top.toFixed(), arrowPos.toFixed()];
        }
      }

      if (top > window.innerHeight - popoverRect.height - wrapperMargin) {
        if (!placementIsVertical) {
          arrowPos =
            arrowPos +
            (top - (window.innerHeight - popoverRect.height - wrapperMargin));
        }
        top = window.innerHeight - popoverRect.height - wrapperMargin;
      }

      if (top < 0 + wrapperMargin) {
        if (!placementIsVertical) {
          arrowPos = arrowPos + top + wrapperMargin;
        }
        top = 0 + wrapperMargin;
      }

      return [left.toFixed(), top.toFixed(), arrowPos.toFixed()];
    },
    [align, padding, placement, hideTip, wrapperMargin]
  );

  const updatePosition = useCallback(() => {
    if (!isOpen) {
      return false;
    }
    if (targetRef.current && popoverRef.current && getContainer()) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();
      const containerRect = getContainer().getBoundingClientRect();

      const [left, top, arrowPos] = calculatePosition(
        targetRect,
        popoverRect,
        containerRect
      );

      if (
        !position.isInPosition ||
        left !== position.left ||
        top !== position.top ||
        arrowPos !== position.arrowPos
      ) {
        setPosition({
          left: left,
          top: top,
          arrowPos: arrowPos,
          isInPosition: true,
        });
      }
    }
  }, [position, calculatePosition, getContainer, isOpen]);

  useEffect(() => {
    // Update position on re-render
    updatePosition();
    // Bind the event listener
    if (isOpen) {
      document.addEventListener('pointerdown', handleEventOutside);
      document.addEventListener('wheel', handleEventOutside);
      window.addEventListener('resize', updatePosition);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('pointerdown', handleEventOutside);
      document.removeEventListener('wheel', handleEventOutside);
      window.removeEventListener('resize', updatePosition);
    };
  }, [handleEventOutside, updatePosition, isOpen]);

  const popoverElement = useMemo(
    () => (
      <PopoverContainer className={className}>
        <PopoverWrapper
          style={{
            left: position.left + 'px',
            top: position.top + 'px',
          }}
          isInPosition={isOpen && position.isInPosition}
          ref={popoverRef}
          placement={placement}
          width={width}
          theme={theme}
          onMouseLeave={() => {
            setHoverPopover(false);
            onMouseLeave && onMouseLeave();
          }}
          onMouseEnter={() => setHoverPopover(true)}
          margin={margin}
        >
          {!hideTip && (
            <PopoverTip
              margin={margin}
              placement={placement}
              theme={theme}
              style={
                placement === 'top' || placement === 'bottom'
                  ? {
                      left: position.arrowPos + 'px',
                    }
                  : {
                      top: position.arrowPos + 'px',
                    }
              }
            />
          )}
          <Content theme={theme} data-testid={dataTestId}>
            {showHeader && (
              <HeadWrapper theme={theme}>
                <PanelHeader
                  theme={{ ...theme, padding: '0px' }}
                  label={label}
                  onClose={close}
                />
              </HeadWrapper>
            )}

            {content}
          </Content>
        </PopoverWrapper>
      </PopoverContainer>
    ),
    [
      placement,
      position,
      label,
      content,
      dataTestId,
      onMouseLeave,
      showHeader,
      width,
      close,
      isOpen,
      theme,
      hideTip,
      margin,
      className,
    ]
  );

  const renderPopover = useCallback(() => {
    if (isOpen) {
      return createPortal(popoverElement, getContainer());
    }
    return null;
  }, [isOpen, getContainer, popoverElement]);

  useImperativeHandle(props.toggleRef, () => ({ toggle }));

  const handleOnClick = (event) => {
    event.preventDefault();
    if (!useHover) {
      toggle(event);
    }

    // only trigger onClick on desktops when a mouse is used
    if (
      event.nativeEvent?.pointerType === 'mouse' ||
      [0, 1, 4].includes(event.nativeEvent?.mozInputSource) // firefox source is mouse or unknown
    ) {
      onClick && onClick(event);
    }
  };

  const openPopover = useCallback(() => {
    setIsOpen(true); // open
    props.onOpened && props.onOpened();

    // reset for animation
    setPosition((prev) => ({ ...prev, isInPosition: false }));
    renderPopover();
    updatePosition();
  }, [props, renderPopover, updatePosition]);

  useEffect(() => {
    if (!useHover) return;
    const isHover = hoverTarget || hoverPopover;
    if (!isOpen && isHover) {
      openPopover();
    }
    if (isOpen && !isHover) {
      close();
    }
  }, [isOpen, useHover, hoverTarget, hoverPopover, openPopover, close]);

  const toggle = (evt) => {
    evt && evt.stopPropagation();
    props.onToggle && props.onToggle();
    if (isOpen) {
      close();
      return;
    }

    openPopover();
  };

  useEffect(() => {
    if (open === undefined || isOpen === open) return;

    if (!open) {
      close();
      return;
    }
    setIsOpen(true); // open
    // reset for animation
    setPosition((prev) => ({ ...prev, isInPosition: false }));
    renderPopover();
    updatePosition();
  }, [open, close, updatePosition, renderPopover, isOpen]);

  if (!props.children) return null;

  return (
    <>
      {cloneElement(props.children, {
        onClick: handleOnClick,
        onMouseEnter: () => setHoverTarget(true),
        onMouseLeave: () => setHoverTarget(false),
        onTouchStart: () => setHoverTarget((prev) => !prev),
        isActive: isOpen,
        ref: targetRef,
      })}
      {renderPopover()}
    </>
  );
};

Popover.propTypes = {
  /**
   * Label of the popover
   */
  label: PropTypes.string,
  /**
   * Width of the popover
   */
  width: PropTypes.string,
  /**
   * On which side of the target should the popover be placed
   */
  placement: PropTypes.oneOf(['bottom', 'top', 'right', 'left']),
  /**
   * How should the popover be aligned in relation to the target
   */
  align: PropTypes.oneOf(['start', 'center', 'end']),
  /**
   * The content that is shown within the popover
   */
  content: PropTypes.element,
  /**
   * A function that is triggered when the popover is closed
   */
  onClose: PropTypes.func,
  /**
   * A function that is triggered before the popover might be closed
   */
  onWillClose: PropTypes.func,
  /**
   * ignore events outside the popover that would close it
   */
  ignoreEventOutside: PropTypes.bool,
  /**
   * A function that is triggered when the popover is opened
   */
  onOpened: PropTypes.func,
  /**
   * A function that is triggered when the mouse leaves the popover
   */
  onMouseLeave: PropTypes.func,
  /**
   * Padding (in px) between popover and target
   */
  padding: PropTypes.number,
  /**
   * margin around the popover
   */
  margin: PropTypes.string,
  /**
   * Don't render the tip
   */
  hideTip: PropTypes.bool,
  /**
   * Popover styles theme
   */
  theme: PropTypes.shape({
    backgroundColor: PropTypes.string,
    boxShadow: PropTypes.string,
    colorLabel: PropTypes.string,
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.number,
    fontSize: PropTypes.string,
    colorIcon: PropTypes.string,
    colorIconActive: PropTypes.string,
    borderRadius: PropTypes.string,
    padding: PropTypes.string,
    contentOverflowY: PropTypes.string,
  }),
  /**
   * Whether to show header or not
   */
  showHeader: PropTypes.bool,
  /**
   * a ref to the child object that is triggering the popover
   */
  targetRef: PropTypes.func,
  /**
   * a ref that contains the toggle function for special-case use
   */
  toggleRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * A ref to the wrapper element of the popover.
   * Useful to have popovers inside other popovers.
   */
  wrapperRef: PropTypes.object,
  /**
   * an id that can be used for testing
   * added as data-testid to the content of the popover
   */
  dataTestId: PropTypes.string,
  open: PropTypes.bool,
  useHover: PropTypes.bool,
  onClick: PropTypes.func,
  onToggle: PropTypes.func,
  children: PropTypes.node,
  container: PropTypes.object,
  onClickOutside: PropTypes.func,
  className: PropTypes.string,
  /**
   * a margin from the wrapper boundaries
   */
  wrapperMargin: PropTypes.number,
};

Popover.defaultProps = {
  label: 'Popover',
  width: '290px',
  placement: 'bottom',
  align: 'center',
  theme: defaultTheme,
  padding: 5,
  showHeader: true,
  margin: '0px',
};

export default Popover;
