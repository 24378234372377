import React from 'react';
import PropTypes from 'prop-types';

import EffectSelect from '../../EffectSelect/EffectSelect';
import RightMenuHeadline from '../../utilities/RightMenuHeader/RightMenuHeader';
import ColorPicker from '../../ColorPicker/ColorPicker';
import SliderInput from '../../SliderInput/SliderInput';

import { ACTIVE_COLOR, ACTIVE_SHADOW } from '../../../global/events';
import { config } from './config';
import { useOnboardingStore } from '../../../stores/onboardingStore';
import { ANCHOR_REF_KEYS } from '../../../stores/onboardingStore/constants';
import { ConfigurationPanel } from '../../EffectSelect/styles';

/**
 * The ShadingPanel
 */
const ShadingPanel = (props) => {
  const options = props.options || {};

  const updateOptions = (type, value, isChanging) => {
    if (type === 'color') {
      props.dispatch &&
        props.dispatch(ACTIVE_COLOR, { key: 'shadow', value, isChanging });
    } else if (type === 'borderColor') {
      props.dispatch &&
        props.dispatch(ACTIVE_COLOR, { key: 'stroke', value, isChanging });
    } else {
      const updatedShadow = {
        ...options,
        [type]: value,
        isChanging,
      };
      props.dispatch && props.dispatch(ACTIVE_SHADOW, updatedShadow);
    }
  };

  const colorPicker = (
    <ColorPicker
      defaultColor={options.color}
      onChanging={(value) => updateOptions('color', value, true)}
      onChanged={(value) => updateOptions('color', value, false)}
      presetColors={Object.keys(props.colorPalette)}
      dispatch={props.dispatch}
    />
  );

  const getConfigPanel = (item) => {
    const additionalColorPickers = [];
    if (item.isBorderColorConfigurable) {
      additionalColorPickers.push(
        <ColorPicker
          defaultColor={options.stroke}
          onChanging={(value) => updateOptions('borderColor', value, true)}
          onChanged={(value) => updateOptions('borderColor', value, false)}
          presetColors={Object.keys(props.colorPalette)}
          dispatch={props.dispatch}
          label="STROKE"
        />
      );
    }
    return (
      <ConfigurationPanel gap="14px">
        <RightMenuHeadline
          label={item.label}
          colorPickers={[...additionalColorPickers, colorPicker]}
          small
        />
        <div
          ref={(node) => {
            useOnboardingStore
              .getState()
              .registerStepRef(node, ANCHOR_REF_KEYS.SHADING_SLIDER);
          }}
        >
          {/**
           * we do +1, since we want to store values in [-1...99] but show them as [0...100]
           */}
          <SliderInput
            label={'Offset'}
            startValue={options.offset + 1}
            min={0}
            max={100}
            precision={0}
            step={1}
            onChanging={(value) => updateOptions('offset', value - 1, true)}
            onChanged={(value) => updateOptions('offset', value - 1, false)}
          />
        </div>
        <SliderInput
          label={'Angle'}
          unit={'°'}
          startValue={options.angle}
          min={-180}
          max={180}
          precision={0}
          step={1}
          onChanging={(value) => updateOptions('angle', value, true)}
          onChanged={(value) => updateOptions('angle', value, false)}
        />
        <SliderInput
          label={'Blur'}
          startValue={options.blur}
          min={0}
          max={100}
          precision={0}
          step={1}
          onChanging={(value) => updateOptions('blur', value, true)}
          onChanged={(value) => updateOptions('blur', value, false)}
          disabled={!item.hasBlur}
        />
        <SliderInput
          label={'Outline Width'}
          startValue={Math.round(options.outlineWidth)}
          min={0}
          max={100}
          precision={0}
          step={1}
          onChanging={(value) => updateOptions('outlineWidth', value, true)}
          onChanged={(value) => updateOptions('outlineWidth', value, false)}
          disabled={!item.hasOutline}
        />
      </ConfigurationPanel>
    );
  };

  const processedConfig = config.map(({ icon, disabled, ...item }) => {
    const configPanel = getConfigPanel(item);
    return {
      id: item.id,
      icon,
      disabled,
      configPanel,
      iconMapping: item.iconMapping,
    };
  });

  return (
    <EffectSelect
      label={'Shading'}
      useActiveOverlay={true}
      config={processedConfig}
      activeEffect={options.type}
      disabled={props.disabled}
      onSelect={(value) => updateOptions('type', value, false)}
    />
  );
};

ShadingPanel.propTypes = {
  /**
   * current effect options
   */
  options: PropTypes.shape({
    type: PropTypes.oneOf(['drop', 'line', 'block', 'detail']),
    offset: PropTypes.number,
    angle: PropTypes.number,
    blur: PropTypes.number,
    color: PropTypes.string,
  }),
  /**
   * Object containing information about the artboards color palette
   */
  colorPalette: PropTypes.object,
  /**
   * Whether the panel is disabled
   */
  disabled: PropTypes.bool,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
};

export default ShadingPanel;
