import Nprogress from 'nprogress';

/**
 * Control the progress bar within distributed, loose-coupled functions.
 * The initiator of the functions is responsible to define the progress after each function call,
 * while the funtions only need to tell the service when it's done.
 */
const distributedProgress = {
  stepProgress: [],
  completedSteps: 0,
  initialized: false,

  init(stepProgress) {
    this.stepProgress = stepProgress;
    this.completedSteps = 0;
    this.initialized = true;
  },

  start() {
    if (!this.initialized) return;

    Nprogress.start();
  },

  proceed() {
    if (!this.initialized) return;

    this.completedSteps++;
    Nprogress.set(this.stepProgress[this.completedSteps]);

    if (this.completedSteps === this.stepProgress.length - 1) {
      this.end();
    }
  },

  end() {
    if (!this.initialized) return;

    Nprogress.done();
    this.stepProgress = [];
    this.completedSteps = 0;
    this.initialized = false;
  },
};

export default distributedProgress;
