import {
  ADD_TEXT,
  DESELECT_OBJECTS,
  MAIN_SELECT,
  OPEN_DOWNLOAD_PANEL,
} from '../../global/events';
import { OnboardingStepConfig } from './types';
import { OnboardingState } from '../../types';

export const ANCHOR_REF_KEYS = {
  LEFT_MENU: 'leftMenu',
  STYLES_MENU: 'stylesMenu',

  ARTBOARD: 'artboard',

  TRANSFORMATION: 'transformationPanel',
  COLORS_PANEL: 'colorsPanel',
  DOWNLOAD_PANEL: 'downloadPanel',

  DECORATION_SLIDER: 'decorationSlider',
  SHADING_SLIDER: 'shadingSlider',

  TEMPLATES_BUTTON: 'templatesButton',
  ELEMENTS_BUTTON: 'elementsButton',
  COLORS_BUTTON: 'colorsButton',
  DOWNLOAD_BUTTON: 'downloadButton',

  SAVE: 'saveText',
};

export const ONBOARDING_USER_STEP: Record<
  OnboardingState,
  OnboardingStepConfig
> = {
  [OnboardingState.SignUp]: {
    name: OnboardingState.SignUp,
    index: 1,
    steps: [
      {
        index: 0,
      },
      {
        index: 1,
      },
    ],
  },
  [OnboardingState.Survey]: {
    name: OnboardingState.Survey,
    index: 2,
    steps: [{}],
  },
  [OnboardingState.ChooseTemplate]: {
    name: OnboardingState.ChooseTemplate,
    index: 3,
    label: 'Choose a template',
    openMenu: 'template',
    steps: [
      {
        description: 'Use free templates for private and commercial projects.',
        refs: [ANCHOR_REF_KEYS.LEFT_MENU, ANCHOR_REF_KEYS.TEMPLATES_BUTTON],
        displayIndex: 1,
      },
    ],
  },
  [OnboardingState.EditText]: {
    name: OnboardingState.EditText,
    index: 4,
    label: 'Select & edit text',
    openMenu: 'close',
    steps: [
      {
        label: 'Add text',
        icon: 'tButton',
        description: 'Press “T” to add text.',
        dispatch: { event: ADD_TEXT, value: { deselect: true } },
        refs: [ANCHOR_REF_KEYS.ARTBOARD],
        displayIndex: 2,
      },
      {
        description: 'Customise the template by adding your text.',
        dispatch: { event: MAIN_SELECT },
        refs: [ANCHOR_REF_KEYS.ARTBOARD],
        displayIndex: 2,
      },
    ],
  },
  [OnboardingState.TransformText]: {
    name: OnboardingState.TransformText,
    index: 5,
    label: 'Transform text',
    fallback: {
      label: 'Select text',
      description: 'Select text to add effects.',
      dispatch: { event: MAIN_SELECT },
      refs: [ANCHOR_REF_KEYS.ARTBOARD],
      displayIndex: 2,
    },
    steps: [
      {
        description: 'Use transformation tools to warp your text.',
        dispatch: { event: DESELECT_OBJECTS },
        refs: [ANCHOR_REF_KEYS.STYLES_MENU, ANCHOR_REF_KEYS.TRANSFORMATION],
        displayIndex: 3,
      },
    ],
  },
  [OnboardingState.AddElement]: {
    name: OnboardingState.AddElement,
    index: 6,
    label: 'Choose an element',
    openMenu: 'elements',
    steps: [
      {
        description:
          'Drag and drop high-quality illustrations and customize them to your needs.',
        dispatch: { event: DESELECT_OBJECTS, value: { colors: true } },
        refs: [ANCHOR_REF_KEYS.LEFT_MENU, ANCHOR_REF_KEYS.ELEMENTS_BUTTON],
        displayIndex: 4,
      },
    ],
  },
  [OnboardingState.ColorPalette]: {
    name: OnboardingState.ColorPalette,
    index: 7,
    label: 'Choose a color palette',
    openRightMenu: 'projectColors',
    steps: [
      {
        description:
          'Choose one of the trending color palettes or replace color by color.',
        dispatch: { event: OPEN_DOWNLOAD_PANEL },
        refs: [ANCHOR_REF_KEYS.COLORS_PANEL, ANCHOR_REF_KEYS.COLORS_BUTTON],
        displayIndex: 5,
      },
    ],
  },
  [OnboardingState.Download]: {
    name: OnboardingState.Download,
    index: 8,
    label: 'Download',
    steps: [
      {
        description:
          'Your design will be saved automatically but you can download them in preferred format.',
        refs: [ANCHOR_REF_KEYS.DOWNLOAD_PANEL, ANCHOR_REF_KEYS.DOWNLOAD_BUTTON],
        displayIndex: 6,
      },
    ],
  },
  // last step must have only one substep
  [OnboardingState.Complete]: {
    name: OnboardingState.Complete,
    index: 9,
    steps: [{}],
  },
  [OnboardingState.Skipped]: {
    name: OnboardingState.Skipped,
    index: 0,
    steps: [],
  },
  [OnboardingState.Mobile]: {
    name: OnboardingState.Mobile,
    index: 0,
    steps: [],
  },
};
