import React from 'react';
import PropTypes from 'prop-types';

import RightMenuHeadline from '../../utilities/RightMenuHeader/RightMenuHeader';
import Button from '../../Button/Button/Button';

import { EDIT_SHAPE } from '../../../global/events';

/**
 * The PolygonPanel
 */
const PolygonPanel = (props) => {
  return (
    <>
      <RightMenuHeadline label={'Polygon'} />
      <Button
        isBlock
        label={'Edit Polygon'}
        onClick={() => props.dispatch && props.dispatch(EDIT_SHAPE)}
      />
    </>
  );
};

PolygonPanel.propTypes = {
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
};

export default PolygonPanel;
