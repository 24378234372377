import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import {
  Content,
  Divider,
  OrWrapper,
  OtherProviders,
  PolicyLabel,
  SwitchModeLabel,
} from './styles';
import { forgotPasswordButtonTheme } from '../theme';

import Logo from '../../Logo/Logo';
import Button from '../../Button/Button/Button';
import Form from '../Form/Form';
import Spacer from '../../Spacer/Spacer';
import ProfileImage from '../../Profile/ProfileImage/ProfileImage';
import { H2, P2, P3 } from '../../utilities/Typography/styles';

import { themeStyle } from '../../../services/theming';
import { USER_API_URL } from '../../../global/userApi';
import { buildProfileImageUrl, buildWebsiteUrl } from '../../../utils/url';
import analytics from '../../../global/analytics';
import {
  SOCIAL_FACEBOOK,
  SOCIAL_GOOGLE,
} from '../../../global/analytics/events';
import {
  COOKIE_UTM_CAMPAIGN,
  IMPACT_CLICK_ID,
} from '../../../global/constants';
import { secondaryTheme } from '../../Button/Button/theme';

const getSocialQueryParameter = (props, rerequest) => {
  const params = [];
  if (props.inviteCode) {
    params.push(`invite=${props.inviteCode}`);
  } else if (props.referralCode) {
    params.push(`referral=${props.referralCode}`);
  } else if (props.promoCode) {
    params.push(`promoCode=${props.promoCode}`);
  }
  if (props.impactClickId) {
    params.push(`impactClickId=${props.impactClickId}`);
  }
  if (props.campaign) {
    params.push(`campaign=${props.campaign}`);
  }
  if (rerequest) {
    params.push('rerequest=true');
  }

  if (params.length) {
    return `?${params.join('&')}`;
  }
  return '';
};

/**
 * The Authentication is the left column of the AuthComponent on sign-up
 * and the modal content on log in
 */
const Authentication = (props) => {
  const onFacebookLogin = () => {
    if (window?.location) {
      const _props = { ...props };

      const impactClickId = Cookies.get(IMPACT_CLICK_ID);
      if (impactClickId) _props.impactClickId = impactClickId;

      const campaign = Cookies.get(COOKIE_UTM_CAMPAIGN);
      if (campaign) _props.campaign = campaign;

      let redirectURL = `${USER_API_URL}/sessions/facebook/create`;
      const params = getSocialQueryParameter(_props, _props.rerequest);
      if (params) {
        redirectURL += params;
      }
      analytics.track(
        SOCIAL_FACEBOOK,
        props.rerequest ? { label: 'rerequest: true;' } : undefined
      );
      window.location = redirectURL;
    }
  };

  const onGoogleLogin = () => {
    if (window?.location) {
      const _props = { ...props };

      const impactClickId = Cookies.get(IMPACT_CLICK_ID);
      if (impactClickId) _props.impactClickId = impactClickId;

      const campaign = Cookies.get(COOKIE_UTM_CAMPAIGN);
      if (campaign) _props.campaign = campaign;

      let redirectURL = `${USER_API_URL}/sessions/google/create`;
      const params = getSocialQueryParameter(_props);
      if (params) {
        redirectURL += params;
      }
      analytics.track(SOCIAL_GOOGLE);
      window.location = redirectURL;
    }
  };

  const socialButtonProps = {
    width: 'unset',
    height: '40px',
    theme: secondaryTheme,
  };

  const invitedBy = props.invitedBy
    ? {
        ...props.invitedBy,
        name: props.invitedBy.name || props.invitedBy.slug,
        profileImage: buildProfileImageUrl(props.invitedBy.profileImage),
      }
    : null;

  return (
    <Content>
      <Logo height={40} style={{ marginBottom: '24px' }} />
      {!invitedBy && (
        <>
          <H2>{props.isSignup ? 'Create Free Account' : 'Welcome To Kittl'}</H2>
          {!props.isSignup && (
            <P2 style={{ marginTop: '8px' }}>Log in below</P2>
          )}
          <Spacer h={props.isSignup ? '48px' : '24px'} />
        </>
      )}
      {!!(props.isSignup && invitedBy) && (
        <>
          <ProfileImage user={invitedBy} theme={{ size: '60px' }} />
          <Spacer h={'12px'} />
          <P2>
            {invitedBy.name} has invited you to join Kittl. Create your free
            account.
          </P2>
          <Spacer h={'32px'} />
        </>
      )}
      {props.customContent && (
        <>
          {props.customContent}
          <Spacer h={'24px'} />
        </>
      )}
      <OtherProviders>
        <Button
          {...socialButtonProps}
          dataTestId="google-sign-in"
          icon={{ name: 'google', height: '16px' }}
          label="Google"
          onClick={onGoogleLogin}
        />
        <Spacer w={'12px'} />
        <Button
          {...socialButtonProps}
          dataTestId="facebook-sign-in"
          icon={{ name: 'facebook', height: '16px' }}
          label="Facebook"
          onClick={onFacebookLogin}
        />
      </OtherProviders>

      <OrWrapper>
        <Divider />
        <P3 color={themeStyle.varInkMedium}>OR</P3>
        <Divider />
      </OrWrapper>

      <Form
        isSignup={props.isSignup}
        callToAction={props.callToAction}
        email={props.email}
        inviteCode={props.inviteCode}
        referralCode={props.referralCode}
        promoCode={props.promoCode}
        error={props.error}
        onSubmit={props.onSubmit}
      />

      {!props.isSignup && (
        <>
          <Spacer h={'8px'} />
          <Button
            onClick={() => props.onForgotPassword()}
            dataTestId="forgot-password"
            label={'Forgot your password?'}
            isBlock
            height={'22px'}
            theme={forgotPasswordButtonTheme}
          />
          {props.onGoToSignup && (
            <>
              <Spacer h={'24px'} />
              <P2>Don't have an account yet?</P2>
              <SwitchModeLabel onClick={props.onGoToSignup}>
                Sign up
              </SwitchModeLabel>
            </>
          )}
        </>
      )}
      {props.isSignup && (
        <>
          <PolicyLabel>
            By registering, you agree to our{' '}
            <a
              href={buildWebsiteUrl('/terms')}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{' '}
            &amp;{' '}
            <a
              href={buildWebsiteUrl('/privacy')}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </PolicyLabel>
          {props.onGoToLogin && (
            <>
              <Spacer h={'24px'} />
              <P2>Already have an account?</P2>
              <SwitchModeLabel onClick={props.onGoToLogin}>
                Log in
              </SwitchModeLabel>
            </>
          )}
          {!props.onGoToLogin && !props.invitedBy && !props.customFooter && (
            <Spacer h={'48px'} />
          )}
          {props.customFooter}
        </>
      )}
    </Content>
  );
};

Authentication.propTypes = {
  rerequest: PropTypes.bool,
  invitedBy: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    profileImage: PropTypes.string,
  }),
  isSignup: PropTypes.bool,
  customContent: PropTypes.element,
  callToAction: PropTypes.string,
  email: PropTypes.string,
  inviteCode: PropTypes.string,
  promoCode: PropTypes.string,
  referralCode: PropTypes.string,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
  onForgotPassword: PropTypes.func,
  onGoToSignup: PropTypes.func,
  onGoToLogin: PropTypes.func,
  customFooter: PropTypes.element,
};

export default Authentication;
