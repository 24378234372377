import styled from 'styled-components';

import { themeStyle } from '../../services/theming';

export const Wrapper = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${themeStyle.varBackgroundAlt};
  box-sizing: border-box;
  font-weight: ${themeStyle.fontMedium};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeStyle.varInkMedium};
  cursor: pointer;
  pointer-events: auto;
  box-shadow: ${themeStyle.shadowSmallHeavy};

  html[data-theme='dark'] && {
    background: ${themeStyle.darkBackgroundAlt};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: calc(100% + 8px);
    width: 1px;
    height: 25px;
    background: ${themeStyle.varInkLight};
    border-radius: 0.5px;
    opacity: ${({ hovering }) => (hovering ? 1 : 0)};
    transition-property: opacity;
    transition-duration: 0.5s;
    ${({ hovering }) => (hovering ? `transition-delay: 0.5s` : ``)};
  }
`;
