import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import nProgress from 'nprogress';

import { themeStyle } from '../../services/theming';
import Modal from '../Modal/Modal';
import Button from '../Button/Button/Button';
import { useUserStore, userStoreSelector } from '../../stores/userStore';
import { buildImageProxyUrl, buildWebsiteUrl } from '../../utils/url';
import TextInput from '../TextInput/TextInput';
import Select from '../SelectNew/Select';
import Spacer from '../Spacer/Spacer';
import Icon from '../Icon/Icon';
import Switch from '../Switch/Switch';
import Api from '../../global/api';
import { POST_SUBMIT } from '../../global/events';
import {
  Wrapper,
  Preview,
  Image,
  Info,
  LoadingWrapper,
  ImageContainer,
} from './styles';
import { useMenuStore, menuStoreSelector } from '../../stores/menuStore';
import { primaryTheme } from '../Button/Button/theme';
import { H3, H5, P2, P3, P4 } from '../utilities/Typography/styles';
import { FlexRow, FlexColumn, Divider } from '../utilities/styles';
import TagInput from '../TagInput/TagInput';
import Checkbox from '../Checkbox/Checkbox';
import { getFormattedArtboardSize } from '../../utils/artboard';
import DecisionModal from '../DecisionModal/DecisionModal';
import userApi from '../../global/userApi';

const DEFAULT_CATEGORY = {
  id: '0',
  label: 'Choose Category',
};

const DEFAULT_CHALLENGE = {
  id: '0',
  label: 'None Selected',
};

/**
 * Modal to enable a creator to submit a template
 */
const TemplateSubmitModal = ({ onClose, design, dispatch, onSubmit }) => {
  const user = useUserStore(userStoreSelector.user);
  const blockSubmitDesign = useMenuStore(menuStoreSelector.blockSubmitDesign);
  const [templateName, setTemplateName] = useState('');
  const [category, setCategory] = useState(DEFAULT_CATEGORY);
  const [categories, setCategories] = useState([DEFAULT_CATEGORY]);
  const [challenge, setChallenge] = useState(DEFAULT_CHALLENGE);
  const [challenges, setChallenges] = useState([DEFAULT_CHALLENGE]);
  const [errors, setErrors] = useState({
    name: null,
    category: null,
    challenge: null,
  });
  const errorsRef = useRef({ name: true, category: true, challenge: true });
  const [tags, setTags] = useState([]);
  const [acceptedGuidelines, setAcceptedGuidelines] = useState(false);
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [premium, setPremium] = useState(false);

  const submissionGuidelinesUrl = buildWebsiteUrl('/publish/guidelines');

  const updateTemplateId = useMenuStore(menuStoreSelector.updateTemplateId);

  const savedDesign = useMenuStore(menuStoreSelector.saved);
  const [formReady, setFormReady] = useState(false);

  useEffect(() => {
    if (!!design && savedDesign) {
      setFormReady(true);
    }
  }, [design, savedDesign]);

  const setArtboardHotkeysEnabled = useMenuStore(
    menuStoreSelector.setArtboardHotkeysEnabled
  );
  useEffect(() => {
    setArtboardHotkeysEnabled(!design);
  }, [design, setArtboardHotkeysEnabled]);

  useEffect(() => {
    if (premium && challenge.id !== DEFAULT_CHALLENGE.id) {
      setErrors((prev) => ({
        ...prev,
        challenge: "You can't submit premium templates to challenges",
      }));
      errorsRef.current.challenge = true;
    } else if (challenge.submissionsSent >= challenge.submissionsLimit) {
      setErrors((prev) => ({ ...prev, challenge: 'Submission limit reached' }));
      errorsRef.current.challenge = true;
    } else {
      setErrors((prev) => ({ ...prev, challenge: null }));
      errorsRef.current.challenge = false;
    }
  }, [premium, challenge]);

  const canUpdate = useMemo(() => {
    return design && user && updateTemplateId;
  }, [user, updateTemplateId, design]);

  const handleClose = () => {
    setFormReady(false);
    onClose && onClose();
  };

  const handleSetCategory = (option) => {
    setCategory(option);
    setErrors((prev) => ({ ...prev, category: null }));
    errorsRef.current.category = null;
  };

  const handleSetChallenge = (option) => {
    setChallenge(option);
    if (option.submissionsSent >= option.submissionsLimit) {
      setErrors((prev) => ({ ...prev, challenge: 'Submission limit reached' }));
      errorsRef.current.challenge = true;
    } else {
      setErrors((prev) => ({ ...prev, challenge: null }));
      errorsRef.current.challenge = false;
    }
  };

  const validateName = () => {
    if (!templateName) {
      setErrors((prev) => ({ ...prev, name: 'Name is required' }));
      errorsRef.current.name = true;
    } else {
      setErrors((prev) => ({ ...prev, name: null }));
      errorsRef.current.name = null;
    }
  };

  const validateCategory = () => {
    if (!canUpdate && (!category || category.id === '0')) {
      setErrors((prev) => ({ ...prev, category: 'Category is required' }));
      errorsRef.current.category = true;
    } else {
      setErrors((prev) => ({ ...prev, category: null }));
      errorsRef.current.category = false;
    }
  };

  const handleSave = () => {
    validateName();
    validateCategory();

    if (errorsRef.current.name || errorsRef.current.category) return;

    setIsLoading(true);
    nProgress.start();
    setErrors((prev) => ({
      ...prev,
      server: null,
    }));

    dispatch(POST_SUBMIT, {
      templateName,
      category:
        category.id !== DEFAULT_CATEGORY.id
          ? canUpdate
            ? category
            : category.label
          : undefined,
      tags: Array.from(tags),
      description,
      premium,
      challengeId:
        challenge.id !== DEFAULT_CHALLENGE.id ? challenge.id : undefined,
      id: canUpdate ? Number(updateTemplateId) : undefined,
      callback: (response) => {
        if (response.id) {
          setTemplateName('');
          setTags([]);
          setCategory(DEFAULT_CATEGORY);
          setChallenge(DEFAULT_CHALLENGE);
          setDescription('');
          setPremium(false);
          if (response.challenge?.slug) {
            window.location.href = buildWebsiteUrl(
              `/challenges/${response.challenge.slug}?ppid=${response.id}`
            );
          } else {
            onSubmit && onSubmit();
          }
        } else {
          if (response.error) {
            setErrors((prev) => ({ ...prev, server: response.error }));
          } else {
            setErrors((prev) => ({
              ...prev,
              server:
                'An error occurred while submitting the design. Try again.',
            }));
          }
        }
        setIsLoading(false);
        nProgress.done();
      },
    });
  };

  useEffect(() => {
    const loadCategories = async () => {
      const { categories } = await Api.getCategories();
      const formattedCategories = [];
      categories?.forEach((c) => {
        if (c.name === 'Creators Space') return;
        formattedCategories.push({ id: c.id, label: c.name });
      });
      setCategories(formattedCategories);
    };

    const loadActiveChallenges = async () => {
      // we use a take of 10 here to fetch options for active challenges
      // this should be enough, since there will probably be never more than 2 active challenges
      const response = await userApi.getActiveChallenges(10, 0, true);
      if (response?.results?.length) {
        setChallenges([
          DEFAULT_CHALLENGE,
          ...response.results.map((challenge) => ({
            id: challenge.id,
            label:
              challenge.name +
              ' (' +
              challenge.submissionsSent +
              '/' +
              challenge.submissionsLimit +
              ')',
            submissionsLimit: challenge.submissionsLimit,
            submissionsSent: challenge.submissionsSent,
          })),
        ]);
      }
    };

    // load options for the select
    loadCategories();
    loadActiveChallenges();
  }, []);

  useEffect(() => {
    const getTemplateData = async () => {
      const response = await Api.getTemplate(updateTemplateId, true);
      if (response.template) {
        setTemplateName(response.template.name);
        setTags(response.template.tags.map((tag) => tag.name));
        setDescription(response.template.description || '');

        if (response.template.groups?.length) {
          const group = response.template.groups[0];
          setCategory({ id: group.categoryId, label: group.category.name });
        }
      }
    };

    if (canUpdate) {
      getTemplateData();
    }
  }, [design, canUpdate, updateTemplateId]);

  if (!design) return null;

  const { width, height, unit } = getFormattedArtboardSize(
    design.state.artboardOptions
  );
  const sufix = {
    px: 'px',
    in: '"',
    mm: 'mm',
  }[unit];

  const precision = unit === 'px' ? 0 : 2;
  const sizeLabel = `${width.toFixed(precision)}${sufix} x ${height.toFixed(
    precision
  )}${sufix}`;

  if (blockSubmitDesign)
    return (
      <DecisionModal
        isOpen={true}
        title={'We are sorry'}
        message={`You can't publish a design that is using custom uploaded fonts.`}
        onClose={handleClose}
        firstButton={{
          label: 'Continue',
          onClick: handleClose,
          type: 'primary',
        }}
      />
    );

  return (
    <Modal isOpen={!!design} onClose={handleClose} width={'1020px'}>
      <Wrapper>
        <FlexRow
          gap="24px"
          width="100%"
          justifyContent="flex-start"
          position="relative"
        >
          {!formReady && (
            <LoadingWrapper>
              <P2>Your design is being prepared for publishing...</P2>
            </LoadingWrapper>
          )}
          <Preview>
            <ImageContainer>
              <Image
                isWide={
                  design.state.artboardOptions?.width >=
                  design.state.artboardOptions?.height
                }
                src={buildImageProxyUrl(`api/${design.preview}`, {
                  width: 612,
                  scale: 2,
                })}
                alt={design.name}
              />
            </ImageContainer>
          </Preview>
          <Info>
            <FlexColumn>
              <H3>Publish Design to Community</H3>
              <Spacer h="24px" />
              <FlexRow>
                <FlexColumn>
                  <FlexRow alignItems="center">
                    <P2 color={themeStyle.varInkMedium}>Created by</P2> &nbsp;
                    <H5>{user?.name}</H5>
                  </FlexRow>
                  <P2 color={themeStyle.varInkMedium}>Size: {sizeLabel}</P2>
                </FlexColumn>
              </FlexRow>
              <Spacer h="24px" />
              <TextInput
                value={templateName}
                onChanging={setTemplateName}
                placeholder={'Enter Design Name'}
                activeOnFocus={false}
                activeOnHover={false}
                error={errors.name}
              />
              <Spacer h={'12px'} />
              <Select
                options={categories}
                activeOption={category}
                onSelect={handleSetCategory}
                error={errors.category}
              />
              <Spacer h={'12px'} />
              <TagInput
                onChange={setTags}
                tags={tags}
                maxTagsAmount={user?.admin ? 99 : 8}
                infoText={`Separate with enter (max. ${
                  user?.admin ? 99 : 8
                } tags)`}
              />
              <Spacer h={'12px'} />
              <TextInput
                value={description}
                theme={{
                  height: '80px',
                }}
                placeholder="Write a short description"
                isTextArea
                onChanging={setDescription}
                blurOnEnter={false}
              />
              {user?.creator && (
                <>
                  <Spacer h="24px" />
                  <FlexRow alignItems="center">
                    <Icon name={'premiumBadge'} height={'20px'} />
                    <Spacer w="8px" />
                    <H5>Publish as Premium Template</H5>
                    <Switch
                      label=""
                      onChange={setPremium}
                      theme={{ padding: '0px' }}
                    />
                  </FlexRow>
                  <Spacer h="8px" />
                  <P3 color={themeStyle.varInkMedium}>
                    Earn revenue share by offering your design as a Premium
                    Template.&nbsp;
                    <a
                      href={buildWebsiteUrl('/create/become-a-kittl-creator')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here
                    </a>
                    &nbsp;to learn more.
                  </P3>
                </>
              )}
              {!canUpdate && challenges.length > 1 && (
                <>
                  <Spacer h="24px" />
                  <FlexRow gap="5px">
                    <H5>Submit to a Challenge</H5>
                    <P3 color={themeStyle.varInkMedium}>(optional)</P3>
                  </FlexRow>
                  <Spacer h="8px" />
                  <P3 color={themeStyle.varInkMedium}>
                    Want to take part in a design challenge?&nbsp;
                    <a
                      href={buildWebsiteUrl('/challenges')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click here
                    </a>
                    &nbsp;to learn more and view current challenges.
                  </P3>
                  <Spacer h="12px" />
                  <Select
                    options={challenges}
                    activeOption={challenge}
                    onSelect={handleSetChallenge}
                    error={errors.challenge}
                  />
                </>
              )}
            </FlexColumn>
          </Info>
        </FlexRow>
        <Divider color={themeStyle.varInkSuperLight} />
        <FlexRow alignItems="center">
          <Checkbox
            value={acceptedGuidelines}
            onClick={() => setAcceptedGuidelines((accepted) => !accepted)}
          />
          <Spacer w="12px" />
          <P2>
            I agree with the{' '}
            <a href={submissionGuidelinesUrl} target="_blank" rel="noreferrer">
              Publishing Design Guidelines
            </a>
            {challenge.id !== DEFAULT_CHALLENGE.id && (
              <>
                &nbsp;and&nbsp;
                <a
                  href={buildWebsiteUrl('/challenge-rules')}
                  target="_blank"
                  rel="noreferrer"
                >
                  Challenge Rules
                </a>
              </>
            )}
          </P2>
        </FlexRow>
        <Button
          label={canUpdate ? 'Submit Changes' : 'Publish To Community'}
          theme={primaryTheme}
          width="335px"
          icon={{ name: 'eyelashes' }}
          loading={isLoading}
          disabled={
            !acceptedGuidelines ||
            isLoading ||
            !formReady ||
            errorsRef.current.challenge
          }
          onClick={handleSave}
        />
        {errors.server && (
          <P4 color={themeStyle.highAlertRed}>{errors.server}</P4>
        )}
      </Wrapper>
    </Modal>
  );
};

TemplateSubmitModal.propTypes = {
  /**
   * Trigger popup close
   */
  onClose: PropTypes.func,
  /**
   * Design object displayed on the modal (or false/null)
   */
  design: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  /**
   * Event dispatcher
   */
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default TemplateSubmitModal;
