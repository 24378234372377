import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { FlexRow } from '../../utilities/styles';
import { ACTIVE_ALIGN, ACTIVE_TIDY } from '../../../global/events';
import { alignActions } from './config';
import Button from '../../Button/Button/Button';
import { editorMenuItemTheme } from '../../Button/Button/theme';
import Tooltip from '../../Tooltip/Tooltip';

/**
 * A collection of action buttons that adjust the position of objects.
 */
const PositionActionButtons = ({ dispatch }) => {
  const dispatchAlignEvent = useCallback(
    (actionItem) => {
      dispatch && dispatch(ACTIVE_ALIGN, actionItem.id);
    },
    [dispatch]
  );

  const dispatchTidyEvent = useCallback(() => {
    dispatch && dispatch(ACTIVE_TIDY);
  }, [dispatch]);

  return (
    <FlexRow
      justifyContent="space-between"
      data-testid="position-actions"
      width="100%"
    >
      {alignActions.map((action) => (
        <Tooltip text={action.label}>
          <Button
            dataTestId={action.id}
            icon={{
              name: action.icon,
              height: '15px',
            }}
            width="35px"
            theme={editorMenuItemTheme}
            onClick={(item) => dispatchAlignEvent(action)}
          />
        </Tooltip>
      ))}
      <Tooltip text="Tidy" align="end">
        <Button
          dataTestId="tidy"
          icon={{
            name: 'tidy',
            height: '15px',
          }}
          width="35px"
          theme={editorMenuItemTheme}
          onClick={dispatchTidyEvent}
        />
      </Tooltip>
    </FlexRow>
  );
};

PositionActionButtons.propTypes = {
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
};

export default React.memo(PositionActionButtons);
