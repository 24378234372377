import { useCallback, useState } from 'react';
import Nprogress from 'nprogress';

import { LayersLoadingState, UpdateLayersLoading } from '../types';

const updateProgressBar = (layersLoading: LayersLoadingState): void => {
  const stillLoading = Object.values(layersLoading).some(
    (value: boolean): boolean => value
  );

  if (stillLoading) {
    Nprogress.inc();
  } else {
    Nprogress.done();
  }
};

/**
 * This hook holds the loading status of layers.
 */
export const useLayersLoading = (): [
  LayersLoadingState,
  UpdateLayersLoading
] => {
  const [layersLoading, setLayersLoading] = useState<LayersLoadingState>({
    designLayer: false,
    sceneLayer: false,
    lightBlendLayer: false,
    darkBlendLayer: false,
  });
  const updateLoading: UpdateLayersLoading = useCallback(
    (value: Partial<LayersLoadingState>) => {
      setLayersLoading((state) => {
        const newState = {
          ...state,
          ...value,
        };
        updateProgressBar(newState);
        return newState;
      });
    },
    []
  );

  return [layersLoading, updateLoading];
};
