import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import memoize from 'lodash/memoize';

import { DEFAULT_FONT_WEIGHT } from '../font/fonts';

import api from '../global/api';
import useCommonFontsStore from './commonFontsStore';
import fontApi from '../global/fontApi';
import { DEFAULT_USER_FONTS_TAKE } from '../global/constants';
import { buildElementUrl } from '../utils/url';
import { uniques } from '../utils';

const getCmsFonts = async () => {
  const response = await api.getFonts();

  if (!response.fonts?.length) return [];

  return response.fonts.map((font) => {
    const weights = font.styles.map((s) => s.name);
    // `defaultWeight` is either `DEFAULT_FONT_WEIGHT` or just the first element of `weights` array
    const defaultWeight = weights.includes(DEFAULT_FONT_WEIGHT)
      ? DEFAULT_FONT_WEIGHT
      : weights[0];
    return {
      id: font.id,
      fontFamily: font.name,
      name: font.name,
      variable: font.variable,
      img: buildElementUrl(font.fontPreviewObjectName),
      defaultWeight,
      weights,
      styles: font.styles,
      type: font.type,
      isCmsFont: true,
      tags: font.tags.map((t) => t.name),
    };
  });
};

const prepareUserFont = (font) => {
  const weights = font.fonts.map((s) => s.style);
  // `defaultWeight` is either `DEFAULT_FONT_WEIGHT` or just the first element of `weights` array
  const defaultWeight = weights.includes(DEFAULT_FONT_WEIGHT)
    ? DEFAULT_FONT_WEIGHT
    : weights[0];

  return {
    id: font.id,
    fontFamily: font.id, // we use id as fontFamily, to get unique fonts
    name: font.name,
    variable: false,
    img: null,
    defaultWeight,
    weights: weights,
    styles: font.fonts.map((style) => ({
      name: style.style,
      objectName: style.objectName,
    })),
    isUserFont: true,
    objectName: font.objectName,
  };
};

const getUserFonts = async (options = {}) => {
  let response;

  if (options.include) {
    response = await fontApi.findFonts(options.include);
  } else {
    const { take, skip, cursor } = options;
    response = await fontApi.getFonts(take, skip, cursor);
  }

  if (!response.results) {
    response.results = [];
  } else {
    response.results = response.results
      .filter((font) => font.fonts.length)
      .map(prepareUserFont);
  }

  return response;
};

export const userFontsStore = createVanilla((set, get) => ({
  /*
    Holds the names of the user uploaded fonts required to load the current design (as it came from Api)
    These fonts are considered "Relevant" and are loaded before anything else, along with all CMS fonts.

    The rest of the user fonts are loaded later on in chunks.

    NOTE: some CMS font names might creep in here. We should discuss a way to filter them out when loading
    the design to the artboard. But for now it should not cause problems.
  */
  _headerUserFonts: [],
  _remainingFontsRequested: false,
  showUploadError: false,
  fonts: [],

  _addFonts: (newFonts) => {
    const fonts = uniques([...get().fonts, ...newFonts]);
    set({ fonts });
    useCommonFontsStore.getState().setFontList(fonts);
  },

  // Gets all fonts that are not "Relevant" in chunks
  _getRemainingFonts: () => {
    if (get()._remainingFontsRequested) return;
    set({ _remainingFontsRequested: true });

    const __getRemainingFonts = (cursor = null) => {
      getUserFonts({
        cursor,
        take: DEFAULT_USER_FONTS_TAKE,
        skip: cursor ? 1 : 0,
      }).then((response) => {
        const { results } = response;
        get()._addFonts(results);
        if (!response.nextCursor) return;
        __getRemainingFonts(response.nextCursor);
      });
    };

    __getRemainingFonts();
  },

  _getRelevantFonts: memoize(async () => {
    const headerUserFonts = getUserFonts({ include: get()._headerUserFonts });
    const cmsFonts = getCmsFonts();
    return await new Promise((resolve) => {
      Promise.all([headerUserFonts, cmsFonts]).then((results) => {
        const [{ results: userResults }, cmsResults] = results;
        const allResults = [].concat(userResults, cmsResults);
        get()._addFonts(allResults);
        resolve();
      });
    });
  }),

  getFonts: async () => {
    await get()._getRelevantFonts();
    get()._getRemainingFonts();
  },

  getFontParams: async (fontFamily) => {
    await get().getFonts();

    const { fonts } = get();
    const params = fonts.find((font) => font.fontFamily === fontFamily);

    if (!params) {
      const request = await getUserFonts({ include: [fontFamily] });

      const [font] = request.results;
      if (font) {
        const allFonts = [...fonts, font];
        set({ fonts: allFonts });
        useCommonFontsStore.getState().setFontList(allFonts);
      }

      return font;
    }

    return params;
  },

  uploadFont: async (name, files, styles) => {
    try {
      const response = await fontApi.uploadFont(name, files, styles);
      if (response.error) {
        set({ showUploadError: true });
      } else {
        set((state) => ({
          fonts: [...(state.fonts || []), prepareUserFont(response.fontFamily)],
        }));
        useCommonFontsStore.getState().setFontList(get().fonts);
      }
    } catch {
      set({ showUploadError: true });
    }
  },

  closeUploadError: () => {
    set({ showUploadError: false });
  },

  setHeaderUserFonts: (_headerUserFonts) => {
    set({ _headerUserFonts });
  },
}));

export const useUserFontsStore = create(userFontsStore);

export const userFontsStoreSelector = {
  fonts: (state) => state.fonts,
  showUploadError: (state) => state.showUploadError,
  getFonts: (state) => state.getFonts,
  uploadFont: (state) => state.uploadFont,
  closeUploadError: (state) => state.closeUploadError,
};
