import React from 'react';

import Button from '../Button/Button/Button';
import { primaryTheme } from '../Button/Button/theme';
import Logo from '../Logo/Logo';
import { ContentContainer } from './styles';
import { P2 } from '../utilities/Typography/styles';
import Spacer from '../Spacer/Spacer';

const GenericErrorPanel = () => {
  const reload = () => {
    window.location.reload();
    return false;
  };

  return (
    <ContentContainer>
      <Logo />
      <Spacer h="12px" />
      <P2>
        Ooops, something bad happened!
        <br />
        <br />
        We apologize for any inconvenience. We are looking at each error and
        working hard to fix them. For now please refresh this page to continue
        working. If the error persists please contact us at{' '}
        <a href="mailto:support@kittl.com">support@kittl.com</a>
      </P2>
      <Spacer h="24px" />
      <Button
        theme={primaryTheme}
        width={'140px'}
        height={'40px'}
        label="REFRESH PAGE"
        onClick={reload}
      />
    </ContentContainer>
  );
};

export default GenericErrorPanel;
