import { loadFont } from './fonts';
import { getAllAlternativeSubGlyphs, getLigatures } from './glyphLoader';

const LINE_BREAK_CODE_POINT = 10;

/**
 * function to handle the substitution of alternative glyphs in text
 * @param {String} text
 * @param {*} oldFont old font, the text is for
 */
export const substituteAlternativeGlyphs = async (text, oldFontProps) => {
  let oldFont;
  try {
    oldFont = await loadFont(oldFontProps.fontFamily, oldFontProps.fontWeight);
  } catch (error) {
    return text;
  }
  const allGlyphs = oldFont.characterSet.map((codePoint) => {
    return oldFont.glyphForCodePoint(codePoint);
  });
  // create a map to easily find alternative glyphs
  // map {[altGlyph_id]=orgGlyph_id}
  const alternativeTable = getAllAlternativeSubGlyphs(oldFont);
  const altGlyphMap = Object.keys(alternativeTable).reduce((map, key) => {
    alternativeTable[key].forEach((id) => {
      map[id] = parseInt(key);
    });
    return map;
  }, {});

  const codePoints = Array.from(text).map((char) => char.codePointAt());
  return codePoints.reduce((text, codePoint) => {
    const oldGlyph = oldFont.glyphForCodePoint(codePoint);

    // the current glyph is an alternative glyph, if it is a key in altGlyphMap
    const isAltGlyph = Object.keys(altGlyphMap).includes(
      oldGlyph.id.toString()
    );

    if (isAltGlyph) {
      // find the original glyph for an alternative glyph
      const orgGlyph = allGlyphs.find(
        ({ id }) => id === altGlyphMap[oldGlyph.id]
      );
      if (orgGlyph) {
        // add text for char of original glyph
        orgGlyph.codePoints.forEach((codePoint) => {
          text += String.fromCharCode(codePoint);
        });
        return text;
      }
    }
    text += String.fromCharCode(codePoint);
    return text;
  }, '');
};

/**
 * function to handle the substitution of glyphs in text when changing the font
 * @param {String} text
 * @param {*} oldFont old font, the text is for
 * @param {} newFont new font, that should be substituted to
 * @param {function} cb callback to handle the new substituted text
 */
export const substituteGlyphsInText = async (
  text,
  oldFontProps,
  newFontProps,
  cb
) => {
  const substitutedText = await substituteAlternativeGlyphs(text, oldFontProps);

  let oldFont;
  let newFont;
  try {
    oldFont = await loadFont(oldFontProps.fontFamily, oldFontProps.fontWeight);
    newFont = await loadFont(newFontProps.fontFamily, newFontProps.fontWeight);
  } catch (error) {
    cb && cb(substitutedText);
    return;
  }
  // create a list of all ligature glyphs
  const ligatures = getLigatures(oldFont);
  const ligatureGlyphs = ligatures.map((l) => {
    const run = oldFont.layout(l, ['liga', 'dlig']);
    return { ...run.glyphs[0], ligature: l };
  });

  const codePoints = Array.from(substitutedText).map((char) =>
    char.codePointAt()
  );
  const newText = codePoints.reduce((substitutedText, codePoint) => {
    const oldGlyph = oldFont.glyphForCodePoint(codePoint);

    if (
      newFont.hasGlyphForCodePoint(codePoint) ||
      codePoint === LINE_BREAK_CODE_POINT
    ) {
      substitutedText += String.fromCodePoint(codePoint);
      return substitutedText;
    }

    // the old glyph is a ligature, if its id is in ligatureGlyphs
    const ligature = ligatureGlyphs.find(({ id }) => id === oldGlyph.id);
    if (ligature) {
      // add names of ligature parts (eg. `ffi`) to text
      substitutedText += ligature.ligature;
      return substitutedText;
    }

    return substitutedText;
  }, '');

  cb && cb(newText);
};
