import { GetState, SetState } from 'zustand';
import analytics from '../../global/analytics';
import { AUTHENTICATION_SHOW } from '../../global/analytics/events';

import { AuthenticationOptions, State } from './types';

const getDefaultOptions = (): AuthenticationOptions => ({
  isSignup: true,
  enableClose: true,
  blurBackground: false,
  enableBackgroundScrolling: true,
  onSubmit: null,
});

export const stateCreator = (
  set: SetState<State>,
  get: GetState<State>
): State => ({
  /**
   * whether the authentication is currently open
   */
  isOpen: false,

  /**
   * options for the authentication
   */
  options: getDefaultOptions(),

  /**
   * open authentication with custom options
   * use `forceOverwrite` to make sure the options are applied
   * `forceOverwrite` shouldn't be used twice on the same page
   */
  openAuthentication: (
    customOptions?: Partial<AuthenticationOptions>,
    forceOverwrite?: boolean
  ): void => {
    if (!forceOverwrite && get().isOpen) return;

    const options = { ...getDefaultOptions(), ...customOptions };
    analytics.track(AUTHENTICATION_SHOW, {
      label: options.isSignup ? 'signup' : 'login',
    });
    set({ isOpen: true, options });
  },

  closeAuthentication: (): void => {
    set({ isOpen: false });
  },
});
