import styled from 'styled-components';
import { handleTextOverflow } from '../styles';

export const InnerWrapper = styled.div<any>`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  margin-left: ${({ hasLabelPrefix }) =>
    hasLabelPrefix
      ? '8'
      : '0'}px; /* 8px = 5 (padding of select) + 3 (offset) */
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div<any>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeight};
  font-size: ${({ theme }) => theme.fontSize};
  line-height: ${({ theme }) => theme.lineHeight};
  color: ${({ theme }) => theme.colorLabel};
  letter-spacing: ${({ theme }) => theme.letterSpacing};
  text-transform: ${({ theme }) => theme.textTransform};
  user-select: none;
  padding: ${({ theme }) => theme.padding};

  ${handleTextOverflow}
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0px;
`;

export const ClickableArea = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
`;
