import styled, { css } from 'styled-components';
import { darkBackgroundBlur, FlexRow } from '../utilities/styles';
import { themeStyle } from '../../services/theming';

export const Wrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-end;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  bottom: 20px;
`;

export const ButtonList = styled.div`
  position: relative;
  height: 20px;
  & > button:not(:last-of-type) {
    margin-right: 20px;
  }
`;

export const HelpAnchor = styled.div`
  position: absolute;
  top: 0px;
  right: 48px;
  pointer-events: none;
`;

export const FloatingPanel = styled.div`
  position: relative;
  pointer-events: auto;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'space-evenly'};

  height: 30px;
  width: ${(props) => props.width};

  background: ${({ transparent }) =>
    transparent ? 'none' : themeStyle.varBackgroundAlt};
  ${({ transparent }) => (transparent ? '' : darkBackgroundBlur)};
  box-shadow: ${({ transparent }) =>
    transparent ? 'none' : themeStyle.shadowHeavy};
  padding: 3px;
  border-radius: ${themeStyle.radiusMedium};

  &:not(:first-child) {
    margin-left: 10px;
  }

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          * {
            pointer-events: none !important;
          }
        `
      : ''};
`;

export const CenterAndRightContent = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: calc(100% / 2);
  width: calc(100% / 2 - 20px);

  ${FlexRow}:first-child {
    transform: translateX(-50%);
  }

  ${FlexRow}:last-child {
    flex: 1;
    justify-content: flex-end;
    min-width: 0;

    ${FloatingPanel}:first-child {
      width: 65%;
    }
  }
`;

export const LeftContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;

  ${FlexRow} {
    flex: 1;
    justify-content: flex-end;
  }

  ${FlexRow}:last-of-type {
    ${FloatingPanel}:first-of-type {
      width: 100%;
    }
  }
`;

export const HelpList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
