import { useEffect, useRef } from 'react';

import fabric from '../../../Artboard/fabric';
import {
  LayersLoadingState,
  MockupTemplate,
  UpdateLayersLoading,
} from '../../types';
import WarpCanvas from './WarpCanvas';
import { designLayerId, isMockupTemplateWithWarpGrid } from '../../utils';

const removeExistingDesignLayer = (canvas: fabric.Canvas): void => {
  const existingDesignLayer = canvas
    .getObjects()
    .find((object: fabric.Object): boolean => object.id === designLayerId);

  if (existingDesignLayer) {
    canvas.remove(existingDesignLayer);
  }
};

/**
 * This hook handles the warping of the design and draw the result on board.
 */
export const useWarpRenderer = (
  canvas: fabric.Canvas | null,
  designDataUrl: string,
  mockupTemplate: MockupTemplate | null,
  { designLayer: designLayerLoading }: LayersLoadingState,
  updateLoading: UpdateLayersLoading
): void => {
  const designLayer = useRef<null | fabric.Image>(null);
  const warpCanvas = useRef<null | WarpCanvas>(null);

  useEffect(() => {
    // destroy warp canvas on mockup template change or unmount
    return (): void => {
      if (warpCanvas.current) {
        warpCanvas.current.destroy();
        warpCanvas.current = null;
      }
    };
  }, [mockupTemplate?.id]);

  useEffect(() => {
    if (!mockupTemplate || !isMockupTemplateWithWarpGrid(mockupTemplate))
      return;

    if (
      !warpCanvas.current ||
      warpCanvas.current.mockupTemplate.id !== mockupTemplate.id
    ) {
      warpCanvas.current = new WarpCanvas(mockupTemplate);
      designLayer.current = null;
    }

    if (!designDataUrl) return;

    (async (): Promise<void> => {
      if (designLayer.current || designLayerLoading) return;

      removeExistingDesignLayer(canvas);

      updateLoading({
        designLayer: true,
      });

      await warpCanvas.current!.updateTargetMaterialMap(designDataUrl);
      const warpedDesign = await warpCanvas.current!.createdWarpedImage();
      warpedDesign.set({
        id: designLayerId,
      });
      designLayer.current = warpedDesign;

      canvas.insertAt(warpedDesign, 1);
      canvas.setActiveObject(warpedDesign);

      updateLoading({
        designLayer: false,
      });
    })();
  }, [
    canvas,
    designDataUrl,
    mockupTemplate,
    designLayerLoading,
    updateLoading,
  ]);
};
