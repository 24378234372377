import { StyledTheme } from '../../types';
import { IconType } from '../Icon/types';
import { defaultIconTheme, secondaryIconTheme } from '../MenuHeader/theme';

export const getObjectSettingsIcon = (
  options: Record<string, unknown>
): keyof typeof IconType => {
  if (options?.type === 'pathText') return 'text';
  return 'style';
};

export const getObjectIconTheme = (
  options: Record<string, unknown>
): StyledTheme => {
  const iconName = getObjectSettingsIcon(options);
  return iconName === 'style' ? secondaryIconTheme : defaultIconTheme;
};

export const getObjectLabel = (options: Record<string, unknown>): string => {
  if (options?.type === 'pathText') return 'Text';
  if (options?.type === 'artboard') return 'Background';
  if (options?.type === 'activeSelection') return 'Group';
  return 'Object';
};
