import React from 'react';
import PropTypes from 'prop-types';

import { Image, LeftColumn, List, Wrapper } from './styles';
import { getBenefits, getSubTitle } from './config';

import PanelHeader from '../utilities/PanelHeader/PanelHeader';
import Button from '../Button/Button/Button';
import { primaryTheme } from '../Button/Button/theme';
import Spacer from '../Spacer/Spacer';
import { FlexRow } from '../utilities/styles';
import { H2, H4, P2 } from '../utilities/Typography/styles';

const InviteFriendsPopup = (props) => {
  const subTitle = getSubTitle(props.plan);
  const benefits = getBenefits(props.plan);

  return (
    <Wrapper>
      <PanelHeader label={''} onClose={props.onClose} />
      <Spacer h={'8px'} />
      <FlexRow justifyContent={'space-between'} alignItems={'center'}>
        <LeftColumn>
          <H2>Invite Friends</H2>
          <Spacer h={'12px'} />
          <H4>{subTitle}</H4>
          <List>
            {benefits.map((benefit, index) => (
              <li key={index}>
                <P2>{benefit}</P2>
              </li>
            ))}
          </List>
          <Button
            label={'Learn More'}
            theme={primaryTheme}
            onClick={props.onClick}
          />
        </LeftColumn>
        <Image src={'/images/inviteFriends.png'} />
      </FlexRow>
    </Wrapper>
  );
};

InviteFriendsPopup.propTypes = {
  /**
   * current plan for which the popup should be displayed
   */
  plan: PropTypes.string,
  /**
   * onClick function
   */
  onClick: PropTypes.func,
  /**
   * onClose function
   */
  onClose: PropTypes.func,
};

export default InviteFriendsPopup;
