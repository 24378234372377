export enum ArtboardLayout {
  custom = 'custom',
  standard = 'standard',
  postcard = 'postcard',
  businesscard = 'businesscard',
  a4 = 'a4',
  a5 = 'a5',
  posterbig = 'posterbig',
  postersmall = 'postersmall',
  instagrampost = 'instagrampost',
  facebookpost = 'facebookpost',
}

export enum Unit {
  px = 'px',
  in = 'in',
  mm = 'mm',
}

export interface ArtboardOptions {
  activeLayout: ArtboardLayout;
  width: number;
  height: number;
  fill: string;
  opacity: number;
  portrait?: boolean;
  unit?: Unit;
}

interface ColorPaletteItemLinkedObject {
  id: string;
  key: string;
  visible: boolean;
  color: null | { h: number; s: number; l: number; a: number };
}

export type ArtboardColorPalette = Record<
  string,
  ColorPaletteItemLinkedObject[]
>;

export interface ArtboardState {
  config: {
    title: string;
    dpiVersion?: number;
    designId?: number;
    updatedAt?: string;
    templateId?: number;
  };
  artboardOptions: ArtboardOptions;
  colorPalette: ArtboardColorPalette;
}
