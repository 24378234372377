import fabric from '../../components/Artboard/fabric';
import { EventBusData } from '../../types';
import {
  ACTIVE_MOVE_BY,
  COLOR_PICKING,
  DOWNLOAD,
  ENABLE_DRAGGING,
  ENABLE_ZOOM_ON_WHEEL,
  PICK_COLOR,
  SET_BACKGROUND,
  ZOOM,
  ZOOM_CENTER,
  ZOOM_STEP,
  ZOOM_TO,
} from '../../global/events';
import {
  handleZoom,
  handleZoomStep,
  handleZoomTo,
  handleActiveMoveBy,
  handleZoomCenter,
  handleEnableZoomOnWheel,
  handleEnableDragging,
  handlePickColor,
  handleColorPicking,
} from '../editor/eventHandlers';
import { handleDownload } from './eventHandlers';

export interface PickColorEvent {
  pickEvent: React.MouseEvent;
  color: string | null;
}

export interface SetBackgroundEvent {
  color: string;
  isChanging: boolean;
}

export interface DownloadEvent {
  activeDesignId: string;
}

/**
 * Handles common events from event bus.
 * @returns {boolean} if event is handled
 *
 * This handles common events for different mockup modes,
 * i.e Standard Mockup Mode vs MTC (Mockup Template Creator)
 */
export const handleCommonEventBusEvents = async (
  canvas: fabric.Canvas,
  data: EventBusData
): Promise<boolean> => {
  let eventHandled = true;
  const { key, val } = data;

  switch (key) {
    case SET_BACKGROUND: {
      const { color, isChanging } = val as SetBackgroundEvent;
      canvas.modifyArtboard({ fill: color }, isChanging);
      break;
    }
    case ZOOM:
      handleZoom(canvas, val as string);
      break;
    case ZOOM_TO:
      handleZoomTo(
        canvas,
        val as { pos: { x: number; y: number }; zoom: number }
      );
      break;
    case ZOOM_STEP:
      handleZoomStep(canvas, val as 1 | -1);
      break;
    case ZOOM_CENTER:
      handleZoomCenter(canvas);
      break;
    case ENABLE_ZOOM_ON_WHEEL:
      handleEnableZoomOnWheel(canvas, val as boolean);
      break;
    case ENABLE_DRAGGING:
      handleEnableDragging(canvas, val as boolean);
      break;
    case ACTIVE_MOVE_BY:
      handleActiveMoveBy(canvas, val as { x: number; y: number });
      break;
    case COLOR_PICKING: {
      handleColorPicking(val as boolean, canvas);
      break;
    }
    case PICK_COLOR: {
      const value = val as PickColorEvent;
      handlePickColor(
        value.pickEvent,
        canvas,
        (color: string) => (value.color = color)
      );
      break;
    }
    case DOWNLOAD: {
      await handleDownload(canvas, val as DownloadEvent);
      break;
    }
    default:
      eventHandled = false;
  }

  return Promise.resolve(eventHandled);
};
