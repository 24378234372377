import React from 'react';
import { defaultTheme } from './theme';
import {
  Wrapper,
  InnerWrapper,
  Label,
  IconWrapper,
  ClickableArea,
} from './styles';
import Icon from '../../Icon/Icon';
import IconEventWrapper from '../../Icon/IconEventWrapper';
import { PanelHeaderProps } from './types';

/**
 * Utility component to create a panel header, with a label and a close button.
 * This component is used as a header for Popover and LeftMenu panels.
 * Optionally, this component can be extended by a Select component, that is placed
 * in front of the label.
 */
const PanelHeader: React.FC<PanelHeaderProps> = (props) => {
  const theme = { ...defaultTheme, ...props.theme };
  const iconTheme = {
    color: theme.colorIcon,
    activeColor: theme.colorIconActive,
    opacity: theme.opacityIcon,
  };

  const onClose = (event: React.MouseEvent): void => {
    props.onClose && props.onClose(event);
  };

  return (
    <Wrapper>
      {props.labelPrefix && <props.labelPrefix />}
      <InnerWrapper
        hasLabel={!!props.label}
        hasLabelPrefix={!!props.labelPrefix}
      >
        {props.label && <Label theme={theme}>{props.label}</Label>}
        {props.labelPostfix && <props.labelPostfix />}
        {props.onClose && (
          <IconWrapper data-testid={props.dataTestId} onClick={onClose}>
            <IconEventWrapper theme={iconTheme}>
              <ClickableArea />
              <Icon
                theme={iconTheme}
                name={'close'}
                height={props.iconHeight}
              />
            </IconEventWrapper>
          </IconWrapper>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

PanelHeader.defaultProps = {
  label: 'Panel Header',
  iconHeight: '10px',
  dataTestId: 'panel-header-close',
};

export default PanelHeader;
