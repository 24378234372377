import styled, {
  css,
  FlattenSimpleInterpolation,
  Keyframes,
  keyframes,
} from 'styled-components';
import { themeStyle } from '../../services/theming';
import { darkBackgroundBlur, hideScrollBar } from '../utilities/styles';
import VerticallyScrollable from '../VerticallyScrollable/VerticallyScrollable';
import { ModalState } from './types';

const scaleKeyframes = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const opacityKeyframesLight = keyframes`
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.6);
  }
`;

const opacityKeyframesDark = keyframes`
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const blurAnimation = keyframes`
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(4px);
  }
`;

export const ExternalCloseButton = styled.div`
  position: absolute;
  right: -12px;
  top: 0px;
  pointer-events: auto;
`;

const getAnimationName = (
  animation: Keyframes,
  blurBackground?: boolean
): FlattenSimpleInterpolation => {
  if (!blurBackground) {
    return css`
      animation-name: ${animation};
    `;
  }
  return css`
    animation-name: ${animation}, ${blurAnimation};
  `;
};

export const Background = styled.div<{
  disableClose?: boolean;
  externalCloseButton?: boolean;
  blurBackground?: boolean;
  state: ModalState;
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: auto;
  cursor: ${({ disableClose, externalCloseButton }) =>
    disableClose || externalCloseButton ? 'default' : 'pointer'};

  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;

  ${({ blurBackground }) =>
    getAnimationName(opacityKeyframesLight, blurBackground)};
  background-color: ${({ state }) =>
    state.closing || !state.isOpen ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.6)'};

  html[data-theme='dark'] && {
    ${({ blurBackground }) =>
      getAnimationName(opacityKeyframesDark, blurBackground)};
    background-color: ${({ state }) =>
      state.closing || !state.isOpen
        ? 'rgba(0, 0, 0, 0)'
        : 'rgba(0, 0, 0, 0.4)'};
  }

  transition: background-color ease-in-out 0.25s;
  z-index: ${themeStyle.zIndexM1};
  backdrop-filter: ${({ blurBackground }) =>
    blurBackground ? 'blur(4px)' : 'unset'};
`;

const noScrollBar = css`
  overflow-y: scroll;
  ${hideScrollBar}
`;

interface ModalWrapperProps {
  width: string;
  height: string;
  noOverflowX?: boolean;
  noShadow?: boolean;
}

const modalWrapperCommonStyle = css<ModalWrapperProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-width: 100%;
  max-height: 100%;
  overflow-x: ${({ noOverflowX }) => (noOverflowX ? 'hidden' : 'visible')};
  pointer-events: auto;

  background: ${themeStyle.varBackgroundAlt};
  border-radius: ${themeStyle.radiusLarge};
  box-shadow: ${({ noShadow }) =>
    noShadow ? 'none' : themeStyle.varShadowHeavy};

  ${darkBackgroundBlur}
`;

export const ModalWrapperWithScrollbar = styled(
  VerticallyScrollable
)<ModalWrapperProps>`
  ${modalWrapperCommonStyle}
`;

export const ModalWrapper = styled.div<
  ModalWrapperProps & {
    noOverflow?: boolean;
    hideScrollbar?: boolean;
  }
>`
  ${modalWrapperCommonStyle};
  overflow-y: ${({ noOverflow }) => (noOverflow ? 'hidden' : 'auto')};
  ${({ hideScrollbar }) => hideScrollbar && noScrollBar}
`;

export const ContentWrapper = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ForegroundWrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  padding: 55px 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: ${themeStyle.zIndexM1};
`;

export const Foreground = styled.div<{ state: ModalState }>`
  max-width: 100%;
  max-height: 100%;
  position: relative;
  display: flex;

  animation-name: ${scaleKeyframes};
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;

  transform: ${({ state }) =>
    ((state.closing || !state.isOpen) && 'scale(0)') || 'scale(1)'};
  transition: transform ease-in-out 0.25s;
  will-change: transform;
`;
