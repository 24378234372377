import styled from 'styled-components';
import { fadeIn } from '../../../../utilities/styles';

export const ButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;

  opacity: 0;
  transition: opacity 0.3s;
`;

export const Wrapper = styled.div.attrs(({ background }) => ({
  style: {
    backgroundColor: background,
  },
}))`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  user-select: none;
  border-radius: 5px;
  margin-bottom: 8px;

  filter: contrast(${({ isActive }) => (isActive ? '1' : '0.5')});
  transition: filter 500ms;
  -webkit-touch-callout: none;
`;

export const PreviewImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 6px;
`;

export const BorderBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.borderColor};
  opacity: 0.5;
  border-radius: 5px;

  z-index: 1;
  pointer-events: none;
`;

export const OuterWrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
  cursor: pointer;
  animation: ${fadeIn};
  animation-duration: 1s;

  &:hover {
    ${ButtonsWrapper} {
      opacity: 1;
    }
    ${Wrapper} {
      filter: contrast(1);
    }
  }
`;
