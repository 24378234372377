import { themeStyle } from '../../services/theming';

export const defaultTheme = {
  colorDropIndicator: themeStyle.varSelectionBlue,
  colorDropContainerOutline: themeStyle.varSelectionBlue,
  colorVerticalContainerLine: themeStyle.transparent,
  colorSelectedLayer: 'red',
  colorTargetLayer: themeStyle.varInkMedium,
  borderColor: themeStyle.varInkSuperLight,
};

export const layerIconTheme = {
  color: themeStyle.varInkMedium,
  activeDefaultColor: themeStyle.varInkMain,
  activeColor: themeStyle.transparent,
};

export const layerIconThemeGroup = {
  color: themeStyle.varInkMedium,
  activeDefaultColor: themeStyle.varInkMain,
  activeColor: themeStyle.varBrandLight,
};

export const layerActionsIconTheme = {
  color: themeStyle.varInkLight,
  activeDefaultColor: themeStyle.varInkMedium,
  activeColor: themeStyle.varInkMedium,
};

export const layerChevronTheme = {
  color: themeStyle.varInkMedium,
  activeColor: themeStyle.varInkMain,
};
