import styled, { keyframes, css } from 'styled-components';
import { themeStyle } from '../../../services/theming';

const shimmer = keyframes`
  from {
    background-position: -468px 0
  }
  to {
    background-position: 468px 0
  }
`;

const profileStyles = css<any>`
  border-radius: 50%;
  width: ${({ theme }) => theme.size};
  height: ${({ theme }) => theme.size};
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  cursor: ${({ hasOnClick }) => (hasOnClick ? 'pointer' : 'unset')};
`;

export const ProfileImg = styled.img.attrs<any>(({ src }) => {
  return {
    src,
  };
})<any>`
  ${profileStyles}
  object-fit: cover;
`;

export const ProfileImageFallback = styled.div<any>`
  ${profileStyles}

  background: ${themeStyle.varBrandMedium};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  color: ${themeStyle.varBackgroundAlt};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fallbackFontSize};
`;

export const Loading = styled.div<any>`
  ${profileStyles}

  animation: ${shimmer};
  animation-duration: 2.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    ${themeStyle.inkLight},
    ${themeStyle.inkBase} 50%,
    ${themeStyle.inkLight} 100%
  );
  background-size: 1000px;
`;
