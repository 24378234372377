import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Background,
  BackLabel,
  FolderTitle,
  MasonryWrapper,
  UnauthenticatedHint,
} from './styles';
import useDesignsStore, {
  designsStoreSelector,
} from '../../../../stores/designsStore';
import { useUserStore, userStoreSelector } from '../../../../stores/userStore';
import { buildImageProxyUrl } from '../../../../utils/url';
import { ADD_DESIGN } from '../../../../global/events';
import Masonry from '../../../Masonry/Masonry';
import Design from './Design/Design';
import ProjectFoldersModal from '../../../ProjectFoldersModal/ProjectFoldersModal';
import Folder from '../../../ProjectFoldersModal/Folder/Folder';
import { smallTheme } from '../../../ProjectFoldersModal/Folder/theme';
import { FlexColumn, FlexRow } from '../../../utilities/styles';
import VerticallyScrollable from '../../../VerticallyScrollable/VerticallyScrollable';
import Spacer from '../../../Spacer/Spacer';
import DesignButtons from './DesignButtons/DesignButtons';
import FolderButtons from './FolderButtons/FolderButtons';
import { P2 } from '../../../utilities/Typography/styles';
import Button from '../../../Button/Button/Button';
import { secondaryTheme } from '../../../Button/Button/theme';

const Designs = (props) => {
  const { dispatch } = props;
  const authenticated = useUserStore(userStoreSelector.isLoggedIn);

  const elements = useDesignsStore(designsStoreSelector.elements);
  const active = useDesignsStore(designsStoreSelector.active);
  const fetch = useDesignsStore(designsStoreSelector.fetch);

  const [folder, setFolder] = useDesignsStore(designsStoreSelector.useFolder);
  const folderFetch = useDesignsStore(designsStoreSelector.folderFetch);
  const folderDesigns = useDesignsStore(designsStoreSelector.folderDesigns);
  const folderTotal = useDesignsStore(designsStoreSelector.folderTotal);
  const folderIsFetching = useDesignsStore(
    designsStoreSelector.folderIsFetching
  );
  const [moveDesignId, setMoveDesignId] = useState(null);

  const addDesign = useCallback(
    async (value) => {
      if (value.isActive) return;
      dispatch(ADD_DESIGN, value);
    },
    [dispatch]
  );

  const closeFolder = () => setFolder(null);

  const openFolder = useCallback(
    (folder) => {
      setFolder(folder);
    },
    [setFolder]
  );

  useEffect(() => {
    if (folder) {
      folderFetch();
    }
  }, [folder, folderFetch]);

  const renderElement = useCallback(
    ({ type, ...elementProps }) => {
      if (type === 'folder') {
        return (
          <Folder
            folder={elementProps}
            theme={smallTheme}
            onClick={() => openFolder(elementProps)}
            hasContrastTransition
            overlay={<FolderButtons id={elementProps.id} />}
          />
        );
      }

      const designProps = {
        id: elementProps.id,
        name: elementProps.name,
        preview: buildImageProxyUrl(`api/${elementProps.preview}`, {
          width: 270,
          height: 270,
          scale: 2,
        }),
        artboardOptions: elementProps.artboardOptions,
      };

      return (
        <Design
          design={designProps}
          isActive={active === elementProps.id}
          buttonsComponent={
            <DesignButtons
              id={elementProps.id}
              preview={elementProps.preview}
              isActive={active === elementProps.id}
              folderId={elementProps.folderId}
              dispatch={dispatch}
              onMove={() => setMoveDesignId(elementProps.id)}
            />
          }
          onDrop={addDesign}
          dispatch={dispatch}
        />
      );
    },
    [addDesign, openFolder, dispatch, active]
  );

  if (!authenticated)
    return (
      <UnauthenticatedHint>
        To access your projects, you need to be logged in.
      </UnauthenticatedHint>
    );

  return (
    <Background isGroup={!!folder}>
      {folder && (
        <>
          <FlexRow alignItems="center" justifyContent="space-between">
            <FolderTitle noTextOverflow>{`${folder.name}${
              folderTotal !== null ? ` (${folderTotal})` : ''
            }`}</FolderTitle>
            <BackLabel isGroup={!!folder} onClick={closeFolder}>
              Go Back
            </BackLabel>
          </FlexRow>
          <Spacer h="10px" />
        </>
      )}
      <MasonryWrapper>
        {!!folder && (
          <>
            {!!folderDesigns.length && (
              <VerticallyScrollable>
                <Masonry
                  minColumnWidth={128}
                  gap={10}
                  items={folderDesigns}
                  fetch={folderFetch}
                  render={renderElement}
                  transitioning={props.transitioning}
                  equalDimensions
                />
              </VerticallyScrollable>
            )}
            {!folderDesigns.length && !folderIsFetching && (
              <FlexColumn alignItems="center">
                <Spacer h="48px" />
                <P2>This folder is currently empty.</P2>
                <Spacer h="12px" />
                <Button
                  label="Go Back"
                  icon={{ name: 'chevronLeft', height: '16px' }}
                  height="40px"
                  onClick={closeFolder}
                  theme={secondaryTheme}
                />
              </FlexColumn>
            )}
          </>
        )}
        {!folder && (
          <VerticallyScrollable>
            <Masonry
              minColumnWidth={140}
              gap={10}
              items={elements}
              fetch={fetch}
              render={renderElement}
              transitioning={props.transitioning}
              equalDimensions
            />
          </VerticallyScrollable>
        )}
      </MasonryWrapper>
      <ProjectFoldersModal
        isOpen={!!moveDesignId}
        projectIds={[moveDesignId]}
        onClose={() => setMoveDesignId(null)}
      />
    </Background>
  );
};

Designs.propTypes = {
  id: PropTypes.string,
  dispatch: PropTypes.func,
  transitioning: PropTypes.bool,
};

export default Designs;
