import React, { useEffect, useLayoutEffect } from 'react';
import {
  useNotificationStore,
  notificationStoreSelector,
} from '../../../stores/notificationStore';
import { PanelWrapper, Loader } from './styles';
import Spinner from '../../utilities/Spinner/Spinner';
import NoNotifications from './NoNotifications';
import { useInView } from 'react-intersection-observer';
import NotificationsRenderer from './NotificationsRenderer';

/**
 * A panel to display a list of notifications
 */
function Panel(props) {
  const notifications = useNotificationStore(
    notificationStoreSelector.notifications
  );
  const isLoading = useNotificationStore(notificationStoreSelector.isLoading);
  const finished = useNotificationStore(notificationStoreSelector.finished);

  const fetch = useNotificationStore(notificationStoreSelector.fetch);
  const handleSeeNotificationPanel = useNotificationStore(
    notificationStoreSelector.handleSeeNotificationPanel
  );
  const handleHasSeenNotificationPanel = useNotificationStore(
    notificationStoreSelector.handleHasSeenNotificationPanel
  );
  const setReadNotification = useNotificationStore(
    notificationStoreSelector.setReadNotification
  );

  useLayoutEffect(() => {
    // on opening
    handleSeeNotificationPanel();

    return () => {
      // on close
      handleHasSeenNotificationPanel();
    };
  }, [handleSeeNotificationPanel, handleHasSeenNotificationPanel]);

  // Trigger fetching data
  const { ref: loaderRef, inView: loaderVisible } = useInView();
  useEffect(() => {
    if (loaderVisible) {
      fetch();
    }
  }, [loaderVisible, fetch]);

  if (!isLoading && !notifications.length) {
    return (
      <>
        <NoNotifications />
        {!(isLoading || finished) && <Loader ref={loaderRef} />}
      </>
    );
  } else {
    return (
      <PanelWrapper>
        <NotificationsRenderer
          notifications={notifications}
          setReadNotification={setReadNotification}
        />
        {isLoading && <Spinner size="16px" style={{ padding: '20px 0' }} />}
        {!(isLoading || finished) && <Loader ref={loaderRef} />}
      </PanelWrapper>
    );
  }
}

export default Panel;
