/**
 * events that should be tracked in customer io
 * see: https://www.notion.so/kittl/f9b5d43a16104522b080c179687edc23?v=4cad7ccf0c4442b6b40151f1bf44646f
 */

import {
  ACTIVE_DECORATION,
  ACTIVE_SHADOW,
  ACTIVE_TRANSFORM,
  ADD_ILLUSTRATION,
  ADD_TEMPLATE,
  ADD_TEXTURE,
  ADD_TEXT_LAYOUT,
  DESIGN_DELETE,
  DESIGN_NEW,
  DESIGN_SHARE,
  DOWNLOAD,
  POST_SUBMIT,
  SET_COLOR_PALETTE,
  SET_COLOR_PALETTE_PRESET,
  SOCIAL_SHARE,
} from '../events';
import {
  FOLLOW_CREATE,
  SHORTCUTS_MENU_OPEN,
  SIGNUP_MOBILE,
  TEMPLATE_BOOKMARK,
} from './events';

const customerIoEvents = [
  // app events
  DESIGN_NEW,
  ADD_ILLUSTRATION,
  ADD_TEXT_LAYOUT,
  ACTIVE_TRANSFORM,
  ACTIVE_SHADOW,
  ACTIVE_DECORATION,
  DESIGN_DELETE,
  ADD_TEMPLATE,
  DESIGN_SHARE,
  POST_SUBMIT,
  SOCIAL_SHARE,
  SET_COLOR_PALETTE,
  SET_COLOR_PALETTE_PRESET,
  DOWNLOAD,
  ADD_TEXTURE,
  // analytics events
  TEMPLATE_BOOKMARK,
  SHORTCUTS_MENU_OPEN,
  FOLLOW_CREATE,
  SIGNUP_MOBILE,
];

export default customerIoEvents;
