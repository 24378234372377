/**
 * utils that are used in the editor project
 * TODO: re-organize utils inside
 */

import { fabric } from 'fabric';
import { getCircleParameterFromPoints } from './shapes';

export const adjustToView = (x, y, canvas) => {
  const zoom = canvas ? canvas.getZoom() : 1;
  const viewportOffsetX = canvas ? canvas.viewportTransform[4] : 0;
  const viewportOffsetY = canvas ? canvas.viewportTransform[5] : 0;
  return { x: x * zoom + viewportOffsetX, y: y * zoom + viewportOffsetY };
};

//Used for both freeLine and circle
export function pathTransformPositionHandler(dim, finalMatrix, fabricObject) {
  const x = fabricObject.points[this.pointIndex].x;
  const y = fabricObject.points[this.pointIndex].y;
  return adjustToView(x, y, fabricObject.canvas);
}

export function circleRotationHandlePositionHandler(
  dim,
  finalMatrix,
  fabricObject
) {
  const points = fabricObject.points;
  const top = points[1];
  const { center } = getCircleParameterFromPoints(points);

  const topToCenter = { x: top.x - center.x, y: top.y - center.y };
  const topToCenterDist = Math.hypot(topToCenter.x, topToCenter.y);
  const topToHandleDist = Math.max(
    0.75 * (fabricObject.xHeight * fabricObject.fontSize),
    15
  );
  const distFactor = topToHandleDist / topToCenterDist;

  const handleVector = { ...topToCenter };
  handleVector.x *= distFactor;
  handleVector.y *= distFactor;

  return adjustToView(
    top.x + handleVector.x,
    top.y + handleVector.y,
    fabricObject.canvas
  );
}

export const dontPropagate = (handler) => {
  return function (event) {
    event.stopPropagation();
    handler(event);
  };
};

/**
 * get center coordinates from these sources in order:
 * viewport, artboard height/width, default to 0,0
 * @param {Object} options Standard options object
 * returns an object with the shape { centerX: number, centerY: number }
 */
export const getCenterFromOptions = (options) => {
  // calculate based on viewport coords, if present
  if (options?.canvas?.vptCoords?.tr && options?.canvas?.vptCoords?.bl) {
    const { tr, bl } = options.canvas.vptCoords;
    return {
      centerX: (tr.x + bl.x) / 2,
      centerY: (bl.y + tr.y) / 2,
    };
  }

  // calculate based on artboard height/width, if present
  if (options?.canvas?.artboard) {
    const artboard = options.canvas.artboard;
    return {
      centerX: artboard.width / 2,
      centerY: artboard.height / 2,
    };
  }

  // default to 0, 0
  return { centerX: 0, centerY: 0 };
};

/**
 * utility function to test whether a rotation event is an altKey rotation
 * around the top/left of the target
 * Just testing `e.transform.altKey` does not work, since this is false, if the rotation
 * was started with the altKey, but the key was released before the end of it.
 * @param {*} e
 */
export const isAltKeyRotation = (e) => {
  const { original, target } = e.transform;
  const TOLERANCE = 0.0001; // top/left are tracked with 13 decimal places
  if (
    Math.abs(original.top - target.top) < TOLERANCE &&
    Math.abs(original.left - target.left) < TOLERANCE
  ) {
    return true;
  }
  return false;
};

/* Receives an array of transform matrices and returns the combined transform */
export const composeTransforms = (matrixArray) => {
  //Reverse array since T1(T2(X)) = (T1 * T2)X
  return matrixArray.reverse().reduce((acc, matrix) => {
    return fabric.util.multiplyTransformMatrices(acc, matrix);
  });
};

export const lockToAxis = (target, lockObj, pointer) => {
  const distY = Math.abs(lockObj.pointer.y - pointer.y);
  const distX = Math.abs(lockObj.pointer.x - pointer.x);

  distY > distX ? (target.left = lockObj.left) : (target.top = lockObj.top);
};

export const lockPointToAxis = (currentPosition, lastPosition) => {
  const distX = Math.abs(lastPosition.x - currentPosition.x);
  const distY = Math.abs(lastPosition.y - currentPosition.y);

  const locked = currentPosition;
  distX > distY ? (locked.y = lastPosition.y) : (locked.x = lastPosition.x);

  return locked;
};

export const filterColorPalette = (colorPalette, isGroup) => {
  const palette = { ...colorPalette };
  for (const rgb in palette) {
    /*
      A color user is thought of as an object id and a property of that object,
      that is associated with a color.
      E.g, { objectId: someID, key: 'some_property' } <-- This is not an exact interface
    */
    const colorUsers = palette[rgb];
    // filter out colors on invisible objects
    const someVisible = colorUsers.some((user) => user.visible);
    // and invisible colors (if not for a group palette)
    if ((!isGroup && rgb === 'null') || !someVisible) delete palette[rgb];
  }
  return palette;
};

/*
  Turns any array into an array of bucketCount arrays,
  all populated as uniformly as possible with the elements of array.
*/
export const arrayToBuckets = (array, bucketCount) => {
  if (bucketCount < 1) return null;

  const bucketSize = Math.floor(array.length / bucketCount);

  let remainder = array.length % bucketCount;

  let remainderGap = remainder ? Math.floor(bucketCount / remainder) : 0;

  if (remainderGap && remainder - remainderGap <= bucketCount % remainder) {
    // In this specific case the gap can be increased by 1
    remainderGap++;
  }

  const adjustCount = (count, index) => {
    if (!remainder) return count;
    if (index % remainderGap === 0) {
      remainder--;
      return count + 1;
    }
    return count;
  };

  const buckets = Array(bucketCount)
    .fill(null)
    .map(() => bucketSize)
    .map(adjustCount);

  let totalPushed = 0;
  return buckets.map((count) => {
    const elements = array.slice(totalPushed, count + totalPushed);
    totalPushed += count;
    return elements;
  });
};

export const updateCanvasSize = (boardEl, canvas) => {
  const rect = boardEl.getBoundingClientRect();
  canvas.setDimensions({
    width: rect.width,
    height: rect.height,
  });
  canvas.centerArtboard();
};
