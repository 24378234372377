import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Content } from './styles';
import InitialPayment from './InitialPayment';
import Modal from '../Modal/Modal';
import {
  Dispatcher,
  SubscriptionPlan,
  SubscriptionPlanInterval,
} from '../../types';
import { userStoreSelector, useUserStore } from '../../stores/userStore';
import { TOGGLE_SUBSCRIPTION_MODAL } from '../../global/events';
import { usePriceId } from './hooks/usePriceId';
import { useMonthlyFee } from './hooks/useMonthlyFee';

interface SubscriptionProps {
  dispatch: Dispatcher;
}

const SubscriptionModal: React.FC<SubscriptionProps> = ({ dispatch }) => {
  const user = useUserStore(userStoreSelector.user);
  const plan = useUserStore(userStoreSelector.plan);
  const updateUserInfo = useUserStore(userStoreSelector.updateUserInfo);
  const [intendedPlan, setIntendedPlan] = useState<null | SubscriptionPlan>(
    null
  );
  const [interval, setInterval] = useState<SubscriptionPlanInterval>(
    SubscriptionPlanInterval.YEAR
  );
  const priceId = usePriceId(intendedPlan, interval);
  const monthlyFee = useMonthlyFee(intendedPlan, interval);
  const [disablePlanSelecting, setDisablePlanSelecting] = useState(false);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);

  useEffect(() => {
    if (plan) return;

    if (plan === SubscriptionPlan.PRO) {
      setIntendedPlan(SubscriptionPlan.EXPERT);
    } else {
      setIntendedPlan(SubscriptionPlan.PRO);
    }
  }, [plan]);

  const handleIntendedPlanChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIntendedPlan(event.target.value as SubscriptionPlan);
    },
    []
  );

  const handleIntervalChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInterval(event.target.value as SubscriptionPlanInterval);
    },
    []
  );

  const onModalClose = useCallback(() => {
    dispatch(TOGGLE_SUBSCRIPTION_MODAL, false);
  }, [dispatch]);

  const onInitialPaymentLoading = useCallback(() => {
    setDisablePlanSelecting(true);
  }, []);

  const onInitialPaymentLoaded = useCallback(() => {
    setDisablePlanSelecting(false);
  }, []);

  const onInitialPaymentSuccess = useCallback(() => {
    setPaymentSucceeded(true);
    // update user after payment succeeded
    updateUserInfo();
  }, [updateUserInfo]);

  return (
    <Modal isOpen={true} onClose={onModalClose}>
      <Content>
        {paymentSucceeded ? (
          <div>
            <p>Congrats! Your are now with the {intendedPlan} plan.</p>
          </div>
        ) : (
          <>
            <div>
              <span>Current plan:</span>
              {plan ?? 'FREE'}
            </div>
            <div>
              <span>Change to:</span>
              <label>
                PRO:
                <input
                  type="radio"
                  name="plan"
                  id="PRO"
                  value="PRO"
                  disabled={disablePlanSelecting}
                  checked={intendedPlan === SubscriptionPlan.PRO}
                  onChange={handleIntendedPlanChange}
                />
              </label>
              <label>
                EXPERT:
                <input
                  type="radio"
                  name="plan"
                  id="EXPERT"
                  value="EXPERT"
                  disabled={disablePlanSelecting}
                  checked={intendedPlan === SubscriptionPlan.EXPERT}
                  onChange={handleIntendedPlanChange}
                />
              </label>
            </div>
            <div>
              <span>Bill</span>
              <label>
                yearly:
                <input
                  type="radio"
                  name="interval"
                  id="year"
                  value="year"
                  disabled={disablePlanSelecting}
                  checked={interval === SubscriptionPlanInterval.YEAR}
                  onChange={handleIntervalChange}
                />
              </label>
              <label>
                monthly:
                <input
                  type="radio"
                  name="interval"
                  id="month"
                  value="month"
                  disabled={disablePlanSelecting}
                  checked={interval === SubscriptionPlanInterval.MONTH}
                  onChange={handleIntervalChange}
                />
              </label>
            </div>
            <div>${monthlyFee} / month</div>
            {user && !plan && priceId && (
              <InitialPayment
                priceId={priceId}
                onSuccess={onInitialPaymentSuccess}
                onLoading={onInitialPaymentLoading}
                onLoaded={onInitialPaymentLoaded}
              />
            )}
          </>
        )}
      </Content>
    </Modal>
  );
};

export default memo(SubscriptionModal);
