// needed when object uses _finalizeDimensions = (x, y) => ({ x, y });
const ignoreStrokeTypes = [
  'basicShape',
  'illustration',
  'circle',
  'rect',
  'editablePolygon',
  'pathText',
];

const initObjectInteractivity = function (fabric) {
  /* 
        We need to override this function since we draw pathText borders "to the inside" of the text,
        so when making them part of an active selection, we get bigger bounds than we should. This takes
        care of that. If the object is of type === patText, this function "sees" that it has a strokeWidth
        of zero, but stroke is being handled all the same in the pathText class
    */

  /**
   * Draws borders of an object's bounding box when it is inside a group.
   * Requires public properties: width, height
   * Requires public options: padding, borderColor
   * @param {CanvasRenderingContext2D} ctx Context to draw on
   * @param {object} options object representing current object parameters
   * @param {Object} styleOverride object to override the object style
   * @return {fabric.Object} thisArg
   * @chainable
   */
  fabric.Object.prototype.drawBordersInGroup = function (
    ctx,
    options,
    styleOverride
  ) {
    styleOverride = styleOverride || {};
    const bbox = fabric.util.sizeAfterTransform(
        this.width,
        this.height,
        options
      ),
      strokeWidth = !ignoreStrokeTypes.includes(this.type)
        ? this.strokeWidth
        : 0, //---> We changed this
      strokeUniform = this.strokeUniform,
      borderScaleFactor = this.borderScaleFactor,
      width =
        bbox.x +
        strokeWidth * (strokeUniform ? this.canvas.getZoom() : options.scaleX) +
        borderScaleFactor,
      height =
        bbox.y +
        strokeWidth * (strokeUniform ? this.canvas.getZoom() : options.scaleY) +
        borderScaleFactor;
    ctx.save();
    this._setLineDash(
      ctx,
      styleOverride.borderDashArray || this.borderDashArray,
      null
    );
    ctx.strokeStyle = styleOverride.borderColor || this.borderColor;
    ctx.strokeRect(-width / 2, -height / 2, width, height);

    ctx.restore();
    return this;
  };

  /* 
    We override this function since there are cases when we don't want controls
    to actually have some place where they react to a cursor. E.g transform lines.
    To skip an individual control from being targeted, add 
    skipTargetFind: true as one of its properties.
  */
  fabric.Object.prototype.isControlVisible = function (controlKey) {
    return (
      this.controls[controlKey] &&
      this.controls[controlKey].getVisibility(this, controlKey) &&
      !this.controls[controlKey].skipTargetFind
    );
  };
};

export default initObjectInteractivity;
