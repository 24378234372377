import zip from 'lodash/zip';
import { getDistance } from '../../utils/geometry/point';
import { getDifference, getMidPoint } from '../../utils/geometry/vector';
import {
  dispatchMouseEventFromTouch,
  mouseEventDefaults,
} from './EventForwarding';
import { distance, pointFromTouch } from './util';
import fabric from '../../components/Artboard/fabric/index';
import { useMenuStore } from '../../stores/menuStore';

const TOLERANCE = 1e-8;
const DOUBLE_CLICK_DISTANCE = 35;
const DOUBLE_CLICK_INTERVAL = 500;
const THREE_FINGER_GESTURE_THRESHOLD = 15;

function createTapHandler(canvas) {
  let doubleTapAwaiter;
  let lastTap;

  function handleTap(args) {
    const upperCanvas = canvas.upperCanvasEl;
    const {
      taps: [tap],
    } = args;

    if (doubleTapAwaiter) {
      dispatchMouseEventFromTouch(tap, 'mousedown', upperCanvas);
      dispatchMouseEventFromTouch(tap, 'mouseup', upperCanvas);

      if (distance(tap, lastTap) < DOUBLE_CLICK_DISTANCE) {
        dispatchMouseEventFromTouch(tap, 'dblclick', upperCanvas);
      }

      clearTimeout(doubleTapAwaiter);
      doubleTapAwaiter = null;
    } else {
      dispatchMouseEventFromTouch(tap, 'mousedown', upperCanvas);
      dispatchMouseEventFromTouch(tap, 'mouseup', upperCanvas);
      doubleTapAwaiter = setTimeout(
        () => (doubleTapAwaiter = null),
        DOUBLE_CLICK_INTERVAL
      );
    }

    lastTap = tap;
  }

  return handleTap;
}

function handleOneFingerGesture(canvas) {
  let currentTouch = null;
  const upperCanvas = canvas.upperCanvasEl;

  function onStart(args) {
    const {
      startTouches: [onlyStartTouch],
    } = args;
    currentTouch = onlyStartTouch;
    dispatchMouseEventFromTouch(onlyStartTouch, 'mousedown', upperCanvas);
  }

  function onChange(args) {
    const { touches } = args;

    // Check that the initial touch hasn't been lifted up.
    // The gesture could still be in progress if a modifier is still
    // in contact with the screen.
    if (touches.length !== 1) return;
    const touch = touches[0];
    currentTouch = touch;
    dispatchMouseEventFromTouch(touch, 'mousemove', upperCanvas);
  }

  function onEnd() {
    dispatchMouseEventFromTouch(currentTouch, 'mouseup', upperCanvas);
  }

  return {
    onStart,
    onChange,
    onEnd,
  };
}

function handleTwoFingerGesture(canvas) {
  let startVpt = null;

  function onStart() {
    startVpt = canvas.viewportTransform.slice();

    // If right click menu was open, close it when starting the gesture
    document.dispatchEvent(new Event('closeRightClickMenu'));
  }

  function onChange(args) {
    const { touches, startTouches } = args;

    // For now, we only support the most basic case
    // So, if touches.length !== 2, i.e, one of the two initial fingers has
    // been lifted up, gesture stops.
    // Later we can support more complex behavior
    if (touches.length !== 2) return;

    const firstTouch = pointFromTouch(touches[0]);
    const secondTouch = pointFromTouch(touches[1]);
    const firstStartTouch = pointFromTouch(startTouches[0]);
    const secondStartTouch = pointFromTouch(startTouches[1]);

    const midPoint = getMidPoint([firstTouch, secondTouch]);
    const startMidPoint = getMidPoint([firstStartTouch, secondStartTouch]);
    const midPointOffset = getDifference(midPoint, startMidPoint);

    let startDistance = getDistance(firstStartTouch, secondStartTouch);
    let distance = getDistance(firstTouch, secondTouch);

    // Distances represent physical distances between
    // points of contact in a screen, so they should never be zero.
    // But we are still safe just in case
    startDistance = Math.max(startDistance, TOLERANCE);
    distance = Math.max(distance, TOLERANCE);
    const scale = distance / startDistance;

    const startZoom = startVpt[0];
    const updatedVpt = startVpt.slice();

    const startMidPointBefore = fabric.util.transformPoint(
      startMidPoint,
      fabric.util.invertTransform(startVpt)
    );

    const newZoom = canvas.adjustZoom(scale * startZoom);
    updatedVpt[0] = updatedVpt[3] = newZoom;

    const startMidPointAfter = fabric.util.transformPoint(
      startMidPointBefore,
      updatedVpt
    );

    const rawViewportX =
      startVpt[4] + startMidPoint.x - startMidPointAfter.x + midPointOffset.x;
    const rawViewportY =
      startVpt[5] + startMidPoint.y - startMidPointAfter.y + midPointOffset.y;

    const { x: viewportX, y: viewportY } = canvas.getViewportPos(
      rawViewportX,
      rawViewportY,
      newZoom
    );
    updatedVpt[4] = viewportX;
    updatedVpt[5] = viewportY;

    canvas.setViewportTransform(updatedVpt);
    canvas.requestRenderAll();
    useMenuStore.getState().setZoomDebounced(newZoom);
  }

  function onEnd(args) {} // Not needed atm

  return {
    onStart,
    onChange,
    onEnd,
  };
}

export function handleThreeFingerGesture(canvas, canvasContainerElement) {
  let openedRightMenu;

  function onStart() {
    openedRightMenu = false;
  }

  function onChange(args) {
    if (!canvas.getActiveObject()) {
      // Only open right menu if there is an already selected object
      return;
    }

    const { touches, startTouches } = args;
    if (touches.length !== 3 || openedRightMenu) {
      // If one or more fingers were lifted up, return
      // If the right menu was already opened, return
      return;
    }

    const orderedTouches = touches.sort((a, b) => a - b);
    const orderedStartTouches = startTouches.sort((a, b) => a - b);
    const touchPairs = zip(orderedStartTouches, orderedTouches);

    const isSwipingDown = touchPairs.every((pair) => {
      const [startTouch, touch] = pair;
      return startTouch.clientY < touch.clientY;
    });

    if (!isSwipingDown) return;

    const swipedDownEnough = touchPairs.some((pair) => {
      const [startTouch, touch] = pair;
      return distance(startTouch, touch) > THREE_FINGER_GESTURE_THRESHOLD;
    });

    if (swipedDownEnough) {
      const allPoints = [
        ...touches.map((touch) => pointFromTouch(touch)),
        ...startTouches.map((startTouch) => pointFromTouch(startTouch)),
      ];

      const midPoint = getMidPoint(allPoints);

      const mouseEvent = new MouseEvent('contextmenu', {
        ...mouseEventDefaults,
        clientX: midPoint.x,
        clientY: midPoint.y,
        screenX: midPoint.x,
        screenY: midPoint.y,
        offsetX: midPoint.x,
        offsetY: midPoint.y,
      });
      canvasContainerElement.dispatchEvent(mouseEvent);

      openedRightMenu = true;
    }
  }

  function onEnd() {} // Not needed atm

  return {
    onStart,
    onChange,
    onEnd,
  };
}

export function createGestureHandlers(canvas, canvasContainerElement) {
  const oneFingerHandlers = handleOneFingerGesture(canvas);
  const twoFingerHandlers = handleTwoFingerGesture(canvas);
  const threeFingerHandlers = handleThreeFingerGesture(
    canvas,
    canvasContainerElement
  );
  const tapHandler = createTapHandler(canvas);

  return {
    onStart: {
      1: oneFingerHandlers.onStart,
      2: twoFingerHandlers.onStart,
      3: threeFingerHandlers.onStart,
    },
    onChange: {
      1: oneFingerHandlers.onChange,
      2: twoFingerHandlers.onChange,
      3: threeFingerHandlers.onChange,
    },
    onEnd: {
      1: oneFingerHandlers.onEnd,
      2: twoFingerHandlers.onEnd,
      3: threeFingerHandlers.onEnd,
    },
    onTap: {
      1: (args) => tapHandler(args),
    },
  };
}
