import React from 'react';
import PropTypes from 'prop-types';

import {
  Navigation,
  NavButtonWrapper,
  NavButton,
  OverlayLabel,
  OverlayHitbox,
} from './styles';
import IconEventWrapper from '../../Icon/IconEventWrapper';

import { useOnboardingStore } from '../../../stores/onboardingStore';
import IconButton from '../../Button/IconButton';
import Tooltip from '../../Tooltip/Tooltip';
import { ANCHOR_REF_KEYS } from '../../../stores/onboardingStore/constants';
import {
  Theme,
  themeStoreSelector,
  themeStyle,
  useThemeStore,
} from '../../../services/theming';

export const SIDE_MENU_TRANSITION = '0.5s ease-in-out';

/**
 * Utility component to add a navigation to a side menu.
 * This component is used as a navigation for LeftMenu and RightMenu
 */
const SideMenuNavigation = ({
  items,
  onClickItem,
  state,
  activeItemId,
  width,
  ...props
}) => {
  const theme = useThemeStore(themeStoreSelector.theme);
  const iconTheme = {
    color: state !== 'closed' ? themeStyle.varInkMedium : themeStyle.varInkMain,
    activeColor: themeStyle.varBrandMedium,
    activeDefaultColor:
      theme === Theme.DARK ? themeStyle.varBrandMedium : themeStyle.varInkMain,
    transition: SIDE_MENU_TRANSITION,
  };

  const handleRef = (node, item) => {
    let key = null;
    if (item.id === 'template') {
      key = ANCHOR_REF_KEYS.TEMPLATES_BUTTON;
    }
    if (item.id === 'elements') {
      key = ANCHOR_REF_KEYS.ELEMENTS_BUTTON;
    }

    if (key) {
      useOnboardingStore.getState().registerStepRef(node, key);
    }
  };

  return (
    <Navigation
      state={state}
      width={width}
      visible={props.visible}
      transition={SIDE_MENU_TRANSITION}
      menuIsMoving={props.menuIsMoving}
    >
      {items.map((item) => {
        const disabled = item.disabled;
        return (
          <Tooltip
            text={state !== 'closed' ? item.label : null}
            offsetX={10}
            offsetY={12}
            key={item.id}
          >
            <NavButtonWrapper
              data-testid={`${item.id}-panel-trigger`}
              onClick={() => !disabled && onClickItem(item.id)}
              disabled={disabled}
            >
              <IconEventWrapper
                fitParent={true}
                theme={iconTheme}
                disabled={disabled}
              >
                <NavButton ref={(node) => handleRef(node, item)}>
                  <IconButton
                    iconName={item.icon}
                    height="20px"
                    theme={iconTheme}
                    isActive={item.id === activeItemId && state !== 'closed'}
                    disabled={disabled}
                  />
                  {!disabled && (
                    <>
                      <OverlayHitbox
                        width={width}
                        transition={SIDE_MENU_TRANSITION}
                      />
                      <OverlayLabel
                        width={width}
                        transition={SIDE_MENU_TRANSITION}
                      >
                        {item.label}
                      </OverlayLabel>
                    </>
                  )}
                </NavButton>
              </IconEventWrapper>
            </NavButtonWrapper>
          </Tooltip>
        );
      })}
    </Navigation>
  );
};

SideMenuNavigation.propTypes = {
  /**
   * items of the navigation
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  /**
   * id of the currently active item
   */
  activeItemId: PropTypes.string,
  /**
   * current state of the menu
   * the navigation is only checking if state === 'closed' or not
   */
  state: PropTypes.string,
  /**
   * width of the navigation
   */
  width: PropTypes.string,
  /**
   * A function that is triggered when a navigation item is clicked
   * @param id of the clicked item
   */
  onClickItem: PropTypes.func.isRequired,
  /**
   * whether the navigation is visible
   */
  visible: PropTypes.bool,
  /**
   * whether the menu is currently moving
   */
  menuIsMoving: PropTypes.bool,
};

SideMenuNavigation.defaultProps = {
  state: 'closed',
  width: '50px',
  visible: true,
};

export default React.memo(SideMenuNavigation);
