import React from 'react';

import ProfileImage from '../Profile/ProfileImage/ProfileImage';
import { MinimalTestimonialWrapper, ProfileImageWrapper } from './styles';
import { H2, H6 } from '../utilities/Typography/styles';
import { themeStyle } from '../../services/theming';
import Spacer from '../Spacer/Spacer';

export enum MinimalTestimonialSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'marge',
}

interface MinimalTestimonialProps {
  name: string;
  title: string;
  image: string;
  size: MinimalTestimonialSize;
}

const MinimalTestimonial: React.FC<MinimalTestimonialProps> = ({
  name,
  title,
  image,
  size,
}) => {
  let imageSize;
  if (size === MinimalTestimonialSize.Large) {
    imageSize = 70;
  } else if (size === MinimalTestimonialSize.Medium) {
    imageSize = 60;
  } else {
    imageSize = 50;
  }

  return (
    <MinimalTestimonialWrapper size={size} imageSize={imageSize}>
      <ProfileImageWrapper imageSize={imageSize}>
        <ProfileImage
          user={{ profileImage: image, name }}
          theme={{ size: `${imageSize}px` }}
        />
      </ProfileImageWrapper>
      <H6 className="title" color={themeStyle.varBrandMedium}>
        {title}
      </H6>
      <H2 className="name">{name}</H2>
      <Spacer h="4px" />
    </MinimalTestimonialWrapper>
  );
};

export default React.memo(MinimalTestimonial);
