// This is what you may call action types in redux

// Artboard events
export const ARTBOARD_PREFIX = 'artboard';
export const ARTBOARD_STATE = `${ARTBOARD_PREFIX}:state`;
export const ARTBOARD_SELECTION = `${ARTBOARD_PREFIX}:selection`;
export const ARTBOARD_IS_LOADING = `${ARTBOARD_PREFIX}:isLoading`;

// Add new element events
export const ADD_PREFIX = 'add';
export const ADD_TEXT = `${ADD_PREFIX}:text`;
export const ADD_IMAGE = `${ADD_PREFIX}:image`;
export const ADD_ILLUSTRATION = `${ADD_PREFIX}:illustration`;
export const ADD_BASIC_SHAPE = `${ADD_PREFIX}:basicShape`;
export const ADD_TEXTURE = `${ADD_PREFIX}:texture`;
export const ADD_SQUARE = `${ADD_PREFIX}:square`;
export const ADD_CIRCLE = `${ADD_PREFIX}:circle`;
export const ADD_POLYGON = `${ADD_PREFIX}:polygon`;
export const ADD_TEMPLATE = `${ADD_PREFIX}:template`;
export const ADD_DESIGN = `${ADD_PREFIX}:design`;
export const ADD_TEXT_LAYOUT = `${ADD_PREFIX}:textLayout`;
export const ADD_PHOTO = `${ADD_PREFIX}:photo`;
export const ADD_MASK = `${ADD_PREFIX}:mask`;
export const ADD_MOCKUP_TEMPLATE = `${ADD_PREFIX}:mockupTemplate`;

// Edit artboard elements
export const EDIT_PREFIX = 'edit';
export const EDIT_TEXT = `${EDIT_PREFIX}:text`;
export const EDIT_SHAPE = `${EDIT_PREFIX}:shape`;
export const EDIT_TEXTURE = `${EDIT_PREFIX}:texture`;

// Active object events
export const ACTIVE_PREFIX = 'active';
export const STYLE_TAG = 'style'; //Events that change styles should have this property
export const ACTIVE_DELETE = `${ACTIVE_PREFIX}:delete`;
export const ACTIVE_OPACITY = `${ACTIVE_PREFIX}:opacity`;
export const ACTIVE_MOVE = `${ACTIVE_PREFIX}:move`;
export const ACTIVE_MOVE_BY = `${ACTIVE_PREFIX}:moveBy`;
export const ACTIVE_MOVED = `${ACTIVE_PREFIX}:moved`;
export const ACTIVE_COPY = `${ACTIVE_PREFIX}:copy`;
export const ACTIVE_PASTE = `${ACTIVE_PREFIX}:paste`;
export const ACTIVE_CUT = `${ACTIVE_PREFIX}:cut`;
export const ACTIVE_REFLECT_HORIZONTAL = `${ACTIVE_PREFIX}:reflectHorizontal`;
export const ACTIVE_REFLECT_VERTICAL = `${ACTIVE_PREFIX}:reflectVertical`;
export const ACTIVE_GROUP = `${ACTIVE_PREFIX}:group`;
export const ACTIVE_LOCK = `${ACTIVE_PREFIX}:lock`;
export const ACTIVE_ALIGN = `${ACTIVE_PREFIX}:align`;
export const ACTIVE_GLYPH = `${ACTIVE_PREFIX}:glyph`;
export const ACTIVE_OVERLAY_MODE = `${ACTIVE_PREFIX}:overlayMode`;
export const ACTIVE_COLOR = `${ACTIVE_PREFIX}:${STYLE_TAG}:color`;
export const ACTIVE_STROKE_WIDTH = `${ACTIVE_PREFIX}:${STYLE_TAG}:strokeWidth`;
export const ACTIVE_FONTSIZE = `${ACTIVE_PREFIX}:${STYLE_TAG}:fontSize`;
export const ACTIVE_LETTERSPACING = `${ACTIVE_PREFIX}:${STYLE_TAG}:letterSpacing`;
export const ACTIVE_FONTFAMILY = `${ACTIVE_PREFIX}:${STYLE_TAG}:fontFamily`;
export const ACTIVE_TEXTALIGNMENT = `${ACTIVE_PREFIX}:${STYLE_TAG}:textAlignment`;
export const ACTIVE_UPPERCASE = `${ACTIVE_PREFIX}:${STYLE_TAG}:uppercase`;
export const ACTIVE_UNDERLINE = `${ACTIVE_PREFIX}:${STYLE_TAG}:underline`;
export const ACTIVE_LIGATURES = `${ACTIVE_PREFIX}:${STYLE_TAG}:ligatures`;
export const ACTIVE_LINEHEIGHT = `${ACTIVE_PREFIX}:${STYLE_TAG}:lineHeight`;
export const ACTIVE_VARIATION = `${ACTIVE_PREFIX}:${STYLE_TAG}:variation`;
export const ACTIVE_SHADOW = `${ACTIVE_PREFIX}:${STYLE_TAG}:shadow`;
export const ACTIVE_TRANSFORM = `${ACTIVE_PREFIX}:${STYLE_TAG}:transform`;
export const ACTIVE_TRANSFORM_CURVE = `${ACTIVE_PREFIX}:${STYLE_TAG}:transformCurve`;
export const ACTIVE_DECORATION = `${ACTIVE_PREFIX}:${STYLE_TAG}:decoration`;
export const ACTIVE_CLIPPING_MASK = `${ACTIVE_PREFIX}:clippingMask`;
export const ACTIVE_EDIT_MODE = `${ACTIVE_PREFIX}:editMode`;
export const ACTIVE_TIDY = `${ACTIVE_PREFIX}:tidy`;
export const ACTIVE_REMOVE_BG = `${ACTIVE_PREFIX}:removeBackground`;
export const ACTIVE_FILTER = `${ACTIVE_PREFIX}:filter`;
export const ACTIVE_FILTER_RESET = `${ACTIVE_PREFIX}:filterReset`;

// Layer events
export const LAYER_PREFIX = 'layer';
export const LAYER_LOCKED = `${LAYER_PREFIX}:locked`;
export const LAYER_HIDDEN = `${LAYER_PREFIX}:hidden`;
export const LAYER_MOVE = `${LAYER_PREFIX}:move`;
export const LAYER_SELECTION = `${LAYER_PREFIX}:selection`;
export const LAYER_SEND_TO = `${LAYER_PREFIX}:sendTo`;
export const LAYER_RENAME = `${LAYER_PREFIX}:rename`;
export const LAYER_EDITING_NAME = `${LAYER_PREFIX}:editingName`;

// History events
export const HISTORY_PREFIX = 'history';
export const UNDO = `${HISTORY_PREFIX}:undo`;
export const REDO = `${HISTORY_PREFIX}:redo`;
export const HISTORY_RESET = `${HISTORY_PREFIX}:reset`;

// Design events
export const DESIGN_PREFIX = 'design';
export const DESIGN_RENAME = `${DESIGN_PREFIX}:rename`;
export const DESIGN_SAVE = `${DESIGN_PREFIX}:save`;
export const DESIGN_NEW = `${DESIGN_PREFIX}:newDesign`;
export const DESIGN_DELETE = `${DESIGN_PREFIX}:deleteDesign`;
export const DESIGN_DUPLICATE = `${DESIGN_PREFIX}:duplicateDesign`;
export const DESIGN_SHARE = `${DESIGN_PREFIX}:share`;

// Miscellaneous events
export const MISC_PREFIX = 'misc';
export const OPEN_DOWNLOAD_PANEL = `${MISC_PREFIX}:openDownloadPanel`;
export const DOWNLOAD = `${MISC_PREFIX}:download`;
export const SET_STATE = `${MISC_PREFIX}:setState`;
export const SELECT_ALL = `${MISC_PREFIX}:selectAll`;
export const SET_BACKGROUND = `${MISC_PREFIX}:setBackground`;
export const SET_COLOR_PALETTE = `${MISC_PREFIX}:setColorPalette`;
export const SET_COLOR_PALETTE_PRESET = `${MISC_PREFIX}:setColorPalettePreset`;
export const SET_COLOR_PALETTE_PRESET_PREVIEW = `${MISC_PREFIX}:setColorPalettePresetPreview`;
export const REMOVE_COLOR_PALETTE_PRESET_PREVIEW = `${MISC_PREFIX}:removeColorPalettePresetPreview`;
export const GRID_SET = `${MISC_PREFIX}:gridSet`;
export const GRID_SHOW = `${MISC_PREFIX}:gridShow`;
export const ZOOM = `${MISC_PREFIX}:zoom`;
export const ZOOM_BY = `${MISC_PREFIX}:zoomBy`; // deprecated
export const ZOOM_TO = `${MISC_PREFIX}:zoomTo`;
export const ZOOM_STEP = `${MISC_PREFIX}:zoomStep`;
export const ZOOM_CENTER = `${MISC_PREFIX}:zoomCenter`;
export const ENABLE_SNAPPING = `${MISC_PREFIX}:enableSnapping`;
export const ENABLE_ZOOM_ON_WHEEL = `${MISC_PREFIX}:enableZoomOnWheel`;
export const ENABLE_DRAGGING = `${MISC_PREFIX}:enableDragging`;
export const RESIZE = `${MISC_PREFIX}:resize`;
export const SHOW_RULERS = `${MISC_PREFIX}:showRulers`;
export const TRIM_VIEW = `${MISC_PREFIX}:trimView`;
export const HIDE_TRANSPARENCY_GRID = `${MISC_PREFIX}:hideTransparencyGrid`;
export const LAYOUT = `${MISC_PREFIX}:layout`;
export const SOCIAL_SHARE = `${MISC_PREFIX}:socialShare`;
export const LIKE_ILLUSTRATION = `${MISC_PREFIX}:likeIllustration`;
export const INFO_ILLUSTRATION = `${MISC_PREFIX}:infoIllustration`;
export const COLOR_PICKING = `${MISC_PREFIX}:colorPicking`;
export const PICK_COLOR = `${MISC_PREFIX}:pickColor`;
export const DELETE_TEXTURE = `${MISC_PREFIX}:deleteTexture`;
export const DELETE_UPLOAD = `${MISC_PREFIX}:deleteUpload`;
export const DESELECT_OBJECTS = `${MISC_PREFIX}:deselectObjects`;
export const SELECT_ARTBOARD = `${MISC_PREFIX}:selectArtboard`;
export const ACTIVE_TRANSFORM_BUTTON = `${MISC_PREFIX}:transformButton`;
export const POST_SUBMIT = `${MISC_PREFIX}:postSubmit`;
export const OPEN_SHARE_PANEL = `${MISC_PREFIX}:openSharePanel`;
export const OPEN_ARTBOARD_SIZE_MODAL = `${MISC_PREFIX}:openArtboardSizeModal`;
export const RELEASE_TEXTURE = `${MISC_PREFIX}:releaseTexture`;
export const OVERLAY_CLIP = `${MISC_PREFIX}:overlayClip`;
export const START_DRAGGING_ELEMENT = `${MISC_PREFIX}:startDraggingElement`;
export const DRAGGING_ELEMENT = `${MISC_PREFIX}:draggingElement`;
export const STOP_DRAGGING_ELEMENT = `${MISC_PREFIX}:stopDraggingElement`;

// Debug events
export const DEV_PREFIX = 'development';
export const DEV_VISUALIZE_COORDS = `${DEV_PREFIX}:visualizeCoords`;
export const DEV_OUTLINE_BOUNDING_RECT = `${DEV_PREFIX}:outlineBoundingRect`;
export const DEV_VISUALIZE_POINTS = `${DEV_PREFIX}:visualizePoints`;
export const DEV_SAVE_TEXT_LAYOUT = `${DEV_PREFIX}:saveTextLayout`;

// MTC events
const MTC_PREFIX = 'mtc';
export const MTC_SAVE = `${MTC_PREFIX}:save`;
export const MTC_DELETE_WRAP_GRID = `${MTC_PREFIX}:deleteWarpGrid`;
export const MTC_CREATE_WRAP_GRID = `${MTC_PREFIX}:createWarpGrid`;

// Mockup events
const MOCKUP_PREFIX = 'mockup';
export const MOCKUP_SAVE_TO_UPLOADS = `${MOCKUP_PREFIX}:saveToUploads`;

// Main
export const MAIN_PREFIX = 'main';
export const MAIN_EDIT = `${MAIN_PREFIX}:edit`;
export const MAIN_SELECT = `${MAIN_PREFIX}:select`;

// Subscription
export const SUBSCRIPTION_PREFIX = 'subscription';
export const TOGGLE_SUBSCRIPTION_MODAL = `${SUBSCRIPTION_PREFIX}:toggleModal`;
