import { useCallback, useEffect } from 'react';
import { Theme, themeStoreSelector, useThemeStore } from './themeStore';
import { disableAnimation } from './utils';

export const useTheme = (): Theme.LIGHT | Theme.DARK => {
  const theme = useThemeStore(themeStoreSelector.resolvedTheme);

  const applyTheme = useCallback((theme: Theme) => {
    // disable all transition animations, to avoid differently times color transitions
    const enable = disableAnimation();
    const d = document.documentElement;
    d.setAttribute('data-theme', theme);
    // enable transitions again
    enable();
  }, []);

  useEffect(() => {
    applyTheme(theme);
  }, [theme, applyTheme]);

  return theme;
};
