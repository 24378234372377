/**
 * utils for detection based on navigator and document information
 */

/**
 * get the currently used operating system
 */
export const getOperatingSystem = (): string | null => {
  if (typeof navigator === 'undefined') return null;

  if (navigator.appVersion.indexOf('Win') !== -1) {
    return 'Windows';
  }
  if (navigator.appVersion.indexOf('Mac') !== -1) {
    return 'MacOS';
  }
  if (navigator.appVersion.indexOf('X11') !== -1) {
    return 'UNIX';
  }
  if (navigator.appVersion.indexOf('Linux') !== -1) {
    return 'Linux';
  }

  return null;
};

/**
 * Test whether the current device supports more than 2 touch points
 */
export const isCompatibleTouchDevice = (): boolean => {
  if (typeof navigator === 'undefined') return false;

  return !!navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
};

/**
 * test whether the current device is an iPadPro
 * iPadPro devices have the same user agent as other Mac devices,
 * but they also support multiple touch points
 */
export const isIpadOperatingSystem = (): boolean => {
  if (typeof navigator === 'undefined') return false;

  return (
    isCompatibleTouchDevice() &&
    (/MacIntel/.test(navigator.platform) || /iPad/.test(navigator.platform))
  );
};

/**
 * src: https://stackoverflow.com/a/24600597/15415954
 */
export const isMobile = (): boolean => {
  if (typeof navigator === 'undefined') return false;

  return /Mobi|Android/i.test(navigator.userAgent);
};

/**
 * test if cookies are enabled
 */
export const cookiesAreEnabled = (): boolean => {
  try {
    document.cookie = 'cookietest=1';
    const enabled = document.cookie.indexOf('cookietest=') !== -1; // test if cookie was set
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'; // remove cookie
    return enabled;
  } catch (e) {
    return false;
  }
};
