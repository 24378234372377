/**
 * general utils that can be used in all projects
 */

import { uniqBy } from 'lodash';

/**
 * remove duplicate objects from an array
 */
export const uniques = <T extends { id: string }>(array: T[]): T[] => {
  return uniqBy(array, (e: T) => e.id);
};

/**
 * builds a function to check whether an id is in the list of ids
 */
export const isInIds = (ids: string[]): ((id: string) => boolean) => {
  const set = new Set(ids);
  return (id: string): boolean => {
    return set.has(id);
  };
};

/**
 * builds a function to check whether the id of an object is in the list of ids
 */
export const isObjectInIds = (
  ids: string[]
): (<T extends { id: string }>(object: T) => boolean) => {
  const set = new Set(ids);
  return <T extends { id: string }>(object: T): boolean => {
    return set.has(object.id);
  };
};

export const getStdOf = <T>(
  array: T[],
  valueGetter: (item: T) => number
): number => {
  let sum = 0;

  for (const item of array) {
    const value = valueGetter(item);
    sum += value;
  }

  const mean = sum / array.length;
  const deviationSquareSum = array.reduce((sum: number, item: T) => {
    return sum + Math.pow(valueGetter(item) - mean, 2);
  }, 0);

  return Math.sqrt(deviationSquareSum / array.length);
};

export const blobToDataURL = (
  blob: Blob
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve) => {
    const fileReader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileReader.onload = (e: ProgressEvent<FileReader>): any => {
      if (!e.target) {
        resolve(null);
        return;
      }
      resolve(e.target.result);
    };
    fileReader.readAsDataURL(blob);
  });

export type Range = [number, number];

/**
 * Converts a value from a domain range to a target range
 * by linear interpolation.
 * @param value - The value to convert
 * @param rangeFrom - The domain range
 * @param rangeTo - The target range
 */
export const mapToRange = (
  value: number,
  rangeFrom: Range,
  rangeTo: Range
): number => {
  const fromLength = rangeFrom[1] - rangeFrom[0];
  const toLength = rangeTo[1] - rangeTo[0];
  const valueRelative = (value - rangeFrom[0]) / fromLength;
  return rangeTo[0] + valueRelative * toLength;
};
