import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { themeStyle } from '../../services/theming';
import { defaultTheme, arrowTheme } from './theme';
import { Header, Panel, Wrapper, Arrow, NewBadge, Label } from './styles';
import Icon from '../Icon/Icon';
import IconEventWrapper from '../Icon/IconEventWrapper';
import PanelContent from './PanelContent';
import { FlexRow } from '../utilities/styles';

const Collapse = (props) => {
  const theme = { ...defaultTheme, ...props.theme };
  const [active, setActive] = useState(props.activePanel);

  useEffect(() => {
    setActive(props.activePanel);
  }, [props.activePanel]);

  const handleChange = (panelId) => {
    setActive(panelId);
    props.onChange && props.onChange(panelId);
  };

  const handleClick = (id, panel) => {
    if (!panel.children) {
      panel.onClick && panel.onClick();
      return;
    }

    if (id === active) {
      handleChange(null);
      return;
    }

    handleChange(id);
  };

  const children = props.children
    ? props.children.length
      ? props.children
      : [props.children]
    : null;

  return (
    <Wrapper>
      {children?.map((panel, index) => {
        if (!panel?.props) return null;

        const { icon, iconHeight, label, panelTheme, isNew, children } =
          panel.props;
        const panelId = panel.props.id || index;
        const isActive = active === panelId;

        const HeaderWrapper =
          panel.type !== props.linkComponentType
            ? React.Fragment
            : ({ children: headerChildren }) => {
                if (children) {
                  throw new Error(
                    'Expected link component to have no children'
                  );
                }

                return React.cloneElement(panel, null, headerChildren);
              };

        return (
          <Panel key={panelId} theme={panelTheme}>
            <HeaderWrapper>
              <Header
                onClick={() => handleClick(panelId, panel.props)}
                isActive={isActive}
                hasIcon={!!icon}
                theme={theme}
              >
                {icon && (
                  <Icon
                    name={icon}
                    theme={{ color: themeStyle.varInkMain }}
                    height={iconHeight ?? '24px'}
                  />
                )}
                <FlexRow alignItems={'center'} gap={'8px'}>
                  <Label isActive={isActive} theme={theme} semi>
                    {label}
                  </Label>
                  {isNew && <NewBadge>New!</NewBadge>}
                </FlexRow>
                {!!children && (
                  <IconEventWrapper
                    theme={{
                      color: theme.arrowColor ?? arrowTheme.color,
                      activeColor:
                        theme.arrowActiveColor ?? arrowTheme.activeColor,
                    }}
                  >
                    <Arrow isActive={isActive}>
                      <Icon
                        name={'arrowUp'}
                        height={theme.arrowHeight}
                        isActive={isActive}
                        theme={{
                          color: theme.arrowColor ?? arrowTheme.color,
                          activeColor:
                            theme.arrowActiveColor ?? arrowTheme.activeColor,
                        }}
                      />
                    </Arrow>
                  </IconEventWrapper>
                )}
              </Header>
            </HeaderWrapper>
            {!!children && (
              <PanelContent isActive={isActive}>{children}</PanelContent>
            )}
          </Panel>
        );
      })}
    </Wrapper>
  );
};

Collapse.propTypes = {
  /**
   * id or index of the active panel
   */
  activePanel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * function called on change of active panel
   */
  onChange: PropTypes.func,
  theme: PropTypes.object,
  children: PropTypes.node,
  /**
   * Children that have this type are considered links and are handled in a special way:
   *  - they are expected to have no children of their own
   *  - instead of being completely replaced by content generated by Collapse,
   *    they are cloned and injected with it
   */
  linkComponentType: PropTypes.elementType,
};

export default Collapse;
