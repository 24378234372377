import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  ReactElement,
} from 'react';
import uniqBy from 'lodash/uniqBy';
import ElementGallery from '../ElementGallery/ElementGallery';
import Api from '../../global/api';
import { Wrapper } from './styles';
import { DEFAULT_TAKE } from '../../global/constants';
import { ElementProps } from '../../types';
import { ElementGalleryDimensions } from '../ElementGallery/types';

interface InfiniteElementSearchProps {
  query: string;
  collapsed: boolean;
  render: (element: ElementProps) => ReactElement;
  onGoBack: () => void;
  elementGalleryDimensions?: Partial<ElementGalleryDimensions>;
}

/*
 * Used to display elements after a search is performed.
 */
const InfiniteElementSearch: React.FC<InfiniteElementSearchProps> = (
  props: InfiniteElementSearchProps
) => {
  const { query, collapsed, render, onGoBack } = props;

  const [elements, setElements] = useState<ElementProps[]>([]);
  const fetchInfo = useRef<{
    take: number;
    skip: number;
    fetching: boolean;
    finished: boolean;
    emptySearchCount: number;
    isFirstQuery: boolean;
    tags: string[];
    seenTags: string[];
    currentTag: string | undefined;
  }>({
    take: DEFAULT_TAKE,
    skip: 0,
    fetching: false,
    finished: false,
    // emptySearchCount lets us bail out of the search once certain number of "empty" searches are reached
    // since a tag might return elements that we have seen all of before some searches might be "empty"
    //
    // Say we've got three elements: E1, E2, E3. E1 has tags T1, T2, T3. E2 has tag T1. E3 has tag T2.
    // Consider that T1 is directly searched for, E1 and E2 will be returned -- both will be added to
    // the results since we haven't seen any of them before. T2 and T3 will be enqeueud to be searched
    // next since they are found in the E1.
    // T2 search will return E1 and E3, only E3 will be added to the results, since E1 is already seen.
    // When T3 is searched, E1 will be returned. Nothing will be added to the search since E1 was already
    // seen before. T3 here is considered an "empty" search.
    emptySearchCount: 0,
    isFirstQuery: true,
    tags: [],
    // seenTags keeps tracks of tags already searched for, so we don't re-search
    seenTags: [],
    currentTag: undefined,
  });

  const fetch = useCallback(async () => {
    const { take, skip, fetching, finished, isFirstQuery } = fetchInfo.current;
    if (fetching || finished) {
      return;
    }
    fetchInfo.current.fetching = true;
    const fetcher = Api.getSearchElements;
    let elements: {
      skip: number;
      take: number;
      total: number;
      query: string;
      elements: ElementProps[];
    };
    if (fetchInfo.current.currentTag === undefined)
      elements = await fetcher(query, take, skip, isFirstQuery);
    else
      elements = await fetcher(
        fetchInfo.current.currentTag,
        take,
        skip,
        isFirstQuery
      );

    if (query !== currentQuery.current) return;

    fetchInfo.current.isFirstQuery = false;

    const results = elements.elements;
    if (!results.length) {
      fetchInfo.current.finished = true;
      return;
    }

    if (fetchInfo.current.tags.length === 0) {
      const tags = results.map((result: ElementProps) => result.tags).flat();
      const tagNames: string[] = tags
        .map((tag: { name: string }) => tag.name)
        .filter((tag: string) => !fetchInfo.current.seenTags.includes(tag));
      fetchInfo.current.tags = Array.from(new Set(tagNames));
    }

    setElements((prevElements) => {
      const newElements = uniqBy([...prevElements, ...results], (e) => e.id);
      if (
        fetchInfo.current.currentTag !== undefined &&
        newElements.length === prevElements.length &&
        ++fetchInfo.current.emptySearchCount === 10
      )
        fetchInfo.current.finished = true;
      else if (
        (fetchInfo.current.currentTag !== undefined && results.length === 0) ||
        // This condition signifies that all results for the initial query has been consumed
        (newElements.length >= elements.total && elements.total !== 0)
      ) {
        while (
          fetchInfo.current.seenTags.includes(
            fetchInfo.current.currentTag ?? ''
          ) &&
          fetchInfo.current.tags.length > 0
        ) {
          fetchInfo.current.emptySearchCount = 0;
          fetchInfo.current.currentTag = fetchInfo.current.tags.shift();
          if (fetchInfo.current.tags.length === 0) {
            const tags = newElements
              .map((newElement) => newElement.tags)
              .flat();
            const tagNames = tags
              .map((tag) => tag.name)
              .filter((tag) => !fetchInfo.current.seenTags.includes(tag));
            fetchInfo.current.tags = Array.from(new Set(tagNames));
          }
        }
        fetchInfo.current.seenTags.push(fetchInfo.current.currentTag ?? '');
        fetchInfo.current.skip = 0 - take;
      }
      return newElements;
    });

    fetchInfo.current.skip += take;
    fetchInfo.current.fetching = false;
  }, [query]);

  const currentQuery = useRef(query);

  useEffect(() => {
    if (query === currentQuery.current) return;
    currentQuery.current = query;
    fetchInfo.current = {
      ...fetchInfo.current,
      skip: 0,
      fetching: false,
      finished: false,
      emptySearchCount: 0,
      isFirstQuery: true,
      tags: [],
      seenTags: [],
      currentTag: undefined,
    };
    setElements([]);
    fetch();
  }, [query, fetch]);

  return (
    <Wrapper>
      <ElementGallery
        collapsed={collapsed}
        elements={elements}
        render={render}
        fetch={fetch}
        labelLeft={`SEARCH: ${query}`}
        dimensions={props.elementGalleryDimensions}
        sideEffectBeginTransition={onGoBack}
      />
    </Wrapper>
  );
};

export default InfiniteElementSearch;
