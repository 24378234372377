import { getCenterFromOptions } from '../../../utils/editor/misc';

export default function (fabric) {
  fabric.Circle.prototype.initialize = (function (initialize) {
    return function (options) {
      options || (options = {});

      if (options.canvas?.artboard) {
        if (options.wasDropped) {
          const zoom = options.canvas.getZoom();
          options.top = options.top - options.distanceToTopCorner.y / zoom;
          options.left = options.left - options.distanceToTopCorner.x / zoom;
          options.radius = options.imgWidth / (2 * zoom);
        } else {
          const { centerX, centerY } = getCenterFromOptions(options);
          const artboard = options.canvas.artboard;
          const minSide = Math.min(artboard.width, artboard.height);
          const radius = (minSide * fabric.NEW_OBJECT_SCALE) / 2;
          options.radius = options.radius || radius;
          options.top = centerY - options.radius;
          options.left = centerX - options.radius;
        }
      }

      options.objectCaching = false;
      options.strokeWidth = options.strokeWidth || 0;

      initialize.apply(this, [options]);

      if (this.scaleX !== 1 || this.scaleY !== 1) {
        this.adjustDimensionsToScale(); // check scale on initialization (if refreshed without deselected)
      }

      this.on('scaled', () => {
        this.adjustDimensionsToScale(); // reset scale on scaled
      });

      this.on('deselected', () => {
        this.adjustDimensionsToScale(); // reset scale after it changed in an activeSelection
      });

      // We need to enable middle controls back, as they're disabled on Object level
      this.setControlsVisibility({
        mr: true,
        mt: true,
        ml: true,
        mb: true,
      });
      return this;
    };
  })(fabric.Circle.prototype.initialize);

  /**
   * apply scale to width and height and reset it to a base scale of 1
   */
  fabric.Circle.prototype.adjustDimensionsToScale = function () {
    // its necessary to keep the relation between scales, to still apply radius
    const minScale = Math.min(this.scaleX, this.scaleY);
    const { scaleX, scaleY } =
      minScale === this.scaleX
        ? { scaleX: 1, scaleY: this.scaleY / this.scaleX }
        : { scaleX: this.scaleX / this.scaleY, scaleY: 1 };
    const height = this.height * minScale;
    const width = this.width * minScale;

    this.set({
      width,
      height,
      scaleX,
      scaleY,
      radius: Math.min(height, width) / 2,
    });
  };

  // ignore stroke for bounding box
  fabric.Circle.prototype._finalizeDimensions = (x, y) => ({ x, y });
}
