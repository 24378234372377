import {
  LAYER_SELECTION,
  LAYER_MOVE,
  LAYER_HIDDEN,
  LAYER_LOCKED,
  LAYER_SEND_TO,
  LAYER_RENAME,
  LAYER_EDITING_NAME,
} from '../../../global/events';
import {
  moveElementsInStructure,
  removeEmptyGroups,
  flattenStructure,
  cleanStructure,
  getElementById,
} from '../../../utils/groupStructure';

const disabledFilter = (layer) => !layer.disabled;

export default function (event, canvas, allObjects) {
  switch (event.key) {
    case LAYER_RENAME:
      const { layerId, newLayerName } = event.val;
      const node = getElementById(canvas.groupStructure, layerId);
      const canvasObj = canvas.getObjects().find((obj) => obj.id === layerId);
      if (canvasObj) {
        canvasObj.set('name', newLayerName);
      } else if (node) {
        node.meta = {
          ...node.meta,
          name: newLayerName,
        };
        canvas.fire('object:modified');
      }
      break;
    case LAYER_SELECTION:
      canvas.discardActiveObject();
      if (event.val.ids.length) {
        canvas.selectIds(event.val.ids);
      }
      break;
    case LAYER_MOVE:
      const structure = {
        children: event.val.structure.filter(disabledFilter),
      };
      const cleanedStructure = cleanStructure(structure); // remove unnecessary props
      canvas.groupStructure = removeEmptyGroups(cleanedStructure);

      canvas.discardActiveObject(); // remove objects from activeSelection

      // adjust order of canvas objects
      canvas.adjustObjectsIndexToStructure();
      canvas.updateObjectsGroupRelatedProperties();

      // Update state
      canvas.fire('object:modified');
      // redo selection
      canvas.selectIds(event.val.selectedKeys);
      break;
    case LAYER_HIDDEN:
      const id = event.val.id;
      if (id === 'background') {
        canvas.modifyArtboard({
          opacity: canvas.artboardOptions?.opacity === 0 ? 1 : 0,
        });
        canvas.requestRenderAll();
        return;
      }
      if (id === 'overlay') {
        canvas.modifyOverlay({ hidden: !canvas.overlayOptions?.hidden });
        return;
      }
      canvas.toggleElementHidden(id);
      canvas.fire('object:modified');
      break;
    case LAYER_LOCKED:
      canvas.toggleElementLocked(event.val.id);
      canvas.fire('object:modified');
      // Update selection, to take locked property into account
      canvas.updateSelectionLock();
      break;
    case LAYER_SEND_TO:
      const objectIds = canvas.getSelectedElements();
      if (!objectIds.length) break;

      const flatStructure = flattenStructure(canvas.groupStructure);
      const objects = flatStructure.filter((x) => objectIds.includes(x.id));
      const groupIds = objects.map((x) => x.groupId);
      const groupIndexes = objects.map((x) => x.groupIndex);

      const groupId = groupIds[0]; // there is no way to select elements belonging to different groups
      const lowestIndex = Math.min(...groupIndexes);

      const groupSize = flatStructure.filter(
        (x) => x.groupId === groupId
      ).length;

      let moveTo;
      if (event.val === 'front') {
        moveTo = 0;
      } else if (event.val === 'forward') {
        moveTo = lowestIndex > 0 ? lowestIndex - 1 : 0;
      } else if (event.val === 'back') {
        moveTo = groupSize - 1;
      } else if (event.val === 'backward') {
        moveTo = lowestIndex + 1;
      }

      const finalStructure = moveElementsInStructure(
        canvas.groupStructure,
        objects.map(({ id }) => id), // use objects ids instead of objectIds to get them in correct order
        groupId,
        moveTo
      );

      canvas.groupStructure = finalStructure;

      // adjust order of canvas objects
      canvas.adjustObjectsIndexToStructure();
      canvas.updateObjectsGroupRelatedProperties();

      // Update state
      canvas.fire('object:modified');
      break;
    case LAYER_EDITING_NAME:
      const { isEditing } = event.val;
      canvas.isEditingLayerName = isEditing;
      break;
    default:
      console.warn('Unknown Artboard Layer event', event?.key, event?.val);
  }
  canvas.requestRenderAll();
}
