import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { darkBackgroundBlur } from '../utilities/styles';

export const TooltipWrapper = styled.div<{ isPositioned: boolean }>`
  position: absolute;
  padding: 4px 8px;
  background-color: ${themeStyle.varInkMain};
  color: ${themeStyle.varInkBase};
  font-family: ${themeStyle.fontFamily};
  font-weight: ${themeStyle.fontMedium};
  font-size: ${themeStyle.fontSize11};
  border-radius: ${themeStyle.radiusSmall};
  box-shadow: ${themeStyle.varShadowHeavy};
  z-index: 999;
  opacity: ${({ isPositioned }) => (isPositioned ? '1' : '0')};
  transition: opacity 225ms;
  text-align: center;

  ${darkBackgroundBlur};
  html[data-theme='dark'] && {
    background-color: ${themeStyle.varBackgroundAlt};
    color: ${themeStyle.varInkMain};
  }

  @media (hover: none) {
    display: none;
  }
`;
