import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import Modal from '../Modal/Modal';
import { H2, H3 } from '../utilities/Typography/styles';
import { Buttons, Folders, Wrapper } from './styles';
import TextInput from '../TextInput/TextInput';
import { FlexColumn } from '../utilities/styles';
import Button from '../Button/Button/Button';
import { primaryTheme, secondaryTheme } from '../Button/Button/theme';
import folderApi from '../../global/folderApi';
import Folder from './Folder/Folder';
import Spinner from '../utilities/Spinner/Spinner';
import { useToastStore, toastSelector } from '../../stores/toastStore';
import useWindowSize from '../../hooks/useWindowSize';
import designsStore, { designsStoreSelector } from '../../stores/designsStore';
import Spacer from '../Spacer/Spacer';

const initialFetchState = {
  take: 10,
  skip: 0,
  isFetching: false,
  isFinished: false,
};

const ProjectFoldersModal = (props) => {
  const [query, setQuery] = useState('');
  const [folders, setFolders] = useState([]);
  const [folderInEditMode, setFolderInEditMode] = useState(null); // id of folder that should be initialized in editMode
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const fireToast = useToastStore(toastSelector.fire);
  const { isMobile } = useWindowSize();

  const [isLoading, setIsLoading] = useState(true);
  const [processingCreate, setProcessingCreate] = useState(false);
  const [processingMove, setProcessingMove] = useState(false);

  const elements = designsStore(designsStoreSelector.elements);
  const updateElement = designsStore(designsStoreSelector.updateElement);
  const updateFolder = designsStore(designsStoreSelector.updateFolder);

  const handleCreateFolder = async () => {
    setProcessingCreate(true);
    const response = await folderApi.createFolder();
    if (response.folder?.id) {
      setSelectedFolderId(response.folder.id);
      setFolderInEditMode(response.folder.id);
      setFolders((folders) => [response.folder, ...folders]);
      updateElement(response.folder);
    } else {
      fireToast({
        label: `Failed to create a new folder. ${response.error}`,
        error: true,
      });
    }
    setQuery(''); // reset search
    setProcessingCreate(false);
  };

  const handleMoveToFolder = async () => {
    setProcessingMove(true);

    let error = '';
    const response = await folderApi.moveToFolder(
      selectedFolderId,
      props.projectIds
    );
    if (!response.success)
      error = response.error || 'Failed to move the project(s)';
    else {
      const updatedElements = elements.filter((e) =>
        props.projectIds.includes(e.id)
      );
      updatedElements.forEach((e) =>
        updateElement({ ...e, folderId: selectedFolderId })
      );
    }

    await updateFolder(selectedFolderId);

    fireToast({
      label:
        error ||
        `Moved ${props.projectIds.length} project${
          props.projectIds.length === 1 ? '' : 's'
        }`,
      error: !!error,
    });

    setProcessingMove(false);

    if (!error) {
      props.onClose && props.onClose();
    }
  };

  const fetchState = useRef({ ...initialFetchState });

  const fetch = useCallback(async () => {
    if (!props.isOpen) return;
    const { take, skip, isFetching, isFinished } = fetchState.current;

    if (isFetching || isFinished) return;
    fetchState.current.isFetching = true;
    setIsLoading(true);

    const response = await folderApi.getFolders(take, skip);
    if (response.error || !response.results?.length) {
      fetchState.current.isFinished = true;
      fetchState.current.isFetching = false;
      setIsLoading(false);

      if (response.error) {
        fireToast({
          label: `Failed to load folders: ${response.error}`,
          error: true,
        });
      }

      return;
    }

    setFolders((folders) => {
      const currentIds = folders.map(({ id }) => id);
      return [
        ...folders,
        ...response.results.filter(({ id }) => !currentIds.includes(id)),
      ];
    });

    fetchState.current.skip += fetchState.current.take;
    fetchState.current.isFetching = false;
    setIsLoading(false);
  }, [fireToast, props.isOpen]);

  const { ref: loader, inView } = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (inView) {
      fetch();
    }
  }, [inView, fetch]);

  // reset data on close, to ensure current data when opened
  useEffect(() => {
    if (!props.isOpen) {
      setFolders([]);
      setFolderInEditMode(null);
      setSelectedFolderId(null);
      setQuery('');
      setIsLoading(true);
      fetchState.current = { ...initialFetchState };
    }
  }, [props.isOpen]);

  const byQuery = (folder) => {
    if (!query) return true;
    return folder.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      width="850px"
      externalCloseButton
    >
      <Wrapper>
        <H2>
          Move {props.projectIds.length} Project
          {props.projectIds.length === 1 ? '' : 's'} to Folder
        </H2>
        <Spacer h="24px" />
        <TextInput
          disabled={!folders.length}
          value={query}
          icon={'search'}
          placeholder={'Search'}
          theme={{ iconHeight: '14px' }}
          onChanging={setQuery}
        />
        {!!folders.filter(byQuery).length && (
          <Folders>
            {folders.filter(byQuery).map((folder) => (
              <Folder
                key={folder.id}
                editMode={folder.id === folderInEditMode}
                folder={folder}
                isActive={folder.id === selectedFolderId}
                onClick={() => setSelectedFolderId(folder.id)}
                showTotal
                hasDarkOverlayTransition
              />
            ))}
            <div ref={loader} />
          </Folders>
        )}
        {!folders.filter(byQuery).length && (
          <FlexColumn
            gap="24px"
            alignItems="center"
            justifyContent="center"
            ref={loader}
            style={{ minHeight: '314px', marginTop: '24px' }}
          >
            {isLoading && <Spinner size="45px" />}
            {!isLoading && (
              <img
                src="/images/nothingPublished.svg"
                alt="Nothing Found"
                width={isMobile ? '177' : '304'}
                height={isMobile ? '140' : '240'}
              />
            )}
            <H3>
              {isLoading
                ? 'Loading'
                : !folders.length
                ? 'No folders yet'
                : 'Nothing found'}
            </H3>
          </FlexColumn>
        )}
        <Spacer h="24px" />
        <Buttons>
          <Button
            icon={{ name: 'plus' }}
            label="Create New Folder"
            theme={secondaryTheme}
            height="40px"
            width="185px"
            onClick={handleCreateFolder}
            loading={processingCreate}
          />
          <Button
            icon={{ name: 'folder' }}
            label="Move To Folder"
            theme={primaryTheme}
            height="40px"
            width="165px"
            onClick={handleMoveToFolder}
            disabled={!selectedFolderId}
            loading={processingMove}
          />
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

ProjectFoldersModal.propTypes = {
  /**
   * ids of projects
   */
  projectIds: PropTypes.arrayOf(PropTypes.string),
  /**
   * whether the modal is open
   */
  isOpen: PropTypes.bool,
  /**
   * function triggered on closing the modal
   */
  onClose: PropTypes.func,
};

ProjectFoldersModal.defaultProps = {
  projectIds: [],
};

export default ProjectFoldersModal;
