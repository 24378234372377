import { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import { H6 } from '../utilities/Typography/styles';
import { Color, Colors, NameWrapper, Wrapper, InnerWrapper } from './styles';

const ColorPalettePreset = (props) => {
  const timeoutRef = useRef();

  const renderColors = () => {
    return props.colors.map((c, idx) => (
      <Color color={c} key={`color-${idx}`} />
    ));
  };

  const onMouseEnter = () => {
    // We're using timeout to avoid laggin when scrolling color palettes list
    timeoutRef.current = setTimeout(() => {
      props.onMouseEnter();
    }, 25);
  };

  const onMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    props.onMouseLeave();
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const dataTestId = `color-palette-preset--${props.presetName
    .toLowerCase()
    .replace(' ', '-')}`;

  return (
    <Wrapper
      data-testid={dataTestId}
      onClick={props.onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <InnerWrapper>
        <Colors>{renderColors()}</Colors>
        <NameWrapper>
          {props.showPremium && <Icon height="15px" name="premiumBadge" />}
          <H6>{props.presetName}</H6>
        </NameWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

ColorPalettePreset.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  showPremium: PropTypes.bool,
  presetName: PropTypes.string,
};

export default memo(ColorPalettePreset);
