import { MICRO_DRAG_THRESHOLD } from '../../components/Artboard/fabric/canvas_events.mixin';
import {
  deleteFromTouchDictionary,
  distance,
  updateTouchDictionary,
} from './util';

const GESTURE_EVENTS = [
  'gesturestart',
  'gesturechange',
  'gestureend',
  'modifierchange',
];

// For detailed info about usage check https://www.notion.so/kittl/Gesture-Tracker-facc6739511845a29db80984c719b9fc

/**
 * Class to keep track of gestures performed on an element via a touch screen
 * @param {HTMLElement} element The element to listen to the events on
 * @param {number} gestureStartThreshold Distance that any point of contact on the screen needs to be moved in order to trigger a gesture start.
 * @param {number} tapTimeThreshold Time, in milliseconds that plays a part in determining whether a sequence of events is considered a "tap"
 * @returns {{x: number, y: number}}
 */
class GestureTracker {
  constructor(
    element,
    gestureStartThreshold = MICRO_DRAG_THRESHOLD,
    tapTimeThreshold = 250
  ) {
    this.eventHandlers = {};
    this.gestureSize = 0;
    this.gestureStartThreshold = gestureStartThreshold;
    this.tapTimeThreshold = tapTimeThreshold;
    this.element = element;
    this._resetState();

    const preventDefaultWrapped = (f) => {
      return (event) => {
        event.preventDefault();
        f(event);
      };
    };

    const touchStartHandler = preventDefaultWrapped(
      this._registerTouchStart.bind(this)
    );
    const touchMoveHandler = preventDefaultWrapped(
      this._registerTouchMove.bind(this)
    );
    const touchEndHandler = preventDefaultWrapped(
      this._registerTouchEnd.bind(this)
    );

    element.addEventListener('touchstart', touchStartHandler, {
      passive: false,
    });

    element.addEventListener('touchmove', touchMoveHandler, { passive: false });

    element.addEventListener('touchend', touchEndHandler, { passive: false });

    element.addEventListener('touchcancel', touchEndHandler, {
      passive: false,
    });

    this.cleanupListeners = function () {
      element.removeEventListener('touchstart', touchStartHandler);
      element.removeEventListener('touchmove', touchMoveHandler);
      element.removeEventListener('touchend', touchEndHandler);
      element.removeEventListener('touchcancel', touchEndHandler);
      this.eventHandlers = {};
    };
  }

  _resetState() {
    this.touches = {};
    this.startTouches = {};
    this.tapTouches = {};
    this.modifiers = {};
    this.isPotentialTap = true;
    this.isReleasing = false;
    clearTimeout(this.tapAwaiter);
  }

  _updateTouches(touches) {
    updateTouchDictionary(this.touches, touches);
  }

  _updateModifiers(touches) {
    updateTouchDictionary(this.modifiers, touches);
  }

  _updateTapTouches(touches) {
    updateTouchDictionary(this.tapTouches, touches);
  }

  _updateStartTouches(touches) {
    updateTouchDictionary(this.startTouches, touches);
  }

  _removeStartTouches(touches) {
    deleteFromTouchDictionary(this.startTouches, touches);
  }

  _removeModifiers(touches) {
    deleteFromTouchDictionary(this.modifiers, touches);
  }

  _removeTouches(touches) {
    deleteFromTouchDictionary(this.touches, touches);
  }

  _separateModifiersAndTouches(entries) {
    const isModifier = (entry) => !!this.modifiers[entry.identifier];
    const isNotModifier = (entry) => !this.modifiers[entry.identifier];
    const modifiers = entries.filter(isModifier);
    const touches = entries.filter(isNotModifier);
    return {
      touches,
      modifiers,
    };
  }

  _resetTapAwaiter() {
    clearTimeout(this.tapAwaiter);
    this.tapAwaiter = setTimeout(() => {
      this.isPotentialTap = false;
      this.tapTouches = {};
    }, this.tapTimeThreshold);
  }

  _shouldStartGesture(movingTouches) {
    for (let i = 0; i < movingTouches.length; i++) {
      const movingTouch = movingTouches[i];
      const startTouch = this.startTouches[movingTouch.identifier];

      if (distance(startTouch, movingTouch) > this.gestureStartThreshold) {
        return true;
      }
    }

    return false;
  }

  _registerTouchStart(event) {
    const startedTouches = Array.from(event.changedTouches);

    if (this.gestureInProgress) {
      this._updateModifiers(startedTouches);
      this._fire('modifierchange');
    } else {
      this._updateTouches(startedTouches);
      this._updateStartTouches(startedTouches);
    }

    if (this.isPotentialTap && !this.isReleasing) {
      this._resetTapAwaiter();
      this._updateTapTouches(startedTouches);
    }

    this._fire('touchstart', event);
  }

  _registerTouchMove(event) {
    const changedTouches = Array.from(event.changedTouches).filter(
      (touch) => touch.target === this.element
    );

    const { touches: movingTouches, modifiers: movingModifiers } =
      this._separateModifiersAndTouches(changedTouches);

    this._updateTouches(movingTouches);
    this._updateModifiers(movingModifiers);

    if (this.gestureInProgress) {
      this._fire('gesturechange');
    } else if (this._shouldStartGesture(movingTouches)) {
      this.gestureInProgress = true;
      this._fire('gesturestart');
    }

    this._fire('touchmove', event);
  }

  _registerTouchEnd(event) {
    const changedTouches = Array.from(event.changedTouches);

    const { touches: removedTouches, modifiers: removedModifiers } =
      this._separateModifiersAndTouches(changedTouches);

    this._removeTouches(removedTouches);
    this._removeModifiers(removedModifiers);

    const totalTouchCount = this.getTouchCount() + this.getModifierCount();

    if (this.gestureInProgress) {
      if (!totalTouchCount) {
        this.gestureInProgress = false;
        this._fire('gestureend');
      } else if (removedModifiers.length) {
        this._fire('modifierchange');
      } else {
        this._fire('gesturechange');
      }
    } else {
      if (this.isPotentialTap) {
        if (!totalTouchCount) {
          this._fire('tap');
        } else {
          this._resetTapAwaiter();
        }
      }

      this._removeStartTouches(removedTouches);
    }

    this.isReleasing = true;
    this._fire('touchend', event);

    if (!totalTouchCount) {
      this._resetState();
    }
  }

  _fire(event, options) {
    if (GESTURE_EVENTS.includes(event)) {
      options = {
        touches: Object.values(this.touches),
        startTouches: Object.values(this.startTouches),
        modifiers: Object.values(this.modifiers),
        gestureSize: Object.keys(this.startTouches).length,
      };
    }

    if (event === 'tap') {
      options = {
        taps: Object.values(this.tapTouches),
      };
    }

    const eventHandlers = this.eventHandlers[event];
    if (eventHandlers) {
      eventHandlers.forEach((handler) => handler(options));
    }
  }

  getTouchCount() {
    return Object.keys(this.touches).length;
  }

  getModifierCount() {
    return Object.keys(this.modifiers).length;
  }

  on(event, handler) {
    if (this.eventHandlers[event]) {
      this.eventHandlers[event].push(handler);
    } else {
      this.eventHandlers[event] = [handler];
    }
  }

  off(event, handler) {
    if (this.eventHandlers[event]) {
      this.eventHandlers[event] = this.eventHandlers[event].filter(
        (_handler) => handler !== _handler
      );
    }
  }
}

export default GestureTracker;
