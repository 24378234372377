import { themeStyle } from '../../../services/theming';

const defaultTheme = {
  // label
  colorLabel: themeStyle.varInkMain,
  fontFamily: themeStyle.fontFamily,
  fontWeight: themeStyle.fontSemiBold,
  fontSize: themeStyle.fontSize14,
  lineHeight: '18px',
  letterSpacing: 'unset',
  textTransform: 'unset',
  padding: '0px',

  // icon
  colorIcon: themeStyle.varInkMedium,
  colorIconActive: themeStyle.varInkMain,
  opacityIcon: 1,
};

export { defaultTheme };
