import React from 'react';

import { Wrapper, IconWrapper, getColor } from './styles';
import Icon from '../Icon/Icon';
import { TagProps } from './types';

const Tag: React.FC<TagProps> = ({
  children,
  onClick,
  onClose,
  isActive,
  theme = {},
}) => {
  const iconTheme = {
    color: getColor({ theme, tagIsActive: false }),
    activeDefaultColor: getColor({ theme, tagIsActive: true }),
    activeColor: getColor({ theme, tagIsActive: true }),
    transition: '0.3s',
  };

  return (
    <Wrapper
      onClick={onClick ?? onClose}
      hasOnClick={!!onClick || !!onClose}
      theme={{ ...theme, ...iconTheme }}
      tagIsActive={isActive}
      activeOnHover
    >
      <span>{children}</span>
      {!!onClose && (
        <IconWrapper onClick={onClose}>
          <Icon
            name="close"
            height={theme.iconHeight ?? '7px'}
            theme={iconTheme}
          />
        </IconWrapper>
      )}
    </Wrapper>
  );
};

export default Tag;
