import styled, { css } from 'styled-components';

const getDefaultColor = (props: any) => {
  if (props.disabled) return props.theme.disabledColor;
  return props.isActive ? props.theme.activeDefaultColor : props.theme.color;
};

const getSecondaryDefaultColor = (props: any) => {
  if (props.disabled) return props.theme.secondaryDisabledColor;
  return props.theme.secondaryColor;
};

export const IconStyle = styled.div<any>`
  height: ${(props) => props.height};
  width: auto;

  svg {
    position: relative;
    display: block;

    height: ${(props) => props.height};
    width: auto;
  }

  .fillDefault {
    fill: ${getDefaultColor};

    &.secondary {
      fill: ${getSecondaryDefaultColor};
    }
  }
  .fillActive {
    ${(props) =>
      props.isActive
        ? `fill: ${props.theme.activeColor};
      &.secondary {
        fill: ${props.theme.secondaryActiveColor};
      };
      `
        : ''}
  }
  .strokeDefault {
    stroke: ${getDefaultColor};

    &.secondary {
      stroke: ${getSecondaryDefaultColor};
    }
  }
  .strokeActive {
    ${(props) =>
      props.isActive
        ? `stroke: ${props.theme.activeColor};
    &.secondary {
      stroke: ${props.theme.secondaryActiveColor};
    };`
        : ''}
  }

  &.ignoreHoverStyles .fillDefault {
    fill: ${getDefaultColor} !important;
  }
  &.ignoreHoverStyles .fillDefault.secondary {
    fill: ${getSecondaryDefaultColor} !important;
  }
  &.ignoreHoverStyles .strokeDefault {
    stroke: ${getDefaultColor} !important;
  }
  &.ignoreHoverStyles .strokeDefault.secondary {
    stroke: ${getSecondaryDefaultColor} !important;
  }

  /* active parts are hidden when not active */
  .fillActive,
  .strokeActive {
    opacity: ${(props) => (props.isActive ? props.theme.opacity : '0')};
  }

  /* default parts are always visible */
  .fillDefault,
  .strokeDefault {
    opacity: ${(props) => props.theme.opacity};
  }

  .fillDefault,
  .fillActive,
  .strokeDefault,
  .strokeActive {
    transition: ${(props) => props.theme.transition};
    transition-property: stroke, fill, opacity;
  }

  transform: ${(props) => props.theme.transform || 'unset'};

  padding-bottom: ${(props) => props.theme.paddingBottom};
`;

export const activeStyle = css<any>`
  .fillDefault {
    ${(props) =>
      !props.disabled ? `fill: ${props.theme.activeDefaultColor}` : ''};
  }
  .strokeDefault {
    ${(props) =>
      !props.disabled ? `stroke: ${props.theme.activeDefaultColor}` : ''};
  }

  .fillActive {
    ${(props) =>
      !props.disabled
        ? `fill: ${props.theme.activeColor}; opacity: ${props.theme.opacity};
        
      &.secondary {
        fill: ${props.theme.secondaryActiveColor};
      };`
        : ''}
  }
  .strokeActive {
    ${(props) =>
      !props.disabled
        ? `stroke: ${props.theme.activeColor}; opacity: ${props.theme.opacity};
        
      &.secondary {
        stroke: ${props.theme.secondaryActiveColor};
      };`
        : ''}
  }
`;

const onActiveStyle = css<any>`
  ${activeStyle}

  .fillActive {
    ${(props) =>
      !props.disabled
        ? `fill: ${props.theme.onActiveColor}; opacity: ${props.theme.opacity};
        
      &.secondary {
        fill: ${props.theme.secondaryOnActiveColor};
      };`
        : ''}
  }
  .strokeActive {
    ${(props) =>
      !props.disabled
        ? `stroke: ${props.theme.onActiveColor}; opacity: ${props.theme.opacity};
        
      &.secondary {
        stroke: ${props.theme.secondaryOnActiveColor};
      };`
        : ''}
  }
`;

export const IconEventStyle = styled.div<any>`
  display: flex;
  justify-content: ${(props) => props.theme.justifyContent};
  align-items: center;
  ${(props) =>
    props.fitParent ? 'width: 100%; height: 100%;' : 'width: fit-content;'}

  &:hover ${IconStyle} {
    ${(props) => (props.activeOnHover ? activeStyle : '')}
  }

  &:active ${IconStyle} {
    ${(props) => (props.activeOnHover ? onActiveStyle : '')}
  }

  &:focus-within ${IconStyle} {
    ${(props) => (props.activeOnFocus ? activeStyle : '')}
  }
`;

export const SpinnerWrapper = styled.div`
  .spinner {
    animation: rotate 2s linear infinite;
    position: relative;

    & .path {
      stroke: ${({ theme }) => theme.spinnerColor};
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
