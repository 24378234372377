import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { H5 } from '../utilities/Typography/styles';

export const ColorPickerWrapper = styled.div`
  margin-top: 6px;
  padding-top: 7px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 14px;
  background: ${({ colorPanel }) => colorPanel};
  border-radius: 5px;
`;

export const SaturationWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-sizing: border-box;
`;

export const HueAndNoColorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 16px;
  margin: 10px 0;
`;

export const NoColorSelector = styled.button`
  position: relative;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-right: 8px;
  border: 1px solid #dfdfdf;
  background-color: ${themeStyle.backgroundBase};
  overflow: hidden;
  cursor: pointer;
  font-size: 0px; // a hack to ignore minimum button size on older iPads

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 6px;
    width: 2px;
    height: 16px;
    background-color: ${themeStyle.varAlertRed};
    transform: rotate(45deg);
  }

  /**
   * highlight on isActive/:hover 
   * this is done via an :after element with an inset shadow instead of a border
   * so that the content of the ColorSwatch keeps the same dimensions
   */
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 10px;

    box-shadow: ${({ isActive, theme }) =>
      isActive ? theme.activeButtonInnerShadow : 'none'};
  }
  &:hover:after {
    box-shadow: ${({ theme }) => theme.activeButtonInnerShadow};
  }
`;

export const AlphaRow = styled.div`
  display: flex;
  flex: 1;
`;

export const ColorPickerButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;

  padding: 0;

  width: 56px;
  min-width: 56px;
  height: 28px;
  margin-right: 9px;

  background-color: ${themeStyle.varInkLight};
  border-radius: 5px;
`;

export const PaletteLabel = styled(H5)`
  margin-top: 12px;
  border-top: 1px solid ${themeStyle.varInkSuperLight};
  padding-top: 12px;
  margin-bottom: 10px;
  user-select: none;
`;

export const ColorSwatches = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
