import { themeStyle } from '../../../services/theming';
import { ReactComponent as ColorCutIcon } from '../assets/colorCut.svg';
import { ReactComponent as FadingColorCutIcon } from '../assets/fadingColorCut.svg';
import { ReactComponent as HorizontalLinesIcon } from '../assets/horizontalLines.svg';
import { ReactComponent as ObliqueLinesIcon } from '../assets/obliqueLines.svg';

const commonIconMapping = {
  primary: {
    light: [themeStyle.inkMain, themeStyle.inkMain],
    dark: [themeStyle.darkInkMain, themeStyle.darkBrandLight],
  },
  secondary: {
    light: [themeStyle.inkLight, themeStyle.backgroundAlt],
    dark: [themeStyle.darkInkSuperLight, themeStyle.darkBackgroundAlt],
  },
};

export const config = [
  {
    id: 'horizontalLines',
    icon: HorizontalLinesIcon,
    iconMapping: commonIconMapping,
    label: 'Horizontal Lines',
  },
  {
    id: 'colorCut',
    icon: ColorCutIcon,
    iconMapping: {
      primary: {
        light: [themeStyle.inkLight, themeStyle.backgroundAlt],
        dark: [themeStyle.darkInkLight, themeStyle.darkBackgroundAlt],
      },
      secondary: {
        light: [themeStyle.inkMain, themeStyle.inkMain],
        dark: [themeStyle.darkInkMain, themeStyle.darkBrandLight],
      },
    },
    label: 'Color Cut',
  },
  {
    id: 'obliqueLines',
    icon: ObliqueLinesIcon,
    iconMapping: commonIconMapping,
    label: 'Oblique Lines',
  },
  {
    id: 'fadingColorCut',
    icon: FadingColorCutIcon,
    iconMapping: commonIconMapping,
    label: 'Fading Color Cut',
  },
];
