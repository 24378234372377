import { isDebugActive } from '../../utils/dev';
import UploadsMenu from './UploadsMenu/UploadsMenu';
import DebugMenu from './DebugMenu/DebugMenu';
import DesignsMenu from './DesignsMenu/DesignsMenu';
import TemplatesPanel from '../ElementPanels/TemplatesPanel/TemplatesPanel';
import TextLayoutsPanel from '../ElementPanels/TextLayoutsPanel/TextLayoutsPanel';
import TexturesPanel from '../ElementPanels/TexturesPanel/TexturesPanel';
import ElementsPanel from '../ElementPanels/ElementsPanel/ElementsPanel';
import PhotosMenu from './PhotosMenu/PhotosMenu';
import MockupTemplatesPanel from '../MockupTemplatesPanel/MockupTemplatesPanel';
import TextToImagePanel from '../TextToImagePanel/TextToImagePanel';

export const panels = [
  {
    id: 'designs',
    icon: 'create',
    label: 'My Projects',
    isExpandable: true,
    element: DesignsMenu,
  },
  {
    id: 'template',
    icon: 'template',
    label: 'Templates',
    isExpandable: true,
    element: TemplatesPanel,
  },
  {
    id: 'text',
    icon: 'text',
    label: 'Text',
    isExpandable: true,
    element: TextLayoutsPanel,
  },
  {
    id: 'elements',
    icon: 'elements',
    label: 'Elements',
    isExpandable: true,
    element: ElementsPanel,
  },
  {
    id: 'upload',
    icon: 'upload',
    label: 'Uploads',
    isExpandable: true,
    element: UploadsMenu,
  },
  {
    id: 'photo',
    icon: 'camera',
    label: 'Photos',
    isExpandable: true,
    element: PhotosMenu,
  },
  {
    id: 'texture',
    icon: 'texture',
    label: 'Textures',
    isExpandable: true,
    element: TexturesPanel,
  },
  {
    id: 'mockup',
    icon: 'mockup',
    isExpandable: false,
    element: MockupTemplatesPanel,
  },
  {
    id: 'textToImage',
    icon: 'starPair',
    label: 'Kittl AI',
    isExpandable: false,
    element: TextToImagePanel,
  },
];

isDebugActive() &&
  panels.push({
    id: 'debug',
    icon: 'settings',
    label: 'Debug',
    isExpandable: false,
    element: DebugMenu,
  });
