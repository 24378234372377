import styled from 'styled-components';

import { handleTextOverflow } from '../../utilities/styles';
import { P1 } from '../../utilities/Typography/styles';

export const FontImageWrapper = styled.div`
  height: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
  mask-image: linear-gradient(90deg, #000 60%, transparent);
  white-space: nowrap;
  box-sizing: border-box;
`;

export const FontImg = styled.img.attrs(({ src, alt }) => ({
  src,
  alt,
}))`
  height: 85%;
  width: auto;

  html[data-theme='dark'] && {
    filter: invert(1);
  }
`;

export const TypefaceAndVariable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, calc((100% - 16px) / 3));
  gap: 8px;
  margin: 8px 0;

  > :last-child {
    grid-column: 3;
  }

  > :first-child {
    grid-column: 1 / 3;
  }
`;

export const FontLabel = styled(P1)`
  ${handleTextOverflow}
`;
