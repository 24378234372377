// Adds two special canvas elements to the Canvas class instance
// _trueCanvas -> used for object true rendering
// _trueClipPathCanvas -> used for object clip path true rendering
// This is done to avoid having a single canvas for each element which can make memory usage go way up
export function addTrueRenderToCanvas(fabric) {
  fabric.StaticCanvas.prototype._createTrueCanvas = function () {
    this._trueCanvas = fabric.util.createCanvasElement();
    this._trueClipPathCanvas = fabric.util.createCanvasElement();
    this._trueContext = this._trueCanvas.getContext('2d');
    this._trueClipPathContext = this._trueClipPathCanvas.getContext('2d');
  };

  fabric.StaticCanvas.prototype.updateTrueCanvas = function () {
    if (!this._trueCanvas) {
      this._createTrueCanvas();
    }

    this._trueCanvas.setAttribute('width', this.width);
    this._trueCanvas.setAttribute('height', this.height);
    this._trueClipPathCanvas.setAttribute('width', this.width);
    this._trueClipPathCanvas.setAttribute('height', this.height);
  };
}

export default function (fabric, klass) {
  fabric.util.object.extend(klass.prototype, {
    getTrueCanvas: function (isClipPath = false) {
      return isClipPath
        ? this.canvas._trueClipPathCanvas
        : this.canvas._trueCanvas;
    },

    getTrueContext: function (isClipPath = false) {
      return isClipPath
        ? this.canvas._trueClipPathContext
        : this.canvas._trueContext;
    },

    clearTrueCanvas: function (clipPath) {
      const ctx = this.getTrueContext(clipPath);
      ctx.save();
      ctx.resetTransform();
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.restore();
    },

    renderTrue: function (options = {}) {
      const { clipPath } = options;
      const forClipping = !!clipPath;

      this.clearTrueCanvas(forClipping);

      const ctx = this.getTrueContext(forClipping);

      ctx.save();

      fabric.util.setImageSmoothing(ctx, this.canvas.imageSmoothingEnabled);
      ctx.transform(...this.getViewportTransform());

      if (clipPath) {
        if (!clipPath.absolutePositioned) {
          // this is currently the case for clipPaths in fabric.Mask and fabric.PathText
          this.transform(ctx);
        } else {
          clipPath.transform(ctx);
        }
      }

      if (clipPath) {
        clipPath.drawObject(ctx, true);
      } else if (this instanceof fabric.IllustrationImage) {
        // to trueRender fabric.IllustrationImage objects, it is necessary
        // to apply `this.transform(ctx)` when rendering the image, but not for the clipPath
        // the code below is fabrics default Object.drawObject, with small additions
        ctx.save(); // <-- this is added
        this.transform(ctx); // <-- this is added
        const originalFill = this.fill,
          originalStroke = this.stroke;
        if (forClipping) {
          this.fill = 'black';
          this.stroke = '';
          this._setClippingProperties(ctx);
        } else {
          this._renderBackground(ctx);
        }
        this._render(ctx);
        ctx.restore(); // <-- this is added
        this._drawClipPath(ctx, this.clipPath);
        this.fill = originalFill;
        this.stroke = originalStroke;
        // end of Object.drawObject
      } else {
        this.drawObject(ctx, false);
      }
      ctx.restore();
    },

    /**
     * decide whether trueRender should be used to render this object
     */
    _shouldRenderTrue: function () {
      if (!this.trueRender) return false;

      // flag to force trueRender for exports
      if (fabric.forceTrueRender) return true;

      // if the cache is at its maximum size
      const dims = this._limitCacheSize(this._getCacheCanvasDimensions());
      if (dims.capped) return true;

      return false;
    },

    _drawClipPath: function (ctx, tr) {
      const path = this.clipPath;
      if (!path) {
        return;
      }
      // path canvas gets overridden with this one. It's the same behaviour as for cache canvas
      path.canvas = this.canvas;
      path.shouldCache();
      path._transformDone = true;
      if (this._shouldRenderTrue()) {
        this.renderTrue({ clipPath: path });
        this.drawTrueOnCanvas(ctx, path);
      } else {
        path.renderCache({ forClipping: true });
        this.drawClipPathOnCache(ctx, path);
      }
    },

    drawTrueOnCanvas: function (ctx, clipPath) {
      ctx.save();
      ctx.transform(
        ...fabric.util.invertTransform(this.getViewportTransform())
      );

      if (clipPath) {
        // If we are drawing a clipPath we set the correct composite operation
        if (clipPath.inverted) {
          ctx.globalCompositeOperation = 'destination-out';
        } else {
          ctx.globalCompositeOperation = 'destination-in';
        }
      }

      ctx.drawImage(this.getTrueCanvas(!!clipPath), 0, 0);
      ctx.restore();
    },

    // This render function builds on top of what is changed in ./Object.js
    // on top of those changes it adds an extra handling of render in case `trueRender` property is set on the object
    render: function (ctx, opts) {
      // do not render if width/height are zeros or object is not visible
      if (this.isNotVisible()) {
        return;
      }
      if (
        this.canvas &&
        this.canvas.skipOffscreen &&
        !this.group &&
        !this.isOnScreen() &&
        !opts?.renderIfOffScreen
      ) {
        return;
      }

      if (opts?.ignoreFill) {
        this.setAllColors('black');
      }
      ctx.save();
      this._setupCompositeOperation(ctx);
      this.drawSelectionBackground(ctx);
      if (this._shouldRenderTrue()) {
        // That if clause was added to handle "true render" case
        this._setShadow(ctx, this);
        this.renderTrue();
        this._setOpacity(ctx);
        this.drawTrueOnCanvas(ctx);
      } else {
        this._transformDone = true;
        this.transform(ctx);
        this._setOpacity(ctx);
        this._setShadow(ctx, this);
        if (this.shouldCache()) {
          this.dirty = this.dirty || this._cacheIsDirty; // <-- That's what we added
          this._cacheIsDirty = false; // <-- That's what we added
          this.renderCache();
          this.drawCacheOnCanvas(ctx);
        } else {
          this._cacheIsDirty = this.dirty || this._cacheIsDirty; // <-- That's what we added
          // this._removeCacheCanvas(); // <-- That's what we removed
          this.dirty = false;
          this.drawObject(ctx);
          if (this.objectCaching && this.statefullCache) {
            this.saveState({ propertySet: 'cacheProperties' });
          }
        }
      }
      ctx.restore();
      this._transformDone = false;
    },
  });
}
