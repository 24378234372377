import styled, { keyframes } from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { P4 } from '../../utilities/Typography/styles';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Image = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 100%;
  height: auto;
  border-radius: ${themeStyle.radiusMedium};
  height: 100%;
  object-fit: cover;
`;

export const ImageWrapper = styled.div<any>`
  position: relative;
  box-sizing: border-box;
  border-radius: ${themeStyle.radiusMedium};
  cursor: pointer;
  width: ${({ width, height }) => width || height}px;
  height: ${({ width, height }) => width || height}px;
  animation: ${fadeIn} ease-in-out 0.5s;
`;

export const Gradient = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${themeStyle.zIndexS1};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 53.65%,
    rgba(0, 0, 0, 0.8) 100%
  );
  transition: opacity ease-in-out 0.5s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  border-radius: ${themeStyle.radiusMedium};
`;

export const LabelWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${themeStyle.zIndexS2};
  pointer-events: none;
  text-align: center;
  transition: opacity ease-in-out 0.5s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  width: 100%;

  ${P4} {
    line-height: 14px;
  }
`;

export const BorderOverlay = styled.div<any>`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid
    ${({ active }) => (active ? themeStyle.brandMedium : 'transparent')};
  box-shadow: inset 0 0 0 2px
    ${({ active }) => (active ? themeStyle.varBackgroundAlt : 'transparent')};
  border-radius: ${themeStyle.radiusMedium};
  top: 0;
  left: 0;
  z-index: ${themeStyle.zIndexS3};
`;
