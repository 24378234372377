import { themeStyle } from '../../../services/theming';

export const defaultTheme = {
  iconHeight: '16px',
  labelGap: '6px',
  fontSize: themeStyle.fontSize18,
  lineHeight: '22px',
  marginBottom: '0px',
  editColor: themeStyle.varInkMedium,
  backgroundColor: themeStyle.transparent,
};

export const smallTheme = {
  iconHeight: '14px',
  labelGap: '8px',
  fontSize: themeStyle.fontSize12,
  lineHeight: '17px',
  marginBottom: '5px',
};
