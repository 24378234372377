import React from 'react';
import PropTypes from 'prop-types';

import AutoSuggest from '../AutoSuggest/AutoSuggest';
import { SearchBarContainer } from './styles';

/**
 * Default header for Element Panels.
 */
const ElementsDefaultHeader = (props) => {
  const { query, setSearchQuery, suggestions, disabled } = props;

  return (
    <SearchBarContainer>
      <AutoSuggest
        value={query}
        placeholder={'Search'}
        icon={'search'}
        tags={suggestions}
        onChanged={setSearchQuery}
        disabled={disabled}
      />
    </SearchBarContainer>
  );
};

ElementsDefaultHeader.propTypes = {
  query: PropTypes.string,
  setSearchQuery: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ElementsDefaultHeader;
