import styled from 'styled-components';
import { BREAKPOINTS } from '../utilities/queries';

export const MainContainer = styled.div<{ isSignup?: boolean }>`
  height: auto;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.largeTabletMin}px) {
    width: ${({ isSignup }) => (isSignup ? '816px' : '408px')};
  }

  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${BREAKPOINTS.largeTabletMin}px) {
    grid-template-columns: ${({ isSignup }) => (isSignup ? '1fr 1fr' : '1fr')};
    padding: 0;
  }
`;
