/* eslint-disable no-console */

export default function debugPlugin() {
  return {
    name: 'debug',
    initialize: () => {
      // Check if window is undefined to avoid build and SSR errors.
      if (typeof window !== 'undefined') {
        console.log('[analytics] initialize');
      }
    },
    page: ({ payload }) => {
      console.log('[analytics] page', payload.properties);
    },
    track: ({ payload }) => {
      console.log('[analytics] track', payload.event, payload.properties);
    },
    identify: ({ payload }) => {
      const { userId } = payload;
      console.log('[analytics] identify', userId, payload.traits);
    },
    reset: () => {
      console.log('[analytics] reset');
    },
  };
}
