import PropTypes from 'prop-types';

export const Notification = PropTypes.shape({
  id: PropTypes.string,
  notificationType: PropTypes.string,
  readAt: PropTypes.string,
  relatedUser: PropTypes.shape({
    name: PropTypes.string,
    profileImage: PropTypes.string,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    })
  ),
});
