import { themeStyle } from '../../services/theming';

export const themeIconRow = {
  cellGap: 1,
  rowHeight: 45,
  backgroundColor: themeStyle.transparent,
  activeBackgroundColor: themeStyle.varBrandSuperLight,
  iconCellLabelGap: 4,
  heightToContent: false,
  width: '100%',
  borderBetween: 'none',
  borderRadius: themeStyle.radiusSmall,

  // Label
  textColor: themeStyle.varInkMedium,
  textActiveColor: themeStyle.varInkMain,
  fontFamily: themeStyle.fontFamily,
  fontWeight: themeStyle.fontMedium,
  fontSize: themeStyle.fontSize10,
  lineHeight: '13px',
};

export const iconTheme = {
  color: themeStyle.varInkMedium,
  activeColor: themeStyle.varInkMain,
  activeDefaultColor: themeStyle.varInkMain,
};
