import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Popover from '../Popover/Popover';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import SharePanelContent from './SharePanelContent';

const SharePanel = (props) => {
  const wrapperRef = useRef();
  const content = <SharePanelContent {...props} wrapperRef={wrapperRef} />;
  const isOpen = useMenuStore(menuStoreSelector.sharePanel);

  return (
    <Popover
      open={isOpen}
      onToggle={() => useMenuStore.setState({ sharePanel: !isOpen })}
      onWillClose={() => useMenuStore.setState({ sharePanel: false })}
      align="end"
      content={content}
      showHeader={false}
      width={'400px'}
      theme={{ padding: '0 12px' }}
      margin="10px 0 0 0"
      wrapperRef={wrapperRef}
    >
      {props.children}
    </Popover>
  );
};

SharePanel.propTypes = {
  /**
   * Dispatch function for handling events
   */
  dispatch: PropTypes.func,
  children: PropTypes.node,
};

export default SharePanel;
