// config ids are use as values that decide what id done on the event
export const alignActions = [
  { id: 'left', icon: 'left', label: 'Left' },
  { id: 'center', icon: 'center', label: 'Center' },
  { id: 'right', icon: 'right', label: 'Right' },
  { id: 'top', icon: 'top', label: 'Top' },
  { id: 'middle', icon: 'middle', label: 'Middle' },
  { id: 'bottom', icon: 'bottom', label: 'Bottom' },
];

export const tidyAction = { id: 'tidy', icon: 'tidy', label: 'Tidy' };
