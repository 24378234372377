import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Logo from '../Logo/Logo';
import {
  Wrapper,
  LeftButtons,
  LinkToWebsite,
  MiddleButtons,
  RightButtons,
  ToolsWrapper,
  VerticalDivider,
} from './styles';
import analytics from '../../global/analytics';
import { ATTRIBUTION_SHOW, MOCKUP_OPEN } from '../../global/analytics/events';
import ProjectTitle from '../ProjectTitle/ProjectTitle';
import Authentication from './AuthenticationButtons/AuthenticationButtons';
import UserButtons from './UserButtons/UserButtons';
import WithAuth from '../WithAuth/WithAuth';

import { FlexRow } from '../utilities/styles';
import IconButton from '../Button/IconButton';

import SettingsPanel from '../SettingsPanel/SettingsPanel';
import SharePanel from '../SharePanel/SharePanel';
import DownloadPanel from './DownloadPanel/DownloadPanel';
import Popover from '../Popover/Popover';
import Spacer from '../Spacer/Spacer';
import { WEBSITE_URL } from '../../utils/url';
import Notifications from './Notifications/Notifications';
import AttributionModal from '../AttributionModal/AttributionModal';
import {
  promotionSelector,
  usePromotionStore,
} from '../../stores/promotionStore';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import {
  getDisplayStep,
  onboardingStoreSelector,
  useOnboardingStore,
} from '../../stores/onboardingStore';
import {
  ANCHOR_REF_KEYS,
  ONBOARDING_USER_STEP,
} from '../../stores/onboardingStore/constants';
import Button from '../Button/Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import { floatTheme } from '../Button/Button/theme';
import { WorkingMode } from '../../types';
import { DESIGN_SAVE } from '../../global/events';
import { userStoreSelector, useUserStore } from '../../stores/userStore';
import useWindowSize from '../../hooks/useWindowSize';

/**
 * The Header is a Menu on the top of the page.
 */
const Header = (props) => {
  const [showAttributionModal, setShowAttributionModal] = useState(false);
  const upgradeModalIsOpen = usePromotionStore(
    promotionSelector.upgradeModalIsOpen
  );
  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );

  const openAttributionModal = () => {
    analytics.track(ATTRIBUTION_SHOW);
    setShowAttributionModal(true);
  };

  const showHighResolutionWarning = useMenuStore(
    menuStoreSelector.showHighResolutionWarning
  );

  const toggleDownload = useRef();

  const workingMode = useMenuStore(menuStoreSelector.workingMode);
  const isDefaultWorkingMode = workingMode === WorkingMode.Default;
  const downloadPanel = useMenuStore(menuStoreSelector.downloadPanel);
  const setDownloadPanel = useMenuStore(menuStoreSelector.setDownloadPanel);

  const step = useOnboardingStore(onboardingStoreSelector.step);
  const subStep = useOnboardingStore(onboardingStoreSelector.subStep);
  const displayStep = getDisplayStep(step, subStep);

  const onDownloadPanelToggle = useCallback(() => {
    setDownloadPanel(!downloadPanel);
  }, [setDownloadPanel, downloadPanel]);

  const closeDownloadPanel = useCallback(() => {
    setDownloadPanel(false);
  }, [setDownloadPanel]);

  const { dispatch } = props;
  const plan = useUserStore(userStoreSelector.plan);
  const setWorkingMode = useMenuStore(menuStoreSelector.setWorkingMode);
  const toggleMockupMode = useCallback(() => {
    if (isDefaultWorkingMode) {
      const cb = () => {
        analytics.track(MOCKUP_OPEN, {
          plan: plan ?? 'FREE',
        });
        setWorkingMode(WorkingMode.Mockup);
      };
      dispatch(DESIGN_SAVE, { cb });
    } else {
      setWorkingMode(WorkingMode.Default);
    }
  }, [isDefaultWorkingMode, setWorkingMode, plan, dispatch]);

  const { width } = useWindowSize();
  const showCompactView = width < 1280;

  return (
    <Wrapper hasBanner={props.hasBanner}>
      <LeftButtons>
        <LinkToWebsite href={WEBSITE_URL}>
          <Logo />
        </LinkToWebsite>
      </LeftButtons>
      <MiddleButtons>
        {isDefaultWorkingMode && (
          <ProjectTitle
            config={props.options.config}
            dispatch={props.dispatch}
            isLoading={props.isLoading}
          />
        )}
      </MiddleButtons>
      <RightButtons>
        {isDefaultWorkingMode && (
          <>
            <ToolsWrapper>
              <FlexRow height="100%">
                <SettingsPanel
                  options={props.options.artboardOptions}
                  dispatch={props.dispatch}
                >
                  <IconButton
                    dataTestId="settings-panel-toggler"
                    disabled={props.isLoading}
                    iconName="settings"
                    height="16px"
                    tooltipText="Settings"
                    tooltipPlacement="bottom"
                    tooltipAlignment="center"
                  />
                </SettingsPanel>
                <VerticalDivider />
                <SharePanel
                  isLoading={props.isLoading}
                  dispatch={props.dispatch}
                >
                  <IconButton
                    disabled={props.isLoading}
                    iconName="share"
                    height="16px"
                    tooltipText="Share"
                    tooltipPlacement="bottom"
                    tooltipAlignment="center"
                  />
                </SharePanel>
                <AttributionModal
                  isOpen={showAttributionModal}
                  onClose={() => setShowAttributionModal(false)}
                />
                <VerticalDivider />
                <Notifications />
              </FlexRow>
            </ToolsWrapper>
            <Spacer w="12px" />
          </>
        )}
        {workingMode !== WorkingMode.MockupTemplate && (
          <>
            <Button
              width="auto"
              theme={floatTheme}
              disabled={props.isLoading}
              icon={
                isDefaultWorkingMode
                  ? {
                      name: 'mockup',
                      height: '17px',
                    }
                  : undefined
              }
              label={
                isDefaultWorkingMode
                  ? showCompactView
                    ? ''
                    : 'Mockup'
                  : '<--  Back to Editor'
              }
              onClick={toggleMockupMode}
            />
            <Spacer w="12px" />
          </>
        )}
        {isDefaultWorkingMode && (
          <>
            <Popover
              open={downloadPanel}
              label="Download Options"
              align={'end'}
              width={'288px'}
              ignoreEventOutside={
                upgradeModalIsOpen ||
                showAttributionModal ||
                showHighResolutionWarning ||
                displayStep?.name === ONBOARDING_USER_STEP.DOWNLOAD.name
              }
              targetRef={(targetRef) =>
                useOnboardingStore
                  .getState()
                  .registerStepRef(
                    targetRef?.current,
                    ANCHOR_REF_KEYS.DOWNLOAD_BUTTON
                  )
              }
              toggleRef={toggleDownload}
              onToggle={onDownloadPanelToggle}
              onWillClose={closeDownloadPanel}
              content={
                <DownloadPanel
                  options={props.options.artboardOptions}
                  onShowUpgradeModal={showUpgradeModal}
                  onShowAttributionModal={openAttributionModal}
                  onMockupMode={closeDownloadPanel}
                  dispatch={props.dispatch}
                />
              }
              theme={{
                padding: '0px',
                headPadding: '12px',
              }}
            >
              <Tooltip placement="bottom" align="center">
                <Button
                  width="auto"
                  theme={floatTheme}
                  dataTestId="download-panel-toggler"
                  disabled={props.isLoading}
                  icon={{
                    name: 'download',
                    height: '17px',
                  }}
                  label={showCompactView ? '' : 'Download'}
                />
              </Tooltip>
            </Popover>
            <Spacer w="12px" />
          </>
        )}
        <WithAuth
          disableAnimation={true}
          renderAuth={(user, onLogOut) => (
            <UserButtons user={user} onLogOut={onLogOut} />
          )}
          renderUnauth={() => <Authentication />}
        />
      </RightButtons>
    </Wrapper>
  );
};

Header.propTypes = {
  /**
   * options that determine values of the settings panel
   */
  options: PropTypes.shape({
    artboardOptions: PropTypes.shape({
      activeLayout: PropTypes.string,
      showRulers: PropTypes.bool,
      orientation: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    config: PropTypes.shape({}),
  }),
  /**
   * indicates that the artboard is currently loading
   */
  isLoading: PropTypes.bool,
  /**
   * Dispatch function for handling events
   */
  dispatch: PropTypes.func,
  hasBanner: PropTypes.bool,
};

export default React.memo(Header, (prev, newProps) => {
  // we don't need to compare dispatch
  return (
    JSON.stringify(prev.options) === JSON.stringify(newProps.options) &&
    prev.hasBanner === newProps.hasBanner &&
    prev.isLoading === newProps.isLoading
  );
});
