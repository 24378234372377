import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import Api from '../global/api';
import userApi from '../global/userApi';
import { DEFAULT_SORT, DEFAULT_TAKE } from '../global/constants';
import { OfflineError } from '../utils/fetchRetry';
import mockupApi from '../api/mockups';

const { getBookmarkReferences } = userApi;

const fetchFunctions = {
  templates: {
    fetch: Api.getGroupTemplates,
    getOverview: Api.getTemplatesOverview,
  },
  elements: {
    fetch: Api.getGroupElements,
    getOverview: Api.getOverview,
  },
  textures: {
    fetch: Api.getGroupElements,
    getOverview: Api.getTextureOverview,
  },
  layouts: {
    fetch: Api.getGroupTextLayouts,
    getOverview: Api.getTextLayoutsOverview,
  },
  mockups: {
    fetch: mockupApi.getGroup,
    getOverview: mockupApi.getOverview,
  },
};

const getFetchResults = (type, response) => {
  switch (type) {
    case 'templates':
      return response.templates;
    case 'elements':
      return response.elements;
    case 'textures':
      return response.elements;
    case 'layouts':
      return response.layouts;
    case 'mockups':
      return response.mockups;
    default:
      return null;
  }
};

const getResponseOverview = (type, response) => {
  switch (type) {
    case 'templates':
      return response.templates;
    case 'elements':
      return response.overview;
    case 'textures':
      return response.overview;
    case 'layouts':
      return response;
    case 'mockups':
      return response;
    default:
      return null;
  }
};

const hasCategories = (type) => {
  switch (type) {
    case 'layouts':
      return false;
    case 'mockups':
      return false;
    default:
      return true;
  }
};

/*
  Elements store keeps different objects with info about groups of elements and
  the elements themselves.

  They are kept in the format
  ElementType: {
    groupId_1 : {
      total,
      name,
      skip,
      sort,
      finished,
      isFetching,
      elements ---> The actual elements
    },
    groupId_2: {
      ...
    },
    ...,
    ...
  }

  When using the fetch function, you can pass the type of element you need to fetch,
  the id of the group and the sorting method, i.e fetch('templates', 6, 'name'), and store
  takes care of updating the state.
*/

const elementsStore = createVanilla((set, get) => ({
  initialized: false,
  overviewsInitialized: false,

  /* ================= */
  /* Elements */

  templates: {},
  layouts: {},
  elements: {},
  textures: {},
  mockups: {},

  /* ================= */

  bookmarks: {},
  categories: {},

  _initialize: () => {
    /*
      setOverview sets some initial data for groups of elements by using the overview endpoints.
      Menus need access to this info since they need to know the list of group ids that elements need to
      be fetched with.
    */

    const setOverview = (typeKey) => {
      const { getOverview } = fetchFunctions[typeKey];

      let categories = {};
      return getOverview().then((overview) => {
        overview = getResponseOverview(typeKey, overview);
        overview = Object.keys(overview).reduce((acc, categoryKey) => {
          let categoryOverview = overview[categoryKey];
          if (!Array.isArray(categoryOverview)) {
            return acc;
          }

          if (hasCategories(typeKey)) {
            categories[categoryKey] = categoryOverview
              .sort((a, b) => b.popularity - a.popularity)
              .map((e) => e.id);
          }

          categoryOverview = categoryOverview.reduce((_acc, groupOverview) => {
            const { id, name, total, popularity } = groupOverview;

            if (!hasCategories(typeKey)) {
              categories[name] = [id];
            }

            acc[id] = {
              total,
              name,
              popularity,
              skip: 0,
              sort: DEFAULT_SORT,
              finished: false,
              isFetching: false,
              elements: [],
            };
            return acc;
          }, {});
          for (const key in categoryOverview) {
            acc[key] = categoryOverview[key];
          }
          return acc;
        }, {});

        if (!hasCategories(typeKey)) {
          const allGroupIds = Object.keys(categories).map(
            (key) => categories[key][0]
          );
          categories = { All: allGroupIds, ...categories };
        }

        set((state) => {
          state.categories[typeKey] = categories;
          state[typeKey] = overview;
          return { ...state };
        });
      });
    };

    const overviews = Promise.all(
      Object.keys(fetchFunctions).map((typeKey) => setOverview(typeKey))
    );
    overviews.then(() => set({ overviewsInitialized: true }));
  },

  initialize: () => {
    if (!get().initialized) {
      get()._initialize();
      set({ initialized: true });
    }
  },

  updateBookmarks: (typeKey) => {
    if (get().bookmarks[typeKey]) return;
    getBookmarkReferences().then((bookmarks) => {
      set({
        bookmarks: {
          [typeKey]: {
            local: [],
            fetched: bookmarks[typeKey],
          },
        },
      });
    });
  },

  resetBookmarks: (typeKey) => {
    set({
      bookmarks: {
        [typeKey]: {
          local: [],
          fetched: [],
        },
      },
    });
  },

  /* Resets info on a group id from a specific type of element */
  reset: (type, groupId, sort) => {
    set((state) => {
      state[type][groupId].skip = 0;
      state[type][groupId].elements = [];
      state[type][groupId].finished = false;
      state[type][groupId].isFetching = false;
      state[type][groupId].sort = sort;
      return { ...state };
    });
  },

  fetch: async (type, groupId, sort, take = DEFAULT_TAKE) => {
    const {
      [type]: {
        [groupId]: { sort: currentSort },
      },
    } = get();

    if (sort && currentSort !== sort) {
      //If sort changes, reset group
      get().reset(type, groupId, sort);
    } else {
      sort = currentSort;
    }

    const {
      [type]: {
        [groupId]: { finished, skip, elements, isFetching },
      },
    } = get();

    if (isFetching) return;
    if (finished) return;

    set((state) => {
      state[type][groupId].isFetching = true;
      return { ...state };
    });

    const { fetch: _fetch } = fetchFunctions[type];

    let fetchedElements;

    try {
      fetchedElements = await _fetch(groupId, take, skip, sort);
      fetchedElements = getFetchResults(type, fetchedElements);
    } catch (e) {
      set((state) => {
        state[type][groupId] = {
          ...state[type][groupId],
          isFetching: false,
        };
        return { ...state };
      });

      if (e instanceof OfflineError) throw e;
      return;
    }

    if (!fetchedElements) {
      set((state) => {
        state[type][groupId] = {
          ...state[type][groupId],
          isFetching: false,
        };
        return { ...state };
      });
      return;
    }

    const {
      [type]: {
        [groupId]: { sort: updatedSort, name: groupName },
      },
    } = get();
    if (updatedSort !== sort) return;

    const isFinished = fetchedElements.length < take;

    set((state) => {
      const total = isFinished
        ? { total: elements.length + fetchedElements.length }
        : {};

      state[type][groupId] = {
        ...state[type][groupId],
        elements: [
          ...elements,
          ...fetchedElements.map((elem) => ({ ...elem, groupName })),
        ],
        skip: skip + take,
        isFetching: false,
        finished: isFinished,
        sort: sort,
        ...total,
      };
      return { ...state };
    });

    return true;
  },

  isBookmarked: (elementType, id) => {
    if (!get().bookmarks[elementType]) return false;
    const {
      bookmarks: {
        [elementType]: { local, fetched },
      },
    } = get();
    return !!(fetched?.includes(id) || local?.find((b) => b.id === id));
  },

  addOrRemoveBookmark: (elementType, element) => {
    if (!get().bookmarks[elementType]) return false;
    const { isBookmarked } = get();
    if (isBookmarked(elementType, element.id)) {
      set((state) => ({
        bookmarks: {
          ...state.bookmarks,
          [elementType]: {
            fetched: state.bookmarks[elementType].fetched.filter(
              (fetched) => fetched !== element.id
            ),
            local: state.bookmarks[elementType].local.filter(
              (local) => local.id !== element.id
            ),
          },
        },
      }));
    } else {
      set((state) => ({
        bookmarks: {
          ...state.bookmarks,
          [elementType]: {
            ...state.bookmarks[elementType],
            local: [element, ...state.bookmarks[elementType].local],
          },
        },
      }));
    }
  },
}));

const useElementsStore = create(elementsStore);

export const elementStoreSelector = {
  initialize: (state) => state.initialize,
  isBookmarked: (state) => state.isBookmarked,
  storeBookmarks: (state) => state.bookmarks.templates?.fetched,
  localBookmarks: (state) => state.bookmarks.templates?.local,
  templates: (state) => state.bookmarks.templates,
  categories: (state) => state.categories,
  fetch: (state) => state.fetch,
  addOrRemoveBookmark: (state) => state.addOrRemoveBookmark,
  updateBookmarks: (state) => state.updateBookmarks,
  overviewsInitialized: (state) => state.overviewsInitialized,
};

export default useElementsStore;
