import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ButtonsWrapper } from './styles';
import { DESIGN_DELETE, DESIGN_DUPLICATE } from '../../../../../global/events';
import DecisionModal from '../../../../DecisionModal/DecisionModal';
import IconButton from '../../../../Button/IconButton';
import { circledIconTheme } from '../../../../Icon/theme';
import Popover from '../../../../Popover/Popover';
import OptionList from '../../../../OptionList/OptionList';

import useDesignsStore, {
  designsStoreSelector,
} from '../../../../../stores/designsStore';
import folderApi from '../../../../../global/folderApi';
import { useToastStore, toastSelector } from '../../../../../stores/toastStore';

const DesignButtons = ({
  id,
  isActive,
  dispatch,
  preview,
  folderId,
  onMove,
}) => {
  const [folder] = useDesignsStore(designsStoreSelector.useFolder);
  const updateElement = useDesignsStore(designsStoreSelector.updateElement);
  const fireToast = useToastStore(toastSelector.fire);
  const [isConfirmPanelOpen, setIsConfirmPanelOpen] = useState(false);

  const deleteDesign = (id) => {
    dispatch(DESIGN_DELETE, { id });
  };

  const onConfirmDelete = () => {
    deleteDesign(id);
    setIsConfirmPanelOpen(false);
  };

  const onCancelDelete = () => {
    setIsConfirmPanelOpen(false);
  };

  const handleDuplicate = () => {
    dispatch(DESIGN_DUPLICATE, { id, preview });
  };

  const onRemove = async () => {
    const response = await folderApi.removeFromFolder(folderId, id);
    let error;
    if (!response.success) {
      error = response.error || 'Failed to remove the project';
    } else {
      updateElement(response.design);
    }

    fireToast({
      label: error || 'Removed 1 project from folder',
      error: !!error,
    });
  };

  const options = [
    {
      key: 'duplicate',
      label: 'Duplicate',
      iconName: 'copy',
      onClick: handleDuplicate,
    },
  ];

  // disallow deleting active design
  if (!isActive) {
    options.push({
      key: 'delete',
      label: 'Delete',
      iconName: 'delete',
      onClick: () => setIsConfirmPanelOpen(true),
    });
  }

  if (folderId && folderId === folder?.id) {
    options.unshift({
      key: 'removeFromFolder',
      label: 'Remove from Folder',
      iconName: 'folderCrossedOut',
      onClick: onRemove,
    });
  }

  options.unshift({
    key: 'moveToFolder',
    label: 'Move to Folder',
    iconName: 'folder',
    onClick: onMove,
  });

  const optionList = <OptionList options={options} />;

  return (
    <>
      <DecisionModal
        isOpen={isConfirmPanelOpen}
        onClose={() => setIsConfirmPanelOpen(false)}
        icon="delete"
        message="Are you sure you want to delete this project?"
        note={`You can restore projects from your trash folder\nwithin 90 days after deletion.`}
        firstButton={{
          label: 'Cancel',
          onClick: onCancelDelete,
        }}
        secondButton={{
          label: 'Confirm',
          onClick: onConfirmDelete,
        }}
      />
      <ButtonsWrapper>
        <Popover
          hideTip
          placement="right"
          align="start"
          showHeader={false}
          width="185px"
          content={optionList}
          theme={{ padding: '12px' }}
        >
          <IconButton
            iconName="designElementSettings"
            height="24px"
            theme={circledIconTheme}
          />
        </Popover>
      </ButtonsWrapper>
    </>
  );
};

DesignButtons.propTypes = {
  /**
   * id of the design
   */
  id: PropTypes.string,
  /**
   * whether the design is active
   */
  isActive: PropTypes.bool,
  /**
   * a preview for the design
   */
  preview: PropTypes.string,
  /**
   * the folder id of the design
   */
  folderId: PropTypes.string,
  /**
   * function to handle deletion and duplication
   */
  dispatch: PropTypes.func,
  /**
   * function to handle moving a design
   */
  onMove: PropTypes.func,
};

export default DesignButtons;
