export interface WarpControlPointInitOptions {
  /*
    The position where the point gets rendered.
    Note that with `{ originX: 'center', originY: 'center' }`,
    these should be the position of the center.
   */
  left: number;
  top: number;
  /*
    The indices of the row and column where the point is on in the grid.
   */
  i: number;
  j: number;

  locked?: boolean;
}

export enum WarpControlHandleDirection {
  'Left' = 'left',
  'Right' = 'right',
  'Top' = 'top',
  'Bottom' = 'bottom',
}
