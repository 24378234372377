import styled from 'styled-components';
import { handleTextOverflow } from '../utilities/styles';
import { themeStyle } from '../../services/theming';

export const Header = styled.div`
  margin-bottom: 12px;
  display: flex;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
  width: 100%;
  overflow: hidden;
`;

export const UserName = styled.label`
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  color: ${themeStyle.varInkMain};
  width: 100%;
  ${handleTextOverflow}
`;
