import styled, { css } from 'styled-components';
import { themeStyle } from '../../services/theming';

import { FlexColumn } from '../utilities/styles';
import { MinimalTestimonialWrapper } from '../Testimonial/styles';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 32px 24px;
  box-sizing: border-box;
  width: 336px;
  flex-shrink: 0;
`;

export const ImageWrapper = styled.div<{ background: string }>`
  flex-grow: 1;

  background: ${({ background }) => background};
  background-size: cover;
  background-position-x: center;
  background-position-y: 25%; /* this is an experimental value that looks good with current content */
`;

const centerChildren = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageComponentWrapper = styled.div`
  flex-grow: 1;

  height: 100%;
  max-width: 100%;

  padding: 48px;
  box-sizing: border-box;
  ${centerChildren}
`;

export const TestimonialWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${centerChildren}

  ${MinimalTestimonialWrapper}:first-of-type {
    align-self: flex-end;
  }

  ${MinimalTestimonialWrapper}:last-of-type {
    margin-left: 62px;
  }
`;

export const BrandsWrapper = styled(FlexColumn)`
  padding: 0 12px;
`;

export const BrandsImage = styled.img`
  width: 100%;
  height: auto;
`;

export const DesignPreview = styled.img`
  max-width: 100%;
  border-radius: ${themeStyle.radiusMedium};
  box-shadow: ${themeStyle.varShadowSoft};
`;
