import styled from 'styled-components';

export const PanelWrapper = styled.div`
  position: relative;
  display: flex;
  margin-top: 5px;
  flex-direction: column;
`;

export const PreviewImage = styled.img.attrs(({ src }) => ({
  src,
  crossOrigin: 'anonymous',
}))`
  height: 62px;
  width: 62px;
  user-drag: none;

  margin-right: 8px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.borderColor};
`;

export const RightColumn = styled.div`
  flex-grow: 1;

  > *:not(:last-child) {
    margin-top: 0px;
    margin-bottom: 7px;
  }
`;
