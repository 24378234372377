import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button/Button';
import { menuSmallTheme } from '../Button/Button/theme';
import { FlexColumn } from '../utilities/styles';

const OptionList = (props) => {
  const { options } = props;

  return (
    <FlexColumn>
      {options.map((option, index) => {
        const key = option.key || index;
        return (
          <Button
            onClick={option.onClick}
            isCentered={false}
            isBlock
            key={key}
            dataTestId={key}
            icon={{ name: option.iconName, height: options.iconHeight }}
            label={option.label}
            theme={{ ...menuSmallTheme, padding: '8px' }}
          />
        );
      })}
    </FlexColumn>
  );
};

OptionList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      iconName: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default OptionList;
