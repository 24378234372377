import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal/Modal';
import Button from '../Button/Button/Button';
import { primaryTheme, secondaryTheme } from '../Button/Button/theme';
import Icon from '../Icon/Icon';
import Logo from '../Logo/Logo';
import { Wrapper, Header, Message, Note, ButtonWrapper } from './styles';
import { H3 } from '../utilities/Typography/styles';

const ESCAPE = 'Escape';
const ENTER = 'Enter';

const getButtonTheme = ({ type, theme, position }) => {
  let baseTheme;
  if (position === 'first' || type === 'secondary' || !type)
    baseTheme = secondaryTheme;
  if (position === 'second' || type === 'primary') baseTheme = primaryTheme;

  return { ...baseTheme, ...theme };
};

const DecisionModal = ({
  isOpen,
  width,
  logo,
  icon,
  iconTheme,
  title,
  message,
  note,
  firstButton,
  secondButton,
  onClose,
  escapeTrigger,
  content,
}) => {
  const firstButtonTheme = React.useMemo(
    () =>
      firstButton &&
      getButtonTheme({
        type: firstButton.type,
        theme: firstButton.theme,
        position: 'first',
      }),
    [firstButton]
  );
  const secondButtonTheme = React.useMemo(
    () =>
      secondButton &&
      getButtonTheme({
        type: secondButton.type,
        theme: secondButton.theme,
        position: 'second',
      }),
    [secondButton]
  );

  const handleKeyDown = React.useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (event.key === ESCAPE) {
        if (!escapeTrigger) {
          onClose && onClose();
          return;
        }
        firstButton.onClick();
        onClose && onClose();
      }
      if (event.key === ENTER) {
        if (!secondButton) {
          firstButton.onClick();
          onClose && onClose();
        } else {
          secondButton.onClick();
          onClose && onClose();
        }
      }
    },
    [firstButton, secondButton, escapeTrigger, onClose]
  );

  React.useEffect(() => {
    isOpen && document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, isOpen]);

  return (
    <Modal isOpen={isOpen} width={width} onClose={onClose}>
      <Wrapper className={secondButton && 'two-buttons'}>
        <Header>
          {logo && <Logo />}
          {icon && <Icon name={icon} theme={iconTheme} height="30px" />}
          {title && <H3>{title}</H3>}
        </Header>
        {message && <Message>{message}</Message>}
        {content ? content : null}
        {note && <Note>{note}</Note>}
        <ButtonWrapper>
          {firstButton && (
            <Button
              label={firstButton.label}
              width={firstButton.width}
              onClick={() => {
                firstButton.onClick();
                onClose && onClose();
              }}
              theme={firstButtonTheme}
              icon={firstButton.icon ? { name: firstButton.icon } : null}
            />
          )}
          {secondButton && (
            <Button
              label={secondButton.label}
              width={secondButton.width}
              onClick={() => {
                secondButton.onClick();
                onClose && onClose();
              }}
              theme={secondButtonTheme}
              icon={secondButton.icon ? { name: secondButton.icon } : null}
            />
          )}
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
};

DecisionModal.defaultProps = {
  isOpen: false,
  firstButton: { label: 'OK', type: 'primary', onClick: () => {} },
  escapeTrigger: true,
};

const buttonPropTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.shape({}),
  icon: PropTypes.string,
  width: PropTypes.string,
};

DecisionModal.propTypes = {
  isOpen: PropTypes.bool,
  /**
   * width of the modal
   */
  width: PropTypes.string,
  title: PropTypes.node,
  message: PropTypes.node,
  /**
   * an additional message that will be displayed in the modal
   */
  note: PropTypes.node,
  icon: PropTypes.string,
  iconTheme: PropTypes.object,
  logo: PropTypes.bool,
  firstButton: PropTypes.shape(buttonPropTypes).isRequired,
  secondButton: PropTypes.shape(buttonPropTypes),
  onClose: PropTypes.func,
  // Will trigger first button's onClick function when pressing escape
  escapeTrigger: PropTypes.bool,
  //Optional content to display
  content: PropTypes.element,
};

DecisionModal.defaultProps = {
  width: '408px',
};

export default DecisionModal;
