import StylesMenu from '../StylesMenu/StylesMenu';
import EffectsMenu from '../EffectsMenu/EffectsMenu';

import { getObjectIconTheme, getObjectSettingsIcon } from './utils';
import ProjectColorsMenu from '../ProjectColorsMenu/ProjectColorsMenu';
import MockupMenu from '../MockupMenu/MockupMenu';

import omit from 'lodash/omit';
import { Panel } from './types';
import { MenuTabConfig } from '../MenuHeader/types';
import { secondaryIconTheme } from '../MenuHeader/theme';

const styleIconConfig: Partial<Panel> = {
  icon: 'style',
  iconTheme: secondaryIconTheme,
};

export const panels: Panel[] = [
  {
    id: 'styles',
    label: 'Settings',
    element: StylesMenu,
    getIcon: getObjectSettingsIcon,
    getIconTheme: getObjectIconTheme,
  },
  {
    id: 'effects',
    icon: 'effect',
    label: 'Effects',
    element: EffectsMenu,
  },
  {
    id: 'projectColors',
    label: 'Settings',
    element: ProjectColorsMenu,
    ...styleIconConfig,
  },
  {
    id: 'mockup',
    label: 'Mockup Settings',
    element: MockupMenu,
    ...styleIconConfig,
  },
];

export const getTabsFromActivePanel = (
  activePanel?: Panel,
  options?: Record<string, unknown>
): MenuTabConfig[] => {
  if (!activePanel) {
    return [];
  }

  let filteredPanels;

  if (activePanel.id === 'mockup') {
    filteredPanels = [panels.find((panel) => panel.id === 'mockup')];
  } else if (activePanel.id === 'projectColors') {
    filteredPanels = [panels.find((panel) => panel.id === 'projectColors')];
  } else if (options?.type === 'pathText') {
    filteredPanels = panels.filter(
      ({ id }) => id !== 'projectColors' && id !== 'mockup'
    );
  } else {
    filteredPanels = panels.filter(({ id }) => id === activePanel.id);
  }

  return filteredPanels.map((panel) => ({
    ...omit(panel, ['element']),
    icon: options && panel!.getIcon ? panel!.getIcon(options) : panel!.icon,
    iconTheme:
      options && panel!.getIconTheme
        ? panel!.getIconTheme(options)
        : panel!.iconTheme,
  }));
};

export const defaultPanel = 'styles';
