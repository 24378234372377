import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { darkBackgroundBlur } from '../utilities/styles';

const marginBottom = '70px'; // height of the BottomMenu
const transition = '0.5s ease-in-out';

export const Wrapper = styled.div`
  position: absolute;
  left: 0px;
  top: ${({ marginTop }) => marginTop};
  height: calc(100% - ${({ marginTop }) => marginTop} - ${marginBottom});
  width: 100%;

  /* vertically center navigation */
  display: flex;
  align-items: center;
`;

export const Body = styled.div`
  pointer-events: auto;
  height: 100%;
  width: ${(props) => (props.state !== 'expanded' ? '360px' : '100%')};
  position: absolute;
  left: 0;
  padding-left: ${({ navWidth, navHidden }) => (navHidden ? '15px' : navWidth)};
  padding-right: ${(props) => (props.state === 'expanded' ? '30' : '0')}px;
  box-sizing: border-box;

  visibility: ${(props) => (props.state === 'closed' ? 'hidden' : 'visible')};
  transform: translateX(
    ${(props) => (props.state === 'closed' ? '-360' : '0')}px
  );
  transition: all ${transition};

  background: ${themeStyle.varBackgroundAlt};
  box-shadow: ${themeStyle.varShadowMedium};
  border-radius: ${(props) =>
    props.state !== 'expanded'
      ? `0px ${themeStyle.radiusLarge} ${themeStyle.radiusLarge} 0px`
      : '0px'};

  // this was added to prevent the absolute container cutting off AutoSuggest's TextInput shadow
  & > div {
    padding-left: 3px;
  }

  ${darkBackgroundBlur}
`;

export const ExpandButton = styled.div`
  position: absolute;
  right: ${(props) =>
    props.state === 'expanded'
      ? '0'
      : '-20'}px; /* 20px is the width of the button */
  top: calc(50% - 50px); /* 50px is the height of the button divided by 2 */

  transition: all ${transition};

  cursor: pointer;
  transform: scaleX(${(props) => (props.state === 'expanded' ? '-1' : '1')});

  svg {
    .fillDefault {
      transition: fill ${transition};
      fill: ${(props) =>
        props.state !== 'expanded'
          ? themeStyle.varBackgroundAlt
          : themeStyle.varBackgroundAltTransparent};
    }
    .strokeDefault {
      transition: stroke ${transition};
      stroke: ${themeStyle.varInkLight};
    }
  }
`;

export const ElementGroupContainer = styled.div`
  margin-bottom: 10px;
`;
