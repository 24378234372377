import styled, { css, keyframes } from 'styled-components';

import { themeStyle } from '../../services/theming';

/**
 *  Utility style to handle overflow
 * in single line labels
 */
export const handleTextOverflow = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface ApplyFontStylesProps {
  fontFamily: string;
  fontWeight: string;
  fontSize?: string;
  lineHeight?: string;
}

/**
 * Apply default font style attributes
 */
export const applyFontStyles = css`
  font-family: ${(props: ApplyFontStylesProps) => props.fontFamily};
  font-weight: ${(props: ApplyFontStylesProps) => props.fontWeight};
  ${(props: ApplyFontStylesProps) =>
    props.fontSize ? `font-size:${props.fontSize};` : ''};
  ${(props: ApplyFontStylesProps) =>
    props.lineHeight ? `line-Height:${props.lineHeight};` : ''};
`;

/**
 * Utility styled component to center the children
 * in its parent
 */
export const CenterChildren = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

/**
 * Utility styled component to create a row with flex
 * in its parent
 */
export const FlexContainer = styled.div<any>`
  display: flex;

  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'unset'};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'unset')};
  width: ${({ width }) => width || 'unset'};
  min-width: ${({ minWidth }) => minWidth || 'unset'};

  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 'unset')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : 'unset'};
  height: ${({ height }) => (height ? height : 'unset')};
  gap: ${({ gap }) => (gap ? gap : 'unset')};
  position: ${({ position }) => (position ? position : 'unset')};
`;

export const FlexRow = styled(FlexContainer)<any>``;
export const FlexColumn = styled(FlexContainer)<any>`
  flex-direction: column;
`;

export const Background = styled.div<{
  background: string;
  isLoading: boolean;
}>`
  // no background after loaded, to avoid side effect of transparent color overlapping
  background: ${({ background, isLoading }) =>
    isLoading ? background : 'none'};
  height: 100%;
  width: 100%;
`;

export const Message = styled.div<any>`
  font-family: ${themeStyle.fontFamily};
  font-weight: ${themeStyle.fontMedium};
  font-size: ${themeStyle.fontSize12};
  line-height: 175%;
  color: ${themeStyle.inkMedium};

  margin: auto;
  max-width: 400px;
  text-align: ${({ textAlign }) => textAlign || 'center'};
`;

export const Divider = styled.div<any>`
  height: 0;
  width: 100%;
  border-top: 1px solid;
  border-color: ${({ color }) => color};
  margin: ${({ margin }) => margin || 'unset'};
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const hideScrollBar = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const darkBackgroundBlur = css`
  html[data-theme='dark'] && {
    backdrop-filter: blur(4px);
  }
`;
