import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import IconEventWrapper from '../Icon/IconEventWrapper';
import { handleTextOverflow } from '../utilities/styles';
import { TagTheme } from './types';

interface StyleProps {
  theme: TagTheme;
  tagIsActive?: boolean;
}

const getBackgroundColor = (props: StyleProps): string => {
  if (props.tagIsActive && props.theme.backgroundColorHover) {
    return props.theme.backgroundColorHover;
  }
  return props.theme.backgroundColor ?? themeStyle.varInkSuperLight;
};

const getBorderColor = (props: StyleProps): string => {
  if (props.tagIsActive && props.theme.borderColorHover) {
    return props.theme.borderColorHover;
  }
  return props.theme.borderColor ?? themeStyle.varInkLight;
};

export const getColor = (props: StyleProps): string => {
  if (props.tagIsActive) {
    return props.theme.colorHover ?? themeStyle.varInkMain;
  }
  return props.theme.color ?? themeStyle.varInkMedium;
};

export const Wrapper = styled(IconEventWrapper)<any>`
  box-sizing: border-box;
  max-width: 270px;
  border-radius: ${({ theme }) =>
    theme.borderRadius || themeStyle.radiusMedium};
  padding: ${({ theme }) => theme.padding ?? '0 8px'};
  display: flex;
  align-items: center;

  height: ${({ theme }) => theme.height ?? '20px'};
  cursor: ${({ hasOnClick }) => (hasOnClick ? 'pointer' : 'default')};

  border: 1px solid;
  border-color: ${getBorderColor};
  background-color: ${getBackgroundColor};

  transition: all 0.3s;

  span {
    font-family: ${themeStyle.fontFamily};
    font-size: ${({ theme }) => theme.fontSize || themeStyle.fontSize10};
    line-height: ${({ theme }) => theme.lineHeight || '12px'};
    font-weight: ${({ tagIsActive, theme }) =>
      theme.fontWeight ?? tagIsActive
        ? themeStyle.fontSemiBold
        : themeStyle.fontMedium};
    color: ${getColor};
    letter-spacing: ${({ theme }) => theme.letterSpacing ?? '0.03em'};

    transition: all 0.3s;
    ${handleTextOverflow}
  }

  &:hover {
    border-color: ${({ theme }) =>
      getBorderColor({ theme, tagIsActive: true })};
    background-color: ${({ theme }) =>
      getBackgroundColor({ theme, tagIsActive: true })};
    span {
      color: ${({ theme }) => getColor({ theme, tagIsActive: true })};
    }
  }
`;

export const IconWrapper = styled.div<any>`
  padding-left: 6px;
`;
