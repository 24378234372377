import styled, { keyframes } from 'styled-components';
import { FlexColumn } from '../utilities/styles';

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
    margin-bottom: -10px;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    margin-bottom: 0px;
  }
`;

export const Row = styled.div<{ index: number }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ index }) => (index === 0 ? '0px' : '8px')};
`;

export const ConfigurationPanel = styled(FlexColumn)`
  position: relative;
  animation-name: ${slideIn};
  animation-duration: 0.3s;
  margin-top: 10px;
`;
