import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Wrapper, InnerWrapper, Label, UnstyledButton } from './styles';
import { iconTheme, inputTheme } from './theme';
import Icon from '../Icon/Icon';
import IconEventWrapper from '../Icon/IconEventWrapper';
import TextInput from '../TextInput/TextInput';

import {
  DESELECT_OBJECTS,
  DESIGN_RENAME,
  DESIGN_SAVE,
} from '../../global/events';
import { useUserStore, userStoreSelector } from '../../stores/userStore';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import { timeAgo } from '../../utils/timeAgo';
import { MAX_DESIGN_NAME_LENGTH } from '../../global/constants';
import Tooltip from '../Tooltip/Tooltip';
import { ProjectTitleProps } from './types';
import { TooltipAlignment, TooltipPlacement } from '../Tooltip/types';

const savedLabel = (
  config: ProjectTitleProps['config'],
  isLoading: boolean,
  isSaving: boolean,
  autoSaved: boolean,
  saved: boolean
): string => {
  if (isLoading) return '';
  if (isSaving) return 'Saving ...';
  if (!saved) return 'Save Project';
  if (!config.updatedAt) return '';
  return `${autoSaved ? 'Autos' : 'S'}aved ${timeAgo(config.updatedAt)}`;
};

/**
 * Display the project title and last save time. Can also be used to
 * edit the title and make a new save.
 */
const ProjectTitle: React.FC<ProjectTitleProps> = ({
  config,
  isLoading,
  dispatch,
}) => {
  const isLoggedIn = useUserStore(userStoreSelector.isLoggedIn);
  const [title, setTitle] = useState(config.title || '');
  const saving = useMenuStore(menuStoreSelector.saving);
  const autoSaved = useMenuStore(menuStoreSelector.autoSaved);
  const saved = useMenuStore(menuStoreSelector.saved);

  const [lastSave, setLastSave] = useState(
    savedLabel(config, isLoading, saving, autoSaved, saved)
  );
  const inputRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTitle(config.title || '');
    setLastSave(savedLabel(config, isLoading, saving, autoSaved, saved));
    const interval = setInterval(() => {
      setLastSave(savedLabel(config, isLoading, saving, autoSaved, saved));
    }, 1000 * 20); // 20 seconds

    return (): void => {
      clearInterval(interval);
    };
  }, [config, isLoading, saving, autoSaved, saved]);

  const renameTitle = useCallback(
    (newTitle: string) => {
      if (newTitle === title) return;

      setTitle(newTitle);
      dispatch(DESIGN_RENAME, { title: newTitle });
    },
    [dispatch, title]
  );

  const saveDesign = useCallback(() => {
    if (!isLoggedIn) return;
    dispatch(DESIGN_SAVE, { type: 'manual' });
  }, [isLoggedIn, dispatch]);

  const deselectObjects = useCallback(
    (): void => dispatch(DESELECT_OBJECTS),
    [dispatch]
  );

  const onPenClick = useCallback(() => {
    if (!inputRef?.current?.childNodes[0]) return;

    deselectObjects();
    const range = document.createRange();
    range.selectNodeContents(inputRef?.current?.childNodes[0]);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [deselectObjects]);

  return (
    <Wrapper data-testid="project-title">
      <IconEventWrapper theme={iconTheme}>
        <InnerWrapper>
          <Tooltip
            text="Edit project title"
            placement={TooltipPlacement.Bottom}
            align={TooltipAlignment.Start}
          >
            <UnstyledButton onClick={onPenClick}>
              <Icon name="edit" height="16px" theme={iconTheme} />
            </UnstyledButton>
          </Tooltip>
          <TextInput
            value={title}
            placeholder={'Project Title'}
            maxLength={MAX_DESIGN_NAME_LENGTH}
            onChanging={deselectObjects}
            onChanged={renameTitle}
            useInput={false}
            theme={inputTheme}
            inputRef={inputRef}
          />
          {isLoggedIn && (
            <Tooltip
              text="Save project"
              placement={TooltipPlacement.Bottom}
              align={TooltipAlignment.Start}
            >
              <UnstyledButton onClick={saveDesign}>
                <Label>{lastSave}</Label>
              </UnstyledButton>
            </Tooltip>
          )}
        </InnerWrapper>
      </IconEventWrapper>
    </Wrapper>
  );
};

ProjectTitle.defaultProps = {
  config: {
    title: 'New Project',
  },
};

export default ProjectTitle;
