import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { ColorSwatchWrapper } from '../ColorPicker/ColorSwatch/styles';
import { Wrapper as ColorPaletteWrapper } from '../ColorPaletteButton/styles';

const defaultColorSwatchSize = 20;
const colorSwatchSize = 25;

const getWidth = (count, gap, hovering) => {
  if (!hovering) {
    count = Math.min(5, count); // we are just showing 5 items at a maximum (collapsed state)
    return colorSwatchSize + count * (colorSwatchSize / 2);
  }
  return count
    ? count * colorSwatchSize + (count - 1) * gap + (16 + colorSwatchSize)
    : 0;
};

const getTranslation = (zIndex, gap, hovering) => {
  let translation;
  if (hovering) {
    //expanded
    translation = zIndex * gap - (16 + colorSwatchSize);
  } else {
    translation = -zIndex * (colorSwatchSize / 2);
    translation -= colorSwatchSize / 2;
  }
  return translation;
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  z-index: 0;
  height: ${colorSwatchSize}px;
  padding: 5px;
  width: 100%;
  ${ColorPaletteWrapper} {
    position: absolute;
    right: 5px;
    z-index: 1;
    transition: opacity 0.5s ease-in-out;
  }
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  overflow: scroll;
`;

export const ColorWrapper = styled.div`
  right: 0;
  cursor: pointer;
  transform: ${({ zIndex, choosingColor, gap }) =>
    `translateX(${getTranslation(zIndex, gap, choosingColor)}px)`};
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  z-index: ${({ zIndex }) => zIndex};
  width: ${colorSwatchSize}px;
  min-width: ${colorSwatchSize}px;

  ${Wrapper}:hover && {
    transform: ${({ zIndex, gap }) =>
      `translateX(${getTranslation(zIndex, gap, true)}px)`};
    &:nth-child(n + 6) {
      opacity: 1;
    }
    pointer-events: unset;
  }

  &:nth-child(n + 6) {
    opacity: ${({ choosingColor }) => (choosingColor ? 1 : 0)};
    pointer-events: ${({ choosingColor }) =>
      choosingColor ? 'unset' : 'none'};
  }

  ${ColorSwatchWrapper} {
    transform: scale(${1 + (1 - defaultColorSwatchSize / colorSwatchSize)});
    transform-origin: top left;
  }
`;

export const HoverBlock = styled.div`
  position: absolute;
  right: ${({ count, gap, hovering }) => getWidth(count, gap, hovering) + 8}px;
  width: calc(
    100% - ${({ count, gap, hovering }) => getWidth(count, gap, hovering) + 8}px
  );
  height: ${colorSwatchSize + 10}px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: ${themeStyle.fontFamily};
  font-size: ${themeStyle.fontSize14};
  font-weight: ${themeStyle.fontSemiBold};
  &::after {
    content: 'Project Colors';
    color: ${themeStyle.varInkMedium};
    opacity: ${({ hovering }) => (hovering ? 0 : 1)};
    transition: opacity
      ${({ hovering }) => (hovering ? '0s' : '0.5s ease-in-out')};
    transition-delay: ${({ hovering }) => (hovering ? 0 : '0.5s')};
    margin-right: 12px;
  }
`;
