import styled from 'styled-components';
import { PointerProps, SaturationWrapperProps } from './types';

export const SaturationWrapper = styled.div.attrs<SaturationWrapperProps>(
  ({ backgroundColor }) => ({
    style: {
      backgroundColor: `hsl(${backgroundColor}, 100%, 50%)`,
    },
  })
)<SaturationWrapperProps>`
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
  position: relative;
  touch-action: none;
  width: 100%;
`;

export const BlackGradient = styled.div`
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const WhiteGradient = styled.div`
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const Pointer = styled.div.attrs<PointerProps>(
  ({ colorPointer, left, bottom }) => ({
    style: {
      border: `2px solid ${colorPointer}`,
      left: `${left}%`,
      bottom: `${bottom}%`,
    },
  })
)<PointerProps>`
  background-color: transparent;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  height: 10px;
  position: absolute;
  transform: translate(-5px, 5px);
  width: 10px;
`;
