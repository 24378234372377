import styled from 'styled-components';
import { P4 } from '../utilities/Typography/styles';
import { SliderInputTheme } from './types';

const getForegroundSize = ({ theme }: { theme: SliderInputTheme }) =>
  `${theme.handleHeight + theme.handlePadding * 2}px`;

export const SliderBackground = styled.div<any>`
  position: relative;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.trackHeight}px;
  box-shadow: ${({ theme }) => theme.trackShadow};
  border-radius: ${({ theme }) => theme.trackHeight}px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background: ${({ theme, disabled }) =>
    disabled ? theme.trackBackgroundDisabledColor : theme.trackBackgroundColor};
  touch-action: none;
`;

export const SliderForeground = styled.div.attrs<any>(({ percentage }) => {
  return {
    style: {
      width: percentage,
    },
  };
})<any>`
  position: absolute;
  min-width: ${getForegroundSize};
  height: ${getForegroundSize};
  border-radius: ${getForegroundSize};

  padding: ${({ theme }) => theme.handlePadding}px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  background: ${({ theme, disabled }) =>
    disabled ? theme.trackDisabledColor : theme.trackColor};
  transition-property: background;
  transition-duration: 0.3s;
  touch-action: none;
  backface-visibility: hidden;
`;

export const Handle = styled.div<any>`
  height: ${({ theme }) => theme.handleHeight}px;
  width: ${({ theme }) => theme.handleHeight}px;
  border: ${({ theme }) => theme.handleBorder};

  box-sizing: border-box;
  border-radius: 50%;
  background: ${({ disabled, theme }) =>
    disabled ? theme.trackDisabledColor : theme.handleColor};
  transition-property: background;
  transition-duration: 0.3s;
  position: relative;
  touch-action: none;

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: transparent;
    box-sizing: border-box;
    border: 5px solid;
    border-color: ${({ theme }) => theme.handleHaloColor};
    opacity: ${({ sliding }) => (sliding ? 1 : 0)};
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition-property: opacity;
    transition-duration: 0.3s;
  }

  &:hover {
    background: ${({ disabled, theme }) =>
      disabled ? theme.trackDisabledColor : theme.handleActiveColor};
  }
`;

export const Label = styled(P4)<any>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.textDisabledColor : theme.textColor};

  margin-bottom: 2px;
  transition-property: color;
  transition-duration: 0.3s;
`;

export const Marks = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background: transparent;
`;

export const Mark = styled.div.attrs<any>(({ left }) => ({
  style: {
    left,
  },
}))<any>`
  height: 100%;
  background: ${({ theme }) => theme.markColor};
  width: 1.5px;
  border-radius: 0.75px;
  position: absolute;
`;

export const MainContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  &:focus {
    outline: none;
  }
`;
