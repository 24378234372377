import { useEffect } from 'react';
import hotkeys from 'hotkeys-js';

import {
  ACTIVE_MOVE_BY,
  ENABLE_DRAGGING,
  ENABLE_ZOOM_ON_WHEEL,
  REDO,
  UNDO,
  ZOOM_CENTER,
  ZOOM_STEP,
} from '../../global/events';
import { createHotkeyHandlerUtils } from '../../utils/mockup/createHotkeyHandlerUtils';
import fabric from '../../components/Artboard/fabric';
import { Dispatcher } from '../../types';

const handleZoomOut = (event: KeyboardEvent, dispatch: Dispatcher): void => {
  dispatch(ZOOM_STEP, -1);
  event.preventDefault();
};

const handleZoomIn = (event: KeyboardEvent, dispatch: Dispatcher): void => {
  dispatch(ZOOM_STEP, +1);
  event.preventDefault();
};

const handleZoomCenter = (event: KeyboardEvent, dispatch: Dispatcher): void => {
  dispatch(ZOOM_CENTER);
  event.preventDefault();
};

const toggleEnableDragging = (
  event: KeyboardEvent,
  dispatch: Dispatcher
): void => {
  if (event.type === 'keydown') {
    dispatch(ENABLE_DRAGGING, true);
  } else if (event.type === 'keyup') {
    dispatch(ENABLE_DRAGGING, false);
  }
};

const handleUndo = (event: KeyboardEvent, dispatch: Dispatcher): void => {
  dispatch(UNDO);
  event.preventDefault();
};

const handleRedo = (event: KeyboardEvent, dispatch: Dispatcher): void => {
  dispatch(REDO);
  event.preventDefault();
};

const toggleEnableZoomOnWheel = (dispatch: Dispatcher): void => {
  if (hotkeys.cmd || hotkeys.ctrl) {
    const keyPressed = hotkeys.isPressed('cmd') || hotkeys.isPressed('ctrl');
    dispatch(ENABLE_ZOOM_ON_WHEEL, keyPressed);
  }

  // Replicate zoom functionality with option and alt a-la Adobe XD
  if (hotkeys.option || hotkeys.alt) {
    const keyPressed = hotkeys.isPressed('option') || hotkeys.isPressed('alt');
    dispatch(ENABLE_ZOOM_ON_WHEEL, keyPressed);
  }
};

let enableMoveBy = true;
const handleMoveWithArrowKeys = (
  event: KeyboardEvent,
  dispatch: Dispatcher
): void => {
  const isKeyDown = event.type === 'keydown';
  const artboardTargeted = ['canvas-container'].some((c) =>
    (event.target as HTMLElement).classList.contains(c)
  );
  const bodyTargeted = (event.target as HTMLElement).tagName === 'BODY';

  // Handle arrow keys
  // XXX: it's not in the separate handler because of diagonals and shift support
  const left = hotkeys.isPressed('left');
  const right = hotkeys.isPressed('right');
  const up = hotkeys.isPressed('up');
  const down = hotkeys.isPressed('down');

  if (
    (left || right || up || down) &&
    (artboardTargeted || bodyTargeted) &&
    isKeyDown
  ) {
    let x = left ? -1 : right ? 1 : 0;
    let y = up ? -1 : down ? 1 : 0;
    if (hotkeys.shift) {
      x *= 10;
      y *= 10;
    }

    // throttled triggering of ACTIVE_MOVE_BY
    if (enableMoveBy) {
      dispatch(ACTIVE_MOVE_BY, { x, y });

      enableMoveBy = false;
      const MOVE_BY_THROTTLE = 17;
      setTimeout(() => (enableMoveBy = true), MOVE_BY_THROTTLE);
    }
    event.preventDefault();
  }
};

/**
 * This hook handles the binding/unbinding of hotkeys.
 */
export const useHotkeys = (
  canvas: fabric.Canvas | null,
  dispatch: Dispatcher
): void => {
  useEffect(() => {
    if (!canvas) return;

    const { bind, unbindAll } = createHotkeyHandlerUtils(canvas);

    bind('-,shift+-', (event) => {
      handleZoomOut(event, dispatch);
    });

    // `shift+=` is the plus key ¯\_(ツ)_/¯
    bind('shift+=,=', (event) => {
      handleZoomIn(event, dispatch);
    });

    bind('shift+0', (event) => {
      handleZoomCenter(event, dispatch);
    });

    bind(
      'space',
      (event) => {
        toggleEnableDragging(event, dispatch);
      },
      { keyup: true }
    );

    bind('ctrl+z,command+z', (event) => {
      handleUndo(event, dispatch);
    });

    bind('ctrl+y,command+y,ctrl+shift+z,command+shift+z', (event) => {
      handleRedo(event, dispatch);
    });

    bind(
      '*',
      (event) => {
        toggleEnableZoomOnWheel(dispatch);
        handleMoveWithArrowKeys(event, dispatch);
      },
      { keyup: true }
    );

    return (): void => {
      unbindAll();
    };
  }, [canvas, dispatch]);
};
