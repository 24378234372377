import fabric from '../../components/Artboard/fabric';
import {
  findDesignLayer,
  getRasterizedDesign,
} from '../../components/MockupBoard/utils';
import {
  MOCKUP_EXPORT_OPTIONS,
  MOCKUP_EXPORT_SIZE_EXPERT,
  MOCKUP_EXPORT_SIZE_PRO,
  MOCKUP_EXPORT_SIZE_FREE,
} from '../../global/constants';
import { downloadImage } from '../../helpers/download';
import useMockupStore from '../../stores/mockupStore/mockupStore';
import { matchObjectPosition } from '../editor/objects';
import { DownloadEvent } from './handleCommonEventBusEvents';
import { ObjectType, SubscriptionPlan } from '../../types';
import monitoring from '../../services/monitoring';
import { useToastStore } from '../../stores/toastStore';
import { toggleWarpControlPointsVisibility } from '../../components/Artboard/fabric/mockupTemplate/utils';
import { useUserStore } from '../../stores/userStore';
import userApi from '../../global/userApi';
import { createFileFromDataUrl, createFileFromBlob } from '../file';
import {
  MOCKUP_DOWNLOAD,
  MOCKUP_SAVE_TO_UPLOADS,
} from '../../global/analytics/events';
import analytics from '../../global/analytics';
import { uploadStore } from '../../stores/uploadStore';

interface ExportOptions {
  size: {
    width: number;
    height: number;
    unit: string;
  };
  format: string;
  dpi: number;
}

const downloadWithWarpedDesign = async (
  canvas: fabric.Canvas,
  options: ExportOptions,
  skipSaveFile = false
): Promise<Blob | string> => {
  toggleWarpControlPointsVisibility(canvas, false);
  const result = await downloadImage(canvas, options, skipSaveFile);
  toggleWarpControlPointsVisibility(canvas, true);
  return result?.blob ?? result?.dataUrl;
};

const downloadWithNonWarpedDesign = async (
  canvas: fabric.Canvas,
  designLayer: fabric.Image,
  activeDesignId: string,
  options: ExportOptions,
  skipSaveFile = false
): Promise<Blob | string> => {
  /**
   * Design layer has a fixed size. But it can be manipulated around the artboard
   * as the user pleases. When downloading, the image is scaled down / up and information is lost,
   * i.e looks very bad.
   *
   * Since we are free to rasterize at whatever resolution we want, we export the image
   * at the size it appears at in the artboard.
   */

  designLayer.set('locked', true);

  // Current image src
  const src = designLayer.getElement().src;

  // Size of design layer not accounting for zoom level
  let { tl, tr, br } = designLayer.calcACoords(true);
  const invRot = fabric.util.invertTransform(
    fabric.util.composeMatrix({ angle: designLayer.angle })
  );
  tl = fabric.util.transformPoint(tl, invRot);
  tr = fabric.util.transformPoint(tr, invRot);
  br = fabric.util.transformPoint(br, invRot);

  const width = tr.x - tl.x;
  const height = br.y - tr.y;

  // URL of correctly sized image
  const designLayerExportImgURL = await getRasterizedDesign(activeDesignId, {
    width,
    height,
  });

  // Store backup of design layer to use for positioning later on
  const refObj = { ...designLayer };

  return new Promise((resolve, reject) => {
    designLayer.setSrc(designLayerExportImgURL, async () => {
      // Match position to previous one
      matchObjectPosition(designLayer, refObj);

      try {
        const result = await downloadImage(canvas, options, skipSaveFile);

        // Restore old image source
        designLayer.setSrc(src, () => {
          matchObjectPosition(designLayer, refObj);
          designLayer.set('locked', false);
          canvas.requestRenderAll();
          resolve(result?.blob ?? result?.dataUrl);
        });
      } catch (error) {
        reject(error);
      }
    });
  });
};

const getExportOptions = (): ExportOptions => {
  const user = useUserStore.getState().user;
  const plan = user ? user.plan : undefined;

  return {
    ...MOCKUP_EXPORT_OPTIONS,
    size:
      plan === SubscriptionPlan.EXPERT
        ? MOCKUP_EXPORT_SIZE_EXPERT
        : plan === SubscriptionPlan.PRO
        ? MOCKUP_EXPORT_SIZE_PRO
        : MOCKUP_EXPORT_SIZE_FREE,
  };
};

export const handleDownload = async (
  canvas: fabric.Canvas,
  val: DownloadEvent
): Promise<void> => {
  useMockupStore.getState().setExporting(true);
  const exportOptions = getExportOptions();

  const user = useUserStore.getState().user;
  analytics.track(MOCKUP_DOWNLOAD, {
    designId: val.activeDesignId, // could be interesting for debugging
    plan: user ? user.plan ?? 'FREE' : 'FREE',
  });

  try {
    const designLayer = findDesignLayer(canvas);
    if (!designLayer) {
      throw new Error('design layer not found when downloading');
    }

    if (designLayer.type === ObjectType.WarpedImage) {
      await downloadWithWarpedDesign(canvas, exportOptions);
    } else {
      await downloadWithNonWarpedDesign(
        canvas,
        designLayer,
        val.activeDesignId,
        exportOptions
      );
    }
  } catch (error) {
    monitoring.captureException(error);
    useToastStore.getState().fire({
      label: 'There was an error while downloading your mockup',
      duration: 3000,
      error: true,
    });
  }

  useMockupStore.getState().setExporting(false);
};

export const handleSaveToUpload = async (
  canvas: fabric.Canvas,
  data: DownloadEvent
): Promise<void> => {
  useMockupStore.getState().setUploading(true);
  const exportOptions = getExportOptions();

  try {
    const designLayer = findDesignLayer(canvas);
    if (!designLayer) {
      throw new Error('design layer not found when save to Uploads');
    }

    const user = useUserStore.getState().user;
    analytics.track(MOCKUP_SAVE_TO_UPLOADS, {
      designId: data.activeDesignId, // could be interesting for debugging
      plan: user ? user.plan ?? 'FREE' : 'FREE',
    });

    let mockupData: Blob | string;
    if (designLayer.type === ObjectType.WarpedImage) {
      mockupData = await downloadWithWarpedDesign(canvas, exportOptions, true);
    } else {
      mockupData = await downloadWithNonWarpedDesign(
        canvas,
        designLayer,
        data.activeDesignId,
        exportOptions,
        true
      );
    }

    const fileName = 'mockup.png';
    let file: File;
    if (typeof mockupData === 'string') {
      file = createFileFromDataUrl(mockupData, fileName);
    } else {
      file = createFileFromBlob(mockupData, fileName);
    }

    const result = await userApi.uploadElement(file);

    if (result?.error) {
      uploadStore.getState().setError(result.error);
    } else if (!result?.objectName) {
      throw new Error('Uploading mockup returns no object name');
    } else {
      useToastStore.getState().fire({
        label: 'Image saved to Uploads',
        duration: 3000,
      });
    }
  } catch (error) {
    monitoring.captureException(error);
    useToastStore.getState().fire({
      label: 'There was an error while uploading the mockup',
      duration: 3000,
      error: true,
    });
  }

  useMockupStore.getState().setUploading(false);
};
