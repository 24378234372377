import { getPointDistanceToSegments } from '../../../utils/geometry/segment';
import { getBounds } from '../../../utils/editor/objects';

export default function (fabric) {
  fabric.Canvas.prototype._checkTarget = function (
    pointer,
    object,
    globalPointer
  ) {
    if (object.type === 'CustomTextbox' || object.type === 'artboard') {
      const { width, height, left, top } = getBounds(object);

      pointer = fabric.util.transformPoint(
        pointer,
        fabric.util.invertTransform(this.viewportTransform)
      );

      return (
        pointer.x >= left &&
        pointer.x <= left + width &&
        pointer.y >= top &&
        pointer.y <= top + height
      );
    }

    // When in text edit mode only return true for text, that's being edited
    if (this.activeTextEdit) return !!object.editTextBox;

    const { topline, bottomline, leftline, rightline } =
      object.getLineCoordinates();

    // Height and width based on line coordinates
    const objectHeight = Math.hypot(
      topline.o.x - bottomline.d.x,
      topline.o.y - bottomline.d.y
    );

    const objectWidth = Math.hypot(
      leftline.o.x - rightline.d.x,
      leftline.o.y - rightline.d.y
    );

    const distanceToLineCoords = getPointDistanceToSegments(globalPointer, [
      topline,
      bottomline,
      leftline,
      rightline,
    ]);

    const pointerIsCloseEnoughToObject =
      distanceToLineCoords < this.getTargetFindTolerance();

    // If object is too narrow to select robustly based on inclusion / transparency
    // Select based on distance to line coordinates
    if (objectHeight < 1 || objectWidth < 1) {
      // This means < 1px
      return pointerIsCloseEnoughToObject;
    }

    // ================================================== //
    // Original _checkTargetCode

    //Reason for the change:
    //fabric does a geometric pre-check before inspecting pixel data,
    //where the pointer is tested to be inside line coordinates.
    //The problem is that this makes it so that "targetFindTolerance" only
    //works within the bounds defined by said coordinates.
    //To fix it, we add a distance criteria as well, so if the pointer is close enough
    //pixel based selection is also carried out.

    if (
      object &&
      object.visible &&
      object.evented &&
      (pointerIsCloseEnoughToObject ||
        object.containsPoint(pointer) ||
        !!object._findTargetCorner(pointer)) // We modified this line
    ) {
      if (
        (this.perPixelTargetFind || object.perPixelTargetFind) &&
        !object.isEditing
      ) {
        const isTransparent = this.isTargetTransparent(
          object,
          globalPointer.x,
          globalPointer.y
        );
        if (!isTransparent) {
          return true;
        }
      } else {
        return true;
      }
    }

    // ================================================== //
  };

  /**
   * basically _searchPossibleTargets, but also returns all found targets
   */
  fabric.Canvas.prototype._searchAllPossibleTargets = function (
    objects,
    pointer,
    stopOnFirstResult
  ) {
    let target;
    const targets = [];
    let i = objects.length;

    // Iterate objects backwards so we go from top to bottom
    while (i--) {
      const objToCheck = objects[i];
      if (objToCheck.locked || objToCheck.lockedParent) continue;

      const pointerToUse = objToCheck.group
        ? this._normalizePointer(objToCheck.group, pointer)
        : pointer;

      if (this._checkTarget(pointerToUse, objToCheck, pointer)) {
        target = objects[i];
        targets.push(objects[i]);

        /**
         * Target will never be an activeSelection since we don't store
         * selection masks for them.
         * Traverse hierarchy until the outermost group and
         * store each entry in this.targets
         */
        while (target.group) {
          this.targets.push(target);
          target = target.group;
          targets.push(target.group);
        }

        if (stopOnFirstResult) {
          break;
        }
      }
    }

    return { target, targets };
  };

  fabric.Canvas.prototype._searchPossibleTargets = function (
    objects,
    pointer,
    skipGroup
  ) {
    const { target } = this._searchAllPossibleTargets(objects, pointer, true);
    /**
     * If target is the active object and skipGroup,
     * return outermost group or object in this.targets, i.e ignore activeGroup
     */
    return skipGroup && target === this._activeObject && this.targets.length
      ? this.targets[this.targets.length - 1]
      : target;
  };

  /*
        This is just a cut down version of the original findTarget. The only difference is we check for less
        cases (since in our case it's not necessary), and we offload skipGroup to _searchPossibleTargets.
    */
  fabric.Canvas.prototype.findTarget = function (e, skipGroup) {
    if (this.skipTargetFind) {
      return;
    }

    const targetPointer = this.getPointer(e, true);
    const pointer = this.getPointer(e, true);
    const activeObject = this._activeObject;
    const aObjects = this.getActiveObjects();
    const isTouch = fabric.util.isTouchEvent(e);
    const shouldLookForActive =
      (aObjects.length > 1 && !skipGroup) || aObjects.length === 1;

    this.targets = [];

    /* If we are targeting activeObject's controls, we return that */
    if (
      shouldLookForActive &&
      activeObject._findTargetCorner(targetPointer, isTouch)
    ) {
      return activeObject;
    }

    const target = this._searchPossibleTargets(
      this._objects,
      pointer,
      skipGroup
    );
    return target;
  };

  fabric.Canvas.prototype.isTargetTransparent = (function (
    isTargetTransparent
  ) {
    return function (target, x, y) {
      this.updateTrueCanvas();
      return isTargetTransparent.bind(this)(target, x, y);
    };
  })(fabric.Canvas.prototype.isTargetTransparent);
}
