import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 420px;
  box-sizing: border-box;
  margin: 0 auto;

  padding: 48px 20px;
  @media (min-width: 400px) {
    padding: 48px 40px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
