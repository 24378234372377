import themeStyle from '../../../global/themeStyle';

export const defaultTheme = {
  backgroundColor: themeStyle.darkerGrayBackground,
  borderColor: themeStyle.grayScrollbar,
};

export const selectTheme = {
  colorButton: themeStyle.darkGrayText,
  fontFamily: themeStyle.fontFamily,
  fontWeightButton: themeStyle.fontMedium,
  fontSizeButton: themeStyle.fontSizeLarge,
  lineHeightButton: '14px',
  buttonPadding: '6px 8px 5px',
  buttonWidth: '100px',
  buttonHeight: '30px',
  buttonBackground: themeStyle.white,
  buttonBorderRadius: '5px',
  textAlign: 'left',
};
