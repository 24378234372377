import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper,
  ButtonList,
  LeftContent,
  FloatingPanel,
  CenterAndRightContent,
  HelpAnchor,
} from './styles';
import { FlexRow } from '../utilities/styles';
import IconButton from '../Button/IconButton';
import Select from '../SelectNew/Select';
import Modal from '../Modal/Modal';
import ShortcutPanel from './ShortcutPanel/ShortcutPanel';
import Popover from '../Popover/Popover';
import { ZOOM, ZOOM_STEP, UNDO, REDO } from '../../global/events';
import { ZOOM_STEPS } from '../../global/constants';
import HelpPopover from './HelpPopover';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import ColorPalette from '../ColorPalette/ColorPalette';
import analytics from '../../global/analytics';
import { SHORTCUTS_MENU_OPEN } from '../../global/analytics/events';
import {
  onboardingStoreSelector,
  useOnboardingStore,
} from '../../stores/onboardingStore';
import { headerTheme } from '../OnboardingStep/theme';
import PanelHeader from '../utilities/PanelHeader/PanelHeader';
import { P2 } from '../utilities/Typography/styles';
import { themeStyle } from '../../services/theming';
import { popupTheme } from '../OnboardingManager/theme';

// create options list from config
const zoomOptions = ZOOM_STEPS.map((o) => {
  return { id: `${o.toFixed(2)}`, label: `${(o * 100).toFixed(0)}%` };
});

/**
 * Given zoom options may vary from the options provided in the config.
 * To not break and display correct values, a new option is added to the list
 * when it is not part of the defined options.
 * @param {string} zoom
 */
const getConfigWithCurrentZoom = (zoom) => {
  if (zoomOptions.some((option) => option.id === zoom)) {
    return zoomOptions;
  }
  const options = [
    ...zoomOptions,
    { id: zoom, label: `${(zoom * 100).toFixed(0)}%` },
  ];
  return options.sort((a, b) => a.id - b.id);
};

/**
 * The BottomMenu
 */
const BottomMenu = (props) => {
  const buttonTheme = {
    color: themeStyle.varInkMedium,
    activeColor: themeStyle.varInkMain,
    activeDefaultColor: themeStyle.varInkMain,
  };
  const floatingButtonTheme = {
    color: themeStyle.varInkMedium,
    activeColor: themeStyle.varInkMain,
    activeDefaultColor: themeStyle.varInkMain,
    padding: '8px 6px',
  };

  const zoom = useMenuStore(menuStoreSelector.zoom);
  const showLayers = useMenuStore(menuStoreSelector.layers);
  const setLayers = useMenuStore(menuStoreSelector.setLayers);
  const showRestart = useOnboardingStore(onboardingStoreSelector.showRestart);
  const setShowRestart = useOnboardingStore(
    onboardingStoreSelector.setShowRestart
  );
  const [shortcutOpen, setShortcutOpen] = useState(false);
  const [_zoomOptions, setZoomOptions] = useState(
    getConfigWithCurrentZoom(zoom)
  );

  const _zoom = useMemo(
    () => (typeof zoom === 'string' ? zoom : zoom.toFixed(2)),
    [zoom]
  );

  useEffect(() => {
    setZoomOptions(getConfigWithCurrentZoom(_zoom));
  }, [_zoom]);

  const dispatchEvent = (event, value) => {
    props.dispatch && props.dispatch(event, value);
  };

  const openShortcut = () => {
    analytics.track(SHORTCUTS_MENU_OPEN);
    setShortcutOpen(true);
  };

  const highlightRestartContent = (
    <>
      <PanelHeader
        label={'Onboarding'}
        onClose={() => setShowRestart(false)}
        theme={{
          ...headerTheme,
          fontWeight: themeStyle.fontBold,
          fontSize: themeStyle.fontSize16,
        }}
      />
      <FlexRow marginTop={'12px'}>
        <P2 color={themeStyle.inkBase}>
          Click here to restart the onboarding.
        </P2>
      </FlexRow>
    </>
  );

  const isDefaultWorkingMode =
    useMenuStore(menuStoreSelector.workingMode) === 'default';

  return (
    <Wrapper>
      <LeftContent>
        <ButtonList>
          <IconButton
            iconName={'keyboard'}
            label={'Shortcuts'}
            useActiveUnderline={false}
            theme={buttonTheme}
            isActive={shortcutOpen}
            onClick={() => openShortcut()}
          />
          <Modal
            width={'auto'}
            isOpen={shortcutOpen}
            onClose={() => setShortcutOpen(false)}
          >
            <ShortcutPanel />
          </Modal>
          <Popover
            showHeader={false}
            content={<HelpPopover />}
            onToggle={() => {
              setShowRestart(false);
            }}
            align="center"
            placement="top"
            label="Help"
            width="205px"
            theme={{
              padding: '12px',
            }}
          >
            <IconButton
              iconName={'help'}
              label={'Help'}
              useActiveUnderline={false}
              theme={buttonTheme}
            />
          </Popover>
          {showRestart && (
            <Popover
              open={true}
              showHeader={false}
              content={highlightRestartContent}
              align="center"
              placement="top"
              width="260px"
              theme={popupTheme}
            >
              <HelpAnchor />
            </Popover>
          )}
        </ButtonList>
      </LeftContent>
      <CenterAndRightContent>
        <FlexRow alignItems={'center'}>
          <FloatingPanel width={'60px'}>
            <IconButton
              iconName={'undo'}
              height={'10px'}
              theme={floatingButtonTheme}
              onClick={() => dispatchEvent(UNDO)}
              tooltipText="Undo"
            />
            <IconButton
              iconName={'redo'}
              height={'10px'}
              theme={floatingButtonTheme}
              onClick={() => dispatchEvent(REDO)}
              tooltipText="Redo"
            />
          </FloatingPanel>
          <FloatingPanel width={'110px'}>
            <IconButton
              dataTestId="zoom-out"
              iconName={'minus'}
              height={'10px'}
              theme={floatingButtonTheme}
              onClick={() => dispatchEvent(ZOOM_STEP, -1)}
              disabled={
                _zoomOptions.findIndex((option) => option.id === zoom) === 0
              }
              tooltipText="Zoom out"
            />
            <Select
              dataTestId="zoom-level-select"
              small
              compact
              noExpandIcon
              options={_zoomOptions}
              activeOption={_zoomOptions.find((option) => option.id === _zoom)}
              customStyles={{
                width: '60px',
                height: '24px',
                textAlign: 'center',
                noBorder: true,
                background: themeStyle.varInkSuperLight,
              }}
              onSelect={(option) => dispatchEvent(ZOOM, option.id)}
            />
            <IconButton
              dataTestId="zoom-in"
              iconName={'plus'}
              height={'10px'}
              theme={floatingButtonTheme}
              onClick={() => dispatchEvent(ZOOM_STEP, +1)}
              disabled={
                _zoomOptions.findIndex((option) => option.id === zoom) ===
                _zoomOptions.length - 1
              }
              tooltipText="Zoom in"
            />
          </FloatingPanel>
        </FlexRow>
        <FlexRow alignItems={'center'}>
          <FloatingPanel
            justifyContent="flex-end"
            transparent={true}
            disabled={!isDefaultWorkingMode}
          >
            <ColorPalette
              colorPalette={props.colorPalette}
              gap={5}
              dispatch={props.dispatch}
              isLoading={props.isLoading}
            />
          </FloatingPanel>
          <FloatingPanel width={'104px'} disabled={!isDefaultWorkingMode}>
            <IconButton
              dataTestId="layers-panel-toggler"
              iconName={'layer'}
              height={'16px'}
              label={'Layers'}
              useActiveUnderline={false}
              theme={{
                ...floatingButtonTheme,
                activeColor: themeStyle.primary,
              }}
              onClick={() => setLayers(!showLayers)}
            />
          </FloatingPanel>
        </FlexRow>
      </CenterAndRightContent>
    </Wrapper>
  );
};

BottomMenu.propTypes = {
  /**
   * Object containing information about the artboards color palette
   */
  colorPalette: PropTypes.object,
  /**
   * current zoom
   */
  zoom: PropTypes.string,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default React.memo(BottomMenu);
