import { themeStyle } from '../../services/theming';
import { SliderInputTheme } from './types';

export const defaultSliderTheme: SliderInputTheme = {
  trackColor: themeStyle.varBrandMedium,
  trackDisabledColor: themeStyle.varInkLight,
  trackBackgroundColor: themeStyle.varBrandSuperLight,
  trackBackgroundDisabledColor: themeStyle.varInkSuperLight,
  handleColor: themeStyle.inkMain,
  handleActiveColor: themeStyle.backgroundAlt,
  handleHaloColor: themeStyle.varBrandSuperLight,

  textColor: themeStyle.varInkMain,
  textDisabledColor: themeStyle.varInkLight,

  markColor: themeStyle.varInkLight,

  trackHeight: 8,
  handleHeight: 6,
  handlePadding: 1,
  handleBorder: 'unset',
  trackShadow: 'unset',
};

export const hueSliderTheme: Partial<SliderInputTheme> = {
  trackBackgroundColor:
    'linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);',
  trackHeight: 10,
  handleHeight: 8,
  handleBorder: `1.5px ${themeStyle.inkBase} solid`,
  // trackBorderColor: ${themeStyle.inkBase},
  handleColor: 'transparent',
  handleActiveColor: 'transparent',
  handleHaloColor: 'transparent',

  trackColor: 'transparent',
  trackDisabledColor: 'transparent',
};

export const opacitySliderTheme: Partial<SliderInputTheme> = {
  trackBackgroundColor: `linear-gradient(45deg, rgba(235, 235, 235) 0%, rgba(18, 19, 26) 100%), ${themeStyle.varInkLight}`,
  trackShadow: `inset 0 0 0 0.5px ${themeStyle.varInkLight}`, // we use a shadow, since border with opacity and gradient does not work properly
  handleColor: themeStyle.inkBase,
  handleActiveColor: themeStyle.inkBase,
  handleHaloColor: 'transparent',

  trackColor: 'transparent',
  trackDisabledColor: 'transparent',
};
