import { Mockup, MockupGroup } from '../types/Mockup';
import { tryFetchRetry } from '../utils/fetchRetry';
import { CMS_API_URL } from '../global/api';
import { getAccessToken } from '../global/userApi';
import { WarpGrid } from '../components/MockupBoard/types';

type GenericCmsResponse<T> =
  | ({ success: true; error: never } & T)
  | {
      success: false;
      error: string;
    };

type GetResponse = GenericCmsResponse<{ mockup: Mockup }>;
type OverviewResponse = GenericCmsResponse<{
  mockups: {
    [key: string]: MockupGroup &
      {
        mockups: Mockup[];
        total: number;
        skip: number;
        take: number;
      }[];
  };
}>;
type GroupResponse = GenericCmsResponse<{
  mockups: Mockup[];
  skip: number;
  take: number;
  sort: string | null;
}>;
type CreateResponse = GenericCmsResponse<{ mockup: { id: number } }>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createAPI = ({ url }: { url: string | undefined }) => {
  const get = async (id: number): Promise<GetResponse> => {
    return tryFetchRetry<GetResponse>(`${url}/${id}`);
  };

  const getOverview = async (): Promise<OverviewResponse> => {
    return tryFetchRetry<OverviewResponse>(`${url}/overview`);
  };

  const getGroup = async (
    groupId: string,
    take = 10,
    skip = 0,
    sort = 'name'
  ): Promise<GroupResponse> => {
    return await tryFetchRetry<GroupResponse>(
      `${url}/groups/${groupId}?take=${take}&skip=${skip}&sort=${sort}`
    );
  };

  const create = async ({
    preview,
    sceneLayer,
    darkBlendLayer,
    lightBlendLayer,
    warpGrid,
  }: {
    preview: string;
    sceneLayer: File;
    darkBlendLayer?: File | null;
    lightBlendLayer?: File | null;
    warpGrid?: WarpGrid | null;
  }): Promise<CreateResponse> => {
    const body = new FormData();
    body.append('preview', preview);
    body.append('sceneLayer', sceneLayer);

    darkBlendLayer && body.append('darkBlendLayer', darkBlendLayer);
    lightBlendLayer && body.append('lightBlendLayer', lightBlendLayer);
    warpGrid && body.append('warpGrid', JSON.stringify(warpGrid));

    return tryFetchRetry<CreateResponse>(`${url}/create`, {
      method: 'POST',
      body,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  };

  return {
    get,
    getOverview,
    getGroup,

    // mockup management
    create,
  };
};

const mockupApi = createAPI({ url: `${CMS_API_URL}/api/mockups` });
export default mockupApi;
