import { isOnDevEnvironment } from '../../../global/environment';

// This class is needed only so that we can call `initTrueRender(fabric, fabric.ClipPath)` on it.
// it should be used as a `clipPath` on objects with `trueRender = true`
export default function (fabric) {
  // allow re-initialize on dev environment to prevent issues with fast refresh
  if (fabric.ClipPath && !isOnDevEnvironment()) {
    fabric.warn('fabric.ClipPath is already defined');
    return;
  }

  fabric.ClipPath = fabric.util.createClass(fabric.Path, {
    type: 'clipPath',
  });
}
