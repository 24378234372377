import noop from 'lodash/noop';
import { createContext } from 'react';

export interface ContextProps {
  getRootContainer: () => HTMLElement | null;
  hideMobileBanner: () => void;
  showMobileBanner: boolean;
}

const Context = createContext<ContextProps>({
  getRootContainer: () => {
    return null;
  },

  hideMobileBanner: noop,
  showMobileBanner: false,
});

export const ContextProvider = Context.Provider;

export default Context;
