import { Wrapper } from './styles';
import { ElementProps } from './types';

const Element: React.FC<ElementProps> = (props) => {
  const Icon = props.icon;

  return (
    <Wrapper
      isActive={props.isActive}
      onClick={props.onClick}
      data-testid={props.id.toLowerCase()}
      iconMapping={props.iconMapping}
      size={props.size}
    >
      {Icon && <Icon />}
    </Wrapper>
  );
};

export default Element;
