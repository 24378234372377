import { themeStyle } from '../../services/theming';

export const defaultIconTheme = {
  color: themeStyle.varInkMedium,
  activeColor: themeStyle.varInkMain,
  activeDefaultColor: themeStyle.varInkMain,
};

export const secondaryIconTheme = {
  color: themeStyle.varInkMedium,
  activeColor: themeStyle.transparent,
  activeDefaultColor: themeStyle.varInkMain,
};
