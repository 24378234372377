import paper from '@kittl/paper';

import Bezier from '../../../../../../helpers/Bezier';

/**
 * Create a Paper.Segment object for points with absolute coordinates.
 * @param _point {{ x: number, y: number }}
 * @param _pointIn {{ x: number, y: number }}
 * @param _pointOut {{ x: number, y: number }}
 * @returns {paper.Segment}
 */
export const createSegment = (_point, _pointIn, _pointOut) => {
  const point = new paper.Point(_point);
  const pointIn = _pointIn ? new paper.Point(_pointIn) : null;
  const pointOut = _pointOut ? new paper.Point(_pointOut) : null;
  const pointInRelative = pointIn ? pointIn.subtract(point) : null;
  const pointOutRelative = pointOut ? pointOut.subtract(point) : null;

  return new paper.Segment(point, pointInRelative, pointOutRelative);
};

/**
 * Create a curve path consist of 3 segments, each defined by 3 points.
 * @param params {[{ x: number, y: number }, { x: number, y: number }, { x: number, y: number }][]}
 * @returns {paper.Path}
 */
export const createTriSegmentCurve = ([
  [left, leftIn, leftOut],
  [center, centerIn, centerOut],
  [right, rightIn, rightOut],
]) => {
  const leftSegment = createSegment(left, leftIn, leftOut);
  const centerSegment = createSegment(center, centerIn, centerOut);
  const rightSegment = createSegment(right, rightIn, rightOut);
  const curve = new paper.Path();
  curve.add(leftSegment);
  curve.add(centerSegment);
  curve.add(rightSegment);

  return curve;
};

/**
 * Create curves for free-form (distort) transformation, given 10 points.
 * @param points {{ x: number, y: number }[]}
 * @returns {{top: paper.Path, bottom: paper.Path}}
 */
export const createFreeFormCurves = (points) => {
  const topLeft = points[0];
  const topCenter = points[1];
  const topRight = points[2];
  const bottomRight = points[3];
  const bottomCenter = points[4];
  const bottomLeft = points[5];
  const topLeftHandle = points[6];
  const topRightHandle = points[7];
  const bottomLeftHandle = points[8];
  const bottomRightHandle = points[9];

  const top = createTriSegmentCurve([
    [topLeft, null, null],
    [topCenter, topLeftHandle, topRightHandle],
    [topRight, null, null],
  ]);

  const bottom = createTriSegmentCurve([
    [bottomLeft, null, null],
    [bottomCenter, bottomLeftHandle, bottomRightHandle],
    [bottomRight, null, null],
  ]);

  return {
    top,
    bottom,
  };
};

/**
 * Get the bbox of the curve, consist of 7 points when having flag, rise, arch, wave transformation.
 * @param points {{ x: number, y: number }[]}
 * @returns {{bottom: number, x: number, width: number, y: number, right: number, height: number}}
 */
export const getWarpCurveBbox = (points) => {
  const cruveLeft = new Bezier(points[0], points[1], points[2], points[3]);
  const curveLeftBbox = cruveLeft.bbox();
  const curveRight = new Bezier(points[3], points[4], points[5], points[6]);
  const curveRightBbox = curveRight.bbox();

  const left = Math.min(curveLeftBbox.x.min, curveRightBbox.x.min);
  const right = Math.max(curveLeftBbox.x.max, curveRightBbox.x.max);
  const top = Math.min(curveLeftBbox.y.min, curveRightBbox.y.min);
  const bottom = Math.max(curveLeftBbox.y.max, curveRightBbox.y.max);
  const width = right - left;
  const height = bottom - top;

  return {
    x: left,
    y: top,
    width,
    height,
    right,
    bottom,
  };
};
