import React, { useMemo } from 'react';
import Icon from '../../Icon/Icon';
import IconEventWrapper from '../../Icon/IconEventWrapper';
import MultiOption from '../../MultiOption/MultiOption';
import { options as defaultOptions } from './config';
import {
  OptionText,
  IconContainer,
  BottomList,
  BottomListElement,
  SubscriptionBadge,
} from './styles';
import analytics from '../../../global/analytics';
import { USER_NAVIGATION_CLICK } from '../../../global/analytics/events';
import { UserNavigationProps } from './types';
import { iconEventWrapperTheme, iconTheme } from './theme';
import {
  Theme,
  themeStoreSelector,
  themeStyle,
  useThemeStore,
} from '../../../services/theming';
import { H5, P3 } from '../../utilities/Typography/styles';
import Switch from '../../Switch/Switch';
import { userStoreSelector, useUserStore } from '../../../stores/userStore';
import Tag from '../../Tag/Tag';
import { specialTheme } from '../../Tag/theme';

/**
 * Menu in which the user can navigate different profile pages.
 */
const UserNavigation: React.FC<UserNavigationProps> = (props) => {
  const subscriptionLabel = useUserStore(userStoreSelector.plan) ?? 'FREE';

  // This memo function filters Subscription and Referral options from menu on smaller screens until they're mobile optimized
  const options = useMemo(() => {
    let _options = props.options;

    const isMobile = window?.matchMedia(
      'only screen and (max-width: 760px)'
    ).matches;

    if (isMobile) {
      const newOptions = Object.entries(_options).filter(
        ([key, _]) => key !== 'Subscription' && key !== 'Referral'
      );
      _options = Object.fromEntries(newOptions);
    }

    return _options;
  }, [props.options]);

  const onSelectOption = (optionKey: string): void => {
    analytics.track(USER_NAVIGATION_CLICK, {
      label: options[optionKey].url,
    });
    props.onClickItem(options[optionKey]);
  };

  const handleHelp = (): void => {
    analytics.track(USER_NAVIGATION_CLICK, {
      label: 'help',
    });
    props.onHelp();
  };

  const renderOption = (optionKey: string): React.ReactNode => {
    const option = options[optionKey];
    return (
      <IconEventWrapper
        fitParent
        activeOnHover={true}
        theme={iconEventWrapperTheme}
      >
        <IconContainer>
          <Icon
            height="auto"
            name={option.icon}
            isActive={props.selectedOption === optionKey}
            theme={iconTheme}
          />
        </IconContainer>
        <OptionText>
          <P3 color="unset">{option.label}</P3>
        </OptionText>

        {optionKey === 'Subscription' && (
          <SubscriptionBadge>
            <Tag theme={specialTheme}>{subscriptionLabel}</Tag>
          </SubscriptionBadge>
        )}
      </IconEventWrapper>
    );
  };

  const theme = useThemeStore(themeStoreSelector.theme);
  const setTheme = useThemeStore(themeStoreSelector.setTheme);

  const toggleTheme = (): void => {
    setTheme(theme === Theme.DARK ? Theme.LIGHT : Theme.DARK);
  };

  return (
    <>
      <MultiOption
        options={options}
        renderOption={renderOption}
        select={onSelectOption}
        selected={props.selectedOption}
      />
      <BottomList>
        <BottomListElement data-testid="dark-mode-toggle">
          <H5 onClick={toggleTheme} color={themeStyle.varInkMain}>
            Dark Mode
          </H5>
          <Switch onChange={toggleTheme} checked={theme === Theme.DARK} />
        </BottomListElement>
        <BottomListElement data-testid="help">
          <H5 onClick={(): void => handleHelp()} color={themeStyle.varInkMain}>
            Help
          </H5>
        </BottomListElement>
        <BottomListElement>
          <H5
            onClick={(): void => props.onLogOut()}
            color={themeStyle.varInkMain}
            data-testid="log-out"
          >
            Log Out
          </H5>
        </BottomListElement>
      </BottomList>
    </>
  );
};

UserNavigation.defaultProps = {
  options: defaultOptions,
};

export default UserNavigation;
