const freePalettes = {
  'Red Autumn': {
    type: 'free',
    colors: ['#FFF6F6', '#F1C788', '#F0E4BB', '#DE9999', '#A43939', '#3D0303'],
  },
  'Ocean Blue': {
    type: 'free',
    colors: ['#FCEEE1', '#CEF9FF', '#67ADC3', '#2D79A4', '#09266F', '#040720'],
  },
  'Candy Shop': {
    type: 'free',
    colors: ['#FFF9F4', '#FFE3DA', '#FFB9C1', '#9CDDD5', '#FF7C52', '#5F0717'],
  },
  'Deep Wood': {
    type: 'free',
    colors: ['#FFF7EF', '#EDCEAA', '#8B9D75', '#5D7748', '#134B19', '#0A2402'],
  },
  'Lilac Wine': {
    type: 'free',
    colors: ['#FFF9F4', '#FFCFE6', '#FF96BC', '#BA518B', '#6D093E', '#09052B'],
  },
  'First Love': {
    type: 'free',
    colors: ['#FFDFE1', '#FF727A', '#DD2835', '#B00D1E', '#7B0D0D', '#470A11'],
  },
  'Earth Brown': {
    type: 'free',
    colors: ['#FFF3EC', '#FFDC98', '#C59653', '#754C1D', '#40200A', '#130800'],
  },
  Grayscale: {
    type: 'free',
    colors: ['#FCFCFC', '#CACACA', '#979797', '#656565', '#323232', '#000000'],
  },
  'Retro Toy': {
    type: 'free',
    colors: ['#FFEDDF', '#FECBA0', '#E4C477', '#4E9B8F', '#2E4552', '#16181E'],
  },
  'Red Stone': {
    type: 'free',
    colors: ['#FAFAFA', '#AFA7A6', '#818181', '#B71109', '#6B0600', '#0B090A'],
  },
  'Cold Forest': {
    type: 'free',
    colors: ['#FCFFF5', '#93EDDD', '#44CDAC', '#198C87', '#0F4D57', '#082226'],
  },
  'Purple Clouds': {
    type: 'free',
    colors: ['#F5FFFF', '#C9EEF7', '#B5E2D7', '#77B2B7', '#635284', '#26203C'],
  },
  'Mango Dreams': {
    type: 'free',
    colors: ['#FFFAD3', '#FCE94E', '#FFC700', '#FF960A', '#E56000', '#551400'],
  },
  'Retro Summer': {
    type: 'free',
    colors: ['#FFF6DF', '#FFDE8A', '#FF8569', '#CF2C46', '#770B2B', '#430928'],
  },
  'Purple Mint': {
    type: 'free',
    colors: ['#EFFFFB', '#98FFE0', '#68E2E5', '#4F7DE9', '#5612BF', '#15064B'],
  },
  'Purple Sand': {
    type: 'free',
    colors: ['#FFFDFA', '#FFF0CA', '#D9B073', '#BA52A9', '#8E009A', '#570049'],
  },
  'Gold Rush': {
    type: 'free',
    colors: ['#FFF9F4', '#FAD36F', '#D3A052', '#785947', '#4B4B4B', '#1D1D1D'],
  },
  'Sea Gold': {
    type: 'free',
    colors: ['#FDFDFD', '#E4E4E4', '#D0AD68', '#5E5E67', '#172363', '#000534'],
  },
  'Natural Organic': {
    type: 'free',
    colors: ['#FFFAF5', '#FBE9D8', '#D8BFAC', '#B7B7A6', '#6C705E', '#353924'],
  },
  'Ocean Sun': {
    type: 'free',
    colors: ['#FFFAF2', '#FFDD88', '#FFAE4F', '#246DDB', '#0A2CA5', '#020B24'],
  },
};

const premiumPalettes = {
  'Lovely Lilac': {
    type: 'premium',
    colors: ['#FFF6F6', '#F8E3DC', '#F096B1', '#A9759F', '#744B91', '#3E1958'],
  },
  'Olive Tree': {
    type: 'premium',
    colors: ['#FEFEF8', '#F8F1D8', '#F5E4A7', '#A2A45F', '#1D4142', '#111010'],
  },
  'Coral Reef': {
    type: 'premium',
    colors: ['#FFF4F4', '#FFDADA', '#FFA6B0', '#4D2BF5', '#429DFF', '#160084'],
  },
  'Forest Ranger': {
    type: 'premium',
    colors: ['#FFF1E0', '#EDD79D', '#D4B854', '#72AF3B', '#3B751A', '#112E00'],
  },
  'Retro Purple': {
    type: 'premium',
    colors: ['#FFF8F5', '#FFDAEC', '#A1CAE1', '#906A97', '#3A5F74', '#302E3D'],
  },
  'Retro Sunset': {
    type: 'premium',
    colors: ['#FFF9F4', '#FFE767', '#FAB553', '#C53552', '#374474', '#141D3F'],
  },
  'Soft Mint': {
    type: 'premium',
    colors: ['#FEFCF2', '#E1F2E1', '#ABC7B1', '#829E89', '#53755B', '#244239'],
  },
  'Red Plastic': {
    type: 'premium',
    colors: ['#F4EDDE', '#E55056', '#D0D2CF', '#B53136', '#98AAB1', '#68091A'],
  },
  'French Sweets': {
    type: 'premium',
    colors: ['#FFF9F5', '#FFE8C5', '#F2D1EC', '#D4F2EE', '#6A9E8E', '#2C5145'],
  },
  '80s Retro Vibes': {
    type: 'premium',
    colors: ['#FFF1E8', '#F8CD59', '#EE9C59', '#EC5952', '#4D238A', '#310B68'],
  },
  'Magic Carpet': {
    type: 'premium',
    colors: ['#FFFEED', '#FFF8A9', '#ED7BB3', '#9F53CB', '#74E3CC', '#720BAE'],
  },
  'Day and Night': {
    type: 'premium',
    colors: ['#FFC480', '#FF8E41', '#FDF3E9', '#D05826', '#343066', '#201A37'],
  },
  'Jamaica Love': {
    type: 'premium',
    colors: ['#FFEF9B', '#F9DC40', '#DD342B', '#C0785F', '#378D34', '#31221A'],
  },
  'Late Summer': {
    type: 'premium',
    colors: ['#FFF9EB', '#F1B44D', '#E8683B', '#E3D5BB', '#BC7600', '#1C1B18'],
  },
  'Strawberry Mint': {
    type: 'premium',
    colors: ['#F9F9E6', '#E34961', '#E2E2C1', '#C8203A', '#8ACD9A', '#270909'],
  },
  'Stone Harbor': {
    type: 'premium',
    colors: ['#F6F5F2', '#E3DBC7', '#CCA872', '#8F8E80', '#253339', '#0F161E'],
  },
  'Pink Lemonade': {
    type: 'premium',
    colors: ['#FFF6F4', '#FFE5DD', '#FFBDBD', '#FF87AC', '#F05685', '#A90C3C'],
  },
  Television: {
    type: 'premium',
    colors: ['#FFFCFA', '#FFE296', '#FFCB42', '#D63EF6', '#3A1CCF', '#16161A'],
  },
  'Cold Coast': {
    type: 'premium',
    colors: ['#F6FFFF', '#E2FEFF', '#BFFCFE', '#6EBBB9', '#4B838A', '#0C2026'],
  },
  'Beach Bar': {
    type: 'premium',
    colors: ['#FFFCFA', '#FFFCAD', '#FFBD8D', '#EE6E69', '#C9FCBB', '#B74541'],
  },
};

export default { ...freePalettes, ...premiumPalettes };
