import React from 'react';
import Icon from '../Icon/Icon';
import IconEventWrapper from '../Icon/IconEventWrapper';
import {
  CellWrapper,
  RowWrapper,
  Label,
  ContentWrapper,
  IconWrapper,
} from './styles';
import { iconTheme, themeIconRow } from './theme';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip/Tooltip';

const IconRow = ({ config, onClick, iconHeight, noLabels, theme }) => {
  const _theme = { ...themeIconRow, ...theme };

  return (
    <RowWrapper theme={_theme} count={config.length}>
      {config.map((cell) => (
        <CellWrapper
          data-testid={cell.id}
          key={cell.id}
          onClick={() => onClick && onClick(cell)}
          theme={_theme}
        >
          <IconEventWrapper fitParent={true} theme={iconTheme}>
            <Tooltip
              text={cell.tooltip || cell.label}
              align={cell.tooltipPlacement}
              placement={cell.tooltipPlacement}
            >
              <ContentWrapper theme={_theme}>
                <IconWrapper theme={_theme} isActive={cell.isActive}>
                  <Icon
                    name={cell.icon}
                    isActive={cell.isActive}
                    height={iconHeight}
                    theme={{
                      ...iconTheme,
                      color: cell.isActive
                        ? iconTheme.activeColor
                        : iconTheme.color,
                    }}
                  />
                </IconWrapper>
                {!noLabels && (
                  <Label isActive={cell.isActive} {..._theme}>
                    {cell.label}
                  </Label>
                )}
              </ContentWrapper>
            </Tooltip>
          </IconEventWrapper>
        </CellWrapper>
      ))}
    </RowWrapper>
  );
};

IconRow.propTypes = {
  /**
   * Config containing icons
   */
  config: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      icon: PropTypes.string,
      isActive: PropTypes.bool,
    })
  ),
  /**
   * OnClick Action
   */
  onClick: PropTypes.func,
  /**
   * The height that is used for icons in the row
   */
  iconHeight: PropTypes.string,
  /**
   * If true, no labels are shown for icon cells
   */
  noLabels: PropTypes.bool,
  /**
   * Theme object
   */
  theme: PropTypes.shape({
    cellGap: PropTypes.number,
    rowHeight: PropTypes.number,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    iconCellLabelGap: PropTypes.number,
    noBackgroundColor: PropTypes.bool,
    heightToContent: PropTypes.bool,
    width: PropTypes.string,
  }),
};

export default IconRow;
