import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import BasePanel from '../BasePanel/BasePanel';
import Button from '../../Button/Button/Button';
import Masonry from '../../Masonry/Masonry';
import { Background, ButtonsWrapper, Label } from './styles';
import DecisionModal from '../../DecisionModal/DecisionModal';
import IconButton from '../../Button/IconButton';
import { circledIconTheme } from '../../Icon/theme';
import { secondaryTheme } from '../../Button/Button/theme';
import {
  ADD_ILLUSTRATION,
  DELETE_UPLOAD,
  DRAGGING_ELEMENT,
  START_DRAGGING_ELEMENT,
  STOP_DRAGGING_ELEMENT,
} from '../../../global/events';
import { uploadFile } from '../../../global/uploads';
import {
  uploadStoreSelector,
  useUploadStore,
} from '../../../stores/uploadStore';
import { useUserStore, userStoreSelector } from '../../../stores/userStore';
import analytics from '../../../global/analytics';
import { UPLOAD_MEDIA_CLICK } from '../../../global/analytics/events';
import Element from '../../ElementPanels/Element/Element';
import VerticallyScrollable from '../../VerticallyScrollable/VerticallyScrollable';
import { themeStyle } from '../../../services/theming';

/**
 * The head of the UploadsMenu is used to trigger an upload via a button
 */
const UploadsMenuHeader = (props) => {
  const fileInputRef = useRef();
  const onSelectFile = (event) => {
    const file = event.target.files[0];

    uploadFile(file);
    fileInputRef.current.value = null;
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        hidden
      />
      <Button
        theme={{
          ...secondaryTheme,
          iconMinWidth: '21px',
        }}
        width="100%"
        isCentered={false}
        disabled={!props.authenticated}
        hasCaret={true}
        label={'Upload Media (PNG, JPG, SVG)'}
        icon={{ name: 'upload', height: '15px' }}
        onClick={() => {
          analytics.track(UPLOAD_MEDIA_CLICK);
          fileInputRef.current.click();
        }}
      />
    </>
  );
};

/**
 * The body of the UploadsMenu is used to display previous uploads of a user
 * From here, elements can be dragged onto the artboard
 */
const UploadElementButtons = (props) => {
  const [isConfirmPanelOpen, setIsConfirmPanelOpen] = useState(false);

  const onConfirmDelete = () => {
    props.dispatch && props.dispatch(DELETE_UPLOAD, props);
    setIsConfirmPanelOpen(false);
  };

  return (
    <>
      <DecisionModal
        isOpen={isConfirmPanelOpen}
        onClose={() => setIsConfirmPanelOpen(false)}
        icon="delete"
        message={`Are you sure you want to delete this upload?\nThis file will be deleted from all your projects and cannot be restored.`}
        firstButton={{
          label: 'Cancel',
          onClick: () => setIsConfirmPanelOpen(false),
        }}
        secondButton={{
          label: 'Delete',
          onClick: onConfirmDelete,
        }}
      />
      <ButtonsWrapper>
        <IconButton
          onClick={() => setIsConfirmPanelOpen(true)}
          iconName="elementDelete"
          height="24px"
          theme={circledIconTheme}
        />
      </ButtonsWrapper>
    </>
  );
};

const UploadsMenuBody = ({ dispatch, ...props }) => {
  const uploads = useUploadStore(uploadStoreSelector.uploads);
  const localUploads = useUploadStore(uploadStoreSelector.localUploads);
  const fetch = useUploadStore(uploadStoreSelector.fetchAndHandleLocal);

  const addUpload = useCallback(
    (val) => dispatch && dispatch(ADD_ILLUSTRATION, val),
    [dispatch]
  );

  const onDragging = useCallback(
    (value) => {
      dispatch(DRAGGING_ELEMENT, value);
    },
    [dispatch]
  );

  const onStopDragging = useCallback(() => {
    dispatch(STOP_DRAGGING_ELEMENT);
  }, [dispatch]);

  const renderElement = useCallback(
    (props) => {
      const elementProps = {
        id: props.id,
        objectName: props.objectName,
        preview: props.src,
        skipPopularityTracking: props.skipPopularityTracking,
      };

      const onStartDragging = () => {
        dispatch(START_DRAGGING_ELEMENT, {
          objectName: elementProps.objectName,
          type: 'illustration',
        });
      };

      return (
        <Element
          element={elementProps}
          buttonsComponent={
            <UploadElementButtons {...props} dispatch={dispatch} />
          }
          onDrop={addUpload}
          onStartDragging={onStartDragging}
          onDragging={onDragging}
          onStopDragging={onStopDragging}
          theme={{
            imagePadding: '15px',
            borderColor: themeStyle.varInkLight,
          }}
        />
      );
    },
    [addUpload, onDragging, onStopDragging, dispatch]
  );

  if (!props.authenticated) {
    return <p>To upload custom illustrations, you need to be logged in.</p>;
  }

  return (
    <Background>
      <Label>UPLOADS</Label>
      <VerticallyScrollable>
        <Masonry
          minColumnWidth={128}
          gap={10}
          items={[...localUploads, ...uploads]}
          fetch={fetch}
          render={renderElement}
          transitioning={props.transitioning}
        />
      </VerticallyScrollable>
    </Background>
  );
};

const UploadsMenu = (props) => {
  const authenticated = useUserStore(userStoreSelector.isLoggedIn);
  const [noOverflow, setNoOverflow] = useState(false);

  const onSwitchDimension = useCallback(
    (expanding) => setNoOverflow(expanding),
    []
  );

  const bodyElement = (
    <UploadsMenuBody
      {...props}
      authenticated={authenticated}
      onSwitchDimension={onSwitchDimension}
    />
  );

  return (
    <BasePanel
      label="Uploads"
      onClose={props.onClose}
      bodyElement={bodyElement}
      headElement={<UploadsMenuHeader authenticated={authenticated} />}
      noOverflow={noOverflow}
    />
  );
};

UploadsMenuHeader.propTypes = {
  authenticated: PropTypes.bool,
};

UploadElementButtons.propTypes = {
  dispatch: PropTypes.func,
};

UploadsMenuBody.propTypes = {
  dispatch: PropTypes.func,
  authenticated: PropTypes.bool,
  id: PropTypes.string,
  objectName: PropTypes.string,
  src: PropTypes.string,
  skipPopularityTracking: PropTypes.bool,
  transitioning: PropTypes.bool,
};

UploadsMenu.propTypes = {
  /**
   * event dispatcher
   */
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
};

export default UploadsMenu;
