import round from 'lodash/round';
import { isOnDevEnvironment } from '../../global/environment';

/**
 * In tests that we need to take screenshots, it's often hard to tell when the canvas is ready after some operation.
 * This function helps mount an element with a given testid, which can be used as an indicator of the completion of some long running (drawing) job.
 * The element will be destroyed automatically after some short amount of time.
 * @param testId
 */
export const showIndicator = (testId: string): void => {
  if (!isOnDevEnvironment()) return;

  const indicator = document.createElement('div');
  indicator.style.position = 'fixed';
  indicator.style.zIndex = '-999';
  indicator.style.top = '-999px';
  indicator.style.left = '-999px';
  indicator.setAttribute('data-testid', testId);

  document.body.appendChild(indicator);

  setTimeout(() => {
    document.body.removeChild(indicator);
  }, 1000);
};

interface TextureOptions {
  texture: string;
  globalCompositeOperation:
    | 'normal'
    | 'color-burn'
    | 'multiply'
    | 'overlay'
    | 'soft-light'
    | 'destination-out';
  opacity: number;
}

export const getTextureIndicatorId = ({
  texture,
  globalCompositeOperation,
  opacity,
}: TextureOptions): string =>
  `texture-applied--${texture}--${globalCompositeOperation}--${round(
    opacity,
    2
  )}`;
