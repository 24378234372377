import { css, FlattenSimpleInterpolation } from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { WrapperProps } from './styles';
import { IconMapping, IconMappingRule } from './types';

export const getDark = (rule: IconMappingRule): string => rule.dark[0];
export const getActiveDark = (rule: IconMappingRule): string => rule.dark[1];
export const getLight = (rule: IconMappingRule): string => rule.light[0];
export const getActiveLight = (rule: IconMappingRule): string => rule.light[1];

export const getCSSIconStyle =
  (selector: (rule: IconMappingRule) => string) =>
  ({
    iconMapping: mapping,
  }: {
    iconMapping?: IconMapping;
  }): FlattenSimpleInterpolation | '' => {
    if (!mapping) {
      return '';
    }

    return css`
      ${Object.entries(mapping)
        .map(
          ([className, rule]) => `
            .${className} {
                fill: ${selector(rule)};
            }

            .${className}_stroke {
                stroke: ${selector(rule)};
            }
        `
        )
        .join('\n')}
    `;
  };

export const getBackgroundColor =
  (active: boolean, dark: boolean) =>
  (props: WrapperProps): string => {
    active = Boolean(active || props.isActive);

    if (active && dark) {
      return themeStyle.darkBackgroundAlt;
    } else if (active && !dark) {
      return themeStyle.backgroundAlt;
    } else if (!active && dark) {
      return themeStyle.darkInkSuperLight;
    } else {
      return themeStyle.varInkSuperLight;
    }
  };

export const getBorder =
  (active: boolean, dark: boolean) =>
  (props: WrapperProps): string => {
    active = Boolean(active || props.isActive);

    if (active) {
      return `2px solid ${themeStyle.varBrandMedium}`;
    } else if (dark) {
      return `1px solid ${themeStyle.darkInkLight}`;
    } else {
      return `1px solid ${themeStyle.inkLight}`;
    }
  };
