import { useEffect } from 'react';

import fabric from '../../Artboard/fabric';
import { handleWheelEvent } from '../../../utils/editor/eventHandlers';
import { Dispatcher } from '../../../types';
import { updateArtboardPosition } from '../../../stores/onboardingStore';
import { HistoryUtil } from '../../../services/history';
import { createCanvasEventHandlerUtils } from '../../../utils/mockup/createCanvasEventHandlerUtils';
import { warpControlRootPointObjectType } from '../../Artboard/fabric/mockupTemplate/utils';

const handleMouseDown = (canvas: fabric.Canvas, event: MouseEvent): void => {
  if (canvas.draggingEnabled) {
    // drag canvas
    canvas.setCursor('grabbing');
    canvas.isDragging = true;
    canvas.lastPosX = event.clientX;
    canvas.lastPosY = event.clientY;
  }
};

const handleMouseMove = (canvas: fabric.Canvas, event: MouseEvent): void => {
  if (canvas.draggingEnabled) {
    canvas.setCursor(canvas.isDragging ? 'grabbing' : 'grab');
  }

  if (canvas.isDragging) {
    const vpt = canvas.viewportTransform;
    // calculate the new viewport position after dragging
    const newPosX = vpt[4] + event.clientX - canvas.lastPosX;
    const newPosY = vpt[5] + event.clientY - canvas.lastPosY;
    // set the new viewport pos
    canvas.setViewportPos(newPosX, newPosY);
    canvas.requestRenderAll();
    updateArtboardPosition(
      canvas.artboard,
      canvas.getZoom(),
      canvas.viewportTransform
    );
  }
  canvas.lastPosX = event.clientX;
  canvas.lastPosY = event.clientY;
};

const handleMouseUp = (canvas: fabric.Canvas): void => {
  // on mouse up we want to recalculate new interaction
  // for all objects, so we call setViewportTransform
  canvas.setViewportTransform(canvas.viewportTransform);
  canvas.isDragging = false;
};

const handleObjectModified = (
  canvas: fabric.Canvas,
  history: HistoryUtil | null,
  event: fabric.Event
): void => {
  if (event.action === 'drag') {
    canvas.hasMovingObject = false;
  }

  if (canvas.hasMovingObject) return;

  // don't push empty state to history when intialzing
  if (history && canvas._objects.length > 1) {
    const artboardState = canvas.toJSON();
    history.update(artboardState);
  }
};

/**
 * Check if the current selection is a group of warp control root points.
 * Technically on mockup template board, if the selection is a group,
 * then its children should all be warp control root points.
 * This function is added just to be on the safe side.
 */
const selectedGroupOfWarpControlRootPoints = (
  canvas: fabric.Canvas
): boolean => {
  const activeObjects = canvas.getActiveObjects();
  const activeObject = canvas.getActiveObject();
  return (
    activeObject?.type === 'activeSelection' &&
    activeObjects.every(
      (object: fabric.Object) => object.type === warpControlRootPointObjectType
    )
  );
};

/**
 * Rotation is not supported for a group of warp control points (for now).
 */
const hideRotateControl = (canvas: fabric.Canvas): void => {
  const activeObject = canvas.getActiveObject();
  activeObject.setControlsVisibility({
    rotate: false,
  });
};

/**
 * When having a group of warp control points selected, padding is added for better usability.
 */
const addPaddingToSelection = (canvas: fabric.Canvas): void => {
  const activeObject = canvas.getActiveObject();
  activeObject.set({
    padding: 8,
  });
};

/**
 * Handle move for a warp control root point, if it's moving in an active selection.
 */
const handleWarpControlRootPointMoving = (canvas: fabric.Canvas): void => {
  const activeObjects = canvas.getActiveObjects();
  activeObjects.forEach((object: fabric.Object): void => {
    if (object.type === warpControlRootPointObjectType) {
      object.handleMove();
    }
  });
};

/**
 * This hook handles the binding/unbinding of event handlers on canvas.
 */
export const useCanvasEventHandlers = (
  canvas: fabric.Canvas | null,
  history: HistoryUtil | null,
  dispatch: Dispatcher
): void => {
  useEffect(() => {
    if (!canvas) return;

    const { add, removeAll } = createCanvasEventHandlerUtils();

    add(canvas, 'mouse:wheel', (options: { e: WheelEvent }) => {
      handleWheelEvent(canvas, options.e, dispatch);
    });

    add(canvas, 'mouse:down', ({ e }: { e: MouseEvent }) => {
      handleMouseDown(canvas, e);
    });

    add(canvas, 'mouse:move', ({ e }: { e: MouseEvent }) => {
      handleMouseMove(canvas, e);
    });

    add(canvas, 'mouse:up', () => {
      handleMouseUp(canvas);
    });

    add(canvas, 'selection:created', () => {
      if (selectedGroupOfWarpControlRootPoints(canvas)) {
        hideRotateControl(canvas);
        addPaddingToSelection(canvas);
      }
    });

    add(canvas, 'selection:updated', () => {
      if (selectedGroupOfWarpControlRootPoints(canvas)) {
        hideRotateControl(canvas);
        addPaddingToSelection(canvas);
      }
    });

    add(canvas, 'object:moving', () => {
      if (selectedGroupOfWarpControlRootPoints(canvas)) {
        handleWarpControlRootPointMoving(canvas);
      }
    });

    add(canvas, 'object:modified', (event: fabric.event) => {
      handleObjectModified(canvas, history, event);
    });

    return (): void => {
      removeAll(canvas);
    };
  }, [canvas, history, dispatch]);
};
