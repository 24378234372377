import styled, { keyframes } from 'styled-components';

import { OuterWrapper as MasonryOuterWrapper } from '../Masonry/styles';
import { IconStyle } from '../Icon/styles';
import { themeStyle } from '../../services/theming';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div.attrs(({ galleryHeight }) => ({
  style: { height: galleryHeight },
}))`
  width: ${({ width }) => width ?? '100%'};
  border-radius: ${themeStyle.radiusMedium};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${({ padding, collapsed }) =>
    collapsed ? `${padding}px` : '0'};
  box-sizing: border-box;
  position: relative;
  transition: height 0.5s ease-in-out;

  ${MasonryOuterWrapper} {
    padding-left: ${({ padding }) => `${padding}px`};
    box-sizing: border-box;
  }

  animation: ${fadeIn};
  animation-duration: 1s;

  background: ${themeStyle.varInkSuperLight};
`;

export const LeftLabel = styled.div`
  display: flex;
  flex: 1;
  max-width: 75%;
  align-items: center;

  :last-child {
    flex-shrink: 0;
  }

  ${IconStyle} {
    margin-right: 4px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ headerHeight }) => headerHeight}px;
  width: 100%;
  padding: 0 ${({ padding }) => padding}px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

export const ElementWrapper = styled.div.attrs(({ width }) => ({
  style: {
    width: width ? `${width}px` : 'unset',
  },
}))`
  flex-shrink: 0;
  display: flex;
`;

export const GalleryWrapper = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  height: calc(
    100% - ${({ headerHeight, padding }) => headerHeight + padding}px
  );
`;

export const Gallery = styled.div`
  display: flex;
  overflow-x: scroll;
  will-change: transform;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  ${ElementWrapper} + ${ElementWrapper} {
    margin-left: ${({ elementGap }) => elementGap}px;
  }

  overflow-y: hidden;
`;

export const Scroller = styled.img.attrs(() => ({
  src: '/images/galleryScroll.svg',
}))`
  transform: rotate(${({ dir }) => (dir === 'left' ? '180deg' : '0')});
  right: ${({ dir }) => (dir === 'right' ? '7px' : 'unset')};
  left: ${({ dir }) => (dir === 'left' ? '7px' : 'unset')};
  height: 18px;
  position: absolute;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 0.5s ease-in-out;
  z-index: 1;
  cursor: pointer;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25))
    drop-shadow(0 1px 1px rgba(0, 0, 0, 0.3))
    drop-shadow(0 1px 1px rgba(0, 0, 0, 0.25));

  &:hover {
    opacity: 1;
  }
`;
