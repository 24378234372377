import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';

import { scrollbarWidth } from '../../VerticallyScrollable/style';

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DesignsPanelWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export const ProjectLimit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  margin: 10px ${scrollbarWidth} 0 0;
  background: ${themeStyle.varBrandSuperLight};
  border-radius: ${themeStyle.radiusMedium};
  text-align: center;
`;
