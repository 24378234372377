import PropTypes from 'prop-types';

export const Toast = PropTypes.shape({
  id: PropTypes.number,
  label: PropTypes.string,
  duration: PropTypes.number,
  delay: PropTypes.number,
  error: PropTypes.bool,
  small: PropTypes.bool,
  theme: PropTypes.object,
  verticalAlignment: PropTypes.oneOf(['top', 'bottom']),
  horizontalAlignment: PropTypes.oneOf(['left', 'center', 'right']),
});
