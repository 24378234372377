import React, {
  useCallback,
  useEffect,
  useState,
  cloneElement,
  useLayoutEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import useResizeObserver from '@react-hook/resize-observer';

import useElementsStore, {
  elementStoreSelector,
} from '../../stores/elementsStore';
import { defaultDimensions } from '../ElementGallery/config';
import ElementGroup from '../ElementGroup/ElementGroup';
import Spacer from '../Spacer/Spacer';
import { Wrapper, GroupWrapper, Loader, Groups } from './styles';

const BATCH_SIZE = 5;

/*
 * Component that renders a list of ElementGallery components in a vertical fashion.
 * It has to be passed a valid elementType and category for it to work, since it uses the store to retrieve
 * a fetch function.
 *
 * headGalleries is a list of additional ElementGallery objects that can be appended at the top of the whole list.
 * An example use case is the bookmarks gallery in the templates panel.
 */
const ElementGroupShowcase = (props) => {
  const {
    elementType,
    sort,
    category,
    onGoBack,
    render,
    headGalleries,
    hide,
    elementGalleryDimensions,
  } = props;

  const wrapper = useRef(null);
  const [wrapperHeight, setWrapperHeight] = useState();
  useLayoutEffect(() => {
    if (wrapper.current) {
      setWrapperHeight(wrapper.current.getBoundingClientRect().height);
    }
  }, []);

  useResizeObserver(wrapper, (entry) => {
    setWrapperHeight(entry.contentRect.height);
  });

  const [allowScroll, setAllowScroll] = useState(true);
  useEffect(() => {
    setExtraSpace(0);
    setTranslate(0);
    setAllowScroll(true);
  }, [category]);

  const categories = useElementsStore(elementStoreSelector.categories);

  const [currentFocused, setCurrentFocused] = useState(false);
  const focusOrUnfocusGallery = useCallback((elem, collapsing) => {
    if (!wrapper.current) return;

    const { top: galleryTop } = elem.getBoundingClientRect();
    const { top: panelTop } = wrapper.current.getBoundingClientRect();
    setExtraSpace(collapsing ? 0 : galleryTop - panelTop);
    setTranslate(collapsing ? 0 : -(galleryTop - panelTop));
    setAllowScroll(collapsing);
  }, []);

  const [extraSpace, setExtraSpace] = useState('0');
  const [translate, setTranslate] = useState('0');
  const [groups, setGroups] = useState([]);
  const [displayedGroups, setDisplayedGroups] = useState([]);

  useEffect(() => {
    if (!categories) return;
    if (!categories[elementType] || !categories[elementType][category]) return;

    const buildGroups = () => {
      const categoryFilter = (groupId) => {
        return categories[elementType][category].includes(Number(groupId));
      };

      const filter = category ? categoryFilter : () => true;
      const groupSort = props.groupSort ? props.groupSort : () => 0;

      const groups = categories[elementType][category]
        .filter(filter)
        .map((groupId) => ({
          groupId,
          elementType,
          elementGalleryDimensions: {
            ...elementGalleryDimensions,
            heightExpanded: wrapperHeight,
          },
          collapsed: !(
            (elementType === 'layouts' || elementType === 'mockups') &&
            category !== 'All'
          ),
          sort,
          render,
          hide,
          sideEffectBeginTransition: (elem, collapsing) => {
            if (collapsing) {
              onGoBack && onGoBack();
              setCurrentFocused(false);
            }
            focusOrUnfocusGallery(elem, collapsing);
          },
          sideEffectEndTransition: (elem, collapsed) => {
            setCurrentFocused(!collapsed);
          },
        }))
        .sort(groupSort);

      setGroups(groups);
      setDisplayedGroups((displayedGroups) => {
        return groups.slice(0, displayedGroups.length);
      });
    };

    buildGroups();
  }, [
    wrapperHeight,
    elementType,
    render,
    sort,
    category,
    categories,
    hide,
    focusOrUnfocusGallery,
    onGoBack,
    props.groupSort,
    elementGalleryDimensions,
  ]);

  const headGalleryCount = headGalleries?.length || 0;
  const currentHeadGalleryCount = useRef(headGalleryCount);

  useEffect(() => {
    if (currentFocused) {
      const countDifference =
        headGalleryCount - currentHeadGalleryCount.current;
      if (!countDifference) return;
      const heightCollapsed =
        elementGalleryDimensions?.heightCollapsed ||
        defaultDimensions.heightCollapsed;
      setTranslate(
        (translate) => translate - countDifference * heightCollapsed - 10 //10 is top margin
      );
    }
  }, [headGalleryCount, elementGalleryDimensions, currentFocused]);

  useEffect(() => {
    currentHeadGalleryCount.current = headGalleryCount;
  }, [headGalleryCount]);

  const { ref: loader, inView: loaderInView } = useInView({
    rootMargin: '100px',
    root: wrapper.current,
  });

  useEffect(() => {
    if (!groups) return;
    if (loaderInView) {
      setDisplayedGroups((displayedGroups) => {
        const currentLength = displayedGroups.length;
        if (currentLength < groups.length) {
          return [
            ...displayedGroups,
            ...groups.slice(currentLength, currentLength + BATCH_SIZE),
          ];
        }
        return displayedGroups;
      });
    }
  }, [loaderInView, groups]);

  return (
    <Groups allowScroll={allowScroll} ref={wrapper}>
      <Wrapper translate={translate} currentFocused={currentFocused}>
        {headGalleries?.map((gallery, idx) => (
          <GroupWrapper key={`head-${idx}`}>
            {cloneElement(gallery, {
              ...props,
              elementGalleryDimensions: {
                ...elementGalleryDimensions,
                heightExpanded: wrapperHeight,
              },
              collapsed: true,
            })}
          </GroupWrapper>
        ))}
        {displayedGroups.map((group) => (
          <GroupWrapper key={group.groupId}>
            <ElementGroup {...group} />
          </GroupWrapper>
        ))}
        <Spacer w="100%" h={`${extraSpace}px`} />
        <Loader ref={loader} />
      </Wrapper>
    </Groups>
  );
};

ElementGroupShowcase.propTypes = {
  /**
   * Total expected amount of elements
   */
  elementType: PropTypes.string,
  /**
   * Sorting of elements
   */
  sort: PropTypes.string,
  /**
   * dimensions for element gallery
   */
  elementGalleryDimensions: PropTypes.object,
  /**
   * Category of elements
   */
  category: PropTypes.string,
  /**
   * Function that gets triggered when pressing 'Go Back' on a gallery
   */
  onGoBack: PropTypes.func,
  /**
   * Render function for elements
   */
  render: PropTypes.func,
  /**
   * Whether to hide elements or not
   */
  hide: PropTypes.bool,
  /**
   * List of galleries to append at the top of the group list
   */
  headGalleries: PropTypes.arrayOf(PropTypes.element),
  /**
   * function to sort the groups
   */
  groupSort: PropTypes.func,
};

export default ElementGroupShowcase;
