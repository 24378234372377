import { Challenge, Template } from './index';

enum PostType {
  TEMPLATE = 'TEMPLATE',
}

export interface Post {
  id: string;
  shortId: string;
  template?: Template | null;
  title: string;
  text?: string;
  type: PostType;
  likes: number;
  likedByUser: boolean;
  comments: number;
  challenge?: Challenge | null;
  upvotes: number;
  upvotedByUser: boolean;
  challengePosition: number | null;
}
