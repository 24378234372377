import { themeStyle } from '../../services/theming';
import { TextInputTheme } from './types';

export const defaultTheme: TextInputTheme = {
  // size
  height: '40px',
  width: '100%',
  iconHeight: '16px',
  rightIconHeight: '16px',

  // input
  borderRadius: themeStyle.radiusMedium,

  // input
  color: themeStyle.varInkMedium,
  colorHover: themeStyle.varInkMedium,
  colorActive: themeStyle.varInkMain,
  backgroundColor: themeStyle.varBackgroundAlt,
  backgroundColorDark: null,
  backgroundColorHoverDark: themeStyle.darkInkSuperLight,
  border: '1px solid',
  borderColor: themeStyle.varInkLight,
  borderColorHover: themeStyle.transparent,
  borderColorActive: themeStyle.varInkLight,
  hoverShadow: themeStyle.varShadowSmallFocus,
  innerPadding: '10px',

  // input text
  fontFamily: themeStyle.fontFamily,
  fontWeight: themeStyle.fontMedium,
  fontSize: themeStyle.fontSize14,
  lineHeight: '22px',
  textAlign: 'left',

  // label
  labelColor: themeStyle.varInkMain,

  passwordToggleColor: themeStyle.varBrandMedium,
  transition: '0.3s',

  // error icon
  colorError: themeStyle.alertRed,
};

export const borderLessInputTheme: Partial<TextInputTheme> = {
  border: 'unset',
  backgroundColor: themeStyle.varInkSuperLight,
};

export const smallSearchTheme: Partial<TextInputTheme> = {
  // size
  height: '30px',
  iconHeight: '14px',

  // input text
  fontSize: themeStyle.fontSize12,
  lineHeight: '19px',
  backgroundColorDark: themeStyle.varInkSuperLight,
  backgroundColorHoverDark: null,
};

export const profileInputTheme: Partial<TextInputTheme> = {
  // size
  height: '32px',
  iconHeight: '18px',

  color: themeStyle.varInkMain,
  colorHover: themeStyle.varInkMedium,
  colorActive: themeStyle.varInkMain,
  backgroundColor: themeStyle.varInkBase,
  backgroundColorHoverDark: null,
  borderColor: themeStyle.varInkBase,
  borderColorHover: themeStyle.varInkLight,
  borderColorActive: themeStyle.varInkLight,

  hoverShadow: 'unset',

  innerPadding: '8px',
};

export const valueInputTheme: Partial<TextInputTheme> = {
  // size
  height: '30px',

  // input text
  fontSize: themeStyle.fontSize12,
  lineHeight: '19px',

  color: themeStyle.varInkMedium,
  colorHover: themeStyle.varInkMain,
  colorActive: themeStyle.varInkMain,

  backgroundColor: themeStyle.varInkBase,
  backgroundColorHoverDark: null,

  borderColor: themeStyle.varInkLight,
  borderColorHover: themeStyle.varInkLight,
  borderColorActive: themeStyle.varInkLight,

  hoverShadow: 'unset',
  innerPadding: '4px',
};

export const layerInputTheme: Partial<TextInputTheme> = {
  color: themeStyle.varInkMain,
  height: '100%',
  border: 'none',
  backgroundColor: themeStyle.transparent,
  backgroundColorHoverDark: null,
  hoverShadow: 'none',
  fontSize: themeStyle.fontSize12,
  fontWeight: themeStyle.fontMedium,
  lineHeight: '14px',
  innerPadding: '5px',
};

/**
 * @deprecated
 */
export const newInputTheme = defaultTheme;
