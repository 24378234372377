import React, { useState, useEffect, useMemo, useCallback } from 'react';

import {
  InputWrapper,
  InputContainer,
  InputField,
  InputBox,
  IconWrapper,
  ContentWrapper,
  InfoText,
  RightIcons,
  Label,
} from './styles';
import { defaultTheme } from './theme';
import useDebounce from '../../hooks/useDebounce';
import Icon from '../Icon/Icon';
import IconEventWrapper from '../Icon/IconEventWrapper';
import Tooltip from '../Tooltip/Tooltip';
import analytics from '../../global/analytics';
import { TOGGLE_PASSWORD } from '../../global/analytics/events';
import IconButton from '../Button/IconButton';
import { TextInputElement, TextInputProps, TextInputTheme } from './types';

const KEY_ENTER = 13;
const DEFAULT_DEBOUNCE = 2000;

const stopPropagation = (event: React.SyntheticEvent<TextInputElement>): void =>
  event.stopPropagation();
const preventDefault = (event: React.SyntheticEvent<TextInputElement>): void =>
  event.preventDefault();

const getIconColor = (
  color: string,
  theme: TextInputTheme,
  error?: string
): string => {
  if (error) return theme.colorError;
  return color;
};

const TextInput: React.FC<TextInputProps> = ({
  useInput,
  maxLength,
  placeholder,
  onChanging,
  onChanged,
  onFocus,
  onBlur,
  onEnter,
  blurOnEnter,
  onKeyDown,
  ...props
}) => {
  const debounceTime = props.debounceTime || DEFAULT_DEBOUNCE;
  const theme = { ...defaultTheme, ...props.theme };
  const iconTheme = {
    color: getIconColor(theme.iconColor || theme.color, theme, props.error),
    activeDefaultColor: getIconColor(
      theme.iconColorActive || theme.iconColor || theme.colorActive,
      theme,
      props.error
    ),
    activeColor: getIconColor(
      theme.iconColorActive || theme.iconColor || theme.colorActive,
      theme,
      props.error
    ),
    transition: theme.transition,
  };

  const [currentValue, setCurrentValue] = useState(props.value || '');
  const [key, setKey] = useState(false); // key is used to force update contentEditable field

  const [showPassword, setShowPassword] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const error = useMemo(() => {
    return props.error;
  }, [props.error]);

  useEffect(() => {
    // if a value is set and editing is left, use the given value
    if (props.value !== undefined && !isEditing) {
      setCurrentValue(props.value || '');
    }
  }, [props.value, isEditing]);

  const onChangedDebounced = useDebounce({
    callback: (val: string) => {
      props.onDebounce && props.onDebounce(val);
    },
    delay: debounceTime,
  });

  const getValue = useCallback(
    (
      event:
        | React.KeyboardEvent<TextInputElement>
        | React.ChangeEvent<TextInputElement>
        | React.FocusEvent<TextInputElement>
    ) => {
      let value = '';
      if (
        useInput &&
        (event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement)
      ) {
        value = event.target.value;
      } else if (event.target instanceof HTMLDivElement) {
        // source: https://stackoverflow.com/questions/822452/strip-html-from-text-javascript/47140708#47140708
        const doc = new DOMParser().parseFromString(
          event.target.innerHTML,
          'text/html'
        );
        value = doc.body.textContent?.trim() || placeholder || '';
      }

      if (maxLength) {
        return value.slice(0, maxLength);
      }
      return value;
    },
    [useInput, maxLength, placeholder]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = getValue(event);

      setCurrentValue(newValue);
      onChanging && onChanging(newValue);
      onChangedDebounced(newValue);
    },
    [getValue, onChanging, onChangedDebounced]
  );

  const onInputFocus = useCallback(
    (_event: React.FocusEvent<TextInputElement>) => {
      setIsEditing(true);
      onFocus && onFocus();
    },
    [onFocus]
  );

  const onInputBlur = useCallback(
    (event: React.FocusEvent<TextInputElement>) => {
      const newValue = getValue(event);

      setCurrentValue(newValue);
      setIsEditing(false);
      !useInput && setKey((prev) => !prev); // key is used to force update contentEditable field
      onChanged && onChanged(newValue);
      onBlur && onBlur();
    },
    [getValue, useInput, onChanged, onBlur]
  );

  const onInputKeyDown = useCallback(
    (event: React.KeyboardEvent<TextInputElement>) => {
      event.stopPropagation();
      if (event.keyCode === KEY_ENTER || event.key === 'Enter') {
        setIsEditing(false);
        onEnter && onEnter(getValue(event));
        if (blurOnEnter && event.target instanceof HTMLElement) {
          event.target.blur(); // blur input, triggers `onInputBlur`
        }
      }
      onKeyDown && onKeyDown(event);
    },
    [getValue, onEnter, blurOnEnter, onKeyDown]
  );

  const handleEditableInput = useCallback(
    (event: React.ChangeEvent<HTMLDivElement>) => {
      const doc = new DOMParser().parseFromString(
        event.target.innerHTML,
        'text/html'
      );
      const value = doc.body.textContent ?? '';
      onChanging && onChanging(value);
      if (maxLength && value.length > maxLength) {
        event.target.innerHTML = value.slice(0, maxLength);
      }
    },
    [onChanging, maxLength]
  );

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((previous) => {
      analytics.track(TOGGLE_PASSWORD, {
        label: `hidden: ${previous}`,
      });
      return !previous;
    });
  }, []);

  const inputName = props.name ?? props.label;

  const inputProps = {
    onFocus: onInputFocus,
    onBlur: onInputBlur,
    onKeyDown: onInputKeyDown,
    disabled: props.disabled,
    isPasswordInput: props.isPasswordInput,
    isTextArea: props.isTextArea,
    autoComplete: props.autoComplete,
    name: inputName,
    error: props.error,
  };

  return (
    <InputWrapper theme={theme} onClick={stopPropagation}>
      {props.label && (
        <Label error={error} theme={theme} as="label" htmlFor={inputName}>
          {props.label}
        </Label>
      )}
      <InputContainer
        theme={theme}
        error={props.error}
        disabled={props.disabled}
        isActive={props.isActive}
      >
        <ContentWrapper>
          <IconEventWrapper
            fitParent={true}
            theme={iconTheme}
            disabled={props.disabled}
            activeOnHover={props.activeOnHover}
            activeOnFocus={props.activeOnFocus}
          >
            {props.icon && !props.iconAtEnd && (
              <Tooltip
                text={props.tooltipText}
                align={props.tooltipAlignment}
                placement={props.tooltipPlacement}
              >
                <IconWrapper theme={theme}>
                  <Icon
                    name={props.icon}
                    height={theme.iconHeight}
                    disabled={props.disabled}
                    isActive={props.isActive}
                    theme={iconTheme}
                  />
                </IconWrapper>
              </Tooltip>
            )}
            {useInput && (
              <InputField
                {...inputProps}
                as={props.isTextArea ? 'textarea' : 'input'}
                value={currentValue}
                placeholder={placeholder}
                onChange={onInputChange}
                isPasswordInput={props.isPasswordInput && !showPassword}
                theme={theme}
                ref={props.inputRef}
              />
            )}
            {!useInput && (
              <InputBox
                key={key.toString()}
                {...inputProps}
                onInput={handleEditableInput}
                onKeyUp={preventDefault}
                ref={props.inputRef}
                isPasswordInput={props.isPasswordInput}
                theme={theme}
              >
                {currentValue}
              </InputBox>
            )}
            {!props.error && props.icon && props.iconAtEnd && (
              <IconWrapper theme={theme} isEnd>
                <Icon
                  name={props.icon}
                  height={theme.iconHeight}
                  disabled={props.disabled}
                  theme={iconTheme}
                />
              </IconWrapper>
            )}
          </IconEventWrapper>
          <RightIcons>
            {props.rightIcon && (
              <>
                {props.onRightIconClick ? (
                  <IconButton
                    iconName={props.rightIcon}
                    height={theme.rightIconHeight}
                    theme={props.rightIconTheme}
                    onClick={props.onRightIconClick}
                    ariaLabel={props.rightIconLabel ?? ''}
                  />
                ) : (
                  <Icon
                    name={props.rightIcon}
                    height={theme.rightIconHeight}
                    theme={props.rightIconTheme}
                  />
                )}
              </>
            )}
            {props.isPasswordInput && (
              <button
                onClick={togglePasswordVisibility}
                style={{ cursor: 'pointer' }}
                type={'button'}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                <Icon
                  name={showPassword ? 'eyeOutlineSlash' : 'eyeOutline'}
                  height="13px"
                  theme={{
                    color: error ? theme.colorError : theme.passwordToggleColor,
                  }}
                />
              </button>
            )}
            {props.error && (
              <Icon
                name="infoThick"
                height="18px"
                theme={{
                  color: theme.colorError,
                  secondaryColor: theme.backgroundColor,
                }}
              />
            )}
          </RightIcons>
        </ContentWrapper>
      </InputContainer>
      {(props.infoText || error) && (
        <InfoText error={error} theme={theme}>
          {error || props.infoText}
        </InfoText>
      )}
    </InputWrapper>
  );
};

TextInput.defaultProps = {
  placeholder: '',
  disabled: false,
  blurOnEnter: true,
  useInput: true,
  activeOnHover: true,
  activeOnFocus: true,
};

export default TextInput;
