import styled from 'styled-components';

export const WidgetsWrapper = styled.div<any>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;

  * {
    pointer-events: auto;
  }
`;

export const WidgetWrapper = styled.div.attrs<any>(
  ({ translateX, translateY, angle }) => {
    return {
      style: {
        transform: `translate(${translateX}px, ${translateY}px) rotate(${angle}deg)`,
      },
    };
  }
)<any>`
  position: absolute;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  touch-action: none;
`;

export const WidgetContent = styled.div<any>`
  transform: translate(-50%, -50%);
`;
