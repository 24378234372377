import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { H4 } from '../utilities/Typography/styles';

export const Panel = styled.div`
  padding-top: ${({ theme }) => (theme.paddingTop ? theme.paddingTop : '8px')};
  padding-bottom: ${({ theme }) =>
    theme.paddingBottom ? theme.paddingBottom : '8px'};
`;

export const Wrapper = styled.div`
  ${Panel}:not(:first-child) {
    border-top: 1px solid ${themeStyle.varInkSuperLight};
  }
`;

export const Header = styled.div`
  height: ${({ theme }) => theme.buttonHeight};
  padding: 0 ${({ theme }) => theme.outerPadding};
  border-radius: 5px;
  cursor: pointer;
  display: grid;
  grid-template-columns: ${({ hasIcon }) => (hasIcon ? '24px ' : '')}auto 16px;
  gap: 8px;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.hoverBackgroundColor};
  }
`;

export const Arrow = styled.div`
  transform: rotate(${({ isActive }) => (isActive ? '0' : '180')}deg);
  transition: transform 0.3s;
`;

export const Content = styled.div`
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
`;

export const NewBadge = styled.div`
  font-family: ${themeStyle.fontFamily};
  font-weight: ${themeStyle.fontSemiBold};
  font-size: ${themeStyle.fontSize10};
  line-height: 10.5px;
  color: ${themeStyle.varInkBase};
  padding: 5px;
  border-radius: 5px;
`;

export const Label = styled(H4)`
  color: ${({ isActive, theme }) =>
    isActive ? theme.activeColor : theme.color};
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: ${({ theme }) => theme.fontWeight};
`;
