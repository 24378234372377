import fabric from '../index';
import {
  createWarpControlPointName,
  drawGirdOrHandleLine,
  drawWarpControlPoint,
  getWarpControlPoint,
  warpControlHandlePointObjectType,
  warpControlPointDefaultOptions,
} from './utils';
import {
  WarpControlHandleDirection,
  WarpControlPointInitOptions,
} from './types';
import { themeStyle } from '../../../../services/theming';
import {
  getMovedPointAlongDirection,
  getVectorAngle,
} from '../../../../utils/geometry/vector';
import { getDistance } from '../../../../utils/geometry/point';
import { Point } from '../../../../types';
import { moveTo, lineTo } from '../../../../utils/path/commands';

interface WarpControlHandlePointInitOptions
  extends WarpControlPointInitOptions {
  direction: WarpControlHandleDirection;
}

export const extendWarpControlHandlePoint = (fabric: fabric): void => {
  /**
   * The class for warp control handle point.
   * Instance properties:
   * @property {string} name: follows a pattern, useful for look-up
   * @property {number} i: on which row the point is in the warp grid
   * @property {number} j: on which column the point is in the warp grid
   * @property {{ x: number; y: number }} lastPosition:
   *  last absolute position, useful when moving
   * @property {fabric.Path} handleLine:
   *  The handle line between this and its root point.
   *  It will be rendered as part of this.
   * @property {WarpControlHandleDirection} direction:
   *  The direction of the handle, that this handle point is on.
   */
  fabric.WarpControlHandlePoint = fabric.util.createClass(fabric.Circle, {
    type: warpControlHandlePointObjectType,

    initialize({
      left,
      top,
      i,
      j,
      locked = false,
      direction,
    }: WarpControlHandlePointInitOptions): fabric.WarpControlHandlePoint {
      const options = {
        ...warpControlPointDefaultOptions,
        name: createWarpControlPointName(i, j, direction),
        left,
        top,
        locked,
        fill: 'transparent',
      };
      this.callSuper('initialize', options);

      this.direction = direction;
      this.i = i;
      this.j = j;
      this.updateLastPosition();
      this.initHandleLine();

      this.on('moving', this.handleMove);

      return this;
    },

    /*
      Gets the root point associated with this.
     */
    getRootPoint(): fabric.WarpControlRootPoint | undefined {
      if (!this.canvas) return undefined;

      return getWarpControlPoint(this.canvas, this.i, this.j);
    },

    /*
      Gets the "linked" point, i.e., the handle point to the counter direction of this.
     */
    getLinkedPoint(): fabric.WarpControlHandlePoint | undefined {
      if (!this.canvas) return undefined;

      let linkedPointDirection: WarpControlHandleDirection;
      if (this.direction === WarpControlHandleDirection.Left) {
        linkedPointDirection = WarpControlHandleDirection.Right;
      } else if (this.direction === WarpControlHandleDirection.Right) {
        linkedPointDirection = WarpControlHandleDirection.Left;
      } else if (this.direction === WarpControlHandleDirection.Top) {
        linkedPointDirection = WarpControlHandleDirection.Bottom;
      } else if (this.direction === WarpControlHandleDirection.Bottom) {
        linkedPointDirection = WarpControlHandleDirection.Top;
      } else {
        throw new Error('Missing direction on WarpControlHandlePoint');
      }

      return getWarpControlPoint(
        this.canvas,
        this.i,
        this.j,
        linkedPointDirection
      );
    },

    initHandleLine(): void {
      this.handleLine = new fabric.Path();
      this.handleLine.set({
        locked: true,
        fill: 'transparent',
        stroke: themeStyle.selectionBlue,
        objectCaching: false,
      });
    },

    updateLastPosition(): void {
      this.lastPosition = this.getAbsoluteCenterPoint();
    },

    getAngleFromRoot(): number {
      const rootPoint = this.getRootPoint()!;

      return getVectorAngle(
        rootPoint.getAbsoluteCenterPoint(),
        this.getAbsoluteCenterPoint()
      );
    },

    handleMove(): void {
      this.updateLastPosition();

      // Move the linked point so that it, the root point, and this are always on one line.
      const rootPoint = this.getRootPoint();
      const linkedPoint = this.getLinkedPoint();
      if (!rootPoint || !linkedPoint) return;

      const angle = this.getAngleFromRoot();

      const linkedPointAngleFromRoot = angle + Math.PI;
      const linkedPointPosition = getMovedPointAlongDirection(
        rootPoint.getAbsoluteCenterPoint(),
        linkedPointAngleFromRoot,
        getDistance(
          linkedPoint.getAbsoluteCenterPoint(),
          rootPoint.getAbsoluteCenterPoint()
        ),
        true
      );
      linkedPoint.setPosition(linkedPointPosition);
    },

    setPosition(position: Point): void {
      this.setPositionByOrigin(position, 'center', 'center');
      this.updateLastPosition();
    },

    /*
       Willbe called with the root point of this when it moves.
     */
    moveWithRoot(delta: fabric.Point): void {
      this.setPosition(this.lastPosition.add(delta));
    },

    /*
      This is an override of fabric function.
     */
    _set(key: string, value: unknown): void {
      // in some cases there are methods setting `hasControls` to be `true`,
      // but we don't want any controls for this object.
      if (key === 'hasControls') {
        this.callSuper('_set', key, false);
      } else {
        this.callSuper('_set', key, value);
      }
    },

    renderHandleLine(ctx: CanvasRenderingContext2D): void {
      const rootPoint = this.getRootPoint();
      if (!rootPoint) return;

      const rootCenter = rootPoint.getAbsoluteCenterPoint();
      const center = this.getAbsoluteCenterPoint();
      const pathData = moveTo(center) + lineTo(rootCenter);
      this.handleLine.set({
        path: fabric.util.parsePath(pathData),
      });
      drawGirdOrHandleLine(ctx, this.canvas, this.handleLine);
    },

    /*
      This is an override of fabric function.
     */
    render(ctx: CanvasRenderingContext2D): void {
      if (!this.visible) return;
      drawWarpControlPoint(ctx, this.canvas, this);
      this.renderHandleLine(ctx);
    },

    /*
      This is an override of fabric function.
     */
    toObject(): void {
      // keep extra properties for initialization
      return this.callSuper('toObject', ['i', 'j', 'direction']);
    },
  });

  fabric.WarpControlHandlePoint.fromObject = (
    object: Record<string, unknown>,
    callback: (object: fabric.WarpControlHandlePoint) => void
  ): void => {
    const handlePoint = new fabric.WarpControlHandlePoint(object);
    callback(handlePoint);
  };
};
