import fabric from '../../Artboard/fabric';
import { handleWheelEvent } from '../../../utils/editor/eventHandlers';
import { Dispatcher } from '../../../types';
import { updateArtboardPosition } from '../../../stores/onboardingStore';
import { HistoryUtil } from '../../../services/history';
import { createCanvasEventHandlerUtils } from '../../../utils/mockup/createCanvasEventHandlerUtils';
import { useEffect } from 'react';
import useMockupStore, {
  mockupStoreSelector,
} from '../../../stores/mockupStore/mockupStore';

const handleMouseDown = (canvas: fabric.Canvas, event: MouseEvent): void => {
  if (canvas.draggingEnabled) {
    // drag canvas
    canvas.setCursor('grabbing');
    canvas.isDragging = true;
    canvas.lastPosX = event.clientX;
    canvas.lastPosY = event.clientY;
  }
};

const handleMouseMove = (canvas: fabric.Canvas, event: MouseEvent): void => {
  if (canvas.draggingEnabled) {
    canvas.setCursor(canvas.isDragging ? 'grabbing' : 'grab');
  }

  if (canvas.isDragging) {
    const vpt = canvas.viewportTransform;
    // calculate the new viewport position after dragging
    const newPosX = vpt[4] + event.clientX - canvas.lastPosX;
    const newPosY = vpt[5] + event.clientY - canvas.lastPosY;
    // set the new viewport pos
    canvas.setViewportPos(newPosX, newPosY);
    canvas.requestRenderAll();
    updateArtboardPosition(
      canvas.artboard,
      canvas.getZoom(),
      canvas.viewportTransform
    );
  }
  canvas.lastPosX = event.clientX;
  canvas.lastPosY = event.clientY;
};

const handleMouseUp = (canvas: fabric.Canvas): void => {
  // on mouse up we want to recalculate new interaction
  // for all objects, so we call setViewportTransform
  canvas.setViewportTransform(canvas.viewportTransform);
  canvas.isDragging = false;
};

const handleObjectModified = (
  canvas: fabric.Canvas,
  history: HistoryUtil | null,
  event: fabric.Event
): void => {
  if (event.action === 'drag') {
    canvas.hasMovingObject = false;
  }

  if (canvas.hasMovingObject) return;

  // don't push empty state to history when intialzing
  if (history && canvas._objects.length > 1) {
    const artboardState = canvas.toJSON();
    history.update(artboardState);
  }
};

/**
 * This hook handles the binding/unbinding of event handlers on canvas.
 */
export const useCanvasEventHandlers = (
  canvas: fabric.Canvas | null,
  history: HistoryUtil | null,
  dispatch: Dispatcher
): void => {
  const setMockupState = useMockupStore(mockupStoreSelector.setState);

  useEffect(() => {
    if (!canvas) return;

    const { add, removeAll } = createCanvasEventHandlerUtils();

    add(canvas, 'mouse:wheel', (options: { e: WheelEvent }) => {
      handleWheelEvent(canvas, options.e, dispatch);
    });

    add(canvas, 'mouse:down', ({ e }: { e: MouseEvent }) => {
      handleMouseDown(canvas, e);
    });

    add(canvas, 'mouse:move', ({ e }: { e: MouseEvent }) => {
      handleMouseMove(canvas, e);
    });

    add(canvas, 'mouse:up', () => {
      handleMouseUp(canvas);
    });

    add(canvas, 'object:modified', (event: fabric.event) => {
      handleObjectModified(canvas, history, event);
      setMockupState(canvas);
    });

    return (): void => {
      removeAll(canvas);
    };
  }, [canvas, history, dispatch, setMockupState]);
};
