import styled, { keyframes, css } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const enableFadeIn = css`
  animation: ${fadeIn} 0.3s;
`;

export const FadeIn = styled.div`
  ${({ enabled }) => enabled && enableFadeIn}
`;
