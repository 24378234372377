import Cookies from 'js-cookie';
import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { COOKIE_DOMAIN } from '../../global/constants';
import { getSystemTheme } from './utils';

export enum Theme {
  SYSTEM = 'system',
  LIGHT = 'light',
  DARK = 'dark',
}

interface State {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const THEME_KEY = 'htcTheme';

const getInitialTheme = (): Theme => {
  const themeValue = Cookies.get(THEME_KEY);
  if (themeValue === Theme.DARK) return Theme.DARK;
  return Theme.LIGHT;
};

const themeStore = createVanilla<State>((set) => ({
  theme: getInitialTheme(),

  setTheme: (theme: Theme): void => {
    set(() => ({ theme }));
    Cookies.set(THEME_KEY, theme, {
      path: '/',
      domain: COOKIE_DOMAIN,
      sameSite: 'lax',
      secure: true,
    });
  },
}));

export const themeStoreSelector = {
  theme: (state: State): State['theme'] => state.theme,
  resolvedTheme: (state: State): Exclude<Theme, Theme.SYSTEM> => {
    if (state.theme !== Theme.SYSTEM) return state.theme;
    return getSystemTheme();
  },
  setTheme: (state: State): State['setTheme'] => state.setTheme,
};

export const useThemeStore = create(themeStore);
