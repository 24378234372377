import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import ElementGallery from '../ElementGallery/ElementGallery';
import userApi from '../../global/userApi';
import useElementsStore, {
  elementStoreSelector,
} from '../../stores/elementsStore';
import { uniques } from '../../utils';
import WithAuth from '../WithAuth/WithAuth';
import { DEFAULT_TAKE } from '../../global/constants';

const Bookmarks = (props) => {
  const { collapsed, render } = props;

  const [bookmarks, setBookmarks] = useState([]);
  const fetchInfo = useRef({
    take: DEFAULT_TAKE,
    skip: 0,
    fetching: false,
  });

  const isBookmarked = useElementsStore(elementStoreSelector.isBookmarked);
  const storeBookmarks = useElementsStore(elementStoreSelector.storeBookmarks);
  const localBookmarks = useElementsStore(elementStoreSelector.localBookmarks);

  const [elements, setElements] = useState([]);

  /*
    Everytime bookmarks (in store) update, or bookmarks state variable changes here,
    set the elements to be only the ones that are bookmarked in store.

    This is because it could happen that a bookmark that is coming from 'getBookmarks' is then
    unbookmarked by the user. The local state variable 'bookmarks' will still hold this element,
    but the store will know that it is not bookmarked anymore.
  */
  useEffect(() => {
    if (!bookmarks.length) return;
    setElements(
      bookmarks.filter((b) => b?.id && isBookmarked('templates', b.id))
    );
  }, [storeBookmarks, bookmarks, isBookmarked, localBookmarks]);

  const fetch = useCallback(async () => {
    const { take, skip, fetching, finished } = fetchInfo.current;

    if (fetching || finished) {
      return;
    }

    fetchInfo.current.fetching = true;
    const bookmarks = await userApi.getBookmarks(take, skip);
    setBookmarks((prevBookmarks) => {
      const newBookmarks = [...prevBookmarks, ...bookmarks.results];
      fetchInfo.current.skip += bookmarks.results.length;
      if (newBookmarks.length === bookmarks.total)
        fetchInfo.current.finished = true;
      return newBookmarks;
    });
    fetchInfo.current.fetching = false;
  }, []);

  /* If no elements, fetch */
  useEffect(() => {
    if (!elements.length) fetch();
  }, [fetch, elements.length]);

  if (!((localBookmarks?.length || 0) + (storeBookmarks?.length || 0)))
    return null;

  /*
    There could be duplicates if a user unbookmarks an element that was bookmarked before the current session
    (coming from 'getBookmarks'), and then bookmarks it again, since store will add it to the local bookmarks.
  */
  const displayElements = uniques([...(localBookmarks || []), ...elements]);

  return (
    <ElementGallery
      collapsed={collapsed}
      elements={displayElements}
      render={render}
      fetch={fetch}
      labelLeft="Bookmarked"
      dimensions={props.elementGalleryDimensions}
    />
  );
};

const BookmarksWithAuth = (props) => {
  return (
    <WithAuth renderAuth={() => Bookmarks(props)} renderUnauth={() => null} />
  );
};

const propTypes = {
  /**
   * Whether the gallery used is collapsed or not
   */
  collapsed: PropTypes.bool,
  /**
   * Render function for elements
   */
  render: PropTypes.func,
  /**
   * dimensions for the ElementGallery
   */
  elementGalleryDimensions: PropTypes.object,
};

Bookmarks.propTypes = propTypes;
BookmarksWithAuth.propTypes = propTypes;

export default BookmarksWithAuth;
