import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import { themeStyle } from '../services/theming';

const SHOW_TOAST_AMOUNT = 5;
export const TOAST_GAP = 20; // in px
export const FADE_IN_OUT_DURATION = 150; // in ms

const DEFAULT_TOAST_PARAMS = {
  verticalAlignment: 'bottom',
  horizontalAlignment: 'center',
  duration: 1500,
  theme: {
    height: 60,
    fontFamily: themeStyle.fontFamily,
    fontSize: themeStyle.fontSize16,
    fontWeight: themeStyle.fontSemiBold,
    color: themeStyle.varBackgroundAlt,
    background: themeStyle.varInkMain,
    iconColor: themeStyle.varBrandMain,
    errorIconColor: themeStyle.varAlertRed,
    secondaryIconColor: themeStyle.inkBase,
    shadow: themeStyle.varShadowHeavy,
  },
};

const toastStore = createVanilla((set, get) => ({
  // data
  queue: [],

  // internal methods
  _initToast: (toast) => {
    setTimeout(() => {
      return set((state) => {
        const newQueue = [...state.queue];

        if (newQueue.length >= SHOW_TOAST_AMOUNT) {
          newQueue.pop();
        }

        newQueue.unshift(toast);

        return {
          queue: newQueue,
        };
      });
    }, toast.delay);

    setTimeout(() => {
      set((state) => {
        const newQueue = [...state.queue].filter((t) => t.id !== toast.id);
        return {
          queue: newQueue,
        };
      });
    }, toast.delay + toast.duration);
  },

  _initToastBatch: (toasts) => {
    const { _initToast } = get();

    toasts.forEach((t) => _initToast(t));
  },

  // public methods
  fire: (params) => {
    const { _initToast, _initToastBatch } = get();

    if (Array.isArray(params)) {
      const newToasts = params.map((t) => ({
        id: Math.floor(Math.random() * 1000000) + 1,
        label: t.label,
        ...DEFAULT_TOAST_PARAMS,
        ...t,
        duration: t.duration || DEFAULT_TOAST_PARAMS.duration,
        delay: t.delay || 0,
        error: t.error || false,
        small: t.small || false,
        theme: {
          ...DEFAULT_TOAST_PARAMS.theme,
          ...t.theme,
          height: t.small ? 30 : DEFAULT_TOAST_PARAMS.theme.height,
        },
      }));

      _initToastBatch(newToasts);
    } else {
      const newToast = {
        id: Math.floor(Math.random() * 1000000) + 1,
        label: params.label,
        ...DEFAULT_TOAST_PARAMS,
        ...params,
        duration: params.duration || DEFAULT_TOAST_PARAMS.duration,
        delay: params.delay || 0,
        error: params.error || false,
        small: params.small || false,
        theme: {
          ...DEFAULT_TOAST_PARAMS.theme,
          ...params.theme,
          height: params.small ? 30 : DEFAULT_TOAST_PARAMS.theme.height,
        },
      };

      _initToast(newToast);
    }
  },
}));

export const useToastStore = create(toastStore);

export const toastSelector = {
  queue: (state) => state.queue,
  fire: (state) => state.fire,
};

/**
 * @deprecated
 */
export const selector = toastSelector;
