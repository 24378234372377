import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { filter, auditTime } from 'rxjs/operators';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import UnauthView from './UnauthView';
import { AppUIOverlay, ContentWrapper } from './appStyles';

import {
  ARTBOARD_STATE_KEY,
  COOKIE_DOMAIN,
  HTC_DESIGNER_VISITED,
  INVITE_FRIENDS_KEY,
  PROJECT_ID_KEY,
} from './global/constants';
import createDispatcher from './global/dispatcher';
import {
  ARTBOARD_STATE,
  ARTBOARD_SELECTION,
  ARTBOARD_IS_LOADING,
  DESIGN_SAVE,
  ACTIVE_TRANSFORM_BUTTON,
  OPEN_ARTBOARD_SIZE_MODAL,
  TOGGLE_SUBSCRIPTION_MODAL,
} from './global/events';
import analytics from './global/analytics';
import {
  POPUP_LEARN_MORE_CLICK,
  UPGRADE_MODAL_CLICK,
  PROJECTLIMIT_MODAL_CLICK,
  DOWNLOAD_MODAL_CLICK,
  UPLOAD_FONT_MODAL_CLICK,
} from './global/analytics/events';
import { onDropHandler, onDragOverHandler } from './global/uploads';

import activeObjectConverter from './helpers/activeObject/converter';
import useAggregatedLayers from './hooks/useAggregatedLayers';

import StateProvider from './components/StateProvider';
import leftMenuContext from './components/leftMenuContext';
import Artboard from './components/Artboard/Artboard';
import Header from './components/Header/Header';
import RightMenu from './components/RightMenu/RightMenu';
import LeftMenu from './components/LeftMenu/LeftMenu';
import BottomMenu from './components/BottomMenu/BottomMenu';
import { Background, Message } from './components/utilities/styles';
import OnboardingManager from './components/OnboardingManager/OnboardingManager';
import DecisionModal from './components/DecisionModal/DecisionModal';

import { isDebugActive } from './utils/dev';
import { urlParams, PROMO_CODE } from './utils/window';
import { filterColorPalette } from './utils/editor/misc';
import { buildWebsiteUrl, getSubscriptionPageUrl } from './utils/url';

import {
  settingsStoreSelector,
  useSettingsStore,
} from './stores/settingsStore';
import { userStoreSelector, useUserStore } from './stores/userStore';
import {
  useOnboardingStore,
  getDisplayStep,
  onboardingStoreSelector,
} from './stores/onboardingStore';
import {
  notificationStoreSelector,
  useNotificationStore,
} from './stores/notificationStore';
import useElementsStore, { elementStoreSelector } from './stores/elementsStore';
import { uploadStoreSelector, useUploadStore } from './stores/uploadStore';
import useCommonFontsStore, {
  commonFontsStoreSelector,
} from './stores/commonFontsStore';
import { menuStoreSelector, useMenuStore } from './stores/menuStore';
import { useToastStore, toastSelector } from './stores/toastStore';
import {
  DOWNLOAD_PANEL_UPGRADE_TYPES,
  promotionSelector,
  usePromotionStore,
} from './stores/promotionStore';
import useDesignsStore, { designsStoreSelector } from './stores/designsStore';

import Banner from './components/Banner/Banner';
import PromoCodeModal from './components/PromoCodeModal/PromoCodeModal';
import Popover from './components/Popover/Popover';
import InviteFriendsPopup from './components/InviteFriendsPopup/InviteFriendsPopup';
import { Anchor } from './components/OnboardingManager/styles';
import TemplateSubmitModal from './components/TemplateSubmitModal/TemplateSubmitModal';
import UpgradeModal from './components/UpgradeModal/UpgradeModal';
import { isMaskGroup } from './utils/masking';
import { ONBOARDING_USER_STEP } from './stores/onboardingStore/constants';
import Authentication from './components/Authentication/Authentication';
import ArtboardSizeModal from './components/ArtboardSizeModal/ArtboardSizeModal';
import SubscriptionModal from './components/SubscriptionModal/SubscriptionModal';
import MockupBoard from './components/MockupBoard/MockupBoard';
import MockupTemplateBoard from './components/MockupTemplateBoard/MockupTemplateBoard';
import { setOnboardingStepIfActive } from './components/Artboard/handleEvents/utils';
import { useTheme } from './services/theming';
import { getBoardBackground } from './utils/editor/theme';

const setVisitedCookie = () => {
  Cookies.set(HTC_DESIGNER_VISITED, true, {
    expires: 7,
    path: '/',
    domain: COOKIE_DOMAIN,
    sameSite: 'lax',
    secure: true,
  });
};

const updateBookmarks = () => {
  useElementsStore.getState().updateBookmarks('templates');
};

function AppContent(props) {
  const [appState, setAppState] = useState({
    artboard: {},
    selectedObjects: [],
    activeObject: null,
  });

  const fireToast = useToastStore(toastSelector.fire);
  const dispatcherRef = useRef(null);

  const layers = useAggregatedLayers(appState.artboard);

  const [isLoading, setIsLoading] = useState(true);

  const [leftPanelState, setLeftPanelState] = useState({ state: 'closed' });

  const workingMode = useMenuStore(menuStoreSelector.workingMode);
  const isMockupMode = workingMode === 'mockup';
  const isMockupTemplateMode = workingMode === 'mockupTemplate';

  const handleOpenDesignsPanel = () => {
    setLeftPanelState({
      state: 'open',
      active: {
        panel: 'designs',
      },
    });
  };

  const theme = useTheme();
  const trimView = useSettingsStore(settingsStoreSelector.trimView);

  useEffect(() => {
    if (isMockupMode) {
      setLeftPanelState({ state: 'open', active: { panel: 'mockup' } });
    } else {
      setLeftPanelState({ state: 'closed' });
    }
  }, [isMockupMode]);

  const [justPressedEditTransform, setJustPressedEditTransform] =
    useState(null);

  const [promoCode, setPromoCode] = useState(null);

  const [manualSaves, setManualSaves] = useState(0);
  const currentManualSaves = useRef(0); // To keep track of when the user has just saved

  const getDispatcher = () => {
    if (dispatcherRef.current === null) {
      dispatcherRef.current = createDispatcher();
    }
    return dispatcherRef.current;
  };

  let initialProjectId = localStorage.getItem(PROJECT_ID_KEY);
  if (!initialProjectId || initialProjectId === 'undefined') {
    // fallback to old artboard in localstorage
    const initialState = JSON.parse(localStorage.getItem(ARTBOARD_STATE_KEY));
    initialProjectId = initialState?.config?.designId;

    if (initialProjectId === 'undefined') {
      // NOTE: by accident we were setting PROJECT_ID_KEY to 'undefined' in the past
      initialProjectId = null;
      localStorage.removeItem(ARTBOARD_STATE_KEY);
      localStorage.removeItem(PROJECT_ID_KEY);
    } else if (initialProjectId) {
      // clear artboardstate key
      localStorage.removeItem(ARTBOARD_STATE_KEY);
      localStorage.setItem(PROJECT_ID_KEY, initialProjectId);
    }
  }

  const upgradeModalProps = usePromotionStore(
    promotionSelector.upgradeModalProps
  );

  const handleOnUpgrade = () => {
    const type = upgradeModalProps.contentType;

    // track old events for click in upgrade modals
    // TODO: remove after test phase
    if (type === 'projectLimitReached') {
      analytics.track(PROJECTLIMIT_MODAL_CLICK, {
        label: 'subscription',
      });
    } else if (DOWNLOAD_PANEL_UPGRADE_TYPES.includes(type)) {
      analytics.track(DOWNLOAD_MODAL_CLICK, {
        label: 'subscription',
      });
    } else if (type === 'uploadCustomFonts') {
      analytics.track(UPLOAD_FONT_MODAL_CLICK, {
        label: 'subscription',
      });
    }
    // track with new event
    analytics.track(UPGRADE_MODAL_CLICK, { label: type });

    window.open(getSubscriptionPageUrl(), '_blank', 'noreferrer');
  };

  const initFontStore = useCommonFontsStore(commonFontsStoreSelector.init);

  const uploadError = useUploadStore(uploadStoreSelector.error);
  const shareUrl = useMenuStore(menuStoreSelector.shareUrl);
  const setShareUrl = useMenuStore(menuStoreSelector.setShareUrl);
  const updateTemplate = useMenuStore(menuStoreSelector.updateTemplateId);
  const submitModalDesign = useMenuStore(menuStoreSelector.submitDesign);
  const triggerInviteFriendsPopup = usePromotionStore(
    promotionSelector.triggerInviteFriendsPopup
  );
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [showExitUpdateModal, setShowExitUpdateModal] = useState(false);
  const [showArtboardSizeModal, setShowArtboardSizeModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const step = useOnboardingStore(onboardingStoreSelector.step);
  const subStep = useOnboardingStore(onboardingStoreSelector.subStep);
  const displayStep = getDisplayStep(step, subStep);

  const user = useUserStore(userStoreSelector.user);
  const initializeUser = useUserStore(userStoreSelector.initialize);
  const updateUserInfo = useUserStore(userStoreSelector.updateUserInfo);
  const registerBeforeLoginAction = useUserStore(
    userStoreSelector.registerBeforeLoginAction
  );
  const initSettings = useSettingsStore(settingsStoreSelector.init);
  const initNotifications = useNotificationStore(
    notificationStoreSelector.init
  );
  const resetNotifications = useNotificationStore(
    notificationStoreSelector.reset
  );

  const showInviteFriends = usePromotionStore(
    promotionSelector.showInviteFriendsPopup
  );
  const closeInviteFriendsPopup = usePromotionStore(
    promotionSelector.closeInviteFriendsPopup
  );

  const tooComplexSVGModalVisible = useMenuStore(
    menuStoreSelector.tooComplexSVGModalVisible
  );
  const hideTooComplexSVGModal = useMenuStore(
    menuStoreSelector.hideTooComplexSVGModal
  );

  const initializeNotifications = useCallback(() => {
    resetNotifications();
    initNotifications();
  }, [resetNotifications, initNotifications]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const status = JSON.parse(localStorage.getItem(INVITE_FRIENDS_KEY));
      const today = new Date();
      if (!status?.lastSeenAt) {
        // show after 15 minutes for the first time
        triggerInviteFriendsPopup();
        localStorage.setItem(
          INVITE_FRIENDS_KEY,
          JSON.stringify({ count: 1, lastSeenAt: today })
        );
      } else {
        // show after one day, one week, every 4 weeks afterwards
        const differenceInDays =
          (today.getTime() - new Date(status.lastSeenAt).getTime()) /
          (1000 * 60 * 60 * 24); // 1 day

        const count = status.count;
        if (
          (count === 1 && differenceInDays >= 1) ||
          (count === 2 && differenceInDays >= 7) ||
          (count > 2 && differenceInDays >= 28)
        ) {
          triggerInviteFriendsPopup();
          localStorage.setItem(
            INVITE_FRIENDS_KEY,
            JSON.stringify({ count: count + 1, lastSeenAt: today })
          );
        }
      }
    }, 15 * 60 * 1000); // 15 minutes

    return () => {
      clearTimeout(timeout);
    };
  }, [triggerInviteFriendsPopup]);

  useEffect(() => {
    registerBeforeLoginAction(setVisitedCookie);
    registerBeforeLoginAction(initializeNotifications);
    registerBeforeLoginAction(updateBookmarks);
    initializeUser();
    initSettings();
  }, [
    initializeUser,
    registerBeforeLoginAction,
    initSettings,
    initializeNotifications,
  ]);

  useEffect(() => {
    const onFocus = () => {
      updateUserInfo();
    };

    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [updateUserInfo]);

  const initElementsStore = useElementsStore(elementStoreSelector.initialize);
  useEffect(() => {
    initElementsStore();
  }, [initElementsStore]);

  useEffect(() => {
    if (user) {
      initFontStore(user);
    }
  }, [initFontStore, user]);

  const contentState = useMemo(() => {
    const promoCode = urlParams.get(PROMO_CODE);
    if (promoCode) setPromoCode(promoCode);

    if (isDebugActive() || user) {
      return 'authorized';
    } else if (user === null && !promoCode) {
      return 'unauthorized';
    } else if (user === null && promoCode) {
      return 'promoCode';
    }
    return 'loading';
  }, [user]);

  useEffect(() => {
    const { bus, dispatch } = getDispatcher();
    useMenuStore.setState({ dispatch });

    // changes with ARTBOARD_STATE impacts history and UI
    const stateSubscription = bus
      .pipe(filter(({ key }) => key === ARTBOARD_STATE))
      .pipe(auditTime(16)) // Delay state updates by 1 per frame (for 60 FPS) 1000/60 ≈ 16
      .subscribe((obj) => {
        setAppState((previous) => {
          const objects = obj.val.objects;
          if (
            previous.activeObjects?.length === objects?.length &&
            objects.every((object) =>
              previous.activeObjects.includes(object.id)
            )
          ) {
            // only update selection on state change when the selection is the same
            // otherwise, a selectionChange event might be overwritten due to the audittime
            return {
              ...previous,
              activeObject: activeObjectConverter(objects), // this is needed to update activeObject in Styles, when state changes
              artboard: obj.val.state,
            };
          }
          return {
            ...previous,
            artboard: obj.val.state,
          };
        });
        if (obj.val.state.config?.designId) {
          localStorage.setItem(PROJECT_ID_KEY, obj.val.state.config?.designId);
        }
      });

    // changes with ARTBOARD_SELECTION only impact UI
    const selectionSubscription = bus
      .pipe(filter(({ key }) => key === ARTBOARD_SELECTION))
      .subscribe((obj) => {
        const { objects, state, selectedObjects, zoom } = obj.val;

        const activeObject = activeObjectConverter(objects);

        const allSelectedObjects =
          activeObject?.type === 'artboard'
            ? ['background', 'overlay']
            : selectedObjects?.length
            ? selectedObjects
            : [];

        setAppState(() => ({
          artboard: state,
          selectedObjects: allSelectedObjects,
          activeObject,
          activeObjects: objects.map((obj) => obj.id),
          canUnmask: isMaskGroup(objects),
          zoom: zoom.toFixed(2),
        }));
      });

    const loadingSubscription = bus
      .pipe(filter(({ key }) => key === ARTBOARD_IS_LOADING))
      .subscribe((obj) => {
        setIsLoading(obj.val);
      });

    const saveSubsctiption = bus
      .pipe(filter(({ key }) => key === DESIGN_SAVE))
      .subscribe((obj) => {
        if (obj.val.type === 'manual') {
          setManualSaves((saveNumber) => saveNumber + 1);
        }
      });

    const editTransformSubscription = bus
      .pipe(filter(({ key }) => key === ACTIVE_TRANSFORM_BUTTON))
      .subscribe(() => setJustPressedEditTransform({}));

    const openArtboardSizeModal = bus
      .pipe(filter(({ key }) => key === OPEN_ARTBOARD_SIZE_MODAL))
      .subscribe(() => setShowArtboardSizeModal(true));

    const toggleSubscriptionModal = bus
      .pipe(filter(({ key }) => key === TOGGLE_SUBSCRIPTION_MODAL))
      .subscribe(({ val }) => setShowSubscriptionModal(val));

    return () => {
      stateSubscription.unsubscribe();
      selectionSubscription.unsubscribe();
      loadingSubscription.unsubscribe();
      saveSubsctiption.unsubscribe();
      editTransformSubscription.unsubscribe();
      openArtboardSizeModal.unsubscribe();
      toggleSubscriptionModal.unsubscribe();
    };
  }, []);

  // handle the dropping of a file
  const dropHandler = (event) => {
    if (!user) return false;
    const { dispatch } = getDispatcher();
    onDropHandler(event, dispatch, fireToast);
  };

  const showSubmitModal = useMenuStore(menuStoreSelector.showSubmitModal);
  const hideSubmitModal = useMenuStore(menuStoreSelector.hideSubmitModal);
  const setUpdateTemplateId = useMenuStore(
    menuStoreSelector.setUpdateTemplateId
  );
  const setUploadError = useUploadStore(uploadStoreSelector.setError);

  const lastActiveObjectId = useRef(appState?.activeObject?.id);
  const mergeRightMenuState = useMenuStore(
    menuStoreSelector.mergeRightMenuState
  );
  const activeDesign = useDesignsStore(designsStoreSelector.activeDesign);

  useEffect(() => {
    const justSaved = currentManualSaves.current !== manualSaves;
    // this shouldn't re-fire useEffect so we're calling useMenuStore outside of render cycle
    const inEditMode = !!useMenuStore.getState().updateTemplateId;
    currentManualSaves.current = manualSaves;

    setLeftPanelState((oldState) => {
      let state = oldState.state;
      if (displayStep?.openMenu || (justSaved && !inEditMode)) {
        state = 'open';
      }
      if (displayStep?.openMenu === 'close') {
        state = 'closed';
      }

      let active;
      if (displayStep?.openMenu && displayStep.openMenu !== 'close') {
        active = { panel: displayStep.openMenu };
      } else {
        active = justSaved && !inEditMode ? { panel: 'designs' } : null;
      }

      const newState = {
        state: state,
        active: active,
      };

      return newState;
    });

    if (displayStep?.openRightMenu) {
      mergeRightMenuState({
        open: true,
        activeGeneralPanel: displayStep?.openRightMenu,
      });
    }
  }, [manualSaves, displayStep, mergeRightMenuState]);

  useEffect(() => {
    if (isMockupMode) {
      mergeRightMenuState({
        open: true,
        activeGeneralPanel: 'mockup',
      });
      return;
    }

    if (isMockupTemplateMode) {
      mergeRightMenuState({
        open: false,
      });
      return;
    }

    if (
      appState.activeObject &&
      appState.activeObject.id !== lastActiveObjectId.current
    ) {
      // change activeObjectPanel, only if a new object is selected
      // so that effects panel would not be changed on state change
      mergeRightMenuState({
        activeObjectPanel: 'styles',
        activeGeneralPanel: null,
      });
    }
    mergeRightMenuState({ open: !!appState.activeObject });
    lastActiveObjectId.current = appState?.activeObject?.id;
  }, [
    appState.activeObject,
    mergeRightMenuState,
    isMockupMode,
    isMockupTemplateMode,
  ]);

  // Handle pen input so that html inputs don't break the rest of the UI (scribble)
  useEffect(() => {
    const onPointerDown = (event) => {
      if (event.pointerType !== 'pen') {
        return; // If it's not a pen don't do anything
      }

      const activeElement = document.activeElement;
      if (activeElement) {
        const isInputType = activeElement.nodeName === 'INPUT';
        if (isInputType && activeElement !== event.target) {
          /**
           * If the active element is not the target of the event,
           * and the active element is of type <input />, then blur it.
           * This is to avoid Safari's native behavior that allows for drawing outside
           * of the input (think slider control near an input).
           */

          activeElement.blur();
        }
      }
    };

    document.addEventListener('pointerdown', onPointerDown);
    return () => {
      document.removeEventListener('pointerdown', onPointerDown);
    };
  }, []);

  useEffect(() => {
    if (
      displayStep?.name === ONBOARDING_USER_STEP.EDIT_TEXT.name &&
      displayStep?.currentSubStep === 0 &&
      appState.artboard.objects?.some((x) => x.type === 'pathText')
    ) {
      setOnboardingStepIfActive(ONBOARDING_USER_STEP.EDIT_TEXT, 0);
    }
  }, [
    appState.artboard.objects,
    displayStep?.name,
    displayStep?.currentSubStep,
  ]);

  const canvasContainer = useRef();

  if (contentState === 'loading') {
    return <Message>{'Loading ...'}</Message>;
  }
  if (contentState === 'unauthorized') {
    return <UnauthView />;
  }

  const showBanner = updateTemplate;

  let board;
  if (isMockupMode && activeDesign) {
    board = (
      <MockupBoard
        bus={getDispatcher().bus}
        dispatch={getDispatcher().dispatch}
      />
    );
  } else if (isMockupTemplateMode) {
    board = (
      <MockupTemplateBoard
        bus={getDispatcher().bus}
        dispatch={getDispatcher().dispatch}
      />
    );
  } else {
    board = (
      <Artboard
        initialProjectId={initialProjectId}
        bus={getDispatcher().bus}
        dispatch={getDispatcher().dispatch}
        canUnmask={appState.canUnmask}
        isLoading={isLoading}
        ref={canvasContainer}
      />
    );
  }

  return (
    <>
      <ContentWrapper onDrop={dropHandler} onDragOver={onDragOverHandler}>
        <Background
          isLoading={contentState === 'loading'}
          background={getBoardBackground(theme, trimView)}
        >
          {board}
        </Background>
        <AppUIOverlay>
          {updateTemplate && !isMockupMode && !isMockupTemplateMode && (
            <Banner
              label={'You are currently updating your published design. '}
              onClickLabel={'Submit Changes'}
              onClick={showSubmitModal}
              disabled={isLoading}
              onClose={() => setShowExitUpdateModal(true)}
              underline
            />
          )}
          <Header
            hasBanner={showBanner}
            options={{
              artboardOptions: appState.artboard.artboardOptions,
              config: appState.artboard.config,
            }}
            isLoading={isLoading}
            dispatch={getDispatcher().dispatch}
          />
          <BottomMenu
            dispatch={getDispatcher().dispatch}
            colorPalette={filterColorPalette(appState.artboard.colorPalette)}
            isLoading={isLoading}
          />
          <RightMenu
            marginTop={showBanner ? '94px' : '86px'}
            options={appState.activeObject}
            background={appState.artboard.artboardOptions}
            overlay={appState.artboard.overlayOptions}
            colorPalette={appState.artboard.colorPalette}
            dispatch={getDispatcher().dispatch}
            layers={layers}
            selectedLayers={appState.selectedObjects}
            activeObjects={appState.activeObjects}
            justPressedEditTransform={justPressedEditTransform}
          />
          <StateProvider
            context={leftMenuContext}
            initialState={{
              rootNode: props.rootContainer,
              canvasContainer,
              expanded: false,
            }}
          >
            <LeftMenu
              marginTop={showBanner ? '94px' : '86px'}
              state={leftPanelState.state}
              active={leftPanelState.active}
              artboardState={isDebugActive() ? appState.artboard : null}
              dispatch={getDispatcher().dispatch}
              isLoading={isLoading}
            />
          </StateProvider>
        </AppUIOverlay>
      </ContentWrapper>
      <Authentication />
      <OnboardingManager
        isLoading={isLoading}
        dispatch={getDispatcher().dispatch}
        onOpenDesignsPanel={handleOpenDesignsPanel}
      />
      <UpgradeModal
        imageComponentType={'brands'}
        onUpgrade={handleOnUpgrade}
        projectCount={user?.designsCount ?? 0}
        projectCountLimit={user?.designsLimit ?? 0}
        {...upgradeModalProps}
      />
      <DecisionModal
        isOpen={!!uploadError}
        onClose={() => setUploadError(null)}
        firstButton={{
          label: 'Got it!',
          type: 'primary',
          onClick: () => setUploadError(null),
        }}
        title={uploadError?.title}
        message={uploadError?.message}
        note={uploadError?.note}
      />
      <DecisionModal
        isOpen={!!shareUrl}
        onClose={() => setShareUrl(null)}
        firstButton={{
          label: 'Open Design',
          type: 'primary',
          onClick: () => {
            window.open(shareUrl, '_blank', 'noopener');
            setShareUrl(null);
          },
        }}
        title={'Your design is ready to share'}
      />
      <DecisionModal
        isOpen={tooComplexSVGModalVisible}
        title={'SVG is Too Complex'}
        message={"Kittl can't yet handle such complex SVGs."}
        note={'The maximum allowed size is 3MB'}
        onClose={hideTooComplexSVGModal}
        firstButton={{
          label: 'Got it',
          type: 'primary',
          onClick: hideTooComplexSVGModal,
        }}
      />
      <PromoCodeModal
        isOpen={contentState === 'promoCode'}
        promoCode={promoCode}
      />
      {showSubscriptionModal && (
        <SubscriptionModal dispatch={getDispatcher().dispatch} />
      )}
      {showInviteFriends && (
        <Popover
          open={true}
          placement={'top'}
          align={'end'}
          showHeader={false}
          hideTip
          theme={{
            padding: '0px',
          }}
          content={
            <InviteFriendsPopup
              plan={user?.plan}
              onClick={() => {
                analytics.track(POPUP_LEARN_MORE_CLICK, {
                  label: user?.plan || 'FREE',
                });
                window.open(
                  buildWebsiteUrl('/user/settings/referral'),
                  '_blank'
                );
                localStorage.setItem(
                  INVITE_FRIENDS_KEY,
                  JSON.stringify({ show: false })
                );
              }}
              onClose={closeInviteFriendsPopup}
            />
          }
          width={'676px'}
        >
          <Anchor style={{ bottom: '5px', right: '15px' }} />
        </Popover>
      )}
      <TemplateSubmitModal
        design={submitModalDesign}
        onClose={hideSubmitModal}
        onSubmit={() => {
          hideSubmitModal();
          setUpdateTemplateId(null);
          setShowThanksModal(true);
        }}
        dispatch={getDispatcher().dispatch}
      />
      <DecisionModal
        isOpen={showThanksModal}
        title={'Congrats!'}
        message={
          'Your design has been published. You can manage your published designs in your profile.'
        }
        onClose={() => setShowThanksModal(false)}
        firstButton={{
          label: 'Open Profile',
          onClick: () => {
            window.location.href = buildWebsiteUrl('/user/dashboard/published');
          },
        }}
        secondButton={{
          label: 'Continue',
          onClick: () => setShowThanksModal(false),
        }}
      />
      <DecisionModal
        isOpen={showExitUpdateModal}
        title={'Exit update mode'}
        message={
          'Are you sure you want to exit the update mode and discard all changes?'
        }
        onClose={() => setShowExitUpdateModal(false)}
        firstButton={{
          label: 'Discard',
          onClick: () => setUpdateTemplateId(null),
        }}
        secondButton={{
          label: 'Continue',
          onClick: () => setShowExitUpdateModal(false),
        }}
      />
      <ArtboardSizeModal
        isOpen={showArtboardSizeModal}
        onClose={() => setShowArtboardSizeModal(false)}
        dispatch={getDispatcher().dispatch}
      />
    </>
  );
}

AppContent.propTypes = {
  //Ref to root app container element
  rootContainer: PropTypes.any,
};

export default AppContent;
