import React from 'react';

import { userStoreSelector, useUserStore } from '../../stores/userStore';
import { SubscriptionPlan, User } from '../../types';
import Button from '../Button/Button/Button';
import { primarySmallTheme } from '../Button/Button/theme';
import Icon from '../Icon/Icon';
import Spacer from '../Spacer/Spacer';
import { FlexRow } from '../utilities/styles';
import { H5, P2, P4 } from '../utilities/Typography/styles';
import { CreditsWrapper, Wrapper } from './styles';
import { UserCreditProps } from './types';

const getUserCredits = (user: User): number =>
  user.generationCredits + user.recurringGenerationCredits;

const getCreditsAmountLabel = (user: User | null | false): string => {
  if (!user) return '0';
  let total = '';
  if (user.monthlyRecurringGenerationCreditAmount) {
    total = `/${user.monthlyRecurringGenerationCreditAmount}`;
  }
  return `${getUserCredits(user)}${total}`;
};

const getCreditsLabel = (user: User | null | false): string => {
  if (!user || user.monthlyRecurringGenerationCreditAmount) return 'Credits';
  if (getUserCredits(user) === 1) return 'Credit';
  return 'Credits';
};

const UserCredits: React.FC<UserCreditProps> = ({ onUpgrade }) => {
  const pro = useUserStore(userStoreSelector.proAccess);
  const user = useUserStore(userStoreSelector.user);
  const userPlan = useUserStore(userStoreSelector.plan);
  const userHasCredits = useUserStore(userStoreSelector.hasCredits);

  return (
    <Wrapper>
      <CreditsWrapper>
        <FlexRow alignItems="center">
          {!userHasCredits && userPlan !== SubscriptionPlan.EXPERT && (
            <>
              <Icon name="premiumBadge" height="20px" />
              <Spacer w="6px" />
            </>
          )}
          <P2>{getCreditsAmountLabel(user)}</P2>
          <Spacer w="4px" />
          <H5>
            <b>{getCreditsLabel(user)}</b>
          </H5>
        </FlexRow>
        {!userHasCredits && (
          <Button
            label="Upgrade Plan"
            height="30px"
            width="118px"
            theme={primarySmallTheme}
            onClick={onUpgrade}
          />
        )}
      </CreditsWrapper>
      {!userHasCredits && (
        <P4>
          {!pro && `Upgrade now to use Kittl AI.`}
          {pro &&
            !userHasCredits &&
            `You have reached your monthly credit limit. Upgrade now for more credits to use Kittl AI.`}
        </P4>
      )}
    </Wrapper>
  );
};

export default UserCredits;
