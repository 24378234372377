const dataUrltoBlob = (dataUrl: string): Blob => {
  // structure of data URLs:
  // data:[<mediatype>][;base64],<data>
  const dataArray = dataUrl.split(',');
  const mime = dataArray[0].match(/:(.*?);/)![1];
  const binaryString = atob(dataArray[1]);
  let binaryStringLength = binaryString.length;
  const u8Array = new Uint8Array(binaryStringLength);

  while (binaryStringLength--) {
    u8Array[binaryStringLength] = binaryString.charCodeAt(binaryStringLength);
  }

  return new Blob([u8Array], { type: mime });
};

export const createFileFromDataUrl = (
  dataUrl: string,
  fileName: string
): File => createFileFromBlob(dataUrltoBlob(dataUrl), fileName);

export const createFileFromBlob = (blob: Blob, fileName: string): File =>
  new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  });
