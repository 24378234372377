import styled from 'styled-components';

import { themeStyle } from '../../../services/theming';
import { QUERIES } from '../../utilities/queries';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 32px;

  ${QUERIES.largeTabletAndUp} {
    padding: 48px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: auto 1px auto;
  grid-column-gap: 20px;
  margin-top: 18px;
  padding: 0 12px;

  ${QUERIES.largeTabletAndUp} {
    grid-column-gap: 50px;
    margin-top: 24px;
    padding: 0 24px;
  }
`;

export const Table = styled.table`
  font-size: ${themeStyle.fontSize14};
  line-height: 17px;
  color: ${themeStyle.varInkMain};
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${themeStyle.varInkSuperLight};
`;

export const Combination = styled.th`
  padding: 8px 12px 8px 0;
  font-weight: ${themeStyle.fontMedium};
  text-align: left;

  ${QUERIES.largeTabletAndUp} {
    padding: 12px 18px 12px 0;
  }
`;

export const Description = styled.td`
  padding: 8px 0;

  ${QUERIES.largeTabletAndUp} {
    padding: 12px 0;
  }
`;
