import { ReactComponent as AngleTransformIcon } from './assets/angle.svg';
import { ReactComponent as ArchTransformIcon } from './assets/arch.svg';
import { ReactComponent as CircleTransformIcon } from './assets/circle.svg';
import { ReactComponent as FreeLineTransformIcon } from './assets/freeLine.svg';
import { ReactComponent as FreeFormTransformIcon } from './assets/freeForm.svg';
import { ReactComponent as FlagTransformIcon } from './assets/flag.svg';
import { ReactComponent as RiseTransformIcon } from './assets/rise.svg';
import { ReactComponent as WaveTransformIcon } from './assets/wave.svg';
import { themeStyle } from '../../../services/theming';

export const config = [
  { id: 'freeLine', icon: FreeLineTransformIcon, label: 'Custom' },
  { id: 'freeForm', icon: FreeFormTransformIcon, label: 'Free Form' },
  { id: 'circle', icon: CircleTransformIcon, label: 'Circle' },
  { id: 'angle', icon: AngleTransformIcon, label: 'Angle', hasCurve: true },
  { id: 'arch', icon: ArchTransformIcon, label: 'Arch', hasCurve: true },
  { id: 'rise', icon: RiseTransformIcon, label: 'Rise', hasCurve: true },
  { id: 'wave', icon: WaveTransformIcon, label: 'Wave', hasCurve: true },
  { id: 'flag', icon: FlagTransformIcon, label: 'Flag', hasCurve: true },
];

export const defaultIconMapping = {
  primary: {
    light: [themeStyle.inkMedium, themeStyle.inkMain],
    dark: [themeStyle.darkInkMedium, themeStyle.darkBrandLight],
  },
};
