import { useCallback, useRef } from 'react';

/* 
  Returns a function based on callback that is only allowed to be executed 1 time every {delay} milliseconds 
  if skipLeading is provided, leading calls are not triggered immediately 
*/
function useThrottled({ callback, delay, skipLeading }) {
  const timeout = useRef();
  const lastExec = useRef(0); // last time callback was executed
  const firstCall = useRef(0); // first time throttled function was called, note that it's not getting updated unless {delay} ms passed since last firstCall (only used for skipLeading)

  const throttled = useCallback(
    (...args) => {
      const now = Date.now();
      const execDiff = lastExec.current + delay - now;

      const createTimeout = (ms) => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          lastExec.current = Date.now();
          if (callback) callback(...args);
        }, ms);
      };

      if (execDiff < 0) {
        // more than {delay} ms passed since last function execution
        if (skipLeading) {
          // if `skipLeading` is provided, initial execution is delayed
          // if function was first called less than {delay} ms ago, execute it after {first call time + delay - now}
          let callAfter = firstCall.current + delay - now;
          if (callAfter < 0) {
            // update first call time
            firstCall.current = now;
            // if function was called more than {delay} ms ago, execute it after {delay}
            callAfter = delay;
          }
          createTimeout(callAfter);
          return;
        }
        // execute function immediately
        if (callback) callback(...args);
        lastExec.current = now;
      } else {
        // less than {delay} ms passed since last function execution
        // delay function execution by {last execution time + delay - now}
        createTimeout(execDiff);
      }
    },
    [callback, delay, skipLeading]
  );

  return throttled;
}

export default useThrottled;
