import React from 'react';
import PropTypes from 'prop-types';

import { themeStyle } from '../../../../services/theming';
import Button from '../../../Button/Button/Button';
import { primaryTheme } from '../../../Button/Button/theme';
import Modal from '../../../Modal/Modal';
import Spacer from '../../../Spacer/Spacer';
import { H3, P2, P4 } from '../../../utilities/Typography/styles';
import { Wrapper } from './styles';

/**
 * Warning show to the user before uploading fonts
 */
const CustomFontsWarning = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Wrapper>
        <H3>Font Upload</H3>
        <Spacer h="12px" />
        <P2>
          We cannot guarantee the full usability of text features with custom
          fonts.
        </P2>
        <Spacer h="12px" />
        <P2>
          We recommend uploading all styles of a font family at once. It is not
          possible to add more files to a font family or change single styles
          later on. But of course you can always restart the whole upload
          process.
        </P2>
        <Spacer h="24px" />
        <Button
          theme={primaryTheme}
          label="Choose Fonts"
          onClick={() => props.onConfirm()}
          width="130px"
        />
        <Spacer h="6px" />
        <P4 color={themeStyle.varInkMedium}>
          Supported file types are .ttf or .otf
        </P4>
      </Wrapper>
    </Modal>
  );
};

CustomFontsWarning.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCloseButton: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default CustomFontsWarning;
