import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Masonry from '../Masonry/Masonry';
import { Background, Label } from './styles';
import usePhotosStore from '../../stores/photosStore';
import Element from './Element/Element';
import VerticallyScrollable from '../VerticallyScrollable/VerticallyScrollable';

const PhotoElements = (props) => {
  const { dispatch, scrollToTop } = props;

  const elements = usePhotosStore((state) => state.elements);
  const fetch = usePhotosStore((state) => state.fetch);

  const renderElement = useCallback(
    (props) => {
      return <Element element={props} dispatch={dispatch} dimmed />;
    },
    [dispatch]
  );

  return (
    <Background>
      <Label>{props.label}</Label>
      <VerticallyScrollable>
        <Masonry
          minColumnWidth={128}
          gap={10}
          items={elements}
          fetch={fetch}
          render={renderElement}
          transitioning={props.transitioning}
          scrollToTop={scrollToTop}
        />
      </VerticallyScrollable>
    </Background>
  );
};

PhotoElements.propTypes = {
  dispatch: PropTypes.func,
  scrollToTop: PropTypes.number,
  transitioning: PropTypes.bool,
  label: PropTypes.string,
};

export default PhotoElements;
