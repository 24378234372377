import styled from 'styled-components';

import { themeStyle } from '../../services/theming';
import { activeStyle, IconStyle } from '../Icon/styles';
import { CustomStyles } from '../SelectNew/types';
import { IconTheme } from './types';

export const SelectControllerWrapper = styled.div<{
  dropdownExpanded: boolean;
  disabled: boolean;
  noExpandIcon: boolean;
  small: boolean;
  alert: boolean;
  customStyles?: CustomStyles;
  theme: IconTheme; // only used when targeting icon on hover
}>`
  box-sizing: border-box;
  width: ${({ customStyles }) => customStyles?.width ?? '100%'};
  height: ${({ customStyles, small }) =>
    customStyles?.height ?? (small ? '30px' : '40px')};
  background: ${({ customStyles }) =>
    customStyles?.background ?? themeStyle.varBackgroundAlt};
  border-radius: ${({ customStyles }) =>
    customStyles?.borderRadius ?? themeStyle.radiusMedium};
  border: ${({ customStyles, alert }) =>
    alert
      ? `1px solid ${themeStyle.varAlertRed}`
      : customStyles?.noBorder
      ? 'none'
      : `1px solid ${themeStyle.varInkLight}`};
  padding-left: ${({ customStyles }) =>
    customStyles?.paddingLeft ? customStyles.paddingLeft : '12px'};
  padding-right: ${({ noExpandIcon }) => (noExpandIcon ? '12px' : 0)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: ${({ small }) =>
    small ? themeStyle.fontSize12 : themeStyle.fontSize14};
  font-weight: ${({ customStyles }) =>
    customStyles?.fontWeight ?? themeStyle.fontMedium};
  color: ${({ disabled, dropdownExpanded, alert, customStyles }) =>
    disabled
      ? themeStyle.varInkLight
      : alert
      ? themeStyle.varAlertRed
      : dropdownExpanded
      ? themeStyle.varInkMain
      : customStyles?.textColor ?? themeStyle.varInkMedium};
  text-decoration: ${({ customStyles }) =>
    customStyles?.textDecoration ?? 'unset'};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all ${themeStyle.transitionDuration};

  ${({ disabled, alert }) =>
    !disabled &&
    !alert &&
    `:hover {
    color: ${themeStyle.varInkMain};
    }`}

  :hover {
    ${IconStyle} {
      ${activeStyle}
    }
  }
`;

export const Label = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const AlertIcon = styled.div`
  margin-right: -5px;
`;

export const ExpandIcon = styled.div<{
  small: boolean;
  dropdownExpanded: boolean;
  customStyles?: CustomStyles;
}>`
  width: ${({ small, customStyles }) =>
    customStyles?.expandIconWidth ?? small ? '24px' : '36px'};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ dropdownExpanded }) =>
    dropdownExpanded
      ? 'translate(1px, 0) rotate(0deg)'
      : 'translate(0, 1px) rotate(180deg)'};
  transition-property: transform;
  transition-duration: ${themeStyle.transitionDuration};
`;
