import { UNSPLASH_PHOTO_WIDTH } from '../components/PhotoElements/Element/Element';
import { isOnTestEnvironment } from '../global/environment';
import { isIpadOperatingSystem } from './detection';

interface ImageUrlOptions {
  /**
   * SEO optimized object name to use instead of object name if possible
   */
  seoObjectName?: string | null;
  /**
   * target width of the image
   */
  width?: number;
  /**
   * target height of the image
   */
  height?: number;
  /**
   * scale applied to width and height
   */
  scale?: number;
  /**
   * target format of the image (example: png, jpg, webp)
   */
  format?: string;
  /**
   * whether the blank space in SVGs should be removed (used for custom uploads)
   */
  trimSVG?: boolean;
  /**
   * whether to keep the original file format
   */
  keepFormat?: boolean;
  /**
   * special use case (changes svg -> webp, or keeps original format) ignores `format` and `keepFormat` options
   */
  isUpload?: boolean;
}

// URL of CDN that supports SEO optimized image names
export const SEO_CONTENT_URL =
  process.env.REACT_APP_SEO_CONTENT_URL ||
  process.env.STORYBOOK_SEO_CONTENT_URL ||
  process.env.NEXT_PUBLIC_SEO_CONTENT_URL;

// URL of CDN that's in front of image proxy
export const CONTENT_URL =
  process.env.REACT_APP_CONTENT_URL ||
  process.env.STORYBOOK_CONTENT_URL ||
  process.env.NEXT_PUBLIC_CONTENT_URL;

// URL of CMS object storage
export const CMS_STORAGE_URL =
  process.env.REACT_APP_CMS_IMAGE_URL ||
  process.env.STORYBOOK_CMS_IMAGE_URL ||
  process.env.NEXT_PUBLIC_CMS_IMAGE_URL;

export const API_STORAGE_URL = process.env.REACT_APP_API_STORAGE_URL;

// URL of Website
export const WEBSITE_URL =
  process.env.REACT_APP_WEBSITE_URL ||
  process.env.NEXT_PUBLIC_WEBSITE_URL ||
  '';

// URL of background removal service
export const REMBG_URL =
  process.env.REACT_APP_REMBG_URL || process.env.NEXT_PUBLIC_REMBG_URL;

// Tests can't use webp
export const OUTPUT_FORMAT = isOnTestEnvironment() ? 'png' : 'webp';
const FALLBACK_OUTPUT_FORMAT = 'png';
const PROXY_WIDTH = 256;
const PROXY_HEIGHT = 256;
const PROXY_SCALE = 1;
const SAMPLE_WEBP =
  'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
const UNSPLASH_DOMAIN = 'https://images.unsplash.com';

let webpTested = false;
let webpSupported = false;
/**
 * Check if WebP is supported
 * There is no reliable way to do that synchronously, so this function is async
 * @returns {Promise<Boolean>}
 */
export const testWebPSupport = (): Promise<boolean> => {
  return new Promise((resolve) => {
    if (webpTested) return resolve(webpSupported);
    const img = new Image();
    img.src = SAMPLE_WEBP;
    img.onload = img.onerror = (): void => {
      webpSupported = img.height === 1;
      webpTested = true;
      resolve(webpSupported);
    };
  });
};

/**
 * check whether an objectName is coming from unsplash
 */
export const isUnsplashPhoto = (objectName: string): boolean =>
  objectName.startsWith(UNSPLASH_DOMAIN);

export const isUserUpload = (objectName: string): boolean =>
  objectName.startsWith('api/users/');

/**
 * Build a url for CMS image proxy
 * @param {String} objectName - this is generally an object name from s3 like storage
 * @param {ImageUrlOptions} options - configure the output dimensions and format
 * @returns {String}
 */
export const buildImageProxyUrl = (
  objectName: string,
  options: ImageUrlOptions = {}
): string => {
  const width =
    typeof options.width !== 'undefined' ? options.width : PROXY_WIDTH;
  const height =
    typeof options.height !== 'undefined' ? options.height : PROXY_HEIGHT;
  const scale = options.scale || PROXY_SCALE;
  const defaultFormat = webpSupported ? OUTPUT_FORMAT : FALLBACK_OUTPUT_FORMAT;
  let keepFormat = options.keepFormat;

  // handle trimming
  const isSVG = objectName.endsWith('.svg');
  const trim = options.trimSVG && isSVG ? '/trim:0:FF00FF' : '';

  // handle output format
  let targetFormat = options.format || defaultFormat;
  if (options.isUpload) {
    if (isSVG) {
      targetFormat = defaultFormat;
      keepFormat = false;
    } else {
      keepFormat = true;
    }
  }

  const format = keepFormat ? '' : `@${targetFormat}`;

  if (!isUnsplashPhoto(objectName)) {
    return buildCdnImageUrl({
      objectName,
      seoObjectName: options.seoObjectName,
      width,
      height,
      scale,
      trim,
      format,
    });
  }

  // add `&raw_url=true` to always use the same urls
  if (!objectName.endsWith('&raw_url=true')) {
    objectName = objectName + '&raw_url=true';
  }

  if (options.height) {
    return (
      objectName + `&w=${width * scale}&h=${height * scale}&fit=fill&fill=blur`
    );
  }

  return objectName + `&w=${width * scale}&fit=fill`;
};

const buildCdnImageUrl = ({
  objectName,
  seoObjectName,
  width,
  height,
  scale,
  trim,
  format,
}: {
  objectName: string;
  seoObjectName?: string | null;
  width: number;
  height: number;
  scale: number;
  trim: string;
  format: string;
}): string => {
  const name = seoObjectName && SEO_CONTENT_URL ? seoObjectName : objectName;
  const base = seoObjectName && SEO_CONTENT_URL ? SEO_CONTENT_URL : CONTENT_URL;

  /**
   * For iPad we restrict sizes to max canvas size.
   * This is to allow things like e.g filtering to work properly
   */
  if (
    isIpadOperatingSystem() &&
    (width * scale > 2048 || height * scale > 2048)
  ) {
    width = height = 2048;
    scale = 1;
  }

  return `${base}/pr:sharp/rs:fit:${width * scale}:${
    height * scale
  }:0${trim}/plain/${name}${format}`;
};

/**
 * build the url for a profile image
 */
export const buildProfileImageUrl = (
  profileImage?: string | null,
  options?: ImageUrlOptions
): string | null => {
  if (!profileImage) return null;
  return buildImageProxyUrl(`api/${profileImage}`, options);
};

/**
 * builds an absolute URL from the given objectName
 * @param {string} objectName
 */
export const buildElementUrl = (objectName: string): string => {
  if (!objectName.startsWith(UNSPLASH_DOMAIN)) {
    return `${CONTENT_URL}/pr:sharp/plain/${objectName}`;
  }

  // We're asking for a Unsplash URL, objectName should be just the url

  // But for iPad we restrict dimensions
  if (isIpadOperatingSystem()) {
    return `${objectName}&w=${2048}&h=${2048}&fit=clip`;
  }

  return objectName;
};

/**
 * Builds source and preview using objectName
 * @param {string} objectName
 */
export const buildSources = (
  objectName: string
): { src: string; previewSrc: string } => {
  const src = buildElementUrl(objectName);
  const isUpload = isUserUpload(objectName);

  // it is important to get the same src for the preview as the src used to display the element in the UI
  const previewSrc = buildImageProxyUrl(objectName, {
    trimSVG: isUpload,
    width: isUnsplashPhoto(objectName) ? UNSPLASH_PHOTO_WIDTH : undefined,
  });

  return { src, previewSrc };
};

/**
 * build the url to a page on the website
 */
export const buildWebsiteUrl = (url: string): string => {
  return `${WEBSITE_URL}${url}`;
};

/**
 * get the url to the subscription page
 */
export const getSubscriptionPageUrl = (): string => {
  return buildWebsiteUrl('/pricing');
};

type RemBGModel =
  | 'u2net'
  | 'u2netp'
  | 'u2net_human_seg'
  | 'u2net_cloth_seg'
  | 'silueta';

type RemBGParams = {
  model?: RemBGModel;
  om?: boolean;
  ppm?: boolean;
};

const DEFAULT_REMBG_PARAMS: RemBGParams = {
  model: 'u2net',
  om: false,
  ppm: false,
};

/**
 * Get the url to service that removes backgrounds
 * NOTE: params a, af, ab and ae are not used.
 * @param {string} url - url of the image to remove the background from
 * @param {boolean} params.om - only mask, we never want this as it's not really usable in the editor
 * @param {boolean} params.ppm - post process mask - usually just crops the mask, removing the blurry parts
 * @param {RemBGModel} params.model - model to use
 * @returns {string} - the resulting url
 */
export const getRemBGUrl = (
  url: string,
  params: RemBGParams = DEFAULT_REMBG_PARAMS
): string => {
  const { model, om, ppm } = { ...DEFAULT_REMBG_PARAMS, ...params };

  return `${REMBG_URL}/?url=${encodeURIComponent(
    url
  )}&model=${model}&a=false&af=240&ab=10&ae=10&om=${om}&ppm=${ppm}`;
};
