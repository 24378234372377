import { Option } from '../../MultiOption/types';

export const options: Record<string, Option> = {
  Profile: {
    label: 'Profile',
    icon: 'profile',
    url: '/user/dashboard',
  },
  AccountSettings: {
    label: 'Account Settings',
    icon: 'settings',
    url: '/user/settings/account',
  },
  Subscription: {
    label: 'Subscription',
    icon: 'star',
    url: '/user/settings/subscription',
  },
  Referral: {
    label: 'Invite Friends',
    icon: 'smiley',
    url: '/user/settings/referral',
  },
};
