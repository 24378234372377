import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { FlexContainer } from '../utilities/styles';
import { P4 } from '../utilities/Typography/styles';

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ArtboardSettingsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

export const ArtboardLayoutText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
`;

export const IconContainer = styled.div`
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SliderContainer = styled.div`
  height: ${({ show }) => (show && '49px') || '0'};
  display: flex;
  align-items: flex-start;
  transition-property: height;
  transition-duration: 0.25s;
  overflow: hidden;
  padding: 0 12px 0 12px;
`;

export const Orientation = styled.div`
  display: flex;
  align-items: center;
`;

export const OrientationOption = styled.div`
  border-radius: 5px;
  background: ${({ selected }) =>
    (selected && themeStyle.varBrandSuperLight) || themeStyle.varInkSuperLight};
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    ${P4} {
      color: ${themeStyle.primaryBlack};
    }
  }
`;

export const CustomSizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  gap: 8px;

  ${({ disablePointerEvents }) =>
    disablePointerEvents
      ? `
    cursor: pointer;
    * {
      cursor: pointer;
      pointer-events: none;
    }
  `
      : ''};
`;

export const CustomSizeSettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Section = styled(FlexContainer)`
  width: 100%;
  box-sizing: border-box;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  padding: 0 12px 0 12px;
  border-bottom: ${({ last }) =>
    last ? 'unset' : `1px solid ${themeStyle.varInkSuperLight}`};
`;
