import {
  getCenterFromOptions,
  lockPointToAxis,
} from '../../../utils/editor/misc';
import { isOnDevEnvironment } from '../../../global/environment';

export default function (fabric) {
  // allow re-initialize on dev environment to prevent issues with fast refresh
  if (fabric.EditablePolygon && !isOnDevEnvironment()) {
    fabric.warn('fabric.EditablePolygon is already defined');
    return;
  }
  /**
   * EditablePolygon class
   * This class handles EditablePolygons
   * @class fabric.EditablePolygon
   * @extends fabric.Polygon
   */
  fabric.EditablePolygon = fabric.util.createClass(fabric.Polygon, {
    type: 'editablePolygon',

    initialize: function (points, options) {
      options || (options = {});
      points ||
        (points = [
          {
            x: -100,
            y: 0,
          },
          {
            x: -50,
            y: 86.603,
          },
          {
            x: 50,
            y: 86.603,
          },
          {
            x: 100,
            y: 0,
          },
          {
            x: 50,
            y: -86.603,
          },
          {
            x: -50,
            y: -86.603,
          },
        ]);
      this.callSuper('initialize', points, options);

      const { centerX, centerY } = getCenterFromOptions(options);

      this.strokeWidth = options.strokeWidth || 0;
      this.objectCaching = false;
      if (options.canvas?.artboard) {
        if (options.wasDropped) {
          const zoom = options.canvas.getZoom();
          this.scaleToWidth(options.imgWidth);
          this.top = options.top - options.distanceToTopCorner.y / zoom;
          this.left = options.left - options.distanceToTopCorner.x / zoom;
        } else {
          const artboard = options.canvas.artboard;
          const minSide = Math.min(artboard.width, artboard.height);
          this.scaleToWidth(minSide * fabric.NEW_OBJECT_SCALE, true);
          this.top = centerY - (this.height * this.scaleY) / 2;
          this.left = centerX - (this.width * this.scaleX) / 2;
        }
      }
      this.lastPoints = [...points];
    },

    toObject: function () {
      return this.callSuper('toObject');
    },

    toggleEdit() {
      // clone what are you copying since you
      // may want copy and paste on different moment.
      // and you do not want the changes happened
      // later to reflect on the copy.
      this.edit = !this.edit;
      if (this.edit) {
        const lastControl = this.points.length - 1;
        this.controls = this.points.reduce((acc, point, index) => {
          acc['p' + index] = new fabric.Control({
            positionHandler: this._polygonPositionHandler,
            actionHandler: this._anchorWrapper(
              index > 0 ? index - 1 : lastControl,
              this._actionHandler
            ),
            actionName: 'modifyPolygon',
            mouseDownHandler: function () {
              this.lastPoints = [...this.points];
            }.bind(this),
            pointIndex: index,
          });
          return acc;
        }, {});
      } else {
        // reset to normal controls
        this.controls = fabric.Object.prototype.controls;
      }
      this.hasBorders = !this.edit;
    },

    // define a function that will define what the control does
    // this function will be called on every mouse move after a control has been
    // clicked and is being dragged.
    // The function receive as arguments the mouse event, the current transform object
    // and the current position in canvas coordinates
    // transform.target is a reference to the current object being transformed
    _actionHandler: function (eventData, transform, x, y) {
      const polygon = transform.target;
      const currentControl = polygon.controls[polygon.__corner];

      const mouseLocalPosition = polygon.toLocalPoint(
        new fabric.Point(x, y),
        'center',
        'center'
      );

      const polygonBaseSize = polygon._getNonTransformedDimensions();
      const size = polygon._getTransformedDimensions(0, 0);
      let finalPointPosition = {
        x:
          (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
          polygon.pathOffset.x,
        y:
          (mouseLocalPosition.y * polygonBaseSize.y) / size.y +
          polygon.pathOffset.y,
      };

      const lastPoints = polygon.lastPoints;
      const lastPosition = lastPoints[currentControl.pointIndex];

      if (eventData.shiftKey) {
        finalPointPosition = lockPointToAxis(finalPointPosition, lastPosition);
      }

      polygon.points[currentControl.pointIndex] = finalPointPosition;
      return true;
    },

    // define a function that can locate the controls.
    // this function will be used both for drawing and for interaction.
    _polygonPositionHandler: function (dim, finalMatrix, fabricObject) {
      const x =
        fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x;
      const y =
        fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
      return fabric.util.transformPoint(
        { x, y },
        fabric.util.multiplyTransformMatrices(
          fabricObject.canvas.viewportTransform,
          fabricObject.calcTransformMatrix()
        )
      );
    },

    // define a function that can keep the polygon in the same position when we change its
    // width/height/top/left.
    _anchorWrapper: function (anchorIndex, fn) {
      return function (eventData, transform, x, y) {
        const fabricObject = transform.target;
        const absolutePoint = fabric.util.transformPoint(
          {
            x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
            y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
          },
          fabricObject.calcTransformMatrix()
        );
        const actionPerformed = fn(eventData, transform, x, y);
        fabricObject._setPositionDimensions({});
        const polygonBaseSize = fabricObject._getTransformedDimensions();
        const newX =
          ((fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) /
            polygonBaseSize.x) *
          fabricObject.scaleX;
        const newY =
          ((fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) /
            polygonBaseSize.y) *
          fabricObject.scaleY;
        fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
        return actionPerformed;
      };
    },

    // ignore stroke for bounding box
    _finalizeDimensions: (x, y) => ({ x, y }),
  });

  fabric.EditablePolygon.fromObject = function (object, callback) {
    return fabric.Object._fromObject(
      'EditablePolygon',
      object,
      callback,
      'points'
    );
  };
}
