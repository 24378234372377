import { themeStyle } from '../../services/theming';
import Button from '../Button/Button/Button';
import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { FlexColumn, FlexRow } from '../utilities/styles';
import { H2, H5, P2, P3 } from '../utilities/Typography/styles';
import { content, getBackground, imageComponents } from './config';
import {
  ContentWrapper,
  ImageComponentWrapper,
  ImageWrapper,
  Wrapper,
} from './styles';
import useWindowSize from '../../hooks/useWindowSize';
import Spacer from '../Spacer/Spacer';
import { UpgradeModalBuilderProps, UpgradeModalProps } from './types';

const UpgradeModalBuilder: React.FC<UpgradeModalBuilderProps> = (props) => {
  const {
    title,
    description,
    items,
    background,
    imageComponent,
    onUpgrade,
    isMobile,
  } = props;

  return (
    <Wrapper>
      <ContentWrapper>
        <FlexColumn>
          <FlexRow alignItems="center">
            <Icon name="premiumBadge" height="20px" />
            <Spacer w="8px" />
            <H5>Upgrade Plan</H5>
          </FlexRow>
          <Spacer h="24px" />
          <H2>{title}</H2>
          <Spacer h="12px" />
          <P2 dangerouslySetInnerHTML={{ __html: description ?? '' }} />
        </FlexColumn>
        <Spacer h="32px" />
        <H5>Here is what's included:</H5>
        <Spacer h="24px" />
        <FlexColumn alignItems="center" gap="24px">
          {items?.map((item, index) => (
            <FlexRow
              key={`item-${index}`}
              alignItems="flex-start"
              gap="12px"
              minWidth="100%"
            >
              <Icon
                name="pricingCheck"
                height="22px"
                theme={{ color: themeStyle.brandMedium }}
              />
              {item}
            </FlexRow>
          ))}
        </FlexColumn>
        <Spacer h="75px" />
        <FlexColumn marginTop="auto" width="100%" alignItems="center">
          <Button
            label="Upgrade Now"
            width="100%"
            height="40px"
            onClick={onUpgrade}
          />
          <Spacer h="8px" />
          <P3>You can cancel any time.</P3>
        </FlexColumn>
      </ContentWrapper>
      {!isMobile && (
        <ImageWrapper background={background}>
          <ImageComponentWrapper>{imageComponent}</ImageComponentWrapper>
        </ImageWrapper>
      )}
    </Wrapper>
  );
};

const UpgradeModal: React.FC<UpgradeModalProps> = (props) => {
  const {
    contentType,
    imageComponentType,
    isOpen,
    onClose,
    onUpgrade,
    projectCount,
    projectCountLimit,
    designPreview,
  } = props;
  let title, items, description;
  if (content[contentType]) {
    title = content[contentType].title;
    items = content[contentType].items;

    description = content[contentType].description;
    if (typeof description === 'function') {
      description = description(projectCount, projectCountLimit);
    }
  }

  const background = getBackground(imageComponentType);
  const ImageComponent = imageComponents[imageComponentType];

  const { isMobile } = useWindowSize();
  const modalWidth = isMobile ? 'unset' : '940px';

  return (
    <Modal
      externalCloseButton
      isOpen={isOpen}
      width={modalWidth}
      onClose={onClose}
    >
      <UpgradeModalBuilder
        title={title}
        description={description}
        items={items}
        background={background}
        imageComponent={<ImageComponent designPreview={designPreview} />}
        onUpgrade={onUpgrade}
        isMobile={Boolean(isMobile)}
      />
    </Modal>
  );
};

UpgradeModal.defaultProps = {
  contentType: 'highResVectorDownload',
  imageComponentType: 'none',
  projectCountLimit: 100,
  projectCount: 10,
  designPreview: '',
};

export default UpgradeModal;
