import styled from 'styled-components';
import { StyledTheme } from '../../../types';

const getKey = (key: string, prefix?: string, postfix?: string) => {
  if (prefix) return `${prefix}${key}${postfix ?? ''}`;
  return `${key.charAt(0).toLowerCase() + key.slice(1)}${postfix ?? ''}`;
};

interface Props {
  disabled: boolean;
  isLoading: boolean;
  isActive: boolean;
  theme: StyledTheme;
}

type ThemeColorValue = string | null | undefined;

const getColor = (
  props: Props,
  prefix?: string,
  postfix?: string
): ThemeColorValue => {
  if (props.disabled && !props.isLoading) {
    return props.theme[
      getKey('DisabledColor', prefix, postfix)
    ] as ThemeColorValue;
  }
  const activeColor = props.theme[
    getKey('ActiveColor', prefix, postfix)
  ] as ThemeColorValue;
  // if no activeColor is defined for this prefix, return default color below (used for border-color)
  if (props.isActive && (activeColor || !postfix)) {
    return activeColor;
  }
  return props.theme[getKey('Color', prefix, postfix)] as ThemeColorValue;
};

const getDarkColor = (props: Props, prefix?: string) => {
  const darkColor = getColor(props, prefix, 'Dark');
  if (darkColor) return darkColor;
  return getColor(props, prefix);
};

export const Label = styled.div<any>`
  cursor: inherit;
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: ${({ theme, isActive }) =>
    isActive ? theme.fontWeightActive : theme.fontWeight};

  transition: font-weight 225ms ease;
`;

export const ButtonContainer = styled.div<any>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  opacity: ${({ theme }) => theme.opacity || 1};
  border-radius: ${({ theme }) => theme.borderRadius};

  background: ${(props) => getColor(props, 'background')};
  color: ${(props) => getColor(props)};
  border: 1px solid;
  border-color: ${(props) => getColor(props, 'border')};
  box-shadow: ${({ theme }) => theme.boxShadow};

  &:hover {
    background: ${(props) =>
      getColor({ ...props, isActive: true }, 'background')};
    color: ${(props) => getColor({ ...props, isActive: true })};
    border-color: ${(props) =>
      getColor({ ...props, isActive: true }, 'border')};

    ${Label} {
      font-weight: ${({ theme }) => theme.fontWeightActive};
    }
  }

  html[data-theme='dark'] && {
    background: ${(props) => getDarkColor(props, 'background')};
    color: ${(props) => getDarkColor(props)};
    border-color: ${(props) => getDarkColor(props, 'border')};

    &:hover {
      background: ${(props) =>
        getDarkColor({ ...props, isActive: true }, 'background')};
      color: ${(props) => getDarkColor({ ...props, isActive: true })};
      border-color: ${(props) =>
        getDarkColor({ ...props, isActive: true }, 'border')};
    }
  }
`;

export const Content = styled.div<any>`
  width: 100%;
  padding: 0 ${({ theme }) => theme.padding};
  box-sizing: border-box;
  display: flex;
  justify-content: ${({ isCentered }) =>
    isCentered ? 'center' : 'space-between'};
  align-items: center;
  position: relative;

  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  html[data-theme='dark'] && {
    opacity: ${({ isDisabled }) => (isDisabled ? '0.3' : '1')};
  }
`;

export const InputContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconWrapper = styled.div`
  min-width: ${({ theme }) => theme.iconMinWidth};
  display: flex;
  justify-content: space-around;
`;

export const Caret = styled.div``;
