// @refresh reset
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import debounce from 'lodash/debounce';
import hotkeys from 'hotkeys-js';
import { nanoid } from 'nanoid';

import {
  ArtboardWrapper,
  PathTextAlert,
  PathTextAlertTitle,
  PathTextAlertContent,
} from './styles';
import fabric from './fabric';
import RightClickMenu from '../RightClickMenu/RightClickMenu';
import CursorInfoBox from '../CursorInfoBox/CursorInfoBox';
import useDebounce from '../../hooks/useDebounce';
import useThrottled from '../../hooks/useThrottle';
import { userStoreSelector, useUserStore } from '../../stores/userStore';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import {
  DESIGN_SAVE,
  EDIT_TEXT,
  ARTBOARD_SELECTION,
  ARTBOARD_STATE,
  ARTBOARD_PREFIX,
  ACTIVE_MOVE,
  ACTIVE_MOVED,
  HISTORY_PREFIX,
  ENABLE_ZOOM_ON_WHEEL,
  ENABLE_DRAGGING,
  ARTBOARD_IS_LOADING,
  ACTIVE_TRANSFORM_BUTTON,
  OPEN_ARTBOARD_SIZE_MODAL,
} from '../../global/events';
import { addHotkeysHandlers } from './hotkeys';
import {
  EMPTY_STATE,
  AUTOSAVE_THROTTLE_DELAY,
  TYPE_ACTIVE_SELECTION,
} from '../../global/constants';
import {
  urlParams,
  TEMPLATE_PARAM,
  UPDATE_TEMPLATE_PARAM,
  DESIGN_PARAM,
  NEW_DESIGN_PARAM,
  FOLDER_PARAM,
  SHARE_PARAM,
  ONBOARDING_DESIGN_CATEGORY,
} from '../../utils/window';
import { ALERT_WIDTH } from './fabric/DangerControl';
import {
  updateArtboardPosition,
  useOnboardingStore,
} from '../../stores/onboardingStore';
import useDesignsStore, {
  designsStoreSelector,
} from '../../stores/designsStore';
import {
  getElementById,
  structureCleanup,
  getParent,
  getCompatibleIndex,
} from '../../utils/groupStructure';
import maybeRegisterE2ETestUtils from './maybeResgisterE2ETestUtils';
import { isRightClick } from './fabric/canvas_events.mixin';
import { isCompatibleTouchDevice } from '../../utils/detection';
import {
  cloneObjects,
  getObjectById,
  updateObjectIds,
} from '../../utils/editor/objects';
import handleEvents from './handleEvents';
import WidgetStage from '../ObjectWidgets/WidgetStage';
import { handleWheelEvent } from '../../utils/editor/eventHandlers';
import useHistory from '../../hooks/useHistory';
import { useThemeChanged } from '../../hooks/useThemeChanged';
import { useGestures } from '../../hooks/useGestures';

const CACHING_DISABLE_DELAY = 300;
const DEBOUNCE_SELECTION_DELAY = 300;

const Artboard = forwardRef(
  (
    { bus, dispatch, initialProjectId, isLoading, canUnmask },
    canvasContainerRef
  ) => {
    const [displayAngle, setDisplayAngle] = useState({
      value: false,
      position: null,
    });
    const [pathTextAlert, setPathTextAlert] = useState({
      top: null,
      left: null,
      visible: false,
    });
    const initialProjectIdRef = useRef(initialProjectId);
    const canvasRef = useRef();
    const canvasElRef = useRef();
    const dragDuplicateHandler = useRef(null);

    /* When moving an object this is filled with some stuff. It's cleared on onObjectMoved */
    const movingInfo = useRef();

    //We init with empty object since clicking on an object when not having the canvas focused can cause an error
    const lockPointer = useRef({});

    const loaded = useRef(false);

    /* Hotkeys info object */
    const hotkeyInfo = useRef({
      Shift: {
        lock: lockPointer,
        movingInfo: movingInfo,
      },
    });

    useEffect(() => {
      const setLockPointer = (event) => {
        const target = canvasRef.current.findTarget(event, false);
        if (target) {
          const tl = {
            left: target.group?.left || target.left,
            top: target.group?.top || target.top,
          };

          const pointer = canvasRef.current.getPointer(event);
          lockPointer.current = { ...lockPointer.current, ...tl, pointer };
        }
      };

      /* The fabric equivalents are not reliable when pressing keys, so we use these ones */
      document.addEventListener('mousedown', setLockPointer);

      return () => {
        document.removeEventListener('mousedown', setLockPointer);
      };
    }, []);

    const setActiveDesign = useDesignsStore(designsStoreSelector.setActive);
    const forceActiveModified = useDesignsStore(
      designsStoreSelector.forceActiveModified
    );

    useEffect(() => {
      // React 18 introduced a new dev-only check to Strict mode
      // https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-strict-mode
      //
      // It's not safe to load all artboard state and dispatch events twice here for now,
      // the current architecture demands the only initialisation,
      // so we skip the second useEffect call.
      if (loaded.current) {
        return;
      }

      loaded.current = true;
      if (!canvasRef.current) {
        canvasRef.current = new fabric.Canvas(canvasElRef.current);
      }

      const updateTemplateId = urlParams.get(UPDATE_TEMPLATE_PARAM);
      if (updateTemplateId) {
        useMenuStore.setState({ updateTemplateId });
      }

      const templateId = updateTemplateId || urlParams.get(TEMPLATE_PARAM);
      const designId = urlParams.get(DESIGN_PARAM);
      const newProject = urlParams.get(NEW_DESIGN_PARAM);
      const folderId = urlParams.get(FOLDER_PARAM);
      const shareId = urlParams.get(SHARE_PARAM);
      const onboardingCategory = urlParams.get(ONBOARDING_DESIGN_CATEGORY);
      // delete params from url to prevent refresh re-loading and causing a loss of work
      [
        TEMPLATE_PARAM,
        UPDATE_TEMPLATE_PARAM,
        DESIGN_PARAM,
        NEW_DESIGN_PARAM,
        FOLDER_PARAM,
        SHARE_PARAM,
        ONBOARDING_DESIGN_CATEGORY,
      ].forEach((param) => urlParams.delete(param));
      const searchString = urlParams.toString();
      window.history.replaceState(
        null,
        '',
        searchString.length ? `?${searchString}` : '/'
      );

      const loadEmptyDesign = (folderId, openSizeModal = true) =>
        canvasRef.current.stagedLoadFromJSON(
          folderId ? { config: { folderId }, ...EMPTY_STATE } : EMPTY_STATE,
          null,
          () => {
            // this does not always trigger a save, since user might not be authenticated yet
            dispatch(DESIGN_SAVE, { type: 'auto', folderId });
            dispatch(ARTBOARD_IS_LOADING, false);
            if (openSizeModal) {
              dispatch(OPEN_ARTBOARD_SIZE_MODAL);
            }
          }
        );

      dispatch(ARTBOARD_IS_LOADING, true);
      if (newProject || onboardingCategory) {
        loadEmptyDesign(folderId, !onboardingCategory);
      } else if (designId) {
        canvasRef.current.stagedLoadFromJSON({ designId }, null, () => {
          dispatch(ARTBOARD_IS_LOADING, false);
          setActiveDesign(designId);
          forceActiveModified();
        });
      } else if (templateId || shareId) {
        canvasRef.current.stagedLoadFromJSON(
          { templateId, shareId },
          null,
          () => {
            dispatch(ARTBOARD_IS_LOADING, false);
          }
        );
      } else if (initialProjectIdRef.current) {
        if (
          canvasRef.current.config?.designId ||
          canvasRef.current.config?.templateId
        ) {
          // in dev environment, this could happen after hot reload
          dispatch(ARTBOARD_IS_LOADING, false);
        } else {
          try {
            // fetch state
            canvasRef.current.stagedLoadFromJSON(
              { designId: initialProjectIdRef.current },
              null,
              () => {
                dispatch(ARTBOARD_IS_LOADING, false);
                forceActiveModified();
                setActiveDesign();
              }
            );
          } catch (e) {
            console.warn(
              'Error with loading state: ',
              initialProjectIdRef.current
            );
            throw e;
          }
        }
      } else {
        loadEmptyDesign();
      }

      if (onboardingCategory) {
        useOnboardingStore.getState().setOnboardingCategory(onboardingCategory);
      }
      if (folderId) {
        useDesignsStore.getState().setFolder({ id: folderId });
      }
    }, [dispatch, setActiveDesign, forceActiveModified]);

    const _debouncedSelectionUpdate = useDebounce({
      delay: DEBOUNCE_SELECTION_DELAY,
      callback: () =>
        canvasRef.current?.fire('selection:updated', { noDeselectText: true }),
    });
    const debouncedSelectionUpdate = useRef(_debouncedSelectionUpdate);

    // add a throttled design save function
    const isLoggedIn = useUserStore(userStoreSelector.isLoggedIn);
    const disableAutoSave = useMenuStore(menuStoreSelector.disableAutoSave);
    const _throttledDesignSave = useThrottled({
      delay: AUTOSAVE_THROTTLE_DELAY,
      callback: () => {
        if (isLoggedIn && !isLoading && !disableAutoSave) {
          dispatch(DESIGN_SAVE, { type: 'auto' });
        }
      },
    });

    // function to trigger design save
    const throttledDesignSave = useRef(() => {});
    useEffect(() => {
      throttledDesignSave.current = () => {
        if (isLoggedIn && !isLoading) {
          _throttledDesignSave();
        }
      };
    }, [isLoggedIn, isLoading, _throttledDesignSave]);

    // if there are unsaved changes, warn the user when leaving the page
    useEffect(() => {
      const beforeUnload = (event) => {
        if (useMenuStore.getState().saving || !useMenuStore.getState().saved) {
          event.preventDefault();
          event.returnValue = '';
        }
      };

      window.addEventListener('beforeunload', beforeUnload);
      return () => {
        window.removeEventListener('beforeunload', beforeUnload);
      };
    }, []);

    const registeredEventHandlersOnCanvas = useRef([]);
    const addEventHandlerOnCanvas = (canvas, eventName, handler) => {
      canvas.on(eventName, handler);
      registeredEventHandlersOnCanvas.current.push({ eventName, handler });
    };
    const removeEventHandlersOnCanvas = (canvas) => {
      registeredEventHandlersOnCanvas.current.forEach(
        ({ eventName, handler }) => {
          canvas.off(eventName, handler);
        }
      );
      registeredEventHandlersOnCanvas.current = [];
    };

    const { historyRef } = useHistory(bus, dispatch);

    // Used for object widgets
    const [activeObject, setActiveObject] = useState(null);
    const [lineCoordinates, setLineCoordinates] = useState(null);

    const updateLineCoordinates = useCallback(
      (object) => {
        setLineCoordinates(object?.getLineCoordinates() || null);
      },
      [setLineCoordinates]
    );

    useEffect(() => {
      if (!bus) return;
      const canvas = canvasRef.current;
      const events = bus
        // filter out all the events that artboard creates itself
        .pipe(
          filter(
            ({ key }) =>
              !key.startsWith(ARTBOARD_PREFIX) &&
              !key.startsWith(HISTORY_PREFIX)
          )
        );

      const mainSubscription = events.subscribe((obj) => {
        handleEvents(canvas, obj, dispatch);
      });

      // maybe add utils for e2e testing that require artboard information
      maybeRegisterE2ETestUtils(canvas);

      // The code below handles disabling/enabling cache on illustration objects
      // that's needed, because cache causes blurriness effect,
      // but without cache editor can become really sluggish
      let cachingDisableTimeout;
      const disableCaching = () => {
        canvas.forEachObject((o) => {
          if (o.switchFastRendering) {
            o.switchFastRendering(false);
          }
        });
        canvas.requestRenderAll();
        canvas.perPixelTargetFind = true;
      };
      const enableCaching = () => {
        clearTimeout(cachingDisableTimeout);
        cachingDisableTimeout = setTimeout(
          disableCaching,
          CACHING_DISABLE_DELAY
        );
        canvas.forEachObject((o) => {
          if (o.switchFastRendering) {
            o.switchFastRendering(true);
          }
        });

        // perPixelTargetFind is expensive, so we turn it off when caching
        canvas.perPixelTargetFind = false;
      };

      const stateChange = () => {
        if (canvas.hasMovingObject) return;
        useMenuStore.getState().change();
        throttledDesignSave.current();
        const artboardState = canvas.toJSON();
        dispatch(ARTBOARD_STATE, {
          state: artboardState,
          objects: canvas.getActiveObjects(),
        });
        historyRef.current.update(artboardState);
      };

      const onObjectMoved = () => {
        /* Not moving anything anymore */
        movingInfo.current = null;
        dispatch(ACTIVE_MOVED);
      };

      // This is needed to update some PathText properties (e.g. fontSize)
      const onObjectScaled = () => {
        selectionChange();
      };

      const onObjectModified = (event) => {
        switch (event.action) {
          case 'drag':
            onObjectMoved();
            break;
          case 'scale':
          case 'scaleY':
          case 'scaleX':
            onObjectScaled();
            break;
          default:
            break;
        }

        if (event.action) {
          setActiveObject(event.target);
          updateLineCoordinates(event.target);
        }

        stateChange();
      };

      addEventHandlerOnCanvas(canvas, 'object:modified', onObjectModified);
      addEventHandlerOnCanvas(canvas, 'object:rotating', (opts) => {
        enableCaching();
        // angle should be between -180 and +180, where 181 is -179
        let angle = opts.transform.target.angle.toFixed(0);
        if (angle > 180) {
          angle = angle - 360;
        }
        setDisplayAngle({
          value: `${angle}°`,
          position: { left: opts.e.x + 20, top: opts.e.y + 20 },
        });
      });

      addEventHandlerOnCanvas(canvas, 'object:moving', (event) => {
        const target = event.target;

        if (dragDuplicateHandler.current) {
          dragDuplicateHandler.current();
        }

        /* Save info about the moving operation */
        if (!lockPointer.doLock) {
          movingInfo.current = {
            positionWithoutLocking: {
              left: target.group?.left || target.left,
              top: target.group?.top || target.top,
            },
            event,
          };
        }

        dispatch(ACTIVE_MOVE, { ...event, lock: lockPointer.current });
      });

      addEventHandlerOnCanvas(canvas, 'before:transform', (event) => {
        if (
          event.isCustomSelectionTriggered ||
          event.transform.action !== 'drag' ||
          !event?.e?.altKey ||
          event.transform?.target?.type === 'artboard'
        )
          return;

        // Alt/Opt+Drag Duplication

        // Empty fabric object to use as a dummy while copying is taking place
        const empty = new fabric.Object({
          top: 0,
          left: 0,
          canvas,
        });

        // Id for the current transform to later be able to identify it
        const transformId = nanoid();

        canvas._setupCurrentTransform(event, empty);
        canvas._currentTransform.id = transformId;

        // ========================= Duplication =========================
        const someObjectLocked = canvas
          .getActiveObjects()
          .some((obj) => obj.locked);
        if (someObjectLocked) return;

        // Get structural info about the copy operation
        const structureSelection = canvas._selectedElements;

        const parentStructure = getParent(
          canvas.groupStructure,
          structureSelection[0]
        );

        const structuresToCopy = parentStructure.children.filter((element) =>
          structureSelection.some((selected) =>
            getElementById(element, selected)
          )
        );

        const index = getCompatibleIndex(
          parentStructure.children.map((s) => s.id),
          structuresToCopy.map((s) => s.id)
        );

        const activeObject = canvas.getActiveObject();

        cloneObjects(
          [activeObject],
          (clone) => {
            return new Promise((resolve) => {
              if (clone.type === 'pathText') {
                clone.updateText(() => {
                  resolve(clone);
                });
                return;
              }
              resolve(clone);
            });
          },
          (objects) => {
            const { newObjects, newObjectIds } = updateObjectIds(
              objects,
              false
            );

            const addObjectsToCanvas = () => {
              newObjects.forEach((obj) => {
                obj.top += empty.top;
                obj.left += empty.left;
                obj.fire('moved');
                canvas.add(obj);
              });

              structureCleanup(
                canvas,
                structuresToCopy,
                newObjectIds,
                parentStructure.id || null,
                index,
                true
              );

              canvas.requestRenderAll();
            };

            if (canvas._currentTransform?.id === transformId) {
              /*
                Here, the same drag operation that started the duplication process is taking place.
                We set dragDuplicateHandler so that it's called in the object:moving handler

                It will:
                  - Add the objects to canvas
                  - Set the transform target to the newly selected objects, so that the move operation continues on them
                  - Nullyify the handler so that it's not called again
              */

              dragDuplicateHandler.current = () => {
                addObjectsToCanvas();
                canvas._setupCurrentTransform(event, canvas.getActiveObject());
                dragDuplicateHandler.current = null;
              };
            } else {
              /*
                Here the drag operation was finished before the objects were finished being duplicated.
                In this case we add the objects to canvas and then set the position of the new selection to
                match the empty that was dragged silently.
              */

              if (!(empty.left + empty.top)) return; // This was just an Alt/Opt + Click, and no dragging

              addObjectsToCanvas();

              const activeObject = canvas.getActiveObject();
              activeObject.set({
                left: empty.left,
                top: empty.top,
              });

              activeObject.fire('moved');
            }
          }
        );
      });

      const selectionChange = () => {
        const activeObject = canvas.getActiveObject();

        if (!activeObject?.pathTextAlertPosition) {
          setPathTextAlert((oldAlert) => ({
            ...oldAlert,
            visible: false,
          }));
        }

        const isPathText = activeObject?.type === 'pathText';
        const isActiveSelectionWithPathText =
          activeObject instanceof fabric.ActiveSelection &&
          activeObject.getObjects().some((obj) => obj.type === 'pathText');

        // If selection does not include pathText, allow non-uniform scaling with shift key
        canvas.uniScaleKey =
          !isPathText && !isActiveSelectionWithPathText ? 'shiftKey' : null;

        // update active objects, make sure state is current
        dispatch(ARTBOARD_SELECTION, {
          objects: canvas.getActiveObjects(),
          selectedObjects: canvas._selectedElements,
          state: canvas.toJSON(),
          zoom: canvas.getZoom(),
        });

        setActiveObject(activeObject);
        setLineCoordinates(
          activeObject ? activeObject.getLineCoordinates() : null
        );
      };

      addEventHandlerOnCanvas(canvas, 'selection:updated', selectionChange);
      addEventHandlerOnCanvas(canvas, 'selection:created', selectionChange);
      addEventHandlerOnCanvas(canvas, 'selection:cleared', selectionChange);
      addEventHandlerOnCanvas(canvas, 'object:scaling', enableCaching);

      addEventHandlerOnCanvas(canvas, 'mouse:down:before', (options) => {
        const target = options.target;
        if (
          !isRightClick(options.e) &&
          target instanceof fabric.PathText &&
          !target.__corner &&
          target === canvas.getActiveObject() &&
          !canvas.activeTextEdit
        ) {
          canvas.registerForMouseUp('textedit', () => {
            dispatch(EDIT_TEXT, { target });
          });
        }
      });

      addEventHandlerOnCanvas(canvas, 'mouse:down', (options) => {
        const evt = options.e;
        const target = options.target;

        if (canvas.draggingEnabled) {
          // drag canvas
          canvas.setCursor('grabbing');
          canvas.isDragging = true;
          canvas.lastPosX = evt.clientX;
          canvas.lastPosY = evt.clientY;
        } else if (options.button === 3 && options.target) {
          // select element on right click, if not already selected
          if (
            !['CustomTextbox', TYPE_ACTIVE_SELECTION, 'artboard'].includes(
              target.type
            ) &&
            !getObjectById(target.id, canvas.getActiveObjects())
          ) {
            canvas.discardActiveObject();
            canvas.selectIds([target.id]);
            canvas.requestRenderAll();
          }
        }

        if (target) {
          useMenuStore
            .getState()
            .mergeRightMenuState({ activeGeneralPanel: null });
        } else {
          useMenuStore.getState().mergeRightMenuState({ open: false });
        }
      });

      const detectPathTextAlertMouseover = (e) => {
        const pointer = canvas.getPointer(e);
        const zoom = canvas.getZoom();
        const activeObject = canvas.getActiveObject();

        const alert = activeObject?.pathTextAlertPosition;
        const dist = alert
          ? Math.hypot(pointer.x - alert.x, pointer.y - alert.y)
          : null;
        if (dist !== null && dist <= ALERT_WIDTH / (zoom * 2)) {
          const vpt = canvas.viewportTransform;
          const transformed = fabric.util.transformPoint(alert, vpt);

          setPathTextAlert({
            left: transformed.x,
            top: transformed.y,
            visible: true,
          });
        } else {
          setPathTextAlert((oldAlert) => ({
            ...oldAlert,
            visible: false,
          }));
        }
      };

      const detectPathTextAlertMouseoverDebounced = debounce(
        detectPathTextAlertMouseover,
        16,
        { leading: true, trailing: true }
      );

      addEventHandlerOnCanvas(canvas, 'mouse:move', (options) => {
        const e = options.e;
        detectPathTextAlertMouseoverDebounced(e);

        if (canvas.draggingEnabled) {
          canvas.setCursor(canvas.isDragging ? 'grabbing' : 'grab');
        }

        if (canvas.isDragging) {
          enableCaching();
          const vpt = canvas.viewportTransform;
          // calculate the new viewport position after dragging
          const newPosX = vpt[4] + e.clientX - canvas.lastPosX;
          const newPosY = vpt[5] + e.clientY - canvas.lastPosY;
          // set the new viewport pos
          canvas.setViewportPos(newPosX, newPosY);
          canvas.requestRenderAll();
          updateArtboardPosition(
            canvas.artboard,
            canvas.getZoom(),
            canvas.viewportTransform
          );
        }
        canvas.lastPosX = e.clientX;
        canvas.lastPosY = e.clientY;
      });
      addEventHandlerOnCanvas(canvas, 'mouse:up', () => {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        canvas.setViewportTransform(canvas.viewportTransform);
        canvas.isDragging = false;
        // remove info box for current angle
        setDisplayAngle({ value: false, position: null });
      });

      addEventHandlerOnCanvas(canvas, 'mouse:up:before', (e) => {
        /*
        This handles clicking of the `Edit Transform` widget that pathText objects show when they have an active transform
        On clicking, ACTIVE_TRANSFORM_BUTTON is dispatched, and the transform panel should scroll down as far as possible so
        that the user can easily see all of the menu options related to text transforms.
      */

        dragDuplicateHandler.current = null;

        const corner = e.target?.__corner;
        if (corner === 'editTransform') {
          dispatch(ACTIVE_TRANSFORM_BUTTON);
        }
      });

      addEventHandlerOnCanvas(canvas, 'mouse:wheel', (options) => {
        enableCaching();
        handleWheelEvent(canvas, options.e, dispatch);
      });

      const removeHotkeysHandlers = addHotkeysHandlers(
        canvas,
        canvasElRef.current,
        dispatch,
        hotkeyInfo
      );

      const handleTransforming = (event) => {
        setActiveObject(null);
      };

      addEventHandlerOnCanvas(
        canvasRef.current,
        'object:scaling',
        handleTransforming
      );

      addEventHandlerOnCanvas(
        canvasRef.current,
        'object:rotating',
        handleTransforming
      );

      addEventHandlerOnCanvas(
        canvasRef.current,
        'object:moving',
        handleTransforming
      );

      return () => {
        mainSubscription.unsubscribe();
        removeHotkeysHandlers();
        removeEventHandlersOnCanvas(canvas);
      };
    }, [bus, dispatch, historyRef, updateLineCoordinates]);

    const removeHotkeysOnBlur = useCallback(() => {
      hotkeys.shift = false;
      hotkeys.ctrl = false;
      hotkeys.alt = false;
      hotkeys.option = false;
      hotkeys.control = false;
      hotkeys.cmd = false;
      hotkeys.command = false;

      lockPointer.current = {
        pointer: {
          ...lockPointer.current?.pointer,
          altKey: false,
          ctrlKey: false,
          shiftKey: false,
        },
      };

      dispatch && dispatch(ENABLE_DRAGGING, false);
      dispatch && dispatch(ENABLE_ZOOM_ON_WHEEL, false);
    }, [dispatch]);

    useEffect(() => {
      window.addEventListener('blur', removeHotkeysOnBlur);

      return () => window.removeEventListener('blur', removeHotkeysOnBlur);
    }, [removeHotkeysOnBlur]);

    const updateCanvasContainerSize = useCallback(
      (centerArtboard) => {
        if (canvasContainerRef.current) {
          const rect = canvasContainerRef.current.getBoundingClientRect();
          canvasRef.current.setDimensions({
            width: rect.width,
            height: rect.height,
          });

          if (centerArtboard) {
            canvasRef.current.centerArtboard();
          } else {
            const currentZoom = canvasRef.current.getZoom();
            const zoom = canvasRef.current.adjustZoom(currentZoom);
            canvasRef.current.setZoom(zoom);
          }

          debouncedSelectionUpdate.current(); // update zoom in UI
        }
      },
      [canvasContainerRef]
    );

    const handleResize = useCallback(
      () => updateCanvasContainerSize(),
      [updateCanvasContainerSize]
    );

    useEffect(() => {
      updateCanvasContainerSize(true);
      canvasRef.current.forEachObject((obj) => obj.setCoords());
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [handleResize, updateCanvasContainerSize]);

    useGestures(
      canvasRef.current,
      canvasContainerRef.current,
      dispatch,
      updateLineCoordinates
    );

    useThemeChanged(canvasRef.current);

    return (
      <>
        {isCompatibleTouchDevice() && activeObject && lineCoordinates && (
          <WidgetStage
            canvas={canvasRef.current}
            canvasContainer={canvasContainerRef.current}
            dispatch={dispatch}
            object={activeObject}
            objectPosition={{
              ...lineCoordinates,
              angle: activeObject.angle,
            }}
          />
        )}
        <ArtboardWrapper ref={canvasContainerRef} isLoading={isLoading}>
          <canvas ref={canvasElRef} />
        </ArtboardWrapper>
        <RightClickMenu
          canvasContainerRef={canvasContainerRef}
          canUnmask={canUnmask}
          dispatch={dispatch}
        />
        {displayAngle?.value !== false && (
          <CursorInfoBox isOpen={true} targetPosition={displayAngle.position}>
            {displayAngle.value}
          </CursorInfoBox>
        )}
        <PathTextAlert
          top={pathTextAlert.top}
          left={pathTextAlert.left}
          isPositioned={pathTextAlert.visible}
        >
          <PathTextAlertTitle>Overset Text</PathTextAlertTitle>
          <PathTextAlertContent>
            Try lowering the font size
          </PathTextAlertContent>
        </PathTextAlert>
      </>
    );
  }
);

Artboard.propTypes = {
  /**
   * Event bus that artboard subscribes to
   */
  bus: PropTypes.instanceOf(Subject),
  /**
   * Dispatch function that sends events into the bus
   */
  dispatch: PropTypes.func,
  /**
   * id of the project to load initially
   */
  initialProjectId: PropTypes.string,
  /**
   * whether the artboard is currently loading
   */
  isLoading: PropTypes.bool,
  /**
   * whether a mask selection can be removed
   */
  canUnmask: PropTypes.bool,
};

export default React.memo(Artboard, (prev, newProps) => {
  // we don't need to compare initialProjectId, bus or dispatch
  return (
    prev.isLoading === newProps.isLoading &&
    prev.canUnmask === newProps.canUnmask
  );
});
