import React, { useCallback } from 'react';

import { DropdownOptionProps } from './types';
import { DropdownOptionWrapper } from './styles';

const DropdownOption: React.FC<DropdownOptionProps> = ({
  option,
  small,
  compact,
  isActive,
  onClick,
  onMouseEnter,
}) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(option);
    }
  }, [onClick, option]);

  const handleMouseEnter = useCallback(() => {
    if (onMouseEnter) {
      onMouseEnter(option);
    }
  }, [onMouseEnter, option]);

  return (
    <DropdownOptionWrapper
      small={small}
      compact={compact}
      isActive={isActive}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
    >
      {option.label}
    </DropdownOptionWrapper>
  );
};

export default React.memo(DropdownOption);
