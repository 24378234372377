export const iconRowConfig = [
  { id: 'shapes', icon: 'shapes', label: 'Shapes' },
  { id: 'ornaments', icon: 'ornament', label: 'Ornaments' },
  {
    id: 'illustrations',
    icon: 'illustration',
    label: 'Illustrations',
  },
  { id: 'abstract', icon: 'abstract', label: 'Abstract' },
];
