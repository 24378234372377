import { forwardRef } from 'react';

import {
  ColorSwatchWrapper,
  ColorTransparent,
  HalfColorSolid,
  InnerWrapper,
} from './styles';
import { defaultTheme } from './theme';
import { ColorSwatchProps } from './types';

const ColorSwatch = forwardRef<HTMLButtonElement, ColorSwatchProps>(
  (props, ref) => {
    const theme = { ...defaultTheme, ...props.theme };

    return (
      <ColorSwatchWrapper
        ref={ref}
        data-testid={props.dataTestId}
        onClick={props.onClick}
        theme={theme}
        isEmpty={props.color.isEmpty}
      >
        <InnerWrapper isEmpty={props.color.isEmpty}>
          <ColorTransparent
            style={{
              backgroundColor: props.color.rgb ?? '',
              opacity: props.color.opacity,
            }}
          />
          <HalfColorSolid
            style={{
              backgroundColor: props.color.rgb ?? '',
            }}
          />
        </InnerWrapper>
      </ColorSwatchWrapper>
    );
  }
);

export default ColorSwatch;
