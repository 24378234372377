import { forwardRef } from 'react';
import { IconWrapperSpan, ButtonLabel } from './styles';
import { defaultTheme } from './theme';
import ButtonBase from './ButtonBase';
import Icon from '../Icon/Icon';
import IconEventWrapper from '../Icon/IconEventWrapper';
import Tooltip from '../Tooltip/Tooltip';
import { IconButtonProps } from './types';

const IconButton = forwardRef<
  HTMLButtonElement,
  IconButtonProps & ({ label: string } | { ariaLabel: string }) // one label is required
>((props, ref) => {
  const {
    label,
    iconName,
    height,
    isActive,
    tooltipText,
    tooltipAlignment,
    tooltipPlacement,
    disabled,
    useActiveUnderline,
  } = props;

  const theme = { ...defaultTheme, ...props.theme };
  return (
    <ButtonBase {...props} theme={theme} ref={ref} height={undefined}>
      <IconEventWrapper
        activeOnHover={!disabled}
        disabled={props.disabled}
        theme={theme}
      >
        <Tooltip
          text={tooltipText}
          align={tooltipAlignment}
          placement={tooltipPlacement}
        >
          {/* Icon Wrapper is needed to `display: inline-flex;` the children */}
          <IconWrapperSpan {...theme}>
            <Icon
              name={iconName}
              isActive={isActive}
              height={height}
              theme={theme}
              disabled={disabled}
            />
            {label && (
              <ButtonLabel
                isActive={isActive}
                theme={theme}
                useActiveUnderline={useActiveUnderline}
                disabled={disabled}
              >
                {label}
              </ButtonLabel>
            )}
          </IconWrapperSpan>
        </Tooltip>
      </IconEventWrapper>
    </ButtonBase>
  );
});

IconButton.defaultProps = {
  disabled: false,
  useActiveUnderline: true,
  height: '19px',
};

export default IconButton;
