import Nprogress from 'nprogress';
import networkStore from '../../../stores/networkStore';
import { blobToDataURL } from '../../../utils';
import { isIpadOperatingSystem } from '../../../utils/detection';
import { FETCH_RETRIES, FETCH_RETRY_DELAY } from '../../../utils/fetchRetry';
import { buildElementUrl } from '../../../utils/url';

const defaultOptions = {
  srcFromAttribute: true,
};

export const alphaMasksCache = {};

export default function (fabric) {
  fabric.Image.prototype.initialize = (function (initialize) {
    return function (element, options) {
      options || (options = {});
      options.allowCompositeOperation = true;

      initialize.apply(this, [element, { ...defaultOptions, ...options }]);

      this.on('removed', this.dispose);

      return this;
    };
  })(fabric.Image.prototype.initialize);

  fabric.Image.fromURL = (function (fromURL) {
    return function (url, callback, options) {
      options || (options = {});

      const cb = (img, isError) => {
        if (options.wasDropped) {
          img.adjustToDrop(options);
        } else {
          img.adjustToArtboard(options);
        }
        callback && callback(img, isError);
      };
      fromURL.apply(this, [url, cb, options]);
    };
  })(fabric.Image.fromURL);

  fabric.Image.fromURLRetry = function (url, callback, options, tryCount = 0) {
    this.fromURL(
      url,
      (target, err) => {
        if (err && tryCount < FETCH_RETRIES) {
          setTimeout(() => {
            this.fromURLRetry(url, callback, options, tryCount + 1);
          }, FETCH_RETRY_DELAY);
        } else if (err) {
          if (!navigator.onLine) {
            networkStore.getState().setStatus(false);
          }
          callback && callback(target, err);
        } else {
          callback && callback(target, err);
        }
      },
      options
    );
  };

  /**
   * wrapper function for fromURL, to use objectName instead
   */
  fabric.Image.fromObjectName = function (objectName, callback, options) {
    const src = buildElementUrl(objectName);
    options.objectName = objectName;
    return this.fromURL(src, callback, options);
  };

  /**
   * Returns object representation of an instance
   * Extend fabrics toObject, to replace src property with `objectName`, if the latter exists.
   * @param {Array} [propertiesToInclude] Any properties that you might want to additionally include in the output
   * @return {Object} Object representation of an instance
   */
  fabric.Image.prototype.toObject = (function (toObject) {
    return function (propertiesToInclude) {
      propertiesToInclude || (propertiesToInclude = []);

      if (this.objectName) {
        const object = toObject.apply(this, [
          [...propertiesToInclude, 'objectName'],
        ]);
        delete object.src;
        return object;
      } else {
        return toObject.apply(this, [propertiesToInclude]);
      }
    };
  })(fabric.Image.prototype.toObject);

  fabric.Image.prototype.adjustToDrop = function (options) {
    if (options.distanceToTopCorner) {
      const zoom = options.canvas.getZoom();
      const top = options.top - options.distanceToTopCorner.y / zoom;
      const left = options.left - options.distanceToTopCorner.x / zoom;
      this.set({ top, left });
    } else {
      const { top, left } = options;
      this.set({ top, left });
    }
    if (options.imgWidth) {
      this.scaleToWidth(options.imgWidth);
    }
  };

  fabric.Image.prototype.adjustToArtboard = function (options) {
    if (options.canvas?.artboard) {
      const artboard = options.canvas.artboard;
      const scaleX = (artboard.width * fabric.NEW_OBJECT_SCALE) / this.width;
      const scaleY = (artboard.height * fabric.NEW_OBJECT_SCALE) / this.height;
      const scale = Math.min(scaleX, scaleY);
      this.scale(scale); // scale is needed, as width/height would crop the image
      this.top = (artboard.height - this.height * scale) / 2;
      this.left = (artboard.width - this.width * scale) / 2;
    }
  };

  /* This should be made in https://github.com/fabricjs/fabric.js/blob/master/src/shapes/image.class.js#L363
   * however, that element cant be used for toDataUrl
   */
  fabric.Image.prototype.getSvgSrc = function (filtered) {
    // set opacity to 1 during export, since its opacity will be set in the svg node
    const currentOpacity = this.opacity;
    this.opacity = 1;
    // same for clipPath. This will be applied by the svg
    const currentClipPath = this.clipPath;
    this.clipPath = null;

    // this.canvas.viewportToExportRatio is set during SVG download
    // add a fallback in case this method is called anywhere else
    const viewportToExportRatio = this.canvas?.viewportToExportRatio || 1;
    const src = this.toDataURL({
      multiplier: Math.min(2 * (this.scaleX / viewportToExportRatio), 1),
      withoutTransform: true,
    });

    this.opacity = currentOpacity;
    this.clipPath = currentClipPath;
    return src;
  };

  /**
   * implement objects getColors
   * image objects don't have colors that can be changed
   */
  fabric.Image.prototype.getColors = function () {
    return [];
  };

  /**
   * Computes an alpha mask based on luminance values of the image,
   * and returns a url object pointing to it.
   */
  fabric.Image.prototype.getAlphaMaskSrc = function (forPDF = false) {
    Nprogress.start();
    Nprogress.inc(0.1);

    const image = this.getElement();
    const { naturalWidth: width, naturalHeight: height } = image;

    // Draw the current image in separate canvas
    const canvas = fabric.util.createCanvasElement();
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0);
    Nprogress.inc(0.4);

    // Convert it to a suitable alpha mask
    const imageData = ctx.getImageData(0, 0, width, height);
    const { data } = imageData;
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i]; // red component
      const g = data[i + 1]; // green component
      const b = data[i + 2]; // blue component

      // This is the V in HSV
      // See https://en.wikipedia.org/wiki/HSL_and_HSV#From_RGB
      // It represents how bright a pixel is
      const value = Math.max(r, g, b);

      // data[i + 3] holds opacity of the pixel

      // We modify opacity values based on the luminance of each pixel
      // It works as follows:
      //     - The brighter the pixel, the more the opacity is preserved
      //     - The darker the pixel, the more the opacity is lowered
      // After this was carried out, the image's opacity values are inverted.
      // This is to allow the use of destination-out as composite mode,
      // which achieves our desired result.

      // However, SVG needs the non-inverted version, with a few other tweaks,
      // for the mask to work properly. For raw SVG exports, this is performed easily
      // with SVG filters, so for these exports, the inverted version is still used,
      // and tweaked inside the SVG.

      // But PDF does not support these filters, so we have a special case
      // where we provide the non-inverted version of the mask.

      if (!forPDF) {
        data[i + 3] = 255 - (data[i + 3] * value) / 255;
      } else {
        // Don't invert image for PDF
        data[i + 3] = (data[i + 3] * value) / 255;
      }
    }

    ctx.putImageData(imageData, 0, 0);
    Nprogress.inc(0.8);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blobToDataURL(blob));
      });
    }).finally(Nprogress.done());
  };

  fabric.Image.prototype.toggleAlphaMask = async function (enabled, callback) {
    const src = buildElementUrl(this.objectName);
    if (!src) {
      // If there is no image, don't do anything
      if (callback) {
        callback(this);
      }
      return;
    }

    const _callback = () => {
      this.isAlphaMask = enabled;
      if (callback) {
        callback(this);
      }
    };

    if (enabled) {
      // Look for alpha mask source in cache, if not found populate it
      if (!alphaMasksCache[src]) {
        alphaMasksCache[src] = await this.getAlphaMaskSrc();
      }

      // Set the source to be the one of the alpha mask
      this.setSrc(alphaMasksCache[src], _callback, {
        crossOrigin: 'anonymous',
      });

      // Store an alpha mask compatible with PDF as well for potential use on export
      // See explanation in getAlphaMaskSrc
      this.pdfSource = await this.getAlphaMaskSrc(true);
    } else {
      this.setSrc(buildElementUrl(this.objectName), _callback, {
        crossOrigin: 'anonymous',
      });
    }
  };

  /**
   * Originally, removeTexture runs evictCachesForKey on
   * fabric.filterBackend.
   *
   * However, in our case, since we have multiple WebGL backends,
   * we run it on each of them.
   *
   * evictCachesForKey is ineffective if the key is not found
   * in the backend's textureCache, so it's harmless to call it
   * on the wrong backend.
   *
   * We don't modify removeTexture to instead use this.filterBackend
   * because then we need more complicated overrides to make things work.
   */
  fabric.Image.prototype.removeTexture = function (key) {
    fabric.WebGLBackends?.forEach((backend) => {
      backend?.evictCachesForKey(key);
    });
  };

  fabric.Image.prototype.updateFilterBackend = function () {
    // getElement() can return both an img element or a canvas element
    const { naturalWidth, naturalHeight, width, height } = this.getElement();

    const w = naturalWidth ?? width;
    const h = naturalHeight ?? height;

    const maxSide = Math.max(w, h);

    if (fabric.WebGLBackends) {
      // Find smallest backend that supports this image
      // NOTE: Backends are sorted from smallest to biggest
      this.filterBackend = fabric.WebGLBackends.find(
        (backend) => backend && backend.tileSize >= maxSide
      );
    }

    if (!this.filterBackend) {
      this.filterBackend = fabric.JSFilterBackend;
    }

    fabric.filterBackend = this.filterBackend;
  };

  fabric.Image.prototype.applyFilters = (function (applyFilters) {
    return function (filters) {
      if (isIpadOperatingSystem()) {
        return this;
      }

      this.updateFilterBackend();
      return applyFilters.bind(this)(filters);
    };
  })(fabric.Image.prototype.applyFilters);
}
