import React from 'react';
import { ACTIVE_FILTER, ACTIVE_FILTER_RESET } from '../../global/events';
import SliderInput from '../SliderInput/SliderInput';
import { FlexColumn, FlexRow } from '../utilities/styles';
import { ActiveObject, ImageAdjustmentProps, SerialisedFilter } from './types';
import Button from '../Button/Button/Button';
import { secondarySmallTheme } from '../Button/Button/theme';
import Spacer from '../Spacer/Spacer';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import { ImageFilterType } from '../../types';
import { sliderFilterConfig } from './config';
import { defaultFilterOptions } from '../Artboard/fabric/IllustrationImage';
import { convertFilterValue } from './utils';
import { P4 } from '../utilities/Typography/styles';
import ColorPicker from '../ColorPicker/ColorPicker';
import useThrottled from '../../hooks/useThrottle';

const getFilter = (
  object: ActiveObject,
  type: ImageFilterType
): SerialisedFilter =>
  object.filters.find((filter: SerialisedFilter) => filter.type === type) ?? {
    type,
    ...defaultFilterOptions[type],
  };

const ImageAdjustments: React.FC<ImageAdjustmentProps> = (props) => {
  const showAll = useMenuStore(menuStoreSelector.rightMenuState).showAllFilters;
  const mergeRightMenuState = useMenuStore(
    menuStoreSelector.mergeRightMenuState
  );

  const onReset = (): void => {
    props.dispatch(ACTIVE_FILTER_RESET);
  };

  const removeColorFilterValue = getFilter(
    props.object,
    ImageFilterType.RemoveColor
  );

  const onRemoveColorColorChange = (
    color: null | string,
    isChanging = false
  ): void => {
    const filterValue: {
      color: null | string;
      distance?: number;
    } = {
      color,
    };

    // reset distance to 0 for no-color, or all colors will be affected
    if (color === null) {
      filterValue.distance = 0;
    }

    props.dispatch(ACTIVE_FILTER, {
      type: ImageFilterType.RemoveColor,
      value: filterValue,
      isChanging,
    });
  };

  const throttledOnRemoveColorColorChange = useThrottled({
    callback: onRemoveColorColorChange,
    delay: 200,
    skipLeading: false,
  });

  const onRemoveColorIntensityChange = (
    value: number,
    isChanging = false
  ): void => {
    props.dispatch(ACTIVE_FILTER, {
      type: ImageFilterType.RemoveColor,
      value: {
        distance: value / 100,
      },
      isChanging,
    });
  };

  const onSliderFilterChange = (
    value: number,
    type: ImageFilterType,
    isChanging = true
  ): void => {
    const config = sliderFilterConfig.find((filter) => filter.type === type)!;
    value = convertFilterValue(value, [-100, 100], [config.min, config.max]);
    if (type === ImageFilterType.Blur) {
      if (value < 0) {
        // https://www.taylorpetrick.com/blog/post/convolution-part3#sharpen
        const matrix = [0, -1, 0, -1, 4, -1, 0, -1, 0].map(
          (x) => x * Math.abs(value) * 2
        );
        matrix[4] += 1; // Add identity matrix
        props.dispatch(ACTIVE_FILTER, {
          type: ImageFilterType.Convolute,
          value: {
            matrix,
          },
          isChanging,
        });
        // Reset Blur if user jumps from positive value to negative by clicking on slider
        props.dispatch(ACTIVE_FILTER, {
          type,
          value: {
            [config.key]: 0,
          },
          isChanging,
        });
      } else {
        props.dispatch(ACTIVE_FILTER, {
          type,
          value: {
            [config.key]: value,
          },
          isChanging,
        });
        // Reset Convolute if user jumps from negative value to positive by clicking on slider
        props.dispatch(ACTIVE_FILTER, {
          type: ImageFilterType.Convolute,
          value: {
            matrix: [0, 0, 0, 0, 1, 0, 0, 0, 0], // Identity matrix
          },
          isChanging,
        });
      }
    } else {
      props.dispatch(ACTIVE_FILTER, {
        type,
        value: {
          [config.key]: value,
        },
        isChanging,
      });
    }
  };

  const getSliderFilterValue = (type: ImageFilterType): number => {
    const filter = getFilter(props.object, type);
    const config = sliderFilterConfig.find((config) => config.type === type)!;
    if (type === ImageFilterType.Blur && filter[config.key] === 0) {
      const convoluteFilter = getFilter(
        props.object,
        ImageFilterType.Convolute
      );
      return convertFilterValue(
        (convoluteFilter['matrix'] as number[])[4] === 1
          ? 0
          : Math.abs(((convoluteFilter['matrix'] as number[])[4] - 1) / 4 / 2) *
              -1,
        [config.min, config.max],
        [-100, 100]
      );
    } else {
      return convertFilterValue(
        filter[config.key] as number,
        [config.min, config.max],
        [-100, 100]
      );
    }
  };

  return (
    <FlexColumn gap="6px">
      <FlexColumn gap="6px" marginBottom="8px">
        <FlexRow justifyContent="space-between">
          <P4>Remove Color</P4>
          <FlexRow gap="6px">
            <P4>Color</P4>
            <ColorPicker
              defaultColor={removeColorFilterValue.color as string}
              onChanging={(value): void =>
                throttledOnRemoveColorColorChange(value, true)
              }
              onChanged={(value): void => onRemoveColorColorChange(value)}
              presetColors={Object.keys(props.colorPalette)}
              dispatch={props.dispatch}
            />
          </FlexRow>
        </FlexRow>
        <SliderInput
          startValue={(removeColorFilterValue.distance as number) * 100}
          min={0}
          max={100}
          unit={'%'}
          precision={0}
          label="Intensity"
          name="intensity"
          disabled={!removeColorFilterValue.color}
          onChanging={(value: number): void =>
            onRemoveColorIntensityChange(value)
          }
          onChanged={(value: number): void =>
            onRemoveColorIntensityChange(value, false)
          }
        />
      </FlexColumn>
      {sliderFilterConfig.map(
        ({ type, label, min, unit }, index) =>
          (showAll || index < 3) && (
            <SliderInput
              key={type}
              startValue={getSliderFilterValue(type)}
              min={min >= 0 ? min : -100}
              max={100}
              unit={unit}
              precision={0}
              label={label}
              name={label}
              showCenterGuide
              onChanging={(value: number): void =>
                onSliderFilterChange(value, type)
              }
              onChanged={(value: number): void =>
                onSliderFilterChange(value, type, false)
              }
            />
          )
      )}

      <Spacer h="6px" />

      <FlexRow>
        <Button
          label={!showAll ? 'Show All' : 'Show Less'}
          height="30px"
          width="50%"
          theme={secondarySmallTheme}
          style={{ paddingRight: '6px', whiteSpace: 'nowrap' }}
          onClick={(_): void =>
            mergeRightMenuState({
              showAllFilters: !showAll,
            })
          }
        />
        <Button
          label="Reset"
          height="30px"
          width="50%"
          theme={secondarySmallTheme}
          onClick={onReset}
        />
      </FlexRow>
    </FlexColumn>
  );
};

export default ImageAdjustments;
