import styled from 'styled-components';
import IconEventWrapper from '../Icon/IconEventWrapper';
import { inputActiveStyle } from '../NumberInput/styles';
import { WrappedNumberInputTheme } from './types';

const getBackgroundColor = ({
  theme,
  disabled,
  isDark,
}: {
  theme: WrappedNumberInputTheme;
  disabled?: boolean;
  isDark?: boolean;
}): string => {
  if (disabled) {
    if (isDark && theme.wrapperBackgroundColorDisabledDark) {
      return theme.wrapperBackgroundColorDisabledDark;
    }
    if (theme.wrapperBackgroundColorDisabled) {
      return theme.wrapperBackgroundColorDisabled;
    }
  }
  if (isDark && theme.wrapperBackgroundColorDark)
    return theme.wrapperBackgroundColorDark;
  return theme.wrapperBackgroundColor;
};

const getBorderColor = ({
  theme,
  disabled,
  isDark,
}: {
  theme: WrappedNumberInputTheme;
  disabled?: boolean;
  isDark?: boolean;
}): string => {
  if (disabled) {
    if (isDark && theme.wrapperBorderColorDisabledDark) {
      return theme.wrapperBorderColorDisabledDark;
    }
    if (theme.wrapperBorderColorDisabled) {
      return theme.wrapperBorderColorDisabled;
    }
  }
  if (isDark && theme.wrapperBorderColorDark)
    return theme.wrapperBorderColorDark;
  return theme.wrapperBorderColor;
};

export const Wrapper = styled(IconEventWrapper)<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${getBackgroundColor};
  padding: ${({ theme }) => theme.padding};
  width: ${({ theme }) => theme.width};
  height: ${({ theme }) => theme.height};
  min-height: ${({ theme }) => theme.minHeight};
  border-radius: ${({ theme }) => theme.wrapperBorderRadius};
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${getBorderColor};
  overflow: hidden;

  &:hover,
  &:focus-within {
    ${inputActiveStyle}
  }

  html[data-theme='dark'] && {
    background: ${(props) => getBackgroundColor({ ...props, isDark: true })};
    border-color: ${(props) => getBorderColor({ ...props, isDark: true })};
  }
`;

export const IconWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.iconWidth};
`;
