import React, { useState, useRef } from 'react';
import { Content } from './styles';
import Logo from '../../Logo/Logo';
import TextInput from '../../TextInput/TextInput';
import Button from '../../Button/Button/Button';
import { primaryTheme } from '../../Button/Button/theme';
import { EMAIL_REGEXP } from '../../../global/constants';

import Spacer from '../../Spacer/Spacer';
import { ForgotPasswordProps } from './types';
import { H2, P2 } from '../../utilities/Typography/styles';
import { forgotPasswordButtonTheme } from '../theme';
import { buildWebsiteUrl } from '../../../utils/url';
import userApi from '../../../global/userApi';

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const email = useRef<string | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [allowSubmit, setAllowSubmit] = useState(true);

  const getError = (text: string): string => {
    if (!EMAIL_REGEXP.test(text)) {
      return 'E-Mail is invalid';
    }
    return '';
  };

  const commit = (text: string): void => {
    email.current = text;

    const error = getError(text);
    setError(error);
  };

  const submit = async (): Promise<void> => {
    if (error) return;
    setAllowSubmit(false);
    try {
      const response = await userApi.requestPasswordResetCode(email.current!);
      if (response.error) {
        setError(response.error);
      } else {
        setSubmitted(true);
      }
    } catch (e) {
      setError('Failed to reset password.');
    }
    setAllowSubmit(true);
  };

  const renderNotSubmitted = (): JSX.Element => (
    <>
      <P2>Please enter your e-mail address to reset your password.</P2>
      <Spacer h="24px" />
      <TextInput
        placeholder="Your E-Mail"
        name="email"
        autoComplete="username"
        onEnter={submit}
        onChanging={(email: string): void => commit(email)}
        error={error}
      />
      <Spacer h={error ? '8px' : '12px'} />
      <Button
        label="Continue"
        theme={primaryTheme}
        isBlock
        height="40px"
        onClick={submit}
        disabled={!allowSubmit || error === undefined || !!error}
      />
      <Spacer h={'8px'} />
      <Button
        onClick={props.onGoBack}
        label="Back"
        isBlock
        height="22px"
        theme={forgotPasswordButtonTheme}
      />
    </>
  );

  const renderSubmitted = (): JSX.Element => (
    <>
      <P2>We sent you an email with password reset instructions</P2>
      <Spacer h="24px" />
      <P2>
        Didn't receive the email?
        <br />
        Check your spam folder or contact&nbsp;
        <a href={buildWebsiteUrl('/help')}>Support</a>.
        <Spacer h="24px" />
        <Button
          label="Back to Log In"
          theme={primaryTheme}
          isBlock
          height="40px"
          onClick={props.onGoBack}
        />
      </P2>
    </>
  );

  return (
    <Content>
      <Logo height={40} />
      <Spacer h="24px" />
      <H2>Forgot your Password?</H2>
      <Spacer h="8px" />
      {submitted ? renderSubmitted() : renderNotSubmitted()}
    </Content>
  );
};

export default ForgotPassword;
