import { composeTransforms } from '../../../utils/editor/misc';
import { getVectorAngle } from '../../../utils/geometry/vector';
import fabric from './index';

/**
 * Reworks paths gradients to be in "objectBoundingBox" space.
 * This makes it so that destructive operations we perform on paths (e.g scaling on export for BasicShapes)
 * won't break how the gradients look.
 * @param {fabric.Path[]} paths Array of paths
 */
export const normalizeGradients = (paths) => {
  for (const path of paths) {
    const { fill } = path;

    const isGradient = fill instanceof fabric.Gradient;
    const userSpaceOnUse = fill.gradientUnits === 'pixels'; // This means gradientUnits="userSpaceOnUse" in the svg file

    if (isGradient && userSpaceOnUse) {
      // With calcLineCoords we get the transformed *original* bounding box of the path
      const { tl, tr, bl } = path.calcLineCoords();
      const width = tr.x - tl.x; // Transformed width of path
      const height = bl.y - tl.y; // Transformed height of path

      if (!width || !height) {
        return;
      }

      const gradientTransform = fill.gradientTransform
        ? fill.gradientTransform
        : fabric.iMatrix.slice();

      const fromBounds = fabric.util.composeMatrix({
        translateX: tl.x,
        translateY: tl.y,
        scaleX: width,
        scaleY: height,
        angle: getVectorAngle(tr.x - tl.x, tr.x - tl.y) * (180 / Math.PI),
      });

      const toBounds = fabric.util.invertTransform(fromBounds);

      let userSpaceTransform = path.calcTransformMatrix().slice();
      userSpaceTransform = fabric.util.composeMatrix({
        ...fabric.util.qrDecompose(userSpaceTransform),
        // fabric applies some obscure offsetting to paths that I don't understand,
        // but line coordinates match svg coordinate space, so we adjust translate values
        translateX: tl.x,
        translateY: tl.y,
      });

      const finalGradientTransform = composeTransforms([
        gradientTransform,
        userSpaceTransform,
        toBounds,
      ]);

      fill.gradientTransform = finalGradientTransform;
      fill.gradientUnits = 'percentage';
    }
  }
};
