import hotkeys, { KeyHandler } from 'hotkeys-js';

import { isPriorityKeyCode } from '../keys';
import fabric from '../../components/Artboard/fabric';

interface Options {
  scope?: string;
  element?: HTMLElement | null;
  keyup?: boolean | null;
  keydown?: boolean | null;
  splitKey?: string;
}

interface HotkeyHandlerUtils {
  bind: (combination: string, handler: KeyHandler, options?: Options) => void;
  unbindAll: () => void;
}

const shouldDisableHotkeys = (canvas: fabric.Canvas): boolean =>
  canvas.hasMovingObject;

/**
 * Returns utils to bind and unbind hotkeys.
 */
export const createHotkeyHandlerUtils = (
  canvas: fabric.Canvas
): HotkeyHandlerUtils => {
  let combinations: string[] = [];

  const bind = (
    combination: string,
    handler: KeyHandler,
    options?: Options
  ): void => {
    const extendedHandler: KeyHandler = (event, ...rest) => {
      const disableHotkeys = shouldDisableHotkeys(canvas);
      const keyCode = event.code;
      if (!isPriorityKeyCode(keyCode) && disableHotkeys) return;

      handler(event, ...rest);
    };

    combinations.push(combination);

    return options
      ? hotkeys(combination, options, extendedHandler)
      : hotkeys(combination, extendedHandler);
  };

  const unbindAll = (): void => {
    combinations.forEach((combination) => {
      hotkeys.unbind(combination);
    });
    combinations = [];
  };

  return {
    bind,
    unbindAll,
  };
};
