import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import fabric from '../../components/Artboard/fabric';
import { MockupState } from './types';
import { canvasToMockupState } from './util';

interface MockupStore {
  exporting: boolean;
  uploading: boolean;
  state: MockupState;
  setState: (canvas: fabric.Canvas) => void;
  setExporting: (exporting: boolean) => void;
  setUploading: (uploading: boolean) => void;
}

const mockupStore = createVanilla<MockupStore>((set) => ({
  state: {},
  exporting: false,
  uploading: false,

  setState: (canvas: fabric.Canvas): void => {
    set({ state: canvasToMockupState(canvas) });
  },

  setExporting: (exporting: boolean): void => {
    set({ exporting });
  },

  setUploading: (uploading: boolean): void => {
    set({ uploading });
  },
}));

export const mockupStoreSelector = {
  state:
    (key: keyof MockupState) =>
    (state: MockupStore): string | undefined =>
      state.state[key],
  setState: (state: MockupStore): ((canvas: fabric.Canvas) => void) =>
    state.setState,
  exporting: (state: MockupStore): MockupStore['exporting'] => state.exporting,
  setExporting: (state: MockupStore): ((exporting: boolean) => void) =>
    state.setExporting,
  uploading: (state: MockupStore): MockupStore['uploading'] => state.uploading,
  setUploading: (state: MockupStore): ((uploading: boolean) => void) =>
    state.setUploading,
};

const useMockupStore = create(mockupStore);
export default useMockupStore;
