import {
  ACTIVE_COPY,
  ACTIVE_PASTE,
  ACTIVE_DELETE,
  ACTIVE_REFLECT_HORIZONTAL,
  ACTIVE_REFLECT_VERTICAL,
  LAYER_SEND_TO,
  ACTIVE_GROUP,
  ACTIVE_CLIPPING_MASK,
  ACTIVE_LOCK,
} from '../../global/events';

export const menuGroups = [
  [
    {
      icon: 'copy',
      label: 'Copy',
      shortcut: '⌘ + C',
      action: [ACTIVE_COPY],
    },
    {
      icon: 'paste',
      label: 'Paste',
      shortcut: '⌘ + V',
      action: [ACTIVE_PASTE],
    },
    {
      icon: 'delete',
      label: 'Delete',
      shortcut: 'Backspace',
      action: [ACTIVE_DELETE],
    },
    {
      icon: 'group',
      label: '(Un-)Group',
      shortcut: '⌘ + G',
      action: [ACTIVE_GROUP],
    },
    {
      icon: 'lock',
      label: 'Lock',
      action: [ACTIVE_LOCK],
    },
  ],
  [
    {
      icon: 'flipHorizontal',
      label: 'Flip Horizontal',
      action: [ACTIVE_REFLECT_HORIZONTAL],
    },
    {
      icon: 'flipVertical',
      label: 'Flip Vertical',
      action: [ACTIVE_REFLECT_VERTICAL],
    },
    {
      icon: 'clippingMask',
      label: 'Release Mask',
      action: [ACTIVE_CLIPPING_MASK],
    },
  ],
  [
    {
      icon: 'forward',
      label: 'Forward',
      action: [LAYER_SEND_TO, 'forward'],
    },
    {
      icon: 'backward',
      label: 'Backward',
      action: [LAYER_SEND_TO, 'backward'],
    },
    {
      icon: 'toFront',
      label: 'To Front',
      action: [LAYER_SEND_TO, 'front'],
    },
    {
      icon: 'toBack',
      label: 'To Back',
      action: [LAYER_SEND_TO, 'back'],
    },
  ],
];
