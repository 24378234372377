import isEqual from 'lodash/isEqual';

/* ==== fallback functions for CHARACTER_STYLES_RULES ==== */

const min = (values) => Math.min.apply(null, values);
const undefine = () => undefined;

/*
  Fallback for variation style.

  A variation looks like this:
  {
    variationAxis1: value1,
    variationAxis2: value2,
    ...
    ...
  }

  This function will return:
  {
    variationAxis1: min (...allVariationAxis1Values),
    variationAxis2: min (...allVariationAxis2Values),
    ...
    ...
  }
*/
const handleVariations = (variations) => {
  variations = variations.reduce((acc, variation) => {
    for (const [key, val] of Object.entries(variation)) {
      if (acc[key]) {
        acc[key].push(val);
      } else {
        acc[key] = [val];
      }
    }

    return acc;
  }, {});

  for (const [key, val] of Object.entries(variations)) {
    variations[key] = min(val.filter((e) => !!e));
  }

  return variations;
};

/* ==== fallback functions for CHARACTER_STYLES_RULES END ==== */

/*
  Add rules to CHARACTER_STYLES_RULES to define what styles can be edited 
  while selecting multiple text objects.

  fallback: when specified for [key], if all of the objects don't have
  the same value for the [key] property, it is applied to the array of all of these values

  dependsOn: a dependency that some [key] property depends on.
  Styles should be ordered in a way that they come after all of its dependencies, and they are only processed
  if all of their dependencies are not mixtures.

  e.g if fontFamily produces a { mixture: true, ... } object then fontWeight is automatically set to
  { mixture: true, value: undefined } since it depends on fontFamily being well defined.

*/
const CHARACTER_STYLES_RULES = {
  fontFamily: { fallback: undefine },
  fontWeight: { fallback: undefine, dependsOn: 'fontFamily' },
  textAlignment: { fallback: undefine },
  uppercase: { fallback: undefine },
  useLigatures: { fallback: undefine },
  lineHeight: { fallback: min },
  letterSpacing: { fallback: min },
  fontSize: { fallback: min },
  variationAxes: { fallback: undefine, dependsOn: 'fontFamily' },
  variation: {
    fallback: handleVariations,
    dependsOn: 'variationAxes',
  },
};

// Keep group scale into account when getting font size
const getFontSize = (obj) => {
  const { scaleX, scaleY } = obj.group?.getObjectScaling() || {
    scaleX: 1,
    scaleY: 2,
  };
  return (
    (obj.fontSize * obj.scaleX * obj.pathText.scaleX * (scaleX + scaleY)) / 2
  );
};

// Getters for each style
// If no getter is present for some style, its raw value is retrieved from the object
const styleGetters = {
  fontSize: getFontSize,
};

export default (activeObjects) => {
  const styles = {};

  for (const [key, { fallback, dependsOn }] of Object.entries(
    CHARACTER_STYLES_RULES
  )) {
    if (dependsOn) {
      if (styles[dependsOn].mixture) {
        styles[key] = { mixture: true };
        continue;
      }
    }

    for (let index = 0; index < activeObjects.length; index++) {
      const object = activeObjects[index];
      const styleValue = styleGetters[key]
        ? styleGetters[key](object)
        : object[key];

      // If key is not defined for styles, set it to the [key] property value of the object,
      // and declare mixture = false, since we still don't know
      if (!styles[key]) {
        styles[key] = { value: styleValue, mixture: false };
        continue;
      }

      // Else, set mixture to true, and set value to the fallback of all [key] property values,
      // according to CHARACTER_STYLE_RULES
      if (!isEqual(styleValue, styles[key].value)) {
        let value;
        if (fallback.length) {
          // If fallback expects any arguments
          value = fallback(
            Array.prototype.concat.apply(
              [styles[key].value, styleValue],
              activeObjects.slice(index + 1).map((object) => styleValue)
            )
          );
        } else {
          // Else avoid further processing on the array
          value = fallback();
        }

        styles[key] = {
          value,
          mixture: true,
        };

        break;
      }
    }
  }

  return styles;
};
