import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { IconEventStyle } from '../Icon/styles';
import { QUERIES } from '../utilities/queries';

export const Wrapper = styled.div`
  width: 100%;
  pointer-events: auto;

  ${IconEventStyle} {
    width: 100%;
  }

  ${QUERIES.laptopAndUp} {
    max-width: 60%;
  }
`;

export const Label = styled.div`
  font-family: ${themeStyle.fontFamily};
  font-size: ${themeStyle.fontSize12};
  font-weight: ${themeStyle.fontSemiBold};
  line-height: 15px;
  margin-top: 1px;

  flex-shrink: 0;
  min-width: 165px;
  text-align: left;
  color: ${themeStyle.varInkMedium};
`;

export const InnerWrapper = styled.span`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const UnstyledButton = styled.button`
  background-color: transparent;
  border: none;
  border-width: 0;
  padding: 0;
  cursor: pointer;
`;
