import styled from 'styled-components';
import { themeStyle } from '../../../../services/theming';

export const NotificationHeaderWrapper = styled.div<{ hasUser: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasUser }) => (hasUser ? '38px 1fr' : '1fr')} 85px;
`;

export const CreatedAt = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const UsernameAndMessage = styled.div`
  overflow: hidden;
`;

export const UnreadDot = styled.div`
  margin-top: 6px;
  background-color: ${themeStyle.varAlertRed};
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-right: 4px;
`;
