import fabric from '../../components/Artboard/fabric';

/**
 * check whether the active selection is mask selection
 */
export const isMaskGroup = (activeObjects: fabric.Object[]): boolean => {
  const target = activeObjects.find((obj) =>
    ['basicShape', 'illustration', 'illustrationImage'].includes(obj.type)
  );
  const suitable =
    target && activeObjects.find((obj) => ['mask'].includes(obj.type));
  if (activeObjects.length === 2 && suitable) {
    const maskedObject = target.clipPathMaskId;
    if (maskedObject) {
      return true;
    }
  }

  return false;
};

const _isMaskTarget = (
  target: fabric.Object | null,
  canvas: fabric.Canvas
): fabric.Object | null => {
  if (target?.type === 'mask') {
    return target;
  }

  if (target?.clipPathMaskId) {
    const mask = canvas
      .getObjects()
      .find(
        (object: fabric.Object) =>
          object.id === target.clipPathMaskId && object.type === 'mask'
      );
    if (mask) return mask;
  }

  return null;
};

/**
 * check whether there is a mask that can be used as target when an object is added.
 * This is the case, when the object is dropped onto a mask
 */
export const findMaskTarget = (
  eventValue: { dropX?: number; dropY: number },
  canvas: fabric.Canvas
): fabric.Object | null => {
  // test whether element was dropeed onto a mask
  const dropX = eventValue?.dropX;
  const dropY = eventValue?.dropY;
  if (dropX !== undefined && dropY !== undefined) {
    const { target, targets } = canvas._searchAllPossibleTargets(
      canvas.getObjects(),
      {
        x: dropX,
        y: dropY,
      }
    );

    const dropTarget = _isMaskTarget(target, canvas);
    if (dropTarget) return dropTarget;

    for (const object of targets) {
      const dropTarget = _isMaskTarget(object, canvas);
      if (dropTarget) return dropTarget;
    }
  }

  return null;
};

/**
 * if a mask object was targeted, create a mask with it
 */
export const tryMasking = (
  canvas: fabric.Canvas,
  target: fabric.Object,
  mask: fabric.Object
): void => {
  if (!mask) return;
  // if already masked, remove old mask
  if (mask.isClipPath) {
    const currentTarget = canvas
      .getObjects()
      .find((object: fabric.Object) => object.clipPathMaskId === mask.id);
    if (currentTarget) {
      currentTarget.removeClipPath(currentTarget);
      canvas.remove(currentTarget);
      canvas.onAddRemoveObject();
    }
  }
  target.positionRelativeToObject(mask);

  target.addClipPath(mask.id, true);
  canvas.createClippingMaskStructure(target, mask);
  canvas.fire('object:modified'); // history update
};
