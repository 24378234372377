export const transformRecursive = (array, callback) => {
  if (!array) return undefined;
  return array.map((item) => {
    if (item.children?.length) {
      const children = transformRecursive(item.children, callback);
      return callback({ ...item, children });
    }
    return callback(item);
  });
};

export const findRecursive = (array, callback) => {
  const result = array.find(callback);
  if (result !== undefined) return result;

  for (const child of array) {
    if (child.children) {
      const resultChild = findRecursive(child.children, callback);
      if (resultChild) return resultChild;
    }
  }

  return undefined;
};

export const filterRecursive = (array, callback) => {
  if (!array) return undefined;
  return array.reduce((acc, item) => {
    if (callback(item)) {
      if (item.children?.length) {
        const children = filterRecursive(item.children, callback);
        if (children.length) return [...acc, { ...item, children }];

        const { children: c, ...newItem } = item;
        return [...acc, newItem];
      }
      return [...acc, item];
    }
    return acc;
  }, []);
};

/**
 * loopRecurive must be used with caution.
 */
export const loopRecursive = (data, key, callback) => {
  data.forEach((item, index, arr) => {
    if (item.key === key) {
      callback(item, index, arr);
      return;
    }
    if (item.children) {
      loopRecursive(item.children, key, callback);
    }
  });
};
