import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import { buildSources } from '../../../utils/url';
import { matchObjectPosition } from '../../../utils/editor/objects';
import { ImageFilterType } from '../../../types';

export const defaultFilterOptions = {
  [ImageFilterType.Saturation]: {
    saturation: 0,
  },
  [ImageFilterType.Brightness]: {
    brightness: 0,
  },
  [ImageFilterType.Contrast]: {
    contrast: 0,
  },
  [ImageFilterType.RemoveColor]: {
    color: null,
    distance: 0,
  },
  [ImageFilterType.Vibrance]: {
    vibrance: 0,
  },
  [ImageFilterType.HueRotation]: {
    rotation: 0,
  },
  [ImageFilterType.Noise]: {
    noise: 0,
  },
  [ImageFilterType.Pixelate]: {
    blocksize: 1,
  },
  [ImageFilterType.Blur]: {
    blur: 0,
  },
  [ImageFilterType.Convolute]: {
    matrix: [0, 0, 0, 0, 1, 0, 0, 0, 0], // Identity kernel
  },
};

export default function (fabric) {
  const fabricFilters = fabric.Image.filters;

  fabric.IllustrationImage = fabric.util.createClass(fabric.Image, {
    type: 'illustrationImage',

    initialize: function (objectName, options, cb) {
      const { src, previewSrc } = buildSources(objectName);

      options || (options = {});

      let initOptions = { ...options };
      delete initOptions.width;
      delete initOptions.height;
      delete initOptions.scaleX;
      delete initOptions.scaleY;
      delete initOptions.angle;
      delete initOptions.left;
      delete initOptions.top;
      delete initOptions.filters;

      const hasTransformOptions =
        !options.wasDropped &&
        Object.keys(options).length &&
        Object.keys(initOptions).length !== Object.keys(options).length;

      if (options.wasDropped) initOptions = options;

      initOptions.objectName = objectName;

      this.callSuper('initialize', '', initOptions);

      this.trueRender = this.clipPath;
      this.clipPathMaskId = options.clipPathMaskId;
      this.findClipPath();
      this.createFilters(options.filters);

      /*
        setSrc messes up objects transforms, so we call it inside fromURL so that we can take
        a snapshot of the current object (when it applies), and match transforms later

        In some other cases, (when there are no transform options in 'options'), we copy
        transforms from the target that fromURL exposes, since positioning is resolved in our
        Image class in adjustToArtboard and adjustToDrop
      */
      if (!fabric.skipImagePreviews) {
        fabric.Image.fromURLRetry(
          previewSrc,
          (target, error) => {
            if (this.original || error) return;
            this.setSrc(
              previewSrc,
              () => {
                if (this.original) return;

                this.preview = true;
                const refObj = hasTransformOptions ? options : target;
                matchObjectPosition(this, refObj);

                cb && cb(this);
              },
              { crossOrigin: 'anonymous' }
            );
          },
          { ...initOptions, crossOrigin: 'anonymous' }
        );
      }

      fabric.Image.fromURLRetry(
        src,
        (target, error) => {
          if (error && !this.preview) {
            cb && cb(this);
            return;
          }

          if (this.preview) {
            const backup = { ...this };

            this.setSrc(
              src,
              () => {
                matchObjectPosition(this, backup);
                this.setCoords();

                this.canvas?.requestRenderAll();
              },
              { crossOrigin: 'anonymous' }
            );
          } else {
            this.original = true;
            this.setSrc(
              src,
              () => {
                const refObj = hasTransformOptions ? options : target;
                matchObjectPosition(this, refObj);
                this.setCoords();
                if (!this.preview) cb && cb(this);
              },
              { crossOrigin: 'anonymous' }
            );
          }
        },
        { ...initOptions, crossOrigin: 'anonymous' }
      );

      this.on('moving', this.tryPreviewMaskOnMoving);
      this.on('moved', this.tryMaskingOnMoved);
    },

    createFilters: function (filters = []) {
      const filtersInOrder = [
        ImageFilterType.Saturation,
        ImageFilterType.Contrast,
        ImageFilterType.Brightness,
        ImageFilterType.Vibrance,
        ImageFilterType.HueRotation,
        ImageFilterType.Noise,
        ImageFilterType.Blur,
        ImageFilterType.Convolute,
        ImageFilterType.Pixelate,
        ImageFilterType.RemoveColor,
      ];

      this.allFilters = filtersInOrder.map((type) => {
        const options = filters.find((filter) => filter.type === type) ?? {};
        return new fabricFilters[type]({
          ...defaultFilterOptions[type],
          ...options,
        });
      });

      const usedFilters = filters.filter(
        (filter) =>
          !intersection(
            Object.keys(filter),
            Object.keys(defaultFilterOptions[filter.type])
          ).every((key) =>
            isEqual(filter[key], defaultFilterOptions[filter.type][key])
          )
      );

      this.filters = this.allFilters.filter((filter) =>
        usedFilters.map((f) => f.type).includes(filter.type)
      );
    },

    toObject: function () {
      const object = this.callSuper('toObject');
      const unusedAttrs = [
        'skewX',
        'skewY',
        'clipPath',
        'fill',
        'stroke',
        'strokeWidth',
        'strokeUniform',
      ];
      unusedAttrs.forEach((attr) => {
        delete object[attr];
      });

      return fabric.util.object.extend(object, {
        objectName: this.objectName,
        clipPathMaskId: this.clipPathMaskId
          ? this.clipPathMaskId
          : this.clipPath?.id,
      });
    },

    /**
     * Allows setting a new object name for illustration and keeps it's size and position
     * Inspired by the way we handle preview images
     * @param {string} newObjectName
     * @param {function} callback
     */
    setObjectName: function (newObjectName, callback) {
      const { src } = buildSources(newObjectName);
      const backup = { ...this };
      this.setSrc(
        src,
        () => {
          matchObjectPosition(this, backup);
          this.objectName = newObjectName;
          this.canvas?.requestRenderAll();
          callback && callback();
        },
        { crossOrigin: 'anonymous' }
      );
    },

    switchFastRendering: function (enable) {
      this.trueRender = !enable && this.clipPath;
    },

    updateFilters: function ({ type, value: filterValue }) {
      let filter = this.filters.find((filter) => filter.type === type);
      if (!filter) {
        filter = this.allFilters.find((filter) => filter.type === type);
        this.filters.unshift(filter);
      }
      Object.entries(filterValue).forEach(([key, value]) => {
        filter[key] = value;
      });
      this.filters = this.filters.filter(
        (filter) =>
          !intersection(
            Object.keys(filter),
            Object.keys(defaultFilterOptions[filter.type])
          ).every((key) =>
            isEqual(filter[key], defaultFilterOptions[filter.type][key])
          )
      );
      this.applyFilters();
    },

    resetFilters: function () {
      this.filters = [];
      this.applyFilters();
    },
  });

  fabric.IllustrationImage.fromObject = function (object, callback) {
    new fabric.IllustrationImage(object.objectName, object, (target) => {
      callback(target);
    });
  };
}
