import styled from 'styled-components';
import { themeStyle } from '../../services/theming';

import { isIpadOperatingSystem } from '../../utils/detection';

export const scrollbarWidth = '13px';

export const Scrollable = styled.div<{ needsPadding: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-gutter: stable;
  scroll-behavior: smooth;
  scrollbar-width: auto;
  ::-webkit-scrollbar {
    width: ${scrollbarWidth};
  }
  scrollbar-color: ${themeStyle.varInkLight} transparent;
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${themeStyle.varInkLight};
    border-radius: 7px;
    border: 3px solid transparent;
  }

  ${({ needsPadding }): string =>
    needsPadding || isIpadOperatingSystem()
      ? `padding-right: ${scrollbarWidth} !important;`
      : ''}
`;
