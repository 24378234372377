/**
 * Currently that's a simple version of CMS api client
 */
import analytics from './analytics';
import {
  SEARCH_ELEMENTS,
  SEARCH_PHOTOS,
  SEARCH_TEMPLATES,
  SEARCH_TEXTURES,
} from './analytics/events';
import { useUserStore } from '../stores/userStore';
import { fetchRetry } from '../utils/fetchRetry';
import { getAccessToken } from './userApi';
import {
  ElementProps,
  ElementTag,
  ElementGroup,
  ElementCategory,
  UnsplashPhoto,
  Font,
  Template,
  ArtboardLayout,
  User,
} from '../types';

const SEARCH_INDEX_ELEMENTS = 'elements';

export const CMS_API_URL =
  process.env.REACT_APP_CMS_API_URL ||
  process.env.STORYBOOK_CMS_API_URL ||
  process.env.NEXT_PUBLIC_CMS_API_URL;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createAPI = ({ url }: { url: string | undefined }) => {
  // gets the list of categories and groups in them
  const getOverview = async (): Promise<{
    overview: {
      [key: string]: {
        elements: ElementProps[];
        id: number;
        name: string;
        popularity: number;
        skip: number;
        take: number;
        total: number;
      }[];
    };
  }> => {
    const resp = await fetchRetry(`${url}/api/overview`);
    const overview = await resp.json();

    return overview;
  };

  // gets the list of categories and groups in them
  const getTextureOverview = async (): Promise<{
    overview: {
      [key: string]: {
        elements: ElementProps[];
        id: number;
        name: string;
        popularity: number;
        skip: number;
        take: number;
        total: number;
      }[];
    };
  }> => {
    const resp = await fetchRetry(`${url}/api/textures/overview`);
    const overview = await resp.json();

    return overview;
  };

  // get the elements of specified group
  const getGroupElements = async (
    groupId: string,
    take = 10,
    skip = 0,
    sort = 'name'
  ): Promise<
    { error: string } | { skip: number; take: number; elements: ElementProps[] }
  > => {
    const resp = await fetchRetry(
      `${url}/api/groups/${groupId}?take=${take}&skip=${skip}&sort=${sort}`
    );
    const elements = await resp.json();

    return elements;
  };

  const getSearchElements = async (
    query: string,
    take = 10,
    skip = 0,
    isFirstQuery = true
  ): Promise<{
    skip: number;
    take: number;
    query: string;
    elements: ElementProps[];
    total: number;
  }> => {
    if (!process.env.REACT_APP_OPENSEARCH_URL) {
      const resp = await fetchRetry(
        `${url}/api/elements/search?query=${encodeURIComponent(
          query
        )}&take=${take}&skip=${skip}`
      );
      const result = await resp.json();
      if (isFirstQuery)
        analytics.track(SEARCH_ELEMENTS, {
          label: query,
          value: result.total,
        });
      return result;
    } else {
      const queryDSL = {
        from: skip,
        size: take,
        query: {
          bool: {
            must: {
              query_string: {
                // The \p{L} escape matches any Unicode character that belongs to the
                // "letter" category, which includes both ASCII and Unicode alphabetic
                // characters. The \p{N} escape matches any Unicode character that
                // belongs to the "number" category, which includes both ASCII and
                // Unicode numeric characters. The u flag at the end of the regular
                // expression enables the Unicode Property Escapes syntax.
                query: query.replace(/[^\p{L}\p{N} ]/gu, ''),
              },
            },
            must_not: [
              { match: { category: 'overlay' } },
              { match: { category: 'background' } },
              { match: { category: 'pattern' } },
            ],
          },
        },
      };
      const url = new URL(process.env.REACT_APP_OPENSEARCH_URL);
      const resp = await (
        await fetchRetry(
          `${url.protocol}//${url.host}/${SEARCH_INDEX_ELEMENTS}/_search`,
          {
            method: 'POST',
            body: JSON.stringify(queryDSL),
            headers: {
              'Content-Type': 'application/json',
              Authorization:
                'Basic ' + window.btoa(url.username + ':' + url.password),
            },
          }
        )
      ).json();
      const result = {
        elements:
          resp?.hits?.hits?.map((h: { _source: ElementProps }) => h._source) ??
          [],
        query,
        skip,
        take,
        total: resp?.hits?.total?.value ?? 0,
      };
      if (isFirstQuery)
        analytics.track(SEARCH_ELEMENTS, {
          label: query,
          // We can't get total for all combined tags so only direct result count is logged
          value: result.total,
        });
      return result;
    }
  };

  const getSearchTextures = async (
    query: string,
    take = 10,
    skip = 0,
    sort = 'name'
  ): Promise<{
    skip: number;
    take: number;
    query: string;
    elements: ElementProps[];
    total: number;
  }> => {
    const resp = await fetchRetry(
      `${url}/api/elements/search?query=${encodeURIComponent(
        query
      )}&take=${take}&skip=${skip}&isTexture=true&sort=${sort}`
    );

    const result = await resp.json();
    if (skip === 0)
      analytics.track(SEARCH_TEXTURES, {
        label: query,
        value: result.total,
      });

    return result;
  };

  const getElementsSuggestions = async (): Promise<{
    tags: ElementTag[];
    groups: ElementGroup[];
  }> => {
    const resp = await fetchRetry(`${url}/api/elements/suggestions`);
    const allTags = await resp.json();
    return allTags;
  };

  const getTextureSuggestions = async (): Promise<{
    tags: ElementTag[];
    groups: ElementGroup[];
  }> => {
    const resp = await fetchRetry(
      `${url}/api/elements/suggestions?isTexture=true`
    );
    const allTags = await resp.json();
    return allTags;
  };

  // get the fonts
  const getFonts = async (): Promise<{ fonts: Font[] }> => {
    const resp = await fetchRetry(`${url}/api/fonts/all`);
    const fonts = await resp.json();

    return fonts;
  };

  // gets the list of template categories and groups in them
  const getTemplatesOverview = async (): Promise<{
    templates: {
      [key: string]: {
        templates: Template[];
        id: number;
        name: string;
        popularity: number;
        skip: number;
        take: number;
        total: number;
      }[];
    };
  }> => {
    const resp = await fetchRetry(`${url}/api/templates/overview`);
    return await resp.json();
  };

  const updateBookmarks = async (
    templates: Template[]
  ): Promise<Template[]> => {
    const bookmarks = useUserStore.getState().bookmarks;
    if (bookmarks.length === 0) return templates;

    return templates?.map((template: Template) => ({
      ...template,
      bookmarked: !!bookmarks.find((id) => Number(id) === template.id),
    }));
  };

  // gets the templates of specified group
  const getGroupTemplates = async (
    groupId: string,
    take = 10,
    skip = 0,
    sort = 'name'
  ): Promise<{
    templates: Template[];
    skip: number;
    take: number;
    sort: string | null;
  }> => {
    const resp = await fetchRetry(
      `${url}/api/templates/${
        groupId === 'all' ? 'all' : `groups/${groupId}`
      }?take=${take}&skip=${skip}&sort=${sort}`
    );
    const result = await resp.json();

    const withBookmarks = await updateBookmarks(result.templates);
    return { ...result, templates: withBookmarks };
  };

  // gets the templates of recommended group in specified category
  const getRecommendedGroupTemplates = async (
    category: string
  ): Promise<{
    templates: Template[];
  }> => {
    const resp = await fetchRetry(
      `${url}/api/templates/groups/recommended/${category}`
    );
    const result = await resp.json();

    // NOTE: the error handling here is not ideal
    if (!result.templates) {
      return { templates: [] };
    }

    const withBookmarks = await updateBookmarks(result.templates);
    return { ...result, templates: withBookmarks };
  };

  // gets the list of template categories and groups in them
  const getTemplate = async (
    id: string,
    preview?: string
  ): Promise<{ success: boolean; template: Template }> => {
    const resp = await fetchRetry(
      `${url}/api/templates/${id}${preview ? '?preview=true' : ''}`
    );
    return await resp.json();
  };

  const getSearchTemplates = async (
    query: string,
    take = 10,
    skip = 0,
    sort = 'name',
    category: string
  ): Promise<{
    skip: number;
    take: number;
    query: string;
    sort: string;
    templates: Template[];
    total: number;
  }> => {
    const resp = await fetchRetry(
      `${url}/api/templates/search?query=${encodeURIComponent(
        query
      )}&take=${take}&skip=${skip}&sort=${sort}${
        category?.length ? `&category=${category}` : ''
      }`
    );

    const result = await resp.json();
    if (skip === 0)
      analytics.track(SEARCH_TEMPLATES, {
        label: query,
        value: result.total,
      });

    return result;
  };

  const getTemplateSuggestions = async (
    category: string
  ): Promise<{
    names: string[];
    authors: string[];
    tags: string[];
    groups: string[];
    categories: string[];
  }> => {
    const resp = await fetchRetry(
      `${url}/api/templates/suggestions${
        category?.length ? `?category=${category}` : ''
      }`
    );
    const allTags = await resp.json();
    return allTags;
  };

  // update the template, only supposed to be used by dev/design teams
  const __updateTemplate = async (
    id: string,
    template: Template,
    preview: string,
    category: string,
    groups: string[],
    name: string,
    tags: string[],
    description: string
  ): Promise<{ success: boolean; template: Template }> => {
    const resp = await fetchRetry(`${url}/api/templates/${id}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({
        state: template,
        preview,
        category,
        groups,
        name,
        tags,
        description,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    const result = await resp.json();
    return result;
  };

  // save the text layout, only supposed to be used by dev/design teams
  const __saveTextLayout = async (
    textLayout: ArtboardLayout,
    preview: string
  ): Promise<{ success: boolean; layout: { id: string } }> => {
    const resp = await fetchRetry(`${CMS_API_URL}/api/layouts/create`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        layout: textLayout,
        preview,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    const result = await resp.json();
    return result;
  };

  // store the elements as ids, for which the use was registered
  const registeredElements: string[] = [];

  // register the use of an element
  const registerElementUse = async (id: string): Promise<boolean> => {
    // register the use of elements only once per session
    if (registeredElements.includes(id)) return false;

    registeredElements.push(id);
    try {
      await fetchRetry(`${url}/api/elements/use`, {
        method: 'POST',
        body: JSON.stringify({ id }),
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (_error) {
      return false;
    }
    return true;
  };

  // store the templates as ids, for which the use was registered
  const registeredTemplates: string[] = [];

  // register the use of a template
  const registerTemplateUse = async (id: string): Promise<boolean> => {
    // register the use of templates only once per session
    if (registeredTemplates.includes(id)) return false;

    registeredTemplates.push(id);

    const user = await useUserStore.getState().getUser();
    const userId = user ? user.id : undefined;
    try {
      await fetchRetry(`${url}/api/templates/use`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, userId }),
      });
    } catch (_error) {
      return false;
    }
    return true;
  };

  /**
   * TEXT LAYOUTS
   */

  // gets the list of text layouts groups in them
  const getTextLayoutsOverview = async (): Promise<{
    layouts: {
      [key: string]: {
        layouts: ArtboardLayout[];
        id: number;
        name: string;
        skip: number;
        take: number;
        total: number;
      }[];
    };
  }> => {
    const resp = await fetchRetry(`${url}/api/layouts/overview`);
    return await resp.json();
  };

  // gets the text layouts of specified group
  const getGroupTextLayouts = async (
    groupId: string,
    take = 10,
    skip = 0,
    sort = 'name'
  ): Promise<{
    group: ElementGroup;
    layouts: ArtboardLayout;
    skip: number;
    take: number;
  }> => {
    const resp = await fetchRetry(
      `${url}/api/layouts/groups/${groupId}?take=${take}&skip=${skip}&sort=${sort}`
    );
    return await resp.json();
  };

  // gets the list of text layouts categories and groups in them
  const getTextLayout = async (
    id: string
  ): Promise<{ success: boolean; layout: ArtboardLayout }> => {
    const resp = await fetchRetry(`${url}/api/layouts/${id}`);
    return await resp.json();
  };

  // get information about a cms creator
  const getCreator = async (
    id: string
  ): Promise<{ success: boolean; user: User }> => {
    const resp = await fetchRetry(`${url}/api/users/${id}`);
    return await resp.json();
  };

  const getCategories = async (): Promise<{
    categories: ElementCategory[];
  }> => {
    const resp = await fetchRetry(`${url}/api/templates/categories/all`);
    return await resp.json();
  };

  const getTrendingPhotos = async (
    take = 10,
    skip = 0
  ): Promise<{
    results: UnsplashPhoto[];
    skip: number;
    take: number;
    cached: boolean;
  }> => {
    const resp = await fetchRetry(
      `${url}/api/unsplash/trending?take=${take}&skip=${skip}`
    );
    return await resp.json();
  };

  const searchUnsplashPhotos = async (
    query: string,
    take = 10,
    skip = 0
  ): Promise<{
    total?: number;
    total_pages?: number;
    cached?: boolean;
    results: UnsplashPhoto[];
  }> => {
    let result;

    try {
      const resp = await fetchRetry(
        `${url}/api/unsplash/search/${encodeURIComponent(
          query
        )}?take=${take}&skip=${skip}`
      );
      result = await resp.json();
    } catch (_) {
      return { results: [] };
    }

    if (skip === 0)
      analytics.track(SEARCH_PHOTOS, {
        label: query,
        value: result.total,
      });

    return result;
  };

  const flagUnsplashDownload = async (
    id: string,
    downloadLocation: string
  ): Promise<{ success: boolean } | { error: string }> => {
    const resp = await fetchRetry(`${url}/api/unsplash/download`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, downloadLocation }),
    });
    return await resp.json();
  };

  return {
    getOverview,
    getTextureOverview,
    getGroupElements,
    getSearchElements,
    getSearchTextures,
    getElementsSuggestions,
    getTextureSuggestions,
    getFonts,
    getTemplatesOverview,
    getSearchTemplates,
    getTemplateSuggestions,
    getGroupTemplates,
    getRecommendedGroupTemplates,
    getTemplate,
    __updateTemplate,
    __saveTextLayout,
    registerElementUse,
    registerTemplateUse,
    getTextLayoutsOverview,
    getGroupTextLayouts,
    getTextLayout,
    getCreator,
    getCategories,
    getTrendingPhotos,
    searchUnsplashPhotos,
    flagUnsplashDownload,
  };
};

export default createAPI({ url: CMS_API_URL });
