import cloneDeep from 'lodash/cloneDeep';
import { loadFont } from '../../../../font/fonts';
import {
  substituteAlternativeGlyphs,
  substituteGlyphsInText,
} from '../../../../font/glyphSubstitution';
import {
  getCenterFromOptions,
  isAltKeyRotation,
} from '../../../../utils/editor/misc';
import { getPaths } from '../../../../font/loadPath';
import { isOnDevEnvironment } from '../../../../global/environment';
import { isDebugActive } from '../../../../utils/dev';
import { CURVED_TRANSFORMS } from '../transformConfig';
import applyTransform from './utils/transformation/applyTransform';
import applyShadow from './utils/shadow/applyShadow';
import createPerformancer from '../../../../utils/performancer';
import { MAX_FONT_SIZE } from '../../../../global/constants';
import { getXHeight } from '../../../../font/util';
import { rotatePointAroundSinglePoint } from '../../../../utils/editor/points';
import { calcOffset, rotationOffset } from '../../../../utils/editor/angle';

const PATH_TEXT_PROPS = ['stroke', 'strokeWidth'];
const PATH_SHADOW_PROPS = ['visible', 'opacity'];

const DEFAULTS = {
  shadowColor: 'rgba(114, 114, 114, 1)',
  lineHeight: 400,
  letterSpacing: 0,
  fontSize: 50,
  transparent: 'rgba(0,0,0,0)', // transparent, actual fill color is handled by fillRect
  stroke: 'rgba(193,193,193,1)',
  textAlignment: 'center',
  shadowOutlineWidth: 0,
};

const getStrokeColor = (stroke) => {
  if (stroke === null) return DEFAULTS.transparent;
  if (!stroke) return DEFAULTS.stroke;
  return stroke;
};

export function initPathText(fabric) {
  // allow re-initialize on dev environment to prevent issues with fast refresh
  if (fabric.PathText && !isOnDevEnvironment()) {
    fabric.warn('fabric.PathText is already defined');
    return;
  }

  // Helper function to parse svg paths for fabric
  const parsePath = (path) =>
    fabric.util.makePathSimpler(fabric.util.parsePath(path));

  /**
   * PathText class
   * This class handles all the effects and decorations that text can have
   * @class fabric.PathText
   * @extends fabric.Path
   */
  fabric.PathText = fabric.util.createClass(fabric.Group, {
    type: 'pathText',
    lockSkewingX: true,
    lockSkewingY: true,

    initialize: function (text, options, cb) {
      options || (options = {});
      const perf = createPerformancer(`initialize ${options.id || 'new'}`);
      perf.mark('init');

      text = text || 'Sample text';

      // handle pathText being initialized with scaling
      const scaleX = options.scaleX || 1;
      const scaleY = options.scaleY || 1;
      if (scaleX !== 1 || scaleY !== 1) {
        const scale = (scaleX + scaleY) / 2;
        // width and height must be set here, to get a correct stroke
        options.width = options.width * scaleX;
        options.height = options.height * scaleY;
        options.fontSize = (options.fontSize || DEFAULTS.fontSize) * scale;
        options.scaleX = 1;
        options.scaleY = 1;
      }

      this.callSuper('initialize', [], { ...options }, true);
      this.set('trueRender', true);
      this.set('objectCaching', false);

      this.set('text', text);
      this.set('width', options.width);
      this.set('fontFamily', options.fontFamily);
      this.set('fontWeight', options.fontWeight);

      // create name when loading initialising object
      this.set('name', options.name || null);

      this.set('fontSize', options.fontSize || DEFAULTS.fontSize);
      this.set(
        'letterSpacing',
        options.letterSpacing || DEFAULTS.letterSpacing
      );
      this.set(
        'textAlignment',
        options.textAlignment || DEFAULTS.textAlignment
      );
      this.set('uppercase', !!options.uppercase);
      this.set('underline', !!options.underline);
      this.set(
        'lineHeight',
        options.lineHeight !== undefined
          ? options.lineHeight
          : DEFAULTS.lineHeight
      );

      const { shadowOptions } = options;
      if (['block', 'detail'].includes(shadowOptions?.type)) {
        if (shadowOptions.outlineWidth === undefined) {
          shadowOptions.outlineWidth = DEFAULTS.shadowOutlineWidth;
        }
      }

      this.set(
        'shadowOptions',
        options.shadowOptions || { color: DEFAULTS.shadowColor }
      );

      this.set('transformOptions', cloneDeep(options.transformOptions || {}));
      this.points = this.transformOptions.points;
      this.set('variation', options.variation || {});
      this.set('variationAxes', options.variationAxes || {});
      this.set('visibility', !!options.visibility);
      this.set(
        'useLigatures',
        options.useLigatures !== undefined ? !!options.useLigatures : true
      );

      this.pathMask = new fabric.ClipPath();
      // fillRect is a rectangle, that's positioned below all other child objects of pathText
      // and handles fill color
      this.fillRect = new fabric.Rect();
      // pathShadow is a path that represents a shadow (line, long and detail)
      // it is rendered below the text
      this.pathShadow = new fabric.Path('', {
        fill: this.shadowOptions.color,
        stroke: this.shadowOptions.color,
        strokeWidth: 0,
        strokeLineJoin: 'round',
        objectCaching: false,
        opacity: options.opacity,
      });
      // pathDecoration is a path that represents a decoration for a text (only used in detail shadow)
      // it is rendered above the text
      this.pathDecoration = new fabric.Path('', {
        fill: this.stroke,
        objectCaching: false,
        opacity: options.opacity,
      });
      // pathDecorationShadow is a path that represents a shadow for a pathDecoration (only used in detail shadow)
      // it is rendered above the text, but below the pathDecoration
      this.pathDecorationShadow = new fabric.Path('', {
        fill: this.shadowOptions.color,
        objectCaching: false,
        opacity: options.opacity,
      });
      // pathText is a path that represent the text itself
      this.pathText = new fabric.Path('', {
        fill: DEFAULTS.transparent,
        // getStrokeColor conditionally selects between transparent,
        // initial color or options value.
        stroke: getStrokeColor(options.stroke),
        strokeWidth: options.strokeWidth || 0,
        objectCaching: false,
      });

      // override `_finalizeDimensions` to remove strokeWidth from the calculation
      this.pathText._finalizeDimensions = (x, y) => ({ x, y });

      // We need to enable horizontal middle controls back, as they're disabled on Object level
      // They are used to set the width of the text.
      if (!this.transformOptions?.type) {
        this.setControlsVisibility({
          mr: true,
          ml: true,
        });
      }

      perf.mark('before updateText');
      this.updateText(() => {
        perf.mark('after updateText');
        this._updateClipPath();
        this.addWithUpdate(this.pathText);
        options.width && this.set('width', options.width);
        options.height && this.set('height', options.height);

        options.stroke && this.set('stroke', options.stroke);
        options.strokeWidth && this.set('strokeWidth', options.strokeWidth);
        this.previousStrokeWidth = this.strokeWidth;

        const isChanging = true; // we don't want state updates at this point
        this.setShadow({ ...this.shadowOptions, isChanging });
        this.setDecoration({ ...this.decorationOptions, isChanging });

        // get center coords from options object
        const { centerX, centerY } = getCenterFromOptions(options);

        // use existing top/left in state, otherwise center coords
        const top =
          typeof options.top === 'number'
            ? options.top
            : centerY - this.height / 2;
        const left =
          typeof options.left === 'number'
            ? options.left
            : centerX - this.width / 2;

        this.set('top', top);
        this.set('left', left);
        options.scaleX && this.set('scaleX', options.scaleX);
        options.scaleY && this.set('scaleY', options.scaleY);
        options.flipX && this.set('flipX', options.flipX);
        options.flipY && this.set('flipY', options.flipY);
        options.angle && this.set('angle', options.angle);

        /* Temporary fix for selection issues */
        this.updateText(() => {
          cb && cb(this);
        }, false);

        perf.mark('end');
        // eslint-disable-next-line no-console
        perf.measure().forEach((m) => console.debug(m));
        perf.clear();
      });

      this.on('scaled', () => {
        const { scale } = this.getTextScale();
        this.updateStrokeWidthOnScale(scale);
        this.updateLastStrokeWidth(true);

        this.handleOnScale();
        this.handleFlip();
        this.width = this.width * this.scaleX; // also adjust width (similar to isWidthScaling on scaling)
        this.setFontSize(this.fontSize * scale); // apply scale to fontSize and reset scale
        this.updateShadowsAndDecorations();
        this.resetControls(true);
      });

      this.on('scaling', (e) => {
        this.resetControls(false);
        const isWidthScaling = ['ml', 'mr'].includes(e.transform.corner);

        if (isWidthScaling) {
          this.width = this.width * this.scaleX;
          this.scaleX = 1;
          this.updateText();
        }
      });

      this.on('scaling:updated', (e) => {
        this.updateLastStrokeWidth();

        this.resetControls(false);
        const { scale } = this.getTextScale();

        if (this.fontSize * scale > MAX_FONT_SIZE) {
          const newScale = MAX_FONT_SIZE / this.fontSize;
          this.scaleX = newScale;
          this.scaleY = newScale;
        } else {
          this.updateStrokeWidthOnScale(scale);
        }

        this.updateShadowsAndDecorations();
      });

      this.on('rotating:updated', () => {
        this.resetControls(false);
        this.updateShadowsAndDecorations();
      });

      this.on('rotated', (e) => {
        this.handleRotation(this, isAltKeyRotation(e));
        this.updateLastAngle();
        this.updateShadowsAndDecorations();
        this.resetControls(true);
      });

      this.on('moved', this.updateShadowsAndDecorations);

      this.on('moving', this.handleOnMove);

      // handle selection events
      this.on('selected', () => {
        if (this.group) {
          // when `pathText` is selected and in a group, it is in an `ActiveSelection`
          // events are no longer fired on `this`, but just on the selection, which is `this.group`
          this.group.on('moving', () => this.updateShadowsAndDecorations());
          this.group.on('moved', () => this.updateShadowsAndDecorations());
          this.group.on('rotated', () => this.updateShadowsAndDecorations());
          this.group.on('rotating:updated', () =>
            this.updateShadowsAndDecorations()
          );
          this.group.on('scaling:updated', () =>
            this.updateShadowsAndDecorations()
          );

          this.group.on('scaled', () => {
            const { scale } = this.getTextScale();
            this.updateStrokeWidthOnScale(scale / this.lastScale);
            this.updateLastStrokeWidth(true);
            this.handleOnScale();
            this.updateShadowsAndDecorations();
          });

          this.group.on('scaling', () => {
            this.updateLastStrokeWidth();
            const { scale } = this.getTextScale();
            if (this.fontSize * scale <= MAX_FONT_SIZE) {
              this.updateStrokeWidthOnScale(scale / this.lastScale);
            }
          });
          // catch selection changes, like the change from an activeSelection to just an activeObject
          this.canvas.on('selection:updated', () =>
            this.updateShadowsAndDecorations()
          );
        }

        if (!this.inPathEditMode) {
          this.resetControls(true);
        }
      });
      this.on('deselected', () => {
        this.setEditMode(false);
        this.updateLastAngle(); // while selected, `angle` might have changed from `activeSelection`
        this.canvas &&
          this.canvas.off('selection:updated', () =>
            this.updateShadowsAndDecorations()
          );
        this.updateShadowsAndDecorations();
      });
    },

    updateShadowsAndDecorations() {
      this.applyTransformation(this.pathShadow);
      this.applyTransformation(this.pathDecoration);
      this.applyTransformation(this.pathDecorationShadow);
    },

    applyTransformation(object) {
      const newTransform = fabric.util.multiplyTransformMatrices(
        this.calcTransformMatrix(),
        object.relationship
      );
      const options = fabric.util.qrDecompose(newTransform);
      object.set({
        flipX: false,
        flipY: false,
      });
      object.setPositionByOrigin(
        { x: options.translateX, y: options.translateY },
        'center',
        'center'
      );
      object.set(options);
      object.setCoords();
    },

    _callModified: function () {
      // force artboard state update
      this.canvas && this.canvas.fire('object:modified', { target: this });
    },

    /**
     * store lastStrokeWidth value, to correctly apply scale later
     * @param {Boolean} reset if true set values to null, otherwise set them to their current values
     */
    updateLastStrokeWidth: function (reset) {
      if (reset) {
        this.lastStrokeWidth = null;
        this.lastOutlineWidth = null;
        return;
      }

      if (!this.lastStrokeWidth) {
        this.lastStrokeWidth = this.strokeWidth;
      }

      if (!this.lastOutlineWidth) {
        this.lastOutlineWidth = this.shadowOptions?.outlineWidth;
      }
    },

    /**
     * update strokeWidth values according to scale
     * @param {Number} scale
     */
    updateStrokeWidthOnScale: function (scale) {
      this.set(
        'strokeWidth',
        (this.lastStrokeWidth || this.strokeWidth) * scale
      );

      if (!['block', 'detail'].includes(this.shadowOptions?.type)) return;
      const outlineWidth =
        (this.lastOutlineWidth || this.shadowOptions.outlineWidth) * scale;
      this.set('shadowOptions', { ...this.shadowOptions, outlineWidth });
      this.pathShadow.set('strokeWidth', outlineWidth);
    },

    toObject: function (_propertiesToInclude = []) {
      const propertiesToInclude = [
        ..._propertiesToInclude,
        'text',
        'name',
        'isUserFont',
        'fontFamily',
        'fontWeight',
        'fontSize',
        'letterSpacing',
        'textAlignment',
        'uppercase',
        'underline',
        'useLigatures',
        'lineHeight',
        'variationAxes',
        'variation',
        'shadowOptions',
        'transformOptions',
        'decorationOptions',
        'singleline',
        'adjustableWidth',
      ];
      const object = this.callSuper('toObject', propertiesToInclude);
      // remove all unused attributes
      const unusedAttrs = [
        'skewX',
        'skewY',
        'objects',
        'clipPath',
        'globalCompositeOperation',
        'shadow', // shadow data is stored in `shadowOptions`
      ];
      unusedAttrs.forEach((attr) => {
        delete object[attr];
      });

      if (object.shadowOptions?.type) {
        delete object.shadowOptions.isChanging;
      } else {
        delete object.shadowOptions;
      }

      if (!object.transformOptions?.type) {
        delete object.transformOptions;
      }

      if (object.decorationOptions?.type) {
        delete object.decorationOptions.isChanging;
      } else {
        delete object.decorationOptions;
      }

      return object;
    },

    _set: function (key, value) {
      if (key === 'name') {
        if (value === null && this.fontFamily && this.fontWeight) {
          substituteAlternativeGlyphs(this.text, {
            fontFamily: this.fontFamily,
            fontWeight: this.fontWeight,
          }).then((name) => {
            this.callSuper('_set', key, name);
          });
          return;
        }
        this.callSuper('_set', key, value);
        return;
      }
      this.callSuper('_set', key, value);
      if (key === 'fill' && this.pathText) {
        this.fillRect.set(key, value);
        !this._needsClipPath() && this.pathText.set(key, value);
        this.dirty = true; // for some reason dirty flag is not set in `_set`
      }
      if (key === 'stroke' && this.pathDecoration) {
        this.pathDecoration.set('fill', value);
      }
      if (key === 'strokeWidth') {
        this._updateClipPath();
      }
      if (PATH_TEXT_PROPS.includes(key) && this.pathText) {
        this.pathText.set(key, value);
      }
      if (PATH_SHADOW_PROPS.includes(key) && this.pathShadow) {
        this.pathShadow.set(key, value);
        this.pathDecoration.set(key, value);
        this.pathDecorationShadow.set(key, value);
      }
    },

    // override `_finalizeDimensions` to remove strokeWidth from the calculation
    _finalizeDimensions: (width, height) => ({ x: width, y: height }),

    setPath: function (path) {
      // Based on https://github.com/fabricjs/fabric.js/blob/v4.1.0/src/shapes/path.class.js#L59
      const parsedPath = parsePath(path);
      let shadowPath = '';
      let decorationPath = '';
      let decorationShadowPath = '';
      this.applyDropShadow();
      if (this.shadowOptions?.type && this.shadowOptions.type !== 'drop') {
        const shadowObj = applyShadow(path, this.shadowOptions, this.fontSize);
        shadowPath = shadowObj.shadowPath;
        if (shadowObj.offsetPath && shadowObj.offsetShadowPath) {
          decorationPath = shadowObj.offsetPath;
          decorationShadowPath = shadowObj.offsetShadowPath;
        }
      }
      this.pathText.set('path', parsedPath);
      this.pathMask.set('path', parsedPath);
      this.pathShadow.set('path', parsePath(shadowPath));
      this.pathDecoration.set('path', parsePath(decorationPath));
      this.pathDecorationShadow.set('path', parsePath(decorationShadowPath));
      this.updatePosition();
      this._calcBounds(true);
      this.updateLastPos();
      this.updateLastFlip();
      this.updateLastAngle();
      this.updateLastScale();
      this.updateDecorationPath();
      this._updateFillRect();
      this.canvas && this.canvas.requestRenderAll();
    },

    /**
     * update the position of the grouped objects that make up pathText.
     * Positional updates are based on the dimensions of rendering results and differences between the individual parts.
     * `this` is a group, so all objects inside are positioned relative to it.
     */
    updatePosition: function () {
      const hasTransform = Boolean(this.transformOptions?.type);
      // When has transform, we should use transformed bounds;
      // because unfortunately, fabric's `Path._calcDimensions()` is slightly off.
      const pathTextBounds = hasTransform
        ? {
            left: this._transformedBounds.minX,
            top: -this._transformedBounds.minY,
            width: this._transformedBounds.maxX - this._transformedBounds.minX,
            height: this._transformedBounds.minY - this._transformedBounds.maxY,
          }
        : this.pathText._calcDimensions();

      const width = this.width;
      const useAdjustableWidth = !this.singleline && width !== undefined;
      const widthDiff = this.width - pathTextBounds.width; // this should always be positive
      const alignedX =
        this.textAlignment === 'left'
          ? width / 2 + pathTextBounds.left // left
          : this.textAlignment === 'right'
          ? width / 2 + pathTextBounds.left - widthDiff // right
          : pathTextBounds.width / 2 + pathTextBounds.left; // center

      const pathOpts = {
        width: useAdjustableWidth ? width : pathTextBounds.width,
        height: pathTextBounds.height,
        pathOffset: {
          x: useAdjustableWidth
            ? alignedX
            : pathTextBounds.width / 2 + pathTextBounds.left,
          y: pathTextBounds.height / 2 + pathTextBounds.top,
        },
        dirty: true,
        originX: 'center',
        originY: 'center',
      };

      this.pathText.set({
        ...pathOpts,
      });

      this.pathMask.set({
        ...pathOpts,
      });

      if (this.shadowOptions?.type && this.shadowOptions.type !== 'drop') {
        let { scale: fontScale } = this.getTextScale();

        let { top, left } = this;
        let angle = this.angle;
        let flipX = this.flipX;
        let flipY = this.flipY;
        if (this.group?.type === 'activeSelection') {
          // consider group flip when calculating angle
          if (this.group?.flipX !== this.group?.flipY) {
            angle = this.group.angle - angle;
          } else {
            angle = this.group.angle + angle;
          }
          flipX = this.flipX !== this.group.flipX; // xor, as 2 flips mean no flip at all
          flipY = this.flipY !== this.group.flipY; // xor as well
          // top and left need to be updated as they are in group coordinate system now
          const matrix = this.group.calcTransformMatrix();
          const topLeft = fabric.util.transformPoint(
            { x: left, y: top },
            matrix
          );
          top = topLeft.y;
          left = topLeft.x;
        } else if (this.group) {
          fontScale = (this.scaleX + this.scaleY) / 2;
        }

        const shadowDims = this.pathShadow._calcDimensions();
        let diffX = (shadowDims.left - pathTextBounds.left) * fontScale;
        // if flipped, we need right, not left
        if (flipX) {
          diffX =
            (shadowDims.left +
              shadowDims.width -
              pathTextBounds.left -
              pathTextBounds.width) *
            fontScale *
            -1;
        }
        let diffY = (shadowDims.top - pathTextBounds.top) * fontScale;
        // if flipped, we need bottom, not top
        if (flipY) {
          diffY =
            (shadowDims.top +
              shadowDims.height -
              pathTextBounds.top -
              pathTextBounds.height) *
            fontScale *
            -1;
        }

        const { x: rotationXoffset, y: rotationYoffset } = rotationOffset(
          { x: diffX, y: diffY },
          angle
        );

        const updateShadowPosition = (path) => {
          // shadow offset must be adjusted by the difference to the objects width
          // depending on the textAlignment
          let alignedXDiff =
            !useAdjustableWidth || this.textAlignment === 'left'
              ? 0
              : this.textAlignment === 'right'
              ? widthDiff // right
              : widthDiff / 2; // center
          if (this.flipX) {
            alignedXDiff =
              !useAdjustableWidth || this.textAlignment === 'right'
                ? 0
                : this.textAlignment === 'left'
                ? -widthDiff // left
                : -widthDiff / 2; // center
          }
          path.set({
            width: shadowDims.width,
            height: shadowDims.height,
            left: left + rotationXoffset,
            top: top + rotationYoffset,
            scaleX: fontScale,
            scaleY: fontScale,
            flipX,
            flipY,
            angle,
            originX: 'center',
            originY: 'center',
            pathOffset: {
              x:
                shadowDims.left +
                (this.flipX ? shadowDims.width : 0) -
                alignedXDiff,
              y: shadowDims.top + (this.flipY ? shadowDims.height : 0),
            },
          });
        };

        updateShadowPosition(this.pathShadow);
        updateShadowPosition(this.pathDecoration);
        updateShadowPosition(this.pathDecorationShadow);
      }
    },

    /*
      Renders only the drop shadow svg markup.
      Returns false if there is no drop shadow.
    */
    svgDropShadow: function () {
      if (this.shadow) {
        this.shadow.exportStandalone = true;
        const dropShadowMarkup = this.callSuper('toSVG');
        this.shadow.exportStandalone = false;
        return dropShadowMarkup;
      }
      return false;
    },

    /**
     * This overrides `_createBaseSVGMarkup` for SVG exporting.
     * Source: https://github.com/fabricjs/fabric.js/blob/master/src/mixins/object.svg_export.ts
     *
     * PathText can have drop shadow and clip path at the time,
     * as we use the latter to get clipped decoration and inner stroke.
     * As a result, drop shadow will be clipped when a text element has decoration or stroke.
     *
     * For more info: https://github.com/fabricjs/fabric.js/issues/6651 and
     * https://github.com/fabricjs/fabric.js/pull/6653
     *
     * We solve this by adding a group around the clipped content, then apply shadow on that outmost group.
     */
    _createBaseSVGMarkup: function (objectMarkup, options) {
      options = options || {};

      const noStyle = options.noStyle;
      const reviver = options.reviver;

      const clipPath = this.clipPath;
      const vectorEffect = this.strokeUniform
        ? 'vector-effect="non-scaling-stroke" '
        : '';
      const absoluteClipPath =
        clipPath && clipPath.absolutePositioned && !this.clipPathMaskId;

      const shadow = this.shadow;

      const hasBothShadowAndClipPath = Boolean(clipPath && shadow);
      // get styles without shadow
      const styles = this.getSvgStyles(hasBothShadowAndClipPath);
      const styleInfo = !noStyle && styles.trim() ? `style="${styles}" ` : '';

      const stroke = this.stroke;
      const fill = this.fill;
      const markup = [];
      let clipPathMarkup;
      // insert commons in the markup, style and svgCommons
      const index = objectMarkup.indexOf('COMMON_PARTS');
      const additionalTransform = options.additionalTransform;
      if (clipPath) {
        clipPath.clipPathId = 'CLIPPATH_' + fabric.Object.__uid++;
        clipPathMarkup =
          '<clipPath id="' +
          clipPath.clipPathId +
          '" >\n' +
          clipPath.toClipPathSVG(reviver, this) +
          '</clipPath>\n';
      }
      if (hasBothShadowAndClipPath) {
        // add outer group to apply shadow
        const filter = this.getSvgFilter();
        markup.push('<g ', filter ? `style="${filter}"` : '', ' >\n');
      }
      if (absoluteClipPath) {
        markup.push('<g ', this.getSvgCommons(), ' >\n');
      }
      markup.push(
        '<g ',
        this.getSvgTransform(false),
        !absoluteClipPath ? this.getSvgCommons() : '',
        ' >\n'
      );
      // common pieces
      objectMarkup[index] = [
        styleInfo,
        vectorEffect,
        noStyle ? '' : this.addPaintOrder(),
        ' ',
        additionalTransform ? 'transform="' + additionalTransform + '" ' : '',
      ].join('');
      if (fill && fill.toLive) {
        markup.push(fill.toSVG(this));
      }
      if (stroke && stroke.toLive) {
        markup.push(stroke.toSVG(this));
      }
      if (shadow) {
        markup.push(shadow.toSVG(this));
      }
      if (clipPath) {
        markup.push(clipPathMarkup);
      }
      markup.push(objectMarkup.join(''));
      markup.push('</g>\n');
      absoluteClipPath && markup.push('</g>\n');

      // close outer group
      if (hasBothShadowAndClipPath) {
        markup.push('</g>\n');
      }

      return reviver ? reviver(markup.join('')) : markup.join('');
    },

    toSVG: function (reviver) {
      return [
        this.pathShadow.toSVG(reviver),
        this.callSuper('toSVG', reviver),
        this.pathDecorationShadow.toSVG(reviver),
        this.pathDecoration.toSVG(reviver),
      ].join('');
    },

    // append objects align function, to update position.
    // this is needed, to update the position of the shadow
    align: function (value) {
      this.callSuper('align', value);
      this.updateRelationshipMatrices();
      this.updateShadowsAndDecorations();
    },

    /**
     * This method allows us to get notified, when PathText has finished updating
     * might be useful for testing and some positioning stuff, when we need final dimensions, etc.
     * Resolves immediately if it is not updating right now.
     * @returns {Promise}
     */
    waitForUpdate: function () {
      if (!this.isUpdated) {
        return new Promise((resolve, reject) => {
          this._updateAwaiters = this._updateAwaiters || [];
          this._updateAwaiters.push({ resolve, reject });
        });
      }
      return Promise.resolve();
    },

    /**
     * updates the text based on the current attributes
     * @param {Func} cb
     * @param {Boolean} pathUnchanged used to skip the generation of paths from font
     * @param {Boolean} isChanging used to skip expensive steps while text is still changing
     */
    updateText: function (cb, pathUnchanged, isChanging) {
      const perf = createPerformancer(`updateText ${this.id}`);
      perf.mark('init updateText');
      this.isUpdated = false;
      loadFont(this.fontFamily, this.fontWeight)
        .then((font) => {
          perf.mark('loadFont');

          this.set('isUserFont', font.isUserFont);

          // handle pathText being initialized with scaling
          const { scale } = this.getTextScale();
          if (scale !== 1) {
            // reset scale
            this.scaleX = 1;
            this.scaleY = 1;
            this.pathText.scaleX = 1;
            this.pathText.scaleY = 1;

            this.updateLastScale();

            // update font size
            this.set('fontSize', this.fontSize * scale);
            pathUnchanged = false;
          }

          try {
            this.variationAxes = font.variationAxes;
          } catch (e) {
            // do nothing
          }

          this.xHeight = getXHeight(font);
          let path = '';

          if (!pathUnchanged || !this._paths) {
            perf.mark('path changed');
            let text = this.uppercase ? this.text.toUpperCase() : this.text;
            text = text.trim(); // remove spaces and problematic newlines
            text = this.singleline ? text.split('\n')[0] : text;
            const { paths, bounds, targetWidth } = getPaths(font, {
              text,
              width: this.width ? this.width * scale : undefined,
              singleline: this.singleline,
              fontSize: this.fontSize,
              letterSpacing: this.letterSpacing,
              variation: this.variation,
              textAlignment: this.textAlignment,
              underline: this.underline,
              useLigatures: this.useLigatures,
              lineHeight: this.lineHeight,
              fixOrientation: !this.transformOptions?.type, // don't fix orientation if there is a transform, as it's fixed there as well
            });
            this._paths = paths;
            this._bounds = bounds;
            if (!this.singleline) {
              this.adjustableWidth = targetWidth; // store adjustableWidth values, to use them when changing between singleline true/false
              this.width = targetWidth; // this is important for when no width is specified to adjust to
            }
          }

          if (this.transformOptions?.type) {
            perf.mark('has transform');
            const options = this.getApplyTransformOptions();
            // remove the angle information from points, since that is applied via `this.angle`
            const transformOptions = this.getNeutralTransformOptions();
            const {
              path: transformedPath,
              bounds: transformedBounds,
              controlVector,
              outOfBounds,
              curveLength,
            } = applyTransform(this._paths, transformOptions, options);
            path = transformedPath;

            this.textOutOfBounds = outOfBounds;

            if (controlVector && transformedBounds) {
              this._controlVector = controlVector;
              this._transformedBounds = transformedBounds;
            } else {
              delete this._controlVector;
              delete this._transformedBounds;
            }

            // curveLength is only relevant for warp transforms and their decorations
            if (curveLength) {
              this._curveLength = curveLength;
            } else {
              delete this._curveLength;
            }
          } else {
            path = this._paths.join('');
            delete this._controlVector;
            delete this._transformedBounds;
          }
          this._pathTextWasChangedAfterUpdate = false;
          perf.mark('before setPath');

          // That function call is needed for free line transform, basically for any change related to font(size, letter spacing, etc.)
          // TODO: later we can create some sort of a hook functionality, where each transform or decoration can register it's own callbacks.
          // In this case it can be smth. like `registerAfterUpdateHook`
          this.translateToControlPoint();
          this.updateLastAngle();
          perf.mark('after translateToControlPoint');

          this.setPath(path);
          perf.mark('after setPath');

          if (!isChanging || !pathUnchanged) {
            this.updateRelationshipMatrices();
          }

          cb && cb();

          // notify everyone that update was successful
          if (this._updateAwaiters?.length) {
            this._updateAwaiters.forEach(({ resolve }) => resolve());
            this._updateAwaiters = [];
          }
          this.isUpdated = true;
          // eslint-disable-next-line no-console
          perf.measure().forEach((m) => console.debug(m));
          perf.clear();
        })
        .catch((err) => {
          console.error('We failed to create path text', err);

          // notify everyone that update failed
          if (this._updateAwaiters?.length) {
            this._updateAwaiters.forEach(({ reject }) => reject());
            this._updateAwaiters = [];
          }
          this.isUpdated = true;
        });
    },

    updateRelationshipMatrices() {
      const textTransform = this.calcTransformMatrix();
      const invertedTextTransform = fabric.util.invertTransform(textTransform);

      this.pathShadow.relationship = fabric.util.multiplyTransformMatrices(
        invertedTextTransform,
        this.pathShadow.calcTransformMatrix()
      );

      this.pathDecoration.relationship = fabric.util.multiplyTransformMatrices(
        invertedTextTransform,
        this.pathDecoration.calcTransformMatrix()
      );

      this.pathDecorationShadow.relationship =
        fabric.util.multiplyTransformMatrices(
          invertedTextTransform,
          this.pathDecorationShadow.calcTransformMatrix()
        );
    },

    getTextScale() {
      const { scaleX, scaleY } = this.pathText.getObjectScaling();

      return {
        scaleX,
        scaleY,
        scale: (scaleX + scaleY) / 2,
      };
    },

    setText: function (text, cb) {
      const oldText = this.text;
      this.set('text', text);
      this.updateText(cb);

      substituteAlternativeGlyphs(oldText, {
        fontFamily: this.fontFamily,
        fontWeight: this.fontWeight,
      }).then((oldAutoName) => {
        if (oldAutoName === this.name) {
          substituteAlternativeGlyphs(this.text, {
            fontFamily: this.fontFamily,
            fontWeight: this.fontWeight,
          }).then((newAutoName) => {
            this.set('name', newAutoName);
          });
        } else {
          this._callModified();
        }
      });
    },

    /**
     * enable or disable the use of ligatures
     * @param {bool} value
     */
    setUseLigatures: function (value, cb) {
      this.useLigatures = value;
      this.updateText(cb);
      this._callModified();
    },

    setFontSize: function (fontSize, isChanging, cb) {
      // reset scale
      this.scaleX = 1;
      this.scaleY = 1;
      this.pathText.scaleX = 1;
      this.pathText.scaleY = 1;

      this.updateLastScale();
      this.set('fontSize', fontSize);
      this.updateText(
        () => {
          !isChanging && this._callModified();
          cb && cb();
        },
        false,
        isChanging
      );
    },

    setLetterSpacing: function (letterSpacing, isChanging, cb) {
      this.set('letterSpacing', letterSpacing);
      this.updateText(cb, false, isChanging);
      !isChanging && this._callModified();
    },

    setFontFamily: function ({ fontFamily, fontWeight }, cb) {
      const oldFont = {
        fontFamily: this.fontFamily,
        fontWeight: this.fontWeight,
      };
      const newFont = { fontFamily, fontWeight };

      substituteGlyphsInText(this.text, oldFont, newFont, (text) => {
        this.set('text', text);
        this.set(newFont);
        this.updateText(cb);
        this._callModified();
      });
    },
    setFontWeight: function (fontWeight, cb) {
      this.set('fontWeight', fontWeight);
      this.updateText(cb);
      this._callModified();
    },
    setUppercase: function (uppercase, cb) {
      this.set('uppercase', uppercase);
      this.updateText(cb);
      this._callModified();
    },
    setTextAlignment: function (textAlignment, cb) {
      this.set('textAlignment', textAlignment);
      // hack to make it play nice with freeLine text
      // todo: find the real cause
      this.updateText(() => this.updateText(cb), false, true);
      this._callModified();
    },
    setUnderline: function (underline, cb) {
      this.set('underline', underline);
      this.updateText(cb);
      this._callModified();
    },
    setLineHeight: function (lineHeight, isChanging, cb) {
      this.set('lineHeight', lineHeight);
      this.updateText(cb, false, isChanging);
      !isChanging && this._callModified();
    },
    setVariation: function (varAxis, value, isChanging, cb) {
      this.set('variation', { ...this.variation, [varAxis]: value });
      this.updateText(cb, false, isChanging);
      !isChanging && this._callModified();
    },

    // Implements standard Object `getColors`
    getColors() {
      const fill = this.fill;
      const hasStroke =
        !!this.strokeWidth || this.shadowOptions.type === 'detail';
      const stroke = this.stroke;
      const hasShadow = !!this.shadowOptions?.type;
      const shadow = hasShadow && this.shadowOptions?.color;
      const hasDecoration = !!this.decorationOptions?.type;
      const decoration = hasDecoration && this.decorationOptions?.color;
      // Account for no-color
      const colors = [
        { key: 'fill', value: fill, visible: true },
        {
          key: 'stroke',
          value: stroke,
          visible: hasStroke,
        },
        {
          key: 'shadow',
          value: shadow,
          visible: hasShadow,
        },
        {
          key: 'decoration',
          value: decoration,
          visible: hasDecoration,
        },
      ];
      return colors;
    },

    // Implements standard Object `setColor`
    setColor(key, value) {
      if (['fill', 'stroke'].includes(key)) {
        this.set(key, value);
      }
      if (key === 'shadow') {
        this.setShadowColor(value);
      }
      if (key === 'decoration' && this.decorationPath) {
        this.setDecorationColor(value);
      }
    },

    setShadowColor: function (value) {
      const shadow = { ...this.shadowOptions, color: value };
      this.set('shadowOptions', shadow);
      if (this.pathShadow) {
        this.pathShadow.set('fill', value);
        this.pathShadow.set('stroke', value);
        this.pathDecorationShadow.set('fill', value);
      }
      // blur shadow
      if (this.shadow) {
        this.shadow.color = value;
      }
    },

    setShadow: function (shadowOptions) {
      const newShadowOptions = {
        ...shadowOptions,
        color: this.shadowOptions.color,
      };
      const previousOptions = this.shadowOptions;

      // harmonize shadow offsets
      const weakShadows = ['drop', 'line', null];
      const strongShadows = ['detail', 'block']; // strong shadows cast ca. 10x larger shadow
      if (previousOptions?.type !== newShadowOptions?.type) {
        if (
          weakShadows.includes(previousOptions.type) &&
          strongShadows.includes(newShadowOptions.type)
        ) {
          // weak to strong
          newShadowOptions.offset /= 10;
        } else if (
          strongShadows.includes(previousOptions.type) &&
          weakShadows.includes(newShadowOptions.type)
        ) {
          // strong to weak
          newShadowOptions.offset *= 10;
        }
      }

      // only check bounds if type, to enable turning shadow on/off
      if (newShadowOptions.type) {
        newShadowOptions.offset = Math.min(newShadowOptions.offset, 100);
      }

      this.set('shadowOptions', newShadowOptions);

      if (newShadowOptions.type === 'detail') {
        this.previousStrokeWidth = this.strokeWidth;
        this.set('strokeWidth', 0);
      } else if (previousOptions?.type === 'detail') {
        this.set(
          'strokeWidth',
          this.strokeWidth || this.previousStrokeWidth || 0
        );
      }

      this.pathShadow.set(
        'strokeWidth',
        ['block', 'detail'].includes(newShadowOptions.type)
          ? newShadowOptions.outlineWidth
          : 0
      );

      this.updateText(null, true);

      if (!shadowOptions.isChanging) this._callModified();
    },

    applyDropShadow: function () {
      const shadowOptions = this.shadowOptions;
      if (shadowOptions.type === 'drop') {
        // normalize offset between 0 and 20% of font size
        const adjustedOffset =
          (shadowOptions.offset / 100) * (this.fontSize * 0.2);
        // angle is negated to apply shadow in the same direction as other shadows
        const shadowOffset = calcOffset(adjustedOffset, -shadowOptions.angle);
        this.set(
          'shadow',
          new fabric.Shadow({
            offsetX: shadowOffset.x,
            offsetY: shadowOffset.y,
            color: shadowOptions.color,
            // nonScaling: true,
            blur: shadowOptions.blur || 0,
          })
        );
      } else {
        this.set('shadow', null);
      }
    },

    // first options param represents transformOptions,
    // second is optional parameters around enabling/disabling freeline xform
    setTransform: function (options, freelineOptions = {}) {
      const { justEnabledFreeLineEdit } = freelineOptions;
      const transformTypeChanged = this.transformOptions?.type !== options.type;

      if (!options.type) {
        // if transform is turned off
        const center = this.getTextCenterPoint();
        this.set('singleline', false);
        this.setControlsVisibility({
          mr: true,
          ml: true,
        });
        this.width = this.adjustableWidth; // reset width for adjustable width
        this.setEditMode(false);
        this.set('transformOptions', options);
        this.resetControls(false);
        this.updateText(() => {
          // keep same center position for untransformed text
          this.adjustToCenter(center);
          this._callModified();
        }, false);
        return;
      } else if (transformTypeChanged || justEnabledFreeLineEdit) {
        // set or reset transform options if point based edit was just toggled on
        this.setControlsVisibility({
          mr: false,
          ml: false,
        });
        return this.setEditMode(options);
      }

      // changes in options.points
      if (CURVED_TRANSFORMS.includes(options.type)) {
        // adjust curve value to actual curve factor applied in points
        options.curve =
          this.getCurveFactorFromPoints() *
          (Math.sign(this.transformOptions?.curve) || 1);
      }
      this.set('transformOptions', options);
      this.adjustAdjustableWidthToPoints();
      this.updateText(
        () => {
          if (!options.isChanging) {
            this._callModified();
          }
        },
        false,
        options.isChanging
      );
    },

    setTransformCurve: function ({ curve, isChanging }) {
      const previousCurve = this.transformOptions?.curve;
      this.set('transformOptions', { ...this.transformOptions, curve });
      this.adjustPointsToCurve(curve, previousCurve);

      this.updateText(
        () => {
          if (!isChanging) {
            this._callModified();
          }
        },
        false,
        isChanging
      );
    },

    /**
     * update transformOptions after points are changed by controls
     * @param {*} points
     * @param {*} options
     */
    setTransformPoints: function (points, { type, isChanging }) {
      // only update points, when still in edit mode and with the same transform
      // this can happen because of the updateDelay from the controls
      if (this.inPathEditMode && type === this.transformOptions?.type) {
        let curve = this.transformOptions.curve;
        if (CURVED_TRANSFORMS.includes(type)) {
          // adjust curve value to actual curve factor applied in points
          curve =
            this.getCurveFactorFromPoints() *
            (Math.sign(this.transformOptions.curve) || 1);
        }
        this.set('transformOptions', {
          ...this.transformOptions,
          curve,
          points,
        });
        this.adjustAdjustableWidthToPoints();

        // update text, to render path based on changed points
        this.updateText(
          () => {
            !isChanging && this._callModified();
          },
          false,
          isChanging
        );
      }
    },

    /**
     * update circle transform from rotation control
     */
    rotateAroundPoint: function (angle, point, isChanging) {
      if (!isChanging) {
        this._callModified();
        return;
      }

      const topLeft = new fabric.Point(this.left, this.top);
      const rotatedTopLeft = rotatePointAroundSinglePoint(
        topLeft,
        point,
        angle
      );

      this.set('angle', this.angle + angle);
      this.set('top', rotatedTopLeft.y);
      this.set('left', rotatedTopLeft.x);
      this.handleRotation(this, false, point);
      this.updateShadowsAndDecorations();
      this.canvas && this.canvas.requestRenderAll();
    },

    setDecorationColor: function (value) {
      const decorationOptions = { ...this.decorationOptions, color: value };
      this.set({ decorationOptions });
      this.decorationPath.set({
        fill: value,
        stroke: value,
      });
    },

    setDecoration: function (opts) {
      this.set({ decorationOptions: { ...this.decorationOptions, ...opts } });
      this._updateClipPath();
      this.updateDecorationPath();
      if (!opts?.isChanging) this._callModified();
    },

    _updateFillRect() {
      this.fillRect.set({
        originX: 'center',
        originY: 'center',
        top: 0,
        left: 0,
        angle: 0,
        width: this.width,
        height: this.height,
        fill: this.fill,
        scaleX: 1,
        scaleY: 1,
      });
    },

    // this overrides https://github.com/fabricjs/fabric.js/blob/master/src/shapes/group.class.js#L474
    _calcBounds: function (onlyWidthHeight) {
      const aX = [];
      const aY = [];
      const props = ['tr', 'br', 'bl', 'tl'];
      if (this.pathText) {
        this.pathText.aCoords = this.pathText.calcACoords();
        for (let j = 0; j < props.length; j++) {
          const prop = props[j];
          aX.push(this.pathText.aCoords[prop].x);
          aY.push(this.pathText.aCoords[prop].y);
        }
      }
      this._getBounds(aX, aY, onlyWidthHeight);
    },

    // helper function to determine if clipPath is needed
    _needsClipPath: function () {
      // clipPath is needed only if text has stroke or decorations, in every other case
      // there is no need for clipPath and therefore no need for TrueRender
      return (
        (this.stroke && this.strokeWidth > 0) || !!this.decorationOptions?.type
      );
    },

    /**
     * if clip path is needed, this adds a fillRect that renders the fill value of text
     * must be called before other children are added that depend on clipPath (decorations)
     */
    _updateClipPath: function () {
      if (this._needsClipPath()) {
        this.clipPath = this.pathMask;
        this.trueRender = true;
        if (this.fillRect && this._objects[0]?.type !== 'rect')
          this.insertAt(this.fillRect, 0);
        this.pathText && this.pathText.set('fill', null);
      } else {
        delete this.clipPath;
        this.trueRender = false;
        this.remove(this.fillRect);
        this.pathText && this.pathText.set('fill', this.fill);
      }
    },

    switchFastRendering: function (enable) {
      this.trueRender = enable ? false : this._needsClipPath();
      this.objectCaching = enable;
    },
  });

  fabric.PathText.fromObject = function (object, callback) {
    fabric.Group.fromObject(object, (group) => {
      new fabric.PathText(
        object.text,
        {
          ...object,
          objects: group.getObjects(),
        },
        (target) => {
          callback(target);
        }
      );
    });
  };
}

// initTrueRender messes up render function of PathText, so we have a separate function to update render
export function initPathTextRender(fabric) {
  fabric.PathText.prototype.render = (function (render) {
    return function (ctx, opts) {
      this.pathShadow.render(ctx);
      render.apply(this, [ctx, opts]);
      this.pathDecorationShadow.render(ctx);
      this.pathDecoration.render(ctx);

      if (isDebugActive()) {
        this.renderDevUtils(ctx);
      }
    };
  })(fabric.PathText.prototype.render);
}
