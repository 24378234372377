import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isOnDevEnvironment } from '../global/environment';

const monitoring = {
  _reportingOff() {
    return isOnDevEnvironment() || !process.env.REACT_APP_SENTRY_URL;
  },

  init() {
    if (this._reportingOff()) return;

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: process.env.REACT_APP_SENTRY_ENV,
      integrations: [
        new Integrations.BrowserTracing(),
        // We disabled auto reporting here because we report the global unhandled exception/rejection manually for better insight.
        // Reference: https://docs.sentry.io/platforms/javascript/troubleshooting/#events-with-non-error-exception
        new Sentry.Integrations.GlobalHandlers({
          onunhandledrejection: false,
          onerror: false,
        }),
      ],
      ignoreErrors: [
        // This comes from embedded chromium in .NET apps,
        // see discussion here: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/20
        'Object Not Found Matching Id:',
      ],
      tracesSampleRate: 1.0,
    });
  },

  configureScope(callback) {
    if (this._reportingOff()) return;

    Sentry.configureScope(callback);
  },

  setUser(user) {
    if (this._reportingOff()) return;

    Sentry.setUser(user);
  },

  captureException(error, extraInfo) {
    if (this._reportingOff()) {
      console.error({ error, extraInfo });
    } else {
      extraInfo
        ? Sentry.captureException(error, {
            extra: {
              info: extraInfo,
            },
          })
        : Sentry.captureException(error);
    }
  },

  captureMessage(message) {
    Sentry.captureMessage(message);
  },
};

export default monitoring;
