import wrapFetch from 'fetch-retry';
import networkStore from '../stores/networkStore';

export const FETCH_RETRIES = 3;
export const FETCH_RETRY_DELAY = 1000;

export const _fetchRetry = wrapFetch(fetch, {
  retries: FETCH_RETRIES,
  retryDelay: FETCH_RETRY_DELAY,
  retryOn: function (attempt: number, error: Error | null) {
    if (!error) return false; // resolve when there is no error
    if (attempt > FETCH_RETRIES) return false; // resolve error after all retries

    if (error.name === 'AbortError') return false; // do not retry on abort
    return true;
  },
});

export class OfflineError extends Error {
  constructor() {
    super();

    this.name = 'OfflineError';
    this.message = 'No network was detected on fetch failure';
  }
}

export const fetchRetry = (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  return _fetchRetry(input, init).catch((err) => {
    if (typeof navigator !== 'undefined' && !navigator.onLine) {
      networkStore.getState().setStatus(false);
      throw new OfflineError(); // offline errors are handled in useErrorHandling
    } else {
      throw err;
    }
  });
};

/**
 * try to fetch data and handle errors
 */
export const tryFetchRetry = async <T>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T | { success: false; error: string }> => {
  try {
    const response = await fetchRetry(input, init);
    return await response.json();
  } catch (error) {
    if (error instanceof OfflineError) throw error; // offline errors are handled in useErrorHandling
    const errorMessage = error instanceof Error ? error.message : String(error);
    return { success: false, error: errorMessage };
  }
};
