import { fetchRetry } from '../utils/fetchRetry';
import { getAccessToken } from './userApi';

export const USER_API_URL =
  process.env.REACT_APP_USER_API_URL ||
  process.env.NEXT_PUBLIC_USER_API_URL ||
  process.env.STORYBOOK_USER_API_URL;

const DEFAULT_TAKE = 6;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createAPI = ({ url }: { url: string | undefined }) => {
  /**
   * gets cms' the most used content (fonts)
   * @returns {array}
   */
  const getContentUses = async (): Promise<
    { id: string; usedAt: string }[] | boolean
  > => {
    try {
      const resp = await fetchRetry(
        `${url}/contentuses/index?type=FONT&take=${DEFAULT_TAKE}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );
      const contentUses = await resp.json();

      if (contentUses.results?.length) {
        return contentUses.results;
      } else return false;
    } catch {
      return false;
    }
  };

  /**
   * creates a new usage event for a cms object (fonts)
   * @param {string[]} fontFamilyArray
   * @returns {boolean}
   */
  const createContentUse = async (
    fontFamilyArray: string[]
  ): Promise<{ id: string; usedAt: string }[] | boolean> => {
    try {
      const resp = await fetchRetry(`${url}/contentuses/create`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: fontFamilyArray,
          type: 'FONT',
          take: DEFAULT_TAKE,
        }),
      });
      const contentUses = await resp.json();

      if (contentUses.results?.length) {
        return contentUses.results;
      } else return false;
    } catch {
      return false;
    }
  };

  return {
    getContentUses,
    createContentUse,
  };
};

export default createAPI({ url: USER_API_URL });
