import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import AuthComponent from '../AuthComponent/AuthComponent';
import { P2, P4 } from '../utilities/Typography/styles';
import Spacer from '../Spacer/Spacer';

function PromoCodeModal({ isOpen, promoCode }) {
  const [localIsOpen, setLocalIsOpen] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setLocalIsOpen(isOpen), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  return (
    <Modal isOpen={localIsOpen} width={'auto'}>
      <AuthComponent
        isSignup={true}
        promoCode={promoCode}
        customContent={
          <P2>
            Sign up to continue creating and save more than $599 today.
            <sup>*</sup>
          </P2>
        }
        customFooter={
          <>
            <Spacer h={'48px'} />
            <P4>
              *Limited to 100 new accounts per day. Average market price for
              comparable graphic offerings.
            </P4>
          </>
        }
      />
    </Modal>
  );
}

PromoCodeModal.defaultProps = {
  isOpen: false,
};

PromoCodeModal.propTypes = {
  isOpen: PropTypes.bool,
  promoCode: PropTypes.string,
};

export default PromoCodeModal;
