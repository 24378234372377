import { forwardRef, PropsWithChildren } from 'react';
import { ButtonWrapper } from './styles';
import { defaultTheme } from './theme';
import { ButtonBaseProps } from './types';

const ButtonBase = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonBaseProps>
>(({ children, ...props }, ref) => {
  const {
    as,
    width,
    height,
    disabled,
    isBlock,
    dataTestId,
    onClick,
    ariaLabel,
    style,
    href,
    target,
  } = props;

  const theme = { ...defaultTheme, ...props.theme, width, height, isBlock };

  return (
    <ButtonWrapper
      {...props}
      as={as}
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      href={href}
      target={target}
      style={style}
      theme={theme}
    >
      {children}
    </ButtonWrapper>
  );
});

export default ButtonBase;
