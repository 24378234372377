import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import {
  GRID_SET,
  GRID_SHOW,
  RESIZE,
  // SHOW_RULERS,
  TRIM_VIEW,
} from '../../global/events';
import Icon from '../Icon/Icon';
import IconEventWrapper from '../Icon/IconEventWrapper';
import MultiOption from '../MultiOption/MultiOption';
import Select from '../SelectNew/Select';
import { layouts, activeDefault } from './config';
import {
  ArtboardLayoutText,
  IconContainer,
  MainContainer,
  ArtboardSettingsTitle,
  Orientation,
  OrientationOption,
  SliderContainer,
  LoaderWrapper,
  CustomSizeWrapper,
  CustomSizeSettingsWrapper,
  Section,
} from './styles';
import Switch from '../Switch/Switch';
import SliderInput from '../SliderInput/SliderInput';
import Popover from '../Popover/Popover';
import {
  UNITS,
  MAXIMUM_SIZE,
  MINIMUM_SIZE,
  UNIT_DESCRIPTIONS,
} from '../../global/constants';
import {
  settingsStoreSelector,
  useSettingsStore,
} from '../../stores/settingsStore';
import { convertToUnit, getFormattedArtboardSize } from '../../utils/artboard';
import Shortcut from '../Shortcut/Shortcut';
import { FlexRow } from '../utilities/styles';
import { P3, P4 } from '../utilities/Typography/styles';
import WrappedNumberInput from '../WrappedNumberInput/WrappedNumberInput';
import { primarySmallTheme } from '../Button/Button/theme';
import Button from '../Button/Button/Button';
import { useUserStore, userStoreSelector } from '../../stores/userStore';
import {
  promotionSelector,
  usePromotionStore,
} from '../../stores/promotionStore';
import { settingsInputTheme } from '../WrappedNumberInput/theme';
import { themeStyle } from '../../services/theming';
import { iconTheme } from './theme';

const unitOptions = Object.entries(UNIT_DESCRIPTIONS).map(([key, val]) => {
  return { id: key, label: val };
}, {});

const RenderLoader = () => {
  return (
    <LoaderWrapper>
      <P4 color={themeStyle.varInkMedium} textDecoration="underline">
        Load More
      </P4>
    </LoaderWrapper>
  );
};

const SettingsPanelContent = (props) => {
  const showGrid = useSettingsStore(settingsStoreSelector.showGrid);
  const gridSize = useSettingsStore(settingsStoreSelector.gridSize);
  const trimView = useSettingsStore(settingsStoreSelector.trimView);
  const hasProAccess = useUserStore(userStoreSelector.proAccess);

  const [options, setOptions] = useState(
    getFormattedArtboardSize(props.options)
  );

  const [customSizeChanged, setCustomSizeChanged] = useState(false);

  useEffect(() => {
    setOptions(getFormattedArtboardSize(props.options));
  }, [props.options]);

  const onChange = (val) => {
    setCustomSizeChanged(false);
    props.dispatch(RESIZE, val);
  };

  const [customSize, setCustomSize] = useState({});
  useLayoutEffect(() => {
    setCustomSize({
      width: options.width,
      height: options.height,
      unit: options.unit,
    });
  }, [options.width, options.height, options.unit]);

  const onConfirm = () => {
    onChange({
      size: {
        x: customSize.width,
        y: customSize.height,
        unit: customSize.unit,
      },
      key: 'custom',
      portrait: customSize.height > customSize.width,
    });
  };

  const showUpgradeModal = usePromotionStore(
    promotionSelector.showUpgradeModal
  );
  const handleShowUpgradeModal = () => {
    if (hasProAccess) return;
    showUpgradeModal('customArtboardSize');
  };

  const onEnter = (value, key) => {
    const newWidth = key === 'width' ? value : customSize.width;
    const newHeight = key === 'height' ? value : customSize.height;

    onChange({
      size: {
        x: newWidth,
        y: newHeight,
        unit: customSize.unit,
      },
      key: 'custom',
      portrait: newHeight > newWidth,
    });
  };

  const onInputSizeChange = (key, val) => {
    setCustomSizeChanged(true);
    setCustomSize((size) => ({
      ...size,
      [key]: val,
    }));
  };

  const onLayoutChange = (layoutKey) => {
    const { size } = layouts[layoutKey];
    let newUnit = size.unit;
    let newHeight = size.y;
    let newWidth = size.x;

    newWidth = newWidth || options.width;
    newHeight = newHeight || options.height;
    newUnit = newUnit || options.unit;

    onChange({
      size: {
        x: newWidth,
        y: newHeight,
        unit: newUnit,
      },
      key: layoutKey,
      portrait: options.portrait,
    });
  };

  const onUnitChange = (newUnit) => {
    setCustomSizeChanged(true);
    const { width: newWidth, height: newHeight } = convertToUnit({
      width: options.width,
      height: options.height,
      oldUnit: options.unit,
      newUnit,
    });

    setCustomSize({
      width: newWidth,
      height: newHeight,
      unit: newUnit,
    });
  };

  const onOrientationChange = (portrait) => {
    onChange({
      size: {
        x: options.width,
        y: options.height,
        unit: options.unit,
      },
      key: options.activeLayout,
      portrait,
    });
  };

  const getSizeLabel = (size) => {
    const displayUnit = UNITS[size.unit];
    return `${size.x}${displayUnit} × ${size.y}${displayUnit}`;
  };

  const updateGridSize = (gridSize, isChanging) => {
    props.dispatch(GRID_SET, {
      gridSize,
      isChanging,
    });
  };

  const updateTrimView = () => {
    const _trimView = !trimView;
    props.dispatch(TRIM_VIEW, _trimView);
  };

  const updateShowGrid = () => {
    const _showGrid = !showGrid;
    props.dispatch(GRID_SHOW, _showGrid);
  };

  const renderOption = (layoutKey) => {
    const option = layouts[layoutKey];
    const isActive = options.activeLayout === layoutKey;
    return (
      <IconEventWrapper fitParent theme={iconTheme}>
        <IconContainer>
          <Icon
            height="auto"
            name={option.icon}
            theme={iconTheme}
            isActive={isActive}
          ></Icon>
        </IconContainer>
        <ArtboardLayoutText>
          <P4 color="unset">{option.label}</P4>
          <P4 color="unset">{getSizeLabel(option.size)}</P4>
        </ArtboardLayoutText>
      </IconEventWrapper>
    );
  };

  return (
    <MainContainer>
      <Section>
        <ArtboardSettingsTitle>
          <P3 color={themeStyle.varInkMain}>Artboard Size</P3>
          <Orientation>
            <P4 color={themeStyle.varInkMedium}>Orientation</P4>
            <OrientationOption
              data-testid="orientation-landscape"
              onClick={() => onOrientationChange(false)}
              selected={!options.portrait}
            >
              <Icon
                name="landscape"
                height="8px"
                theme={iconTheme}
                isActive={!options.portrait}
              />
            </OrientationOption>
            <OrientationOption
              data-testid="orientation-portrait"
              onClick={() => onOrientationChange(true)}
              selected={options.portrait}
            >
              <Icon
                name="portrait"
                height="10px"
                theme={iconTheme}
                isActive={options.portrait}
              />
            </OrientationOption>
          </Orientation>
        </ArtboardSettingsTitle>
        <MultiOption
          options={layouts}
          selected={options.activeLayout || activeDefault}
          renderOption={renderOption}
          select={onLayoutChange}
          visibleCount={6}
          renderLoader={RenderLoader}
        />
        <CustomSizeWrapper
          data-testid="artboard-custom-size"
          onClick={handleShowUpgradeModal}
          disablePointerEvents={!hasProAccess}
        >
          <FlexRow alignItems="center" gap="8px">
            {!hasProAccess && <Icon name="premiumBadge" height="16px" />}
            <P3
              color={
                hasProAccess ? themeStyle.varInkMain : themeStyle.varInkMedium
              }
            >
              Custom Size
            </P3>
          </FlexRow>
          <CustomSizeSettingsWrapper>
            <FlexRow gap="8px">
              <WrappedNumberInput
                dataTestId="artboard-size-width"
                min={MINIMUM_SIZE[customSize.unit]}
                max={MAXIMUM_SIZE[customSize.unit]}
                icon="doubleArrows"
                value={{ value: customSize.width }}
                theme={settingsInputTheme}
                onChanged={(val) => onInputSizeChange('width', val)}
                onValueEdit={() => setCustomSizeChanged(true)}
                onEnter={(val) => onEnter(val, 'width')}
                precision={customSize.unit === 'px' ? 0 : 2}
                disabled={!hasProAccess}
                debounceOnChanged={false}
              />
              <WrappedNumberInput
                dataTestId="artboard-size-height"
                min={MINIMUM_SIZE[customSize.unit]}
                max={MAXIMUM_SIZE[customSize.unit]}
                icon="doubleArrows"
                value={{ value: customSize.height }}
                theme={{
                  ...settingsInputTheme,
                  inputMarginLeft: '0px',
                  iconTransform: 'rotate(90deg)',
                }}
                onChanged={(val) => onInputSizeChange('height', val)}
                onValueEdit={() => setCustomSizeChanged(true)}
                onEnter={(val) => onEnter(val, 'height')}
                precision={customSize.unit === 'px' ? 0 : 2}
                disabled={!hasProAccess}
                debounceOnChanged={false}
              />
            </FlexRow>
            <FlexRow width="100%" gap="8px">
              <Select
                small
                dataTestId="artboard-size-unit-select"
                options={unitOptions}
                activeOption={unitOptions.find(
                  (opt) => opt.id === customSize.unit
                )}
                onSelect={(val) => onUnitChange(val.id)}
                disabled={!hasProAccess}
                customStyles={{ borderRadius: themeStyle.radiusSmall }}
              />
              <Button
                label="Confirm"
                theme={primarySmallTheme}
                onClick={onConfirm}
                disabled={!hasProAccess || !customSizeChanged}
              />
            </FlexRow>
          </CustomSizeSettingsWrapper>
        </CustomSizeWrapper>
      </Section>
      <Section height="42px" alignItems="center" row>
        <FlexRow gap="8px" alignItems="center">
          <P3>Trim view</P3>
          <Shortcut label="W" />
        </FlexRow>
        <Switch
          dataTestId="trim-view-toggler"
          label={
            <FlexRow gap="8px" alignItems="center">
              Trim view
              <Shortcut label="W" />
            </FlexRow>
          }
          onChange={updateTrimView}
          checked={trimView}
        />
      </Section>
      <Section height="42px" alignItems="center" row last>
        <P3>Show Grid</P3>
        <Switch
          dataTestId="grid-toggler"
          label="Show Grid"
          onChange={updateShowGrid}
          checked={showGrid}
        />
      </Section>
      <SliderContainer show={showGrid}>
        <SliderInput
          dataTestId="grid-size-input"
          label="Size"
          min={1}
          max={80}
          step={1}
          marks={[1, 10, 20, 40, 80]}
          pointerPolicy={'closest'}
          startValue={gridSize}
          onChanging={(val) => updateGridSize(val, true)}
          onChanged={(val) => updateGridSize(val, false)}
        />
      </SliderContainer>
    </MainContainer>
  );
};

const SettingsPanel = (props) => {
  const content = <SettingsPanelContent {...props} />;

  const upgradeModalIsOpen = usePromotionStore(
    promotionSelector.upgradeModalIsOpen
  );

  return (
    <Popover
      dataTestId="settings-panel"
      content={content}
      align="center"
      ignoreEventOutside={upgradeModalIsOpen}
      theme={{
        contentOverflowY: 'visible',
        padding: '0px',
        headPadding: '12px',
      }}
      margin="10px 0 0 0"
      label="Settings"
      width="288px"
    >
      {props.children}
    </Popover>
  );
};

const Options = PropTypes.shape({
  activeLayout: PropTypes.string,
  showRulers: PropTypes.bool,
  orientation: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
});

SettingsPanelContent.propTypes = {
  options: Options,
  dispatch: PropTypes.func,
};

SettingsPanel.propTypes = {
  /**
   * Config object that determines values of settings. If not supplied, a default one is used
   */
  options: Options,
  /**
   * Dispatch function for handling events
   */
  dispatch: PropTypes.func,
  children: PropTypes.node,
};

export default SettingsPanel;
