import { nanoid } from 'nanoid';
import { isInIds } from '../../../utils';
import {
  getElementById,
  createGroupInStructure,
  discardGroupFromStructure,
  flattenStructure,
  toggleElementsMetaProperty,
  isElementLocked,
  isElementHidden,
  getParent,
  getAllChildObjects,
} from '../../../utils/groupStructure';
import { transformRecursive } from '../../../utils/groupStructure/recursive';
import { NUMBER_OF_BACKGROUND_OBJECTS } from '../../../global/constants';
import networkStore from '../../../stores/networkStore';
import { getObjectById } from '../../../utils/editor/objects';

export default function (fabric) {
  /* structure: object of group structure
   *   - this.groupStructure: structure object for all objects on canvas
   * element: structural object within structure
   *   - object: id reference to an actual fabric.Object
   *   - group: element with an id and an children array of elements
   */
  fabric.util.object.extend(fabric.StaticCanvas.prototype, {
    /**
     * Initialize group structure
     * @param {Object} json - Canvas state object
     */
    initGroupStructure(json) {
      if (json && !json.groupStructure?.children) {
        // handle case when incoming json has no groupStructure(should never happen)
        this.groupStructure = {
          children: json.objects
            .slice() // copy array
            .reverse() // groupStructure has a reversed order
            .map(({ id }) => ({ id })),
        };
      } else if (json && json.groupStructure?.children) {
        // handle good case, incoming json has a groupStructure
        this.groupStructure = json.groupStructure;
      } else if (!this.groupStructure?.children) {
        // handle fallback
        this.groupStructure = { children: [] };
      }
    },

    shouldUngroup(objectIds) {
      const selectedObjectIds = objectIds ? objectIds : this._selectedElements;

      const parent = getParent(this.groupStructure, selectedObjectIds[0]);
      const wholeGroup =
        parent?.id && parent.children.length === selectedObjectIds.length;

      if (wholeGroup) {
        this._selectedElements = [parent.id];
        return true;
      }
      // we want to ungroup, if exactly one element is selected that is a group
      if (selectedObjectIds.length === 1) {
        const id = selectedObjectIds[0];
        const structure = getElementById(this.groupStructure, id);
        if (structure?.children?.length) {
          return true;
        }
      }
      return false;
    },

    createGroup(objectIds) {
      // a group can be created from current selection (default) or given element ids
      const selectedObjectIds = objectIds ? objectIds : this._selectedElements;

      // move objects on the order they are in right know, not in the order they were selected in
      const selectedObjectIdsInOrder = [];
      flattenStructure(this.groupStructure).forEach(({ id }) => {
        const objectId = selectedObjectIds.find((objectId) => objectId === id);
        if (objectId) selectedObjectIdsInOrder.push(objectId);
      });

      const currentGroupStructure = this.groupStructure;
      if (selectedObjectIdsInOrder.length > 1) {
        this.discardActiveObject(); // reset selection
        const groupId = nanoid();
        this.groupStructure = createGroupInStructure(
          currentGroupStructure,
          selectedObjectIdsInOrder,
          groupId
        );

        this.adjustObjectsIndexToStructure(); // move objects to group index
        this.selectIds([groupId]); // redo selection
      }
      this.updateObjectsGroupRelatedProperties();
    },

    discardGroup(objectIds) {
      // the to-be discarded group can come from current selection (default) or given element ids
      const selectedObjectIds = objectIds ? objectIds : this._selectedElements;

      // typically only one group (from selection) is discarded
      if (selectedObjectIds.length) {
        selectedObjectIds.forEach((objId) => {
          this.groupStructure = discardGroupFromStructure(
            this.groupStructure,
            objId
          );
        });
      }

      this.discardActiveObject();
      this.updateObjectsGroupRelatedProperties();
    },

    // reset the group selection, called on selection:cleared
    resetGroupSelection() {
      this._selectedElements = [];
    },

    /**
     * This is a function based on fabric's `_fireSelectionEvents` in v4.3.1.
     * The only difference is that, we fire `selected` for all new objects to fix an issue,
     * see diff here: https://github.com/Kittl/editor/pull/1415/files#diff-ecbf954ad54bfd2a32974e305ca567537e2e733f9ef974c9e772ea34f46508a0L652-L662
     */
    fireSelectionEvents(previousObjects, newObjects, e) {
      let somethingChanged = false;
      const added = [];
      const removed = [];
      const opt = { e };

      previousObjects.forEach((previousObject) => {
        if (newObjects.indexOf(previousObject) === -1) {
          somethingChanged = true;

          // `previousObject` might be `undefined` when a previous group was dismissed
          if (previousObject) {
            previousObject.fire('deselected', opt);
            removed.push(previousObject);
          }
        }
      });

      newObjects.forEach((object) => {
        // Fix a bug with first object not having `selected` event fired
        // when it becomes a part of ActiveSelection
        // Fixes CU-apzjaj
        object.fire('selected', opt);
        if (previousObjects.indexOf(object) === -1) {
          somethingChanged = true;
          added.push(object);
        }
      });

      if (previousObjects.length > 0 && newObjects.length > 0) {
        opt.selected = added;
        opt.deselected = removed;
        // added for backward compatibility
        opt.updated = added[0] || removed[0];
        opt.target = this._activeObject;
        somethingChanged && this.fire('selection:updated', opt);
      } else if (newObjects.length > 0) {
        opt.selected = added;
        opt.target = this._activeObject;
        this.fire('selection:created', opt);
      } else if (previousObjects.length > 0) {
        opt.deselected = removed;
        this.fire('selection:cleared', opt);
      }
    },

    /* Update groupStructure, by
     *   - first: see if any of the objects were removed
     *   - then: adding new objects to structure
     * This function must be used if an object is added or removed from canvas
     */
    updateGroupStructure() {
      const objectsIds = this.allObjects()
        .reverse() // reverse, to have order from upper level objects to lower level ones
        .filter((obj) => !obj.linkedToObject) // do not include linking objects (IText)
        .map((obj) => obj.id);
      const foundObjectIds = [];

      const testRecursive = (structure) => {
        // is object
        if (!structure.children) {
          const actualIndex = objectsIds.indexOf(structure.id);
          if (actualIndex !== -1) {
            foundObjectIds.push(structure.id);
            return structure;
          } else {
            return false;
          }
        }

        // is group
        const checkedChildren = structure.children.reduce((acc, child) => {
          const childStructure = testRecursive(child);
          if (childStructure) {
            return [...acc, childStructure];
          }
          return acc;
        }, []);

        if (checkedChildren.length >= 1 || !structure.id) {
          if (checkedChildren.length === 1 && structure.id) {
            // if one child and is not canvas
            // delete group and reference child directly
            return checkedChildren[0];
          }
          return { ...structure, children: checkedChildren };
        } else {
          return false;
        }
      };

      const updatedStructure = testRecursive(this.groupStructure);

      objectsIds
        .filter((id) => !foundObjectIds.includes(id))
        .reverse()
        .forEach((id) => {
          updatedStructure.children.unshift({ id });
        });

      this.groupStructure = updatedStructure;
      this.updateObjectsGroupRelatedProperties();
    },

    // handle updates after an object was added or removed
    onAddRemoveObject(target) {
      if (target && !networkStore.getState().online) {
        this.remove(target);
        return;
      }

      const opt = target ? { target } : {};
      this.updateGroupStructure();

      // Remove objects that have been removed from _selectedElements
      const groupStructureLeafNodeIds = getAllChildObjects(this.groupStructure);
      const isInGroupStructure = isInIds(groupStructureLeafNodeIds);
      this._selectedElements =
        this._selectedElements.filter(isInGroupStructure);

      this.fire('object:modified', opt);
      this.fire('selection:updated', opt);
      // Direct selection
      if (target) {
        this.selectIds([target.id]);
      }
      this.lastColorPalette = null;
      this.requestRenderAll();
    },

    // a function to adjust the objects on canvas to the order given by the structure
    adjustObjectsIndexToStructure() {
      const objectsInStructure = flattenStructure(this.groupStructure)
        .filter(
          (element) =>
            !['structuralGroup', 'clippingMask'].includes(element.type)
        ) // we only care about actual objects
        .reverse() // order in groupsStructure is from highest to lowest, while objects is from lowest to highest
        .map((obj) => obj.id);

      // filter out alignment lines
      const allObjects = this.allObjects().filter(
        (obj) => !obj.isAlignmentAuxiliary
      );

      if (objectsInStructure.length !== allObjects.length) {
        console.warn(
          'adjustObjectsIndexToStructure() can only be applied when allObjects and groupStructure contain the same objects!'
        );
        return false;
      }

      let currentObjectOrder = allObjects.map((obj) => obj.id);
      objectsInStructure.forEach((id, index) => {
        if (currentObjectOrder[index] !== id) {
          // if there is a problem in the current order
          const object = getObjectById(id, allObjects); // get object
          this.moveTo(object, index + NUMBER_OF_BACKGROUND_OBJECTS); // move object
          currentObjectOrder = this.allObjects().map((obj) => obj.id); // update order
        }
      });
    },

    _toggleElementsProperty(elementId, property) {
      const object = this.getObjects().find(
        (object) => object.id === elementId
      );
      if (object) {
        // if the element is a canvas object, we just have to toggle its hidden value
        object.set(property, !object.get(property));
      } else {
        // elements is a group
        this.groupStructure = toggleElementsMetaProperty(
          this.groupStructure,
          elementId,
          property
        );
      }
    },

    toggleElementHidden(elementId) {
      this._toggleElementsProperty(elementId, 'hidden');
      this.updateObjectsGroupRelatedProperties();
    },

    toggleElementLocked(elementId) {
      this._toggleElementsProperty(elementId, 'locked');
      this.updateObjectsGroupRelatedProperties();
    },

    shouldLockSelection() {
      const selectedElementIds = this._selectedElements; // top-level selected structure elements
      const allObjects = this.getObjects();
      return selectedElementIds.some((id) => {
        if (isElementLocked(this.groupStructure, id)) {
          return true; // if group element or parent group is locked
        }
        const object = allObjects.find((object) => object.id === id);
        if (object && object.locked) {
          return true;
        }
        return false;
      });
    },

    // some properties of an object are dependent on properties of their group (locked/hidden)
    // these properties should be updated when the groupStructure changes
    updateObjectsGroupRelatedProperties() {
      const allObjects = this.getObjects().filter(
        (object) => object.type !== 'artboard' && !object.isAlignmentAuxiliary
      );
      allObjects.forEach((object) => {
        const lockedParent = isElementLocked(this.groupStructure, object.id);
        if (object.lockedParent !== lockedParent) {
          object.set({ lockedParent });
        }
        const hiddenParent = isElementHidden(this.groupStructure, object.id);
        if (object.hiddenParent !== hiddenParent) {
          object.set({ hiddenParent });
        }
      });
    },

    getSelectedElements() {
      return this._selectedElements;
    },

    /**
     * Set opacity values for groups in the groupStructure
     * @param {*} opacity
     */
    handleGroupOpacity(opacity) {
      // _selectedElements are selected top-level elements (eg. groups)
      if (this._selectedElements.length) {
        this.groupStructure.children = transformRecursive(
          this.groupStructure.children,
          (item) => {
            // is a top level group?
            if (
              item.children?.length &&
              this._selectedElements.includes(item.id)
            ) {
              const meta = { ...item.meta, opacity };
              return { ...item, meta };
            }
            return item;
          }
        );
      }
    },
  });
}
