import { themeStyle } from '../../services/theming';

const themeCursorInfoBox = {
  backgroundColor: themeStyle.varInkMain,
  borderRadius: themeStyle.radiusSmall,

  labelColor: themeStyle.varInkBase,
  labelFont: themeStyle.fontMedium,
  labelFontSize: themeStyle.fontSize10,
};

export { themeCursorInfoBox };
