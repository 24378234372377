export const filterOptions = [
  { id: 'all', label: 'All' },
  { id: 'serif', label: 'Serif' },
  { id: 'sans-serif', label: 'Sans Serif' },
  { id: 'script', label: 'Script' },
  { id: 'other', label: 'Other' },
];

const FILTERS = ['serif', 'sans-serif', 'script', 'other'];

// add testing data
export const addTags = (fonts) =>
  fonts.map((font) => {
    const tags = [font.name];
    if (font.variable) tags.push('variable');
    if (font.type) tags.push(font.type);
    if (font.tags) tags.push(...font.tags);

    const filter = FILTERS.includes(font.type) ? font.type : undefined;
    const lowerTags = tags.filter((tag) => tag).map((tag) => tag.toLowerCase());

    return {
      ...font,
      filter,
      tags: lowerTags,
    };
  });

export const getConfig = (fonts) => {
  const fontsWithTags = addTags(fonts);
  const uploads = {
    label: 'Uploads',
    items: fontsWithTags.filter(({ isUserFont }) => isUserFont),
  };

  return [
    ...(uploads.items.length ? [uploads] : []),
    {
      label: 'More',
      items: fontsWithTags.filter(({ isUserFont }) => !isUserFont),
    },
  ];
};
