import { fabric } from 'fabric';

import { ItemRect } from './types';

export const hasEnoughItemsToTidy = (
  topLevelIds: string[],
  objects: fabric.Object[]
): boolean => {
  const selectedOneGroup =
    topLevelIds.length === 1 &&
    !objects.find((object) => object.id === topLevelIds[0]);

  return (selectedOneGroup && objects.length > 2) || topLevelIds.length > 2;
};

export const getObjectsRect = (objects: fabric.Object[]): ItemRect => {
  const objectRects = objects.map((object) => object.getBoundingRect());
  const left = Math.min(...objectRects.map((rect) => rect.left));
  const top = Math.min(...objectRects.map((rect) => rect.top));
  const right = Math.max(...objectRects.map((rect) => rect.left + rect.width));
  const bottom = Math.max(...objectRects.map((rect) => rect.top + rect.height));

  return {
    left,
    top,
    width: right - left,
    height: bottom - top,
  };
};
