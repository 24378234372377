import Cookies from 'js-cookie';

import {
  COOKIE_DOMAIN,
  DEVICE_ID_KEY,
  ONBOARDING_KEY,
  USER_ACCESS_TOKEN_KEY,
} from '../../global/constants';
import userApi from '../../global/userApi';
import { buildProfileImageUrl } from '../../utils/url';
import { growthbook } from '../../services/growthbook';
import { GrowthBook } from '@growthbook/growthbook-react';
import { State } from './types';
import { ONBOARDING_USER_STEP } from '../onboardingStore/constants';
import { isOnDevEnvironment } from '../../global/environment';
import { getUniqueId } from '../../utils/id';
import { ResponseWithError, User } from '../../types';
import { onboardingStore } from '../onboardingStore';

const COOKIE_PARAMS: Cookies.CookieAttributes = {
  expires: 30,
  path: '/',
  domain: COOKIE_DOMAIN,
  sameSite: 'lax',
  secure: !isOnDevEnvironment(),
};

// update token in cookie
export const setToken = (accessToken: string | null): void => {
  if (!accessToken) {
    Cookies.remove(USER_ACCESS_TOKEN_KEY, COOKIE_PARAMS);
  } else {
    Cookies.set(USER_ACCESS_TOKEN_KEY, accessToken, COOKIE_PARAMS);
  }
};

// get deviceId from cookie (or set it if it's not there)
const getDeviceId = (): string => {
  let deviceId = Cookies.get(DEVICE_ID_KEY);
  if (!deviceId) {
    deviceId = getUniqueId();
    Cookies.set(DEVICE_ID_KEY, deviceId!, COOKIE_PARAMS);
  }
  return deviceId!;
};

// utility to get information about the current user
export const getUserInfo = async (
  accessToken?: string
): Promise<User | null> => {
  const _accessToken = accessToken || Cookies.get(USER_ACCESS_TOKEN_KEY);

  if (_accessToken) {
    const response = await userApi.getUserInfo(_accessToken);
    if ((response as ResponseWithError).error) {
      return null;
    }

    const user = response as User;
    if (user.profileImage) {
      user.profileImage = buildProfileImageUrl(user.profileImage);
    }
    if (growthbook) {
      (growthbook as GrowthBook).setAttributes({
        id: user.id,
        email: user.email,
        deviceId: getDeviceId(),
        plan: user.plan || 'FREE',
      });
    }
    return user;
  } else {
    if (growthbook) {
      (growthbook as GrowthBook).setAttributes({
        deviceId: getDeviceId(),
      });
    }
    return null;
  }
};

export const updateOnboarding = (user: State['user']): void => {
  const initOnboarding = onboardingStore.getState().init;
  const localOnboarding = JSON.parse(sessionStorage.getItem(ONBOARDING_KEY)!);
  if (localOnboarding?.step === ONBOARDING_USER_STEP.SKIPPED.name) {
    initOnboarding(ONBOARDING_USER_STEP.SKIPPED);
  } else if (user && user?.onboardingState) {
    initOnboarding(ONBOARDING_USER_STEP[user.onboardingState]);
  }
};
