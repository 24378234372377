import styled from 'styled-components';

import { themeStyle } from '../../services/theming';

export const Wrapper = styled.span`
  svg {
    .kittl {
      fill: ${themeStyle.varInkMain};
    }

    .beta {
      fill: ${themeStyle.varInkBase};
    }

    .beta-wrapper {
      fill: ${themeStyle.varBrandMain};
    }
  }
`;
