import { Delta, DiffPatcher } from 'jsondiffpatch';
import cloneDeep from 'lodash/cloneDeep';

// properties on objects that history doesn't care about
const FILTERED_PROPERTIES = ['selected'];

// this number is a delay between two updates to be considered separate updates
// also it's low enough so that person actually CAN NOT do meaningful updates in this time
// and mostly used to prevent internal changes (that come in bursts) to pollute history stack
const UPDATE_DELAY = 250;

// initial load can take some time and cause lots of updates
const INITIAL_DELAY = 1000;

const jdf = new DiffPatcher({
  propertyFilter: (name: string): boolean => {
    return !FILTERED_PROPERTIES.includes(name);
  },
});

export type HistoryState = Record<string, unknown>;

export interface HistoryUtil {
  undo: (skipLock?: boolean) => HistoryState | undefined;
  redo: (skipLock?: boolean) => HistoryState | undefined;
  update: (
    updatedState: HistoryState
  ) => Promise<void | HistoryState | undefined>;
  getState: () => HistoryState | undefined;
  drop: () => void;
  reset: (newState: HistoryState) => void;
}

const createHistory = (initialState?: HistoryState): HistoryUtil => {
  let state = cloneDeep(initialState);
  let stack: Delta[] = [];
  let position = 0;
  let locked = false;
  let updateTimeout: number;

  const drop = (): void => {
    stack = [];
    position = 0;
    locked = false;
  };

  const reset = (newState: HistoryState): void => {
    drop();
    state = cloneDeep(newState);
  };

  const unlock = (): void => {
    setTimeout(() => {
      locked = false;
    }, UPDATE_DELAY);
  };

  const lock = (): void => {
    locked = true;
    unlock();
  };

  const canUndo = (): boolean => {
    return position > 0;
  };

  const canRedo = (): boolean => {
    return position < stack.length;
  };

  const undo = (skipLock = false): HistoryState | undefined => {
    if (canUndo()) {
      !skipLock && lock();
      position--;
      state = jdf.unpatch(state, stack[position]);
      return getState();
    } else {
      return undefined;
    }
  };

  const redo = (skipLock = false): HistoryState | undefined => {
    if (canRedo()) {
      !skipLock && lock();
      state = jdf.patch(state, stack[position]);
      position++;
      return getState();
    } else {
      return undefined;
    }
  };

  const getState = (): HistoryState | undefined => cloneDeep(state);

  // TODO: won't resolve if called 2+ times in a row
  const update = async (
    updatedState: HistoryState
  ): Promise<void | HistoryState | undefined> => {
    const newState = cloneDeep(updatedState);
    // don't save state right after undo/redo
    if (locked) {
      return Promise.resolve();
    }
    // lots of successive small changes are combined into one
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }
    const delay = state ? UPDATE_DELAY : INITIAL_DELAY;
    return new Promise((resolve) => {
      updateTimeout = window.setTimeout(() => {
        // if initialState wasn't set, use first newState as initialState
        if (!state) {
          state = newState;
          return resolve(getState());
        }
        const delta = jdf.diff(state, newState);
        if (delta) {
          stack = stack.slice(0, position);
          position++;
          stack.push(delta);
          state = newState;
          clearTimeout(updateTimeout);
        }
        resolve(getState());
      }, delay);
    });
  };

  return {
    undo,
    redo,
    update,
    getState,
    drop,
    reset,
  };
};

export default createHistory;
