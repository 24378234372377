import { themeStyle } from '../../services/theming';
import fabric from './fabric';

const GUIDELINE_OFFSET = 5; // 5 was experimentally determined to be a good number
const STROKE_WIDTH = 1;

// see https://konvajs.org/docs/sandbox/Objects_Snapping.html

// figure out on which positions a object can be aligned to other objects?
// get the alignment value and the boundingBox for each possible alignment position
export const getLineGuideStops = (skipShapes, canvas) => {
  // objects can be aligned to canvas start, center and end of the canvas
  const vertical = [];
  const horizontal = [];

  // and objects can be aligned to start, center and end of other objects
  canvas.getObjects().forEach((guideObject) => {
    if (
      skipShapes.find((obj) => obj.id === guideObject.id) ||
      guideObject.isAlignmentAuxiliary
    ) {
      return;
    }
    const box = guideObject.getBoundingRect(true);
    vertical.push(getStopsForObject(box.left, box.width, box.top, box.height));
    horizontal.push(
      getStopsForObject(box.top, box.height, box.left, box.width)
    );
  });
  return {
    vertical: vertical.flat(),
    horizontal: horizontal.flat(),
  };
};

export const getGridStops = (rect, gridSize) => {
  // return closest grid positions
  const vertical = [
    { val: Math.round(rect.left / gridSize) * gridSize },
    {
      val: Math.round((rect.left + rect.width) / gridSize) * gridSize,
    },
  ];
  const horizontal = [
    { val: Math.round(rect.top / gridSize) * gridSize },
    {
      val: Math.round((rect.top + rect.height) / gridSize) * gridSize,
    },
  ];

  return {
    vertical: vertical,
    horizontal: horizontal,
  };
};

// get the lineGuideStops for an object on a dimension
// start is either left or top
// distance is either width or height
// the second pair of values are the start and distance from the other dimension
const getStopsForObject = (start, distance, drawStart, drawDistance) => {
  const stops = [start, start + distance / 2, start + distance];
  return stops.map((stop) => {
    return {
      val: stop,
      start: drawStart,
      end: drawStart + drawDistance,
    };
  });
};

// guide: used to determine whether to trigger alignment
// offset: offset between object and its boundingBox
export const getObjectSnappingEdges = (target) => {
  const rect = target.getBoundingRect(true);
  return {
    vertical: [
      {
        guide: Math.round(rect.left),
        offset: Math.round(target.left - rect.left),
        snap: 'start',
      },
      {
        guide: Math.round(rect.left + rect.width / 2),
        offset: Math.round(target.left - rect.left - rect.width / 2),
        snap: 'center',
      },
      {
        guide: Math.round(rect.left + rect.width),
        offset: Math.round(target.left - rect.left - rect.width),
        snap: 'end',
      },
    ],
    horizontal: [
      {
        guide: Math.round(rect.top),
        offset: Math.round(target.top - rect.top),
        snap: 'start',
      },
      {
        guide: Math.round(rect.top + rect.height / 2),
        offset: Math.round(target.top - rect.top - rect.height / 2),
        snap: 'center',
      },
      {
        guide: Math.round(rect.top + rect.height),
        offset: Math.round(target.top - rect.top - rect.height),
        snap: 'end',
      },
    ],
  };
};

// find all alignment possibilities
export const getGuides = (lineGuideStops, itemBounds) => {
  const resultV = [];
  const resultH = [];

  lineGuideStops.vertical.forEach((lineGuide) => {
    itemBounds.vertical.forEach((itemBound) => {
      const diff = Math.abs(lineGuide.val - itemBound.guide);
      // if the distance between guide line and object snap point is close we can consider this for alignment
      if (diff < GUIDELINE_OFFSET) {
        resultV.push({
          lineGuide: lineGuide.val,
          diff: diff,
          orientation: 'V',
          snap: itemBound.snap,
          offset: itemBound.offset,
          targetDim: { start: lineGuide.start, end: lineGuide.end },
        });
      }
    });
  });

  lineGuideStops.horizontal.forEach((lineGuide) => {
    itemBounds.horizontal.forEach((itemBound) => {
      const diff = Math.abs(lineGuide.val - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        resultH.push({
          lineGuide: lineGuide.val,
          diff: diff,
          orientation: 'H',
          snap: itemBound.snap,
          offset: itemBound.offset,
          targetDim: { start: lineGuide.start, end: lineGuide.end },
        });
      }
    });
  });

  const guides = [];

  // find closest alignment options
  const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
  const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
  if (minV) {
    guides.push({
      lineGuide: minV.lineGuide,
      offset: minV.offset,
      orientation: 'V',
      snap: minV.snap,
      targetDim: minV.targetDim,
    });
  }
  if (minH) {
    guides.push({
      lineGuide: minH.lineGuide,
      offset: minH.offset,
      orientation: 'H',
      snap: minH.snap,
      targetDim: minH.targetDim,
    });
  }
  return guides;
};

// the drawn lines are either vertical or horizontal, which means that those coordinates are the same
// in the other dimension, the line should not exceed the bounding rect of the target and the object to align it to
export const drawGuides = (guides, targetRect, canvas) => {
  guides.forEach((lineGuide) => {
    if (lineGuide.orientation === 'H') {
      canvas.add(
        getAlignmentLine([
          Math.min(targetRect.left, lineGuide.targetDim.start),
          lineGuide.lineGuide - STROKE_WIDTH / 2,
          Math.max(targetRect.left + targetRect.width, lineGuide.targetDim.end),
          lineGuide.lineGuide - STROKE_WIDTH / 2,
        ])
      );
    } else if (lineGuide.orientation === 'V') {
      canvas.add(
        getAlignmentLine([
          lineGuide.lineGuide - STROKE_WIDTH / 2,
          Math.min(targetRect.top, lineGuide.targetDim.start),
          lineGuide.lineGuide - STROKE_WIDTH / 2,
          Math.max(targetRect.top + targetRect.height, lineGuide.targetDim.end),
        ])
      );
    }
  });
};

const getAlignmentLine = (points) => {
  return new fabric.Line(points, {
    strokeWidth: STROKE_WIDTH,
    strokeDashArray: [3, 5],
    stroke: themeStyle.selectionBlue,
    strokeLineCap: 'square',
    excludeFromExport: true,
    isAlignmentAuxiliary: true,
    selectable: false,
  });
};
