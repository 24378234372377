import { themeStyle } from '../../services/theming';
import { TextInputTheme } from '../TextInput/types';

export const trashTheme = {
  color: themeStyle.varInkMedium,
  activeColor: themeStyle.varBrandMain,
  activeDefaultColor: themeStyle.varBrandMain,
};

export const transparentInputTheme: Partial<TextInputTheme> = {
  // text
  fontWeight: themeStyle.fontSemiBold,
  fontSize: themeStyle.fontSize14,
  lineHeight: '18px',

  color: themeStyle.varInkMain,
  colorHover: themeStyle.varInkMain,
  colorActive: themeStyle.varInkMain,
  backgroundColor: 'transparent',
  backgroundColorHoverDark: null,
  border: 'none',
  borderColor: 'transparent',
  hoverShadow: 'unset',
  innerPadding: '0px',

  // size
  height: '18px',
};
