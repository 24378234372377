import React, { FormEvent, useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import subscriptionApi from '../../global/subscriptionApi';

interface PaymentFormProps {
  // The handler after the payment succeeds
  onSuccess: () => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    try {
      await subscriptionApi.comfirmSubscriptionIntent(stripe, elements);
      onSuccess();
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      }
    }
    setLoading(false);
  };

  return (
    <form name="kittl_subscription" onSubmit={handleSubmit}>
      <PaymentElement />
      <button type="submit" disabled={loading || !stripe || !elements}>
        <span>{loading ? 'Loading...' : 'Pay now'}</span>
      </button>
      {error && <div>{error}</div>}
    </form>
  );
};

export default React.memo(PaymentForm);
