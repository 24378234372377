/**
 * utility function to validate user input
 */
import { EMAIL_REGEXP, MIN_PASSWORD_LENGTH } from '../global/constants';

export const NAME_REQUIRED = 'Name field is required';
export const EMAIL_REQUIRED = 'E-Mail field is required';
export const EMAIL_INVALID = 'E-Mail is invalid';
export const PASSWORD_REQUIRED = 'Password field is required';
export const PASSWORD_SHORT = 'Password is too short';

export const validateName = (value?: string | null): string | null => {
  if (!value) {
    return NAME_REQUIRED;
  }
  return null;
};

export const validateEmail = (value?: string | null): string | null => {
  if (!value) {
    return EMAIL_REQUIRED;
  }
  if (!EMAIL_REGEXP.test(value)) {
    return EMAIL_INVALID;
  }
  return null;
};

export const validatePassword = (value?: string | null): string | null => {
  if (!value) {
    return PASSWORD_REQUIRED;
  }
  if (value.length < MIN_PASSWORD_LENGTH) {
    return PASSWORD_SHORT;
  }

  return null;
};
