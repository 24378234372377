import fabric from './index';
import { ObjectType, Point } from '../../../types';

interface WarpedImageOptions {
  id: string;
  /*
    The id of the warp canvas element.
    Used to query the warp canvas.
   */
  warpCanvasId: string;
  /*
    The top left corner of the warped image.
   */
  topLeftInWarpGrid: Point;
  /*
    The size of the image in the warp grid.
    In relative values (between 0 and 1).
   */
  sizeInWarpGrid: {
    width: number;
    height: number;
  };
  /*
    Render the image with given top left and size in the warp grid on the warp canvas.
   */
  warpRender: (
    topLeft: {
      x: number;
      y: number;
    },
    size: {
      width: number;
      height: number;
    }
  ) =>
    | {
        warpedAttrs: {
          cropX: number;
          cropY: number;
          left: number;
          top: number;
          width: number;
          height: number;
        };
        topLeftRatioInBBox: Point;
      }
    | undefined;
}

const initWarpedImage = (fabric: fabric): void => {
  /**
   * Custom fabric class for warped images.
   * How it works:
   * For any warped image, there should be an off-screen (WebGL) canvas where the warp takes place.
   * This can be seen as an interface between the fabric canvas and the warp canvas --
   * on one hand, it provides the position and the size of the image used for warp,
   * on the other hand, the warped content is clipped and displayed on the fabric canvas.
   */
  fabric.WarpedImage = fabric.util.createClass(fabric.Image, {
    type: ObjectType.WarpedImage,

    async initialize({
      id,
      warpCanvasId,
      topLeftInWarpGrid,
      sizeInWarpGrid,
      warpRender,
    }: WarpedImageOptions): fabric.WarpedImage {
      this.callSuper('initialize');
      // rotation is not supported for now
      this.setControlsVisibility({
        rotate: false,
      });
      this.id = id;
      this.warpCanvasId = warpCanvasId;
      this.warpCanvas = document.getElementById(this.warpCanvasId);
      this.topLeftInWarpGrid = topLeftInWarpGrid;
      this.sizeInWarpGrid = sizeInWarpGrid;
      this.warpRender = warpRender;

      // Set src with debounce, as it's too expensive for continuous interactions.
      this.setSrcTimeout = null;

      this.on('moving', this.handleMoving);
      this.on('moved', this.handleMoved);
      this.on('scaling', this.handleScaling);
      this.on('scaled', this.handleScaled);

      return new Promise((resolve) => {
        const result = this.warpRender(
          this.topLeftInWarpGrid,
          this.sizeInWarpGrid
        );

        // the initial warp should always in bound, because it
        // is either the first time the image is added,
        // comes from undo/redo.
        if (!result) {
          throw new Error('Image is out-of-bound in warp grid initially.');
        }

        const { warpedAttrs, topLeftRatioInBBox } = result;

        // Used to "pin point" the image when scaling, or we can get a "spiral" effect.
        this.topLeftRatioInBBox = topLeftRatioInBBox;
        // The last warped attrs, used as fallback value when the image gets moved out of bound.
        this.lastWarpedAttrs = warpedAttrs;

        const sourceDataUrl = this.warpCanvas.toDataURL();
        this.setSrc(sourceDataUrl, () => {
          this.set(warpedAttrs);
          resolve(this);
        });
      });
    },

    /*
      Render the image on warp canvas on transform, with updated top left and size.
      Returns the attrs to our interest after warp, which should then be set to `this`.
     */
    warpOnTransform(): {
      cropX: number;
      cropY: number;
      left: number;
      top: number;
      width: number;
      height: number;
    } {
      const topLeftInWarpGrid = {
        x: this.left + this.topLeftRatioInBBox.x * this.width,
        y: this.top + this.topLeftRatioInBBox.y * this.height,
      };
      const sizeInWarpGrid = {
        width: this.sizeInWarpGrid.width * this.scaleX,
        height: this.sizeInWarpGrid.height * this.scaleY,
      };

      const result = this.warpRender(topLeftInWarpGrid, sizeInWarpGrid);

      if (!result) return this.lastWarpedAttrs;

      const { warpedAttrs, topLeftRatioInBBox } = result;
      // only set top left and size if a warp is done (within bound),
      // or it will break for undo / redo after movements out of bound.
      this.topLeftInWarpGrid = topLeftInWarpGrid;
      this.sizeInWarpGrid = sizeInWarpGrid;

      this.topLeftRatioInBBox = topLeftRatioInBBox;
      this.lastWarpedAttrs = warpedAttrs;
      return warpedAttrs;
    },

    /*
      Toggle the visibility of borders and controls.
      As the size of the image can be different when moving/scaling,
      we don't want to show the updating borders and controls.
     */
    toggleBordersAndControls(visible: boolean): void {
      this.set({
        hasBorders: visible,
        hasControls: visible,
      });
    },

    /*
      The handler when the object is moved "indirectly".
      e.g., when using the arrow keys.
     */
    handleOnMove(): void {
      // use a more performant handler while the moving is happening.
      this.handleMoving();

      if (this.setSrcTimeout) {
        clearTimeout(this.setSrcTimeout);
      }

      this.setSrcTimeout = setTimeout(() => {
        // use a less performant handler when the moving is done.
        this.handleMoved();
        this.setSrcTimeout = null;
      }, 200);
    },

    /*
      A rather performant handler for movement.
      It can result in a partial `src` for methods like `toObject`,
      so only consider to use it when performance is crucial,
      e.g., when this is being continuously moved.
     */
    handleMoving(e?: fabric.Event): void {
      const warpedAttrs = this.warpOnTransform();
      this.setElement(this.warpCanvas);
      this.set(warpedAttrs);

      if (e) {
        this.updateCurrentTransform(e);
      }

      this.toggleBordersAndControls(false);
    },

    /*
      Update `_currentTransform` used for continous transforms, like moving and scaling,
      Because the `left` and `top` are set with updated values after warp,
      the info kept in `_currentTransform` is stale.
     */
    updateCurrentTransform(e: fabric.Event): void {
      const pointer = this.canvas.getPointer(e);
      this.canvas._currentTransform.scaleX = this.scaleX;
      this.canvas._currentTransform.scaleY = this.scaleY;
      this.canvas._currentTransform.offsetX = pointer.x - this.left;
      this.canvas._currentTransform.offsetY = pointer.y - this.top;
      this.canvas._currentTransform.ex = pointer.x;
      this.canvas._currentTransform.ey = pointer.y;
      this.canvas._currentTransform.lastX = pointer.x;
      this.canvas._currentTransform.lastY = pointer.y;
    },

    /*
      A less performant handler for movement.
      It directly sets the `src` so there won't be partial data.
      Used when a movement is done.
     */
    handleMoved(): void {
      const warpedAttrs = this.warpOnTransform();
      const sourceDataUrl = this.warpCanvas.toDataURL();
      this.setSrc(sourceDataUrl, () => {
        this.set(warpedAttrs);
        this.toggleBordersAndControls(true);
        this.canvas.requestRenderAll();
      });
    },

    /*
      A rather performant handler for scaling.
     */
    handleScaling(e: fabric.Event): void {
      const warpedAttrs = this.warpOnTransform();
      this.setElement(this.warpCanvas);
      this.set({
        ...warpedAttrs,
        scaleX: 1,
        scaleY: 1,
      });
      this.updateCurrentTransform(e);
      this.toggleBordersAndControls(false);
    },

    /*
      A less performant handler for scaling.
     */
    handleScaled(): void {
      const warpedAttrs = this.warpOnTransform();
      const sourceDataUrl = this.warpCanvas.toDataURL();
      this.setSrc(sourceDataUrl, () => {
        this.set({
          ...warpedAttrs,
          scaleX: 1,
          scaleY: 1,
        });
        this.toggleBordersAndControls(true);
        this.canvas.requestRenderAll();
      });
    },

    /*
      This is an override of fabric function.
     */
    toObject(propertiesToInclude: string[] = []): Record<string, unknown> {
      const filters: Record<string, unknown>[] = [];
      this.filters.forEach((filterObj: fabric.Image.filters) => {
        if (filterObj) {
          filters.push(filterObj.toObject());
        }
      });

      /* our changes starts */
      // We don't need `src` because it's quite a burdon for history comparison,
      // and `getSrc` can be quite time-consuming.
      // Here we need to use the `toObject` in `fabric.Object` rather than `fabric.Image`,
      // because the latter always call `getSrc`.
      const object = fabric.util.object.extend(
        fabric.Object.prototype.toObject.call(
          this,
          [
            'cropX',
            'cropY',
            'warpCanvasId',
            'topLeftInWarpGrid',
            'sizeInWarpGrid',
            'warpRender',
          ].concat(propertiesToInclude)
        ),
        {
          crossOrigin: this.getCrossOrigin(),
          filters: filters,
        }
      );
      /* our changes ends */

      if (this.resizeFilter) {
        object.resizeFilter = this.resizeFilter.toObject();
      }

      return object;
    },
  });

  fabric.WarpedImage.fromObject = async (
    object: Record<string, unknown>,
    callback: (object: fabric.WarpedImage) => void
  ): Promise<void> => {
    const warpedImage = await new fabric.WarpedImage(object);
    callback(warpedImage);
  };
};

export default initWarpedImage;
