import React, { useState, useEffect } from 'react';
import chunk from 'lodash/chunk';
import { Row } from './styles';
import Element from './Element/Element';
import { EffectSelectProps } from './types';
import RightMenuHeader from '../utilities/RightMenuHeader/RightMenuHeader';

/**
 * An EffectSelect is used to select one of multiple events.
 * This component is used for text Transform, Shading and Decoration Panels
 */
const EffectSelect: React.FC<EffectSelectProps> = (props) => {
  // get elementSize as number
  const matches = props.elementSize.match(/\d+/g); // creates array from matches
  const elementSize = Number(matches!.shift());
  const width = 270;

  const [activeEffect, setActiveEffect] = useState<string | null>(
    props.activeEffect
  );

  useEffect(() => {
    setActiveEffect(props.activeEffect);
  }, [props.activeEffect]);

  const onClickEffect = (id: string): void => {
    let selectedElement: string | null = id;

    if (id === activeEffect) {
      selectedElement = null;
    }

    setActiveEffect(selectedElement);
    props.onSelect && props.onSelect(selectedElement);
  };

  // split list of effects into rows of four
  const effectsPerRow = Math.floor(width / elementSize);
  const effectRows = chunk(props.config, effectsPerRow);

  // render n empty elements, to fill a row
  const renderEmptyFillElements = (n: number): React.ReactNode => {
    const range = Array(n).fill(null);
    return range.map((_, index) => (
      <Element
        id={`empty-${index}`}
        key={`fill-${index}`}
        icon={null}
        size={props.elementSize}
      />
    ));
  };

  // render the panel, on which a selected effect can be configured
  const renderEffectConfig = (): React.ReactNode => {
    const activeEffectConfig = props.config.find(
      ({ id }) => id.toString() === activeEffect
    );

    return activeEffectConfig?.configPanel
      ? React.cloneElement(activeEffectConfig.configPanel)
      : null;
  };

  return (
    <div>
      <RightMenuHeader label={props.label} />
      {effectRows.map((row, index) => (
        <div key={index}>
          <Row index={index}>
            {row.map((effect) => (
              <Element
                id={`${props.label}-${effect.id}`}
                key={effect.id}
                icon={effect.icon}
                size={props.elementSize}
                isActive={effect.id === activeEffect}
                onClick={(): void => onClickEffect(effect.id)}
                iconMapping={effect.iconMapping || props.defaultIconMapping}
              />
            ))}
            {effectsPerRow - row.length > 0 &&
              renderEmptyFillElements(effectsPerRow - row.length)}
          </Row>
        </div>
      ))}
      {activeEffect && renderEffectConfig()}
    </div>
  );
};

EffectSelect.defaultProps = {
  label: 'Effect Title',
  elementSize: '60px',
};

export default EffectSelect;
