import OptionRowWidget from './Widgets/OptionRowWidget/OptionRowWidget';
import { WidgetDictionary, WidgetStageProps } from './types';
import {
  ACTIVE_COPY,
  ACTIVE_DELETE,
  ACTIVE_EDIT_MODE,
  ACTIVE_PASTE,
} from '../../global/events';
import { Option } from './Widgets/OptionRowWidget/types';
import { defaultPositioner } from './util/positioning';

const defaultOptions: Option[] = [
  {
    icon: 'delete',
    onClick: (dispatch): void => {
      dispatch(ACTIVE_DELETE);
    },
  },
  {
    icon: 'copy',
    onClick: (dispatch): void => {
      dispatch(ACTIVE_COPY);
      dispatch(ACTIVE_PASTE);
    },
  },
];

const pathTextOptions: Option[] = [
  ...defaultOptions,
  {
    icon: 'textEdit',
    onClick: (dispatch): void => {
      dispatch(ACTIVE_EDIT_MODE);
    },
  },
];

const DefaultWidget: React.FC<WidgetStageProps> = (props: WidgetStageProps) => {
  return (
    <OptionRowWidget options={defaultOptions} iconHeight="17px" {...props} />
  );
};

const PathTextWidget: React.FC<WidgetStageProps> = (
  props: WidgetStageProps
) => {
  const { object } = props;

  const options = object.transformOptions?.type
    ? pathTextOptions
    : defaultOptions;

  if (object.inPathEditMode) return null;

  return <OptionRowWidget options={options} iconHeight="17px" {...props} />;
};

export const widgets: WidgetDictionary = {
  default: [
    {
      render: DefaultWidget,
      computePosition: defaultPositioner,
    },
  ],
  pathText: [
    {
      render: PathTextWidget,
      computePosition: defaultPositioner,
    },
  ],
  artboard: [],
};
