import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

export const PanelWrapper = styled.ul`
  height: 500px;
  overflow-y: auto;
  margin: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  scrollbar-color: ${themeStyle.varInkLight};

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px ${themeStyle.varInkLight};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${themeStyle.varInkMedium};
  }
  overflow-x: hidden;
`;

export const Loader = styled.div`
  width: 100%;
`;
