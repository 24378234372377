import React from 'react';
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import 'pepjs'; // polyfill for pointerevents. Needed for older safari versions (< 13).

import App from './App';
import './index.css';
import './assets/styles/fonts.css';
import * as serviceWorker from './serviceWorker';
import { testWebPSupport } from './utils/url';
import './global/analytics';
import './global/polyfills';
import monitoring from './services/monitoring';
import { createRoot } from 'react-dom/client';

monitoring.init();

Nprogress.configure({ showSpinner: false });

testWebPSupport();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
