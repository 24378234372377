import Api from '../../../global/api';
import UserApi from '../../../global/userApi';
import { uploadStore } from '../../../stores/uploadStore';
import { menuStore } from '../../../stores/menuStore';
import { CLIPBOARD_LOCAL_STORAGE_KEY } from '../../../global/constants';
import {
  ADD_TEXT,
  ADD_IMAGE,
  ADD_ILLUSTRATION,
  ADD_BASIC_SHAPE,
  ADD_SQUARE,
  ADD_CIRCLE,
  ADD_POLYGON,
  ADD_TEMPLATE,
  ADD_TEXTURE,
  ACTIVE_DELETE,
  ACTIVE_COPY,
  ACTIVE_PASTE,
  ACTIVE_CUT,
  ARTBOARD_IS_LOADING,
  EDIT_TEXT,
  DESIGN_SAVE,
  ADD_DESIGN,
  DELETE_UPLOAD,
  SELECT_ARTBOARD,
  ADD_TEXT_LAYOUT,
  HISTORY_RESET,
  ADD_PHOTO,
  ADD_MASK,
} from '../../../global/events';
import fabric from '../fabric';
import designsStore from '../../../stores/designsStore';
import {
  getElementById,
  structureCleanup,
  getCompatibleIndex,
} from '../../../utils/groupStructure';
import { commonFontsStore } from '../../../stores/commonFontsStore';
import { useDownloadPanelStore } from '../../../stores/downloadPanelStore';
import { useToastStore } from '../../../stores/toastStore';
import { copySelection, updateObjectIds } from '../../../utils/editor/objects';
import { findMaskTarget, tryMasking } from '../../../utils/masking';

const { addSingleFont, addFontsFromCanvas } = commonFontsStore.getState();

const deleteUpload = uploadStore.getState().deleteUpload;
const fireToast = useToastStore.getState().fire;

const defaults = {
  text: 'Headline',
  fontFamily: 'Roboto',
  fontWeight: 'Bold',
  lineHeight: 400,
  fontSize: 72,
};

const defaultOverlayOptions = {
  opacity: 100,
  mode: 'color-burn',
  hidden: false,
};

/**
 * This checks if the editor is already loading a design.
 * That way we ensure we don't trigger another iteration over elements with
 * each corresponding API request.
 */
let isDesignLoading = false;

export default function (event, canvas, dispatch) {
  /*
    To fix CU-ff6rj6 we need to add `crossOrigin: 'anonymous'` that mitigates the issue with tainted canvases
    see the following links for more info:
    https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
    https://stackoverflow.com/questions/22710627/tainted-canvases-may-not-be-exported
    https://stackoverflow.com/questions/31038027/why-does-adding-crossorigin-break-fabric-image-fromurl
  */
  let addOptions = { canvas, crossOrigin: 'anonymous' };

  const dropX = event.val?.dropX;
  const dropY = event.val?.dropY;
  if (dropX !== undefined && dropY !== undefined) {
    const vpt = canvas.viewportTransform;
    const point = new fabric.Point(dropX, dropY);
    const transformedPoint = fabric.util.transformPoint(
      point,
      fabric.util.invertTransform(vpt)
    );

    addOptions = {
      ...addOptions,
      wasDropped: true,
      left: transformedPoint.x,
      top: transformedPoint.y,
      distanceToTopCorner: event.val.distanceToTopCorner,
      imgWidth: event.val.imgWidth,
      imgHeight: event.val.imgHeight,
    };
  }

  const shapeOptions = {
    fill: '#C1C1C1',
    stroke: '#8E8E8E',
    strokeWidth: 1,
  };

  switch (event.key) {
    case ADD_TEXT:
      new fabric.PathText(
        event.val?.text || defaults.text,
        {
          ...defaults,
          ...event.val,
          // canvas is passed down, so we can center via viewport or artboard
          canvas,
        },
        (target) => {
          canvas.add(target);
          canvas.onAddRemoveObject(target);
          addSingleFont(target);
          // Text is in edit mode on creation
          dispatch(EDIT_TEXT, {
            target,
            selectAll: true,
            deselect: event.val?.deselect,
          });
        }
      );
      break;
    case ADD_IMAGE:
      fabric.loadSVGFromURL(event.val.src, (paths) => {
        const img = new fabric.Group(paths, addOptions);
        canvas.add(img);
        canvas.onAddRemoveObject(img);
        event.val.fireToast && event.val.fireToast();
      });
      break;
    case ADD_ILLUSTRATION:
    case ADD_PHOTO:
      const mask = findMaskTarget(event.val, canvas);
      if (event.val.objectName.endsWith('.svg')) {
        new fabric.Illustration(event.val.objectName, addOptions, (target) => {
          canvas.add(target);
          canvas.onAddRemoveObject(target);
          tryMasking(canvas, target, mask);
          event.val.fireToast && event.val.fireToast();
          event.val.callback && event.val.callback();
        });
      } else {
        new fabric.IllustrationImage(
          event.val.objectName,
          addOptions,
          (target) => {
            canvas.add(target);
            canvas.onAddRemoveObject(target);
            tryMasking(canvas, target, mask);
            event.val.fireToast && event.val.fireToast();
            event.val.callback && event.val.callback();
          }
        );
      }
      // don't track usage
      if (!event.val.skipPopularityTracking) {
        Api.registerElementUse(event.val.id);
      }
      break;
    case ADD_MASK:
      new fabric.Mask(event.val.objectName, addOptions, (target) => {
        canvas.add(target);
        canvas.onAddRemoveObject(target);
        event.val.fireToast && event.val.fireToast();
      });

      Api.registerElementUse(event.val.id);
      break;
    case ADD_BASIC_SHAPE: {
      const mask = findMaskTarget(event.val, canvas);
      new fabric.BasicShape(event.val.objectName, addOptions, (target) => {
        canvas.add(target);
        canvas.onAddRemoveObject(target);
        tryMasking(canvas, target, mask);
        event.val.fireToast && event.val.fireToast();
      });
      Api.registerElementUse(event.val.id);
      break;
    }
    case ADD_SQUARE:
      const rect = new fabric.Rect({ ...addOptions, ...shapeOptions });
      canvas.add(rect);
      canvas.onAddRemoveObject(rect);
      event.val.fireToast && event.val.fireToast();
      break;
    case ADD_CIRCLE:
      const circle = new fabric.Circle({ ...addOptions, ...shapeOptions });
      canvas.add(circle);
      canvas.onAddRemoveObject(circle);
      event.val.fireToast && event.val.fireToast();
      break;
    case ADD_POLYGON:
      const polygon = new fabric.EditablePolygon(event.val?.points, {
        ...addOptions,
        ...shapeOptions,
      });
      canvas.add(polygon);
      canvas.onAddRemoveObject(polygon);
      event.val.fireToast && event.val.fireToast();
      break;
    case ACTIVE_DELETE:
      const activeObjects = canvas
        .getActiveObjects()
        .filter((object) => object.type !== 'artboard');
      canvas.discardActiveObject();
      activeObjects.forEach((obj) => {
        if (obj.isClipPath || obj.clipPathMaskId) {
          canvas.forEachObject((baseObject) => {
            if (baseObject.clipPathMaskId === obj.id) {
              baseObject.removeClipPath();
            } else if (obj.clipPathMaskId === baseObject.id) {
              obj.removeClipPath();
            }
          });
        }
        canvas.remove(obj);
      });
      canvas.onAddRemoveObject();
      break;
    case ADD_TEMPLATE:
      useDownloadPanelStore.getState().resetOptions();
      const { id, state, preview, newProject, name } = event.val;
      state.templateId = id;
      dispatch(ARTBOARD_IS_LOADING, true);
      menuStore.getState().change();

      if (newProject) {
        canvas.resetConfig();
      }
      canvas.modifyConfig({ title: name });

      canvas.stagedLoadFromJSON(state, preview, () => {
        // it is needed to trigger a state update after a save, since otherwise
        // the save from state update could be ignored in a race condition with
        // ARTBOARD_IS_LOADING
        dispatch(DESIGN_SAVE, { type: 'auto' });
        dispatch(ARTBOARD_IS_LOADING, false);
        event.val.fireToast('Template Activated');
      });
      break;
    case ADD_TEXTURE:
      canvas.modifyOverlay({
        ...defaultOverlayOptions,
        ...canvas.overlayOptions,
        texture: event.val.objectName,
        hidden: false,
      });
      Api.registerElementUse(event.val.id);
      dispatch(SELECT_ARTBOARD);
      break;
    case ACTIVE_COPY:
      const selection = canvas.getActiveObjects();
      const structureSelection = canvas._selectedElements;
      if (!selection.length || !structureSelection?.length) break;

      canvas.discardActiveObject();
      copySelection(selection, structureSelection, canvas.groupStructure);
      canvas.selectIds(structureSelection);
      break;
    case ACTIVE_CUT:
      const cutSelection = canvas.getActiveObjects();
      const cutStructure = canvas._selectedElements;
      if (!cutSelection.length || !cutStructure?.length) break;

      canvas.discardActiveObject();
      copySelection(cutSelection, cutStructure, canvas.groupStructure, true);

      cutSelection.forEach((obj) => {
        if (obj.isClipPath || obj.clipPathMaskId) {
          canvas.forEachObject((baseObject) => {
            if (baseObject.clipPathMaskId === obj.id) {
              baseObject.removeClipPath();
            } else if (obj.clipPathMaskId === baseObject.id) {
              obj.removeClipPath();
            }
          });
        }
        canvas.remove(obj);
      });

      canvas.onAddRemoveObject();
      break;
    case ACTIVE_PASTE:
      const localStorageClipboard = JSON.parse(
        localStorage.getItem(CLIPBOARD_LOCAL_STORAGE_KEY)
      );
      if (localStorageClipboard?.objects.length) {
        const { objects, structuresToCopy } = localStorageClipboard;

        let { parentStructure } = localStorageClipboard;
        if (parentStructure.id) {
          // Parent structure has id, so it's not root
          // Parent structure could not exist anymore in the editor, we look for it
          const updatedParentStructure = getElementById(
            canvas.groupStructure,
            parentStructure.id
          );
          parentStructure = updatedParentStructure || canvas.groupStructure;
        }

        const index = getCompatibleIndex(
          parentStructure.children.map((s) => s.id),
          structuresToCopy.map((s) => s.id)
        );

        const { newObjects, newObjectIds } = updateObjectIds(objects);

        fabric.util.enlivenObjects(
          newObjects,
          () => {
            structureCleanup(
              canvas,
              structuresToCopy,
              newObjectIds,
              parentStructure.id || null,
              index,
              true
            );
            canvas.fire('object:modified');
          },
          'fabric',
          (_oldObj, obj) => {
            canvas.add(obj);
            obj.setCoords();
          }
        );

        fireToast({
          label: 'Object Pasted',
          small: true,
        });
      }
      break;
    case ADD_DESIGN:
      if (isDesignLoading) return;
      isDesignLoading = true;
      dispatch(DESIGN_SAVE, { type: 'onExit' });
      useDownloadPanelStore.getState().resetOptions();
      UserApi.getDesign(event.val.id).then((response) => {
        // Handle empty response if fetch is cancelled
        if (!response) {
          isDesignLoading = false;
          return;
        }

        // Handle errors on fetching
        if (response.error || !response.state) {
          if (response.error === 'NOT_FOUND') {
            designsStore.getState().deleteElement(event.val.id);
          }
          isDesignLoading = false;
          return;
        }

        const { state: designState, id: designId } = response;
        const designPreview = event.val.preview;
        designState.config.designId = designId;
        dispatch(ARTBOARD_IS_LOADING, true);
        canvas.stagedLoadFromJSON(designState, designPreview, () => {
          designsStore.getState().setActive(designId);
          menuStore.getState().change();
          dispatch(DESIGN_SAVE, { type: 'auto' });
          dispatch(ARTBOARD_IS_LOADING, false);
          dispatch(HISTORY_RESET, canvas.toJSON());
          designsStore.getState().forceActiveModified();
          isDesignLoading = false;
        });
      });
      break;
    case ADD_TEXT_LAYOUT:
      const { objects: textLayoutObjects, structure } = event.val.state;

      addFontsFromCanvas(textLayoutObjects);

      const dummyActiveSelection = new fabric.ActiveSelection([], { canvas });

      const { newObjects, newObjectIds } = updateObjectIds(textLayoutObjects);

      fabric.util.enlivenObjects(
        newObjects,
        (objects) => {
          objects.forEach((obj) => {
            dummyActiveSelection.addWithUpdate(obj);
          });
          dummyActiveSelection.positionOnArtboard({
            canvas,
            ...addOptions,
          });
          const pendingObjects = objects.map((obj) => {
            obj.group = dummyActiveSelection;
            dummyActiveSelection.realizeTransform(obj);
            delete obj.group;

            return new Promise((resolve) => {
              switch (obj.type) {
                case 'pathText':
                  obj.fire('scaled');
                  obj.updateText(() => {
                    resolve(obj);
                  });
                  break;
                case 'basicShape':
                  obj.fire('scaled');
                  resolve(obj);
                  break;
                default:
                  resolve(obj);
              }
            });
          });

          Promise.all(pendingObjects).then((updatedObjects) => {
            updatedObjects.forEach((obj) => canvas.add(obj));
            structureCleanup(canvas, structure, newObjectIds);
            canvas.requestRenderAll();
          });
        },
        'fabric'
      );

      break;
    case DELETE_UPLOAD:
      deleteUpload(event.val.id);
      canvas
        .getObjects()
        .forEach(
          (obj) =>
            obj.objectName &&
            obj.objectName === event.val.objectName &&
            canvas.remove(obj) &&
            canvas.onAddRemoveObject()
        );
      break;
    default:
      console.warn('Unknown Artboard Add/Remove event', event?.key, event?.val);
  }
}
