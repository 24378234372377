import { useState, useEffect, useCallback, FC, useRef } from 'react';

import ElementGallery from '../ElementGallery/ElementGallery';
import { RecommendedProps } from './types';
import api from '../../global/api';
import { Template } from '../../types';

const Recommended: FC<RecommendedProps> = (props) => {
  const { category, render } = props;
  const fetched = useRef<boolean>(false);
  const [elements, setElements] = useState<Template[]>([]);

  const fetch = useCallback(async () => {
    if (fetched.current) {
      return;
    }

    const recommended = await api.getRecommendedGroupTemplates(category);
    setElements(recommended.templates);
    fetched.current = true;
  }, [category]);

  /* If no elements, fetch */
  useEffect(() => {
    if (!elements.length) {
      void fetch();
    }
  }, [fetch, elements.length]);

  return (
    <ElementGallery
      collapsed={false}
      elements={elements}
      render={render}
      fetch={fetch}
      labelLeft="Recommended"
      total={elements.length}
      dimensions={props.elementGalleryDimensions}
    />
  );
};

export default Recommended;
