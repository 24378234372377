import {
  getDistance,
  getInterpolatedPoint,
} from '../../../../../../utils/geometry/point';
import {
  getInterpolatedAngle,
  getMovedPointAlongDirection,
} from '../../../../../../utils/geometry/vector';
import {
  closePath,
  cubicCurveTo,
  lineTo,
  moveTo,
} from '../../../../../../utils/path/commands';

/**
 * Get the interpolated curve at a given ratio between two curves, defined by 10 points when a pathText has distort transform.
 * Used to generate horizontal-line decoration curved for text with distort transform.
 * @param {number} ratio, 0<=ratio<=1, where the interpolation happens from the first curve to the second curve.
 * @param {{ x: number, y: number }[]} points
 * @returns {{rightHandle: {x: number, y: number}, leftHandle: {x: number, y: number}, left: {x: number, y: number}, center: {x: number, y: number}, right: {x: number, y: number}}}, points that define the curve
 */
export const getInterpolatedCurve = (ratio, points) => {
  const topLeft = points[0];
  const topCenter = points[1];
  const topRight = points[2];
  const bottomRight = points[3];
  const bottomCenter = points[4];
  const bottomLeft = points[5];
  const topLeftHandle = points[6];
  const topRightHandle = points[7];
  const bottomLeftHandle = points[8];
  const bottomRightHandle = points[9];

  const topCenterToLeftHandleLength = getDistance(topLeftHandle, topCenter);
  const topCenterToRightHandleLength = getDistance(topRightHandle, topCenter);
  const bottomCenterToLeftHandleLength = getDistance(
    bottomLeftHandle,
    bottomCenter
  );
  const bottomCenterToRightHandleLength = getDistance(
    bottomRightHandle,
    bottomCenter
  );

  const left = getInterpolatedPoint(topLeft, bottomLeft, ratio);
  const right = getInterpolatedPoint(topRight, bottomRight, ratio);
  const center = getInterpolatedPoint(topCenter, bottomCenter, ratio);
  const centerLineAngle = getInterpolatedAngle(
    topLeftHandle,
    topRightHandle,
    bottomLeftHandle,
    bottomRightHandle,
    ratio
  );
  const centerToLeftHandleLength =
    topCenterToLeftHandleLength +
    ratio * (bottomCenterToLeftHandleLength - topCenterToLeftHandleLength);
  const leftHandle = getMovedPointAlongDirection(
    center,
    centerLineAngle,
    centerToLeftHandleLength,
    false
  );
  const centerToRightHandleLength =
    topCenterToRightHandleLength +
    ratio * (bottomCenterToRightHandleLength - topCenterToRightHandleLength);
  const rightHandle = getMovedPointAlongDirection(
    center,
    centerLineAngle,
    centerToRightHandleLength,
    true
  );

  return {
    left,
    right,
    center,
    leftHandle,
    rightHandle,
  };
};

/**
 * creates the path markup for a parallelogram
 * this is constructed based on the two bottom points, the of height the parallelogram
 * and an offset on the y axis
 */
export const getParallelogramPath = (
  bottomLeft,
  bottomRight,
  height,
  offset = 0
) => {
  // start, right, up, left, close
  const path = `${moveTo({ x: bottomLeft.x, y: bottomLeft.y + offset })}
    ${lineTo({ x: bottomRight.x, y: bottomRight.y + offset })}
    ${lineTo({ x: bottomRight.x, y: bottomRight.y + offset + height })}
    ${lineTo({ x: bottomLeft.x, y: bottomLeft.y + offset + height })}
    ${closePath()}
  `;
  return path;
};

/**
 * creates the path markup between two curves
 */
export const getPathBetweenCurves = (topCurve, bottomCurve) => {
  const path = `${moveTo(topCurve.left)}
    ${cubicCurveTo(topCurve.left, topCurve.leftHandle, topCurve.center)}
    ${cubicCurveTo(topCurve.rightHandle, topCurve.right, topCurve.right)}
    ${lineTo(bottomCurve.right)}
    ${cubicCurveTo(
      bottomCurve.right,
      bottomCurve.rightHandle,
      bottomCurve.center
    )}
    ${cubicCurveTo(bottomCurve.leftHandle, bottomCurve.left, bottomCurve.left)}
    ${closePath()}`;
  return path;
};
