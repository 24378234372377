import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { IconEventStyle } from '../Icon/styles';
import { P4 } from '../utilities/Typography/styles';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
  background-color: ${themeStyle.varBackgroundAlt};
  border-radius: 5px;
  box-shadow: ${themeStyle.shadowModal};
  padding: 20px 20px 24px 20px;
`;

export const PresetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const SizeElementContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  height: 400px;
  margin-top: 3px;
  margin-left: 3px;
  @media (max-width: 888px) {
    ${IconEventStyle} {
      flex: 1 0 45%;
    }
  }
`;

export const SizeElement = styled.div`
  cursor: pointer;
  width: 190px;
  height: 190px;
  background-color: ${themeStyle.varInkSuperLight};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    background-color: ${themeStyle.varBackgroundAlt};
    box-shadow: ${themeStyle.varShadowSmallFocus};
  }
  &.active {
    background-color: ${themeStyle.varBackgroundAlt};
    box-shadow: ${themeStyle.varShadowSmallFocus};
  }
  @media (max-width: 888px) {
    flex-grow: 1;
    width: initial;
  }
`;

export const CustomSizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  min-width: 196px;
`;

export const CustomInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 90px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: auto;
`;

export const CustomInputLabel = styled(P4)`
  color: ${themeStyle.varInkMedium};
  height: 18px;
`;
