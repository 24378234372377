import { fetchRetry } from '../utils/fetchRetry';
import { getAccessToken, USER_API_URL } from './userApi';
import { Design, Folder } from '../types';

type FolderResponse =
  | { success: true; folder: Folder }
  | { success?: false; error?: string };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createAPI = ({ url }: { url: string }) => {
  const getFolder = async ({ id }: { id: string }): Promise<FolderResponse> => {
    const resp = await fetchRetry(`${url}/folders/${id}`, {
      method: 'Get',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return await resp.json();
  };

  const createFolder = async ({
    name = 'New Folder',
  } = {}): Promise<FolderResponse> => {
    const resp = await fetchRetry(`${url}/folders/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name }),
    });
    return await resp.json();
  };

  const updateFolder = async ({
    id,
    name,
  }: {
    id: string;
    name: string;
  }): Promise<FolderResponse> => {
    const resp = await fetchRetry(`${url}/folders/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, name }),
    });
    return await resp.json();
  };

  const deleteFolder = async ({
    id,
  }: {
    id: string;
  }): Promise<{ error?: string; success?: boolean }> => {
    const resp = await fetchRetry(`${url}/folders/delete`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
    return await resp.json();
  };

  const moveToFolder = async (
    folderId: string,
    designIds: string[]
  ): Promise<{ success: boolean; designs?: Design[] }> => {
    const resp = await fetchRetry(`${url}/folders/${folderId}/move_to`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ designIds }),
    });
    return await resp.json();
  };

  const removeFromFolder = async (
    folderId: string,
    designId: string
  ): Promise<{ success: boolean; design?: Design }> => {
    const resp = await fetchRetry(`${url}/folders/${folderId}/remove`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ designId }),
    });
    return await resp.json();
  };

  const getFolders = async (
    take = 10,
    skip = 0
  ): Promise<
    | {
        success: boolean;
        results: Folder[];
        total: number;
        skip: number;
        take: number;
      }
    | { error: string }
  > => {
    try {
      const resp = await fetchRetry(
        `${url}/folders/index?take=${take}&skip=${skip}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return await resp.json();
    } catch (e) {
      console.warn('Could not load user folders');
      return { error: 'Connection failed' };
    }
  };

  return {
    getFolder,
    createFolder,
    updateFolder,
    deleteFolder,
    moveToFolder,
    removeFromFolder,
    getFolders,
  };
};

export default createAPI({ url: USER_API_URL });
