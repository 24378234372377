import React from 'react';
import PropTypes from 'prop-types';

import monitoring from '../../services/monitoring';

class ErrorBoundary extends React.PureComponent {
  componentDidCatch(error, errorInfo) {
    monitoring.captureException(error, errorInfo);
    this.props.onError(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  onError: PropTypes.func,
  children: PropTypes.node,
};

export default React.memo(ErrorBoundary);
