import { useEffect, useState } from 'react';
import createTrie from 'autosuggest-trie';

// autosuggest-trie types are taken from https://www.npmjs.com/package/@types/autosuggest-trie
interface MatchOptions {
  /**
   * Integer >= 1
   * For example: getMatches('me', { limit: 3 }) will return no more than 3 items that match 'me'.
   * @default Infinity
   */
  limit?: number;
  /**
   * Used to split the query into words.
   * @default /\s+/
   */
  splitRegex?: RegExp;
}

interface Trie<T> {
  /**
   * Returns items that match the given query.
   * @param query Non-blank query string. If query is blank, [] is returned..
   * @param options Additional query options.
   */
  getMatches(query: string, options?: MatchOptions): T[];
}

type TagTrie = Trie<{ tag: string }>;

// build a trie structure based on the given tags
const buildTrie = (tags: string[]): TagTrie => {
  const uniqueTags = Array.from(
    new Set(
      tags.reduce((acc, tag) => {
        if (tag) {
          acc.push(tag.toLowerCase());
        }
        return acc;
      }, [] as string[])
    ) // use unique lower case tags
  );
  return createTrie(
    uniqueTags.map((tag) => ({
      tag,
    })),
    'tag'
  );
};

const useTrie = (tags: string[]): TagTrie => {
  // store trie of the given tags, for a faster match generation
  const [tagTrie, setTagTrie] = useState(buildTrie(tags));
  useEffect(() => {
    setTagTrie(buildTrie(tags));
  }, [tags]);

  return tagTrie;
};

export default useTrie;
