import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { themeCursorInfoBox } from './theme';
import { CursorInfoBoxWrapper, MenuContainer } from './styles';
import Context from '../../components/context';

const CursorInfoBox = (props) => {
  const context = useContext(Context);

  const theme = { ...themeCursorInfoBox, ...props.theme };

  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [position, setPosition] = useState({
    left: 0,
    top: 0,
    isInPosition: false,
  });
  const [targetPosition, setTargetPosition] = useState(props.targetPosition);

  const menuRef = useRef();

  useEffect(() => {
    setIsOpen(props.isOpen);
    setTargetPosition(props.targetPosition);
  }, [props]);

  useLayoutEffect(() => {
    // Update position on re-render
    updatePosition();
  });

  const updatePosition = () => {
    if (!isOpen) {
      return false;
    }
    if (targetPosition && menuRef.current && context.getRootContainer()) {
      const popoverRect = menuRef.current.getBoundingClientRect();
      const containerRect = context.getRootContainer().getBoundingClientRect();

      const [left, top] = calculatePosition(
        targetPosition,
        popoverRect,
        containerRect
      );

      if (
        !position.isInPosition ||
        left !== position.left ||
        top !== position.top
      ) {
        setPosition({
          left: left,
          top: top,
          isInPosition: true,
        });
      }
    }
  };

  const calculatePosition = (targetPos, popoverRect, containInRect) => {
    const padding = 5;

    let left = targetPos.left;
    let top = targetPos.top;

    // Keep menu within window
    const maxLeft =
      containInRect.left + containInRect.width - padding - popoverRect.width;
    left = Math.min(left, maxLeft);
    left = Math.max(padding, left);

    const maxTop =
      containInRect.top + containInRect.height - padding - popoverRect.height;
    top = Math.min(top, maxTop);
    top = Math.max(padding, top);

    return [left.toFixed(), top.toFixed()];
  };

  const menu = (
    <MenuContainer>
      <CursorInfoBoxWrapper
        ref={menuRef}
        theme={theme}
        isInPosition={position.isInPosition}
        style={{
          left: position.left + 'px',
          top: position.top + 'px',
        }}
      >
        {props.children}
      </CursorInfoBoxWrapper>
    </MenuContainer>
  );

  const renderMenu = () => {
    if (isOpen) {
      return createPortal(menu, context.getRootContainer());
    }
    return null;
  };

  return <>{renderMenu()}</>;
};

CursorInfoBox.propTypes = {
  /**
   * CursorInfoBox styles theme
   */
  theme: PropTypes.shape({
    backgroundColor: PropTypes.string,
    boxShadow: PropTypes.string,
    borderRadius: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  /**
   * Whether the box is open
   */
  isOpen: PropTypes.bool,
  /**
   * Where the box should be placed
   */
  targetPosition: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  children: PropTypes.node,
};

CursorInfoBox.defaultProps = {
  theme: themeCursorInfoBox,
};

export default CursorInfoBox;
