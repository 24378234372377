import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  to { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpinnerStyle = styled.div`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: 2px solid ${({ theme }) => theme.color};
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.trackColor};
  animation: ${rotate} 1s ease-in-out infinite;
`;
