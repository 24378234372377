import styled from 'styled-components';
import { themeStyle } from '../../services/theming';

export const InnerWrapper = styled.div`
  padding: 48px 24px;
  display: flex;
  gap: 24px;
`;

export const CopyContainer = styled.div`
  padding: 10px;
  padding-left: 16px;

  display: flex;
  align-items: center;
  gap: 14px;

  border: 1px solid ${themeStyle.varInkLight};
  border-radius: 5px;
  cursor: pointer;

  button {
    flex-shrink: 0;
  }
`;

export const AttributionFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  padding: 32px 0;

  background: ${themeStyle.backgroundGradientFull};
`;
