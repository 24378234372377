import { fabric } from 'fabric';

import { extendWarpControlRootPoint } from './WarpControlRootPoint';
import { extendWarpControlHandlePoint } from './WarpControlHandlePoint';

const extend = (fabric: fabric): void => {
  extendWarpControlRootPoint(fabric);
  extendWarpControlHandlePoint(fabric);
};

export default extend;
