import React from 'react';

import { SpacerElement } from './styles';
import { SpacerProps } from './types';

const Spacer = React.forwardRef<HTMLSpanElement, SpacerProps>(
  ({ w, h, id }, ref) => {
    return <SpacerElement w={w} h={h} ref={ref} id={id} />;
  }
);

export default Spacer;
