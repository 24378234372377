import fabric from '../../Artboard/fabric';
import { EventBusData } from '../../../types';
import {
  DownloadEvent,
  handleCommonEventBusEvents,
} from '../../../utils/mockup/handleCommonEventBusEvents';
import { isDebugActive } from '../../../utils/dev';
import {
  ADD_MOCKUP_TEMPLATE,
  MOCKUP_SAVE_TO_UPLOADS,
  SET_STATE,
} from '../../../global/events';
import { findDesignLayer } from './index';
import { handleSaveToUpload } from '../../../utils/mockup/eventHandlers';

const handleSetState = (
  canvas: fabric.Canvas,
  {
    isHistoryChange,
    state: newState,
  }: {
    isHistoryChange: boolean;
    state: JSON;
  }
): void => {
  canvas.loadFromJSON(newState, () => {
    // re-select selected elements
    const selectedStructureIds = canvas._selectedElements;
    if (isHistoryChange && selectedStructureIds?.length) {
      canvas.selectIds(selectedStructureIds);
    }

    if (isHistoryChange) {
      // disable rotate control on design layer
      const designLayer = findDesignLayer(canvas);
      designLayer.setControlsVisibility({
        rotate: false,
      });
    }

    canvas.fire('object:modified');
  });
};

export const handleEventBusEvents = async (
  canvas: fabric.Canvas,
  data: EventBusData
): Promise<void> => {
  const eventHandled = await handleCommonEventBusEvents(canvas, data);

  if (!eventHandled) {
    const { key, val } = data;
    switch (key) {
      case SET_STATE: {
        handleSetState(
          canvas,
          val as {
            isHistoryChange: boolean;
            state: JSON;
          }
        );
        break;
      }
      case ADD_MOCKUP_TEMPLATE:
        // handled in MockupBoard
        break;
      case MOCKUP_SAVE_TO_UPLOADS:
        await handleSaveToUpload(canvas, val as DownloadEvent);
        break;
      default:
        if (isDebugActive()) {
          console.warn('Unhandled event', data);
        }
    }
  }

  canvas.requestRenderAll();
};
