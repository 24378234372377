import styled from 'styled-components';
import AuthenticationButtons from './components/Header/AuthenticationButtons/AuthenticationButtons';
import Button from './components/Button/Button/Button';
import Authentication from './components/Authentication/Authentication';
import { H3 } from './components/utilities/Typography/styles';

const UnauthView = () => {
  const handleGoToWebsite = () => {
    window.location = `${process.env.REACT_APP_WEBSITE_URL}`;
  };

  return (
    <>
      <Wrapper>
        <AuthenticationButtons showSignInInitially />
        <Authentication />
      </Wrapper>
      <UnauthMessage>
        <H3>You need an account to use the editor.</H3>
        <Button
          onClick={handleGoToWebsite}
          label="Go back to website"
          height="42px"
          width="180px"
        />
      </UnauthMessage>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 22px;
`;

const UnauthMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30vh;
`;

export default UnauthView;
