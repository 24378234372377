import styled from 'styled-components';

import { themeStyle } from '../../services/theming';
import { P2 } from '../utilities/Typography/styles';

export const ContentContainer = styled.div`
  box-sizing: border-box;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${P2} {
    a {
      color: ${themeStyle.varBrandMain};
    }
  }
`;
