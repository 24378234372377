import React from 'react';

import {
  notificationStoreSelector,
  useNotificationStore,
} from '../../../stores/notificationStore';
import IconButton from '../../Button/IconButton';
import Popover from '../../Popover/Popover';
import { TooltipAlignment, TooltipPlacement } from '../../Tooltip/types';
import Panel from './Panel';
import { Wrapper } from './styles';

const Notifications: React.FC = () => {
  const showUnseenBadge = useNotificationStore(
    notificationStoreSelector.showUnseenBadge
  );

  return (
    /* eslint-disable-next-line */
    //@ts-ignore
    <Popover
      label={'Notifications'}
      align={'end'}
      width={'288px'}
      content={<Panel />}
      theme={{
        padding: '0px',
        headPadding: '12px',
      }}
      margin="10px 0 0 0"
    >
      <Wrapper>
        <IconButton
          iconName={showUnseenBadge ? 'bellClipped' : 'bell'}
          height="17px"
          ariaLabel="Notifications"
          tooltipText="Notifications"
          tooltipPlacement={TooltipPlacement.Bottom}
          tooltipAlignment={TooltipAlignment.Center}
        />
      </Wrapper>
    </Popover>
  );
};

export default React.memo(Notifications);
