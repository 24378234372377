import { isAltKeyRotation } from '../../../utils/editor/misc';

import {
  getElementById,
  getAllChildObjects,
} from '../../../utils/groupStructure';

export default function (fabric) {
  fabric.ActiveSelection.prototype.initialize = (function (initialize) {
    return function (objects, options) {
      options || (options = {});
      options.subTargetCheck = true; // adds an subTargets array to click events
      options.lockScalingFlip = true; // disabling negative scaling of a selection

      initialize.call(this, objects, options);

      this.lastAngle = this.angle;

      // functionality to select objects within groups
      this.on('mousedblclick', (e) => {
        if (this.canvas._isSelectionKeyPressed(e.e)) return;
        if (e.subTargets.length && this.canvas) {
          this.canvas.setActiveObject(e.subTargets[0], {
            isSubTargetSelect: true,
          });
          this.canvas.requestRenderAll();
        }
      });

      this.on('moved', () => {
        // handle move of individual objects
        this._objects.forEach((obj) => {
          obj.handleOnMove();
        });
      });

      // pass on handleRotation to children (needed in pathText)
      this.on('rotated', (e) => {
        const isAltKey = isAltKeyRotation(e);
        this._objects.forEach((obj) => {
          obj.handleRotation(this, isAltKey);
        });
        this.lastAngle = this.angle;
      });

      return this;
    };
  })(fabric.ActiveSelection.prototype.initialize);

  /**
   * align child objects inside an activeSelection.
   * This function is similar to Object.align, but since this is a group, the origin to align to
   * is not at {0,0} for the artboard, but at {-width/2, -height/2}.
   * @param {String} value to indicate the alignment
   */
  fabric.ActiveSelection.prototype.align = function (value) {
    const selectedElements = this.canvas.getSelectedElements();

    // if only one group is selected, handle it as a single object
    if (selectedElements.length === 1) {
      this.callSuper('align', value);
      this._objects.forEach((object) => object.handleOnMove());
      return;
    }

    const structureElements = [];
    selectedElements.forEach((id) => {
      const element = getElementById(this.canvas.groupStructure, id);
      if (element) structureElements.push(element);
    });
    structureElements.forEach((structure) => {
      const objectIds = getAllChildObjects(structure);
      const objects = this._objects.filter((obj) => objectIds.includes(obj.id));

      // get bounds of all objects, to get a boundingRect of the whole group
      const bounds = objects.map((obj) => {
        obj.setCoords(); // make sure objects coords are correct
        const { top, left, width, height } = obj.getBoundingRect(true);
        return {
          top,
          left,
          right: left + width,
          bottom: top + height,
        };
      });
      const left = Math.min(...bounds.map(({ left }) => left));
      const right = Math.max(...bounds.map(({ right }) => right));
      const top = Math.min(...bounds.map(({ top }) => top));
      const bottom = Math.max(...bounds.map(({ bottom }) => bottom));

      const groupRect = {
        top,
        left,
        width: right - left,
        height: bottom - top,
      };

      // position objects relative to align and group
      objects.forEach((object) => {
        const rect = object.getBoundingRect(true);
        const leftGroupOffset = groupRect.left - rect.left;
        const topGroupOffset = groupRect.top - rect.top;
        // calculate offset between relative and absolute top/left. That's needed
        // for rotated objects. And combine it with group offset
        const topOffset = object.top - rect.top - topGroupOffset;
        const leftOffset = object.left - rect.left - leftGroupOffset;

        if (value === 'left') {
          object.left = -this.width / 2 + leftOffset;
        } else if (value === 'center') {
          object.left = leftOffset - groupRect.width / 2;
        } else if (value === 'right') {
          object.left = leftOffset + this.width / 2 - groupRect.width;
        } else if (value === 'top') {
          object.top = -this.height / 2 + topOffset;
        } else if (value === 'middle') {
          object.top = topOffset - groupRect.height / 2;
        } else if (value === 'bottom') {
          object.top = topOffset + this.height / 2 - groupRect.height;
        }
        object.handleOnMove();
      });
    });

    this.addWithUpdate();
  };
}
