export enum ElementCategory {
  illustrations = 'illustrations',
  ornaments = 'ornaments',
  catchWords = 'catchWords',
  shapes = 'shapes',
  abstract = 'abstract',
  upload = 'upload',
  overlay = 'overlay',
  background = 'background',
  pattern = 'pattern',
}
