/**
 * This file contains a list with all icons used for the Icon component
 * To add a new icon:
 *   1. add the svg file to the project
 *   2. add css classes to the svg elements, that indicate what needs to colored how and when
 *   3. adjust height (and viewport), so that the height is 21 (for a default sized icon)
 *   4. import the file here and export the component reference (copy and replace the commented lines for a new icon)
 *   5. add the chosen name to the name props of the Icon component
 */

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as KeyboardIcon } from '../../assets/icons/keyboard.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as TemplateIcon } from '../../assets/icons/template.svg';
import { ReactComponent as TextIcon } from '../../assets/icons/text.svg';
import { ReactComponent as ElementsIcon } from '../../assets/icons/elements.svg';
import { ReactComponent as DrawIcon } from '../../assets/icons/draw.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as TextureIcon } from '../../assets/icons/texture.svg';
import { ReactComponent as MoreIcon } from '../../assets/icons/more.svg';
import { ReactComponent as LayerIcon } from '../../assets/icons/layer.svg';
import { ReactComponent as StyleIcon } from '../../assets/icons/style.svg';
import { ReactComponent as EffectIcon } from '../../assets/icons/effect.svg';
import { ReactComponent as ColorPickerIcon } from '../../assets/icons/colorPicker.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrowUp.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as Like } from '../../assets/icons/like.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevronRight.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevronLeft.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as DropShadowIcon } from '../../assets/icons/dropShadow.svg';
import { ReactComponent as LineShadowIcon } from '../../assets/icons/lineShadow.svg';
import { ReactComponent as BlockShadowIcon } from '../../assets/icons/blockShadow.svg';
import { ReactComponent as DetailShadowIcon } from '../../assets/icons/detailShadow.svg';
import { ReactComponent as HorizontalLinesIcon } from '../../assets/icons/horizontalLines.svg';
import { ReactComponent as ColorCutIcon } from '../../assets/icons/colorCut.svg';
import { ReactComponent as FadingColorCutIcon } from '../../assets/icons/fadingColorCut.svg';
import { ReactComponent as ObliqueLinesIcon } from '../../assets/icons/obliqueLines.svg';
import { ReactComponent as AngleTransformIcon } from '../../assets/icons/angleTransform.svg';
import { ReactComponent as ArchTransformIcon } from '../../assets/icons/archTransform.svg';
import { ReactComponent as CircleTransformIcon } from '../../assets/icons/circleTransform.svg';
import { ReactComponent as FlagTransformIcon } from '../../assets/icons/flagTransform.svg';
import { ReactComponent as FreeFormTransformIcon } from '../../assets/icons/freeFormTransform.svg';
import { ReactComponent as CustomTransformIcon } from '../../assets/icons/customTransform.svg';
import { ReactComponent as RiseTransformIcon } from '../../assets/icons/riseTransform.svg';
import { ReactComponent as WaveTransformIcon } from '../../assets/icons/waveTransform.svg';
import { ReactComponent as AlignLeftIcon } from '../../assets/icons/alignLeft.svg';
import { ReactComponent as AlignCenterIcon } from '../../assets/icons/alignCenter.svg';
import { ReactComponent as AlignRightIcon } from '../../assets/icons/alignRight.svg';
import { ReactComponent as AlignJustifyIcon } from '../../assets/icons/alignJustify.svg';
import { ReactComponent as UppercaseIcon } from '../../assets/icons/uppercase.svg';
import { ReactComponent as UnderlineIcon } from '../../assets/icons/underline.svg';
import { ReactComponent as ForwardIcon } from '../../assets/icons/forward.svg';
import { ReactComponent as BackwardIcon } from '../../assets/icons/backward.svg';
import { ReactComponent as ToFrontIcon } from '../../assets/icons/toFront.svg';
import { ReactComponent as ToBackIcon } from '../../assets/icons/toBack.svg';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as CenterIcon } from '../../assets/icons/center.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right.svg';
import { ReactComponent as TopIcon } from '../../assets/icons/top.svg';
import { ReactComponent as MiddleIcon } from '../../assets/icons/middle.svg';
import { ReactComponent as BottomIcon } from '../../assets/icons/bottom.svg';
import { ReactComponent as GlyphIcon } from '../../assets/icons/glyph.svg';
import { ReactComponent as BearIcon } from '../../assets/icons/bear.svg';
import { ReactComponent as OrnamentIcon } from '../../assets/icons/ornament.svg';
import { ReactComponent as TheIcon } from '../../assets/icons/the.svg';
import { ReactComponent as ShapesIcon } from '../../assets/icons/shapes.svg';
import { ReactComponent as OverlayIcon } from '../../assets/icons/overlay.svg';
import { ReactComponent as UnderlayIcon } from '../../assets/icons/underlay.svg';
import { ReactComponent as PatternIcon } from '../../assets/icons/pattern.svg';
import { ReactComponent as A4A5 } from '../../assets/icons/a4_a5.svg';
import { ReactComponent as BusinessCard } from '../../assets/icons/businessCard.svg';
import { ReactComponent as CustomSize } from '../../assets/icons/customSize.svg';
import { ReactComponent as DefaultArtboard } from '../../assets/icons/defaultArtboard.svg';
import { ReactComponent as FacebookPost } from '../../assets/icons/facebookPost.svg';
import { ReactComponent as InstagramPost } from '../../assets/icons/instagramPost.svg';
import { ReactComponent as Landscape } from '../../assets/icons/landscape.svg';
import { ReactComponent as Portrait } from '../../assets/icons/portrait.svg';
import { ReactComponent as Postcard } from '../../assets/icons/postcard.svg';
import { ReactComponent as Poster } from '../../assets/icons/poster.svg';
import { ReactComponent as GroupIcon } from '../../assets/icons/group.svg';
import { ReactComponent as BackgroundIcon } from '../../assets/icons/background.svg';
import { ReactComponent as TIcon } from '../../assets/icons/t.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeSlashIcon } from '../../assets/icons/eyeSlash.svg';
import { ReactComponent as EyeOutlineIcon } from '../../assets/icons/eyeOutline.svg';
import { ReactComponent as EyeOutlineSlashIcon } from '../../assets/icons/eyeOutlineSlash.svg';
import { ReactComponent as UnlockIcon } from '../../assets/icons/unlock.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as LockDetailed } from '../../assets/icons/lockDetailed.svg';
import { ReactComponent as FlipVerticalIcon } from '../../assets/icons/flipVertical.svg';
import { ReactComponent as FlipHorizontalIcon } from '../../assets/icons/flipHorizontal.svg';
import { ReactComponent as ClippingMaskIcon } from '../../assets/icons/clippingMask.svg';
import { ReactComponent as IllustrationIcon } from '../../assets/icons/illustration.svg';
import { ReactComponent as UndoIcon } from '../../assets/icons/undo.svg';
import { ReactComponent as RedoIcon } from '../../assets/icons/redo.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as PasteIcon } from '../../assets/icons/paste.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as AssetsIcon } from '../../assets/icons/assets.svg';
import { ReactComponent as AbstractIcon } from '../../assets/icons/abstract.svg';
import { ReactComponent as LigatureIcon } from '../../assets/icons/ligature.svg';
import { ReactComponent as Rects } from '../../assets/icons/rects.svg';
import { ReactComponent as Tag } from '../../assets/icons/tag.svg';
import { ReactComponent as Smiley } from '../../assets/icons/smiley.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import { ReactComponent as Free } from '../../assets/icons/free.svg';
import { ReactComponent as Pro } from '../../assets/icons/pro.svg';
import { ReactComponent as Bell } from '../../assets/icons/bell.svg';
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as Google } from '../../assets/icons/google.svg';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as Create } from '../../assets/icons/create.svg';
import { ReactComponent as ElementDelete } from '../../assets/icons/elementDelete.svg';
import { ReactComponent as Bookmark } from '../../assets/icons/bookmark.svg';
import { ReactComponent as Crown } from '../../assets/icons/crown.svg';
import { ReactComponent as ElementBookmark } from '../../assets/icons/elementBookmark.svg';
import { ReactComponent as Gift } from '../../assets/icons/gift.svg';
import { ReactComponent as Checkbox } from '../../assets/icons/checkbox.svg';
import { ReactComponent as LockedValues } from '../../assets/icons/lockedValues.svg';
import { ReactComponent as Progress } from '../../assets/icons/progress.svg';
import { ReactComponent as Envelope } from '../../assets/icons/envelope.svg';
import { ReactComponent as Dialog } from '../../assets/icons/dialog.svg';
import { ReactComponent as PricingCheck } from '../../assets/icons/pricingCheck.svg';
import { ReactComponent as LetterSpacing } from '../../assets/icons/letterSpacing.svg';
import { ReactComponent as LineHeight } from '../../assets/icons/lineHeight.svg';
import { ReactComponent as MapMarker } from '../../assets/icons/mapMarker.svg';
import { ReactComponent as Filter } from '../../assets/icons/filter.svg';
import { ReactComponent as FilledBookmark } from '../../assets/icons/filledBookmark.svg';
import { ReactComponent as Pinterest } from '../../assets/icons/pinterest.svg';
import { ReactComponent as BellClipped } from '../../assets/icons/bellClipped.svg';
import { ReactComponent as WiFi } from '../../assets/icons/wifi.svg';
import { ReactComponent as Exclamation } from '../../assets/icons/exclamation.svg';
import { ReactComponent as TemplateBookmark } from '../../assets/icons/templateBookmark.svg';
import { ReactComponent as Link } from '../../assets/icons/link.svg';
import { ReactComponent as Camera } from '../../assets/icons/camera.svg';
import { ReactComponent as Website } from '../../assets/icons/website.svg';
import { ReactComponent as MapMarker2 } from '../../assets/icons/mapMarker_2.svg';
import { ReactComponent as PremiumBadge } from '../../assets/icons/premiumBadge.svg';
import { ReactComponent as PremiumBadgeOutlined } from '../../assets/icons/premiumBadgeOutlined.svg';
import { ReactComponent as FacebookCircle } from '../../assets/icons/facebookCircle.svg';
import { ReactComponent as YoutubeCircle } from '../../assets/icons/youtubeCircle.svg';
import { ReactComponent as InstagramCircle } from '../../assets/icons/instagramCircle.svg';
import { ReactComponent as TwitterCircle } from '../../assets/icons/twitterCircle.svg';
import { ReactComponent as Linkedin } from '../../assets/icons/linkedin.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as Comments } from '../../assets/icons/comments.svg';
import { ReactComponent as Clicks } from '../../assets/icons/clicks.svg';
import { ReactComponent as Likes } from '../../assets/icons/likes.svg';
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import { ReactComponent as DesignElementSettings } from '../../assets/icons/designElementSettings.svg';
import { ReactComponent as Lightning } from '../../assets/icons/lightning.svg';
import { ReactComponent as EyeLashes } from '../../assets/icons/eyelashes.svg';
import { ReactComponent as Whatsapp } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as ElementEdit } from '../../assets/icons/elementEdit.svg';
import { ReactComponent as Masonry } from '../../assets/icons/masonry.svg';
import { ReactComponent as RowLayout } from '../../assets/icons/rowLayout.svg';
import { ReactComponent as LinkShare } from '../../assets/icons/linkShare.svg';
import { ReactComponent as FilledHeart } from '../../assets/icons/filledHeart.svg';
import { ReactComponent as Heart } from '../../assets/icons/heart.svg';
import { ReactComponent as NotificationTick } from '../../assets/icons/notificationTick.svg';
import { ReactComponent as NotificationCross } from '../../assets/icons/notificationCross.svg';
import { ReactComponent as AddFriend } from '../../assets/icons/addFriend.svg';
import { ReactComponent as Upgrade } from '../../assets/icons/upgrade.svg';
import { ReactComponent as PaperClip } from '../../assets/icons/paperClip.svg';
import { ReactComponent as InfoThick } from '../../assets/icons/infoThick.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as ColorPalette } from '../../assets/icons/colorPalette.svg';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { ReactComponent as ThumbsUp } from '../../assets/icons/thumbsUp.svg';
import { ReactComponent as Ticket } from '../../assets/icons/ticket.svg';
import { ReactComponent as Trophy } from '../../assets/icons/trophy.svg';
import { ReactComponent as PlusThin } from '../../assets/icons/plusThin.svg';
import { ReactComponent as MinusThin } from '../../assets/icons/minusThin.svg';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import { ReactComponent as Loading } from '../../assets/icons/loading.svg';
import { ReactComponent as Youtube } from '../../assets/icons/youtube.svg';
import { ReactComponent as DoubleArrows } from '../../assets/icons/doubleArrows.svg';
import { ReactComponent as UnlockedDetailed } from '../../assets/icons/unlockedDetailed.svg';
import { ReactComponent as NewsFeed } from '../../assets/icons/newsFeed.svg';
import { ReactComponent as BellClippedMobile } from '../../assets/icons/bellClippedMobile.svg';
import { ReactComponent as Folder } from '../../assets/icons/folder.svg';
import { ReactComponent as FolderCrossedOut } from '../../assets/icons/folderCrossedOut.svg';
import { ReactComponent as Dots } from '../../assets/icons/dots.svg';
import { ReactComponent as TButton } from '../../assets/icons/tButton.svg';
import { ReactComponent as TextEdit } from '../../assets/icons/textEdit.svg';
import { ReactComponent as Tidy } from '../../assets/icons/tidy.svg';
import { ReactComponent as Mockup } from '../../assets/icons/mockup.svg';
import { ReactComponent as Bucket } from '../../assets/icons/bucket.svg';
import { ReactComponent as StarPairSolid } from '../../assets/icons/starPairSolid.svg';
import { ReactComponent as StarPair } from '../../assets/icons/starPair.svg';
import { ReactComponent as PlayCircle } from '../../assets/icons/playCircle.svg';
import { ReactComponent as Picture } from '../../assets/icons/picture.svg';
import { ReactComponent as Files } from '../../assets/icons/files.svg';

// import { ReactComponent as NewIcon } from '../../assets/icons/new.svg';

export default {
  edit: EditIcon,
  share: ShareIcon,
  download: DownloadIcon,
  keyboard: KeyboardIcon,
  help: HelpIcon,
  settings: SettingsIcon,
  close: CloseIcon,
  template: TemplateIcon,
  text: TextIcon,
  elements: ElementsIcon,
  draw: DrawIcon,
  upload: UploadIcon,
  texture: TextureIcon,
  more: MoreIcon,
  layer: LayerIcon,
  style: StyleIcon,
  effect: EffectIcon,
  colorPicker: ColorPickerIcon,
  search: SearchIcon,
  arrowUp: ArrowUpIcon,
  info: Info,
  like: Like,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  dropShadow: DropShadowIcon,
  lineShadow: LineShadowIcon,
  blockShadow: BlockShadowIcon,
  detailShadow: DetailShadowIcon,
  horizontalLines: HorizontalLinesIcon,
  colorCut: ColorCutIcon,
  fadingColorCut: FadingColorCutIcon,
  obliqueLines: ObliqueLinesIcon,
  angleTransform: AngleTransformIcon,
  archTransform: ArchTransformIcon,
  circleTransform: CircleTransformIcon,
  flagTransform: FlagTransformIcon,
  freeFormTransform: FreeFormTransformIcon,
  customTransform: CustomTransformIcon,
  riseTransform: RiseTransformIcon,
  waveTransform: WaveTransformIcon,
  alignLeft: AlignLeftIcon,
  alignCenter: AlignCenterIcon,
  alignRight: AlignRightIcon,
  alignJustify: AlignJustifyIcon,
  uppercase: UppercaseIcon,
  underline: UnderlineIcon,
  forward: ForwardIcon,
  backward: BackwardIcon,
  toFront: ToFrontIcon,
  toBack: ToBackIcon,
  left: LeftIcon,
  center: CenterIcon,
  right: RightIcon,
  top: TopIcon,
  middle: MiddleIcon,
  bottom: BottomIcon,
  glyph: GlyphIcon,
  bear: BearIcon,
  ornament: OrnamentIcon,
  the: TheIcon,
  shapes: ShapesIcon,
  overlay: OverlayIcon,
  underlay: UnderlayIcon,
  pattern: PatternIcon,
  a4_a5: A4A5,
  businessCard: BusinessCard,
  customSize: CustomSize,
  defaultArtboard: DefaultArtboard,
  facebookPost: FacebookPost,
  instagramPost: InstagramPost,
  landscape: Landscape,
  portrait: Portrait,
  postcard: Postcard,
  poster: Poster,
  group: GroupIcon,
  background: BackgroundIcon,
  t: TIcon,
  eye: EyeIcon,
  eyeSlash: EyeSlashIcon,
  eyeOutline: EyeOutlineIcon,
  eyeOutlineSlash: EyeOutlineSlashIcon,
  unlock: UnlockIcon,
  lock: LockIcon,
  lockDetailed: LockDetailed,
  flipHorizontal: FlipHorizontalIcon,
  flipVertical: FlipVerticalIcon,
  clippingMask: ClippingMaskIcon,
  illustration: IllustrationIcon,
  undo: UndoIcon,
  redo: RedoIcon,
  plus: PlusIcon,
  minus: MinusIcon,
  copy: CopyIcon,
  paste: PasteIcon,
  delete: DeleteIcon,
  assets: AssetsIcon,
  abstract: AbstractIcon,
  ligature: LigatureIcon,
  rects: Rects,
  tag: Tag,
  smiley: Smiley,
  star: Star,
  free: Free,
  pro: Pro,
  bell: Bell,
  facebook: Facebook,
  google: Google,
  check: Check,
  create: Create,
  elementDelete: ElementDelete,
  bookmark: Bookmark,
  crown: Crown,
  elementBookmark: ElementBookmark,
  gift: Gift,
  checkbox: Checkbox,
  lockedValues: LockedValues,
  progress: Progress,
  envelope: Envelope,
  dialog: Dialog,
  pricingCheck: PricingCheck,
  letterSpacing: LetterSpacing,
  lineHeight: LineHeight,
  mapMarker: MapMarker,
  filter: Filter,
  filledBookmark: FilledBookmark,
  pinterest: Pinterest,
  bellClipped: BellClipped,
  wifi: WiFi,
  exclamation: Exclamation,
  templateBookmark: TemplateBookmark,
  link: Link,
  camera: Camera,
  website: Website,
  mapMarker2: MapMarker2,
  premiumBadge: PremiumBadge,
  premiumBadgeOutlined: PremiumBadgeOutlined,
  facebookCircle: FacebookCircle,
  youtubeCircle: YoutubeCircle,
  instagramCircle: InstagramCircle,
  twitterCircle: TwitterCircle,
  linkedin: Linkedin,
  twitter: Twitter,
  comments: Comments,
  clicks: Clicks,
  likes: Likes,
  profile: Profile,
  designElementSettings: DesignElementSettings,
  lightning: Lightning,
  eyelashes: EyeLashes,
  whatsapp: Whatsapp,
  elementEdit: ElementEdit,
  masonry: Masonry,
  rowLayout: RowLayout,
  linkShare: LinkShare,
  filledHeart: FilledHeart,
  heart: Heart,
  notificationTick: NotificationTick,
  notificationCross: NotificationCross,
  addFriend: AddFriend,
  upgrade: Upgrade,
  paperClip: PaperClip,
  infoThick: InfoThick,
  user: User,
  colorPalette: ColorPalette,
  clock: Clock,
  thumbsUp: ThumbsUp,
  ticket: Ticket,
  trophy: Trophy,
  plusThin: PlusThin,
  minusThin: MinusThin,
  arrow: Arrow,
  loading: Loading,
  youtube: Youtube,
  doubleArrows: DoubleArrows,
  unlockedDetailed: UnlockedDetailed,
  newsFeed: NewsFeed,
  bellClippedMobile: BellClippedMobile,
  folder: Folder,
  folderCrossedOut: FolderCrossedOut,
  dots: Dots,
  tButton: TButton,
  textEdit: TextEdit,
  tidy: Tidy,
  mockup: Mockup,
  bucket: Bucket,
  starPairSolid: StarPairSolid,
  starPair: StarPair,
  playCircle: PlayCircle,
  picture: Picture,
  files: Files,
  // new: NewIcon,
};
