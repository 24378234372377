import { getCenterFromOptions } from '../../../utils/editor/misc';

export default function (fabric) {
  fabric.Rect.prototype.initialize = (function (initialize) {
    return function (options) {
      options || (options = {});

      if (options.canvas?.artboard) {
        if (options.wasDropped) {
          const zoom = options.canvas.getZoom();
          options.top = options.top - options.distanceToTopCorner.y / zoom;
          options.left = options.left - options.distanceToTopCorner.x / zoom;
          options.width = options.imgWidth / zoom;
          options.height = options.imgHeight / zoom;
        } else {
          const { centerX, centerY } = getCenterFromOptions(options);
          const artboard = options.canvas.artboard;
          const minSide = Math.min(artboard.width, artboard.height);
          options.width = options.width || minSide * fabric.NEW_OBJECT_SCALE;
          options.height = options.height || minSide * fabric.NEW_OBJECT_SCALE;
          options.top = centerY - options.height / 2;
          options.left = centerX - options.width / 2;
        }
      }

      options.objectCaching = false;
      options.strokeWidth = options.strokeWidth || 0;

      initialize.apply(this, [options]);

      if (this.scaleX !== 1 || this.scaleY !== 1) {
        this.adjustDimensionsToScale(); // reset scale on initialization (if refreshed without deselected)
      }

      this.on('scaled', () => {
        this.adjustDimensionsToScale(); // reset scale on scaled
      });

      this.on('deselected', () => {
        this.adjustDimensionsToScale(); // reset scale after it changed in an activeSelection
      });

      // We need to enable middle controls back, as they're disabled on Object level
      this.setControlsVisibility({
        mr: true,
        mt: true,
        ml: true,
        mb: true,
      });
      return this;
    };
  })(fabric.Rect.prototype.initialize);

  /**
   * apply scale to width and height and reset it to 1
   */
  fabric.Rect.prototype.adjustDimensionsToScale = function () {
    this.set({
      height: this.height * this.scaleY,
      width: this.width * this.scaleX,
      scaleX: 1,
      scaleY: 1,
    });
  };

  // ignore stroke for bounding box
  fabric.Rect.prototype._finalizeDimensions = (x, y) => ({ x, y });
}
