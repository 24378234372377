import * as THREE from 'three';
import noop from 'lodash/noop';

import fabric from '../../../Artboard/fabric';
import { MockupTemplateWithWarpGrid } from '../../types';
import WarpGrid, { GeometryArea } from './WarpGrid';
import { Point } from '../../../../types';
import { designLayerId } from '../../utils';
import { mockupTemplateDimension } from '../../../MockupTemplateBoard/utils';

/**
 * This capsules the functionality of warping an image within a warp grid.
 * How it works:
 * A 3D scene and a warp grid is setup for a given mockup template.
 * The image to warp is created as a mesh in the scene, which takes:
 *  - a material, which takes the image as its texture;
 *  - a buffer geometry, which can be created based on the top left point and size in the warp grid;
 */
export default class WarpCanvas {
  /*
    if `true`, some debug elements will be visible.
   */
  debug: boolean;

  mockupTemplate: MockupTemplateWithWarpGrid;

  element: HTMLCanvasElement;

  scene: THREE.Scene;

  renderer: THREE.Renderer;

  camera: THREE.OrthographicCamera;

  /*
    The utility instance for creating geometries within a warp grid.
   */
  warpGrid: WarpGrid;

  /*
    The mesh of the warp grid.
   */
  gridMesh: THREE.Mesh;

  /*
    The mesh of the target, whose material has a texture of the image to be warped.
   */
  targetMesh: null | THREE.Mesh;

  targetMaterial: THREE.MeshBasicMaterial;

  /*
    The id of the warp canvas element.
   */
  static elementId = 'mockup-warp-canvas';

  static loadingManager = new THREE.TextureLoader();

  constructor(mockupTemplate: MockupTemplateWithWarpGrid) {
    this.debug = false;
    this.mockupTemplate = mockupTemplate;
    this.element = this.initCanvasElement();
    const { scene, renderer, camera } = this.setupScene();
    this.scene = scene;
    this.renderer = renderer;
    this.camera = camera;
    this.warpGrid = new WarpGrid({
      row: mockupTemplate.warpGrid.row,
      col: mockupTemplate.warpGrid.col,
      points: this.getVerticallyFlippedPoints(
        mockupTemplate.warpGrid.points,
        mockupTemplateDimension.height
      ),
    });
    this.gridMesh = this.createGrid();
    this.targetMesh = null;
    this.targetMaterial = new THREE.MeshBasicMaterial({
      transparent: true,
    });

    return this;
  }

  /*
    Init the canvas element. It can be visible for debug purposes.
   */
  initCanvasElement(): HTMLCanvasElement {
    const element = document.createElement('canvas');
    element.id = WarpCanvas.elementId;
    element.width = mockupTemplateDimension.width;
    element.height = mockupTemplateDimension.height;
    element.style.position = 'fixed';

    if (this.debug) {
      element.style.width = `${window.innerHeight * 0.5}px`;
      element.style.height = `${window.innerHeight * 0.5}px`;
      element.style.zIndex = '9999';
      element.style.right = '0';
      element.style.top = '50px';
    } else {
      element.style.zIndex = '-9999';
      element.style.left = '-9999px';
      element.style.top = '-9999px';
    }

    document.body.appendChild(element);

    return element;
  }

  /*
    Setup the scene given the mockup template.
   */
  setupScene(): {
    scene: THREE.Scene;
    renderer: THREE.Renderer;
    camera: THREE.OrthographicCamera;
  } {
    const renderer = new THREE.WebGLRenderer({
      canvas: this.element,
      alpha: true,
    });
    renderer.setClearColor(0xffffff, 0);

    const scene = new THREE.Scene();

    const camera = new THREE.OrthographicCamera(
      0,
      mockupTemplateDimension.width,
      mockupTemplateDimension.height,
      0,
      0,
      1
    );
    scene.add(camera);

    return {
      renderer,
      scene,
      camera,
    };
  }

  /*
    Create the warp grid mesh. It can be visible for debug purposes.
   */
  createGrid(): THREE.Mesh {
    const { geometry } = this.warpGrid.createGeometry();
    const material = new THREE.MeshBasicMaterial({ color: 0xdddddd });
    material.wireframe = true;
    const gridMesh = new THREE.Mesh(geometry, material);

    if (this.debug) {
      this.scene.add(gridMesh);
      this.renderer.render(this.scene, this.camera);
    }

    return gridMesh;
  }

  /*
    Get vertically flipped point.
    Used for processing corrdinated between 2D and 3D canvases,
    as they point to diffferent directions on the y axis.
   */
  getVerticallyFlippedPoint(point: Point, height: number): Point {
    return {
      x: point.x,
      y: height - point.y,
    };
  }

  getVerticallyFlippedPoints(
    points: Record<string, Point>,
    height: number
  ): Record<string, Point> {
    const newPoints: Record<string, Point> = {};
    Object.entries(points).forEach(([key, point]): void => {
      newPoints[key] = this.getVerticallyFlippedPoint(point, height);
    });
    return newPoints;
  }

  /*
    Update the texture of the target mesh with an image.
   */
  async updateTargetMaterialMap(dataUrl: string): Promise<void> {
    return new Promise((resolve, reject) => {
      WarpCanvas.loadingManager.load(
        dataUrl,
        (texture) => {
          this.targetMaterial.map = texture;
          this.targetMaterial.transparent = true;
          this.targetMaterial.map.needsUpdate = true;
          resolve();
        },
        noop,
        () => {
          reject('Load design dataURL as texture failed');
        }
      );
    });
  }

  getIntersectWithWarpGrid(point: Point): THREE.Intersection | undefined {
    const raycaster = new THREE.Raycaster();
    raycaster.set(
      new THREE.Vector3(point.x, point.y, 0.5),
      new THREE.Vector3(0, 0, -0.5)
    );
    const intersects: THREE.Intersection[] = [];
    raycaster.intersectObject(this.gridMesh, false, intersects);
    return intersects[0];
  }

  /*
    Get the area in the warp grid with given top left point and size.
    Returns the start/end row and column if they are in bound;
    otherwise `undefined`.
   */
  getGeometryArea(
    topLeft: Point,
    size: { width: number; height: number }
  ): GeometryArea | undefined {
    const intersect = this.getIntersectWithWarpGrid(topLeft);
    if (!intersect) return undefined;

    const point = new THREE.Vector3(topLeft.x, topLeft.y, 0);
    const iStart = Math.floor(
      intersect.faceIndex! / (this.warpGrid.totalDivision.col * 2)
    );
    const jStart = Math.floor(
      (intersect.faceIndex! % (this.warpGrid.totalDivision.col * 2)) / 2
    );
    const startBottomVertex = this.warpGrid.getVertex(iStart + 1, jStart);
    const startRightVertex = this.warpGrid.getVertex(iStart, jStart + 1);
    const startVertex = this.warpGrid.getVertex(iStart, jStart);
    const startBaryCoordTriangle = new THREE.Triangle(
      new THREE.Vector3(startBottomVertex.x, startBottomVertex.y, 0),
      new THREE.Vector3(startRightVertex.x, startRightVertex.y, 0),
      new THREE.Vector3(startVertex.x, startVertex.y, 0)
    );
    const startBaryCoord = new THREE.Vector3();
    startBaryCoordTriangle.getBarycoord(point, startBaryCoord);

    const start = {
      i: iStart + startBaryCoord.x,
      j: jStart + startBaryCoord.y,
    };

    const end = {
      i: start.i + this.warpGrid.totalDivision.row * size.height,
      j: start.j + this.warpGrid.totalDivision.col * size.width,
    };

    if (
      end.i > this.warpGrid.totalDivision.row ||
      end.j > this.warpGrid.totalDivision.col
    ) {
      return undefined;
    } else {
      return { start, end };
    }
  }

  /*
    Render the target in the scene with given top left point and size.
    Returns
      * attrs to our interests after warp
      * the top left point ratio in the bbox of warped target.
    which are used in `WarpedImaged`.
    Returns `undefined` if the geometry to create is out of bound.
   */
  renderTarget(
    topLeft: Point,
    size: {
      width: number;
      height: number;
    }
  ):
    | {
        warpedAttrs: {
          cropX: number;
          cropY: number;
          left: number;
          top: number;
          width: number;
          height: number;
        };
        topLeftRatioInBBox: Point;
      }
    | undefined {
    const geometryArea = this.getGeometryArea(
      this.getVerticallyFlippedPoint(topLeft, mockupTemplateDimension.height),
      size
    );
    if (!geometryArea) return;

    const { geometry, boundary, corners } =
      this.warpGrid.createGeometry(geometryArea);

    if (!this.targetMesh) {
      this.targetMesh = new THREE.Mesh(geometry, this.targetMaterial);
      this.scene.add(this.targetMesh);
    } else {
      this.targetMesh.geometry = geometry;
      this.targetMesh.geometry.attributes.position.needsUpdate = true;
    }

    this.renderer.render(this.scene, this.camera);

    const warpedAttrs = {
      cropX: boundary.minX,
      cropY: mockupTemplateDimension.height - boundary.maxY,
      width: boundary.maxX - boundary.minX,
      height: boundary.maxY - boundary.minY,
      left: boundary.minX,
      top: mockupTemplateDimension.height - boundary.maxY,
    };

    const topLeftRatioInBBox = {
      x: (corners.tl.x - boundary.minX) / warpedAttrs.width,
      y: (boundary.maxY - corners.tl.y) / warpedAttrs.height,
    };

    return {
      warpedAttrs,
      topLeftRatioInBBox,
    };
  }

  /*
    Get the initial top left point and size of the target in the warp grid.
   */
  getTargetInitTopLeftAndSize(): {
    topLeft: Point;
    size: { width: number; height: number };
  } {
    const maxSize = 0.2;
    const size = {
      width: maxSize,
      height: maxSize,
    };
    const ratio = this.targetMaterial.map
      ? this.targetMaterial.map.image.width /
        this.targetMaterial.map.image.height
      : 1;

    if (ratio > 1) {
      size.height = maxSize / ratio;
    } else if (ratio < 1) {
      size.width = maxSize * ratio;
    }

    const topLeft = {
      x: ((1 - size.width) / 2) * mockupTemplateDimension.width,
      y: ((1 - size.height) / 2) * mockupTemplateDimension.height,
    };

    return {
      topLeft,
      size,
    };
  }

  /*
   Create a fabric object with warp utils provided by this.
   */
  async createdWarpedImage(): Promise<fabric.WarpedImage> {
    const { topLeft, size } = this.getTargetInitTopLeftAndSize();
    return await new fabric.WarpedImage({
      id: designLayerId,
      warpCanvasId: WarpCanvas.elementId,
      topLeftInWarpGrid: topLeft,
      sizeInWarpGrid: size,
      warpRender: (
        topLeft: Point,
        size: {
          width: number;
          height: number;
        }
      ):
        | {
            warpedAttrs: {
              cropX: number;
              cropY: number;
              left: number;
              top: number;
              width: number;
              height: number;
            };
            topLeftRatioInBBox: Point;
          }
        | undefined => this.renderTarget(topLeft, size),
    });
  }

  destroy(): void {
    if (document.body.contains(this.element)) {
      document.body.removeChild(this.element);
    }
  }
}
