import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  FontImageWrapper,
  FontImg,
  TypefaceAndVariable,
  FontLabel,
} from './styles';
import { FlexRow } from '../../utilities/styles';
import Select from '../../SelectNew/Select';
import RightMenuHeadline from '../../utilities/RightMenuHeader/RightMenuHeader';
import GlyphsButton from '../GlyphsPanel/GlyphsButton';
import TypefacePanel from '../TypefacePanel/TypefacePanel';
import VariableFontPanel from '../VariableFontPanel/VariableFontPanel';
import GlyphsPanel from '../GlyphsPanel/GlyphsPanel';
import Popover from '../../Popover/Popover';
import {
  ACTIVE_FONTFAMILY,
  ACTIVE_FONTSIZE,
  ACTIVE_LETTERSPACING,
  ACTIVE_LINEHEIGHT,
  ACTIVE_TEXTALIGNMENT,
  ACTIVE_GLYPH,
} from '../../../global/events';
import {
  checkFontWeight,
  DEFAULT_FONT_WEIGHT,
  loadFont,
} from '../../../font/fonts';
import { MAX_FONT_SIZE } from '../../../global/constants';
import { textTransformConfig, textAlignConfig } from './config';
import WrappedNumberInput from '../../WrappedNumberInput/WrappedNumberInput';
import {
  userFontsStoreSelector,
  useUserFontsStore,
} from '../../../stores/userFontsStore';
import {
  promotionSelector,
  usePromotionStore,
} from '../../../stores/promotionStore';
import Tooltip from '../../Tooltip/Tooltip';
import SelectController from '../../SelectController/SelectController';
import Button from '../../Button/Button/Button';
import { editorMenuItemTheme } from '../../Button/Button/theme';

/**
 * The TextSettingsPanel
 */
const TextSettingsPanel = ({ options, ...props }) => {
  const fonts = useUserFontsStore(userFontsStoreSelector.fonts);
  const getFonts = useUserFontsStore(userFontsStoreSelector.getFonts);
  const [font, setFont] = useState();
  const [fontWeight, setFontWeight] = useState(DEFAULT_FONT_WEIGHT);
  const [typefacePanelOpen, setTypefacePanelOpen] = useState(false);
  const [variablePanelOpen, setVariablePanelOpen] = useState(false);

  const dispatchEvent = (event, value, isChanging = false) => {
    props.dispatch && props.dispatch(event, { ...value, isChanging });
  };

  const dispatchTextAlign = (textAlignment) => {
    dispatchEvent(ACTIVE_TEXTALIGNMENT, { textAlignment });
  };

  useEffect(() => {
    if (!options) return;

    if (options.fontFamily.mixture) {
      setFont(null);
      return;
    }

    // If options.fontFamily is not a mixture then its value must be defined
    if (font?.fontFamily === options.fontFamily.value) return;

    const fontData = fonts.find(
      (font) => font.fontFamily === options.fontFamily.value
    );

    // load font options
    loadFont(options.fontFamily.value).then((font) => {
      let variationAxes;
      let variationKeys;
      try {
        variationAxes = font.variationAxes;
        variationKeys = Object.keys(font.variationAxes);
      } catch {
        // do nothing
      }
      setFont({ ...fontData, variationAxes, variationKeys });
    });
  }, [font, fonts, options]);

  useEffect(() => {
    getFonts();
  }, [getFonts]);

  useEffect(() => {
    if (!options) return;

    if (options.fontWeight.mixture && !options.fontWeight.value) {
      setFontWeight(null);
      return;
    }

    if (fontWeight === options.fontWeight.value) return;

    /*
      If options.fontWeight.value is defined then:
        - options.fontFamily is not a mixture => its value is defined
    */
    const effect = async () =>
      setFontWeight(
        await checkFontWeight(
          options.fontFamily.value,
          options.fontWeight.value
        )
      );
    effect();
  }, [fontWeight, options]);

  const [ignoreOutsideEvents, setIgnoreOutsideEvents] = useState(false);
  const showUploadError = useUserFontsStore(
    userFontsStoreSelector.showUploadError
  );
  const upgradeModalIsOpen = usePromotionStore(
    promotionSelector.upgradeModalIsOpen
  );

  const numberInputTheme = {
    width: '100%',
    height: '100%',
  };

  return (
    <div>
      <RightMenuHeadline label={'Text Settings'} />
      <TypefaceAndVariable
        gap="8px"
        justifyContent={'space-between'}
        marginBottom={'8px'}
        marginTop={'8px'}
        height={'40px'}
      >
        <Popover
          dataTestId="typeface-panel"
          label={'Typeface'}
          placement={'left'}
          align={'center'}
          ignoreEventOutside={
            ignoreOutsideEvents || showUploadError || upgradeModalIsOpen
          }
          theme={{
            padding: '0',
            headPadding: '12px 16px',
          }}
          content={
            <TypefacePanel
              fonts={fonts}
              onSelect={(value) =>
                dispatchEvent(ACTIVE_FONTFAMILY, {
                  fontFamily: value.fontFamily,
                  fontWeight: value.defaultWeight,
                })
              }
              onModalOpen={() => setIgnoreOutsideEvents(true)}
              onModalClose={() => setIgnoreOutsideEvents(false)}
            />
          }
          width="268px"
          onToggle={() => setTypefacePanelOpen((value) => !value)}
        >
          <Tooltip text="Typeface">
            <SelectController
              dataTestId="font-family-selector"
              label={
                <FontImageWrapper>
                  {font?.img && <FontImg src={font.img} label={font.name} />}
                  {font && !font.img && <FontLabel>{font.name}</FontLabel>}
                  {options?.fontFamily.mixture && 'Multiple Fonts Selected'}
                </FontImageWrapper>
              }
              dropdownExpanded={typefacePanelOpen}
            />
          </Tooltip>
        </Popover>
        {(font?.weights?.length === 1 && !!font?.variationKeys?.length && (
          <Popover
            label={'Variable Font Settings'}
            placement={'left'}
            align={'start'}
            content={
              <VariableFontPanel
                activeFont={font}
                options={options}
                dispatch={props.dispatch}
              />
            }
            theme={{
              padding: '0',
              headPadding: '12px 16px',
            }}
            width="294px"
            onToggle={() => setVariablePanelOpen((value) => !value)}
          >
            <Tooltip text="Variable settings" align="end">
              <SelectController
                small
                label="Variable"
                dropdownExpanded={variablePanelOpen}
                customStyles={{
                  height: '40px',
                }}
              />
            </Tooltip>
          </Popover>
        )) ||
          (font && (
            <Tooltip text="Font Weight" align="end">
              <Select
                dataTestId="font-weight-selector"
                small
                options={font?.weights?.map((value) => ({
                  id: value,
                  label: value,
                }))}
                activeOption={{
                  id: fontWeight || 'Mix',
                  label: fontWeight || 'Mix',
                }}
                customStyles={{
                  height: '40px',
                }}
                onSelect={(option) =>
                  dispatchEvent(ACTIVE_FONTFAMILY, {
                    fontFamily: font?.fontFamily,
                    fontWeight: option.id,
                  })
                }
              />
            </Tooltip>
          ))}
      </TypefaceAndVariable>
      <FlexRow height="30px" gap="8px">
        <WrappedNumberInput
          dataTestId="font-size"
          value={{ value: options?.fontSize.value }}
          startingPlaceholder={options?.fontSize.mixture ? 'Mix' : undefined}
          precision={0}
          min={1}
          max={MAX_FONT_SIZE}
          onChanging={(fontSize) => {
            dispatchEvent(ACTIVE_FONTSIZE, { fontSize }, true);
          }}
          onChanged={(fontSize) => {
            dispatchEvent(ACTIVE_FONTSIZE, { fontSize }, false);
          }}
          icon="text"
          tooltipText="Font size"
          theme={numberInputTheme}
        />
        <WrappedNumberInput
          dataTestId="letter-spacing"
          value={{ value: options?.letterSpacing.value }}
          startingPlaceholder={
            options?.letterSpacing.mixture ? 'Mix' : undefined
          }
          precision={0}
          min={-300}
          max={1000}
          onChanging={(letterSpacing) => {
            dispatchEvent(ACTIVE_LETTERSPACING, { letterSpacing }, true);
          }}
          onChanged={(letterSpacing) => {
            dispatchEvent(ACTIVE_LETTERSPACING, { letterSpacing }, false);
          }}
          icon="letterSpacing"
          tooltipText="Letter spacing"
          theme={numberInputTheme}
        />
        <WrappedNumberInput
          value={{ value: options?.lineHeight.value / 10 }}
          startingPlaceholder={options?.lineHeight.mixture ? 'Mix' : undefined}
          precision={0}
          min={-30}
          max={100}
          onChanging={(lineHeight) => {
            dispatchEvent(
              ACTIVE_LINEHEIGHT,
              { lineHeight: lineHeight * 10 },
              true
            );
          }}
          onChanged={(lineHeight) => {
            dispatchEvent(
              ACTIVE_LINEHEIGHT,
              { lineHeight: lineHeight * 10 },
              false
            );
          }}
          icon="lineHeight"
          tooltipText="Line height"
          theme={numberInputTheme}
        />
      </FlexRow>
      <FlexRow
        data-testid="text-alignment-actions"
        justifyContent="space-between"
        marginTop={'8px'}
      >
        {textAlignConfig.map((item) => (
          <Tooltip text={item.tooltip}>
            <Button
              dataTestId={item.id}
              isActive={item.id === options?.textAlignment.value}
              icon={{
                name: item.icon,
                height: '12px',
              }}
              width="35px"
              theme={editorMenuItemTheme}
              onClick={() => dispatchTextAlign(item.id)}
            />
          </Tooltip>
        ))}
        {textTransformConfig.map((item) => (
          <Tooltip text={item.tooltip}>
            <Button
              dataTestId={item.id}
              isActive={options && !!options[item.id]?.value}
              icon={{
                name: item.icon,
                height: '12px',
              }}
              width="35px"
              theme={editorMenuItemTheme}
              onClick={() =>
                dispatchEvent(item.event, {
                  [item.id]: options && !options[item.id].value,
                })
              }
            />
          </Tooltip>
        ))}
        {!props.isMultipleEdit && (
          <Popover
            label={`${font?.name} Glyphs`}
            placement={'left'}
            align={'center'}
            theme={{
              padding: '0',
              headPadding: '12px 16px',
            }}
            content={
              <GlyphsPanel
                onSelect={(value) => dispatchEvent(ACTIVE_GLYPH, value)}
                activeFont={options?.fontFamily.value}
              />
            }
            width="314px"
            onClose={() => {}}
          >
            <GlyphsButton />
          </Popover>
        )}
      </FlexRow>
    </div>
  );
};

TextSettingsPanel.propTypes = {
  /**
   * current text setting options
   */
  options: PropTypes.shape({
    fontFamily: PropTypes.shape({
      value: PropTypes.string,
      mixture: PropTypes.bool,
    }),
    fontWeight: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      mixture: PropTypes.bool,
    }),
    textAlignment: PropTypes.shape({
      value: PropTypes.oneOf(['justify', 'center', 'left', 'right']),
      mixture: PropTypes.bool,
    }),
    uppercase: PropTypes.shape({
      value: PropTypes.bool,
      mixture: PropTypes.bool,
    }),
    useLigatures: PropTypes.shape({
      value: PropTypes.bool,
      mixture: PropTypes.bool,
    }),
    fontSize: PropTypes.shape({
      value: PropTypes.number,
      mixture: PropTypes.bool,
    }),
    letterSpacing: PropTypes.shape({
      value: PropTypes.number,
      mixture: PropTypes.bool,
    }),
    lineHeight: PropTypes.shape({
      value: PropTypes.number,
      mixture: PropTypes.bool,
    }),
    variationAxes: PropTypes.shape({
      value: PropTypes.object,
      mixture: PropTypes.bool,
    }),
    variation: PropTypes.shape({
      value: PropTypes.object,
      mixture: PropTypes.bool,
    }),
  }).isRequired,
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
  isMultipleEdit: PropTypes.bool,
};

export default TextSettingsPanel;
