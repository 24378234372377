/**
 * get a label to display the time passed between
 * the input time and now
 */
export const timeAgo = (
  time: string,
  noSeconds?: boolean,
  shortTimeDescriptions?: boolean
): string => {
  const timeDate = Date.parse(time);
  const now = Date.now();
  const diff = now - timeDate;

  if (diff / 1000 < 20) {
    return 'just now';
  }

  if (diff / 1000 < 40) {
    return noSeconds
      ? 'just now'
      : `20 ${shortTimeDescriptions ? 's.' : 'seconds'} ago`;
  }

  if (diff / 1000 < 60) {
    return noSeconds
      ? 'just now'
      : `40 ${shortTimeDescriptions ? 's.' : 'seconds'} ago`;
  }

  if (diff / (1000 * 60) < 60) {
    const timeDiff = Math.floor(diff / (1000 * 60));
    return `${timeDiff} ${
      shortTimeDescriptions ? 'min.' : `minute${timeDiff > 1 ? 's' : ''}`
    } ago`;
  }

  if (diff / (1000 * 60 * 60) < 24) {
    const timeDiff = Math.floor(diff / (1000 * 60 * 60));
    return `${timeDiff} hour${timeDiff > 1 ? 's' : ''} ago`;
  }

  if (diff / (1000 * 60 * 60 * 24) < 365) {
    const timeDiff = Math.floor(diff / (1000 * 60 * 60 * 24));
    return `${timeDiff} day${timeDiff > 1 ? 's' : ''} ago`;
  }

  const timeDiff = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  return `${timeDiff} year${timeDiff > 1 ? 's' : ''} ago`;
};
