import React, { useCallback } from 'react';

import Button from '../../Button/Button/Button';
import { MockupTemplateControlsWrapper } from './styles';
import { FlexRow } from '../../utilities/styles';
import {
  mockupTemplateStoreSelector,
  useMockupTemplateStore,
} from '../../../stores/mockupTemplateStore';
import { menuStoreSelector, useMenuStore } from '../../../stores/menuStore';
import { Dispatcher, WorkingMode } from '../../../types';
import { MockupTemplateLayer } from '../../../stores/mockupTemplateStore/types';
import {
  primarySmallTheme,
  secondarySmallTheme,
} from '../../Button/Button/theme';
import {
  DESIGN_SAVE,
  MTC_SAVE,
  MTC_DELETE_WRAP_GRID,
  MTC_CREATE_WRAP_GRID,
} from '../../../global/events';
import Spacer from '../../Spacer/Spacer';

interface MockupTemplateControlsProps {
  dispatch: Dispatcher;
}

/**
 * A set of controls when creating a mockup template.
 */
const MockupTemplateControls: React.FC<MockupTemplateControlsProps> = ({
  dispatch,
}) => {
  const warpGridSize = useMockupTemplateStore(
    mockupTemplateStoreSelector.warpGridSize
  );
  const updateWarpGridSize = useMockupTemplateStore(
    mockupTemplateStoreSelector.updateWarpGridSize
  );
  const layers = useMockupTemplateStore(mockupTemplateStoreSelector.layers);
  const updateLayers = useMockupTemplateStore(
    mockupTemplateStoreSelector.updateLayers
  );
  const updateLayerFiles = useMockupTemplateStore(
    mockupTemplateStoreSelector.updateLayerFiles
  );
  const resetTemplateData = useMockupTemplateStore(
    mockupTemplateStoreSelector.resetTemplateData
  );

  const workingMode = useMenuStore(menuStoreSelector.workingMode);
  const isMockupTemplateMode = workingMode === WorkingMode.MockupTemplate;
  const setWorkingMode = useMenuStore(menuStoreSelector.setWorkingMode);
  const resetWorkingMode = useMenuStore(menuStoreSelector.resetWorkingMode);

  const toggleMockupTemplateMode = useCallback(() => {
    if (isMockupTemplateMode) {
      resetWorkingMode();
      resetTemplateData();
    } else {
      const cb = (): void => {
        setWorkingMode(WorkingMode.MockupTemplate);
      };

      dispatch(DESIGN_SAVE, { cb });
    }
  }, [
    dispatch,
    isMockupTemplateMode,
    resetWorkingMode,
    setWorkingMode,
    resetTemplateData,
  ]);

  const onWarpGridRowCountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateWarpGridSize({
        row: Number(e.target.value),
      });
    },
    [updateWarpGridSize]
  );

  const onWarpGridColCountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateWarpGridSize({
        col: Number(e.target.value),
      });
    },
    [updateWarpGridSize]
  );

  const createOnLayerChange =
    (layer: MockupTemplateLayer) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      // revoke current layer url, reference: https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
      if (layers[layer]) {
        URL.revokeObjectURL(layers[layer]);
      }

      const layerUrl = !e.target.files?.[0]
        ? ''
        : URL.createObjectURL(e.target.files[0]);
      updateLayers({ [layer]: layerUrl });
      updateLayerFiles({ [layer]: e.target.files?.[0] ?? null });
    };

  const onSave = async (): Promise<void> => {
    await dispatch(MTC_SAVE);
  };

  const onDeleteWarpGrid = useCallback(() => {
    dispatch(MTC_DELETE_WRAP_GRID);
  }, [dispatch]);

  const onCreateWarpGrid = useCallback(() => {
    dispatch(MTC_CREATE_WRAP_GRID);
  }, [dispatch]);

  return (
    <MockupTemplateControlsWrapper $isMockupTemplateMode={isMockupTemplateMode}>
      <Button
        width={'145px'}
        label={'Toggle MTC Mode'}
        onClick={toggleMockupTemplateMode}
        theme={primarySmallTheme}
      />
      {isMockupTemplateMode && (
        <>
          <label>
            <span>Upload scene:</span>
            <input
              type="file"
              name="scene_layer"
              accept="image/png"
              onChange={createOnLayerChange(MockupTemplateLayer.SceneLayer)}
            />
          </label>
          <label>
            <span>Upload light blending layer:</span>
            <input
              type="file"
              name="light_blend_layer"
              accept="image/png"
              onChange={createOnLayerChange(
                MockupTemplateLayer.LightBlendLayer
              )}
            />
          </label>
          <label>
            <span>Upload dark blending layer:</span>
            <input
              type="file"
              name="dark_blend_layer"
              accept="image/png"
              onChange={createOnLayerChange(MockupTemplateLayer.DarkBlendLayer)}
            />
          </label>
          <div>
            Warp grid:
            <Spacer h="8px" />
            <FlexRow>
              <label>
                <span>Row:</span>
                <input
                  type="number"
                  step="1"
                  min="1"
                  max="8"
                  name="warp_grid_size_row"
                  value={warpGridSize.row}
                  onChange={onWarpGridRowCountChange}
                />
              </label>
              <label>
                <span>Column:</span>
                <input
                  type="number"
                  step="1"
                  min="1"
                  max="8"
                  name="warp_grid_size_col"
                  value={warpGridSize.col}
                  onChange={onWarpGridColCountChange}
                />
              </label>
            </FlexRow>
            <Spacer h="8px" />
            <FlexRow gap="8px">
              <Button
                width="50%"
                label="Delete"
                onClick={onDeleteWarpGrid}
                theme={secondarySmallTheme}
              />
              <Button
                width="50%"
                label="Create"
                onClick={onCreateWarpGrid}
                theme={secondarySmallTheme}
              />
            </FlexRow>
          </div>
          <Button
            width="100%"
            label="Save"
            onClick={onSave}
            theme={primarySmallTheme}
          />
        </>
      )}
    </MockupTemplateControlsWrapper>
  );
};

export default React.memo(MockupTemplateControls);
