import {
  forwardRef,
  cloneElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Panel,
  PanelHead,
  PanelBody,
  Header,
  PanelExtensionWrapper,
  PanelExtension,
} from './styles';
import { panelHeaderTheme } from './theme';

import PanelHeader from '../../utilities/PanelHeader/PanelHeader';
import { BasePanelHandler, BasePanelProps, PanelState } from './types';
import VerticallyScrollable from '../../VerticallyScrollable/VerticallyScrollable';

/**
 * This is a panel component that can be used when creating new panels.
 * It has a head and a body element, supports interactions and can be extended with
 * custom elements in body and head.
 */
const BasePanel = forwardRef<BasePanelHandler, BasePanelProps>((props, ref) => {
  const panelBodyRef = useRef<HTMLDivElement>(null);
  const panelExtensionRef = useRef<HTMLDivElement>(null);
  const [showExtension, setShowExtension] = useState(false);

  const handleEventOutside = useCallback((event: Event) => {
    if (
      panelExtensionRef.current &&
      !panelExtensionRef.current.contains(event.target as Node)
    ) {
      setShowExtension(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('pointerdown', handleEventOutside);

    return (): void => {
      document.removeEventListener('pointerdown', handleEventOutside);
    };
  }, [handleEventOutside]);

  useImperativeHandle(ref, () => ({
    setShowExtension: (value: boolean): void => setShowExtension(value),
  }));

  return (
    <Panel>
      <PanelHead>
        <Header>
          <PanelHeader
            theme={panelHeaderTheme}
            label={props.label}
            onClose={props.onClose}
            labelPrefix={props.labelPrefix}
            labelPostfix={props.labelPostfix}
          />
        </Header>
        {props.headElement}
      </PanelHead>
      <PanelBody noOverflow={props.noOverflow} ref={panelBodyRef}>
        {cloneElement(props.bodyElement, { panelBody: panelBodyRef })}
      </PanelBody>
      {showExtension &&
        props.state === PanelState.Open &&
        !!props.extensionElement && (
          <PanelExtensionWrapper>
            <PanelExtension ref={panelExtensionRef}>
              <VerticallyScrollable>
                {props.extensionElement}
              </VerticallyScrollable>
            </PanelExtension>
          </PanelExtensionWrapper>
        )}
    </Panel>
  );
});

export default BasePanel;

BasePanel.defaultProps = {
  label: 'Panel',
};
