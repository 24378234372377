import styled, { keyframes } from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { FlexColumn } from '../../utilities/styles';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const Wrapper = styled.div<any>`
  overflow-y: ${({ disabled }) => (disabled ? 'hidden' : 'unset')};
  position: relative;
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  animation: ${fadeIn} 0.5s ease-in-out;
  z-index: ${themeStyle.zIndexS1};
`;

export const MenuWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${themeStyle.zIndexS1};

  display: ${({ visible }) => (!visible ? 'none' : 'unset')};
`;

export const PromptConfigurationWrapper = styled(FlexColumn)<{
  visible: boolean;
  disabled: boolean;
}>`
  gap: 16px;
  transition: opacity ease-in-out 0.5s;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ disabled, visible }) =>
    disabled || !visible
      ? `
    * {
      pointer-events: none;
    }
  `
      : ''}
`;

export const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: 40px;
  background: ${themeStyle.varInkLight};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${themeStyle.radiusMedium};
  gap: 12px;
`;

export const TextInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  textarea::placeholder {
    font-style: italic;
  }
`;
