import { GetState, SetState } from 'zustand';

import { DownloadFormat, DownloadOptions, State } from './types';
import { DEFAULT_DOWNLOAD_DPI } from '../../global/constants';
import { Unit } from '../../types/ArtboardState';

const getDefaultDownloadOptions = (): DownloadOptions => ({
  width: null,
  height: null,
  dpi: DEFAULT_DOWNLOAD_DPI,
  unit: Unit.px,
  removeBackground: false,
  optimizedQuality: false,
});

export const stateCreator = (
  set: SetState<State>,
  get: GetState<State>
): State => ({
  // if downloading of a certain format is processing
  processing: {
    png: false,
    jpg: false,
    svg: false,
    pdf: false,
  },

  // Options for downloading a project.
  // This will be preserved between opening and closing the panel,
  // but reset when switching to other projects.
  options: getDefaultDownloadOptions(),

  setProcessing: ({
    processing,
    format,
  }: {
    processing: boolean;
    format: DownloadFormat;
  }): void => {
    set({ processing: { ...get().processing, [format]: processing } });
  },

  setOptions: (options: Partial<DownloadOptions>): void => {
    set({ options: { ...get().options, ...options } });
  },

  resetOptions: (): void => {
    set({
      options: getDefaultDownloadOptions(),
    });
  },
});
