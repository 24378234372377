import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { H5, P3 } from '../../utilities/Typography/styles';

export const OptionText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 9px;
`;

export const IconContainer = styled.div`
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomListElement = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const BottomList = styled.div`
  padding-left: 5px;
  padding-right: 5px;

  ${H5} {
    cursor: pointer;
  }

  ${BottomListElement}:not(:last-child) {
    border-bottom: 1px solid ${themeStyle.varInkSuperLight};
  }

  ${BottomListElement} {
    ${H5}:hover {
      color: ${themeStyle.varBrandMedium};
    }
  }

  ${P3} {
    user-select: none;
  }
`;

export const SubscriptionBadge = styled.div`
  margin-right: 8px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
`;
