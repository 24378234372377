import React, { useCallback, useRef } from 'react';
import { useContextSelector } from 'use-context-selector';
import noop from 'lodash/noop';

import useDragNDrop from '../../../hooks/useDragNDrop/useDragNDrop';

import { defaultTheme } from './theme';
import Icon from '../../Icon/Icon';
import {
  Wrapper,
  PreviewImage,
  OuterWrapper,
  ButtonsWrapper,
  IconsWrapper,
  BorderBox,
} from './styles';

import leftMenuContext from '../../leftMenuContext';
import { toastSelector, useToastStore } from '../../../stores/toastStore';
import { ElementProps } from './types';
import { OnDropValue } from '../../../hooks/useDragNDrop/types';
import { themeStyle } from '../../../services/theming';

/**
 * Element component used to render elements in the LeftMenu
 * Used for Designs (Templates), Elements, Textures and TextLayouts
 */
const Element: React.FC<ElementProps> = ({
  onDrop,
  element,
  onStartDragging,
  onDragging,
  onStopDragging,
  ...props
}) => {
  const theme = { ...defaultTheme, ...props.theme };

  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const leftMenuExpanded = useContextSelector(leftMenuContext, (v) => {
    return v[0].expanded;
  });
  const fireToast = useToastStore(toastSelector.fire);

  const handleOnDrop = useCallback(
    (value: OnDropValue) => {
      onDrop &&
        onDrop({
          ...element,
          fireToast: leftMenuExpanded
            ? (label?: string): void =>
                fireToast({
                  label: label || 'Element Added',
                  delay: 100,
                })
            : noop,
          ...value,
        });
    },
    [onDrop, element, fireToast, leftMenuExpanded]
  );

  const { renderOverlay } = useDragNDrop({
    wrapperRef,
    imageRef,
    preview: element.preview,
    onDrop: handleOnDrop,
    onStartDragging,
    onDragging,
    onStopDragging,
  });

  return (
    <OuterWrapper
      elementHeight={element.elementHeight}
      elementWidth={element.elementWidth}
      padding={theme.imagePadding}
    >
      <ButtonsWrapper>{props.buttonsComponent}</ButtonsWrapper>
      {element.premium && (
        <IconsWrapper>
          <Icon name={'premiumBadgeOutlined'} height={'21px'} />
        </IconsWrapper>
      )}
      <Wrapper
        data-testid={`element-${element.id}`}
        padding={theme.imagePadding}
        background={element.artboardOptions?.fill ?? themeStyle.transparent}
        ref={wrapperRef}
      >
        <BorderBox
          outlineOnHover={props.outlineOnHover}
          borderColor={theme.borderColor}
        />
        <PreviewImage
          draggable={false}
          ref={imageRef}
          src={element.preview}
          theme={theme}
        />
      </Wrapper>
      {renderOverlay()}
    </OuterWrapper>
  );
};

export default React.memo(Element);
