import React, { useState, useEffect } from 'react';
import { Wrapper, RailStyle, ThumbStyle } from './styles';
import { defaultTheme } from './theme';
import { SwitchProps, SwitchSize } from './types';

const defaultSize: SwitchSize = {
  width: 30,
  height: 15,
  horizontalInnerPadding: 2,
};

const largeSize: SwitchSize = {
  width: 48,
  height: 24,
  horizontalInnerPadding: 4,
};

/**
 * Switch input, used for boolean inputs. It is displayed in a row with a label.
 */
const Switch: React.FC<SwitchProps> = (props) => {
  const theme = { ...defaultTheme, ...props.theme };
  const [checked, setChecked] = useState(props.checked);
  const size = props.large ? largeSize : defaultSize;

  // enable switch value to be changed from an external source
  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const handleClick = (): void => {
    if (props.disabled) return;

    const newValue = !checked;
    setChecked(newValue);
    props.onChange && props.onChange(newValue);
  };

  return (
    <Wrapper data-testid={props.dataTestId} {...theme}>
      <RailStyle
        data-testid="switcher"
        {...theme}
        checked={checked}
        onClick={handleClick}
        disabled={props.disabled}
        height={size.height}
        width={size.width}
      >
        <ThumbStyle
          {...theme}
          checked={checked}
          disabled={props.disabled}
          size={size}
        />
      </RailStyle>
    </Wrapper>
  );
};

export default Switch;

Switch.defaultProps = {
  checked: false,
  disabled: false,
};
