import fabric from '../../components/Artboard/fabric';

interface CanvasEventHandlerUtils {
  add: (
    canvas: fabric.Canvas,
    eventName: string,
    handler: fabric.EventHandler
  ) => void;
  removeAll: (canvas: fabric.Canvas) => void;
}

/**
 * Returns utils to add and remove event handlers on canvas.
 */
export const createCanvasEventHandlerUtils = (): CanvasEventHandlerUtils => {
  let registeredEventHandlersOnCanvas: {
    eventName: string;
    handler: fabric.EventHandler;
  }[] = [];

  const add = (
    canvas: fabric.Canvas,
    eventName: string,
    handler: fabric.EventHandler
  ): void => {
    canvas.on(eventName, handler);
    registeredEventHandlersOnCanvas.push({ eventName, handler });
  };

  const removeAll = (canvas: fabric.Canvas): void => {
    registeredEventHandlersOnCanvas.forEach(({ eventName, handler }) => {
      canvas.off(eventName, handler);
    });
    registeredEventHandlersOnCanvas = [];
  };

  return {
    add,
    removeAll,
  };
};
