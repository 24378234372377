import React from 'react';

import useWindowSize from '../../hooks/useWindowSize';
import ProfileImage from '../Profile/ProfileImage/ProfileImage';
import Spacer from '../Spacer/Spacer';
import { H2, H6, P2 } from '../utilities/Typography/styles';
import { ProfileImageWrapper, TestimonialWrapper } from './styles';
import { themeStyle } from '../../services/theming';

interface TestimonialProps {
  image: string;
  name: string;
  title: string;
  text: string | React.ReactNode;
  compact?: boolean;
}

const Testimonial: React.FC<TestimonialProps> = ({
  image,
  name,
  title,
  text,
  compact = false,
}) => {
  const { isMobile } = useWindowSize();
  const imageSize = compact ? 75 : isMobile ? 75 : 110;

  return (
    <TestimonialWrapper imageSize={imageSize} compact={compact}>
      <ProfileImageWrapper imageSize={imageSize}>
        <ProfileImage
          user={{ profileImage: image, name }}
          theme={{ size: `${imageSize}px` }}
        />
      </ProfileImageWrapper>
      <H6 color={themeStyle.varBrandMedium}>{title}</H6>
      <Spacer h="4px" />
      <H2>{name}</H2>
      <Spacer h="24px" />
      <P2 className="text" as="div">
        “{text}“
      </P2>
    </TestimonialWrapper>
  );
};

export default React.memo(Testimonial);
