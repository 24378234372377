import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';

import {
  ColorPickerWrapper,
  SaturationWrapper,
  HueAndNoColorWrapper,
  NoColorSelector,
  AlphaRow,
  ColorPickerButton,
  PaletteLabel,
  ColorSwatches,
} from './styles';
import { CenterChildren, FlexRow } from '../utilities/styles';
import { defaultTheme, colorPickerIconTheme, inputTheme } from './theme';

import Popover from '../Popover/Popover';
import Icon from '../Icon/Icon';
import IconEventWrapper from '../Icon/IconEventWrapper';
import NumberInput from '../NumberInput/NumberInput';
import TextInput from '../TextInput/TextInput';
import InputLabel from '../utilities/InputLabel/InputLabel';

import { COLOR_PICKING, PICK_COLOR } from '../../global/events';
import { hsvToHsl, rgbStringToObj } from '../../utils/editor/colors';
import SliderInput from '../SliderInput/SliderInput';
import ColorSwatch from './ColorSwatch/ColorSwatch';
import Saturation from './Saturation/Saturation';
import { valueInputTheme } from '../TextInput/theme';
import { hueSliderTheme, opacitySliderTheme } from '../Slider/theme';

const ColorPicker = (props) => {
  const theme = { ...defaultTheme, ...props.theme };

  // transform a color to a color object that is used as the state for this component
  const transformColor = (color) => {
    const isEmpty = color === null || color === 'null';
    isEmpty && (color = '#ffffff');
    const colorObject = tinycolor(rgbStringToObj(color));
    return {
      hsv: colorObject.toHsv(),
      hsl: colorObject.toHsl(),
      rgb: colorObject.toRgb(),
      hex: colorObject.toHex(),
      colorString: isEmpty ? null : colorObject.toRgbString(),
      colorStringRgb: colorObject.setAlpha(1).toRgbString(),
      isEmpty,
    };
  };

  const [color, setColor] = useState(transformColor(props.defaultColor));
  const [hasChanged, setHasChanged] = useState(false);
  const [isColorPicking, setIsColorPicking] = useState(false);

  useEffect(() => {
    setColor(transformColor(props.defaultColor));
  }, [props.defaultColor]);

  const handleColorChange = (hsvOrEmpty) => {
    let newColor = {};
    let isEmpty = false;
    let hsv = { h: 0, s: 0, v: 0, a: 1 };
    if (hsvOrEmpty === null) {
      isEmpty = true;
    } else {
      hsv = hsvOrEmpty;
    }
    const colorObject = tinycolor(hsv);
    newColor = {
      hsv,
      hsl: hsvToHsl(hsv),
      rgb: colorObject.toRgb(),
      hex: colorObject.toHex(),
    };
    const colorString = hsvOrEmpty === null ? null : colorObject.toRgbString();
    const colorStringRgb = colorObject.setAlpha(1).toRgbString(); // this allows to set opacity seperate in styles
    setColor({
      ...newColor,
      colorString,
      colorStringRgb,
      isEmpty,
    });
    setHasChanged(true);

    props.onChanging && props.onChanging(colorString);
  };

  const handleChangeHsv = (changedAttributes) => {
    const hsv = { ...color.hsv, ...changedAttributes };
    handleColorChange(hsv);
  };

  const handleChangeRgb = (rgb) => {
    const newRgb = { ...color.rgb, ...rgb };
    const hsv = tinycolor(newRgb).toHsv();
    handleColorChange(hsv);
  };

  const handleChangeAlphaPercent = (percent) => {
    handleChangeHsv({ a: percent / 100 });
  };

  const handleOnAfterChange = (_, close) => {
    if (hasChanged && props.onChanged) {
      const isEmpty = color.isEmpty;
      setHasChanged(false); // reset hasChanged
      props.onChanged(isEmpty ? null : color.colorString);
    }
    if (close) props.onClose && props.onClose();
  };

  const handleRemoveColor = () => {
    const white = tinycolor('hsv(0, 0%, 100%)');
    setColor({
      hsv: { h: 0, s: 0, v: 0, a: 1 },
      hsl: hsvToHsl({ h: 0, s: 0, v: 0, a: 1 }),
      rgb: white.toRgb(),
      hex: white.toHex(),
      colorString: null,
      colorStringRgb: white.setAlpha(1).toRgbString(),
      isEmpty: true,
    });
    props.onChanged(null);
  };

  const onPickColor = (e) => {
    e.stopPropagation();

    if (isColorPicking) {
      setIsColorPicking(false);
      props.dispatch && props.dispatch(COLOR_PICKING, false);
      return;
    }

    props.dispatch && props.dispatch(COLOR_PICKING, true);
    setIsColorPicking(true);

    const pickColor = (pickEvent) => {
      const data = { pickEvent, color: null };
      props.dispatch && props.dispatch(PICK_COLOR, data);

      if (data.color) {
        const tColor = transformColor(data.color);
        setColor(tColor);
        props.onChanged && props.onChanged(tColor.colorString);
      }

      setIsColorPicking(false);
      props.dispatch && props.dispatch(COLOR_PICKING, false);
      document.removeEventListener('click', pickColor);
    };
    document.addEventListener('click', pickColor, { passive: false });
  };

  const colorPicker = (
    <ColorPickerWrapper {...theme} data-testid="color-picker-panel">
      <SaturationWrapper {...theme} data-testid="sl-area">
        <Saturation
          color={{
            hsv: color.hsv,
            isEmpty: color.isEmpty,
          }}
          onChange={handleChangeHsv}
        />
      </SaturationWrapper>
      <HueAndNoColorWrapper>
        {!props.disableNoColor && (
          <NoColorSelector
            data-testid="no-color"
            theme={theme}
            onClick={(e) => {
              e.preventDefault();
              handleRemoveColor();
            }}
            isActive={color.isEmpty && color.colorString === null}
          />
        )}
        <SliderInput
          startValue={color.hsl.h}
          onChanging={(h) => {
            handleChangeHsv({ h });
          }}
          precision={0}
          min={0}
          max={360}
          hasNumberInput={false}
          hideHandle={color.isEmpty}
          theme={hueSliderTheme}
          dataTestId="h-area"
        />
      </HueAndNoColorWrapper>
      <FlexRow alignItems="end">
        <ColorPickerButton
          data-testid="pick-color-globally"
          onClick={onPickColor}
        >
          <IconEventWrapper theme={colorPickerIconTheme} fitParent={true}>
            <CenterChildren>
              <Icon
                theme={colorPickerIconTheme}
                name={'colorPicker'}
                height={'16px'}
                isActive={isColorPicking}
              />
            </CenterChildren>
          </IconEventWrapper>
        </ColorPickerButton>
        {!props.disableAlpha && (
          <AlphaRow>
            <SliderInput
              label="Opacity/Alpha"
              name="alpha"
              unit="%"
              precision={0}
              min={0}
              max={100}
              startValue={color.rgb.a * 100}
              onChanging={(percent) => {
                handleChangeAlphaPercent(percent);
              }}
              hideHandle={color.isEmpty}
              theme={{
                ...opacitySliderTheme,
                width: '56px',
                textAlign: 'center',
              }}
            />
          </AlphaRow>
        )}
      </FlexRow>
      <FlexRow justifyContent={'space-between'} marginTop={'10px'}>
        <InputLabel label="HEX" width="56px" theme={theme}>
          <TextInput
            name="hex"
            value={color.hex}
            theme={{ ...valueInputTheme, ...inputTheme }}
            onChanged={(value) => {
              // tinycolor can transform a wide range of strings into a color object
              const hsv = tinycolor(value).toHsv();
              handleColorChange(hsv);
            }}
          />
        </InputLabel>
        {['r', 'g', 'b'].map((attr) => (
          <InputLabel
            key={attr}
            label={attr.toUpperCase()}
            width="56px"
            theme={theme}
          >
            <NumberInput
              name={attr}
              precision={0}
              theme={inputTheme}
              min={0}
              max={255}
              value={{ value: color.rgb[attr] }}
              onChanging={(value) => {
                const rgb = { [attr]: value };
                handleChangeRgb(rgb);
              }}
              hideArrows
            />
          </InputLabel>
        ))}
      </FlexRow>
      {props.presetColors?.length > 0 && (
        <>
          <PaletteLabel>Document Colors</PaletteLabel>
          <ColorSwatches>
            {props.presetColors.map((preset, index) => {
              let presetObject = {};
              let isEmpty = false;
              let swatchHandleColorChange;
              // Account for no-color
              if (preset === null || preset === 'null') {
                presetObject = tinycolor(rgbStringToObj('rgb(255, 255, 255)'));
                isEmpty = true;
                swatchHandleColorChange = () => handleRemoveColor();
              } else {
                presetObject = tinycolor(rgbStringToObj(preset));
                swatchHandleColorChange = () =>
                  handleColorChange(presetObject.toHsv());
              }
              // Long conditional. Accounts for no-color
              const isActive =
                color.colorString === presetObject.toRgbString() ||
                (isEmpty && color.colorString === null);
              const opacity = presetObject.getAlpha();
              const backgroundColor = presetObject.setAlpha(1).toRgbString();

              return (
                <ColorSwatch
                  key={index}
                  dataTestId={`document-palette--${preset}`}
                  color={{
                    rgb: backgroundColor,
                    opacity,
                    isEmpty: isEmpty,
                  }}
                  isActive={isActive}
                  onClick={() => swatchHandleColorChange()}
                  theme={theme}
                />
              );
            })}
          </ColorSwatches>
        </>
      )}
    </ColorPickerWrapper>
  );

  const onClose = (e) => {
    handleOnAfterChange(e, true);
    props.onClose && props.onClose();
  };

  return (
    <Popover
      dataTestId="color-picker"
      label={props.label ? `${props.label} color` : 'Solid Color'}
      placement={props.placement}
      align={props.alignment}
      content={colorPicker}
      width={'290px'}
      onClose={onClose}
      onMouseLeave={handleOnAfterChange} // `onClose` is not always triggered when clicking outside of the popover
      onToggle={props.onToggle}
      theme={{
        textTransform: 'capitalize',
        padding: '0px',
        headPadding: '12px',
      }}
    >
      <ColorSwatch
        dataTestId={`color-picker-toggler--${color.colorString}`}
        color={{
          rgb: color.colorStringRgb,
          opacity: color.rgb.a,
          isEmpty: color.isEmpty,
        }}
        theme={theme}
      />
    </Popover>
  );
};

ColorPicker.propTypes = {
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
  /**
   * the default color
   */
  defaultColor: PropTypes.string,
  /**
   * a label that is used in the popover of the color picker
   */
  label: PropTypes.string,
  /*
   * is the alpha value editable
   */
  disableAlpha: PropTypes.bool,
  /*
   * is the no-color value editable
   */
  disableNoColor: PropTypes.bool,
  /**
   * an array of preset colors (as a hex or rgb string)
   */
  presetColors: PropTypes.arrayOf(PropTypes.string),
  /**
   * placement of popover
   */
  placement: PropTypes.string,
  /**
   * style of the ColorPicker
   */
  theme: PropTypes.shape({
    fontFamily: PropTypes.string,
    fontWeight: PropTypes.number,
    fontSizeLabel: PropTypes.string,
    fontSizeInput: PropTypes.string,
    lineHeightLabel: PropTypes.string,
    colorLabel: PropTypes.string,
    fontSizeTitle: PropTypes.string,
    lineHeightTitle: PropTypes.string,
    colorTitle: PropTypes.string,
    colorPointer: PropTypes.string,
    colorPanel: PropTypes.string,
    buttonShadow: PropTypes.string,
    activeButtonInnerShadow: PropTypes.string,
  }),
  /**
   * A function that is triggered on a change event. Gets the current value.
   */
  onChanging: PropTypes.func,
  /**
   * A function that is triggered after change events. Gets the current value.
   */
  onChanged: PropTypes.func,
  onClose: PropTypes.func,
  alignment: PropTypes.string,
  onToggle: PropTypes.func,
};

ColorPicker.defaultProps = {
  defaultColor: '#ffffff',
  disableAlpha: false,
  disableNoColor: false,
  presetColors: [],
  placement: 'left',
  alignment: 'start',
  showDocumentPalette: true,
};

export default ColorPicker;
