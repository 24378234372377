import { themeStyle } from '../../services/theming';
import { StyledTheme } from '../../types/StyledComponents';

const defaultTheme: StyledTheme = {
  color: themeStyle.varInkMain,
  fontFamily: themeStyle.fontFamily,
  fontWeight: themeStyle.fontSemiBold,
  fontSize: themeStyle.fontSize14,
  lineHeight: '17px',
  padding: '0px',
  disabledColor: themeStyle.varInkMedium,
  activeColor: themeStyle.varBrandMedium,
  activeDefaultColor: themeStyle.varInkMain,
};

export { defaultTheme };
