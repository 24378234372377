import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Container, StyledToast } from './styles';
import { useToastStore, TOAST_GAP } from '../../stores/toastStore';
import { defaultTheme } from './theme';
import { H4 } from '../utilities/Typography/styles';
import Icon from '../Icon/Icon';
import { FlexRow } from '../utilities/styles';
import { getFadeToastAnimation, getPositionStyles } from './utils';
import { Toast as ToastPropTypes } from '../../propTypes';

export const Toast = ({ theme, toast, error, small }) => {
  const icon = error ? 'notificationCross' : 'notificationTick';

  return (
    <StyledToast {...theme}>
      <FlexRow
        gap="12px"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
      >
        {!small ? (
          <Icon
            name={icon}
            theme={{
              color: !error ? theme.iconColor : theme.errorIconColor,
              secondaryColor: theme.secondaryIconColor,
            }}
            height="100%"
          />
        ) : (
          <span style={{ paddingLeft: '5px' }} />
        )}
        <H4 semi color={theme.color}>
          {toast.label}
        </H4>
      </FlexRow>
    </StyledToast>
  );
};

const ToastWrapper = ({ index, id }) => {
  const toast = useToastStore(
    useCallback((state) => state.queue.find((t) => t.id === id), [id])
  );
  const indexHeight = useToastStore(
    useCallback(
      (state) => {
        return state.queue.reduce((prev, curr, i) => {
          if (i <= index - 1) {
            return prev + curr.theme.height + TOAST_GAP;
          } else return prev;
        }, TOAST_GAP);
      },
      [index]
    )
  );
  const animation = getFadeToastAnimation({
    duration: toast.duration,
    horizontalAlignment: toast.horizontalAlignment,
    verticalAlignment: toast.verticalAlignment,
  });

  const theme = { ...defaultTheme, ...toast.theme };

  const positionStyles = getPositionStyles({
    horizontalAlignment: toast.horizontalAlignment,
    verticalAlignment: toast.verticalAlignment,
  });
  const direction = toast.verticalAlignment === 'bottom' ? -1 : 1;

  return (
    <Wrapper
      translateY={indexHeight * direction + 'px'}
      translateX={toast.horizontalAlignment === 'center' ? '-50%' : 0}
      positionStyles={positionStyles}
    >
      <Container duration={toast.duration} animation={animation}>
        <Toast
          theme={theme}
          toast={toast}
          error={toast.error}
          small={toast.small}
        />
      </Container>
    </Wrapper>
  );
};

Toast.propTypes = {
  theme: PropTypes.object,
  toast: ToastPropTypes,
  error: PropTypes.bool,
  small: PropTypes.bool,
};

ToastWrapper.propTypes = {
  /**
   *  Toast label
   */
  label: PropTypes.string,
  /**
   * Theme object
   */
  theme: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
  }),
  index: PropTypes.number,
  id: PropTypes.number,
};

export default ToastWrapper;
