export const degToRad = (degrees) => degrees * (Math.PI / 180);

export const radToDeg = (rad) => rad / (Math.PI / 180);

export const calcOffset = (offset, angle) => {
  const rad = degToRad(angle);
  const x = Math.cos(rad) * offset;
  const y = Math.sin(rad) * offset;
  return { x, y };
};

export const rotationOffset = ({ x, y }, angle) => {
  const a = degToRad(angle);
  const cos = Math.cos(a);
  const sin = Math.sin(a);

  const resX = cos * x - sin * y;
  const resY = sin * x + cos * y;
  return { x: resX, y: resY };
};

/**
 * return the time in the curve (0..1) in which the curve is tangent to a given angle
 * It rotates the curve in order to have the tangent parallel to x axis (-angle),
 * so the problem is reduced to find the max / min point of the curve. This occurs when
 * the first derivate of a cubic bezier curve is zero. The first derivate is a quadratic equation.
 * @param {paper.Curve} curve to check
 * @param {Number} angle tangent angle, in radians
 * @returns an array with all the times in which the curve is tangent
 */
export const getTimesWithTangent = (curve, angle) => {
  const result = [];

  function addResult(t) {
    if (t >= 0 && t <= 1 && result.indexOf(t) === -1) result.push(t);
  }

  function rotateY(point) {
    return point.y * Math.cos(-angle) + point.x * Math.sin(-angle);
  }

  function compute(...p) {
    const a = 3 * p[3] - 9 * p[2] + 9 * p[1] - 3 * p[0];
    const b = 6 * p[0] - 12 * p[1] + 6 * p[2];
    const c = 3 * p[1] - 3 * p[0];
    if (Math.abs(a) < 1e-12) {
      if (Math.abs(b) > 1e-12) addResult(-c / b);
    } else {
      const b2ac = b * b - 4 * c * a;
      if (b2ac < 0) {
        if (Math.abs(b2ac) < 1e-12) addResult(-b / (2 * a));
      } else {
        const sqrtb2ac = Math.sqrt(b2ac);
        addResult((-b + sqrtb2ac) / (2 * a));
        addResult((-b - sqrtb2ac) / (2 * a));
      }
    }
  }

  compute(
    rotateY(curve.points[0]),
    rotateY(curve.points[1]),
    rotateY(curve.points[2]),
    rotateY(curve.points[3])
  );

  return result;
};

/**
 * get the difference between two angles in degrees
 * from http://blog.lexique-du-net.com/index.php?post/Calculate-the-real-difference-between-two-angles-keeping-the-sign
 * @param {number} oldAngle in degrees
 * @param {number} newAngle in degrees
 * returns the new angle in degrees
 */
export const getAngleDifference = (oldAngle, newAngle) => {
  const difference = oldAngle - newAngle;
  if (difference < -180) {
    return difference + 360;
  }
  if (difference > 180) {
    return difference - 360;
  }
  return difference;
};
