/**
 * This code is basically a "fork" of https://github.com/glenzli/paperjs-offset
 * (with some neat changes)
 */
import paper from '@kittl/paper';

const JOIN_THRESHOLD = 1e-6;
const SMALL_AREA_PERCENTAGE = 1e-3;

/*
  Some errors are caused by adaptiveOffsetCurve not converging
  to the threshold and overflowing the stack. We add a small max iteration
  parameter so that this does not happen.
*/
const MAX_ADAPTIVE_OFFSET_ITERATION = 10;

/**
 * Offset the start/terminal segment of a bezier curve
 * @param segment segment to offset
 * @param curve curve to offset
 * @param handleNormal the normal of the the line formed of two handles
 * @param offset offset value
 */
function offsetSegment(segment, curve, handleNormal, offset) {
  const isFirst = segment.curve === curve;
  // get offset vector
  const offsetVector = curve.getNormalAtTime(isFirst ? 0 : 1).multiply(offset);
  // get offset point
  const point = segment.point.add(offsetVector);
  const newSegment = new paper.Segment(point);
  // handleOut for start segment & handleIn for terminal segment
  const handle = isFirst ? 'handleOut' : 'handleIn';
  newSegment[handle] = segment[handle].add(
    handleNormal.subtract(offsetVector).divide(2)
  );
  return newSegment;
}
/**
 * Adaptive offset a curve by repeatly apply the approximation proposed by Tiller and Hanson.
 * @param curve curve to offset
 * @param offset offset value
 */
function adaptiveOffsetCurve(curve, offset, iteration = 0) {
  const hNormal = new paper.Curve(
    curve.segment1.handleOut.add(curve.segment1.point),
    new paper.Point(0, 0),
    new paper.Point(0, 0),
    curve.segment2.handleIn.add(curve.segment2.point)
  )
    .getNormalAtTime(0.5)
    .multiply(offset);
  const segment1 = offsetSegment(curve.segment1, curve, hNormal, offset);
  const segment2 = offsetSegment(curve.segment2, curve, hNormal, offset);

  if (iteration > MAX_ADAPTIVE_OFFSET_ITERATION) return [segment1, segment2];

  // divide && re-offset
  const offsetCurve = new paper.Curve(segment1, segment2);
  // if the offset curve is not self intersected, divide it
  if (offsetCurve.getIntersections(offsetCurve).length === 0) {
    const threshold = Math.min(Math.abs(offset) / 10, 1);
    const midOffset = offsetCurve
      .getPointAtTime(0.5)
      .getDistance(curve.getPointAtTime(0.5));
    if (Math.abs(midOffset - Math.abs(offset)) > threshold) {
      const subCurve = curve.divideAtTime(0.5);
      if (subCurve != null) {
        return adaptiveOffsetCurve(curve, offset, iteration + 1).concat(
          adaptiveOffsetCurve(subCurve, offset, iteration + 1)
        );
      }
    }
  }
  return [segment1, segment2];
}
/**
 * Create a round join segment between two adjacent segments.
 */
function makeRoundJoin(segment1, segment2, originPoint, radius) {
  const through = segment1.point
    .subtract(originPoint)
    .add(segment2.point.subtract(originPoint))
    .normalize(Math.abs(radius * 0.99)) // reduce the radius a bit to give us some "wiggle" room
    .add(originPoint);
  const arc = new paper.Path.Arc({
    from: segment1.point,
    to: segment2.point,
    through: through,
    insert: false,
  });
  segment1.handleOut = arc.firstSegment.handleOut;
  segment2.handleIn = arc.lastSegment.handleIn;
  return arc.segments.length === 3 ? arc.segments[1] : null;
}
function det(p1, p2) {
  return p1.x * p2.y - p1.y * p2.x;
}
/**
 * Get the intersection point of point based lines
 */
function getPointLineIntersections(p1, p2, p3, p4) {
  const l1 = p1.subtract(p2);
  const l2 = p3.subtract(p4);
  const dl1 = det(p1, p2);
  const dl2 = det(p3, p4);
  return new paper.Point(
    dl1 * l2.x - l1.x * dl2,
    dl1 * l2.y - l1.y * dl2
  ).divide(det(l1, l2));
}
/**
 * Connect two adjacent bezier curve, each curve is represented by two segments,
 * create different types of joins or simply removal redundant segment.
 */
function connectAdjacentBezier(
  segments1,
  segments2,
  origin,
  joinType,
  offset,
  limit
) {
  const curve1 = new paper.Curve(segments1[0], segments1[1]);
  const curve2 = new paper.Curve(segments2[0], segments2[1]);
  const intersection = curve1.getIntersections(curve2);
  const distance = segments1[1].point.getDistance(segments2[0].point);
  if (origin.isSmooth()) {
    segments2[0].handleOut = segments2[0].handleOut.project(origin.handleOut);
    segments2[0].handleIn = segments1[1].handleIn.project(origin.handleIn);
    segments2[0].point = segments1[1].point.add(segments2[0].point).divide(2);
    segments1.pop();
  } else {
    if (intersection.length === 0) {
      if (distance > Math.abs(offset) * 0.1) {
        // connect
        switch (joinType) {
          case 'miter':
            const join = getPointLineIntersections(
              curve1.point2,
              curve1.point2.add(curve1.getTangentAtTime(1)),
              curve2.point1,
              curve2.point1.add(curve2.getTangentAtTime(0))
            );
            // prevent sharp angle
            const joinOffset = Math.max(
              join.getDistance(curve1.point2),
              join.getDistance(curve2.point1)
            );
            if (joinOffset < Math.abs(offset) * limit) {
              segments1.push(new paper.Segment(join));
            }
            break;
          default:
            // case 'round':
            const mid = makeRoundJoin(
              segments1[1],
              segments2[0],
              origin.point,
              offset
            );
            if (mid) {
              segments1.push(mid);
            }
            break;
        }
      } else {
        const handleLength = segments1[1].handleIn.length;
        // It's generally a bug if handle is longer than the distance between segments
        if (handleLength < distance) {
          segments2[0].handleIn = segments1[1].handleIn;
          segments1.pop();
        }
      }
    } else {
      const second1 = curve1.divideAt(intersection[0]);
      if (second1) {
        const join = second1.segment1;
        const second2 = curve2.divideAt(curve2.getIntersections(curve1)[0]);
        join.handleOut = second2
          ? second2.segment1.handleOut
          : segments2[0].handleOut;
        segments1.pop();
        segments2[0] = join;
      } else {
        segments2[0].handleIn = segments1[1].handleIn;
        segments1.pop();
      }
    }
  }
}
/**
 * Connect all the segments together.
 */
function connectBeziers(rawSegments, join, source, offset, limit) {
  const originSegments = source.segments;
  const first = rawSegments[0].slice();
  for (let i = 0; i < rawSegments.length - 1; ++i) {
    connectAdjacentBezier(
      rawSegments[i],
      rawSegments[i + 1],
      originSegments[i + 1],
      join,
      offset,
      limit
    );
  }
  if (source.closed) {
    connectAdjacentBezier(
      rawSegments[rawSegments.length - 1],
      first,
      originSegments[0],
      join,
      offset,
      limit
    );
    rawSegments[0][0] = first[0];
  }
  return rawSegments;
}

function isSameDirection(partialPath, fullPath) {
  const offset1 = partialPath.segments[0].location.offset;
  const offset2 =
    partialPath.segments[
      Math.max(1, Math.floor(partialPath.segments.length / 2))
    ].location.offset;
  const sampleOffset = (offset1 + offset2) / 3;
  const originOffset1 = fullPath.getNearestLocation(
    partialPath.getPointAt(sampleOffset)
  ).offset;
  const originOffset2 = fullPath.getNearestLocation(
    partialPath.getPointAt(2 * sampleOffset)
  ).offset;
  return originOffset1 < originOffset2;
}

/*
  We refer as "loops" to closed circuits in the path that can be traversed without
  having to "jump" over any curve in the path (i.e passing through a self intersection)
*/

/*
  Think of this function as starting from any random loop in the path and declaring it even.
  Then, declaring all of its adjacent loops odd. Then, all of these new odd loops's adjacents, even, and so on.
  This should return two different compound paths whose children are all disjoint islands.
*/
function evenOddSplit(path) {
  // Get the first crossing in the path
  const firstCrossing = path.segments.find((segment) => segment.sibling); // Crossings should always have a reference to a sibling segment

  if (!firstCrossing) return false;

  const [evenLoops, oddLoops] = [[], []];

  function getAllSegmentsInLoop(crossing) {
    const segments = [];

    // Next segment to travel to
    let next = crossing;
    while (next) {
      segments.push(next);
      const { sibling } = next;
      if (sibling) {
        // If the segment is part of a crossing
        if (sibling.index === crossing.index) {
          // The whole loop has been walked, stop
          next = null;
        } else if (segments.length > 1) {
          // We are in the middle of walking the loop. Avoid "jumping" over the crossing
          segments[segments.length - 1] = new paper.Segment(
            next.point,
            next.handleIn,
            sibling.handleOut
          );

          segments[segments.length - 1].pathSegment = next;
          next = sibling.next;
          sibling.processed = true;
        } else {
          next = next.next;
        }
      } else {
        next = next.next;
      }
    }

    return segments;
  }

  function split(crossing, evenLoops, oddLoops, isEven = true) {
    if (crossing.processed) return;
    crossing.processed = true;

    const loop = getAllSegmentsInLoop(crossing);
    for (let i = 0; i < loop.length; i++) {
      const segment = loop[i].pathSegment || loop[i];
      if (segment.sibling && !segment.processed) {
        split(segment, evenLoops, oddLoops, !isEven);
      }
    }

    const list = isEven ? evenLoops : oddLoops;
    const loopPath = new paper.Path(loop);
    loopPath.join(loopPath, JOIN_THRESHOLD * loopPath.length);
    list.push(loopPath);
  }

  split(firstCrossing, evenLoops, oddLoops);
  return {
    evenLoops: new paper.CompoundPath({ children: evenLoops }),
    oddLoops: new paper.CompoundPath({ children: oddLoops }),
  };
}

/*
  Removes loops that are deemed bad.
  i.e loops that are a product of self intersections after offseting
*/
function removeBadLoops(path) {
  if (!path.closed) return path;

  path = path.reduce({});
  const crossings = path.getCrossings();

  /*
    Divide the path at every self crossing, both at t0 and t1, 
    where t0 and t1 are the two times that it takes to get to the crossing respectively
  */
  for (let i = 0; i < crossings.length; i++) {
    const crossing = crossings[i];
    const { intersection } = crossing;

    // These are the segments that are created when dividing
    const firstDivision = path.divideAt(crossing.offset);
    const secondDivision = path.divideAt(intersection.offset);

    /* 
      Sometimes the path cannot be divided. Happens in some weird cases where
      where the crossing is a false positive due to a messy path
    */
    if (!firstDivision || !secondDivision) continue;

    // The two segments are said to be siblings so we can have access to them from the other later on
    firstDivision.sibling = secondDivision;
    secondDivision.sibling = firstDivision;
  }

  const evenOddLoops = evenOddSplit(path);
  if (!evenOddLoops) return path;

  /*
    To decide which one of the two sets of paths we'll select, we make use of the idea
    that self intersections after offsetting produce loops of small area in comparison to the rest of the loops.

    This is not a general, exact solution. But it works reliably, and even when using extreme transforms I wasn't able
    to break it.
  */

  const { evenLoops, oddLoops } = evenOddLoops;

  let goodLoops;
  let badLoops;

  if (Math.abs(evenLoops.area) > Math.abs(oddLoops.area)) {
    goodLoops = evenLoops;
    badLoops = oddLoops;
  } else {
    goodLoops = oddLoops;
    badLoops = evenLoops;
  }

  /*
    Now, we know that all of the loops in badLoops should be discarded.
    But there might be false positives in goodLoops. These are loops that are created
    after the bad loops themselves start to have self intersections.

    Also, after previous processing of the path (I suspect connectBeziers), 
    points with high curvatures create really small loops that most times are directly connected
    to bad loops, but are not inside these. So we also filter by very small area. This could also help
    get rid of very small parts of the offset path when using text transforms, for a bit of a better result.
  */

  const finalPaths = goodLoops.children.slice().filter((loop) => {
    const closestBadLoop = badLoops.getNearestLocation(loop.firstSegment.point);
    return (
      !closestBadLoop.path.contains(loop.interiorPoint) &&
      Math.abs(loop.area) > SMALL_AREA_PERCENTAGE * Math.abs(goodLoops.area)
    );
  });

  return new paper.CompoundPath({ children: finalPaths });
}

function preparePath(path, offset) {
  let source = path.clone({ insert: false });
  source = source.reduce({});
  if (!path.clockwise) {
    source.reverse();
    offset = -offset;
  }

  /*
    When paths are not joined, offsetting can glitch at the end of the path
    and cause weird behavior. Here the tolerance for joining is chosen based solely on testing.
  */
  source.join(source, JOIN_THRESHOLD * path.length);

  return [source, offset];
}

function offsetSimpleShape(path, offset, join, limit) {
  const [source, offset2] = preparePath(path, offset);
  const curves = source.curves.slice();
  const offsetCurves = curves
    .map((curve) => {
      return adaptiveOffsetCurve(curve, offset2);
    })
    .flat();
  const raws = [];
  for (let i = 0; i < offsetCurves.length; i += 2) {
    raws.push(offsetCurves.slice(i, i + 2));
  }

  const segments = connectBeziers(raws, join, source, offset2, limit).flat();
  let newPath = new paper.Path({
    segments: segments,
    insert: false,
    closed: path.closed,
  });
  newPath = removeBadLoops(newPath);

  // recovery path
  if (source.clockwise !== path.clockwise) {
    newPath.reverse();
  }
  return newPath;
}

function makeRoundCap(from, to, offset) {
  const origin = from.point.add(to.point).divide(2);
  const normal = to.point
    .subtract(from.point)
    .rotate(-90, new paper.Point(0, 0))
    .normalize(offset);
  const through = origin.add(normal);
  const arc = new paper.Path.Arc({
    from: from.point,
    to: to.point,
    through: through,
    insert: false,
  });
  return arc.segments;
}

function connectSide(outer, inner, offset, cap) {
  if (outer instanceof paper.CompoundPath) {
    let cs = outer.children.map((c) => {
      return { c: c, a: Math.abs(c.area) };
    });
    cs = cs.sort((c1, c2) => {
      return c2.a - c1.a;
    });
    outer = cs[0].c;
  }
  const oSegments = outer.segments.slice();
  const iSegments = inner.segments.slice();
  switch (cap) {
    case 'round':
      const heads = makeRoundCap(
        iSegments[iSegments.length - 1],
        oSegments[0],
        offset
      );
      const tails = makeRoundCap(
        oSegments[oSegments.length - 1],
        iSegments[0],
        offset
      );
      const result = new paper.Path({
        segments: heads.concat(oSegments, tails, iSegments),
        closed: true,
        insert: false,
      });
      result.reduce({});
      return result;
    default:
      return new paper.Path({
        segments: oSegments.concat(iSegments),
        closed: true,
        insert: false,
      });
  }
}

function offsetSimpleStroke(path, offset, join, cap, limit) {
  offset = path.clockwise ? offset : -offset;
  const positiveOffset = offsetSimpleShape(path, offset, join, limit);
  const negativeOffset = offsetSimpleShape(path, -offset, join, limit);
  if (path.closed) {
    return positiveOffset.subtract(negativeOffset, { insert: false });
  } else {
    let inner = negativeOffset;
    let holes = [];
    if (negativeOffset instanceof paper.CompoundPath) {
      holes = negativeOffset.children.filter((c) => {
        return c.closed;
      });
      holes.forEach((h) => {
        return h.remove();
      });
      inner = negativeOffset.children[0];
    }
    inner.reverse();
    let final = connectSide(positiveOffset, inner, offset, cap);
    if (holes.length > 0) {
      for (let _i = 0, holes_1 = holes; _i < holes_1.length; _i++) {
        const hole = holes_1[_i];
        final = final.subtract(hole, { insert: false });
      }
    }
    return final;
  }
}

function getNonSelfIntersectionPath(path) {
  if (path.closed) {
    return path.unite(path, { insert: false });
  }
  return path;
}

function offsetPath(path, offset, join, limit, skipDirectionCheck) {
  path = path.reduce();
  const nonSIPath = getNonSelfIntersectionPath(path);
  let result = nonSIPath;
  if (nonSIPath instanceof paper.Path) {
    result = offsetSimpleShape(nonSIPath, offset, join, limit);
  } else {
    const offsetParts = nonSIPath.children.map((c) => {
      if (Math.abs(c.area) < SMALL_AREA_PERCENTAGE * Math.abs(nonSIPath.area))
        return null;
      if (c.segments.length > 1) {
        if (!skipDirectionCheck && !isSameDirection(c, path)) {
          c.reverse();
        }
        const offseted = offsetSimpleShape(c, offset, join, limit);
        if (offseted.clockwise !== c.clockwise) {
          offseted.reverse();
        }
        if (offseted instanceof paper.CompoundPath) {
          offseted.applyMatrix = true;
          return offseted.children;
        } else {
          return offseted;
        }
      } else {
        return null;
      }
    });
    const children = offsetParts.flat().filter((c) => {
      return !!c;
    });
    result = new paper.CompoundPath({ children: children, insert: false });
  }
  result.copyAttributes(nonSIPath, false);
  result.remove();
  return result;
}

function offsetStroke(path, offset, join, cap, limit) {
  const nonSIPath = getNonSelfIntersectionPath(path);
  let result = nonSIPath;
  if (nonSIPath instanceof paper.Path) {
    result = offsetSimpleStroke(nonSIPath, offset, join, cap, limit);
  } else {
    const children = nonSIPath.children.flatMap((c) => {
      return offsetSimpleStroke(c, offset, join, cap, limit);
    });
    result = children.reduce((c1, c2) => {
      return c1.unite(c2, { insert: false });
    });
  }
  result.strokeWidth = 0;
  result.fillColor = nonSIPath.strokeColor;
  result.shadowBlur = nonSIPath.shadowBlur;
  result.shadowColor = nonSIPath.shadowColor;
  result.shadowOffset = nonSIPath.shadowOffset;
  return result;
}

const PaperOffset = /** @class */ (function () {
  function PaperOffset() {}
  PaperOffset.offset = function (path, offset, options) {
    options = options || {};
    const newPath = offsetPath(
      path,
      offset,
      options.join || 'miter',
      options.limit === undefined ? 10 : options.limit,
      options.skipDirectionCheck
    );
    if (options.insert === undefined) {
      options.insert = true;
    }
    if (options.insert) {
      (path.parent || paper.project.activeLayer).addChild(newPath);
    }
    return newPath;
  };
  PaperOffset.offsetStroke = function (path, offset, options) {
    options = options || {};
    const newPath = offsetStroke(
      path,
      offset,
      options.join || 'miter',
      options.cap || 'butt',
      options.limit === undefined ? 10 : options.limit
    );
    if (options.insert === undefined) {
      options.insert = true;
    }
    if (options.insert) {
      (path.parent || paper.project.activeLayer).addChild(newPath);
    }
    return newPath;
  };
  return PaperOffset;
})();

/**
 * EXTEND existing paper module
 * @deprecated
 */
function ExtendPaperJs(paper) {
  paper.Path.prototype.offset = function (offset, options) {
    return PaperOffset.offset(this, offset, options);
  };
  paper.Path.prototype.offsetStroke = function (offset, options) {
    return PaperOffset.offsetStroke(this, offset, options);
  };
  paper.CompoundPath.prototype.offset = function (offset, options) {
    return PaperOffset.offset(this, offset, options);
  };
  paper.CompoundPath.prototype.offsetStroke = function (offset, options) {
    return PaperOffset.offsetStroke(this, offset, options);
  };
}

export default ExtendPaperJs;
export { PaperOffset };
