import React, { useCallback } from 'react';
import { CommentMentionNotification as NotificationType } from '../../../../types';

import { WEBSITE_URL } from '../../../../utils/url';
import NotificationHeader from '../NotificationHeader/NotificationHeader';
import { NotificationWrapper } from './styles';
import { NotificationProps } from './types';

const CommentMentionNotification: React.FC<
  NotificationProps<NotificationType>
> = ({ notification, onReadNotification }) => {
  const handleClick = useCallback((): void => {
    onReadNotification(notification.id);
    if (notification.relatedUser) {
      window.open(
        `${WEBSITE_URL || ''}/${notification.data.path}`,
        WEBSITE_URL ? '_blank' : '_self'
      );
    }
  }, [onReadNotification, notification]);

  return (
    <NotificationWrapper role="button" onClick={handleClick}>
      <NotificationHeader
        user={notification.relatedUser}
        message={
          <>
            mentioned you in <b>{notification.data.name}</b>
          </>
        }
        createdAt={notification.createdAt}
        isUnseen={!notification.seenAt}
      />
    </NotificationWrapper>
  );
};

export default CommentMentionNotification;
