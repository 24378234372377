import { useRef } from 'react';
import { MoreWrapper, Wrapper } from './styles';
import { OptionRowWidgetProps, Option } from './types';
import IconButton from '../../../Button/IconButton';
import themeStyle from '../../../../global/themeStyle';

// Gap between the widget and the context menu to the right
const CONTEXT_MENU_GAP = 12;

const OptionRowWidget: React.FC<OptionRowWidgetProps> = (props) => {
  const { options, iconHeight, dispatch, canvas, object } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);

  const contextMenuOpen = useRef(false);

  const onMore = (): void => {
    if (!contextMenuOpen.current) {
      const boundingRect = wrapperRef.current?.getBoundingClientRect();

      if (boundingRect) {
        const { left, top, width } = boundingRect;

        props.canvasContainer.dispatchEvent(
          new MouseEvent('contextmenu', {
            clientX: left + width + CONTEXT_MENU_GAP,
            clientY: top,
            screenX: left + width + CONTEXT_MENU_GAP,
            screenY: top,
          })
        );
      }
    } else {
      props.canvasContainer.dispatchEvent(
        new MouseEvent('closeRightClickMenu')
      );
    }

    contextMenuOpen.current = !contextMenuOpen.current;
  };

  return (
    <Wrapper ref={wrapperRef}>
      {options.map((option: Option) => {
        return (
          <IconButton
            theme={{
              activeColor: themeStyle.grey700,
              color: themeStyle.grey500,
            }}
            height={iconHeight}
            iconName={option.icon}
            ariaLabel="Widget Option"
            onClick={(): void => option.onClick(dispatch, canvas, object)}
          />
        );
      })}

      <MoreWrapper height={props.iconHeight} onClick={onMore}>
        <IconButton
          height="4px"
          iconName="dots"
          ariaLabel="Widget Option"
          theme={{ activeColor: themeStyle.grey700, color: themeStyle.grey500 }}
        />
      </MoreWrapper>
    </Wrapper>
  );
};

export default OptionRowWidget;
