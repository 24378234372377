import { State } from './types';

export const authenticationStoreSelector = {
  isOpen: (state: State): State['isOpen'] => state.isOpen,
  options: (state: State): State['options'] => state.options,
  openAuthentication: (state: State): State['openAuthentication'] =>
    state.openAuthentication,
  closeAuthentication: (state: State): State['closeAuthentication'] =>
    state.closeAuthentication,
};
