import { useCallback, useEffect, useRef, useState } from 'react';

import { P4 } from '../../utilities/Typography/styles';
import { buildImageProxyUrl } from '../../../utils/url';
import {
  BorderOverlay,
  Gradient,
  Image,
  ImageWrapper,
  LabelWrapper,
} from './styles';
import { StyleElementProps } from './types';
import { themeStyle } from '../../../services/theming';

const StyleElement: React.FC<StyleElementProps> = (props) => {
  const { element, onSelect } = props;

  const [hovered, setHovered] = useState<boolean>(false);
  const ref = useRef<null | HTMLDivElement>(null);

  const select = useCallback(() => {
    onSelect(element);
  }, [onSelect, element]);

  const hover = useCallback(() => {
    setHovered(true);
  }, []);

  const unHover = useCallback(() => {
    setHovered(false);
  }, []);

  useEffect(() => {
    if (props.active && ref.current?.scrollIntoView) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  }, [props.active]);

  return (
    <ImageWrapper
      onClick={select}
      key={element.id}
      width={element.elementWidth}
      height={element.elementHeight}
      ref={ref}
    >
      <Image src={buildImageProxyUrl(`api/${element.preview}`)} />
      <Gradient visible={hovered || props.active} />
      <LabelWrapper visible={hovered || props.active}>
        <P4 color={themeStyle.inkBase}>{element.name}</P4>
      </LabelWrapper>
      <BorderOverlay
        active={props.active}
        onPointerEnter={hover}
        onPointerLeave={unHover}
      />
    </ImageWrapper>
  );
};

export default StyleElement;
