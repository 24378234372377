import { CompoundPath } from '@kittl/paper';

import DistortTransform from './DistortTransform';
import { fixPathOrientation } from '../../../font/util';

export const getControlVector = (
  transformedBounds,
  topLeftPointOnTransformConvex,
  { flipX, flipY }
) => {
  const controlPoint = {
    x: transformedBounds.x + (flipX ? transformedBounds.width : 0),
    y: transformedBounds.y + (flipY ? transformedBounds.height : 0),
  };

  return {
    x: topLeftPointOnTransformConvex.x - controlPoint.x,
    y: topLeftPointOnTransformConvex.y - controlPoint.y,
  };
};

export default function (paths, { points }, { bounds, flipX, flipY }) {
  const distortTransform = new DistortTransform({
    points,
    bounds,
    flipX,
    flipY,
  });

  // Here we extract all coordinates from path string, e.g., for `M 10 1.234e16`,
  // 10 and 1.234e16 are two matches mapping to x and y that will be transformed.
  const coordinatesPattern = /([-eE\d]+\.?[-eE\d]*)\s([-eE\d]+\.?[-eE\d]*)/gm;
  const transformedPaths = paths
    .map((path) => {
      const transformedPath = new CompoundPath(
        path.replace(coordinatesPattern, (match) =>
          distortTransform.getTransformedCoordinatesString(match)
        )
      );

      // While initiatizing, `getPaths` is called, where orientation is fixed for text without transformation.
      // So it's necessary to fix the orientation after transformation.
      fixPathOrientation(transformedPath);

      return transformedPath.pathData;
    })
    .join(' ');

  const transformedPathsBounds = new CompoundPath({
    pathData: transformedPaths,
    insert: false,
  }).bounds;

  return {
    path: transformedPaths,
    controlVector: getControlVector(
      transformedPathsBounds,
      distortTransform.points[0],
      { flipX, flipY }
    ),
    bounds: {
      minX: transformedPathsBounds.x,
      maxX: transformedPathsBounds.right,
      minY: -transformedPathsBounds.y,
      maxY: -transformedPathsBounds.bottom,
    },
  };
}
