import { useEffect, useState } from 'react';

import { SubscriptionPlan, SubscriptionPlanInterval } from '../../../types';

type PriceId = string | null;

/**
 * This hook gets the price id given the subscription plan and interval.
 * @param plan
 * @param interval
 */
export const usePriceId = (
  plan: SubscriptionPlan | null,
  interval: SubscriptionPlanInterval | null
): PriceId => {
  const [priceId, setPriceId] = useState<PriceId>(null);

  useEffect((): void => {
    if (
      plan === SubscriptionPlan.PRO &&
      interval === SubscriptionPlanInterval.MONTH
    ) {
      setPriceId(process.env.REACT_APP_PRO_PRICE!);
    } else if (
      plan === SubscriptionPlan.PRO &&
      interval === SubscriptionPlanInterval.YEAR
    ) {
      setPriceId(process.env.REACT_APP_PRO_PRICE_YEARLY!);
    } else if (
      plan === SubscriptionPlan.EXPERT &&
      interval === SubscriptionPlanInterval.MONTH
    ) {
      setPriceId(process.env.REACT_APP_EXPERT_PRICE!);
    } else if (
      plan === SubscriptionPlan.EXPERT &&
      interval === SubscriptionPlanInterval.YEAR
    ) {
      setPriceId(process.env.REACT_APP_EXPERT_PRICE_YEARLY!);
    }
  }, [plan, interval]);

  return priceId;
};
