import { CompoundPath, Point } from '@kittl/paper';

import applyTransform from './utils/transformation/applyTransform';
import { CURVED_TRANSFORMS, ROTATION_TRANSFORMS } from '../transformConfig';
import { getFlippedPointInBounds } from '../../../../utils/geometry/point';
import {
  moveTo,
  lineTo,
  cubicCurveTo,
  closePath,
} from '../../../../utils/path/commands';
import {
  createFreeFormCurves,
  createTriSegmentCurve,
  getWarpCurveBbox,
} from './utils/transformation';
import {
  getInterpolatedCurve,
  getParallelogramPath,
  getPathBetweenCurves,
} from './utils/decoration';
import {
  getInterpolatedPolygon,
  getInterpolatedRect,
} from '../../../../utils/editor/shapes';
import { rotatePointAroundSinglePoint } from '../../../../utils/editor/points';

// fading color cut config
export const FADING_COLOR_CUT = {
  getGapHeight: (i, weight) => 0.0065 * (6 - i) * (0.1 + weight * 10) * 2,
  getRectHeight: (i, weight) => 0.0065 * i * (0.1 + weight * 10) * 2,
  getSize: (weight) => 0.195 * (0.1 + weight * 10) * 2,
};

export default function (fabric) {
  fabric.util.object.extend(fabric.PathText.prototype, {
    /**
     * get the offset the decorationObject should have in the pathText group
     * this is done by comparing the controlVector of the decoration object and
     * the controlVector of the pathText
     * @param {*} controlVector
     * @param {*} bounds
     */
    getTransformedDecorationOffset(controlVector, bounds) {
      if (this._controlVector && this._transformedBounds) {
        // the offset is the difference between the controlVectors
        // in case there is a flip, the controlVectors need to be cleaned from
        // that flip, since the decoration object is flipped by the group it is in
        let offsetX = this._controlVector.x - controlVector.x;
        if (this.flipX) {
          offsetX +=
            this._transformedBounds.maxX - this._transformedBounds.minX;
          offsetX += bounds.minX - bounds.maxX;
        }
        let offsetY = this._controlVector.y - controlVector.y;
        if (this.flipY) {
          offsetY +=
            -this._transformedBounds.maxY + this._transformedBounds.minY;
          offsetY += bounds.maxY - bounds.minY;
        }

        return { offsetX, offsetY };
      }
      return { offsetX: 0, offsetY: 0 };
    },

    /**
     * transform the path of a decoration
     * @param {String} path
     */
    transformDecorationPath(path) {
      const options = {
        ...this.getApplyTransformOptions(),
        decoration: true,
      };
      const transformOptions = this.getNeutralTransformOptions();
      const {
        path: transformedPath,
        bounds: transformedBounds,
        controlVector,
      } = applyTransform(path, transformOptions, options);
      path = transformedPath;

      const { offsetX, offsetY } = this.getTransformedDecorationOffset(
        controlVector,
        transformedBounds
      );

      return { path, offsetX, offsetY };
    },

    /**
     * applies a decoration and transforms it for the current path
     * similar to 'transformDecorationPath', but this one is used for
     * 'freeLine' and 'circle' transforms, since for them we cannot simply
     * transform points of a decoration path, but need to know the bounds of
     * every single glyph to transform the decoration by
     * @param {String} decoration type of the decoration
     * @param {*} decorationOptions
     */
    decorateAndTransformPath(decoration, decorationOptions) {
      const options = {
        ...this.getApplyTransformOptions(),
        decoration,
        ...decorationOptions,
      };
      const transformOptions = this.getNeutralTransformOptions();
      const {
        path: transformedPath,
        bounds: transformedBounds,
        controlVector,
      } = applyTransform(this._paths, transformOptions, options);

      const { offsetX, offsetY } = this.getTransformedDecorationOffset(
        controlVector,
        transformedBounds
      );

      return { path: transformedPath, offsetX, offsetY };
    },

    updateDecorationPath: function () {
      const type = this.decorationOptions?.type;
      const needToUpdateText = type && this._pathTextWasChangedAfterUpdate;

      if (needToUpdateText) {
        // run `updateText` before updating decoration path, which will call
        // `updateDecorationPath` again with `_pathTextWasChangedAfterUpdate` being `false`
        this.updateText();
        return;
      }

      this.remove(this.decorationPath);
      delete this.decorationPath;

      if (!type) return;

      let decorationPath;

      if (type === 'colorCut') {
        decorationPath = this.getColorCutRect(this.decorationOptions);
      } else if (type === 'fadingColorCut') {
        decorationPath = this.getFadingColorCutRect(this.decorationOptions);
      } else if (type === 'horizontalLines') {
        decorationPath = this.getHorizontalLines(this.decorationOptions);
      } else if (type === 'obliqueLines') {
        decorationPath = this.getObliqueLines(this.decorationOptions);
      }

      this.decorationPath = decorationPath;

      // Insert decorationPath right above fillRect, but below pathText
      this.insertAt(this.decorationPath, 1);
    },

    getColorCutRect: function (options) {
      const { color, cutDistance: offset, weight } = options;

      let path = '';
      let top = -this.height / 2;
      let left = -this.width / 2;
      let strokeWidth = 0;

      const transformType = this.transformOptions?.type;

      if (transformType === 'freeForm') {
        ({ path, top, left } =
          this.getColorCutDecorationWithDistortTransform(offset));
      } else if (transformType === 'angle') {
        ({ path, top, left } =
          this.getColorCutDecorationWithAngledTransform(offset));
      } else if (CURVED_TRANSFORMS.includes(transformType)) {
        ({ path, top, left } =
          this.getColorCutDecorationWithCurvedTransform(offset));
      } else if (ROTATION_TRANSFORMS.includes(transformType)) {
        const {
          path: transformedPath,
          offsetX,
          offsetY,
        } = this.decorateAndTransformPath('colorCut', {
          offset,
          weight: weight,
        });
        path = transformedPath;

        // the path subtraction of paper does not give us a perfect path to overlay
        // the text path. So a stroke is added to hide that. And the position is corrected
        // by strokeWidth/2
        strokeWidth = 0.4; // 0.4 seems like a good balance between having no lines and a correct decoration

        // get top/left relative to center (no angle needed) and apply offset
        top = -this.height / 2 + offsetY - strokeWidth / 2;
        left = -this.width / 2 + offsetX - strokeWidth / 2;
      } else {
        const hasTransform = !!this.transformOptions?.type;
        const height = this._bounds.maxY - this._bounds.minY;
        const width = hasTransform
          ? this._curveLength || this._bounds.maxX - this._bounds.minX
          : this.width;

        path = this.getColorCutPath(
          width,
          height,
          offset,
          this._bounds,
          hasTransform
        );

        if (this.transformOptions?.type) {
          const {
            path: transformedPath,
            offsetX,
            offsetY,
          } = this.transformDecorationPath(path);
          path = transformedPath;

          // get top/left relative to center (no angle needed) and apply offset
          top = -this.height / 2 + offsetY;
          left = -this.width / 2 + offsetX;
        }
      }

      return new fabric.Path(path, {
        originX: 'left',
        originY: 'top',
        top,
        left,
        width: this.width,
        height: this.height,
        fill: color,
        stroke: color,
        strokeWidth,
      });
    },

    getFadingColorCutRect: function (options) {
      const { color, cutDistance: offset, weight } = options;

      let path = '';
      let top = -this.height / 2;
      let left = -this.width / 2;
      let strokeWidth = 0;

      const transformType = this.transformOptions?.type;

      if (transformType === 'freeForm') {
        const decoration = this.getFadingColorCutDecorationWithDistortTransform(
          {
            weight,
            distance: offset,
          }
        );
        path = decoration.path;
        top = decoration.top;
        left = decoration.left;
      } else if (transformType === 'angle') {
        const decoration = this.getFadingColorCutDecorationWithAngledTransform({
          weight,
          distance: offset,
        });
        path = decoration.path;
        top = decoration.top;
        left = decoration.left;
      } else if (CURVED_TRANSFORMS.includes(transformType)) {
        const decoration = this.getFadingColorCutDecorationWithCurvedTransform({
          weight,
          distance: offset,
        });
        path = decoration.path;
        top = decoration.top;
        left = decoration.left;
      } else if (ROTATION_TRANSFORMS.includes(transformType)) {
        const {
          path: transformedPath,
          offsetX,
          offsetY,
        } = this.decorateAndTransformPath('fadingColorCut', {
          offset,
          weight: weight,
        });
        path = transformedPath;

        // the path subtraction of paper does not give us a perfect path to overlay
        // the text path. So a stroke is added to hide that. And the position is corrected
        // by strokeWidth/2
        strokeWidth = 0.4; // 0.4 seems like a good balance between having no lines and a correct decoration

        // get top/left relative to center (no angle needed) and apply offset
        top = -this.height / 2 + offsetY - strokeWidth / 2;
        left = -this.width / 2 + offsetX - strokeWidth / 2;
      } else {
        const hasTransform = !!this.transformOptions?.type;
        const height = this._bounds.maxY - this._bounds.minY;
        const width = hasTransform
          ? this._curveLength || this._bounds.maxX - this._bounds.minX
          : this.width;

        path = this.getFadingColorCutPath(
          width,
          height,
          offset,
          this._bounds,
          hasTransform,
          weight
        );

        if (this.transformOptions?.type) {
          const {
            path: transformedPath,
            offsetX,
            offsetY,
          } = this.transformDecorationPath(path);
          path = transformedPath;

          // get top/left relative to center (no angle needed) and apply offset
          top = -this.height / 2 + offsetY;
          left = -this.width / 2 + offsetX;
        }
      }

      return new fabric.Path(path, {
        originX: 'left',
        originY: 'top',
        top,
        left,
        width: this.width,
        height: this.height,
        fill: color,
        stroke: color,
        strokeWidth,
      });
    },

    /**
     * get a path used for the colorCut decoration
     */
    getColorCutPath: function (width, height, offset, bounds, hasTransform) {
      const absoluteOffset = offset * height;

      // using an interpolated rect is a quickfix for new transforms, that work without extra interpolation
      return getInterpolatedRect(width, absoluteOffset, {
        x: hasTransform ? bounds.minX : 0,
        y: -bounds.maxY,
      });
    },

    /**
     * get a path used for the fadingColorCut decoration
     */
    getFadingColorCutPath: function (
      width,
      height,
      offset,
      bounds,
      hasTransform,
      weight
    ) {
      let path = '';
      let y = height * (offset - FADING_COLOR_CUT.getSize(weight) / 2);

      if (y > 0) {
        path += getInterpolatedRect(width, y, {
          x: hasTransform ? bounds.minX : 0,
          y: -bounds.maxY,
        });
      }

      for (let i = 1; i <= 5; i++) {
        const gap = FADING_COLOR_CUT.getGapHeight(i, weight) * height;
        const rectHeight = FADING_COLOR_CUT.getRectHeight(i, weight) * height;
        y += rectHeight;

        if (!(y > height || y + gap < 0)) {
          path += getInterpolatedRect(width, gap + Math.min(0, y), {
            x: hasTransform ? bounds.minX : 0,
            y: -bounds.maxY + Math.max(0, y),
          });
        }

        y += gap;
      }

      return path;
    },

    getObliqueLines: function (opts) {
      const options = opts || this.decorationOptions;
      const { color, distance, weight } = options;

      let path = '';
      const top = -this.height / 2;
      const left = -this.width / 2;
      const strokeWidth = 0;

      const bounds = { ...(this._transformedBounds || this._bounds) };
      if (this._transformedBounds) {
        const maxY = bounds.maxY;
        const minY = bounds.minY;
        bounds.maxY = Math.max(maxY, minY);
        bounds.minY = Math.min(maxY, minY);
      }
      const hasTransform = !!this.transformOptions?.type;
      const height = bounds.maxY - bounds.minY;
      const width = hasTransform
        ? this._curveLength || bounds.maxX - bounds.minX
        : this.width;
      const absoluteDistance = distance * height;
      const absoluteWeight = weight * height;
      const increment = absoluteDistance + absoluteWeight;

      const xStart = hasTransform ? bounds.minX : 0;
      const xEnd = xStart + width;
      const yStart = -bounds.maxY;
      const yEnd = -bounds.minY;

      for (let x = xStart; x <= xEnd; x += increment) {
        // TR is the intersection of the oblique line starting at TL with the right line of the bounding box
        const xTr = Math.min(yEnd - yStart + x, xEnd);
        const yTr = yStart + xTr - x;

        // BR is the intersection of the oblique line starting at BL with the right line of the bounding box
        const xBr = Math.min(yEnd - yStart + x + absoluteWeight, xEnd);
        const yBr = yStart + xBr - Math.min(x + absoluteWeight, xEnd);

        path += getInterpolatedPolygon(
          { x, y: yStart },
          { x: xTr, y: yTr },
          { x: Math.min(x + absoluteWeight, xEnd), y: yStart },
          { x: xBr, y: yBr },
          1
        );
      }

      for (let y = yStart + absoluteDistance; y <= yEnd; y += increment) {
        // TR is the intersection of the oblique line starting at TL with the bottom line of the bounding box
        const yTr = Math.min(y + xEnd, yEnd);
        const xTr = yTr - y + xStart;

        // BR is the intersection of the oblique line starting at BL with the bottom line of the bounding box
        const yBr = Math.min(y + absoluteWeight + xEnd, yEnd);
        const xBr = yBr - Math.min(y + absoluteWeight, yEnd) + xStart;

        path += getInterpolatedPolygon(
          { x: xStart, y: y }, // TL
          { x: xTr, y: yTr },
          { x: xStart, y: Math.min(y + absoluteWeight, yEnd) }, // BL
          { x: xBr, y: yBr },
          1
        );
      }

      return new fabric.Path(path, {
        originX: 'left',
        originY: 'top',
        top,
        left,
        width: this.width,
        height: this.height,
        fill: color,
        stroke: color,
        strokeWidth,
      });
    },

    getHorizontalLines: function (options) {
      const { color, distance, weight } = options;
      const width = this.width;
      const height = this.height;
      let path = '';
      let top = -this.height / 2;
      let left = -this.width / 2;
      let strokeWidth = 0;

      const transformType = this.transformOptions?.type;

      if (transformType === 'freeForm') {
        const decoration = this.getLineDecorationWithDistortTransform({
          weight,
          distance,
        });
        path = decoration.path;
        top = decoration.top;
        left = decoration.left;
      } else if (transformType === 'angle') {
        const decoration = this.getLineDecorationWithAngledTransform({
          weight,
          distance,
        });
        path = decoration.path;
        top = decoration.top;
        left = decoration.left;
      } else if (CURVED_TRANSFORMS.includes(transformType)) {
        const decoration = this.getLineDecorationWithCurvedTransform({
          weight,
          distance,
        });
        path = decoration.path;
        top = decoration.top;
        left = decoration.left;
      } else if (ROTATION_TRANSFORMS.includes(transformType)) {
        const {
          path: transformedPath,
          offsetX,
          offsetY,
        } = this.decorateAndTransformPath('horizontalLines', {
          distance,
          weight,
        });
        path = transformedPath;

        // the path subtraction of paper does not give us a perfect path to overlay
        // the text path. So a stroke is added to hide that. And the position is corrected
        // by strokeWidth/2
        strokeWidth = 0.4; // 0.4 seems like a good balance between having no lines and a correct decoration

        // get top/left relative to center (no angle needed) and apply offset
        top = -this.height / 2 + offsetY - strokeWidth / 2;
        left = -this.width / 2 + offsetX - strokeWidth / 2;
      } else {
        path = this.getLineDecorationPathWithNoTransform({ weight, distance });
      }

      return new fabric.Path(path, {
        originX: 'left',
        originY: 'top',
        top,
        left,
        width,
        height,
        fill: color,
        stroke: color,
        strokeWidth,
      });
    },

    getLineDecorationPathWithNoTransform({ weight, distance }) {
      let path = '';

      const height = this.height;
      const width = this.width;
      const absoluteDistance = distance * height;
      const absoluteWeight = weight * height;
      const increment = absoluteDistance + absoluteWeight;

      for (let y = -this._bounds.maxY; y < -this._bounds.minY; y += increment) {
        path += getInterpolatedRect(width, absoluteWeight, {
          x: 0,
          y,
        });
      }

      return path;
    },

    /**
     * Get points for warp transformation in pathText's coordinate system, useful for positioning.
     * @returns {{bounds: Partial<paper.Rectangle>, points: {x: number, y: number}[]}}
     */
    getRelativeWarpPoints(isAngledTransform = false) {
      const { points: neutralPoints } = this.getNeutralTransformOptions();
      // In this case the points live at base line of the text (when not flipped),
      // but decoration lines start at the top, so we need to transform the points.
      const originalHeight = this._bounds.maxY - this._bounds.minY;
      const transformedPoints = neutralPoints.map(({ x, y }) => ({
        x,
        // -this._bounds.minY represents the height below the base line
        y: y - (this._bounds.minY + originalHeight) * (this.flipY ? -1 : 1),
      }));

      const bounds = isAngledTransform
        ? {
            x: Math.min(transformedPoints[0].x, transformedPoints[1].x),
            y: Math.min(transformedPoints[0].y, transformedPoints[1].y),
            right: Math.max(transformedPoints[0].x, transformedPoints[1].x),
            bottom: Math.max(transformedPoints[0].y, transformedPoints[1].y),
            width: Math.abs(transformedPoints[0].x - transformedPoints[1].x),
            height: Math.abs(transformedPoints[0].y - transformedPoints[1].y),
          }
        : getWarpCurveBbox(transformedPoints);

      return {
        points: transformedPoints.map(({ x, y }) =>
          getFlippedPointInBounds({
            point: {
              x: x + this.left - bounds.x,
              y: y + this.top - bounds.y,
            },
            bounds,
            flipX: this.flipX,
            flipY: this.flipY,
          })
        ),
        bounds,
      };
    },

    /**
     * Get points for distort transformation in pathText's coordinate system, useful for positioning.
     * @returns {{bounds: paper.Rectangle, points: {x: number, y: number}[]}}
     */
    getRelativeDistortPoints() {
      const { points: neutralPoints } = this.getNeutralTransformOptions();
      const { top, bottom } = createFreeFormCurves(neutralPoints);
      const bounds = new CompoundPath({
        children: [top, bottom],
        insert: false,
      }).bounds;

      return {
        points: neutralPoints.map(({ x, y }) =>
          getFlippedPointInBounds({
            point: {
              x: x + this.left - bounds.x,
              y: y + this.top - bounds.y,
            },
            bounds,
            flipX: this.flipX,
            flipY: this.flipY,
          })
        ),
        bounds,
      };
    },

    /**
     * Get the position of horizontal-line decoration in pathText's coordinate system,
     * considering possible transforms on pathText like rotation and flips.
     * @param pointsBounds: the bounds of points for transformation.
     * @returns {{top: number, left: number}}
     */
    getDecorationPosition(pointsBounds) {
      const center = this.getAbsoluteCenterPoint();
      const topLeft = new fabric.Point(this.left, this.top);
      const originalTopLeft = rotatePointAroundSinglePoint(
        topLeft,
        center,
        -this.angle
      );

      // Because decoration's originX and originY are 'left' and 'top',
      // in order to place it relatively to the center of the group,
      // we always need to translate (-this.width / 2, -this.height / 2)
      let top = -this.height / 2 - (originalTopLeft.y - pointsBounds.y);
      let left = -this.width / 2 - (originalTopLeft.x - pointsBounds.x);

      if (this.flipY) {
        top =
          -this.height / 2 +
          (originalTopLeft.y + this.height - pointsBounds.bottom);
      }

      if (this.flipX) {
        left =
          -this.width / 2 +
          (originalTopLeft.x + this.width - pointsBounds.right);
      }

      return {
        left,
        top,
      };
    },

    /**
     * Get line decoration with curved transform
     * @param {{ weight: number, distance: number }}
     * @returns {{ path: string, top: number, left: number }}
     */
    getLineDecorationWithCurvedTransform({ weight, distance }) {
      const height = this._bounds.maxY - this._bounds.minY;
      const absoluteDistance = distance * height;
      const absoluteWeight = weight * height;
      const increment = absoluteDistance + absoluteWeight;
      const linesCount = Math.ceil(height / increment);
      const { points, bounds } = this.getRelativeWarpPoints();

      const curve = createTriSegmentCurve([
        [points[0], null, points[1]],
        [points[3], points[2], points[4]],
        [points[6], points[5], null],
      ]);
      const curveInverted = createTriSegmentCurve([
        [points[6], null, points[5]],
        [points[3], points[4], points[2]],
        [points[0], points[1], null],
      ]);

      let path = '';

      for (let i = 0; i < linesCount; i++) {
        const yRatioTop = i * (weight + distance);
        const yRatioBottom = Math.min(i * (weight + distance) + weight, 1);

        const topCurve = curve.clone();
        topCurve.translate(new Point(0, yRatioTop * height));
        const bottomCurve = curveInverted.clone();
        bottomCurve.translate(new Point(0, yRatioBottom * height));

        path += `${topCurve.pathData} L ${bottomCurve.pathData.slice(1)} Z`;
      }

      return {
        path,
        ...this.getDecorationPosition(bounds),
      };
    },

    /**
     * Get line decoration with angled transform
     * @param {{ weight: number, distance: number }}
     * @returns {{ path: string, top: number, left: number }}
     */
    getLineDecorationWithAngledTransform({ weight, distance }) {
      const height = this._bounds.maxY - this._bounds.minY;
      const absoluteDistance = distance * height;
      const absoluteWeight = weight * height;
      const increment = absoluteDistance + absoluteWeight;
      const linesCount = Math.ceil(height / increment);

      const { points, bounds } = this.getRelativeWarpPoints(true);

      let path = '';

      for (let i = 0; i < linesCount; i++) {
        const yRatioTop = i * (weight + distance);
        const yRatioBottom = Math.min(i * (weight + distance) + weight, 1);

        const topY = yRatioTop * height;
        const bottomY = yRatioBottom * height;

        path += `${moveTo({ x: points[0].x, y: points[0].y + topY })}
          ${lineTo({ x: points[1].x, y: points[1].y + topY })}
          ${lineTo({ x: points[1].x, y: points[1].y + bottomY })}
          ${lineTo({ x: points[0].x, y: points[0].y + bottomY })}
          ${closePath()}
        `;
      }

      return {
        path,
        ...this.getDecorationPosition(bounds),
      };
    },

    /**
     * Get fadingColorCut decoration with curved transform
     * @param {{ weight: number, distance: number }}
     * @returns {{ path: string, top: number, left: number }}
     */
    getFadingColorCutDecorationWithCurvedTransform({ weight, distance }) {
      const height = this._bounds.maxY - this._bounds.minY;
      const { points, bounds } = this.getRelativeWarpPoints();

      const curve = createTriSegmentCurve([
        [points[0], null, points[1]],
        [points[3], points[2], points[4]],
        [points[6], points[5], null],
      ]);
      const curveInverted = createTriSegmentCurve([
        [points[6], null, points[5]],
        [points[3], points[4], points[2]],
        [points[0], points[1], null],
      ]);

      let path = '';

      let offsetY = height * (distance - FADING_COLOR_CUT.getSize(weight) / 2);

      if (offsetY > 0) {
        const topCurve = curve.clone();
        topCurve.translate(new Point(0, offsetY));
        const bottomCurve = curveInverted.clone();

        path += `${topCurve.pathData} L ${bottomCurve.pathData.slice(1)} Z`;
      }

      for (let i = 1; i <= 5; i++) {
        const gap = FADING_COLOR_CUT.getGapHeight(i, weight) * height;
        const rectHeight = FADING_COLOR_CUT.getRectHeight(i, weight) * height;
        offsetY += rectHeight;

        const negativeOffset = offsetY < 0 ? offsetY : 0;
        const offset = Math.max(offsetY, 0);
        const pathHeight = Math.max(gap + negativeOffset, 0);

        const topCurve = curve.clone();
        topCurve.translate(new Point(0, offset + pathHeight));
        const bottomCurve = curveInverted.clone();
        bottomCurve.translate(new Point(0, offset));

        path += `${topCurve.pathData} L ${bottomCurve.pathData.slice(1)} Z`;

        offsetY += gap;
      }

      return {
        path,
        ...this.getDecorationPosition(bounds),
      };
    },

    /**
     * Get fadingColorCut decoration with angled transform
     * @param {{ weight: number, distance: number }}
     * @returns {{ path: string, top: number, left: number }}
     */
    getFadingColorCutDecorationWithAngledTransform({ weight, distance }) {
      const height = this._bounds.maxY - this._bounds.minY;
      const { points, bounds } = this.getRelativeWarpPoints(true);

      let path = '';
      let offsetY = height * (distance - FADING_COLOR_CUT.getSize(weight) / 2);

      if (offsetY > 0) {
        path += getParallelogramPath(points[0], points[1], offsetY);
      }

      for (let i = 1; i <= 5; i++) {
        const gap = FADING_COLOR_CUT.getGapHeight(i, weight) * height;
        const rectHeight = FADING_COLOR_CUT.getRectHeight(i, weight) * height;
        offsetY += rectHeight;

        const negativeOffset = offsetY < 0 ? offsetY : 0;
        const offset = Math.max(offsetY, 0);
        const pathHeight = Math.max(gap + negativeOffset, 0);
        path += getParallelogramPath(points[0], points[1], pathHeight, offset);

        offsetY += gap;
      }

      return {
        path,
        ...this.getDecorationPosition(bounds),
      };
    },

    /**
     * Get fadingColorCut decoration with distort transform
     * @param {{ weight: number, distance: number }}
     * @returns {{ path: string, top: number, left: number }}
     */
    getFadingColorCutDecorationWithDistortTransform({ weight, distance }) {
      const { points, bounds } = this.getRelativeDistortPoints();

      let path = '';
      let offsetY = distance - FADING_COLOR_CUT.getSize(weight) / 2;

      if (offsetY > 0) {
        const topCurve = getInterpolatedCurve(0, points);
        const bottomCurve = getInterpolatedCurve(offsetY, points);

        path += getPathBetweenCurves(topCurve, bottomCurve);
      }

      for (let i = 1; i <= 5; i++) {
        const gap = FADING_COLOR_CUT.getGapHeight(i, weight);
        const rectHeight = FADING_COLOR_CUT.getRectHeight(i, weight);
        offsetY += rectHeight;

        const negativeOffset = offsetY < 0 ? offsetY : 0;
        const offset = Math.max(offsetY, 0);
        const pathHeight = Math.max(gap + negativeOffset, 0);

        const topCurve = getInterpolatedCurve(offset, points);
        const bottomCurve = getInterpolatedCurve(offset + pathHeight, points);

        path += getPathBetweenCurves(topCurve, bottomCurve);

        offsetY += gap;
      }

      // pad one curve to ensure that decorations have the exact same bounds with the actual text
      path += `${moveTo(points[5])}
          ${cubicCurveTo(points[5], points[8], points[4])}
          ${cubicCurveTo(points[9], points[3], points[3])}
          ${cubicCurveTo(points[3], points[9], points[4])}
          ${cubicCurveTo(points[8], points[5], points[5])}
          `;

      return {
        path,
        ...this.getDecorationPosition(bounds),
      };
    },

    /**
     * Get line decoration with distort transform
     * @param {{ weight: number, distance: number }}
     * @returns {{ path: string, top: number, left: number }}
     */
    getLineDecorationWithDistortTransform({ weight, distance }) {
      const height = this.height;
      const absoluteDistance = distance * height;
      const absoluteWeight = weight * height;
      const increment = absoluteDistance + absoluteWeight;
      const linesCount = Math.ceil(height / increment);

      const { points, bounds } = this.getRelativeDistortPoints();

      let path = '';
      // Ensure decorations to have the exact same bounds with the actual text
      let needLastPaddingCurve = false;

      for (let i = 0; i < linesCount; i++) {
        const yRatioTop = i * (weight + distance);
        const yRatioBottom = Math.min(i * (weight + distance) + weight, 1);

        needLastPaddingCurve = yRatioBottom < 1;

        const topCurve = getInterpolatedCurve(yRatioTop, points);
        const bottomCurve = getInterpolatedCurve(yRatioBottom, points);

        path += getPathBetweenCurves(topCurve, bottomCurve);
      }

      if (needLastPaddingCurve) {
        path += `${moveTo(points[5])}
          ${cubicCurveTo(points[5], points[8], points[4])}
          ${cubicCurveTo(points[9], points[3], points[3])}
          ${cubicCurveTo(points[3], points[9], points[4])}
          ${cubicCurveTo(points[8], points[5], points[5])}
          `;
      }

      return {
        path,
        ...this.getDecorationPosition(bounds),
      };
    },

    /**
     * Get colorCut decoration with curved transform
     * @param {number} distance
     * @returns {{ path: string, top: number, left: number }}
     */
    getColorCutDecorationWithCurvedTransform(distance) {
      const height = this._bounds.maxY - this._bounds.minY;
      const { points, bounds } = this.getRelativeWarpPoints();

      let path = '';

      const topCurve = createTriSegmentCurve([
        [points[0], null, points[1]],
        [points[3], points[2], points[4]],
        [points[6], points[5], null],
      ]);
      const bottomCurve = createTriSegmentCurve([
        [points[6], null, points[5]],
        [points[3], points[4], points[2]],
        [points[0], points[1], null],
      ]);
      const pathHeight = distance * height;
      bottomCurve.translate(new Point(0, pathHeight));

      path += `${topCurve.pathData} L ${bottomCurve.pathData.slice(1)} Z`;

      return {
        path,
        ...this.getDecorationPosition(bounds),
      };
    },

    /**
     * Get colorCut decoration with angled transform
     * @param {number} distance
     * @returns {{ path: string, top: number, left: number }}
     */
    getColorCutDecorationWithAngledTransform(distance) {
      const height = this._bounds.maxY - this._bounds.minY;
      const { points, bounds } = this.getRelativeWarpPoints(true);

      let path = '';

      const topCurve = getParallelogramPath(points[0], points[1], 0);
      path += topCurve;
      const pathHeight = distance * height;
      const bottomCurve = getParallelogramPath(
        points[0],
        points[1],
        pathHeight
      );
      path += bottomCurve;

      return {
        path,
        ...this.getDecorationPosition(bounds),
      };
    },

    /**
     * Get colorCut decoration with distort transform
     * @param {number} distance
     * @returns {{ path: string, top: number, left: number }}
     */
    getColorCutDecorationWithDistortTransform(distance) {
      const { points, bounds } = this.getRelativeDistortPoints();

      let path = '';
      const topCurve = getInterpolatedCurve(0, points);
      const bottomCurve = getInterpolatedCurve(distance, points);
      path += getPathBetweenCurves(topCurve, bottomCurve);

      // pad one curve to ensure that decorations have the exact same bounds with the actual text
      path += `${moveTo(points[5])}
          ${cubicCurveTo(points[5], points[8], points[4])}
          ${cubicCurveTo(points[9], points[3], points[3])}
          ${cubicCurveTo(points[3], points[9], points[4])}
          ${cubicCurveTo(points[8], points[5], points[5])}
          `;

      return {
        path,
        ...this.getDecorationPosition(bounds),
      };
    },
  });
}
