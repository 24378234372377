import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeGrid as GridWindow } from 'react-window';

import { Wrapper, Controls, Message, ContentWrapper, Item } from './styles';
import { CenterChildren } from '../../utilities/styles';
import TextInput from '../../TextInput/TextInput';
import SliderInput from '../../SliderInput/SliderInput';
import { getAllGlyphs } from '../../../font/glyphLoader';
import createPerformancer from '../../../utils/performancer';
import { themeStyle } from '../../../services/theming';

const GridCell = ({
  columnIndex,
  rowIndex,
  style,
  onSelect,
  size,
  options,
}) => {
  const index = rowIndex * size.columnCount + columnIndex;
  const item = index < options.length ? options[index] : undefined;
  return (
    <div style={style}>
      {item && (
        <Item
          onClick={() => {
            onSelect && onSelect(item);
          }}
          style={{
            width: size.targetItemSize,
            height: size.targetItemSize,
          }}
        >
          <CenterChildren>
            <img src={`data:image/svg+xml;base64,${item.svg}`} alt="" />
          </CenterChildren>
        </Item>
      )}
    </div>
  );
};

/**
 * The GlyphsPanel is used to show, search and select glyphs.
 * The glyphs are loaded with the glyphLoader utility scripts. To improve performance, by rendering less,
 * a Grid from react-window is used. That only renders grid cells in view.
 */
const GlyphsPanel = ({ activeFont, ...props }) => {
  const [query, setQuery] = useState('');
  const [allOptions, setAllOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const gridWidth = 274;
  const gridHeight = 700;
  const [size, setSize] = useState({
    size: 8,
    targetItemSize: 8 ** 2,
    columnCount: Math.floor(gridWidth / 8 ** 2),
  });

  const updateSize = (newSize) => {
    setSize({
      size: newSize,
      targetItemSize: newSize ** 2,
      columnCount: Math.floor(gridWidth / newSize ** 2),
    });
  };

  // simple filter, to search by a query
  const byQuery = useCallback((font, query) => {
    return font.tags.some((tag) => tag.indexOf(query) !== -1);
  }, []);

  // loadOptions
  useEffect(() => {
    loadGlyphs(activeFont);
  }, [activeFont]);

  const loadGlyphs = async (font) => {
    const perf = createPerformancer(`initialize load glyphs for font ${font}`);
    perf.mark('init');
    setIsLoading(true);
    getAllGlyphs(font)
      .then((e) => {
        perf.mark('loaded');
        const encoded = e.map((g) => {
          const tags = g.tags.map((tag) => tag.toLowerCase());
          return {
            ...g,
            tags,
            svg: btoa(unescape(encodeURIComponent(g.svg))),
          };
        });
        setAllOptions(encoded);
        perf.mark('end');
        // eslint-disable-next-line no-console
        perf.measure().forEach((m) => console.debug(m));
        perf.clear();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // update options, when filter or query changes
  useEffect(() => {
    let items = allOptions;
    const q = query.trim().toLowerCase();
    if (q.length) {
      items = allOptions.filter((option) => byQuery(option, q));
    }

    setOptions(items);
  }, [query, allOptions, setOptions, byQuery]);

  const scrollbarStyle = {
    /* scrollbar */
    marginRight: '-3px',
    paddingRight: '13px' /* 10 + 3 */,
    scrollbarWidth: 'thin',
    scrollBehavior: 'smooth',
    scrollbarColor: `${themeStyle.varInkLight} ${themeStyle.varBackgroundAlt}`,
  };

  return (
    <Wrapper>
      <Controls gap="10px" alignItems={'center'} marginBottom={'10px'}>
        <TextInput
          value={query}
          icon={'search'}
          placeholder={'Search'}
          onChanging={setQuery}
        />
        <SliderInput
          name="glyphs-preview-size"
          label="Size"
          startValue={size.size}
          min={6}
          max={14}
          precision={0}
          step={1}
          hasNumberInput={false}
          onChanging={(value) => updateSize(value)}
        />
      </Controls>
      <ContentWrapper gridHeight={gridHeight}>
        {(isLoading && <Message>Loading glyphs ...</Message>) || (
          <>
            {(!options.length && (
              <Message>No glyph available that fits your search.</Message>
            )) || (
              <GridWindow
                columnCount={size.columnCount}
                columnWidth={gridWidth / size.columnCount}
                height={gridHeight}
                rowCount={Math.ceil(options.length / size.columnCount)}
                rowHeight={size.targetItemSize}
                width={gridWidth}
                style={scrollbarStyle}
              >
                {({ columnIndex, rowIndex, style }) => (
                  <GridCell
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    style={style}
                    onSelect={props.onSelect}
                    size={size}
                    options={options}
                  />
                )}
              </GridWindow>
            )}
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

GridCell.propTypes = {
  columnIndex: PropTypes.number,
  rowIndex: PropTypes.number,
  style: PropTypes.object,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.shape({
    size: PropTypes.number,
    targetItemSize: PropTypes.number,
    columnCount: PropTypes.number,
  }),
};

GlyphsPanel.propTypes = {
  /**
   * name of the font for which glyphs should be displayed
   */
  activeFont: PropTypes.string.isRequired,
  /**
   * A function that is triggered after an option is selected. Gets the current option.
   */
  onSelect: PropTypes.func,
};

GlyphsPanel.defaultProps = {
  activeFont: 'Mogan',
};

export default GlyphsPanel;
