import isEqual from 'lodash/isEqual';
import { useState, useEffect, useRef } from 'react';
import { transformRecursive } from '../utils/groupStructure/recursive';

/**
 * hook to manage a tree structure of layers ([layer={children: [layer]}])
 * The layers are aggregated with object data from the state
 */
const useAggregatedLayers = (state) => {
  const [layers, setLayers] = useState([]);

  const { groupStructure, objects, artboardOptions, overlayOptions } = state;

  const lastAggregatedLayer = useRef([]);
  useEffect(() => {
    let aggregatedLayers = [];
    // check variables
    if (groupStructure?.children.length) {
      const layerObjects = objects || [];

      // recursively map layers to add relevant objects data to them
      aggregatedLayers = transformRecursive(
        groupStructure.children,
        (layer) => {
          if (layer.children?.length) {
            return {
              id: layer.id,
              meta: layer.meta,
              children: layer.children,
              type: 'structuralGroup',
              ...layer.meta,
            }; // aggregate group data
          }

          const object = layerObjects.find((obj) => obj.id === layer.id);
          if (object) {
            const { type, text, locked, hidden, name, opacity } = object;
            return {
              id: layer.id,
              type,
              text,
              locked,
              hidden,
              hide: object.isClipPath,
              name,
              opacity,
              disabled: !!(object.clipPathMaskId || object.isClipPath),
            }; // prefer object data over layer data
          }
          console.warn(
            `Could not find object with the id '${layer.id}' (${layer.type}) in the given state.`
          );
          return layer;
        }
      );
    }

    // add layers that are not part of objects
    aggregatedLayers.push({
      id: 'background',
      locked: false,
      hidden: artboardOptions?.opacity === 0,
      disabled: true,
      name: 'Background',
      type: 'background',
    });
    if (overlayOptions) {
      aggregatedLayers.unshift({
        id: 'overlay',
        locked: false,
        hidden: overlayOptions.hidden,
        disabled: true,
        name: 'Texture',
        type: 'overlay',
      });
    }

    if (!isEqual(aggregatedLayers, lastAggregatedLayer.current)) {
      setLayers(aggregatedLayers);
      lastAggregatedLayer.current = aggregatedLayers;
    }
  }, [groupStructure, objects, artboardOptions, overlayOptions]);

  return layers;
};

export default useAggregatedLayers;
