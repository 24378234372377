import { matchObjectPosition } from '../../../utils/editor/objects';
import { illustrationsCache, loadSVG } from '../../../utils/editor/loadSvg';

/**
 * A mixin that implements initializing an object of paths from an svg.
 * A two step initialization is used that can first load an image and then
 * replace it with a group of svgs once initialized.
 */
export default function (fabric, klass) {
  fabric.util.object.extend(klass.prototype, {
    initializeSvg: async function (options, cb) {
      const showPreview =
        options.previewSrc &&
        !fabric.skipImagePreviews &&
        !options.skipPreview &&
        !illustrationsCache[options.src];

      if (showPreview) {
        fabric.Image.fromURLRetry(
          options.previewSrc,
          (target, error) => {
            // If SVG was cached or loaded faster by some dark magic, we just return, nothing to preview
            if (this.svgLoaded || error) return;

            const filter = new fabric.Image.filters.Blur({
              blur: 0.25,
            });

            target.filters.push(filter);
            target.applyFilters();

            fabric.Group.prototype.initialize.call(this, [target], options);
            this.initialized = true;
            this.inLoadingStage = true;

            this.previewImage = target;

            // store fill property in case toObject is called before
            // initialization was fully completed
            this.previousColorList = options.fill;

            cb && cb(this);
          },
          { ...options, crossOrigin: 'anonymous' }
        );
      }

      const paths = await loadSVG(options.src);
      this.svgLoaded = true;

      // keep preview image when fetch fails
      if (paths.length || !this.initialized) {
        if (this.inLoadingStage) {
          let refObj;
          if (this.initialized) {
            // There is preview
            this.removeWithUpdate(this.previewImage);
            refObj = this.previewImage;
          } else {
            /**
             * This can happen when the illustration never finished loading but
             * it was saved in state. Now it is being loaded. So we need to initialize it.
             * We do it with an empty object array since initializing with paths will break positioning.
             * (Dimensions found in options are due to a previously existing previewImage, which does
             * not exist anymore)
             */

            fabric.Group.prototype.initialize.call(this, [], options);
            refObj = options;
          }

          this.add.apply(this, paths);
          this.addWithUpdate(); // ---> as per http://fabricjs.com/docs/fabric.Group.html

          /**
           * Force keep aspect ratio.
           * In some cases when using custom uploads, if there are objects in the svg
           * path that are outside the viewbox, it can happen that the preview image and
           * the bounding box containing all paths have different aspect ratios.
           * In this case we trust the bounding box aspect ratio. Otherwise we can get distortions.
           */
          matchObjectPosition(this, refObj, true);
          this.setCoords();

          this.previewImage = null;
          this.inLoadingStage = false;
        } else {
          fabric.Group.prototype.initialize.call(this, paths, options);
        }

        // add outline
        if (options.strokeWidth && this.createOutlinePath) {
          const outline = await this.createOutlinePath(options);
          if (outline) {
            this.insertAt(outline, 0);
          }
        }
      }

      paths.forEach((path) => {
        path.objectCaching = false;
        path.linkedColors = {};
      });

      return paths;
    },
  });
}
