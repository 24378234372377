import React from 'react';

import { IconStyle } from './styles';
import { defaultTheme } from './theme';
import icons from './Icons';
import { IconProps } from './types';

const Icon: React.FC<IconProps> = (props) => {
  const theme = { ...defaultTheme, ...props.theme };
  if (!theme.secondaryColor) theme.secondaryColor = theme.color;
  if (!theme.secondaryActiveColor)
    theme.secondaryActiveColor = theme.activeColor;
  if (!theme.secondaryDisabledColor)
    theme.secondaryDisabledColor = theme.disabledColor;
  if (!theme.onActiveColor) theme.onActiveColor = theme.activeColor;
  if (!theme.secondaryOnActiveColor)
    theme.secondaryOnActiveColor = theme.secondaryActiveColor;

  const IconSvg = icons[props.name!];

  return (
    <IconStyle
      theme={theme}
      height={props.height}
      isActive={props.isActive}
      disabled={props.disabled}
      className={props.ignoreHoverStyles ? 'ignoreHoverStyles' : null}
    >
      <IconSvg />
    </IconStyle>
  );
};

export default Icon;

Icon.defaultProps = {
  name: 'edit',
  isActive: false,
  disabled: false,
  height: '21px',
};
