import { ArtboardLayout, Unit } from '../../types/ArtboardState';

export interface LayoutConfig {
  label: string;
  icon: string;
  size: {
    x: number;
    y: number;
    unit: Unit;
  };
}

type Layouts = { [Layout in Exclude<ArtboardLayout, 'custom'>]: LayoutConfig };

export const layouts: Layouts = {
  standard: {
    label: 'Standard',
    icon: 'defaultArtboard',
    size: {
      x: 1200,
      y: 1200,
      unit: Unit.px,
    },
  },
  postcard: {
    label: 'Postcard',
    icon: 'postcard',
    size: {
      x: 4,
      y: 6,
      unit: Unit.in,
    },
  },
  businesscard: {
    label: 'Business Card',
    icon: 'businessCard',
    size: {
      x: 2,
      y: 3.5,
      unit: Unit.in,
    },
  },
  a4: {
    label: 'A4',
    icon: 'a4_a5',
    size: {
      x: 210,
      y: 297,
      unit: Unit.mm,
    },
  },
  a5: {
    label: 'A5',
    icon: 'a4_a5',
    size: {
      x: 148,
      y: 210,
      unit: Unit.mm,
    },
  },
  postersmall: {
    label: 'Poster Small',
    icon: 'poster',
    size: {
      x: 16,
      y: 20,
      unit: Unit.in,
    },
  },
  posterbig: {
    label: 'Poster Big',
    icon: 'poster',
    size: {
      x: 24,
      y: 36,
      unit: Unit.in,
    },
  },
  instagrampost: {
    label: 'Instagram Post',
    icon: 'instagramPost',
    size: {
      x: 1080,
      y: 1080,
      unit: Unit.px,
    },
  },
  facebookpost: {
    label: 'Facebook Post',
    icon: 'facebookPost',
    size: {
      x: 1200,
      y: 1200,
      unit: Unit.px,
    },
  },
};

export const activeDefault = 'standard';
