import styled from 'styled-components';

export const FormWrapper = styled.form`
  width: 100%;
  text-align: left;
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
`;
