import { themeStyle } from '../../services/theming';
import { StyledTheme } from '../../types/StyledComponents';

const transparentWhite92 = '#FFFFFFEB';

export const defaultTheme: StyledTheme = {
  color: themeStyle.varInkMain,
  disabledColor: themeStyle.inkMedium,
  activeDefaultColor: themeStyle.varInkMain,
  activeColor: themeStyle.varBrandMedium,
  transition: 'none',
  justifyContent: 'center',
  opacity: 1,
};

export const circledIconTheme: StyledTheme = {
  color: themeStyle.inkMedium,
  activeDefaultColor: themeStyle.inkMain,
  activeColor: themeStyle.inkMain,
  secondaryColor: transparentWhite92,
  secondaryActiveColor: transparentWhite92,
  secondaryDisabledColor: transparentWhite92,
};
