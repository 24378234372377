import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { handleTextOverflow } from '../utilities/styles';
import { P3 } from '../utilities/Typography/styles';

export const SelectWrapper = styled.div<any>`
  position: absolute;
  box-sizing: border-box;
  top: calc(${({ theme }) => theme.height} + 5px);
  width: ${({ theme }) => theme.width};
  max-width: 100%;

  z-index: ${({ theme }) => theme.dropdownZIndex};

  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  overflow: hidden;

  background: ${({ theme }) => theme.dropDownBackgroundColor};
  box-shadow: ${({ theme }) => theme.dropDownShadow};
  border-radius: ${themeStyle.radiusMedium};
  padding: 8px 0;
`;

export const Wrapper = styled.div`
  width: ${({ theme }) => theme.width};
  max-width: 100%;
  height: ${({ theme }) => theme.height};
  box-sizing: border-box;
  position: relative;
`;

export const SelectOption = styled(P3)<{ isActive: boolean }>`
  ${handleTextOverflow}
  color: ${themeStyle.varInkMedium};
  line-height: 26px;
  padding: 0 10px;
  cursor: pointer;

  background-color: ${({ isActive }) =>
    isActive ? themeStyle.varInkSuperLight : 'unset'};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${themeStyle.varInkSuperLight};
  }
`;

export const OptionLabel = styled.span`
  color: ${themeStyle.varInkMain};
  font-weight: ${themeStyle.fontSemiBold};
`;
