import { nanoid } from 'nanoid';
import noop from 'lodash/noop';
import { isDebugActive } from './dev';

/**
 * performáncer, like pyromancer but instead of fire - performance
 * it's an easier interface for Performance API
 * https://developer.mozilla.org/en-US/docs/Web/API/Performance
 */

type KeyOfPerformanceEntry = keyof PerformanceEntry;
type ValueOfPerformanceEntry = PerformanceEntry[keyof PerformanceEntry];

/**
 * Creates a performancer object
 * @param {*} name - performancer name, will be replaced by unique id if not specified
 */
const createPerformancer = (
  name: string
): {
  mark: (markName: string) => void;
  clear: () => void;
  measure: () => Record<string, ValueOfPerformanceEntry>[];
} => {
  // add a check for running in node or production mode
  if (!performance.mark || !isDebugActive()) {
    return {
      mark: noop,
      clear: noop,
      measure: (): Record<string, ValueOfPerformanceEntry>[] => [],
    };
  }

  const id = nanoid();
  const marks: string[] = [];

  const mark = (markName: string): void => {
    const name = markName || marks.length.toString();
    performance.mark(`${id}:${name}`);
    marks.push(name);
  };

  const defaultMeasureOpts = {
    filterZeroDurations: true,
    fields: ['name', 'duration'] as KeyOfPerformanceEntry[],
  };

  const measure = (
    opts: {
      filterZeroDurations?: boolean;
      fields?: KeyOfPerformanceEntry[];
    } = {}
  ): Record<string, ValueOfPerformanceEntry>[] => {
    const { filterZeroDurations, fields } = { ...defaultMeasureOpts, ...opts };
    marks.forEach((stop, i) => {
      if (i !== 0) {
        const prevStop = marks[i - 1];
        performance.measure(
          `[${id}] Measure ${prevStop} to ${stop}`,
          `${id}:${prevStop}`,
          `${id}:${stop}`
        );
      }
    });

    return performance
      .getEntriesByType('measure')
      .filter((m) => m.name.startsWith(`[${id}]`))
      .filter((m) => !filterZeroDurations || m.duration > 0)
      .map((m) => {
        const obj: Record<string, ValueOfPerformanceEntry> = {
          perfName: name || id,
        };
        return fields.reduce((acc, field) => {
          if (field in m) acc[field] = m[field];
          return acc;
        }, obj);
      });
  };

  const clear = (): void => {
    marks.forEach((stop, i) => {
      performance.clearMarks(`${id}:${stop}`);
      if (i !== 0) {
        performance.clearMeasures(`[${id}] Measure ${marks[i - 1]} to ${stop}`);
      }
    });
  };

  return {
    mark,
    clear,
    measure,
  };
};

export default createPerformancer;
