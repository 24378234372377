import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Transition } from 'react-transition-group';

import {
  Wrapper,
  Body,
  Header,
  ScrollContent,
  TabContent,
  StylesWrapper,
  LayersWrapper,
} from './styles';
import LayersMenu from '../LayersMenu/LayersMenu';
import { useOnboardingStore } from '../../stores/onboardingStore';
import { menuStoreSelector, useMenuStore } from '../../stores/menuStore';
import { panels, defaultPanel, getTabsFromActivePanel } from './config';
import analytics from '../../global/analytics';
import { RIGHT_MENU_OPEN } from '../../global/analytics/events';
import { DESELECT_OBJECTS } from '../../global/events';
import { ANCHOR_REF_KEYS } from '../../stores/onboardingStore/constants';
import MenuHeader from '../MenuHeader/MenuHeader';
import { secondaryIconTheme } from '../MenuHeader/theme';
import { RightMenuProps } from './types';

/**
 * RightMenu is the menu on the right of the app. It is mainly used to edit elements
 * on the artboard.
 */
const RightMenu: React.FC<RightMenuProps> = (props) => {
  const workingMode = useMenuStore(menuStoreSelector.workingMode);
  const isMockupMode = workingMode === 'mockup';
  const isDefaultWorkingMode = workingMode === 'default';
  const showLayers =
    useMenuStore(menuStoreSelector.layers) && isDefaultWorkingMode;

  const [activePanel, setActivePanel] = useState(
    panels.find(({ id }) => id === defaultPanel)
  );

  const getRightMenuActivePanel = useMenuStore(
    (state) => state.getRightMenuActivePanel
  );

  const activePanelId = getRightMenuActivePanel();

  const isOpen = useMenuStore(menuStoreSelector.rightMenuState).open;

  const mergeRightMenuState = useMenuStore(
    menuStoreSelector.mergeRightMenuState
  );

  const setRightMenuActivePanel = useCallback(
    (panelId: string): void => {
      mergeRightMenuState({ activeObjectPanel: panelId });
    },
    [mergeRightMenuState]
  );

  const setRightMenuOpen = useCallback(
    (open: boolean): void => mergeRightMenuState({ open }),
    [mergeRightMenuState]
  );

  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    if (props.options) {
      setOptions(props.options);
    }
  }, [props.options]);

  const scrollContent = useRef<HTMLDivElement | null>();
  useEffect(() => {
    if (props.justPressedEditTransform) {
      scrollContent.current?.scrollTo({
        top: scrollContent.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [props.justPressedEditTransform]);

  const updateActivePanel = useCallback(
    (id: string): void => {
      if (id) {
        setRightMenuActivePanel(id);
      }
    },
    [setRightMenuActivePanel]
  );

  useEffect(() => {
    const activePanel = panels.find((panel) => panel.id === activePanelId);
    if (activePanel) {
      setActivePanel(activePanel);
    }
  }, [activePanelId]);

  const handleNavigation = (panelId: string): void => {
    // change active
    updateActivePanel(panelId);
    analytics.track(RIGHT_MENU_OPEN, { label: panelId });
  };

  const renderActivePanel = (): React.ReactNode | null => {
    if (activePanel)
      switch (activePanel.id) {
        case 'styles': {
          return (
            <activePanel.element
              options={options}
              background={props.background}
              overlay={props.overlay}
              colorPalette={props.colorPalette}
              activeObjects={props.activeObjects}
              layers={props.layers}
              selectedLayers={props.selectedLayers}
              dispatch={props.dispatch}
            />
          );
        }
        case 'effects': {
          return (
            <activePanel.element
              options={options}
              colorPalette={props.colorPalette}
              dispatch={props.dispatch}
            />
          );
        }
        case 'projectColors': {
          return (
            <activePanel.element
              options={options}
              colorPalette={props.colorPalette}
              dispatch={props.dispatch}
            />
          );
        }
        default: {
          return <activePanel.element dispatch={props.dispatch} />;
        }
      }

    return null;
  };

  const stylesMenuRef = useRef<HTMLDivElement | null>();

  const onCloseMenu = (): void => {
    props.dispatch && props.dispatch(DESELECT_OBJECTS);
    setRightMenuOpen(false);
  };

  return (
    <Wrapper marginTop={props.marginTop}>
      <Body>
        <Transition
          in={isOpen}
          timeout={{ exit: 500 }}
          unmountOnExit={true}
          nodeRef={stylesMenuRef}
        >
          {(state): React.ReactNode => (
            <StylesWrapper
              data-testid="styles-panel"
              state={state}
              ref={(node): void => {
                stylesMenuRef.current = node;
                useOnboardingStore
                  .getState()
                  .registerStepRef(node, ANCHOR_REF_KEYS.STYLES_MENU);
              }}
              onTransitionEnd={(event): void => {
                if (
                  event.target === stylesMenuRef.current &&
                  event.propertyName === 'transform'
                ) {
                  useOnboardingStore
                    .getState()
                    .registerStepRef(
                      stylesMenuRef.current,
                      ANCHOR_REF_KEYS.STYLES_MENU
                    );
                }
              }}
            >
              <Header>
                <MenuHeader
                  config={getTabsFromActivePanel(activePanel, options)}
                  active={activePanel?.id}
                  onClose={onCloseMenu}
                  onClickTab={(id): void => handleNavigation(id)}
                  hideClose={activePanel?.id === 'mockup'}
                />
              </Header>
              <ScrollContent ref={scrollContent}>
                {renderActivePanel()}
              </ScrollContent>
            </StylesWrapper>
          )}
        </Transition>
        <Transition
          in={showLayers}
          timeout={{ exit: 500 }}
          unmountOnExit={true}
        >
          {(state): React.ReactNode => (
            <LayersWrapper data-testid="layers-panel" state={state}>
              <Header>
                <MenuHeader
                  config={[
                    {
                      id: 'layers',
                      label: 'Layers',
                      icon: 'layer',
                      iconTheme: secondaryIconTheme,
                    },
                  ]}
                  active="layers"
                  onClose={(): void => useMenuStore.setState({ layers: false })}
                  hideClose={isMockupMode}
                />
              </Header>
              <ScrollContent>
                <TabContent>
                  <LayersMenu
                    layers={props.layers}
                    selectedLayers={props.selectedLayers}
                    dispatch={props.dispatch}
                  />
                </TabContent>
              </ScrollContent>
            </LayersWrapper>
          )}
        </Transition>
      </Body>
    </Wrapper>
  );
};

RightMenu.defaultProps = {};

export default React.memo(RightMenu);
