import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tree from 'rc-tree';

import { Wrapper, LayersWrapper } from './styles';
import { defaultTheme } from './theme';
import DropIndicator from './utilities/DropIndicator';
import LayerIcon from './utilities/LayerIcon';
import GroupSwitch from './utilities/GroupSwitch';
import Layer from './Layer/Layer';
import DecisionModal from '../../components/DecisionModal/DecisionModal';
import {
  transformRecursive,
  findRecursive,
  loopRecursive,
} from '../../utils/groupStructure/recursive';
import {
  LAYER_MOVE,
  LAYER_SELECTION,
  ACTIVE_DELETE,
  LAYER_RENAME,
  LAYER_EDITING_NAME,
} from '../../global/events';
import { getElementById, getParent } from '../../utils/groupStructure';
import LayerSelection from './utilities/LayerSelection';
import { SPECIAL_LAYERS } from '../../global/constants';
import { Layer as LayerType } from '../../propTypes';

const BACKSPACE = 8;
const DELETE = 46;
const DELETE_KEYS = [BACKSPACE, DELETE];
const LAYERS_LIMIT = 500;

/**
 * preprocess layers to be used in the LayersMenu component
 * add attributes key, title, and groupId
 */
const preprocessLayers = (layers) => {
  const gData = transformRecursive(layers, (item) => {
    const key = item.id;
    const title = item.name || `(${item.type})`;
    const newItem = { ...item, key, title };

    if (item.children?.length) {
      return {
        ...newItem,
        children: item.children.map((c) => {
          return {
            ...c,
            groupId: item.id,
          };
        }),
      };
    }
    return newItem;
  });

  return { gData };
};

/**
 * hide some items in layers from beeing displayed
 */
const filterLayers = (layers) => {
  return transformRecursive(layers, (item) => {
    if (item.children?.length) {
      return {
        ...item,
        children: item.children.filter((c) => !c.hide),
      };
    }
    return item;
  });
};

/**
 * The LayersMenu is a component that can display a tree view of layers and allows the user to interact with it.
 */
const LayersMenu = ({
  layers,
  selectedLayers,
  dispatch,
  theme: themeFromProps,
}) => {
  const theme = { ...defaultTheme, ...themeFromProps };

  // Stores a layer selection helper object for manual selection of layers
  const manualSelection = useRef(new LayerSelection());

  const [state, setState] = useState(() => {
    const { gData } = preprocessLayers(layers);
    return {
      gData,
      selectedKeys: selectedLayers,
      expandedKeys: [],
    };
  });
  const lastCount = useRef();
  const [modalOpen, setModalOpen] = useState(false);

  const { gData, selectedKeys, expandedKeys } = state;

  const currentlySelectedKeys = useRef(state?.selectedKeys);
  useEffect(() => {
    currentlySelectedKeys.current = state?.selectedKeys;
  }, [state?.selectedKeys]);

  const updateLayers = useCallback(() => {
    const { gData } = preprocessLayers(layers);

    if (selectedLayers && currentlySelectedKeys.current) {
      // Compare incoming selection
      // If the change in props comes from dispatch in this component, we assume that selections will match

      const selectionToString = (selection) => {
        return selection.sort((a, b) => a.localeCompare(b)).join('');
      };
      const current = selectionToString(currentlySelectedKeys.current);
      const incoming = selectionToString(selectedLayers);

      if (current !== incoming) {
        // Selections are different, meaning the manualSelection object is outdated
        // To handle this, we get the indexes of the newly selected keys and pretend that
        // they were multi selected in ascending order
        manualSelection.current.reset();

        const shouldPopulate =
          selectedLayers.length &&
          !selectedLayers.some((sl) => SPECIAL_LAYERS.includes(sl));

        if (shouldPopulate) {
          const [firstItem] = selectedLayers;
          const parent = findRecursive(
            gData,
            ({ children }) =>
              children && children.find((child) => child.key === firstItem)
          );
          const childrenKeys = (parent?.children || gData).map(
            (child) => child.key
          );
          const indexes = selectedLayers
            .map((sl) => childrenKeys.indexOf(sl))
            .sort((a, b) => a - b);
          indexes.forEach((index) => {
            manualSelection.current.multiSelect(index);
          });
        }
      }
    }

    const parents = selectedLayers
      .map((layer) => {
        const parent = getParent({ children: layers }, layer);
        return parent?.id;
      })
      .filter((id) => id);
    setState((prevState) => {
      return {
        ...prevState,
        gData,
        selectedKeys: selectedLayers,
        expandedKeys: [...new Set([...prevState.expandedKeys, ...parents])],
      };
    });
  }, [layers, selectedLayers]);

  useEffect(() => {
    updateLayers();
  }, [updateLayers]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    if (!layers.length) return;
    if (lastCount.current < LAYERS_LIMIT && layers.length >= LAYERS_LIMIT) {
      setModalOpen(true);
    }
    lastCount.current = layers.length;
  }, [layers?.length]);

  const layersMenuRef = useRef(null);

  const handleKeyDown = (event) => {
    // if no element is active (tagName = body) or the active element is in the layers menu (but not an input)
    if (
      event.target.tagName === 'BODY' ||
      (event.target.tagName !== 'INPUT' &&
        layersMenuRef.current.contains(event.target))
    ) {
      if (DELETE_KEYS.includes(event.keyCode)) {
        dispatchEvent(ACTIVE_DELETE);
      }
    }
  };

  const dispatchEvent = useCallback(
    (event, value) => {
      dispatch(event, value);
    },
    [dispatch]
  );

  /**
   * utility function to build a new active selection array, based on the current state and the event
   */
  const getNewSelection = useCallback(
    (e, item) => {
      // only add to selection, if items are in the same group
      const firstItem = findRecursive(
        gData,
        ({ key }) => key === selectedKeys[0]
      );

      let parent;
      if (item.groupId) {
        parent = findRecursive(gData, ({ key }) => key === item.groupId);
      }

      const groupChildren = parent?.children || gData;
      const itemIndex = groupChildren.findIndex(
        (child) => child.key === item.key
      );

      if (firstItem?.groupId !== item.groupId) {
        manualSelection.current.normalSelect(itemIndex);
      } else {
        // multi selection is now possible
        const selectType = e.shiftKey
          ? 'bulk'
          : e.metaKey || e.ctrlKey
          ? 'multi'
          : 'normal';
        switch (selectType) {
          case 'normal':
            manualSelection.current.normalSelect(itemIndex);
            break;
          case 'multi':
            manualSelection.current.multiSelect(itemIndex);
            break;
          case 'bulk':
            manualSelection.current.bulkSelect(itemIndex);
            break;
          default:
            break;
        }
      }

      return manualSelection.current
        .toSelectionList()
        .map((index) => groupChildren[index]?.key)
        .filter((child) => child);
    },
    [gData, selectedKeys]
  );

  // function(selectedKeys, e:{selected: bool, selectedNodes, node, event, nativeEvent})
  const onSelect = useCallback(
    (keys, e) => {
      const { node, nativeEvent } = e;

      const selectedKeys = getNewSelection(nativeEvent, node);

      setState((prevState) => ({
        ...prevState,
        selectedKeys,
      }));

      dispatchEvent(LAYER_SELECTION, {
        ids: selectedKeys,
      });
    },
    [getNewSelection, setState, dispatchEvent]
  );

  const onDragStart = useCallback(
    ({ node }) => {
      const nodeIsSelected = selectedKeys.includes(node.key);
      let updateSelection = {};
      if (!nodeIsSelected) {
        const newSelectedKeys = [node.key];
        const newExpandedKeys = expandedKeys.filter(
          (key) => !newSelectedKeys.includes(key)
        );

        updateSelection = {
          selectedKeys: newSelectedKeys,
          expandedKeys: newExpandedKeys,
        };

        dispatchEvent(LAYER_SELECTION, {
          ids: newSelectedKeys,
        });
      } else {
        const newExpandedKeys = expandedKeys.filter(
          (key) => !selectedKeys.includes(key)
        );

        updateSelection = { expandedKeys: newExpandedKeys };
      }

      setState((prevState) => ({
        ...prevState,
        ...updateSelection,
        isDragging: true,
        draggingKey: node.key,
      }));
    },
    [expandedKeys, selectedKeys, dispatchEvent]
  );

  /**
   * function to handle rebuilding the layers tree after an element was dragged
   * see also https://github.com/react-component/tree/blob/master/examples/draggable.jsx#L23
   * @param {*} info
   */
  const onDrop = useCallback(
    (info) => {
      const dropKey = info.node.key;
      const dropPos = info.node.pos.split('-');
      const dropPosition =
        info.dropPosition - Number(dropPos[dropPos.length - 1]);

      const dropItem = getElementById({ children: layers }, dropKey);
      const dropParent = getParent({ children: layers }, dropKey);

      // don't allow to drag into a clipping mask group
      if (
        (dropItem?.type === 'clippingMask' && dropPosition === 0) ||
        dropParent?.type === 'clippingMask'
      ) {
        return false;
      }

      const data = [...gData];

      // Find dragObjects
      const dragObjs = [];
      selectedKeys.forEach((key) => {
        loopRecursive(data, key, (item, index, arr) => {
          arr.splice(index, 1); // remove from data
          dragObjs.push(item); // store in dragObjs
        });
      });

      if (dropPosition === 0) {
        // Drop on the content
        loopRecursive(data, dropKey, (item) => {
          item.children.unshift(...dragObjs);
        });
      } else {
        // Drop on the gap (insert before or insert after)
        let ar;
        let i;
        let itm;
        loopRecursive(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
          itm = item;
        });

        if (!itm) {
          // if no item was found, throw a meaningful error
          throw new Error({
            message: 'No item was found to drop the layers on',
            data: {
              data,
              dropKey,
              state,
            },
          });
        }

        const taggedDragObjs = dragObjs.map((obj) => {
          return {
            ...obj,
            groupId: itm.groupId,
          };
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, ...taggedDragObjs);
        } else {
          ar.splice(i + 1, 0, ...taggedDragObjs);
        }
      }
      setState((prevState) => ({
        ...prevState,
        gData: data,
        isDragging: false,
        draggingKey: undefined,
      }));

      dispatchEvent(LAYER_MOVE, { structure: data, selectedKeys });
    },
    [gData, selectedKeys, dispatchEvent, layers, state]
  );

  const onExpand = useCallback((expandedKeys) => {
    setState((prevState) => {
      return {
        ...prevState,
        expandedKeys,
      };
    });
  }, []);

  const allowDrop = useCallback(
    ({ dropNode, dropPosition }) => {
      if (dropPosition === 0 && !dropNode.children?.length) {
        // do not allow to create new groups via drop
        return false;
      }

      // do not allow to drop the selected items on themselves
      return !selectedKeys.includes(dropNode.key);
    },
    [selectedKeys]
  );

  const onEditingLayerName = useCallback(
    (value) => {
      /**
       * This will hold auto saving while user is editing layer names
       * to avoid jumps in edit mode
       */
      dispatchEvent(LAYER_EDITING_NAME, { isEditing: value });
    },
    [dispatchEvent]
  );

  const onLayerNameChange = useCallback(
    (layerId, newLayerName) => {
      /*
        If empty string, set name to null (for most objects this will default to `(${object.type})`,
        but for pathText it is handled internally and will use substituteAlternativeGlyphs to resolve the name based on the text
      */
      const newName = newLayerName.length ? newLayerName : null;
      dispatchEvent(LAYER_RENAME, {
        layerId,
        newLayerName: newName,
      });
    },
    [dispatchEvent]
  );

  const switcherIcon = useCallback(
    (object) => !object.isLeaf && <GroupSwitch expanded={object.expanded} />,
    []
  );

  const titleRender = useCallback(
    (node) => (
      <Layer
        id={node.id}
        title={node.title}
        type={node.type}
        hidden={node.hidden}
        locked={node.locked}
        disabled={node.disabled}
        dispatch={dispatch}
        onIsEditMode={onEditingLayerName}
        onNameChange={onLayerNameChange}
      />
    ),
    [dispatch, onEditingLayerName, onLayerNameChange]
  );

  const iconRender = useCallback(
    ({ data: { type, children }, expanded, selected }) => (
      <LayerIcon
        type={type}
        hasChildren={children?.length > 0}
        expanded={expanded}
        selected={selected}
      />
    ),
    []
  );

  return (
    <>
      <Wrapper ref={layersMenuRef}>
        <LayersWrapper {...theme}>
          <Tree
            selectedKeys={selectedKeys}
            treeData={filterLayers(gData)}
            expandedKeys={expandedKeys}
            allowDrop={allowDrop}
            draggable
            multiple
            onSelect={onSelect}
            onDragStart={onDragStart}
            onDrop={onDrop}
            onExpand={onExpand}
            switcherIcon={switcherIcon}
            icon={iconRender}
            titleRender={titleRender}
            dropIndicatorRender={DropIndicator}
          />
        </LayersWrapper>
      </Wrapper>
      <DecisionModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Attention"
        message="You have reached 500 layers in your project, it's recommended to use
          less layers to improve your user experience."
        firstButton={{
          label: 'Got it!',
          type: 'primary',
          onClick: () => setModalOpen(false),
        }}
      />
    </>
  );
};

LayersMenu.propTypes = {
  /**
   * Layer options. Each layer can have more layers in children
   */
  layers: PropTypes.arrayOf(LayerType),
  /**
   * A list if ids that indicate which layers are currently selected
   */
  selectedLayers: PropTypes.arrayOf(PropTypes.string),
  /**
   * A function that is triggered to dispatch an event
   */
  dispatch: PropTypes.func,
  theme: PropTypes.object,
};

LayersMenu.defaultProps = {
  layers: [],
  selectedLayers: [],
};

export default React.memo(LayersMenu);
