import React, { useCallback } from 'react';
import { DesignCommentNotification } from '../../../../types';

import { buildImageProxyUrl, WEBSITE_URL } from '../../../../utils/url';
import NotificationHeader from '../NotificationHeader/NotificationHeader';
import PreviewImage from '../PreviewImage/PreviewImage';
import {
  NotificationWrapper,
  Bubble,
  BubbleTextWrapper,
  BubbleText,
} from './styles';
import { NotificationProps } from './types';

const CommentNotification: React.FC<
  NotificationProps<DesignCommentNotification>
> = ({ notification, onReadNotification }) => {
  const message = 'commented your design';

  const handleClick = useCallback((): void => {
    onReadNotification(notification.id);
    window.open(
      `${WEBSITE_URL || ''}/${notification.data.path}`,
      WEBSITE_URL ? '_blank' : '_self'
    );
  }, [onReadNotification, notification]);

  return (
    <NotificationWrapper role="button" onClick={handleClick}>
      <NotificationHeader
        user={notification.relatedUser}
        message={message}
        createdAt={notification.createdAt}
        isUnseen={!notification.seenAt}
      />
      <Bubble>
        <PreviewImage
          noMargin
          key={notification.data.postId}
          src={buildImageProxyUrl(notification.data.templatePreviewObjectName, {
            height: 70,
            width: 0,
            scale: 2,
          })}
        />
        <BubbleTextWrapper>
          <BubbleText>{`"${notification.data.comment}"`}</BubbleText>
        </BubbleTextWrapper>
      </Bubble>
    </NotificationWrapper>
  );
};

export default CommentNotification;
