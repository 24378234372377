import fabric from '../index';
import { WarpControlHandleDirection } from './types';
import { themeStyle } from '../../../../services/theming';

export const warpControlPointDefaultOptions = {
  originX: 'center',
  originY: 'center',
  // `radius` doesn't matter because the point is rendered with custom function,
  // to have a consistent size regardless zoom level.
  // It's set close to `0` to avoid side effects on dimension when scaling;
  // but it cannot be `0` bacause shift + scale won't work for group selection that has width/height equals 0.
  radius: 0.000001,
  stroke: themeStyle.selectionBlue,
  // we don't want any controls or borders for a single warp control point
  hasControls: false,
  hasBorders: false,
  // for this simple object, render it directly is faster
  objectCaching: false,
};

export const warpControlRootPointObjectType = 'warpControlRootPoint';
export const warpControlHandlePointObjectType = 'warpControlHandlePoint';

const warpControlPointNamePrefix = 'warpControlPoint';
export const createWarpControlPointName = (
  i: number,
  j: number,
  handleDirection?: WarpControlHandleDirection
): string =>
  `${warpControlPointNamePrefix}_${i}_${j}${
    handleDirection ? `_${handleDirection}` : ''
  }`;

export const getWarpControlPoint = (
  canvas: fabric.Canvas,
  i: number,
  j: number,
  handleDirection?: WarpControlHandleDirection
): fabric.WarpControlRootPoint | fabric.WarpControlHandlePoint | undefined => {
  const name = createWarpControlPointName(i, j, handleDirection);
  return canvas
    .getObjects()
    .find((object: fabric.Object): boolean => object.name === name);
};

export const getWarpControlPoints = (
  canvas: fabric.Canvas
): (fabric.WarpControlRootPoint | fabric.WarpControlHandlePoint)[] =>
  canvas
    .getObjects()
    .filter(
      (object: fabric.Object): boolean =>
        object.type === warpControlRootPointObjectType ||
        object.type === warpControlHandlePointObjectType
    );

export const addWarpControlPoint = (
  canvas: fabric.Canvas,
  point: fabric.WarpControlRootPoint | fabric.WarpControlHandlePoint,
  isHandle = false
): void => {
  canvas.add(point);
  // only add root point in group structure, because
  // we don't want handles to be direct targets of multi-object selection
  if (!isHandle) {
    canvas.groupStructure.children.push({ id: point.id });
  }
};

export const removeWarpControlPoints = (canvas: fabric.Canvas): void => {
  const warpControlPoints = getWarpControlPoints(canvas);
  Object.values(warpControlPoints).forEach((object: fabric.Object) => {
    canvas.remove(object);
  });
  canvas.updateGroupStructure();
};

export const toggleWarpControlPointsVisibility = (
  canvas: fabric.Canvas,
  visible: boolean
): void => {
  const warpControlPoints = getWarpControlPoints(canvas);
  Object.values(warpControlPoints).forEach((object: fabric.Object) => {
    object.set('visible', visible);
  });
  canvas.requestRenderAll();
};

/**
 * Draw a warp control points with given context.
 * The size is consistent regardless the zoom level (with a radius of 5 and stroke width of 1).
 */
export const drawWarpControlPoint = (
  ctx: CanvasRenderingContext2D,
  canvas: fabric.Canvas,
  object: fabric.WarpControlRootPoint | fabric.WarpControlHandlePoint
): void => {
  const vpt = canvas.viewportTransform;
  const center = object.getAbsoluteCenterPoint();
  ctx.save();
  ctx.translate(center.x, center.y);
  ctx.scale(1 / vpt[0], 1 / vpt[3]);
  ctx.fillStyle = object.fill;
  ctx.strokeStyle = object.stroke;
  ctx.lineWidth = 1;
  ctx.beginPath();
  ctx.arc(0, 0, 5, 0, 2 * Math.PI, false);
  ctx.fill();
  ctx.stroke();
  ctx.restore();
};

/**
 * Draw a grid segment or a handle line.
 * The size is consistent regardless the zoom level (with stroke with of 1).
 * @param ctx
 * @param canvas
 * @param path
 */
export const drawGirdOrHandleLine = (
  ctx: CanvasRenderingContext2D,
  canvas: fabric.Canvas,
  path: fabric.Path
): void => {
  ctx.save();
  const vpt = canvas.viewportTransform;
  // scale x and scale y should be the same, here we take the former
  ctx.lineWidth = 1 / vpt[0];
  ctx.strokeStyle = path.stroke;
  path._renderPathCommands(ctx);
  ctx.stroke();
  ctx.restore();
};

export type SerialisedWarpControlPoints = Record<
  string,
  { x: number; y: number }
>;

export const serialiseWarpControlPoints = (
  canvas: fabric.Canvas
): SerialisedWarpControlPoints | null => {
  const warpControlPoints = getWarpControlPoints(canvas);

  return warpControlPoints.length > 0
    ? warpControlPoints.reduce(
        (
          acc: SerialisedWarpControlPoints,
          point: fabric.WarpControlRootPoint | fabric.WarpControlHandlePoint
        ) => {
          const key = `${point.i}_${point.j}${
            point.direction ? `_${point.direction}` : ''
          }`;
          acc[key] = { x: point.left, y: point.top };
          return acc;
        },
        {}
      )
    : null;
};
