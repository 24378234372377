import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import Api from '../global/api';
import { DEFAULT_TAKE } from '../global/constants';
import { uniques } from '../utils';

export const photosStore = createVanilla((set, get) => ({
  elements: [],
  query: '',
  trending: [],
  searches: {},
  take: DEFAULT_TAKE,
  skip: 0,
  isFetching: false,
  finished: false,

  fetch: async () => {
    const { query, take, skip, isFetching, finished } = get();

    if (isFetching) return;
    if (finished) return;

    const querySanitized = query.replace(/[&/\\#,+()$~%.'":*?<>{}@!^]/g, '');

    set({ isFetching: true });

    if (querySanitized.length) {
      await get().searchPhotos(querySanitized, take, skip);
    } else {
      await get().fetchTrending(take, skip);
    }
  },

  fetchTrending: async (take = DEFAULT_TAKE, skip = 0) => {
    const trending = get().trending;
    if (trending.length >= take + skip) {
      set({ elements: trending, skip: trending.length, isFetching: false });
      return;
    }

    const cmsResponse = await Api.getTrendingPhotos(take, skip);
    if (!cmsResponse.results?.length || cmsResponse.error) {
      set({
        isFetching: false,
        finished: true,
      });
      return;
    }

    const elements = uniques([...trending, ...cmsResponse.results]);
    set({
      trending: elements,
      elements,
      skip: skip + take,
      isFetching: false,
      finished: cmsResponse.results.length < take,
    });
  },

  searchPhotos: async (query, take = DEFAULT_TAKE, skip = 0) => {
    const searches = { ...get().searches };

    if (searches[query] && searches[query].length >= take + skip) {
      set({ elements: searches[query], skip: skip + take, isFetching: false });

      return;
    }

    const cmsResponse = await Api.searchUnsplashPhotos(query, take, skip);
    if (!cmsResponse.results?.length || cmsResponse.error) {
      return;
    }

    if (searches[query] && searches[query].length) {
      searches[query] = uniques([...searches[query], ...cmsResponse.results]);
    } else {
      searches[query] = cmsResponse.results;
    }

    if (
      (cmsResponse.total && take + skip >= cmsResponse.total) ||
      cmsResponse.results.length < take
    ) {
      set({ finished: true });
    }

    set({
      searches: searches,
      elements: searches[query],
      skip: skip + take,
      isFetching: false,
    });
  },

  setQuery: async (newQuery) => {
    set({ elements: [] });
    set({ query: newQuery });
    set({ skip: 0 });
    set({ finished: false });
    set({ isFetching: false });
  },
}));

const usePhotosStoreStore = create(photosStore);

export const photosStoreSelector = {
  photosQuery: (state) => ({ query: state.query, setQuery: state.setQuery }),
};

export default usePhotosStoreStore;
