import styled from 'styled-components';
import { themeStyle } from '../../../services/theming';
import { darkBackgroundBlur } from '../styles';
import { P3 } from '../Typography/styles';

export const OverlayLabel = styled(P3)`
  position: absolute;
  left: ${({ width }) => width}; /* same as 100% */
  padding-left: 15px;

  color: ${themeStyle.varInkMain};
  visibility: hidden;
  opacity: 0;
  transform: translateX(-10px);
  transition: all ${({ transition }) => transition};
  transition-property: transform, opacity, visibility;
  min-width: 75px;
  white-space: nowrap;
`;

// This hit-box is used to keep the overlay open, when the user hovers between different labels.
export const OverlayHitbox = styled.div`
  position: absolute;
  left: ${({ width }) => width}; /* same as 100% */
  top: 0px;
  height: 100%;
  min-width: 75px; /* width of the widest label (Templates) */

  visibility: hidden;
  transition: visibility ${({ transition }) => transition};
`;

export const Navigation = styled.div`
  position: absolute;
  left: 0px;
  width: ${({ width }) => width};
  flex-shrink: 0;
  background-color: ${(props) =>
    props.state === 'closed' || props.menuIsMoving
      ? themeStyle.varBackgroundAlt
      : themeStyle.varBackgroundAltTransparent};
  ${(props) =>
    props.state === 'closed' || props.menuIsMoving ? darkBackgroundBlur : ''}
  transition: ${({ transition }) =>
    `box-shadow ${transition}, opacity ${transition}`};
  box-shadow: ${(props) =>
    props.state === 'closed' ? themeStyle.varShadowMedium : 'none'};
  border-radius: 0px ${themeStyle.radiusLarge} ${themeStyle.radiusLarge} 0px;
  z-index: ${themeStyle.zIndexS1};

  &:hover ${OverlayLabel} {
    visibility: ${(props) => (props.state === 'closed' ? 'visible' : 'hidden')};
    transform: translateX(
      ${(props) => (props.state === 'closed' ? '0' : '-10')}px
    );
    opacity: ${(props) => (props.state === 'closed' ? '1' : '0')};
  }

  &:hover ${OverlayHitbox} {
    visibility: ${(props) => (props.state === 'closed' ? 'visible' : 'hidden')};
  }

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
`;

export const NavButton = styled.div`
  display: flex;
  justify-content: center;

  padding-top: 11px;
  padding-bottom: 11px;
`;

export const NavButtonWrapper = styled.div`
  margin: auto;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  position: relative;

  &:first-child ${NavButton} {
    padding-top: 15px;
  }

  &:last-child ${NavButton} {
    padding-bottom: 15px;
  }

  &:hover ${OverlayLabel} {
    color: ${({ disabled }) =>
      disabled ? themeStyle.varInkMain : themeStyle.varBrandMain};
  }
`;
