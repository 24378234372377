import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import { ContextProvider } from './components/context';
import useErrorHandling from './hooks/useErrorHandling';
import Modal from './components/Modal/Modal';
import GenericErrorPanel from './components/GenericErrorPanel/GenericErrorPanel';
import OldBrowserWarning from './components/OldBrowserWarning/OldBrowserWarning';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { AppWrapper } from './appStyles';
import { Message } from './components/utilities/styles';
import AppContent from './AppContent';
import { cookiesAreEnabled } from './utils/detection';
import analytics from './global/analytics';
import { GrowthbookProvider } from './services/growthbook';
import ToastQueue from './components/Toasts/ToastQueue';
import isOldBrowser from './utils/detection/isOldBrowser';
import { ThemeVariables } from './services/theming';

const App = () => {
  const [error, setError] = useState(false);
  const [oldBrowserWarningModalOpen, setOldBrowserWarningModalOpen] =
    useState(false);
  const { error: _error } = useErrorHandling();

  useEffect(() => {
    setError(_error);
  }, [_error]);

  useEffect(() => {
    analytics.page();
  }, []);

  useEffect(() => {
    if (isOldBrowser()) setOldBrowserWarningModalOpen(true);
  }, []);

  const rootContainer = useRef();
  const [mounted, setMounted] = useState(false);
  const setRootNode = (node) => {
    rootContainer.current = node;
    setMounted(true);
  };

  if (!cookiesAreEnabled()) {
    return (
      <GrowthbookProvider>
        <AppWrapper>
          <Message>{'Please enable cookies to use this site.'}</Message>
        </AppWrapper>
      </GrowthbookProvider>
    );
  }

  const onOldBrowserWarningModalClose = () => {
    setOldBrowserWarningModalOpen(false);
  };

  return (
    <GrowthbookProvider>
      <ThemeVariables />
      <ContextProvider
        value={{
          getRootContainer: () => {
            return rootContainer.current ?? null;
          },
        }}
      >
        <Modal isOpen={mounted && error && !oldBrowserWarningModalOpen}>
          <GenericErrorPanel />
        </Modal>
        <Modal
          isOpen={mounted && oldBrowserWarningModalOpen}
          onClose={onOldBrowserWarningModalClose}
        >
          <OldBrowserWarning />
        </Modal>
        <AppWrapper ref={setRootNode}>
          <ErrorBoundary onError={() => setError(true)}>
            <AppContent rootContainer={rootContainer} />
          </ErrorBoundary>
        </AppWrapper>
      </ContextProvider>
      <ToastQueue />
    </GrowthbookProvider>
  );
};

export default App;
