import { fabric } from 'fabric';
import { normalizeGradients } from '../../components/Artboard/fabric/SVGPreprocess';
import useNetworkStore from '../../stores/networkStore';
import { FETCH_RETRIES, FETCH_RETRY_DELAY } from '../fetchRetry';

export const illustrationsCache = {};
const illustrationsLoading = {};

// asyncify default fabric clone
export const clone = async (o) => {
  return new Promise((resolve) => {
    o.clone(resolve);
  });
};

/**
 * handle loading an svg file for an illustration from cache
 */
export const loadSVG = async (src) => {
  if (illustrationsCache[src]) {
    return Promise.all(illustrationsCache[src].map(clone));
  }
  return new Promise((resolve) => {
    if (illustrationsLoading[src]) {
      return illustrationsLoading[src].push({ resolve });
    }
    illustrationsLoading[src] = [{ resolve }];
    _loadSVG(src, resolve);
  });
};

/**
 * load svg from a src file and retry if something goes wrong
 */
const _loadSVG = (src, resolve, retryCount = 0) => {
  fabric.loadSVGFromURL(
    src,
    async (paths) => {
      if (!paths) {
        if (retryCount < FETCH_RETRIES) {
          // if something went wrong in `loadSVGFromURL`
          setTimeout(
            () => _loadSVG(src, resolve, retryCount + 1),
            FETCH_RETRY_DELAY
          );
        } else {
          if (!navigator.onLine) {
            useNetworkStore.getState().setStatus(false);
            delete illustrationsLoading[src];
          }
          // don't error when fetch fails
          illustrationsLoading[src].forEach(({ resolve }) => resolve([]));
          resolve([]);
        }
        return;
      }

      normalizeGradients(paths);

      // Add clones of paths to cache, otherwise new Illustrations will
      // have wrong colors and other issues
      illustrationsCache[src] = await Promise.all(paths.map(clone));
      illustrationsLoading[src].forEach(({ resolve }) =>
        resolve(Promise.all(paths.map(clone)))
      );
      delete illustrationsLoading[src];
      resolve(paths);
    },
    null,
    { crossOrigin: 'anonymous' } // NOTE: fixes CU-ff6rj6
  );
};
