import React from 'react';
import { defaultTheme } from './theme';
import { Box } from './styles';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';

const Checkbox = (props) => {
  const theme = { ...defaultTheme, ...props.theme };

  return (
    <Box {...theme} checked={props.value} onClick={props.onClick}>
      {props.value && (
        <Icon
          name="check"
          height={theme.checkIconSize}
          theme={{ color: theme.checkColor }}
        />
      )}
    </Box>
  );
};

Checkbox.propTypes = {
  /**
   * Value of checkbox
   */
  value: PropTypes.bool,
  /**
   * Theme Object
   */
  theme: PropTypes.shape({
    size: PropTypes.string,
    borderColor: PropTypes.string,
    defaultColor: PropTypes.string,
    checkColor: PropTypes.string,
    checkedColor: PropTypes.string,
    checkIconSize: PropTypes.string,
    borderRadius: PropTypes.string,
  }),
  /**
   * onClick action
   */
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  value: false,
};

export default Checkbox;
