import { isOnDevEnvironment } from '../../../global/environment';
import { STANDARD_UNITS_PER_EM } from '../../../font/util';
import { themeStyle } from '../../../services/theming';
import { useMenuStore } from '../../../stores/menuStore';

export default function (fabric) {
  // allow re-initialize on dev environment to prevent issues with fast refresh
  if (fabric.CustomTextbox && !isOnDevEnvironment()) {
    fabric.warn('fabric.CustomTextbox is already defined');
    return;
  }
  /**
   * CustomTextbox class
   * Instances of this class are used as text inputs for pathText.
   * @class fabric.CustomTextbox
   * @extends fabric.Textbox
   */
  fabric.CustomTextbox = fabric.util.createClass(fabric.Textbox, {
    type: 'CustomTextbox',

    initialize: function (pathText, options) {
      options || (options = {});

      // get options from pathText object
      const pathTextOptions = this.getOptionsFromPathText(pathText);

      const defaultOptions = {
        originX: 'left',
        originY: 'top',
        excludeFromExport: true,
        editingBorderColor: themeStyle.selectionBlue,
        selectionColor: themeStyle.selectionBlue30,
      };

      const text = pathText.uppercase
        ? pathText.text.toUpperCase()
        : pathText.text;

      // initialize object with extracted options
      this.callSuper('initialize', text, {
        ...defaultOptions,
        ...pathTextOptions,
        ...options,
      });

      this.on('editing:entered', () =>
        useMenuStore.getState().setShowObjectWidgets(false)
      );
      this.on('editing:exited', () =>
        useMenuStore.getState().setShowObjectWidgets(true)
      );

      return this;
    },

    getOptionsFromPathText: function (pathText) {
      const pathTextRect = pathText.getBoundingRect(true);
      const { scale: fontScale } = pathText.getTextScale();
      const pathTextOptions = {
        top: pathTextRect.top,
        left: pathTextRect.left,
        selectionStart: pathText.text.length,
        selectionEnd: pathText.text.length,
        fill: pathText.fill,
        fontSize: pathText.fontSize * fontScale,
        underline: pathText.underline,
        linkedToObject: pathText.id,
        textAlign: pathText.textAlignment,
        charSpacing: pathText.letterSpacing,
        lineHeight: 1 + pathText.lineHeight / STANDARD_UNITS_PER_EM,
        width: pathText.width * pathText.scaleX + 1,
      };
      if (!pathText.singleline) {
        pathTextOptions.splitByGrapheme = false; // This makes the text wrap at whitespaces level
      }

      return pathTextOptions;
    },

    /**
     * Insert a new glyph at the current start of the selection.
     * @param {String} glyph
     */
    insertGlyph: function (glyph) {
      const styleObjects = glyph.split('').map(() => ({})); // array of style objects
      this.insertChars(glyph, styleObjects, this.selectionStart);
      this.selectionStart++;
      this.selectionEnd++;

      // if not updated, the hiddenTextarea would overwrite text on the next input
      this.hiddenTextarea.value = this.text;
      this._updateTextarea();
    },

    /**
     * We override `renderCursorOrSelection` to render text on top of selection
     * @override
     */
    renderCursorOrSelection: function () {
      if (!this.isEditing || !this.canvas || !this.canvas.contextTop) {
        return;
      }
      const boundaries = this._getCursorBoundaries();
      const ctx = this.canvas.contextTop;
      this.clearContextTop(true);
      if (this.selectionStart === this.selectionEnd) {
        this.renderCursor(boundaries, ctx);
      } else {
        this.renderSelection(boundaries, ctx);
        // Render text on top to make it visible when background is same color as text
        this.callSuper('_render', ctx);
      }

      ctx.restore();
    },

    /**
     * Inserts style object(s)
     *
     * This function is overwritten to add a fix from a newer fabric version
     * see: https://github.com/fabricjs/fabric.js/pull/7526/files
     *
     * @param {Array} insertedText Characters at the location where style is inserted
     * @param {Number} start cursor index for inserting style
     * @param {Array} [copiedStyle] array of style objects to insert.
     */
    insertNewStyleBlock: function (insertedText, start, copiedStyle) {
      const cursorLoc = this.get2DCursorLocation(start, true),
        addedLines = [0];
      let linesLength = 0;
      // get an array of how many char per lines are being added.
      for (let i = 0; i < insertedText.length; i++) {
        if (insertedText[i] === '\n') {
          linesLength++;
          addedLines[linesLength] = 0;
        } else {
          addedLines[linesLength]++;
        }
      }
      // for the first line copy the style from the current char position.
      if (addedLines[0] > 0) {
        this.insertCharStyleObject(
          cursorLoc.lineIndex,
          cursorLoc.charIndex,
          addedLines[0],
          copiedStyle
        );
        copiedStyle = copiedStyle && copiedStyle.slice(addedLines[0] + 1);
      }
      linesLength &&
        this.insertNewlineStyleObject(
          cursorLoc.lineIndex,
          cursorLoc.charIndex + addedLines[0],
          linesLength
        );
      for (let i = 1; i < linesLength; i++) {
        if (addedLines[i] > 0) {
          this.insertCharStyleObject(
            cursorLoc.lineIndex + i,
            0,
            addedLines[i],
            copiedStyle
          );
        } else if (copiedStyle) {
          // this test is required in order to close #6841          // <---- this block is what got changed
          // when a pasted buffer begins with a newline then
          // this.styles[cursorLoc.lineIndex + i] and copiedStyle[0]
          // may be undefined for some reason
          if (this.styles[cursorLoc.lineIndex + i] && copiedStyle[0]) {
            this.styles[cursorLoc.lineIndex + i][0] = copiedStyle[0];
          }
        }
        copiedStyle = copiedStyle && copiedStyle.slice(addedLines[i] + 1);
      }

      const index = linesLength - 1;
      if (addedLines[index] > 0) {
        this.insertCharStyleObject(
          cursorLoc.lineIndex + index,
          0,
          addedLines[index],
          null // we ignore copiedStyle style
        );
      }
    },

    /**
     * We override `_clearTextArea` to clean most of the canvas
     * and remove artifacts from selection and cursor
     * @param {*} ctx - upper canvas context
     * @override
     */
    _clearTextArea: function (ctx) {
      // we use this.canvas.width and this.canvas.height, to be sure not to leave any pixel out
      const width = this.canvas.width;
      const height = this.canvas.height;
      ctx.clearRect(-width / 2, -height / 2, width, height);
    },
  });
}
