import { useState, useEffect, useCallback } from 'react';
import { OfflineError } from '../utils/fetchRetry';
import monitoring from '../services/monitoring';

// More about why this error is mostly benign and why it can be ignored:
// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
// https://github.com/WICG/resize-observer/issues/38#issuecomment-422126006
// Here's the task if it needs more attention: https://app.clickup.com/t/2cq7qk1
const isResizeObserverLoopError = (event) =>
  event?.message?.includes('ResizeObserver loop limit exceeded') || // Chrome
  event?.message?.includes(
    'ResizeObserver loop completed with undelivered notifications' // Firefox
  );

// When Safari encounters a ResizeObserver loop error, there is no specific message other than "'Script error'".
const isAmbiguousScriptError = (event) =>
  event?.message?.includes('Script error');

// this might return weird responses
// we don't need to let the user know if this fails
const isAnalyticsEventsError = (event) =>
  event?.filename?.includes('connect.facebook.net') ||
  event?.filename?.includes('clarity.ms') ||
  event?.filename?.includes('analytics.tiktok');

const ignoreEvent = (event) => {
  if (isResizeObserverLoopError(event)) return true;
  if (isAmbiguousScriptError(event)) return true;
  if (isAnalyticsEventsError(event)) return true;
  return false;
};

/* This hook is not intended to be re-usable. It should only be used at App level */
const useErrorHandling = () => {
  const [error, setError] = useState(false);

  const handleError = useCallback((event) => {
    const ignored = ignoreEvent(event);

    // To prevent error from being reported twice, here and inside ErrorBoundary
    if (!event?.error?.__sentry_captured__) {
      const message = event?.message || 'Error with no message was caught';
      const filename = event?.filename;
      monitoring.captureException(message, { filename, ignored });
    }

    if (ignored) return false;

    setError(true);
  }, []);

  const handleRejection = useCallback((event) => {
    if (event.reason instanceof OfflineError) return false;

    const reason = event?.reason || 'Rejection with no reason was caught';
    monitoring.captureException(reason);

    setError(true);
  }, []);

  useEffect(() => {
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  }, [handleError, handleRejection]);

  return {
    error,
  };
};

export default useErrorHandling;
