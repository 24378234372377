import styled from 'styled-components';
import { handleTextOverflow } from '../utilities/styles';

export const Form = styled.form<any>`
  width: 100%;
`;

export const NameEdit = styled.input<any>`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight};
  font-size: ${({ theme }) => theme.fontSize};
  line-height: ${({ theme }) => theme.lineHeight};
  background-color: ${({ theme }) => theme.backgroundColor};
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.color};
  width: 100%;
  ${handleTextOverflow}

  &:hover {
    text-decoration: ${({ theme }) => theme.activeTextDecoration};
  }
`;

export const Name = styled.label<any>`
  font-weight: ${({ theme }) => theme.fontWeight};
  font-size: ${({ theme }) => theme.fontSize};
  line-height: ${({ theme }) => theme.lineHeight};
  color: ${({ theme }) => theme.color};
  ${handleTextOverflow}
`;
