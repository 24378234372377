import React, { useCallback, useEffect, useRef, useState } from 'react';

import { defaultSliderTheme } from './theme';
import {
  SliderBackground,
  SliderForeground,
  Handle,
  Label,
  MainContainer,
  Marks,
  Mark,
} from './styles';
import useDynamicTheme from '../../hooks/useDynamicTheme';
import useThrottle from '../../hooks/useThrottle';
import useDebounce from '../../hooks/useDebounce';
import useRAFThrottle from '../../hooks/useRAFThrottle';
import Spacer from '../Spacer/Spacer';
import { SliderProps } from './types';
import { useSliderMarks } from './useSliderMarks';

const LEFT_ARROW = 37;
const UP_ARROW = 38;
const DOWN_ARROW = 40;
const RIGHT_ARROW = 39;
const WHEEL_DELAY = 32;

const Slider: React.FC<SliderProps> = ({
  label,
  min = 0,
  max = 100,
  precision = 2,
  pointerPolicy = 'delta',
  value,
  disabled,
  onChanging,
  onChanged,
  onFocus,
  onBlur,
  allowWheel,
  onChangedDelay = 300,
  hideHandle,
  dataTestId,
  showCenterGuide,
  ...props
}) => {
  const [theme] = useDynamicTheme(defaultSliderTheme, props.theme);

  const step = props.step ?? 0.1 ** precision;

  const getPercentageFromValue = useCallback(
    (value: number, pixelOffset = 0) => {
      const perc = max !== min ? ((value - min) / (max - min)) * 100 : min;
      return `calc(${perc}% + ${
        (1 - perc / 100) * (theme.handleHeight + theme.handlePadding)
      }px + ${pixelOffset}px)`;
    },
    [max, min, theme.handleHeight, theme.handlePadding]
  );

  const getTruncatedValue = useCallback(
    (value: number) => {
      const valueInPrecision = parseFloat(value.toFixed(precision));
      return Math.max(min, Math.min(valueInPrecision, max));
    },
    [min, max, precision]
  );

  const initialValue = getTruncatedValue(value);
  const currentValue = useRef<number>(initialValue);
  const [percentage, setPercentage] = useState(
    getPercentageFromValue(initialValue)
  );
  const [sliding, setSliding] = useState(false);
  const mainContainer = useRef<HTMLDivElement | null>(null);
  const track = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updatedValue = getTruncatedValue(value);
    currentValue.current = updatedValue;
    setPercentage(getPercentageFromValue(updatedValue));
  }, [value, min, max, getPercentageFromValue, getTruncatedValue]);

  const onChangedDebounced = useDebounce({
    delay: onChangedDelay,
    callback: (value: number) => onChanged && onChanged(value),
  });

  const onChangingThrottled = useRAFThrottle(
    (value: number) => onChanging && onChanging(value)
  );

  const { marks, getClosestMarkedValue, getNextOrPreviousMarkedValue } =
    useSliderMarks({ ...props, max, min });

  const setValue = useCallback(
    (newValue: number) => {
      if (newValue === currentValue.current) return;

      currentValue.current = newValue;
      setPercentage(getPercentageFromValue(currentValue.current));

      onChangingThrottled(newValue);
      onChangedDebounced(newValue);
    },
    [
      currentValue,
      getPercentageFromValue,
      onChangingThrottled,
      onChangedDebounced,
    ]
  );

  // The actual value based on the pointer position, without restrictions
  const realValueFromPointerPosition = useCallback(
    (pointerX: number) => {
      const trackClientRect = track.current!.getBoundingClientRect();
      const relativePointerX =
        pointerX - (trackClientRect.x + theme.handleHeight / 2);
      return (
        (relativePointerX / (trackClientRect.width - theme.handleHeight)) *
          (max - min) +
        min
      );
    },
    [max, min, theme.handleHeight]
  );

  // Once a value is computed, withStepping can be called to add stepping logic
  const withStepping = useCallback(
    (value: number, newValue: number): number => {
      const direction = Math.sign(newValue - value);
      if (pointerPolicy === 'closest') {
        const prevStep: number =
          value +
          Math.floor(Math.abs(value - newValue) / step) * step * direction;
        const nextStep = prevStep + step * direction;
        const distNextStep = Math.abs(newValue - nextStep);
        const distPrevStep = Math.abs(newValue - prevStep);
        if (distNextStep >= distPrevStep) return prevStep;
        return nextStep;
      }
      return (
        value + Math.floor(Math.abs(value - newValue) / step) * step * direction
      );
    },
    [step, pointerPolicy]
  );

  const setValueFromPointerPosition = useCallback(
    (pointerX: number) => {
      let newValue = realValueFromPointerPosition(pointerX);
      if (marks) {
        newValue = getClosestMarkedValue(marks, newValue, currentValue.current);
      } else {
        newValue = withStepping(currentValue.current, newValue);
      }
      newValue = getTruncatedValue(newValue);
      setValue(newValue);
    },
    [
      getClosestMarkedValue,
      realValueFromPointerPosition,
      withStepping,
      setValue,
      marks,
      getTruncatedValue,
    ]
  );

  const stepTrack = useCallback(
    (direction: number): void => {
      let newValue;
      if (marks) {
        newValue = getNextOrPreviousMarkedValue(
          marks,
          currentValue.current,
          direction
        );
      } else {
        newValue = currentValue.current + step * direction;
      }
      newValue = getTruncatedValue(newValue);
      setValue(newValue);
    },
    [
      setValue,
      getTruncatedValue,
      currentValue,
      marks,
      getNextOrPreviousMarkedValue,
      step,
    ]
  );

  const handlePointerMove = (
    event: React.PointerEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ): void => {
    if (!sliding) return;
    let pointerX;
    if ('clientX' in event) {
      pointerX = event.clientX;
    } else {
      pointerX = event.touches[0].clientX;
    }
    setValueFromPointerPosition(pointerX);
  };

  const handlePointerDown = (
    event: React.PointerEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ): void => {
    if ('pointerId' in event) {
      (event.target as HTMLDivElement).setPointerCapture(event.pointerId);
    }
    setSliding(true);
  };

  const handlePointerUp = (
    event: React.PointerEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ): void => {
    if ('pointerId' in event) {
      (event.target as HTMLDivElement).releasePointerCapture(event.pointerId);
    }
    setSliding(false);

    let pointerX = null;
    if ('clientX' in event) {
      pointerX = event.clientX;
    } else if (event.touches?.length) {
      pointerX = event.touches[0].clientX;
    }

    if (pointerX !== null) {
      setValueFromPointerPosition(pointerX);
    }
  };

  const handleWheel = useCallback(
    (event: WheelEvent): void => {
      mainContainer.current?.focus();
      const direction = Math.sign(event.deltaY) * -1;
      stepTrack(direction);
    },
    [stepTrack]
  );
  const throttledHandleWheel = useThrottle({
    delay: WHEEL_DELAY,
    callback: handleWheel,
    skipLeading: false,
  });

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      event.preventDefault();

      const key = event.keyCode;
      let direction;
      if (key === LEFT_ARROW || key === DOWN_ARROW) direction = -1;
      else if (key === RIGHT_ARROW || key === UP_ARROW) direction = 1;
      else return;

      stepTrack(direction);
    },
    [stepTrack]
  );

  const handleEvent = useCallback(
    // TODO: find better types for event
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (handler: ((event: any) => void) | undefined, event: any) => {
      if (disabled) return;
      return handler && handler(event);
    },
    [disabled]
  );

  /**
   * This is necessary for older mobile devices using the TouchEvents interface, since
   * otherwise, default browser behaviour can prioritize finger gestures on the document
   * over the slider. Right now there's no other way to add listeners in React with passive: false
   */
  useEffect(() => {
    const preventDefaultGestures = (event: TouchEvent): void => {
      if (sliding) event.preventDefault();
    };
    document.addEventListener('touchmove', preventDefaultGestures, {
      passive: false,
    });

    return (): void =>
      document.removeEventListener('touchmove', preventDefaultGestures);
  }, [sliding]);

  useEffect(() => {
    if (!mainContainer.current || !allowWheel) return;
    const mainContainerCurrent = mainContainer.current;
    const _handleWheel = (event: WheelEvent): void => {
      event.preventDefault();
      handleEvent(throttledHandleWheel, event);
    };
    mainContainerCurrent.addEventListener('wheel', _handleWheel, {
      passive: false,
    });

    return (): void => {
      mainContainerCurrent.removeEventListener('wheel', _handleWheel);
    };
  }, [
    allowWheel,
    mainContainer,
    handleWheel,
    handleEvent,
    throttledHandleWheel,
  ]);

  const styledProps = {
    theme,
    disabled,
    percentage,
  };

  if (min > max)
    throw new Error('Props min and max should be such that max >= min');

  return (
    <MainContainer
      ref={mainContainer}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>): void =>
        handleEvent(handleKeyDown, event)
      }
      onFocus={(event: React.FocusEvent<HTMLDivElement>): void =>
        handleEvent(onFocus, event)
      }
      onBlur={(event: React.FocusEvent<HTMLDivElement>): void =>
        handleEvent(onBlur, event)
      }
      tabIndex="0"
    >
      {label && <Label {...styledProps}>{label}</Label>}
      <SliderBackground
        {...styledProps}
        data-testid={dataTestId}
        hasLabel={!!label}
        ref={track}
        onPointerDown={(event: React.PointerEvent<HTMLDivElement>): void =>
          handleEvent(handlePointerDown, event)
        }
        onPointerUp={(event: React.PointerEvent<HTMLDivElement>): void =>
          handleEvent(handlePointerUp, event)
        }
        onPointerMove={(event: React.PointerEvent<HTMLDivElement>): void =>
          handleEvent(handlePointerMove, event)
        }
        onTouchStart={(event: React.TouchEvent<HTMLDivElement>): void =>
          handleEvent(handlePointerDown, event)
        }
        onTouchEnd={(event: React.TouchEvent<HTMLDivElement>): void =>
          handleEvent(handlePointerUp, event)
        }
        onTouchMove={(event: React.TouchEvent<HTMLDivElement>): void =>
          handleEvent(handlePointerMove, event)
        }
      >
        <SliderForeground {...styledProps}>
          {!hideHandle && <Handle {...styledProps} sliding={sliding} />}
        </SliderForeground>
      </SliderBackground>
      {(marks || showCenterGuide) && (
        <>
          <Spacer h="4px" />
          <Marks theme={theme}>
            <>
              {marks &&
                marks.map((mark) => (
                  <Mark
                    key={mark}
                    theme={theme}
                    left={getPercentageFromValue(mark, -theme.handleHeight / 2)}
                  />
                ))}
              {showCenterGuide && (
                <Mark
                  key="center-guide"
                  theme={theme}
                  left={getPercentageFromValue(0, -theme.handleHeight / 2)}
                />
              )}
            </>
          </Marks>
        </>
      )}
    </MainContainer>
  );
};

export default Slider;
