import { useCallback, useRef } from 'react';

/* Returns a function based on callback that is only allowed to be executed once every frame */

function useRAFThrottle(callback) {
  const plannedCallback = useRef();

  const throttled = useCallback(
    (...args) => {
      if (plannedCallback.current) {
        cancelAnimationFrame(plannedCallback.current);
        plannedCallback.current = null;
      }
      plannedCallback.current = requestAnimationFrame(() => {
        plannedCallback.current = null;
        if (callback) callback(...args);
      });
    },
    [callback]
  );

  return throttled;
}

export default useRAFThrottle;
