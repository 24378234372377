import paper from '@kittl/paper';
import { FADING_COLOR_CUT } from '../../PathTextDecoration.mixin';

/**
 * Apply a decoration to a glyph object
 * @param {String} decoration the decoration type to apply
 * @param {paper.CompoundPath} glyph the glyph object to apply the decoration on
 * @param {*} pathBounds
 * @param {*} options
 */
const applyDecoration = (decoration, glyph, pathBounds, options) => {
  switch (decoration) {
    case 'colorCut':
      return applyColorCut(glyph, pathBounds, options);
    case 'fadingColorCut':
      return applyFadingColorCut(glyph, pathBounds, options);
    case 'horizontalLines':
      return applyHorizontalLines(glyph, pathBounds, options);
    default:
      console.warn('Unknown decoration applied. ', decoration);
      return glyph;
  }
};

const applyColorCut = (glyph, pathBounds, { offset }) => {
  const { maxY, minY } = pathBounds;
  const rect = new paper.Path.Rectangle({
    from: [glyph.bounds.x, maxY],
    to: [glyph.bounds.x + glyph.bounds.width, minY + (maxY - minY) * offset],
  });
  return glyph.subtract(rect);
};

const applyFadingColorCut = (glyph, pathBounds, { offset, weight }) => {
  const { maxY, minY } = pathBounds;
  const xStart = glyph.bounds.x;
  const xEnd = glyph.bounds.x + glyph.bounds.width;
  const height = maxY - minY;

  let y = height * (offset + FADING_COLOR_CUT.getSize(weight) / 2);
  const rect = new paper.Path.Rectangle({
    from: [xStart, maxY],
    to: [xEnd, minY + y],
  });
  glyph = glyph.subtract(rect);

  for (let i = 5; i >= 1; i--) {
    const gap = FADING_COLOR_CUT.getGapHeight(i, weight) * height;
    const rectHeight = FADING_COLOR_CUT.getRectHeight(i, weight) * height;
    y -= gap;

    const rect = new paper.Path.Rectangle({
      from: [xStart, minY + y],
      // its necessary to add `+ 0.0035` extra, to counter the stroke
      to: [xEnd, minY + y - (rectHeight + 0.0035)],
    });
    glyph = glyph.subtract(rect);

    y -= rectHeight;
  }

  return glyph;
};

const applyHorizontalLines = (glyph, pathBounds, { distance, weight }) => {
  const { maxY, minY } = pathBounds;
  const height = maxY - minY;

  const absoluteDistance = distance * height;
  const absoluteWeight = weight * height;
  const increment = absoluteDistance + absoluteWeight;
  for (let y = minY; y < maxY; y += increment) {
    const rect = new paper.Path.Rectangle({
      point: [glyph.bounds.x, y],
      size: [glyph.bounds.width, absoluteDistance],
    });
    glyph = glyph.subtract(rect);
  }
  return glyph;
};

export default applyDecoration;
