export enum NotificationType {
  FOLLOW = 'FOLLOW',
  ADDED_TEMPLATES = 'ADDED_TEMPLATES',
  TRENDING_TEMPLATES = 'TRENDING_TEMPLATES',
  DESIGN_SHARED = 'DESIGN_SHARED',
  DESIGN_COMMENT = 'DESIGN_COMMENT',
  BOOKMARK = 'BOOKMARK',
  LIKE = 'LIKE',
  TEMPLATE_FEATURED = 'TEMPLATE_FEATURED',
  DESIGN_UPVOTE = 'DESIGN_UPVOTE',
  COMMENT_MENTION = 'COMMENT_MENTION',
}

export interface GenericNotification<
  Type extends NotificationType,
  Data = unknown
> {
  id: string;
  type: Type;
  data: Data;
  readAt: string | null;
  seenAt: string | null;
  createdAt: string;
  relatedUser?: {
    slug: string | null;
    name: string | null;
    profileImage: string | null;
  };
}

export type FollowNotification = GenericNotification<
  NotificationType.FOLLOW,
  { message: string }
>;

export type AddedTemplatesNotification = GenericNotification<
  NotificationType.ADDED_TEMPLATES,
  { id: number; name: string; templatePreviewObjectName: string }[]
>;

export type TrendingTemplatesNotification = GenericNotification<
  NotificationType.TRENDING_TEMPLATES,
  { id: number; name: string; templatePreviewObjectName: string }[]
>;

export type DesignSharedNotification = GenericNotification<
  NotificationType.DESIGN_SHARED,
  {
    path: string;
    link: string;
    objectName: string;
  }
>;

export type DesignCommentNotification = GenericNotification<
  NotificationType.DESIGN_COMMENT,
  {
    path: string;
    templatePreviewObjectName: string;
    postId: string;
    comment: string;
  }
>;

export type BookmarkNotification = GenericNotification<
  NotificationType.BOOKMARK,
  {
    path: string;
    templatePreviewObjectName: string;
  }
>;

export type LikeNotification = GenericNotification<
  NotificationType.LIKE,
  {
    path: string;
    templatePreviewObjectName: string;
  }
>;

export type TemplateFeaturedNotification = GenericNotification<
  NotificationType.TEMPLATE_FEATURED,
  {
    path: string;
    templatePreviewObjectName: string;
  }
>;

export type DesignUpvoteNotification = GenericNotification<
  NotificationType.DESIGN_UPVOTE,
  {
    path: string;
    templatePreviewObjectName: string;
  }
>;

export type CommentMentionNotification = GenericNotification<
  NotificationType.COMMENT_MENTION,
  { name: string; path: string }
>;

export type Notification =
  | FollowNotification
  | AddedTemplatesNotification
  | TrendingTemplatesNotification
  | DesignSharedNotification
  | DesignCommentNotification
  | BookmarkNotification
  | LikeNotification
  | TemplateFeaturedNotification
  | DesignUpvoteNotification
  | CommentMentionNotification;
