import { getElementById, getParent } from '../groupStructure';
import { CLIPBOARD_LOCAL_STORAGE_KEY } from '../../global/constants';
import identity from 'lodash/identity';
import { fabric } from 'fabric';
import { nanoid } from 'nanoid';
import cloneDeep from 'lodash/cloneDeep';

export const getObjectById = (id, objects) => {
  return objects.find((obj) => obj.id === id);
};

/**
 * copy the currently selected objects to clipboard
 */
export const copySelection = (
  selection,
  structureSelection,
  groupStructure
) => {
  const objects = [];

  selection
    .filter((object) => object.type !== 'artboard')
    .forEach((obj) => {
      const toObj = obj.toObject();
      objects.push(toObj);
    });

  // The parent node of the nodes we are copying
  let parentStructure = getParent(groupStructure, structureSelection[0]);

  // this should never happen. This means that the object to copy is not
  // part of the current groupStructure
  if (!parentStructure) return;

  const structuresToCopy = parentStructure.children.filter((element) =>
    structureSelection.some((selected) => getElementById(element, selected))
  );

  // if object is in a mask, we don't want to paste it into the mask again, so we take the parents parent
  if (parentStructure.meta?.type === 'clippingMask') {
    parentStructure = getParent(groupStructure, parentStructure.id);
    objects.forEach((object) => (object.clipPathMaskId = null));
  }

  localStorage.setItem(
    CLIPBOARD_LOCAL_STORAGE_KEY,
    JSON.stringify({
      objects,
      structuresToCopy,
      parentStructure,
    })
  );
};

export const updateObjectIds = (objects, clone = true) => {
  if (clone) objects = cloneDeep(objects);

  const newObjectIds = {};

  // generate new ids
  objects.forEach((object) => {
    const newId = nanoid();
    newObjectIds[object.id] = newId;
    object.id = newId;
  });

  // update id referneces in other objects
  objects.forEach((object) => {
    if (object.clipPathMaskId) {
      object.clipPathMaskId = newObjectIds[object.clipPathMaskId];
    }
  });

  return { newObjects: objects, newObjectIds };
};

/**
 * Clones an array of objects. We might want to use this instead of fabric's obj.clone method
 * since when applied on an instance of ActiveSelection it breaks transformed PathText.
 */
export const cloneObjects = (objects, reviver = identity, callback) => {
  /*
    Modify objects such that:
      -If an object is an instance of ActiveSelection, replace it with its own objects
      -If not, keep it
  */
  objects = [].concat(
    ...objects.map((obj) =>
      obj instanceof fabric.ActiveSelection ? obj.getObjects() : obj
    )
  );

  /* We want to call {callback} when all objects are cloned, so we store promises to avoid callback hell */
  const pendingClones = objects.map((obj) => {
    return new Promise((resolve) => {
      const resolveRevived = (clone) => {
        if (obj.group && obj.group instanceof fabric.ActiveSelection) {
          clone.group = obj.group;
          // transform cloned group
          obj.group.realizeTransform(clone);
          delete clone.group;
          clone.setCoords();
        }

        /* Promise.resolve is here to support both Promise-returning and not Promise-returning revivers */
        Promise.resolve(reviver(clone)).then((revived) => resolve(revived));
      };

      obj.clone(resolveRevived);
    });
  });

  return Promise.all(pendingClones).then(
    (clones) => callback && callback(clones)
  );
};

/**
 * utility to get absolute points for an object based on a set of relative points
 * @param {*} relativePoints a list of points
 * @param {*} object a fabric object
 * @returns absolute points
 */
export const getAbsolutePointsOnObject = (relativePoints, object) => {
  const matrix = object.calcTransformMatrix();

  return relativePoints.map((p) => {
    // get untransformed point relative to the center of the object
    const beforeTransform = new fabric.Point(
      object.width * p.x - object.width / 2,
      object.height * p.y - object.height / 2
    );
    // apply objects transform to the point and extract coordinates
    const { x, y } = fabric.util.transformPoint(beforeTransform, matrix);
    return { x, y };
  });
};

export const matchObjectPosition = (
  obj,
  refObj,
  maintainAspectRatio = false
) => {
  /* eslint-disable-next-line prefer-const */
  let { scaleX, scaleY, width, height, angle, left, top } = refObj;

  /*
    Sometimes these aren't set in fabric objects
  */
  scaleX = scaleX || 1;
  scaleY = scaleY || 1;
  angle = angle || 0;
  obj.left = left;
  obj.top = top;
  obj.angle = angle;

  if (maintainAspectRatio) {
    // Scale proportionally
    const aspectRatio = (width * scaleX) / (height * scaleY);
    if (aspectRatio < 1) {
      obj.scaleY = (height * scaleY) / obj.height;
      obj.scaleX = obj.scaleY;
    } else {
      obj.scaleX = (width * scaleX) / obj.width;
      obj.scaleY = obj.scaleX;
    }

    /*
      There is now a remainder or excess along one of the two axis.
      Calculate the difference and offset half to center.
    */
    const xDiff = width * scaleX - obj.width * obj.scaleX;
    const yDiff = height * scaleY - obj.height * obj.scaleY;
    obj.left += xDiff / 2;
    obj.top += yDiff / 2;
  } else {
    obj.scaleY = (height * scaleY) / obj.height;
    obj.scaleX = (width * scaleX) / obj.width;
  }

  /*
    When the preview image for an Illustration is removed from the group,
    fabric sets flips to false, so we also account for that here.

    There's a cast because having undefined flips causes some fabric bugs.
  */
  obj.flipX = !!refObj.flipX;
  obj.flipY = !!refObj.flipY;
};

export const getBounds = (obj, includeExtras = false) => {
  //Gets bounds of path text without taking into account the custom edit box (so, only the visible path)
  const getOnlyPathTextBounds = (pathTextObj) => {
    const { width, height } = pathTextObj._calcDimensions();
    const tl = { x: -width / 2, y: -height / 2 };
    const tr = { x: width / 2, y: -height / 2 };
    const bl = { x: -width / 2, y: height / 2 };
    const br = { x: width / 2, y: height / 2 };
    return [tl, tr, bl, br].map((p) =>
      fabric.util.transformPoint(p, pathTextObj.calcTransformMatrix())
    );
  };

  let corners;

  /*
    We only include the shadows and borders of shapes when exporting previews for layouts.
    In this case, we can't use the normal bounds for text because they might be bigger than the actual text,
    so we use getOnlyPathTextBounds
  */
  if (obj.type === 'pathText' && includeExtras) {
    corners = getOnlyPathTextBounds(obj.pathText);
  } else {
    corners = Object.keys(obj.aCoords).reduce((acc, key) => {
      const { x, y } = obj.aCoords[key];
      const point = new fabric.Point(x, y);
      return [point, ...acc];
    }, []);
  }

  if (obj.group) {
    corners = corners.map((p) => {
      return fabric.util.transformPoint(p, obj.group.calcTransformMatrix());
    });
  }

  if (includeExtras) {
    /*
      Gets extra points.
      Finds all pathText objects and shape objects
      and extends the corners list by using either shadows (text) or stroke (basic shapes)
    */
    const getExtraPoints = (obj, acc = []) => {
      if (obj.type === 'pathText') {
        const customExtras = [
          obj.pathShadow,
          obj.pathDecoration,
          obj.pathDecorationShadow,
        ];

        let extraPoints = [].concat(
          ...customExtras
            .filter(
              (extra) =>
                extra &&
                extra.width &&
                extra.height &&
                extra.visible &&
                extra.path?.length
            )
            .map((extra) => {
              const dims = extra._calcDimensions();
              const { width, height } = dims;
              let { top, left } = dims;
              const {
                pathOffset: { x: offsetX, y: offsetY },
              } = obj.pathText;

              left -= offsetX;
              top -= offsetY;

              const tl = { x: left, y: top };
              const br = { x: tl.x + width, y: tl.y + height };
              const bl = { x: tl.x, y: br.y };
              const tr = { x: br.x, y: tl.y };

              return [tl, br, tr, bl].map((p) =>
                fabric.util.transformPoint(
                  p,
                  obj.pathText.calcTransformMatrix()
                )
              );
            })
        );

        /*
            This accounts for the default fabric shadow (we use it for drop shadow)
        */
        const { shadow } = obj;
        if (shadow) {
          const {
            width: textWidth,
            height: textHeight,
            top: textTop,
            left: textLeft,
          } = getBounds(obj, false);
          const { offsetX, offsetY, blur } = shadow;
          const shadowTL = {
            x: textLeft - blur + offsetX,
            y: textTop + offsetY - blur,
          };
          const shadowBR = {
            x: shadowTL.x + textWidth + blur,
            y: shadowTL.y + textHeight + blur,
          };
          extraPoints = [...extraPoints, shadowTL, shadowBR];
        }

        return [...acc, ...extraPoints];
      } else if (obj.type === 'basicShape' && obj.strokeWidth) {
        const {
          width: shapeWidth,
          height: shapeHeight,
          top: shapeTop,
          left: shapeLeft,
        } = getBounds(obj, false);

        const shapeTL = {
          x: shapeLeft - obj.strokeWidth / 2,
          y: shapeTop - obj.strokeWidth / 2,
        };
        const shapeBR = {
          x: shapeTL.x + shapeWidth + obj.strokeWidth,
          y: shapeTL.y + shapeHeight + obj.strokeWidth,
        };

        return [...acc, shapeTL, shapeBR];
      } else if (obj.type === 'activeSelection') {
        return [
          ...acc,
          ...[].concat(...obj._objects.map((o) => getExtraPoints(o))),
        ];
      }
      return acc;
    };

    const extraPoints = getExtraPoints(obj);
    corners.push(...extraPoints);
  }

  const allX = corners.map((p) => p.x);
  const allY = corners.map((p) => p.y);
  const minX = Math.min(...allX);
  const maxX = Math.max(...allX);
  const minY = Math.min(...allY);
  const maxY = Math.max(...allY);
  const width = maxX - minX;
  const height = maxY - minY;
  const left = minX;
  const top = minY;

  return {
    left,
    top,
    height,
    width,
  };
};
