import styled from 'styled-components';
import { themeStyle } from '../../services/theming';
import { darkBackgroundBlur } from '../utilities/styles';

export const PopoverWrapper = styled.div`
  position: absolute;

  width: ${(props) => props.width};
  padding: ${(props) => props.margin};
  box-sizing: border-box;
  opacity: ${(props) => (props.isInPosition ? '1' : '0')};
  transition: opacity 0.5s;
  z-index: ${(props) => props.theme.zIndex};

  /* 5px is the height of the tip that is visible */
  transform: ${({ placement }) => {
    if (placement === 'top') {
      return 'translateY(-5px)';
    } else if (placement === 'bottom') {
      return 'translateY(5px)';
    } else if (placement === 'left') {
      return 'translateX(-5px)';
    } else if (placement === 'right') {
      return 'translateX(5px)';
    }
  }};
`;

export const Content = styled.div`
  position: relative;
  max-height: 100vh;
  width: 100%;
  overflow-y: ${({ theme }) => theme?.contentOverflowY ?? 'auto'};
  box-sizing: border-box;
  background-color: ${(props) => props.theme.backgroundColor};
  box-shadow: ${(props) => props.theme.boxShadow};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${({ theme }) => (theme.padding ? `${theme.padding}` : '10px')};

  ${darkBackgroundBlur}
`;

export const PopoverTip = styled.div`
  position: absolute;
  margin: ${(props) => props.margin};
  height: 10px;
  width: 10px;
  z-index: ${(props) => props.theme.zIndexTip};
  border-radius: 0px 1px 0px 0px;
  background-color: ${(props) => props.theme.backgroundColor};
  ${({ placement }) => {
    if (placement === 'top') {
      return 'bottom: -4px; transform: rotate(-225deg);';
    } else if (placement === 'bottom') {
      return 'top: -4px; transform: rotate(-45deg);';
    } else if (placement === 'left') {
      return 'right: -4px; transform: rotate(45deg);';
    } else if (placement === 'right') {
      return 'left: -4px; transform: rotate(225deg);';
    }
  }}
`;

export const PopoverContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  pointer-events: auto;
  z-index: ${themeStyle.zIndexM1};
`;

export const HeadWrapper = styled.div`
  padding: ${(props) => props.theme.headPadding};
  border-bottom: ${({ theme }) => (theme.hasHeadBorder ? '1px solid' : 'none')};
  border-color: ${themeStyle.varInkSuperLight};
  box-sizing: border-box;
`;
