import analytics from '.';
import {
  ACTIVE_COPY,
  ACTIVE_GROUP,
  ACTIVE_ALIGN,
  ACTIVE_DECORATION,
  ACTIVE_FONTFAMILY,
  ACTIVE_COLOR,
  ACTIVE_STROKE_WIDTH,
  ACTIVE_GLYPH,
  ACTIVE_FONTSIZE,
  ACTIVE_LETTERSPACING,
  ACTIVE_TEXTALIGNMENT,
  ACTIVE_UNDERLINE,
  ACTIVE_LIGATURES,
  ACTIVE_LINEHEIGHT,
  ACTIVE_UPPERCASE,
  ACTIVE_PASTE,
  ACTIVE_SHADOW,
  ACTIVE_TRANSFORM,
  ACTIVE_REFLECT_HORIZONTAL,
  ACTIVE_REFLECT_VERTICAL,
  SET_COLOR_PALETTE,
  ADD_BASIC_SHAPE,
  ADD_DESIGN,
  ADD_ILLUSTRATION,
  ADD_TEXT,
  ADD_TEXTURE,
  ADD_TEXT_LAYOUT,
  COLOR_PICKING,
  DELETE_TEXTURE,
  DELETE_UPLOAD,
  DESIGN_NEW,
  DESIGN_RENAME,
  DESIGN_SAVE,
  EDIT_SHAPE,
  EDIT_TEXT,
  EDIT_TEXTURE,
  GRID_SHOW,
  LAYER_HIDDEN,
  LAYER_LOCKED,
  LAYER_MOVE,
  LAYER_SELECTION,
  LAYER_SEND_TO,
  PICK_COLOR,
  RESIZE,
  SOCIAL_SHARE,
  TRIM_VIEW,
  UNDO,
  REDO,
  HIDE_TRANSPARENCY_GRID,
  SET_COLOR_PALETTE_PRESET,
  ADD_PHOTO,
  DESIGN_DELETE,
  DESIGN_SHARE,
  POST_SUBMIT,
} from '../events';

// Global events that are tracked
const ANALYTICS_EVENTS = [
  PICK_COLOR,
  COLOR_PICKING,
  EDIT_TEXT,
  EDIT_SHAPE,
  EDIT_TEXTURE,
  DELETE_TEXTURE,
  ACTIVE_COLOR,
  ACTIVE_STROKE_WIDTH,
  ACTIVE_GLYPH,
  ACTIVE_FONTSIZE,
  ACTIVE_LETTERSPACING,
  ACTIVE_TEXTALIGNMENT,
  ACTIVE_UNDERLINE,
  ACTIVE_LIGATURES,
  ACTIVE_LINEHEIGHT,
  ACTIVE_UPPERCASE,
  ACTIVE_FONTFAMILY,
  ACTIVE_SHADOW,
  ACTIVE_TRANSFORM,
  ACTIVE_DECORATION,
  ACTIVE_REFLECT_HORIZONTAL,
  ACTIVE_REFLECT_VERTICAL,
  SET_COLOR_PALETTE,
  GRID_SHOW,
  TRIM_VIEW,
  RESIZE,
  ADD_TEXT,
  ADD_ILLUSTRATION,
  ADD_BASIC_SHAPE,
  ADD_TEXTURE,
  ADD_TEXT_LAYOUT,
  ADD_PHOTO,
  ACTIVE_COPY,
  ACTIVE_PASTE,
  ACTIVE_GROUP,
  ACTIVE_ALIGN,
  LAYER_SEND_TO,
  ADD_DESIGN,
  DELETE_UPLOAD,
  DESIGN_RENAME,
  DESIGN_SAVE,
  DESIGN_NEW,
  LAYER_SELECTION,
  LAYER_MOVE,
  LAYER_HIDDEN,
  LAYER_LOCKED,
  SOCIAL_SHARE,
  UNDO,
  REDO,
  HIDE_TRANSPARENCY_GRID,
  SET_COLOR_PALETTE_PRESET,
  DESIGN_DELETE,
  DESIGN_SHARE,
  POST_SUBMIT,
];

/**
 * Function that tracks artboard events in analytics tools
 * @param {*} event Artboard event
 */
export default function (event) {
  if (!ANALYTICS_EVENTS.includes(event?.key)) return;

  const value = event?.val;
  switch (event.key) {
    case COLOR_PICKING:
      analytics.track(COLOR_PICKING, { label: value?.toString() });
      break;
    case ACTIVE_FONTFAMILY:
      analytics.track(ACTIVE_FONTFAMILY, { label: value.fontFamily });
      break;
    case ACTIVE_SHADOW:
      !value.isChanging &&
        analytics.track(ACTIVE_SHADOW, { label: value.type });
      break;
    case ACTIVE_TRANSFORM:
      analytics.track(ACTIVE_TRANSFORM, { label: value.type });
      break;
    case ACTIVE_DECORATION:
      !value.isChanging &&
        analytics.track(ACTIVE_DECORATION, { label: value.type });
      break;
    case ACTIVE_ALIGN:
      analytics.track(ACTIVE_ALIGN, { label: value });
      break;
    case LAYER_SEND_TO:
      analytics.track(LAYER_SEND_TO, { label: value });
      break;
    case GRID_SHOW:
      analytics.track(GRID_SHOW, { label: value?.toString() });
      break;
    case TRIM_VIEW:
      analytics.track(TRIM_VIEW, { label: value?.toString() });
      break;
    case ADD_ILLUSTRATION:
      analytics.track(ADD_ILLUSTRATION, {
        label: value.objectName,
      });
      break;
    case ADD_PHOTO:
      analytics.track(ADD_PHOTO, {
        label: value.objectName,
      });
      break;
    case ADD_BASIC_SHAPE:
      analytics.track(ADD_BASIC_SHAPE, {
        label: value.objectName,
      });
      break;
    case ADD_TEXTURE:
      analytics.track(ADD_TEXTURE, {
        label: value.objectName,
      });
      break;
    case ADD_TEXT_LAYOUT:
      analytics.track(ADD_TEXT_LAYOUT, {
        label: value.preview,
      });
      break;
    case DESIGN_RENAME:
      analytics.track(DESIGN_RENAME, { label: value.title });
      break;
    case DESIGN_SAVE:
      // value.type is `auto` or `manual`, we skip tracking autosaves for now
      if (value.type !== 'auto') {
        analytics.track(DESIGN_SAVE, { label: value.type });
      }
      break;
    case SOCIAL_SHARE:
      analytics.track(SOCIAL_SHARE, { label: value.label });
      break;
    case RESIZE:
      !value.isChanging && analytics.track(RESIZE, { label: value.key });
      break;
    case SET_COLOR_PALETTE_PRESET:
      analytics.track(SET_COLOR_PALETTE_PRESET, {
        label: value.presetName,
      });
      break;
    default:
      if (
        [
          ACTIVE_COLOR,
          SET_COLOR_PALETTE,
          ACTIVE_FONTSIZE,
          ACTIVE_LETTERSPACING,
          ACTIVE_LINEHEIGHT,
          ACTIVE_STROKE_WIDTH,
          EDIT_TEXTURE,
        ].includes(event.key)
      ) {
        !value.isChanging && analytics.track(event.key);
      } else {
        // default handler just tracks event without adding extra data
        analytics.track(event.key);
      }
  }
}
