export const config = [
  {
    key: 'social',
    label: 'Social Media',
    title: 'Instagram, Facebook, Twitter, Pinterest etc.',
    text: 'Insert the following credit line in the description of your post.',
    credit: 'Created with www.kittl.com',
  },
  {
    key: 'web',
    label: 'Web & Apps',
    title: 'Websites, apps, blogs, ebooks, newsletters etc.',
    text: `Copy this link and paste it in a visible place, close to where you're using the design. If that's not possible, place it at the footer of your website.`,
    credit: '<a href="https://www.kittl.com">Created with www.kittl.com</a>',
  },
  {
    key: 'print',
    label: 'Print',
    title: 'Books, packaging, clothing, flyers etc.',
    text: `Insert the credit line close to where you're using the design.`,
    credit: 'Created with www.kittl.com',
  },
  {
    key: 'video',
    label: 'Video',
    title: 'Online video platforms like Youtube, Vimeo etc.',
    text: 'Paste this link in the appropriate area of the video description.',
    credit: '<a href="https://www.kittl.com">Created with www.kittl.com</a>',
  },
];
