import React from 'react';
import PropTypes from 'prop-types';

import { SwicthIcon } from '../styles';

import Icon from '../../Icon/Icon';
import IconEventWrapper from '../../Icon/IconEventWrapper';

import { layerChevronTheme } from '../theme';

/**
 * GroupSwitch
 */
const GroupSwitch = (props) => {
  return (
    <div style={{ transform: `rotate(${props.expanded ? 90 : 0}deg)` }}>
      <IconEventWrapper fitParent={true} theme={layerChevronTheme}>
        <SwicthIcon>
          <Icon
            theme={layerChevronTheme}
            name={'chevronRight'}
            height={'8px'}
          />
        </SwicthIcon>
      </IconEventWrapper>
    </div>
  );
};

GroupSwitch.propTypes = {
  /**
   * Whether the group is expanded
   */
  expanded: PropTypes.bool,
};

export default GroupSwitch;
