import styled from 'styled-components';
import { themeStyle } from '../../services/theming';

export const Wrapper = styled.div`
  position: absolute;
  height: 32px;
  width: 100%;
  background-color: ${themeStyle.highlight};

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${themeStyle.fontFamily};
  font-weight: ${themeStyle.fontMedium};
  font-size: ${themeStyle.fontSize12};
  line-height: 19px;

  color: ${themeStyle.inkBase};
`;

export const Close = styled.div`
  position: absolute;
  right: 10px;
  display: inline-flex;
`;

export const Clickable = styled.span`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: auto;
  font-weight: ${themeStyle.fontBold};
  margin-left: 3px;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'unset')};
`;
