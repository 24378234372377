import React, { memo } from 'react';
import { themeStyle } from '../../services/theming';
import Icon from '../Icon/Icon';
import { Wrapper } from './styles';
import PropTypes from 'prop-types';
import { useOnboardingStore } from '../../stores/onboardingStore';
import { ANCHOR_REF_KEYS } from '../../stores/onboardingStore/constants';

const ColorPaletteButton = (props) => {
  return (
    <Wrapper
      hovering={props.hovering}
      onClick={props.onClick}
      data-testid="document-color-palette-toggler"
      ref={(node) => {
        useOnboardingStore
          .getState()
          .registerStepRef(node, ANCHOR_REF_KEYS.COLORS_BUTTON);
      }}
    >
      <Icon
        name="colorPalette"
        height="14px"
        theme={{ color: themeStyle.varInkMedium }}
      />
    </Wrapper>
  );
};

ColorPaletteButton.propTypes = {
  /**
   * onClick action
   */
  onClick: PropTypes.func,
  /**
   * If the button is being hovered
   */
  hovered: PropTypes.bool,
  hovering: PropTypes.bool,
};

export default memo(ColorPaletteButton);
